import { React, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import TextField from "@mui/material/TextField";

import {
  retrieveUserAttributes,
  retrieveUserProfile,
} from "../../services/UserManagementService";
import "./DealScoutLandingPage.css";
import DealScoutNavBar from "../../components/NavBar/DealScoutNavBar";
import SetPageTitle from "../../components/SetPageTitle/SetPageTitle";
import ResultNumbers from "./ResultNumbers";

import Footer from "../../components/Footer/Footer";
import Industries from "./Industries";
import buyers from "../../assets/DealScout/buyers.webp";
import DealScoutCard from "./DealScoutCard";
import search from "../../assets/DealScout/search.webp";
import source from "../../assets/DealScout/source.webp";
import track from "../../assets/DealScout/track.webp";
import connect from "../../assets/DealScout/connect.webp";
import Action from "./Action";
import { Stack } from "@mui/material";
const Buyers = () => {
  const [userAttributes, setUserAttributes] = useState();
  const [userProfileData, setUserProfileData] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const location = useLocation();

  const dealScoutData = [
    {
      p: " Use our data points to find businesses specifically tailored to your search. These include location, industry, employee size, revenue, and many more",
      n: "50+",
      h: "Data Points",
    },
    {
      h: "Lower Middle Market Companies",
      p: "Find proprietary deals specifically in the SME space that fit your search criteria",
      n: "17M+",
    },
    {
      p: "Connect directly with business owners and key decision makers",
      n: "50M+",
      h: "Email Contacts",
    },
  ];
  const labelsData = [
    {
      position: "left",
      img: search,
      chip: "Versatile Search",
      title: "Source proprietary deals right for you",
      paragraph:
        "Find businesses directly associated with your search criteria. Filter from over 17M lower middle market  companies that fall into your thesis guidelines. ",
      note_1: "Basic info",
      note_2: "Search by location, keywords and categories",
      note_3: "Advanced info",
      note_4: "Narrow your search by employee counts and estimated revenue",
    },
    {
      position: "right",
      img: track,
      chip: "Efficient Management",
      title: "Track your Outreach",
      paragraph:
        "Keep track of potential deals by using tags to update outreach progress",
      note_1: "Always in sync",
      note_2: "Don't worry about the data, always be synchronized",
      note_3: "Keep status noted",
      note_4: "Keep the progress in mind through each update",
    },
  ];

  const listData = {
    position: "right",
    img: source,
    chip: "Powerful Database",
    title: "Access to company's data",
    paragraph:
      "Data points on company's description, contact information, revenue, employee count, location, year founded, and many more",
    points: [
      "Location",
      "Primary contact",
      "Revenue",
      "Year Founded",
      "Employee Count",
      "Contact Information",
    ],
  };

  const numbersData = [
    {
      position: "left",
      img: connect,
      chip: "Strong Connection",
      title: "Create Campaigns",
      paragraph: "Use the CRM function to keep save searches and lists",
      number_1: "17M+",
      note_1: "Lower Middle Market Companies",
      number_2: "50M+",
      note_2: "Email Contacts",
    },
  ];

  const getUserAttributes = async () => {
    retrieveUserAttributes().then((response) => {
      if (response.errorMessage) {
        setErrorMessage(response.errorMessage);
      } else {
        setUserAttributes(response.result);
      }
    });
  };

  const getUserProfile = async () => {
    retrieveUserProfile().then((response) => {
      if (response.errorMessage) {
        setErrorMessage(response.errorMessage);
      } else {
        setUserProfileData(response);
      }
    });
  };
  useEffect(() => {
    if (localStorage.getItem("authorized") && !userAttributes) {
      getUserAttributes();
    } else if (localStorage.getItem("authorized") && !userProfileData) {
      getUserProfile();
    }

    if (
      !window.location.host.includes("localhost") &&
      !window.location.host.includes("dev.onedeal.biz")
    ) {
      window.gtag("event", "page_view", {
        page_path: location.pathname + location.search,
      });
    }
  }, [userAttributes, userProfileData, location]);
  return (
    <div>
      <SetPageTitle title="Buyers | Deal Scout" />
      <DealScoutNavBar
        userAttributes={userAttributes}
        setUserAttributes={setUserAttributes}
        userProfileData={userProfileData}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "0.75rem",
          marginTop: "5rem",
        }}
      >
        <Stack spacing={1} justifyContent="center" alignItems="center">
          <h5 style={{ color: "#0e9384" }}>Buyers</h5>
          <h2>Buyers</h2>
        </Stack>
        <p
          style={{ textAlign: "center", maxWidth: "50%", marginBottom: "3rem" }}
        >
          There are over 17M lower middle market business owners awaiting to be
          contacted by you. Get started by entering your email below.
        </p>

        <button
          className="listingDetails-btn"
          style={{ width: "10rem" }}
          onClick={() => {
            window.open("/deal-scout/checkout", "_blank");
          }}
        >
          Get Started
        </button>

        <img
          src={buyers}
          style={{ maxWidth: "70%", margin: "4rem 0 4rem 0 " }}
          alt="hero"
        ></img>
      </div>
      <Industries />
      <section
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          margin: "5rem 0",
          gap: "4rem",
        }}
      >
        {" "}
        <ResultNumbers data={dealScoutData} />
        <DealScoutCard
          labelsData={labelsData}
          listData={listData}
          numbersData={numbersData}
        />
      </section>
      <Action />
      <Footer />
    </div>
  );
};

export default Buyers;
