import { Button } from "@mui/material";
import React from "react";
import sourcing from "../../assets/Landing/sourcing.webp";
import business from "../../assets/Landing/business.webp";
import { useNavigate } from "react-router-dom";

const Link = (props) => {
  const navigate = useNavigate();

  return (
    <div>
      <section
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            maxWidth: "77%",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h2>
            Source from over{" "}
            <span style={{ color: "var(--base)" }}>
              17M+ proprietary businesses
            </span>
            and over <span style={{ color: "var(--base)" }}>80K+</span> actively
            available brokered deals{" "}
          </h2>
          <p style={{ margin: "1.5rem 0 2rem 0" }}>
            Source and manage proprietary deals more efficiently. Transform your
            manual workflow into an all-in-one hub.{" "}
          </p>
          <button className="listingDetails-btn">
            <p
              className="actionText"
              onClick={() => {
                navigate("/find-proprietary-deals");
              }}
            >
              Proprietary Deal Sourcing
            </p>
          </button>
          <div className="links-img">
            <img src={sourcing} alt=""></img>
          </div>{" "}
        </div>
        <div
          style={{
            maxWidth: "77%",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h2>
            <span style={{ color: "var(--base)" }}>Verified</span> Brokered
            Deals
          </h2>
          <p style={{ margin: "1.5rem 0 2rem 0" }}>
            Use our marketplace to search from over 80,000 actively listed
            deals.
          </p>
          <button className="listingDetails-btn">
            <p
              className="actionText"
              onClick={() => {
                navigate("/brokered-deals");
              }}
            >
              Browse Businesses
            </p>
          </button>
          <div className="links-img">
            <img src={business} alt=""></img>
          </div>{" "}
        </div>
      </section>
    </div>
  );
};

export default Link;
