import { React } from 'react';
import IconButton from '@mui/material/IconButton';
import { modifyUserFavoriteListings } from '../../../services/UserManagementService';
import { HashLink } from 'react-router-hash-link';
import { useNavigate } from "react-router-dom";
import { BadgeIcon, HeartIcon, FavoritedHeartIcon } from '../../IconTheme/IconTheme';
import '../../../pages/Landing/App.scss'
import { Link } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteListing, listingStatistics } from '../../../services/ListingManagementService';
import LinearProgress from '@mui/joy/LinearProgress';
import Typography from '@mui/joy/Typography';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';

export const ListingMobile = (props) => {
    const navigate = useNavigate();

    const getLocation = (locationValue) => {
        const splitLocation = locationValue.split(',');
        return `${splitLocation[1]}, ${splitLocation[0]}`;
    }

    const shortNumber = (numberToShorten) => {
        let shortenedNumber = numberToShorten.toString();
        if(numberToShorten >= 1000000){
            shortenedNumber = (numberToShorten / 1000000).toFixed(1) + "M";
        }else if(numberToShorten < 1000000 && numberToShorten > 999){
            shortenedNumber = (numberToShorten / 1000).toFixed(1) + "k";
        }
        return '$' + shortenedNumber.replace('.0','');
    };

    const handleModifyFavorites = (favoritePayload) => {
        if(!props.userAttributes && !localStorage.getItem("authorized")){
            navigate('/login');
        }else{
            modifyUserFavoriteListings(favoritePayload).then((response) => {
                if(response.errorMessage){
                    props.setErrorMessage(response.errorMessage);
                }else{
                    props.setUserFavorites(response.Attributes.favorites);
                }
              });

            if(props.userAttributes.email !== props.listing['posted-by-email']){
                listingStatistics({
                    "user-email": props.userAttributes.email, 
                    "listing": props.listing, 
                    "action": "favorited"
                });
            }
        }
    };

    const getLoadingBarValue = (status) => {
        let progressPercentage = 0;
        if(status){
            if(status["requested-info"]){
                progressPercentage += 25;
            }
    
            if(status["signed-nda"]){
                progressPercentage += 25;
            }
    
            if(status["dr-request-sent"]){
                progressPercentage += 25;
            }
    
            if(status["dr-request-response"] === "Approved" || status["dr-request-response"] === "Rejected"){
                progressPercentage += 25;
            }
        }
        return progressPercentage;
    }

    const getLoadingBarStep = (progress, status) => {
        let step = "Step 1/4: Requested Info";
        if(progress === 50){
            step = "Step 2/4: Signed NDA";
        }else if(progress === 75){
            step = "Step 3/4: Requested Data room Access";
        }else if(progress === 100 && status["dr-request-response"] === "Approved"){
            step = "Step 4/4: Data Room Access Granted"
        }else if(progress === 100 && status["dr-request-response"] === "Rejected"){
            step = "Step 4/4: Data Room Access Rejected"
        }
        return step;
    }
    
    return(
        <div className="trending-card">
            <HashLink to={`/listingDetails?id=${props.listing['listing-id']}`} className="position-relative">
            <img src={`${process.env.REACT_APP_S3_URL}${props.listing['s3-resource-url']}`} alt="BusinessPhoto"/>
                {'isFeatured' in props.listing && props.listing.isFeatured === 'Y' && <span className="badge cmn-badge"><BadgeIcon /> Featured</span>}
            </HashLink>
            <div className="trending-card-content">
                <div className="content">
                    <div>
                        <h4 className="price">{(props.listing.price) > 0 ? ('$' + props.listing.price.toLocaleString('en-US')) : "N/A"}</h4>
                        <h4 className="title">
                        <HashLink to={`/listingDetails?id=${props.listing['listing-id']}`}>{(props.listing.headline > 40) ? (props.listing.headline.substr(0, 40) + "...") : props.listing.headline}</HashLink>
                        </h4>
                        <div className="location">{(props.listing.location.includes("Undisclosed")) ? "Undisclosed Location" :getLocation(props.listing.location)}</div>
                    </div>
                    {
                        props.userAttributes?.email !== props.listing['posted-by-email'] &&
                        ((props.indexOfFavorite === -1) ? <IconButton onClick={async() => handleModifyFavorites({favoritedListingId: props.listing['listing-id'], unfavoritedIndex:null})}><HeartIcon/></IconButton> : <IconButton onClick={async() =>handleModifyFavorites({favoritedListingId: props.listing['listing-id'], unfavoritedIndex: props.indexOfFavorite})}><FavoritedHeartIcon /></IconButton>)
                    }
                    {
                        props.userAttributes?.email === props.listing['posted-by-email'] && window.location.pathname.includes('myOneDeal') &&
                        <div>
                            <Link to={`/edit-listing?listing-id=${props.listing['listing-id']}`}  state={{ data: props.listing }} className='listed-by-link'>
                                <IconButton sx={{color:"black", fontSize:"16px"}}>
                                    Edit<EditIcon sx={{paddingLeft:"5px"}}/>
                                </IconButton>
                            </Link>
                            <HashLink to={`/edit-listing?listing-id=${props.listing['listing-id']}#dataroom`}  state={{ data: props.listing }} className='listed-by-link'>
                                <IconButton sx={{color:"black", fontSize:"16px"}}>
                                    DataRoom<DriveFolderUploadIcon sx={{paddingLeft:"5px"}}/>
                                </IconButton>
                            </HashLink>  
                            <IconButton sx={{color:"black", fontSize:"16px"}} onClick={async() => await deleteListing({'listing-id': props.listing['listing-id'], 'request-time': props.listing['request-time']}).then(() => props.getUserListings())}>
                                Delete<DeleteIcon sx={{paddingLeft:"5px"}}/>
                            </IconButton> 
                        </div>      
                    }
                </div>
                    {
                       props.dealStatuses &&
                        <LinearProgress
                            determinate
                            size="md"
                            variant="soft"
                            thickness={32}
                            value={
                                getLoadingBarValue(props.dealStatuses[props.listing['listing-id']])
                            }
                            sx={{backgroundColor: "#8DCAC3", color:"#0e9384", marginBottom: "5px"}}
                            >
                                <Typography
                                    level="body3"
                                    fontWeight="xl"
                                    textColor="common.white"
                                    sx={{mixBlendMode: 'color-dodge' }}
                                >
                                    {
                                        getLoadingBarStep(getLoadingBarValue(props.dealStatuses[props.listing['listing-id']]), props.dealStatuses[props.listing['listing-id']])
                                    }
                                </Typography>
                        </LinearProgress>
                    }
                <div className="trending-card-footer">
                    <div>
                        <div className="info">Revenue</div>
                        <h6 className="value">{(props.listing['gross-revenue'] > 0) ? (shortNumber(props.listing["gross-revenue"])) : "N/A"}</h6>
                    </div>
                    <div>
                        <div className="info">Cash Flow</div>
                        <h6 className="value">{(props.listing["cash-flow"] > 0) ? (shortNumber(props.listing["cash-flow"])) : "N/A"}</h6>
                    </div>
                </div>
            </div>
        </div>
    );
}