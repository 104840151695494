import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const SnackBar = (props) => {
  const [open, setOpen] = React.useState(true);
  const handleClose = () => { 
    setOpen(false);
    if(props.errorMessage && !props.successMessage){
      props.setErrorMessage("");
    }
    else if(props.successMessage){
      props.setSuccessMessage("");
    }
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      {
        props.errorMessage &&
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
              {props.errorMessage}
            </Alert>
        </Snackbar>
      }
      {
        props.successMessage &&
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            {props.successMessage}
          </Alert>
       </Snackbar>
      }
    </Stack>
  );
}