import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom/client";
import Landing from "./pages/Landing/Landing";
import BrowseListings from "./pages/BrowseListings/BrowseListings";
import { Login } from "./pages/Login/Login";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import MyOneDeal from "./pages/MyOneDeal/MyOneDeal";
import { PostListing } from "./pages/MyOneDeal/PostAListing";
import ListingDetails from "./pages/ListingDetails/ListingDetails";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import FAQ from "./pages/FAQ/FAQ";
import TermsOfService from "./pages/TermsOfService/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import Unsubscribe from "./pages/Unsubscribe/Unsubscribe";
import UpsertSearch from "./pages/MyOneDeal/SavedSearch/UpsertSearch";
import BrokerProfile from "./pages/BrokerProfile/BrokerProfile";
import BrowseBrokers from "./pages/BrowseBrokers/BrowseBrokers";
import UpsertListing from "./pages/MyOneDeal/UpsertListing";
import TransitionalSignup from "./pages/SignUp/TransitionalSignup";
import BuyerProfile from "./pages/BuyerProfile/BuyerProfile";
import DealScoutBuyers from "./pages/DealScoutLandingPage/Buyers";
import DealScoutBrokers from "./pages/DealScoutLandingPage/Brokers";
import DealScoutCheckout from "./pages/DealScoutCheckout/DealScoutCheckout";
// import DealScout from "./pages/DealScout/DealScout";
// import DealScoutLandingPage from "./pages/DealScoutLandingPage/DealScoutLandingPage";
import DealScoutSwitch from "./pages/DealScout/DealScoutSwitch";
import PaymentConfirmation from "./pages/DealScout/PaymentConfirmation";
import BrokeredDeals from "./pages/Landing/BrokeredDeals";
import Brokers from "./pages/Landing/Brokers";
import ProprietaryDeals from "./pages/Landing/ProprietaryDeals";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/login" element={<Login />} />
      <Route path="/saved-business-search" element={<UpsertSearch />} />
      <Route path="/unsubscribe" element={<Unsubscribe />} />
      <Route path="/frequently-asked-questions" element={<FAQ />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/forgotPassword" element={<ForgotPassword />} />
      <Route path="/signup" element={<TransitionalSignup />} />
      <Route path="/myonedeal/*" element={<MyOneDeal />} />
      <Route path="/listingDetails/*" element={<ListingDetails />} />
      <Route path="/buyer-profile/*" element={<BuyerProfile />} />
      <Route path="/broker-profile/*" element={<BrokerProfile />} />
      <Route path="/edit-listing" element={<UpsertListing />} />
      <Route path="/404" element={<PageNotFound />} />
      <Route path="*" element={<PageNotFound />} />
      {/* New landing page*/}
      <Route path="/businesses-for-sale" element={<BrowseListings />} />
      <Route path="/sell-a-business" element={<PostListing />} />
      <Route path="/browse-brokers" element={<BrowseBrokers />} />
      <Route path="/brokered-deals" element={<BrokeredDeals />} />
      <Route path="/brokers" element={<Brokers />} />
      <Route path="/find-proprietary-deals" element={<ProprietaryDeals />} />

      {/* DealScout */}
      <Route path="/deal-scout" element={<DealScoutSwitch />} />
      <Route path="/deal-scout/buyers" element={<DealScoutBuyers />} />
      <Route path="/deal-scout/brokers" element={<DealScoutBrokers />} />
      <Route path="/deal-scout/checkout" element={<DealScoutCheckout />} />
      <Route
        path="/deal-scout/checkout/confirmation"
        element={<PaymentConfirmation />}
      />
    </Routes>
  </Router>
);
