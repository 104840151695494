import { React, useState } from 'react';
import Joyride from 'react-joyride';
import { useNavigate, useLocation } from "react-router-dom";

const TakeTheTour = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [steps, setSteps] = useState(
        {
            run : true,
            buyerSteps: [
                {
                    target: '#key-word-search',
                    title: "Keyword Search",
                    content: 'Enter relevant keywords related to industry, location, or business type, and discover a wide range of potential opportunities at your fingertips.',
                    disableBeacon: true,
                    placement: "auto"
                },
                {
                    target: '#filter-drawer',
                    title: "Apply Filters",
                    content: 'Easily refine your search results with our advanced filters, setting preferred price, cash flow, revenue, and industry parameters. Additionally, sort by newest, oldest, or other criteria to prioritize relevant opportunities.',
                    disableBeacon: true,
                    placement: "auto",
                },
                {
                    target: '#simple-tab-0',
                    title: "My Deals",
                    content: 'Effortlessly track the progress of all your SMB deals, from requesting more information to signing NDAs and gaining dataroom access.',
                    disableBeacon: true,
                    placement: "right",
                    hideBackButton: true
                },
                {
                    target: '#simple-tab-1',
                    title: "Favorite Listings",
                    content: 'Conveniently bookmark your favorite listings for easy access and quick reference.',
                    disableBeacon: true,
                    placement: "right"
                },
                {
                    target: '#simple-tab-2',
                    title: "Saved Searches",
                    content: 'Easily save your preferred SMB searches with custom parameters like industry, location, keywords, price, revenue, and receive notifications for new businesses that match your criteria.',
                    disableBeacon: true,
                    placement: "right"
                },
                {
                    target: '#simple-tab-3',
                    title: "Prequalify",
                    content: 'Effortlessly store all your pre-qualification documents in a user-friendly and centralized platform.',
                    disableBeacon: true,
                    placement: "right"
                },
                {
                    target: '#simple-tab-4',
                    title: "User Profile",
                    content: 'Easily update your profile with your avatar, name, phone number, and email.',
                    disableBeacon: true,
                    placement: "right"
                },
                {
                    target: '.pricing-card',
                    title: "Searcher Subscription",
                    content: 'Subscribe to work directly with our team for personalized assistance with direct outreach to brokers and pre-qualifying deals on your behalf.',
                    disableBeacon: true,
                    placement: "auto",
                    disableScrolling : true,
                    hideBackButton: true
                }
            ],
            brokerSteps: [
                {
                    target: '#post-a-business',
                    title: "Post A Business",
                    content: 'List your business for sale at no cost and instantly share it with the entire OneDeal buyer community. Provide essential details like location, headline, description, images, financial data, and private diligence documents.',
                    disableBeacon: true,
                    placement: "auto",
                    
                },
                {
                    target: ('#' + props.userEmail.replace("@", "").replaceAll(".","")),
                    title: "Broker Profile",
                    content: 'Easily review and share your public broker profile, showcasing all your posted listings.',
                    disableBeacon: true,
                    placement: "auto",
                },
                {
                    target: '#simple-tab-0',
                    title: "My Listings",
                    content: 'Access and manage all your posted listings on OneDeal with ease. Edit listing details, modify dataroom documents, or delete listings effortlessly.',
                    disableBeacon: true,
                    placement: "right",
                    hideBackButton: true
                },
                {
                    target: '#simple-tab-1',
                    title: "Listing Analytics",
                    content: 'Effortlessly monitor your listing\'s performance with real-time tracking. Stay updated on daily, weekly, monthly, and yearly clicks, as well as favorites and information requests.',
                    disableBeacon: true,
                    placement: "right"
                },
                {
                    target: '#simple-tab-2',
                    title: "DataRoom Requests",
                    content: 'View buyer requests for your listings and access to datarooms. Click on their name to review their profile and approve or reject their requests. All buyers have signed the required NDA before their request is sent to you.',
                    disableBeacon: true,
                    placement: "right"
                },
                {
                    target: '#simple-tab-3',
                    title: "NDAs",
                    content: 'Manage NDAs for your listings. Upload and remove NDAs to be attached to listings, ensuring prospective buyers sign before accessing your dataroom.',
                    disableBeacon: true,
                    placement: "right"
                },
                {
                    target: '#simple-tab-4',
                    title: "Favorite Listings",
                    content: 'Conveniently bookmark your favorite listings for easy access and quick reference.',
                    disableBeacon: true,
                    placement: "right"
                },
                {
                    target: '#simple-tab-5',
                    title: "User Profile",
                    content: 'Easily update your profile with your avatar, name, phone number, and email.',
                    disableBeacon: true,
                    placement: "right"
                }
            ]
          }
    );

    const handleJoyrideCallback = (data) => {
        //buyer rules
        if(props.isBroker === "N"){
            //tour rules for businesses-for-sale
            if(location.pathname.includes("businesses-for-sale")){
                if(data.index === 2){
                    localStorage.setItem('listingManagementTab', 0);
                    navigate(
                        '/myOneDeal/my-deals',
                        {
                            replace: true,
                            state: {showTour : true}
                        }
                    );
                }
            }else if(location.pathname.includes("myOneDeal")){ //tour rules for myOneDeal
                if(data.index === 2){
                    localStorage.setItem('listingManagementTab', 0);
                    props.setTabValue(0);
                    navigate(
                        '/myOneDeal/my-deals',
                        {
                            replace: true,
                            state: {showTour : true}
                        }
                    );
                }else if(data.index === 3){
                    localStorage.setItem('listingManagementTab', 1);
                    props.setTabValue(1); 
                    navigate(
                        '/myOneDeal/favorites',
                        {
                            replace: true,
                            state: {showTour : true}
                        }
                    );
                }else if(data.index === 4){
                    localStorage.setItem('listingManagementTab', 2);
                    props.setTabValue(2); 
                    navigate(
                        '/myOneDeal/saved-searches',
                        {
                            replace: true,
                            state: {showTour : true}
                        }
                    );
                }else if(data.index === 5){
                    localStorage.setItem('listingManagementTab', 3);
                    props.setTabValue(3); 
                    navigate(
                        '/myOneDeal/prequalify',
                        {
                            replace: true,
                            state: {showTour : true}
                        }
                    );
                }else if(data.index === 6){
                    localStorage.setItem('listingManagementTab', 4);
                    props.setTabValue(4); 
                    navigate(
                        '/myOneDeal/user-profile',
                        {
                            replace: true,
                            state: {showTour : true}
                        }
                    );
                }else if(data.index === 7){
                    navigate(
                        '/business-buyers',
                        {
                            state: {showTour : true}
                        }
                    );
                }
            }
        }else{ //broker rules

            if(location.pathname.includes("businesses-for-sale")){
                if(data.index === 0){
                    navigate(
                        '/sell-a-business',
                        {
                            state: {showTour : true}
                        }
                    );
                }
            }else if(location.pathname.includes("sell-a-business")){
                if(data.index === 1){
                    navigate(
                        '/browse-brokers',
                        {
                            state: {showTour : true}
                        }
                    );
                }
            }else if(location.pathname.includes("browse-brokers")){
                if(data.index === 2){
                    localStorage.setItem('listingManagementTab', 0);
                    navigate(
                        '/myOneDeal/my-listings',
                        {
                            replace: true,
                            state: {showTour : true}
                        }
                    );
                }
            }else if(location.pathname.includes("myOneDeal")){
                if(data.index === 2){
                    localStorage.setItem('listingManagementTab', 0);
                    props.setTabValue(0);
                    navigate(
                        '/myOneDeal/my-listings',
                        {
                            replace: true,
                            state: {showTour : true}
                        }
                    );
                }else if(data.index === 3){
                    localStorage.setItem('listingManagementTab', 1);
                    props.setTabValue(1);
                    navigate(
                        '/myOneDeal/listing-analytics',
                        {
                            replace: true,
                            state: {showTour : true}
                        }
                    );
                }else if(data.index === 4){
                    localStorage.setItem('listingManagementTab', 2);
                    props.setTabValue(2);
                    navigate(
                        '/myOneDeal/requests',
                        {
                            replace: true,
                            state: {showTour : true}
                        }
                    );
                }else if(data.index === 5){
                    localStorage.setItem('listingManagementTab', 3);
                    props.setTabValue(3);
                    navigate(
                        '/myOneDeal/non-disclosure-agreements',
                        {
                            replace: true,
                            state: {showTour : true}
                        }
                    );
                }else if(data.index === 6){
                    localStorage.setItem('listingManagementTab', 4);
                    props.setTabValue(4);
                    navigate(
                        '/myOneDeal/favorites',
                        {
                            replace: true,
                            state: {showTour : true}
                        }
                    );
                }else if(data.index === 7){
                    localStorage.setItem('listingManagementTab', 5);
                    props.setTabValue(5);
                    navigate(
                        '/myOneDeal/user-profile',
                        {
                            replace: true,
                            state: {showTour : true}
                        }
                    );
                }       
            }
        }
    };

    return (
      <div className="app">
        <Joyride
          continuous={true}
          hideCloseButton={false}
          run={true}
          scrollToFirstStep={true}
          showProgress
          showSkipButton
          steps={props.isBroker === "N" ? steps.buyerSteps : steps.brokerSteps}
          callback={handleJoyrideCallback}
          styles={{
            options: {
              zIndex: 10000,
              primaryColor: "#0e9384",
              arrowColor:"#0e9384",
              border: "#0e9384",
            },

          }}
          stepIndex={props.stepIndex ? props.stepIndex : null}
        />
      </div>
    );
}

export default TakeTheTour;