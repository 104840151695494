import { React, useState, useEffect } from 'react';
import {Grid} from '@mui/material';
import FilterDesktop from './Desktop/FilterDesktop';
import FilterMobile from './Mobile/FilterMobile';
import { searchListings } from '../../services/ListingManagementService';
import { States } from '../../helperData/LocationData';

const mobile = {
  textAlign:"center",
  '@media(min-Width: 1024px)' : {
      display: "none"
  }
}

const desktop = {
  textAlign:"center",
  width:"100%",
  '@media(max-Width: 1023px)' : {
      display: "none"
  }
}

const Filter = (props) => {
  const [locations, setLocations] = useState();

  const getLocations = async() => {
    const locationResponse = await searchListings({"size": 0,"aggs": {"locations": {"terms": {"field": "location.keyword","size": 50000}}}});
    const locationAgg = [];
    for(let x = 0; x < locationResponse.aggregations.locations.buckets.length; x++){
        const currentLoc = locationResponse.aggregations.locations.buckets[x].key.split(',');
        locationAgg.push(currentLoc[1] + ',' + currentLoc[0]);
    }
    const locationAggSet = [...new Set(locationAgg)];
    locationAggSet.sort();
    setLocations(States.concat(locationAggSet));
  }

  useEffect(() => {
      if(!locations){
          getLocations();
      }
  },[locations])

  return (
    <>
      <Grid item sx={mobile}> 
        <FilterMobile filterMap={props.filterMap} setFilterMap={props.setFilterMap} getFilteredListings={props.getFilteredListings} numberOfListings={props.numberOfListings} locations={locations}/>
      </Grid>
      <Grid item sx={desktop}>
        <FilterDesktop filterMap={props.filterMap} setFilterMap={props.setFilterMap} getFilteredListings={props.getFilteredListings} numberOfListings={props.numberOfListings} locations={locations}/>
      </Grid>
    </>
  );
};
export default Filter;
