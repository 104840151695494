import { React } from 'react';
import { Grid } from '@mui/material';
import Listing from '../../components/Listing/Listing';
import Footer from '../../components/Footer/Footer';
import Button from '@mui/material/Button';
import { HashLink } from 'react-router-hash-link';

const UserPostedListings = (props) => {

    return(
      <>
        <Grid container direction="row" justifyContent="center" marginTop="8px">
          {
            <Grid item sx={{textAlign:"center"}}>
                <HashLink to="/sell-a-business">
                    <Button variant="contained" sx={{backgroundColor: "#0e9384", mt:"8px", mb:"8px", fontWeight:"bold",borderRadius:"10px"}}>Create a new listing</Button>
                </HashLink>
            </Grid>
          }
          { 
           props.userPostedListings && props.userFavorites &&
            props.userPostedListings.map((listing) => {
              return(
                <Listing userAttributes={props.userAttributes} listing={listing} key={listing['listing-id']} indexOfFavorite={props.userFavorites.indexOf(listing['listing-id'])} setUserFavorites={props.setUserFavorites} setErrorMessage={props.setErrorMessage} getUserListings={props.getUserListings}/>
              )
            })
          }
        </Grid>
        <Footer />
      </>
    );
}

export default UserPostedListings;