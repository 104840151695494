import { React } from 'react';
import Button from '@mui/material/Button';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { Grid } from '@mui/material';

const ProfilePicture = (props) => {

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      }).catch(error => {
        // Code to handle the error
        console.error('An error occurred during ProfilePicture myOneDeal:', error);
    });  ;

    const handleFile = async(event) => {
        const file = event.target.files[0];
        const base64 = await toBase64(file);
        props.setNewAvatar({"image-title":file.name, "image-content": base64, "image-type": file.type});
    }

    return (
    <Grid container direction="row" alignItems="center" justifyContent="center">
        <Grid item xs={11} sm={6.5} sx={{marginBottom:"10px"}}>
            <Button variant="contained" component="label" sx={{backgroundColor:"#0e9384", fontWeight:"bold"}}>
                Change Profile Picture
                <input hidden accept="image/*" type="file" onChange={handleFile} id="image"/>
                <PhotoCamera sx={{pl:"10px"}}/>
            </Button>
        </Grid>
    </Grid>
    );
}
export default ProfilePicture;