export const Industries = [
    "Agriculture",
    "Automotive & Boat",
    "Beauty & Personal Care",
    "Building & Construction",
    "Communication & Media",
    "Education & Children",
    "Entertainment & Recreation",
    "Financial Services",
    "Health Care & Fitness",
    "Manufacturing",
    // "Non-Classifiable Establishments",
    "Online & Technology",
    "Pet Services",
    "Restaurants & Food",
    "Retail",
    "Service Businesses",
    "Transportation & Storage",
    "Travel",
    "Wholesale & Distributors",
];

export const ListingType = [
    "Established Business",
    "Asset Sale",
    "Franchise",
    "Real Estate",
];