import { React } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { HashLink } from 'react-router-hash-link';
import { modifySavedSearches } from '../../../services/UserManagementService';

export default function SavedSearchCard(props) {
  const navigate = useNavigate();

  const handleModifySavedSearches = (removeSavedSearchPayload) => {
    modifySavedSearches(removeSavedSearchPayload).then((response) => {
        if(response.errorMessage){
            props.setErrorMessage(response.errorMessage);
        }else{
            props.setUserSavedSearches(response.Attributes['saved-search']);
        }
      });
  };

  const handleApplySavedSearch = () => {
    localStorage.setItem("filterSettings", JSON.stringify(props.data));
    navigate('/businesses-for-sale');
  }

  return (
    <Grid item xs={11} sm={6} md={3} sx={{display:"flex", justifyContent:'center', padding:"10px"}}>
      
        <Card sx={{width:"100%"}}>
            <CardContent sx={{textAlign:"center"}}>
                <Button onClick={() => handleApplySavedSearch()} size="small">{props.data.saved_search_name}</Button>
            </CardContent>
            <CardActions sx={{display:"flex", justifyContent:'center'}}>
                <HashLink to='/saved-business-search' state={{data : props.data, searchKey : props.searchKey}}>
                  <IconButton sx={{color:"black", fontSize:"16px"}}>
                    Edit<EditIcon sx={{paddingLeft:"5px"}}/>
                  </IconButton>
                </HashLink>
                <IconButton sx={{color:"black", fontSize:"16px"}} onClick={() => {handleModifySavedSearches({searchId: props.searchKey, searchBody: null})}}>
                  Delete <DeleteIcon sx={{paddingLeft:"5px"}}/>
                </IconButton>
            </CardActions>
        </Card>
    </Grid>
  );
}