import { React, useState, useEffect} from 'react';
import { Grid, Box } from '@mui/material';
import NavBar from '../../components/NavBar/NavBar'
import { useNavigate, useLocation } from 'react-router-dom';
import { retrieveUserAttributes, retrieveUserProfile } from '../../services/UserManagementService';
import { retrieveUserListings, searchListings } from '../../services/ListingManagementService';
import { fetchFavoritesFromOpenSearch } from './HelperFunctions';
import { SnackBar } from '../../components/SnackBar/SnackBar'
import TabManager from './TabManager';
function MyOneDeal() {
  // state management
  const navigate = useNavigate();
  const [userAttributes, setUserAttributes] = useState();
  const [userProfileData, setUserProfileData] = useState();
  const [userFavorites, setUserFavorites] = useState(false);
  const [userSavedSearches, setUserSavedSearches] = useState(false);
  const [userPostedListings, setPostedUserListings] = useState();
  const [userInfoRequests, setUserInfoRequests] = useState();
  // const [userInfoRequestsListings, setUserInfoRequestsListings] = useState();
  const [buyerDeals, setBuyerDeals] = useState();
  const [prequalSettings, setPrequalSettings] = useState();
  const [userFavoritedListings, setUserFavoritedListings] = useState();
  const [drRequestsReceived, setDrRequestsReceived] = useState();
  const [drRequestsSent,setDrRequestsSent] = useState();
  const [drRequestsSentListings, setDrRequestsSentListings] = useState();
  const [isBroker, setIsBroker] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const getUserListings = async() => {
    // setIsUserPostedLoading(true);
    retrieveUserListings().then((response) => {
      if(!response.Items){
        setErrorMessage(response);
      }else{
        setPostedUserListings(response.Items);
      }
      // setIsUserPostedLoading(false);
    })
  }

  const getUserAttributes = async() => {
    retrieveUserAttributes().then((data) => {
      if(data.errorMessage){
        setErrorMessage(data.errorMessage);
      }else{
        setUserAttributes(data.result)
      }
    });
  }

  const getUserFavorites = async() => {
    retrieveUserProfile().then((response) => {
      if(response.errorMessage){
        setErrorMessage(response.errorMessage);
      }else{
        setUserFavorites(response.favorites);
        setUserSavedSearches(response['saved-search']);
        setIsBroker(response['is-broker']);
        setUserProfileData(response);
        setPrequalSettings(response.prequalification);
        setUserInfoRequests(response['info-requests'] ? response['info-requests'] : {})
        setDrRequestsReceived(response['dr-requests-received'] ? response['dr-requests-received'] : {})
        setDrRequestsSent(response['dr-requests-sent'] ? response['dr-requests-sent'] : {})
      }
    });
  }

  const getSpecificListings = async(listingIds, stateSetter) => {
    const results = await searchListings(fetchFavoritesFromOpenSearch(listingIds));
    
    if(!results.hits){
      setErrorMessage(results);
    }else{
      stateSetter(results.hits.hits);
    }
  }

  const location = useLocation();
  useEffect(() => {
    if (!window.location.host.includes('localhost') && !window.location.host.includes('dev.onedeal.biz')) {
      window.gtag("event", "page_view", {
        page_path: location.pathname + location.search,
      });
    }
    if(!localStorage.getItem("authorized")){
      navigate('/login');
    }else if(localStorage.getItem("authorized")){
      if(!userAttributes){
        getUserAttributes();
      }

      if(!userPostedListings){
        getUserListings();
      }

      if(!userFavorites && userAttributes){
        getUserFavorites();
      }

      if((userFavorites && !userFavoritedListings) || (userFavoritedListings && userFavorites.length !== userFavoritedListings.length)){
        getSpecificListings(userFavorites, setUserFavoritedListings);
      }

      if(userInfoRequests && drRequestsSent && !buyerDeals){
        const deals = new Set(Object.keys(userInfoRequests).concat(Object.keys(drRequestsSent)));
        getSpecificListings(Array.from(deals), setBuyerDeals);
      } 

      if(drRequestsSent && !drRequestsSentListings){
        getSpecificListings(Object.keys(drRequestsSent), setDrRequestsSentListings);
      } 
    } 
  },[location, userAttributes, userFavorites, userFavoritedListings, userPostedListings, userSavedSearches, userProfileData, prequalSettings, buyerDeals]);

  return (
    <Box sx={{position:"relative", height:"100%"}}>
      <NavBar userAttributes={userAttributes} setUserAttributes={setUserAttributes} userProfileData={userProfileData}/>
      <Grid container direction="row" justifyContent="center" alignItems="center" >
        <Grid item xs={12} sm={11}>
          <TabManager 
            userAttributes={userAttributes}
            userFavorites={userFavorites}
            userFavoritedListings={userFavoritedListings}
            setUserFavorites={setUserFavorites}
            userPostedListings={userPostedListings}
            userSavedSearches={userSavedSearches}
            setUserSavedSearches={setUserSavedSearches}
            isBroker={isBroker}
            setErrorMessage={setErrorMessage}
            getUserListings={getUserListings}
            prequalSettings={prequalSettings}
            // userInfoRequestsListings={userInfoRequestsListings}
            userInfoRequests={userInfoRequests}
            drRequestsReceived={drRequestsReceived}
            setDrRequestsReceived={setDrRequestsReceived}
            userProfileData={userProfileData}
            drRequestsSent={drRequestsSent}
            buyerDeals={buyerDeals}
            // setDrRequestsSent={setDrRequestsSent}
            // drRequestsSentListings={drRequestsSentListings}
          />
        </Grid>
      </Grid>
      {errorMessage && <SnackBar errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>}
    </Box>
  );
}

export default MyOneDeal;
