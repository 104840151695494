import { React, useState } from 'react';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';

const DataRoomUploader = (props) => {
  const [dragging, setDragging] = useState(false);

  const handleDragEnter = e => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDragOver = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    const newFiles = [...props.dataRoomFiles];
    for (let i = 0; i < e.dataTransfer.files.length; i++) {
      newFiles.push(e.dataTransfer.files[i]);
    }
    props.setDataRoomFiles(newFiles);
  };

  const handleFileUpload = e => {
    if (!window.location.host.includes('localhost') && !window.location.host.includes('dev.onedeal.biz')) {
      window.gtag("event", "button_click", {
        button: "upload_dr_doc"
      });
    }
    const newFiles = [...props.dataRoomFiles];
    for (let i = 0; i < e.target.files.length; i++) {
      newFiles.push(e.target.files[i]);
    }
    props.setDataRoomFiles(newFiles);
  };

  const handleRemoveFile = index => {
    if (!window.location.host.includes('localhost') && !window.location.host.includes('dev.onedeal.biz')) {
      window.gtag("event", "button_click", {
        button: "remove_dr_doc"
      });
    }
    const newFiles = [...props.dataRoomFiles];
    newFiles.splice(index, 1);
    props.setDataRoomFiles(newFiles);
  };

  return (
    <Grid container direction="row" justifyContent="center">
      <Grid item >
        <h4 style={{textAlign:"center"}}>Dataroom Documents</h4>
        <p>Brokers, enhance your listing's credibility and facilitate informed decisions for potential buyers. Please upload all relevant documents, such as financial statements, tax records, contracts, leases, and inventory lists, to aid in due diligence. Rest assured, uploaded documents are securely stored for verified buyers. Expediting due diligence increases successful transactions. </p>
          <div
          className={`drag-and-drop${dragging ? ' active' : ''}`}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          style={{
              textAlign:"center",
              borderRadius:"10px",
              // borderStyle:"dotted",
              // borderColor: "#8DCAC3"
              padding:"10px",
              outline: "4px dashed #8DCAC3"
          }}
          >
              <input
                  type="file"
                  multiple
                  onChange={handleFileUpload}
                  className="hidden-input"
                  style={{display:"None"}}
              />
              <Button className="upload-button" variant="contained" component="label" sx={{backgroundColor:"#0e9384", fontWeight:"bold"}} onClick={() => document.querySelector('.hidden-input').click()}>
                  Select Files
              </Button>
              <p style={{paddingTop:"10px"}}>Drag and drop files here, or click to select files</p>
              {
                props.dataRoomFiles &&
                <div className="file-list">
                  {props.dataRoomFiles.map((file, index) => (
                  <div key={file.name} className="file-item">
                      <p>{file.name}</p>
                      <Button variant="contained" component="label" sx={{backgroundColor:"#0e9384", fontWeight:"bold", marginBottom:"10px"}} onClick={() => handleRemoveFile(index)}>
                          Remove
                      </Button>
                  </div>
                  ))}
              </div>}
          </div>
      </Grid>
    </Grid>
    
  );
};

export default DataRoomUploader;