import { Grid } from "@mui/material";
import React from "react";

const Step = ({ data, position }) => {
  return (
    <div style={{ maxWidth: "77%", padding: "0", margin: "2rem" }}>
      {position === true ? (
        <Grid container spacing={10} style={{ alignItems: "center" }}>
          <Grid
            item
            xs={10}
            sm={6}
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            <Grid
              container
              rowSpacing={2}
              gap={2}
              style={{ marginBottom: "1.5rem" }}
            >
              <p
                style={{
                  backgroundColor: "rgb(14, 147, 132)",
                  borderRadius: "50%",
                  width: "2rem",
                  height: "2rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                {data.number}
              </p>
              <h5
                style={{
                  color: "#0e9384",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {data.h4}
              </h5>
            </Grid>
            <h4 style={{ whiteSpace: "pre-wrap", justifyContent: "start" }}>
              {data.para}
            </h4>
          </Grid>
          <Grid
            item
            xs={10}
            sm={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <img
              src={data.img}
              style={{ width: "75%", height: "auto" }}
              alt=""
            ></img>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={10} style={{ alignItems: "center" }}>
          <Grid
            item
            xs={10}
            sm={6}
            style={{
              alignItems: "center",
              justifyContent: "start",
              display: "flex",
            }}
          >
            <img
              src={data.img}
              style={{
                width: "75%",
                height: "auto",
              }}
              alt=""
            ></img>
          </Grid>
          <Grid item xs={10} sm={6}>
            <Grid
              container
              rowSpacing={2}
              gap={2}
              style={{ marginBottom: "1.5rem" }}
            >
              <p
                style={{
                  backgroundColor: "rgb(14, 147, 132)",
                  borderRadius: "50%",
                  width: "2rem",
                  height: "2rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                {data.number}
              </p>
              <h5
                style={{
                  color: "#0e9384",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {data.h4}
              </h5>
            </Grid>
            <h4 style={{ whiteSpace: "pre-wrap" }}>{data.para}</h4>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Step;
