import {Grid, Avatar} from '@mui/material';
import { Link } from "react-router-dom";
import './BrokerCard.css'

const BrokerCard = (brokerProperties) => {

    const GetAvatar = (brokerName) => {

        let initials = "";
        let splitNameArray = brokerName.split(" ");
        if(splitNameArray.length > 1){
          initials = splitNameArray[0][0] + splitNameArray[splitNameArray.length - 1][0];
        }else{
          initials = splitNameArray[0][0];
        }
       
        return (
    
          <>
            {/* 1. if user has google pic, display that */}
            {/* {
              brokerProperties.userAttributes.picture && <Avatar src={brokerProperties.userAttributes.picture} imgProps={{referrerPolicy:"no-referrer"}}/>
            } */}
            
            {/* 2. else if, user has brokerProperties.avatar display that */}
            {
              brokerProperties.avatar && <Avatar src={`${process.env.REACT_APP_S3_AVATARS_URL}${brokerProperties.avatar}`} imgProps={{referrerPolicy:"no-referrer"}}/>
            }
            
            {/* 3. else display initials */}
            {!brokerProperties.avatar && <Avatar sx={{ bgcolor: "#0e9384"}} src="">{initials}</Avatar>}
            
          </>
        );
      }

    return (
            <div className="pricing-card">
                <div>
                    <div style={{display:"flex", justifyContent:"center", marginBottom:"20px"}}>{GetAvatar(brokerProperties.name)}</div>
                    <Link to={{ pathname: '/broker-profile', search: `?email=${brokerProperties.email}`}} className='listed-by-link' sx={{padding:"0px"}}><h6 className="name">{brokerProperties.name}</h6></Link>
                    <Grid container direction="row" display="flex" justifyContent="center" alignItems="center">
                        <Grid item xs={9.5} sx={{marginBottom:"10px"}}>
                            <span style={{float:"left", fontWeight:"bold"}}>Email: </span>
                            <span style={{float:"right"}}>{brokerProperties.email}</span>
                        </Grid>
                        { 
                            brokerProperties.phone && 
                            <Grid item xs={9.5} sx={{marginBottom:"10px"}}>
                                <span style={{float:"left", fontWeight:"bold"}}>Phone: </span>
                                <span style={{float:"right"}}>{brokerProperties.phone}</span>
                            </Grid>
                        }
                         { 
                            brokerProperties.extension && 
                            <Grid item xs={9.5} sx={{marginBottom:"10px"}}>
                                <span style={{float:"left", fontWeight:"bold"}}>Extension: </span>
                                <span style={{float:"right"}}>{brokerProperties.extension}</span>
                            </Grid>
                        }
                    </Grid>
                </div>
                <div className="btn-area">
                    <a href={`mailto:${brokerProperties.email}`} target="_blank" rel="noreferrer">
                        <button className="cmn-btn w-100">Contact</button>
                    </a>
                </div>
            </div>
    );
};

export default BrokerCard;