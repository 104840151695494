import { React, useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FilledInput from '@mui/material/FilledInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import LoadingButton from '@mui/lab/LoadingButton';
import { SnackBar } from '../../components/SnackBar/SnackBar';
import { authenticate } from '../../services/UserManagementService';
import { GoogleLoginButton } from "react-social-login-buttons";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './Login.css'
import Footer from '../../components/Footer/Footer';
import SetPageTitle from '../../components/SetPageTitle/SetPageTitle';
const LoginFormField = {
    margin: "auto"
}

export const Login = () => {

    // state management
    const [errorMessage, setErrorMessage] = useState();
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [loginDetails, setLoginDetails] = useState({
        "email" : "",
        "password" : "",
    });

    const navigate = useNavigate();

    // update registration details state
    const handleFieldUpdate = (event) => {
        setLoginDetails({...loginDetails, [event.target.id] : event.target.value});
    }

    // reveal password
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = (event) => { event.preventDefault(); };

    // attempt to register user
    const handleSubmit = async(registrationProps) => {
        authenticate(
            setLoading,
            registrationProps,
            setErrorMessage,
            navigate
        )
    }

    const handleGoogleLogin = () => {
        window.location.href=`${process.env.REACT_APP_COGNITO_HOSTED_UI}oauth2/authorize?identity_provider=Google&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=CODE&client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&scope=aws.cognito.signin.user.admin profile openid email`;
    }

    const location = useLocation();

    useEffect(() => {
        if (!window.location.host.includes('localhost') && !window.location.host.includes('dev.onedeal.biz')) {
            window.gtag("event", "page_view", {
              page_path: location.pathname + location.search,
            });
          }
    }, [location])

    return(
        <Box sx={{height:"100%"}}>
            <SetPageTitle title="Login" />
            <Grid container direction="row" justifyContent="center" alignItems="start" position="relative" sx={{backgroundColor:"#ffffff", height:"100%"}}>
                <Grid item xs={11} sm={6}>
                    <Grid item xs={11} md={6} sx={LoginFormField}>
                        <h3>Welcome Back!</h3>
                        <p>Please enter your details</p>
                        <TextField
                                required
                                id="email"
                                label="Email Address"
                                variant="filled"
                                autoComplete="username"
                                onChange={handleFieldUpdate}
                                style={{width:"100%"}}
                        />
                        <FormControl sx={{ marginTop: "8px", marginBottom: "8px", width: '100%' }} variant="filled">
                            <InputLabel htmlFor="filled-adornment-password">Password</InputLabel>
                            <FilledInput
                                id="password"
                                autoComplete='new-password'
                                type={showPassword ? 'text' : 'password'}
                                value={loginDetails.password}
                                onChange={handleFieldUpdate}
                                endAdornment={
                                    <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                    </InputAdornment>
                                }  
                            />
                        </FormControl>
                        <Link to="/forgotpassword" id="forgotPassword">Forgot your password?</Link>
                        <LoadingButton
                                onClick={() => handleSubmit(loginDetails)}
                                loading={loading}
                                // loadingPosition="end"
                                variant="contained"
                                style={{width:"100%", marginTop:"8px", marginBottom: "8px", backgroundColor: "#0e9384"}}
                            >
                            Login
                        </LoadingButton>
                        <div id="outerGoogleDiv" style={{marginBottom:"8px"}}>
                            <GoogleLoginButton style={{width:"100%", height:"36px", margin:"0px"}} onClick={handleGoogleLogin}>Login with Google</GoogleLoginButton>
                        </div>
                        <Link to="/signup" id="signUp">Don't have an account? Sign up for free!</Link>
                    </Grid>
                </Grid>
            </Grid>
            {errorMessage && <SnackBar errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>}
            <Footer />
        </Box>
    );
};