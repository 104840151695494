import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import "./DealScoutLandingPage.css";
import avatar1 from "../../assets/Landing/avatar1.png";
import avatar2 from "../../assets/Landing/avatar2.png";
import avatar3 from "../../assets/Landing/avatar3.png";

const Carousel = (props) => {
  const cards = [
    {
      name: "Sophia W.",
      role: "Self funded Buyer",
      img: avatar1,
      testimonial:
        '"In the past, locating proprietary deals in the lower middle market was a challenge, but this platform has made it a breeze. It consistently allows me to find businesses within my investment criteria, and the owner’s contact information so I can contact with owners on a human level."',
    },
    {
      name: "John D.,",
      role: " Searchfunder",
      img: avatar2,

      testimonial:
        '"DealScout has been a game-changer for Searchfunders. It is not just a platform; it is a strategic tool that has fundamentally changed the way we run our search process."',
    },
    {
      name: "David F.,",
      role: "Private Investor",
      img: avatar3,

      testimonial:
        '"DealScout has effectively resolved the capital limitations and access to deal flow challenges for self-funded buyers in the lower middle market. It is our gateway to a world of investment opportunities that were once out of reach."',
    },

    // Add more cards here
  ];
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "1.5rem",
          transform: `translateX(-${props.currentCardIndex * 22}rem)`, // 根据当前索引设置卡片位置
          transition: "transform 0.5s ease",
        }}
      >
        {cards.map((card, index) => (
          <div
            key={index}
            className={`carousel-card ${
              index === props.currentCardIndex ? "activeCard" : ""
            }`}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1.5rem",
                alignItems: "flex-start",
              }}
            >
              <Avatar src={card.img} sx={{ width: 64, height: 64 }} />

              <div style={{ display: "flex", flexDirection: "column" }}>
                <h4
                  className={`${
                    index === props.currentCardIndex ? "activeCardText" : ""
                  }`}
                >
                  {card.name}
                </h4>
                <h5
                  className={`${
                    index === props.currentCardIndex ? "activeCardText" : ""
                  }`}
                >
                  {card.role}
                </h5>
              </div>
            </div>
            <p
              style={{ fontStyle: "italic" }}
              className={`${
                index === props.currentCardIndex ? "activeCardText" : ""
              }`}
            >
              {card.testimonial}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;

{
  /* <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "1.5rem",
        }}
      >
        <div
          className={`carousel-card ${
            0 === props.currentCardIndex ? "activeCard" : ""
          }`}
        >
          <div style={{ display: "flex", flexDirection: "row", gap: "1.5rem" }}>
            <Avatar sx={{ width: 64, height: 64 }} />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h4>Sophia W.</h4>
              <h5> Self funded Buyer </h5>
            </div>
          </div>
          <p>
            "In the past, locating proprietary deals in the lower middle market
            was a challenge, but this platform has made it a breeze. It
            consistently allows me to find businesses within my investment
            criteria, and the owner’s contact information so I can contact with
            owners on a human level.”
          </p>
        </div>
        <div
          className={`carousel-card ${
            1 === props.currentCardIndex ? "activeCard" : ""
          }`}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "1.5rem",
              alignItems: "flex-start",
            }}
          >
            <Avatar sx={{ width: 64, height: 64 }} />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h4>Sophia W.</h4>
              <h5> Self funded Buyer </h5>
            </div>
          </div>
          <p>
            "In the past, locating proprietary deals in the lower middle market
            was a challenge, but this platform has made it a breeze. It
            consistently allows me to find businesses within my investment
            criteria, and the owner’s contact information so I can contact with
            owners on a human level.”
          </p>
        </div>
        <div
          style={{ marginRight: "15rem" }}
          className={`carousel-card ${
            2 === props.currentCardIndex ? "activeCard" : ""
          }`}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "1.5rem",
              alignItems: "flex-start",
            }}
          >
            <Avatar sx={{ width: 64, height: 64 }} />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h4>Sophia W.</h4>
              <h5> Self funded Buyer </h5>
            </div>
          </div>
          <p>
            "In the past, locating proprietary deals in the lower middle market
            was a challenge, but this platform has made it a breeze. It
            consistently allows me to find businesses within my investment
            criteria, and the owner’s contact information so I can contact with
            owners on a human level.”
          </p>
        </div>
      </div>*/
}
