// import React from "react";
// import { HashLink } from "react-router-hash-link";
// import {
//   ArrowRight,
//   BuyBusinessIcon,
//   FinancingIcon,
//   SellBusinessIcon,
// } from "../../components/IconTheme/IconTheme";
// import SectionTitle from "../../components/SectionTitle/SectionTitle";

// const data = [
//   {
//     icon: <SellBusinessIcon />,
//     title: "Sell a business",
//     text: "Connect with qualified buyers and let us help you navigate a successful sale.",
//     linkText: "Sell a business",
//     link: "/sell-a-business",
//   },
//   {
//     icon: <BuyBusinessIcon />,
//     title: "Buy a business",
//     text: "Browse high quality businesses listings with our advanced suite of tools.",
//     linkText: "Browse Listings",
//     link: "/businesses-for-sale",
//   },
//   {
//     icon: <FinancingIcon />,
//     title: "Financing",
//     text: "Seamlessly finance your transaction from one of our many loan partners.",
//     linkText: "Learn more",
//     link: "/DealScoutLandingPage",
//   },
// ];

// const WhyOneDeal = () => {
//   return (
//     <section className="why-section section-gap">
//       <div className="container">
//         <SectionTitle
//           subtitle="Why OneDeal?"
//           title="The most efficient way to buy and sell a small business"
//           text="Our cutting-edge features make it easy to browse and search for businesses, create detailed listings, and connect with potential buyers and sellers."
//           style={{ maxWidth: "932px" }}
//         />
//         <div className="row g-4 justify-content-center">
//           {data?.map((item, i) => (
//             <div className="col-md-4 col-sm-6" key={i}>
//               <WhyCard {...item} />
//             </div>
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// };

// export const WhyCard = ({ icon, title, text, link, linkText }) => (
//   <div className="why-card-1">
//     <div className="icon">{icon}</div>
//     {title && <h6>{title}</h6>}
//     {text && <p>{text}</p>}
//     <HashLink to={link + "#"}>
//       {linkText} <ArrowRight />
//     </HashLink>
//   </div>
// );

// export default WhyOneDeal;

import { Box, Stack } from "@mui/material";
import React from "react";
import why1 from "../../assets/Landing/why1.webp";
import why2 from "../../assets/Landing/why2.webp";
import why3 from "../../assets/Landing/why3.webp";

const WhyData = [
  {
    img: why1,
    h4: "Over 17.5M Off Market Businesses",
    p: "Every searcher has a different thesis. Find the businesses with the right location, industry, employee count, estimated revenue, and contact info",
  },
  {
    img: why2,
    h4: "Over 80K actively listed brokered deals ",
    p: "Aggregated from every marketplace and broker website on the internet",
  },
  {
    img: why3,
    h4: "Direct listed  Deals",
    p: "All of the directly listed deals on our marketplace include a data room with relevant documents including NDA, CIM, and financials. Make a decision withing minutes not weeks if the business is worth pursuing. ",
  },
];
const WhyOneDeal = (data) => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          justifyContent: "center",
          flexDirection: "column",
          margin: "6rem 0",
          gap: "4rem",
        }}
      >
        <Stack spacing={2}>
          <h3>Why Use OneDeal</h3>
          <p>
            Successful searchers use a combination of brokered deals and
            proprietary search. Why not use a tool that does both!{" "}
          </p>
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          alignItems="start"
          justifyContent="center"
        >
          {WhyData.map((item, index) => {
            return (
              <Box style={{ maxWidth: "23%", textAlign: "start" }}>
                <img
                  style={{
                    maxWidth: "80%",
                    height: "auto",
                  }}
                  src={item.img}
                  alt=""
                ></img>
                <h4 style={{ margin: "3rem 0 1.5rem 0" }}>{item.h4}</h4>
                <p style={{ maxWidth: "80%" }}> {item.p}</p>
              </Box>
            );
          })}
        </Stack>
      </div>
    </div>
  );
};

export default WhyOneDeal;
