import { React, useState, useEffect } from "react";
import { Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import {
  upsertDealScoutCampaign,
  upsertDealScoutList,
} from "../../../services/DealScout";
import ListSelect from "./ListSelect";

const Campaigns = (props) => {
  //state management
  const [displayPopup, setDisplayPopup] = useState(false);

  const [displayNewCampaign, setDisplayNewCampaign] = useState(false);
  const [selectCampaignDropDown, setSelectCampaignDropDown] = useState("");
  const [currentCampaign, setCurrentCampaign] = useState({
    lists: {},
  });
  const [noCampaignName, setNoCampaignName] = useState(false);
  //hanlder functions
  const handleCampaignSelect = (event) => {
    setSelectCampaignDropDown(event.target.value);
    if (event.target.value in props.usersDealScouts.scoutData.campaigns) {
      setCurrentCampaign(
        props.usersDealScouts.scoutData.campaigns[event.target.value]
      );
    }
  };

  const handleCampaignName = (event) => {
    // setCurrentCampaign({...currentCampaign, [event.target.id] : event.target.value});
    setCurrentCampaign({ name: event.target.value, lists: {} });
  };

  const handleAddRemoveFromCampaign = async (listKey) => {
    if (!currentCampaign.name) {
      setNoCampaignName(true);
    } else {
      let updatedLists = currentCampaign.lists;
      if (listKey in updatedLists) {
        delete updatedLists[listKey];
      } else {
        updatedLists[listKey] = props.usersDealScouts.scoutData.lists[listKey];
      }

      setCurrentCampaign({ ...currentCampaign, lists: updatedLists });
      await upsertDealScoutCampaign({
        campaignId: selectCampaignDropDown,
        campaignBody: currentCampaign,
      });
    }

    if (!currentCampaign.name) {
      setDisplayPopup(true);
    } else {
      setDisplayPopup(false);
    }
  };

  const handleRemoveFromList = async (listId, bizId) => {
    let updatedLists = props.usersDealScouts.scoutData.lists;
    if (bizId in updatedLists[listId].businesses) {
      delete updatedLists[listId].businesses[bizId];
    }
    let updatedScoutData = props.usersDealScouts;
    updatedScoutData.scoutData.lists = updatedLists;
    props.setUsersDealScouts(updatedScoutData);
    await upsertDealScoutList({
      listId: listId,
      listBody: updatedLists[listId],
    });
  };

  useEffect(() => {}, [currentCampaign]);

  return (
    <div>
      {" "}
      {displayPopup && (
        <div className="popup-overlay">
          <div className="popup">
            <h3>Please select or create a list to start.</h3>
            <button
              variant="contained"
              className="listingDetails-btn"
              onClick={() => setDisplayPopup(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={10}>
          <h1 style={{ color: "black", marginTop: "3rem" }}>Campaigns</h1>
        </Grid>
        {!displayNewCampaign && (
          <Grid
            item
            xs={5}
            sx={{ backgroundColor: "white", borderRadius: "10px" }}
          >
            <FormControl sx={{ m: 1, minWidth: "100%" }}>
              <InputLabel id="demo-simple-select-helper-label">
                Select A Saved Campaign Or Create A New One
              </InputLabel>
              <Select
                //   labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={selectCampaignDropDown}
                label="Select A Saved Campaign Or Create A New One"
                onChange={handleCampaignSelect}
                error={noCampaignName}
                sx={{ borderRadius: "24px" }}
              >
                <MenuItem value="" onClick={() => setDisplayNewCampaign(true)}>
                  Create New Campaign
                </MenuItem>
                {props.usersDealScouts &&
                  "scoutData" in props.usersDealScouts &&
                  Object.keys(props.usersDealScouts.scoutData.campaigns).map(
                    (key) => {
                      return (
                        <MenuItem value={key}>
                          {props.usersDealScouts.scoutData.campaigns[key].name}
                        </MenuItem>
                      );
                    }
                  )}
              </Select>
            </FormControl>
          </Grid>
        )}
        {displayNewCampaign && (
          <>
            <Grid item xs={5}>
              <TextField
                id="name"
                label="Enter Your New Campaign Name Here!"
                variant="outlined"
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": { borderRadius: "24px" },
                }}
                onChange={handleCampaignName}
                // onKeyDown={(e) => {if(e.code === 'NumpadEnter' || e.code === 'Enter'){props.getFilteredListings()}}}
                value={currentCampaign.name}
              />
            </Grid>
          </>
        )}
        <Grid item xs={5}>
          <button
            onClick={async () =>
              await upsertDealScoutCampaign({
                campaignId: selectCampaignDropDown,
                campaignBody: currentCampaign,
              }).then((response) => {
                setDisplayNewCampaign(false);
                setSelectCampaignDropDown(response["updated-campaign-id"]);
                setCurrentCampaign(
                  response.Attributes.campaigns[response["updated-campaign-id"]]
                );
                props.setUsersDealScouts({
                  scoutData: response.Attributes,
                  userExists: true,
                });
              })
            }
            variant="contained"
            className="listingDetails-btn"
            style={{ width: "100%" }}
          >
            Save This Campaign
          </button>
        </Grid>
        <Grid item xs={10}>
          <h2 style={{ color: "black" }}>Saved Lists</h2>
        </Grid>
        {props.usersDealScouts !== undefined &&
          Object.keys(props.usersDealScouts.scoutData.lists).map((listKey) => {
            let existsInCampaign = listKey in currentCampaign.lists;
            return (
              <ListSelect
                existsInCampaign={existsInCampaign}
                usersDealScouts={props.usersDealScouts}
                listKey={listKey}
                handleAddRemoveFromCampaign={handleAddRemoveFromCampaign}
                handleRemoveFromList={handleRemoveFromList}
                name={props.name}
                setDrawComponent={props.setDrawComponent}
                setListControlledByCampaign={props.setListControlledByCampaign}
              />
            );
          })}
      </Grid>
    </div>
  );
};
export default Campaigns;
