import Modal from '@mui/material/Modal';
import { Box, Grid, Button } from '@mui/material';
import { HashLink } from 'react-router-hash-link';
import pattern from '../../assets/Landing/pattern-1.svg'

const requireLoginStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: '75%',
    backgroundColor: '#ffffff',
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };

const SignUpType = (props) => {
    const handleClose = () => props.setSignUpModal(false);
    return (
      <div>
        <Modal
          open={props.signUpModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          >
          <Box sx={requireLoginStyle}>
              <section className="banner-section">
              {/* <img src={pattern} alt="" className="pattern" /> */}
              <div className="container">
                  <div className="banner-content">
                      <div className="content">
                          <h4 style={{textAlign:"center", marginBottom:"10px", color:"#0e9384"}}>
                            Are you signing up as an
                          </h4>
                      </div>
                      <div style={{textAlign:"center"}}>
                      <HashLink to="/signup?isBroker=false">
                          <Button variant="contained" sx={{
                              backgroundColor: "#0e9384", 
                              color: "#ffffff", 
                              mt:"8px", mb:"8px", 
                              fontWeight:"bold",
                              borderRadius:"24px",
                              ':hover': {
                                  bgcolor: '#13548b', // theme.palette.primary.main
                                  color: '#ffffff',
                                },
                              }}>
                                  Buyer
                          </Button>
                      </HashLink>
                      <span style={{paddingLeft:"5px", paddingRight:"5px", color:"#0e9384"}}><b>OR</b></span>
                      <HashLink to="/signup?isBroker=true">
                          <Button variant="contained" sx={{
                              backgroundColor: "#0e9384", 
                              color: "#ffffff", 
                              mt:"8px", mb:"8px", 
                              fontWeight:"bold",
                              borderRadius:"24px",
                              ':hover': {
                                bgcolor: '#13548b', // theme.palette.primary.main
                                color: '#ffffff',
                                },
                              }}>
                                  Broker
                          </Button>
                      </HashLink>
                      </div>
                  </div>
              </div>
          </section>
          </Box>
          </Modal>
      </div>
    );
  }

  export default SignUpType;