import { React, useState, useEffect} from 'react';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { deleteFilesFromS3, deleteBrokerFilesFromS3, getS3Docs} from '../../services/UserManagementService';
import { putPrequalificationDocument, putBrokerDocument } from '../../services/UserManagementService';
import { Paid, ManageSearch} from '@mui/icons-material';
import PrequalQuestions from './Prequalification/Prequalification';

const DragAndDrop = (props) => {
  const [dragging, setDragging] = useState(false);
  const [files, setFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [fundsDocuments, setFundsDocuments] = useState();
  const [showStep, setShowStep] = useState("proof_of_funds");
  const [prequalSettings, setPrequalSettings] = useState((props.prequalSettings) ? props.prequalSettings : {
    funding: "self", //required
    lowest_down_payment: 0, //required,
    highest_down_payment: 3000000, //required,
    lowest_net_worth: 0,
    highest_net_worth: 30000000,
    experience : "N", //required
    experience_details: "",
    background: "",
    leadership: "",
    industries: [], //required
    investment_type: "", //required
    timeframe: "", //required
    financing : "",
    prequalified : "N",
    prequalified_details: "",
    "locations": [],
    lowest_investment_range: 0,
    highest_investment_range: 30000000,
    professional_support: "",
    additional_info: "",
});


  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  }).catch(error => {
    // Code to handle the error
    console.error('An error occurred during fileUploader myOneDeal:', error);
});  ;

  const handleUploadToS3 = async() => {
    setLoading(true);
    if (!window.location.host.includes('localhost') && !window.location.host.includes('dev.onedeal.biz')) {
      window.gtag("event", "button_click", {
        button: "nda_upload",
        user_email: props.email
      });
    }
    for(let x=0; x < files.length; x++){
        const base64 = await toBase64(files[x]);
        if(props.isBroker !== 'Y'){
          await putPrequalificationDocument(
            base64,
            files[x].type,
            files[x].name,
            "funds",
            setErrorMessage
          );
        }else{
          await putBrokerDocument(
            base64,
            files[x].type,
            files[x].name,
            "ndas",
            setErrorMessage
          );
        }
    }
    setLoading(false);
  }

  const getPreviouslyUploadedDocs = async() => {
    let results;
    if(props.isBroker !== 'Y'){
      results = await getS3Docs("onedeal-prequalification-documents", `${props.email}/funds/`);
    }else{
      results = await getS3Docs("onedeal-broker-documents",  `${props.email}/ndas/`);
    }
    setFundsDocuments(results);
  }

  const deleteUploadedDocs = async(title, folder) => {
    if (!window.location.host.includes('localhost') && !window.location.host.includes('dev.onedeal.biz')) {
      window.gtag("event", "button_click", {
        button: "nda_delete",
        user_email: props.email
      });
    }
    if(props.isBroker !== 'Y'){
      await deleteFilesFromS3(title, folder).then(async() => await getPreviouslyUploadedDocs());
    }else{
      await deleteBrokerFilesFromS3(title, folder).then(async() => await getPreviouslyUploadedDocs());
    }
    
  }

  const handleDragEnter = e => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDragOver = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    const newFiles = [...files];
    for (let i = 0; i < e.dataTransfer.files.length; i++) {
      newFiles.push(e.dataTransfer.files[i]);
    }
    setFiles(newFiles);
  };

  const handleFileUpload = e => {
    const newFiles = [...files];
    for (let i = 0; i < e.target.files.length; i++) {
      newFiles.push(e.target.files[i]);
    }
    setFiles(newFiles);
  };

  const handleRemoveFile = index => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  const pofStyle = {
    display:"flex",
    justifyContent:"center",
    mt:"10px",
    cursor:
    "pointer",
    "&:hover": {
      color: showStep === 'proof_of_funds' ? "#grey" : "#0e9384"
    },
    color: showStep === 'proof_of_funds' ? "#0e9384" : "grey"
  };

  const dealPreferences = {
    display:"flex",
    justifyContent:"center",
    mt:"10px",
    cursor:
    "pointer",
    "&:hover": {
      color: showStep === 'deal_preferences' ? "#grey" : "#0e9384"
    },
    color: showStep === 'deal_preferences' ? "#0e9384" : "grey"
  };

    useEffect(() => {
        if(!fundsDocuments){
            getPreviouslyUploadedDocs();
        } 
    },[fundsDocuments])

  return (
    <Grid container direction="row" justifyContent="center">
      {
        props.isBroker !== 'Y' &&
        <>
        <Grid item xs={12} sx={{display:"flex"}}>
          <Grid item xs={6} sx={pofStyle} onClick={() => setShowStep('proof_of_funds')}>
            <Paid sx={{mr:"10px"}}/><p>Proof of Funds</p>
          </Grid>

         { 
         <Grid item xs={6} sx={dealPreferences} onClick={() => setShowStep('deal_preferences')}>
            <ManageSearch sx={{mr:"10px"}}/><p>Questionnaire</p>
          </Grid>}
        </Grid>
        {
          showStep === "proof_of_funds" &&
          <Grid item xs={11}>
            <h4 style={{textAlign:"center"}}>Proof of Funds</h4>
            <p>Thank you for your interest in our services. To proceed with your request, we require proof of funds documentation. Please upload your latest bank statement showing your available balance and recent transactions. Your uploaded document will be kept confidential and securely stored. Please ensure that the document is clear and legible. Thank you for your cooperation.</p>
            {fundsDocuments?.map((fundDoc) => {
                let fundDocSplit = fundDoc.Key.split('/');
                return(
                    <div style={{display:"flex", justifyContent:"center", marginBottom:"10px", alignItems:'center'}}>
                        <p style={{marginBottom:"0px", paddingRight:"10px"}}>{fundDocSplit[2]}</p>
                        <Button variant="outlined" color="error" onClick={() => deleteUploadedDocs(fundDocSplit[2],fundDocSplit[1])}>
                            Remove
                        </Button>
                    </div>
                )
            })}
            <div
            className={`drag-and-drop${dragging ? ' active' : ''}`}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            style={{
                textAlign:"center",
                borderRadius:"10px",
                // borderStyle:"dotted",
                // borderColor: "#8DCAC3"
                padding:"10px",
                outline: "4px dashed #8DCAC3"
            }}
            >
                <input
                    type="file"
                    multiple
                    onChange={handleFileUpload}
                    className="hidden-input"
                    style={{display:"None"}}
                />
                <Button className="upload-button" variant="contained" component="label" sx={{backgroundColor:"#0e9384", fontWeight:"bold"}} onClick={() => document.querySelector('.hidden-input').click()}>
                    Select Files
                </Button>
                <p style={{paddingTop:"10px"}}>Drag and drop files here, or click to select files</p>
                <div className="file-list">
                    {files.map((file, index) => (
                    <div key={file.name} className="file-item">
                        <p>{file.name}</p>
                        <Button variant="contained" component="label" sx={{backgroundColor:"#0e9384", fontWeight:"bold", marginBottom:"10px"}} onClick={() => handleRemoveFile(index)}>
                            Remove
                        </Button>
                    </div>
                    ))}
                </div>
                <LoadingButton variant="contained" component="label" sx={{backgroundColor:"#0e9384", fontWeight:"bold"}} onClick={() => handleUploadToS3().then(() => setFiles([])).then(async() => await getPreviouslyUploadedDocs())} loading={loading}>
                    Upload
                </LoadingButton>
            </div>
        </Grid>}
        {
          showStep === "deal_preferences" &&
          <PrequalQuestions prequalSettings={prequalSettings} setPrequalSettings={setPrequalSettings}/>
        }
      </>}

      {
        props.isBroker === 'Y' &&
        <>
        {/* <Grid item xs={12} sx={{display:"flex"}}>
          <Grid item xs={6} sx={pofStyle}>
            <ManageSearch sx={{mr:"10px"}}/><p>NDAs</p>
          </Grid>
        </Grid> */}

        <Grid item xs={11}>
          <h4 style={{textAlign:"center"}}>Non-Disclosure Agreements</h4>
          <p>To keep SMB listing financial data confidential, all potential buyers must sign an NDA. Brokers can upload their NDA to OneDeal for automatic prompt to potential buyers. Protect your sensitive data and offer secure buying experience by uploading your NDA.</p>
          {fundsDocuments?.map((fundDoc) => {
              let fundDocSplit = fundDoc.Key.split('/');
              return(
                  <div style={{display:"flex", justifyContent:"center", marginBottom:"10px", alignItems:'center'}}>
                      <p style={{marginBottom:"0px", paddingRight:"10px"}}>{fundDocSplit[2]}</p>
                      <Button variant="outlined" color="error" onClick={() => deleteUploadedDocs(fundDocSplit[2],fundDocSplit[1])}>
                          Remove
                      </Button>
                  </div>
              )
          })}
          <div
          className={`drag-and-drop${dragging ? ' active' : ''}`}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          style={{
              textAlign:"center",
              borderRadius:"10px",
              // borderStyle:"dotted",
              // borderColor: "#8DCAC3"
              padding:"10px",
              outline: "4px dashed #8DCAC3"
          }}
          >
              <input
                  type="file"
                  multiple
                  onChange={handleFileUpload}
                  className="hidden-input"
                  style={{display:"None"}}
              />
              <Button className="upload-button" variant="contained" component="label" sx={{backgroundColor:"#0e9384", fontWeight:"bold"}} onClick={() => document.querySelector('.hidden-input').click()}>
                  Select Files
              </Button>
              <p style={{paddingTop:"10px"}}>Drag and drop files here, or click to select files</p>
              <div className="file-list">
                  {files.map((file, index) => (
                  <div key={file.name} className="file-item">
                      <p>{file.name}</p>
                      <Button variant="contained" component="label" sx={{backgroundColor:"#0e9384", fontWeight:"bold", marginBottom:"10px"}} onClick={() => handleRemoveFile(index)}>
                          Remove
                      </Button>
                  </div>
                  ))}
              </div>
              <LoadingButton variant="contained" component="label" sx={{backgroundColor:"#0e9384", fontWeight:"bold"}} onClick={() => {handleUploadToS3().then(() => setFiles([])).then(async() => await getPreviouslyUploadedDocs())}} loading={loading}>
                  Upload
              </LoadingButton>
          </div>
      </Grid>
      </>}
    </Grid>
    
  );
};

export default DragAndDrop;