import { React } from 'react';
import { Grid } from '@mui/material';
import styled from 'styled-components';
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { BadgeIcon, HeartIcon, FavoritedHeartIcon } from '../../IconTheme/IconTheme';
import { useNavigate } from "react-router-dom";
import '../Listing.css'
import { modifyUserFavoriteListings } from '../../../services/UserManagementService';
import IconButton from '@mui/material/IconButton';
import '../../../pages/Landing/App.scss'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteListing, listingStatistics } from '../../../services/ListingManagementService';
import LinearProgress from '@mui/joy/LinearProgress';
import Typography from '@mui/joy/Typography';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';

const Location = styled.span`
    line-height:0px;
    font-size: 13px;
    color: grey;
`;

const Description = styled.p`
    font-size: 13px;
    color: grey;
    min-height: 50px
`;

const StatisticTitle = styled.p`
    font-weight: bold;
    font-size: 13px;
    color: grey;
`;

const Featured = {
    display: 'inline-flex',
    background: '#0e9384',
    padding: '8px 16px',
    alignItems: 'center',
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '16px',
    gap: '4px',
    borderRadius: '8px',
    color:'white',
    marginTop: '20px'
}

export const ListingDesktop = (props) => {
    const navigate = useNavigate();

    const getLocation = (locationValue) => {
        if(locationValue.includes("Undisclosed")){
            return "Undisclosed Location"
        }
        const splitLocation = locationValue.split(',');
        return `${splitLocation[1]}, ${splitLocation[0]}`;
    }

    const handleModifyFavorites = (favoritePayload) => {
        if(!props.userAttributes && !localStorage.getItem("authorized")){
            navigate('/login');
        }else{
            modifyUserFavoriteListings(favoritePayload).then((response) => {
                if(response.errorMessage){
                    props.setErrorMessage(response.errorMessage);
                }else{
                    props.setUserFavorites(response.Attributes.favorites);
                }
              });
            
            if(props.userAttributes.email !== props.listing['posted-by-email']){
                listingStatistics({
                    "user-email": props.userAttributes.email, 
                    "listing": props.listing, 
                    "action": "favorited"
                });
            }
        }
    };

    const getLoadingBarValue = (status) => {
        let progressPercentage = 0;
        if(status){
            if(status["requested-info"]){
                progressPercentage += 25;
            }
    
            if(status["signed-nda"]){
                progressPercentage += 25;
            }
    
            if(status["dr-request-sent"]){
                progressPercentage += 25;
            }
    
            if(status["dr-request-response"] === "Approved" || status["dr-request-response"] === "Rejected"){
                progressPercentage += 25;
            }
        }
        return progressPercentage;
    }

    const getLoadingBarStep = (progress, status) => {
        let step = "Step 1/4: Requested Info";
        if(progress === 50){
            step = "Step 2/4: Signed NDA";
        }else if(progress === 75){
            step = "Step 3/4: Requested Data room Access";
        }else if(progress === 100 && status["dr-request-response"] === "Approved"){
            step = "Step 4/4: Data Room Access Granted"
        }else if(progress === 100 && status["dr-request-response"] === "Rejected"){
            step = "Step 4/4: Data Room Access Rejected"
        }
        return step;
    }

    return(
        <Grid container direction="row" justifyContent="center" alignItems="center" marginBottom="8px">
            <Grid item xs={props.brokerProfile ? 12 : 10} sx={{borderRadius: "10px", boxShadow:" 0 2px 4px -1px rgba(0,0,0,0.25)", display:"flex", backgroundColor:"white"}}>
                <Grid item xs={4} sx={{padding:"20px", position:'relative'}}>
                    <HashLink to={`/listingDetails?id=${props.listing['listing-id']}`}>
                        <img src={`${process.env.REACT_APP_S3_URL}${props.listing['s3-resource-url']}`} alt="BusinessPhoto" style={{width:"100%", borderRadius: "20px"}}/>
                        {'isFeatured' in props.listing && props.listing.isFeatured === 'Y' && <span style={Featured}><BadgeIcon /> Featured</span>}
                    </ HashLink>
                    {
                        props.userAttributes?.email === props.listing['posted-by-email'] && window.location.pathname.includes('myOneDeal') &&
                        <>
                        <Link to={`/edit-listing?listing-id=${props.listing['listing-id']}`}  state={{ data: props.listing }} className='listed-by-link'>
                             <IconButton sx={{color:"black", fontSize:"16px"}}>
                                Edit<EditIcon sx={{paddingLeft:"5px"}}/>
                            </IconButton>
                        </Link>
                        <IconButton sx={{color:"black", fontSize:"16px"}} onClick={async() => await deleteListing({'listing-id': props.listing['listing-id'], 'request-time': props.listing['request-time']}).then(() => props.getUserListings())}>
                            Delete<DeleteIcon sx={{paddingLeft:"5px"}}/>
                        </IconButton>
                        <HashLink to={`/edit-listing?listing-id=${props.listing['listing-id']}#dataroom`}  state={{ data: props.listing }} className='listed-by-link'>
                             <IconButton sx={{color:"black", fontSize:"16px"}}>
                                DataRoom<DriveFolderUploadIcon sx={{paddingLeft:"5px"}}/>
                            </IconButton>
                        </HashLink>  
                        </>
                    }

                    {
                       props.dealStatuses &&
                        <LinearProgress
                            determinate
                            size="md"
                            variant="soft"
                            thickness={32}
                            value={
                                getLoadingBarValue(props.dealStatuses[props.listing['listing-id']])
                            }
                            sx={{backgroundColor: "#8DCAC3", color:"#0e9384",position:"absolute",width:"82%", bottom: "0", marginBottom: "18px"}}
                            >
                                <Typography
                                    level="body3"
                                    fontWeight="xl"
                                    textColor="common.white"
                                    sx={{mixBlendMode: 'color-dodge' }}
                                >
                                    {
                                        getLoadingBarStep(getLoadingBarValue(props.dealStatuses[props.listing['listing-id']]), props.dealStatuses[props.listing['listing-id']])
                                    }
                                </Typography>
                        </LinearProgress>
                    }
                </Grid>
                <Grid container direction="row" alignItems="center">
                    <Grid item xs={12}>
                        <h4>{props.listing.headline}</h4>
                        <Location>{(props.listing.location.includes("Undisclosed")) ? "Undisclosed Location" :getLocation(props.listing.location)}</Location>
                        <Description>{(props.listing.description.length > 400) ? (props.listing.description.substr(0, 400) + "...") : props.listing.description}</Description>
                    </Grid>
                    <Grid item xs={12} sx={{justifyContent:"center", display:"flex", textAlign:"center"}}>
                        <Grid item xs={4}>
                            <StatisticTitle>Revenue</StatisticTitle>
                            <Description>{(props.listing['gross-revenue'] > 0) ? ('$' + (props.listing["gross-revenue"].toLocaleString('en-US'))) : "N/A"}</Description>
                        </Grid>
                        <Grid item xs={4}>
                            <StatisticTitle>Cash Flow</StatisticTitle>
                            <Description>{(props.listing["cash-flow"] > 0) ? ('$' + props.listing["cash-flow"].toLocaleString('en-US')) : "N/A"}</Description>
                        </Grid>
                        <Grid item xs={4}>
                            <StatisticTitle>Financing Options?</StatisticTitle>
                            <Description>{(props.listing.financing === true) ? "Yes" : "No"}</Description>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={5} sx={{textAlign:"center", position:'relative'}}>
                    <h4 style={{color:"#0e9384"}}>{(props.listing.price > 0) ? ('$' + props.listing.price.toLocaleString('en-US')) : "N/A"}</h4>
                    <HashLink to={`/listingDetails?id=${props.listing['listing-id']}`}>
                        <button variant="contained" className='listingDetails-btn'>Business Overview</button>
                    </HashLink>
                    {
                        props.listing['posted-by-name'] !== "OneDeal" ? <Link to={{ pathname: '/broker-profile', search: `?email=${props.listing['posted-by-email']}`, }} state={{email: props.listing['posted-by-email']}} className='listed-by-link'>Listed By: {props.listing['posted-by-name']}</Link> : <Link to="#" className='listed-by-link'>Listed by: OneDeal</Link>
                    }
                   
                    <Grid item sx={{position:"absolute", bottom: "0", width:"100%", marginBottom: "10px"}}>
                        {
                            (props.indexOfFavorite === -1) ? <IconButton onClick={() => {
                                handleModifyFavorites({
                                    favoritedListingId: props.listing['listing-id'], 
                                    unfavoritedIndex:null
                                    });
                            }
                            }><HeartIcon/></IconButton> : <IconButton onClick={async() =>handleModifyFavorites({favoritedListingId: props.listing['listing-id'], unfavoritedIndex: props.indexOfFavorite})}><FavoritedHeartIcon /></IconButton>
                        }                           
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}