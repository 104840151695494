import { React, useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { retrieveUserAttributes, retrieveUserProfile, putAvatar, updateUserProfile} from '../../../services/UserManagementService';
import { SnackBar } from '../../../components/SnackBar/SnackBar';
import Footer from '../../../components/Footer/Footer';
import { Box, Grid } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { useNavigate, useLocation } from 'react-router-dom';
import ProfilePicture from './ProfilePicture';

const avatarPhoto = {
  width: "5vw", 
  height:"5vw",
  margin:"auto", 
  marginBottom:"8px", 
  marginTop:"8px",
  '@media(max-Width: 768px)' : {
    width: "15vw", 
    height:"15vw",
  }
}

const avatarNoPhoto = { 
  bgcolor: "#0e9384", 
  width: "5vw", 
  height:"5vw", 
  margin:"auto", 
  marginBottom:"8px", 
  marginTop:"8px",
  '@media(max-Width: 768px)' : {
    width: "15vw", 
    height:"15vw",
  }
}

export const UserProfile = () => {
  const [userProfileData, setUserProfileData] = useState();
  const [newAvatar, setNewAvatar] = useState({});
  const [userAttributes, setUserAttributes] = useState();
  const [loading, setLoading] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const navigate = useNavigate();

  const getUserAttributes = async() => {
    retrieveUserAttributes().then((data) => {
      if(data.errorMessage){
        setErrorMessage(data.errorMessage);
      }else{
        setUserAttributes(data.result)
      }
    });
  }

  const getUserProfile = async() => {
    retrieveUserProfile().then((response) => {
      if(response.errorMessage){
        setErrorMessage(response.errorMessage);
      }else{
        if(!response['first-name']) response['first-name'] = "";
        if(!response['last-name']) response['last-name'] = "";
        if(!response.phone) response.phone = "";
        setUserProfileData(response);
      }
    });
  }
  
  const handleFieldUpdate = (event) => {
    setUserProfileData({...userProfileData, [event.target.id] : event.target.value});
  }

  const GetAvatar = () => {

    let initials = "";
    if(!userAttributes.picture && !userProfileData.avatar){
      let splitNameArray = userAttributes.name.split(" ");
      if(splitNameArray.length > 1){
        initials = splitNameArray[0][0] + splitNameArray[splitNameArray.length - 1][0];
      }else{
        initials = splitNameArray[0][0];
      }
    }
   
    return (

      <>
        {/* 1. else if, user has userProfileData.avatar display that */}
        
        {
          userProfileData.avatar && !newAvatar['image-content'] && <Avatar src={`${process.env.REACT_APP_S3_AVATARS_URL}${userProfileData.avatar}`} sx={avatarPhoto} imgProps={{referrerPolicy:"no-referrer"}}/>
        }

        {/* 2. if user has google pic, display that */}
        {
          !userProfileData.avatar && userAttributes.picture && !newAvatar['image-content'] && <Avatar src={userAttributes.picture} sx={avatarPhoto} imgProps={{referrerPolicy:"no-referrer"}}/>
        }

        {/* 3. else display initials */}
        {!userAttributes.picture && !userProfileData.avatar && !newAvatar['image-content'] && <Avatar sx={avatarNoPhoto} src="">{initials}</Avatar>}
        
        {
          newAvatar['image-content'] && <Avatar src={newAvatar['image-content']} sx={avatarPhoto} imgProps={{referrerPolicy:"no-referrer"}} />
        }
      </>
    );
  }

  const handleSubmit = async() => {
    setLoading(true);
    let profileData = userProfileData;
    if(newAvatar['image-content']){
      const avatarResponse = await putAvatar(newAvatar['image-content'], newAvatar['image-type'], newAvatar['image-title'], setErrorMessage, userProfileData.email);
      if(!avatarResponse.key){
        setErrorMessage("Error uploading new avatar");
      }else{
        setUserProfileData({...userProfileData, 'avatar' : avatarResponse.key});
        profileData = {...userProfileData, 'avatar' : avatarResponse.key};
      }
    }

    const updateProfileResponse = await updateUserProfile(profileData);
    if(!updateProfileResponse.email){
      setErrorMessage("Error updating user profile")
    }
    setLoading(false);
  }
  const location = useLocation();
  useEffect(() => {
    if (!window.location.host.includes('localhost') && !window.location.host.includes('dev.onedeal.biz')) {
      window.gtag("event", "page_view", {
        page_path: location.pathname + location.search,
      });
    }
    
    if(localStorage.getItem("authorized") && !userAttributes){
      getUserAttributes();
    }else if(localStorage.getItem("authorized") && !userProfileData){
      getUserProfile();
    }else if(!localStorage.getItem("authorized")){
      navigate('/login');
    }
  },[location, userAttributes, userProfileData]);

  return (
    <Box sx={{position:"relative", height:"100%"}}>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item xs={10} sm={5} sx={{textAlign:'center'}}>
          { userAttributes && userProfileData &&
            <GetAvatar/>
          }
          {
            userAttributes && !userAttributes.picture &&
            <ProfilePicture newAvatar={newAvatar} setNewAvatar={setNewAvatar}/>
          }
          { userProfileData &&
          <>
             <TextField
                required
                id='first-name'
                key='first-name'
                label="First Name"
                variant="filled"
                onChange={handleFieldUpdate}
                style={{width:"100%"}}
                defaultValue={userProfileData['first-name']}
                disabled={false}
              />
              <TextField
                required
                id='last-name'
                key='last-name'
                label='Last Name'
                variant="filled"
                onChange={handleFieldUpdate}
                style={{width:"100%"}}
                defaultValue={userProfileData['last-name']}
                disabled={false}
              />
              <TextField
                required
                id='phone'
                key='phone'
                label='Phone'
                variant="filled"
                onChange={handleFieldUpdate}
                style={{width:"100%"}}
                defaultValue={userProfileData['phone']}
                disabled={false}
              />
              <TextField
                required
                id='email'
                key='email'
                label="Email"
                variant="filled"
                onChange={handleFieldUpdate}
                style={{width:"100%"}}
                defaultValue={userProfileData['email']}
                disabled={true}
              />
              {/* add email-subscription */}
          </>
        }
        </Grid>
        <Grid item xs={10} sx={{textAlign:'center'}}>
          <LoadingButton
              onClick={handleSubmit}
              loading={loading}
              // loadingPosition="end"
              variant="contained"
              style={{marginTop:"8px", marginBottom:"8px", backgroundColor:"#0e9384", fontWeight:"bold"}}
              size='large'
          >
          Update Profile
          </LoadingButton>
        </Grid>
      </Grid>
      {errorMessage && <SnackBar errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>}
      <Footer />
    </Box>
  );
}