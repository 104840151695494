import {React, useState, useEffect} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { retrieveBrokerInfo } from "../../services/UserManagementService";
import { SnackBar } from "../../components/SnackBar/SnackBar";
import NavBar from "../../components/NavBar/NavBar";
import Footer from '../../components/Footer/Footer'
import { retrieveUserAttributes, retrieveUserProfile } from "../../services/UserManagementService";
import {Grid } from '@mui/material';
import BrokerPostedListings from "./BrokerPostedListings";
import BrokerCard from "../../components/BrokerCard/BrokerCard";
import SetPageTitle from "../../components/SetPageTitle/SetPageTitle";

const BrokerProfile = () => {
    const [userAttributes, setUserAttributes] = useState();
    const [userFavorites, setUserFavorites] = useState(false);
    const [userProfileData, setUserProfileData] = useState();
    const [brokerEmail, setBrokerEmail] = useState(new URLSearchParams(window.location.search).get('email'));
    const [brokerInfo, setBrokerInfo] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const location = useLocation();
    const navigate = useNavigate();

    const getBrokerInfo = () => {        
        retrieveBrokerInfo(brokerEmail).then((response) => {
            if(response.Error){
                setErrorMessage(response.Error);
                navigate('/browse-brokers') // should navigate to business brokers page if they dont exist
            }else{
                setBrokerInfo(response)
            }
          });
    }

    const getUserAttributes = async() => {
        retrieveUserAttributes().then((response) => {
            if(response.errorMessage){
            setErrorMessage(response.errorMessage);
            }else{
            setUserAttributes(response.result)
            }
        });
      } 

    const getUserFavorites = async() => {
        retrieveUserProfile().then((response) => {
            if(response.errorMessage){
            setErrorMessage(response.errorMessage);
            }else{
            setUserFavorites(response.favorites);
            setUserProfileData(response);
            }
        });
    }
    
    useEffect(() => {
        if (!window.location.host.includes('localhost') && !window.location.host.includes('dev.onedeal.biz')) {
			window.gtag("event", "page_view", {
			  page_path: location.pathname + location.search,
			});
		  }
          
          if(!localStorage.getItem("authorized") && !(userFavorites instanceof Array)){
            setUserFavorites([]);
          }else if(localStorage.getItem("authorized")){
			if(!userAttributes){
                getUserAttributes();
              }

              

            if(!userFavorites && userAttributes){
                getUserFavorites();
              }
		}

        if(!brokerInfo && brokerEmail){
            getBrokerInfo()
        }

    }, [location, brokerInfo, brokerEmail, userAttributes, userFavorites])

    return(
        <>
        <SetPageTitle title="Broker Profile" />
        <NavBar userAttributes={userAttributes} setUserAttributes={setUserAttributes} userProfileData={userProfileData}/>
        {
            brokerInfo &&
            <Grid container direction="row" justifyContent="center" alignItems="start" spacing={2} marginTop="10px">
                <Grid item xs={11} sm={3}>
                <BrokerCard 
                    name={brokerInfo['first-name'] && brokerInfo['last-name'] ? brokerInfo['first-name'] + ' ' + brokerInfo['last-name'] : 'Broker Profile'}
                    email={brokerInfo.email}
                    phone={brokerInfo.phone}
                    extension={brokerInfo.extension}
                />
                </Grid>
                <Grid item xs={11} sm={7}>
                    <BrokerPostedListings
                        userFavorites={userFavorites}
                        setUserFavorites={setUserFavorites}
                        brokerPostedListings={brokerInfo.listings}
                        brokerProfile={true}
                    />
                </Grid>
            </Grid>
        }
        <Footer />
        {errorMessage && <SnackBar errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>}
        </>
    );
}

export default BrokerProfile;