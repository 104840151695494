import { React, useState } from 'react';
import { Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { SnackBar } from '../SnackBar/SnackBar';
import { forgotPasswordConfirmation, resendCode } from '../../services/UserManagementService';
import { useNavigate } from "react-router-dom";
import FilledInput from '@mui/material/FilledInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// confirmation code component
export const ConfirmResetPassword = (props) => {

    // const [resendResponse, setResendResponse] = useState();    
    // const [resendLoading, setResendLoading] = useState(false);
    const [confirmationCode, setConfirmationCode] = useState();
    const [confirmationLoading, setConfirmationLoading] = useState(false);
    const [resetResponse, setResetResponse] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const [newPassword, setNewPassword] = useState();
    const [errorMessage, setErrorMessage] = useState();

    const handleConfirmationCode = (event) => { setConfirmationCode(event.target.value) };
    const handleNewPassword = (event) => { setNewPassword(event.target.value) };
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = (event) => { event.preventDefault(); };
    
    const navigate = useNavigate();

    const handleConfirmationSubmit = async() => {
      forgotPasswordConfirmation(
        setConfirmationLoading,
        props.email,
        confirmationCode,
        newPassword,
        setErrorMessage,
        setResetResponse,
        navigate
      );
    }
  
    return(
        <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={11} sm={7}>
                <h3>Check your email for a confirmation code and enter it below :) </h3>
                <TextField
                required
                id="outlined-number"
                label="Confirmation Code"
                type="number"
                InputLabelProps={{
                    shrink: true,
                }}
                variant="filled"
                onChange={handleConfirmationCode}
                sx={{width:"100%", marginBottom: "8px"}}
                />
            </Grid>
            <Grid item xs={11} sm={7}>
              <FormControl sx={{marginBottom: "8px", width: '100%'  }} variant="filled">
                <InputLabel htmlFor="filled-adornment-password">New Password</InputLabel>
                <FilledInput
                    id="password"
                    autoComplete='new-password'
                    type={showPassword ? 'text' : 'password'}
                    value={newPassword}
                    onChange={handleNewPassword}
                    endAdornment={
                        <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                        </InputAdornment>
                    }
                />
              </FormControl>
            </Grid>
            <Grid item xs={11} sm={7}>
                <LoadingButton
                    onClick={handleConfirmationSubmit}
                    loading={confirmationLoading}
                    // loadingPosition="end"
                    variant="contained"
                    sx={{backgroundColor:"green", width:"100%", marginBottom: "8px"}}
                    >
                    Submit
                </LoadingButton>
            </Grid>
            {/* <Grid item xs={11} sm={7}>
                <p>Need a new code?</p>
                <LoadingButton
                    onClick={handleResendSubmit}
                    loading={resendLoading}
                    // loadingPosition="end"
                    variant="contained"
                    sx={{width:"100%"}}
                    >
                    Resend Confirmation Code
                </LoadingButton>
            </Grid> */}
            {errorMessage && <SnackBar errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>}
      </Grid>
    );
  }