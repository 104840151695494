import React from "react";
import { Link } from "react-router-dom";
import img from "../../assets/Landing/financialfuture.webp";
import {
  ArrowRight,
  ConnectSeller,
  Finance,
  FinanceTools,
} from "../../components/IconTheme/IconTheme";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import { Stack } from "@mui/material";

const data = [
  {
    icon: <ConnectSeller />,
    title: "Proprietary Off Market Sourcing",
    text: "Search from over 17.5M lower middle market businesses with relevant data points. Find owner info, email addresses, phone number, employee count,  est revenue, and more",
    // link: "/businesses-for-sale#",
  },
  {
    icon: <Finance />,
    title: " Brokered Deal Aggregation  ",
    text: "Access to over 80,000 actively listed brokers deals aggregated from every marketplace and broker website on the internet. ",
    // link: "/DealScoutLandingPage#",
  },
  {
    icon: <FinanceTools />,
    title: "Direct Listed Brokered Deals ",
    text: "Don't waste time waiting for confidential documents. Deals listed directly by the broker will  include a data room with an NDA, CIM’s, and financials. ",
    // link: "/businesses-for-sale#",
  },
];

const FinancialFuture = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        margin: "6rem 0",
        gap: "4rem",
      }}
    >
      <Stack spacing={2} style={{ textAlign: "center" }}>
        <h4 style={{ color: "var(--base)" }}>OneDeal Steps</h4>
        <h3>Find the right sourcing process for you</h3>
      </Stack>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            maxWidth: "30%",
            gap: "2rem",
          }}
        >
          {data?.map(({ icon, title, text, link }, i) => (
            <div
              key={i}
              style={{ display: "flex", flexDirection: "row", gap: "1rem" }}
            >
              <div className="icon">{icon}</div>

              <div style={{ marginTop: "0.5rem" }}>
                {title && <h6 className="subtitle">{title}</h6>}

                {text && <p>{text}</p>}
                {/* <Link to={link} style={{ color: "var(--base-2)" }}>
                      Learn more <ArrowRight />{" "}
                    </Link> */}
              </div>
            </div>
          ))}
        </div>
        <img
          src={img}
          style={{
            borderRadius: "16px",
            maxWidth: "40%",
            height: "auto",
            float: "right",
          }}
          alt=""
        />
      </div>
    </div>
  );
};

export default FinancialFuture;
