export const dataAxleCategories = [
    {
        "value": "011198",
        "label": "Wheat",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11114001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "011298",
        "label": "Rice Production",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11116001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "011598",
        "label": "Corn",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11115001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "011698",
        "label": "Soybeans",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11111001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "011901",
        "label": "Oilseed (except Soybean) Farming ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11112001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "011902",
        "label": "Dry Pea and Bean Farming ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11113001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "011903",
        "label": "Oilseed and Grain Combination Farming ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11119101",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "011999",
        "label": "Cash Grains",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11119901",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "013198",
        "label": "Cotton",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11192001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "013201",
        "label": "Tobacco Growers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11191001",
        "yellow_page_code": "86700",
        "kiosk": false
    },
    {
        "value": "013301",
        "label": "Sugar Beet Farming ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11199101",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "013398",
        "label": "Sugarcane & Sugar Beets",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11193001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "013401",
        "label": "Potato Growers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11121101",
        "yellow_page_code": "67509",
        "kiosk": false
    },
    {
        "value": "013901",
        "label": "Agricultural Products",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11199801",
        "yellow_page_code": "00811",
        "kiosk": false
    },
    {
        "value": "013902",
        "label": "Hay Farming ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11194001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "013903",
        "label": "Peanut Farming ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11199201",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "013999",
        "label": "Field Crops-Except Cash Grains",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11199802",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "016101",
        "label": "Vegetable Farms",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11121901",
        "yellow_page_code": "90701, 90610",
        "kiosk": false
    },
    {
        "value": "016102",
        "label": "Pumpkin Patches",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11121903",
        "yellow_page_code": "69109",
        "kiosk": false
    },
    {
        "value": "016198",
        "label": "Vegetables & Melons",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11121902",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "017101",
        "label": "Berry Farming-Except Strawberry",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11133401",
        "yellow_page_code": "22305",
        "kiosk": false
    },
    {
        "value": "017102",
        "label": "Strawberry Farming ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11133301",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "017201",
        "label": "Vineyards",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11133201",
        "yellow_page_code": "91134",
        "kiosk": false
    },
    {
        "value": "017301",
        "label": "Macadamia Nuts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11133501",
        "yellow_page_code": "49102",
        "kiosk": false
    },
    {
        "value": "017302",
        "label": "Farms-Pecan",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11133503",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "017398",
        "label": "Tree Nuts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11133502",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "017401",
        "label": "Citrus Growers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11132002",
        "yellow_page_code": "18103",
        "kiosk": false
    },
    {
        "value": "017402",
        "label": "Citrus Fruit Products",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11132001",
        "yellow_page_code": "18011",
        "kiosk": false
    },
    {
        "value": "017403",
        "label": "Orange Groves",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11131001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "017501",
        "label": "Orchards",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11133103",
        "yellow_page_code": "57800",
        "kiosk": false
    },
    {
        "value": "017503",
        "label": "Cider Mills",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31194105",
        "yellow_page_code": "15805, 15807",
        "kiosk": false
    },
    {
        "value": "017504",
        "label": "Apple Growers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11133101",
        "yellow_page_code": "02814",
        "kiosk": false
    },
    {
        "value": "017505",
        "label": "Apple Products",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11133102",
        "yellow_page_code": "02816",
        "kiosk": false
    },
    {
        "value": "017901",
        "label": "Dates-Edible",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11133903",
        "yellow_page_code": "23505",
        "kiosk": false
    },
    {
        "value": "017999",
        "label": "Fruits & Tree Nuts NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11133901",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "018101",
        "label": "Greenhouses",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11142103",
        "yellow_page_code": "33507, 38906, 57806, 75405, 75410",
        "kiosk": false
    },
    {
        "value": "018102",
        "label": "Seeds-Certified",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11142104",
        "yellow_page_code": "75407",
        "kiosk": false
    },
    {
        "value": "018103",
        "label": "Turf",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11142106",
        "yellow_page_code": "89501",
        "kiosk": false
    },
    {
        "value": "018104",
        "label": "Ferneries",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11142203",
        "yellow_page_code": "31336",
        "kiosk": false
    },
    {
        "value": "018105",
        "label": "Holly Growers & Shippers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11142201",
        "yellow_page_code": "41113",
        "kiosk": false
    },
    {
        "value": "018106",
        "label": "Nursery and Tree Production ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11142101",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "018198",
        "label": "Ornamental Floriculture & Nursery Products",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11142202",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "018201",
        "label": "Mushrooms",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11141101",
        "yellow_page_code": "54606",
        "kiosk": false
    },
    {
        "value": "018202",
        "label": "Hydroponics",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11141902",
        "yellow_page_code": "42212, 42128, 31115",
        "kiosk": false
    },
    {
        "value": "018298",
        "label": "Food Crops Grown Under Cover",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11141901",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "019101",
        "label": "Farms",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11199803",
        "yellow_page_code": "30908, 00822, 30799",
        "kiosk": false
    },
    {
        "value": "019102",
        "label": "Farm Products",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11199804",
        "yellow_page_code": "30903",
        "kiosk": false
    },
    {
        "value": "019103",
        "label": "Plantations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11199805",
        "yellow_page_code": "66012",
        "kiosk": false
    },
    {
        "value": "019104",
        "label": "Organic Farms",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11199806",
        "yellow_page_code": "57814, 57811, 30822",
        "kiosk": false
    },
    {
        "value": "019105",
        "label": "Seed Farms",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11199810",
        "yellow_page_code": "75395",
        "kiosk": false
    },
    {
        "value": "019106",
        "label": "Cannabis Growers/Cultivators",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11199811",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "019198",
        "label": "General Farms-Primarily Crop",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11199807",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "021101",
        "label": "Livestock Feeding",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11211201",
        "yellow_page_code": "31209, 48204, 14121, 31205",
        "kiosk": false
    },
    {
        "value": "021201",
        "label": "Livestock Producers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11211103",
        "yellow_page_code": "48304",
        "kiosk": false
    },
    {
        "value": "021202",
        "label": "Beef Cattle-Except Feedlots",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11211101",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "021203",
        "label": "Cattle-Registered",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11211102",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "021398",
        "label": "Hogs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11221002",
        "yellow_page_code": "82916",
        "kiosk": false
    },
    {
        "value": "021403",
        "label": "Sheepskins",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11241001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "021404",
        "label": "Sheep Farms",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11241003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "021405",
        "label": "Goats & Goat By-Products",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11242001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "021498",
        "label": "Sheep & Goats",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11241002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "024101",
        "label": "Dairies (Milk)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11212001",
        "yellow_page_code": "23206",
        "kiosk": false
    },
    {
        "value": "024103",
        "label": "Dairy Farms",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11212002",
        "yellow_page_code": "24602",
        "kiosk": false
    },
    {
        "value": "025198",
        "label": "Broiler Fryer & Roaster Chickens",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11232001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "025298",
        "label": "Chicken Eggs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11231001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "025301",
        "label": "Turkey Farms",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11233001",
        "yellow_page_code": "89506",
        "kiosk": false
    },
    {
        "value": "025401",
        "label": "Poultry Farms",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11234001",
        "yellow_page_code": "40206, 67800",
        "kiosk": false
    },
    {
        "value": "025901",
        "label": "Quail Farms",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11239002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "025902",
        "label": "Ostriches & Emus",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11239003",
        "yellow_page_code": "58052, 28607",
        "kiosk": false
    },
    {
        "value": "025903",
        "label": "Duck Farms",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11239005",
        "yellow_page_code": "30918",
        "kiosk": false
    },
    {
        "value": "025904",
        "label": "Pheasant Farms",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11239004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "025999",
        "label": "Poultry & Eggs NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11239001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "027101",
        "label": "Fur Farms",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11293002",
        "yellow_page_code": "35202, 35117",
        "kiosk": false
    },
    {
        "value": "027102",
        "label": "Rabbits",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11293006",
        "yellow_page_code": "69504, 69531",
        "kiosk": false
    },
    {
        "value": "027103",
        "label": "Game Farms",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11293004",
        "yellow_page_code": "36004",
        "kiosk": false
    },
    {
        "value": "027104",
        "label": "Fur-Shearing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11293003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "027105",
        "label": "Chinchillas",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11293001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "027298",
        "label": "Horses & Other Equines",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11292001",
        "yellow_page_code": "41532, 29725",
        "kiosk": false
    },
    {
        "value": "027301",
        "label": "Aquaculture",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11251901",
        "yellow_page_code": "03103",
        "kiosk": false
    },
    {
        "value": "027302",
        "label": "Oyster Growers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11251201",
        "yellow_page_code": "58209",
        "kiosk": false
    },
    {
        "value": "027303",
        "label": "Crawfish Farms",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11251202",
        "yellow_page_code": "22416",
        "kiosk": false
    },
    {
        "value": "027304",
        "label": "Fish Farms and Hatcheries",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11251101",
        "yellow_page_code": "32408, 40212, 32415, 32510, 32421, 03125",
        "kiosk": false
    },
    {
        "value": "027305",
        "label": "Shellfish Farming ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11251203",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "027901",
        "label": "Cats",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11299006",
        "yellow_page_code": "14104",
        "kiosk": false
    },
    {
        "value": "027902",
        "label": "Beekeepers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11291001",
        "yellow_page_code": "07502, 07499, 02812",
        "kiosk": false
    },
    {
        "value": "027903",
        "label": "Animals-Laboratory Use",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11299004",
        "yellow_page_code": "02606",
        "kiosk": false
    },
    {
        "value": "027904",
        "label": "Earthworms",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11299007",
        "yellow_page_code": "27106, 94507",
        "kiosk": false
    },
    {
        "value": "027905",
        "label": "Animal Breeders",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11299001",
        "yellow_page_code": "02410",
        "kiosk": false
    },
    {
        "value": "027906",
        "label": "Llamas",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11299011",
        "yellow_page_code": "48307",
        "kiosk": false
    },
    {
        "value": "027908",
        "label": "Frog Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11251902",
        "yellow_page_code": "34507",
        "kiosk": false
    },
    {
        "value": "027910",
        "label": "Deer Farms",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11299015",
        "yellow_page_code": "23718",
        "kiosk": false
    },
    {
        "value": "027911",
        "label": "Butterfly Breeders",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11299005",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "027912",
        "label": "Bird Farm",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11299018",
        "yellow_page_code": "08238",
        "kiosk": false
    },
    {
        "value": "027999",
        "label": "Animal Specialties NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11299003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "029101",
        "label": "Ranches",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11299013",
        "yellow_page_code": "70308",
        "kiosk": false
    },
    {
        "value": "029102",
        "label": "Animal Brokers & Dealers-Zoo Circus Etc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11299002",
        "yellow_page_code": "02406, 02414, 02522",
        "kiosk": false
    },
    {
        "value": "029103",
        "label": "Reptiles",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11299014",
        "yellow_page_code": "71801",
        "kiosk": false
    },
    {
        "value": "029104",
        "label": "Livestock Brokers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11299008",
        "yellow_page_code": "48115",
        "kiosk": false
    },
    {
        "value": "029105",
        "label": "Alpacas",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11299017",
        "yellow_page_code": "01926",
        "kiosk": false
    },
    {
        "value": "071101",
        "label": "Soil Conservation Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511215",
        "yellow_page_code": "79204",
        "kiosk": false
    },
    {
        "value": "071103",
        "label": "Lime & Fertilizer Spreaders",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511208",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "071104",
        "label": "Agricultural Minerals",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511201",
        "yellow_page_code": "00809",
        "kiosk": false
    },
    {
        "value": "071105",
        "label": "Herbicide Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511209",
        "yellow_page_code": "40934",
        "kiosk": false
    },
    {
        "value": "071106",
        "label": "Discing-Rotary Cultivating & Plowing Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511205",
        "yellow_page_code": "25000, 66705",
        "kiosk": false
    },
    {
        "value": "071107",
        "label": "Manure Pumping",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511211",
        "yellow_page_code": "49845, 49839",
        "kiosk": false
    },
    {
        "value": "071108",
        "label": "Permaculture",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511217",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "071109",
        "label": "Manure Application",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511212",
        "yellow_page_code": "49837",
        "kiosk": false
    },
    {
        "value": "071110",
        "label": "Swathing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511218",
        "yellow_page_code": "82613",
        "kiosk": false
    },
    {
        "value": "071111",
        "label": "Soil Preparation Planting & Cultivating ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511219",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "072101",
        "label": "Irrigation Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511210",
        "yellow_page_code": "43908",
        "kiosk": false
    },
    {
        "value": "072102",
        "label": "Agricultural Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511203",
        "yellow_page_code": "22690",
        "kiosk": false
    },
    {
        "value": "072103",
        "label": "Aerial Applicators",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48121903",
        "yellow_page_code": "00707, 22700, 00711, 80113, 00721, 80027, 11714, 00820, 00734",
        "kiosk": false
    },
    {
        "value": "072104",
        "label": "Weed Control Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511216",
        "yellow_page_code": "93004, 56477",
        "kiosk": false
    },
    {
        "value": "072105",
        "label": "Entomologists",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511207",
        "yellow_page_code": "29605",
        "kiosk": false
    },
    {
        "value": "072106",
        "label": "Custom Farming",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511204",
        "yellow_page_code": "23017",
        "kiosk": false
    },
    {
        "value": "072107",
        "label": "Pollen & Pollination Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511214",
        "yellow_page_code": "67211",
        "kiosk": false
    },
    {
        "value": "072109",
        "label": "Orchard Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511213",
        "yellow_page_code": "57802",
        "kiosk": false
    },
    {
        "value": "072110",
        "label": "Chemicals-Spraying",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511202",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "072111",
        "label": "Vineyard Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511206",
        "yellow_page_code": "91141, 91144",
        "kiosk": false
    },
    {
        "value": "072201",
        "label": "Fruits & Vegetables-Harvesting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511303",
        "yellow_page_code": "34611",
        "kiosk": false
    },
    {
        "value": "072202",
        "label": "Hay Grinding",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511305",
        "yellow_page_code": "40309",
        "kiosk": false
    },
    {
        "value": "072203",
        "label": "Hay Baling Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511304",
        "yellow_page_code": "40311, 06107",
        "kiosk": false
    },
    {
        "value": "072204",
        "label": "Custom Harvesting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511302",
        "yellow_page_code": "23018, 30917",
        "kiosk": false
    },
    {
        "value": "072298",
        "label": "Crop Harvesting-Primarily By Machine",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511301",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "072301",
        "label": "Seed & Grain Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511424",
        "yellow_page_code": "75400, 38822",
        "kiosk": false
    },
    {
        "value": "072302",
        "label": "Potatoes Packaging",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511403",
        "yellow_page_code": "67519",
        "kiosk": false
    },
    {
        "value": "072303",
        "label": "Citrus Fruit Processing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511404",
        "yellow_page_code": "18009",
        "kiosk": false
    },
    {
        "value": "072304",
        "label": "Citrus Packers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511405",
        "yellow_page_code": "18105",
        "kiosk": false
    },
    {
        "value": "072305",
        "label": "Grain Drying",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511415",
        "yellow_page_code": "38606",
        "kiosk": false
    },
    {
        "value": "072306",
        "label": "Grain Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511414",
        "yellow_page_code": "38600",
        "kiosk": false
    },
    {
        "value": "072307",
        "label": "Fruit & Produce Packers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511411",
        "yellow_page_code": "34703",
        "kiosk": false
    },
    {
        "value": "072308",
        "label": "Nuts-Edible-Processing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511419",
        "yellow_page_code": "56036",
        "kiosk": false
    },
    {
        "value": "072309",
        "label": "Cottonseed Delinting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511408",
        "yellow_page_code": "22104",
        "kiosk": false
    },
    {
        "value": "072310",
        "label": "Rice Dryers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511423",
        "yellow_page_code": "72107",
        "kiosk": false
    },
    {
        "value": "072311",
        "label": "Farm Produce",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511410",
        "yellow_page_code": "30811",
        "kiosk": false
    },
    {
        "value": "072312",
        "label": "Grain Fumigation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511416",
        "yellow_page_code": "38709",
        "kiosk": false
    },
    {
        "value": "072313",
        "label": "Bean Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511402",
        "yellow_page_code": "07111",
        "kiosk": false
    },
    {
        "value": "072315",
        "label": "Apple Packers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511401",
        "yellow_page_code": "02815",
        "kiosk": false
    },
    {
        "value": "072316",
        "label": "Cotton Linters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511407",
        "yellow_page_code": "22005",
        "kiosk": false
    },
    {
        "value": "072317",
        "label": "Fruit Drying",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511412",
        "yellow_page_code": "34705",
        "kiosk": false
    },
    {
        "value": "072318",
        "label": "Corn Shelling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511406",
        "yellow_page_code": "21636",
        "kiosk": false
    },
    {
        "value": "072320",
        "label": "Pea Processors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511420",
        "yellow_page_code": "60005",
        "kiosk": false
    },
    {
        "value": "072321",
        "label": "Peanut Shellers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511421",
        "yellow_page_code": "60007",
        "kiosk": false
    },
    {
        "value": "072324",
        "label": "Vegetables-Processed",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511425",
        "yellow_page_code": "90695",
        "kiosk": false
    },
    {
        "value": "072398",
        "label": "Crop Preparation Services For Market",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511409",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "072401",
        "label": "Cotton Gins",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511101",
        "yellow_page_code": "21909",
        "kiosk": false
    },
    {
        "value": "072402",
        "label": "Cotton Picking Machinery",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511102",
        "yellow_page_code": "22015",
        "kiosk": false
    },
    {
        "value": "074201",
        "label": "Veterinarians",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "54194009",
        "yellow_page_code": "90908, 90915, 90917, 90918, 60522, 90929, 90914, 90934, 90935, 24954, 60443",
        "kiosk": false
    },
    {
        "value": "074202",
        "label": "Veterinarian Emergency Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54194007",
        "yellow_page_code": "91001, 90933",
        "kiosk": false
    },
    {
        "value": "074203",
        "label": "Animal Hospitals",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54194002",
        "yellow_page_code": "02504, 91002, 60418",
        "kiosk": false
    },
    {
        "value": "074204",
        "label": "Veterinarian Referral/Information Svcs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54194008",
        "yellow_page_code": "91003, 90916",
        "kiosk": false
    },
    {
        "value": "074205",
        "label": "Bird Hospitals",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54194003",
        "yellow_page_code": "08301",
        "kiosk": false
    },
    {
        "value": "074206",
        "label": "Animal Ambulance Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54194001",
        "yellow_page_code": "02514, 02111",
        "kiosk": false
    },
    {
        "value": "074207",
        "label": "Veterinarians-Oncologist",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "54194006",
        "yellow_page_code": "90926",
        "kiosk": false
    },
    {
        "value": "074208",
        "label": "Veterinarians-Dermatologists",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "54194011",
        "yellow_page_code": "90909",
        "kiosk": false
    },
    {
        "value": "074209",
        "label": "Veterinarians-DVM Dentistry",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "54194010",
        "yellow_page_code": "90919, 29731, 41541",
        "kiosk": false
    },
    {
        "value": "074210",
        "label": "Veterinarians-Surgeons",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "54194013",
        "yellow_page_code": "90911, 90940",
        "kiosk": false
    },
    {
        "value": "074211",
        "label": "Veterinarians-Ophthalmologists",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "54194012",
        "yellow_page_code": "90912",
        "kiosk": false
    },
    {
        "value": "074212",
        "label": "Veterinarians-Laboratory Animal Med",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "54194016",
        "yellow_page_code": "90920",
        "kiosk": false
    },
    {
        "value": "074213",
        "label": "Veterinarians-Neurologists",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "54194017",
        "yellow_page_code": "90925",
        "kiosk": false
    },
    {
        "value": "074214",
        "label": "Veterinarians-Radiologists",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "54194018",
        "yellow_page_code": "90923",
        "kiosk": false
    },
    {
        "value": "074215",
        "label": "Veterinarians-Cardiologists",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "54194019",
        "yellow_page_code": "90924",
        "kiosk": false
    },
    {
        "value": "074218",
        "label": "Pet Care Resource Guide",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54194022",
        "yellow_page_code": "60412",
        "kiosk": false
    },
    {
        "value": "074219",
        "label": "Veterinarians-Microbiologists",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "54194023",
        "yellow_page_code": "90931",
        "kiosk": false
    },
    {
        "value": "074220",
        "label": "Veterinarians-Public Health",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "54194024",
        "yellow_page_code": "90936",
        "kiosk": false
    },
    {
        "value": "074221",
        "label": "Veterinarians-Pathologists",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "54194020",
        "yellow_page_code": "90941",
        "kiosk": false
    },
    {
        "value": "074222",
        "label": "Veterinarians-Toxicologists",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "54194025",
        "yellow_page_code": "90937",
        "kiosk": false
    },
    {
        "value": "074223",
        "label": "Veterinary Services ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54194026",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "075101",
        "label": "Cattle Insemination Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11521022",
        "yellow_page_code": "14122",
        "kiosk": false
    },
    {
        "value": "075102",
        "label": "Livestock Breeders",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11521010",
        "yellow_page_code": "10402, 14109, 48104",
        "kiosk": false
    },
    {
        "value": "075103",
        "label": "Insemination Service-Artificial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11521009",
        "yellow_page_code": "02506, 03804, 43206",
        "kiosk": false
    },
    {
        "value": "075105",
        "label": "Livestock Grooming",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11521011",
        "yellow_page_code": "48205, 41425, 30824",
        "kiosk": false
    },
    {
        "value": "075106",
        "label": "Cattle Branding",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11521002",
        "yellow_page_code": "14105",
        "kiosk": false
    },
    {
        "value": "075107",
        "label": "Sheep Shearing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11521016",
        "yellow_page_code": "76112",
        "kiosk": false
    },
    {
        "value": "075108",
        "label": "Livestock Transplants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11521014",
        "yellow_page_code": "48301",
        "kiosk": false
    },
    {
        "value": "075109",
        "label": "Dairy Relief Milking",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11521019",
        "yellow_page_code": "22325",
        "kiosk": false
    },
    {
        "value": "075110",
        "label": "Swine Insemination Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11521020",
        "yellow_page_code": "82914",
        "kiosk": false
    },
    {
        "value": "075111",
        "label": "Cattle Raising Equip & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11521023",
        "yellow_page_code": "14125",
        "kiosk": false
    },
    {
        "value": "075112",
        "label": "Corral Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11521026",
        "yellow_page_code": "21795",
        "kiosk": false
    },
    {
        "value": "075198",
        "label": "Livestock Services-Except Veterinary",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11521012",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "075201",
        "label": "Pet Training",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81291020",
        "yellow_page_code": "60506",
        "kiosk": false
    },
    {
        "value": "075202",
        "label": "Pet Cemeteries & Crematories",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81291013",
        "yellow_page_code": "14309, 60402, 02515, 60544",
        "kiosk": false
    },
    {
        "value": "075203",
        "label": "Animal Shelters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81291003",
        "yellow_page_code": "02508, 60523",
        "kiosk": false
    },
    {
        "value": "075204",
        "label": "Pet Washing & Grooming",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81291021",
        "yellow_page_code": "25402, 60600, 25315, 60426, 39129, 14124, 02422, 60439",
        "kiosk": false
    },
    {
        "value": "075206",
        "label": "Dog Training",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81291007",
        "yellow_page_code": "25403",
        "kiosk": false
    },
    {
        "value": "075207",
        "label": "Dog Breeders",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11521004",
        "yellow_page_code": "25401, 25312, 10412",
        "kiosk": false
    },
    {
        "value": "075208",
        "label": "Horse Breeders",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11521005",
        "yellow_page_code": "41408, 41520",
        "kiosk": false
    },
    {
        "value": "075209",
        "label": "Horse Training",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11521008",
        "yellow_page_code": "41506",
        "kiosk": false
    },
    {
        "value": "075210",
        "label": "Pet Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81291018",
        "yellow_page_code": "60409, 60528, 60428, 60526, 60419, 60435, 60432",
        "kiosk": false
    },
    {
        "value": "075211",
        "label": "Pet Boarding Sitting & Kennels",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81291028",
        "yellow_page_code": "60507, 25400, 44706, 25313, 02440, 25318, 08631, 14038, 60520, 44715, 60431, 14020, 25317, 08601, 02427, 08630, 60442",
        "kiosk": false
    },
    {
        "value": "075213",
        "label": "Animal Registration & Identification Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81291002",
        "yellow_page_code": "02409",
        "kiosk": false
    },
    {
        "value": "075214",
        "label": "Pet Identification Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81291016",
        "yellow_page_code": "60503",
        "kiosk": false
    },
    {
        "value": "075215",
        "label": "Pet Breeders",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11521015",
        "yellow_page_code": "60511",
        "kiosk": false
    },
    {
        "value": "075216",
        "label": "Animal Nutritionist Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81291001",
        "yellow_page_code": "02507",
        "kiosk": false
    },
    {
        "value": "075217",
        "label": "Livestock-Embryo Transfer",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81291011",
        "yellow_page_code": "48309",
        "kiosk": false
    },
    {
        "value": "075218",
        "label": "Pet Funeral Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81291015",
        "yellow_page_code": "60411",
        "kiosk": false
    },
    {
        "value": "075219",
        "label": "Animal Training",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11521007",
        "yellow_page_code": "02612",
        "kiosk": false
    },
    {
        "value": "075220",
        "label": "Animal Therapy-Alternative",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "81291019",
        "yellow_page_code": "60416, 90927, 02405, 90910, 02419, 02428, 60438, 15520",
        "kiosk": false
    },
    {
        "value": "075222",
        "label": "Horse Care",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11521006",
        "yellow_page_code": "41600",
        "kiosk": false
    },
    {
        "value": "075223",
        "label": "Cats-Products & Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81291004",
        "yellow_page_code": "14037",
        "kiosk": false
    },
    {
        "value": "075225",
        "label": "Equestrian Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81291008",
        "yellow_page_code": "29713, 29724, 29726",
        "kiosk": false
    },
    {
        "value": "075226",
        "label": "Pet Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81291014",
        "yellow_page_code": "60415",
        "kiosk": false
    },
    {
        "value": "075227",
        "label": "Livestock Care Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81291010",
        "yellow_page_code": "48316",
        "kiosk": false
    },
    {
        "value": "075228",
        "label": "Animal Shows & Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11521018",
        "yellow_page_code": "02519",
        "kiosk": false
    },
    {
        "value": "075230",
        "label": "Pet Finding Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81291023",
        "yellow_page_code": "60538",
        "kiosk": false
    },
    {
        "value": "075231",
        "label": "Veterinary Pharmacies & Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11521021",
        "yellow_page_code": "90922",
        "kiosk": false
    },
    {
        "value": "075232",
        "label": "Pooper Scooper Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81291012",
        "yellow_page_code": "67310",
        "kiosk": false
    },
    {
        "value": "075233",
        "label": "Pet Temporary & Permanent Placement",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81291006",
        "yellow_page_code": "60519",
        "kiosk": false
    },
    {
        "value": "075234",
        "label": "Horse Physical Therapy",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81291009",
        "yellow_page_code": "41523, 41539, 41024",
        "kiosk": false
    },
    {
        "value": "075235",
        "label": "Wedding Dove & Butterfly Releasing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81291017",
        "yellow_page_code": "25740, 92945",
        "kiosk": false
    },
    {
        "value": "075236",
        "label": "Animal Communicator",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81291022",
        "yellow_page_code": "02420",
        "kiosk": false
    },
    {
        "value": "075237",
        "label": "Pet Massage",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81291024",
        "yellow_page_code": "60541",
        "kiosk": false
    },
    {
        "value": "075238",
        "label": "Equine Therapy",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11521003",
        "yellow_page_code": "29722",
        "kiosk": false
    },
    {
        "value": "075239",
        "label": "Dog Catchers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81291025",
        "yellow_page_code": "25391",
        "kiosk": false
    },
    {
        "value": "075240",
        "label": "Cat Breeders",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11521024",
        "yellow_page_code": "14010",
        "kiosk": false
    },
    {
        "value": "075241",
        "label": "Pets Dental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81291026",
        "yellow_page_code": "60436",
        "kiosk": false
    },
    {
        "value": "075242",
        "label": "Animal Behavioral Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11521025",
        "yellow_page_code": "02421",
        "kiosk": false
    },
    {
        "value": "075243",
        "label": "Pet Spas",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81291027",
        "yellow_page_code": "60437",
        "kiosk": false
    },
    {
        "value": "076101",
        "label": "Crop Planting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511506",
        "yellow_page_code": "66008",
        "kiosk": false
    },
    {
        "value": "076102",
        "label": "Citrus Harvesting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511502",
        "yellow_page_code": "18104",
        "kiosk": false
    },
    {
        "value": "076103",
        "label": "Harvesting-Contract",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511505",
        "yellow_page_code": "40205",
        "kiosk": false
    },
    {
        "value": "076104",
        "label": "Agricultural Technicians",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511501",
        "yellow_page_code": "00812",
        "kiosk": false
    },
    {
        "value": "076105",
        "label": "Farm Work-Custom",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511504",
        "yellow_page_code": "30820",
        "kiosk": false
    },
    {
        "value": "076198",
        "label": "Farm Labor",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511503",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "076201",
        "label": "Citrus Grove Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511601",
        "yellow_page_code": "18101",
        "kiosk": false
    },
    {
        "value": "076202",
        "label": "Citrus Groves & Management",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511602",
        "yellow_page_code": "18102",
        "kiosk": false
    },
    {
        "value": "076203",
        "label": "Farming Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511606",
        "yellow_page_code": "30906, 00798",
        "kiosk": false
    },
    {
        "value": "076204",
        "label": "Farm Management Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511604",
        "yellow_page_code": "30900, 00823, 00824, 30815, 00825",
        "kiosk": false
    },
    {
        "value": "076205",
        "label": "Farm Drainage",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511603",
        "yellow_page_code": "30813",
        "kiosk": false
    },
    {
        "value": "076206",
        "label": "Farm Supervisors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511605",
        "yellow_page_code": "30911",
        "kiosk": false
    },
    {
        "value": "076207",
        "label": "Barn & Feedlot Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511607",
        "yellow_page_code": "06614",
        "kiosk": false
    },
    {
        "value": "076208",
        "label": "Silage Cutting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11511608",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "078101",
        "label": "Horticultural Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54132001",
        "yellow_page_code": "41606, 41605",
        "kiosk": false
    },
    {
        "value": "078102",
        "label": "Land Planning Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54132003",
        "yellow_page_code": "45805, 65996",
        "kiosk": false
    },
    {
        "value": "078103",
        "label": "Landscape Designers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54132004",
        "yellow_page_code": "45901, 45815, 45814, 36215, 30288, 43741, 45852",
        "kiosk": false
    },
    {
        "value": "078104",
        "label": "Lawn Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54132006",
        "yellow_page_code": "46303",
        "kiosk": false
    },
    {
        "value": "078105",
        "label": "Interior Landscaping",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54132002",
        "yellow_page_code": "43606",
        "kiosk": false
    },
    {
        "value": "078106",
        "label": "Landscape Foresters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54132005",
        "yellow_page_code": "45834",
        "kiosk": false
    },
    {
        "value": "078107",
        "label": "Park Planning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169001",
        "yellow_page_code": "59309, 71013",
        "kiosk": false
    },
    {
        "value": "078108",
        "label": "Tree Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54132007",
        "yellow_page_code": "88504",
        "kiosk": false
    },
    {
        "value": "078109",
        "label": "Sprinklers-Irrigation Designers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54132008",
        "yellow_page_code": "80211",
        "kiosk": false
    },
    {
        "value": "078110",
        "label": "Landscaping-Stone",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56173027",
        "yellow_page_code": "45842, 81619, 81618",
        "kiosk": false
    },
    {
        "value": "078111",
        "label": "Concrete Hardscaping",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54132014",
        "yellow_page_code": "20512, 20719, 40222",
        "kiosk": false
    },
    {
        "value": "078112",
        "label": "Landscape Architectural Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54132017",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "078201",
        "label": "Rototilling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56173017",
        "yellow_page_code": "72814, 36223",
        "kiosk": false
    },
    {
        "value": "078202",
        "label": "Rock Lawns & Rockscaping",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56173016",
        "yellow_page_code": "72503, 72312",
        "kiosk": false
    },
    {
        "value": "078203",
        "label": "Sod & Sodding Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56173019",
        "yellow_page_code": "79004, 79010, 41342, 43687",
        "kiosk": false
    },
    {
        "value": "078204",
        "label": "Landscape Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56173009",
        "yellow_page_code": "45900, 46212, 45839, 45838, 46310, 45841, 45850, 45831, 45846, 94602, 45820, 21082, 45851, 45847, 72418, 87703, 41382, 43740",
        "kiosk": false
    },
    {
        "value": "078205",
        "label": "Spraying-Horticultural",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56173020",
        "yellow_page_code": "80201",
        "kiosk": false
    },
    {
        "value": "078206",
        "label": "Lawn & Grounds Maintenance",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56173011",
        "yellow_page_code": "36305, 39202, 46304, 46320, 46311, 46326, 36221, 94711, 41348, 36231",
        "kiosk": false
    },
    {
        "value": "078207",
        "label": "Topsoil",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56173021",
        "yellow_page_code": "87304, 87300, 08412, 41447, 24857",
        "kiosk": false
    },
    {
        "value": "078208",
        "label": "Grass",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56173006",
        "yellow_page_code": "38806",
        "kiosk": false
    },
    {
        "value": "078209",
        "label": "Lawn Spraying",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56173012",
        "yellow_page_code": "46400, 45837",
        "kiosk": false
    },
    {
        "value": "078210",
        "label": "Mowing Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56173014",
        "yellow_page_code": "54503, 46329",
        "kiosk": false
    },
    {
        "value": "078211",
        "label": "Hydromulching",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56173007",
        "yellow_page_code": "42203",
        "kiosk": false
    },
    {
        "value": "078212",
        "label": "Seeding & Fertilizing Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56173018",
        "yellow_page_code": "75403, 79011, 31420, 46328",
        "kiosk": false
    },
    {
        "value": "078213",
        "label": "Hydroseeding",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56173008",
        "yellow_page_code": "42307",
        "kiosk": false
    },
    {
        "value": "078214",
        "label": "Waterfalls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56173026",
        "yellow_page_code": "92610, 92222",
        "kiosk": false
    },
    {
        "value": "078215",
        "label": "Aerators",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56173001",
        "yellow_page_code": "00714, 46314",
        "kiosk": false
    },
    {
        "value": "078216",
        "label": "Sports Field Products & Svcs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56173003",
        "yellow_page_code": "80033",
        "kiosk": false
    },
    {
        "value": "078217",
        "label": "Brush Removal Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56173004",
        "yellow_page_code": "11014",
        "kiosk": false
    },
    {
        "value": "078218",
        "label": "Cemetery Lot Care & Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56173005",
        "yellow_page_code": "14313, 14418, 38850",
        "kiosk": false
    },
    {
        "value": "078219",
        "label": "Weed Cutting Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56173025",
        "yellow_page_code": "93003",
        "kiosk": false
    },
    {
        "value": "078220",
        "label": "Maintenance Programs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56173013",
        "yellow_page_code": "49713",
        "kiosk": false
    },
    {
        "value": "078221",
        "label": "Tree Trimming",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56173028",
        "yellow_page_code": "88512",
        "kiosk": false
    },
    {
        "value": "078222",
        "label": "Lawn & Garden-Water Features-Svc-Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56173030",
        "yellow_page_code": "46321",
        "kiosk": false
    },
    {
        "value": "078301",
        "label": "Tree Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56173022",
        "yellow_page_code": "82108, 88506, 82103, 88523, 88533, 88517, 88526, 41392, 88535",
        "kiosk": false
    },
    {
        "value": "078302",
        "label": "Landscape Nurserymen",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56173010",
        "yellow_page_code": "45903",
        "kiosk": false
    },
    {
        "value": "078303",
        "label": "Tropical Foliage Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56173023",
        "yellow_page_code": "88611",
        "kiosk": false
    },
    {
        "value": "078304",
        "label": "Arborists",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56173002",
        "yellow_page_code": "03112, 03114",
        "kiosk": false
    },
    {
        "value": "078305",
        "label": "Tree Planting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56173024",
        "yellow_page_code": "88534",
        "kiosk": false
    },
    {
        "value": "078306",
        "label": "Right Of Way Maintenance Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56173029",
        "yellow_page_code": "72290",
        "kiosk": false
    },
    {
        "value": "078398",
        "label": "Ornamental Shrub & Tree Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56173015",
        "yellow_page_code": "58013",
        "kiosk": false
    },
    {
        "value": "081102",
        "label": "Tree Planting Kits",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11142105",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "081198",
        "label": "Timber Tracts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11311001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "083101",
        "label": "Maple Sugar & Syrup",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11199808",
        "yellow_page_code": "49905, 49938, 49953, 49965",
        "kiosk": false
    },
    {
        "value": "083102",
        "label": "Ginseng",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11321002",
        "yellow_page_code": "37400",
        "kiosk": false
    },
    {
        "value": "083198",
        "label": "Forest Nurseries & Gathering Forest Prod",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11321001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "085101",
        "label": "Fire Lookout Stations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11531002",
        "yellow_page_code": "32104",
        "kiosk": false
    },
    {
        "value": "085102",
        "label": "Foresters-Consulting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11531004",
        "yellow_page_code": "34104, 34117, 34099, 34097, 21062, 34128, 42141, 34094",
        "kiosk": false
    },
    {
        "value": "085103",
        "label": "Moss Control",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11531006",
        "yellow_page_code": "53704",
        "kiosk": false
    },
    {
        "value": "085104",
        "label": "Timber Cruisers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11531008",
        "yellow_page_code": "86105",
        "kiosk": false
    },
    {
        "value": "085105",
        "label": "Forest Restoration",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11531007",
        "yellow_page_code": "71209, 34105",
        "kiosk": false
    },
    {
        "value": "085106",
        "label": "Engineers-Forest",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11531001",
        "yellow_page_code": "28816",
        "kiosk": false
    },
    {
        "value": "085108",
        "label": "Ranger Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11531003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "085109",
        "label": "Forest Firefighting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11531009",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "091201",
        "label": "Fishermen-Commercial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11411102",
        "yellow_page_code": "32509, 32604",
        "kiosk": false
    },
    {
        "value": "091298",
        "label": "Finfish",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11411101",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "091301",
        "label": "Oysters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11411201",
        "yellow_page_code": "58300",
        "kiosk": false
    },
    {
        "value": "091302",
        "label": "Shrimp-Aquaculture",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11411203",
        "yellow_page_code": "77335",
        "kiosk": false
    },
    {
        "value": "091398",
        "label": "Shellfish",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11411202",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "091901",
        "label": "Irish Moss",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11251905",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "091902",
        "label": "Seaweed Products",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11251904",
        "yellow_page_code": "75117",
        "kiosk": false
    },
    {
        "value": "091903",
        "label": "Other Marine Fishing ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11411901",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "091998",
        "label": "Miscellaneous Marine Products",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11251903",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "092101",
        "label": "Fishery Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11251102",
        "yellow_page_code": "32602, 32405, 32636",
        "kiosk": false
    },
    {
        "value": "097101",
        "label": "Hunting & Fishing Preserves",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11421004",
        "yellow_page_code": "42106, 36020",
        "kiosk": false
    },
    {
        "value": "097102",
        "label": "Wildlife Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11421006",
        "yellow_page_code": "93511, 93513, 93520, 93525",
        "kiosk": false
    },
    {
        "value": "097103",
        "label": "Animal Rescue Relocation & Transporting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11421001",
        "yellow_page_code": "02412, 02436",
        "kiosk": false
    },
    {
        "value": "097104",
        "label": "Fishing Tournaments",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11421003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "097105",
        "label": "Fishing Lake Management",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11421002",
        "yellow_page_code": "32618, 67217",
        "kiosk": false
    },
    {
        "value": "097106",
        "label": "Wildlife Conservation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11421005",
        "yellow_page_code": "93512, 93518, 93524, 93527, 21077",
        "kiosk": false
    },
    {
        "value": "097107",
        "label": "Habitat Preservation Associations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11421007",
        "yellow_page_code": "39610",
        "kiosk": false
    },
    {
        "value": "097108",
        "label": "Pond Stocking",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11421008",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "097109",
        "label": "Hunting & Fishing Outfitters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11421009",
        "yellow_page_code": "42093",
        "kiosk": false
    },
    {
        "value": "101198",
        "label": "Iron-Ore",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21221001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "102198",
        "label": "Copper Ores",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21223002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "103198",
        "label": "Lead & Zinc Ores",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21223001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "104101",
        "label": "Gold Panning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21222002",
        "yellow_page_code": "38008",
        "kiosk": false
    },
    {
        "value": "104102",
        "label": "Prospectors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21222003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "104198",
        "label": "Gold Ores",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21222001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "104498",
        "label": "Silver Ores",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21222004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "106198",
        "label": "Ferroalloy Ores Except Vanadium",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21229002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "108101",
        "label": "Metal Mining Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21311401",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "108102",
        "label": "Mines-Exploration",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891034",
        "yellow_page_code": "52521",
        "kiosk": false
    },
    {
        "value": "109401",
        "label": "Uranium",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21229001",
        "yellow_page_code": "90107",
        "kiosk": false
    },
    {
        "value": "109901",
        "label": "Ore",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21229004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "109999",
        "label": "Miscellaneous Metal Ores NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21229003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "122101",
        "label": "Coal Washing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21211402",
        "yellow_page_code": "19001",
        "kiosk": false
    },
    {
        "value": "122102",
        "label": "Coal Mining & Shipping",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21211401",
        "yellow_page_code": "18909",
        "kiosk": false
    },
    {
        "value": "122298",
        "label": "Bituminous Coal-Underground Mining",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21211501",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "123198",
        "label": "Anthracite Mining",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21211403",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "124101",
        "label": "Auger Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891049",
        "yellow_page_code": "04423",
        "kiosk": false
    },
    {
        "value": "124198",
        "label": "Coal Mining Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21311301",
        "yellow_page_code": "18940",
        "kiosk": false
    },
    {
        "value": "131101",
        "label": "Oil & Gas Producers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21112002",
        "yellow_page_code": "56706",
        "kiosk": false
    },
    {
        "value": "131102",
        "label": "Gas Boosters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21112001",
        "yellow_page_code": "36503",
        "kiosk": false
    },
    {
        "value": "131103",
        "label": "Oil Companies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21112004",
        "yellow_page_code": "56505",
        "kiosk": false
    },
    {
        "value": "131104",
        "label": "Oil & Gas-Production",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21112003",
        "yellow_page_code": "56516",
        "kiosk": false
    },
    {
        "value": "131105",
        "label": "Oil Operators",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21112005",
        "yellow_page_code": "56705",
        "kiosk": false
    },
    {
        "value": "131106",
        "label": "Oil Crude",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21112006",
        "yellow_page_code": "56429",
        "kiosk": false
    },
    {
        "value": "131107",
        "label": "Petroleum Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21112007",
        "yellow_page_code": "60518",
        "kiosk": false
    },
    {
        "value": "131108",
        "label": "Petrochemical Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21112008",
        "yellow_page_code": "60534",
        "kiosk": false
    },
    {
        "value": "132198",
        "label": "Natural Gas Liquids",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21113001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "138101",
        "label": "Contractors-Oil Field",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21311101",
        "yellow_page_code": "21231, 56511, 56531, 56520, 86485",
        "kiosk": false
    },
    {
        "value": "138102",
        "label": "Oil Well Drilling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21311106",
        "yellow_page_code": "56908, 56905",
        "kiosk": false
    },
    {
        "value": "138103",
        "label": "Gas Well Drilling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21311104",
        "yellow_page_code": "36801",
        "kiosk": false
    },
    {
        "value": "138104",
        "label": "Oil Well Directional Drilling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21311105",
        "yellow_page_code": "56904, 24831, 56447",
        "kiosk": false
    },
    {
        "value": "138105",
        "label": "Drilling-Diamond Core",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21311102",
        "yellow_page_code": "26403",
        "kiosk": false
    },
    {
        "value": "138107",
        "label": "Drilling Companies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21311108",
        "yellow_page_code": "26397",
        "kiosk": false
    },
    {
        "value": "138198",
        "label": "Drilling-Oil & Gas Wells",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21311103",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "138201",
        "label": "Seismograph Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54136009",
        "yellow_page_code": "75408",
        "kiosk": false
    },
    {
        "value": "138202",
        "label": "Oil Reports",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21311208",
        "yellow_page_code": "56801",
        "kiosk": false
    },
    {
        "value": "138203",
        "label": "Oil & Gas Exploration & Development",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21311203",
        "yellow_page_code": "56404",
        "kiosk": false
    },
    {
        "value": "138204",
        "label": "Oil & Gas Consultant",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21311202",
        "yellow_page_code": "56412, 21080",
        "kiosk": false
    },
    {
        "value": "138205",
        "label": "Explorers-Natural Resources",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54136006",
        "yellow_page_code": "30203",
        "kiosk": false
    },
    {
        "value": "138206",
        "label": "Seismic Data Brokers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54136008",
        "yellow_page_code": "75409",
        "kiosk": false
    },
    {
        "value": "138207",
        "label": "Technical Consultants-Platform Drilling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21311214",
        "yellow_page_code": "84225",
        "kiosk": false
    },
    {
        "value": "138208",
        "label": "Oil & Gas Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21311109",
        "yellow_page_code": "56532",
        "kiosk": false
    },
    {
        "value": "138209",
        "label": "Exploration Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54136003",
        "yellow_page_code": "30111",
        "kiosk": false
    },
    {
        "value": "138210",
        "label": "Exploration-Drilling Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54136005",
        "yellow_page_code": "30112",
        "kiosk": false
    },
    {
        "value": "138211",
        "label": "Exploration Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54136004",
        "yellow_page_code": "30202",
        "kiosk": false
    },
    {
        "value": "138212",
        "label": "Geophysical Brokers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54136007",
        "yellow_page_code": "37212",
        "kiosk": false
    },
    {
        "value": "138213",
        "label": "Lubrication Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21311201",
        "yellow_page_code": "48717",
        "kiosk": false
    },
    {
        "value": "138901",
        "label": "Oil Field Equipment-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21311205",
        "yellow_page_code": "56508",
        "kiosk": false
    },
    {
        "value": "138902",
        "label": "Mud Jacking Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891021",
        "yellow_page_code": "54504",
        "kiosk": false
    },
    {
        "value": "138903",
        "label": "Oil Field Specialties",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891024",
        "yellow_page_code": "56603, 56914, 31525, 56459",
        "kiosk": false
    },
    {
        "value": "138904",
        "label": "Oil Inspectors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21311207",
        "yellow_page_code": "56607, 56462",
        "kiosk": false
    },
    {
        "value": "138905",
        "label": "Oil Field Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891023",
        "yellow_page_code": "56602, 56526, 56528, 56458, 56561, 56562, 86483",
        "kiosk": false
    },
    {
        "value": "138906",
        "label": "Oil Well Cementing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23712002",
        "yellow_page_code": "56902",
        "kiosk": false
    },
    {
        "value": "138907",
        "label": "Oil Well Casing-Pulling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23712001",
        "yellow_page_code": "56900, 93216",
        "kiosk": false
    },
    {
        "value": "138908",
        "label": "Oil Well Drilling Mud & Additives",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891026",
        "yellow_page_code": "57000",
        "kiosk": false
    },
    {
        "value": "138909",
        "label": "Oil Well Surveyors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891027",
        "yellow_page_code": "57103, 56415, 82522",
        "kiosk": false
    },
    {
        "value": "138910",
        "label": "Oil Well Logging & Perforating",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23712003",
        "yellow_page_code": "56909, 57102, 56480",
        "kiosk": false
    },
    {
        "value": "138911",
        "label": "Oil Well Testing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891028",
        "yellow_page_code": "57106",
        "kiosk": false
    },
    {
        "value": "138912",
        "label": "Oil Well Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21311209",
        "yellow_page_code": "57104, 56479",
        "kiosk": false
    },
    {
        "value": "138913",
        "label": "Pipe Inspection",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891030",
        "yellow_page_code": "65604",
        "kiosk": false
    },
    {
        "value": "138914",
        "label": "Riggers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23712004",
        "yellow_page_code": "72208",
        "kiosk": false
    },
    {
        "value": "138915",
        "label": "Hot Shot Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891020",
        "yellow_page_code": "41814",
        "kiosk": false
    },
    {
        "value": "138916",
        "label": "Wireline Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891032",
        "yellow_page_code": "94015",
        "kiosk": false
    },
    {
        "value": "138917",
        "label": "Oil Well Chemical Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891025",
        "yellow_page_code": "56901",
        "kiosk": false
    },
    {
        "value": "138918",
        "label": "Offshore Platforms/Oil",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21311210",
        "yellow_page_code": "56703, 56414",
        "kiosk": false
    },
    {
        "value": "138919",
        "label": "Roustabouts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891031",
        "yellow_page_code": "72900",
        "kiosk": false
    },
    {
        "value": "138920",
        "label": "Gas Well Servicing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891018",
        "yellow_page_code": "36700, 36822, 93213",
        "kiosk": false
    },
    {
        "value": "138921",
        "label": "Oil & Gas Well Plugging",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891019",
        "yellow_page_code": "36721, 56432",
        "kiosk": false
    },
    {
        "value": "138922",
        "label": "Oil Extraction-Downhole",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891022",
        "yellow_page_code": "56519",
        "kiosk": false
    },
    {
        "value": "138923",
        "label": "Oil Well Wire Line Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891029",
        "yellow_page_code": "57107",
        "kiosk": false
    },
    {
        "value": "138924",
        "label": "Oil Field Maintenance",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21311206",
        "yellow_page_code": "56615",
        "kiosk": false
    },
    {
        "value": "138925",
        "label": "Oil Field Compressor Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21311211",
        "yellow_page_code": "56710",
        "kiosk": false
    },
    {
        "value": "138926",
        "label": "Oil Field Solids Control",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21311212",
        "yellow_page_code": "56422",
        "kiosk": false
    },
    {
        "value": "138927",
        "label": "Well Maintenance Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21311213",
        "yellow_page_code": "93207",
        "kiosk": false
    },
    {
        "value": "138928",
        "label": "Oil & Gas Field Training",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21311216",
        "yellow_page_code": "56433, 56435",
        "kiosk": false
    },
    {
        "value": "138929",
        "label": "Casing Crews",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891062",
        "yellow_page_code": "13950",
        "kiosk": false
    },
    {
        "value": "138930",
        "label": "Oil Well Pumping Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23712012",
        "yellow_page_code": "56910",
        "kiosk": false
    },
    {
        "value": "138931",
        "label": "Oil Field Welding",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21311215",
        "yellow_page_code": "56522",
        "kiosk": false
    },
    {
        "value": "138932",
        "label": "Pipe Testing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21311217",
        "yellow_page_code": "65790",
        "kiosk": false
    },
    {
        "value": "138933",
        "label": "Oil Tanks Repairing & Removal",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23712013",
        "yellow_page_code": "56717",
        "kiosk": false
    },
    {
        "value": "138934",
        "label": "Oil & Gas Fleet Washing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21311218",
        "yellow_page_code": "86484, 86486",
        "kiosk": false
    },
    {
        "value": "138999",
        "label": "Oil & Gas-Field Services NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21311204",
        "yellow_page_code": "36513, 56439",
        "kiosk": false
    },
    {
        "value": "141101",
        "label": "Stone-Natural",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21231102",
        "yellow_page_code": "81700",
        "kiosk": false
    },
    {
        "value": "141198",
        "label": "Dimension Stone",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21231101",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "142201",
        "label": "Limestone",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21231203",
        "yellow_page_code": "47506",
        "kiosk": false
    },
    {
        "value": "142202",
        "label": "Dolomite",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21231202",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "142298",
        "label": "Crushed & Broken Limestone",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21231201",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "142301",
        "label": "Granite-Decomposed",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21231302",
        "yellow_page_code": "38800",
        "kiosk": false
    },
    {
        "value": "142302",
        "label": "Granite Cultured",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21231303",
        "yellow_page_code": "38824",
        "kiosk": false
    },
    {
        "value": "142398",
        "label": "Crushed & Broken Granite",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21231301",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "142901",
        "label": "Stone-Crushed",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21231907",
        "yellow_page_code": "81608, 72414",
        "kiosk": false
    },
    {
        "value": "142902",
        "label": "Rock Splitting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21231903",
        "yellow_page_code": "72520",
        "kiosk": false
    },
    {
        "value": "142999",
        "label": "Crushed & Broken Stone NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21231902",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "144201",
        "label": "Quarries",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21232102",
        "yellow_page_code": "69400",
        "kiosk": false
    },
    {
        "value": "144202",
        "label": "Construction-Sand & Gravel",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21232101",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "144203",
        "label": "Sand & Gravel-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21232105",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "144204",
        "label": "Gravel Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21232104",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "144601",
        "label": "Silica",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21232202",
        "yellow_page_code": "77603",
        "kiosk": false
    },
    {
        "value": "144698",
        "label": "Industrial Sand",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21232201",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "145598",
        "label": "Kaolin & Ball Clay",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21232301",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "145901",
        "label": "Clay",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21232303",
        "yellow_page_code": "18208",
        "kiosk": false
    },
    {
        "value": "145902",
        "label": "Bentonite",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21232302",
        "yellow_page_code": "07709",
        "kiosk": false
    },
    {
        "value": "145999",
        "label": "Clay Ceramic & Refractory Minerals NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21232304",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "147498",
        "label": "Potash Soda & Borate Minerals",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21239001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "147501",
        "label": "Phosphate Rock",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21239002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "147901",
        "label": "Sulphur",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21239007",
        "yellow_page_code": "82209",
        "kiosk": false
    },
    {
        "value": "147902",
        "label": "Fluorspar",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21239004",
        "yellow_page_code": "32603",
        "kiosk": false
    },
    {
        "value": "147903",
        "label": "Salt Miners",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21239005",
        "yellow_page_code": "73601",
        "kiosk": false
    },
    {
        "value": "147904",
        "label": "Salt Processing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21239006",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "147999",
        "label": "Chemical & Fertilizer Mining NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21239003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "148198",
        "label": "Nonmetallic Minerals Svc-Except Fuels",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21311501",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "149901",
        "label": "Mining Companies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21239010",
        "yellow_page_code": "52602",
        "kiosk": false
    },
    {
        "value": "149902",
        "label": "Mineral Exploration",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21239009",
        "yellow_page_code": "52506",
        "kiosk": false
    },
    {
        "value": "149903",
        "label": "Mining Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21239011",
        "yellow_page_code": "52601, 52523",
        "kiosk": false
    },
    {
        "value": "149905",
        "label": "Diamond Drilling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21239008",
        "yellow_page_code": "24433",
        "kiosk": false
    },
    {
        "value": "149906",
        "label": "Abrasive Materials",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21239015",
        "yellow_page_code": "00041",
        "kiosk": false
    },
    {
        "value": "149907",
        "label": "Gems & Minerals",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21239013",
        "yellow_page_code": "37007",
        "kiosk": false
    },
    {
        "value": "149908",
        "label": "Mines",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21239014",
        "yellow_page_code": "52514, 36997, 73710",
        "kiosk": false
    },
    {
        "value": "149998",
        "label": "Miscellaneous Nonmetallic Minerals",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21239012",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "152101",
        "label": "Patio & Deck Builders",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611821",
        "yellow_page_code": "59708, 59710, 41385, 41346",
        "kiosk": false
    },
    {
        "value": "152102",
        "label": "Garage Builders",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611843",
        "yellow_page_code": "36102, 36096",
        "kiosk": false
    },
    {
        "value": "152103",
        "label": "General Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611505",
        "yellow_page_code": "21013, 21206, 37004, 21229, 21319, 11218, 20937, 02156",
        "kiosk": false
    },
    {
        "value": "152104",
        "label": "Carports",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611805",
        "yellow_page_code": "13902",
        "kiosk": false
    },
    {
        "value": "152105",
        "label": "Home Improvements",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611816",
        "yellow_page_code": "41302, 41242, 41225, 22421, 22241, 41271, 41367, 70628, 68638, 21932",
        "kiosk": false
    },
    {
        "value": "152106",
        "label": "Designers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611808",
        "yellow_page_code": "24208",
        "kiosk": false
    },
    {
        "value": "152107",
        "label": "Patio Porch & Deck Enclosures",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611822",
        "yellow_page_code": "59801, 67400, 59723",
        "kiosk": false
    },
    {
        "value": "152108",
        "label": "Water Damage Restoration",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611831",
        "yellow_page_code": "92201, 92124",
        "kiosk": false
    },
    {
        "value": "152109",
        "label": "Residential Designers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611824",
        "yellow_page_code": "24305, 71807",
        "kiosk": false
    },
    {
        "value": "152110",
        "label": "Solariums Sunrooms & Atriums",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611828",
        "yellow_page_code": "82260, 79306, 20950",
        "kiosk": false
    },
    {
        "value": "152111",
        "label": "Screen Enclosures",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611825",
        "yellow_page_code": "74804",
        "kiosk": false
    },
    {
        "value": "152112",
        "label": "Home Builders",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611506",
        "yellow_page_code": "11009, 41204, 41230, 22910, 41933, 55122, 41278, 00314, 41335, 41485, 41429",
        "kiosk": false
    },
    {
        "value": "152113",
        "label": "Bathroom Remodeling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611803",
        "yellow_page_code": "06927, 06908, 41282, 06930",
        "kiosk": false
    },
    {
        "value": "152114",
        "label": "Fire Damage Restoration",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611813",
        "yellow_page_code": "31911, 33110, 31903, 32119, 33109, 43459, 41363, 92224, 78522",
        "kiosk": false
    },
    {
        "value": "152115",
        "label": "Handyman Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611815",
        "yellow_page_code": "40101, 40105, 49719, 41947, 41291",
        "kiosk": false
    },
    {
        "value": "152116",
        "label": "Mobile Homes-Improvements",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611818",
        "yellow_page_code": "53007",
        "kiosk": false
    },
    {
        "value": "152117",
        "label": "Kitchen Remodeling & Planning Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611817",
        "yellow_page_code": "41294, 44909, 44931, 44920, 44904, 41370",
        "kiosk": false
    },
    {
        "value": "152118",
        "label": "Mobile Homes Repairing & Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611819",
        "yellow_page_code": "49940, 53322, 53202, 53023, 49925",
        "kiosk": false
    },
    {
        "value": "152119",
        "label": "Spa Builders",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611829",
        "yellow_page_code": "79703",
        "kiosk": false
    },
    {
        "value": "152120",
        "label": "Closets-Designing & Remodeling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611806",
        "yellow_page_code": "18698, 18619, 18618, 41389, 43784",
        "kiosk": false
    },
    {
        "value": "152121",
        "label": "Florida Rooms",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611814",
        "yellow_page_code": "33512",
        "kiosk": false
    },
    {
        "value": "152122",
        "label": "Sun Rooms Solariums Atriums Builders",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611830",
        "yellow_page_code": "82302",
        "kiosk": false
    },
    {
        "value": "152123",
        "label": "Cabinets-Resurfacing & Refinishing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611804",
        "yellow_page_code": "12103, 44905, 44921, 12152, 06929",
        "kiosk": false
    },
    {
        "value": "152124",
        "label": "Attic & Basement Finishing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611801",
        "yellow_page_code": "04202, 06896, 48529, 14271",
        "kiosk": false
    },
    {
        "value": "152125",
        "label": "Dormer Construction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611810",
        "yellow_page_code": "25713",
        "kiosk": false
    },
    {
        "value": "152126",
        "label": "Gazebo Builders",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611844",
        "yellow_page_code": "36909",
        "kiosk": false
    },
    {
        "value": "152127",
        "label": "Storm Shelter Contractors & Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611845",
        "yellow_page_code": "81861",
        "kiosk": false
    },
    {
        "value": "152128",
        "label": "Basement-Repair & Restoration",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611802",
        "yellow_page_code": "06903, 06936, 06715, 41395",
        "kiosk": false
    },
    {
        "value": "152129",
        "label": "Disability Accessibility Renovations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611809",
        "yellow_page_code": "24818, 24901, 40010, 41265, 24861, 06928, 40098",
        "kiosk": false
    },
    {
        "value": "152130",
        "label": "Construction-Building Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611502",
        "yellow_page_code": "21021, 21135, 22916, 41458",
        "kiosk": false
    },
    {
        "value": "152131",
        "label": "Solar Rooms & Additions",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611827",
        "yellow_page_code": "79205",
        "kiosk": false
    },
    {
        "value": "152132",
        "label": "Dry Rot-Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611811",
        "yellow_page_code": "26703, 23326, 23327, 26712",
        "kiosk": false
    },
    {
        "value": "152133",
        "label": "Timber Frame Homes",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611508",
        "yellow_page_code": "86112",
        "kiosk": false
    },
    {
        "value": "152134",
        "label": "Enclosures",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611812",
        "yellow_page_code": "28611",
        "kiosk": false
    },
    {
        "value": "152135",
        "label": "Mobile Offices & Coml Units-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611820",
        "yellow_page_code": "53212",
        "kiosk": false
    },
    {
        "value": "152136",
        "label": "Construction Information Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611501",
        "yellow_page_code": "21015",
        "kiosk": false
    },
    {
        "value": "152137",
        "label": "Emergency Property Restoration",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611847",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "152138",
        "label": "Deck Builders",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611807",
        "yellow_page_code": "23701, 06101, 82206, 23714, 23620, 85218",
        "kiosk": false
    },
    {
        "value": "152139",
        "label": "Remodeling & Repairing Building Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611823",
        "yellow_page_code": "01909, 71606, 41462, 21208, 71636, 21119, 71610, 06895, 30321, 41252, 41372, 41388, 43758, 85029",
        "kiosk": false
    },
    {
        "value": "152140",
        "label": "Chinking",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611509",
        "yellow_page_code": "48621, 15403",
        "kiosk": false
    },
    {
        "value": "152142",
        "label": "Courts Construction-Indoor & Outdoor",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611514",
        "yellow_page_code": "22141, 91255, 80053",
        "kiosk": false
    },
    {
        "value": "152143",
        "label": "Earth Home Construction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611511",
        "yellow_page_code": "27110",
        "kiosk": false
    },
    {
        "value": "152144",
        "label": "Construction Companies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611512",
        "yellow_page_code": "21028",
        "kiosk": false
    },
    {
        "value": "152145",
        "label": "Window Capping",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611832",
        "yellow_page_code": "93523",
        "kiosk": false
    },
    {
        "value": "152146",
        "label": "Termite Damage Restoration",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611833",
        "yellow_page_code": "85110",
        "kiosk": false
    },
    {
        "value": "152147",
        "label": "Laser Construction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611513",
        "yellow_page_code": "46031",
        "kiosk": false
    },
    {
        "value": "152148",
        "label": "Office Renovation Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611846",
        "yellow_page_code": "56312",
        "kiosk": false
    },
    {
        "value": "152149",
        "label": "Home Improvement Resource Guide",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611837",
        "yellow_page_code": "41227",
        "kiosk": false
    },
    {
        "value": "152150",
        "label": "Trusses-Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611838",
        "yellow_page_code": "89212",
        "kiosk": false
    },
    {
        "value": "152151",
        "label": "Bathroom Design & Installation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611839",
        "yellow_page_code": "06922, 06923",
        "kiosk": false
    },
    {
        "value": "152152",
        "label": "Home Building Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611516",
        "yellow_page_code": "41277",
        "kiosk": false
    },
    {
        "value": "152153",
        "label": "Contractors-Design & Development",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611840",
        "yellow_page_code": "21224",
        "kiosk": false
    },
    {
        "value": "152154",
        "label": "Home Builders-Modular",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611517",
        "yellow_page_code": "49952, 49926, 41234",
        "kiosk": false
    },
    {
        "value": "152155",
        "label": "Outdoor Rooms & Living",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611841",
        "yellow_page_code": "58126",
        "kiosk": false
    },
    {
        "value": "152156",
        "label": "Sauna Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611842",
        "yellow_page_code": "74005, 74012",
        "kiosk": false
    },
    {
        "value": "152157",
        "label": "Interior Furnishings Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611835",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "152203",
        "label": "Partitions-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622028",
        "yellow_page_code": "59516",
        "kiosk": false
    },
    {
        "value": "152204",
        "label": "Contractors-Boarding & Housing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611601",
        "yellow_page_code": "21234, 08619",
        "kiosk": false
    },
    {
        "value": "152205",
        "label": "Builders Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622004",
        "yellow_page_code": "11103",
        "kiosk": false
    },
    {
        "value": "152207",
        "label": "Interior Designers-Contract",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54141007",
        "yellow_page_code": "43617",
        "kiosk": false
    },
    {
        "value": "152208",
        "label": "Hotel Renovation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622046",
        "yellow_page_code": "41827",
        "kiosk": false
    },
    {
        "value": "152299",
        "label": "General Contractors-Residential Bldgs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611836",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "153101",
        "label": "Contractors-Panelized Homes",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611702",
        "yellow_page_code": "21132",
        "kiosk": false
    },
    {
        "value": "153198",
        "label": "Operative Builders",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23611701",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "154101",
        "label": "Building Preservation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622006",
        "yellow_page_code": "11305, 41013, 41020, 03121, 71950, 21240, 73616, 72001, 68040, 15726, 48590, 30305",
        "kiosk": false
    },
    {
        "value": "154102",
        "label": "Designers-Industrial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23621002",
        "yellow_page_code": "24302, 24299, 42944",
        "kiosk": false
    },
    {
        "value": "154103",
        "label": "Clean Rooms-Installation & Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622014",
        "yellow_page_code": "18302, 18305",
        "kiosk": false
    },
    {
        "value": "154104",
        "label": "Grain Elevators-Builders",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622038",
        "yellow_page_code": "38608, 38717",
        "kiosk": false
    },
    {
        "value": "154105",
        "label": "Floors-Raised",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622037",
        "yellow_page_code": "33500",
        "kiosk": false
    },
    {
        "value": "154106",
        "label": "General Contractors-Industrial Buildings & Warehouses",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23621003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "154107",
        "label": "Contractors-Industrial & Commercial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23621001",
        "yellow_page_code": "21220, 42910, 42925, 21182, 19926, 19972, 21185, 19945, 37027",
        "kiosk": false
    },
    {
        "value": "154108",
        "label": "Grain Elevators-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622039",
        "yellow_page_code": "38701",
        "kiosk": false
    },
    {
        "value": "154109",
        "label": "Building Systems & Components",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622036",
        "yellow_page_code": "11303",
        "kiosk": false
    },
    {
        "value": "154110",
        "label": "Sewage Treatment Plant Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23621004",
        "yellow_page_code": "75713",
        "kiosk": false
    },
    {
        "value": "154201",
        "label": "Parking Stations & Garages Construction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622027",
        "yellow_page_code": "59407, 59420, 59410",
        "kiosk": false
    },
    {
        "value": "154202",
        "label": "Restaurant Design & Planning Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622029",
        "yellow_page_code": "71905",
        "kiosk": false
    },
    {
        "value": "154203",
        "label": "Store Fronts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622034",
        "yellow_page_code": "81900, 37612",
        "kiosk": false
    },
    {
        "value": "154204",
        "label": "Silos",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622032",
        "yellow_page_code": "77702",
        "kiosk": false
    },
    {
        "value": "154205",
        "label": "Grain Bins",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622021",
        "yellow_page_code": "08204, 38506, 31114",
        "kiosk": false
    },
    {
        "value": "154206",
        "label": "Gate & Fence Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622019",
        "yellow_page_code": "31308, 36898",
        "kiosk": false
    },
    {
        "value": "154207",
        "label": "Greenhouse Builders",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622022",
        "yellow_page_code": "38902",
        "kiosk": false
    },
    {
        "value": "154208",
        "label": "Basketball Court Construction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622003",
        "yellow_page_code": "06814",
        "kiosk": false
    },
    {
        "value": "154209",
        "label": "Dome Structures",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622017",
        "yellow_page_code": "25504, 25515",
        "kiosk": false
    },
    {
        "value": "154210",
        "label": "Buildings-Metal",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622008",
        "yellow_page_code": "11308, 80916, 51418, 80940, 21221, 43647, 20934, 37026, 11107",
        "kiosk": false
    },
    {
        "value": "154211",
        "label": "Airport Designers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622001",
        "yellow_page_code": "01706",
        "kiosk": false
    },
    {
        "value": "154212",
        "label": "Buildings-Pre-Cut Prefabricated/Modular",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622012",
        "yellow_page_code": "11404, 53329, 49877",
        "kiosk": false
    },
    {
        "value": "154213",
        "label": "Building Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622005",
        "yellow_page_code": "11202, 21109, 11201, 21128, 11114, 24951, 41361, 11130, 24958, 28920, 41437",
        "kiosk": false
    },
    {
        "value": "154214",
        "label": "Buildings-Pole",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622010",
        "yellow_page_code": "11400, 66995, 66996",
        "kiosk": false
    },
    {
        "value": "154215",
        "label": "Buildings-Portable",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622011",
        "yellow_page_code": "11402, 67409, 36131, 11127, 30055, 56550, 41492",
        "kiosk": false
    },
    {
        "value": "154216",
        "label": "Service Station Builders",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622030",
        "yellow_page_code": "75509, 75615",
        "kiosk": false
    },
    {
        "value": "154217",
        "label": "Motion Picture Set Design & Construction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622025",
        "yellow_page_code": "54107, 54010",
        "kiosk": false
    },
    {
        "value": "154218",
        "label": "Sheds-Tool & Utility",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622031",
        "yellow_page_code": "76109, 81711, 81714, 81721, 11314, 36218, 36220, 81713",
        "kiosk": false
    },
    {
        "value": "154219",
        "label": "Barns",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622002",
        "yellow_page_code": "06609, 06613, 06610, 02175",
        "kiosk": false
    },
    {
        "value": "154220",
        "label": "Contractors-Specialty",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622016",
        "yellow_page_code": "47513, 21219, 79821, 41454",
        "kiosk": false
    },
    {
        "value": "154221",
        "label": "Farm Buildings",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622018",
        "yellow_page_code": "30802, 11421",
        "kiosk": false
    },
    {
        "value": "154222",
        "label": "Construction Site Clean-Up Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622015",
        "yellow_page_code": "21016, 20403, 21065, 21317, 77918",
        "kiosk": false
    },
    {
        "value": "154223",
        "label": "Buildings-Metal-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622009",
        "yellow_page_code": "11301",
        "kiosk": false
    },
    {
        "value": "154224",
        "label": "Structures Air Supported",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622007",
        "yellow_page_code": "11401, 82018",
        "kiosk": false
    },
    {
        "value": "154225",
        "label": "Church Builders",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622013",
        "yellow_page_code": "15720",
        "kiosk": false
    },
    {
        "value": "154227",
        "label": "Maintenance Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622047",
        "yellow_page_code": "49720, 21140",
        "kiosk": false
    },
    {
        "value": "154228",
        "label": "Horse Farm-Building & Construction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622023",
        "yellow_page_code": "41501",
        "kiosk": false
    },
    {
        "value": "154229",
        "label": "Office Remodeling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622026",
        "yellow_page_code": "56314",
        "kiosk": false
    },
    {
        "value": "154230",
        "label": "Jails-Builders",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622035",
        "yellow_page_code": "44101",
        "kiosk": false
    },
    {
        "value": "154231",
        "label": "Underground Buildings",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622044",
        "yellow_page_code": "89910",
        "kiosk": false
    },
    {
        "value": "154232",
        "label": "Grain Bin Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622045",
        "yellow_page_code": "38514",
        "kiosk": false
    },
    {
        "value": "154233",
        "label": "Feed Lot Construction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622024",
        "yellow_page_code": "31207",
        "kiosk": false
    },
    {
        "value": "154234",
        "label": "Agricultural Construction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622048",
        "yellow_page_code": "00793",
        "kiosk": false
    },
    {
        "value": "154235",
        "label": "Buildings-Factory",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622049",
        "yellow_page_code": "11317",
        "kiosk": false
    },
    {
        "value": "154236",
        "label": "Contractors-Green Building",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622050",
        "yellow_page_code": "21249, 38920, 41355",
        "kiosk": false
    },
    {
        "value": "154299",
        "label": "General Contractors-Nonresidential Buildings",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622020",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "161101",
        "label": "Paving Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23731010",
        "yellow_page_code": "21209, 59808, 59422, 08519, 50416, 41350, 21190, 37032",
        "kiosk": false
    },
    {
        "value": "161102",
        "label": "Road Building Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23731012",
        "yellow_page_code": "21228, 72402, 20936, 21245, 41036",
        "kiosk": false
    },
    {
        "value": "161103",
        "label": "Grading Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23731004",
        "yellow_page_code": "21217, 38504, 72310, 71512",
        "kiosk": false
    },
    {
        "value": "161104",
        "label": "Parking Area/Lots Maintenance & Marking",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23731007",
        "yellow_page_code": "59400, 45214",
        "kiosk": false
    },
    {
        "value": "161105",
        "label": "Sinkhole Consultants & Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23731022",
        "yellow_page_code": "77815",
        "kiosk": false
    },
    {
        "value": "161106",
        "label": "Asphalt & Asphalt Products",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23731001",
        "yellow_page_code": "04002, 04005, 04006, 75103, 75115, 04010, 75124, 50417, 75122, 41281",
        "kiosk": false
    },
    {
        "value": "161107",
        "label": "Pavement Marking",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23731008",
        "yellow_page_code": "59806, 59812, 59813, 59837, 71730, 59412, 82023",
        "kiosk": false
    },
    {
        "value": "161108",
        "label": "Paving Materials",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23731011",
        "yellow_page_code": "59902, 59814",
        "kiosk": false
    },
    {
        "value": "161109",
        "label": "Road Oiling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23731014",
        "yellow_page_code": "72403, 72406",
        "kiosk": false
    },
    {
        "value": "161110",
        "label": "Pavement Sealing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23731009",
        "yellow_page_code": "59811",
        "kiosk": false
    },
    {
        "value": "161111",
        "label": "Driveways-Sealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23731003",
        "yellow_page_code": "26409",
        "kiosk": false
    },
    {
        "value": "161112",
        "label": "Oils-Asphalt & Road",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23731005",
        "yellow_page_code": "57202",
        "kiosk": false
    },
    {
        "value": "161113",
        "label": "Oils-Road",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23731006",
        "yellow_page_code": "57404",
        "kiosk": false
    },
    {
        "value": "161114",
        "label": "Contractors-Parking/Highway Improvement",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23731002",
        "yellow_page_code": "21225",
        "kiosk": false
    },
    {
        "value": "161115",
        "label": "Road Maintenance",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23731013",
        "yellow_page_code": "72309, 56470",
        "kiosk": false
    },
    {
        "value": "161116",
        "label": "Traffic & Zone Marking Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23731015",
        "yellow_page_code": "87813",
        "kiosk": false
    },
    {
        "value": "161117",
        "label": "Concrete Resurfacing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23731016",
        "yellow_page_code": "20614, 72029, 71731",
        "kiosk": false
    },
    {
        "value": "161118",
        "label": "Board Roads",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23731023",
        "yellow_page_code": "08910",
        "kiosk": false
    },
    {
        "value": "162201",
        "label": "Culverts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23799001",
        "yellow_page_code": "22808",
        "kiosk": false
    },
    {
        "value": "162202",
        "label": "Tunneling Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23799002",
        "yellow_page_code": "89404",
        "kiosk": false
    },
    {
        "value": "162203",
        "label": "Bridge Builders",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23731017",
        "yellow_page_code": "10706, 21126",
        "kiosk": false
    },
    {
        "value": "162204",
        "label": "Road Boring",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23731019",
        "yellow_page_code": "72401",
        "kiosk": false
    },
    {
        "value": "162205",
        "label": "Heavy & Civil Engineering Construction ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23799031",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "162298",
        "label": "Bridge Tunnel & Elevated Highway",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23731018",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "162301",
        "label": "Pipe Line Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23712007",
        "yellow_page_code": "21226, 65608, 65602, 56466, 65610",
        "kiosk": false
    },
    {
        "value": "162302",
        "label": "Piping Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23712008",
        "yellow_page_code": "65900, 65412, 36517",
        "kiosk": false
    },
    {
        "value": "162303",
        "label": "Gas Line Installation & Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23712005",
        "yellow_page_code": "36607, 36501",
        "kiosk": false
    },
    {
        "value": "162304",
        "label": "Water Main Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23711014",
        "yellow_page_code": "92304",
        "kiosk": false
    },
    {
        "value": "162305",
        "label": "Pole Line Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23713007",
        "yellow_page_code": "21113, 67006, 67922, 67927",
        "kiosk": false
    },
    {
        "value": "162306",
        "label": "Telephone & Television Cable Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23713009",
        "yellow_page_code": "84303, 84801, 84221, 84622, 30298",
        "kiosk": false
    },
    {
        "value": "162307",
        "label": "Sewer Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23711012",
        "yellow_page_code": "75802, 25814, 21330, 75712, 75823, 75825, 30066, 81860",
        "kiosk": false
    },
    {
        "value": "162308",
        "label": "Utility Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23713014",
        "yellow_page_code": "90204, 67966, 30328, 37023, 90189",
        "kiosk": false
    },
    {
        "value": "162309",
        "label": "Fiber Optics",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23713006",
        "yellow_page_code": "31600, 31521",
        "kiosk": false
    },
    {
        "value": "162310",
        "label": "Underground Wire & Cable Laying Contrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23713013",
        "yellow_page_code": "89809, 21256",
        "kiosk": false
    },
    {
        "value": "162311",
        "label": "Conduit Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23713004",
        "yellow_page_code": "20901",
        "kiosk": false
    },
    {
        "value": "162312",
        "label": "Telecommunications Wiring & Cabling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23713010",
        "yellow_page_code": "84504, 84318, 84321, 84249, 31026, 19990, 84333",
        "kiosk": false
    },
    {
        "value": "162313",
        "label": "Cable Detection Installation & Splicing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23713002",
        "yellow_page_code": "12218",
        "kiosk": false
    },
    {
        "value": "162314",
        "label": "Cable Conduit & Pole-Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23713001",
        "yellow_page_code": "12111",
        "kiosk": false
    },
    {
        "value": "162315",
        "label": "Cable Television Systems-Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23713003",
        "yellow_page_code": "12203",
        "kiosk": false
    },
    {
        "value": "162316",
        "label": "Wiring & Cabling-Home & Office-Voice",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23713015",
        "yellow_page_code": "94016",
        "kiosk": false
    },
    {
        "value": "162317",
        "label": "Towers-Radio",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23713011",
        "yellow_page_code": "87436, 84490",
        "kiosk": false
    },
    {
        "value": "162318",
        "label": "Towers-Communication/Municipal Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23713012",
        "yellow_page_code": "87501, 87412",
        "kiosk": false
    },
    {
        "value": "162319",
        "label": "Gasoline Svc Station Equip-Installing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23712006",
        "yellow_page_code": "36812",
        "kiosk": false
    },
    {
        "value": "162320",
        "label": "Sewer Tappers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23711013",
        "yellow_page_code": "75806",
        "kiosk": false
    },
    {
        "value": "162321",
        "label": "Contractors-Post Tensioning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23713005",
        "yellow_page_code": "21328",
        "kiosk": false
    },
    {
        "value": "162322",
        "label": "Power & Telephone Line Constr/Mntnc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23713008",
        "yellow_page_code": "67915",
        "kiosk": false
    },
    {
        "value": "162323",
        "label": "Sewer Construction Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23711011",
        "yellow_page_code": "75814",
        "kiosk": false
    },
    {
        "value": "162324",
        "label": "Construction-Telecommunications",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23713016",
        "yellow_page_code": "21064",
        "kiosk": false
    },
    {
        "value": "162325",
        "label": "Corrosion Control",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23712011",
        "yellow_page_code": "21802, 56445",
        "kiosk": false
    },
    {
        "value": "162326",
        "label": "Water Lines Installation Replacement-Rpr",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23711026",
        "yellow_page_code": "92040",
        "kiosk": false
    },
    {
        "value": "162327",
        "label": "Pole Inspectors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23713020",
        "yellow_page_code": "67002",
        "kiosk": false
    },
    {
        "value": "162330",
        "label": "Water Towers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23711028",
        "yellow_page_code": "92030",
        "kiosk": false
    },
    {
        "value": "162331",
        "label": "Propane Supply Lines Installation & Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23711029",
        "yellow_page_code": "68605, 36734",
        "kiosk": false
    },
    {
        "value": "162398",
        "label": "Water-Sewer Pipeline & Comms/Power Line",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23711015",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "162901",
        "label": "Pile Driving",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23799023",
        "yellow_page_code": "21211, 21222, 65204, 65209",
        "kiosk": false
    },
    {
        "value": "162902",
        "label": "Docks (Builders)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23799021",
        "yellow_page_code": "25306, 65200, 25237, 25240, 33017, 25231, 25324, 08730, 25234, 25238, 25232, 33013",
        "kiosk": false
    },
    {
        "value": "162903",
        "label": "Marine Contractors & Designers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891041",
        "yellow_page_code": "21205, 50008, 30248, 50052",
        "kiosk": false
    },
    {
        "value": "162904",
        "label": "Drainage Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23711018",
        "yellow_page_code": "21213, 25808, 86011, 45822, 45818, 86015",
        "kiosk": false
    },
    {
        "value": "162905",
        "label": "Fill Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891039",
        "yellow_page_code": "21230, 31606, 27083",
        "kiosk": false
    },
    {
        "value": "162906",
        "label": "Drain Laying",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891038",
        "yellow_page_code": "25901",
        "kiosk": false
    },
    {
        "value": "162907",
        "label": "Tennis Court Construction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23799009",
        "yellow_page_code": "85004",
        "kiosk": false
    },
    {
        "value": "162908",
        "label": "Railroad Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23799007",
        "yellow_page_code": "70202, 70225",
        "kiosk": false
    },
    {
        "value": "162909",
        "label": "Trenching & Underground Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23711017",
        "yellow_page_code": "05608, 88509, 88521, 21247, 72505, 05607",
        "kiosk": false
    },
    {
        "value": "162910",
        "label": "Water Pollution Control",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23711022",
        "yellow_page_code": "92306",
        "kiosk": false
    },
    {
        "value": "162911",
        "label": "Water Works Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23713018",
        "yellow_page_code": "92604",
        "kiosk": false
    },
    {
        "value": "162912",
        "label": "Irrigation Ditch Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23711023",
        "yellow_page_code": "43905, 25210, 44391",
        "kiosk": false
    },
    {
        "value": "162913",
        "label": "Dockboards & Ramps",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23799020",
        "yellow_page_code": "25303",
        "kiosk": false
    },
    {
        "value": "162914",
        "label": "Dredging",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23711019",
        "yellow_page_code": "26104",
        "kiosk": false
    },
    {
        "value": "162915",
        "label": "Golf Course Construction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23799004",
        "yellow_page_code": "38104, 69218, 38219",
        "kiosk": false
    },
    {
        "value": "162916",
        "label": "Soil Solidification",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891065",
        "yellow_page_code": "79206",
        "kiosk": false
    },
    {
        "value": "162917",
        "label": "Sea Wall Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23711016",
        "yellow_page_code": "75111, 75224",
        "kiosk": false
    },
    {
        "value": "162918",
        "label": "Fish Pools",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23799022",
        "yellow_page_code": "32505, 32507",
        "kiosk": false
    },
    {
        "value": "162919",
        "label": "Caissons-Structural",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23799019",
        "yellow_page_code": "12300",
        "kiosk": false
    },
    {
        "value": "162920",
        "label": "Draglines",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891063",
        "yellow_page_code": "25807",
        "kiosk": false
    },
    {
        "value": "162921",
        "label": "Erosion Control",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23711020",
        "yellow_page_code": "29802, 73708, 20929",
        "kiosk": false
    },
    {
        "value": "162922",
        "label": "Flood Control Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23711021",
        "yellow_page_code": "33100, 73743",
        "kiosk": false
    },
    {
        "value": "162923",
        "label": "Baseball Diamond Construction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23799018",
        "yellow_page_code": "06711",
        "kiosk": false
    },
    {
        "value": "162924",
        "label": "Power Plant Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23713017",
        "yellow_page_code": "21114, 67910, 28610",
        "kiosk": false
    },
    {
        "value": "162925",
        "label": "Mine Shaft Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23799005",
        "yellow_page_code": "52516",
        "kiosk": false
    },
    {
        "value": "162926",
        "label": "Ponds & Pond Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891042",
        "yellow_page_code": "67213, 67214, 47610, 32514",
        "kiosk": false
    },
    {
        "value": "162927",
        "label": "Sports-Athletic Field Construction-Mntnc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23799017",
        "yellow_page_code": "87609, 80013, 04109",
        "kiosk": false
    },
    {
        "value": "162928",
        "label": "Terracing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891067",
        "yellow_page_code": "85114",
        "kiosk": false
    },
    {
        "value": "162929",
        "label": "Soil Stabilization",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891066",
        "yellow_page_code": "79215",
        "kiosk": false
    },
    {
        "value": "162931",
        "label": "Paddle Tennis Court Construction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23799006",
        "yellow_page_code": "58501",
        "kiosk": false
    },
    {
        "value": "162932",
        "label": "Abrasive Cutting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891061",
        "yellow_page_code": "00037",
        "kiosk": false
    },
    {
        "value": "162933",
        "label": "Heliports-Construction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23713019",
        "yellow_page_code": "40917",
        "kiosk": false
    },
    {
        "value": "162934",
        "label": "Environmental Reclamation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891064",
        "yellow_page_code": "45735, 29633, 46218",
        "kiosk": false
    },
    {
        "value": "162935",
        "label": "Sawmill Construction & Maintenance",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23799008",
        "yellow_page_code": "74210",
        "kiosk": false
    },
    {
        "value": "162936",
        "label": "Oil-Offshore Construction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23712010",
        "yellow_page_code": "57110",
        "kiosk": false
    },
    {
        "value": "162937",
        "label": "Race Tracks-Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23799026",
        "yellow_page_code": "69512",
        "kiosk": false
    },
    {
        "value": "162938",
        "label": "Floors-Sports",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23799025",
        "yellow_page_code": "33321, 33213",
        "kiosk": false
    },
    {
        "value": "162939",
        "label": "Rivers & Canals",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23799028",
        "yellow_page_code": "72280",
        "kiosk": false
    },
    {
        "value": "162940",
        "label": "Golf Courses Fixed Placement",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23799016",
        "yellow_page_code": "38203",
        "kiosk": false
    },
    {
        "value": "162977",
        "label": "Construction-Heavy Projects",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23799003",
        "yellow_page_code": "21060",
        "kiosk": false
    },
    {
        "value": "171101",
        "label": "Heating Specialties",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822021",
        "yellow_page_code": "40908",
        "kiosk": false
    },
    {
        "value": "171102",
        "label": "Heating Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822020",
        "yellow_page_code": "21218, 27603, 40902, 40848, 40825, 40918, 41292",
        "kiosk": false
    },
    {
        "value": "171103",
        "label": "Sheet Metal Work Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822030",
        "yellow_page_code": "76201, 76204, 76136, 76210, 46522, 51430, 02166",
        "kiosk": false
    },
    {
        "value": "171104",
        "label": "Pipe Thawing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822046",
        "yellow_page_code": "65800",
        "kiosk": false
    },
    {
        "value": "171105",
        "label": "Plumbing Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822025",
        "yellow_page_code": "66708, 66710, 21124, 66716, 41380, 41468, 78599, 66722",
        "kiosk": false
    },
    {
        "value": "171106",
        "label": "Piping-Process & Industrial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822048",
        "yellow_page_code": "65901",
        "kiosk": false
    },
    {
        "value": "171107",
        "label": "Septic Tanks/Systems-Cleaning/Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891048",
        "yellow_page_code": "54505, 75602, 75539, 75531, 83416, 75527, 75530, 41344, 41455",
        "kiosk": false
    },
    {
        "value": "171108",
        "label": "Septic Tanks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891046",
        "yellow_page_code": "75600, 14506, 75538, 75542, 75546, 75817, 75618, 83499, 75532, 41351, 46495, 75533, 83521",
        "kiosk": false
    },
    {
        "value": "171110",
        "label": "Furnaces-Repairing & Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822014",
        "yellow_page_code": "35600, 81510",
        "kiosk": false
    },
    {
        "value": "171111",
        "label": "Solar Heating Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822032",
        "yellow_page_code": "79303, 79302, 21252, 79225",
        "kiosk": false
    },
    {
        "value": "171112",
        "label": "Heat Pumps",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822019",
        "yellow_page_code": "40608, 41442",
        "kiosk": false
    },
    {
        "value": "171113",
        "label": "Gas Burners-Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822015",
        "yellow_page_code": "36507",
        "kiosk": false
    },
    {
        "value": "171114",
        "label": "Mechanical Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821038",
        "yellow_page_code": "21223, 51000, 24215",
        "kiosk": false
    },
    {
        "value": "171115",
        "label": "Humidifying Apparatus",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822023",
        "yellow_page_code": "42104, 42033",
        "kiosk": false
    },
    {
        "value": "171116",
        "label": "Air Pollution Control",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822005",
        "yellow_page_code": "01300, 01302",
        "kiosk": false
    },
    {
        "value": "171117",
        "label": "Air Conditioning Contractors & Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822002",
        "yellow_page_code": "00908, 19625, 00915, 00919, 53110, 21490, 41279",
        "kiosk": false
    },
    {
        "value": "171118",
        "label": "Boilers-Repairing & Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822008",
        "yellow_page_code": "09006, 09003, 08911, 09107, 09004, 09009",
        "kiosk": false
    },
    {
        "value": "171119",
        "label": "Cooling Towers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822010",
        "yellow_page_code": "21500, 30388",
        "kiosk": false
    },
    {
        "value": "171120",
        "label": "Ventilating Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822035",
        "yellow_page_code": "90804, 90814, 04220",
        "kiosk": false
    },
    {
        "value": "171121",
        "label": "Grease Traps & Sand Traps-Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822018",
        "yellow_page_code": "38905",
        "kiosk": false
    },
    {
        "value": "171122",
        "label": "Gas Fitters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822016",
        "yellow_page_code": "36603, 36530",
        "kiosk": false
    },
    {
        "value": "171123",
        "label": "Tank Installation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821036",
        "yellow_page_code": "83502, 56815, 83519",
        "kiosk": false
    },
    {
        "value": "171124",
        "label": "Duct Systems-Air Conditioning & Heating",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822011",
        "yellow_page_code": "26735, 00925",
        "kiosk": false
    },
    {
        "value": "171125",
        "label": "Thawing Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821037",
        "yellow_page_code": "85409",
        "kiosk": false
    },
    {
        "value": "171126",
        "label": "Refrigeration Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821033",
        "yellow_page_code": "71405, 71407",
        "kiosk": false
    },
    {
        "value": "171127",
        "label": "Air Conditioning Equipment Test & Balancing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822003",
        "yellow_page_code": "01103",
        "kiosk": false
    },
    {
        "value": "171128",
        "label": "Dry Well Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891045",
        "yellow_page_code": "26711",
        "kiosk": false
    },
    {
        "value": "171129",
        "label": "Heating Systems-Cleaning & Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822022",
        "yellow_page_code": "40910",
        "kiosk": false
    },
    {
        "value": "171130",
        "label": "Air Balancing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822001",
        "yellow_page_code": "00806, 00909, 00837, 01650",
        "kiosk": false
    },
    {
        "value": "171131",
        "label": "Energy Management Systems & Products",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822013",
        "yellow_page_code": "28633, 28697, 02018, 28634",
        "kiosk": false
    },
    {
        "value": "171132",
        "label": "Geothermal Htg/Cooling Equip/Systs-Dlrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821031",
        "yellow_page_code": "37300, 37219, 01020, 40822, 01021, 37228, 37290, 37233, 37240, 41289, 37241",
        "kiosk": false
    },
    {
        "value": "171133",
        "label": "Leak Detecting Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822045",
        "yellow_page_code": "46501, 92325, 21273, 72621",
        "kiosk": false
    },
    {
        "value": "171134",
        "label": "Septic Tanks Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891047",
        "yellow_page_code": "75603, 75537",
        "kiosk": false
    },
    {
        "value": "171135",
        "label": "Radiant Heating & Cooling Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822029",
        "yellow_page_code": "69701, 40725, 40824, 89891",
        "kiosk": false
    },
    {
        "value": "171136",
        "label": "Refrigerant Recovery",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821032",
        "yellow_page_code": "71437, 71300, 00906",
        "kiosk": false
    },
    {
        "value": "171137",
        "label": "Roof Cooling Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821034",
        "yellow_page_code": "72608",
        "kiosk": false
    },
    {
        "value": "171138",
        "label": "Concrete-Sealing & Waterproofing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891043",
        "yellow_page_code": "20717, 20518, 20810, 20624",
        "kiosk": false
    },
    {
        "value": "171139",
        "label": "Boiler Setting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822006",
        "yellow_page_code": "09000",
        "kiosk": false
    },
    {
        "value": "171140",
        "label": "Pipe-Sealing Service-Industrial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822047",
        "yellow_page_code": "65704, 65515",
        "kiosk": false
    },
    {
        "value": "171141",
        "label": "Plumbing Contractors Referral Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822012",
        "yellow_page_code": "66719, 66711",
        "kiosk": false
    },
    {
        "value": "171142",
        "label": "Plumbing Drains & Sewer Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822026",
        "yellow_page_code": "66712, 75215, 25830",
        "kiosk": false
    },
    {
        "value": "171143",
        "label": "Slab Leaks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822024",
        "yellow_page_code": "78216",
        "kiosk": false
    },
    {
        "value": "171144",
        "label": "Solar Heating Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822033",
        "yellow_page_code": "79305, 79320, 40846, 79221, 79223, 74813",
        "kiosk": false
    },
    {
        "value": "171145",
        "label": "Steam Thawing Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821039",
        "yellow_page_code": "80809",
        "kiosk": false
    },
    {
        "value": "171146",
        "label": "Steam Fitters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822034",
        "yellow_page_code": "80806",
        "kiosk": false
    },
    {
        "value": "171147",
        "label": "Heating & Combustion Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821041",
        "yellow_page_code": "40805, 40840",
        "kiosk": false
    },
    {
        "value": "171148",
        "label": "Cooling Systems-Machine",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822009",
        "yellow_page_code": "21509",
        "kiosk": false
    },
    {
        "value": "171149",
        "label": "Gas Furnaces",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822017",
        "yellow_page_code": "36611",
        "kiosk": false
    },
    {
        "value": "171150",
        "label": "Snow Melting Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821035",
        "yellow_page_code": "78606, 78629",
        "kiosk": false
    },
    {
        "value": "171151",
        "label": "Sewer Locating Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891050",
        "yellow_page_code": "75803",
        "kiosk": false
    },
    {
        "value": "171152",
        "label": "Sewer & Drain Cleaning-Service/Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822039",
        "yellow_page_code": "75710, 18419, 75815, 75715, 75714, 75805, 25810",
        "kiosk": false
    },
    {
        "value": "171153",
        "label": "Sewer Thawing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891051",
        "yellow_page_code": "75807",
        "kiosk": false
    },
    {
        "value": "171154",
        "label": "Water Pollution Measuring Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891053",
        "yellow_page_code": "92314",
        "kiosk": false
    },
    {
        "value": "171155",
        "label": "Air Exchangers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822004",
        "yellow_page_code": "00911",
        "kiosk": false
    },
    {
        "value": "171156",
        "label": "Boilers-Inspection",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822007",
        "yellow_page_code": "09005",
        "kiosk": false
    },
    {
        "value": "171157",
        "label": "Concrete-Shot Crete",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891044",
        "yellow_page_code": "20711, 77299",
        "kiosk": false
    },
    {
        "value": "171158",
        "label": "Water Main Cleaning Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891052",
        "yellow_page_code": "92317",
        "kiosk": false
    },
    {
        "value": "171159",
        "label": "Air Duct Sealing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822049",
        "yellow_page_code": "01113",
        "kiosk": false
    },
    {
        "value": "171160",
        "label": "Ground Thawing & Space Heating",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891069",
        "yellow_page_code": "39125",
        "kiosk": false
    },
    {
        "value": "171161",
        "label": "Basement Pumping",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822037",
        "yellow_page_code": "06917",
        "kiosk": false
    },
    {
        "value": "171162",
        "label": "Heating Systems Alternative",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822040",
        "yellow_page_code": "40849",
        "kiosk": false
    },
    {
        "value": "171163",
        "label": "Lift Station Maintenance & Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822051",
        "yellow_page_code": "47222",
        "kiosk": false
    },
    {
        "value": "171164",
        "label": "Wind Power",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23713023",
        "yellow_page_code": "93532",
        "kiosk": false
    },
    {
        "value": "171165",
        "label": "Fire Sprinkler Systems Installation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822028",
        "yellow_page_code": "31917",
        "kiosk": false
    },
    {
        "value": "171166",
        "label": "Sump Pump Service & Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822052",
        "yellow_page_code": "82199",
        "kiosk": false
    },
    {
        "value": "171198",
        "label": "Plumbing Heating & Air Conditioning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822027",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "172101",
        "label": "Painters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23832003",
        "yellow_page_code": "58701, 58704, 31035, 58720, 31022, 58719, 58639, 29891, 41371, 43666, 02163",
        "kiosk": false
    },
    {
        "value": "172102",
        "label": "Painting Contractors-Commercial & Ind",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23832016",
        "yellow_page_code": "58705, 23630",
        "kiosk": false
    },
    {
        "value": "172103",
        "label": "Spray Painting & Finishing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23832007",
        "yellow_page_code": "80105, 58586, 58587",
        "kiosk": false
    },
    {
        "value": "172104",
        "label": "Wallpapers & Wallcoverings-Installation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23832012",
        "yellow_page_code": "59000, 91501, 91316, 91320, 91334, 41381",
        "kiosk": false
    },
    {
        "value": "172105",
        "label": "Stripers-Mechanical",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23731021",
        "yellow_page_code": "82006",
        "kiosk": false
    },
    {
        "value": "172106",
        "label": "Boat Painting & Lettering",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23832001",
        "yellow_page_code": "08807, 08719, 08698, 50045",
        "kiosk": false
    },
    {
        "value": "172107",
        "label": "Stencil-Decorative",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23832008",
        "yellow_page_code": "81201",
        "kiosk": false
    },
    {
        "value": "172108",
        "label": "Whitewashing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23832014",
        "yellow_page_code": "93411",
        "kiosk": false
    },
    {
        "value": "172109",
        "label": "Painting-Electrostatic",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23832017",
        "yellow_page_code": "28207, 58637",
        "kiosk": false
    },
    {
        "value": "172110",
        "label": "Stripe Painters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23731020",
        "yellow_page_code": "82011",
        "kiosk": false
    },
    {
        "value": "172111",
        "label": "Wall Coatings-Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23832009",
        "yellow_page_code": "91336",
        "kiosk": false
    },
    {
        "value": "172112",
        "label": "Wall Finishes",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23832010",
        "yellow_page_code": "91406, 72880",
        "kiosk": false
    },
    {
        "value": "172113",
        "label": "Walls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23832013",
        "yellow_page_code": "91503",
        "kiosk": false
    },
    {
        "value": "172114",
        "label": "Ship Painting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23832006",
        "yellow_page_code": "76412",
        "kiosk": false
    },
    {
        "value": "172115",
        "label": "Graining (Paint/Stain)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23832015",
        "yellow_page_code": "38711",
        "kiosk": false
    },
    {
        "value": "172116",
        "label": "Painting-Production",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23832004",
        "yellow_page_code": "58710",
        "kiosk": false
    },
    {
        "value": "172117",
        "label": "Window Painting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23832018",
        "yellow_page_code": "93621",
        "kiosk": false
    },
    {
        "value": "172118",
        "label": "Curb Numbering & Marking",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23731024",
        "yellow_page_code": "22838",
        "kiosk": false
    },
    {
        "value": "172119",
        "label": "Cabin Staining",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23832019",
        "yellow_page_code": "12110",
        "kiosk": false
    },
    {
        "value": "172120",
        "label": "Staining Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23832020",
        "yellow_page_code": "80430",
        "kiosk": false
    },
    {
        "value": "172121",
        "label": "Air Brushing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23832021",
        "yellow_page_code": "00834",
        "kiosk": false
    },
    {
        "value": "173101",
        "label": "Electric Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821007",
        "yellow_page_code": "27502, 28012, 21130, 27498, 27817, 27991, 94017, 94020, 41284, 37022, 56450, 41387, 43726",
        "kiosk": false
    },
    {
        "value": "173102",
        "label": "Electric Contractors-Coml & Industrial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821008",
        "yellow_page_code": "27503",
        "kiosk": false
    },
    {
        "value": "173103",
        "label": "Factory Maintenance",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821013",
        "yellow_page_code": "30601",
        "kiosk": false
    },
    {
        "value": "173104",
        "label": "Lightning Protection Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821019",
        "yellow_page_code": "47501",
        "kiosk": false
    },
    {
        "value": "173105",
        "label": "Electric Contractors-Marine",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821009",
        "yellow_page_code": "27501",
        "kiosk": false
    },
    {
        "value": "173106",
        "label": "Electronic Control Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821012",
        "yellow_page_code": "28016",
        "kiosk": false
    },
    {
        "value": "173107",
        "label": "Telecommunications Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821024",
        "yellow_page_code": "84217, 84245, 21250",
        "kiosk": false
    },
    {
        "value": "173108",
        "label": "Telephone-Installations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821027",
        "yellow_page_code": "84414",
        "kiosk": false
    },
    {
        "value": "173109",
        "label": "Landscape Lighting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821018",
        "yellow_page_code": "45905, 47313, 58124",
        "kiosk": false
    },
    {
        "value": "173110",
        "label": "Cable/Wire-Installation-Voice/Data Syst",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821001",
        "yellow_page_code": "12114, 91240, 93914, 12210",
        "kiosk": false
    },
    {
        "value": "173111",
        "label": "Electric Cable Fault Locating",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821006",
        "yellow_page_code": "27404, 27405, 12121",
        "kiosk": false
    },
    {
        "value": "173112",
        "label": "Pay Telephones & Booths Equipment & Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821022",
        "yellow_page_code": "59908, 84331, 59906, 19510",
        "kiosk": false
    },
    {
        "value": "173113",
        "label": "Telecommunications Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821025",
        "yellow_page_code": "84215",
        "kiosk": false
    },
    {
        "value": "173114",
        "label": "Time Locks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821028",
        "yellow_page_code": "86201",
        "kiosk": false
    },
    {
        "value": "173115",
        "label": "Data Communications Systems-Cabling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821005",
        "yellow_page_code": "23414, 20247, 82020, 55431",
        "kiosk": false
    },
    {
        "value": "173116",
        "label": "Fiber Optics-Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821014",
        "yellow_page_code": "31516, 31512",
        "kiosk": false
    },
    {
        "value": "173117",
        "label": "Fire Alarm Systems & Equipment-Testing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56162107",
        "yellow_page_code": "31913",
        "kiosk": false
    },
    {
        "value": "173118",
        "label": "Fire Suppression",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821015",
        "yellow_page_code": "32107, 32116",
        "kiosk": false
    },
    {
        "value": "173119",
        "label": "Commutators",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821004",
        "yellow_page_code": "20103",
        "kiosk": false
    },
    {
        "value": "173120",
        "label": "Commutator Resurfacing Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821003",
        "yellow_page_code": "20114",
        "kiosk": false
    },
    {
        "value": "173121",
        "label": "Load Controllers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821020",
        "yellow_page_code": "48313",
        "kiosk": false
    },
    {
        "value": "173122",
        "label": "Teleconferencing Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821026",
        "yellow_page_code": "84228, 84233",
        "kiosk": false
    },
    {
        "value": "173123",
        "label": "Installation Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821017",
        "yellow_page_code": "43319, 47419, 35501",
        "kiosk": false
    },
    {
        "value": "173124",
        "label": "Street Lighting Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821023",
        "yellow_page_code": "82009",
        "kiosk": false
    },
    {
        "value": "173125",
        "label": "Electronic Analysis",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821011",
        "yellow_page_code": "27907",
        "kiosk": false
    },
    {
        "value": "173126",
        "label": "Electrical Designers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821010",
        "yellow_page_code": "28014, 27909",
        "kiosk": false
    },
    {
        "value": "173127",
        "label": "Communications Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821002",
        "yellow_page_code": "19912, 20015",
        "kiosk": false
    },
    {
        "value": "173128",
        "label": "Low Voltage Systems Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821021",
        "yellow_page_code": "48710",
        "kiosk": false
    },
    {
        "value": "173129",
        "label": "Home Automation Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821016",
        "yellow_page_code": "41224",
        "kiosk": false
    },
    {
        "value": "173130",
        "label": "Oil Field Electric Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821029",
        "yellow_page_code": "56510",
        "kiosk": false
    },
    {
        "value": "173131",
        "label": "Elevators-Telephone Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821030",
        "yellow_page_code": "28205",
        "kiosk": false
    },
    {
        "value": "173132",
        "label": "Cable & Satellite-Wiring & Installation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821040",
        "yellow_page_code": "12146",
        "kiosk": false
    },
    {
        "value": "173133",
        "label": "Sound & Entertainment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821042",
        "yellow_page_code": "79506",
        "kiosk": false
    },
    {
        "value": "173134",
        "label": "Pet Containment Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23821043",
        "yellow_page_code": "60440",
        "kiosk": false
    },
    {
        "value": "174101",
        "label": "Masonry Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23814006",
        "yellow_page_code": "10604, 21207, 50302, 10520, 11315, 50313, 66920, 41476, 10516, 50298, 10521",
        "kiosk": false
    },
    {
        "value": "174102",
        "label": "Pointing-Brick/Stone & Etc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23814009",
        "yellow_page_code": "66908, 89308, 66913",
        "kiosk": false
    },
    {
        "value": "174103",
        "label": "Buildings-Concrete",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23814001",
        "yellow_page_code": "11306",
        "kiosk": false
    },
    {
        "value": "174104",
        "label": "Stone Setting Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23814012",
        "yellow_page_code": "81604",
        "kiosk": false
    },
    {
        "value": "174105",
        "label": "Adobe Materials & Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23814017",
        "yellow_page_code": "00312",
        "kiosk": false
    },
    {
        "value": "174106",
        "label": "Marble Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23814005",
        "yellow_page_code": "49907, 21120, 38523",
        "kiosk": false
    },
    {
        "value": "174107",
        "label": "Chimney Builders & Repairers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23814002",
        "yellow_page_code": "15302, 15317, 41482",
        "kiosk": false
    },
    {
        "value": "174108",
        "label": "Stone Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23814011",
        "yellow_page_code": "81605",
        "kiosk": false
    },
    {
        "value": "174109",
        "label": "Stone-Building",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23814013",
        "yellow_page_code": "81601",
        "kiosk": false
    },
    {
        "value": "174110",
        "label": "Fireplaces-Repaired",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23814003",
        "yellow_page_code": "32209, 32221",
        "kiosk": false
    },
    {
        "value": "174111",
        "label": "Foundation Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23814004",
        "yellow_page_code": "34227, 34129, 41364",
        "kiosk": false
    },
    {
        "value": "174112",
        "label": "Masonry Panels-Prefabricated",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23814007",
        "yellow_page_code": "50306",
        "kiosk": false
    },
    {
        "value": "174113",
        "label": "Memorial Restoration & Preservation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23814008",
        "yellow_page_code": "51219, 81616, 86012",
        "kiosk": false
    },
    {
        "value": "174114",
        "label": "Sporting Goods-Installation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23814010",
        "yellow_page_code": "80000",
        "kiosk": false
    },
    {
        "value": "174115",
        "label": "Tuck Pointing Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23814014",
        "yellow_page_code": "89316",
        "kiosk": false
    },
    {
        "value": "174116",
        "label": "Rock Wall Construction & Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23814015",
        "yellow_page_code": "72419",
        "kiosk": false
    },
    {
        "value": "174117",
        "label": "Stone Instltn Svc-Natural Granite/Marble",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23814016",
        "yellow_page_code": "81614",
        "kiosk": false
    },
    {
        "value": "174201",
        "label": "Acoustical Materials",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23831002",
        "yellow_page_code": "00204, 79513",
        "kiosk": false
    },
    {
        "value": "174202",
        "label": "Acoustical Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23831001",
        "yellow_page_code": "00202, 00198, 56441",
        "kiosk": false
    },
    {
        "value": "174203",
        "label": "Dry Wall Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23831003",
        "yellow_page_code": "26704, 21215, 76219, 26721, 81411, 26705, 41386",
        "kiosk": false
    },
    {
        "value": "174204",
        "label": "Lathing Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23831007",
        "yellow_page_code": "21118, 46104",
        "kiosk": false
    },
    {
        "value": "174205",
        "label": "Insulation Contractors-Cold & Heat",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23831006",
        "yellow_page_code": "43400, 79409, 21320, 43330, 43331, 43332, 79410, 43344, 41391",
        "kiosk": false
    },
    {
        "value": "174206",
        "label": "Plastering Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23831009",
        "yellow_page_code": "66204, 66141, 21277",
        "kiosk": false
    },
    {
        "value": "174207",
        "label": "Stone & Brick-Simulated",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23831014",
        "yellow_page_code": "81602, 10515",
        "kiosk": false
    },
    {
        "value": "174208",
        "label": "Metal Ceilings",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23831008",
        "yellow_page_code": "51412",
        "kiosk": false
    },
    {
        "value": "174209",
        "label": "Radiant Barriers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23831012",
        "yellow_page_code": "69614",
        "kiosk": false
    },
    {
        "value": "174210",
        "label": "Plastering-Ornamental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23831010",
        "yellow_page_code": "66136",
        "kiosk": false
    },
    {
        "value": "174211",
        "label": "Spraying-Insulation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23831013",
        "yellow_page_code": "80115",
        "kiosk": false
    },
    {
        "value": "174212",
        "label": "Insulation Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23831005",
        "yellow_page_code": "43336",
        "kiosk": false
    },
    {
        "value": "174213",
        "label": "Contractors Taping",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23831015",
        "yellow_page_code": "21251",
        "kiosk": false
    },
    {
        "value": "174301",
        "label": "Tile-Ceramic-Contractors & Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23834005",
        "yellow_page_code": "14504, 86002, 86010, 86017, 53712, 30044, 41299",
        "kiosk": false
    },
    {
        "value": "174302",
        "label": "Marble & Terrazzo-Cleaning & Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23834003",
        "yellow_page_code": "50001, 50033, 49994",
        "kiosk": false
    },
    {
        "value": "174303",
        "label": "Mantels",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23834002",
        "yellow_page_code": "49808, 32219",
        "kiosk": false
    },
    {
        "value": "174304",
        "label": "Contractors-Tile",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23834001",
        "yellow_page_code": "21335",
        "kiosk": false
    },
    {
        "value": "174305",
        "label": "Marble Vanity Tops",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23834004",
        "yellow_page_code": "49927, 49961",
        "kiosk": false
    },
    {
        "value": "175101",
        "label": "Building Finish",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23835001",
        "yellow_page_code": "11203",
        "kiosk": false
    },
    {
        "value": "175102",
        "label": "Carpenters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23835003",
        "yellow_page_code": "13508, 21121, 21143, 44527, 13507, 41283, 41275, 21274, 02151, 50043",
        "kiosk": false
    },
    {
        "value": "175103",
        "label": "Cabinet Makers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23835002",
        "yellow_page_code": "12106, 12150, 06926, 12149, 02154",
        "kiosk": false
    },
    {
        "value": "175104",
        "label": "Wood Finishing & Refinishing Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23835012",
        "yellow_page_code": "94204",
        "kiosk": false
    },
    {
        "value": "175105",
        "label": "Window Replacement",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23835011",
        "yellow_page_code": "93603, 71720",
        "kiosk": false
    },
    {
        "value": "175106",
        "label": "Woodworkers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23835013",
        "yellow_page_code": "94306, 23006, 94310, 94312, 30039, 03230, 41441",
        "kiosk": false
    },
    {
        "value": "175107",
        "label": "Garage Doors-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23835007",
        "yellow_page_code": "36115, 36114",
        "kiosk": false
    },
    {
        "value": "175108",
        "label": "Framing Contractors-Buildings",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23813001",
        "yellow_page_code": "34312",
        "kiosk": false
    },
    {
        "value": "175109",
        "label": "Varnishing & Gumming",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23835010",
        "yellow_page_code": "90611",
        "kiosk": false
    },
    {
        "value": "175110",
        "label": "Patio Doors-Suppliers & Installers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23813003",
        "yellow_page_code": "59719",
        "kiosk": false
    },
    {
        "value": "175111",
        "label": "Ship Joiners",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23835009",
        "yellow_page_code": "76507",
        "kiosk": false
    },
    {
        "value": "175112",
        "label": "Jig Sawing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23835008",
        "yellow_page_code": "44502",
        "kiosk": false
    },
    {
        "value": "175113",
        "label": "Clothes Line Installation & Repairs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23835004",
        "yellow_page_code": "18721",
        "kiosk": false
    },
    {
        "value": "175114",
        "label": "Broker-Construction-Indoor & Outdoor",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23835005",
        "yellow_page_code": "22134",
        "kiosk": false
    },
    {
        "value": "175115",
        "label": "Cabinet Installers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23835014",
        "yellow_page_code": "12126",
        "kiosk": false
    },
    {
        "value": "175117",
        "label": "Steel Frame Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23813002",
        "yellow_page_code": "81115",
        "kiosk": false
    },
    {
        "value": "175118",
        "label": "Joiners",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23835016",
        "yellow_page_code": "44511",
        "kiosk": false
    },
    {
        "value": "175119",
        "label": "Contractors-Garage Doors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23835017",
        "yellow_page_code": "21235",
        "kiosk": false
    },
    {
        "value": "175201",
        "label": "Tile-Non-Ceramic-Contractors & Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23833016",
        "yellow_page_code": "86100, 86003",
        "kiosk": false
    },
    {
        "value": "175202",
        "label": "Linoleum Layers Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23833012",
        "yellow_page_code": "47706",
        "kiosk": false
    },
    {
        "value": "175203",
        "label": "Floor Laying Refinishing & Resurfacing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23833007",
        "yellow_page_code": "33302, 33208, 33315, 33525, 33314, 33210, 45518, 41287, 33214, 02161",
        "kiosk": false
    },
    {
        "value": "175204",
        "label": "Linoleum Layers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23833011",
        "yellow_page_code": "47704, 47710",
        "kiosk": false
    },
    {
        "value": "175205",
        "label": "Floor Refinishing & Resurfacing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23833008",
        "yellow_page_code": "33402, 33415, 40118, 94236, 33204",
        "kiosk": false
    },
    {
        "value": "175206",
        "label": "Carpet Layers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23833002",
        "yellow_page_code": "13804, 13801, 13710, 13713, 13618, 13790",
        "kiosk": false
    },
    {
        "value": "175207",
        "label": "Vinyl Layers (Installers)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23833017",
        "yellow_page_code": "91209",
        "kiosk": false
    },
    {
        "value": "175208",
        "label": "Floors-Contractors & Builders",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23833010",
        "yellow_page_code": "33405, 21138, 33310, 33317",
        "kiosk": false
    },
    {
        "value": "175209",
        "label": "Carpet & Rug-Measuring Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23833001",
        "yellow_page_code": "13705",
        "kiosk": false
    },
    {
        "value": "175210",
        "label": "Racquetball Handball/Squash Crt Constr",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23833013",
        "yellow_page_code": "69608",
        "kiosk": false
    },
    {
        "value": "175211",
        "label": "Stairs-Resurfacing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23833014",
        "yellow_page_code": "80413",
        "kiosk": false
    },
    {
        "value": "175212",
        "label": "Floor Armoring",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23833004",
        "yellow_page_code": "33205",
        "kiosk": false
    },
    {
        "value": "175213",
        "label": "Floor Coatings-Concrete",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23833005",
        "yellow_page_code": "33207, 20523, 36133, 36136",
        "kiosk": false
    },
    {
        "value": "175214",
        "label": "Floors-Chemical Resistant",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23833009",
        "yellow_page_code": "33305",
        "kiosk": false
    },
    {
        "value": "175215",
        "label": "Concrete-Sound Insulating",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23833003",
        "yellow_page_code": "20705",
        "kiosk": false
    },
    {
        "value": "175216",
        "label": "Floor Stenciling & Designing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23833020",
        "yellow_page_code": "33209",
        "kiosk": false
    },
    {
        "value": "175220",
        "label": "Surface Preparation & Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23833015",
        "yellow_page_code": "82310",
        "kiosk": false
    },
    {
        "value": "175221",
        "label": "Carpet & Rug Serging",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23833018",
        "yellow_page_code": "13899",
        "kiosk": false
    },
    {
        "value": "175222",
        "label": "Floor Safety Treatments",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23833019",
        "yellow_page_code": "33414, 71738, 73307",
        "kiosk": false
    },
    {
        "value": "175299",
        "label": "Floor Laying & Floor Work NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23833006",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "176101",
        "label": "Coppersmiths",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23816002",
        "yellow_page_code": "21600",
        "kiosk": false
    },
    {
        "value": "176102",
        "label": "Ceilings",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23816001",
        "yellow_page_code": "14300, 14215, 14210",
        "kiosk": false
    },
    {
        "value": "176103",
        "label": "Gutters & Downspouts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23816004",
        "yellow_page_code": "27211, 39500, 39505, 39490, 25720, 41379, 39492",
        "kiosk": false
    },
    {
        "value": "176104",
        "label": "Roof Decks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23816008",
        "yellow_page_code": "23703, 72606, 23619, 86106",
        "kiosk": false
    },
    {
        "value": "176105",
        "label": "Roofing Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23816013",
        "yellow_page_code": "72601, 21086",
        "kiosk": false
    },
    {
        "value": "176106",
        "label": "Roof Maintenance",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23816010",
        "yellow_page_code": "72604, 72714",
        "kiosk": false
    },
    {
        "value": "176107",
        "label": "Roof Coating",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23816007",
        "yellow_page_code": "72603",
        "kiosk": false
    },
    {
        "value": "176108",
        "label": "Roof Structures",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23816011",
        "yellow_page_code": "72700, 72610, 72713, 72618",
        "kiosk": false
    },
    {
        "value": "176109",
        "label": "Roofing Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23816014",
        "yellow_page_code": "72704, 72703, 72711, 72712, 72715, 21241, 72619, 85350, 30264, 72710, 41297, 72620, 43782, 02164",
        "kiosk": false
    },
    {
        "value": "176110",
        "label": "Skylights",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23839002",
        "yellow_page_code": "78208, 71733, 41373, 02169",
        "kiosk": false
    },
    {
        "value": "176111",
        "label": "Siding Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23817002",
        "yellow_page_code": "77406, 77420, 77421, 91154, 41272, 41438, 77419",
        "kiosk": false
    },
    {
        "value": "176112",
        "label": "Tinning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23816016",
        "yellow_page_code": "86302, 86303",
        "kiosk": false
    },
    {
        "value": "176113",
        "label": "Roof Inspection Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23816009",
        "yellow_page_code": "72609",
        "kiosk": false
    },
    {
        "value": "176114",
        "label": "Flashing-Wall & Roof & Etc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23816003",
        "yellow_page_code": "32903",
        "kiosk": false
    },
    {
        "value": "176115",
        "label": "Roof-Screen Support Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23816012",
        "yellow_page_code": "72611",
        "kiosk": false
    },
    {
        "value": "176116",
        "label": "Cornices-Building",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23839001",
        "yellow_page_code": "21801",
        "kiosk": false
    },
    {
        "value": "176117",
        "label": "Metal Roofing Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23816005",
        "yellow_page_code": "51610, 51611",
        "kiosk": false
    },
    {
        "value": "176119",
        "label": "Mobile Homes-Roofing & Roof Covering",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23816006",
        "yellow_page_code": "53203",
        "kiosk": false
    },
    {
        "value": "176120",
        "label": "Fascias & Soffits",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23816017",
        "yellow_page_code": "30930",
        "kiosk": false
    },
    {
        "value": "176198",
        "label": "Roofing Siding & Sheet Metal Work",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23816015",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "177101",
        "label": "Driveways",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23811003",
        "yellow_page_code": "21214, 26405, 21210, 26410, 28822, 26416, 59838, 59839",
        "kiosk": false
    },
    {
        "value": "177102",
        "label": "Balancing Service-Industrial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899083",
        "yellow_page_code": "06102",
        "kiosk": false
    },
    {
        "value": "177103",
        "label": "Floors-Industrial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23811004",
        "yellow_page_code": "33408, 30446",
        "kiosk": false
    },
    {
        "value": "177104",
        "label": "Stucco Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23814018",
        "yellow_page_code": "82104, 82035, 82034, 82123, 82033, 14225, 82124, 60121, 30082",
        "kiosk": false
    },
    {
        "value": "177105",
        "label": "Concrete Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23811001",
        "yellow_page_code": "20600, 20620, 20713, 20515, 14320, 20525, 41453",
        "kiosk": false
    },
    {
        "value": "177106",
        "label": "Concrete Restoration Repair & Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899008",
        "yellow_page_code": "20708, 59421, 20718, 20524",
        "kiosk": false
    },
    {
        "value": "177107",
        "label": "Concrete Pumping Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23811002",
        "yellow_page_code": "20704",
        "kiosk": false
    },
    {
        "value": "177108",
        "label": "Sidewalk Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23811007",
        "yellow_page_code": "77407",
        "kiosk": false
    },
    {
        "value": "177109",
        "label": "Gunite Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23811005",
        "yellow_page_code": "39401, 39335",
        "kiosk": false
    },
    {
        "value": "177110",
        "label": "Grouting Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23831004",
        "yellow_page_code": "39201, 39118",
        "kiosk": false
    },
    {
        "value": "177111",
        "label": "Concrete Grinding & Finishing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899007",
        "yellow_page_code": "20700, 20617, 20625, 74805, 20411",
        "kiosk": false
    },
    {
        "value": "177112",
        "label": "Cement-Finishing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899005",
        "yellow_page_code": "14317",
        "kiosk": false
    },
    {
        "value": "177113",
        "label": "Concrete Ditch Liners",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899006",
        "yellow_page_code": "20601",
        "kiosk": false
    },
    {
        "value": "177114",
        "label": "Machine Foundations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23811006",
        "yellow_page_code": "49009",
        "kiosk": false
    },
    {
        "value": "177115",
        "label": "Cement-Asbestos",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899004",
        "yellow_page_code": "14301",
        "kiosk": false
    },
    {
        "value": "177116",
        "label": "Blacktopping",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899003",
        "yellow_page_code": "08403",
        "kiosk": false
    },
    {
        "value": "177117",
        "label": "Pressure Grouting Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23831011",
        "yellow_page_code": "68121",
        "kiosk": false
    },
    {
        "value": "177118",
        "label": "Asphalt Paving Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899009",
        "yellow_page_code": "03999, 04015",
        "kiosk": false
    },
    {
        "value": "177119",
        "label": "Drilling Contractors-Concrete",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899010",
        "yellow_page_code": "26399",
        "kiosk": false
    },
    {
        "value": "177120",
        "label": "Concrete Foundations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23811008",
        "yellow_page_code": "20506, 41390",
        "kiosk": false
    },
    {
        "value": "177121",
        "label": "Shotblasting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899099",
        "yellow_page_code": "77207, 80917",
        "kiosk": false
    },
    {
        "value": "178101",
        "label": "Water Well Drilling & Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23711005",
        "yellow_page_code": "92508, 92539, 92520, 92535, 93201, 92322, 75590, 41457, 93215",
        "kiosk": false
    },
    {
        "value": "178102",
        "label": "Drilling & Boring Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23711002",
        "yellow_page_code": "26308, 26309, 56448",
        "kiosk": false
    },
    {
        "value": "178103",
        "label": "Well Drilling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23711009",
        "yellow_page_code": "93206, 26312, 26398, 93210, 65907, 56467",
        "kiosk": false
    },
    {
        "value": "178104",
        "label": "Well Contractors-Artesian",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23711008",
        "yellow_page_code": "93212",
        "kiosk": false
    },
    {
        "value": "178106",
        "label": "Water Wheels",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23711007",
        "yellow_page_code": "92602",
        "kiosk": false
    },
    {
        "value": "178107",
        "label": "Well Pumps",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23711010",
        "yellow_page_code": "93211",
        "kiosk": false
    },
    {
        "value": "178108",
        "label": "Water Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23711003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "178109",
        "label": "Water Well Abandonment Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23711004",
        "yellow_page_code": "92512",
        "kiosk": false
    },
    {
        "value": "178110",
        "label": "Water Well Locating",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23711006",
        "yellow_page_code": "92537, 92321",
        "kiosk": false
    },
    {
        "value": "178111",
        "label": "Cisterns",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23711024",
        "yellow_page_code": "17909, 17975, 83417",
        "kiosk": false
    },
    {
        "value": "178112",
        "label": "Hydrofracture Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23711025",
        "yellow_page_code": "42216",
        "kiosk": false
    },
    {
        "value": "178113",
        "label": "Water Well Maintenance Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23711027",
        "yellow_page_code": "92041",
        "kiosk": false
    },
    {
        "value": "179101",
        "label": "Steel Detailers-Structural",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23812003",
        "yellow_page_code": "80908, 80915",
        "kiosk": false
    },
    {
        "value": "179102",
        "label": "Steel Erectors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23812004",
        "yellow_page_code": "81002",
        "kiosk": false
    },
    {
        "value": "179103",
        "label": "Welding & Steel Fabrication",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891057",
        "yellow_page_code": "93107",
        "kiosk": false
    },
    {
        "value": "179104",
        "label": "Metal Fabricators",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891054",
        "yellow_page_code": "51504, 29956, 30589",
        "kiosk": false
    },
    {
        "value": "179105",
        "label": "Steel Detailers-Reinforcing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23812002",
        "yellow_page_code": "80909",
        "kiosk": false
    },
    {
        "value": "179106",
        "label": "Vibration Isolation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891055",
        "yellow_page_code": "91007",
        "kiosk": false
    },
    {
        "value": "179107",
        "label": "Concrete Post Tensioning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23812001",
        "yellow_page_code": "20714, 67510, 21191",
        "kiosk": false
    },
    {
        "value": "179108",
        "label": "Tank Erection",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23819001",
        "yellow_page_code": "83516",
        "kiosk": false
    },
    {
        "value": "179109",
        "label": "Steel Reinforcing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23812005",
        "yellow_page_code": "81001",
        "kiosk": false
    },
    {
        "value": "179110",
        "label": "Structural Steel Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23812006",
        "yellow_page_code": "82014, 81733, 91401",
        "kiosk": false
    },
    {
        "value": "179111",
        "label": "Iron & Iron Work Commercial/Industrial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891056",
        "yellow_page_code": "43824, 21720",
        "kiosk": false
    },
    {
        "value": "179112",
        "label": "Foundation Structure & Building Exterior Contractors ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23819012",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "179301",
        "label": "Curtain Walls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23815001",
        "yellow_page_code": "22905",
        "kiosk": false
    },
    {
        "value": "179302",
        "label": "Glaziers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23815003",
        "yellow_page_code": "37802, 37425",
        "kiosk": false
    },
    {
        "value": "179303",
        "label": "Glass-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23815002",
        "yellow_page_code": "37508, 37417",
        "kiosk": false
    },
    {
        "value": "179304",
        "label": "Windows-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23815006",
        "yellow_page_code": "93702, 93639, 25752",
        "kiosk": false
    },
    {
        "value": "179305",
        "label": "Storm Windows & Doors-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23815005",
        "yellow_page_code": "81904",
        "kiosk": false
    },
    {
        "value": "179306",
        "label": "Sash Operating Devices",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23815004",
        "yellow_page_code": "73908",
        "kiosk": false
    },
    {
        "value": "179307",
        "label": "Safety Glazing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23815007",
        "yellow_page_code": "73407",
        "kiosk": false
    },
    {
        "value": "179308",
        "label": "Glass Tinting & Coating/Residential & Commercial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23815008",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "179401",
        "label": "Landfill Demolition",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891010",
        "yellow_page_code": "45807",
        "kiosk": false
    },
    {
        "value": "179402",
        "label": "Foundation-Drilling Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891008",
        "yellow_page_code": "34203, 37025",
        "kiosk": false
    },
    {
        "value": "179403",
        "label": "Excavating Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891006",
        "yellow_page_code": "21204, 30002, 21254, 24744, 42195, 30178, 41285, 43760, 30030, 29848",
        "kiosk": false
    },
    {
        "value": "179404",
        "label": "Bulldozing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891001",
        "yellow_page_code": "11406, 11410, 11416",
        "kiosk": false
    },
    {
        "value": "179405",
        "label": "Foundation-Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891007",
        "yellow_page_code": "21216, 34202, 30106",
        "kiosk": false
    },
    {
        "value": "179406",
        "label": "Site Development Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891002",
        "yellow_page_code": "21227, 77903, 77890, 77891, 56560, 02638",
        "kiosk": false
    },
    {
        "value": "179407",
        "label": "Land Clearing & Leveling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891009",
        "yellow_page_code": "45802",
        "kiosk": false
    },
    {
        "value": "179408",
        "label": "Dirt Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891003",
        "yellow_page_code": "24828, 24859, 21242",
        "kiosk": false
    },
    {
        "value": "179409",
        "label": "Slabjacking Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891012",
        "yellow_page_code": "78209",
        "kiosk": false
    },
    {
        "value": "179411",
        "label": "Dozer Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891004",
        "yellow_page_code": "25803",
        "kiosk": false
    },
    {
        "value": "179412",
        "label": "Earth Energy",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891005",
        "yellow_page_code": "27081",
        "kiosk": false
    },
    {
        "value": "179413",
        "label": "Trackhoe Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891070",
        "yellow_page_code": "87685",
        "kiosk": false
    },
    {
        "value": "179501",
        "label": "Demolition Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891014",
        "yellow_page_code": "23812",
        "kiosk": false
    },
    {
        "value": "179502",
        "label": "Demolition Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891015",
        "yellow_page_code": "08406, 23806, 94508, 11111, 21333, 52503, 37024, 41139",
        "kiosk": false
    },
    {
        "value": "179503",
        "label": "Wrecker Bodies & Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891017",
        "yellow_page_code": "94514",
        "kiosk": false
    },
    {
        "value": "179504",
        "label": "Concrete Breaking Cutting & Sawing & Etc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891013",
        "yellow_page_code": "20504, 20610",
        "kiosk": false
    },
    {
        "value": "179505",
        "label": "Smoke Stacks-Dismantling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891016",
        "yellow_page_code": "78507",
        "kiosk": false
    },
    {
        "value": "179506",
        "label": "Swimming Pool Removal",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891033",
        "yellow_page_code": "82918",
        "kiosk": false
    },
    {
        "value": "179601",
        "label": "Elevators-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23829004",
        "yellow_page_code": "28307, 28316, 28317, 47207",
        "kiosk": false
    },
    {
        "value": "179602",
        "label": "Dumbwaiters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23829001",
        "yellow_page_code": "26808",
        "kiosk": false
    },
    {
        "value": "179603",
        "label": "Blowers & Blower Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822036",
        "yellow_page_code": "08508, 30574",
        "kiosk": false
    },
    {
        "value": "179604",
        "label": "Machinery-Movers & Erectors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23829007",
        "yellow_page_code": "49206, 42940, 66028",
        "kiosk": false
    },
    {
        "value": "179605",
        "label": "Elevators-Sales & Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23829005",
        "yellow_page_code": "28306, 28310, 28208, 28312, 28314, 28315, 28318, 28319, 28320, 47219, 47127",
        "kiosk": false
    },
    {
        "value": "179606",
        "label": "Millwrights",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23829008",
        "yellow_page_code": "52502",
        "kiosk": false
    },
    {
        "value": "179607",
        "label": "Pneumatic Tube Equipment & Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23829009",
        "yellow_page_code": "66902, 66903",
        "kiosk": false
    },
    {
        "value": "179608",
        "label": "Elevator Cars-Refinishing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23829002",
        "yellow_page_code": "28203",
        "kiosk": false
    },
    {
        "value": "179610",
        "label": "Elevators-Portable",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23829003",
        "yellow_page_code": "28305",
        "kiosk": false
    },
    {
        "value": "179611",
        "label": "Elevator & Escalator Inspection Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23829011",
        "yellow_page_code": "28209",
        "kiosk": false
    },
    {
        "value": "179612",
        "label": "Conveyors & Conveying Equip Installation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23829012",
        "yellow_page_code": "21323",
        "kiosk": false
    },
    {
        "value": "179613",
        "label": "Clothes-Dryer-Venting-Installation-Coml",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822041",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "179614",
        "label": "Laboratory Equip & Supplies Installation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23829014",
        "yellow_page_code": "45212",
        "kiosk": false
    },
    {
        "value": "179615",
        "label": "Medical Equip-Installation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23829015",
        "yellow_page_code": "51129",
        "kiosk": false
    },
    {
        "value": "179616",
        "label": "Commercial Equipment-Installation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822050",
        "yellow_page_code": "20011",
        "kiosk": false
    },
    {
        "value": "179617",
        "label": "Hoisting & Rigging Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23829028",
        "yellow_page_code": "41105",
        "kiosk": false
    },
    {
        "value": "179699",
        "label": "Installation Or Erection-Bldg Eqpt NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23829006",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "179901",
        "label": "Burglar Bars",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899014",
        "yellow_page_code": "11503",
        "kiosk": false
    },
    {
        "value": "179902",
        "label": "Porcelain Enamel-Repairing/Refinishing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23832005",
        "yellow_page_code": "67308",
        "kiosk": false
    },
    {
        "value": "179903",
        "label": "Guards-Door & Window",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23839003",
        "yellow_page_code": "39300, 93630",
        "kiosk": false
    },
    {
        "value": "179904",
        "label": "Guard Rails",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23731025",
        "yellow_page_code": "39209, 39207, 67973",
        "kiosk": false
    },
    {
        "value": "179905",
        "label": "Railings",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899033",
        "yellow_page_code": "70108, 40121, 80509, 36907, 37423",
        "kiosk": false
    },
    {
        "value": "179906",
        "label": "Wallpaper Removal Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23832011",
        "yellow_page_code": "91408, 41452",
        "kiosk": false
    },
    {
        "value": "179907",
        "label": "Columns",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23819003",
        "yellow_page_code": "19906",
        "kiosk": false
    },
    {
        "value": "179908",
        "label": "Weather Stripping Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23839005",
        "yellow_page_code": "92708",
        "kiosk": false
    },
    {
        "value": "179909",
        "label": "Waterproofing Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23839004",
        "yellow_page_code": "21233, 92608, 48514, 48526, 48527, 30038, 41311",
        "kiosk": false
    },
    {
        "value": "179910",
        "label": "Paint & Varnish Removal Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23832002",
        "yellow_page_code": "58601, 58606, 58513, 58590, 30116",
        "kiosk": false
    },
    {
        "value": "179911",
        "label": "Sandblasting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899035",
        "yellow_page_code": "73802, 73811, 73810, 08418, 39130",
        "kiosk": false
    },
    {
        "value": "179912",
        "label": "Pumping Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899032",
        "yellow_page_code": "69106, 69118, 69221, 92038, 83524",
        "kiosk": false
    },
    {
        "value": "179913",
        "label": "Service Station Equipment-Installing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23829020",
        "yellow_page_code": "75605",
        "kiosk": false
    },
    {
        "value": "179914",
        "label": "Cob & Soda Blasting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891037",
        "yellow_page_code": "19214, 79099",
        "kiosk": false
    },
    {
        "value": "179915",
        "label": "Service Station Equipment-Servicing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23829021",
        "yellow_page_code": "75607, 75612",
        "kiosk": false
    },
    {
        "value": "179916",
        "label": "Shoring",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23819006",
        "yellow_page_code": "77200, 77202, 77111, 77301, 29234, 77116",
        "kiosk": false
    },
    {
        "value": "179917",
        "label": "Steam Cleaning-Industrial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899039",
        "yellow_page_code": "80804, 80799, 30081, 80811",
        "kiosk": false
    },
    {
        "value": "179918",
        "label": "Stair Builders",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899038",
        "yellow_page_code": "80500, 80502, 80495, 02170",
        "kiosk": false
    },
    {
        "value": "179919",
        "label": "Steeple Jacks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23829022",
        "yellow_page_code": "81106",
        "kiosk": false
    },
    {
        "value": "179920",
        "label": "Swimming Pool Enclosures",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622042",
        "yellow_page_code": "82802",
        "kiosk": false
    },
    {
        "value": "179921",
        "label": "Swimming Pool Contrs Dealers & Designers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622040",
        "yellow_page_code": "82708, 82818, 67212, 82810, 82819, 82826, 82824, 30040, 41475, 43723",
        "kiosk": false
    },
    {
        "value": "179922",
        "label": "Tennis Court Enclosures",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23799029",
        "yellow_page_code": "85006",
        "kiosk": false
    },
    {
        "value": "179923",
        "label": "Tanks-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23829026",
        "yellow_page_code": "83601",
        "kiosk": false
    },
    {
        "value": "179924",
        "label": "Fireproofing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899025",
        "yellow_page_code": "32208, 32808, 32814, 93539",
        "kiosk": false
    },
    {
        "value": "179925",
        "label": "Gas Detectors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899026",
        "yellow_page_code": "36602",
        "kiosk": false
    },
    {
        "value": "179926",
        "label": "Asbestos Removal Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56291001",
        "yellow_page_code": "04001, 03912, 03920, 03921, 03923",
        "kiosk": false
    },
    {
        "value": "179927",
        "label": "Mobile Homes-Anchoring Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23799010",
        "yellow_page_code": "53103, 53022, 53117, 49860, 49844, 53018, 49929",
        "kiosk": false
    },
    {
        "value": "179928",
        "label": "Boring Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891058",
        "yellow_page_code": "09500, 24829",
        "kiosk": false
    },
    {
        "value": "179929",
        "label": "Building Specialties",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23819002",
        "yellow_page_code": "11304",
        "kiosk": false
    },
    {
        "value": "179930",
        "label": "Bowling Lane Refinishing & Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23829016",
        "yellow_page_code": "09800, 09715",
        "kiosk": false
    },
    {
        "value": "179931",
        "label": "Caulking Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23839006",
        "yellow_page_code": "14108",
        "kiosk": false
    },
    {
        "value": "179932",
        "label": "Cable Splicing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899015",
        "yellow_page_code": "12202",
        "kiosk": false
    },
    {
        "value": "179933",
        "label": "Swimming Pool Decks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56179003",
        "yellow_page_code": "82821, 82822",
        "kiosk": false
    },
    {
        "value": "179934",
        "label": "Dog Enclosures & Runways",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899020",
        "yellow_page_code": "25305, 60510",
        "kiosk": false
    },
    {
        "value": "179935",
        "label": "Computer Rooms-Installation & Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23829027",
        "yellow_page_code": "20308",
        "kiosk": false
    },
    {
        "value": "179936",
        "label": "Pipe & Boiler Covering Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23829017",
        "yellow_page_code": "65404",
        "kiosk": false
    },
    {
        "value": "179937",
        "label": "House Leveling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23819008",
        "yellow_page_code": "42001",
        "kiosk": false
    },
    {
        "value": "179938",
        "label": "Fence Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899023",
        "yellow_page_code": "31303, 31312, 31318, 31317, 31301, 27415, 31320, 30441, 31316, 41286, 02160, 31323",
        "kiosk": false
    },
    {
        "value": "179939",
        "label": "Fireplace Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899071",
        "yellow_page_code": "32212",
        "kiosk": false
    },
    {
        "value": "179940",
        "label": "Basement Waterproofing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899013",
        "yellow_page_code": "06902",
        "kiosk": false
    },
    {
        "value": "179941",
        "label": "Counter Tops Restoration",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23835015",
        "yellow_page_code": "22098",
        "kiosk": false
    },
    {
        "value": "179942",
        "label": "Patio & Deck Cleaning & Restoration",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23819009",
        "yellow_page_code": "68200, 67920, 23650, 59713, 59425, 68122, 59715, 23624, 20925, 23645",
        "kiosk": false
    },
    {
        "value": "179943",
        "label": "Retaining Walls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23799011",
        "yellow_page_code": "72003, 91407, 90614",
        "kiosk": false
    },
    {
        "value": "179944",
        "label": "Tank Removal",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23829024",
        "yellow_page_code": "83514, 83511",
        "kiosk": false
    },
    {
        "value": "179945",
        "label": "Tank Abandonment Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23829013",
        "yellow_page_code": "83414, 83523",
        "kiosk": false
    },
    {
        "value": "179946",
        "label": "Concrete-Forms-Erectors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23819010",
        "yellow_page_code": "20507, 20510",
        "kiosk": false
    },
    {
        "value": "179947",
        "label": "Graffiti Removal & Protection Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899028",
        "yellow_page_code": "38507",
        "kiosk": false
    },
    {
        "value": "179948",
        "label": "Lead Removal & Abatement",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56291004",
        "yellow_page_code": "46503, 46510, 46520",
        "kiosk": false
    },
    {
        "value": "179949",
        "label": "Linings-Plastic Membrane & Etc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23839007",
        "yellow_page_code": "47701, 51232",
        "kiosk": false
    },
    {
        "value": "179950",
        "label": "Fire Violations-Removed",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56291003",
        "yellow_page_code": "32105",
        "kiosk": false
    },
    {
        "value": "179951",
        "label": "Removers-Industrial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56291006",
        "yellow_page_code": "71613",
        "kiosk": false
    },
    {
        "value": "179952",
        "label": "Lake & Pond Construction & Maintenance",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23799024",
        "yellow_page_code": "45505, 67215, 45410, 67219, 67223, 45421, 45520, 45422, 67226, 41296",
        "kiosk": false
    },
    {
        "value": "179953",
        "label": "Dewatering Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56291002",
        "yellow_page_code": "24404, 83518",
        "kiosk": false
    },
    {
        "value": "179954",
        "label": "Concrete Recycling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56291011",
        "yellow_page_code": "20720",
        "kiosk": false
    },
    {
        "value": "179955",
        "label": "House Raising",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23819005",
        "yellow_page_code": "41912, 41949",
        "kiosk": false
    },
    {
        "value": "179956",
        "label": "Predictive Maintenance Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899031",
        "yellow_page_code": "68019",
        "kiosk": false
    },
    {
        "value": "179957",
        "label": "Photographic Sets",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899030",
        "yellow_page_code": "61110",
        "kiosk": false
    },
    {
        "value": "179958",
        "label": "Pipe Covering Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23829018",
        "yellow_page_code": "65413",
        "kiosk": false
    },
    {
        "value": "179959",
        "label": "Pipe Pushing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23829019",
        "yellow_page_code": "65703",
        "kiosk": false
    },
    {
        "value": "179960",
        "label": "Sign Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899042",
        "yellow_page_code": "77435, 77517, 77505, 77520",
        "kiosk": false
    },
    {
        "value": "179961",
        "label": "Skating Rinks-Installation/Equip/Supls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899036",
        "yellow_page_code": "78006",
        "kiosk": false
    },
    {
        "value": "179962",
        "label": "Moss Removal",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899029",
        "yellow_page_code": "53705",
        "kiosk": false
    },
    {
        "value": "179963",
        "label": "Condensation Control",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899017",
        "yellow_page_code": "20814",
        "kiosk": false
    },
    {
        "value": "179964",
        "label": "Elevator Shaft Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899022",
        "yellow_page_code": "28304",
        "kiosk": false
    },
    {
        "value": "179965",
        "label": "Government-Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899027",
        "yellow_page_code": "38411, 21195",
        "kiosk": false
    },
    {
        "value": "179966",
        "label": "Shores-Adjustable",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891060",
        "yellow_page_code": "77201",
        "kiosk": false
    },
    {
        "value": "179967",
        "label": "Steeples & Spires",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23829023",
        "yellow_page_code": "81107",
        "kiosk": false
    },
    {
        "value": "179968",
        "label": "Test Borings",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891059",
        "yellow_page_code": "85211",
        "kiosk": false
    },
    {
        "value": "179969",
        "label": "Wet Blasting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899040",
        "yellow_page_code": "93235",
        "kiosk": false
    },
    {
        "value": "179970",
        "label": "Concrete-Precast",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899016",
        "yellow_page_code": "20802, 68045",
        "kiosk": false
    },
    {
        "value": "179971",
        "label": "Cornblasting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23799027",
        "yellow_page_code": "21716",
        "kiosk": false
    },
    {
        "value": "179972",
        "label": "Carillon-Cast Bell-Tower Clock Contrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23829010",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "179977",
        "label": "Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899018",
        "yellow_page_code": "87720, 43497",
        "kiosk": false
    },
    {
        "value": "179978",
        "label": "Earthquake Products & Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899021",
        "yellow_page_code": "27116, 27117",
        "kiosk": false
    },
    {
        "value": "179979",
        "label": "Artificial Grass",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899011",
        "yellow_page_code": "03708, 51136, 82920, 80039, 89495, 46211",
        "kiosk": false
    },
    {
        "value": "179980",
        "label": "Awnings & Canopies-Maintenance",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899012",
        "yellow_page_code": "05411, 05415",
        "kiosk": false
    },
    {
        "value": "179981",
        "label": "Corral Builders",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899019",
        "yellow_page_code": "21709, 21710",
        "kiosk": false
    },
    {
        "value": "179982",
        "label": "Erecting Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23819004",
        "yellow_page_code": "29708",
        "kiosk": false
    },
    {
        "value": "179984",
        "label": "Rust Removing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899034",
        "yellow_page_code": "73300",
        "kiosk": false
    },
    {
        "value": "179985",
        "label": "Swimming Pool Repair & Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622041",
        "yellow_page_code": "82811, 82705, 82813, 82711, 30041, 82828, 82832, 41358",
        "kiosk": false
    },
    {
        "value": "179987",
        "label": "Lifts-Manned",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899043",
        "yellow_page_code": "47217",
        "kiosk": false
    },
    {
        "value": "179988",
        "label": "Bobcat Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899041",
        "yellow_page_code": "08820",
        "kiosk": false
    },
    {
        "value": "179989",
        "label": "Ground Penetration Radar",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891011",
        "yellow_page_code": "39127, 20527",
        "kiosk": false
    },
    {
        "value": "179990",
        "label": "Flood Vents",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23839008",
        "yellow_page_code": "33105",
        "kiosk": false
    },
    {
        "value": "179991",
        "label": "Rip Rap",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23819011",
        "yellow_page_code": "72220",
        "kiosk": false
    },
    {
        "value": "179992",
        "label": "Skateboard Park Construction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23799030",
        "yellow_page_code": "78003",
        "kiosk": false
    },
    {
        "value": "179993",
        "label": "Mailbox Installation & Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899000",
        "yellow_page_code": "49624",
        "kiosk": false
    },
    {
        "value": "179994",
        "label": "Building Finishing Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23839009",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "179999",
        "label": "Concrete Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899037",
        "yellow_page_code": "20522",
        "kiosk": false
    },
    {
        "value": "201101",
        "label": "Meat Packers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31161102",
        "yellow_page_code": "50804, 78301, 78303, 00015, 41543",
        "kiosk": false
    },
    {
        "value": "201102",
        "label": "Delicatessen Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31161101",
        "yellow_page_code": "23735",
        "kiosk": false
    },
    {
        "value": "201103",
        "label": "Sausage Makers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31161104",
        "yellow_page_code": "74007",
        "kiosk": false
    },
    {
        "value": "201104",
        "label": "Meat Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31161103",
        "yellow_page_code": "50808, 21418, 43769",
        "kiosk": false
    },
    {
        "value": "201301",
        "label": "Sausage Casings (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31161206",
        "yellow_page_code": "74009",
        "kiosk": false
    },
    {
        "value": "201302",
        "label": "Bologna-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31161201",
        "yellow_page_code": "09105",
        "kiosk": false
    },
    {
        "value": "201303",
        "label": "Game Processing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31161203",
        "yellow_page_code": "36006, 93526",
        "kiosk": false
    },
    {
        "value": "201304",
        "label": "Meat Curing Service (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31161204",
        "yellow_page_code": "50709",
        "kiosk": false
    },
    {
        "value": "201305",
        "label": "Pork Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31161205",
        "yellow_page_code": "67405",
        "kiosk": false
    },
    {
        "value": "201306",
        "label": "Meat Processing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31161208",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "201398",
        "label": "Sausages/Other Prepared Meat Prod (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31161207",
        "yellow_page_code": "74105",
        "kiosk": false
    },
    {
        "value": "201501",
        "label": "Poultry Processing Plants (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31161501",
        "yellow_page_code": "67803",
        "kiosk": false
    },
    {
        "value": "201502",
        "label": "Eggs-Canned (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31199903",
        "yellow_page_code": "27236",
        "kiosk": false
    },
    {
        "value": "201503",
        "label": "Eggs-Dried (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31199904",
        "yellow_page_code": "27303",
        "kiosk": false
    },
    {
        "value": "202101",
        "label": "Creameries (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31151201",
        "yellow_page_code": "22505",
        "kiosk": false
    },
    {
        "value": "202198",
        "label": "Butter Manufacturing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31151202",
        "yellow_page_code": "11920",
        "kiosk": false
    },
    {
        "value": "202201",
        "label": "Cheese Processors (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31151301",
        "yellow_page_code": "14904",
        "kiosk": false
    },
    {
        "value": "202301",
        "label": "Ice Cream Mixes-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31151403",
        "yellow_page_code": "42411",
        "kiosk": false
    },
    {
        "value": "202302",
        "label": "Casein (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31151401",
        "yellow_page_code": "13916",
        "kiosk": false
    },
    {
        "value": "202398",
        "label": "Dry Condensed/Evprtd Dairy Prod (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31151402",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "202498",
        "label": "Ice Cream & Frozen Desserts (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31152001",
        "yellow_page_code": "42405",
        "kiosk": false
    },
    {
        "value": "202601",
        "label": "Yogurt-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31151102",
        "yellow_page_code": "94809",
        "kiosk": false
    },
    {
        "value": "202698",
        "label": "Fluid Milk (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31151101",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "203201",
        "label": "Poi-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31199911",
        "yellow_page_code": "66909",
        "kiosk": false
    },
    {
        "value": "203202",
        "label": "Ravioli-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31199916",
        "yellow_page_code": "70511",
        "kiosk": false
    },
    {
        "value": "203203",
        "label": "Chinese Food Products-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31199902",
        "yellow_page_code": "15434",
        "kiosk": false
    },
    {
        "value": "203204",
        "label": "Food Specialties-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31199907",
        "yellow_page_code": "33914",
        "kiosk": false
    },
    {
        "value": "203205",
        "label": "Pudding-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31199915",
        "yellow_page_code": "69014",
        "kiosk": false
    },
    {
        "value": "203298",
        "label": "Canned Specialties (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31142201",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "203301",
        "label": "Preserves/Jams & Jellies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31142106",
        "yellow_page_code": "68008, 44103",
        "kiosk": false
    },
    {
        "value": "203302",
        "label": "Canning (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31142101",
        "yellow_page_code": "12806, 12812",
        "kiosk": false
    },
    {
        "value": "203303",
        "label": "Juices-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31142103",
        "yellow_page_code": "44535",
        "kiosk": false
    },
    {
        "value": "203304",
        "label": "Sauces (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31142107",
        "yellow_page_code": "73936",
        "kiosk": false
    },
    {
        "value": "203305",
        "label": "Cranberries (Canners-Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31142102",
        "yellow_page_code": "22313",
        "kiosk": false
    },
    {
        "value": "203307",
        "label": "Vegetable Concentrates (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31142110",
        "yellow_page_code": "90700",
        "kiosk": false
    },
    {
        "value": "203401",
        "label": "Dehydrating Service (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31142302",
        "yellow_page_code": "23800",
        "kiosk": false
    },
    {
        "value": "203402",
        "label": "Beans Peas Lentils & Etc-Dried (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31142301",
        "yellow_page_code": "07114, 07120",
        "kiosk": false
    },
    {
        "value": "203403",
        "label": "Freeze Dried Foods (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31142304",
        "yellow_page_code": "34320",
        "kiosk": false
    },
    {
        "value": "203404",
        "label": "Soup Mix (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31199921",
        "yellow_page_code": "79470",
        "kiosk": false
    },
    {
        "value": "203498",
        "label": "Dried/Dehydrated Fruits Vegetables",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31142303",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "203501",
        "label": "Pickles & Pickle Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31142105",
        "yellow_page_code": "64908",
        "kiosk": false
    },
    {
        "value": "203502",
        "label": "Condiments & Sauces-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31194101",
        "yellow_page_code": "20811",
        "kiosk": false
    },
    {
        "value": "203503",
        "label": "Mayonnaise & Salad Dressings-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31194102",
        "yellow_page_code": "50636, 73437",
        "kiosk": false
    },
    {
        "value": "203504",
        "label": "Soy-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31142109",
        "yellow_page_code": "79511",
        "kiosk": false
    },
    {
        "value": "203505",
        "label": "Mustard (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31194103",
        "yellow_page_code": "55005",
        "kiosk": false
    },
    {
        "value": "203598",
        "label": "Pickled Fruits/Vegs & Dressings (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31142104",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "203701",
        "label": "Citrus Fruit Products-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31141101",
        "yellow_page_code": "18018",
        "kiosk": false
    },
    {
        "value": "203702",
        "label": "Frozen Fruit/Fruit Juices/Vegs (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31141102",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "203801",
        "label": "Frozen Food Processors (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31141202",
        "yellow_page_code": "34604",
        "kiosk": false
    },
    {
        "value": "203802",
        "label": "Frozen Canapes & Hors D'oeuvres-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31141201",
        "yellow_page_code": "34603",
        "kiosk": false
    },
    {
        "value": "203803",
        "label": "Pizza Products-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31141204",
        "yellow_page_code": "65913",
        "kiosk": false
    },
    {
        "value": "203804",
        "label": "Pierogies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31141203",
        "yellow_page_code": "60311",
        "kiosk": false
    },
    {
        "value": "204101",
        "label": "Flour Mills (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31121102",
        "yellow_page_code": "33503",
        "kiosk": false
    },
    {
        "value": "204102",
        "label": "Milling (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31121106",
        "yellow_page_code": "52407",
        "kiosk": false
    },
    {
        "value": "204103",
        "label": "Flour-Prepared (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31121103",
        "yellow_page_code": "33505",
        "kiosk": false
    },
    {
        "value": "204104",
        "label": "Gristmills (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31121105",
        "yellow_page_code": "39105",
        "kiosk": false
    },
    {
        "value": "204105",
        "label": "Food Products-Reconditioning (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31121104",
        "yellow_page_code": "33812",
        "kiosk": false
    },
    {
        "value": "204106",
        "label": "Corn Mills (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31121101",
        "yellow_page_code": "21705",
        "kiosk": false
    },
    {
        "value": "204107",
        "label": "Mills-Flour Oat & Bran (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31121107",
        "yellow_page_code": "52419",
        "kiosk": false
    },
    {
        "value": "204301",
        "label": "Cereals (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31123001",
        "yellow_page_code": "14502",
        "kiosk": false
    },
    {
        "value": "204401",
        "label": "Rice Mills (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31121201",
        "yellow_page_code": "72109",
        "kiosk": false
    },
    {
        "value": "204598",
        "label": "Prepared Flour Mixes & Doughs (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31182401",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "204601",
        "label": "Dextrine (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31122104",
        "yellow_page_code": "24335",
        "kiosk": false
    },
    {
        "value": "204602",
        "label": "Glucose-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31122102",
        "yellow_page_code": "37812",
        "kiosk": false
    },
    {
        "value": "204698",
        "label": "Wet Corn Milling (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31122103",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "204701",
        "label": "Dog & Cat Food (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31111101",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "204702",
        "label": "Dog Bakeries",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31111102",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "204801",
        "label": "Animal Health Products-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31111901",
        "yellow_page_code": "02516",
        "kiosk": false
    },
    {
        "value": "204802",
        "label": "Bird Feed-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31111902",
        "yellow_page_code": "08221",
        "kiosk": false
    },
    {
        "value": "204803",
        "label": "Feed-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31111903",
        "yellow_page_code": "31216, 60427",
        "kiosk": false
    },
    {
        "value": "204804",
        "label": "Livestock Feed (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31111905",
        "yellow_page_code": "48203",
        "kiosk": false
    },
    {
        "value": "204805",
        "label": "Horse Meat (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31111904",
        "yellow_page_code": "41513",
        "kiosk": false
    },
    {
        "value": "205101",
        "label": "Doughnuts-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31181203",
        "yellow_page_code": "25733",
        "kiosk": false
    },
    {
        "value": "205102",
        "label": "Bagels-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31181201",
        "yellow_page_code": "05714",
        "kiosk": false
    },
    {
        "value": "205198",
        "label": "Bread/Other Bakery Prod-Ex Cookies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31181202",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "205201",
        "label": "Cookies & Crackers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31182101",
        "yellow_page_code": "21413",
        "kiosk": false
    },
    {
        "value": "205202",
        "label": "Ice Cream Cone-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31191902",
        "yellow_page_code": "42312",
        "kiosk": false
    },
    {
        "value": "205203",
        "label": "Pretzels-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31191906",
        "yellow_page_code": "68035",
        "kiosk": false
    },
    {
        "value": "205398",
        "label": "Frozen Bakery Products-Except Bread (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31181301",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "206102",
        "label": "Sugar-Raw-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31131401",
        "yellow_page_code": "82201",
        "kiosk": false
    },
    {
        "value": "206201",
        "label": "Sugar Refiners (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31131403",
        "yellow_page_code": "82109, 82202",
        "kiosk": false
    },
    {
        "value": "206398",
        "label": "Beet Sugar (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31131301",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "206401",
        "label": "Candy & Confectionery-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31135201",
        "yellow_page_code": "12709, 20880",
        "kiosk": false
    },
    {
        "value": "206402",
        "label": "Licorice (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31134002",
        "yellow_page_code": "47116",
        "kiosk": false
    },
    {
        "value": "206403",
        "label": "Nonchocolate Confectionery Manufacturing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31134003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "206601",
        "label": "Chocolate & Cocoa-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31135101",
        "yellow_page_code": "15509",
        "kiosk": false
    },
    {
        "value": "206602",
        "label": "Cocoa Beans-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31135102",
        "yellow_page_code": "19205",
        "kiosk": false
    },
    {
        "value": "206701",
        "label": "Chewing Gum (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31134001",
        "yellow_page_code": "15106",
        "kiosk": false
    },
    {
        "value": "206801",
        "label": "Salted & Roasted Nuts & Seeds (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31191102",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "206802",
        "label": "Sunflower-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31191103",
        "yellow_page_code": "82215",
        "kiosk": false
    },
    {
        "value": "206803",
        "label": "Seeds-Coating (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31191101",
        "yellow_page_code": "75339",
        "kiosk": false
    },
    {
        "value": "206804",
        "label": "Roasted Nuts & Peanut Butter Manufacturing ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31191104",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "207401",
        "label": "Cottonseed Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31122503",
        "yellow_page_code": "22008",
        "kiosk": false
    },
    {
        "value": "207402",
        "label": "Cotton Oil Mills (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31122502",
        "yellow_page_code": "22101",
        "kiosk": false
    },
    {
        "value": "207403",
        "label": "Oil Seeds (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31122402",
        "yellow_page_code": "56805, 38612",
        "kiosk": false
    },
    {
        "value": "207501",
        "label": "Lecithin (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31122506",
        "yellow_page_code": "46805",
        "kiosk": false
    },
    {
        "value": "207598",
        "label": "Soybean Oil Mills (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31122401",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "207601",
        "label": "Tallow (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31122514",
        "yellow_page_code": "83406",
        "kiosk": false
    },
    {
        "value": "207698",
        "label": "Veg Oil Mills-Ex Corn Soybean (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31122515",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "207701",
        "label": "Animal Carcass Removal (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31161301",
        "yellow_page_code": "02408, 23512, 48113",
        "kiosk": false
    },
    {
        "value": "207702",
        "label": "Rendering Companies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31161302",
        "yellow_page_code": "71700, 02435",
        "kiosk": false
    },
    {
        "value": "207703",
        "label": "Fish By-Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31122505",
        "yellow_page_code": "32402",
        "kiosk": false
    },
    {
        "value": "207704",
        "label": "Fat Dealers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31122504",
        "yellow_page_code": "31011",
        "kiosk": false
    },
    {
        "value": "207705",
        "label": "Oils-Animal & Fish (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31122508",
        "yellow_page_code": "57405",
        "kiosk": false
    },
    {
        "value": "207798",
        "label": "Animal & Marine Fats & Oils (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31122501",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "207901",
        "label": "Margarine (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31122507",
        "yellow_page_code": "50004",
        "kiosk": false
    },
    {
        "value": "207902",
        "label": "Olive Oil (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31122511",
        "yellow_page_code": "57500",
        "kiosk": false
    },
    {
        "value": "207903",
        "label": "Shortening (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31122512",
        "yellow_page_code": "77203",
        "kiosk": false
    },
    {
        "value": "207904",
        "label": "Oils-Hydrogenated (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31122509",
        "yellow_page_code": "57208",
        "kiosk": false
    },
    {
        "value": "207905",
        "label": "Oils-Vegetable (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31122510",
        "yellow_page_code": "57209",
        "kiosk": false
    },
    {
        "value": "207998",
        "label": "Shortening Tbl Oils Margarine Etc (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31122513",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "208201",
        "label": "Brewers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31212002",
        "yellow_page_code": "10406, 10503, 52110, 07513, 07515",
        "kiosk": false
    },
    {
        "value": "208202",
        "label": "Beer/Ale Brwrs/Distr-Non-Alcoholic",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31212001",
        "yellow_page_code": "07512",
        "kiosk": false
    },
    {
        "value": "208203",
        "label": "Malt Extracts (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31212004",
        "yellow_page_code": "49736",
        "kiosk": false
    },
    {
        "value": "208298",
        "label": "Malt Beverages (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31212003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "208301",
        "label": "Maltsters (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31121302",
        "yellow_page_code": "49735",
        "kiosk": false
    },
    {
        "value": "208398",
        "label": "Malt (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31121301",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "208401",
        "label": "Wineries (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31213001",
        "yellow_page_code": "93804",
        "kiosk": false
    },
    {
        "value": "208498",
        "label": "Wines Brandy & Brandy Spirits (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31213002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "208501",
        "label": "Distillers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31214001",
        "yellow_page_code": "25108, 15804, 81404",
        "kiosk": false
    },
    {
        "value": "208601",
        "label": "Bottlers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31211101",
        "yellow_page_code": "09600",
        "kiosk": false
    },
    {
        "value": "208602",
        "label": "Soda Water-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31211201",
        "yellow_page_code": "79109",
        "kiosk": false
    },
    {
        "value": "208603",
        "label": "Soft Drink Products-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31211102",
        "yellow_page_code": "79112",
        "kiosk": false
    },
    {
        "value": "208604",
        "label": "Beverages-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31211103",
        "yellow_page_code": "07834",
        "kiosk": false
    },
    {
        "value": "208605",
        "label": "Mineral Water (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31211202",
        "yellow_page_code": "52507",
        "kiosk": false
    },
    {
        "value": "208606",
        "label": "Bottled Water Manufacturing ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31211203",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "208701",
        "label": "Flavoring Extracts-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31194203",
        "yellow_page_code": "32934",
        "kiosk": false
    },
    {
        "value": "208702",
        "label": "Beverage Powders (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31193001",
        "yellow_page_code": "07837",
        "kiosk": false
    },
    {
        "value": "208703",
        "label": "Colors-Food (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31194201",
        "yellow_page_code": "19903",
        "kiosk": false
    },
    {
        "value": "208798",
        "label": "Flavoring Extracts & Syrups NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31194202",
        "yellow_page_code": "33718",
        "kiosk": false
    },
    {
        "value": "209101",
        "label": "Fish Smoking & Curing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31171004",
        "yellow_page_code": "32401, 32506, 32508",
        "kiosk": false
    },
    {
        "value": "209102",
        "label": "Fish Packers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31171003",
        "yellow_page_code": "32502, 32412",
        "kiosk": false
    },
    {
        "value": "209104",
        "label": "Fish Cake Factories (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31171002",
        "yellow_page_code": "32403",
        "kiosk": false
    },
    {
        "value": "209105",
        "label": "Foods-Canned (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31171005",
        "yellow_page_code": "33911",
        "kiosk": false
    },
    {
        "value": "209198",
        "label": "Canned & Cured Fish & Seafoods (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31171001",
        "yellow_page_code": "32419, 32411",
        "kiosk": false
    },
    {
        "value": "209201",
        "label": "Seafood Packers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31171009",
        "yellow_page_code": "75112",
        "kiosk": false
    },
    {
        "value": "209202",
        "label": "Lobster Packers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31171007",
        "yellow_page_code": "48417",
        "kiosk": false
    },
    {
        "value": "209203",
        "label": "Fish Cleaning (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31171006",
        "yellow_page_code": "32404",
        "kiosk": false
    },
    {
        "value": "209204",
        "label": "Shrimp-Packers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31171010",
        "yellow_page_code": "77403",
        "kiosk": false
    },
    {
        "value": "209298",
        "label": "Prepared Fish & Seafood Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31171008",
        "yellow_page_code": "32420",
        "kiosk": false
    },
    {
        "value": "209501",
        "label": "Coffee & Tea Products-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31192001",
        "yellow_page_code": "19213",
        "kiosk": false
    },
    {
        "value": "209502",
        "label": "Coffee Mills (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31192002",
        "yellow_page_code": "19306",
        "kiosk": false
    },
    {
        "value": "209601",
        "label": "Popcorn Products-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31191903",
        "yellow_page_code": "67306",
        "kiosk": false
    },
    {
        "value": "209602",
        "label": "Chips (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31191901",
        "yellow_page_code": "15409, 30291, 78621",
        "kiosk": false
    },
    {
        "value": "209603",
        "label": "Potato Chip Factories (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31191904",
        "yellow_page_code": "67512",
        "kiosk": false
    },
    {
        "value": "209698",
        "label": "Potato Chips Corn Chips/Snacks (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31191905",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "209701",
        "label": "Ice-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31211302",
        "yellow_page_code": "42305",
        "kiosk": false
    },
    {
        "value": "209702",
        "label": "Ice Substitutes (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31211301",
        "yellow_page_code": "42507",
        "kiosk": false
    },
    {
        "value": "209898",
        "label": "Macaroni Spaghetti & Noodles (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31182403",
        "yellow_page_code": "49104, 55700, 59610",
        "kiosk": false
    },
    {
        "value": "209901",
        "label": "Potato Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31199913",
        "yellow_page_code": "67604",
        "kiosk": false
    },
    {
        "value": "209902",
        "label": "Vinegar (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31194104",
        "yellow_page_code": "91131",
        "kiosk": false
    },
    {
        "value": "209903",
        "label": "Food Products & Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31199906",
        "yellow_page_code": "33901, 33813, 33826, 08326, 30448",
        "kiosk": false
    },
    {
        "value": "209904",
        "label": "Garlic (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31194204",
        "yellow_page_code": "36208",
        "kiosk": false
    },
    {
        "value": "209905",
        "label": "Bakers Supplies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31199901",
        "yellow_page_code": "06001",
        "kiosk": false
    },
    {
        "value": "209906",
        "label": "Popcorn & Popcorn Supplies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31199912",
        "yellow_page_code": "67301",
        "kiosk": false
    },
    {
        "value": "209907",
        "label": "Syrups-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31199919",
        "yellow_page_code": "83004",
        "kiosk": false
    },
    {
        "value": "209908",
        "label": "Mexican Food Products-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31199909",
        "yellow_page_code": "52011",
        "kiosk": false
    },
    {
        "value": "209909",
        "label": "Natural Food Products-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31199910",
        "yellow_page_code": "55116",
        "kiosk": false
    },
    {
        "value": "209910",
        "label": "Health Food Products-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31199908",
        "yellow_page_code": "40418",
        "kiosk": false
    },
    {
        "value": "209911",
        "label": "Rice Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31199917",
        "yellow_page_code": "72111",
        "kiosk": false
    },
    {
        "value": "209912",
        "label": "Saimin (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31199918",
        "yellow_page_code": "73411",
        "kiosk": false
    },
    {
        "value": "209913",
        "label": "Salad Products-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31199101",
        "yellow_page_code": "73436",
        "kiosk": false
    },
    {
        "value": "209914",
        "label": "Sandwiches-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31199102",
        "yellow_page_code": "73813",
        "kiosk": false
    },
    {
        "value": "209915",
        "label": "Sauerkraut (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31142108",
        "yellow_page_code": "74002",
        "kiosk": false
    },
    {
        "value": "209916",
        "label": "Spices-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31194205",
        "yellow_page_code": "79910",
        "kiosk": false
    },
    {
        "value": "209917",
        "label": "Processing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31199914",
        "yellow_page_code": "68515",
        "kiosk": false
    },
    {
        "value": "209918",
        "label": "Nutritional Food Supplements-Mfr",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31199920",
        "yellow_page_code": "56005",
        "kiosk": false
    },
    {
        "value": "209919",
        "label": "Barbecue Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31199922",
        "yellow_page_code": "06510",
        "kiosk": false
    },
    {
        "value": "209920",
        "label": "Tortilla Manufacturing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31183001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "209998",
        "label": "Food Preparations NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31199905",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "211198",
        "label": "Cigarettes (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31223002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "212101",
        "label": "Cigar Cigarette & Tobacco-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31223003",
        "yellow_page_code": "17902",
        "kiosk": false
    },
    {
        "value": "212198",
        "label": "Cigars (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31223004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "213101",
        "label": "Tobacco Products-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31223005",
        "yellow_page_code": "86707, 86708",
        "kiosk": false
    },
    {
        "value": "214198",
        "label": "Tobacco Stemming & Redrying (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31223001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "221101",
        "label": "Ticking (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31321023",
        "yellow_page_code": "85903",
        "kiosk": false
    },
    {
        "value": "221103",
        "label": "Cotton Goods-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31321006",
        "yellow_page_code": "22016, 22000",
        "kiosk": false
    },
    {
        "value": "221104",
        "label": "Terry Cloth (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31321021",
        "yellow_page_code": "85201",
        "kiosk": false
    },
    {
        "value": "221105",
        "label": "Wovens-Yarn Dyed Fabric-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31321027",
        "yellow_page_code": "94411",
        "kiosk": false
    },
    {
        "value": "221106",
        "label": "Canvas-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31321002",
        "yellow_page_code": "12934",
        "kiosk": false
    },
    {
        "value": "221107",
        "label": "Fabrics-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31321010",
        "yellow_page_code": "30417",
        "kiosk": false
    },
    {
        "value": "221108",
        "label": "Fabrics-Industrial (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31321009",
        "yellow_page_code": "30415",
        "kiosk": false
    },
    {
        "value": "221109",
        "label": "Cotton Finishers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31321005",
        "yellow_page_code": "22013",
        "kiosk": false
    },
    {
        "value": "221110",
        "label": "Corduroys (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31321004",
        "yellow_page_code": "21611",
        "kiosk": false
    },
    {
        "value": "221111",
        "label": "Pillow Cases (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31321015",
        "yellow_page_code": "65301",
        "kiosk": false
    },
    {
        "value": "221112",
        "label": "Uniform Fabrics (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31321025",
        "yellow_page_code": "90001",
        "kiosk": false
    },
    {
        "value": "221113",
        "label": "Tarlatans (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31321020",
        "yellow_page_code": "83908",
        "kiosk": false
    },
    {
        "value": "221114",
        "label": "Sails-Repair & Recondition (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31321017",
        "yellow_page_code": "73414, 73415",
        "kiosk": false
    },
    {
        "value": "221115",
        "label": "Nets & Nettings-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31321013",
        "yellow_page_code": "55401",
        "kiosk": false
    },
    {
        "value": "221198",
        "label": "Broadwoven Fabric Mills-Cotton (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31321001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "222101",
        "label": "Textile Mills (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31321022",
        "yellow_page_code": "85400, 47597",
        "kiosk": false
    },
    {
        "value": "222102",
        "label": "Silks-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31321018",
        "yellow_page_code": "77604",
        "kiosk": false
    },
    {
        "value": "222104",
        "label": "Draperies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31321007",
        "yellow_page_code": "25907",
        "kiosk": false
    },
    {
        "value": "222105",
        "label": "Synthetic & Blended Fabrics-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31321019",
        "yellow_page_code": "82912",
        "kiosk": false
    },
    {
        "value": "222106",
        "label": "Drapery Fabrics-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31321008",
        "yellow_page_code": "26001",
        "kiosk": false
    },
    {
        "value": "222107",
        "label": "Linings-Drapery (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31321012",
        "yellow_page_code": "47612",
        "kiosk": false
    },
    {
        "value": "222108",
        "label": "Chenilles (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31321003",
        "yellow_page_code": "15104",
        "kiosk": false
    },
    {
        "value": "222109",
        "label": "Pile Fabrics (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31321014",
        "yellow_page_code": "65206",
        "kiosk": false
    },
    {
        "value": "222110",
        "label": "Plushes (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31321016",
        "yellow_page_code": "66811",
        "kiosk": false
    },
    {
        "value": "222111",
        "label": "Polypropylene Materials-Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31321028",
        "yellow_page_code": "67119",
        "kiosk": false
    },
    {
        "value": "222112",
        "label": "Draperies & Curtains Pattern Makers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31321024",
        "yellow_page_code": "25914",
        "kiosk": false
    },
    {
        "value": "223101",
        "label": "Woolen Mills (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331011",
        "yellow_page_code": "94100",
        "kiosk": false
    },
    {
        "value": "223102",
        "label": "Haircloth (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331008",
        "yellow_page_code": "39612",
        "kiosk": false
    },
    {
        "value": "223103",
        "label": "Cloth Shrinking & Sponging (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331012",
        "yellow_page_code": "18705",
        "kiosk": false
    },
    {
        "value": "224101",
        "label": "Elastic Goods (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31322004",
        "yellow_page_code": "27305",
        "kiosk": false
    },
    {
        "value": "224102",
        "label": "Braids (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31322001",
        "yellow_page_code": "10108",
        "kiosk": false
    },
    {
        "value": "224103",
        "label": "Laces-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31322007",
        "yellow_page_code": "45415",
        "kiosk": false
    },
    {
        "value": "224104",
        "label": "Cord Trimming (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31322002",
        "yellow_page_code": "21612",
        "kiosk": false
    },
    {
        "value": "224105",
        "label": "Ribbon Novelties (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31322009",
        "yellow_page_code": "72105",
        "kiosk": false
    },
    {
        "value": "224106",
        "label": "Tassels (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31322014",
        "yellow_page_code": "84005",
        "kiosk": false
    },
    {
        "value": "224107",
        "label": "Underwear Trimmings (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31322015",
        "yellow_page_code": "89815",
        "kiosk": false
    },
    {
        "value": "224108",
        "label": "Tapes-Labeling (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31322013",
        "yellow_page_code": "83801",
        "kiosk": false
    },
    {
        "value": "224109",
        "label": "Corset Materials (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31322003",
        "yellow_page_code": "21811",
        "kiosk": false
    },
    {
        "value": "224110",
        "label": "Fringes (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31322005",
        "yellow_page_code": "34511",
        "kiosk": false
    },
    {
        "value": "224111",
        "label": "Labels-Woven (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31322006",
        "yellow_page_code": "45109",
        "kiosk": false
    },
    {
        "value": "224112",
        "label": "Tag Stringing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31322012",
        "yellow_page_code": "83209",
        "kiosk": false
    },
    {
        "value": "224113",
        "label": "Silk Warpers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31322010",
        "yellow_page_code": "77610",
        "kiosk": false
    },
    {
        "value": "224114",
        "label": "Strapping & Strapping Equipment-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31322011",
        "yellow_page_code": "81921",
        "kiosk": false
    },
    {
        "value": "224198",
        "label": "Narrow Fabric & Other Smallwares-Mills",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31322008",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "225101",
        "label": "Tights-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31512003",
        "yellow_page_code": "85911",
        "kiosk": false
    },
    {
        "value": "225102",
        "label": "Hosiery-Finishing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31512001",
        "yellow_page_code": "41703",
        "kiosk": false
    },
    {
        "value": "225198",
        "label": "Womens Full-Length/Knee-Lnth Hsry (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31512004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "225201",
        "label": "Hosiery-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31512002",
        "yellow_page_code": "41705",
        "kiosk": false
    },
    {
        "value": "225301",
        "label": "T-Shirts-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31512009",
        "yellow_page_code": "83011",
        "kiosk": false
    },
    {
        "value": "225302",
        "label": "Sweaters-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31512008",
        "yellow_page_code": "82612",
        "kiosk": false
    },
    {
        "value": "225303",
        "label": "Bathing Suits-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31512006",
        "yellow_page_code": "06916",
        "kiosk": false
    },
    {
        "value": "225304",
        "label": "Bathing Suit Materials (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31512005",
        "yellow_page_code": "06911",
        "kiosk": false
    },
    {
        "value": "225398",
        "label": "Knit Outerwear Mills (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31512007",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "225401",
        "label": "Underwear (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31512011",
        "yellow_page_code": "89820",
        "kiosk": false
    },
    {
        "value": "225402",
        "label": "Robes & Dressing Gowns-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31512013",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "225498",
        "label": "Knit Underwear & Nightwear Mills (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31512010",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "225798",
        "label": "Weft Knit Fabric Mills (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31324001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "225801",
        "label": "Knitted Fabrics (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31324004",
        "yellow_page_code": "45005",
        "kiosk": false
    },
    {
        "value": "225802",
        "label": "Knit Goods-Processing & Dyeing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331026",
        "yellow_page_code": "45007",
        "kiosk": false
    },
    {
        "value": "225898",
        "label": "Lace & Warp Knit Fabric Mills (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31324006",
        "yellow_page_code": "45420",
        "kiosk": false
    },
    {
        "value": "225901",
        "label": "Knit Goods-Contractors (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331024",
        "yellow_page_code": "45004",
        "kiosk": false
    },
    {
        "value": "225902",
        "label": "Towels-Cloth-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31512012",
        "yellow_page_code": "87413",
        "kiosk": false
    },
    {
        "value": "225903",
        "label": "Curtains-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31324003",
        "yellow_page_code": "23014",
        "kiosk": false
    },
    {
        "value": "225904",
        "label": "Knit Goods-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331025",
        "yellow_page_code": "45013",
        "kiosk": false
    },
    {
        "value": "225905",
        "label": "Bagging-Cotton Bale (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31324002",
        "yellow_page_code": "05711",
        "kiosk": false
    },
    {
        "value": "225906",
        "label": "Apparel Knitting Mills",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31512014",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "225998",
        "label": "Knitting Mills NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31324005",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "226101",
        "label": "Textile Finishing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331009",
        "yellow_page_code": "85402",
        "kiosk": false
    },
    {
        "value": "226102",
        "label": "Flock-Manufacturers & Processors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331004",
        "yellow_page_code": "33008",
        "kiosk": false
    },
    {
        "value": "226103",
        "label": "Bleachery (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331001",
        "yellow_page_code": "08414",
        "kiosk": false
    },
    {
        "value": "226104",
        "label": "Garment Curing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331007",
        "yellow_page_code": "36311",
        "kiosk": false
    },
    {
        "value": "226201",
        "label": "Flocking (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331005",
        "yellow_page_code": "33009",
        "kiosk": false
    },
    {
        "value": "226202",
        "label": "Flocking Equipment & Supplies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331006",
        "yellow_page_code": "33014",
        "kiosk": false
    },
    {
        "value": "226203",
        "label": "Flock-Finishing Supplies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331003",
        "yellow_page_code": "33101",
        "kiosk": false
    },
    {
        "value": "226298",
        "label": "Finishers-Broadwoven Fbrcs-Manmade",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "226901",
        "label": "Textile Converting-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331033",
        "yellow_page_code": "85317",
        "kiosk": false
    },
    {
        "value": "226902",
        "label": "Dyers-Industrial (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331019",
        "yellow_page_code": "27003",
        "kiosk": false
    },
    {
        "value": "226903",
        "label": "Cotton Mills (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331041",
        "yellow_page_code": "22007",
        "kiosk": false
    },
    {
        "value": "226904",
        "label": "Linens-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331042",
        "yellow_page_code": "47605",
        "kiosk": false
    },
    {
        "value": "226905",
        "label": "Smocking (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331021",
        "yellow_page_code": "78435",
        "kiosk": false
    },
    {
        "value": "226998",
        "label": "Finishers Of Textiles NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331022",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "227301",
        "label": "Carpet & Rug-Weavers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31411003",
        "yellow_page_code": "13802, 13738, 92805, 13720",
        "kiosk": false
    },
    {
        "value": "227302",
        "label": "Automobile Floor Coverings-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31411001",
        "yellow_page_code": "04823",
        "kiosk": false
    },
    {
        "value": "227303",
        "label": "Carpet & Rug-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31411002",
        "yellow_page_code": "13513, 13612",
        "kiosk": false
    },
    {
        "value": "228101",
        "label": "Cotton Processing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31311001",
        "yellow_page_code": "22006",
        "kiosk": false
    },
    {
        "value": "228102",
        "label": "Yarn-Spinning Mills (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31311003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "228103",
        "label": "Wool Carding (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31311002",
        "yellow_page_code": "94331",
        "kiosk": false
    },
    {
        "value": "228298",
        "label": "Yarn-Texturizing Throwing/Winding Mills",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331040",
        "yellow_page_code": "94720",
        "kiosk": false
    },
    {
        "value": "228401",
        "label": "Notions-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31311008",
        "yellow_page_code": "55705",
        "kiosk": false
    },
    {
        "value": "228402",
        "label": "Thread-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31311010",
        "yellow_page_code": "85811, 85810",
        "kiosk": false
    },
    {
        "value": "228403",
        "label": "Thread-Cutting-Lace/Fabric & Etc (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31311009",
        "yellow_page_code": "85803",
        "kiosk": false
    },
    {
        "value": "229501",
        "label": "Fabric Laminating & Bonding (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31332004",
        "yellow_page_code": "30409",
        "kiosk": false
    },
    {
        "value": "229502",
        "label": "Leather-Artificial (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31332005",
        "yellow_page_code": "46608",
        "kiosk": false
    },
    {
        "value": "229503",
        "label": "Metallic Fabrics (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31332006",
        "yellow_page_code": "51803",
        "kiosk": false
    },
    {
        "value": "229504",
        "label": "Buckram (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31332002",
        "yellow_page_code": "11102",
        "kiosk": false
    },
    {
        "value": "229505",
        "label": "Backing Cloths-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31332001",
        "yellow_page_code": "05609",
        "kiosk": false
    },
    {
        "value": "229506",
        "label": "Waterproofing Materials-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31332007",
        "yellow_page_code": "23295, 92621",
        "kiosk": false
    },
    {
        "value": "229598",
        "label": "Coated Fabrics-Not Rubberized (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31332003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "229698",
        "label": "Tire Cord & Fabrics (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31499406",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "229701",
        "label": "Nonwoven Fabrics (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31323002",
        "yellow_page_code": "55607, 30420",
        "kiosk": false
    },
    {
        "value": "229702",
        "label": "Blankets-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31323003",
        "yellow_page_code": "08334",
        "kiosk": false
    },
    {
        "value": "229703",
        "label": "Carpet & Rug-Pads & Linings-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31323004",
        "yellow_page_code": "13607",
        "kiosk": false
    },
    {
        "value": "229801",
        "label": "Fish Nets-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31499402",
        "yellow_page_code": "32512",
        "kiosk": false
    },
    {
        "value": "229802",
        "label": "Rope-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31499403",
        "yellow_page_code": "72804",
        "kiosk": false
    },
    {
        "value": "229804",
        "label": "Trawls (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31499404",
        "yellow_page_code": "88434",
        "kiosk": false
    },
    {
        "value": "229805",
        "label": "Fiber Yarn & Thread Mills ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31311011",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "229898",
        "label": "Twines & Cordage-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31499401",
        "yellow_page_code": "89611",
        "kiosk": false
    },
    {
        "value": "229901",
        "label": "Textile Fibers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31499923",
        "yellow_page_code": "85401, 30043",
        "kiosk": false
    },
    {
        "value": "229902",
        "label": "Felt Products-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31323001",
        "yellow_page_code": "31236",
        "kiosk": false
    },
    {
        "value": "229903",
        "label": "Flax (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31311004",
        "yellow_page_code": "32935",
        "kiosk": false
    },
    {
        "value": "229904",
        "label": "Geotextiles-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31499909",
        "yellow_page_code": "37214",
        "kiosk": false
    },
    {
        "value": "229905",
        "label": "Car Trimming & Upholstery Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31499932",
        "yellow_page_code": "13069",
        "kiosk": false
    },
    {
        "value": "229906",
        "label": "Wadding-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31499929",
        "yellow_page_code": "91304",
        "kiosk": false
    },
    {
        "value": "229907",
        "label": "Macrame-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31311005",
        "yellow_page_code": "49311",
        "kiosk": false
    },
    {
        "value": "229908",
        "label": "Needlework & Needlework Materials-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31311006",
        "yellow_page_code": "55405",
        "kiosk": false
    },
    {
        "value": "229909",
        "label": "Oriental Goods-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31499906",
        "yellow_page_code": "57913",
        "kiosk": false
    },
    {
        "value": "229910",
        "label": "Sisal (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31311007",
        "yellow_page_code": "77901",
        "kiosk": false
    },
    {
        "value": "229912",
        "label": "Batting (Quilting Material-Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31499902",
        "yellow_page_code": "07095",
        "kiosk": false
    },
    {
        "value": "229913",
        "label": "Mill Ends (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31499926",
        "yellow_page_code": "52420",
        "kiosk": false
    },
    {
        "value": "229915",
        "label": "Wool Scouring (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31321026",
        "yellow_page_code": "94402",
        "kiosk": false
    },
    {
        "value": "229916",
        "label": "Wool Garnetting (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31499934",
        "yellow_page_code": "94404",
        "kiosk": false
    },
    {
        "value": "229917",
        "label": "Textile Mill Supplies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331043",
        "yellow_page_code": "85390",
        "kiosk": false
    },
    {
        "value": "229918",
        "label": "Oakum (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31499913",
        "yellow_page_code": "56103",
        "kiosk": false
    },
    {
        "value": "229919",
        "label": "Textile Workers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31499925",
        "yellow_page_code": "85321",
        "kiosk": false
    },
    {
        "value": "229920",
        "label": "Mills Fiber",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31321011",
        "yellow_page_code": "52411",
        "kiosk": false
    },
    {
        "value": "229998",
        "label": "Textile Goods NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31499924",
        "yellow_page_code": "18702",
        "kiosk": false
    },
    {
        "value": "231101",
        "label": "Army & Navy Goods-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525002",
        "yellow_page_code": "03309",
        "kiosk": false
    },
    {
        "value": "231102",
        "label": "Formal Wear-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525004",
        "yellow_page_code": "34116",
        "kiosk": false
    },
    {
        "value": "231103",
        "label": "Police Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525006",
        "yellow_page_code": "67007",
        "kiosk": false
    },
    {
        "value": "231104",
        "label": "Coats-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525003",
        "yellow_page_code": "19200",
        "kiosk": false
    },
    {
        "value": "231198",
        "label": "Mens & Boys Suits Coats/Overcoats (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525005",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "232101",
        "label": "Shirts-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525008",
        "yellow_page_code": "76608",
        "kiosk": false
    },
    {
        "value": "232102",
        "label": "Hawaiian Wear-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525007",
        "yellow_page_code": "40318",
        "kiosk": false
    },
    {
        "value": "232201",
        "label": "Underwear-Mens-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525001",
        "yellow_page_code": "89823",
        "kiosk": false
    },
    {
        "value": "232301",
        "label": "Neckwear-Mens-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31599008",
        "yellow_page_code": "55301",
        "kiosk": false
    },
    {
        "value": "232302",
        "label": "Neckwear-Manufacturers Supplies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31599007",
        "yellow_page_code": "55236",
        "kiosk": false
    },
    {
        "value": "232303",
        "label": "Tie Factories (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31599009",
        "yellow_page_code": "85934",
        "kiosk": false
    },
    {
        "value": "232501",
        "label": "Pants-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525010",
        "yellow_page_code": "58707",
        "kiosk": false
    },
    {
        "value": "232502",
        "label": "Jeans-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525009",
        "yellow_page_code": "44217",
        "kiosk": false
    },
    {
        "value": "232602",
        "label": "Overalls-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525011",
        "yellow_page_code": "58135",
        "kiosk": false
    },
    {
        "value": "232603",
        "label": "Mens & Boys Work Clothing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525012",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "232604",
        "label": "Uniforms-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525013",
        "yellow_page_code": "90015, 90016",
        "kiosk": false
    },
    {
        "value": "232605",
        "label": "Clothes-Military-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525014",
        "yellow_page_code": "18745",
        "kiosk": false
    },
    {
        "value": "232901",
        "label": "Men's Clothing-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525018",
        "yellow_page_code": "51402",
        "kiosk": false
    },
    {
        "value": "232902",
        "label": "Sportswear-Mens-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525054",
        "yellow_page_code": "80101",
        "kiosk": false
    },
    {
        "value": "232903",
        "label": "Jackets-Apparel-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525017",
        "yellow_page_code": "44013",
        "kiosk": false
    },
    {
        "value": "232904",
        "label": "Men's Furnishings-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525019",
        "yellow_page_code": "51212",
        "kiosk": false
    },
    {
        "value": "232905",
        "label": "Western Apparel-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525021",
        "yellow_page_code": "93233",
        "kiosk": false
    },
    {
        "value": "232906",
        "label": "Riding Apparel & Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525020",
        "yellow_page_code": "72136",
        "kiosk": false
    },
    {
        "value": "232907",
        "label": "Boys Clothing & Furnishings-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525015",
        "yellow_page_code": "10101",
        "kiosk": false
    },
    {
        "value": "232908",
        "label": "Clothes & Accessories-Mens-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525016",
        "yellow_page_code": "18724",
        "kiosk": false
    },
    {
        "value": "233101",
        "label": "Womens Misses Jrs Blouses/Shirts (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525031",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "233102",
        "label": "Women's Apparel-Contract Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525032",
        "yellow_page_code": "94102",
        "kiosk": false
    },
    {
        "value": "233103",
        "label": "Women's Apparel-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525033",
        "yellow_page_code": "94113",
        "kiosk": false
    },
    {
        "value": "233104",
        "label": "Apparel Design & Mfg",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525034",
        "yellow_page_code": "02861",
        "kiosk": false
    },
    {
        "value": "233501",
        "label": "Dresses-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525037",
        "yellow_page_code": "26205",
        "kiosk": false
    },
    {
        "value": "233502",
        "label": "Bridal Gowns-Womens Misses & Jrs-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525035",
        "yellow_page_code": "10607",
        "kiosk": false
    },
    {
        "value": "233503",
        "label": "Ladies Ready To Wear-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525038",
        "yellow_page_code": "45515",
        "kiosk": false
    },
    {
        "value": "233701",
        "label": "Women's Coats & Suits-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525039",
        "yellow_page_code": "94112",
        "kiosk": false
    },
    {
        "value": "233798",
        "label": "Womens Misses Jr Suits Skrts/Coats",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525040",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "233901",
        "label": "Neckwear-Womens-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31599021",
        "yellow_page_code": "55303",
        "kiosk": false
    },
    {
        "value": "233902",
        "label": "Sportswear-Womens-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525055",
        "yellow_page_code": "80103",
        "kiosk": false
    },
    {
        "value": "233903",
        "label": "Aprons-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31599011",
        "yellow_page_code": "03016",
        "kiosk": false
    },
    {
        "value": "233904",
        "label": "Smocks-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31599027",
        "yellow_page_code": "78436",
        "kiosk": false
    },
    {
        "value": "233905",
        "label": "Swimwear & Accessories-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31599029",
        "yellow_page_code": "82807",
        "kiosk": false
    },
    {
        "value": "233906",
        "label": "Clothes & Accessories-Womens-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525041",
        "yellow_page_code": "18726",
        "kiosk": false
    },
    {
        "value": "233907",
        "label": "Clothing-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31599017",
        "yellow_page_code": "18713",
        "kiosk": false
    },
    {
        "value": "233998",
        "label": "Women's Misses/Jrs Outerwear NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525042",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "234101",
        "label": "Lingerie-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525026",
        "yellow_page_code": "47608",
        "kiosk": false
    },
    {
        "value": "234102",
        "label": "Negligees-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525027",
        "yellow_page_code": "55309",
        "kiosk": false
    },
    {
        "value": "234103",
        "label": "Pajamas (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525028",
        "yellow_page_code": "69311",
        "kiosk": false
    },
    {
        "value": "234105",
        "label": "Robes-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525024",
        "yellow_page_code": "72307",
        "kiosk": false
    },
    {
        "value": "234198",
        "label": "Womens Misses/Childrens Underwear (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525029",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "234201",
        "label": "Brassiere & Corset Materials (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525022",
        "yellow_page_code": "10316",
        "kiosk": false
    },
    {
        "value": "234202",
        "label": "Corset Steel Makers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31521004",
        "yellow_page_code": "21817",
        "kiosk": false
    },
    {
        "value": "234203",
        "label": "Bridal Supplies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31521002",
        "yellow_page_code": "10702",
        "kiosk": false
    },
    {
        "value": "234204",
        "label": "Maternity Apparel-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525025",
        "yellow_page_code": "50501",
        "kiosk": false
    },
    {
        "value": "234206",
        "label": "Dressing Gowns-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525030",
        "yellow_page_code": "26211",
        "kiosk": false
    },
    {
        "value": "234207",
        "label": "Cut & Sew Apparel Contractors ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31521005",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "234298",
        "label": "Brassieres Girdles/Allied Grmnts (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525023",
        "yellow_page_code": "10317",
        "kiosk": false
    },
    {
        "value": "235301",
        "label": "Apparel-Womens Millinery-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31521003",
        "yellow_page_code": "02818",
        "kiosk": false
    },
    {
        "value": "235302",
        "label": "Hats-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31599001",
        "yellow_page_code": "40215",
        "kiosk": false
    },
    {
        "value": "235303",
        "label": "Hats Caps & Millinery (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31599002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "236101",
        "label": "Girls Apparel-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31521001",
        "yellow_page_code": "37336",
        "kiosk": false
    },
    {
        "value": "236901",
        "label": "Clothes & Accessories-Childrens-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525043",
        "yellow_page_code": "18727, 15227, 18746",
        "kiosk": false
    },
    {
        "value": "237101",
        "label": "Fur Finishers & Liners (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525045",
        "yellow_page_code": "35309",
        "kiosk": false
    },
    {
        "value": "237102",
        "label": "Fur Goods (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525044",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "237104",
        "label": "Fur Matching (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525046",
        "yellow_page_code": "35204",
        "kiosk": false
    },
    {
        "value": "237105",
        "label": "Textile Mounting (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525050",
        "yellow_page_code": "85319",
        "kiosk": false
    },
    {
        "value": "238101",
        "label": "Gloves-Work & Industrial-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31599005",
        "yellow_page_code": "37813, 37818",
        "kiosk": false
    },
    {
        "value": "238102",
        "label": "Gloves-Womens-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31599004",
        "yellow_page_code": "37811",
        "kiosk": false
    },
    {
        "value": "238198",
        "label": "Dress/Work Gloves-Ex Knit/Leather (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31599003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "238501",
        "label": "Waterproof Outerwear (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525056",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "238502",
        "label": "Waterproofing-Textile (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525057",
        "yellow_page_code": "92609",
        "kiosk": false
    },
    {
        "value": "238503",
        "label": "Aprons-Industrial-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31599033",
        "yellow_page_code": "03020",
        "kiosk": false
    },
    {
        "value": "238601",
        "label": "Leather Apparel-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525048",
        "yellow_page_code": "46601",
        "kiosk": false
    },
    {
        "value": "238602",
        "label": "Sheepskin Specialties-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525049",
        "yellow_page_code": "76114",
        "kiosk": false
    },
    {
        "value": "238698",
        "label": "Leather & Sheep-Lined Clothing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525047",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "238701",
        "label": "Belts-Mens-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31599015",
        "yellow_page_code": "07609",
        "kiosk": false
    },
    {
        "value": "238702",
        "label": "Belts-Womens-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31599016",
        "yellow_page_code": "07703",
        "kiosk": false
    },
    {
        "value": "238703",
        "label": "Belts-Manufacturers Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31599014",
        "yellow_page_code": "07605",
        "kiosk": false
    },
    {
        "value": "238704",
        "label": "Belts & Suspenders-Mfrs Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31599013",
        "yellow_page_code": "07634",
        "kiosk": false
    },
    {
        "value": "238705",
        "label": "Belts & Suspenders-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31599012",
        "yellow_page_code": "07713",
        "kiosk": false
    },
    {
        "value": "238798",
        "label": "Apparel-Belts (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31599010",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "238901",
        "label": "Costume Fabrics Trimmings & Access-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525051",
        "yellow_page_code": "21915",
        "kiosk": false
    },
    {
        "value": "238902",
        "label": "Costumes-Masquerade & Theatrical-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525052",
        "yellow_page_code": "21914",
        "kiosk": false
    },
    {
        "value": "238904",
        "label": "Handkerchiefs (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31599019",
        "yellow_page_code": "40103",
        "kiosk": false
    },
    {
        "value": "238905",
        "label": "Masks-Masquerade & Novelty (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525053",
        "yellow_page_code": "50300",
        "kiosk": false
    },
    {
        "value": "238906",
        "label": "Disposable Garments (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525058",
        "yellow_page_code": "25112",
        "kiosk": false
    },
    {
        "value": "238907",
        "label": "Bowling Apparel & Accessories-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31599031",
        "yellow_page_code": "09712",
        "kiosk": false
    },
    {
        "value": "238908",
        "label": "Cut & Sew Apparel-Except Contractors (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525059",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "238998",
        "label": "Apparel & Accessories NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31599032",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "239101",
        "label": "Curtain & Linen Mills",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31412007",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "239198",
        "label": "Curtain & Drapery-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31412001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "239201",
        "label": "Furniture Pads (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31491003",
        "yellow_page_code": "35802",
        "kiosk": false
    },
    {
        "value": "239202",
        "label": "Pillows-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31491006",
        "yellow_page_code": "65307",
        "kiosk": false
    },
    {
        "value": "239203",
        "label": "Wiping Cloths-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31491010",
        "yellow_page_code": "93836",
        "kiosk": false
    },
    {
        "value": "239204",
        "label": "Quilts-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31491007",
        "yellow_page_code": "69436",
        "kiosk": false
    },
    {
        "value": "239205",
        "label": "Slip Covers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31412006",
        "yellow_page_code": "78335",
        "kiosk": false
    },
    {
        "value": "239206",
        "label": "Bedspreads-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31491002",
        "yellow_page_code": "07436",
        "kiosk": false
    },
    {
        "value": "239207",
        "label": "Curtains-Shower & Bath-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31412005",
        "yellow_page_code": "22914",
        "kiosk": false
    },
    {
        "value": "239208",
        "label": "House Furnishings-Ex Draperies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31499930",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "239209",
        "label": "Garment Bags & Covers (Fabric-Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31491004",
        "yellow_page_code": "36307",
        "kiosk": false
    },
    {
        "value": "239210",
        "label": "Closet Accessories-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31412004",
        "yellow_page_code": "18612",
        "kiosk": false
    },
    {
        "value": "239211",
        "label": "Ironing Board Covers & Pads (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31491005",
        "yellow_page_code": "43812",
        "kiosk": false
    },
    {
        "value": "239212",
        "label": "Mops-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999405",
        "yellow_page_code": "53613",
        "kiosk": false
    },
    {
        "value": "239213",
        "label": "Wax Applicators (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999407",
        "yellow_page_code": "92703",
        "kiosk": false
    },
    {
        "value": "239214",
        "label": "Shoe Bags (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31491008",
        "yellow_page_code": "76701",
        "kiosk": false
    },
    {
        "value": "239301",
        "label": "Bags-Burlap Canvas & Cotton-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31491001",
        "yellow_page_code": "05804",
        "kiosk": false
    },
    {
        "value": "239398",
        "label": "Textile Bags (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31491009",
        "yellow_page_code": "85308",
        "kiosk": false
    },
    {
        "value": "239401",
        "label": "Sailmakers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31491017",
        "yellow_page_code": "73408, 30358",
        "kiosk": false
    },
    {
        "value": "239402",
        "label": "Awnings & Canopies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31491011",
        "yellow_page_code": "05413",
        "kiosk": false
    },
    {
        "value": "239403",
        "label": "Yurts (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31491020",
        "yellow_page_code": "94811",
        "kiosk": false
    },
    {
        "value": "239404",
        "label": "Boat Covers Tops & Upholstery-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31491012",
        "yellow_page_code": "08712",
        "kiosk": false
    },
    {
        "value": "239405",
        "label": "Tents-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31491019",
        "yellow_page_code": "85137, 50213",
        "kiosk": false
    },
    {
        "value": "239406",
        "label": "Canvas & Related Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31491013",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "239407",
        "label": "Tarpaulins-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31491018",
        "yellow_page_code": "84003",
        "kiosk": false
    },
    {
        "value": "239408",
        "label": "Fish Houses-Portable (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31491016",
        "yellow_page_code": "32500",
        "kiosk": false
    },
    {
        "value": "239409",
        "label": "Drop Cloths (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31491015",
        "yellow_page_code": "26503",
        "kiosk": false
    },
    {
        "value": "239410",
        "label": "Canvas Products-Custom Made",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31491014",
        "yellow_page_code": "12935",
        "kiosk": false
    },
    {
        "value": "239411",
        "label": "Sail Fabrics (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31491021",
        "yellow_page_code": "73416",
        "kiosk": false
    },
    {
        "value": "239501",
        "label": "Pleating & Hemstitching (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31499915",
        "yellow_page_code": "66606, 40933",
        "kiosk": false
    },
    {
        "value": "239502",
        "label": "Patches (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31499931",
        "yellow_page_code": "59609",
        "kiosk": false
    },
    {
        "value": "239503",
        "label": "Quilting-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31499918",
        "yellow_page_code": "69409",
        "kiosk": false
    },
    {
        "value": "239504",
        "label": "Crests-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31499904",
        "yellow_page_code": "22513",
        "kiosk": false
    },
    {
        "value": "239505",
        "label": "Scallop Cutting (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31499920",
        "yellow_page_code": "74307",
        "kiosk": false
    },
    {
        "value": "239506",
        "label": "Rhinestone Setters (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31499919",
        "yellow_page_code": "72014",
        "kiosk": false
    },
    {
        "value": "239507",
        "label": "Lace Cutting (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31499912",
        "yellow_page_code": "45412",
        "kiosk": false
    },
    {
        "value": "239509",
        "label": "Eyeletting (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31499907",
        "yellow_page_code": "30313",
        "kiosk": false
    },
    {
        "value": "239510",
        "label": "Buttonhole Makers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31499903",
        "yellow_page_code": "11909, 11914",
        "kiosk": false
    },
    {
        "value": "239511",
        "label": "Tucking (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31499927",
        "yellow_page_code": "89319",
        "kiosk": false
    },
    {
        "value": "239598",
        "label": "Pleating/Stitching/Tucking-Trade (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31499916",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "239601",
        "label": "Shoulder Pads (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31599025",
        "yellow_page_code": "77205",
        "kiosk": false
    },
    {
        "value": "239602",
        "label": "Knit Goods-Trimmings (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31499911",
        "yellow_page_code": "45011",
        "kiosk": false
    },
    {
        "value": "239603",
        "label": "Linings-Clothing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31599020",
        "yellow_page_code": "43605, 47609, 47611",
        "kiosk": false
    },
    {
        "value": "239604",
        "label": "Ribbon Printing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31599022",
        "yellow_page_code": "72102",
        "kiosk": false
    },
    {
        "value": "239605",
        "label": "Ribbons-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31599023",
        "yellow_page_code": "72114",
        "kiosk": false
    },
    {
        "value": "239606",
        "label": "Coat Fronts (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31599018",
        "yellow_page_code": "19002",
        "kiosk": false
    },
    {
        "value": "239607",
        "label": "Perforating Of Fabrics (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31499914",
        "yellow_page_code": "60212",
        "kiosk": false
    },
    {
        "value": "239608",
        "label": "Visors (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33636007",
        "yellow_page_code": "91217",
        "kiosk": false
    },
    {
        "value": "239609",
        "label": "Waistbands (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31599030",
        "yellow_page_code": "91305",
        "kiosk": false
    },
    {
        "value": "239610",
        "label": "Shoulder Straps (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31599026",
        "yellow_page_code": "77209",
        "kiosk": false
    },
    {
        "value": "239611",
        "label": "Stamping (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31599028",
        "yellow_page_code": "80513",
        "kiosk": false
    },
    {
        "value": "239698",
        "label": "Automotive Trimming/Apprl Findings",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33636003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "239798",
        "label": "Schiffli Machine Embroideries (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31322016",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "239901",
        "label": "Auto Seatcovers Etc-Material/Eqpt-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33636001",
        "yellow_page_code": "05207",
        "kiosk": false
    },
    {
        "value": "239902",
        "label": "Sewing Contractors (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31499921",
        "yellow_page_code": "75902, 75912, 75901",
        "kiosk": false
    },
    {
        "value": "239903",
        "label": "Shoe Contract Stitching (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31599024",
        "yellow_page_code": "76609",
        "kiosk": false
    },
    {
        "value": "239904",
        "label": "Emblems (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31499905",
        "yellow_page_code": "28402",
        "kiosk": false
    },
    {
        "value": "239905",
        "label": "Flags & Banners-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31499908",
        "yellow_page_code": "32811, 32817, 32816",
        "kiosk": false
    },
    {
        "value": "239906",
        "label": "Badges-Manufacturers Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31499901",
        "yellow_page_code": "05703",
        "kiosk": false
    },
    {
        "value": "239907",
        "label": "Powder Puffs (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31499917",
        "yellow_page_code": "67903",
        "kiosk": false
    },
    {
        "value": "239908",
        "label": "Seat Belts-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33636006",
        "yellow_page_code": "75209",
        "kiosk": false
    },
    {
        "value": "239909",
        "label": "Sleeping Bags-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31499922",
        "yellow_page_code": "78316",
        "kiosk": false
    },
    {
        "value": "239910",
        "label": "Automobile Seatcovers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33636002",
        "yellow_page_code": "05118",
        "kiosk": false
    },
    {
        "value": "239911",
        "label": "Hammocks-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31499910",
        "yellow_page_code": "39813",
        "kiosk": false
    },
    {
        "value": "239912",
        "label": "Textile Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31599034",
        "yellow_page_code": "85414",
        "kiosk": false
    },
    {
        "value": "239913",
        "label": "Parachutes-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31499933",
        "yellow_page_code": "59217, 59206",
        "kiosk": false
    },
    {
        "value": "241101",
        "label": "Pulpwood (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11331005",
        "yellow_page_code": "69104",
        "kiosk": false
    },
    {
        "value": "241102",
        "label": "Logging Companies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11331003",
        "yellow_page_code": "48605, 48515, 48522, 48524",
        "kiosk": false
    },
    {
        "value": "241103",
        "label": "Log Scalers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11331001",
        "yellow_page_code": "48601",
        "kiosk": false
    },
    {
        "value": "241104",
        "label": "Pole & Piling-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11331004",
        "yellow_page_code": "67005",
        "kiosk": false
    },
    {
        "value": "241105",
        "label": "Wood-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11331006",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "241198",
        "label": "Logging (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11331002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "242101",
        "label": "Lumber-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32191201",
        "yellow_page_code": "49002",
        "kiosk": false
    },
    {
        "value": "242102",
        "label": "Sawmills (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32111302",
        "yellow_page_code": "74108",
        "kiosk": false
    },
    {
        "value": "242103",
        "label": "Railroad Ties-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32111307",
        "yellow_page_code": "70214",
        "kiosk": false
    },
    {
        "value": "242104",
        "label": "Wood Sawing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32191205",
        "yellow_page_code": "94211",
        "kiosk": false
    },
    {
        "value": "242105",
        "label": "Saws-Timber (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32191203",
        "yellow_page_code": "74111",
        "kiosk": false
    },
    {
        "value": "242106",
        "label": "Sawmill Consultants (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32111301",
        "yellow_page_code": "74201",
        "kiosk": false
    },
    {
        "value": "242107",
        "label": "Planing Mills (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32191202",
        "yellow_page_code": "66014",
        "kiosk": false
    },
    {
        "value": "242108",
        "label": "Lumber Sawing Portable (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32111305",
        "yellow_page_code": "48913",
        "kiosk": false
    },
    {
        "value": "242109",
        "label": "Cut Stock Resawing Lumber & Planing ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32191206",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "242198",
        "label": "Sawmills & Planing Mills-General (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32111303",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "242601",
        "label": "Furniture Frames (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33721505",
        "yellow_page_code": "35706, 35625",
        "kiosk": false
    },
    {
        "value": "242602",
        "label": "Wood-Turning (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32199931",
        "yellow_page_code": "94302",
        "kiosk": false
    },
    {
        "value": "242603",
        "label": "Floor Materials-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32191803",
        "yellow_page_code": "33309",
        "kiosk": false
    },
    {
        "value": "242604",
        "label": "Hardwood Flooring (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32191804",
        "yellow_page_code": "40216, 33421",
        "kiosk": false
    },
    {
        "value": "242605",
        "label": "Gunstock-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32199913",
        "yellow_page_code": "39405",
        "kiosk": false
    },
    {
        "value": "242606",
        "label": "Bench Drawers & Legs-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33712214",
        "yellow_page_code": "07804",
        "kiosk": false
    },
    {
        "value": "242607",
        "label": "Furniture Legs-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33712216",
        "yellow_page_code": "35836",
        "kiosk": false
    },
    {
        "value": "242608",
        "label": "Stair Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32199925",
        "yellow_page_code": "80505",
        "kiosk": false
    },
    {
        "value": "242609",
        "label": "Furniture Components (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33712215",
        "yellow_page_code": "35608",
        "kiosk": false
    },
    {
        "value": "242698",
        "label": "Hardwood Dimension-Flooring Mills (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32191805",
        "yellow_page_code": "40136",
        "kiosk": false
    },
    {
        "value": "242901",
        "label": "Shingles & Shakes-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32111306",
        "yellow_page_code": "76401",
        "kiosk": false
    },
    {
        "value": "242902",
        "label": "Excelsior (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32199908",
        "yellow_page_code": "30005",
        "kiosk": false
    },
    {
        "value": "242903",
        "label": "Barrel-Manufacturers Equip/Supls (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32192015",
        "yellow_page_code": "06636",
        "kiosk": false
    },
    {
        "value": "242904",
        "label": "Staves & Stave Mills (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32191204",
        "yellow_page_code": "80711",
        "kiosk": false
    },
    {
        "value": "242998",
        "label": "Special Product Sawmills NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32111304",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "243101",
        "label": "Stairs (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32191807",
        "yellow_page_code": "80412, 80503",
        "kiosk": false
    },
    {
        "value": "243102",
        "label": "Millwork (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32191806",
        "yellow_page_code": "52500",
        "kiosk": false
    },
    {
        "value": "243103",
        "label": "Building Materials-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32191801",
        "yellow_page_code": "11209",
        "kiosk": false
    },
    {
        "value": "243104",
        "label": "Doors-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32191103",
        "yellow_page_code": "25615, 25624",
        "kiosk": false
    },
    {
        "value": "243105",
        "label": "Door Frames-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32191101",
        "yellow_page_code": "25531",
        "kiosk": false
    },
    {
        "value": "243106",
        "label": "Crossarms (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32191802",
        "yellow_page_code": "22703",
        "kiosk": false
    },
    {
        "value": "243107",
        "label": "Mouldings-Injection (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324814",
        "yellow_page_code": "54334",
        "kiosk": false
    },
    {
        "value": "243108",
        "label": "Sash-Wood-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32191105",
        "yellow_page_code": "73917",
        "kiosk": false
    },
    {
        "value": "243109",
        "label": "Doors-Folding (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32191102",
        "yellow_page_code": "25601, 25610",
        "kiosk": false
    },
    {
        "value": "243110",
        "label": "Windows-Wood-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32191106",
        "yellow_page_code": "93624, 93330, 93331",
        "kiosk": false
    },
    {
        "value": "243111",
        "label": "Trellises (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32191808",
        "yellow_page_code": "88513",
        "kiosk": false
    },
    {
        "value": "243112",
        "label": "Wood Window & Door Manufacturing ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32191107",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "243401",
        "label": "Cabinets-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33711003",
        "yellow_page_code": "44915, 12134, 44529",
        "kiosk": false
    },
    {
        "value": "243402",
        "label": "Wood Kitchen Cabinet & Countertop (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33711007",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "243501",
        "label": "Plywood & Veneers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32121103",
        "yellow_page_code": "66812, 90805",
        "kiosk": false
    },
    {
        "value": "243502",
        "label": "Paneling-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32121102",
        "yellow_page_code": "58735",
        "kiosk": false
    },
    {
        "value": "243503",
        "label": "Laminated Wood (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32121101",
        "yellow_page_code": "45511",
        "kiosk": false
    },
    {
        "value": "243698",
        "label": "Softwood Veneer & Plywood (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32121201",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "243901",
        "label": "Trusses (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32121504",
        "yellow_page_code": "89208, 89213",
        "kiosk": false
    },
    {
        "value": "243902",
        "label": "Roof Structures-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32121503",
        "yellow_page_code": "72614",
        "kiosk": false
    },
    {
        "value": "243903",
        "label": "Beams (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32121501",
        "yellow_page_code": "07201, 94298",
        "kiosk": false
    },
    {
        "value": "243904",
        "label": "Trusses-Construction-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32121505",
        "yellow_page_code": "89211",
        "kiosk": false
    },
    {
        "value": "243999",
        "label": "Structural Wood Members NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32121502",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "244101",
        "label": "Boxes-Wooden-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32192003",
        "yellow_page_code": "09808",
        "kiosk": false
    },
    {
        "value": "244102",
        "label": "Packing Case Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32192006",
        "yellow_page_code": "58319",
        "kiosk": false
    },
    {
        "value": "244103",
        "label": "Shook-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32192009",
        "yellow_page_code": "77003, 77013",
        "kiosk": false
    },
    {
        "value": "244104",
        "label": "Tool Chests Kits/Bags Etc-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32192012",
        "yellow_page_code": "87004",
        "kiosk": false
    },
    {
        "value": "244106",
        "label": "Trays (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32192013",
        "yellow_page_code": "88436",
        "kiosk": false
    },
    {
        "value": "244198",
        "label": "Nailed/Lock Cor Wood Boxes/Shook (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32192007",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "244801",
        "label": "Pallets & Skids-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32192008",
        "yellow_page_code": "58634",
        "kiosk": false
    },
    {
        "value": "244901",
        "label": "Cases-Transit-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31699003",
        "yellow_page_code": "13921, 13924",
        "kiosk": false
    },
    {
        "value": "244902",
        "label": "Tanks-Wooden (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32192011",
        "yellow_page_code": "83606",
        "kiosk": false
    },
    {
        "value": "244903",
        "label": "Barrels & Drums-Wooden (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32192001",
        "yellow_page_code": "06635",
        "kiosk": false
    },
    {
        "value": "244904",
        "label": "Boxes-Wire Bound (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32192002",
        "yellow_page_code": "10004",
        "kiosk": false
    },
    {
        "value": "244905",
        "label": "Cargo & Freight Containers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32192004",
        "yellow_page_code": "13436",
        "kiosk": false
    },
    {
        "value": "244998",
        "label": "Wood Containers NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32192014",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "245101",
        "label": "Mobile Homes-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32199102",
        "yellow_page_code": "53112, 53019, 49931, 49958, 49853",
        "kiosk": false
    },
    {
        "value": "245201",
        "label": "Log Cabins Homes & Buildings (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32199205",
        "yellow_page_code": "48602, 48521, 30130, 41467",
        "kiosk": false
    },
    {
        "value": "245202",
        "label": "Buildings-Pre-Cut Prefab & Modlr-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32199201",
        "yellow_page_code": "11412, 41317, 49833, 68016, 49930",
        "kiosk": false
    },
    {
        "value": "245203",
        "label": "Cabanas-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32199203",
        "yellow_page_code": "12101",
        "kiosk": false
    },
    {
        "value": "245204",
        "label": "Cottages-Precut & Prefabricated (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32199204",
        "yellow_page_code": "21813",
        "kiosk": false
    },
    {
        "value": "245205",
        "label": "Log Siding (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32199206",
        "yellow_page_code": "48517",
        "kiosk": false
    },
    {
        "value": "245206",
        "label": "Buildings-Wood (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32199202",
        "yellow_page_code": "11311",
        "kiosk": false
    },
    {
        "value": "245207",
        "label": "Buildings-Fabric (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32199207",
        "yellow_page_code": "11128",
        "kiosk": false
    },
    {
        "value": "249101",
        "label": "Lumber-Treating (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32111402",
        "yellow_page_code": "49003",
        "kiosk": false
    },
    {
        "value": "249102",
        "label": "Creosote (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32111401",
        "yellow_page_code": "22600",
        "kiosk": false
    },
    {
        "value": "249103",
        "label": "Wood Preservation ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32111403",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "249301",
        "label": "Particle Boards (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32121902",
        "yellow_page_code": "59504",
        "kiosk": false
    },
    {
        "value": "249302",
        "label": "Hardboard-Fabricators & Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32121901",
        "yellow_page_code": "40207",
        "kiosk": false
    },
    {
        "value": "249304",
        "label": "Wallboard & Plasterboard-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32121904",
        "yellow_page_code": "91416, 66222",
        "kiosk": false
    },
    {
        "value": "249398",
        "label": "Reconstituted Wood Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32121903",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "249901",
        "label": "Rattan Reeds & Willows-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32199919",
        "yellow_page_code": "70504",
        "kiosk": false
    },
    {
        "value": "249902",
        "label": "Wood Products-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32199929",
        "yellow_page_code": "94209, 94235, 28722",
        "kiosk": false
    },
    {
        "value": "249903",
        "label": "Picture Frames-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999940",
        "yellow_page_code": "65005",
        "kiosk": false
    },
    {
        "value": "249904",
        "label": "Swimming Pool Enclosures-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32199926",
        "yellow_page_code": "82814",
        "kiosk": false
    },
    {
        "value": "249905",
        "label": "Bamboo Products-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32199902",
        "yellow_page_code": "06209",
        "kiosk": false
    },
    {
        "value": "249906",
        "label": "Baskets-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32192016",
        "yellow_page_code": "06811",
        "kiosk": false
    },
    {
        "value": "249907",
        "label": "Handles-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32199914",
        "yellow_page_code": "40015",
        "kiosk": false
    },
    {
        "value": "249908",
        "label": "Clothes Line Equip & Supplies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32199906",
        "yellow_page_code": "18706",
        "kiosk": false
    },
    {
        "value": "249909",
        "label": "Firewood-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32199909",
        "yellow_page_code": "32235, 32337",
        "kiosk": false
    },
    {
        "value": "249910",
        "label": "Frames-Industrial (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32199910",
        "yellow_page_code": "34305",
        "kiosk": false
    },
    {
        "value": "249911",
        "label": "Pressed Wood Fuel (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32199917",
        "yellow_page_code": "68009",
        "kiosk": false
    },
    {
        "value": "249912",
        "label": "Applicators (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32199901",
        "yellow_page_code": "03018",
        "kiosk": false
    },
    {
        "value": "249913",
        "label": "Masts & Spars (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32199916",
        "yellow_page_code": "50405",
        "kiosk": false
    },
    {
        "value": "249914",
        "label": "Wood-Floor (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32199930",
        "yellow_page_code": "94213",
        "kiosk": false
    },
    {
        "value": "249915",
        "label": "Washboards (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32199927",
        "yellow_page_code": "91614",
        "kiosk": false
    },
    {
        "value": "249916",
        "label": "Shoe Lasts (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32199922",
        "yellow_page_code": "76706",
        "kiosk": false
    },
    {
        "value": "249917",
        "label": "Shoe Trees (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32199923",
        "yellow_page_code": "76904",
        "kiosk": false
    },
    {
        "value": "249918",
        "label": "Rollers-Wooden Metal Rubber Etc-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32199920",
        "yellow_page_code": "72535",
        "kiosk": false
    },
    {
        "value": "249919",
        "label": "Spokes-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32199924",
        "yellow_page_code": "79913",
        "kiosk": false
    },
    {
        "value": "249920",
        "label": "Cork & Cork Products-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32199907",
        "yellow_page_code": "21637",
        "kiosk": false
    },
    {
        "value": "249921",
        "label": "Ladders-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32199915",
        "yellow_page_code": "45517",
        "kiosk": false
    },
    {
        "value": "249998",
        "label": "Wood Products NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32199928",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "251101",
        "label": "Baby Furniture & Accessories-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33712202",
        "yellow_page_code": "05516",
        "kiosk": false
    },
    {
        "value": "251102",
        "label": "Furniture-Unfinished-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33712206",
        "yellow_page_code": "35912",
        "kiosk": false
    },
    {
        "value": "251103",
        "label": "Headboards-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33712207",
        "yellow_page_code": "40407",
        "kiosk": false
    },
    {
        "value": "251104",
        "label": "Furniture-Childrens-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33712204",
        "yellow_page_code": "35716",
        "kiosk": false
    },
    {
        "value": "251105",
        "label": "Silverware Containers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33712209",
        "yellow_page_code": "77711",
        "kiosk": false
    },
    {
        "value": "251106",
        "label": "Room Dividers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33712208",
        "yellow_page_code": "72705",
        "kiosk": false
    },
    {
        "value": "251107",
        "label": "Dinette Sets (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33712203",
        "yellow_page_code": "24807",
        "kiosk": false
    },
    {
        "value": "251108",
        "label": "Baby Carriage Supplies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33712201",
        "yellow_page_code": "05515",
        "kiosk": false
    },
    {
        "value": "251109",
        "label": "Tables-Folding-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33712210",
        "yellow_page_code": "83104",
        "kiosk": false
    },
    {
        "value": "251110",
        "label": "Tiki Huts (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33712213",
        "yellow_page_code": "85990",
        "kiosk": false
    },
    {
        "value": "251198",
        "label": "Wood-Household Furn-Ex Upholstered",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33712211",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "251298",
        "label": "Wood-Household Furn-Upholstered (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33712101",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "251401",
        "label": "Cabinets-Metal (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33712602",
        "yellow_page_code": "12105",
        "kiosk": false
    },
    {
        "value": "251402",
        "label": "Furniture-Outdoor-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33712603",
        "yellow_page_code": "35709",
        "kiosk": false
    },
    {
        "value": "251403",
        "label": "Medicine Cabinets-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33712604",
        "yellow_page_code": "51036",
        "kiosk": false
    },
    {
        "value": "251404",
        "label": "Bed Frames-Metal (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33712601",
        "yellow_page_code": "07308",
        "kiosk": false
    },
    {
        "value": "251498",
        "label": "Metal-Household Furniture (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33712605",
        "yellow_page_code": "35722",
        "kiosk": false
    },
    {
        "value": "251501",
        "label": "Mattresses-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33791008",
        "yellow_page_code": "50601, 50611",
        "kiosk": false
    },
    {
        "value": "251502",
        "label": "Bedding-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33791002",
        "yellow_page_code": "07413",
        "kiosk": false
    },
    {
        "value": "251503",
        "label": "Feathers-Bedding (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33791009",
        "yellow_page_code": "31111",
        "kiosk": false
    },
    {
        "value": "251504",
        "label": "Cushions-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33791004",
        "yellow_page_code": "22936",
        "kiosk": false
    },
    {
        "value": "251505",
        "label": "Mattress Makers Supplies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33791005",
        "yellow_page_code": "50604",
        "kiosk": false
    },
    {
        "value": "251506",
        "label": "Water Beds-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33791007",
        "yellow_page_code": "92035",
        "kiosk": false
    },
    {
        "value": "251507",
        "label": "Bed Springs-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33791001",
        "yellow_page_code": "07313",
        "kiosk": false
    },
    {
        "value": "251508",
        "label": "Bedding-Manufacturers Supplies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33791003",
        "yellow_page_code": "07411",
        "kiosk": false
    },
    {
        "value": "251598",
        "label": "Mattresses/Foundations/Conv Beds (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33791006",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "251798",
        "label": "Wood-TV Radio Phono/Sewing Cabnts (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32199903",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "251901",
        "label": "Windows-Fiberglass (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32799306",
        "yellow_page_code": "93716",
        "kiosk": false
    },
    {
        "value": "251998",
        "label": "Household Furniture NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33712606",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "252198",
        "label": "Wood-Office Furniture (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33721101",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "252201",
        "label": "Filing Equipment Systems & Supls-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33721403",
        "yellow_page_code": "31515",
        "kiosk": false
    },
    {
        "value": "252202",
        "label": "Furniture-Manufacturers-Office & Commercial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33721404",
        "yellow_page_code": "35713",
        "kiosk": false
    },
    {
        "value": "252203",
        "label": "Modular Products-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33721405",
        "yellow_page_code": "53313",
        "kiosk": false
    },
    {
        "value": "252204",
        "label": "Office Furniture & Equip-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33721406",
        "yellow_page_code": "56214",
        "kiosk": false
    },
    {
        "value": "252205",
        "label": "Chairs-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33721401",
        "yellow_page_code": "14513",
        "kiosk": false
    },
    {
        "value": "252206",
        "label": "Filing Consultants (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33721402",
        "yellow_page_code": "31611",
        "kiosk": false
    },
    {
        "value": "252298",
        "label": "Office Furniture-Except Wood (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33721407",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "253101",
        "label": "Boat & Van Seating-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33636004",
        "yellow_page_code": "08713, 75210",
        "kiosk": false
    },
    {
        "value": "253102",
        "label": "Church Furniture-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33712705",
        "yellow_page_code": "15712",
        "kiosk": false
    },
    {
        "value": "253103",
        "label": "Recreational Vehicle Seating-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33636005",
        "yellow_page_code": "71021",
        "kiosk": false
    },
    {
        "value": "253104",
        "label": "Benches-Seating (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33712704",
        "yellow_page_code": "07705, 07805, 07806",
        "kiosk": false
    },
    {
        "value": "253198",
        "label": "Public Building/Related Furniture (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33712711",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "254101",
        "label": "Counter Tops-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33711005",
        "yellow_page_code": "22115",
        "kiosk": false
    },
    {
        "value": "254102",
        "label": "Partitions-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33721509",
        "yellow_page_code": "59513",
        "kiosk": false
    },
    {
        "value": "254103",
        "label": "Butcher Blocks-Household (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33711001",
        "yellow_page_code": "11911",
        "kiosk": false
    },
    {
        "value": "254198",
        "label": "Office & Store Fixtures-Wood (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33721201",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "254201",
        "label": "Office & Store Fixtures-Ex Wood (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33721508",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "254202",
        "label": "Bar Fixtures-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33721501",
        "yellow_page_code": "06413",
        "kiosk": false
    },
    {
        "value": "254203",
        "label": "Garment Racks-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33721506",
        "yellow_page_code": "36313",
        "kiosk": false
    },
    {
        "value": "254204",
        "label": "Racks-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33721510",
        "yellow_page_code": "69605",
        "kiosk": false
    },
    {
        "value": "254205",
        "label": "Shelving-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33721511",
        "yellow_page_code": "76303, 81729",
        "kiosk": false
    },
    {
        "value": "254206",
        "label": "Store Fixtures-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33721512",
        "yellow_page_code": "81807, 50211",
        "kiosk": false
    },
    {
        "value": "254207",
        "label": "Booths-Telephone (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33721502",
        "yellow_page_code": "09404",
        "kiosk": false
    },
    {
        "value": "254208",
        "label": "Exposition Stands-Designers & Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33721504",
        "yellow_page_code": "30209",
        "kiosk": false
    },
    {
        "value": "254209",
        "label": "Display Fixtures & Materials-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33721503",
        "yellow_page_code": "25036",
        "kiosk": false
    },
    {
        "value": "254210",
        "label": "Lockers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33721507",
        "yellow_page_code": "48422",
        "kiosk": false
    },
    {
        "value": "254211",
        "label": "Show Cases-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33721513",
        "yellow_page_code": "77320",
        "kiosk": false
    },
    {
        "value": "254212",
        "label": "Showcase Partition Shelving & Locker (Mfrs) ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33721514",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "259101",
        "label": "Drapery & Curtain Fixtures-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33792002",
        "yellow_page_code": "26003",
        "kiosk": false
    },
    {
        "value": "259103",
        "label": "Window Shades-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33792004",
        "yellow_page_code": "93617",
        "kiosk": false
    },
    {
        "value": "259104",
        "label": "Blinds-Venetian & Vertical-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33792001",
        "yellow_page_code": "90836, 08416",
        "kiosk": false
    },
    {
        "value": "259198",
        "label": "Drapery Hdwr-Window Blinds/Shades (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33792003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "259901",
        "label": "Furniture-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33712709",
        "yellow_page_code": "35708, 07319, 44914, 30553",
        "kiosk": false
    },
    {
        "value": "259902",
        "label": "Vending Carts (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33712714",
        "yellow_page_code": "90712",
        "kiosk": false
    },
    {
        "value": "259903",
        "label": "Restaurant Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33712712",
        "yellow_page_code": "71921",
        "kiosk": false
    },
    {
        "value": "259904",
        "label": "Construction Side Boards (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33712715",
        "yellow_page_code": "21017",
        "kiosk": false
    },
    {
        "value": "259998",
        "label": "Furniture & Fixtures NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33712708",
        "yellow_page_code": "35628",
        "kiosk": false
    },
    {
        "value": "261101",
        "label": "Paper Converters (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32213003",
        "yellow_page_code": "58902",
        "kiosk": false
    },
    {
        "value": "261102",
        "label": "Pulp & Pulp Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32211001",
        "yellow_page_code": "69102, 69037, 69039, 69040, 69036, 58807",
        "kiosk": false
    },
    {
        "value": "262101",
        "label": "Paper-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32212003",
        "yellow_page_code": "59004, 59101, 21125, 49878",
        "kiosk": false
    },
    {
        "value": "262102",
        "label": "Paper Towels-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32212005",
        "yellow_page_code": "59110",
        "kiosk": false
    },
    {
        "value": "262104",
        "label": "Cigarette Papers & Tubes-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32212002",
        "yellow_page_code": "17901",
        "kiosk": false
    },
    {
        "value": "262105",
        "label": "Printing Supplies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32212004",
        "yellow_page_code": "68418",
        "kiosk": false
    },
    {
        "value": "262106",
        "label": "Card & Paper Finishing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32212001",
        "yellow_page_code": "13505",
        "kiosk": false
    },
    {
        "value": "263101",
        "label": "Box-Manufacturers Equip & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32213001",
        "yellow_page_code": "09807",
        "kiosk": false
    },
    {
        "value": "263102",
        "label": "Paperboard Mills (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32213002",
        "yellow_page_code": "58817",
        "kiosk": false
    },
    {
        "value": "265201",
        "label": "Boxes-Paper (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32221901",
        "yellow_page_code": "09906, 09909",
        "kiosk": false
    },
    {
        "value": "265301",
        "label": "Corrugated & Solid Fiber Boxes (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32221102",
        "yellow_page_code": "09904",
        "kiosk": false
    },
    {
        "value": "265303",
        "label": "Boxes-Paper-Gluing & Finishing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32221101",
        "yellow_page_code": "09907",
        "kiosk": false
    },
    {
        "value": "265501",
        "label": "Fiber & Fiber Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32221905",
        "yellow_page_code": "31500, 30179",
        "kiosk": false
    },
    {
        "value": "265502",
        "label": "Mailing Containers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32221906",
        "yellow_page_code": "49705",
        "kiosk": false
    },
    {
        "value": "265503",
        "label": "Packaging Containers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32221907",
        "yellow_page_code": "58312",
        "kiosk": false
    },
    {
        "value": "265504",
        "label": "Containers-Industrial (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32221904",
        "yellow_page_code": "21034",
        "kiosk": false
    },
    {
        "value": "265505",
        "label": "Cans-Fiber (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32221902",
        "yellow_page_code": "12902",
        "kiosk": false
    },
    {
        "value": "265506",
        "label": "Collapsible Tubes (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32221903",
        "yellow_page_code": "19605",
        "kiosk": false
    },
    {
        "value": "265507",
        "label": "Paper Tubes & Cores-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32221908",
        "yellow_page_code": "59201, 89313",
        "kiosk": false
    },
    {
        "value": "265601",
        "label": "Paper & Plastic Cups Containers Etc-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32221912",
        "yellow_page_code": "58809",
        "kiosk": false
    },
    {
        "value": "265602",
        "label": "Dinnerware-Plastic (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32221910",
        "yellow_page_code": "24817",
        "kiosk": false
    },
    {
        "value": "265603",
        "label": "Food Packers Supplies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32221911",
        "yellow_page_code": "33811",
        "kiosk": false
    },
    {
        "value": "265604",
        "label": "Straws-Drinking (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32221914",
        "yellow_page_code": "81912",
        "kiosk": false
    },
    {
        "value": "265615",
        "label": "Caterers Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32221909",
        "yellow_page_code": "14095",
        "kiosk": false
    },
    {
        "value": "265698",
        "label": "Sanitary Food Cntnrs-Ex Folding (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32221913",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "265701",
        "label": "Boxes-Folding-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32221201",
        "yellow_page_code": "09905, 13834, 09903, 68326",
        "kiosk": false
    },
    {
        "value": "265702",
        "label": "Boxes-Specialty & Fancy-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32221202",
        "yellow_page_code": "09900",
        "kiosk": false
    },
    {
        "value": "267101",
        "label": "Packaging Materials-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32222001",
        "yellow_page_code": "58315, 88332, 58805, 49890, 22330",
        "kiosk": false
    },
    {
        "value": "267102",
        "label": "Plastics Packaging Film & Sheet (Mfrs) ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32611201",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "267201",
        "label": "Labels-Paper (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32222006",
        "yellow_page_code": "45206, 45210, 83303",
        "kiosk": false
    },
    {
        "value": "267202",
        "label": "Pressure Sensitive Materials-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32222008",
        "yellow_page_code": "68113",
        "kiosk": false
    },
    {
        "value": "267203",
        "label": "Gummed Tape (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32222003",
        "yellow_page_code": "39309",
        "kiosk": false
    },
    {
        "value": "267204",
        "label": "Label Finishing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32222004",
        "yellow_page_code": "45200",
        "kiosk": false
    },
    {
        "value": "267205",
        "label": "Label Stock-Adhesive (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32222005",
        "yellow_page_code": "45201",
        "kiosk": false
    },
    {
        "value": "267206",
        "label": "Tapes-Industrial-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32222009",
        "yellow_page_code": "83814",
        "kiosk": false
    },
    {
        "value": "267207",
        "label": "Tapes-Pressure Sensitive (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32222010",
        "yellow_page_code": "83807",
        "kiosk": false
    },
    {
        "value": "267208",
        "label": "Paper-Waxed (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32222007",
        "yellow_page_code": "59202",
        "kiosk": false
    },
    {
        "value": "267298",
        "label": "Coated & Laminated Paper NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32222002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "267301",
        "label": "Bags-Plastic (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32222013",
        "yellow_page_code": "05902, 05901, 71735",
        "kiosk": false
    },
    {
        "value": "267302",
        "label": "Plastic Liners & Covers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32611101",
        "yellow_page_code": "66203",
        "kiosk": false
    },
    {
        "value": "267303",
        "label": "Bags-Garbage-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32222012",
        "yellow_page_code": "05813",
        "kiosk": false
    },
    {
        "value": "267398",
        "label": "Plastics-Foil & Coated Paper Bags (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32222014",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "267401",
        "label": "Bags-Paper (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32222016",
        "yellow_page_code": "05900, 58811",
        "kiosk": false
    },
    {
        "value": "267402",
        "label": "Bags-Specialty (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32222017",
        "yellow_page_code": "05903",
        "kiosk": false
    },
    {
        "value": "267403",
        "label": "Bags-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32222015",
        "yellow_page_code": "05809",
        "kiosk": false
    },
    {
        "value": "267404",
        "label": "Glassine Paper Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32222018",
        "yellow_page_code": "37711",
        "kiosk": false
    },
    {
        "value": "267405",
        "label": "Bag Making Machinery (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32222019",
        "yellow_page_code": "05802",
        "kiosk": false
    },
    {
        "value": "267501",
        "label": "Box Partitions (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32229901",
        "yellow_page_code": "09803",
        "kiosk": false
    },
    {
        "value": "267598",
        "label": "Die-Cut Paper & Paperboard (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32223001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "267601",
        "label": "Diaper Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32229101",
        "yellow_page_code": "24510",
        "kiosk": false
    },
    {
        "value": "267602",
        "label": "Sanitary Paper Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32229102",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "267701",
        "label": "Envelopes-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32223003",
        "yellow_page_code": "29609, 29695",
        "kiosk": false
    },
    {
        "value": "267801",
        "label": "Stationery-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32223005",
        "yellow_page_code": "80704, 67954",
        "kiosk": false
    },
    {
        "value": "267802",
        "label": "Greeting Card-Manufacturers Supls (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32223004",
        "yellow_page_code": "39001",
        "kiosk": false
    },
    {
        "value": "267898",
        "label": "Stationery-Tablets & Related Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32223006",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "267901",
        "label": "Paper-Box Board (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32229909",
        "yellow_page_code": "58806",
        "kiosk": false
    },
    {
        "value": "267902",
        "label": "Tags-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32229911",
        "yellow_page_code": "83302",
        "kiosk": false
    },
    {
        "value": "267903",
        "label": "Paper-Corrugated (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32229910",
        "yellow_page_code": "58904, 52412",
        "kiosk": false
    },
    {
        "value": "267904",
        "label": "Wall Covering-Fabric Plastic Etc-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32222011",
        "yellow_page_code": "91312, 33111",
        "kiosk": false
    },
    {
        "value": "267905",
        "label": "Confetti-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32229902",
        "yellow_page_code": "20922",
        "kiosk": false
    },
    {
        "value": "267906",
        "label": "Gift Wrapping Materials-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32229906",
        "yellow_page_code": "37335",
        "kiosk": false
    },
    {
        "value": "267907",
        "label": "Disposable Products-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32229905",
        "yellow_page_code": "25110",
        "kiosk": false
    },
    {
        "value": "267908",
        "label": "Decoration Supplies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32229904",
        "yellow_page_code": "23651",
        "kiosk": false
    },
    {
        "value": "267909",
        "label": "Honeycomb Materials (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32229907",
        "yellow_page_code": "41406",
        "kiosk": false
    },
    {
        "value": "267910",
        "label": "Paper Coating (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32229908",
        "yellow_page_code": "58901",
        "kiosk": false
    },
    {
        "value": "267911",
        "label": "Office Supplies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32223002",
        "yellow_page_code": "56311",
        "kiosk": false
    },
    {
        "value": "267998",
        "label": "Converted Paper/Paperbrd Prod NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32229903",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "271101",
        "label": "Newspapers (Publishers/Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51311001",
        "yellow_page_code": "55506, 69020, 93218",
        "kiosk": false
    },
    {
        "value": "271102",
        "label": "News Dealers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51311002",
        "yellow_page_code": "55412",
        "kiosk": false
    },
    {
        "value": "271103",
        "label": "Printers-Newspapers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51311003",
        "yellow_page_code": "68309",
        "kiosk": false
    },
    {
        "value": "271104",
        "label": "Greeting Card Publishers ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51319101",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "271198",
        "label": "Newspaper Publishing & Printing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51311005",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "272101",
        "label": "Publishers-Magazine (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51312004",
        "yellow_page_code": "69005, 49309, 19991",
        "kiosk": false
    },
    {
        "value": "272102",
        "label": "Publishers-Periodical (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51312005",
        "yellow_page_code": "69006, 30348",
        "kiosk": false
    },
    {
        "value": "272103",
        "label": "News Publications-Trade/Assn/Etc (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51311006",
        "yellow_page_code": "55406",
        "kiosk": false
    },
    {
        "value": "272104",
        "label": "Newsletters (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51312002",
        "yellow_page_code": "55407, 55415, 68918",
        "kiosk": false
    },
    {
        "value": "272106",
        "label": "Wedding Publications (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51312006",
        "yellow_page_code": "92930",
        "kiosk": false
    },
    {
        "value": "272107",
        "label": "Entertainment Guides-Publications (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51312007",
        "yellow_page_code": "29637",
        "kiosk": false
    },
    {
        "value": "272108",
        "label": "Internet Newsletter (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51312008",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "272109",
        "label": "Online Publications (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51311004",
        "yellow_page_code": "57518",
        "kiosk": false
    },
    {
        "value": "272198",
        "label": "Periodicals-Publishing & Printing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51312003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "273101",
        "label": "Publishers-Book (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51313003",
        "yellow_page_code": "69000, 68916, 09223, 09319, 29992",
        "kiosk": false
    },
    {
        "value": "273102",
        "label": "Church Supplies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51313002",
        "yellow_page_code": "15719",
        "kiosk": false
    },
    {
        "value": "273103",
        "label": "Book Development & Production (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51313005",
        "yellow_page_code": "09220",
        "kiosk": false
    },
    {
        "value": "273198",
        "label": "Books-Publishing & Printing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51313001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "273201",
        "label": "Book-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311701",
        "yellow_page_code": "09209",
        "kiosk": false
    },
    {
        "value": "273202",
        "label": "Christian Tracts (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311702",
        "yellow_page_code": "15607",
        "kiosk": false
    },
    {
        "value": "273203",
        "label": "Bible-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311704",
        "yellow_page_code": "07899",
        "kiosk": false
    },
    {
        "value": "273298",
        "label": "Printers-Books (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311703",
        "yellow_page_code": "68313",
        "kiosk": false
    },
    {
        "value": "274101",
        "label": "Art Publishers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51319901",
        "yellow_page_code": "03603, 31833, 03425",
        "kiosk": false
    },
    {
        "value": "274102",
        "label": "Catalog Printers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51319909",
        "yellow_page_code": "14025",
        "kiosk": false
    },
    {
        "value": "274103",
        "label": "Catalog Compilers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51319902",
        "yellow_page_code": "14004, 13991, 09217",
        "kiosk": false
    },
    {
        "value": "274104",
        "label": "Music Publishers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51223001",
        "yellow_page_code": "54802, 68911",
        "kiosk": false
    },
    {
        "value": "274105",
        "label": "Publishers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51319907",
        "yellow_page_code": "68909, 70713, 70613, 68914, 68917, 28034, 43544, 68910, 94780, 54523, 43658, 68905, 00529",
        "kiosk": false
    },
    {
        "value": "274106",
        "label": "Publishers-Legislative Jud/Pub Record",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51314002",
        "yellow_page_code": "69004",
        "kiosk": false
    },
    {
        "value": "274107",
        "label": "Publishers-Directory & Guide (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51314001",
        "yellow_page_code": "24905, 69002, 24814, 24834, 22998, 29944, 24855",
        "kiosk": false
    },
    {
        "value": "274108",
        "label": "Test Publisher-Vocational/Eductnl (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51319915",
        "yellow_page_code": "85208",
        "kiosk": false
    },
    {
        "value": "274109",
        "label": "Shoppers' News Publications (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51319913",
        "yellow_page_code": "77100, 77014",
        "kiosk": false
    },
    {
        "value": "274110",
        "label": "Yellow Pages-Publishers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51314003",
        "yellow_page_code": "94802, 84417, 94715, 94714, 24852, 60731",
        "kiosk": false
    },
    {
        "value": "274111",
        "label": "Cookbooks (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51313004",
        "yellow_page_code": "21400",
        "kiosk": false
    },
    {
        "value": "274112",
        "label": "Indexing Svc-Book/Periodical/Etc (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51319904",
        "yellow_page_code": "42901",
        "kiosk": false
    },
    {
        "value": "274113",
        "label": "Music-Printers & Engravers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51319906",
        "yellow_page_code": "54800",
        "kiosk": false
    },
    {
        "value": "274114",
        "label": "Publishers-Art (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51319908",
        "yellow_page_code": "69001",
        "kiosk": false
    },
    {
        "value": "274116",
        "label": "Publishers-Microform (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51319911",
        "yellow_page_code": "69003",
        "kiosk": false
    },
    {
        "value": "274117",
        "label": "Tax Publications (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51319914",
        "yellow_page_code": "84105",
        "kiosk": false
    },
    {
        "value": "274118",
        "label": "Racing Information (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51319912",
        "yellow_page_code": "69516",
        "kiosk": false
    },
    {
        "value": "274119",
        "label": "Multimedia (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51319905",
        "yellow_page_code": "54516, 54519, 54520, 54525",
        "kiosk": false
    },
    {
        "value": "274120",
        "label": "Music-Sheet-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51223002",
        "yellow_page_code": "54814",
        "kiosk": false
    },
    {
        "value": "274121",
        "label": "Publishers-Computer Software (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51319910",
        "yellow_page_code": "69015, 20267",
        "kiosk": false
    },
    {
        "value": "274122",
        "label": "Instruction Manuals (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51319916",
        "yellow_page_code": "43313",
        "kiosk": false
    },
    {
        "value": "274123",
        "label": "Art Prints-Publishing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51319903",
        "yellow_page_code": "03606",
        "kiosk": false
    },
    {
        "value": "274124",
        "label": "Crafts Publications (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51319917",
        "yellow_page_code": "22211",
        "kiosk": false
    },
    {
        "value": "274125",
        "label": "Legislative Reporting (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51319918",
        "yellow_page_code": "46920",
        "kiosk": false
    },
    {
        "value": "274126",
        "label": "Internet Services-Catalog Design-Pubg",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51314005",
        "yellow_page_code": "43557",
        "kiosk": false
    },
    {
        "value": "274127",
        "label": "Internet Radio (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51611004",
        "yellow_page_code": "43586",
        "kiosk": false
    },
    {
        "value": "274128",
        "label": "Internet Artists Social Network",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51612004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "274129",
        "label": "On Line Directory",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51612007",
        "yellow_page_code": "57526",
        "kiosk": false
    },
    {
        "value": "274130",
        "label": "Social Media & Blogs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51621001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "275201",
        "label": "Post Cards-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311163",
        "yellow_page_code": "67500",
        "kiosk": false
    },
    {
        "value": "275202",
        "label": "Printers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311109",
        "yellow_page_code": "68411, 68302, 68310, 68312, 21371",
        "kiosk": false
    },
    {
        "value": "275203",
        "label": "Posters (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311164",
        "yellow_page_code": "67502, 67441",
        "kiosk": false
    },
    {
        "value": "275205",
        "label": "Printing Brokers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311112",
        "yellow_page_code": "68400",
        "kiosk": false
    },
    {
        "value": "275206",
        "label": "Menu Service (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311105",
        "yellow_page_code": "51302, 29954",
        "kiosk": false
    },
    {
        "value": "275207",
        "label": "Color Separations-Offset Photo Engrave",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311102",
        "yellow_page_code": "19806",
        "kiosk": false
    },
    {
        "value": "275208",
        "label": "Offset Reproductions (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311106",
        "yellow_page_code": "56403, 45216",
        "kiosk": false
    },
    {
        "value": "275209",
        "label": "Decals (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311123",
        "yellow_page_code": "23609",
        "kiosk": false
    },
    {
        "value": "275210",
        "label": "Transfers-Dry (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311108",
        "yellow_page_code": "88207",
        "kiosk": false
    },
    {
        "value": "275212",
        "label": "Calendars-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311110",
        "yellow_page_code": "12335",
        "kiosk": false
    },
    {
        "value": "275216",
        "label": "Announcements (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311141",
        "yellow_page_code": "02608",
        "kiosk": false
    },
    {
        "value": "275217",
        "label": "Lithographic Composition (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311104",
        "yellow_page_code": "48005",
        "kiosk": false
    },
    {
        "value": "275219",
        "label": "Plastic Cards (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311107",
        "yellow_page_code": "66114",
        "kiosk": false
    },
    {
        "value": "275220",
        "label": "Printmaking Workshops (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311111",
        "yellow_page_code": "68414, 03505, 68203",
        "kiosk": false
    },
    {
        "value": "275221",
        "label": "Color Separations-Graphic Arts (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311101",
        "yellow_page_code": "19807",
        "kiosk": false
    },
    {
        "value": "275298",
        "label": "Commercial Printing-Lithographic (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311103",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "275401",
        "label": "Engravers-Stationery (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311116",
        "yellow_page_code": "29504",
        "kiosk": false
    },
    {
        "value": "275402",
        "label": "Rotogravure Printers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311118",
        "yellow_page_code": "72809",
        "kiosk": false
    },
    {
        "value": "275404",
        "label": "Engravers-Rotogravure (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311115",
        "yellow_page_code": "29503",
        "kiosk": false
    },
    {
        "value": "275498",
        "label": "Commercial Printing-Gravure (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311114",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "275901",
        "label": "Paper-Adhesive/Pressure Sensitive (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311161",
        "yellow_page_code": "58801",
        "kiosk": false
    },
    {
        "value": "275902",
        "label": "Screen Printing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311301",
        "yellow_page_code": "74808",
        "kiosk": false
    },
    {
        "value": "275903",
        "label": "Printers-Glass/Metal/Plastic Etc (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311167",
        "yellow_page_code": "68308",
        "kiosk": false
    },
    {
        "value": "275904",
        "label": "Thermographers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311172",
        "yellow_page_code": "85700",
        "kiosk": false
    },
    {
        "value": "275905",
        "label": "Bottles-Printing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311145",
        "yellow_page_code": "09604",
        "kiosk": false
    },
    {
        "value": "275906",
        "label": "Tickets & Coupons-Printers/Supls (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311173",
        "yellow_page_code": "85904",
        "kiosk": false
    },
    {
        "value": "275907",
        "label": "Cold Type Composition (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311147",
        "yellow_page_code": "19604",
        "kiosk": false
    },
    {
        "value": "275908",
        "label": "Business Cards (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311146",
        "yellow_page_code": "11803",
        "kiosk": false
    },
    {
        "value": "275909",
        "label": "Embossing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311149",
        "yellow_page_code": "28404",
        "kiosk": false
    },
    {
        "value": "275910",
        "label": "Labels (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311156",
        "yellow_page_code": "45205",
        "kiosk": false
    },
    {
        "value": "275911",
        "label": "Imprinting (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311153",
        "yellow_page_code": "42708",
        "kiosk": false
    },
    {
        "value": "275912",
        "label": "Labels-Plastic Metal Foil & Etc (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311120",
        "yellow_page_code": "45208",
        "kiosk": false
    },
    {
        "value": "275913",
        "label": "Stock Certificates (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311171",
        "yellow_page_code": "81409",
        "kiosk": false
    },
    {
        "value": "275914",
        "label": "Hot Stamping-Foil Gold & Etc (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311119",
        "yellow_page_code": "41815, 37317",
        "kiosk": false
    },
    {
        "value": "275915",
        "label": "Printing-Management Services (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311168",
        "yellow_page_code": "68415",
        "kiosk": false
    },
    {
        "value": "275917",
        "label": "Menu Printers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311159",
        "yellow_page_code": "51309, 71742, 14022, 29999",
        "kiosk": false
    },
    {
        "value": "275918",
        "label": "Playing Cards (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311162",
        "yellow_page_code": "66608",
        "kiosk": false
    },
    {
        "value": "275919",
        "label": "Printers-Cellophane/Foil & Etc (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311121",
        "yellow_page_code": "68305",
        "kiosk": false
    },
    {
        "value": "275920",
        "label": "Music-Manuscript Reproductions (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311160",
        "yellow_page_code": "54711",
        "kiosk": false
    },
    {
        "value": "275921",
        "label": "Screen Printing-Label Removal (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311302",
        "yellow_page_code": "74811",
        "kiosk": false
    },
    {
        "value": "275922",
        "label": "Linotype Composition (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311157",
        "yellow_page_code": "47800",
        "kiosk": false
    },
    {
        "value": "275923",
        "label": "Printers-Finishing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311166",
        "yellow_page_code": "68307",
        "kiosk": false
    },
    {
        "value": "275924",
        "label": "Invitations & Announcements-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311154",
        "yellow_page_code": "43799",
        "kiosk": false
    },
    {
        "value": "275925",
        "label": "Jackets-Promotional (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311155",
        "yellow_page_code": "44012",
        "kiosk": false
    },
    {
        "value": "275926",
        "label": "Programs (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311169",
        "yellow_page_code": "68509",
        "kiosk": false
    },
    {
        "value": "275927",
        "label": "Menu Covers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311158",
        "yellow_page_code": "51307",
        "kiosk": false
    },
    {
        "value": "275928",
        "label": "Engravers-Bank Note (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311150",
        "yellow_page_code": "29509",
        "kiosk": false
    },
    {
        "value": "275929",
        "label": "Bands & Wrappers-Printed (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311142",
        "yellow_page_code": "06307",
        "kiosk": false
    },
    {
        "value": "275930",
        "label": "Bottle Wrappers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311144",
        "yellow_page_code": "09603",
        "kiosk": false
    },
    {
        "value": "275931",
        "label": "Gift Certificates-Preprinted (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311151",
        "yellow_page_code": "37236, 91253",
        "kiosk": false
    },
    {
        "value": "275932",
        "label": "Steel Stamps (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311170",
        "yellow_page_code": "81102",
        "kiosk": false
    },
    {
        "value": "275933",
        "label": "Baseball & Trading Cards-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311143",
        "yellow_page_code": "06816",
        "kiosk": false
    },
    {
        "value": "275934",
        "label": "Matrices (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311165",
        "yellow_page_code": "50509",
        "kiosk": false
    },
    {
        "value": "275935",
        "label": "Digital Printing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311126",
        "yellow_page_code": "24796",
        "kiosk": false
    },
    {
        "value": "275936",
        "label": "Pad Printing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311174",
        "yellow_page_code": "58416",
        "kiosk": false
    },
    {
        "value": "275937",
        "label": "Printers Water Transfer",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311137",
        "yellow_page_code": "68328",
        "kiosk": false
    },
    {
        "value": "275998",
        "label": "Commercial Printing NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311148",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "276101",
        "label": "Printers-Business Forms (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311130",
        "yellow_page_code": "68304, 68306, 34113",
        "kiosk": false
    },
    {
        "value": "276102",
        "label": "Sales & Order Books (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311131",
        "yellow_page_code": "73504",
        "kiosk": false
    },
    {
        "value": "276103",
        "label": "Business Forms & Systems-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311127",
        "yellow_page_code": "11816",
        "kiosk": false
    },
    {
        "value": "276104",
        "label": "Manifold Business Forms (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311128",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "276105",
        "label": "Legal Forms-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311129",
        "yellow_page_code": "46812",
        "kiosk": false
    },
    {
        "value": "276106",
        "label": "Printers-Bank Note Cheques Security Etc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311132",
        "yellow_page_code": "68324, 14801",
        "kiosk": false
    },
    {
        "value": "277101",
        "label": "Greeting Cards (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311152",
        "yellow_page_code": "38990, 39003, 68915",
        "kiosk": false
    },
    {
        "value": "277102",
        "label": "Birth Announcements (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311122",
        "yellow_page_code": "08303",
        "kiosk": false
    },
    {
        "value": "278201",
        "label": "Loose Leaf Equipment Systs & Supls-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311139",
        "yellow_page_code": "48608, 83150, 68325",
        "kiosk": false
    },
    {
        "value": "278202",
        "label": "Checks-Printing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311113",
        "yellow_page_code": "14901, 30370",
        "kiosk": false
    },
    {
        "value": "278203",
        "label": "Binders-Loose Leaf (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311134",
        "yellow_page_code": "08105",
        "kiosk": false
    },
    {
        "value": "278204",
        "label": "Albums (Photo)-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311133",
        "yellow_page_code": "01801",
        "kiosk": false
    },
    {
        "value": "278205",
        "label": "Blankbooks (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311135",
        "yellow_page_code": "08405",
        "kiosk": false
    },
    {
        "value": "278206",
        "label": "Book Backs-Imitation (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311138",
        "yellow_page_code": "09203",
        "kiosk": false
    },
    {
        "value": "278207",
        "label": "Paper Ruling (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311140",
        "yellow_page_code": "59103",
        "kiosk": false
    },
    {
        "value": "278298",
        "label": "Blankbooks Looseleaf Bndrs/Devices",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311136",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "278901",
        "label": "Gold Stamping (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32312005",
        "yellow_page_code": "38004, 38014",
        "kiosk": false
    },
    {
        "value": "278902",
        "label": "Bookbinders (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32312002",
        "yellow_page_code": "08109, 09304, 09302, 09311, 09310",
        "kiosk": false
    },
    {
        "value": "278903",
        "label": "Mounting & Finishing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32312006",
        "yellow_page_code": "54308, 85013",
        "kiosk": false
    },
    {
        "value": "278904",
        "label": "Covers-Book Catalog & Etc (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32312001",
        "yellow_page_code": "22204, 09227",
        "kiosk": false
    },
    {
        "value": "278905",
        "label": "Paper Cutting (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32312007",
        "yellow_page_code": "58909",
        "kiosk": false
    },
    {
        "value": "278906",
        "label": "Bookbinders-Home Library (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32312003",
        "yellow_page_code": "09307",
        "kiosk": false
    },
    {
        "value": "278907",
        "label": "Swatching (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32312008",
        "yellow_page_code": "82516",
        "kiosk": false
    },
    {
        "value": "278908",
        "label": "Music Binding (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32312009",
        "yellow_page_code": "54610",
        "kiosk": false
    },
    {
        "value": "278998",
        "label": "Bookbinding & Related Work (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32312004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "279101",
        "label": "Typographers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32312026",
        "yellow_page_code": "89806",
        "kiosk": false
    },
    {
        "value": "279102",
        "label": "Typesetting (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32312025",
        "yellow_page_code": "89706",
        "kiosk": false
    },
    {
        "value": "279103",
        "label": "Prepress Services (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32312023",
        "yellow_page_code": "68022",
        "kiosk": false
    },
    {
        "value": "279104",
        "label": "Cold Type Composition-Equip/Supls (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32312010",
        "yellow_page_code": "19612",
        "kiosk": false
    },
    {
        "value": "279105",
        "label": "Phototypesetting (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32312027",
        "yellow_page_code": "60721",
        "kiosk": false
    },
    {
        "value": "279601",
        "label": "Engravers-Plastic Wood & Etc (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32312012",
        "yellow_page_code": "29502",
        "kiosk": false
    },
    {
        "value": "279602",
        "label": "Lettering Machines (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32312015",
        "yellow_page_code": "47003",
        "kiosk": false
    },
    {
        "value": "279603",
        "label": "Engravers-Photo (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32312011",
        "yellow_page_code": "29500, 60805",
        "kiosk": false
    },
    {
        "value": "279604",
        "label": "Lithographic Negatives & Plates (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32312018",
        "yellow_page_code": "48006",
        "kiosk": false
    },
    {
        "value": "279605",
        "label": "Lithographers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32312016",
        "yellow_page_code": "48002",
        "kiosk": false
    },
    {
        "value": "279606",
        "label": "Engravers-Rubber (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32312013",
        "yellow_page_code": "29409",
        "kiosk": false
    },
    {
        "value": "279607",
        "label": "Engravers-Steel & Copper Plate (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32312014",
        "yellow_page_code": "29507, 29415",
        "kiosk": false
    },
    {
        "value": "279608",
        "label": "Lithographing On Metal (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32312019",
        "yellow_page_code": "48001",
        "kiosk": false
    },
    {
        "value": "279609",
        "label": "Lithographers Plate Service (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32312017",
        "yellow_page_code": "48003",
        "kiosk": false
    },
    {
        "value": "279610",
        "label": "Printers' Services (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32312024",
        "yellow_page_code": "68303",
        "kiosk": false
    },
    {
        "value": "279611",
        "label": "Metal-Photoetching (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32312020",
        "yellow_page_code": "51518",
        "kiosk": false
    },
    {
        "value": "279612",
        "label": "Offset Negative & Plate Service (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32312021",
        "yellow_page_code": "56305",
        "kiosk": false
    },
    {
        "value": "279613",
        "label": "Picture Printers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32312028",
        "yellow_page_code": "65012",
        "kiosk": false
    },
    {
        "value": "279614",
        "label": "Support Activities for Printing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32312029",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "279698",
        "label": "Platemaking & Related Services (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32312022",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "281201",
        "label": "Potash (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32518004",
        "yellow_page_code": "67506",
        "kiosk": false
    },
    {
        "value": "281202",
        "label": "Alkalies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32518001",
        "yellow_page_code": "01903",
        "kiosk": false
    },
    {
        "value": "281203",
        "label": "Chlorine-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32518003",
        "yellow_page_code": "15512",
        "kiosk": false
    },
    {
        "value": "281204",
        "label": "Soda Ash (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32518005",
        "yellow_page_code": "79008",
        "kiosk": false
    },
    {
        "value": "281205",
        "label": "Soda Bicarbonate (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32518006",
        "yellow_page_code": "79009",
        "kiosk": false
    },
    {
        "value": "281298",
        "label": "Alkalies & Chlorine (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32518002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "281301",
        "label": "Gas-Ind & Medical-Cylinder & Bulk-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32512001",
        "yellow_page_code": "36613",
        "kiosk": false
    },
    {
        "value": "281303",
        "label": "Hydrogen (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32512002",
        "yellow_page_code": "42111",
        "kiosk": false
    },
    {
        "value": "281304",
        "label": "Gases-Industrial & Medical (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32512003",
        "yellow_page_code": "36732",
        "kiosk": false
    },
    {
        "value": "281601",
        "label": "Titanium (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32513005",
        "yellow_page_code": "86600",
        "kiosk": false
    },
    {
        "value": "281602",
        "label": "Colors & Pigments-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32513002",
        "yellow_page_code": "19901",
        "kiosk": false
    },
    {
        "value": "281603",
        "label": "Lamp-Black (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32518008",
        "yellow_page_code": "45514",
        "kiosk": false
    },
    {
        "value": "281604",
        "label": "Pearl Essence (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32518009",
        "yellow_page_code": "60101",
        "kiosk": false
    },
    {
        "value": "281605",
        "label": "Bronze Powders & Liquids (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32513001",
        "yellow_page_code": "10909",
        "kiosk": false
    },
    {
        "value": "281606",
        "label": "Iron-Oxide (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32513004",
        "yellow_page_code": "43805",
        "kiosk": false
    },
    {
        "value": "281698",
        "label": "Inorganic Pigments (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32513003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "281901",
        "label": "Catalysts (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32518015",
        "yellow_page_code": "14101",
        "kiosk": false
    },
    {
        "value": "281902",
        "label": "Alum-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32518010",
        "yellow_page_code": "02007",
        "kiosk": false
    },
    {
        "value": "281903",
        "label": "Bleaching Compounds (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32518011",
        "yellow_page_code": "08408",
        "kiosk": false
    },
    {
        "value": "281904",
        "label": "Carbon-Activated (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32599804",
        "yellow_page_code": "13207",
        "kiosk": false
    },
    {
        "value": "281905",
        "label": "Radioactive Materials (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32518019",
        "yellow_page_code": "70003",
        "kiosk": false
    },
    {
        "value": "281906",
        "label": "Stain Repellants (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32518022",
        "yellow_page_code": "80501",
        "kiosk": false
    },
    {
        "value": "281907",
        "label": "Copper Oxide (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32518016",
        "yellow_page_code": "21512",
        "kiosk": false
    },
    {
        "value": "281908",
        "label": "Borax (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32518012",
        "yellow_page_code": "09409",
        "kiosk": false
    },
    {
        "value": "281909",
        "label": "Brine (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32518013",
        "yellow_page_code": "10802",
        "kiosk": false
    },
    {
        "value": "281910",
        "label": "Calcium Carbonate (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32518014",
        "yellow_page_code": "12304",
        "kiosk": false
    },
    {
        "value": "281911",
        "label": "Carbide Metals & Products-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32518023",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "281912",
        "label": "Iodine (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32518018",
        "yellow_page_code": "43817",
        "kiosk": false
    },
    {
        "value": "281913",
        "label": "Silica Gel (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32518020",
        "yellow_page_code": "77536",
        "kiosk": false
    },
    {
        "value": "281914",
        "label": "Silver Nitrate (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32518021",
        "yellow_page_code": "77704",
        "kiosk": false
    },
    {
        "value": "281998",
        "label": "Industrial Inorganic Chmcls NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32518017",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "282101",
        "label": "Plastics-Raw Mtrls/Powder/Resin-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32521105",
        "yellow_page_code": "66404",
        "kiosk": false
    },
    {
        "value": "282102",
        "label": "Fiber Glass Products-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32521103",
        "yellow_page_code": "31505",
        "kiosk": false
    },
    {
        "value": "282103",
        "label": "Plastics-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32521104",
        "yellow_page_code": "49915, 66202",
        "kiosk": false
    },
    {
        "value": "282104",
        "label": "Polyurethane Products-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32521107",
        "yellow_page_code": "67203",
        "kiosk": false
    },
    {
        "value": "282105",
        "label": "Resins-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32521108",
        "yellow_page_code": "71813",
        "kiosk": false
    },
    {
        "value": "282106",
        "label": "Rosin (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32521109",
        "yellow_page_code": "72806",
        "kiosk": false
    },
    {
        "value": "282107",
        "label": "Acrylic (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32521101",
        "yellow_page_code": "00213, 66237",
        "kiosk": false
    },
    {
        "value": "282108",
        "label": "Epoxy-Compounds (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32521102",
        "yellow_page_code": "29707",
        "kiosk": false
    },
    {
        "value": "282109",
        "label": "Boxes-Expanded Polystyrene Foam (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32521106",
        "yellow_page_code": "09910",
        "kiosk": false
    },
    {
        "value": "282201",
        "label": "Rubber-Synthetic (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32521201",
        "yellow_page_code": "73003, 73004",
        "kiosk": false
    },
    {
        "value": "282202",
        "label": "Urethane & Urethane Products-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32521202",
        "yellow_page_code": "90105",
        "kiosk": false
    },
    {
        "value": "282301",
        "label": "Rayon-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32522002",
        "yellow_page_code": "70512",
        "kiosk": false
    },
    {
        "value": "282302",
        "label": "Artificial & Synthetic Fibers & Filaments (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32522008",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "282398",
        "label": "Cellulosic Manmade Fibers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32522001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "282401",
        "label": "Manmade Organic Fbrs-Ex Cellulosic",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32522004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "282402",
        "label": "Bristles (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32522003",
        "yellow_page_code": "10805",
        "kiosk": false
    },
    {
        "value": "282403",
        "label": "Synthetic Fibers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32522005",
        "yellow_page_code": "83003",
        "kiosk": false
    },
    {
        "value": "282405",
        "label": "Shrink Wrapping (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32522006",
        "yellow_page_code": "77338",
        "kiosk": false
    },
    {
        "value": "282406",
        "label": "Shrink Polyolefin (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32522007",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "283301",
        "label": "Mercury (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32541102",
        "yellow_page_code": "51312",
        "kiosk": false
    },
    {
        "value": "283302",
        "label": "Witch Hazel Distilleries (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32541103",
        "yellow_page_code": "94012",
        "kiosk": false
    },
    {
        "value": "283398",
        "label": "Medicinal Chemical/Botanical Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32541101",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "283401",
        "label": "Drug-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32541202",
        "yellow_page_code": "26508",
        "kiosk": false
    },
    {
        "value": "283402",
        "label": "Poultry Medicines & Vaccines (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32541208",
        "yellow_page_code": "67802",
        "kiosk": false
    },
    {
        "value": "283403",
        "label": "Vitamin Products-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32541210",
        "yellow_page_code": "91214, 91198",
        "kiosk": false
    },
    {
        "value": "283404",
        "label": "Drug Millers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32541207",
        "yellow_page_code": "26507, 60625",
        "kiosk": false
    },
    {
        "value": "283405",
        "label": "Zinc Oxide-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32541201",
        "yellow_page_code": "94901, 94900",
        "kiosk": false
    },
    {
        "value": "283406",
        "label": "Medicines-Patent-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32541205",
        "yellow_page_code": "51120",
        "kiosk": false
    },
    {
        "value": "283407",
        "label": "Laboratories-Pharmaceutical (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32541203",
        "yellow_page_code": "45309",
        "kiosk": false
    },
    {
        "value": "283408",
        "label": "Allergy Laboratories (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32541213",
        "yellow_page_code": "01911",
        "kiosk": false
    },
    {
        "value": "283409",
        "label": "Lanolin (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32541204",
        "yellow_page_code": "45908",
        "kiosk": false
    },
    {
        "value": "283410",
        "label": "Tablet Makers-Pharm-Food Etc (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32541209",
        "yellow_page_code": "83111",
        "kiosk": false
    },
    {
        "value": "283411",
        "label": "Water Deionization (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32541211",
        "yellow_page_code": "92115",
        "kiosk": false
    },
    {
        "value": "283412",
        "label": "Water Scale Removers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32541212",
        "yellow_page_code": "92407",
        "kiosk": false
    },
    {
        "value": "283413",
        "label": "Nuclear Medicine Supplies & Acces (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32541214",
        "yellow_page_code": "55427",
        "kiosk": false
    },
    {
        "value": "283414",
        "label": "Medications Wholesale & Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32541215",
        "yellow_page_code": "51137",
        "kiosk": false
    },
    {
        "value": "283498",
        "label": "Pharmaceutical Preparation (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32541206",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "283598",
        "label": "In-Vitro/In-Vivo Diagnostic Sbstnc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32541301",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "283601",
        "label": "Biological Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32541401",
        "yellow_page_code": "08206",
        "kiosk": false
    },
    {
        "value": "283602",
        "label": "Biological Specimens-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32541402",
        "yellow_page_code": "08214",
        "kiosk": false
    },
    {
        "value": "283603",
        "label": "Fruit Pollens (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32541403",
        "yellow_page_code": "34709",
        "kiosk": false
    },
    {
        "value": "283604",
        "label": "Serums & Vaccines (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32541404",
        "yellow_page_code": "75611",
        "kiosk": false
    },
    {
        "value": "284101",
        "label": "Soaps & Detergents-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32561101",
        "yellow_page_code": "78903, 24314",
        "kiosk": false
    },
    {
        "value": "284102",
        "label": "Glycerine (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32561103",
        "yellow_page_code": "37914",
        "kiosk": false
    },
    {
        "value": "284201",
        "label": "Polishes (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32561209",
        "yellow_page_code": "67106",
        "kiosk": false
    },
    {
        "value": "284202",
        "label": "Sweeping Compounds (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32561212",
        "yellow_page_code": "82702",
        "kiosk": false
    },
    {
        "value": "284203",
        "label": "Cleaning Compounds-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32561203",
        "yellow_page_code": "18411, 18413",
        "kiosk": false
    },
    {
        "value": "284204",
        "label": "Deodorants-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32561204",
        "yellow_page_code": "24106",
        "kiosk": false
    },
    {
        "value": "284205",
        "label": "Starch (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32561211",
        "yellow_page_code": "80605",
        "kiosk": false
    },
    {
        "value": "284206",
        "label": "Beeswax (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32561201",
        "yellow_page_code": "07607",
        "kiosk": false
    },
    {
        "value": "284207",
        "label": "Disinfectants & Germicides-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32561205",
        "yellow_page_code": "25010",
        "kiosk": false
    },
    {
        "value": "284208",
        "label": "Bronze Cleaning (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32561202",
        "yellow_page_code": "10911",
        "kiosk": false
    },
    {
        "value": "284209",
        "label": "Ink Eradicators (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32561207",
        "yellow_page_code": "43124",
        "kiosk": false
    },
    {
        "value": "284210",
        "label": "Gas Odorants (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32561206",
        "yellow_page_code": "36504",
        "kiosk": false
    },
    {
        "value": "284211",
        "label": "Lye (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32561208",
        "yellow_page_code": "49101",
        "kiosk": false
    },
    {
        "value": "284298",
        "label": "Specialty Clng Plshng/Sanitation (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32561210",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "284301",
        "label": "Leather Finishers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32561301",
        "yellow_page_code": "46702",
        "kiosk": false
    },
    {
        "value": "284302",
        "label": "Oils-Penetrating (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32561302",
        "yellow_page_code": "57309",
        "kiosk": false
    },
    {
        "value": "284303",
        "label": "Textile Finishers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32561304",
        "yellow_page_code": "85310",
        "kiosk": false
    },
    {
        "value": "284398",
        "label": "Surface Active Agents (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32561303",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "284401",
        "label": "Nail Polish-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32562005",
        "yellow_page_code": "55009",
        "kiosk": false
    },
    {
        "value": "284402",
        "label": "Make-Up-Masquerade & Theatrical-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32562004",
        "yellow_page_code": "49716",
        "kiosk": false
    },
    {
        "value": "284403",
        "label": "Perfume-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32562006",
        "yellow_page_code": "60236",
        "kiosk": false
    },
    {
        "value": "284404",
        "label": "Tooth Powders & Pastes-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32561102",
        "yellow_page_code": "87212",
        "kiosk": false
    },
    {
        "value": "284405",
        "label": "Cosmetics-Manufacturers Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399836",
        "yellow_page_code": "21912",
        "kiosk": false
    },
    {
        "value": "284406",
        "label": "Health & Beauty Aids-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32562003",
        "yellow_page_code": "40517",
        "kiosk": false
    },
    {
        "value": "284407",
        "label": "Sachets (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32562008",
        "yellow_page_code": "73303",
        "kiosk": false
    },
    {
        "value": "284408",
        "label": "Fragrance-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32562002",
        "yellow_page_code": "34310",
        "kiosk": false
    },
    {
        "value": "284409",
        "label": "Bay Rum (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32562009",
        "yellow_page_code": "07129",
        "kiosk": false
    },
    {
        "value": "284410",
        "label": "Cosmetic Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32562010",
        "yellow_page_code": "21826",
        "kiosk": false
    },
    {
        "value": "284498",
        "label": "Perfumes Cosmetics/Toilet Preps (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32562007",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "285101",
        "label": "Paint Removers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32551010",
        "yellow_page_code": "58605",
        "kiosk": false
    },
    {
        "value": "285102",
        "label": "Lacquers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32551007",
        "yellow_page_code": "45500",
        "kiosk": false
    },
    {
        "value": "285103",
        "label": "Paint-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32551012",
        "yellow_page_code": "58509",
        "kiosk": false
    },
    {
        "value": "285104",
        "label": "Manufacturers-Coatings",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32551008",
        "yellow_page_code": "49924",
        "kiosk": false
    },
    {
        "value": "285106",
        "label": "Shellac-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32551017",
        "yellow_page_code": "76214",
        "kiosk": false
    },
    {
        "value": "285107",
        "label": "Fillers (Non-Metallic)(mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32551001",
        "yellow_page_code": "31604",
        "kiosk": false
    },
    {
        "value": "285108",
        "label": "Furniture Finishing Materials (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32551002",
        "yellow_page_code": "35611",
        "kiosk": false
    },
    {
        "value": "285109",
        "label": "Putty (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32551014",
        "yellow_page_code": "69209",
        "kiosk": false
    },
    {
        "value": "285110",
        "label": "Metallic Finishes (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32551009",
        "yellow_page_code": "52100",
        "kiosk": false
    },
    {
        "value": "285111",
        "label": "Lacquer Thinners (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32551006",
        "yellow_page_code": "45411",
        "kiosk": false
    },
    {
        "value": "285112",
        "label": "Glazing Compounds (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32551003",
        "yellow_page_code": "37801, 75211",
        "kiosk": false
    },
    {
        "value": "285113",
        "label": "Stainers-Wood (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32551018",
        "yellow_page_code": "80435",
        "kiosk": false
    },
    {
        "value": "285114",
        "label": "Painters Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32551013",
        "yellow_page_code": "58638",
        "kiosk": false
    },
    {
        "value": "285198",
        "label": "Paint Varnish & Allied Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32551011",
        "yellow_page_code": "58607",
        "kiosk": false
    },
    {
        "value": "286101",
        "label": "Gum & Wood Chemicals (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32519401",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "286102",
        "label": "Tanners-Oil (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32519404",
        "yellow_page_code": "83609",
        "kiosk": false
    },
    {
        "value": "286103",
        "label": "Turpentine (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32519405",
        "yellow_page_code": "89601",
        "kiosk": false
    },
    {
        "value": "286104",
        "label": "Naval Stores (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32519403",
        "yellow_page_code": "55206",
        "kiosk": false
    },
    {
        "value": "286105",
        "label": "Methanol (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32519402",
        "yellow_page_code": "52007",
        "kiosk": false
    },
    {
        "value": "286501",
        "label": "Chemicals-Blending & Grinding (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32519406",
        "yellow_page_code": "15012, 56536",
        "kiosk": false
    },
    {
        "value": "286502",
        "label": "Dyes & Dyestuffs-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32513007",
        "yellow_page_code": "26912",
        "kiosk": false
    },
    {
        "value": "286503",
        "label": "Paint-Manufacturing Materials-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32513008",
        "yellow_page_code": "58610",
        "kiosk": false
    },
    {
        "value": "286504",
        "label": "Color Cards (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32513009",
        "yellow_page_code": "19801",
        "kiosk": false
    },
    {
        "value": "286505",
        "label": "Cyclic Crude Intermediate/Gum/Wood Chemical (Mfrs) ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32519407",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "286598",
        "label": "Cyclic Organic Crudes/Dyes/Pgmnts (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32513006",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "286901",
        "label": "Flavoring Extracts-Mfrs Supls (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31193002",
        "yellow_page_code": "33000",
        "kiosk": false
    },
    {
        "value": "286902",
        "label": "Carbon Tetrachloride (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32519901",
        "yellow_page_code": "13302",
        "kiosk": false
    },
    {
        "value": "286903",
        "label": "Plasticizers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32519905",
        "yellow_page_code": "66303",
        "kiosk": false
    },
    {
        "value": "286904",
        "label": "Solvents-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32519906",
        "yellow_page_code": "79401",
        "kiosk": false
    },
    {
        "value": "286905",
        "label": "Nuclear Fuels (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32519904",
        "yellow_page_code": "55801",
        "kiosk": false
    },
    {
        "value": "286906",
        "label": "Missile & Rocket Fuels (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32599803",
        "yellow_page_code": "52910",
        "kiosk": false
    },
    {
        "value": "286907",
        "label": "Artificial Sweetener (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32519907",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "286908",
        "label": "Ethanol Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32519302",
        "yellow_page_code": "29920",
        "kiosk": false
    },
    {
        "value": "286909",
        "label": "Petrochemical Manufacturing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32511001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "286998",
        "label": "Industrial Organic Chemicals NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32519903",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "287301",
        "label": "Fertilizers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32531102",
        "yellow_page_code": "31412",
        "kiosk": false
    },
    {
        "value": "287302",
        "label": "Ammonia-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32531101",
        "yellow_page_code": "02198",
        "kiosk": false
    },
    {
        "value": "287498",
        "label": "Phosphatic Fertilizers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32531201",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "287501",
        "label": "Topsoil-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32531404",
        "yellow_page_code": "87314",
        "kiosk": false
    },
    {
        "value": "287502",
        "label": "Composites (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32531401",
        "yellow_page_code": "20136",
        "kiosk": false
    },
    {
        "value": "287503",
        "label": "Compost (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32531501",
        "yellow_page_code": "20185, 20132, 20186",
        "kiosk": false
    },
    {
        "value": "287598",
        "label": "Fertilizer Mixing Only (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32531403",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "287901",
        "label": "Insecticides-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32532005",
        "yellow_page_code": "43213",
        "kiosk": false
    },
    {
        "value": "287902",
        "label": "Lime-Manufacturers (Pesticides)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32532006",
        "yellow_page_code": "47512",
        "kiosk": false
    },
    {
        "value": "287903",
        "label": "Enzymes (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32532003",
        "yellow_page_code": "29709",
        "kiosk": false
    },
    {
        "value": "287904",
        "label": "Commercial Agricultural Cleaners (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32532002",
        "yellow_page_code": "20010",
        "kiosk": false
    },
    {
        "value": "287905",
        "label": "Pest Control Supplies & Equip-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32532008",
        "yellow_page_code": "60336",
        "kiosk": false
    },
    {
        "value": "287906",
        "label": "Bird Barriers-Repellents & Controls-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32532001",
        "yellow_page_code": "08220",
        "kiosk": false
    },
    {
        "value": "287907",
        "label": "Herbicides (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32532004",
        "yellow_page_code": "41012",
        "kiosk": false
    },
    {
        "value": "287908",
        "label": "Mothproofing Supplies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32532007",
        "yellow_page_code": "53901",
        "kiosk": false
    },
    {
        "value": "287998",
        "label": "Pesticides & Ag Chemicals NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32532009",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "289101",
        "label": "Adhesives & Glues-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32552001",
        "yellow_page_code": "00305",
        "kiosk": false
    },
    {
        "value": "289102",
        "label": "Caulking Materials & Equipment-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32552003",
        "yellow_page_code": "14135",
        "kiosk": false
    },
    {
        "value": "289103",
        "label": "Rubber Cement (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32552005",
        "yellow_page_code": "72915",
        "kiosk": false
    },
    {
        "value": "289105",
        "label": "Pipe-Joint Compounds (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32552004",
        "yellow_page_code": "65605",
        "kiosk": false
    },
    {
        "value": "289198",
        "label": "Adhesives & Sealants (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32552002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "289201",
        "label": "Explosives-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32592001",
        "yellow_page_code": "30213",
        "kiosk": false
    },
    {
        "value": "289301",
        "label": "Inks-Printing & Lithographing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32591001",
        "yellow_page_code": "43106",
        "kiosk": false
    },
    {
        "value": "289302",
        "label": "Printing Ink (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32591002",
        "yellow_page_code": "68405",
        "kiosk": false
    },
    {
        "value": "289501",
        "label": "Carbon Black-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32518007",
        "yellow_page_code": "13208",
        "kiosk": false
    },
    {
        "value": "289901",
        "label": "Fireproofing Materials (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32599811",
        "yellow_page_code": "32300, 32111, 32110, 18755, 32804",
        "kiosk": false
    },
    {
        "value": "289902",
        "label": "Anti-Freeze Compounds-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32599802",
        "yellow_page_code": "02800",
        "kiosk": false
    },
    {
        "value": "289903",
        "label": "Metal-Powder-Fabricators (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32599816",
        "yellow_page_code": "51602",
        "kiosk": false
    },
    {
        "value": "289904",
        "label": "Heat Treating Metal Equip/Supls (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32599814",
        "yellow_page_code": "40804, 51431",
        "kiosk": false
    },
    {
        "value": "289905",
        "label": "Chemicals-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32599805",
        "yellow_page_code": "15003, 00731",
        "kiosk": false
    },
    {
        "value": "289906",
        "label": "Encapsulating (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32599809",
        "yellow_page_code": "28606",
        "kiosk": false
    },
    {
        "value": "289907",
        "label": "Concrete Additives-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32599807",
        "yellow_page_code": "20405",
        "kiosk": false
    },
    {
        "value": "289908",
        "label": "Concrete Patching Compounds-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32599808",
        "yellow_page_code": "20612",
        "kiosk": false
    },
    {
        "value": "289909",
        "label": "Fireworks-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32599812",
        "yellow_page_code": "32205",
        "kiosk": false
    },
    {
        "value": "289910",
        "label": "Flux-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32599813",
        "yellow_page_code": "33606",
        "kiosk": false
    },
    {
        "value": "289911",
        "label": "Inks-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32551005",
        "yellow_page_code": "43209, 43126, 43128",
        "kiosk": false
    },
    {
        "value": "289912",
        "label": "Oil Well Drilling Mud & Additives-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32599818",
        "yellow_page_code": "56907",
        "kiosk": false
    },
    {
        "value": "289913",
        "label": "Plasterers' Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32742008",
        "yellow_page_code": "66111",
        "kiosk": false
    },
    {
        "value": "289915",
        "label": "Oil Additives-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32599817",
        "yellow_page_code": "57105, 56420",
        "kiosk": false
    },
    {
        "value": "289916",
        "label": "Rust Preventives-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32551016",
        "yellow_page_code": "73207",
        "kiosk": false
    },
    {
        "value": "289917",
        "label": "Incense-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32599822",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "289918",
        "label": "Gelatine (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32519902",
        "yellow_page_code": "37001",
        "kiosk": false
    },
    {
        "value": "289919",
        "label": "Grouting Compounds-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32551004",
        "yellow_page_code": "39114",
        "kiosk": false
    },
    {
        "value": "289920",
        "label": "Tank Cleaning Equip-Mfrs & Supls (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32599823",
        "yellow_page_code": "83415",
        "kiosk": false
    },
    {
        "value": "289921",
        "label": "Oils-Core (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32599819",
        "yellow_page_code": "57201",
        "kiosk": false
    },
    {
        "value": "289922",
        "label": "Soldering Fluxes (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32599820",
        "yellow_page_code": "79312",
        "kiosk": false
    },
    {
        "value": "289923",
        "label": "Anti-Fogging Compounds-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32599801",
        "yellow_page_code": "02711",
        "kiosk": false
    },
    {
        "value": "289924",
        "label": "Water Distilled-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32599821",
        "yellow_page_code": "92218",
        "kiosk": false
    },
    {
        "value": "291101",
        "label": "Oil Refiners (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32411006",
        "yellow_page_code": "56802",
        "kiosk": false
    },
    {
        "value": "291102",
        "label": "Oils-Re-Refined (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32411009",
        "yellow_page_code": "57401",
        "kiosk": false
    },
    {
        "value": "291103",
        "label": "Oils Fuel Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32411002",
        "yellow_page_code": "57212",
        "kiosk": false
    },
    {
        "value": "291104",
        "label": "Oils-Petroleum-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32411008",
        "yellow_page_code": "57312",
        "kiosk": false
    },
    {
        "value": "291105",
        "label": "Petroleum Products-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32411010",
        "yellow_page_code": "60535, 34834",
        "kiosk": false
    },
    {
        "value": "291106",
        "label": "Automobile-Oil Refiners-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32411001",
        "yellow_page_code": "04822",
        "kiosk": false
    },
    {
        "value": "291108",
        "label": "Mineral Spirits (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32411004",
        "yellow_page_code": "52511",
        "kiosk": false
    },
    {
        "value": "291109",
        "label": "Gas Plants (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32411003",
        "yellow_page_code": "36723",
        "kiosk": false
    },
    {
        "value": "291110",
        "label": "Oils-Mineral (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32411007",
        "yellow_page_code": "57307",
        "kiosk": false
    },
    {
        "value": "291111",
        "label": "Oil Recovery-Enhanced (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32411005",
        "yellow_page_code": "56711, 56713, 57413",
        "kiosk": false
    },
    {
        "value": "291112",
        "label": "Oils-Fuel-Alternative (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32411011",
        "yellow_page_code": "57211, 34831, 24743",
        "kiosk": false
    },
    {
        "value": "295101",
        "label": "Asphalt & Asphalt Products-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32412101",
        "yellow_page_code": "04012",
        "kiosk": false
    },
    {
        "value": "295102",
        "label": "Patching & Patching Materials (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32412102",
        "yellow_page_code": "59611",
        "kiosk": false
    },
    {
        "value": "295103",
        "label": "Paving Materials-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32412103",
        "yellow_page_code": "59905",
        "kiosk": false
    },
    {
        "value": "295201",
        "label": "Roofing Materials-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32412202",
        "yellow_page_code": "72702",
        "kiosk": false
    },
    {
        "value": "295298",
        "label": "Asphalt Felts & Coatings (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32412201",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "299201",
        "label": "Gasoline Additive-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32419104",
        "yellow_page_code": "36724",
        "kiosk": false
    },
    {
        "value": "299202",
        "label": "Lubricants-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32419105",
        "yellow_page_code": "48718, 56427",
        "kiosk": false
    },
    {
        "value": "299203",
        "label": "Cutting Compounds (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32419102",
        "yellow_page_code": "23105",
        "kiosk": false
    },
    {
        "value": "299204",
        "label": "Brake Fluids-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32419101",
        "yellow_page_code": "10190",
        "kiosk": false
    },
    {
        "value": "299205",
        "label": "Drawing & Extruding Compounds (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32419103",
        "yellow_page_code": "26103",
        "kiosk": false
    },
    {
        "value": "299298",
        "label": "Lubricating Oils & Greases (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32419106",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "299901",
        "label": "Briquettes (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32419901",
        "yellow_page_code": "10803",
        "kiosk": false
    },
    {
        "value": "299902",
        "label": "Coal By-Products",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32419907",
        "yellow_page_code": "18910",
        "kiosk": false
    },
    {
        "value": "299998",
        "label": "Products Of Petroleum & Coal NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32419906",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "301101",
        "label": "Tire-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32621102",
        "yellow_page_code": "86411",
        "kiosk": false
    },
    {
        "value": "301102",
        "label": "Tire-Retreading/Repairing-Eqpt/Supl-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32621103",
        "yellow_page_code": "86507",
        "kiosk": false
    },
    {
        "value": "301198",
        "label": "Tire & Inner Tube-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32621101",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "302198",
        "label": "Rubber & Plastics-Footwear (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31621001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "305202",
        "label": "Belting & Belting Supplies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32622001",
        "yellow_page_code": "07635",
        "kiosk": false
    },
    {
        "value": "305203",
        "label": "V-Belts-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32622006",
        "yellow_page_code": "90208",
        "kiosk": false
    },
    {
        "value": "305204",
        "label": "Vacuum Cleaners-Supplies & Parts-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32622007",
        "yellow_page_code": "90311",
        "kiosk": false
    },
    {
        "value": "305205",
        "label": "Conveyors-Belting (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32622002",
        "yellow_page_code": "21403",
        "kiosk": false
    },
    {
        "value": "305206",
        "label": "Hose & Tubing-Rubber & Plastic-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32622003",
        "yellow_page_code": "41612",
        "kiosk": false
    },
    {
        "value": "305298",
        "label": "Rubber & Plastics-Hose & Belting (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32622004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "305301",
        "label": "Seals-Oil/Grease & Etc (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999106",
        "yellow_page_code": "75201",
        "kiosk": false
    },
    {
        "value": "305302",
        "label": "Gaskets-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999102",
        "yellow_page_code": "36816",
        "kiosk": false
    },
    {
        "value": "305303",
        "label": "Seals-Metal (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999104",
        "yellow_page_code": "75107, 75123",
        "kiosk": false
    },
    {
        "value": "305304",
        "label": "Can Sealing Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999101",
        "yellow_page_code": "12611",
        "kiosk": false
    },
    {
        "value": "305305",
        "label": "Seals-O-Ring-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999105",
        "yellow_page_code": "75110",
        "kiosk": false
    },
    {
        "value": "305306",
        "label": "Seals-Security (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999107",
        "yellow_page_code": "75113",
        "kiosk": false
    },
    {
        "value": "305398",
        "label": "Gaskets-Packing & Sealing Devices (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999103",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "306198",
        "label": "Molded Extruded/Lathe Cut Rbbr Gds",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32629101",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "306901",
        "label": "Rubber Clothing & Footwear (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32629916",
        "yellow_page_code": "72908",
        "kiosk": false
    },
    {
        "value": "306902",
        "label": "Rubberbands (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32629924",
        "yellow_page_code": "72904",
        "kiosk": false
    },
    {
        "value": "306903",
        "label": "Latex Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32629908",
        "yellow_page_code": "46009, 33695",
        "kiosk": false
    },
    {
        "value": "306904",
        "label": "Rubber (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32629915",
        "yellow_page_code": "72902",
        "kiosk": false
    },
    {
        "value": "306905",
        "label": "Balloons-Novelty & Toy-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32629901",
        "yellow_page_code": "06207",
        "kiosk": false
    },
    {
        "value": "306906",
        "label": "Cable Grips-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32629904",
        "yellow_page_code": "12112",
        "kiosk": false
    },
    {
        "value": "306907",
        "label": "Heels-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32629907",
        "yellow_page_code": "40913",
        "kiosk": false
    },
    {
        "value": "306908",
        "label": "Mats & Matting-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32629911",
        "yellow_page_code": "50511",
        "kiosk": false
    },
    {
        "value": "306909",
        "label": "Brake Lining-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32629903",
        "yellow_page_code": "10213",
        "kiosk": false
    },
    {
        "value": "306910",
        "label": "Sponges-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32629926",
        "yellow_page_code": "79912",
        "kiosk": false
    },
    {
        "value": "306911",
        "label": "Gutta Percha (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32629929",
        "yellow_page_code": "39408",
        "kiosk": false
    },
    {
        "value": "306912",
        "label": "Rolls-Rubber Covered (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32629914",
        "yellow_page_code": "72513",
        "kiosk": false
    },
    {
        "value": "306913",
        "label": "Rubber Coating (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32629917",
        "yellow_page_code": "72905",
        "kiosk": false
    },
    {
        "value": "306914",
        "label": "Rubber-Latex (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32629922",
        "yellow_page_code": "72907",
        "kiosk": false
    },
    {
        "value": "306915",
        "label": "Rubber-Compounding & Mixture (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32629921",
        "yellow_page_code": "72912",
        "kiosk": false
    },
    {
        "value": "306916",
        "label": "Rubber Products-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32629918",
        "yellow_page_code": "73005, 72990, 72910",
        "kiosk": false
    },
    {
        "value": "306917",
        "label": "Rubber-Mfrs Supplies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32629923",
        "yellow_page_code": "73007",
        "kiosk": false
    },
    {
        "value": "306918",
        "label": "Rubber Rolls (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32629920",
        "yellow_page_code": "73008",
        "kiosk": false
    },
    {
        "value": "306919",
        "label": "Rubber Reclaiming (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32629919",
        "yellow_page_code": "73009",
        "kiosk": false
    },
    {
        "value": "306920",
        "label": "Rubberized Fabrics (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32629925",
        "yellow_page_code": "73100",
        "kiosk": false
    },
    {
        "value": "306921",
        "label": "Latex Liquid (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32619901",
        "yellow_page_code": "46008",
        "kiosk": false
    },
    {
        "value": "306922",
        "label": "Life Preservers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32629909",
        "yellow_page_code": "47135",
        "kiosk": false
    },
    {
        "value": "306923",
        "label": "Life Rafts & Floats-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32629910",
        "yellow_page_code": "47201",
        "kiosk": false
    },
    {
        "value": "306924",
        "label": "Condoms-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32629905",
        "yellow_page_code": "20817",
        "kiosk": false
    },
    {
        "value": "306925",
        "label": "Pulley Coverings (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32629913",
        "yellow_page_code": "69101",
        "kiosk": false
    },
    {
        "value": "306926",
        "label": "Diaphragms-Industrial (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32629906",
        "yellow_page_code": "24507",
        "kiosk": false
    },
    {
        "value": "306927",
        "label": "Wire Strippers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32629928",
        "yellow_page_code": "94002",
        "kiosk": false
    },
    {
        "value": "306928",
        "label": "Balls-Rubber (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32629902",
        "yellow_page_code": "06208",
        "kiosk": false
    },
    {
        "value": "306929",
        "label": "Type-Rubber (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32629927",
        "yellow_page_code": "89700",
        "kiosk": false
    },
    {
        "value": "306930",
        "label": "Pontoons (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33661209",
        "yellow_page_code": "67300",
        "kiosk": false
    },
    {
        "value": "306931",
        "label": "Recreational Surfaces (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32619905",
        "yellow_page_code": "71016, 58128",
        "kiosk": false
    },
    {
        "value": "306932",
        "label": "Rubber Custom Molded",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32629912",
        "yellow_page_code": "72916",
        "kiosk": false
    },
    {
        "value": "308101",
        "label": "Polyethylene Materials & Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32611306",
        "yellow_page_code": "67204",
        "kiosk": false
    },
    {
        "value": "308102",
        "label": "Plastics-Fabrics/Film & Etc Producer",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32611305",
        "yellow_page_code": "66300, 29892, 66119",
        "kiosk": false
    },
    {
        "value": "308103",
        "label": "Cellophane & Cellulose Materials & Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32611301",
        "yellow_page_code": "14302",
        "kiosk": false
    },
    {
        "value": "308104",
        "label": "Credit Card-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32611302",
        "yellow_page_code": "22433",
        "kiosk": false
    },
    {
        "value": "308105",
        "label": "Vinyl-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32611309",
        "yellow_page_code": "91218",
        "kiosk": false
    },
    {
        "value": "308106",
        "label": "Glass Coating & Tinting Materials-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32611310",
        "yellow_page_code": "37503",
        "kiosk": false
    },
    {
        "value": "308107",
        "label": "Credit Card/Other Plans Equip/Supl-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32611303",
        "yellow_page_code": "22431",
        "kiosk": false
    },
    {
        "value": "308108",
        "label": "Linings-Pits Ponds Lagoons Etc (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32611304",
        "yellow_page_code": "47613",
        "kiosk": false
    },
    {
        "value": "308109",
        "label": "Telephone Calling Cards-Whls & Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32611307",
        "yellow_page_code": "84323",
        "kiosk": false
    },
    {
        "value": "308198",
        "label": "Unsupported Plastics-Film/Sheet (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32611308",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "308201",
        "label": "Plastics-Rod/Tube/Sheets Etc-Supl (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32612101",
        "yellow_page_code": "66500, 43682",
        "kiosk": false
    },
    {
        "value": "308301",
        "label": "Plastics-High Pressure Laminates (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32613003",
        "yellow_page_code": "66306",
        "kiosk": false
    },
    {
        "value": "308302",
        "label": "Laminating Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32613002",
        "yellow_page_code": "45519",
        "kiosk": false
    },
    {
        "value": "308398",
        "label": "Laminated Plstcs-Plate Sht/Profile",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32613001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "308401",
        "label": "Pipe-Plastic (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32612201",
        "yellow_page_code": "65708",
        "kiosk": false
    },
    {
        "value": "308598",
        "label": "Plastic Bottles (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32616001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "308601",
        "label": "Plastics-Foam (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32615004",
        "yellow_page_code": "66304",
        "kiosk": false
    },
    {
        "value": "308602",
        "label": "Insulation Materials-Cold & Heat-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32615003",
        "yellow_page_code": "43334, 43333",
        "kiosk": false
    },
    {
        "value": "308603",
        "label": "Floats-Styrofoam (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32615001",
        "yellow_page_code": "33010",
        "kiosk": false
    },
    {
        "value": "308604",
        "label": "Insulated Containers & Carriers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32615002",
        "yellow_page_code": "43401",
        "kiosk": false
    },
    {
        "value": "308605",
        "label": "Polystyrene Foam Product (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32614001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "308606",
        "label": "Urethane & Other Foam Product-Except Polystyrene (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32615005",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "308798",
        "label": "Custom Compounding-Purchased Resin",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32599101",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "308801",
        "label": "Shower Doors & Enclosures-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32619103",
        "yellow_page_code": "77311",
        "kiosk": false
    },
    {
        "value": "308802",
        "label": "Hot Tubs & Spas-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32619101",
        "yellow_page_code": "41820",
        "kiosk": false
    },
    {
        "value": "308803",
        "label": "Toilets-Composting (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32619104",
        "yellow_page_code": "86809",
        "kiosk": false
    },
    {
        "value": "308804",
        "label": "Toilets-Portable-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32619105",
        "yellow_page_code": "86810",
        "kiosk": false
    },
    {
        "value": "308898",
        "label": "Plastics-Plumbing Fixtures (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32619102",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "308901",
        "label": "Plastics-Mold-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32619910",
        "yellow_page_code": "66309, 66314, 66315, 66316, 66317, 66318, 66320, 66313, 43050, 66235",
        "kiosk": false
    },
    {
        "value": "308902",
        "label": "Plastics & Plastic Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32619913",
        "yellow_page_code": "66207, 66216, 66223, 66215, 66224, 66241",
        "kiosk": false
    },
    {
        "value": "308903",
        "label": "Closures-Industrial-Protective (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32619904",
        "yellow_page_code": "18700",
        "kiosk": false
    },
    {
        "value": "308904",
        "label": "Plastics-Extruders (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32619924",
        "yellow_page_code": "66206, 30335, 66238",
        "kiosk": false
    },
    {
        "value": "308905",
        "label": "Plastics-Vacuum/Pressure Forming (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32619921",
        "yellow_page_code": "66504, 90411, 66218, 66242, 85698",
        "kiosk": false
    },
    {
        "value": "308906",
        "label": "Plastics-Fabricating/Finish/Decor-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32619916",
        "yellow_page_code": "30500, 66208, 21370",
        "kiosk": false
    },
    {
        "value": "308907",
        "label": "Boxes-Plastic & Transparent (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32619902",
        "yellow_page_code": "09908",
        "kiosk": false
    },
    {
        "value": "308908",
        "label": "Counter Coverings-Plastics-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32619906",
        "yellow_page_code": "22113",
        "kiosk": false
    },
    {
        "value": "308909",
        "label": "Paper Towel Holders-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32619911",
        "yellow_page_code": "59107",
        "kiosk": false
    },
    {
        "value": "308910",
        "label": "Plastics-Products-Finished-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32619918",
        "yellow_page_code": "66336, 66210",
        "kiosk": false
    },
    {
        "value": "308911",
        "label": "Swimming Pool Covers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32619922",
        "yellow_page_code": "82707",
        "kiosk": false
    },
    {
        "value": "308912",
        "label": "Plastics-Blow Molding (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32619914",
        "yellow_page_code": "66209",
        "kiosk": false
    },
    {
        "value": "308913",
        "label": "Embedments/Encapsulations-Plastic (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32619908",
        "yellow_page_code": "28311, 28591, 66236",
        "kiosk": false
    },
    {
        "value": "308914",
        "label": "Barrels & Drums-Plastic (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32619926",
        "yellow_page_code": "06705, 66117",
        "kiosk": false
    },
    {
        "value": "308915",
        "label": "Doors-Plastic Leather Or Fabric (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32619907",
        "yellow_page_code": "25613",
        "kiosk": false
    },
    {
        "value": "308916",
        "label": "Pharmaceutical Cntnrs Equip/Supls (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32619912",
        "yellow_page_code": "60609",
        "kiosk": false
    },
    {
        "value": "308917",
        "label": "Buoys-Marine (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33661210",
        "yellow_page_code": "09701, 50210",
        "kiosk": false
    },
    {
        "value": "308918",
        "label": "Ice Chests (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32619909",
        "yellow_page_code": "42403",
        "kiosk": false
    },
    {
        "value": "308919",
        "label": "Plastics-Prototypes (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32619919",
        "yellow_page_code": "66401",
        "kiosk": false
    },
    {
        "value": "308920",
        "label": "Plastics-Tooling (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32619920",
        "yellow_page_code": "66503",
        "kiosk": false
    },
    {
        "value": "308921",
        "label": "Windows-Plastic-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32619923",
        "yellow_page_code": "93622",
        "kiosk": false
    },
    {
        "value": "308922",
        "label": "Containers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32619927",
        "yellow_page_code": "21040",
        "kiosk": false
    },
    {
        "value": "308923",
        "label": "Manufacturing-Layered Processes (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32619915",
        "yellow_page_code": "49960",
        "kiosk": false
    },
    {
        "value": "308924",
        "label": "Molds Mfrs' Materials (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32619925",
        "yellow_page_code": "53418",
        "kiosk": false
    },
    {
        "value": "308998",
        "label": "Plastics-Products NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32619917",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "311101",
        "label": "Tanners (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31611007",
        "yellow_page_code": "83700",
        "kiosk": false
    },
    {
        "value": "311102",
        "label": "Handbags-Mfrs Equipment & Supls (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31611002",
        "yellow_page_code": "39903, 69214",
        "kiosk": false
    },
    {
        "value": "311103",
        "label": "Chamois Skins-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31611001",
        "yellow_page_code": "14703",
        "kiosk": false
    },
    {
        "value": "311104",
        "label": "Leather-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31611004",
        "yellow_page_code": "46609, 46612",
        "kiosk": false
    },
    {
        "value": "311106",
        "label": "Leather Goods-Trimmings (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31611003",
        "yellow_page_code": "46712",
        "kiosk": false
    },
    {
        "value": "311107",
        "label": "Leather-Printing & Decorating (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31611005",
        "yellow_page_code": "46803",
        "kiosk": false
    },
    {
        "value": "311108",
        "label": "Welting (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31611008",
        "yellow_page_code": "93214",
        "kiosk": false
    },
    {
        "value": "311109",
        "label": "Leather Findings & Finishes (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31611009",
        "yellow_page_code": "46618",
        "kiosk": false
    },
    {
        "value": "311110",
        "label": "Upholstery Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31611010",
        "yellow_page_code": "90040",
        "kiosk": false
    },
    {
        "value": "311111",
        "label": "Leather Tooling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31611011",
        "yellow_page_code": "46619",
        "kiosk": false
    },
    {
        "value": "311198",
        "label": "Leather-Tanning & Finishing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31611006",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "313101",
        "label": "Shoes-Manufacturers Supplies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31699028",
        "yellow_page_code": "76802",
        "kiosk": false
    },
    {
        "value": "313102",
        "label": "Leather-Upper (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31699020",
        "yellow_page_code": "46703",
        "kiosk": false
    },
    {
        "value": "313103",
        "label": "Leather-Scrap (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31699018",
        "yellow_page_code": "46705",
        "kiosk": false
    },
    {
        "value": "313104",
        "label": "Leather-Sole (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31699019",
        "yellow_page_code": "46802",
        "kiosk": false
    },
    {
        "value": "313105",
        "label": "Shoe Fabric & Leather Combiners (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31699024",
        "yellow_page_code": "76707",
        "kiosk": false
    },
    {
        "value": "313106",
        "label": "Shoe Fillers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31699025",
        "yellow_page_code": "76708",
        "kiosk": false
    },
    {
        "value": "313107",
        "label": "Shoe Counters (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31699023",
        "yellow_page_code": "76711",
        "kiosk": false
    },
    {
        "value": "313108",
        "label": "Shoe Linings (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31699027",
        "yellow_page_code": "76712",
        "kiosk": false
    },
    {
        "value": "313109",
        "label": "Shoe Findings & Supplies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31699029",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "313198",
        "label": "Boot & Shoe-Cut Stock & Findings (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31699014",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "314201",
        "label": "House Slippers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31621002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "314301",
        "label": "Boots-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31621003",
        "yellow_page_code": "09401",
        "kiosk": false
    },
    {
        "value": "314398",
        "label": "Men's Footwear-Except Athletic (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31621004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "314401",
        "label": "Womens Footwear-Except Athletic (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31621006",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "314402",
        "label": "Shoes-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31621005",
        "yellow_page_code": "76801, 73517",
        "kiosk": false
    },
    {
        "value": "314998",
        "label": "Footwear Except Rubber NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31621007",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "315198",
        "label": "Leather Gloves & Mittens (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31599006",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "316101",
        "label": "Sample Cases (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31699006",
        "yellow_page_code": "73700",
        "kiosk": false
    },
    {
        "value": "316102",
        "label": "Cases-Carrying-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31699004",
        "yellow_page_code": "13922, 13941",
        "kiosk": false
    },
    {
        "value": "316103",
        "label": "Trunks-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31699007",
        "yellow_page_code": "89112",
        "kiosk": false
    },
    {
        "value": "316104",
        "label": "Luggage (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31699016",
        "yellow_page_code": "48812",
        "kiosk": false
    },
    {
        "value": "316105",
        "label": "Wardrobes (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31699008",
        "yellow_page_code": "91511",
        "kiosk": false
    },
    {
        "value": "316107",
        "label": "Case Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31699005",
        "yellow_page_code": "13949",
        "kiosk": false
    },
    {
        "value": "317101",
        "label": "Handbags-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31699001",
        "yellow_page_code": "39905, 05816",
        "kiosk": false
    },
    {
        "value": "317198",
        "label": "Women's Handbags & Purses (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31699002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "317201",
        "label": "Key Holders (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31699010",
        "yellow_page_code": "44806",
        "kiosk": false
    },
    {
        "value": "317202",
        "label": "Leather Goods-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31699012",
        "yellow_page_code": "46704, 43768",
        "kiosk": false
    },
    {
        "value": "317203",
        "label": "Jewelry Cases (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31699009",
        "yellow_page_code": "44307",
        "kiosk": false
    },
    {
        "value": "317204",
        "label": "Sewing Kits & Cases (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31699013",
        "yellow_page_code": "75905",
        "kiosk": false
    },
    {
        "value": "317205",
        "label": "Leather Goods Mfrs' Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31699011",
        "yellow_page_code": "46710, 46616",
        "kiosk": false
    },
    {
        "value": "319901",
        "label": "Saddlery & Harness-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31699022",
        "yellow_page_code": "73320",
        "kiosk": false
    },
    {
        "value": "319902",
        "label": "Holsters (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31699015",
        "yellow_page_code": "41116",
        "kiosk": false
    },
    {
        "value": "319903",
        "label": "Polo Supplies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31699021",
        "yellow_page_code": "67202",
        "kiosk": false
    },
    {
        "value": "319904",
        "label": "Horse Equipment & Supplies-Whls & Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31699026",
        "yellow_page_code": "41528, 41545",
        "kiosk": false
    },
    {
        "value": "319998",
        "label": "Leather Goods NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31699017",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "321101",
        "label": "Glass-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32721103",
        "yellow_page_code": "37701, 37426",
        "kiosk": false
    },
    {
        "value": "321102",
        "label": "Floats-Metal Glass & Etc (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32721102",
        "yellow_page_code": "33007",
        "kiosk": false
    },
    {
        "value": "321103",
        "label": "Doors-Glass-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32721101",
        "yellow_page_code": "25635",
        "kiosk": false
    },
    {
        "value": "321104",
        "label": "Flat Glass (Mfrs) ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32721104",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "322101",
        "label": "Bottles (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32721301",
        "yellow_page_code": "09602",
        "kiosk": false
    },
    {
        "value": "322102",
        "label": "Carboys (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32721302",
        "yellow_page_code": "13307",
        "kiosk": false
    },
    {
        "value": "322103",
        "label": "Vials (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32721304",
        "yellow_page_code": "91013",
        "kiosk": false
    },
    {
        "value": "322198",
        "label": "Glass Containers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32721303",
        "yellow_page_code": "21090",
        "kiosk": false
    },
    {
        "value": "322901",
        "label": "Glass-Blowers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32721203",
        "yellow_page_code": "37406, 37407, 08520",
        "kiosk": false
    },
    {
        "value": "322902",
        "label": "Balls-Glass (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32721201",
        "yellow_page_code": "06201",
        "kiosk": false
    },
    {
        "value": "322904",
        "label": "Lamps-Supplies & Parts (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32721207",
        "yellow_page_code": "45708",
        "kiosk": false
    },
    {
        "value": "322905",
        "label": "Pressed/Blown Glass/Glassware-NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32721206",
        "yellow_page_code": "37715",
        "kiosk": false
    },
    {
        "value": "322906",
        "label": "Glass-Rods & Tubing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32721205",
        "yellow_page_code": "37600",
        "kiosk": false
    },
    {
        "value": "322907",
        "label": "Glass-Pressed Molded & Etc (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32721204",
        "yellow_page_code": "37703",
        "kiosk": false
    },
    {
        "value": "323101",
        "label": "Glass Substitutes (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32721508",
        "yellow_page_code": "37602",
        "kiosk": false
    },
    {
        "value": "323102",
        "label": "Engravers-Glassware (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32721503",
        "yellow_page_code": "29508, 37606, 29410, 37525",
        "kiosk": false
    },
    {
        "value": "323103",
        "label": "Glass-Stained & Leaded-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32721511",
        "yellow_page_code": "37607",
        "kiosk": false
    },
    {
        "value": "323104",
        "label": "Mirrors-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32721513",
        "yellow_page_code": "52704, 52701",
        "kiosk": false
    },
    {
        "value": "323105",
        "label": "Glassware Decoration (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32721512",
        "yellow_page_code": "37708",
        "kiosk": false
    },
    {
        "value": "323106",
        "label": "Aquariums & Aquarium Supls-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32721501",
        "yellow_page_code": "03117",
        "kiosk": false
    },
    {
        "value": "323107",
        "label": "Novelties-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32721514",
        "yellow_page_code": "55735",
        "kiosk": false
    },
    {
        "value": "323108",
        "label": "Crystals-Industrial (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32721502",
        "yellow_page_code": "22807",
        "kiosk": false
    },
    {
        "value": "323109",
        "label": "Glass-Ground (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32721509",
        "yellow_page_code": "37512",
        "kiosk": false
    },
    {
        "value": "323110",
        "label": "Reflectors (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32721515",
        "yellow_page_code": "71205",
        "kiosk": false
    },
    {
        "value": "323111",
        "label": "Watch Crystals (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32721518",
        "yellow_page_code": "91906",
        "kiosk": false
    },
    {
        "value": "323112",
        "label": "Scientific Apparatus & Instruments-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32721516",
        "yellow_page_code": "74714, 74726",
        "kiosk": false
    },
    {
        "value": "323113",
        "label": "Glass Lighting (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32721505",
        "yellow_page_code": "37513",
        "kiosk": false
    },
    {
        "value": "323114",
        "label": "Table Tops-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32721517",
        "yellow_page_code": "83110",
        "kiosk": false
    },
    {
        "value": "323115",
        "label": "Glass-Lettering & Applied Color (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32721510",
        "yellow_page_code": "37515",
        "kiosk": false
    },
    {
        "value": "323116",
        "label": "Glass Decorators (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32721504",
        "yellow_page_code": "37609",
        "kiosk": false
    },
    {
        "value": "323117",
        "label": "Glass Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32721507",
        "yellow_page_code": "37707",
        "kiosk": false
    },
    {
        "value": "323118",
        "label": "Glass Automobile Plate Window Etc Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32721519",
        "yellow_page_code": "37411",
        "kiosk": false
    },
    {
        "value": "323119",
        "label": "Machining Safety Glass (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32721520",
        "yellow_page_code": "49224",
        "kiosk": false
    },
    {
        "value": "323120",
        "label": "Security-Glass (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32721521",
        "yellow_page_code": "75342, 37521",
        "kiosk": false
    },
    {
        "value": "323198",
        "label": "Glass Prod Made-Purchased Glass (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32721506",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "324101",
        "label": "Cement-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32731001",
        "yellow_page_code": "14312",
        "kiosk": false
    },
    {
        "value": "325101",
        "label": "Brick-Clay Common & Face-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32712001",
        "yellow_page_code": "10505",
        "kiosk": false
    },
    {
        "value": "325102",
        "label": "Brick-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32712002",
        "yellow_page_code": "10600, 10525",
        "kiosk": false
    },
    {
        "value": "325103",
        "label": "Chimney Lining Materials-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32712003",
        "yellow_page_code": "15312",
        "kiosk": false
    },
    {
        "value": "325301",
        "label": "Tile-Ceramic-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32712004",
        "yellow_page_code": "86006",
        "kiosk": false
    },
    {
        "value": "325501",
        "label": "Crucibles (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32712010",
        "yellow_page_code": "22707",
        "kiosk": false
    },
    {
        "value": "325598",
        "label": "Clay Refractories (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32712009",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "325901",
        "label": "Adobe Blocks & Shapes (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32712005",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "325902",
        "label": "Drain Pipes-Clay-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32712014",
        "yellow_page_code": "25811",
        "kiosk": false
    },
    {
        "value": "325903",
        "label": "Terra Cotta-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32712008",
        "yellow_page_code": "85220",
        "kiosk": false
    },
    {
        "value": "325998",
        "label": "Structural Clay Products NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32712007",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "326101",
        "label": "Bathroom Accessories-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32711002",
        "yellow_page_code": "06913",
        "kiosk": false
    },
    {
        "value": "326102",
        "label": "Bath Equipment & Supplies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32711001",
        "yellow_page_code": "06914",
        "kiosk": false
    },
    {
        "value": "326201",
        "label": "Chinaware-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32711007",
        "yellow_page_code": "15401",
        "kiosk": false
    },
    {
        "value": "326298",
        "label": "Vitreous China-Tbl/Ktchn Articles (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32711014",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "326398",
        "label": "Fine Earthenware Tbl/Ktchn Article",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32711009",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "326401",
        "label": "Ferrites (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32711016",
        "yellow_page_code": "31403",
        "kiosk": false
    },
    {
        "value": "326498",
        "label": "Porcelain-Electrical-Supplies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32711017",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "326901",
        "label": "Chinaware Decorators (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32711006",
        "yellow_page_code": "15406",
        "kiosk": false
    },
    {
        "value": "326902",
        "label": "Giftwares-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32711011",
        "yellow_page_code": "37305",
        "kiosk": false
    },
    {
        "value": "326903",
        "label": "Ceramic Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32711004",
        "yellow_page_code": "14411",
        "kiosk": false
    },
    {
        "value": "326904",
        "label": "Ceramic Products-Decorative-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32711005",
        "yellow_page_code": "14412",
        "kiosk": false
    },
    {
        "value": "326905",
        "label": "Pottery-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32711013",
        "yellow_page_code": "67701",
        "kiosk": false
    },
    {
        "value": "326906",
        "label": "Furniture Accessories Decor-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32711010",
        "yellow_page_code": "35613",
        "kiosk": false
    },
    {
        "value": "326907",
        "label": "Cone-Seed & Decorative (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32711008",
        "yellow_page_code": "20923",
        "kiosk": false
    },
    {
        "value": "326908",
        "label": "Asbestos Replacement Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32711003",
        "yellow_page_code": "03922",
        "kiosk": false
    },
    {
        "value": "326998",
        "label": "Pottery Products NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32711012",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "327101",
        "label": "Concrete-Block & Brick (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32733102",
        "yellow_page_code": "20513",
        "kiosk": false
    },
    {
        "value": "327102",
        "label": "Cinder Blocks (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32733101",
        "yellow_page_code": "18001",
        "kiosk": false
    },
    {
        "value": "327201",
        "label": "Concrete-Prestressed (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32739003",
        "yellow_page_code": "20701, 67948",
        "kiosk": false
    },
    {
        "value": "327203",
        "label": "Fireplaces-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32739004",
        "yellow_page_code": "32218",
        "kiosk": false
    },
    {
        "value": "327204",
        "label": "Fountains-Garden Display Etc-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32739005",
        "yellow_page_code": "34235",
        "kiosk": false
    },
    {
        "value": "327205",
        "label": "Grease Traps-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32739006",
        "yellow_page_code": "38911",
        "kiosk": false
    },
    {
        "value": "327206",
        "label": "Septic Tanks-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32739007",
        "yellow_page_code": "75536",
        "kiosk": false
    },
    {
        "value": "327207",
        "label": "Stone-Artificial (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32739009",
        "yellow_page_code": "81610",
        "kiosk": false
    },
    {
        "value": "327208",
        "label": "Concrete Pipe (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32733201",
        "yellow_page_code": "20707, 65503, 65415",
        "kiosk": false
    },
    {
        "value": "327209",
        "label": "Concrete Products-Except Block & Brick (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32739002",
        "yellow_page_code": "20715, 20615, 23622, 20520, 20721, 20410, 59722",
        "kiosk": false
    },
    {
        "value": "327210",
        "label": "Catch Basin Covers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32739001",
        "yellow_page_code": "14205",
        "kiosk": false
    },
    {
        "value": "327211",
        "label": "Steps (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32739008",
        "yellow_page_code": "81205, 80901",
        "kiosk": false
    },
    {
        "value": "327301",
        "label": "Ready-Mixed Concrete-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32732001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "327401",
        "label": "Hydrator-Lime (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32741001",
        "yellow_page_code": "42126",
        "kiosk": false
    },
    {
        "value": "327498",
        "label": "Lime-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32741002",
        "yellow_page_code": "47490",
        "kiosk": false
    },
    {
        "value": "327501",
        "label": "Gypsum & Gypsum Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32742002",
        "yellow_page_code": "39508",
        "kiosk": false
    },
    {
        "value": "327502",
        "label": "Plaster Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32742005",
        "yellow_page_code": "66200",
        "kiosk": false
    },
    {
        "value": "328101",
        "label": "Granite Products-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32799104",
        "yellow_page_code": "38712",
        "kiosk": false
    },
    {
        "value": "328102",
        "label": "Marble Products-Natural-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32799106",
        "yellow_page_code": "49824",
        "kiosk": false
    },
    {
        "value": "328103",
        "label": "Stone Products-Natural-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32799110",
        "yellow_page_code": "81613",
        "kiosk": false
    },
    {
        "value": "328104",
        "label": "Monuments-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32799107",
        "yellow_page_code": "53512",
        "kiosk": false
    },
    {
        "value": "328105",
        "label": "Tile-Marble-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32799111",
        "yellow_page_code": "86007",
        "kiosk": false
    },
    {
        "value": "328106",
        "label": "Flagstones (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32799103",
        "yellow_page_code": "32801",
        "kiosk": false
    },
    {
        "value": "328107",
        "label": "Stone Cutters (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32799108",
        "yellow_page_code": "81600, 38816",
        "kiosk": false
    },
    {
        "value": "328108",
        "label": "Blackboards-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32799101",
        "yellow_page_code": "08337",
        "kiosk": false
    },
    {
        "value": "328109",
        "label": "Stone-Cut (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32799109",
        "yellow_page_code": "81603",
        "kiosk": false
    },
    {
        "value": "328110",
        "label": "Marble Contractors Equip & Supls (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32799105",
        "yellow_page_code": "49807, 30496",
        "kiosk": false
    },
    {
        "value": "328111",
        "label": "Stone-Manufactured",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32799112",
        "yellow_page_code": "81609",
        "kiosk": false
    },
    {
        "value": "328112",
        "label": "Stone Carving (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32799113",
        "yellow_page_code": "81592",
        "kiosk": false
    },
    {
        "value": "328113",
        "label": "Granite Cleaning Maintenance-Restoration",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32799114",
        "yellow_page_code": "38803",
        "kiosk": false
    },
    {
        "value": "328198",
        "label": "Cut Stone & Stone Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32799102",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "329101",
        "label": "Grinding Wheels (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32791004",
        "yellow_page_code": "39102",
        "kiosk": false
    },
    {
        "value": "329102",
        "label": "Abrasive Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32791001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "329103",
        "label": "Aluminum Wool-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32791002",
        "yellow_page_code": "02013",
        "kiosk": false
    },
    {
        "value": "329104",
        "label": "Grinding Wheel Dressers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32791003",
        "yellow_page_code": "39103",
        "kiosk": false
    },
    {
        "value": "329105",
        "label": "Steel Wool (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32791006",
        "yellow_page_code": "81103",
        "kiosk": false
    },
    {
        "value": "329106",
        "label": "Sandpaper (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32791005",
        "yellow_page_code": "73709",
        "kiosk": false
    },
    {
        "value": "329201",
        "label": "Asbestos & Asbestos Free Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32799901",
        "yellow_page_code": "03917",
        "kiosk": false
    },
    {
        "value": "329202",
        "label": "Friction Materials (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32799904",
        "yellow_page_code": "34509",
        "kiosk": false
    },
    {
        "value": "329501",
        "label": "Slag (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32799207",
        "yellow_page_code": "78300",
        "kiosk": false
    },
    {
        "value": "329502",
        "label": "Graphite (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32799203",
        "yellow_page_code": "38807",
        "kiosk": false
    },
    {
        "value": "329503",
        "label": "Talc (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32799208",
        "yellow_page_code": "83404",
        "kiosk": false
    },
    {
        "value": "329504",
        "label": "Pumice (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32799206",
        "yellow_page_code": "69105",
        "kiosk": false
    },
    {
        "value": "329505",
        "label": "Flints (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32799202",
        "yellow_page_code": "33012",
        "kiosk": false
    },
    {
        "value": "329506",
        "label": "Diatomaceous Earth (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32799201",
        "yellow_page_code": "24603",
        "kiosk": false
    },
    {
        "value": "329507",
        "label": "Perlite & Perlite Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32799205",
        "yellow_page_code": "60305",
        "kiosk": false
    },
    {
        "value": "329508",
        "label": "Vermiculite (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32799209",
        "yellow_page_code": "90900",
        "kiosk": false
    },
    {
        "value": "329598",
        "label": "Minerals/Earths-Ground Or Treated (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32799204",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "329601",
        "label": "Fiber Glass Fabricators (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32799303",
        "yellow_page_code": "31502, 37526",
        "kiosk": false
    },
    {
        "value": "329602",
        "label": "Acoustical Materials-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32799301",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "329603",
        "label": "Insulation-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32799304",
        "yellow_page_code": "43312",
        "kiosk": false
    },
    {
        "value": "329604",
        "label": "Energy Conservation Products-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32799302",
        "yellow_page_code": "28632",
        "kiosk": false
    },
    {
        "value": "329698",
        "label": "Mineral Wool (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32799305",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "329701",
        "label": "Refractories (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32712013",
        "yellow_page_code": "71206",
        "kiosk": false
    },
    {
        "value": "329702",
        "label": "Brick-Imitation (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32712011",
        "yellow_page_code": "10507",
        "kiosk": false
    },
    {
        "value": "329798",
        "label": "Nonclay Refractories (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32712012",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "329901",
        "label": "Mica (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32742004",
        "yellow_page_code": "52201",
        "kiosk": false
    },
    {
        "value": "329902",
        "label": "Ceramic Products-Industrial (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32742001",
        "yellow_page_code": "14407",
        "kiosk": false
    },
    {
        "value": "329903",
        "label": "Glass-Block Structural & Etc (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32799905",
        "yellow_page_code": "37413",
        "kiosk": false
    },
    {
        "value": "329904",
        "label": "Plaques-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32799907",
        "yellow_page_code": "66036",
        "kiosk": false
    },
    {
        "value": "329906",
        "label": "Art Goods-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32742007",
        "yellow_page_code": "03413",
        "kiosk": false
    },
    {
        "value": "329907",
        "label": "Stucco-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32742006",
        "yellow_page_code": "82036",
        "kiosk": false
    },
    {
        "value": "329908",
        "label": "Fossil Replicas (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32799903",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "329909",
        "label": "Composition Ornaments",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32799902",
        "yellow_page_code": "20131",
        "kiosk": false
    },
    {
        "value": "329998",
        "label": "Nonmetallic Mineral Products NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32799906",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "331201",
        "label": "Steel Mills (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33111007",
        "yellow_page_code": "81006",
        "kiosk": false
    },
    {
        "value": "331202",
        "label": "Tubing (Manufacturers)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33111011",
        "yellow_page_code": "89307",
        "kiosk": false
    },
    {
        "value": "331203",
        "label": "Expanded Metals (Manufacturers)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33111002",
        "yellow_page_code": "30104, 51905, 51910",
        "kiosk": false
    },
    {
        "value": "331204",
        "label": "Steel Processing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33111008",
        "yellow_page_code": "81008, 81112",
        "kiosk": false
    },
    {
        "value": "331205",
        "label": "Ironwork-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33111003",
        "yellow_page_code": "43815",
        "kiosk": false
    },
    {
        "value": "331206",
        "label": "Tubing-Metal-Fabricators",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33111012",
        "yellow_page_code": "89311",
        "kiosk": false
    },
    {
        "value": "331208",
        "label": "Tubing-Metal-Machinery (Manufacturers)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33111013",
        "yellow_page_code": "89301",
        "kiosk": false
    },
    {
        "value": "331209",
        "label": "Wire Products-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33122208",
        "yellow_page_code": "93918, 93924",
        "kiosk": false
    },
    {
        "value": "331211",
        "label": "Rails & Accessories (Manufacturers)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33131805",
        "yellow_page_code": "70303",
        "kiosk": false
    },
    {
        "value": "331212",
        "label": "Stainless Steel (Manufacturers)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33111006",
        "yellow_page_code": "81009, 80436",
        "kiosk": false
    },
    {
        "value": "331213",
        "label": "Tin Plate (Manufacturers)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33111010",
        "yellow_page_code": "86300",
        "kiosk": false
    },
    {
        "value": "331214",
        "label": "Coil Tubing (Manufacturers)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32419902",
        "yellow_page_code": "19311",
        "kiosk": false
    },
    {
        "value": "331215",
        "label": "Coils-Plate (Manufacturers)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32419904",
        "yellow_page_code": "19336",
        "kiosk": false
    },
    {
        "value": "331216",
        "label": "Coils-Pipe (Manufacturers)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32419903",
        "yellow_page_code": "19407",
        "kiosk": false
    },
    {
        "value": "331217",
        "label": "Hydroforming (Manufacturers)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32419905",
        "yellow_page_code": "42127",
        "kiosk": false
    },
    {
        "value": "331218",
        "label": "Scrap Metal Products-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33111014",
        "yellow_page_code": "74733",
        "kiosk": false
    },
    {
        "value": "331219",
        "label": "Iron & Steel Mills & Ferroalloy (Mfrs) ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33111017",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "331298",
        "label": "Steel Works/Blast Furnaces/Rolling Mills",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33111009",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "331301",
        "label": "Molybdenum (Manufacturers)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33111016",
        "yellow_page_code": "53405",
        "kiosk": false
    },
    {
        "value": "331398",
        "label": "Electrometallurgical Prod-Ex Steel (Mfr)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33111015",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "331501",
        "label": "Wire-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33122207",
        "yellow_page_code": "94005",
        "kiosk": false
    },
    {
        "value": "331502",
        "label": "Fence Posts & Fittings-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33122202",
        "yellow_page_code": "31313",
        "kiosk": false
    },
    {
        "value": "331503",
        "label": "Wire Forms & Specialties (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33122206",
        "yellow_page_code": "93916",
        "kiosk": false
    },
    {
        "value": "331504",
        "label": "Bag Ties (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33122201",
        "yellow_page_code": "05805",
        "kiosk": false
    },
    {
        "value": "331505",
        "label": "Cables Steel (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33122204",
        "yellow_page_code": "12145",
        "kiosk": false
    },
    {
        "value": "331507",
        "label": "Nails & Tacks-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33122203",
        "yellow_page_code": "55013",
        "kiosk": false
    },
    {
        "value": "331598",
        "label": "Steel-Wiredrawing/Stl Nails/Spikes (Mfr)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33122205",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "331601",
        "label": "Steel-Bar/Sheet/Strip/Tube & Etc (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33122103",
        "yellow_page_code": "81105, 80913, 81023",
        "kiosk": false
    },
    {
        "value": "331602",
        "label": "Metals-Corrugated (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33122101",
        "yellow_page_code": "51903",
        "kiosk": false
    },
    {
        "value": "331603",
        "label": "Steel Straightening (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33122102",
        "yellow_page_code": "81013",
        "kiosk": false
    },
    {
        "value": "331604",
        "label": "Pipe Corrugated Metal",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33122104",
        "yellow_page_code": "65789",
        "kiosk": false
    },
    {
        "value": "331701",
        "label": "Boiler Tubes (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33121001",
        "yellow_page_code": "09001",
        "kiosk": false
    },
    {
        "value": "331702",
        "label": "Pipe-Stainless (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33121002",
        "yellow_page_code": "65405",
        "kiosk": false
    },
    {
        "value": "331703",
        "label": "Gas Piping (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33121004",
        "yellow_page_code": "36710",
        "kiosk": false
    },
    {
        "value": "331704",
        "label": "Medical Gas Piping Systs-Install (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33121005",
        "yellow_page_code": "51041",
        "kiosk": false
    },
    {
        "value": "331798",
        "label": "Steel Pipe & Tubes (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33121003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "332101",
        "label": "Foundries-Iron (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33151104",
        "yellow_page_code": "34216",
        "kiosk": false
    },
    {
        "value": "332102",
        "label": "Foundries-Gray Iron (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33151103",
        "yellow_page_code": "34213",
        "kiosk": false
    },
    {
        "value": "332103",
        "label": "Manhole Covers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33151107",
        "yellow_page_code": "49803",
        "kiosk": false
    },
    {
        "value": "332104",
        "label": "Foundries-Ductile Iron (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33151102",
        "yellow_page_code": "34215",
        "kiosk": false
    },
    {
        "value": "332105",
        "label": "Sash Balances & Weights (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33151108",
        "yellow_page_code": "73916",
        "kiosk": false
    },
    {
        "value": "332106",
        "label": "Castings-Iron (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33151101",
        "yellow_page_code": "14017, 43823",
        "kiosk": false
    },
    {
        "value": "332107",
        "label": "Foundries-Nickel & Alloys (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33151109",
        "yellow_page_code": "34219",
        "kiosk": false
    },
    {
        "value": "332108",
        "label": "Sewer Pipe-Retail (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33151110",
        "yellow_page_code": "65702, 75816",
        "kiosk": false
    },
    {
        "value": "332198",
        "label": "Gray & Ductile Iron Foundries (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33151106",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "332201",
        "label": "Foundries-Malleable Iron (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33151105",
        "yellow_page_code": "34214",
        "kiosk": false
    },
    {
        "value": "332401",
        "label": "Investment Castings (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33151201",
        "yellow_page_code": "14011, 43705",
        "kiosk": false
    },
    {
        "value": "332501",
        "label": "Castings-Ferrous Metals (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33151302",
        "yellow_page_code": "14008, 80942",
        "kiosk": false
    },
    {
        "value": "332502",
        "label": "Foundries-Steel (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33151304",
        "yellow_page_code": "34206, 34223",
        "kiosk": false
    },
    {
        "value": "332503",
        "label": "Steel Mill Services (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33151307",
        "yellow_page_code": "81003",
        "kiosk": false
    },
    {
        "value": "332504",
        "label": "Bushings & Bearings-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33151301",
        "yellow_page_code": "11711",
        "kiosk": false
    },
    {
        "value": "332505",
        "label": "Castings-Steel (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33151303",
        "yellow_page_code": "14014, 80943",
        "kiosk": false
    },
    {
        "value": "332506",
        "label": "Metal Cutting Coolant Systems (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33151306",
        "yellow_page_code": "51405",
        "kiosk": false
    },
    {
        "value": "332507",
        "label": "Iron-Ore (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33151305",
        "yellow_page_code": "43818",
        "kiosk": false
    },
    {
        "value": "332508",
        "label": "Steel-Tool (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33151308",
        "yellow_page_code": "81012",
        "kiosk": false
    },
    {
        "value": "332509",
        "label": "Foundries Various Metals",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33151309",
        "yellow_page_code": "34222",
        "kiosk": false
    },
    {
        "value": "333198",
        "label": "Primary Smelting-Refining-Copper (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33141001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "333498",
        "label": "Primary Production Of Aluminum (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33131301",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "333901",
        "label": "Smelters-Refiners-Precious Metals (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33141007",
        "yellow_page_code": "78500, 71191, 68031, 68029",
        "kiosk": false
    },
    {
        "value": "333902",
        "label": "Smelters & Refiners-Base Metals (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33141006",
        "yellow_page_code": "78408, 71190, 71212",
        "kiosk": false
    },
    {
        "value": "333903",
        "label": "Beryllium (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33141002",
        "yellow_page_code": "07707",
        "kiosk": false
    },
    {
        "value": "333904",
        "label": "Tantalum (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33141008",
        "yellow_page_code": "83705",
        "kiosk": false
    },
    {
        "value": "333905",
        "label": "Cadmium (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33141003",
        "yellow_page_code": "12215",
        "kiosk": false
    },
    {
        "value": "333906",
        "label": "Silicon (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33141005",
        "yellow_page_code": "77609",
        "kiosk": false
    },
    {
        "value": "333998",
        "label": "Primary Smelting/Refining-Nonferrous Mtl",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33141004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "334101",
        "label": "Babbitting (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33142009",
        "yellow_page_code": "05503, 05502",
        "kiosk": false
    },
    {
        "value": "334102",
        "label": "Silver Recovery (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33149206",
        "yellow_page_code": "77706",
        "kiosk": false
    },
    {
        "value": "334103",
        "label": "Refining (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33149202",
        "yellow_page_code": "71203",
        "kiosk": false
    },
    {
        "value": "334104",
        "label": "Scrap Film (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33149203",
        "yellow_page_code": "74801",
        "kiosk": false
    },
    {
        "value": "334105",
        "label": "Selenium (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33149205",
        "yellow_page_code": "75437",
        "kiosk": false
    },
    {
        "value": "334106",
        "label": "Brass Mills (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33149201",
        "yellow_page_code": "10313",
        "kiosk": false
    },
    {
        "value": "334107",
        "label": "Secondary Smelting & Alloying-Aluminum ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33131401",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "334198",
        "label": "Secondary Smelting & Refining-Nonferrous",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33149204",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "335101",
        "label": "Metal Shapes-Extruded (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33142005",
        "yellow_page_code": "51608",
        "kiosk": false
    },
    {
        "value": "335102",
        "label": "Rolling Drawing-Non-Ferrous Mtls (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33142006",
        "yellow_page_code": "72536",
        "kiosk": false
    },
    {
        "value": "335103",
        "label": "Copper-Sheet Rod Tube Etc (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33142004",
        "yellow_page_code": "21511",
        "kiosk": false
    },
    {
        "value": "335104",
        "label": "Copper Products-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33142010",
        "yellow_page_code": "21523",
        "kiosk": false
    },
    {
        "value": "335105",
        "label": "Brass Rubbings (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33142001",
        "yellow_page_code": "10314",
        "kiosk": false
    },
    {
        "value": "335106",
        "label": "Bronze-Sheet Rod Tube & Etc (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33142002",
        "yellow_page_code": "10912",
        "kiosk": false
    },
    {
        "value": "335107",
        "label": "Iron Sheet Rod & Tube (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33142008",
        "yellow_page_code": "43821",
        "kiosk": false
    },
    {
        "value": "335198",
        "label": "Rolling Drawing/Extruding-Copper (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33142007",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "335301",
        "label": "Aluminum Sheet Plate & Foil (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33131501",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "335498",
        "label": "Aluminum Extruded Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33131801",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "335501",
        "label": "Aluminum Fabricators (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33131802",
        "yellow_page_code": "02100, 02003, 02006, 02096",
        "kiosk": false
    },
    {
        "value": "335502",
        "label": "Anchors-Rail (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33131804",
        "yellow_page_code": "02315",
        "kiosk": false
    },
    {
        "value": "335598",
        "label": "Aluminum Rolling & Drawing NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33131803",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "335601",
        "label": "Lead Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33149103",
        "yellow_page_code": "46506",
        "kiosk": false
    },
    {
        "value": "335602",
        "label": "Solder (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33149106",
        "yellow_page_code": "79304",
        "kiosk": false
    },
    {
        "value": "335603",
        "label": "Metal-Rolling & Forming (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33149104",
        "yellow_page_code": "51604",
        "kiosk": false
    },
    {
        "value": "335604",
        "label": "Gold Filled & Rolled Gold Plate (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33149102",
        "yellow_page_code": "37935",
        "kiosk": false
    },
    {
        "value": "335605",
        "label": "Tin (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33149107",
        "yellow_page_code": "86205",
        "kiosk": false
    },
    {
        "value": "335606",
        "label": "Tin Edging (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33149108",
        "yellow_page_code": "86209",
        "kiosk": false
    },
    {
        "value": "335607",
        "label": "Zirconium (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33149110",
        "yellow_page_code": "94908",
        "kiosk": false
    },
    {
        "value": "335608",
        "label": "Tungsten (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33149109",
        "yellow_page_code": "89402",
        "kiosk": false
    },
    {
        "value": "335609",
        "label": "Lead Shielding Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33149111",
        "yellow_page_code": "46526",
        "kiosk": false
    },
    {
        "value": "335698",
        "label": "Rolling Drawing Extruding-Nonferrous Mtl",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33149105",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "335701",
        "label": "Fiber Optics-Equipment & Systems (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33592103",
        "yellow_page_code": "31509, 12113, 12219",
        "kiosk": false
    },
    {
        "value": "335702",
        "label": "Cable (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33592101",
        "yellow_page_code": "12109, 12139",
        "kiosk": false
    },
    {
        "value": "335703",
        "label": "Cables-Insulated (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33592102",
        "yellow_page_code": "12205",
        "kiosk": false
    },
    {
        "value": "335704",
        "label": "Cord Sets & Cords-Electric (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33592901",
        "yellow_page_code": "21701",
        "kiosk": false
    },
    {
        "value": "335705",
        "label": "Wire Harnesses-Electrical-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33592902",
        "yellow_page_code": "93919",
        "kiosk": false
    },
    {
        "value": "335706",
        "label": "Wire Rope-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33592903",
        "yellow_page_code": "94010",
        "kiosk": false
    },
    {
        "value": "335707",
        "label": "Communication & Energy Wire (Mfrs) ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33592904",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "335798",
        "label": "Drawing/Insulating-Nonferrous Wire (Mfr)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33149101",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "336398",
        "label": "Aluminum Die Castings (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33152301",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "336401",
        "label": "Nonferrous Die Castings-Ex Alum (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33152302",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "336501",
        "label": "Foundries-Aluminum Brass Bronze (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33152304",
        "yellow_page_code": "34207",
        "kiosk": false
    },
    {
        "value": "336502",
        "label": "Castings-Aluminum (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33152303",
        "yellow_page_code": "02012, 13906",
        "kiosk": false
    },
    {
        "value": "336503",
        "label": "Cooking Utensils-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33151111",
        "yellow_page_code": "21414",
        "kiosk": false
    },
    {
        "value": "336504",
        "label": "Aluminum Foundries-Except Die-Casting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33152401",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "336601",
        "label": "Bronze (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33152902",
        "yellow_page_code": "10904, 60715",
        "kiosk": false
    },
    {
        "value": "336602",
        "label": "Brass (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33152901",
        "yellow_page_code": "10304",
        "kiosk": false
    },
    {
        "value": "336603",
        "label": "Castings-Bronze (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33152903",
        "yellow_page_code": "13907",
        "kiosk": false
    },
    {
        "value": "336604",
        "label": "Castings-Centrifugal (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33152904",
        "yellow_page_code": "14007",
        "kiosk": false
    },
    {
        "value": "336605",
        "label": "Castings-Resin (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33152905",
        "yellow_page_code": "14013",
        "kiosk": false
    },
    {
        "value": "336606",
        "label": "Art Casting (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33152910",
        "yellow_page_code": "03501, 03416, 34220",
        "kiosk": false
    },
    {
        "value": "336607",
        "label": "Propellers-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33152906",
        "yellow_page_code": "68623",
        "kiosk": false
    },
    {
        "value": "336608",
        "label": "Nonferrous Metal Foundries-Except Die-Casting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33152911",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "336698",
        "label": "Copper Foundries (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33152305",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "336901",
        "label": "White Metal Castings (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33152907",
        "yellow_page_code": "93406",
        "kiosk": false
    },
    {
        "value": "336903",
        "label": "Castings (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33151112",
        "yellow_page_code": "13905, 14006, 34224",
        "kiosk": false
    },
    {
        "value": "336904",
        "label": "Foundries-Titanium (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33152908",
        "yellow_page_code": "34211",
        "kiosk": false
    },
    {
        "value": "336907",
        "label": "Foundries-Magnesium (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33152909",
        "yellow_page_code": "34218",
        "kiosk": false
    },
    {
        "value": "336908",
        "label": "Metal Molder (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351103",
        "yellow_page_code": "51425",
        "kiosk": false
    },
    {
        "value": "336998",
        "label": "Nonferrous Foundries-Ex Aluminum/Copper",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33152306",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "339801",
        "label": "Heat Treating Metal (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281101",
        "yellow_page_code": "40802, 51423, 40710",
        "kiosk": false
    },
    {
        "value": "339802",
        "label": "Shot Peening (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281103",
        "yellow_page_code": "77204",
        "kiosk": false
    },
    {
        "value": "339803",
        "label": "Lead Burning (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281102",
        "yellow_page_code": "46502",
        "kiosk": false
    },
    {
        "value": "339804",
        "label": "Cryogenic Processing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281104",
        "yellow_page_code": "22810, 22799",
        "kiosk": false
    },
    {
        "value": "339901",
        "label": "Balls-Steel Bronze Etc (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33111001",
        "yellow_page_code": "06205, 06210",
        "kiosk": false
    },
    {
        "value": "339902",
        "label": "Laminations-Motor & Transformer (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281311",
        "yellow_page_code": "45601",
        "kiosk": false
    },
    {
        "value": "339903",
        "label": "Powder Metal Parts-Industrial (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33111005",
        "yellow_page_code": "67904",
        "kiosk": false
    },
    {
        "value": "339904",
        "label": "Primary Metal Products NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33111004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "341101",
        "label": "Can-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33243101",
        "yellow_page_code": "12609",
        "kiosk": false
    },
    {
        "value": "341102",
        "label": "Pails-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33243103",
        "yellow_page_code": "58511",
        "kiosk": false
    },
    {
        "value": "341103",
        "label": "Safety Cans (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33243104",
        "yellow_page_code": "73402",
        "kiosk": false
    },
    {
        "value": "341198",
        "label": "Metal Cans (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33243102",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "341201",
        "label": "Barrels & Drums (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33243901",
        "yellow_page_code": "06700, 06718, 26615, 44785",
        "kiosk": false
    },
    {
        "value": "341202",
        "label": "Metal Shipping Barrels & Drums-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33243910",
        "yellow_page_code": "51609",
        "kiosk": false
    },
    {
        "value": "341203",
        "label": "Industrial Containers-Manufacturing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33243907",
        "yellow_page_code": "42922",
        "kiosk": false
    },
    {
        "value": "341204",
        "label": "Drums-Steel (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33243903",
        "yellow_page_code": "26609, 06701",
        "kiosk": false
    },
    {
        "value": "341298",
        "label": "Metal Shpng Barrels Drum Keg/Pail (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33243911",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "342101",
        "label": "Shears-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221503",
        "yellow_page_code": "76107",
        "kiosk": false
    },
    {
        "value": "342102",
        "label": "Cookware Cutlery & Flatware (Mfrs) ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221508",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "342198",
        "label": "Cutlery-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221501",
        "yellow_page_code": "23195",
        "kiosk": false
    },
    {
        "value": "342301",
        "label": "Knives-Machine (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221617",
        "yellow_page_code": "45102, 49118",
        "kiosk": false
    },
    {
        "value": "342302",
        "label": "Tools-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221629",
        "yellow_page_code": "87109, 11129",
        "kiosk": false
    },
    {
        "value": "342303",
        "label": "Tools-Hand-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221628",
        "yellow_page_code": "87105",
        "kiosk": false
    },
    {
        "value": "342304",
        "label": "Dry Wall Contractors Equip & Supl-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221614",
        "yellow_page_code": "26811",
        "kiosk": false
    },
    {
        "value": "342305",
        "label": "Grouting Equipment & Supplies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221613",
        "yellow_page_code": "39203",
        "kiosk": false
    },
    {
        "value": "342306",
        "label": "Cutting Blocks (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221609",
        "yellow_page_code": "23104",
        "kiosk": false
    },
    {
        "value": "342307",
        "label": "Heat Guns (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221615",
        "yellow_page_code": "40705",
        "kiosk": false
    },
    {
        "value": "342308",
        "label": "Pullers-Bearing/Gear/Wheel & Etc (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221623",
        "yellow_page_code": "69009",
        "kiosk": false
    },
    {
        "value": "342309",
        "label": "Diamond Cutters Equip & Supls (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221610",
        "yellow_page_code": "24432",
        "kiosk": false
    },
    {
        "value": "342310",
        "label": "Blow Torches (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221602",
        "yellow_page_code": "08511",
        "kiosk": false
    },
    {
        "value": "342311",
        "label": "Box Openers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221603",
        "yellow_page_code": "09802",
        "kiosk": false
    },
    {
        "value": "342312",
        "label": "Carpet Layers Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221604",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "342313",
        "label": "Tongs (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221627",
        "yellow_page_code": "86901, 56563",
        "kiosk": false
    },
    {
        "value": "342314",
        "label": "Ironworking Machinery (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221616",
        "yellow_page_code": "43819",
        "kiosk": false
    },
    {
        "value": "342315",
        "label": "Squeegees (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221625",
        "yellow_page_code": "80402",
        "kiosk": false
    },
    {
        "value": "342501",
        "label": "Saw Blades & Handsaws (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221634",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "342502",
        "label": "Sharpening Equipment & Stones (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221633",
        "yellow_page_code": "76009",
        "kiosk": false
    },
    {
        "value": "342901",
        "label": "Bellows (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33251002",
        "yellow_page_code": "07608",
        "kiosk": false
    },
    {
        "value": "342902",
        "label": "Hardware-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33251015",
        "yellow_page_code": "40211, 40116",
        "kiosk": false
    },
    {
        "value": "342903",
        "label": "Cabinet Makers Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33251004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "342904",
        "label": "Clamps-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33251007",
        "yellow_page_code": "18211",
        "kiosk": false
    },
    {
        "value": "342905",
        "label": "Door Closers & Checks-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33251008",
        "yellow_page_code": "25509",
        "kiosk": false
    },
    {
        "value": "342906",
        "label": "Drawer Slides-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33251009",
        "yellow_page_code": "26101",
        "kiosk": false
    },
    {
        "value": "342907",
        "label": "Furniture-Braces-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33251011",
        "yellow_page_code": "35602",
        "kiosk": false
    },
    {
        "value": "342908",
        "label": "Hardware-Marine-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33251016",
        "yellow_page_code": "40113",
        "kiosk": false
    },
    {
        "value": "342909",
        "label": "Hardware-Furniture-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33251014",
        "yellow_page_code": "40114",
        "kiosk": false
    },
    {
        "value": "342910",
        "label": "Table Parts-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33251023",
        "yellow_page_code": "83105",
        "kiosk": false
    },
    {
        "value": "342911",
        "label": "Fireplace Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33251010",
        "yellow_page_code": "32220",
        "kiosk": false
    },
    {
        "value": "342912",
        "label": "Picture Hangers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33251021",
        "yellow_page_code": "65009",
        "kiosk": false
    },
    {
        "value": "342913",
        "label": "Angle Irons (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33251001",
        "yellow_page_code": "02400, 02415",
        "kiosk": false
    },
    {
        "value": "342915",
        "label": "Brackets (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33251003",
        "yellow_page_code": "10106",
        "kiosk": false
    },
    {
        "value": "342916",
        "label": "Casters & Glides-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33251006",
        "yellow_page_code": "13930, 30333",
        "kiosk": false
    },
    {
        "value": "342917",
        "label": "Hose Couplings & Fittings-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33291901",
        "yellow_page_code": "41720",
        "kiosk": false
    },
    {
        "value": "342918",
        "label": "Caskets-Hardware (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33251005",
        "yellow_page_code": "13940",
        "kiosk": false
    },
    {
        "value": "342919",
        "label": "Hinges-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33251017",
        "yellow_page_code": "41017",
        "kiosk": false
    },
    {
        "value": "342920",
        "label": "Vacuum Bottles & Jugs (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33243921",
        "yellow_page_code": "90301",
        "kiosk": false
    },
    {
        "value": "342921",
        "label": "Turnbuckles (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33251024",
        "yellow_page_code": "89507",
        "kiosk": false
    },
    {
        "value": "342922",
        "label": "Sheaves (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33251022",
        "yellow_page_code": "76200",
        "kiosk": false
    },
    {
        "value": "342923",
        "label": "Locks-Mfrs-Except Safe Vault Coin",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33251019",
        "yellow_page_code": "48495",
        "kiosk": false
    },
    {
        "value": "342924",
        "label": "Keys-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33251018",
        "yellow_page_code": "44810",
        "kiosk": false
    },
    {
        "value": "342998",
        "label": "Hardware NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33251012",
        "yellow_page_code": "40125",
        "kiosk": false
    },
    {
        "value": "343101",
        "label": "Hygiene & Sanitary Appliance Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299922",
        "yellow_page_code": "42220",
        "kiosk": false
    },
    {
        "value": "343102",
        "label": "Toilets-Incinerating (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299924",
        "yellow_page_code": "86803",
        "kiosk": false
    },
    {
        "value": "343103",
        "label": "Toilets (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299923",
        "yellow_page_code": "86805",
        "kiosk": false
    },
    {
        "value": "343201",
        "label": "Plumbing Fixtures & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33291302",
        "yellow_page_code": "66803",
        "kiosk": false
    },
    {
        "value": "343202",
        "label": "Manufacturers-Watering Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299909",
        "yellow_page_code": "49828",
        "kiosk": false
    },
    {
        "value": "343203",
        "label": "Sprinklers-Garden & Lawn-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299914",
        "yellow_page_code": "80216",
        "kiosk": false
    },
    {
        "value": "343298",
        "label": "Plumbing Fixture Fittings & Trim (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33291301",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "343301",
        "label": "Boilers-New & Used-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341401",
        "yellow_page_code": "09103",
        "kiosk": false
    },
    {
        "value": "343302",
        "label": "Geothermal Htg/Cooling Equip/Systs-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341404",
        "yellow_page_code": "37218",
        "kiosk": false
    },
    {
        "value": "343303",
        "label": "Heating Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341406",
        "yellow_page_code": "40836, 40829",
        "kiosk": false
    },
    {
        "value": "343304",
        "label": "Oil Burners-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341407",
        "yellow_page_code": "56513",
        "kiosk": false
    },
    {
        "value": "343305",
        "label": "Solar Energy Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341409",
        "yellow_page_code": "79201",
        "kiosk": false
    },
    {
        "value": "343306",
        "label": "Solariums-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341410",
        "yellow_page_code": "79311",
        "kiosk": false
    },
    {
        "value": "343307",
        "label": "Heaters-Unit-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341405",
        "yellow_page_code": "40812",
        "kiosk": false
    },
    {
        "value": "343308",
        "label": "Stoves-Wood Coal Etc-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341411",
        "yellow_page_code": "81834",
        "kiosk": false
    },
    {
        "value": "343309",
        "label": "Burners-Industrial (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341402",
        "yellow_page_code": "11512, 11610",
        "kiosk": false
    },
    {
        "value": "343310",
        "label": "Gas Burners-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341403",
        "yellow_page_code": "36512",
        "kiosk": false
    },
    {
        "value": "343311",
        "label": "Wood Burners-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341412",
        "yellow_page_code": "94398",
        "kiosk": false
    },
    {
        "value": "343312",
        "label": "Heaters-Propane (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341413",
        "yellow_page_code": "40821",
        "kiosk": false
    },
    {
        "value": "343313",
        "label": "Heavy Gauge Metal Tank (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33242011",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "344101",
        "label": "Anchors-Expansion (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33231201",
        "yellow_page_code": "02308, 02316",
        "kiosk": false
    },
    {
        "value": "344102",
        "label": "Towers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33231211",
        "yellow_page_code": "87500, 87439, 87435, 87414",
        "kiosk": false
    },
    {
        "value": "344103",
        "label": "Expansion Joints (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33231205",
        "yellow_page_code": "30108, 30433",
        "kiosk": false
    },
    {
        "value": "344104",
        "label": "Steel Joist (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33231208",
        "yellow_page_code": "81005, 44512",
        "kiosk": false
    },
    {
        "value": "344105",
        "label": "Bridge-Metal-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33231202",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "344106",
        "label": "Steel-Structural (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33231210",
        "yellow_page_code": "81004, 81007, 80910, 81017, 81111, 80919, 80920, 80922",
        "kiosk": false
    },
    {
        "value": "344107",
        "label": "Expansion Bolts & Shields (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33231204",
        "yellow_page_code": "30107",
        "kiosk": false
    },
    {
        "value": "344108",
        "label": "Steel Reinforcing Supplies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33231209",
        "yellow_page_code": "81016, 81019, 81021",
        "kiosk": false
    },
    {
        "value": "344109",
        "label": "Welding Assemblies & Stamping (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33231212",
        "yellow_page_code": "93110",
        "kiosk": false
    },
    {
        "value": "344198",
        "label": "Fabricated Structural Metal (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33231206",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "344201",
        "label": "Doors-Fireproof (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33232103",
        "yellow_page_code": "25603, 31904, 31910",
        "kiosk": false
    },
    {
        "value": "344202",
        "label": "Windows-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33232115",
        "yellow_page_code": "93803",
        "kiosk": false
    },
    {
        "value": "344203",
        "label": "Doors-Metal-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33232104",
        "yellow_page_code": "25612, 25622",
        "kiosk": false
    },
    {
        "value": "344204",
        "label": "Jalousies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33232105",
        "yellow_page_code": "44106, 44109",
        "kiosk": false
    },
    {
        "value": "344205",
        "label": "Louvers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33232106",
        "yellow_page_code": "48635",
        "kiosk": false
    },
    {
        "value": "344206",
        "label": "Screens-Door & Window-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33232109",
        "yellow_page_code": "74905",
        "kiosk": false
    },
    {
        "value": "344207",
        "label": "Shutters-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33232111",
        "yellow_page_code": "77416",
        "kiosk": false
    },
    {
        "value": "344208",
        "label": "Store Fronts-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33232112",
        "yellow_page_code": "81835",
        "kiosk": false
    },
    {
        "value": "344209",
        "label": "Storm Windows & Doors-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33232113",
        "yellow_page_code": "81814",
        "kiosk": false
    },
    {
        "value": "344210",
        "label": "Screens-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33232110",
        "yellow_page_code": "74815",
        "kiosk": false
    },
    {
        "value": "344211",
        "label": "Metal-Trim (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33232108",
        "yellow_page_code": "51711",
        "kiosk": false
    },
    {
        "value": "344212",
        "label": "Weather Strips-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33232114",
        "yellow_page_code": "92712",
        "kiosk": false
    },
    {
        "value": "344213",
        "label": "Doors-Cold Storage (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33232102",
        "yellow_page_code": "25617",
        "kiosk": false
    },
    {
        "value": "344214",
        "label": "Door & Window Components Manufacturing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33232116",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "344298",
        "label": "Metal Doors Sash Frames & Trim (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33232107",
        "yellow_page_code": "73913, 73890",
        "kiosk": false
    },
    {
        "value": "344301",
        "label": "Accumulators (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33242009",
        "yellow_page_code": "00104",
        "kiosk": false
    },
    {
        "value": "344302",
        "label": "Smoke Stacks (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33231306",
        "yellow_page_code": "78600",
        "kiosk": false
    },
    {
        "value": "344303",
        "label": "Cylinders-Gas (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33242003",
        "yellow_page_code": "23200",
        "kiosk": false
    },
    {
        "value": "344304",
        "label": "Fabricated Plate Work-Boiler Shop (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33231303",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "344305",
        "label": "Tanks-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33242006",
        "yellow_page_code": "83501, 69201, 83608, 83510, 49850",
        "kiosk": false
    },
    {
        "value": "344306",
        "label": "Kettles (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33231304",
        "yellow_page_code": "44803",
        "kiosk": false
    },
    {
        "value": "344307",
        "label": "Chutes-Metal Plate-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341506",
        "yellow_page_code": "15803",
        "kiosk": false
    },
    {
        "value": "344309",
        "label": "Culvert-Manufacturers-Corrugated & Iron",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33231302",
        "yellow_page_code": "22900",
        "kiosk": false
    },
    {
        "value": "344310",
        "label": "Cylinders-Pressure-Metal Plate-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341508",
        "yellow_page_code": "23201",
        "kiosk": false
    },
    {
        "value": "344311",
        "label": "Heat Exchangers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33241001",
        "yellow_page_code": "40611",
        "kiosk": false
    },
    {
        "value": "344312",
        "label": "Nuclear Reactors-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33241003",
        "yellow_page_code": "55711",
        "kiosk": false
    },
    {
        "value": "344313",
        "label": "Metal Plating (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33231307",
        "yellow_page_code": "51519",
        "kiosk": false
    },
    {
        "value": "344314",
        "label": "Heat Transfer Materials (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33241002",
        "yellow_page_code": "40706",
        "kiosk": false
    },
    {
        "value": "344315",
        "label": "Vessels-Pressure (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33242007",
        "yellow_page_code": "90901, 68119",
        "kiosk": false
    },
    {
        "value": "344316",
        "label": "Pressurization Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33242004",
        "yellow_page_code": "68106",
        "kiosk": false
    },
    {
        "value": "344317",
        "label": "Cryogenic Equipment & Supplies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33242010",
        "yellow_page_code": "22803",
        "kiosk": false
    },
    {
        "value": "344318",
        "label": "Caissons (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33242001",
        "yellow_page_code": "12301",
        "kiosk": false
    },
    {
        "value": "344319",
        "label": "Condensers-Tubes & Tubing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33242002",
        "yellow_page_code": "20805",
        "kiosk": false
    },
    {
        "value": "344320",
        "label": "Melting Pots (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33231305",
        "yellow_page_code": "51117",
        "kiosk": false
    },
    {
        "value": "344321",
        "label": "Manufacturing Containers-Steel",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33242008",
        "yellow_page_code": "49947",
        "kiosk": false
    },
    {
        "value": "344399",
        "label": "Tank Heads (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33242005",
        "yellow_page_code": "83507, 72207",
        "kiosk": false
    },
    {
        "value": "344401",
        "label": "Mail Chutes (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33243909",
        "yellow_page_code": "49608",
        "kiosk": false
    },
    {
        "value": "344402",
        "label": "Guards-Machine (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33232203",
        "yellow_page_code": "39304, 49015",
        "kiosk": false
    },
    {
        "value": "344403",
        "label": "Sheet Metal Fabricators (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33232204",
        "yellow_page_code": "76203, 76205, 76216, 76134, 76222",
        "kiosk": false
    },
    {
        "value": "344404",
        "label": "Duct & Duct Fittings (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33232202",
        "yellow_page_code": "26801",
        "kiosk": false
    },
    {
        "value": "344405",
        "label": "Hoppers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33243906",
        "yellow_page_code": "41412",
        "kiosk": false
    },
    {
        "value": "344406",
        "label": "Metals-Clad (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33243912",
        "yellow_page_code": "51904, 18226",
        "kiosk": false
    },
    {
        "value": "344408",
        "label": "Guardrail-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33243904",
        "yellow_page_code": "39205",
        "kiosk": false
    },
    {
        "value": "344409",
        "label": "Gutters & Downspouts-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33243905",
        "yellow_page_code": "39411",
        "kiosk": false
    },
    {
        "value": "344410",
        "label": "Machine Guards-Sheet Metal-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33243908",
        "yellow_page_code": "49036",
        "kiosk": false
    },
    {
        "value": "344411",
        "label": "Siding Materials-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33243917",
        "yellow_page_code": "77415",
        "kiosk": false
    },
    {
        "value": "344412",
        "label": "Skylights-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33243918",
        "yellow_page_code": "78213",
        "kiosk": false
    },
    {
        "value": "344413",
        "label": "Postal Boxes-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33243913",
        "yellow_page_code": "67518",
        "kiosk": false
    },
    {
        "value": "344414",
        "label": "Range Hoods & Canopies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33243914",
        "yellow_page_code": "70404, 41420",
        "kiosk": false
    },
    {
        "value": "344415",
        "label": "Roof Curbs-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33243915",
        "yellow_page_code": "72615",
        "kiosk": false
    },
    {
        "value": "344416",
        "label": "Spouts-Pouring (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33243919",
        "yellow_page_code": "80109",
        "kiosk": false
    },
    {
        "value": "344417",
        "label": "Stove-Pipe (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33243920",
        "yellow_page_code": "81903",
        "kiosk": false
    },
    {
        "value": "344418",
        "label": "Sheet Metal Working Equip/Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33232205",
        "yellow_page_code": "76135",
        "kiosk": false
    },
    {
        "value": "344601",
        "label": "Brass Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33232304",
        "yellow_page_code": "10306, 10308",
        "kiosk": false
    },
    {
        "value": "344602",
        "label": "Fire Escapes (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33232307",
        "yellow_page_code": "32000, 32002",
        "kiosk": false
    },
    {
        "value": "344603",
        "label": "Gratings (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33232309",
        "yellow_page_code": "38813, 38808",
        "kiosk": false
    },
    {
        "value": "344604",
        "label": "Ornamental Metal Work (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33232312",
        "yellow_page_code": "43900, 43806, 58004, 43816, 57910, 94580, 57911, 94581, 43827, 51428",
        "kiosk": false
    },
    {
        "value": "344605",
        "label": "Stair Cushions (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33232315",
        "yellow_page_code": "80490",
        "kiosk": false
    },
    {
        "value": "344606",
        "label": "Balconies-Metal-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33232303",
        "yellow_page_code": "06103",
        "kiosk": false
    },
    {
        "value": "344607",
        "label": "Flag Poles-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33232308",
        "yellow_page_code": "32813",
        "kiosk": false
    },
    {
        "value": "344608",
        "label": "Railings-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33232314",
        "yellow_page_code": "70109, 06190",
        "kiosk": false
    },
    {
        "value": "344609",
        "label": "Guards-Door & Window-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33232311",
        "yellow_page_code": "39302",
        "kiosk": false
    },
    {
        "value": "344610",
        "label": "Bronze Ornamental Work (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33232305",
        "yellow_page_code": "10906, 10907",
        "kiosk": false
    },
    {
        "value": "344611",
        "label": "Cattle Guards (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33232306",
        "yellow_page_code": "14106",
        "kiosk": false
    },
    {
        "value": "344612",
        "label": "Grilles (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33232310",
        "yellow_page_code": "39007",
        "kiosk": false
    },
    {
        "value": "344613",
        "label": "Aluminum Ornamental Work (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33232301",
        "yellow_page_code": "02101",
        "kiosk": false
    },
    {
        "value": "344614",
        "label": "Partitions-Metal (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33232313",
        "yellow_page_code": "59509",
        "kiosk": false
    },
    {
        "value": "344615",
        "label": "Stairs-Iron & Steel (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33232316",
        "yellow_page_code": "80504",
        "kiosk": false
    },
    {
        "value": "344698",
        "label": "Architectural/Ornamental Mtl Work (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33232302",
        "yellow_page_code": "03215, 51901",
        "kiosk": false
    },
    {
        "value": "344801",
        "label": "Buildings-Metal-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33231101",
        "yellow_page_code": "11312",
        "kiosk": false
    },
    {
        "value": "344802",
        "label": "Carports-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33231102",
        "yellow_page_code": "13833",
        "kiosk": false
    },
    {
        "value": "344803",
        "label": "Greenhouses-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33231105",
        "yellow_page_code": "38908",
        "kiosk": false
    },
    {
        "value": "344804",
        "label": "Mobile Homes-Equipment & Parts-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33231106",
        "yellow_page_code": "53012, 53115",
        "kiosk": false
    },
    {
        "value": "344805",
        "label": "Garage Temporary Shelters (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33231104",
        "yellow_page_code": "36113",
        "kiosk": false
    },
    {
        "value": "344806",
        "label": "Docks-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33231103",
        "yellow_page_code": "25235",
        "kiosk": false
    },
    {
        "value": "344807",
        "label": "Fallout Shelter Equip & Supplies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33231108",
        "yellow_page_code": "30618, 82537",
        "kiosk": false
    },
    {
        "value": "344808",
        "label": "Showers-Portable (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33231109",
        "yellow_page_code": "77310",
        "kiosk": false
    },
    {
        "value": "344898",
        "label": "Prefabricated Metal Buildings (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33231107",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "344901",
        "label": "Metal Lath (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33231207",
        "yellow_page_code": "51411",
        "kiosk": false
    },
    {
        "value": "344902",
        "label": "Roll Forming (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33211401",
        "yellow_page_code": "72507",
        "kiosk": false
    },
    {
        "value": "344903",
        "label": "Curtain Walls-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33232101",
        "yellow_page_code": "22901",
        "kiosk": false
    },
    {
        "value": "344904",
        "label": "Concrete Reinforcements-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33231203",
        "yellow_page_code": "20616",
        "kiosk": false
    },
    {
        "value": "344998",
        "label": "Structural Metal Work-Misc (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33232317",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "345101",
        "label": "Screw Machine Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33272101",
        "yellow_page_code": "75000",
        "kiosk": false
    },
    {
        "value": "345201",
        "label": "Thread-Rolling/Cutting/Grinding (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33272206",
        "yellow_page_code": "85805",
        "kiosk": false
    },
    {
        "value": "345202",
        "label": "Bolts & Nuts-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33272201",
        "yellow_page_code": "09113",
        "kiosk": false
    },
    {
        "value": "345203",
        "label": "Rivet-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33272204",
        "yellow_page_code": "72336",
        "kiosk": false
    },
    {
        "value": "345204",
        "label": "Screws-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33272205",
        "yellow_page_code": "75003",
        "kiosk": false
    },
    {
        "value": "345205",
        "label": "Pins-Cotter Taper Etc (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33272203",
        "yellow_page_code": "65400",
        "kiosk": false
    },
    {
        "value": "345206",
        "label": "Inserts-Screw Thread (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33272207",
        "yellow_page_code": "43208",
        "kiosk": false
    },
    {
        "value": "345298",
        "label": "Bolts Nuts Screws Rivets/Washers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33272202",
        "yellow_page_code": "56010",
        "kiosk": false
    },
    {
        "value": "346201",
        "label": "Forgings (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33211107",
        "yellow_page_code": "34106, 43826",
        "kiosk": false
    },
    {
        "value": "346202",
        "label": "Crankshaft-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33211104",
        "yellow_page_code": "22401",
        "kiosk": false
    },
    {
        "value": "346203",
        "label": "Flange Protectors (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33211105",
        "yellow_page_code": "32807",
        "kiosk": false
    },
    {
        "value": "346204",
        "label": "Anvils (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33211101",
        "yellow_page_code": "02807",
        "kiosk": false
    },
    {
        "value": "346205",
        "label": "Forging-Die Sinkers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33211106",
        "yellow_page_code": "34107",
        "kiosk": false
    },
    {
        "value": "346206",
        "label": "Coined Parts-Metal (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33211103",
        "yellow_page_code": "19512",
        "kiosk": false
    },
    {
        "value": "346207",
        "label": "Chains-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33211102",
        "yellow_page_code": "14515",
        "kiosk": false
    },
    {
        "value": "346398",
        "label": "Nonferrous Forgings (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33211201",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "346501",
        "label": "Fender-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33637002",
        "yellow_page_code": "31314",
        "kiosk": false
    },
    {
        "value": "346598",
        "label": "Automotive Stampings (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33637001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "346601",
        "label": "Bottle Caps & Seals (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33211901",
        "yellow_page_code": "09502, 09504",
        "kiosk": false
    },
    {
        "value": "346698",
        "label": "Crowns & Closures (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33211902",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "346901",
        "label": "Perforated Metals Plastics Etc (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33211915",
        "yellow_page_code": "60206, 51912, 60214, 12131",
        "kiosk": false
    },
    {
        "value": "346902",
        "label": "Metal Spinning (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33211911",
        "yellow_page_code": "51704",
        "kiosk": false
    },
    {
        "value": "346903",
        "label": "Steel & Brass Stamps (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33211918",
        "yellow_page_code": "80906, 80517",
        "kiosk": false
    },
    {
        "value": "346904",
        "label": "Metal Stamping (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33211912",
        "yellow_page_code": "51708",
        "kiosk": false
    },
    {
        "value": "346905",
        "label": "Mail Boxes-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33211909",
        "yellow_page_code": "49603",
        "kiosk": false
    },
    {
        "value": "346906",
        "label": "Coins & Tokens-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33211903",
        "yellow_page_code": "19602",
        "kiosk": false
    },
    {
        "value": "346907",
        "label": "Foil Stamping (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33211905",
        "yellow_page_code": "33701",
        "kiosk": false
    },
    {
        "value": "346908",
        "label": "Garbage Collection & Disposal Equip-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33211906",
        "yellow_page_code": "36112",
        "kiosk": false
    },
    {
        "value": "346909",
        "label": "Machinery Parts & Supplies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33211908",
        "yellow_page_code": "49201, 49222, 49301",
        "kiosk": false
    },
    {
        "value": "346910",
        "label": "Rubbish Containers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33211916",
        "yellow_page_code": "73107",
        "kiosk": false
    },
    {
        "value": "346911",
        "label": "Refrigerators/Freezers-Supls/Parts-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221507",
        "yellow_page_code": "71501",
        "kiosk": false
    },
    {
        "value": "346912",
        "label": "Housewares-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221505",
        "yellow_page_code": "41930",
        "kiosk": false
    },
    {
        "value": "346913",
        "label": "Kitchen Tools-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221506",
        "yellow_page_code": "44917",
        "kiosk": false
    },
    {
        "value": "346914",
        "label": "License Plate Frames-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33211907",
        "yellow_page_code": "47117",
        "kiosk": false
    },
    {
        "value": "346915",
        "label": "Cold Heading (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33243922",
        "yellow_page_code": "19536",
        "kiosk": false
    },
    {
        "value": "346916",
        "label": "Bottle Openers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221504",
        "yellow_page_code": "09505",
        "kiosk": false
    },
    {
        "value": "346917",
        "label": "Metal-Perforating & Punching (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33211914",
        "yellow_page_code": "51601, 60223",
        "kiosk": false
    },
    {
        "value": "346918",
        "label": "Electronic Enclosures-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33211904",
        "yellow_page_code": "28125",
        "kiosk": false
    },
    {
        "value": "346919",
        "label": "Waste Containers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33211919",
        "yellow_page_code": "91804",
        "kiosk": false
    },
    {
        "value": "346920",
        "label": "Metal Findings (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33211910",
        "yellow_page_code": "51414",
        "kiosk": false
    },
    {
        "value": "346921",
        "label": "Stamps (Tool & Die) (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33211917",
        "yellow_page_code": "80515, 67946",
        "kiosk": false
    },
    {
        "value": "346998",
        "label": "Metal Stampings NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33211913",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "347101",
        "label": "Anodizing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281301",
        "yellow_page_code": "02704",
        "kiosk": false
    },
    {
        "value": "347102",
        "label": "Metal Finishers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281312",
        "yellow_page_code": "51502",
        "kiosk": false
    },
    {
        "value": "347103",
        "label": "Buffing & Polishing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281302",
        "yellow_page_code": "11008, 67111, 28198",
        "kiosk": false
    },
    {
        "value": "347104",
        "label": "Metal Polishing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281314",
        "yellow_page_code": "51508",
        "kiosk": false
    },
    {
        "value": "347105",
        "label": "Plating (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281317",
        "yellow_page_code": "66506, 66510, 66591",
        "kiosk": false
    },
    {
        "value": "347106",
        "label": "Chrome (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281303",
        "yellow_page_code": "66507, 15703, 15699, 15698",
        "kiosk": false
    },
    {
        "value": "347107",
        "label": "Impregnating (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281307",
        "yellow_page_code": "42705, 42710",
        "kiosk": false
    },
    {
        "value": "347108",
        "label": "Plating Consultants (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281318",
        "yellow_page_code": "66509",
        "kiosk": false
    },
    {
        "value": "347109",
        "label": "Metal Finishers-Blasting (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281313",
        "yellow_page_code": "51512",
        "kiosk": false
    },
    {
        "value": "347110",
        "label": "Tumbling Equip & Supplies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281315",
        "yellow_page_code": "89401",
        "kiosk": false
    },
    {
        "value": "347111",
        "label": "Plating-Gold & Silver (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281319",
        "yellow_page_code": "66600",
        "kiosk": false
    },
    {
        "value": "347112",
        "label": "Electroforming (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281304",
        "yellow_page_code": "28109",
        "kiosk": false
    },
    {
        "value": "347113",
        "label": "Kick Plates (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281310",
        "yellow_page_code": "44900",
        "kiosk": false
    },
    {
        "value": "347114",
        "label": "Hot Stamping Equipment & Supls (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281306",
        "yellow_page_code": "41817",
        "kiosk": false
    },
    {
        "value": "347115",
        "label": "Impregnating Apparatus (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281308",
        "yellow_page_code": "42703",
        "kiosk": false
    },
    {
        "value": "347116",
        "label": "Impregnating Castings (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281309",
        "yellow_page_code": "42704",
        "kiosk": false
    },
    {
        "value": "347117",
        "label": "Plastics-Coating (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281316",
        "yellow_page_code": "66301, 66226",
        "kiosk": false
    },
    {
        "value": "347118",
        "label": "Vacuum Plating (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281320",
        "yellow_page_code": "90308",
        "kiosk": false
    },
    {
        "value": "347119",
        "label": "Plating & Polishing Service (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281321",
        "yellow_page_code": "66513",
        "kiosk": false
    },
    {
        "value": "347120",
        "label": "Tumbling Service (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281322",
        "yellow_page_code": "89336",
        "kiosk": false
    },
    {
        "value": "347121",
        "label": "Metal Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281324",
        "yellow_page_code": "51407, 18920",
        "kiosk": false
    },
    {
        "value": "347198",
        "label": "Electroplating/Polishing/Anodizing (Mfr)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281305",
        "yellow_page_code": "28130",
        "kiosk": false
    },
    {
        "value": "347901",
        "label": "Pipe Lining & Coating (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281218",
        "yellow_page_code": "65705",
        "kiosk": false
    },
    {
        "value": "347902",
        "label": "Galvanizing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33991010",
        "yellow_page_code": "36002",
        "kiosk": false
    },
    {
        "value": "347903",
        "label": "Engravers-Mechanical (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281210",
        "yellow_page_code": "29406",
        "kiosk": false
    },
    {
        "value": "347904",
        "label": "Coatings-Protective-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281203",
        "yellow_page_code": "19102, 67965, 19111, 19112, 42920",
        "kiosk": false
    },
    {
        "value": "347905",
        "label": "Coatings-Protective-Applicators (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281204",
        "yellow_page_code": "19104",
        "kiosk": false
    },
    {
        "value": "347906",
        "label": "Enameling Japanning & Lacquering (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281206",
        "yellow_page_code": "28604, 28603, 67315, 28590",
        "kiosk": false
    },
    {
        "value": "347907",
        "label": "Engravers-Metal (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281211",
        "yellow_page_code": "29408, 51421",
        "kiosk": false
    },
    {
        "value": "347908",
        "label": "Jewelry Plating (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33991013",
        "yellow_page_code": "44406",
        "kiosk": false
    },
    {
        "value": "347909",
        "label": "Jewelry Enamelers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33991012",
        "yellow_page_code": "44309",
        "kiosk": false
    },
    {
        "value": "347910",
        "label": "Grinding-Precision & Production (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281207",
        "yellow_page_code": "39104, 68034",
        "kiosk": false
    },
    {
        "value": "347911",
        "label": "Honing & Lapping Service (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351718",
        "yellow_page_code": "41404",
        "kiosk": false
    },
    {
        "value": "347912",
        "label": "Enamels-Baked (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281223",
        "yellow_page_code": "28613",
        "kiosk": false
    },
    {
        "value": "347913",
        "label": "Name Plates-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281216",
        "yellow_page_code": "55036",
        "kiosk": false
    },
    {
        "value": "347914",
        "label": "Coatings-Vacuum Deposition (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281205",
        "yellow_page_code": "19106",
        "kiosk": false
    },
    {
        "value": "347915",
        "label": "Metals-Pre-Coated (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281215",
        "yellow_page_code": "51906",
        "kiosk": false
    },
    {
        "value": "347916",
        "label": "Powder Coatings (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281219",
        "yellow_page_code": "67836, 19103, 58621, 49849, 67837",
        "kiosk": false
    },
    {
        "value": "347917",
        "label": "Powder Processing-Industrial (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281220",
        "yellow_page_code": "67905",
        "kiosk": false
    },
    {
        "value": "347918",
        "label": "Fasteners-Industrial-Painting (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281214",
        "yellow_page_code": "31002",
        "kiosk": false
    },
    {
        "value": "347919",
        "label": "Pipe Coating Materials (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281217",
        "yellow_page_code": "65601, 65512",
        "kiosk": false
    },
    {
        "value": "347920",
        "label": "Etched Products-Metal Glass & Etc-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281213",
        "yellow_page_code": "29914",
        "kiosk": false
    },
    {
        "value": "347921",
        "label": "Engravers-Die (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281209",
        "yellow_page_code": "29411",
        "kiosk": false
    },
    {
        "value": "347922",
        "label": "Engravers-Cylinder (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281208",
        "yellow_page_code": "29412",
        "kiosk": false
    },
    {
        "value": "347923",
        "label": "Card Cases (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281201",
        "yellow_page_code": "13502",
        "kiosk": false
    },
    {
        "value": "347924",
        "label": "Trophy Engravers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281222",
        "yellow_page_code": "88605",
        "kiosk": false
    },
    {
        "value": "347925",
        "label": "Teflon (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281221",
        "yellow_page_code": "84219, 66240",
        "kiosk": false
    },
    {
        "value": "347926",
        "label": "Engravers-Security-Personal-Coml (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281212",
        "yellow_page_code": "29417",
        "kiosk": false
    },
    {
        "value": "347927",
        "label": "Automobile Graphics (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281224",
        "yellow_page_code": "04831",
        "kiosk": false
    },
    {
        "value": "347998",
        "label": "Coating Engraving/Allied Svcs NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33281202",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "348201",
        "label": "Small Arms Ammunition (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299201",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "348301",
        "label": "Fuses-Blasting-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299302",
        "yellow_page_code": "35911",
        "kiosk": false
    },
    {
        "value": "348398",
        "label": "Ammunition-Except For Small Arms (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299301",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "348401",
        "label": "Firearm Accessories-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299402",
        "yellow_page_code": "32211",
        "kiosk": false
    },
    {
        "value": "348402",
        "label": "Firearms-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299403",
        "yellow_page_code": "32213",
        "kiosk": false
    },
    {
        "value": "348403",
        "label": "Small Arms-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299404",
        "yellow_page_code": "78412",
        "kiosk": false
    },
    {
        "value": "348404",
        "label": "Ammunition Reloading Equip & Supls-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299401",
        "yellow_page_code": "02201",
        "kiosk": false
    },
    {
        "value": "348405",
        "label": "Guns Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299405",
        "yellow_page_code": "39413",
        "kiosk": false
    },
    {
        "value": "348406",
        "label": "Guns Accessories Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299406",
        "yellow_page_code": "39346",
        "kiosk": false
    },
    {
        "value": "348998",
        "label": "Ordnance & Accessories NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299407",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "349101",
        "label": "Fire Hydrants (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33291101",
        "yellow_page_code": "32102",
        "kiosk": false
    },
    {
        "value": "349198",
        "label": "Industrial Valves (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33291102",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "349201",
        "label": "Power Wash Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33291203",
        "yellow_page_code": "67914",
        "kiosk": false
    },
    {
        "value": "349202",
        "label": "Hose Assemblies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33291202",
        "yellow_page_code": "41611",
        "kiosk": false
    },
    {
        "value": "349298",
        "label": "Fluid Power Valves/Hose Fittings (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33291201",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "349301",
        "label": "Springs-Auto-Sales & Svc-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33261301",
        "yellow_page_code": "80220",
        "kiosk": false
    },
    {
        "value": "349302",
        "label": "Steel Strapping (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33261303",
        "yellow_page_code": "81101",
        "kiosk": false
    },
    {
        "value": "349398",
        "label": "Steel Spring-Except Wire (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33261302",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "349401",
        "label": "Pipe Hangers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299912",
        "yellow_page_code": "65600",
        "kiosk": false
    },
    {
        "value": "349402",
        "label": "Pipe Fittings-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33291902",
        "yellow_page_code": "65509",
        "kiosk": false
    },
    {
        "value": "349403",
        "label": "Valves-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33291907",
        "yellow_page_code": "90511",
        "kiosk": false
    },
    {
        "value": "349404",
        "label": "Pipe-Thread Protectors (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33291903",
        "yellow_page_code": "65507",
        "kiosk": false
    },
    {
        "value": "349498",
        "label": "Valves & Pipe Fittings NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33291906",
        "yellow_page_code": "33614, 33618",
        "kiosk": false
    },
    {
        "value": "349501",
        "label": "Springs-Precision (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33261305",
        "yellow_page_code": "80206",
        "kiosk": false
    },
    {
        "value": "349502",
        "label": "Springs-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33261304",
        "yellow_page_code": "80207",
        "kiosk": false
    },
    {
        "value": "349503",
        "label": "Filament Winding (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451931",
        "yellow_page_code": "31514",
        "kiosk": false
    },
    {
        "value": "349601",
        "label": "Wire Cloth (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33261821",
        "yellow_page_code": "93906",
        "kiosk": false
    },
    {
        "value": "349602",
        "label": "Shopping Carts/Baskets & Bags-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33261815",
        "yellow_page_code": "77102",
        "kiosk": false
    },
    {
        "value": "349603",
        "label": "Slings (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33261816",
        "yellow_page_code": "78400",
        "kiosk": false
    },
    {
        "value": "349604",
        "label": "Chain Link Fences-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33261803",
        "yellow_page_code": "14512",
        "kiosk": false
    },
    {
        "value": "349605",
        "label": "Fence-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33261805",
        "yellow_page_code": "31302",
        "kiosk": false
    },
    {
        "value": "349606",
        "label": "Manufacturers-Lath",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33261810",
        "yellow_page_code": "49917",
        "kiosk": false
    },
    {
        "value": "349607",
        "label": "Television-Cable-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33261817",
        "yellow_page_code": "84609",
        "kiosk": false
    },
    {
        "value": "349608",
        "label": "Trapping Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33261819",
        "yellow_page_code": "88336",
        "kiosk": false
    },
    {
        "value": "349610",
        "label": "Bird Feeders & Houses-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33261801",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "349611",
        "label": "Hangers-Garment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33261806",
        "yellow_page_code": "40137",
        "kiosk": false
    },
    {
        "value": "349612",
        "label": "Insect Control Devices-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33261808",
        "yellow_page_code": "43214",
        "kiosk": false
    },
    {
        "value": "349613",
        "label": "Ties-Wire & Plastic (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33261818",
        "yellow_page_code": "85936",
        "kiosk": false
    },
    {
        "value": "349614",
        "label": "Partitions-Wire (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33261813",
        "yellow_page_code": "59510",
        "kiosk": false
    },
    {
        "value": "349615",
        "label": "Cages-Animal (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33261802",
        "yellow_page_code": "12211",
        "kiosk": false
    },
    {
        "value": "349616",
        "label": "Metal-Powder-Parts-Fabricators (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33261811",
        "yellow_page_code": "51510",
        "kiosk": false
    },
    {
        "value": "349617",
        "label": "Wire Goods (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33261822",
        "yellow_page_code": "93901",
        "kiosk": false
    },
    {
        "value": "349618",
        "label": "Wire & Cable Markers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33261820",
        "yellow_page_code": "93915",
        "kiosk": false
    },
    {
        "value": "349619",
        "label": "Wire Spooling & Coiling (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33261823",
        "yellow_page_code": "93917",
        "kiosk": false
    },
    {
        "value": "349620",
        "label": "Gates-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33261807",
        "yellow_page_code": "36899",
        "kiosk": false
    },
    {
        "value": "349621",
        "label": "Paper Clips (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33261812",
        "yellow_page_code": "58900",
        "kiosk": false
    },
    {
        "value": "349622",
        "label": "Shackles (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33261814",
        "yellow_page_code": "76008",
        "kiosk": false
    },
    {
        "value": "349623",
        "label": "Lobster Traps & Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33261809",
        "yellow_page_code": "48419, 48421",
        "kiosk": false
    },
    {
        "value": "349624",
        "label": "Aviary Equipment Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33261824",
        "yellow_page_code": "05350",
        "kiosk": false
    },
    {
        "value": "349698",
        "label": "Fabricated Wire Products-Misc (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33261804",
        "yellow_page_code": "93926",
        "kiosk": false
    },
    {
        "value": "349701",
        "label": "Foil & Foil Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299905",
        "yellow_page_code": "33605",
        "kiosk": false
    },
    {
        "value": "349702",
        "label": "Magnesium & Magnesium Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299908",
        "yellow_page_code": "49500",
        "kiosk": false
    },
    {
        "value": "349801",
        "label": "Tube-Bending & Fabricating (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299607",
        "yellow_page_code": "89302",
        "kiosk": false
    },
    {
        "value": "349802",
        "label": "Pipe Cutting & Threading (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299603",
        "yellow_page_code": "65504",
        "kiosk": false
    },
    {
        "value": "349803",
        "label": "Pipe Bending & Fabricating (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299602",
        "yellow_page_code": "65408",
        "kiosk": false
    },
    {
        "value": "349804",
        "label": "Pipe-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299604",
        "yellow_page_code": "65711",
        "kiosk": false
    },
    {
        "value": "349805",
        "label": "Pipe-Reconditioning (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299605",
        "yellow_page_code": "65801",
        "kiosk": false
    },
    {
        "value": "349806",
        "label": "Piping Systs-Prefab-Insulated (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299606",
        "yellow_page_code": "65809",
        "kiosk": false
    },
    {
        "value": "349807",
        "label": "Tubing-Coils (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299608",
        "yellow_page_code": "89305",
        "kiosk": false
    },
    {
        "value": "349898",
        "label": "Fabricated Pipe & Pipe Fittings (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299601",
        "yellow_page_code": "65411",
        "kiosk": false
    },
    {
        "value": "349901",
        "label": "Reels (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299913",
        "yellow_page_code": "71200, 71195, 12130",
        "kiosk": false
    },
    {
        "value": "349902",
        "label": "Bronze Tablets (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299903",
        "yellow_page_code": "11000",
        "kiosk": false
    },
    {
        "value": "349903",
        "label": "Metal Goods-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299910",
        "yellow_page_code": "51505, 51513, 51422, 30419",
        "kiosk": false
    },
    {
        "value": "349904",
        "label": "Metal-Powder Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33211701",
        "yellow_page_code": "51511",
        "kiosk": false
    },
    {
        "value": "349905",
        "label": "Safes & Vaults-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33243916",
        "yellow_page_code": "73317",
        "kiosk": false
    },
    {
        "value": "349906",
        "label": "Trophies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33991032",
        "yellow_page_code": "88529",
        "kiosk": false
    },
    {
        "value": "349907",
        "label": "Spray Nozzles-Industrial (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33291905",
        "yellow_page_code": "80111",
        "kiosk": false
    },
    {
        "value": "349908",
        "label": "Shims (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33291904",
        "yellow_page_code": "76309",
        "kiosk": false
    },
    {
        "value": "349909",
        "label": "Wire Wrapping Service (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299915",
        "yellow_page_code": "94009",
        "kiosk": false
    },
    {
        "value": "349910",
        "label": "Ferrules (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299904",
        "yellow_page_code": "31404",
        "kiosk": false
    },
    {
        "value": "349911",
        "label": "Boxes-Metal-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33243902",
        "yellow_page_code": "09914",
        "kiosk": false
    },
    {
        "value": "349912",
        "label": "Bronze Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299902",
        "yellow_page_code": "10914",
        "kiosk": false
    },
    {
        "value": "349913",
        "label": "Metal Coatings (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33251020",
        "yellow_page_code": "51907",
        "kiosk": false
    },
    {
        "value": "349914",
        "label": "Ice Cream Freezers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299906",
        "yellow_page_code": "42313",
        "kiosk": false
    },
    {
        "value": "349915",
        "label": "Ironing Boards (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299907",
        "yellow_page_code": "43808",
        "kiosk": false
    },
    {
        "value": "349916",
        "label": "Lunch Boxes (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33243923",
        "yellow_page_code": "49013",
        "kiosk": false
    },
    {
        "value": "349920",
        "label": "Barricades-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299901",
        "yellow_page_code": "06710",
        "kiosk": false
    },
    {
        "value": "349921",
        "label": "Magnet Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299918",
        "yellow_page_code": "49503",
        "kiosk": false
    },
    {
        "value": "351101",
        "label": "Turbines-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33361108",
        "yellow_page_code": "89500, 93715",
        "kiosk": false
    },
    {
        "value": "351102",
        "label": "Generators-Wind Powered (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33361104",
        "yellow_page_code": "37104",
        "kiosk": false
    },
    {
        "value": "351103",
        "label": "Hydraulic Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33361105",
        "yellow_page_code": "42116, 41120, 42137",
        "kiosk": false
    },
    {
        "value": "351104",
        "label": "Engines-Steam (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33361101",
        "yellow_page_code": "29403, 80539",
        "kiosk": false
    },
    {
        "value": "351105",
        "label": "Generators-Steam (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33361103",
        "yellow_page_code": "37105",
        "kiosk": false
    },
    {
        "value": "351106",
        "label": "Gas Atmosphere Generators (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33361102",
        "yellow_page_code": "36404",
        "kiosk": false
    },
    {
        "value": "351107",
        "label": "Turbine Seal (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33361107",
        "yellow_page_code": "89437",
        "kiosk": false
    },
    {
        "value": "351108",
        "label": "Riggers' Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33361106",
        "yellow_page_code": "72211",
        "kiosk": false
    },
    {
        "value": "351109",
        "label": "Engine Manufacturers & Distributors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33361109",
        "yellow_page_code": "28723",
        "kiosk": false
    },
    {
        "value": "351901",
        "label": "Internal Combustion Engines NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33361803",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "351902",
        "label": "Mini-Bike Engines (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33361802",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "351903",
        "label": "Engines-Diesel-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33361801",
        "yellow_page_code": "29323",
        "kiosk": false
    },
    {
        "value": "351904",
        "label": "Engines-Racing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33361804",
        "yellow_page_code": "29318, 69515",
        "kiosk": false
    },
    {
        "value": "352301",
        "label": "Windmills (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33232318",
        "yellow_page_code": "93508",
        "kiosk": false
    },
    {
        "value": "352302",
        "label": "Potato Warehouse Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33311116",
        "yellow_page_code": "67600",
        "kiosk": false
    },
    {
        "value": "352303",
        "label": "Feed Mill Equipment & Supplies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33311106",
        "yellow_page_code": "31204",
        "kiosk": false
    },
    {
        "value": "352304",
        "label": "Farm Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33311103",
        "yellow_page_code": "30805, 49859, 68116",
        "kiosk": false
    },
    {
        "value": "352305",
        "label": "Balers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33392201",
        "yellow_page_code": "06106",
        "kiosk": false
    },
    {
        "value": "352306",
        "label": "Barn Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33311101",
        "yellow_page_code": "06611",
        "kiosk": false
    },
    {
        "value": "352307",
        "label": "Farm Equipment-Repairing & Parts-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33311104",
        "yellow_page_code": "30812",
        "kiosk": false
    },
    {
        "value": "352308",
        "label": "Fertilizing Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33311107",
        "yellow_page_code": "31411",
        "kiosk": false
    },
    {
        "value": "352309",
        "label": "Grain Cleaning Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33311109",
        "yellow_page_code": "38601",
        "kiosk": false
    },
    {
        "value": "352310",
        "label": "Irrigation Systems & Equipment-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33311111",
        "yellow_page_code": "43911",
        "kiosk": false
    },
    {
        "value": "352311",
        "label": "Poultry Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33311117",
        "yellow_page_code": "67709",
        "kiosk": false
    },
    {
        "value": "352312",
        "label": "Shredders-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33311118",
        "yellow_page_code": "77336",
        "kiosk": false
    },
    {
        "value": "352313",
        "label": "Potato Harvesting/Planting Equip (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33311114",
        "yellow_page_code": "67507, 67521",
        "kiosk": false
    },
    {
        "value": "352314",
        "label": "Feed Handling Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33311105",
        "yellow_page_code": "31201",
        "kiosk": false
    },
    {
        "value": "352315",
        "label": "Grain Handling Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33311108",
        "yellow_page_code": "38705, 38710",
        "kiosk": false
    },
    {
        "value": "352316",
        "label": "Potato Sprout Inhibitors (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33311115",
        "yellow_page_code": "67606",
        "kiosk": false
    },
    {
        "value": "352317",
        "label": "Hog Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33311110",
        "yellow_page_code": "41037",
        "kiosk": false
    },
    {
        "value": "352318",
        "label": "Waterers-Livestock (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33311120",
        "yellow_page_code": "92603",
        "kiosk": false
    },
    {
        "value": "352319",
        "label": "Livestock Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33311112",
        "yellow_page_code": "48110",
        "kiosk": false
    },
    {
        "value": "352320",
        "label": "Sulkies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33311119",
        "yellow_page_code": "82207",
        "kiosk": false
    },
    {
        "value": "352321",
        "label": "Nut Cracking & Shelling Machines (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33311113",
        "yellow_page_code": "56009, 56011",
        "kiosk": false
    },
    {
        "value": "352322",
        "label": "Cattle-Shed Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33311102",
        "yellow_page_code": "14120",
        "kiosk": false
    },
    {
        "value": "352323",
        "label": "Ground Applicators (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33311121",
        "yellow_page_code": "39128",
        "kiosk": false
    },
    {
        "value": "352401",
        "label": "Nurserymen Equipment & Supplies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221622",
        "yellow_page_code": "55901",
        "kiosk": false
    },
    {
        "value": "352402",
        "label": "Tree Service Equipment & Supplies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33311207",
        "yellow_page_code": "88508, 88515, 15415",
        "kiosk": false
    },
    {
        "value": "352403",
        "label": "Garden & Lawn Equipment & Supls-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33311201",
        "yellow_page_code": "36209",
        "kiosk": false
    },
    {
        "value": "352404",
        "label": "Horticultural Equipment Mfrs & Distrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221618",
        "yellow_page_code": "45833, 41620",
        "kiosk": false
    },
    {
        "value": "352405",
        "label": "Lawn Mowers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33311203",
        "yellow_page_code": "46317",
        "kiosk": false
    },
    {
        "value": "352406",
        "label": "Manufacturers-Snow Removal Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33311206",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "352407",
        "label": "Lawn Sweepers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33311204",
        "yellow_page_code": "46318",
        "kiosk": false
    },
    {
        "value": "352408",
        "label": "Trimmers-Industrial (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221631",
        "yellow_page_code": "88510",
        "kiosk": false
    },
    {
        "value": "352409",
        "label": "Lawn & Garden Outdoor Power Equip (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33311202",
        "yellow_page_code": "46305",
        "kiosk": false
    },
    {
        "value": "352410",
        "label": "Trimmers-String (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221632",
        "yellow_page_code": "88514",
        "kiosk": false
    },
    {
        "value": "352498",
        "label": "Lawn/Grdn Tractors/Home Lawn Eqpt (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33311205",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "353101",
        "label": "Machinery-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33312014",
        "yellow_page_code": "49205, 49912, 18860, 49865, 49130",
        "kiosk": false
    },
    {
        "value": "353102",
        "label": "Concrete Handling Equipment-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33312004",
        "yellow_page_code": "20611",
        "kiosk": false
    },
    {
        "value": "353103",
        "label": "Concrete-Precast-Machinery-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33312006",
        "yellow_page_code": "20801",
        "kiosk": false
    },
    {
        "value": "353104",
        "label": "Construction Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33312007",
        "yellow_page_code": "20931",
        "kiosk": false
    },
    {
        "value": "353105",
        "label": "Excavation Equipment Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33312009",
        "yellow_page_code": "30021",
        "kiosk": false
    },
    {
        "value": "353106",
        "label": "Blast Cleaning Equipment Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33312020",
        "yellow_page_code": "08424",
        "kiosk": false
    },
    {
        "value": "353107",
        "label": "Pile Driving Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33312016",
        "yellow_page_code": "65205",
        "kiosk": false
    },
    {
        "value": "353108",
        "label": "Street Cleaning Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33312024",
        "yellow_page_code": "82008",
        "kiosk": false
    },
    {
        "value": "353109",
        "label": "Cranes-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33312010",
        "yellow_page_code": "22316",
        "kiosk": false
    },
    {
        "value": "353110",
        "label": "Concrete Breaking Cutting Equipment-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33312003",
        "yellow_page_code": "20501",
        "kiosk": false
    },
    {
        "value": "353111",
        "label": "Concrete Mixers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33312005",
        "yellow_page_code": "20613",
        "kiosk": false
    },
    {
        "value": "353112",
        "label": "Logging Equipment Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33312013",
        "yellow_page_code": "48623",
        "kiosk": false
    },
    {
        "value": "353113",
        "label": "Prestressing Equip & Materials (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33312019",
        "yellow_page_code": "68111",
        "kiosk": false
    },
    {
        "value": "353114",
        "label": "Paving Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33312015",
        "yellow_page_code": "59836, 72421",
        "kiosk": false
    },
    {
        "value": "353115",
        "label": "Bottle Breaking Machines (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33312002",
        "yellow_page_code": "09512",
        "kiosk": false
    },
    {
        "value": "353116",
        "label": "Insulation Applying Machinery (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33312012",
        "yellow_page_code": "43335",
        "kiosk": false
    },
    {
        "value": "353117",
        "label": "Scrap Processing Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33312021",
        "yellow_page_code": "74737",
        "kiosk": false
    },
    {
        "value": "353118",
        "label": "Piling Contractors' Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33312017",
        "yellow_page_code": "65207",
        "kiosk": false
    },
    {
        "value": "353119",
        "label": "Pipe Pushing Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33312018",
        "yellow_page_code": "65709",
        "kiosk": false
    },
    {
        "value": "353120",
        "label": "Batching Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33312001",
        "yellow_page_code": "06901",
        "kiosk": false
    },
    {
        "value": "353121",
        "label": "Ship Building Equip & Supplies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33312022",
        "yellow_page_code": "76408",
        "kiosk": false
    },
    {
        "value": "353122",
        "label": "Shovels-Power (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33312023",
        "yellow_page_code": "77206",
        "kiosk": false
    },
    {
        "value": "353123",
        "label": "Stripers-Mechanical-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33312025",
        "yellow_page_code": "82012",
        "kiosk": false
    },
    {
        "value": "353124",
        "label": "Dredging Machinery-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33312011",
        "yellow_page_code": "26110",
        "kiosk": false
    },
    {
        "value": "353125",
        "label": "Aerial Towers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33392306",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "353126",
        "label": "Construction Elevators (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33312026",
        "yellow_page_code": "21042",
        "kiosk": false
    },
    {
        "value": "353127",
        "label": "Scaffolding (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33392308",
        "yellow_page_code": "73516",
        "kiosk": false
    },
    {
        "value": "353198",
        "label": "Construction Machinery & Equip (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33312008",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "353201",
        "label": "Manufacturing-Augers & Trenchers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33313101",
        "yellow_page_code": "49913",
        "kiosk": false
    },
    {
        "value": "353301",
        "label": "Oil Field Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33313202",
        "yellow_page_code": "56517, 56455, 56818",
        "kiosk": false
    },
    {
        "value": "353302",
        "label": "Water Well Drilling Equip & Supls-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33313203",
        "yellow_page_code": "92538",
        "kiosk": false
    },
    {
        "value": "353398",
        "label": "Oil & Gas-Field Machinery/Equip (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33313201",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "353401",
        "label": "Elevators-Sales & Service-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33392103",
        "yellow_page_code": "28309",
        "kiosk": false
    },
    {
        "value": "353402",
        "label": "Escalators (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33392105",
        "yellow_page_code": "29804",
        "kiosk": false
    },
    {
        "value": "353403",
        "label": "Dumbwaiters-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33392101",
        "yellow_page_code": "26816",
        "kiosk": false
    },
    {
        "value": "353404",
        "label": "Stairs-Disappearing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33392106",
        "yellow_page_code": "80411",
        "kiosk": false
    },
    {
        "value": "353405",
        "label": "Elevators-Supplies & Parts-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33392104",
        "yellow_page_code": "28302",
        "kiosk": false
    },
    {
        "value": "353498",
        "label": "Elevators & Moving Stairways (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33392102",
        "yellow_page_code": "28215, 47211, 28322",
        "kiosk": false
    },
    {
        "value": "353501",
        "label": "Conveyors & Conveying Equipment-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33392204",
        "yellow_page_code": "21401, 21395",
        "kiosk": false
    },
    {
        "value": "353502",
        "label": "Conveyor-Chain Protective Devices (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33392203",
        "yellow_page_code": "21324",
        "kiosk": false
    },
    {
        "value": "353503",
        "label": "Conveyor Parts & Supplies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33392202",
        "yellow_page_code": "21326",
        "kiosk": false
    },
    {
        "value": "353601",
        "label": "Cranes-Mobile (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33392302",
        "yellow_page_code": "22319",
        "kiosk": false
    },
    {
        "value": "353602",
        "label": "Boat Lifts (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33392301",
        "yellow_page_code": "08800",
        "kiosk": false
    },
    {
        "value": "353603",
        "label": "Monorail Transit Systems-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33392304",
        "yellow_page_code": "53503",
        "kiosk": false
    },
    {
        "value": "353604",
        "label": "Hoists-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33392303",
        "yellow_page_code": "41110",
        "kiosk": false
    },
    {
        "value": "353698",
        "label": "Overhead Traveling Cranes Hoists (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33392305",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "353701",
        "label": "Loading Dock Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33392403",
        "yellow_page_code": "48312",
        "kiosk": false
    },
    {
        "value": "353702",
        "label": "Machinery-Moving Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33392404",
        "yellow_page_code": "49221",
        "kiosk": false
    },
    {
        "value": "353703",
        "label": "Mechanical Handling Equipment Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33392405",
        "yellow_page_code": "50997",
        "kiosk": false
    },
    {
        "value": "353704",
        "label": "Hand Truck (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33392401",
        "yellow_page_code": "39901",
        "kiosk": false
    },
    {
        "value": "353705",
        "label": "Trucks Ind Parts & Supplies Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299917",
        "yellow_page_code": "88635",
        "kiosk": false
    },
    {
        "value": "353798",
        "label": "Industrial Trucks Tractors/Trailers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33392402",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "354101",
        "label": "Metallizing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351712",
        "yellow_page_code": "51802",
        "kiosk": false
    },
    {
        "value": "354102",
        "label": "Broaching (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351701",
        "yellow_page_code": "10806",
        "kiosk": false
    },
    {
        "value": "354103",
        "label": "Electro-Chemical Machining (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351704",
        "yellow_page_code": "28211",
        "kiosk": false
    },
    {
        "value": "354104",
        "label": "Deburring (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351702",
        "yellow_page_code": "23606",
        "kiosk": false
    },
    {
        "value": "354105",
        "label": "Tube Cleaning & Expanding Equip (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351717",
        "yellow_page_code": "89303",
        "kiosk": false
    },
    {
        "value": "354106",
        "label": "Screw Machines & Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351716",
        "yellow_page_code": "75002",
        "kiosk": false
    },
    {
        "value": "354107",
        "label": "Machine Tools-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351709",
        "yellow_page_code": "49109",
        "kiosk": false
    },
    {
        "value": "354108",
        "label": "Die Cushions-Pneumatic (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351703",
        "yellow_page_code": "24700",
        "kiosk": false
    },
    {
        "value": "354109",
        "label": "Honing Machines & Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351707",
        "yellow_page_code": "41409",
        "kiosk": false
    },
    {
        "value": "354110",
        "label": "Milling Machines (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351713",
        "yellow_page_code": "52408",
        "kiosk": false
    },
    {
        "value": "354111",
        "label": "Metal Stamping Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351711",
        "yellow_page_code": "51709",
        "kiosk": false
    },
    {
        "value": "354112",
        "label": "Filing Services (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351705",
        "yellow_page_code": "31612",
        "kiosk": false
    },
    {
        "value": "354113",
        "label": "Grinding Machines & Equip-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351706",
        "yellow_page_code": "39013",
        "kiosk": false
    },
    {
        "value": "354114",
        "label": "Roll Grinding (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351715",
        "yellow_page_code": "72512",
        "kiosk": false
    },
    {
        "value": "354115",
        "label": "Lapping (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351708",
        "yellow_page_code": "46005, 46003",
        "kiosk": false
    },
    {
        "value": "354116",
        "label": "Metal Spinning Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351710",
        "yellow_page_code": "51701",
        "kiosk": false
    },
    {
        "value": "354117",
        "label": "Numerical Control Machining (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351714",
        "yellow_page_code": "55807, 89503",
        "kiosk": false
    },
    {
        "value": "354118",
        "label": "Drilling & Boring Equip & Supls-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351721",
        "yellow_page_code": "26310",
        "kiosk": false
    },
    {
        "value": "354201",
        "label": "Presses-Power (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351729",
        "yellow_page_code": "68100, 68101",
        "kiosk": false
    },
    {
        "value": "354202",
        "label": "Punch Presses (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351730",
        "yellow_page_code": "69206",
        "kiosk": false
    },
    {
        "value": "354203",
        "label": "Brakes-Metal-Forming-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351723",
        "yellow_page_code": "10207",
        "kiosk": false
    },
    {
        "value": "354204",
        "label": "Can-Manufacturers Machinery (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351724",
        "yellow_page_code": "12700",
        "kiosk": false
    },
    {
        "value": "354205",
        "label": "Bending Machinery (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351722",
        "yellow_page_code": "07708, 51403",
        "kiosk": false
    },
    {
        "value": "354206",
        "label": "Die Casting Machinery (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351725",
        "yellow_page_code": "24701",
        "kiosk": false
    },
    {
        "value": "354207",
        "label": "Metal Forming Machinery (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351728",
        "yellow_page_code": "51507",
        "kiosk": false
    },
    {
        "value": "354208",
        "label": "Drill Presses (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351726",
        "yellow_page_code": "26307",
        "kiosk": false
    },
    {
        "value": "354209",
        "label": "Forging Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351727",
        "yellow_page_code": "34101",
        "kiosk": false
    },
    {
        "value": "354210",
        "label": "Punching & Shearing Machinery (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351731",
        "yellow_page_code": "69301, 41123",
        "kiosk": false
    },
    {
        "value": "354211",
        "label": "Swaging (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351732",
        "yellow_page_code": "82603, 82535",
        "kiosk": false
    },
    {
        "value": "354212",
        "label": "Nail Making Machinery (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351734",
        "yellow_page_code": "55020",
        "kiosk": false
    },
    {
        "value": "354213",
        "label": "White Metal Castings Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351733",
        "yellow_page_code": "93415",
        "kiosk": false
    },
    {
        "value": "354301",
        "label": "Model Makers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299925",
        "yellow_page_code": "53302, 53250",
        "kiosk": false
    },
    {
        "value": "354302",
        "label": "Industrial Patterns (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299919",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "354303",
        "label": "Pattern Makers-Foundries Model Mkrs Etc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299920",
        "yellow_page_code": "59804",
        "kiosk": false
    },
    {
        "value": "354304",
        "label": "Cores-Foundry (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299926",
        "yellow_page_code": "21707",
        "kiosk": false
    },
    {
        "value": "354305",
        "label": "Patterns-Clothing Dress & Etc (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299921",
        "yellow_page_code": "59805",
        "kiosk": false
    },
    {
        "value": "354401",
        "label": "Die Cutting Equipment & Supplies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351402",
        "yellow_page_code": "24705",
        "kiosk": false
    },
    {
        "value": "354402",
        "label": "Die Makers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351403",
        "yellow_page_code": "24708",
        "kiosk": false
    },
    {
        "value": "354403",
        "label": "Die Cutting (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351401",
        "yellow_page_code": "24704",
        "kiosk": false
    },
    {
        "value": "354404",
        "label": "Dies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351406",
        "yellow_page_code": "24801, 24709, 80918",
        "kiosk": false
    },
    {
        "value": "354405",
        "label": "Molds (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351410",
        "yellow_page_code": "53404, 54335, 53324, 66231, 53340",
        "kiosk": false
    },
    {
        "value": "354406",
        "label": "Jigs & Fixtures (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351409",
        "yellow_page_code": "44504",
        "kiosk": false
    },
    {
        "value": "354407",
        "label": "Tool & Die Makers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351413",
        "yellow_page_code": "24707, 86905",
        "kiosk": false
    },
    {
        "value": "354408",
        "label": "Wire Drawing Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351414",
        "yellow_page_code": "93911",
        "kiosk": false
    },
    {
        "value": "354409",
        "label": "Wire-Straightening & Cutting (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351415",
        "yellow_page_code": "94001",
        "kiosk": false
    },
    {
        "value": "354410",
        "label": "Dies-Steel Rule (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351407",
        "yellow_page_code": "24712",
        "kiosk": false
    },
    {
        "value": "354411",
        "label": "Rules (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351411",
        "yellow_page_code": "73201",
        "kiosk": false
    },
    {
        "value": "354412",
        "label": "Die Makers Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351404",
        "yellow_page_code": "24710",
        "kiosk": false
    },
    {
        "value": "354413",
        "label": "Die Sinking (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351405",
        "yellow_page_code": "24711",
        "kiosk": false
    },
    {
        "value": "354414",
        "label": "Gasket Cutting Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351408",
        "yellow_page_code": "36811",
        "kiosk": false
    },
    {
        "value": "354415",
        "label": "Mold Makers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351101",
        "yellow_page_code": "53401",
        "kiosk": false
    },
    {
        "value": "354416",
        "label": "Mold Polishing & Finishing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351102",
        "yellow_page_code": "53323",
        "kiosk": false
    },
    {
        "value": "354417",
        "label": "Industrial Mold (Mfrs) ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351104",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "354498",
        "label": "Special Dies/Tools Fxtrs/Ind Molds (Mfr)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351412",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "354501",
        "label": "Measuring Machines (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221620",
        "yellow_page_code": "50702, 50705, 50703",
        "kiosk": false
    },
    {
        "value": "354502",
        "label": "Broaches (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351502",
        "yellow_page_code": "10804",
        "kiosk": false
    },
    {
        "value": "354503",
        "label": "Automobile Machine Shop Equip/Supls-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351501",
        "yellow_page_code": "04921",
        "kiosk": false
    },
    {
        "value": "354504",
        "label": "Cams-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351503",
        "yellow_page_code": "12614",
        "kiosk": false
    },
    {
        "value": "354505",
        "label": "Chuck-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221605",
        "yellow_page_code": "15711",
        "kiosk": false
    },
    {
        "value": "354506",
        "label": "Chucks-Drill Lathe & Magnetic-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221606",
        "yellow_page_code": "15636",
        "kiosk": false
    },
    {
        "value": "354508",
        "label": "Reticles (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221624",
        "yellow_page_code": "72011",
        "kiosk": false
    },
    {
        "value": "354509",
        "label": "Collets (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221607",
        "yellow_page_code": "19703",
        "kiosk": false
    },
    {
        "value": "354510",
        "label": "Comparators (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221608",
        "yellow_page_code": "20109, 57613",
        "kiosk": false
    },
    {
        "value": "354511",
        "label": "Diamond Tools (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221611",
        "yellow_page_code": "24436",
        "kiosk": false
    },
    {
        "value": "354512",
        "label": "Bits (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221601",
        "yellow_page_code": "08316",
        "kiosk": false
    },
    {
        "value": "354513",
        "label": "Milling Cutters (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221621",
        "yellow_page_code": "52406",
        "kiosk": false
    },
    {
        "value": "354514",
        "label": "Drill Heads-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221612",
        "yellow_page_code": "26315",
        "kiosk": false
    },
    {
        "value": "354515",
        "label": "Tools-Precision (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221630",
        "yellow_page_code": "87203",
        "kiosk": false
    },
    {
        "value": "354516",
        "label": "Lathe Attachments (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221619",
        "yellow_page_code": "46103",
        "kiosk": false
    },
    {
        "value": "354598",
        "label": "Cutting Tools/Acces/Measuring Dvcs (Mfr)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351504",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "354601",
        "label": "Riveting Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399104",
        "yellow_page_code": "72308",
        "kiosk": false
    },
    {
        "value": "354602",
        "label": "Flexible Shafting (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399101",
        "yellow_page_code": "32936",
        "kiosk": false
    },
    {
        "value": "354603",
        "label": "Mason Contractors Equipment & Supls-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399102",
        "yellow_page_code": "50312",
        "kiosk": false
    },
    {
        "value": "354604",
        "label": "Tools-Carbide-Coating (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399105",
        "yellow_page_code": "87012",
        "kiosk": false
    },
    {
        "value": "354605",
        "label": "Tools-Powder Actuated-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399107",
        "yellow_page_code": "87013",
        "kiosk": false
    },
    {
        "value": "354606",
        "label": "Tools-Pneumatic-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399106",
        "yellow_page_code": "87195",
        "kiosk": false
    },
    {
        "value": "354698",
        "label": "Power-Driven Hand Tools (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399103",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "354701",
        "label": "Steel Processing/Fabricating Equip (Mfr)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351904",
        "yellow_page_code": "81100",
        "kiosk": false
    },
    {
        "value": "354702",
        "label": "Rolling Mill Machinery (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351903",
        "yellow_page_code": "72605",
        "kiosk": false
    },
    {
        "value": "354703",
        "label": "Metal Shaping (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351901",
        "yellow_page_code": "51607",
        "kiosk": false
    },
    {
        "value": "354704",
        "label": "Pipe Coils (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351902",
        "yellow_page_code": "65501",
        "kiosk": false
    },
    {
        "value": "354705",
        "label": "Tube Cutting (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351905",
        "yellow_page_code": "89318",
        "kiosk": false
    },
    {
        "value": "354801",
        "label": "Welding Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399201",
        "yellow_page_code": "93103",
        "kiosk": false
    },
    {
        "value": "354901",
        "label": "Wire & Cable Machinery-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351909",
        "yellow_page_code": "93912",
        "kiosk": false
    },
    {
        "value": "354902",
        "label": "Metal Treating (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351906",
        "yellow_page_code": "51713",
        "kiosk": false
    },
    {
        "value": "354903",
        "label": "Slitting Machines (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351908",
        "yellow_page_code": "78406",
        "kiosk": false
    },
    {
        "value": "354904",
        "label": "Marking Service-Metal Rubber-Etc (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351910",
        "yellow_page_code": "50202, 50122",
        "kiosk": false
    },
    {
        "value": "354998",
        "label": "Metalworking Machinery NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351907",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "355201",
        "label": "Bobbin-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324815",
        "yellow_page_code": "08904",
        "kiosk": false
    },
    {
        "value": "355202",
        "label": "Textile Machinery (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324816",
        "yellow_page_code": "85315",
        "kiosk": false
    },
    {
        "value": "355203",
        "label": "Embroidery-Industrial-Commercial (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324817",
        "yellow_page_code": "28536",
        "kiosk": false
    },
    {
        "value": "355204",
        "label": "Spindles (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324818",
        "yellow_page_code": "79904",
        "kiosk": false
    },
    {
        "value": "355205",
        "label": "Cloth Cutting Machines-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324819",
        "yellow_page_code": "18710",
        "kiosk": false
    },
    {
        "value": "355206",
        "label": "Cloth Laying Machines (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324820",
        "yellow_page_code": "18711",
        "kiosk": false
    },
    {
        "value": "355207",
        "label": "Pleating Machines (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324821",
        "yellow_page_code": "66607",
        "kiosk": false
    },
    {
        "value": "355208",
        "label": "Thread-Rolling Cutng/Grndng Equip (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324822",
        "yellow_page_code": "85802",
        "kiosk": false
    },
    {
        "value": "355209",
        "label": "Tufting Machinery (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324823",
        "yellow_page_code": "89312",
        "kiosk": false
    },
    {
        "value": "355210",
        "label": "Winding Machinery (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324824",
        "yellow_page_code": "93506",
        "kiosk": false
    },
    {
        "value": "355301",
        "label": "Pattern Makers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324303",
        "yellow_page_code": "59802",
        "kiosk": false
    },
    {
        "value": "355302",
        "label": "Furniture-Manufacturers Equip/Supls-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324302",
        "yellow_page_code": "35715, 30552",
        "kiosk": false
    },
    {
        "value": "355303",
        "label": "Saws-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324306",
        "yellow_page_code": "74209",
        "kiosk": false
    },
    {
        "value": "355304",
        "label": "Woodworking Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324307",
        "yellow_page_code": "49213, 94311",
        "kiosk": false
    },
    {
        "value": "355305",
        "label": "Filter Presses (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324301",
        "yellow_page_code": "31714",
        "kiosk": false
    },
    {
        "value": "355306",
        "label": "Machinery-Sawmill (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324305",
        "yellow_page_code": "49216",
        "kiosk": false
    },
    {
        "value": "355307",
        "label": "Plywood & Veneer Machinery (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324304",
        "yellow_page_code": "66807",
        "kiosk": false
    },
    {
        "value": "355308",
        "label": "Sawmill Woodworking & Paper Machinery (Mfrs) ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324312",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "355398",
        "label": "Woodworking Machinery (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324308",
        "yellow_page_code": "94242, 49011",
        "kiosk": false
    },
    {
        "value": "355401",
        "label": "Paper Mill Machinery-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324310",
        "yellow_page_code": "59006",
        "kiosk": false
    },
    {
        "value": "355402",
        "label": "Folding Machines (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324309",
        "yellow_page_code": "33706",
        "kiosk": false
    },
    {
        "value": "355403",
        "label": "Paper Mill Supplies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324311",
        "yellow_page_code": "59007",
        "kiosk": false
    },
    {
        "value": "355501",
        "label": "Offset Reproductions Equip & Supl (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324802",
        "yellow_page_code": "56405",
        "kiosk": false
    },
    {
        "value": "355502",
        "label": "Printing Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324806",
        "yellow_page_code": "67958, 68336",
        "kiosk": false
    },
    {
        "value": "355503",
        "label": "Ruling Machines (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324809",
        "yellow_page_code": "73113",
        "kiosk": false
    },
    {
        "value": "355504",
        "label": "Print Cutters (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324804",
        "yellow_page_code": "68205",
        "kiosk": false
    },
    {
        "value": "355505",
        "label": "Print Blocks (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324803",
        "yellow_page_code": "68206",
        "kiosk": false
    },
    {
        "value": "355506",
        "label": "Printing Plates-Plastic (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324808",
        "yellow_page_code": "68413",
        "kiosk": false
    },
    {
        "value": "355507",
        "label": "Printing Plate Molding Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324807",
        "yellow_page_code": "68422",
        "kiosk": false
    },
    {
        "value": "355508",
        "label": "Etching Equipment & Supplies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324801",
        "yellow_page_code": "29907",
        "kiosk": false
    },
    {
        "value": "355509",
        "label": "Type Steel (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324811",
        "yellow_page_code": "89701",
        "kiosk": false
    },
    {
        "value": "355510",
        "label": "Typesetting Machines-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324813",
        "yellow_page_code": "89612",
        "kiosk": false
    },
    {
        "value": "355511",
        "label": "Type-Founders (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324812",
        "yellow_page_code": "89608",
        "kiosk": false
    },
    {
        "value": "355512",
        "label": "Printer Blades (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324805",
        "yellow_page_code": "68301",
        "kiosk": false
    },
    {
        "value": "355513",
        "label": "Stereotypers Equipment & Supls (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324810",
        "yellow_page_code": "81209",
        "kiosk": false
    },
    {
        "value": "355601",
        "label": "Meat Choppers & Grinders (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324111",
        "yellow_page_code": "50801",
        "kiosk": false
    },
    {
        "value": "355602",
        "label": "Meat Saws (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324112",
        "yellow_page_code": "50900",
        "kiosk": false
    },
    {
        "value": "355603",
        "label": "Candy & Confectionery-Mfrs Equip (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324103",
        "yellow_page_code": "12706",
        "kiosk": false
    },
    {
        "value": "355604",
        "label": "Popcorn Machines (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324116",
        "yellow_page_code": "67304",
        "kiosk": false
    },
    {
        "value": "355605",
        "label": "Ovens-Bakers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324114",
        "yellow_page_code": "58200",
        "kiosk": false
    },
    {
        "value": "355606",
        "label": "Separators (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324120",
        "yellow_page_code": "75508",
        "kiosk": false
    },
    {
        "value": "355607",
        "label": "Can Washers & Sanitizers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324102",
        "yellow_page_code": "12612",
        "kiosk": false
    },
    {
        "value": "355608",
        "label": "Milk Dispensers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324113",
        "yellow_page_code": "52308, 52320",
        "kiosk": false
    },
    {
        "value": "355609",
        "label": "Fish Packers Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324105",
        "yellow_page_code": "32504, 32410",
        "kiosk": false
    },
    {
        "value": "355610",
        "label": "Ice Cream-Manufacturers Supplies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324108",
        "yellow_page_code": "42409",
        "kiosk": false
    },
    {
        "value": "355611",
        "label": "Bakers Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324101",
        "yellow_page_code": "05911",
        "kiosk": false
    },
    {
        "value": "355612",
        "label": "Rice Mill Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324119",
        "yellow_page_code": "72108",
        "kiosk": false
    },
    {
        "value": "355613",
        "label": "Dairy Equipment & Supplies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324104",
        "yellow_page_code": "23210",
        "kiosk": false
    },
    {
        "value": "355614",
        "label": "Macaroni Machinery (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324109",
        "yellow_page_code": "49034",
        "kiosk": false
    },
    {
        "value": "355615",
        "label": "Potato Chip Machines (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324117",
        "yellow_page_code": "67520",
        "kiosk": false
    },
    {
        "value": "355616",
        "label": "Potato Processing Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324118",
        "yellow_page_code": "67607",
        "kiosk": false
    },
    {
        "value": "355617",
        "label": "Pasta Machinery (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324115",
        "yellow_page_code": "59604",
        "kiosk": false
    },
    {
        "value": "355618",
        "label": "Tortilla & Tamale Making Equip (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324121",
        "yellow_page_code": "87309",
        "kiosk": false
    },
    {
        "value": "355619",
        "label": "Malted Milk Mixers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324110",
        "yellow_page_code": "49814",
        "kiosk": false
    },
    {
        "value": "355620",
        "label": "Homogenizers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324107",
        "yellow_page_code": "41405",
        "kiosk": false
    },
    {
        "value": "355621",
        "label": "Coffee Roasting/Handling Equipment-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324122",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "355698",
        "label": "Food Products-Machinery (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324106",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "355901",
        "label": "Kilns (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324825",
        "yellow_page_code": "44902",
        "kiosk": false
    },
    {
        "value": "355902",
        "label": "Cotton Ginning Equip & Supplies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331048",
        "yellow_page_code": "21907",
        "kiosk": false
    },
    {
        "value": "355903",
        "label": "Wheel Alignment-Frame Svc-Equip-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331085",
        "yellow_page_code": "93314, 93318",
        "kiosk": false
    },
    {
        "value": "355904",
        "label": "Automobile Body Shop Equipment-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331039",
        "yellow_page_code": "04605",
        "kiosk": false
    },
    {
        "value": "355905",
        "label": "Manufacturers-Aquatic Weed Harvester",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331060",
        "yellow_page_code": "49921",
        "kiosk": false
    },
    {
        "value": "355906",
        "label": "Shoe Machinery-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331075",
        "yellow_page_code": "76803, 34028, 34030",
        "kiosk": false
    },
    {
        "value": "355907",
        "label": "Rubber Mill Machinery (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324826",
        "yellow_page_code": "72913",
        "kiosk": false
    },
    {
        "value": "355908",
        "label": "Industrial Wash Systems (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331089",
        "yellow_page_code": "42943, 59428",
        "kiosk": false
    },
    {
        "value": "355909",
        "label": "Special Industry Machinery NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331087",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "355910",
        "label": "Cutting Room Equipment & Supplies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331049",
        "yellow_page_code": "23106",
        "kiosk": false
    },
    {
        "value": "355911",
        "label": "Glass Making & Working Machinery (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324827",
        "yellow_page_code": "37507, 37722",
        "kiosk": false
    },
    {
        "value": "355912",
        "label": "Metallizing Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331063",
        "yellow_page_code": "51804",
        "kiosk": false
    },
    {
        "value": "355913",
        "label": "Pharmaceutical Machinery (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331067",
        "yellow_page_code": "60608, 29978",
        "kiosk": false
    },
    {
        "value": "355914",
        "label": "Leather-Working Machinery (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324828",
        "yellow_page_code": "46709",
        "kiosk": false
    },
    {
        "value": "355915",
        "label": "Colloidal Mills (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331045",
        "yellow_page_code": "19704",
        "kiosk": false
    },
    {
        "value": "355916",
        "label": "Concrete-Block/Prod-Molding Mach (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331047",
        "yellow_page_code": "20503",
        "kiosk": false
    },
    {
        "value": "355917",
        "label": "Fur Dressers & Dyers Equip/Supls (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324829",
        "yellow_page_code": "35201",
        "kiosk": false
    },
    {
        "value": "355918",
        "label": "Parking Stations & Garages Equip-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331065",
        "yellow_page_code": "59409",
        "kiosk": false
    },
    {
        "value": "355919",
        "label": "Petroleum Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331066",
        "yellow_page_code": "60512",
        "kiosk": false
    },
    {
        "value": "355920",
        "label": "Brick Makers' Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331040",
        "yellow_page_code": "10509, 30325",
        "kiosk": false
    },
    {
        "value": "355921",
        "label": "Chemical Plant-Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331042",
        "yellow_page_code": "14936",
        "kiosk": false
    },
    {
        "value": "355922",
        "label": "Cigar/Cigarette-Mfrs Equip/Supls (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331043",
        "yellow_page_code": "17906",
        "kiosk": false
    },
    {
        "value": "355923",
        "label": "Metal-Disintegrating (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331062",
        "yellow_page_code": "51409",
        "kiosk": false
    },
    {
        "value": "355924",
        "label": "Jewelers Equipment & Machinery (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324830",
        "yellow_page_code": "44218",
        "kiosk": false
    },
    {
        "value": "355925",
        "label": "Plastics-Machinery & Equipment-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324831",
        "yellow_page_code": "66311",
        "kiosk": false
    },
    {
        "value": "355926",
        "label": "Plating Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331068",
        "yellow_page_code": "66512",
        "kiosk": false
    },
    {
        "value": "355927",
        "label": "Tobacco Machinery (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324832",
        "yellow_page_code": "86607",
        "kiosk": false
    },
    {
        "value": "355928",
        "label": "Tobacco Growers' Equip & Supplies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324833",
        "yellow_page_code": "86701",
        "kiosk": false
    },
    {
        "value": "355929",
        "label": "Zipper Machinery (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331086",
        "yellow_page_code": "94905",
        "kiosk": false
    },
    {
        "value": "355930",
        "label": "Glass-Grinding/Cutting Machinery (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331056",
        "yellow_page_code": "37509",
        "kiosk": false
    },
    {
        "value": "355931",
        "label": "Roofing-Manufacturers-Machinery (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331071",
        "yellow_page_code": "72607",
        "kiosk": false
    },
    {
        "value": "355932",
        "label": "Steak & Patty Machines (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331076",
        "yellow_page_code": "80707",
        "kiosk": false
    },
    {
        "value": "355933",
        "label": "Recycling Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324834",
        "yellow_page_code": "71046",
        "kiosk": false
    },
    {
        "value": "355934",
        "label": "Semiconductor Manufacturing Equip (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324201",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "355935",
        "label": "Industrial Machinery (Mfrs) ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33324835",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "356101",
        "label": "Pumps-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33391402",
        "yellow_page_code": "69107",
        "kiosk": false
    },
    {
        "value": "356102",
        "label": "Sump Pumps (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33391403",
        "yellow_page_code": "82210",
        "kiosk": false
    },
    {
        "value": "356198",
        "label": "Pumps & Pumping Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33391401",
        "yellow_page_code": "67967",
        "kiosk": false
    },
    {
        "value": "356201",
        "label": "Bearings-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299102",
        "yellow_page_code": "07121",
        "kiosk": false
    },
    {
        "value": "356298",
        "label": "Ball & Roller Bearing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299101",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "356301",
        "label": "Compressors-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33391202",
        "yellow_page_code": "20260",
        "kiosk": false
    },
    {
        "value": "356302",
        "label": "Metal Spraying Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33391203",
        "yellow_page_code": "51707",
        "kiosk": false
    },
    {
        "value": "356303",
        "label": "Spraying Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33391204",
        "yellow_page_code": "80112",
        "kiosk": false
    },
    {
        "value": "356398",
        "label": "Air & Gas Compressors (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33391201",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "356401",
        "label": "Fans-Industrial & Commercial-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341310",
        "yellow_page_code": "30609",
        "kiosk": false
    },
    {
        "value": "356402",
        "label": "Air Cleaning & Purifying Equipment-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341301",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "356403",
        "label": "Blowers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341307",
        "yellow_page_code": "08509, 30715",
        "kiosk": false
    },
    {
        "value": "356404",
        "label": "Dust Collector Bags-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341303",
        "yellow_page_code": "26907",
        "kiosk": false
    },
    {
        "value": "356405",
        "label": "Fan & Blower Parts-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341309",
        "yellow_page_code": "30703, 08513",
        "kiosk": false
    },
    {
        "value": "356406",
        "label": "Filters-Air & Gas-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341304",
        "yellow_page_code": "31721",
        "kiosk": false
    },
    {
        "value": "356408",
        "label": "Filtration Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341305",
        "yellow_page_code": "31715",
        "kiosk": false
    },
    {
        "value": "356410",
        "label": "Air Curtains & Screens-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341302",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "356411",
        "label": "Diffusers-Air (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341308",
        "yellow_page_code": "24803",
        "kiosk": false
    },
    {
        "value": "356412",
        "label": "Garage Exhaust Systems (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341306",
        "yellow_page_code": "36103",
        "kiosk": false
    },
    {
        "value": "356413",
        "label": "Ventilating Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341312",
        "yellow_page_code": "90803",
        "kiosk": false
    },
    {
        "value": "356498",
        "label": "Industrial & Coml Fans & Blowers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341311",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "356501",
        "label": "Labeling Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399303",
        "yellow_page_code": "45111",
        "kiosk": false
    },
    {
        "value": "356502",
        "label": "Packaging Machinery-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399304",
        "yellow_page_code": "58305",
        "kiosk": false
    },
    {
        "value": "356503",
        "label": "Filling Machinery (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399302",
        "yellow_page_code": "31608",
        "kiosk": false
    },
    {
        "value": "356504",
        "label": "Capping Machinery (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399301",
        "yellow_page_code": "13005",
        "kiosk": false
    },
    {
        "value": "356601",
        "label": "Gears & Gear Cutting (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33361201",
        "yellow_page_code": "37000",
        "kiosk": false
    },
    {
        "value": "356602",
        "label": "Speed Reducers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33361203",
        "yellow_page_code": "79806",
        "kiosk": false
    },
    {
        "value": "356698",
        "label": "Speed Changers-Ind-Drives & Gears (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33361202",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "356701",
        "label": "Ovens-Industrial (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399406",
        "yellow_page_code": "58202",
        "kiosk": false
    },
    {
        "value": "356702",
        "label": "Furnaces-Industrial (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399401",
        "yellow_page_code": "35508, 35510",
        "kiosk": false
    },
    {
        "value": "356703",
        "label": "Heat Recovery Equipment & Systems-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399402",
        "yellow_page_code": "40613",
        "kiosk": false
    },
    {
        "value": "356704",
        "label": "Heaters-Industrial (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399403",
        "yellow_page_code": "40803, 82249",
        "kiosk": false
    },
    {
        "value": "356705",
        "label": "Incinerators-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399404",
        "yellow_page_code": "42810",
        "kiosk": false
    },
    {
        "value": "356798",
        "label": "Industrial Process-Furnaces/Ovens (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399405",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "356801",
        "label": "Rotary Joints (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33361302",
        "yellow_page_code": "72807",
        "kiosk": false
    },
    {
        "value": "356802",
        "label": "Shafting (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33361303",
        "yellow_page_code": "76005",
        "kiosk": false
    },
    {
        "value": "356898",
        "label": "Mechanical Power Trans Equip NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33361301",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "356901",
        "label": "Screening & Sifting Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399830",
        "yellow_page_code": "74901",
        "kiosk": false
    },
    {
        "value": "356902",
        "label": "Centrifugal Machinery (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399807",
        "yellow_page_code": "14404, 69126",
        "kiosk": false
    },
    {
        "value": "356903",
        "label": "Tying Machines (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399833",
        "yellow_page_code": "89606",
        "kiosk": false
    },
    {
        "value": "356904",
        "label": "Filters-Liquid (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399812",
        "yellow_page_code": "31708, 31733",
        "kiosk": false
    },
    {
        "value": "356905",
        "label": "Filtering Materials & Supplies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399810",
        "yellow_page_code": "31700, 31711",
        "kiosk": false
    },
    {
        "value": "356906",
        "label": "Lubricating Devices & Systems (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399825",
        "yellow_page_code": "48706, 30492",
        "kiosk": false
    },
    {
        "value": "356907",
        "label": "Automation Systems & Equipment-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399804",
        "yellow_page_code": "04504",
        "kiosk": false
    },
    {
        "value": "356908",
        "label": "Baling Equipment & Supls-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399805",
        "yellow_page_code": "06111",
        "kiosk": false
    },
    {
        "value": "356909",
        "label": "Crematories-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399808",
        "yellow_page_code": "22507",
        "kiosk": false
    },
    {
        "value": "356910",
        "label": "Fire Protection Equipment & Supls-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399814",
        "yellow_page_code": "32113",
        "kiosk": false
    },
    {
        "value": "356911",
        "label": "Hose-Industrial-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399819",
        "yellow_page_code": "41701",
        "kiosk": false
    },
    {
        "value": "356912",
        "label": "Robots-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399829",
        "yellow_page_code": "72511",
        "kiosk": false
    },
    {
        "value": "356913",
        "label": "Sprinklers-Automatic-Fire-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399832",
        "yellow_page_code": "80217",
        "kiosk": false
    },
    {
        "value": "356914",
        "label": "Ozone Machines (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399827",
        "yellow_page_code": "58304, 58212, 58215",
        "kiosk": false
    },
    {
        "value": "356915",
        "label": "Spill Control Equip & Materials (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399831",
        "yellow_page_code": "79909",
        "kiosk": false
    },
    {
        "value": "356916",
        "label": "Filters-Chemical (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399811",
        "yellow_page_code": "31716",
        "kiosk": false
    },
    {
        "value": "356917",
        "label": "Fire Department Equipment & Supls-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399813",
        "yellow_page_code": "31912",
        "kiosk": false
    },
    {
        "value": "356918",
        "label": "Purifiers-Industrial (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399828",
        "yellow_page_code": "69217",
        "kiosk": false
    },
    {
        "value": "356919",
        "label": "Hose Racks & Reels (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399818",
        "yellow_page_code": "41609",
        "kiosk": false
    },
    {
        "value": "356920",
        "label": "Ice Crushers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399820",
        "yellow_page_code": "42316",
        "kiosk": false
    },
    {
        "value": "356921",
        "label": "Gas Purifiers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399815",
        "yellow_page_code": "36813",
        "kiosk": false
    },
    {
        "value": "356922",
        "label": "Gasoline Plant Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399816",
        "yellow_page_code": "36814",
        "kiosk": false
    },
    {
        "value": "356923",
        "label": "Ice Shaving Machines (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399822",
        "yellow_page_code": "42509",
        "kiosk": false
    },
    {
        "value": "356924",
        "label": "Environmental Test Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399809",
        "yellow_page_code": "29611",
        "kiosk": false
    },
    {
        "value": "356925",
        "label": "Ice Resurfacing Machines (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399821",
        "yellow_page_code": "42500",
        "kiosk": false
    },
    {
        "value": "356998",
        "label": "General Ind Machinery/Equip NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399817",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "357101",
        "label": "Computers-Electronic-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33411101",
        "yellow_page_code": "20300, 20261, 20291",
        "kiosk": false
    },
    {
        "value": "357103",
        "label": "Data Processing Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33411102",
        "yellow_page_code": "23412",
        "kiosk": false
    },
    {
        "value": "357104",
        "label": "Computer Equipment Mini (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33411103",
        "yellow_page_code": "20231",
        "kiosk": false
    },
    {
        "value": "357105",
        "label": "Computers & Computer Equipment Whls/Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33411104",
        "yellow_page_code": "20354",
        "kiosk": false
    },
    {
        "value": "357298",
        "label": "Computer Storage Devices (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33411201",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "357598",
        "label": "Computer Terminals (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33411805",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "357701",
        "label": "Computer Supplies & Parts-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33411802",
        "yellow_page_code": "20234",
        "kiosk": false
    },
    {
        "value": "357702",
        "label": "Computer Peripherals (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33411801",
        "yellow_page_code": "20319",
        "kiosk": false
    },
    {
        "value": "357703",
        "label": "Plotters (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331006",
        "yellow_page_code": "66704",
        "kiosk": false
    },
    {
        "value": "357704",
        "label": "Presentation Graphics (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33411804",
        "yellow_page_code": "68038, 38825",
        "kiosk": false
    },
    {
        "value": "357706",
        "label": "Magnetic Ink Incoding (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33461003",
        "yellow_page_code": "49509",
        "kiosk": false
    },
    {
        "value": "357801",
        "label": "Bank Equipment & Supplies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331022",
        "yellow_page_code": "06314",
        "kiosk": false
    },
    {
        "value": "357802",
        "label": "Calculating & Adding Machines/Supls-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331026",
        "yellow_page_code": "12310",
        "kiosk": false
    },
    {
        "value": "357803",
        "label": "Cash Registers & Supplies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331028",
        "yellow_page_code": "13910",
        "kiosk": false
    },
    {
        "value": "357804",
        "label": "Cash Drawers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331027",
        "yellow_page_code": "13911",
        "kiosk": false
    },
    {
        "value": "357805",
        "label": "Point Of Sales Systems (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33411803",
        "yellow_page_code": "66910, 66917",
        "kiosk": false
    },
    {
        "value": "357806",
        "label": "ATM Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33411806",
        "yellow_page_code": "04195",
        "kiosk": false
    },
    {
        "value": "357898",
        "label": "Calculating & Accounting Machines (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331025",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "357901",
        "label": "Numbering Machines (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331034",
        "yellow_page_code": "55806",
        "kiosk": false
    },
    {
        "value": "357902",
        "label": "Adhesives & Gluing Equipment-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33994004",
        "yellow_page_code": "37908, 39307, 00304",
        "kiosk": false
    },
    {
        "value": "357903",
        "label": "Tape Applying & Dispensing Machs (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33994013",
        "yellow_page_code": "83706",
        "kiosk": false
    },
    {
        "value": "357904",
        "label": "Perforating Machines (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331036",
        "yellow_page_code": "60208",
        "kiosk": false
    },
    {
        "value": "357905",
        "label": "Time Stamps (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451934",
        "yellow_page_code": "86206",
        "kiosk": false
    },
    {
        "value": "357906",
        "label": "Time Recorders & Systems-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451933",
        "yellow_page_code": "86204, 92008, 86133",
        "kiosk": false
    },
    {
        "value": "357907",
        "label": "Mailing Machines & Equipment-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331033",
        "yellow_page_code": "49613",
        "kiosk": false
    },
    {
        "value": "357908",
        "label": "Copying Machines & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331030",
        "yellow_page_code": "21514, 21525",
        "kiosk": false
    },
    {
        "value": "357909",
        "label": "Time Cards Racks & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451932",
        "yellow_page_code": "86134",
        "kiosk": false
    },
    {
        "value": "357910",
        "label": "Typewriters-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331038",
        "yellow_page_code": "89711",
        "kiosk": false
    },
    {
        "value": "357911",
        "label": "Coin Dealers Supplies Etc-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331029",
        "yellow_page_code": "19503",
        "kiosk": false
    },
    {
        "value": "357912",
        "label": "Ticket Issuing Machines (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331037",
        "yellow_page_code": "85901",
        "kiosk": false
    },
    {
        "value": "357913",
        "label": "Counterfeit Money Detecting Dvcs (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331031",
        "yellow_page_code": "22107",
        "kiosk": false
    },
    {
        "value": "357914",
        "label": "Business Forms Handling Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331032",
        "yellow_page_code": "11828",
        "kiosk": false
    },
    {
        "value": "357915",
        "label": "Pencil Sharpeners (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33994010",
        "yellow_page_code": "60114",
        "kiosk": false
    },
    {
        "value": "357916",
        "label": "Bindings-Manufacturers Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331023",
        "yellow_page_code": "08111",
        "kiosk": false
    },
    {
        "value": "357917",
        "label": "Bursting Machines (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331024",
        "yellow_page_code": "11514, 11527",
        "kiosk": false
    },
    {
        "value": "357918",
        "label": "Staples Staplers & Tackers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33994012",
        "yellow_page_code": "80534",
        "kiosk": false
    },
    {
        "value": "357919",
        "label": "Paper Cutting-Knives (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33994009",
        "yellow_page_code": "58907",
        "kiosk": false
    },
    {
        "value": "357998",
        "label": "Office Machines NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331035",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "358101",
        "label": "Vending Machines-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331017",
        "yellow_page_code": "90711",
        "kiosk": false
    },
    {
        "value": "358102",
        "label": "Sanitary Napkin Machines (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331015",
        "yellow_page_code": "73806",
        "kiosk": false
    },
    {
        "value": "358103",
        "label": "Vending Machines Supplies & Parts-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331016",
        "yellow_page_code": "90714",
        "kiosk": false
    },
    {
        "value": "358201",
        "label": "Carpet & Rug Cleaning Equip & Supls-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331018",
        "yellow_page_code": "13605",
        "kiosk": false
    },
    {
        "value": "358202",
        "label": "Chillers-Liquid (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331019",
        "yellow_page_code": "15207",
        "kiosk": false
    },
    {
        "value": "358203",
        "label": "Pressing Machines-Clothes-Reprng (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331021",
        "yellow_page_code": "68112",
        "kiosk": false
    },
    {
        "value": "358298",
        "label": "Commercial Laundry Dryclean Machs (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331020",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "358501",
        "label": "Air Conditioning Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341501",
        "yellow_page_code": "01004",
        "kiosk": false
    },
    {
        "value": "358502",
        "label": "Air Conditioning Supplies & Parts-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341503",
        "yellow_page_code": "01011",
        "kiosk": false
    },
    {
        "value": "358503",
        "label": "Beverage Dispensing Equip/Supls-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341505",
        "yellow_page_code": "07835",
        "kiosk": false
    },
    {
        "value": "358504",
        "label": "Heat Pumps-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341512",
        "yellow_page_code": "40709",
        "kiosk": false
    },
    {
        "value": "358505",
        "label": "Ice Making Equipment & Machines-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341515",
        "yellow_page_code": "42502",
        "kiosk": false
    },
    {
        "value": "358506",
        "label": "Refrigeration Equipment-Truck (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341507",
        "yellow_page_code": "71406",
        "kiosk": false
    },
    {
        "value": "358507",
        "label": "Refrigerating Equip Supls & Parts-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341517",
        "yellow_page_code": "71307",
        "kiosk": false
    },
    {
        "value": "358508",
        "label": "Evaporative Coolers Mfg & Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341518",
        "yellow_page_code": "29930",
        "kiosk": false
    },
    {
        "value": "358509",
        "label": "Dehumidifying Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341509",
        "yellow_page_code": "23712",
        "kiosk": false
    },
    {
        "value": "358510",
        "label": "Electric Heating Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341510",
        "yellow_page_code": "27514",
        "kiosk": false
    },
    {
        "value": "358511",
        "label": "Humidifying Apparatus-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341513",
        "yellow_page_code": "42035",
        "kiosk": false
    },
    {
        "value": "358512",
        "label": "Marine Refrigeration & Air Cond-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341516",
        "yellow_page_code": "50037",
        "kiosk": false
    },
    {
        "value": "358513",
        "label": "Air Conditioning Room Units-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341502",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "358514",
        "label": "Snow Making Machines (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341520",
        "yellow_page_code": "78605",
        "kiosk": false
    },
    {
        "value": "358515",
        "label": "Evaporating Apparatus (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341511",
        "yellow_page_code": "30000",
        "kiosk": false
    },
    {
        "value": "358516",
        "label": "Ice Boxes (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341514",
        "yellow_page_code": "42309",
        "kiosk": false
    },
    {
        "value": "358517",
        "label": "Water Coolers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341521",
        "yellow_page_code": "92114",
        "kiosk": false
    },
    {
        "value": "358518",
        "label": "Snow Ball Machines Equip/Supplies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341519",
        "yellow_page_code": "78614",
        "kiosk": false
    },
    {
        "value": "358519",
        "label": "Refrigerant Recovery Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341522",
        "yellow_page_code": "71524",
        "kiosk": false
    },
    {
        "value": "358598",
        "label": "Air Conditioning/Htg/Refrig Equip (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341504",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "358601",
        "label": "Dispensing Devices-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33391404",
        "yellow_page_code": "25014",
        "kiosk": false
    },
    {
        "value": "358602",
        "label": "Service Station Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33391406",
        "yellow_page_code": "75614",
        "kiosk": false
    },
    {
        "value": "358698",
        "label": "Measuring & Dispensing Pumps (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33391405",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "358901",
        "label": "Floor Layers Equipment & Supplies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331053",
        "yellow_page_code": "33312",
        "kiosk": false
    },
    {
        "value": "358902",
        "label": "Water Works Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331084",
        "yellow_page_code": "92606",
        "kiosk": false
    },
    {
        "value": "358903",
        "label": "Compactors-Waste-Industrial/Coml (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331046",
        "yellow_page_code": "20200",
        "kiosk": false
    },
    {
        "value": "358904",
        "label": "Car Washing & Polishing Equipment-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331041",
        "yellow_page_code": "13103, 49963",
        "kiosk": false
    },
    {
        "value": "358905",
        "label": "Floor Sanding & Polishing Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331054",
        "yellow_page_code": "33212, 33324, 18424, 33330",
        "kiosk": false
    },
    {
        "value": "358906",
        "label": "Janitors Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331058",
        "yellow_page_code": "44115",
        "kiosk": false
    },
    {
        "value": "358907",
        "label": "Motel & Hotel Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331064",
        "yellow_page_code": "53709",
        "kiosk": false
    },
    {
        "value": "358908",
        "label": "Sewage Treatment Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331073",
        "yellow_page_code": "75707",
        "kiosk": false
    },
    {
        "value": "358909",
        "label": "Waste Reduction Disposal Equip-Ind-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331080",
        "yellow_page_code": "91836",
        "kiosk": false
    },
    {
        "value": "358910",
        "label": "Vacuum Equipment & Systems-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331079",
        "yellow_page_code": "90412, 82212",
        "kiosk": false
    },
    {
        "value": "358911",
        "label": "Water Softening/Cond Equip Svc/Supl-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331082",
        "yellow_page_code": "92411",
        "kiosk": false
    },
    {
        "value": "358912",
        "label": "Water Treatment Equip Svc & Supls-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331083",
        "yellow_page_code": "92509",
        "kiosk": false
    },
    {
        "value": "358913",
        "label": "Water Purification/Filtration Eqpt-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331081",
        "yellow_page_code": "92408",
        "kiosk": false
    },
    {
        "value": "358914",
        "label": "Cleaning Systems-Pressure Chemical-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331044",
        "yellow_page_code": "18412",
        "kiosk": false
    },
    {
        "value": "358915",
        "label": "Pushcarts (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331070",
        "yellow_page_code": "69305",
        "kiosk": false
    },
    {
        "value": "358916",
        "label": "Sewer Cleaning Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331074",
        "yellow_page_code": "75810",
        "kiosk": false
    },
    {
        "value": "358998",
        "label": "Service Industry Machinery NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331072",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "359201",
        "label": "Carburetors-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33631002",
        "yellow_page_code": "13312",
        "kiosk": false
    },
    {
        "value": "359202",
        "label": "Gas-Liquid Petro-Carburetion Syst (Mfr)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33631003",
        "yellow_page_code": "36702",
        "kiosk": false
    },
    {
        "value": "359203",
        "label": "Motor Vehicle Gas Engine & Parts (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33631004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "359298",
        "label": "Carburetors Pistons Rings/Valves (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33631001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "359301",
        "label": "Cylinders-Electric (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399502",
        "yellow_page_code": "23109",
        "kiosk": false
    },
    {
        "value": "359302",
        "label": "Cylinders-Air & Hydraulic-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399501",
        "yellow_page_code": "23110",
        "kiosk": false
    },
    {
        "value": "359303",
        "label": "Cylinder Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399504",
        "yellow_page_code": "23115",
        "kiosk": false
    },
    {
        "value": "359398",
        "label": "Fluid Power Cylinders & Actuators (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399503",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "359401",
        "label": "Fluid Power Pumps & Motors (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399601",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "359601",
        "label": "Scales-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399803",
        "yellow_page_code": "74312",
        "kiosk": false
    },
    {
        "value": "359602",
        "label": "Baby Scales (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399801",
        "yellow_page_code": "05505",
        "kiosk": false
    },
    {
        "value": "359698",
        "label": "Scales & Balances-Ex Laboratory (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399802",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "359901",
        "label": "Filters (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331052",
        "yellow_page_code": "31701",
        "kiosk": false
    },
    {
        "value": "359902",
        "label": "Chemical Milling (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33271003",
        "yellow_page_code": "15001",
        "kiosk": false
    },
    {
        "value": "359903",
        "label": "Machine Shops (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33271002",
        "yellow_page_code": "49106, 49310, 31511, 30143, 49225, 49114, 49131, 56555",
        "kiosk": false
    },
    {
        "value": "359904",
        "label": "Woodworking Machinery Equip/Supls (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399835",
        "yellow_page_code": "94307",
        "kiosk": false
    },
    {
        "value": "359905",
        "label": "Machinery-Specially Designed & Built",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331059",
        "yellow_page_code": "49302",
        "kiosk": false
    },
    {
        "value": "359906",
        "label": "Precleaners-Air Intake Systems (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331069",
        "yellow_page_code": "68021",
        "kiosk": false
    },
    {
        "value": "359907",
        "label": "Bottlers Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399806",
        "yellow_page_code": "09510",
        "kiosk": false
    },
    {
        "value": "359908",
        "label": "Machine Shop Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33271001",
        "yellow_page_code": "49103",
        "kiosk": false
    },
    {
        "value": "359909",
        "label": "Water Detection/Protection Systs (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399834",
        "yellow_page_code": "92215",
        "kiosk": false
    },
    {
        "value": "359914",
        "label": "Oil Well Logging/Perforating Eqpt (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399826",
        "yellow_page_code": "57101",
        "kiosk": false
    },
    {
        "value": "359915",
        "label": "Manufacturers Distrs & Indl Products",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399824",
        "yellow_page_code": "42921, 49863",
        "kiosk": false
    },
    {
        "value": "359916",
        "label": "Grinding-Centerless (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331057",
        "yellow_page_code": "39005",
        "kiosk": false
    },
    {
        "value": "359917",
        "label": "Testing Equipment-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331088",
        "yellow_page_code": "85227",
        "kiosk": false
    },
    {
        "value": "359998",
        "label": "Industrial/Coml Machinery/Equip NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33399823",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "361201",
        "label": "Power Plant Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33531103",
        "yellow_page_code": "67917",
        "kiosk": false
    },
    {
        "value": "361202",
        "label": "Electric Transmission/Distr Equip (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33531101",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "361203",
        "label": "Power Protection Systems (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33531104",
        "yellow_page_code": "67916",
        "kiosk": false
    },
    {
        "value": "361204",
        "label": "Power Transmission Equip-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33531105",
        "yellow_page_code": "67937",
        "kiosk": false
    },
    {
        "value": "361205",
        "label": "Voltage Stabilizers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33531107",
        "yellow_page_code": "80405, 91231",
        "kiosk": false
    },
    {
        "value": "361206",
        "label": "Transformers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33531106",
        "yellow_page_code": "88214",
        "kiosk": false
    },
    {
        "value": "361207",
        "label": "Airport Equipment & Supplies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33531108",
        "yellow_page_code": "01708",
        "kiosk": false
    },
    {
        "value": "361298",
        "label": "Power Distr/Specialty Transformer (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33531102",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "361301",
        "label": "Electric Switchboards-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33531302",
        "yellow_page_code": "27811",
        "kiosk": false
    },
    {
        "value": "361302",
        "label": "Switches-Electric-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33531305",
        "yellow_page_code": "82836",
        "kiosk": false
    },
    {
        "value": "361303",
        "label": "Control Panels (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33531301",
        "yellow_page_code": "21236",
        "kiosk": false
    },
    {
        "value": "361304",
        "label": "Fuses-Electric-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33531303",
        "yellow_page_code": "35995",
        "kiosk": false
    },
    {
        "value": "361305",
        "label": "Panelboards-Electric (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33531304",
        "yellow_page_code": "58802, 27608",
        "kiosk": false
    },
    {
        "value": "361398",
        "label": "Switchgear/Switchboard Apparatus (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33531306",
        "yellow_page_code": "27420",
        "kiosk": false
    },
    {
        "value": "362101",
        "label": "Electric Supplies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33531206",
        "yellow_page_code": "27804",
        "kiosk": false
    },
    {
        "value": "362102",
        "label": "Electric Motors-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33531204",
        "yellow_page_code": "27705, 30425, 54225",
        "kiosk": false
    },
    {
        "value": "362103",
        "label": "Buses-Parts & Supplies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33531201",
        "yellow_page_code": "11699",
        "kiosk": false
    },
    {
        "value": "362104",
        "label": "Generators-Electric-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33531208",
        "yellow_page_code": "37106",
        "kiosk": false
    },
    {
        "value": "362105",
        "label": "Phase Conversion Systems-Electric-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33531214",
        "yellow_page_code": "60636",
        "kiosk": false
    },
    {
        "value": "362106",
        "label": "Inverters (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33531211",
        "yellow_page_code": "43614",
        "kiosk": false
    },
    {
        "value": "362107",
        "label": "Electric Drives-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33531203",
        "yellow_page_code": "27505",
        "kiosk": false
    },
    {
        "value": "362108",
        "label": "Electric Motors-Supplies & Parts-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33531205",
        "yellow_page_code": "27707",
        "kiosk": false
    },
    {
        "value": "362109",
        "label": "Servomechanism Systems & Equip (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33531215",
        "yellow_page_code": "75635",
        "kiosk": false
    },
    {
        "value": "362110",
        "label": "Converting Machinery (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33531202",
        "yellow_page_code": "21339",
        "kiosk": false
    },
    {
        "value": "362111",
        "label": "Generators-Diesel (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33531207",
        "yellow_page_code": "37018",
        "kiosk": false
    },
    {
        "value": "362112",
        "label": "Generators-Gas (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33531216",
        "yellow_page_code": "37112",
        "kiosk": false
    },
    {
        "value": "362198",
        "label": "Motor & Generator-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33531212",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "362401",
        "label": "Carbon Brushes-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33599102",
        "yellow_page_code": "13303",
        "kiosk": false
    },
    {
        "value": "362498",
        "label": "Carbon & Graphite Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33599101",
        "yellow_page_code": "13211",
        "kiosk": false
    },
    {
        "value": "362501",
        "label": "Controls & Control Access-Ind-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33531401",
        "yellow_page_code": "21232",
        "kiosk": false
    },
    {
        "value": "362502",
        "label": "Relays (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33531406",
        "yellow_page_code": "71509",
        "kiosk": false
    },
    {
        "value": "362503",
        "label": "Electronic Controls-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33531404",
        "yellow_page_code": "28015",
        "kiosk": false
    },
    {
        "value": "362504",
        "label": "Electric Motors-Controls-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33531403",
        "yellow_page_code": "27713",
        "kiosk": false
    },
    {
        "value": "362505",
        "label": "Industrial Instrumentation (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33531405",
        "yellow_page_code": "42914, 43030",
        "kiosk": false
    },
    {
        "value": "362506",
        "label": "Electric Instruments-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33531402",
        "yellow_page_code": "27636",
        "kiosk": false
    },
    {
        "value": "362598",
        "label": "Relays & Industrial Controls (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33531407",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "362901",
        "label": "Rectifiers-Electric-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33599907",
        "yellow_page_code": "71115",
        "kiosk": false
    },
    {
        "value": "362902",
        "label": "Power Factor Correction (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33599906",
        "yellow_page_code": "67912",
        "kiosk": false
    },
    {
        "value": "362998",
        "label": "Electrical Indstrl Apparatus NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33599904",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "363101",
        "label": "Barbecue Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33522001",
        "yellow_page_code": "06505",
        "kiosk": false
    },
    {
        "value": "363102",
        "label": "Microwave Ovens-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33522003",
        "yellow_page_code": "52214",
        "kiosk": false
    },
    {
        "value": "363103",
        "label": "Stoves & Ranges-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33522004",
        "yellow_page_code": "81920",
        "kiosk": false
    },
    {
        "value": "363198",
        "label": "Household Cooking Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33522002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "363201",
        "label": "Refrigerators-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33522006",
        "yellow_page_code": "71436",
        "kiosk": false
    },
    {
        "value": "363298",
        "label": "Household Home & Farm Refrig (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33522005",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "363301",
        "label": "Household Laundry Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33522007",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "363401",
        "label": "Sauna Equipment & Supplies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341408",
        "yellow_page_code": "74011",
        "kiosk": false
    },
    {
        "value": "363402",
        "label": "Ventilating Systems-Plastic-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33521009",
        "yellow_page_code": "90807",
        "kiosk": false
    },
    {
        "value": "363403",
        "label": "Can Openers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33521001",
        "yellow_page_code": "12533",
        "kiosk": false
    },
    {
        "value": "363404",
        "label": "Electric Appliances-Small-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33521002",
        "yellow_page_code": "27825",
        "kiosk": false
    },
    {
        "value": "363405",
        "label": "Fans-Household-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33521005",
        "yellow_page_code": "30714",
        "kiosk": false
    },
    {
        "value": "363406",
        "label": "Food Mixers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33521003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "363407",
        "label": "Hair Dryers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33521008",
        "yellow_page_code": "39601",
        "kiosk": false
    },
    {
        "value": "363408",
        "label": "Food Processors-Household (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33521007",
        "yellow_page_code": "33822",
        "kiosk": false
    },
    {
        "value": "363410",
        "label": "Water Jet Cutting (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33521010",
        "yellow_page_code": "92303, 23010",
        "kiosk": false
    },
    {
        "value": "363498",
        "label": "Electric Housewares & Fans (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33521004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "363501",
        "label": "Household Vacuum Cleaners (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33521011",
        "yellow_page_code": "90312",
        "kiosk": false
    },
    {
        "value": "363901",
        "label": "Appliances-Household-Major-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33522008",
        "yellow_page_code": "02913",
        "kiosk": false
    },
    {
        "value": "363903",
        "label": "Compactors (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33522009",
        "yellow_page_code": "20112, 20130",
        "kiosk": false
    },
    {
        "value": "363904",
        "label": "Dishwashing Machines-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33522010",
        "yellow_page_code": "24936",
        "kiosk": false
    },
    {
        "value": "363905",
        "label": "Garbage Disposal Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33522011",
        "yellow_page_code": "36206",
        "kiosk": false
    },
    {
        "value": "363906",
        "label": "Sewing Machines Parts & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33522013",
        "yellow_page_code": "76001",
        "kiosk": false
    },
    {
        "value": "363907",
        "label": "Sewing Machines-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33522014",
        "yellow_page_code": "75911",
        "kiosk": false
    },
    {
        "value": "363908",
        "label": "Water Heaters-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33522016",
        "yellow_page_code": "92217",
        "kiosk": false
    },
    {
        "value": "363911",
        "label": "Soda Fountains-Residential (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33522015",
        "yellow_page_code": "79103",
        "kiosk": false
    },
    {
        "value": "363998",
        "label": "Household Appliances NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33522012",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "364101",
        "label": "Lamp Bulbs-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33513901",
        "yellow_page_code": "45606, 47234",
        "kiosk": false
    },
    {
        "value": "364102",
        "label": "Lighting Fixtures-Supplies & Parts-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33513903",
        "yellow_page_code": "47401",
        "kiosk": false
    },
    {
        "value": "364103",
        "label": "Electric Lamp Bulbs & Tubes-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33513905",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "364301",
        "label": "Connectors-Electric-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33593102",
        "yellow_page_code": "20921",
        "kiosk": false
    },
    {
        "value": "364302",
        "label": "Lightning Protection Equipment-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33593106",
        "yellow_page_code": "47414",
        "kiosk": false
    },
    {
        "value": "364303",
        "label": "Contacts & Precious Metals (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33593104",
        "yellow_page_code": "20930",
        "kiosk": false
    },
    {
        "value": "364304",
        "label": "Contact Points (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33593103",
        "yellow_page_code": "21107",
        "kiosk": false
    },
    {
        "value": "364305",
        "label": "Lugs & Terminals-Electrical (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33593107",
        "yellow_page_code": "48807",
        "kiosk": false
    },
    {
        "value": "364306",
        "label": "Anodes-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33593101",
        "yellow_page_code": "02695",
        "kiosk": false
    },
    {
        "value": "364307",
        "label": "Terminals-Phosphate (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33593108",
        "yellow_page_code": "85112",
        "kiosk": false
    },
    {
        "value": "364308",
        "label": "Electrical Contacts Rebuilding (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33593109",
        "yellow_page_code": "27917",
        "kiosk": false
    },
    {
        "value": "364398",
        "label": "Current-Carrying Wiring Devices (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33593105",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "364401",
        "label": "Fittings-Industrial (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33593201",
        "yellow_page_code": "32711, 73825",
        "kiosk": false
    },
    {
        "value": "364498",
        "label": "Noncurrent-Carrying Wiring Devices (Mfr)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33593202",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "364501",
        "label": "Lighting Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33513103",
        "yellow_page_code": "47307",
        "kiosk": false
    },
    {
        "value": "364502",
        "label": "Sidewalk-Doors & Lights (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33513105",
        "yellow_page_code": "77417, 59809",
        "kiosk": false
    },
    {
        "value": "364503",
        "label": "Lamps-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33513102",
        "yellow_page_code": "45711",
        "kiosk": false
    },
    {
        "value": "364504",
        "label": "Lighting Fixtures-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33513104",
        "yellow_page_code": "47311",
        "kiosk": false
    },
    {
        "value": "364505",
        "label": "Residential Electric Lighting Fixture (Mfrs) ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33513107",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "364698",
        "label": "Coml Ind Instnl Elec Lighting (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33513201",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "364798",
        "label": "Vehicular Lighting Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33632004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "364801",
        "label": "Gas Lights-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33513909",
        "yellow_page_code": "36509",
        "kiosk": false
    },
    {
        "value": "364802",
        "label": "Theatrical/Stage Lighting-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33513913",
        "yellow_page_code": "85513",
        "kiosk": false
    },
    {
        "value": "364803",
        "label": "Flashers-Electric (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33513906",
        "yellow_page_code": "32902",
        "kiosk": false
    },
    {
        "value": "364804",
        "label": "Flashlights (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33513907",
        "yellow_page_code": "32904",
        "kiosk": false
    },
    {
        "value": "364805",
        "label": "Stage Lighting Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33513912",
        "yellow_page_code": "80409, 81731",
        "kiosk": false
    },
    {
        "value": "364806",
        "label": "Carbide Lights (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33513902",
        "yellow_page_code": "13202",
        "kiosk": false
    },
    {
        "value": "364807",
        "label": "Lamp Bulb Holders (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33513910",
        "yellow_page_code": "45701",
        "kiosk": false
    },
    {
        "value": "364898",
        "label": "Lighting Equipment NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33513911",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "365101",
        "label": "Recorders-Sound/Video Equip/Supls (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33431008",
        "yellow_page_code": "70911, 70834, 70913",
        "kiosk": false
    },
    {
        "value": "365102",
        "label": "Loud Speakers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33431004",
        "yellow_page_code": "48801",
        "kiosk": false
    },
    {
        "value": "365103",
        "label": "Sound Systems & Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33431009",
        "yellow_page_code": "79505",
        "kiosk": false
    },
    {
        "value": "365104",
        "label": "Automobile Radio & Stereo Systems-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33431002",
        "yellow_page_code": "05114",
        "kiosk": false
    },
    {
        "value": "365105",
        "label": "Audio Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33431006",
        "yellow_page_code": "04325",
        "kiosk": false
    },
    {
        "value": "365106",
        "label": "Radio Supplies & Parts-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33431007",
        "yellow_page_code": "69914",
        "kiosk": false
    },
    {
        "value": "365107",
        "label": "Speakers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33431010",
        "yellow_page_code": "79707",
        "kiosk": false
    },
    {
        "value": "365108",
        "label": "Stereophonic & High Fidelity Equip-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33431011",
        "yellow_page_code": "81236",
        "kiosk": false
    },
    {
        "value": "365109",
        "label": "Tape Recorders/Players-Sound-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33431012",
        "yellow_page_code": "83736",
        "kiosk": false
    },
    {
        "value": "365110",
        "label": "Television & Radio-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33431013",
        "yellow_page_code": "84605",
        "kiosk": false
    },
    {
        "value": "365111",
        "label": "Television-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33431014",
        "yellow_page_code": "84911",
        "kiosk": false
    },
    {
        "value": "365112",
        "label": "Video Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33431015",
        "yellow_page_code": "91017",
        "kiosk": false
    },
    {
        "value": "365113",
        "label": "Video Recorders & Players-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33431016",
        "yellow_page_code": "91020",
        "kiosk": false
    },
    {
        "value": "365114",
        "label": "Phonograph Supplies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33431005",
        "yellow_page_code": "60713",
        "kiosk": false
    },
    {
        "value": "365115",
        "label": "Amplifiers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33431001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "365116",
        "label": "Television Broadband Digital (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33431017",
        "yellow_page_code": "85022",
        "kiosk": false
    },
    {
        "value": "365117",
        "label": "Audio-Visual Equipment Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33431018",
        "yellow_page_code": "04327",
        "kiosk": false
    },
    {
        "value": "365198",
        "label": "Household Audio/Video Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33431003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "365201",
        "label": "Records-Phonograph-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33461010",
        "yellow_page_code": "70935",
        "kiosk": false
    },
    {
        "value": "365202",
        "label": "Video Games-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51225002",
        "yellow_page_code": "91018, 91149",
        "kiosk": false
    },
    {
        "value": "365203",
        "label": "Video Tapes & Discs-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51225003",
        "yellow_page_code": "91021",
        "kiosk": false
    },
    {
        "value": "365204",
        "label": "Cd's Records Tapes-Mfrs Equip & Supls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33461009",
        "yellow_page_code": "14229",
        "kiosk": false
    },
    {
        "value": "365205",
        "label": "Publishers-Audio (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33461008",
        "yellow_page_code": "69013",
        "kiosk": false
    },
    {
        "value": "365206",
        "label": "Compact Discs-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33461006",
        "yellow_page_code": "20143",
        "kiosk": false
    },
    {
        "value": "365207",
        "label": "Cassettes-Manufacturing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51225004",
        "yellow_page_code": "13990",
        "kiosk": false
    },
    {
        "value": "365298",
        "label": "Phonograph Record/Prerecorded Tape (Mfr)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33461007",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "366101",
        "label": "Telephone Auto Answer Equipment-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33421002",
        "yellow_page_code": "84314",
        "kiosk": false
    },
    {
        "value": "366102",
        "label": "Telephone Equipment & Systems-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33421004",
        "yellow_page_code": "84424",
        "kiosk": false
    },
    {
        "value": "366103",
        "label": "Telephone Booths (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33421003",
        "yellow_page_code": "84312",
        "kiosk": false
    },
    {
        "value": "366104",
        "label": "Modems & Set-Tops (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33421005",
        "yellow_page_code": "53319",
        "kiosk": false
    },
    {
        "value": "366198",
        "label": "Telephone & Telegraph Apparatus (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33421001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "366301",
        "label": "Cable Television Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33422003",
        "yellow_page_code": "12116",
        "kiosk": false
    },
    {
        "value": "366302",
        "label": "Communication Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33422004",
        "yellow_page_code": "19913",
        "kiosk": false
    },
    {
        "value": "366303",
        "label": "Marine Electronics Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33422005",
        "yellow_page_code": "50120",
        "kiosk": false
    },
    {
        "value": "366304",
        "label": "Radio Equipment & Parts-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33422006",
        "yellow_page_code": "69808",
        "kiosk": false
    },
    {
        "value": "366305",
        "label": "Satellite Equipment & Systems-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33422011",
        "yellow_page_code": "73915",
        "kiosk": false
    },
    {
        "value": "366306",
        "label": "Television Station Equipment-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33422014",
        "yellow_page_code": "84818",
        "kiosk": false
    },
    {
        "value": "366307",
        "label": "Radios-Citizens Band-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33422009",
        "yellow_page_code": "70012",
        "kiosk": false
    },
    {
        "value": "366308",
        "label": "Television-Cable & Catv-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33422015",
        "yellow_page_code": "84821",
        "kiosk": false
    },
    {
        "value": "366309",
        "label": "Television Master Antenna Systems-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33422013",
        "yellow_page_code": "84712",
        "kiosk": false
    },
    {
        "value": "366310",
        "label": "Radio Station Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33422007",
        "yellow_page_code": "69820",
        "kiosk": false
    },
    {
        "value": "366311",
        "label": "Broadcasting Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33422002",
        "yellow_page_code": "10807",
        "kiosk": false
    },
    {
        "value": "366398",
        "label": "Radio/TV Broadcasting/Comm Equip (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33422008",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "366901",
        "label": "Fire Alarm Systems-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33429003",
        "yellow_page_code": "31905",
        "kiosk": false
    },
    {
        "value": "366902",
        "label": "Security Control Equip & Systems-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33429005",
        "yellow_page_code": "75305",
        "kiosk": false
    },
    {
        "value": "366903",
        "label": "Fire Exit Devices-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33429004",
        "yellow_page_code": "32003",
        "kiosk": false
    },
    {
        "value": "366904",
        "label": "Signals-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33429006",
        "yellow_page_code": "77434",
        "kiosk": false
    },
    {
        "value": "366905",
        "label": "Traffic Signs Signals & Equip-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33429007",
        "yellow_page_code": "87817",
        "kiosk": false
    },
    {
        "value": "366906",
        "label": "Burglar Alarm Systems & Monitoring-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33429001",
        "yellow_page_code": "11520",
        "kiosk": false
    },
    {
        "value": "366998",
        "label": "Communications Equipment NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33429002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "367101",
        "label": "Cathode Ray Tubes-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33441913",
        "yellow_page_code": "14115",
        "kiosk": false
    },
    {
        "value": "367102",
        "label": "Television Picture Tubes-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33441912",
        "yellow_page_code": "84701, 84812",
        "kiosk": false
    },
    {
        "value": "367198",
        "label": "Electron Tubes (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33441911",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "367201",
        "label": "Printed & Etched Circuits-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33441203",
        "yellow_page_code": "68208, 17935, 68211, 68209, 67950",
        "kiosk": false
    },
    {
        "value": "367202",
        "label": "Circuit Boards-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33441202",
        "yellow_page_code": "17936",
        "kiosk": false
    },
    {
        "value": "367203",
        "label": "Circuit Board Assembly & Repairs (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33441201",
        "yellow_page_code": "17937",
        "kiosk": false
    },
    {
        "value": "367401",
        "label": "Semiconductor Devices (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33441302",
        "yellow_page_code": "75504, 75416, 88217",
        "kiosk": false
    },
    {
        "value": "367402",
        "label": "Thermoelectric Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33441304",
        "yellow_page_code": "85609",
        "kiosk": false
    },
    {
        "value": "367403",
        "label": "Microprocessors-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33441301",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "367404",
        "label": "Lighting Laser (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33441305",
        "yellow_page_code": "47413",
        "kiosk": false
    },
    {
        "value": "367405",
        "label": "Fuel Cells (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33441306",
        "yellow_page_code": "34807",
        "kiosk": false
    },
    {
        "value": "367498",
        "label": "Semiconductors & Related Devices (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33441303",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "367501",
        "label": "Electronic Capacitor (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33441602",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "367601",
        "label": "Resistors (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33441603",
        "yellow_page_code": "71808",
        "kiosk": false
    },
    {
        "value": "367798",
        "label": "Electronic Coil & Transformers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33441601",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "367898",
        "label": "Electronic Connectors (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33441701",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "367901",
        "label": "Electronic Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33441902",
        "yellow_page_code": "28007, 28019, 28111, 49866",
        "kiosk": false
    },
    {
        "value": "367903",
        "label": "Quartz (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33441905",
        "yellow_page_code": "69401",
        "kiosk": false
    },
    {
        "value": "367904",
        "label": "Antennas-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33422001",
        "yellow_page_code": "02635",
        "kiosk": false
    },
    {
        "value": "367905",
        "label": "Electronic Instruments-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33441903",
        "yellow_page_code": "28013",
        "kiosk": false
    },
    {
        "value": "367906",
        "label": "Electronic Power Supplies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33441904",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "367907",
        "label": "Rectifiers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33441906",
        "yellow_page_code": "71114",
        "kiosk": false
    },
    {
        "value": "367908",
        "label": "Transducers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33441909",
        "yellow_page_code": "88209",
        "kiosk": false
    },
    {
        "value": "367909",
        "label": "Satellite Dish-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33422010",
        "yellow_page_code": "73918",
        "kiosk": false
    },
    {
        "value": "367910",
        "label": "Seals-Hermetic-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33441908",
        "yellow_page_code": "75121",
        "kiosk": false
    },
    {
        "value": "367911",
        "label": "Television Antenna Systems-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33422012",
        "yellow_page_code": "84613",
        "kiosk": false
    },
    {
        "value": "367912",
        "label": "Crystal Quartz (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33441901",
        "yellow_page_code": "22711",
        "kiosk": false
    },
    {
        "value": "367913",
        "label": "Rheostats (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33441907",
        "yellow_page_code": "72101",
        "kiosk": false
    },
    {
        "value": "367914",
        "label": "Electronic Manufacturing Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33441910",
        "yellow_page_code": "28022",
        "kiosk": false
    },
    {
        "value": "367915",
        "label": "Printed Circuit-Electronic Assembly (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33441801",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "369101",
        "label": "Storage-Batteries (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33591001",
        "yellow_page_code": "07007",
        "kiosk": false
    },
    {
        "value": "369201",
        "label": "Batteries-Dry Cell-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33591002",
        "yellow_page_code": "07014",
        "kiosk": false
    },
    {
        "value": "369298",
        "label": "Primary Batteries-Dry & Wet (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33591003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "369401",
        "label": "Automobile Electric Equipment-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33632007",
        "yellow_page_code": "04820",
        "kiosk": false
    },
    {
        "value": "369402",
        "label": "Battery Charging Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33632009",
        "yellow_page_code": "07012",
        "kiosk": false
    },
    {
        "value": "369403",
        "label": "Ignition Interlock Devices (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33632012",
        "yellow_page_code": "42512",
        "kiosk": false
    },
    {
        "value": "369404",
        "label": "Alternators & Generators-Automotive-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33632005",
        "yellow_page_code": "02014",
        "kiosk": false
    },
    {
        "value": "369405",
        "label": "Alternators & Starters-Marine (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33632006",
        "yellow_page_code": "02005, 01910",
        "kiosk": false
    },
    {
        "value": "369406",
        "label": "Starters-Engine-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33632013",
        "yellow_page_code": "80612",
        "kiosk": false
    },
    {
        "value": "369498",
        "label": "Elec Equip-Internal Comb Engines (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33632010",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "369501",
        "label": "Optical Disk Equipment & Supplies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33461002",
        "yellow_page_code": "57513, 57510, 57515",
        "kiosk": false
    },
    {
        "value": "369598",
        "label": "Magnetic/Optical Recording Media (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33461001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "369901",
        "label": "Chimes & Bells (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33599901",
        "yellow_page_code": "15300, 07810",
        "kiosk": false
    },
    {
        "value": "369902",
        "label": "Electric Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33599903",
        "yellow_page_code": "27508, 27922",
        "kiosk": false
    },
    {
        "value": "369903",
        "label": "Christmas Lights & Decorations-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33513904",
        "yellow_page_code": "15613",
        "kiosk": false
    },
    {
        "value": "369904",
        "label": "Doors-Automatic (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33599902",
        "yellow_page_code": "25536, 25628, 04472",
        "kiosk": false
    },
    {
        "value": "369905",
        "label": "Electrical Products-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33599905",
        "yellow_page_code": "27912",
        "kiosk": false
    },
    {
        "value": "369906",
        "label": "Fly Ash (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33513908",
        "yellow_page_code": "33700",
        "kiosk": false
    },
    {
        "value": "369907",
        "label": "Teaching Machines & Systems (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331077",
        "yellow_page_code": "84203",
        "kiosk": false
    },
    {
        "value": "369908",
        "label": "Food Processing Equipment & Supls-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331055",
        "yellow_page_code": "33823",
        "kiosk": false
    },
    {
        "value": "369909",
        "label": "Masers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331061",
        "yellow_page_code": "50303",
        "kiosk": false
    },
    {
        "value": "369910",
        "label": "Electric Fence (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331050",
        "yellow_page_code": "27605",
        "kiosk": false
    },
    {
        "value": "369911",
        "label": "Electrostatic Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331051",
        "yellow_page_code": "28201",
        "kiosk": false
    },
    {
        "value": "369912",
        "label": "Truck Washing/Cleaning Equip/Supls-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331078",
        "yellow_page_code": "88917",
        "kiosk": false
    },
    {
        "value": "371101",
        "label": "Recreational Vehicles & Campers-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33611006",
        "yellow_page_code": "71103",
        "kiosk": false
    },
    {
        "value": "371102",
        "label": "Automobile-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33611003",
        "yellow_page_code": "04908, 47507, 47520, 13011, 13036, 19943, 30466, 49889",
        "kiosk": false
    },
    {
        "value": "371103",
        "label": "Automobile Body-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33611002",
        "yellow_page_code": "04604, 13013",
        "kiosk": false
    },
    {
        "value": "371104",
        "label": "Electric Cars-Automobile (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33611004",
        "yellow_page_code": "27403",
        "kiosk": false
    },
    {
        "value": "371105",
        "label": "Ambulances & Hearses-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33611001",
        "yellow_page_code": "02105",
        "kiosk": false
    },
    {
        "value": "371106",
        "label": "Armored Car-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33612001",
        "yellow_page_code": "03401",
        "kiosk": false
    },
    {
        "value": "371107",
        "label": "Fire Trucks-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33612003",
        "yellow_page_code": "32103",
        "kiosk": false
    },
    {
        "value": "371108",
        "label": "Buses-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33612002",
        "yellow_page_code": "11702, 18933",
        "kiosk": false
    },
    {
        "value": "371109",
        "label": "Truck-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33612004",
        "yellow_page_code": "88736",
        "kiosk": false
    },
    {
        "value": "371110",
        "label": "Towing Equipment-Automotive-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33611007",
        "yellow_page_code": "87503",
        "kiosk": false
    },
    {
        "value": "371111",
        "label": "Heavy Duty Truck (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33612005",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "371198",
        "label": "Motor Vhcls/Passenger Car Bodies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33611005",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "371301",
        "label": "Buses-Bodies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33621102",
        "yellow_page_code": "11606",
        "kiosk": false
    },
    {
        "value": "371302",
        "label": "Automobile Wreckers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33621101",
        "yellow_page_code": "05403",
        "kiosk": false
    },
    {
        "value": "371303",
        "label": "Truck Bodies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33621104",
        "yellow_page_code": "88807",
        "kiosk": false
    },
    {
        "value": "371304",
        "label": "Truck Sleepers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33621105",
        "yellow_page_code": "88905",
        "kiosk": false
    },
    {
        "value": "371398",
        "label": "Truck & Bus Bodies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33621103",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "371401",
        "label": "Automobile Parts & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33639002",
        "yellow_page_code": "05011, 05017, 04517, 13019, 13045, 13063, 30147, 19942",
        "kiosk": false
    },
    {
        "value": "371402",
        "label": "Automobile Racing/Sports Car Equip-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33632008",
        "yellow_page_code": "05015",
        "kiosk": false
    },
    {
        "value": "371403",
        "label": "Automobile Radiator-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33639003",
        "yellow_page_code": "05036, 69716",
        "kiosk": false
    },
    {
        "value": "371404",
        "label": "Automobile Repair/Svc-Equip & Supls-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33639004",
        "yellow_page_code": "05320",
        "kiosk": false
    },
    {
        "value": "371405",
        "label": "Cylinder Heads-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33634003",
        "yellow_page_code": "23107",
        "kiosk": false
    },
    {
        "value": "371406",
        "label": "Fuel Injection Equipment & Service-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33632011",
        "yellow_page_code": "34815",
        "kiosk": false
    },
    {
        "value": "371407",
        "label": "Mufflers & Exhaust Systems-Engine-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33639015",
        "yellow_page_code": "54513, 30024",
        "kiosk": false
    },
    {
        "value": "371408",
        "label": "Wheel Well Manufacturing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33639017",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "371409",
        "label": "Shock Absorbers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33633002",
        "yellow_page_code": "76611",
        "kiosk": false
    },
    {
        "value": "371410",
        "label": "Truck Equipment & Parts-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33639018",
        "yellow_page_code": "88805",
        "kiosk": false
    },
    {
        "value": "371411",
        "label": "Brakes-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33634002",
        "yellow_page_code": "10236",
        "kiosk": false
    },
    {
        "value": "371412",
        "label": "Filters-Fuel & Oil-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33639012",
        "yellow_page_code": "31722",
        "kiosk": false
    },
    {
        "value": "371413",
        "label": "Transmissions-Parts & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33635002",
        "yellow_page_code": "88233",
        "kiosk": false
    },
    {
        "value": "371414",
        "label": "Transmissions-Automobile-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33635001",
        "yellow_page_code": "88328",
        "kiosk": false
    },
    {
        "value": "371415",
        "label": "Floats-Mechanical (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33639013",
        "yellow_page_code": "33005",
        "kiosk": false
    },
    {
        "value": "371416",
        "label": "Automobile Bumpers Guards & Grills-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33639001",
        "yellow_page_code": "04635",
        "kiosk": false
    },
    {
        "value": "371417",
        "label": "Automobile-Manufacturers Equip/Supl-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33639006",
        "yellow_page_code": "04922",
        "kiosk": false
    },
    {
        "value": "371418",
        "label": "Boat Trailer Tires & Wheels (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33639005",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "371419",
        "label": "Axles-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33639007",
        "yellow_page_code": "05490",
        "kiosk": false
    },
    {
        "value": "371420",
        "label": "Bearings-Rebabbitting (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33639008",
        "yellow_page_code": "07115",
        "kiosk": false
    },
    {
        "value": "371422",
        "label": "Clutches-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33639009",
        "yellow_page_code": "18813",
        "kiosk": false
    },
    {
        "value": "371423",
        "label": "Joints-Ball (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33639014",
        "yellow_page_code": "44509",
        "kiosk": false
    },
    {
        "value": "371424",
        "label": "Engines-Supplies-Equipment & Parts-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33639011",
        "yellow_page_code": "29324, 28640",
        "kiosk": false
    },
    {
        "value": "371425",
        "label": "Automobile Power Steering (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33633001",
        "yellow_page_code": "05027",
        "kiosk": false
    },
    {
        "value": "371426",
        "label": "Drive Shafts-Industrial (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33639010",
        "yellow_page_code": "26501",
        "kiosk": false
    },
    {
        "value": "371427",
        "label": "Brake Service Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33634001",
        "yellow_page_code": "10212",
        "kiosk": false
    },
    {
        "value": "371428",
        "label": "Radiators-Heating (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33639016",
        "yellow_page_code": "69508",
        "kiosk": false
    },
    {
        "value": "371429",
        "label": "Steering Systems & Equipment-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33633003",
        "yellow_page_code": "81200",
        "kiosk": false
    },
    {
        "value": "371430",
        "label": "Windshield Wipers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33639019",
        "yellow_page_code": "93714",
        "kiosk": false
    },
    {
        "value": "371501",
        "label": "Trailers-Industrial-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33621201",
        "yellow_page_code": "88015, 88014",
        "kiosk": false
    },
    {
        "value": "371502",
        "label": "Trailers-Truck-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33621202",
        "yellow_page_code": "88115",
        "kiosk": false
    },
    {
        "value": "371601",
        "label": "Motor Homes-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33621301",
        "yellow_page_code": "54202",
        "kiosk": false
    },
    {
        "value": "371602",
        "label": "Van Conversions-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33621302",
        "yellow_page_code": "90534",
        "kiosk": false
    },
    {
        "value": "372101",
        "label": "Aircraft-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33641103",
        "yellow_page_code": "01506",
        "kiosk": false
    },
    {
        "value": "372102",
        "label": "Aircraft Interior Redesigning (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33641102",
        "yellow_page_code": "01412",
        "kiosk": false
    },
    {
        "value": "372103",
        "label": "Aircraft/Aircraft Equip Testing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33641104",
        "yellow_page_code": "01306",
        "kiosk": false
    },
    {
        "value": "372104",
        "label": "Balloons-Hot Air Whls & Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33641105",
        "yellow_page_code": "06141",
        "kiosk": false
    },
    {
        "value": "372105",
        "label": "Helicopter-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33641107",
        "yellow_page_code": "40922",
        "kiosk": false
    },
    {
        "value": "372106",
        "label": "Gliders (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33641106",
        "yellow_page_code": "37806",
        "kiosk": false
    },
    {
        "value": "372107",
        "label": "Aircraft Designing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33641101",
        "yellow_page_code": "01405",
        "kiosk": false
    },
    {
        "value": "372108",
        "label": "Hovercraft (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33641108",
        "yellow_page_code": "42016",
        "kiosk": false
    },
    {
        "value": "372401",
        "label": "Aircraft Engines-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33641202",
        "yellow_page_code": "01417",
        "kiosk": false
    },
    {
        "value": "372402",
        "label": "Aircraft Tooling (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33641203",
        "yellow_page_code": "01605",
        "kiosk": false
    },
    {
        "value": "372403",
        "label": "Jet Propulsion Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33641204",
        "yellow_page_code": "44205",
        "kiosk": false
    },
    {
        "value": "372498",
        "label": "Aircraft Engines & Engine Parts-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33641201",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "372801",
        "label": "Aircraft Components-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33641301",
        "yellow_page_code": "01403",
        "kiosk": false
    },
    {
        "value": "372802",
        "label": "Aircraft Equipment Parts & Supls-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33641302",
        "yellow_page_code": "01409",
        "kiosk": false
    },
    {
        "value": "372803",
        "label": "Aircraft Machine Work (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33641306",
        "yellow_page_code": "01505",
        "kiosk": false
    },
    {
        "value": "372804",
        "label": "Aircraft Ground Support & Svc Equip-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33641304",
        "yellow_page_code": "01415",
        "kiosk": false
    },
    {
        "value": "372805",
        "label": "Aircraft Modifications (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33641307",
        "yellow_page_code": "01503",
        "kiosk": false
    },
    {
        "value": "372806",
        "label": "Aircraft Parts-Assemblers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33641309",
        "yellow_page_code": "01507",
        "kiosk": false
    },
    {
        "value": "372807",
        "label": "Aircraft Flight Training Equip (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33641303",
        "yellow_page_code": "01501",
        "kiosk": false
    },
    {
        "value": "372808",
        "label": "Aircraft Oxygen Systems (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33641308",
        "yellow_page_code": "01512",
        "kiosk": false
    },
    {
        "value": "372809",
        "label": "Aircraft Research & Development (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33641310",
        "yellow_page_code": "01611",
        "kiosk": false
    },
    {
        "value": "372810",
        "label": "Helicopter-Equip & Parts & Supls (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33641311",
        "yellow_page_code": "41001",
        "kiosk": false
    },
    {
        "value": "372811",
        "label": "Propellers (Airplane-Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33641313",
        "yellow_page_code": "01508, 68621",
        "kiosk": false
    },
    {
        "value": "372812",
        "label": "Refueling Systems (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33641315",
        "yellow_page_code": "71522",
        "kiosk": false
    },
    {
        "value": "372813",
        "label": "Pilots Equipment & Supplies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33641312",
        "yellow_page_code": "65285",
        "kiosk": false
    },
    {
        "value": "372814",
        "label": "Aircraft Industrial Fasteners (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33641305",
        "yellow_page_code": "01419",
        "kiosk": false
    },
    {
        "value": "372815",
        "label": "Cockpits (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33641314",
        "yellow_page_code": "19201",
        "kiosk": false
    },
    {
        "value": "373101",
        "label": "Ship Builders & Repairers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33661102",
        "yellow_page_code": "76501, 76603, 76415, 30067",
        "kiosk": false
    },
    {
        "value": "373102",
        "label": "Barges (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33661101",
        "yellow_page_code": "06604, 06612",
        "kiosk": false
    },
    {
        "value": "373103",
        "label": "Ship Ceiling (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33661103",
        "yellow_page_code": "76411",
        "kiosk": false
    },
    {
        "value": "373104",
        "label": "Ship Yards (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33661104",
        "yellow_page_code": "76414",
        "kiosk": false
    },
    {
        "value": "373201",
        "label": "Boats-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33661201",
        "yellow_page_code": "08700, 08710, 08810",
        "kiosk": false
    },
    {
        "value": "373202",
        "label": "Boat Equipment & Supplies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33661202",
        "yellow_page_code": "08711",
        "kiosk": false
    },
    {
        "value": "373203",
        "label": "Canoe-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33661203",
        "yellow_page_code": "12835",
        "kiosk": false
    },
    {
        "value": "373204",
        "label": "Marine Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33661205",
        "yellow_page_code": "50031, 49879",
        "kiosk": false
    },
    {
        "value": "373205",
        "label": "Houseboats (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33661204",
        "yellow_page_code": "42014",
        "kiosk": false
    },
    {
        "value": "373206",
        "label": "Yachts-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33661206",
        "yellow_page_code": "94617",
        "kiosk": false
    },
    {
        "value": "373207",
        "label": "Yachts-Repairing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149063",
        "yellow_page_code": "94709",
        "kiosk": false
    },
    {
        "value": "373208",
        "label": "Watercraft-Personal-Svc-Repair (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33661207",
        "yellow_page_code": "92110, 44134, 44130, 60324",
        "kiosk": false
    },
    {
        "value": "373209",
        "label": "Windsurfing Whls & Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33661208",
        "yellow_page_code": "93722",
        "kiosk": false
    },
    {
        "value": "373298",
        "label": "Boat Part-Used & Rebuilt (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149004",
        "yellow_page_code": "08633, 08818",
        "kiosk": false
    },
    {
        "value": "374301",
        "label": "Railroad Cars-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33651003",
        "yellow_page_code": "70208",
        "kiosk": false
    },
    {
        "value": "374302",
        "label": "Tracked Vehicles-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33651005",
        "yellow_page_code": "87612",
        "kiosk": false
    },
    {
        "value": "374303",
        "label": "Locomotives (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33651002",
        "yellow_page_code": "48507",
        "kiosk": false
    },
    {
        "value": "374304",
        "label": "Railroad Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33651004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "374305",
        "label": "Dining Car Builders (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33651001",
        "yellow_page_code": "24816",
        "kiosk": false
    },
    {
        "value": "374306",
        "label": "Locomotive Rebuilding (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33651006",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "374307",
        "label": "Railroad Rolling Stock (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33651007",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "375101",
        "label": "Cycle Accessory Mfrs & Wholesalers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33699102",
        "yellow_page_code": "23130",
        "kiosk": false
    },
    {
        "value": "375102",
        "label": "Bicycle Fabricators (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33699101",
        "yellow_page_code": "07914",
        "kiosk": false
    },
    {
        "value": "375103",
        "label": "Motorcycles-Supls & Parts-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33699105",
        "yellow_page_code": "54235",
        "kiosk": false
    },
    {
        "value": "375104",
        "label": "Motorcycles & Motor Scooters-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33699104",
        "yellow_page_code": "54317",
        "kiosk": false
    },
    {
        "value": "375105",
        "label": "Motorcycles-Custom Built (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33699106",
        "yellow_page_code": "54213",
        "kiosk": false
    },
    {
        "value": "375198",
        "label": "Motorcycle-Bicycle & Parts (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33699103",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "376101",
        "label": "Missile & Rocket-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33641402",
        "yellow_page_code": "52905",
        "kiosk": false
    },
    {
        "value": "376102",
        "label": "Space Research & Development (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33641403",
        "yellow_page_code": "79603",
        "kiosk": false
    },
    {
        "value": "376198",
        "label": "Guided Missile & Space Vehicle (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33641401",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "376401",
        "label": "Missile & Rocket Components-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33641502",
        "yellow_page_code": "52902",
        "kiosk": false
    },
    {
        "value": "376498",
        "label": "Guided Mssle/Space Vhcl-Prop Units (Mfr)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33641501",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "376901",
        "label": "Missile & Rocket Research & Dev (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33641902",
        "yellow_page_code": "52906",
        "kiosk": false
    },
    {
        "value": "376902",
        "label": "Space Components & Systems (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33641903",
        "yellow_page_code": "79605",
        "kiosk": false
    },
    {
        "value": "376998",
        "label": "Guided Missile/Spc Vhcl-Parts NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33641901",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "379201",
        "label": "Trailer-Manufacturers & Designers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33621404",
        "yellow_page_code": "87809, 88099",
        "kiosk": false
    },
    {
        "value": "379202",
        "label": "Trailers-Camping & Travel-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33621407",
        "yellow_page_code": "88001",
        "kiosk": false
    },
    {
        "value": "379203",
        "label": "Truck Caps & Shells-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33621411",
        "yellow_page_code": "88617",
        "kiosk": false
    },
    {
        "value": "379204",
        "label": "Campers & Pick-Up Coaches-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33621401",
        "yellow_page_code": "12503",
        "kiosk": false
    },
    {
        "value": "379598",
        "label": "Tanks & Tank Components (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33699201",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "379901",
        "label": "Manufacturers-Trailers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33621402",
        "yellow_page_code": "49933",
        "kiosk": false
    },
    {
        "value": "379902",
        "label": "Manufacturers-Snowmobiles",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33699906",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "379903",
        "label": "Recreational Vehicles-Equip & Parts-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33699904",
        "yellow_page_code": "71020",
        "kiosk": false
    },
    {
        "value": "379904",
        "label": "Snowmobile-Equipment/Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33699905",
        "yellow_page_code": "78804",
        "kiosk": false
    },
    {
        "value": "379905",
        "label": "Trailer Hitches-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33621403",
        "yellow_page_code": "87835",
        "kiosk": false
    },
    {
        "value": "379906",
        "label": "Trailers-Automobile Utility-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33621405",
        "yellow_page_code": "88003",
        "kiosk": false
    },
    {
        "value": "379907",
        "label": "Trailers-Boat-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33621406",
        "yellow_page_code": "87909",
        "kiosk": false
    },
    {
        "value": "379908",
        "label": "Trailers-Equipment & Parts-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33621408",
        "yellow_page_code": "88017",
        "kiosk": false
    },
    {
        "value": "379909",
        "label": "All Terrain Vehicles-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33699901",
        "yellow_page_code": "01904",
        "kiosk": false
    },
    {
        "value": "379910",
        "label": "Golf Cars & Carts-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33699902",
        "yellow_page_code": "38105",
        "kiosk": false
    },
    {
        "value": "379911",
        "label": "Karts-Motorized-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33699903",
        "yellow_page_code": "44703",
        "kiosk": false
    },
    {
        "value": "379912",
        "label": "Trailers-Horse-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33621409",
        "yellow_page_code": "88022",
        "kiosk": false
    },
    {
        "value": "379913",
        "label": "Trailers-Motorcycles (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33621410",
        "yellow_page_code": "88104, 54232",
        "kiosk": false
    },
    {
        "value": "379914",
        "label": "Trailers-Office Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33699908",
        "yellow_page_code": "88130",
        "kiosk": false
    },
    {
        "value": "379915",
        "label": "Wheels & Castors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33699909",
        "yellow_page_code": "93332, 93333",
        "kiosk": false
    },
    {
        "value": "379916",
        "label": "Trailers Cargo",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33699910",
        "yellow_page_code": "87915",
        "kiosk": false
    },
    {
        "value": "379998",
        "label": "Transport Equipment-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33699907",
        "yellow_page_code": "88333",
        "kiosk": false
    },
    {
        "value": "381201",
        "label": "Aerospace Industries (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451103",
        "yellow_page_code": "00802, 00724",
        "kiosk": false
    },
    {
        "value": "381204",
        "label": "Marine Instruments-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451112",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "381205",
        "label": "Radar-Manufacturers & Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451104",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "381206",
        "label": "Gyroscopes (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451101",
        "yellow_page_code": "39600",
        "kiosk": false
    },
    {
        "value": "381207",
        "label": "Aircraft Radio Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451105",
        "yellow_page_code": "01617",
        "kiosk": false
    },
    {
        "value": "381208",
        "label": "Radio Direction Finders (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451102",
        "yellow_page_code": "69712",
        "kiosk": false
    },
    {
        "value": "381210",
        "label": "Missile/Rocket Instr/Guidance Eqpt (Mfr)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451106",
        "yellow_page_code": "52901",
        "kiosk": false
    },
    {
        "value": "381211",
        "label": "Missile & Rocket Propulsion Equip (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451107",
        "yellow_page_code": "52903",
        "kiosk": false
    },
    {
        "value": "381212",
        "label": "Missile/Rocket Grnd Support Equip (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451108",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "381213",
        "label": "Simulators & Training Devices-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451109",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "381214",
        "label": "Gps Navigation Systems & Services (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451110",
        "yellow_page_code": "38503, 55211, 55212, 67971, 42797",
        "kiosk": false
    },
    {
        "value": "381215",
        "label": "Farm Equip-Global Positioning Sys (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451113",
        "yellow_page_code": "30915",
        "kiosk": false
    },
    {
        "value": "381298",
        "label": "Search Detection/Nav Systs/Instr (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451111",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "382101",
        "label": "Laboratory Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451602",
        "yellow_page_code": "45324, 03160",
        "kiosk": false
    },
    {
        "value": "382102",
        "label": "Metallurgists Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911323",
        "yellow_page_code": "51807",
        "kiosk": false
    },
    {
        "value": "382103",
        "label": "Laboratory Furniture (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451603",
        "yellow_page_code": "45312",
        "kiosk": false
    },
    {
        "value": "382201",
        "label": "Static Controls (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451205",
        "yellow_page_code": "80609, 80613",
        "kiosk": false
    },
    {
        "value": "382202",
        "label": "Energy Equipment Systems-Supplies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451204",
        "yellow_page_code": "28704, 28715, 56113",
        "kiosk": false
    },
    {
        "value": "382203",
        "label": "Thermocouples (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451207",
        "yellow_page_code": "85606",
        "kiosk": false
    },
    {
        "value": "382204",
        "label": "Controls Control Systems/Regulators-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451202",
        "yellow_page_code": "21309",
        "kiosk": false
    },
    {
        "value": "382205",
        "label": "Dampers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451203",
        "yellow_page_code": "23305, 23309",
        "kiosk": false
    },
    {
        "value": "382206",
        "label": "Cleaning Machine Mfrs-Steam",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451206",
        "yellow_page_code": "18425",
        "kiosk": false
    },
    {
        "value": "382207",
        "label": "Instruments-Air Flow (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451208",
        "yellow_page_code": "43342",
        "kiosk": false
    },
    {
        "value": "382208",
        "label": "Energy Management Control Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451209",
        "yellow_page_code": "28650",
        "kiosk": false
    },
    {
        "value": "382298",
        "label": "Automatic Contrls-Residential/Coml (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451201",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "382301",
        "label": "Annunciators-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451302",
        "yellow_page_code": "02700",
        "kiosk": false
    },
    {
        "value": "382302",
        "label": "Infrared Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451308",
        "yellow_page_code": "43008",
        "kiosk": false
    },
    {
        "value": "382303",
        "label": "Pyrometers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451312",
        "yellow_page_code": "69306",
        "kiosk": false
    },
    {
        "value": "382304",
        "label": "Combustion Controls (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451303",
        "yellow_page_code": "19908",
        "kiosk": false
    },
    {
        "value": "382305",
        "label": "Analyzers-Industrial Control-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451301",
        "yellow_page_code": "02312",
        "kiosk": false
    },
    {
        "value": "382306",
        "label": "Pneumatic Tube Equipment & Systems-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451310",
        "yellow_page_code": "66904",
        "kiosk": false
    },
    {
        "value": "382307",
        "label": "Temperature Measuring Materials (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451315",
        "yellow_page_code": "85002",
        "kiosk": false
    },
    {
        "value": "382308",
        "label": "Tank Measuring Devices (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451314",
        "yellow_page_code": "83509",
        "kiosk": false
    },
    {
        "value": "382309",
        "label": "Production Control Systems (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451311",
        "yellow_page_code": "68516, 68532",
        "kiosk": false
    },
    {
        "value": "382310",
        "label": "Dials (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451304",
        "yellow_page_code": "24409",
        "kiosk": false
    },
    {
        "value": "382311",
        "label": "Indicators-Dial (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451306",
        "yellow_page_code": "42912",
        "kiosk": false
    },
    {
        "value": "382312",
        "label": "Gas Equipment-Industrial (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451305",
        "yellow_page_code": "36609, 36798",
        "kiosk": false
    },
    {
        "value": "382313",
        "label": "Surge Protection Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451313",
        "yellow_page_code": "82318",
        "kiosk": false
    },
    {
        "value": "382314",
        "label": "Moisture Detection Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451309",
        "yellow_page_code": "53312",
        "kiosk": false
    },
    {
        "value": "382315",
        "label": "Indicators-Flow (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451316",
        "yellow_page_code": "42821",
        "kiosk": false
    },
    {
        "value": "382316",
        "label": "Industrial Process Cntrl Instrumentation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451317",
        "yellow_page_code": "42924",
        "kiosk": false
    },
    {
        "value": "382317",
        "label": "Quality Control Testing Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451318",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "382318",
        "label": "Measuring Analyzing & Controlling Instrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451319",
        "yellow_page_code": "50720, 30536",
        "kiosk": false
    },
    {
        "value": "382398",
        "label": "Industrial Measuring/Controlling Instr (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451307",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "382401",
        "label": "Electro Mechanical Systems (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451401",
        "yellow_page_code": "27822",
        "kiosk": false
    },
    {
        "value": "382402",
        "label": "Measures-Dry Liquid Etc (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451404",
        "yellow_page_code": "50701",
        "kiosk": false
    },
    {
        "value": "382403",
        "label": "Gauges-Accessories & Parts (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451403",
        "yellow_page_code": "36903",
        "kiosk": false
    },
    {
        "value": "382404",
        "label": "Gauges & Gages-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451402",
        "yellow_page_code": "36911",
        "kiosk": false
    },
    {
        "value": "382405",
        "label": "Meter Mfrs & Suppliers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451407",
        "yellow_page_code": "52020",
        "kiosk": false
    },
    {
        "value": "382498",
        "label": "Totalizing Fluid Mtrs/Cntng Device (Mfr)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451405",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "382501",
        "label": "Recording Instruments Ind/Scientfc-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451505",
        "yellow_page_code": "70835, 28110",
        "kiosk": false
    },
    {
        "value": "382502",
        "label": "Tachographs-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451506",
        "yellow_page_code": "83204",
        "kiosk": false
    },
    {
        "value": "382503",
        "label": "Calibration Devices (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451502",
        "yellow_page_code": "12365",
        "kiosk": false
    },
    {
        "value": "382504",
        "label": "Automobile Diagnostic Service Equip-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451501",
        "yellow_page_code": "04809",
        "kiosk": false
    },
    {
        "value": "382505",
        "label": "Instruments-Measuring (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451504",
        "yellow_page_code": "43314",
        "kiosk": false
    },
    {
        "value": "382506",
        "label": "Turned Parts-Precision (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451408",
        "yellow_page_code": "89504",
        "kiosk": false
    },
    {
        "value": "382507",
        "label": "Battery Tester Manufacturer & Sales",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451406",
        "yellow_page_code": "07016",
        "kiosk": false
    },
    {
        "value": "382598",
        "label": "Instrs-Measuring/Testing Elec (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451503",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "382698",
        "label": "Laboratory Analytical Instruments (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451601",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "382701",
        "label": "Magnifying Glasses (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331003",
        "yellow_page_code": "49602",
        "kiosk": false
    },
    {
        "value": "382702",
        "label": "Lenses-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331002",
        "yellow_page_code": "46907",
        "kiosk": false
    },
    {
        "value": "382703",
        "label": "Telescopes-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331005",
        "yellow_page_code": "84436",
        "kiosk": false
    },
    {
        "value": "382704",
        "label": "Binoculars-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331001",
        "yellow_page_code": "08113",
        "kiosk": false
    },
    {
        "value": "382798",
        "label": "Optical Instruments & Lenses (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "382901",
        "label": "Compasses (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451906",
        "yellow_page_code": "20204",
        "kiosk": false
    },
    {
        "value": "382902",
        "label": "Hydrometers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451909",
        "yellow_page_code": "42201",
        "kiosk": false
    },
    {
        "value": "382903",
        "label": "Acoustic/Vibration Instrumentation-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451901",
        "yellow_page_code": "00109",
        "kiosk": false
    },
    {
        "value": "382904",
        "label": "Drafting Equipment Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451907",
        "yellow_page_code": "25822",
        "kiosk": false
    },
    {
        "value": "382905",
        "label": "Hygrometers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451911",
        "yellow_page_code": "42115",
        "kiosk": false
    },
    {
        "value": "382906",
        "label": "Radioactivity Instruments Supls/Svc-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451916",
        "yellow_page_code": "69913",
        "kiosk": false
    },
    {
        "value": "382907",
        "label": "Surveying Instruments-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451921",
        "yellow_page_code": "82413",
        "kiosk": false
    },
    {
        "value": "382908",
        "label": "Testing Apparatus-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451924",
        "yellow_page_code": "85215",
        "kiosk": false
    },
    {
        "value": "382909",
        "label": "Toll Booths-Automatic-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451927",
        "yellow_page_code": "86903",
        "kiosk": false
    },
    {
        "value": "382910",
        "label": "Lie Detection Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451912",
        "yellow_page_code": "47133",
        "kiosk": false
    },
    {
        "value": "382911",
        "label": "Slide Rules-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451919",
        "yellow_page_code": "78317",
        "kiosk": false
    },
    {
        "value": "382912",
        "label": "Air Quality Measuring Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451902",
        "yellow_page_code": "01314",
        "kiosk": false
    },
    {
        "value": "382913",
        "label": "Dynamometers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451908",
        "yellow_page_code": "27006",
        "kiosk": false
    },
    {
        "value": "382914",
        "label": "Measuring Tapes & Rules (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451913",
        "yellow_page_code": "50704, 83805",
        "kiosk": false
    },
    {
        "value": "382915",
        "label": "Stress Relief Equip & Service-Ind (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451920",
        "yellow_page_code": "82004, 82016",
        "kiosk": false
    },
    {
        "value": "382916",
        "label": "Turnstiles (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451928",
        "yellow_page_code": "89508",
        "kiosk": false
    },
    {
        "value": "382917",
        "label": "Rulers & Yardsticks (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451917",
        "yellow_page_code": "73109",
        "kiosk": false
    },
    {
        "value": "382918",
        "label": "Seismograph Equipment & Supplies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451918",
        "yellow_page_code": "75412",
        "kiosk": false
    },
    {
        "value": "382919",
        "label": "Anemometers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451904",
        "yellow_page_code": "02403",
        "kiosk": false
    },
    {
        "value": "382920",
        "label": "Alcohol Breath Detectors (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451903",
        "yellow_page_code": "01806, 10409, 01815",
        "kiosk": false
    },
    {
        "value": "382921",
        "label": "Templates (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451923",
        "yellow_page_code": "85001",
        "kiosk": false
    },
    {
        "value": "382922",
        "label": "Booths-Toll & Parking Lot & Etc (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451905",
        "yellow_page_code": "09405, 30053",
        "kiosk": false
    },
    {
        "value": "382924",
        "label": "Photogrammetric Instruments (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451915",
        "yellow_page_code": "61111",
        "kiosk": false
    },
    {
        "value": "382925",
        "label": "Thermometer Wells (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451925",
        "yellow_page_code": "85705",
        "kiosk": false
    },
    {
        "value": "382926",
        "label": "Thermostatic Bimetals (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451926",
        "yellow_page_code": "85709",
        "kiosk": false
    },
    {
        "value": "382927",
        "label": "Thermometers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911208",
        "yellow_page_code": "85710",
        "kiosk": false
    },
    {
        "value": "382928",
        "label": "Synchronizers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451922",
        "yellow_page_code": "83013",
        "kiosk": false
    },
    {
        "value": "382929",
        "label": "Weather Instruments-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451910",
        "yellow_page_code": "92720, 92725",
        "kiosk": false
    },
    {
        "value": "382998",
        "label": "Measuring/Controlling Devices NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451914",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "384101",
        "label": "Surgical Instruments-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911206",
        "yellow_page_code": "82405",
        "kiosk": false
    },
    {
        "value": "384102",
        "label": "Scientific Instrument Designers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911205",
        "yellow_page_code": "74711",
        "kiosk": false
    },
    {
        "value": "384103",
        "label": "Hospital Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911201",
        "yellow_page_code": "41818, 51127, 02103, 51070, 49854",
        "kiosk": false
    },
    {
        "value": "384104",
        "label": "Physicians & Surgeons Equip & Supls-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911203",
        "yellow_page_code": "61305",
        "kiosk": false
    },
    {
        "value": "384105",
        "label": "Physicians/Surgeons Equip-Spec Designed",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911204",
        "yellow_page_code": "61213",
        "kiosk": false
    },
    {
        "value": "384106",
        "label": "Oxygen Therapy Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911202",
        "yellow_page_code": "58210",
        "kiosk": false
    },
    {
        "value": "384111",
        "label": "Veterinarians Equipment & Supls-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911209",
        "yellow_page_code": "90913",
        "kiosk": false
    },
    {
        "value": "384112",
        "label": "Medical Cleaning Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911210",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "384198",
        "label": "Surgical/Med Instruments/Apparatus (Mfr)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911207",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "384201",
        "label": "Respirators-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911314",
        "yellow_page_code": "71885",
        "kiosk": false
    },
    {
        "value": "384202",
        "label": "Abdominal Supports-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911301",
        "yellow_page_code": "00021",
        "kiosk": false
    },
    {
        "value": "384203",
        "label": "Artificial Limbs-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911303",
        "yellow_page_code": "03812",
        "kiosk": false
    },
    {
        "value": "384204",
        "label": "Hearing Aids-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451002",
        "yellow_page_code": "40536",
        "kiosk": false
    },
    {
        "value": "384205",
        "label": "Orthopedic Appliances-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911311",
        "yellow_page_code": "58012",
        "kiosk": false
    },
    {
        "value": "384206",
        "label": "Physical Therapy Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911313",
        "yellow_page_code": "61212",
        "kiosk": false
    },
    {
        "value": "384207",
        "label": "Safety Equipment & Clothing-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911315",
        "yellow_page_code": "73405",
        "kiosk": false
    },
    {
        "value": "384208",
        "label": "Shoes-Orthopedic-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911316",
        "yellow_page_code": "77001",
        "kiosk": false
    },
    {
        "value": "384209",
        "label": "Surgical Appliances-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911318",
        "yellow_page_code": "82311",
        "kiosk": false
    },
    {
        "value": "384210",
        "label": "Surgical Dressings-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911319",
        "yellow_page_code": "82312",
        "kiosk": false
    },
    {
        "value": "384211",
        "label": "First Aid Supplies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911307",
        "yellow_page_code": "32311",
        "kiosk": false
    },
    {
        "value": "384212",
        "label": "Foot Appliances-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911308",
        "yellow_page_code": "34012",
        "kiosk": false
    },
    {
        "value": "384213",
        "label": "Wheel Chairs-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911320",
        "yellow_page_code": "93316",
        "kiosk": false
    },
    {
        "value": "384214",
        "label": "Whirlpool Bath Equip & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911321",
        "yellow_page_code": "93334",
        "kiosk": false
    },
    {
        "value": "384215",
        "label": "Cotton-Absorbent (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911306",
        "yellow_page_code": "21936",
        "kiosk": false
    },
    {
        "value": "384216",
        "label": "Foot Remedies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911309",
        "yellow_page_code": "34008",
        "kiosk": false
    },
    {
        "value": "384217",
        "label": "Collars (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911304",
        "yellow_page_code": "19615",
        "kiosk": false
    },
    {
        "value": "384218",
        "label": "Colonic Therapy-Apparatus & Supls (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911305",
        "yellow_page_code": "19709",
        "kiosk": false
    },
    {
        "value": "384219",
        "label": "Masks-Protective (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911310",
        "yellow_page_code": "50301",
        "kiosk": false
    },
    {
        "value": "384220",
        "label": "Absorbents-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911302",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "384221",
        "label": "Sterilizing Apparatus-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911317",
        "yellow_page_code": "81309",
        "kiosk": false
    },
    {
        "value": "384222",
        "label": "Health Care Apparel (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911322",
        "yellow_page_code": "40445",
        "kiosk": false
    },
    {
        "value": "384223",
        "label": "Handicapped Equip/Supplies Mfr",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999933",
        "yellow_page_code": "40018",
        "kiosk": false
    },
    {
        "value": "384298",
        "label": "Orthopedic Prosthetic/Srgcl Appl (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911312",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "384301",
        "label": "Dental Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911402",
        "yellow_page_code": "23809",
        "kiosk": false
    },
    {
        "value": "384302",
        "label": "Dentifrices (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911403",
        "yellow_page_code": "23909",
        "kiosk": false
    },
    {
        "value": "384398",
        "label": "Dental Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911401",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "384401",
        "label": "X-Ray Apparatus & Supplies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451702",
        "yellow_page_code": "94616",
        "kiosk": false
    },
    {
        "value": "384498",
        "label": "X-Ray Apparatus & Tubes (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451703",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "384501",
        "label": "Shielding-Magnetic Electrostatic (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451701",
        "yellow_page_code": "76308",
        "kiosk": false
    },
    {
        "value": "384502",
        "label": "Laser Medical (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451003",
        "yellow_page_code": "46036",
        "kiosk": false
    },
    {
        "value": "384503",
        "label": "Pacemakers-Cardiac (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451004",
        "yellow_page_code": "58303",
        "kiosk": false
    },
    {
        "value": "384504",
        "label": "Electroencephalogram (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451005",
        "yellow_page_code": "27914",
        "kiosk": false
    },
    {
        "value": "384505",
        "label": "Automatic External Defibrillator Inf-Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451006",
        "yellow_page_code": "04532",
        "kiosk": false
    },
    {
        "value": "384598",
        "label": "Electromedical/Therapeutic Apprts (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "385101",
        "label": "Contact Lenses-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911502",
        "yellow_page_code": "21029",
        "kiosk": false
    },
    {
        "value": "385102",
        "label": "Sunglasses & Sun Goggles-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911509",
        "yellow_page_code": "82216",
        "kiosk": false
    },
    {
        "value": "385103",
        "label": "Optical Goods-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911505",
        "yellow_page_code": "57607",
        "kiosk": false
    },
    {
        "value": "385104",
        "label": "Optical Laboratories-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911506",
        "yellow_page_code": "57608",
        "kiosk": false
    },
    {
        "value": "385105",
        "label": "Optical Systems-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911507",
        "yellow_page_code": "57609",
        "kiosk": false
    },
    {
        "value": "385106",
        "label": "Opticians Products-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911508",
        "yellow_page_code": "57703",
        "kiosk": false
    },
    {
        "value": "385107",
        "label": "Artificial Eyes-Human (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911501",
        "yellow_page_code": "03702",
        "kiosk": false
    },
    {
        "value": "385108",
        "label": "Goggles-Industrial (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911503",
        "yellow_page_code": "37909",
        "kiosk": false
    },
    {
        "value": "385198",
        "label": "Ophthalmic Goods-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911504",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "386101",
        "label": "Blueprinting Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331007",
        "yellow_page_code": "08535",
        "kiosk": false
    },
    {
        "value": "386102",
        "label": "Microfilming Service Equip & Supls-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331008",
        "yellow_page_code": "52135",
        "kiosk": false
    },
    {
        "value": "386103",
        "label": "Photographic Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331011",
        "yellow_page_code": "61021",
        "kiosk": false
    },
    {
        "value": "386104",
        "label": "Photographic Mounts-Wholesale & Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331012",
        "yellow_page_code": "61136",
        "kiosk": false
    },
    {
        "value": "386105",
        "label": "Motion Picture Equip & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331009",
        "yellow_page_code": "53905",
        "kiosk": false
    },
    {
        "value": "386106",
        "label": "Stereopticons (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331013",
        "yellow_page_code": "81308",
        "kiosk": false
    },
    {
        "value": "386107",
        "label": "Projection Screens-Mfrs & Suppliers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331014",
        "yellow_page_code": "68590",
        "kiosk": false
    },
    {
        "value": "386108",
        "label": "Photographic Film Paper Plate Chemical & Copy Toner (Mfrs) ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32599201",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "386198",
        "label": "Photographic Equip & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33331010",
        "yellow_page_code": "60819",
        "kiosk": false
    },
    {
        "value": "387301",
        "label": "Clocks-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451930",
        "yellow_page_code": "18607",
        "kiosk": false
    },
    {
        "value": "387302",
        "label": "Watchmakers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451940",
        "yellow_page_code": "92011",
        "kiosk": false
    },
    {
        "value": "387303",
        "label": "Timing Devices-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451935",
        "yellow_page_code": "86210",
        "kiosk": false
    },
    {
        "value": "387304",
        "label": "Watch Cases (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451936",
        "yellow_page_code": "91907",
        "kiosk": false
    },
    {
        "value": "387305",
        "label": "Watch Dials (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451937",
        "yellow_page_code": "91908",
        "kiosk": false
    },
    {
        "value": "387306",
        "label": "Watch Straps & Bands-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451938",
        "yellow_page_code": "91911",
        "kiosk": false
    },
    {
        "value": "387398",
        "label": "Watches Clcks Clockwork Etc/Parts (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451939",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "391101",
        "label": "Jewelry-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33991006",
        "yellow_page_code": "44404, 44221, 44291, 44222, 44415, 30484, 44315, 44317, 44318",
        "kiosk": false
    },
    {
        "value": "391102",
        "label": "Jewelry Castings-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33991004",
        "yellow_page_code": "44303",
        "kiosk": false
    },
    {
        "value": "391103",
        "label": "Jewelry Mountings (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33991005",
        "yellow_page_code": "44405",
        "kiosk": false
    },
    {
        "value": "391104",
        "label": "Medals-Designers & Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33991008",
        "yellow_page_code": "50904",
        "kiosk": false
    },
    {
        "value": "391105",
        "label": "Gold Nugget Jewelry-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33991002",
        "yellow_page_code": "37934",
        "kiosk": false
    },
    {
        "value": "391106",
        "label": "Native American Goods-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33991003",
        "yellow_page_code": "42815",
        "kiosk": false
    },
    {
        "value": "391107",
        "label": "Class Rings (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33991001",
        "yellow_page_code": "18111",
        "kiosk": false
    },
    {
        "value": "391108",
        "label": "Jewelry-Model Makers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33991007",
        "yellow_page_code": "44401",
        "kiosk": false
    },
    {
        "value": "391109",
        "label": "Rings-Glass/Metal/Plastic/Etc (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33991009",
        "yellow_page_code": "72305",
        "kiosk": false
    },
    {
        "value": "391110",
        "label": "Jewelry & Silverware (Mfrs) ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33991033",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "391401",
        "label": "Pewterware (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33991014",
        "yellow_page_code": "60606",
        "kiosk": false
    },
    {
        "value": "391402",
        "label": "Goldsmith & Silversmith Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33991011",
        "yellow_page_code": "38006, 77708, 77813, 38009",
        "kiosk": false
    },
    {
        "value": "391403",
        "label": "Cutlery-Manufacturers (Tableware)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221502",
        "yellow_page_code": "23013",
        "kiosk": false
    },
    {
        "value": "391404",
        "label": "Silverware-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33991015",
        "yellow_page_code": "77804",
        "kiosk": false
    },
    {
        "value": "391498",
        "label": "Silverware-Pltd/Stainless Stl Ware (Mfr)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33991016",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "391501",
        "label": "Lapidaries (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33991024",
        "yellow_page_code": "46002",
        "kiosk": false
    },
    {
        "value": "391502",
        "label": "Diamond Cutting (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33991017",
        "yellow_page_code": "24500",
        "kiosk": false
    },
    {
        "value": "391503",
        "label": "Jewelry Polishing (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33991019",
        "yellow_page_code": "44411",
        "kiosk": false
    },
    {
        "value": "391504",
        "label": "Jewelry Craftsmen (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33991022",
        "yellow_page_code": "44214, 44213",
        "kiosk": false
    },
    {
        "value": "391505",
        "label": "Jewelry Soldering (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33991023",
        "yellow_page_code": "44407",
        "kiosk": false
    },
    {
        "value": "391507",
        "label": "Jewelers Supplies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33991021",
        "yellow_page_code": "44220",
        "kiosk": false
    },
    {
        "value": "391509",
        "label": "Sapphires-Industrial (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33991025",
        "yellow_page_code": "73910",
        "kiosk": false
    },
    {
        "value": "391510",
        "label": "Diamond Irradiation (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33991018",
        "yellow_page_code": "24435",
        "kiosk": false
    },
    {
        "value": "391598",
        "label": "Jewelers Findings/Mtrls/Lapidary (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33991020",
        "yellow_page_code": "44316",
        "kiosk": false
    },
    {
        "value": "393101",
        "label": "Musical Instruments-Supls & Acces-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999203",
        "yellow_page_code": "54909",
        "kiosk": false
    },
    {
        "value": "393102",
        "label": "Musical Instruments-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999202",
        "yellow_page_code": "54901, 54913, 43349",
        "kiosk": false
    },
    {
        "value": "393103",
        "label": "Organs-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999204",
        "yellow_page_code": "57813",
        "kiosk": false
    },
    {
        "value": "393104",
        "label": "Pianos-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999205",
        "yellow_page_code": "64811",
        "kiosk": false
    },
    {
        "value": "393105",
        "label": "Violin Makers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999206",
        "yellow_page_code": "91221",
        "kiosk": false
    },
    {
        "value": "393106",
        "label": "Bagpipe Makers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999207",
        "yellow_page_code": "05819",
        "kiosk": false
    },
    {
        "value": "394201",
        "label": "Doll Clothing & Accessories-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33993002",
        "yellow_page_code": "25415",
        "kiosk": false
    },
    {
        "value": "394202",
        "label": "Dolls-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33993004",
        "yellow_page_code": "25416",
        "kiosk": false
    },
    {
        "value": "394203",
        "label": "Artificial Eyes-Toys & Animals (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33993001",
        "yellow_page_code": "03701",
        "kiosk": false
    },
    {
        "value": "394204",
        "label": "Dolls Custom Made",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33993006",
        "yellow_page_code": "25425",
        "kiosk": false
    },
    {
        "value": "394298",
        "label": "Dolls & Stuffed Toys (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33993003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "394401",
        "label": "Toys-Manufacturers Supplies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33993021",
        "yellow_page_code": "87600",
        "kiosk": false
    },
    {
        "value": "394402",
        "label": "Toys-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33993020",
        "yellow_page_code": "87607",
        "kiosk": false
    },
    {
        "value": "394403",
        "label": "Baby Carriages-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33993007",
        "yellow_page_code": "05514",
        "kiosk": false
    },
    {
        "value": "394404",
        "label": "Craft Supplies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33993008",
        "yellow_page_code": "22236",
        "kiosk": false
    },
    {
        "value": "394405",
        "label": "Fund Raising Games Mdse & Supls-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33993010",
        "yellow_page_code": "34905",
        "kiosk": false
    },
    {
        "value": "394406",
        "label": "Games & Game Supplies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33993011",
        "yellow_page_code": "36013",
        "kiosk": false
    },
    {
        "value": "394407",
        "label": "Darts & Dart Boards-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33993009",
        "yellow_page_code": "23334",
        "kiosk": false
    },
    {
        "value": "394408",
        "label": "Handicrafts-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33993013",
        "yellow_page_code": "40014",
        "kiosk": false
    },
    {
        "value": "394409",
        "label": "Hobby & Model Constr Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33993014",
        "yellow_page_code": "41034",
        "kiosk": false
    },
    {
        "value": "394410",
        "label": "Infants Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33993015",
        "yellow_page_code": "42923",
        "kiosk": false
    },
    {
        "value": "394411",
        "label": "Kites-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33993016",
        "yellow_page_code": "44935",
        "kiosk": false
    },
    {
        "value": "394412",
        "label": "Puzzles (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33993018",
        "yellow_page_code": "69211",
        "kiosk": false
    },
    {
        "value": "394413",
        "label": "Marbles-Playing & Etc (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33993017",
        "yellow_page_code": "49935",
        "kiosk": false
    },
    {
        "value": "394414",
        "label": "Sleds (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33993019",
        "yellow_page_code": "78234",
        "kiosk": false
    },
    {
        "value": "394415",
        "label": "Rocking Horses",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33993022",
        "yellow_page_code": "72530",
        "kiosk": false
    },
    {
        "value": "394498",
        "label": "Games Toys & Children's Vehicles (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33993012",
        "yellow_page_code": "36031",
        "kiosk": false
    },
    {
        "value": "394901",
        "label": "Bowling Ball-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33992005",
        "yellow_page_code": "09709",
        "kiosk": false
    },
    {
        "value": "394902",
        "label": "Divers Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33992007",
        "yellow_page_code": "25211",
        "kiosk": false
    },
    {
        "value": "394903",
        "label": "Fishermens Supplies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33992010",
        "yellow_page_code": "32616",
        "kiosk": false
    },
    {
        "value": "394904",
        "label": "Fishing Bait-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33992011",
        "yellow_page_code": "32617",
        "kiosk": false
    },
    {
        "value": "394905",
        "label": "Playground Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33992018",
        "yellow_page_code": "66601",
        "kiosk": false
    },
    {
        "value": "394906",
        "label": "Race Track Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33992019",
        "yellow_page_code": "69514",
        "kiosk": false
    },
    {
        "value": "394907",
        "label": "Surfboards-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33992029",
        "yellow_page_code": "82317",
        "kiosk": false
    },
    {
        "value": "394908",
        "label": "Swimming Pool Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33992030",
        "yellow_page_code": "82803",
        "kiosk": false
    },
    {
        "value": "394909",
        "label": "Trampoline Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33992034",
        "yellow_page_code": "88111",
        "kiosk": false
    },
    {
        "value": "394910",
        "label": "Archery Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33992001",
        "yellow_page_code": "03116",
        "kiosk": false
    },
    {
        "value": "394911",
        "label": "Boardsailing-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33992003",
        "yellow_page_code": "08620",
        "kiosk": false
    },
    {
        "value": "394912",
        "label": "Camping Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33992006",
        "yellow_page_code": "12534",
        "kiosk": false
    },
    {
        "value": "394913",
        "label": "Fish Farming Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33992009",
        "yellow_page_code": "32414",
        "kiosk": false
    },
    {
        "value": "394914",
        "label": "Fishing Tackle-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33992013",
        "yellow_page_code": "32622",
        "kiosk": false
    },
    {
        "value": "394915",
        "label": "Golf Equipment & Supplies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33992014",
        "yellow_page_code": "38213",
        "kiosk": false
    },
    {
        "value": "394916",
        "label": "Gymnasium Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33992004",
        "yellow_page_code": "39515",
        "kiosk": false
    },
    {
        "value": "394917",
        "label": "Health Equipment & Supls-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33992016",
        "yellow_page_code": "40518",
        "kiosk": false
    },
    {
        "value": "394918",
        "label": "Skin & Scuba Diving Equipment-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33992015",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "394919",
        "label": "Skating Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33992023",
        "yellow_page_code": "77912",
        "kiosk": false
    },
    {
        "value": "394920",
        "label": "Skiing Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33992024",
        "yellow_page_code": "78103",
        "kiosk": false
    },
    {
        "value": "394921",
        "label": "Tennis Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33992032",
        "yellow_page_code": "85105",
        "kiosk": false
    },
    {
        "value": "394922",
        "label": "Snowshoes-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33992026",
        "yellow_page_code": "78616",
        "kiosk": false
    },
    {
        "value": "394923",
        "label": "Sporting & Athletic Goods NEC (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33992027",
        "yellow_page_code": "79741",
        "kiosk": false
    },
    {
        "value": "394924",
        "label": "Squash Equipment & Supls-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33992028",
        "yellow_page_code": "80336",
        "kiosk": false
    },
    {
        "value": "394925",
        "label": "Swimming Pool-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33992031",
        "yellow_page_code": "82815, 82827",
        "kiosk": false
    },
    {
        "value": "394926",
        "label": "Rifle & Pistol Ranges Equip/Supls (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33992021",
        "yellow_page_code": "72206",
        "kiosk": false
    },
    {
        "value": "394927",
        "label": "Fishing Tackle Mfrs-Supls (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33992012",
        "yellow_page_code": "32611",
        "kiosk": false
    },
    {
        "value": "394928",
        "label": "Tennis Racket Stringing Machines (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33992033",
        "yellow_page_code": "85107",
        "kiosk": false
    },
    {
        "value": "394929",
        "label": "Racquetball Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33992020",
        "yellow_page_code": "69613",
        "kiosk": false
    },
    {
        "value": "394930",
        "label": "Dog Sleds-Manufacturers & Sales",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33992008",
        "yellow_page_code": "25409",
        "kiosk": false
    },
    {
        "value": "394931",
        "label": "Billiard Tables-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33992002",
        "yellow_page_code": "08099",
        "kiosk": false
    },
    {
        "value": "394932",
        "label": "Jet Drives-Marine (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33992017",
        "yellow_page_code": "44111",
        "kiosk": false
    },
    {
        "value": "394933",
        "label": "Skateboards & Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33992022",
        "yellow_page_code": "77914",
        "kiosk": false
    },
    {
        "value": "394934",
        "label": "Water Skiing Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33992035",
        "yellow_page_code": "92316",
        "kiosk": false
    },
    {
        "value": "394935",
        "label": "Paint Ball Equipment & Supplies Mfr",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33992036",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "395101",
        "label": "Pencils (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33994001",
        "yellow_page_code": "60116",
        "kiosk": false
    },
    {
        "value": "395102",
        "label": "Pens-Brush (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33994002",
        "yellow_page_code": "60115",
        "kiosk": false
    },
    {
        "value": "395103",
        "label": "Balls-Plastic (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33992025",
        "yellow_page_code": "06206",
        "kiosk": false
    },
    {
        "value": "395104",
        "label": "Office Supplies-Ex Paper (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33994025",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "395198",
        "label": "Pens-Mechanical Pencils & Parts (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33994003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "395201",
        "label": "Architects Supplies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33712701",
        "yellow_page_code": "03236",
        "kiosk": false
    },
    {
        "value": "395202",
        "label": "Crayons-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33994007",
        "yellow_page_code": "22413",
        "kiosk": false
    },
    {
        "value": "395203",
        "label": "Picture Frames-Manufacturers Supls-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33994011",
        "yellow_page_code": "65007",
        "kiosk": false
    },
    {
        "value": "395204",
        "label": "Artists Materials-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33994005",
        "yellow_page_code": "03907",
        "kiosk": false
    },
    {
        "value": "395205",
        "label": "Chalk-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33994006",
        "yellow_page_code": "14612",
        "kiosk": false
    },
    {
        "value": "395206",
        "label": "Easels-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33712706",
        "yellow_page_code": "27100",
        "kiosk": false
    },
    {
        "value": "395298",
        "label": "Lead Pencils Crayons/Artists Mtrl (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33994008",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "395301",
        "label": "Rubber Stamps-Mfrs' Equip & Supls (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33994019",
        "yellow_page_code": "73001, 80533, 73015",
        "kiosk": false
    },
    {
        "value": "395302",
        "label": "Branding Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33994014",
        "yellow_page_code": "10302",
        "kiosk": false
    },
    {
        "value": "395303",
        "label": "Marking Devices (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33994016",
        "yellow_page_code": "50200, 50119",
        "kiosk": false
    },
    {
        "value": "395304",
        "label": "Rubber Stamps-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33994018",
        "yellow_page_code": "73012",
        "kiosk": false
    },
    {
        "value": "395305",
        "label": "Stamps-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33994022",
        "yellow_page_code": "80601",
        "kiosk": false
    },
    {
        "value": "395306",
        "label": "Stamp Pads (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33994020",
        "yellow_page_code": "80508",
        "kiosk": false
    },
    {
        "value": "395307",
        "label": "Checks-Metal Fiber Etc (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33994015",
        "yellow_page_code": "14902",
        "kiosk": false
    },
    {
        "value": "395308",
        "label": "Marking Dies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33994017",
        "yellow_page_code": "50112",
        "kiosk": false
    },
    {
        "value": "395309",
        "label": "Stampings-Non-Metallic (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33994021",
        "yellow_page_code": "80512",
        "kiosk": false
    },
    {
        "value": "395501",
        "label": "Carbon Paper-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33994023",
        "yellow_page_code": "13203",
        "kiosk": false
    },
    {
        "value": "395502",
        "label": "Typewriters-Supplies & Attachments-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33994024",
        "yellow_page_code": "89714",
        "kiosk": false
    },
    {
        "value": "396101",
        "label": "Costume Jewelry-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33991026",
        "yellow_page_code": "21816",
        "kiosk": false
    },
    {
        "value": "396102",
        "label": "Rhinestones-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33991031",
        "yellow_page_code": "72017",
        "kiosk": false
    },
    {
        "value": "396103",
        "label": "Pins (Broaches)(mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33991030",
        "yellow_page_code": "65314",
        "kiosk": false
    },
    {
        "value": "396104",
        "label": "Jewelry Craft Supplies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33991028",
        "yellow_page_code": "44308",
        "kiosk": false
    },
    {
        "value": "396105",
        "label": "Jewelry Stamping (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33991029",
        "yellow_page_code": "44412",
        "kiosk": false
    },
    {
        "value": "396198",
        "label": "Costume Jwlry/Novelties-Ex Prcs (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33991027",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "396501",
        "label": "Buckles (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999301",
        "yellow_page_code": "11005",
        "kiosk": false
    },
    {
        "value": "396502",
        "label": "Buttons-Mfrs Equipment & Supls (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999305",
        "yellow_page_code": "11907",
        "kiosk": false
    },
    {
        "value": "396503",
        "label": "Hair Pins (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999307",
        "yellow_page_code": "39603",
        "kiosk": false
    },
    {
        "value": "396504",
        "label": "Buckles-Mfrs Equipment & Supls (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999302",
        "yellow_page_code": "11100",
        "kiosk": false
    },
    {
        "value": "396505",
        "label": "Button Makers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999304",
        "yellow_page_code": "11906",
        "kiosk": false
    },
    {
        "value": "396506",
        "label": "Button Carding (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999303",
        "yellow_page_code": "11936",
        "kiosk": false
    },
    {
        "value": "396507",
        "label": "Pins-Common Safety Etc (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999308",
        "yellow_page_code": "65309",
        "kiosk": false
    },
    {
        "value": "396508",
        "label": "Zippers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999309",
        "yellow_page_code": "94907",
        "kiosk": false
    },
    {
        "value": "396509",
        "label": "Fasteners-Hook & Loop (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999310",
        "yellow_page_code": "31010",
        "kiosk": false
    },
    {
        "value": "396598",
        "label": "Fasteners-Buttons Needles & Pins (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999306",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "399101",
        "label": "Brooms-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999403",
        "yellow_page_code": "11001",
        "kiosk": false
    },
    {
        "value": "399102",
        "label": "Brush-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999404",
        "yellow_page_code": "11101",
        "kiosk": false
    },
    {
        "value": "399103",
        "label": "Paint Rollers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999406",
        "yellow_page_code": "58508",
        "kiosk": false
    },
    {
        "value": "399104",
        "label": "Broom Manufacturers-Equip & Supls (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999401",
        "yellow_page_code": "10913",
        "kiosk": false
    },
    {
        "value": "399198",
        "label": "Brooms & Brushes (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999402",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "399301",
        "label": "Gold Leaf (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33995004",
        "yellow_page_code": "38000",
        "kiosk": false
    },
    {
        "value": "399302",
        "label": "Signs (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33995008",
        "yellow_page_code": "47004, 77501, 47009, 77504, 00513, 77518, 77519, 77437, 90624, 77510, 77526, 77443, 49512",
        "kiosk": false
    },
    {
        "value": "399303",
        "label": "Advertising-Specialties-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33995001",
        "yellow_page_code": "00412, 50411",
        "kiosk": false
    },
    {
        "value": "399304",
        "label": "Letters-Sign-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33995006",
        "yellow_page_code": "47007",
        "kiosk": false
    },
    {
        "value": "399305",
        "label": "Display Designers & Producers-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33995003",
        "yellow_page_code": "25035",
        "kiosk": false
    },
    {
        "value": "399306",
        "label": "Animation Mechanisms-Display Sign (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33995002",
        "yellow_page_code": "02607",
        "kiosk": false
    },
    {
        "value": "399307",
        "label": "Signs-Neon (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33995010",
        "yellow_page_code": "77511",
        "kiosk": false
    },
    {
        "value": "399308",
        "label": "Signs-Silk Screen Process (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33995011",
        "yellow_page_code": "77513",
        "kiosk": false
    },
    {
        "value": "399309",
        "label": "Luminous Products (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33995007",
        "yellow_page_code": "49100",
        "kiosk": false
    },
    {
        "value": "399310",
        "label": "House Numbers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33995005",
        "yellow_page_code": "41918",
        "kiosk": false
    },
    {
        "value": "399311",
        "label": "Vinyl Graphics (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33995013",
        "yellow_page_code": "91225",
        "kiosk": false
    },
    {
        "value": "399331",
        "label": "Stencils & Stencil Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33995012",
        "yellow_page_code": "81210",
        "kiosk": false
    },
    {
        "value": "399398",
        "label": "Signs & Advertising Specialties (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33995009",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "399501",
        "label": "Vault Builders (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999502",
        "yellow_page_code": "90613",
        "kiosk": false
    },
    {
        "value": "399502",
        "label": "Caskets-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999503",
        "yellow_page_code": "13931, 19320",
        "kiosk": false
    },
    {
        "value": "399601",
        "label": "Linoleum-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32619903",
        "yellow_page_code": "47709",
        "kiosk": false
    },
    {
        "value": "399698",
        "label": "Linoleum-Asphalt Felt Base Flr Cvr (Mfr)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32619928",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "399699",
        "label": "Linoleum/Hard Surface Flr Cvrs NEC (Mfr)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32619929",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "399901",
        "label": "Aerosols (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999901",
        "yellow_page_code": "00801",
        "kiosk": false
    },
    {
        "value": "399902",
        "label": "Artificial Flwrs/Plants-Mfrs Supl (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999905",
        "yellow_page_code": "03803",
        "kiosk": false
    },
    {
        "value": "399903",
        "label": "Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999936",
        "yellow_page_code": "30602, 49902, 49822, 49948, 42930",
        "kiosk": false
    },
    {
        "value": "399904",
        "label": "Matches (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32599815",
        "yellow_page_code": "50406",
        "kiosk": false
    },
    {
        "value": "399905",
        "label": "Shells-Marine (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999953",
        "yellow_page_code": "76208",
        "kiosk": false
    },
    {
        "value": "399906",
        "label": "Assembly & Fabricating Service (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999908",
        "yellow_page_code": "04100, 21108, 27908, 49190, 49862, 30196, 49125",
        "kiosk": false
    },
    {
        "value": "399907",
        "label": "Amusement Park Rides Equipment-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999903",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "399908",
        "label": "Artificial Flowers & Plants & Trees-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999904",
        "yellow_page_code": "03707, 33518",
        "kiosk": false
    },
    {
        "value": "399909",
        "label": "Barbers Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33712702",
        "yellow_page_code": "06509",
        "kiosk": false
    },
    {
        "value": "399910",
        "label": "Beauty Salons-Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33712703",
        "yellow_page_code": "07209",
        "kiosk": false
    },
    {
        "value": "399911",
        "label": "Bust Forms-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999913",
        "yellow_page_code": "11817",
        "kiosk": false
    },
    {
        "value": "399912",
        "label": "Dancing Supplies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999917",
        "yellow_page_code": "23314",
        "kiosk": false
    },
    {
        "value": "399913",
        "label": "Dusters-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999919",
        "yellow_page_code": "26911",
        "kiosk": false
    },
    {
        "value": "399914",
        "label": "Engineering Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999920",
        "yellow_page_code": "28705",
        "kiosk": false
    },
    {
        "value": "399915",
        "label": "Fire Extinguishers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32599810",
        "yellow_page_code": "32005",
        "kiosk": false
    },
    {
        "value": "399916",
        "label": "Florists-Supplies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999923",
        "yellow_page_code": "33411",
        "kiosk": false
    },
    {
        "value": "399917",
        "label": "Furniture-Repairing/Refinish Supls-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33712710",
        "yellow_page_code": "35812",
        "kiosk": false
    },
    {
        "value": "399918",
        "label": "Gift Baskets & Parcels-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999927",
        "yellow_page_code": "37234",
        "kiosk": false
    },
    {
        "value": "399919",
        "label": "Hair Goods-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999928",
        "yellow_page_code": "39803, 39636",
        "kiosk": false
    },
    {
        "value": "399920",
        "label": "Hair Ornaments-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999929",
        "yellow_page_code": "39635",
        "kiosk": false
    },
    {
        "value": "399921",
        "label": "Interior Decorators/Designers Supls-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33513101",
        "yellow_page_code": "43613",
        "kiosk": false
    },
    {
        "value": "399922",
        "label": "Christmas Trees-Artificial (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999915",
        "yellow_page_code": "15612",
        "kiosk": false
    },
    {
        "value": "399923",
        "label": "Manicure Implements-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999935",
        "yellow_page_code": "49815",
        "kiosk": false
    },
    {
        "value": "399924",
        "label": "Fingerprinting Equipment (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999922",
        "yellow_page_code": "31901",
        "kiosk": false
    },
    {
        "value": "399925",
        "label": "Ornaments-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999938",
        "yellow_page_code": "57912",
        "kiosk": false
    },
    {
        "value": "399926",
        "label": "Pictures-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999941",
        "yellow_page_code": "65113",
        "kiosk": false
    },
    {
        "value": "399927",
        "label": "Pins-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999942",
        "yellow_page_code": "65403",
        "kiosk": false
    },
    {
        "value": "399928",
        "label": "Premium Goods-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999944",
        "yellow_page_code": "68020",
        "kiosk": false
    },
    {
        "value": "399929",
        "label": "Religious Goods-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999946",
        "yellow_page_code": "71517",
        "kiosk": false
    },
    {
        "value": "399930",
        "label": "School Bags-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999948",
        "yellow_page_code": "74313",
        "kiosk": false
    },
    {
        "value": "399931",
        "label": "School Supplies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999949",
        "yellow_page_code": "74435",
        "kiosk": false
    },
    {
        "value": "399932",
        "label": "Shoe Covers-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999954",
        "yellow_page_code": "76709",
        "kiosk": false
    },
    {
        "value": "399933",
        "label": "Surplus & Salvage Merchandise-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999960",
        "yellow_page_code": "82412",
        "kiosk": false
    },
    {
        "value": "399934",
        "label": "Tanning Salons Equipment-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33712713",
        "yellow_page_code": "83611",
        "kiosk": false
    },
    {
        "value": "399935",
        "label": "Theatrical Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999961",
        "yellow_page_code": "85511",
        "kiosk": false
    },
    {
        "value": "399936",
        "label": "Wedding Supplies & Services-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999963",
        "yellow_page_code": "92903",
        "kiosk": false
    },
    {
        "value": "399937",
        "label": "Wigs & Hairpieces-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999965",
        "yellow_page_code": "93418",
        "kiosk": false
    },
    {
        "value": "399938",
        "label": "Yacht Furnishings-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999966",
        "yellow_page_code": "94615",
        "kiosk": false
    },
    {
        "value": "399939",
        "label": "Beach Supplies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999911",
        "yellow_page_code": "07123",
        "kiosk": false
    },
    {
        "value": "399940",
        "label": "Boutique Items-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999912",
        "yellow_page_code": "09609",
        "kiosk": false
    },
    {
        "value": "399941",
        "label": "Candles-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999914",
        "yellow_page_code": "12713",
        "kiosk": false
    },
    {
        "value": "399942",
        "label": "Curios-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999916",
        "yellow_page_code": "22836",
        "kiosk": false
    },
    {
        "value": "399943",
        "label": "Dress Forms-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999918",
        "yellow_page_code": "26112",
        "kiosk": false
    },
    {
        "value": "399944",
        "label": "Electrolysis Equip & Supls-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33712707",
        "yellow_page_code": "27812",
        "kiosk": false
    },
    {
        "value": "399946",
        "label": "Lamp Shade Machinery (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33513106",
        "yellow_page_code": "45612",
        "kiosk": false
    },
    {
        "value": "399947",
        "label": "Cigar & Cigarette Lighters-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999969",
        "yellow_page_code": "17910",
        "kiosk": false
    },
    {
        "value": "399948",
        "label": "Pipes-Smoking-Mfrs Equip & Supls (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999943",
        "yellow_page_code": "65808",
        "kiosk": false
    },
    {
        "value": "399949",
        "label": "Rental Supplies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999947",
        "yellow_page_code": "71615",
        "kiosk": false
    },
    {
        "value": "399950",
        "label": "Shells-Decorative-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999952",
        "yellow_page_code": "76220",
        "kiosk": false
    },
    {
        "value": "399951",
        "label": "Smokers Articles-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999956",
        "yellow_page_code": "78515",
        "kiosk": false
    },
    {
        "value": "399952",
        "label": "Souvenirs-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999957",
        "yellow_page_code": "79536",
        "kiosk": false
    },
    {
        "value": "399953",
        "label": "Tapes-Measuring-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33221626",
        "yellow_page_code": "83813",
        "kiosk": false
    },
    {
        "value": "399954",
        "label": "Umbrellas-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999962",
        "yellow_page_code": "89826",
        "kiosk": false
    },
    {
        "value": "399955",
        "label": "Gaming Equipment & Supplies-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999926",
        "yellow_page_code": "36015",
        "kiosk": false
    },
    {
        "value": "399956",
        "label": "Stamping-Cold/Gold/Hot & Etc (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999959",
        "yellow_page_code": "80511",
        "kiosk": false
    },
    {
        "value": "399957",
        "label": "Sequins (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999950",
        "yellow_page_code": "75516",
        "kiosk": false
    },
    {
        "value": "399958",
        "label": "Spools (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999958",
        "yellow_page_code": "79908",
        "kiosk": false
    },
    {
        "value": "399959",
        "label": "Prototypes (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999945",
        "yellow_page_code": "68717",
        "kiosk": false
    },
    {
        "value": "399960",
        "label": "Feather Goods (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999921",
        "yellow_page_code": "31101",
        "kiosk": false
    },
    {
        "value": "399961",
        "label": "Handbag-Frames & Fittings (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999930",
        "yellow_page_code": "39907",
        "kiosk": false
    },
    {
        "value": "399962",
        "label": "Artificial Fruits & Food (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999906",
        "yellow_page_code": "03807, 33711",
        "kiosk": false
    },
    {
        "value": "399963",
        "label": "Artificial Snow (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999907",
        "yellow_page_code": "03900",
        "kiosk": false
    },
    {
        "value": "399964",
        "label": "Party Supplies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999939",
        "yellow_page_code": "59599",
        "kiosk": false
    },
    {
        "value": "399965",
        "label": "Game Designers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999925",
        "yellow_page_code": "36010",
        "kiosk": false
    },
    {
        "value": "399966",
        "label": "Hosiery-Repairing Equip (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999931",
        "yellow_page_code": "41715",
        "kiosk": false
    },
    {
        "value": "399967",
        "label": "Badges-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999910",
        "yellow_page_code": "05612",
        "kiosk": false
    },
    {
        "value": "399968",
        "label": "Shoe Patterns (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999955",
        "yellow_page_code": "76806",
        "kiosk": false
    },
    {
        "value": "399969",
        "label": "Atomizers (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999909",
        "yellow_page_code": "04201",
        "kiosk": false
    },
    {
        "value": "399970",
        "label": "Whistles (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999964",
        "yellow_page_code": "93413",
        "kiosk": false
    },
    {
        "value": "399971",
        "label": "Marionettes & Puppets (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999937",
        "yellow_page_code": "50111",
        "kiosk": false
    },
    {
        "value": "399972",
        "label": "Sewage Disposal Systems-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999951",
        "yellow_page_code": "75708, 25816",
        "kiosk": false
    },
    {
        "value": "399973",
        "label": "Magicians Supplies-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999934",
        "yellow_page_code": "49407",
        "kiosk": false
    },
    {
        "value": "399974",
        "label": "Identification Equip & Supls-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999932",
        "yellow_page_code": "42601",
        "kiosk": false
    },
    {
        "value": "399975",
        "label": "Air Fresheners-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999902",
        "yellow_page_code": "01212",
        "kiosk": false
    },
    {
        "value": "399976",
        "label": "Stereographic Machines & Supplies (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999967",
        "yellow_page_code": "81311",
        "kiosk": false
    },
    {
        "value": "399979",
        "label": "Potpourri Mfrs & Distrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999968",
        "yellow_page_code": "67630",
        "kiosk": false
    },
    {
        "value": "399980",
        "label": "Nail Clippers Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999970",
        "yellow_page_code": "55022",
        "kiosk": false
    },
    {
        "value": "399981",
        "label": "Hot & Cold Pack Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33521006",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "399982",
        "label": "Sink Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999971",
        "yellow_page_code": "77816",
        "kiosk": false
    },
    {
        "value": "399983",
        "label": "Snow-Making & Melting Systems-Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999972",
        "yellow_page_code": "78636",
        "kiosk": false
    },
    {
        "value": "399984",
        "label": "Cannabis Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33999973",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "399985",
        "label": "Fabricated Metal Product (Mfrs) ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299927",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "399986",
        "label": "Doll Toy & Game (Mfrs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33993023",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "401101",
        "label": "Railroads",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48211102",
        "yellow_page_code": "70205, 70300, 70217, 70218, 70219, 13076",
        "kiosk": false
    },
    {
        "value": "401102",
        "label": "Trains",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48211103",
        "yellow_page_code": "88116, 68866",
        "kiosk": false
    },
    {
        "value": "401105",
        "label": "Government-Railroads Line-Haul Operators",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48211101",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "401301",
        "label": "Short Line Railroads ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48211201",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "401398",
        "label": "Railroad Switching & Terminal Estblshmnt",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48821005",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "411101",
        "label": "Airport Transportation Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48599901",
        "yellow_page_code": "01800",
        "kiosk": false
    },
    {
        "value": "411102",
        "label": "Transit Lines",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48511901",
        "yellow_page_code": "88300, 68860, 88301, 88222, 37009",
        "kiosk": false
    },
    {
        "value": "411103",
        "label": "Shuttle Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48599905",
        "yellow_page_code": "77414, 68865",
        "kiosk": false
    },
    {
        "value": "411104",
        "label": "Underground Transit Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48511902",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "411105",
        "label": "Commuter & Passenger Rail Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48511201",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "411106",
        "label": "Transit Station",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48511903",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "411107",
        "label": "Mixed Mode Transit Systems ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48511101",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "411108",
        "label": "Bus & Motor Vehicle Transit Systems ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48511301",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "411901",
        "label": "Handicapped Transportation Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48599101",
        "yellow_page_code": "40007, 88311, 93307, 24821, 93322, 93320, 93323",
        "kiosk": false
    },
    {
        "value": "411902",
        "label": "Ambulance Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62191002",
        "yellow_page_code": "02106, 02115, 02116, 02118, 02119, 24950",
        "kiosk": false
    },
    {
        "value": "411903",
        "label": "Limousine Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48532002",
        "yellow_page_code": "47508, 47517, 75318, 92942",
        "kiosk": false
    },
    {
        "value": "411904",
        "label": "Car Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48532001",
        "yellow_page_code": "13008",
        "kiosk": false
    },
    {
        "value": "411905",
        "label": "Ambulette Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62191003",
        "yellow_page_code": "02109",
        "kiosk": false
    },
    {
        "value": "411906",
        "label": "Rescue Squads",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62191004",
        "yellow_page_code": "71706, 71739, 71740, 41257",
        "kiosk": false
    },
    {
        "value": "411908",
        "label": "Car Pool Information Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48599902",
        "yellow_page_code": "13007, 90517",
        "kiosk": false
    },
    {
        "value": "411909",
        "label": "Bus Tours Promoters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48711001",
        "yellow_page_code": "11612, 18925",
        "kiosk": false
    },
    {
        "value": "411910",
        "label": "Casino Transportation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48711003",
        "yellow_page_code": "13919",
        "kiosk": false
    },
    {
        "value": "411911",
        "label": "Mine Rescue",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48599102",
        "yellow_page_code": "52512",
        "kiosk": false
    },
    {
        "value": "411912",
        "label": "Medical Transportation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48599904",
        "yellow_page_code": "51114, 40428, 88327",
        "kiosk": false
    },
    {
        "value": "411913",
        "label": "Transportation Sharing Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48599907",
        "yellow_page_code": "88319",
        "kiosk": false
    },
    {
        "value": "411914",
        "label": "Transportation Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48599906",
        "yellow_page_code": "88322, 88323, 88329, 92960, 11617, 20021, 88318, 88150, 60323, 88321",
        "kiosk": false
    },
    {
        "value": "411915",
        "label": "Trolleys-Charter",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48711005",
        "yellow_page_code": "88522, 88525, 93057, 88610",
        "kiosk": false
    },
    {
        "value": "411916",
        "label": "Mortuary Transportation Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48599903",
        "yellow_page_code": "53612",
        "kiosk": false
    },
    {
        "value": "411917",
        "label": "Patient Transfer Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48599908",
        "yellow_page_code": "59717",
        "kiosk": false
    },
    {
        "value": "411918",
        "label": "Ground Transportation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48599910",
        "yellow_page_code": "39126",
        "kiosk": false
    },
    {
        "value": "411919",
        "label": "Search Dogs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48599103",
        "yellow_page_code": "75203",
        "kiosk": false
    },
    {
        "value": "411920",
        "label": "Intermodal Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48599911",
        "yellow_page_code": "43590",
        "kiosk": false
    },
    {
        "value": "411921",
        "label": "Land Scenic & Sightseeing Transportation ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48711008",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "411999",
        "label": "Local Passenger Transportation NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48599909",
        "yellow_page_code": "88229",
        "kiosk": false
    },
    {
        "value": "412101",
        "label": "Taxicabs & Transportation Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48531002",
        "yellow_page_code": "12209, 84108, 52525, 43664, 21376",
        "kiosk": false
    },
    {
        "value": "412102",
        "label": "Childrens Transportation Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48531001",
        "yellow_page_code": "15216, 15119, 88313",
        "kiosk": false
    },
    {
        "value": "413101",
        "label": "Bus Lines",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48521001",
        "yellow_page_code": "11602, 19918, 11703, 11615, 11616, 18926, 11603, 68862, 68863, 11605",
        "kiosk": false
    },
    {
        "value": "414101",
        "label": "Autobus Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48551001",
        "yellow_page_code": "04407",
        "kiosk": false
    },
    {
        "value": "414198",
        "label": "Local Bus Charter Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48551003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "414201",
        "label": "Buses-Charter & Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48551002",
        "yellow_page_code": "11608, 11607, 18917, 18931",
        "kiosk": false
    },
    {
        "value": "415101",
        "label": "Buses-School Transportation Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48541001",
        "yellow_page_code": "11706, 74405, 74341, 88325, 74441",
        "kiosk": false
    },
    {
        "value": "417301",
        "label": "Terminal Operators & Warehousemen",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48849009",
        "yellow_page_code": "85113",
        "kiosk": false
    },
    {
        "value": "417398",
        "label": "Terminal & Service Facilities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48849008",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "421201",
        "label": "Trucking-Contract Hauling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48411015",
        "yellow_page_code": "40306, 89006, 21246",
        "kiosk": false
    },
    {
        "value": "421202",
        "label": "Log Hauling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48422008",
        "yellow_page_code": "48603",
        "kiosk": false
    },
    {
        "value": "421203",
        "label": "Livestock Hauling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48422007",
        "yellow_page_code": "48208, 89105, 48302, 48210, 14123",
        "kiosk": false
    },
    {
        "value": "421204",
        "label": "Lumber Carriers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48422009",
        "yellow_page_code": "48904, 48911",
        "kiosk": false
    },
    {
        "value": "421205",
        "label": "Delivery Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48411004",
        "yellow_page_code": "23804, 23818, 58313, 19630, 30290, 30059",
        "kiosk": false
    },
    {
        "value": "421206",
        "label": "Cartage",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48411003",
        "yellow_page_code": "13903",
        "kiosk": false
    },
    {
        "value": "421207",
        "label": "Safes & Vaults-Movers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48422012",
        "yellow_page_code": "73305, 54413",
        "kiosk": false
    },
    {
        "value": "421208",
        "label": "Pet Transporting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48422010",
        "yellow_page_code": "60508",
        "kiosk": false
    },
    {
        "value": "421209",
        "label": "Truck-Loading & Unloading",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48411012",
        "yellow_page_code": "88802",
        "kiosk": false
    },
    {
        "value": "421210",
        "label": "Trucking-Dump",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56211903",
        "yellow_page_code": "89100, 30331, 43694, 26899",
        "kiosk": false
    },
    {
        "value": "421211",
        "label": "Sand & Gravel Hauling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48422014",
        "yellow_page_code": "72412, 73704, 89020",
        "kiosk": false
    },
    {
        "value": "421212",
        "label": "Trucking-Local Cartage",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48411016",
        "yellow_page_code": "89106, 34405, 30172",
        "kiosk": false
    },
    {
        "value": "421213",
        "label": "Grain Hauling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48422005",
        "yellow_page_code": "38708, 38707, 89101, 89029",
        "kiosk": false
    },
    {
        "value": "421214",
        "label": "Truck-Transporting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48411014",
        "yellow_page_code": "88907",
        "kiosk": false
    },
    {
        "value": "421215",
        "label": "Furniture Movers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48421002",
        "yellow_page_code": "35805",
        "kiosk": false
    },
    {
        "value": "421216",
        "label": "Movers-Heavy Hauling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48411010",
        "yellow_page_code": "54415",
        "kiosk": false
    },
    {
        "value": "421217",
        "label": "Floats-Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48422004",
        "yellow_page_code": "33015",
        "kiosk": false
    },
    {
        "value": "421218",
        "label": "Feed Hauling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48411005",
        "yellow_page_code": "31113",
        "kiosk": false
    },
    {
        "value": "421219",
        "label": "Fill Dirt",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48411006",
        "yellow_page_code": "31603, 31615, 24841, 24858",
        "kiosk": false
    },
    {
        "value": "421220",
        "label": "Filling Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48411007",
        "yellow_page_code": "31607",
        "kiosk": false
    },
    {
        "value": "421221",
        "label": "Recyclables-Pick Up Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56211902",
        "yellow_page_code": "71043",
        "kiosk": false
    },
    {
        "value": "421222",
        "label": "Hauling & Debris Removal",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56211102",
        "yellow_page_code": "40301, 23625, 40299",
        "kiosk": false
    },
    {
        "value": "421223",
        "label": "Baggage Transfer",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48422002",
        "yellow_page_code": "05705",
        "kiosk": false
    },
    {
        "value": "421224",
        "label": "Animal Transporting-Zoo Circus Etc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48422001",
        "yellow_page_code": "02602",
        "kiosk": false
    },
    {
        "value": "421225",
        "label": "Clock Moving Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48421001",
        "yellow_page_code": "18603",
        "kiosk": false
    },
    {
        "value": "421226",
        "label": "Computer & Electronic Equipment Movers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48422003",
        "yellow_page_code": "20213",
        "kiosk": false
    },
    {
        "value": "421227",
        "label": "Hay Hauling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48422006",
        "yellow_page_code": "40401",
        "kiosk": false
    },
    {
        "value": "421228",
        "label": "Tank Truck Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48422013",
        "yellow_page_code": "83505, 83604, 83607, 89191",
        "kiosk": false
    },
    {
        "value": "421229",
        "label": "Petroleum Products-Transporting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48422011",
        "yellow_page_code": "60536, 40321",
        "kiosk": false
    },
    {
        "value": "421230",
        "label": "Hazardous Materials-Transporting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56211201",
        "yellow_page_code": "40327",
        "kiosk": false
    },
    {
        "value": "421231",
        "label": "Beer Delivery",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48411002",
        "yellow_page_code": "07511",
        "kiosk": false
    },
    {
        "value": "421232",
        "label": "Fertilizing Hauling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56211101",
        "yellow_page_code": "31415",
        "kiosk": false
    },
    {
        "value": "421233",
        "label": "Lime Hauling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48422015",
        "yellow_page_code": "47514",
        "kiosk": false
    },
    {
        "value": "421234",
        "label": "Gift Services-Delivered",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48411017",
        "yellow_page_code": "37229",
        "kiosk": false
    },
    {
        "value": "421235",
        "label": "Trucking Boom",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48422017",
        "yellow_page_code": "89023",
        "kiosk": false
    },
    {
        "value": "421236",
        "label": "Used Household & Office Goods Moving",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48421004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "421237",
        "label": "Solid Waste Collection ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56211103",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "421238",
        "label": "Waste Collection & Hauling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56211904",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "421301",
        "label": "Trailers-Transporting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48423012",
        "yellow_page_code": "88106, 87837, 13070, 30091",
        "kiosk": false
    },
    {
        "value": "421302",
        "label": "Fuel-Bulk-Delivery",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48423005",
        "yellow_page_code": "34810",
        "kiosk": false
    },
    {
        "value": "421303",
        "label": "Trucking-Transportation Brokers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48423020",
        "yellow_page_code": "89001, 89008, 88238, 30271, 30171",
        "kiosk": false
    },
    {
        "value": "421304",
        "label": "Trucking",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48423013",
        "yellow_page_code": "89004, 89011, 88235, 88714, 89010, 89015, 89018, 89019, 89022, 89027, 56475",
        "kiosk": false
    },
    {
        "value": "421305",
        "label": "Trucking-Exempt Commodity",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48423014",
        "yellow_page_code": "89007",
        "kiosk": false
    },
    {
        "value": "421306",
        "label": "Trucking-Liquid & Dry Bulk",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48423016",
        "yellow_page_code": "89104",
        "kiosk": false
    },
    {
        "value": "421307",
        "label": "Trucking-Heavy Hauling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48423015",
        "yellow_page_code": "40308, 89102, 40320, 30256, 71729",
        "kiosk": false
    },
    {
        "value": "421308",
        "label": "Trucking-Refrigerated",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48423019",
        "yellow_page_code": "89200",
        "kiosk": false
    },
    {
        "value": "421309",
        "label": "Trucking-Motor Freight",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48423017",
        "yellow_page_code": "54204, 89108, 88316, 34412, 34403",
        "kiosk": false
    },
    {
        "value": "421310",
        "label": "Express & Transfer Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48423004",
        "yellow_page_code": "30302, 34411",
        "kiosk": false
    },
    {
        "value": "421311",
        "label": "Horse Transporting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48423006",
        "yellow_page_code": "41508",
        "kiosk": false
    },
    {
        "value": "421312",
        "label": "Mobile Homes-Transporting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48423008",
        "yellow_page_code": "53204, 53111, 49945, 53026, 49928, 49934, 53316, 53032",
        "kiosk": false
    },
    {
        "value": "421313",
        "label": "House & Building Movers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48423007",
        "yellow_page_code": "41908, 11211, 54423, 41466, 43678",
        "kiosk": false
    },
    {
        "value": "421314",
        "label": "Oil Field Hauling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48423009",
        "yellow_page_code": "56600, 89103, 56461",
        "kiosk": false
    },
    {
        "value": "421315",
        "label": "Oil Truckers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48423011",
        "yellow_page_code": "56809",
        "kiosk": false
    },
    {
        "value": "421316",
        "label": "Oil Field Transportation Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48423010",
        "yellow_page_code": "56702, 56417, 56423",
        "kiosk": false
    },
    {
        "value": "421317",
        "label": "Citrus Hauling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48423002",
        "yellow_page_code": "18017",
        "kiosk": false
    },
    {
        "value": "421318",
        "label": "Trucking-Containerized Freight",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48412102",
        "yellow_page_code": "89012",
        "kiosk": false
    },
    {
        "value": "421320",
        "label": "Trucking-Overdimensional Load",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48423018",
        "yellow_page_code": "89014",
        "kiosk": false
    },
    {
        "value": "421321",
        "label": "Trucking Management",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48412101",
        "yellow_page_code": "89107",
        "kiosk": false
    },
    {
        "value": "421322",
        "label": "Bulk Commodity Transport",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48423001",
        "yellow_page_code": "11414",
        "kiosk": false
    },
    {
        "value": "421323",
        "label": "General Freight Trucking Long-Distance ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48412103",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "421325",
        "label": "Long-Distance Specialized Freight Trucking ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48423021",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "421401",
        "label": "Movers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48411009",
        "yellow_page_code": "54404, 54406, 54405, 54410, 54420, 81719, 54434, 09811, 54422, 54436, 43593, 71650, 71651, 54437, 41899",
        "kiosk": false
    },
    {
        "value": "421402",
        "label": "Piano & Organ Moving",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48421003",
        "yellow_page_code": "64900",
        "kiosk": false
    },
    {
        "value": "421403",
        "label": "Transfer Companies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48411011",
        "yellow_page_code": "88236",
        "kiosk": false
    },
    {
        "value": "421404",
        "label": "Truck-Storage",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48411013",
        "yellow_page_code": "88912",
        "kiosk": false
    },
    {
        "value": "421405",
        "label": "Art & Antique Movers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48422018",
        "yellow_page_code": "03463",
        "kiosk": false
    },
    {
        "value": "421406",
        "label": "Local Specialized Freight Trucking ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48422019",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "421498",
        "label": "Local Trucking With Storage",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48411008",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "421501",
        "label": "Courier Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49211002",
        "yellow_page_code": "22205",
        "kiosk": false
    },
    {
        "value": "421502",
        "label": "Parcel Delivery",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49221001",
        "yellow_page_code": "59305",
        "kiosk": false
    },
    {
        "value": "421503",
        "label": "Shipping Masters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49221003",
        "yellow_page_code": "76511, 30149",
        "kiosk": false
    },
    {
        "value": "421504",
        "label": "Parcel Post Assembly Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49221002",
        "yellow_page_code": "59311",
        "kiosk": false
    },
    {
        "value": "421505",
        "label": "Art Delivery",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49211005",
        "yellow_page_code": "03516",
        "kiosk": false
    },
    {
        "value": "421506",
        "label": "Medical Courier Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49211006",
        "yellow_page_code": "51044",
        "kiosk": false
    },
    {
        "value": "421507",
        "label": "Baby Formula Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49221005",
        "yellow_page_code": "05513",
        "kiosk": false
    },
    {
        "value": "422101",
        "label": "Grain Elevators",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49313003",
        "yellow_page_code": "38700, 28301, 85111",
        "kiosk": false
    },
    {
        "value": "422102",
        "label": "Cotton Compresses",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49313002",
        "yellow_page_code": "21905",
        "kiosk": false
    },
    {
        "value": "422103",
        "label": "Bean Elevators",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49313001",
        "yellow_page_code": "07200",
        "kiosk": false
    },
    {
        "value": "422104",
        "label": "Potato Warehouses",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49313004",
        "yellow_page_code": "67602",
        "kiosk": false
    },
    {
        "value": "422105",
        "label": "Warehouses-Commodity & Merchandise",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49313007",
        "yellow_page_code": "91507, 30048, 30230",
        "kiosk": false
    },
    {
        "value": "422106",
        "label": "Tobacco Warehouses",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49313006",
        "yellow_page_code": "86705",
        "kiosk": false
    },
    {
        "value": "422107",
        "label": "Warehouses-Cotton",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49313008",
        "yellow_page_code": "91512",
        "kiosk": false
    },
    {
        "value": "422108",
        "label": "Rice Storage",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49313005",
        "yellow_page_code": "72113",
        "kiosk": false
    },
    {
        "value": "422201",
        "label": "Locker Plants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49312001",
        "yellow_page_code": "34600, 34598, 48415",
        "kiosk": false
    },
    {
        "value": "422202",
        "label": "Warehouses-Cold Storage",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49312003",
        "yellow_page_code": "19603, 91506, 19535, 19616, 30198, 81715",
        "kiosk": false
    },
    {
        "value": "422203",
        "label": "Precooling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49312002",
        "yellow_page_code": "68003",
        "kiosk": false
    },
    {
        "value": "422204",
        "label": "Meat Lockers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49312004",
        "yellow_page_code": "50813, 48409",
        "kiosk": false
    },
    {
        "value": "422501",
        "label": "Storage",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49311002",
        "yellow_page_code": "81706",
        "kiosk": false
    },
    {
        "value": "422502",
        "label": "Warehousing-Field",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49311010",
        "yellow_page_code": "91602",
        "kiosk": false
    },
    {
        "value": "422503",
        "label": "Storage-Household & Commercial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53113001",
        "yellow_page_code": "81800, 81810, 81727, 35720, 30265, 81832, 43599, 41479, 43785",
        "kiosk": false
    },
    {
        "value": "422504",
        "label": "Warehouses-Bonded",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49311007",
        "yellow_page_code": "91515, 09190",
        "kiosk": false
    },
    {
        "value": "422505",
        "label": "Warehouses-Merchandise & Self Storage",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49311008",
        "yellow_page_code": "91508, 91518",
        "kiosk": false
    },
    {
        "value": "422506",
        "label": "Warehouses-Private & Public",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49311009",
        "yellow_page_code": "91509, 91600, 91601, 91517, 91519",
        "kiosk": false
    },
    {
        "value": "422507",
        "label": "Warehouses-Self Storage",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53113003",
        "yellow_page_code": "91606, 75444",
        "kiosk": false
    },
    {
        "value": "422508",
        "label": "Warehouses-Mini & Self Storage",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53113002",
        "yellow_page_code": "91603, 52520, 81824, 52513",
        "kiosk": false
    },
    {
        "value": "422509",
        "label": "Warehouses",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49311006",
        "yellow_page_code": "91604, 91516",
        "kiosk": false
    },
    {
        "value": "422510",
        "label": "Records-Stored",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49311001",
        "yellow_page_code": "70903, 81802, 70922, 81818",
        "kiosk": false
    },
    {
        "value": "422511",
        "label": "Tools-Stored",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49311005",
        "yellow_page_code": "87006",
        "kiosk": false
    },
    {
        "value": "422512",
        "label": "Storage Containers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49311003",
        "yellow_page_code": "81709, 09810, 81726, 21089",
        "kiosk": false
    },
    {
        "value": "422513",
        "label": "Office Warehouses",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49311011",
        "yellow_page_code": "56317",
        "kiosk": false
    },
    {
        "value": "422514",
        "label": "Storage-Climate Controlled",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49311012",
        "yellow_page_code": "81819",
        "kiosk": false
    },
    {
        "value": "422515",
        "label": "Storage Systems Portable",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49311014",
        "yellow_page_code": "81708, 81717, 81728",
        "kiosk": false
    },
    {
        "value": "422601",
        "label": "Automobile Storage",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49319002",
        "yellow_page_code": "05305, 81707, 81833, 13046",
        "kiosk": false
    },
    {
        "value": "422602",
        "label": "Storage-Liquid & Bulk",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49319010",
        "yellow_page_code": "81804",
        "kiosk": false
    },
    {
        "value": "422603",
        "label": "Recreational Vehicles-Storage",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49319008",
        "yellow_page_code": "71003, 81817, 13050",
        "kiosk": false
    },
    {
        "value": "422604",
        "label": "Storage-Industrial & Commercial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49311004",
        "yellow_page_code": "81801, 81712, 54323",
        "kiosk": false
    },
    {
        "value": "422605",
        "label": "Trailers-Camping & Travel-Storage",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49319012",
        "yellow_page_code": "88005",
        "kiosk": false
    },
    {
        "value": "422606",
        "label": "Trailer-Storage",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49319011",
        "yellow_page_code": "87904",
        "kiosk": false
    },
    {
        "value": "422607",
        "label": "Wine Storage",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49319015",
        "yellow_page_code": "93808",
        "kiosk": false
    },
    {
        "value": "422608",
        "label": "Oil Storage",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49319007",
        "yellow_page_code": "56807",
        "kiosk": false
    },
    {
        "value": "422609",
        "label": "Warehouses-Steel & Heavy Machinery",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49319014",
        "yellow_page_code": "91612",
        "kiosk": false
    },
    {
        "value": "422610",
        "label": "Motion Picture Film-Storage",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49319006",
        "yellow_page_code": "54006, 81821",
        "kiosk": false
    },
    {
        "value": "422611",
        "label": "Carpet & Rug-Storage",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49319003",
        "yellow_page_code": "13712",
        "kiosk": false
    },
    {
        "value": "422612",
        "label": "Log Storage Companies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49319004",
        "yellow_page_code": "48513",
        "kiosk": false
    },
    {
        "value": "422613",
        "label": "Lumber Terminals",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49319005",
        "yellow_page_code": "49005",
        "kiosk": false
    },
    {
        "value": "422614",
        "label": "Archives-Storage Products",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49319001",
        "yellow_page_code": "03307",
        "kiosk": false
    },
    {
        "value": "422615",
        "label": "Truck Trailer-Parking & Storage",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49319013",
        "yellow_page_code": "88622",
        "kiosk": false
    },
    {
        "value": "422616",
        "label": "Hazardous Materials-Packing & Storage",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49319016",
        "yellow_page_code": "40325, 40322",
        "kiosk": false
    },
    {
        "value": "422617",
        "label": "Storage-Vehicles-Trailers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49311013",
        "yellow_page_code": "81722",
        "kiosk": false
    },
    {
        "value": "422618",
        "label": "Warehouses-Commercial Storage",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49319017",
        "yellow_page_code": "91520",
        "kiosk": false
    },
    {
        "value": "422699",
        "label": "Special Warehousing & Storage NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49319009",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "423101",
        "label": "Truck Terminals",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48849012",
        "yellow_page_code": "88908, 88625",
        "kiosk": false
    },
    {
        "value": "423102",
        "label": "Dispatch Service-Trucking",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48849002",
        "yellow_page_code": "25018",
        "kiosk": false
    },
    {
        "value": "423103",
        "label": "Bulk Truck Transfer Facility",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48849015",
        "yellow_page_code": "11420",
        "kiosk": false
    },
    {
        "value": "431101",
        "label": "Post Offices",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49111001",
        "yellow_page_code": "67501, 38310, 67440",
        "kiosk": false
    },
    {
        "value": "441298",
        "label": "Deep Sea-Foreign Transport Of Freight",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48311101",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "442401",
        "label": "Ocean-Freight",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48311303",
        "yellow_page_code": "56121",
        "kiosk": false
    },
    {
        "value": "442498",
        "label": "Deep Sea-Domestic Transport Of Freight",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48311301",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "443298",
        "label": "Freight-Transport On The Great Lakes",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48311302",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "444901",
        "label": "Barge Lines & Terminals",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48321101",
        "yellow_page_code": "06602",
        "kiosk": false
    },
    {
        "value": "444902",
        "label": "Shipping Agents",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48321105",
        "yellow_page_code": "76513, 76601, 76516, 76508, 76519",
        "kiosk": false
    },
    {
        "value": "444903",
        "label": "Log Booming",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48321103",
        "yellow_page_code": "48516",
        "kiosk": false
    },
    {
        "value": "444904",
        "label": "Log Rafting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48321104",
        "yellow_page_code": "48509",
        "kiosk": false
    },
    {
        "value": "444905",
        "label": "Boom Trucks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48321102",
        "yellow_page_code": "09219",
        "kiosk": false
    },
    {
        "value": "444906",
        "label": "Shipping-Overseas",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48321108",
        "yellow_page_code": "76510",
        "kiosk": false
    },
    {
        "value": "444999",
        "label": "Water Transportation Of Freight NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48321107",
        "yellow_page_code": "92504",
        "kiosk": false
    },
    {
        "value": "448102",
        "label": "Steamship Terminal Companies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48311401",
        "yellow_page_code": "80905",
        "kiosk": false
    },
    {
        "value": "448198",
        "label": "Deep Sea-Passenger Trans-Except Ferry",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48311201",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "448201",
        "label": "Ferries",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48321201",
        "yellow_page_code": "31400, 08615, 14621, 22702, 31407",
        "kiosk": false
    },
    {
        "value": "448901",
        "label": "River Trips",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48721007",
        "yellow_page_code": "72304, 80072",
        "kiosk": false
    },
    {
        "value": "448902",
        "label": "Boats-Excursions",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48721002",
        "yellow_page_code": "08900, 94713, 08812, 70118, 08817, 93420, 00841",
        "kiosk": false
    },
    {
        "value": "448903",
        "label": "Steamship Companies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48721008",
        "yellow_page_code": "80904, 29854",
        "kiosk": false
    },
    {
        "value": "448904",
        "label": "Marine Transportation Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48321202",
        "yellow_page_code": "50029, 50101, 88216",
        "kiosk": false
    },
    {
        "value": "448905",
        "label": "Air Boats",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48721001",
        "yellow_page_code": "00832",
        "kiosk": false
    },
    {
        "value": "448906",
        "label": "Whale Watching",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48721009",
        "yellow_page_code": "93234, 93237",
        "kiosk": false
    },
    {
        "value": "448907",
        "label": "Dolphin Encounters & Watching",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48721010",
        "yellow_page_code": "25429, 22710",
        "kiosk": false
    },
    {
        "value": "448999",
        "label": "Water-Passenger Transportation NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48321203",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "449101",
        "label": "Moorages",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48831002",
        "yellow_page_code": "53602, 53610",
        "kiosk": false
    },
    {
        "value": "449102",
        "label": "Docks (Facility)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48831001",
        "yellow_page_code": "25304, 65201",
        "kiosk": false
    },
    {
        "value": "449103",
        "label": "Terminals-River & Marine",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48832005",
        "yellow_page_code": "72301, 85204",
        "kiosk": false
    },
    {
        "value": "449104",
        "label": "Ports",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48831003",
        "yellow_page_code": "67408",
        "kiosk": false
    },
    {
        "value": "449105",
        "label": "Ship Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48832003",
        "yellow_page_code": "76504",
        "kiosk": false
    },
    {
        "value": "449106",
        "label": "Lighterage",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48832001",
        "yellow_page_code": "47302",
        "kiosk": false
    },
    {
        "value": "449107",
        "label": "Ship Mooring Line Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48832004",
        "yellow_page_code": "76503",
        "kiosk": false
    },
    {
        "value": "449108",
        "label": "Marine Cargo Handling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48832002",
        "yellow_page_code": "50014, 13437",
        "kiosk": false
    },
    {
        "value": "449109",
        "label": "Terminals Blocks Boards & Strips",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48831005",
        "yellow_page_code": "85120",
        "kiosk": false
    },
    {
        "value": "449110",
        "label": "Container Management Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48832007",
        "yellow_page_code": "21092",
        "kiosk": false
    },
    {
        "value": "449111",
        "label": "Ferryboat Terminal",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48831006",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "449201",
        "label": "Tow Boat Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48321106",
        "yellow_page_code": "87405",
        "kiosk": false
    },
    {
        "value": "449202",
        "label": "Towing-Marine",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48833006",
        "yellow_page_code": "89334, 87506, 50036, 08809, 87417, 50113, 87434, 89395, 75216",
        "kiosk": false
    },
    {
        "value": "449203",
        "label": "Marine Vessel Management",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48833001",
        "yellow_page_code": "50027",
        "kiosk": false
    },
    {
        "value": "449301",
        "label": "Boat Moorages",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71393002",
        "yellow_page_code": "08801",
        "kiosk": false
    },
    {
        "value": "449302",
        "label": "Boat Storage",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71393004",
        "yellow_page_code": "08804, 81803, 81813, 81823, 71715, 08821",
        "kiosk": false
    },
    {
        "value": "449303",
        "label": "Boat Yards",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71393005",
        "yellow_page_code": "08806",
        "kiosk": false
    },
    {
        "value": "449304",
        "label": "Boat Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71393003",
        "yellow_page_code": "08802, 08723, 08624, 08722, 08724, 08635, 08637",
        "kiosk": false
    },
    {
        "value": "449305",
        "label": "Marine Repairs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71393008",
        "yellow_page_code": "50104",
        "kiosk": false
    },
    {
        "value": "449306",
        "label": "Marinas",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71393007",
        "yellow_page_code": "08803, 50006, 08726, 40124",
        "kiosk": false
    },
    {
        "value": "449307",
        "label": "Yacht Harbors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71393011",
        "yellow_page_code": "94703",
        "kiosk": false
    },
    {
        "value": "449308",
        "label": "Dry Docks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71393006",
        "yellow_page_code": "26607",
        "kiosk": false
    },
    {
        "value": "449309",
        "label": "Boat Docks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71393001",
        "yellow_page_code": "08603",
        "kiosk": false
    },
    {
        "value": "449310",
        "label": "Vessel Documentation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71393010",
        "yellow_page_code": "90812",
        "kiosk": false
    },
    {
        "value": "449311",
        "label": "Yacht Rigging",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71393012",
        "yellow_page_code": "94613",
        "kiosk": false
    },
    {
        "value": "449312",
        "label": "Ship Fueling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71393009",
        "yellow_page_code": "76505",
        "kiosk": false
    },
    {
        "value": "449313",
        "label": "Boat Clubs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71393013",
        "yellow_page_code": "08714, 94622, 73501",
        "kiosk": false
    },
    {
        "value": "449901",
        "label": "Surveyors-Marine",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48833005",
        "yellow_page_code": "50105, 82508, 82515, 82523",
        "kiosk": false
    },
    {
        "value": "449902",
        "label": "Salvage-Marine",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48833004",
        "yellow_page_code": "73606, 50018, 08819, 89896",
        "kiosk": false
    },
    {
        "value": "449903",
        "label": "Boat Launching",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48839002",
        "yellow_page_code": "08708, 08814, 46117",
        "kiosk": false
    },
    {
        "value": "449904",
        "label": "Boat Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48839001",
        "yellow_page_code": "08701",
        "kiosk": false
    },
    {
        "value": "449905",
        "label": "Boat Transporting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48839003",
        "yellow_page_code": "08805, 08720",
        "kiosk": false
    },
    {
        "value": "449906",
        "label": "Pilots-Marine",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48833003",
        "yellow_page_code": "65306, 50038",
        "kiosk": false
    },
    {
        "value": "449907",
        "label": "Barges-Leased",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53241102",
        "yellow_page_code": "06606",
        "kiosk": false
    },
    {
        "value": "449908",
        "label": "Marine Ways",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48833002",
        "yellow_page_code": "50023, 50028",
        "kiosk": false
    },
    {
        "value": "449909",
        "label": "Boats-Livery",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48839004",
        "yellow_page_code": "08716",
        "kiosk": false
    },
    {
        "value": "449910",
        "label": "Ship Registers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48839007",
        "yellow_page_code": "76409",
        "kiosk": false
    },
    {
        "value": "449911",
        "label": "Ship Owners & Operators",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48839006",
        "yellow_page_code": "76413",
        "kiosk": false
    },
    {
        "value": "449912",
        "label": "Ship Wreckers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48839008",
        "yellow_page_code": "76509",
        "kiosk": false
    },
    {
        "value": "449913",
        "label": "Marine Documentation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48839005",
        "yellow_page_code": "50015",
        "kiosk": false
    },
    {
        "value": "449914",
        "label": "Lighthouses",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48831004",
        "yellow_page_code": "47320",
        "kiosk": false
    },
    {
        "value": "449915",
        "label": "Port & Harbor Operations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48831007",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "449916",
        "label": "Shipping Navigational Services   ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48833007",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "449999",
        "label": "Water Transportation Services NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48839009",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "451201",
        "label": "Airline Companies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48111102",
        "yellow_page_code": "01700, 01432",
        "kiosk": false
    },
    {
        "value": "451202",
        "label": "Air Cargo Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48111201",
        "yellow_page_code": "00900, 01204",
        "kiosk": false
    },
    {
        "value": "451298",
        "label": "Air Transportation-Scheduled",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48111101",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "451301",
        "label": "Air Courier Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49211001",
        "yellow_page_code": "01106",
        "kiosk": false
    },
    {
        "value": "451302",
        "label": "Expediters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49211003",
        "yellow_page_code": "30109, 30102",
        "kiosk": false
    },
    {
        "value": "451303",
        "label": "Parcels For Foreign Countries",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49211004",
        "yellow_page_code": "59306, 58136",
        "kiosk": false
    },
    {
        "value": "452201",
        "label": "Helicopter-Charter & Rental Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48799002",
        "yellow_page_code": "40906, 40925, 05333",
        "kiosk": false
    },
    {
        "value": "452202",
        "label": "Air Ambulance Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62191001",
        "yellow_page_code": "00805, 75136, 02114",
        "kiosk": false
    },
    {
        "value": "452203",
        "label": "Aerial Personnel Lifts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48121101",
        "yellow_page_code": "00708",
        "kiosk": false
    },
    {
        "value": "452204",
        "label": "Helicopter Charter & Tours",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48121102",
        "yellow_page_code": "40927, 87312, 00717",
        "kiosk": false
    },
    {
        "value": "452205",
        "label": "Air Sightseeing Tours",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48799005",
        "yellow_page_code": "00916",
        "kiosk": false
    },
    {
        "value": "452206",
        "label": "Airplane Rides",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48799006",
        "yellow_page_code": "01711",
        "kiosk": false
    },
    {
        "value": "452207",
        "label": "Nonscheduled Passenger Air Transportation ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48121103",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "452208",
        "label": "Specialty Nonscheduled Air Transportation ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48121907",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "452298",
        "label": "Air Transportation-Nonscheduled",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48121201",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "458101",
        "label": "Aircraft Ground Support & Service Equip",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48819002",
        "yellow_page_code": "01502",
        "kiosk": false
    },
    {
        "value": "458102",
        "label": "Aircraft Engines-Servicing & Maintenance",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48819001",
        "yellow_page_code": "01407",
        "kiosk": false
    },
    {
        "value": "458103",
        "label": "Aircraft Ferrying & Transporting Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48811901",
        "yellow_page_code": "01500",
        "kiosk": false
    },
    {
        "value": "458104",
        "label": "Aircraft Servicing & Maintenance",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48819004",
        "yellow_page_code": "01602, 01410, 05329, 01423, 01428",
        "kiosk": false
    },
    {
        "value": "458105",
        "label": "Aircraft Storage",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48811903",
        "yellow_page_code": "01604",
        "kiosk": false
    },
    {
        "value": "458106",
        "label": "Airports",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48811907",
        "yellow_page_code": "68861",
        "kiosk": false
    },
    {
        "value": "458107",
        "label": "Aircraft Upholsterers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48811905",
        "yellow_page_code": "01606",
        "kiosk": false
    },
    {
        "value": "458108",
        "label": "Helicopter-Servicing & Maintenance",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48819005",
        "yellow_page_code": "41003",
        "kiosk": false
    },
    {
        "value": "458109",
        "label": "Air Traffic Control Tower",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48811101",
        "yellow_page_code": "01305",
        "kiosk": false
    },
    {
        "value": "458110",
        "label": "Aircraft Refinishing & Painting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48819003",
        "yellow_page_code": "01609, 01422",
        "kiosk": false
    },
    {
        "value": "458111",
        "label": "Airline Support Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48811906",
        "yellow_page_code": "01701, 05326, 05417, 89510",
        "kiosk": false
    },
    {
        "value": "458112",
        "label": "Aircraft Hangars-Rental & Sales",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48811902",
        "yellow_page_code": "01603",
        "kiosk": false
    },
    {
        "value": "458113",
        "label": "Aircraft Washing & Polishing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899046",
        "yellow_page_code": "01615",
        "kiosk": false
    },
    {
        "value": "458114",
        "label": "Aircraft Support Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48811904",
        "yellow_page_code": "01618",
        "kiosk": false
    },
    {
        "value": "458115",
        "label": "Airport Authority & Terminal Svcs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48811908",
        "yellow_page_code": "90013",
        "kiosk": false
    },
    {
        "value": "458116",
        "label": "Aircraft-Restore & Customize",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48819007",
        "yellow_page_code": "01425",
        "kiosk": false
    },
    {
        "value": "458117",
        "label": "Sea Plane Base",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48811909",
        "yellow_page_code": "75116",
        "kiosk": false
    },
    {
        "value": "458118",
        "label": "Airport Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48811910",
        "yellow_page_code": "01802",
        "kiosk": false
    },
    {
        "value": "458119",
        "label": "Heliports",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48811911",
        "yellow_page_code": "40914",
        "kiosk": false
    },
    {
        "value": "461201",
        "label": "Pipe Line Companies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48611002",
        "yellow_page_code": "65606, 65609",
        "kiosk": false
    },
    {
        "value": "461298",
        "label": "Crude Petroleum Pipelines",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48611001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "461398",
        "label": "Refined Petroleum Pipelines",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48691001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "461901",
        "label": "Coal Hauling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48699002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "461999",
        "label": "Pipelines NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48699001",
        "yellow_page_code": "65775",
        "kiosk": false
    },
    {
        "value": "472401",
        "label": "Tourist Information",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56151004",
        "yellow_page_code": "87400, 88407, 87310",
        "kiosk": false
    },
    {
        "value": "472402",
        "label": "Travel Agencies & Bureaus",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56151007",
        "yellow_page_code": "88406, 44110, 41127, 11726, 43789",
        "kiosk": false
    },
    {
        "value": "472403",
        "label": "Travel Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56151008",
        "yellow_page_code": "88409",
        "kiosk": false
    },
    {
        "value": "472404",
        "label": "Trains-Ticket Agencies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56151006",
        "yellow_page_code": "88114, 88126",
        "kiosk": false
    },
    {
        "value": "472405",
        "label": "Golf Vacation Packages",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56151003",
        "yellow_page_code": "38211",
        "kiosk": false
    },
    {
        "value": "472406",
        "label": "Cruises",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56151001",
        "yellow_page_code": "22708, 22705, 30140, 93076",
        "kiosk": false
    },
    {
        "value": "472407",
        "label": "Destination Management",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56151002",
        "yellow_page_code": "24315",
        "kiosk": false
    },
    {
        "value": "472408",
        "label": "Tours-Sound Tape Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56151005",
        "yellow_page_code": "87311",
        "kiosk": false
    },
    {
        "value": "472409",
        "label": "Viatical Benefits",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56151009",
        "yellow_page_code": "91029",
        "kiosk": false
    },
    {
        "value": "472410",
        "label": "Wedding Honeymoon Planners",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56151010",
        "yellow_page_code": "92950",
        "kiosk": false
    },
    {
        "value": "472411",
        "label": "Travel Adventure",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56151011",
        "yellow_page_code": "88425",
        "kiosk": false
    },
    {
        "value": "472412",
        "label": "Adventure Vacations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56151012",
        "yellow_page_code": "00450, 89895",
        "kiosk": false
    },
    {
        "value": "472501",
        "label": "Tours-Operators & Promoters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56152007",
        "yellow_page_code": "87404, 88412, 44137, 14714, 88415, 27218, 25392, 80077, 44721",
        "kiosk": false
    },
    {
        "value": "472502",
        "label": "Skiing Tours",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56152005",
        "yellow_page_code": "78202",
        "kiosk": false
    },
    {
        "value": "472503",
        "label": "Expeditions-Arranged & Outfitted",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56152002",
        "yellow_page_code": "30105",
        "kiosk": false
    },
    {
        "value": "472504",
        "label": "Tours-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56152008",
        "yellow_page_code": "87403",
        "kiosk": false
    },
    {
        "value": "472505",
        "label": "Skin Diving Tours",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56152006",
        "yellow_page_code": "78111",
        "kiosk": false
    },
    {
        "value": "472506",
        "label": "Golf Tournament Booking Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56152004",
        "yellow_page_code": "38303, 38217",
        "kiosk": false
    },
    {
        "value": "472507",
        "label": "Bicycle Tours",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56152001",
        "yellow_page_code": "07907, 87410, 08020, 23203",
        "kiosk": false
    },
    {
        "value": "472508",
        "label": "Float Trips",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56152003",
        "yellow_page_code": "33011",
        "kiosk": false
    },
    {
        "value": "472509",
        "label": "Travel Escorts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56152009",
        "yellow_page_code": "88500",
        "kiosk": false
    },
    {
        "value": "472510",
        "label": "Snowcat Rentals",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56152010",
        "yellow_page_code": "78626",
        "kiosk": false
    },
    {
        "value": "472511",
        "label": "Winery Tours",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56152011",
        "yellow_page_code": "93820, 93727",
        "kiosk": false
    },
    {
        "value": "472512",
        "label": "Submarine Tours",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56152012",
        "yellow_page_code": "82113",
        "kiosk": false
    },
    {
        "value": "472513",
        "label": "Farm Tours",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56152013",
        "yellow_page_code": "30910, 30823",
        "kiosk": false
    },
    {
        "value": "472514",
        "label": "Art Tours",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56152014",
        "yellow_page_code": "03421",
        "kiosk": false
    },
    {
        "value": "472515",
        "label": "Virtual Tours",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56152015",
        "yellow_page_code": "91246, 38498",
        "kiosk": false
    },
    {
        "value": "472516",
        "label": "Golf Tournaments",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56152016",
        "yellow_page_code": "38210",
        "kiosk": false
    },
    {
        "value": "472517",
        "label": "Bear Viewing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56152017",
        "yellow_page_code": "07140",
        "kiosk": false
    },
    {
        "value": "472518",
        "label": "Motorcycle Tours",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56152018",
        "yellow_page_code": "54329",
        "kiosk": false
    },
    {
        "value": "472901",
        "label": "Airline Ticket Agencies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56159901",
        "yellow_page_code": "01702, 01313, 01703, 85906, 33023",
        "kiosk": false
    },
    {
        "value": "472902",
        "label": "Railroads-Ticket Agencies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56159906",
        "yellow_page_code": "70302",
        "kiosk": false
    },
    {
        "value": "472903",
        "label": "Bus Lines-Ticket Agencies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56159903",
        "yellow_page_code": "11604",
        "kiosk": false
    },
    {
        "value": "472904",
        "label": "Steamship Agencies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56159907",
        "yellow_page_code": "80902",
        "kiosk": false
    },
    {
        "value": "472905",
        "label": "Ride Share Information",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48899901",
        "yellow_page_code": "72112, 20018",
        "kiosk": false
    },
    {
        "value": "472906",
        "label": "Helicopter Tours-Ticket Agencies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56159904",
        "yellow_page_code": "40919",
        "kiosk": false
    },
    {
        "value": "472907",
        "label": "Car Pools",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48899902",
        "yellow_page_code": "13010",
        "kiosk": false
    },
    {
        "value": "472908",
        "label": "Car Sharing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48899903",
        "yellow_page_code": "13081",
        "kiosk": false
    },
    {
        "value": "472999",
        "label": "Arrangement Of Passenger Trnsprtn NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56159902",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "473101",
        "label": "Transportation Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48851011",
        "yellow_page_code": "88400, 88326, 88310, 88320, 21163, 88317",
        "kiosk": false
    },
    {
        "value": "473102",
        "label": "Freight-Consolidating",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48851005",
        "yellow_page_code": "34404",
        "kiosk": false
    },
    {
        "value": "473103",
        "label": "Foreign Trade Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161804",
        "yellow_page_code": "34003, 34015",
        "kiosk": false
    },
    {
        "value": "473104",
        "label": "Freight-Forwarding",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48851006",
        "yellow_page_code": "34406, 31417, 58301, 58318, 49724, 30284",
        "kiosk": false
    },
    {
        "value": "473105",
        "label": "Brokers-Motor Transportation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48851001",
        "yellow_page_code": "10903",
        "kiosk": false
    },
    {
        "value": "473106",
        "label": "Freight-Traffic Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48851008",
        "yellow_page_code": "34500, 30449",
        "kiosk": false
    },
    {
        "value": "473107",
        "label": "Containerized Freight & Cargo Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48851002",
        "yellow_page_code": "21104",
        "kiosk": false
    },
    {
        "value": "473108",
        "label": "Brokers-Merchandise",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161801",
        "yellow_page_code": "10905",
        "kiosk": false
    },
    {
        "value": "473109",
        "label": "Freight-Bill Audit",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161805",
        "yellow_page_code": "34402",
        "kiosk": false
    },
    {
        "value": "473110",
        "label": "Customs Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161803",
        "yellow_page_code": "23003, 23019",
        "kiosk": false
    },
    {
        "value": "473111",
        "label": "Freight Brokers & Agents",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48851004",
        "yellow_page_code": "34401, 89005, 44526",
        "kiosk": false
    },
    {
        "value": "473112",
        "label": "Transportation Lines",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48851012",
        "yellow_page_code": "88405, 88330",
        "kiosk": false
    },
    {
        "value": "473113",
        "label": "Customs Brokers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161802",
        "yellow_page_code": "23004, 23101",
        "kiosk": false
    },
    {
        "value": "473114",
        "label": "Trade Clearing Exchanges",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161810",
        "yellow_page_code": "87707",
        "kiosk": false
    },
    {
        "value": "473115",
        "label": "Tariff Bureaus",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161809",
        "yellow_page_code": "83907",
        "kiosk": false
    },
    {
        "value": "473116",
        "label": "Truck-Permit Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161811",
        "yellow_page_code": "88808",
        "kiosk": false
    },
    {
        "value": "473117",
        "label": "Permit Service-Transportation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161808",
        "yellow_page_code": "60301",
        "kiosk": false
    },
    {
        "value": "473118",
        "label": "Routing Service-Air/Highway/Marine",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48851010",
        "yellow_page_code": "72901",
        "kiosk": false
    },
    {
        "value": "473119",
        "label": "Freight-Traffic Managers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48851007",
        "yellow_page_code": "34409",
        "kiosk": false
    },
    {
        "value": "473120",
        "label": "Cotton Shippers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48851003",
        "yellow_page_code": "22105",
        "kiosk": false
    },
    {
        "value": "473121",
        "label": "Publishers-Shipping Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48851009",
        "yellow_page_code": "69007",
        "kiosk": false
    },
    {
        "value": "473122",
        "label": "Interstate Commerce Commission Prctnrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161806",
        "yellow_page_code": "43601",
        "kiosk": false
    },
    {
        "value": "473123",
        "label": "Material Handling Eqpt-Certification Bur",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161807",
        "yellow_page_code": "50414",
        "kiosk": false
    },
    {
        "value": "473124",
        "label": "Truck-Transport Preparation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48851013",
        "yellow_page_code": "88913",
        "kiosk": false
    },
    {
        "value": "473125",
        "label": "Trucking Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48851014",
        "yellow_page_code": "89013, 89021, 89028",
        "kiosk": false
    },
    {
        "value": "473126",
        "label": "Dog Sled Freight Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48851015",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "473127",
        "label": "Mail Order Fulfillment Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161420",
        "yellow_page_code": "49701",
        "kiosk": false
    },
    {
        "value": "474101",
        "label": "Railroad Car Leasing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53241103",
        "yellow_page_code": "70201",
        "kiosk": false
    },
    {
        "value": "474102",
        "label": "Railroad Cars-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53241104",
        "yellow_page_code": "70209, 70211",
        "kiosk": false
    },
    {
        "value": "478301",
        "label": "Packing & Crating Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48899102",
        "yellow_page_code": "22414, 22402, 58404, 58420, 22323, 29970, 30532",
        "kiosk": false
    },
    {
        "value": "478302",
        "label": "Export Packing Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48899101",
        "yellow_page_code": "30211",
        "kiosk": false
    },
    {
        "value": "478501",
        "label": "Weighers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48849014",
        "yellow_page_code": "93006",
        "kiosk": false
    },
    {
        "value": "478502",
        "label": "Scales-Public",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48849005",
        "yellow_page_code": "74303",
        "kiosk": false
    },
    {
        "value": "478503",
        "label": "Truck Testing Stations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48849013",
        "yellow_page_code": "88909",
        "kiosk": false
    },
    {
        "value": "478504",
        "label": "Surveyors-Cargo",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48849007",
        "yellow_page_code": "82504",
        "kiosk": false
    },
    {
        "value": "478505",
        "label": "Bridge & Tunnel Operating Companies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48849001",
        "yellow_page_code": "10704",
        "kiosk": false
    },
    {
        "value": "478506",
        "label": "Toll Bridges",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48849010",
        "yellow_page_code": "86913",
        "kiosk": false
    },
    {
        "value": "478507",
        "label": "Cargo Superintendents",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48839011",
        "yellow_page_code": "13438",
        "kiosk": false
    },
    {
        "value": "478508",
        "label": "Truck Scales",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48849011",
        "yellow_page_code": "88810, 93011",
        "kiosk": false
    },
    {
        "value": "478509",
        "label": "Freight-Inspection",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48849004",
        "yellow_page_code": "34410",
        "kiosk": false
    },
    {
        "value": "478510",
        "label": "Toll Booth",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48849016",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "478511",
        "label": "Misc Road Transportation Support",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48849017",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "478598",
        "label": "Fixed Facilities & Inspection/Weighing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48849003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "478901",
        "label": "Car Loading & Unloading",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48821001",
        "yellow_page_code": "13006, 70212",
        "kiosk": false
    },
    {
        "value": "478902",
        "label": "Pilot Car Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48821003",
        "yellow_page_code": "65304, 56464, 32820",
        "kiosk": false
    },
    {
        "value": "478903",
        "label": "Carriages-Horse",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48711002",
        "yellow_page_code": "13809, 13811, 41514, 41525, 92936, 92992",
        "kiosk": false
    },
    {
        "value": "478904",
        "label": "Stock Yards",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48821007",
        "yellow_page_code": "81508, 48303",
        "kiosk": false
    },
    {
        "value": "478905",
        "label": "Railroad Equip & Supplies-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48821004",
        "yellow_page_code": "70203, 70215, 70210",
        "kiosk": false
    },
    {
        "value": "478906",
        "label": "Pedicabs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48711004",
        "yellow_page_code": "60111",
        "kiosk": false
    },
    {
        "value": "478907",
        "label": "Hospital Transportation Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48821002",
        "yellow_page_code": "41718",
        "kiosk": false
    },
    {
        "value": "478908",
        "label": "Steamship Conferences",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48821006",
        "yellow_page_code": "80812",
        "kiosk": false
    },
    {
        "value": "478909",
        "label": "Transportation Equipment-Leasing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48821009",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "478910",
        "label": "Fuel Products Transporting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48422016",
        "yellow_page_code": "36514, 36810, 34821, 36525",
        "kiosk": false
    },
    {
        "value": "478911",
        "label": "Support Activities for Transportation ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48899905",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "478977",
        "label": "Transportation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48821008",
        "yellow_page_code": "93729, 21377",
        "kiosk": false
    },
    {
        "value": "478978",
        "label": "Truck & Trailer Escort Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48899904",
        "yellow_page_code": "88640",
        "kiosk": false
    },
    {
        "value": "478999",
        "label": "Transportation Services NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48821010",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "481201",
        "label": "Paging & Signaling Svc-Common Carrier",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51711203",
        "yellow_page_code": "58503",
        "kiosk": false
    },
    {
        "value": "481202",
        "label": "Paging & Signaling Service-Private Systs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51711202",
        "yellow_page_code": "58505",
        "kiosk": false
    },
    {
        "value": "481203",
        "label": "Emergency Reporting Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51711207",
        "yellow_page_code": "28413, 60226",
        "kiosk": false
    },
    {
        "value": "481204",
        "label": "Radiotelephone Communications",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51711211",
        "yellow_page_code": "70008, 70010, 70013, 70009, 30258",
        "kiosk": false
    },
    {
        "value": "481206",
        "label": "Beepers-Equipment Service & Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51711201",
        "yellow_page_code": "07505, 07510, 30128",
        "kiosk": false
    },
    {
        "value": "481207",
        "label": "Cellular Telephones (Services)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51711214",
        "yellow_page_code": "53206, 14303, 84412, 93925, 14235, 14310, 14238, 14250, 93930, 84420, 93927, 93929, 14314, 84431, 14239, 13067, 43670",
        "kiosk": false
    },
    {
        "value": "481208",
        "label": "Marine-Radio Telephone Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51711208",
        "yellow_page_code": "50007",
        "kiosk": false
    },
    {
        "value": "481209",
        "label": "Mobile Radio Emergency Communications",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51711209",
        "yellow_page_code": "53211",
        "kiosk": false
    },
    {
        "value": "481210",
        "label": "Pagers-Sales & Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51711204",
        "yellow_page_code": "58430",
        "kiosk": false
    },
    {
        "value": "481211",
        "label": "Paging Equipment-Sales & Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51711205",
        "yellow_page_code": "58431",
        "kiosk": false
    },
    {
        "value": "481212",
        "label": "Paging & Signaling Equipment & Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51711206",
        "yellow_page_code": "58432",
        "kiosk": false
    },
    {
        "value": "481213",
        "label": "Authorized Retailer-Cellular Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51711215",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "481214",
        "label": "Wireless Telecommunications Carriers-Ex Satellite",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51711216",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "481301",
        "label": "Data Communication Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51712101",
        "yellow_page_code": "23404",
        "kiosk": false
    },
    {
        "value": "481302",
        "label": "Telecommunications Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51781016",
        "yellow_page_code": "84207, 84307, 84242, 43571, 30042, 43656",
        "kiosk": false
    },
    {
        "value": "481304",
        "label": "Telephone Companies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51711106",
        "yellow_page_code": "84308, 84243, 84240, 24727, 84320, 84322, 84325",
        "kiosk": false
    },
    {
        "value": "481305",
        "label": "Telephone Companies-Non-Wire Line",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51711107",
        "yellow_page_code": "84400",
        "kiosk": false
    },
    {
        "value": "481306",
        "label": "Long Distance Telephone Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51711103",
        "yellow_page_code": "48607, 84409, 84432, 20016, 43776",
        "kiosk": false
    },
    {
        "value": "481307",
        "label": "Voice Messaging Equipment & Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51781017",
        "yellow_page_code": "91235, 91230, 91239, 91243, 91229, 79817, 91249, 91238",
        "kiosk": false
    },
    {
        "value": "481308",
        "label": "Telephone Equipment & Systems-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51781018",
        "yellow_page_code": "84416",
        "kiosk": false
    },
    {
        "value": "481309",
        "label": "Telephone Line Providers-Toll Numbers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51781019",
        "yellow_page_code": "84324",
        "kiosk": false
    },
    {
        "value": "481310",
        "label": "Mobile Telephone Service-Non Cellular",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51781020",
        "yellow_page_code": "53215",
        "kiosk": false
    },
    {
        "value": "481311",
        "label": "Augmentative & Assistance Communications",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51781021",
        "yellow_page_code": "04416",
        "kiosk": false
    },
    {
        "value": "481312",
        "label": "Teleprinter Communication Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51711118",
        "yellow_page_code": "84590",
        "kiosk": false
    },
    {
        "value": "481313",
        "label": "Wired Telecommunications Carriers ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51711119",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "482201",
        "label": "Facsimile Transmission Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51711102",
        "yellow_page_code": "30508, 31018, 31021",
        "kiosk": false
    },
    {
        "value": "482202",
        "label": "Telegraph Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51711105",
        "yellow_page_code": "84300, 84299",
        "kiosk": false
    },
    {
        "value": "482203",
        "label": "Electronic Mail Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51711101",
        "yellow_page_code": "28103, 27079, 27071",
        "kiosk": false
    },
    {
        "value": "482204",
        "label": "Teletypewriter Communications Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51711108",
        "yellow_page_code": "84502, 85000, 84427",
        "kiosk": false
    },
    {
        "value": "482205",
        "label": "Telegram Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51711104",
        "yellow_page_code": "84229, 84235, 84296",
        "kiosk": false
    },
    {
        "value": "482206",
        "label": "Video Conferencing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51711109",
        "yellow_page_code": "91015, 91041, 91044, 92891, 91146, 29881",
        "kiosk": false
    },
    {
        "value": "482207",
        "label": "Internet Services-Fax Transmission Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51711117",
        "yellow_page_code": "43651",
        "kiosk": false
    },
    {
        "value": "483201",
        "label": "Radio Stations & Broadcasting Companies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51611003",
        "yellow_page_code": "69906",
        "kiosk": false
    },
    {
        "value": "483202",
        "label": "Government-Radio Broadcasting Stations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51611002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "483203",
        "label": "Broadcasting Companies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51611001",
        "yellow_page_code": "10812",
        "kiosk": false
    },
    {
        "value": "483301",
        "label": "Television Stations & Broadcasting Co",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51612001",
        "yellow_page_code": "84804, 85017, 43734",
        "kiosk": false
    },
    {
        "value": "483302",
        "label": "TV Stations & Broadcasting Co Cnslnts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51612002",
        "yellow_page_code": "84822",
        "kiosk": false
    },
    {
        "value": "483303",
        "label": "Television Service Providers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51612003",
        "yellow_page_code": "84910",
        "kiosk": false
    },
    {
        "value": "484101",
        "label": "Television-Cable & Catv",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51612005",
        "yellow_page_code": "12204, 84901, 84606, 12137, 12138, 84616, 84625, 12140, 84902, 84813, 91026, 73933, 84621, 85024, 24729, 91065, 29862, 43691",
        "kiosk": false
    },
    {
        "value": "484102",
        "label": "Antennas-Television Community Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51711110",
        "yellow_page_code": "02709",
        "kiosk": false
    },
    {
        "value": "484103",
        "label": "Satellite Comms Services-Common Carrier",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51711112",
        "yellow_page_code": "73902, 73919, 73928, 73921, 73922",
        "kiosk": false
    },
    {
        "value": "484104",
        "label": "Television-Cable & Catv-Equip & Supls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51711115",
        "yellow_page_code": "84803",
        "kiosk": false
    },
    {
        "value": "484105",
        "label": "Television Syst-Closed Circuit Tlcstng",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51711114",
        "yellow_page_code": "84907",
        "kiosk": false
    },
    {
        "value": "484107",
        "label": "Satellite Descrambling/Programming Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51711113",
        "yellow_page_code": "73911, 73926",
        "kiosk": false
    },
    {
        "value": "484108",
        "label": "Cable Television Equipment-Retailers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51711111",
        "yellow_page_code": "12117",
        "kiosk": false
    },
    {
        "value": "484109",
        "label": "Captioning Services-Television",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51711116",
        "yellow_page_code": "13100",
        "kiosk": false
    },
    {
        "value": "484110",
        "label": "Television Iptv",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51612008",
        "yellow_page_code": "84628",
        "kiosk": false
    },
    {
        "value": "489901",
        "label": "Communications Services-Common Carriers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51781003",
        "yellow_page_code": "20100, 19915, 84260, 30377",
        "kiosk": false
    },
    {
        "value": "489902",
        "label": "Marine Operators",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51711212",
        "yellow_page_code": "50021",
        "kiosk": false
    },
    {
        "value": "489903",
        "label": "Communications",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51781001",
        "yellow_page_code": "20000, 43596, 56443, 56553",
        "kiosk": false
    },
    {
        "value": "489904",
        "label": "Television Transmission Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51711213",
        "yellow_page_code": "84626",
        "kiosk": false
    },
    {
        "value": "489905",
        "label": "Video Transmission Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51781005",
        "yellow_page_code": "91110",
        "kiosk": false
    },
    {
        "value": "489906",
        "label": "Emergency Calls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51781006",
        "yellow_page_code": "28415",
        "kiosk": false
    },
    {
        "value": "489907",
        "label": "Media Streaming SVC-Audio/Video/Vlog",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51621002",
        "yellow_page_code": "48650, 43565, 92935",
        "kiosk": false
    },
    {
        "value": "489908",
        "label": "Telephone Reassurance-Monitoring",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51781004",
        "yellow_page_code": "84419",
        "kiosk": false
    },
    {
        "value": "489910",
        "label": "Satellite Phones",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51741001",
        "yellow_page_code": "73930",
        "kiosk": false
    },
    {
        "value": "489911",
        "label": "Satellite Imagery",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51741002",
        "yellow_page_code": "73901",
        "kiosk": false
    },
    {
        "value": "489912",
        "label": "Agents for Wireless Telecommunications Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51712201",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "489913",
        "label": "Satellite Telecommunications",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51741003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "489999",
        "label": "Communications Services NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51781002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "491101",
        "label": "Electric Companies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "22112202",
        "yellow_page_code": "27500, 67901, 42117, 27512, 27612, 27819, 27407, 28618, 27517, 27609, 67921",
        "kiosk": false
    },
    {
        "value": "491102",
        "label": "Uninterruptible Power Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33599908",
        "yellow_page_code": "89906, 67930",
        "kiosk": false
    },
    {
        "value": "491104",
        "label": "Power Plants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "22111804",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "492201",
        "label": "Gas Transmission Companies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48621001",
        "yellow_page_code": "36713, 36727",
        "kiosk": false
    },
    {
        "value": "492298",
        "label": "Natural Gas Transmission",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48621002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "492398",
        "label": "Natural Gas Transmission & Distribution",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "22121009",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "492401",
        "label": "Gas-Natural",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "22121007",
        "yellow_page_code": "36800, 55114, 55115, 36540",
        "kiosk": false
    },
    {
        "value": "492402",
        "label": "Heat Supplying Companies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "22121008",
        "yellow_page_code": "40704",
        "kiosk": false
    },
    {
        "value": "492501",
        "label": "Gas Companies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "22121002",
        "yellow_page_code": "36600",
        "kiosk": false
    },
    {
        "value": "492502",
        "label": "Gas Processors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "22121003",
        "yellow_page_code": "36803, 36526",
        "kiosk": false
    },
    {
        "value": "492503",
        "label": "Gas Producers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "22121004",
        "yellow_page_code": "36802",
        "kiosk": false
    },
    {
        "value": "492504",
        "label": "Gas Recycling Plants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "22121005",
        "yellow_page_code": "36505",
        "kiosk": false
    },
    {
        "value": "492505",
        "label": "Propane-Bulk & Bottle-Delivery",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "22121010",
        "yellow_page_code": "68618",
        "kiosk": false
    },
    {
        "value": "493101",
        "label": "Co-Generation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23713021",
        "yellow_page_code": "19312",
        "kiosk": false
    },
    {
        "value": "493102",
        "label": "Nuclear Electric Power Generation ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "22111301",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "493103",
        "label": "Solar Electric Power Generation ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "22111401",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "493104",
        "label": "Wind Electric Power Generation ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "22111501",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "493105",
        "label": "Geothermal Electric Power Generation ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "22111601",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "493106",
        "label": "Biomass Electric Power Generation ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "22111701",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "493107",
        "label": "Electric Bulk Power Transmission & Control ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "22112101",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "493198",
        "label": "Electric & Other Services-Combined",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "22112201",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "493201",
        "label": "Gas Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "22121006",
        "yellow_page_code": "36701",
        "kiosk": false
    },
    {
        "value": "493298",
        "label": "Gas & Other Services-Combined",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "22121001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "493901",
        "label": "Power Take-Offs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "22111802",
        "yellow_page_code": "68000",
        "kiosk": false
    },
    {
        "value": "493902",
        "label": "Utilities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161832",
        "yellow_page_code": "90202, 90206, 43584, 90193, 90194, 90195, 93219, 28652, 68817",
        "kiosk": false
    },
    {
        "value": "493903",
        "label": "Utilities-Underground-Cable Locating Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23713022",
        "yellow_page_code": "90205, 89907, 65706, 48435, 68814, 47530",
        "kiosk": false
    },
    {
        "value": "493904",
        "label": "Utility Management",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161833",
        "yellow_page_code": "90201",
        "kiosk": false
    },
    {
        "value": "493905",
        "label": "Water System Billing Management & Supls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "22111803",
        "yellow_page_code": "92129",
        "kiosk": false
    },
    {
        "value": "493906",
        "label": "Fossil Fuel Electric Power Generation ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "22111201",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "493907",
        "label": "Other Electric Power Generation ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "22111801",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "494102",
        "label": "Water & Sewage Companies-Utility",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "22131003",
        "yellow_page_code": "92106, 92311, 92036",
        "kiosk": false
    },
    {
        "value": "494103",
        "label": "Water Supply Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "22131006",
        "yellow_page_code": "92502, 92120, 92031",
        "kiosk": false
    },
    {
        "value": "494104",
        "label": "Water Conservation Products & Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "22131004",
        "yellow_page_code": "92113",
        "kiosk": false
    },
    {
        "value": "494105",
        "label": "Water Treatment Management Plant",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "22131007",
        "yellow_page_code": "92513, 92112",
        "kiosk": false
    },
    {
        "value": "494106",
        "label": "Water Supply & Irrigation Systems ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "22131010",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "495201",
        "label": "Sewage Disposal Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "22132001",
        "yellow_page_code": "75702, 75701, 75706, 75812, 75717, 75824, 91828, 75718",
        "kiosk": false
    },
    {
        "value": "495202",
        "label": "Sewage Treatment Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "22132003",
        "yellow_page_code": "75704",
        "kiosk": false
    },
    {
        "value": "495203",
        "label": "Sewer Companies-Utility",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "22132004",
        "yellow_page_code": "75801, 75813, 75820",
        "kiosk": false
    },
    {
        "value": "495204",
        "label": "Sewage Treatment Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "22132002",
        "yellow_page_code": "75703",
        "kiosk": false
    },
    {
        "value": "495205",
        "label": "Sewer System Inspection/Rehabilitation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "22132005",
        "yellow_page_code": "75808",
        "kiosk": false
    },
    {
        "value": "495301",
        "label": "Dumps-Rubbish",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56221201",
        "yellow_page_code": "26900",
        "kiosk": false
    },
    {
        "value": "495302",
        "label": "Garbage Collection",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56211901",
        "yellow_page_code": "25107, 36108, 73105, 73106, 88404, 26835, 71302, 36120, 73104, 36122, 36123, 26890, 88418, 71712, 56474, 66220, 41483, 11427",
        "kiosk": false
    },
    {
        "value": "495303",
        "label": "Landfills-Sanitary",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56221202",
        "yellow_page_code": "45806, 45738, 45808, 36121",
        "kiosk": false
    },
    {
        "value": "495304",
        "label": "Waste Disposal",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56221908",
        "yellow_page_code": "91802, 91822",
        "kiosk": false
    },
    {
        "value": "495305",
        "label": "Waste Disposal-Hazardous",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56221103",
        "yellow_page_code": "40312, 91805, 91810, 40319, 40326, 40330, 08222, 36125, 08223, 40328",
        "kiosk": false
    },
    {
        "value": "495306",
        "label": "Waste Containers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56221907",
        "yellow_page_code": "88401, 91806, 91820, 21171, 21174",
        "kiosk": false
    },
    {
        "value": "495307",
        "label": "Sanitation Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56221906",
        "yellow_page_code": "73900, 73814",
        "kiosk": false
    },
    {
        "value": "495308",
        "label": "Sanitation Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56221905",
        "yellow_page_code": "73903",
        "kiosk": false
    },
    {
        "value": "495309",
        "label": "Toxic Waste Removal Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56221101",
        "yellow_page_code": "87515",
        "kiosk": false
    },
    {
        "value": "495310",
        "label": "Refuse Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56221102",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "495311",
        "label": "Recycling Equipment & Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56292001",
        "yellow_page_code": "71101, 71038, 78212, 71039",
        "kiosk": false
    },
    {
        "value": "495312",
        "label": "Landfills-Solid Waste",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56221203",
        "yellow_page_code": "45812, 91839",
        "kiosk": false
    },
    {
        "value": "495313",
        "label": "Refuse Disposal-Depots & Plants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56221204",
        "yellow_page_code": "71511",
        "kiosk": false
    },
    {
        "value": "495314",
        "label": "Waste Materials",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56221104",
        "yellow_page_code": "91809",
        "kiosk": false
    },
    {
        "value": "495315",
        "label": "Waste Removal-Medical",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56221105",
        "yellow_page_code": "91815, 51019, 51060, 91819, 51040",
        "kiosk": false
    },
    {
        "value": "495316",
        "label": "Liquid Waste Removal",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56221903",
        "yellow_page_code": "47803, 47804",
        "kiosk": false
    },
    {
        "value": "495317",
        "label": "Disposal Wells",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56221901",
        "yellow_page_code": "25105",
        "kiosk": false
    },
    {
        "value": "495318",
        "label": "Pet Waste Removal",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56221904",
        "yellow_page_code": "60515",
        "kiosk": false
    },
    {
        "value": "495319",
        "label": "Bomb Disposal",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56211202",
        "yellow_page_code": "09112",
        "kiosk": false
    },
    {
        "value": "495320",
        "label": "Inorganic Waste Treatment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56221902",
        "yellow_page_code": "43122",
        "kiosk": false
    },
    {
        "value": "495321",
        "label": "Hazardous Materials Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56221106",
        "yellow_page_code": "40310",
        "kiosk": false
    },
    {
        "value": "495322",
        "label": "Waste Water Disposal",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56221909",
        "yellow_page_code": "91915, 92048",
        "kiosk": false
    },
    {
        "value": "495323",
        "label": "Crime & Trauma Scene Clean Up",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56221109",
        "yellow_page_code": "22615, 18513, 88440",
        "kiosk": false
    },
    {
        "value": "495324",
        "label": "Meth Lab Cleanup",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56221107",
        "yellow_page_code": "52010",
        "kiosk": false
    },
    {
        "value": "495325",
        "label": "Industrial Waste Removal",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56221108",
        "yellow_page_code": "42932",
        "kiosk": false
    },
    {
        "value": "495326",
        "label": "Junk Removal",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56221910",
        "yellow_page_code": "44607",
        "kiosk": false
    },
    {
        "value": "495327",
        "label": "Appliances-Removal Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56292002",
        "yellow_page_code": "02928",
        "kiosk": false
    },
    {
        "value": "495328",
        "label": "Air Conditioning Systems-Disposal",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56292003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "495329",
        "label": "Solid Waste Combustors & Incinerators ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56221301",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "495330",
        "label": "Nonhazardous Waste Treatment & Disposal ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56221911",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "495331",
        "label": "Materials Recovery Facilities ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56292004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "495901",
        "label": "Sweepers-Industrial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56299805",
        "yellow_page_code": "82606",
        "kiosk": false
    },
    {
        "value": "495902",
        "label": "Sweeping Service-Power",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56299806",
        "yellow_page_code": "82704",
        "kiosk": false
    },
    {
        "value": "495903",
        "label": "Street Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56299804",
        "yellow_page_code": "82000, 72330",
        "kiosk": false
    },
    {
        "value": "495904",
        "label": "Oil Spill Clean Up Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56291005",
        "yellow_page_code": "56812, 57014",
        "kiosk": false
    },
    {
        "value": "495905",
        "label": "Lake & Beach Cleaning & Improvement",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56299801",
        "yellow_page_code": "45501, 45437, 07125",
        "kiosk": false
    },
    {
        "value": "495906",
        "label": "Mosquito Abatement",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56171007",
        "yellow_page_code": "53703",
        "kiosk": false
    },
    {
        "value": "495907",
        "label": "Waste Water Treatment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56299807",
        "yellow_page_code": "91905, 91913, 92519",
        "kiosk": false
    },
    {
        "value": "495908",
        "label": "Oil Skimmers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56299802",
        "yellow_page_code": "56813, 56817",
        "kiosk": false
    },
    {
        "value": "495909",
        "label": "Parking Lots Sweeping",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56179004",
        "yellow_page_code": "59430",
        "kiosk": false
    },
    {
        "value": "495910",
        "label": "Oil Pollution Clean-Up",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56291007",
        "yellow_page_code": "56434",
        "kiosk": false
    },
    {
        "value": "495911",
        "label": "Mold Mildew Moisture Control & Abatement",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56291008",
        "yellow_page_code": "53410, 53412, 53415, 53417, 53336, 53419, 53422",
        "kiosk": false
    },
    {
        "value": "495912",
        "label": "Sweeping",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56299809",
        "yellow_page_code": "82703, 21266",
        "kiosk": false
    },
    {
        "value": "495913",
        "label": "Chemical Spill Clean Up",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56291009",
        "yellow_page_code": "14916",
        "kiosk": false
    },
    {
        "value": "495914",
        "label": "Mining Reclamation Svc & Site Work",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56291010",
        "yellow_page_code": "52538",
        "kiosk": false
    },
    {
        "value": "495915",
        "label": "Remediation Services ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56291012",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "495999",
        "label": "Sanitary Services NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56299803",
        "yellow_page_code": "73515, 42218",
        "kiosk": false
    },
    {
        "value": "496102",
        "label": "Steam Energy Companies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "22133003",
        "yellow_page_code": "80803",
        "kiosk": false
    },
    {
        "value": "496103",
        "label": "Geothermal Heat Pumps",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33341524",
        "yellow_page_code": "37232",
        "kiosk": false
    },
    {
        "value": "496198",
        "label": "Steam & Air Conditioning Supply",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "22133002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "497101",
        "label": "Hydrology",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169085",
        "yellow_page_code": "42109",
        "kiosk": false
    },
    {
        "value": "497102",
        "label": "Irrigation Companies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "22131002",
        "yellow_page_code": "43906",
        "kiosk": false
    },
    {
        "value": "497103",
        "label": "Rainwater Collection Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "22131008",
        "yellow_page_code": "70314, 70191, 70189",
        "kiosk": false
    },
    {
        "value": "497104",
        "label": "Reservoirs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "22131009",
        "yellow_page_code": "71811",
        "kiosk": false
    },
    {
        "value": "501201",
        "label": "Cabs-Tractor Truck & Etc (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42311008",
        "yellow_page_code": "12207, 87692",
        "kiosk": false
    },
    {
        "value": "501202",
        "label": "Ambulances & Hearses (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42311002",
        "yellow_page_code": "02108",
        "kiosk": false
    },
    {
        "value": "501203",
        "label": "Amphibious Vehicles (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42311003",
        "yellow_page_code": "02207",
        "kiosk": false
    },
    {
        "value": "501204",
        "label": "Automobile Auctions (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42311004",
        "yellow_page_code": "04600, 13021, 13022",
        "kiosk": false
    },
    {
        "value": "501205",
        "label": "Automobile Dealers-Used Cars-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42311005",
        "yellow_page_code": "04707",
        "kiosk": false
    },
    {
        "value": "501206",
        "label": "Automobile-Buyers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512002",
        "yellow_page_code": "04701",
        "kiosk": false
    },
    {
        "value": "501207",
        "label": "Buses-Distributors (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42311006",
        "yellow_page_code": "11700, 11707, 18930",
        "kiosk": false
    },
    {
        "value": "501208",
        "label": "Automobile & Truck Brokers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512001",
        "yellow_page_code": "05302, 88609, 04810, 04611, 04717",
        "kiosk": false
    },
    {
        "value": "501209",
        "label": "Buses-New & Used (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42311007",
        "yellow_page_code": "11609",
        "kiosk": false
    },
    {
        "value": "501210",
        "label": "Recreational Vehicles-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42311012",
        "yellow_page_code": "71100, 54205",
        "kiosk": false
    },
    {
        "value": "501211",
        "label": "Campers & Pick-Up Coaches-Distr (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42311009",
        "yellow_page_code": "12500, 12414",
        "kiosk": false
    },
    {
        "value": "501212",
        "label": "Trailers-Truck (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42311018",
        "yellow_page_code": "88108, 88024, 88723",
        "kiosk": false
    },
    {
        "value": "501213",
        "label": "Truck Bodies & Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42311020",
        "yellow_page_code": "88700",
        "kiosk": false
    },
    {
        "value": "501214",
        "label": "Truck-Distributors (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42311021",
        "yellow_page_code": "88803",
        "kiosk": false
    },
    {
        "value": "501215",
        "label": "Trailers-Automobile Utility-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42311014",
        "yellow_page_code": "87906, 88025, 29870",
        "kiosk": false
    },
    {
        "value": "501216",
        "label": "All Terrain Vehicles-Distributors (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42311001",
        "yellow_page_code": "01913",
        "kiosk": false
    },
    {
        "value": "501217",
        "label": "Trailers-Boat-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42311015",
        "yellow_page_code": "87907",
        "kiosk": false
    },
    {
        "value": "501218",
        "label": "Trailers-Industrial-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42311017",
        "yellow_page_code": "88013",
        "kiosk": false
    },
    {
        "value": "501219",
        "label": "Trailers-Camping & Travel-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42311016",
        "yellow_page_code": "87913",
        "kiosk": false
    },
    {
        "value": "501220",
        "label": "Van Conversions-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42311022",
        "yellow_page_code": "90535",
        "kiosk": false
    },
    {
        "value": "501221",
        "label": "Marsh Buggies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42311010",
        "yellow_page_code": "50305",
        "kiosk": false
    },
    {
        "value": "501222",
        "label": "Taxicab Brokers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512003",
        "yellow_page_code": "84116",
        "kiosk": false
    },
    {
        "value": "501223",
        "label": "Tractors-Used (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42311024",
        "yellow_page_code": "87690",
        "kiosk": false
    },
    {
        "value": "501224",
        "label": "Trailers-Truck-Used (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42311019",
        "yellow_page_code": "88103",
        "kiosk": false
    },
    {
        "value": "501225",
        "label": "Motor Carrier (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42311011",
        "yellow_page_code": "54203",
        "kiosk": false
    },
    {
        "value": "501226",
        "label": "Snowmobiles Commercial (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42311023",
        "yellow_page_code": "78711",
        "kiosk": false
    },
    {
        "value": "501227",
        "label": "Snowmobiles-Wholesale & Distributors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42311013",
        "yellow_page_code": "78713",
        "kiosk": false
    },
    {
        "value": "501228",
        "label": "Automobile & Other Motor Vehicle (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42311025",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "501301",
        "label": "Radiators-Automotive-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312041",
        "yellow_page_code": "69704, 69733, 04511",
        "kiosk": false
    },
    {
        "value": "501302",
        "label": "Truck Equipment & Parts-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312065",
        "yellow_page_code": "88800, 88616, 07318, 80121, 30268, 88705, 04554",
        "kiosk": false
    },
    {
        "value": "501303",
        "label": "Towing Equipment-Automotive (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312059",
        "yellow_page_code": "87502, 04432, 88122",
        "kiosk": false
    },
    {
        "value": "501304",
        "label": "Truck Refrigeration Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312050",
        "yellow_page_code": "88900",
        "kiosk": false
    },
    {
        "value": "501305",
        "label": "Engines-Supplies-Equip & Parts (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312016",
        "yellow_page_code": "29402",
        "kiosk": false
    },
    {
        "value": "501306",
        "label": "Turbochargers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312049",
        "yellow_page_code": "89502",
        "kiosk": false
    },
    {
        "value": "501307",
        "label": "Mufflers & Exhaust Systems-Engine-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312038",
        "yellow_page_code": "54507, 54512, 30152, 14039",
        "kiosk": false
    },
    {
        "value": "501308",
        "label": "Motorcycles-Supls & Parts-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312037",
        "yellow_page_code": "54303, 54242, 54320",
        "kiosk": false
    },
    {
        "value": "501309",
        "label": "Automobile Body Shop Equip/Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312006",
        "yellow_page_code": "04608, 04429, 30461, 04524",
        "kiosk": false
    },
    {
        "value": "501310",
        "label": "Automobile Parts-Warehouses (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512004",
        "yellow_page_code": "05005",
        "kiosk": false
    },
    {
        "value": "501311",
        "label": "Automobile Cruise Control Equip (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312008",
        "yellow_page_code": "04703",
        "kiosk": false
    },
    {
        "value": "501312",
        "label": "Automobile Radio & Stereo Systems-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312067",
        "yellow_page_code": "05105, 13110, 30468",
        "kiosk": false
    },
    {
        "value": "501313",
        "label": "Automobile Parts & Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312015",
        "yellow_page_code": "05006, 07611, 05218, 05010, 84121, 30051, 34816",
        "kiosk": false
    },
    {
        "value": "501314",
        "label": "Automobile Repair/Svc-Equip/Supl (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312068",
        "yellow_page_code": "05204",
        "kiosk": false
    },
    {
        "value": "501315",
        "label": "Brakes-Distributors (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312027",
        "yellow_page_code": "10300",
        "kiosk": false
    },
    {
        "value": "501316",
        "label": "Brake Lining-Distributors (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312025",
        "yellow_page_code": "10208",
        "kiosk": false
    },
    {
        "value": "501317",
        "label": "Heaters-Automotive (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312073",
        "yellow_page_code": "40806, 04816",
        "kiosk": false
    },
    {
        "value": "501318",
        "label": "Automobile Hand & Foot Controls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312012",
        "yellow_page_code": "04900",
        "kiosk": false
    },
    {
        "value": "501319",
        "label": "Automobile Motors (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312014",
        "yellow_page_code": "05003, 04632",
        "kiosk": false
    },
    {
        "value": "501320",
        "label": "Automobile-Mfrs Equip & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312022",
        "yellow_page_code": "05000",
        "kiosk": false
    },
    {
        "value": "501321",
        "label": "Drive Shafts (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312032",
        "yellow_page_code": "26404",
        "kiosk": false
    },
    {
        "value": "501322",
        "label": "Buses-Parts & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312028",
        "yellow_page_code": "11701",
        "kiosk": false
    },
    {
        "value": "501323",
        "label": "Magnetos (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312036",
        "yellow_page_code": "49506",
        "kiosk": false
    },
    {
        "value": "501324",
        "label": "Air Motors (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312001",
        "yellow_page_code": "01208",
        "kiosk": false
    },
    {
        "value": "501325",
        "label": "Cams-Grinding & Cutting (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312029",
        "yellow_page_code": "12608, 12425",
        "kiosk": false
    },
    {
        "value": "501326",
        "label": "Jacks (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312074",
        "yellow_page_code": "44008",
        "kiosk": false
    },
    {
        "value": "501327",
        "label": "Piston Rings (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312039",
        "yellow_page_code": "65902",
        "kiosk": false
    },
    {
        "value": "501328",
        "label": "Filters-Air & Gas-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312035",
        "yellow_page_code": "31706",
        "kiosk": false
    },
    {
        "value": "501329",
        "label": "Tire Changing Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312057",
        "yellow_page_code": "86404",
        "kiosk": false
    },
    {
        "value": "501330",
        "label": "Wheel Alignment-Frm/Axle Svc-Eqpt (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312048",
        "yellow_page_code": "93306, 01914, 93319, 04520, 04537, 05430",
        "kiosk": false
    },
    {
        "value": "501331",
        "label": "Automobile Diagnostic Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312009",
        "yellow_page_code": "04800, 04712, 04522, 04716",
        "kiosk": false
    },
    {
        "value": "501332",
        "label": "Tire-Retreading/Repair-Eqpt/Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312058",
        "yellow_page_code": "86506, 30295",
        "kiosk": false
    },
    {
        "value": "501333",
        "label": "Balancing Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312023",
        "yellow_page_code": "06008",
        "kiosk": false
    },
    {
        "value": "501334",
        "label": "Brake Service Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312026",
        "yellow_page_code": "10204",
        "kiosk": false
    },
    {
        "value": "501335",
        "label": "Speedometers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312046",
        "yellow_page_code": "79808",
        "kiosk": false
    },
    {
        "value": "501336",
        "label": "Warehouses-Automotive (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312018",
        "yellow_page_code": "91505",
        "kiosk": false
    },
    {
        "value": "501337",
        "label": "Automobile Uphlstry/Top Mtrl/Equip-Distr",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312021",
        "yellow_page_code": "05215",
        "kiosk": false
    },
    {
        "value": "501338",
        "label": "Governors (Engine Parts-Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312072",
        "yellow_page_code": "38500",
        "kiosk": false
    },
    {
        "value": "501339",
        "label": "Auto Machine Shop Equip/Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312004",
        "yellow_page_code": "04909, 30465",
        "kiosk": false
    },
    {
        "value": "501340",
        "label": "Alternators & Generators-Automotive-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312002",
        "yellow_page_code": "02001",
        "kiosk": false
    },
    {
        "value": "501341",
        "label": "Automobile Electric Equipment-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312010",
        "yellow_page_code": "04812, 30464",
        "kiosk": false
    },
    {
        "value": "501342",
        "label": "Automobile Floor Coverings-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312011",
        "yellow_page_code": "04811",
        "kiosk": false
    },
    {
        "value": "501343",
        "label": "Automobile Machine Shop Service-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312013",
        "yellow_page_code": "04917",
        "kiosk": false
    },
    {
        "value": "501344",
        "label": "Automobile Racing Equipment-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312066",
        "yellow_page_code": "05013",
        "kiosk": false
    },
    {
        "value": "501345",
        "label": "Automobile Seatcovers-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312069",
        "yellow_page_code": "05201, 05113, 05229, 04430, 30273",
        "kiosk": false
    },
    {
        "value": "501346",
        "label": "Battery Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312024",
        "yellow_page_code": "07105, 30470",
        "kiosk": false
    },
    {
        "value": "501347",
        "label": "Carburetors-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312030",
        "yellow_page_code": "13311",
        "kiosk": false
    },
    {
        "value": "501348",
        "label": "Engines-Gasoline-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312034",
        "yellow_page_code": "29311",
        "kiosk": false
    },
    {
        "value": "501349",
        "label": "Filters-Fuel & Oil-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312070",
        "yellow_page_code": "31705",
        "kiosk": false
    },
    {
        "value": "501350",
        "label": "License Plate Frames-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312075",
        "yellow_page_code": "47107",
        "kiosk": false
    },
    {
        "value": "501351",
        "label": "Recreational Vehicles-Equip-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312042",
        "yellow_page_code": "71017",
        "kiosk": false
    },
    {
        "value": "501352",
        "label": "Seat Belts-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312043",
        "yellow_page_code": "75207",
        "kiosk": false
    },
    {
        "value": "501354",
        "label": "Springs-Auto-Sales & Svc-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312054",
        "yellow_page_code": "80214, 80213, 30327",
        "kiosk": false
    },
    {
        "value": "501355",
        "label": "Trailer Hitches-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312060",
        "yellow_page_code": "87903",
        "kiosk": false
    },
    {
        "value": "501356",
        "label": "Trailers-Equipment & Parts-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312053",
        "yellow_page_code": "88008, 29871",
        "kiosk": false
    },
    {
        "value": "501357",
        "label": "Transmissions-Automobile-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312051",
        "yellow_page_code": "88305",
        "kiosk": false
    },
    {
        "value": "501358",
        "label": "Truck Caps & Shells-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312064",
        "yellow_page_code": "88618",
        "kiosk": false
    },
    {
        "value": "501359",
        "label": "Pistons (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312040",
        "yellow_page_code": "65903",
        "kiosk": false
    },
    {
        "value": "501360",
        "label": "Wheels-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312047",
        "yellow_page_code": "93403",
        "kiosk": false
    },
    {
        "value": "501361",
        "label": "Window Tinting/Coating Mtrls-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312019",
        "yellow_page_code": "93613",
        "kiosk": false
    },
    {
        "value": "501362",
        "label": "Transmission Parts & Supls-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312052",
        "yellow_page_code": "88234",
        "kiosk": false
    },
    {
        "value": "501363",
        "label": "Automobile Speed Controls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312020",
        "yellow_page_code": "05203",
        "kiosk": false
    },
    {
        "value": "501364",
        "label": "Garage Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312071",
        "yellow_page_code": "36105",
        "kiosk": false
    },
    {
        "value": "501365",
        "label": "Automobile Bumpers Guards & Grills-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312007",
        "yellow_page_code": "04636",
        "kiosk": false
    },
    {
        "value": "501366",
        "label": "Ambulances & Hearses Equip/Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312003",
        "yellow_page_code": "02110, 28403",
        "kiosk": false
    },
    {
        "value": "501367",
        "label": "Clutches-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312031",
        "yellow_page_code": "18814",
        "kiosk": false
    },
    {
        "value": "501368",
        "label": "Automobile Bodies-Commercial (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312005",
        "yellow_page_code": "04923, 19963",
        "kiosk": false
    },
    {
        "value": "501369",
        "label": "Starters-Engine-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312055",
        "yellow_page_code": "80611, 29853",
        "kiosk": false
    },
    {
        "value": "501370",
        "label": "Driving Training Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312033",
        "yellow_page_code": "26500",
        "kiosk": false
    },
    {
        "value": "501371",
        "label": "Trailers-Commercial-Equip/Parts (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312061",
        "yellow_page_code": "88012",
        "kiosk": false
    },
    {
        "value": "501372",
        "label": "Shock Absorbers-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312044",
        "yellow_page_code": "76616",
        "kiosk": false
    },
    {
        "value": "501373",
        "label": "Truck Accessories (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312062",
        "yellow_page_code": "88615",
        "kiosk": false
    },
    {
        "value": "501374",
        "label": "Truck Bumpers & Tailgates (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312063",
        "yellow_page_code": "88712",
        "kiosk": false
    },
    {
        "value": "501375",
        "label": "Lubricating Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312017",
        "yellow_page_code": "48804, 56538",
        "kiosk": false
    },
    {
        "value": "501376",
        "label": "Superchargers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312056",
        "yellow_page_code": "82306",
        "kiosk": false
    },
    {
        "value": "501377",
        "label": "Canopies Truck Distr & Mfr",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312045",
        "yellow_page_code": "12842",
        "kiosk": false
    },
    {
        "value": "501401",
        "label": "Tire-Distributors (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42313004",
        "yellow_page_code": "86500, 93429",
        "kiosk": false
    },
    {
        "value": "501402",
        "label": "Tire-Dealers-Used (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42313003",
        "yellow_page_code": "86408",
        "kiosk": false
    },
    {
        "value": "501403",
        "label": "Tire Shop-Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42313002",
        "yellow_page_code": "86407, 86502",
        "kiosk": false
    },
    {
        "value": "501404",
        "label": "Tire Additives & Sealants (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42313001",
        "yellow_page_code": "86401",
        "kiosk": false
    },
    {
        "value": "501405",
        "label": "Tires-Flat Proofing (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42313005",
        "yellow_page_code": "86412",
        "kiosk": false
    },
    {
        "value": "501406",
        "label": "Tire Dealers Agricultural & Commercial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42313006",
        "yellow_page_code": "86413",
        "kiosk": false
    },
    {
        "value": "501407",
        "label": "Tire & Tube (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42313007",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "501501",
        "label": "Automobile Parts-Used & Rebuilt (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42314003",
        "yellow_page_code": "05004, 30467, 43644",
        "kiosk": false
    },
    {
        "value": "501502",
        "label": "Automobile Wrecking (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42314004",
        "yellow_page_code": "05205, 05312, 05400, 73615, 73612, 88621, 13078",
        "kiosk": false
    },
    {
        "value": "501503",
        "label": "Automobile Parts & Supls-Antique (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42314002",
        "yellow_page_code": "05008, 04421, 04634, 02719",
        "kiosk": false
    },
    {
        "value": "501504",
        "label": "Truck-Wrecking (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42314008",
        "yellow_page_code": "89003",
        "kiosk": false
    },
    {
        "value": "501505",
        "label": "Truck Equip & Parts-Used-Rebuilt (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42314007",
        "yellow_page_code": "88801",
        "kiosk": false
    },
    {
        "value": "501506",
        "label": "Universal Joints (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42314009",
        "yellow_page_code": "90008",
        "kiosk": false
    },
    {
        "value": "501507",
        "label": "Motorcycle/Mtr Sctrs-Pt/Supl-Used (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42314006",
        "yellow_page_code": "54309",
        "kiosk": false
    },
    {
        "value": "501508",
        "label": "Automobile Dismantling/Recycling (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42314001",
        "yellow_page_code": "04803, 04910, 10337, 19922, 54327",
        "kiosk": false
    },
    {
        "value": "501509",
        "label": "Transmissions Automobile Rebuilt (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33635003",
        "yellow_page_code": "88230, 88220",
        "kiosk": false
    },
    {
        "value": "501517",
        "label": "Elements & Alloys (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42314005",
        "yellow_page_code": "28214",
        "kiosk": false
    },
    {
        "value": "502101",
        "label": "Bathroom Fixtures & Accessories-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42321015",
        "yellow_page_code": "06907",
        "kiosk": false
    },
    {
        "value": "502102",
        "label": "Water Beds-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42321014",
        "yellow_page_code": "92103, 30235",
        "kiosk": false
    },
    {
        "value": "502103",
        "label": "Water Beds-Supplies & Parts (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42321013",
        "yellow_page_code": "92101",
        "kiosk": false
    },
    {
        "value": "502104",
        "label": "Stools (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42321009",
        "yellow_page_code": "81704",
        "kiosk": false
    },
    {
        "value": "502105",
        "label": "School Furniture & Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42321006",
        "yellow_page_code": "74402",
        "kiosk": false
    },
    {
        "value": "502106",
        "label": "Tables-Folding (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42321012",
        "yellow_page_code": "83108",
        "kiosk": false
    },
    {
        "value": "502107",
        "label": "Furniture-Dealers-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42321030",
        "yellow_page_code": "35702, 30182, 30476",
        "kiosk": false
    },
    {
        "value": "502108",
        "label": "Furniture Buyers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42321027",
        "yellow_page_code": "35604",
        "kiosk": false
    },
    {
        "value": "502109",
        "label": "Library Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42321001",
        "yellow_page_code": "47104",
        "kiosk": false
    },
    {
        "value": "502110",
        "label": "Furniture-Outdoor-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42321032",
        "yellow_page_code": "35905",
        "kiosk": false
    },
    {
        "value": "502111",
        "label": "Mattresses-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42321002",
        "yellow_page_code": "50607, 29951",
        "kiosk": false
    },
    {
        "value": "502112",
        "label": "Office Furniture & Equipment-Dealers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42321003",
        "yellow_page_code": "56306, 56221, 41237, 56211, 56216, 77405, 35621, 56224",
        "kiosk": false
    },
    {
        "value": "502113",
        "label": "Office Furniture & Equip-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42321005",
        "yellow_page_code": "56300",
        "kiosk": false
    },
    {
        "value": "502114",
        "label": "Breakfast Nooks & Booths (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42321021",
        "yellow_page_code": "10401",
        "kiosk": false
    },
    {
        "value": "502115",
        "label": "Beds-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42321017",
        "yellow_page_code": "07407",
        "kiosk": false
    },
    {
        "value": "502116",
        "label": "Chairs-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42321024",
        "yellow_page_code": "14604",
        "kiosk": false
    },
    {
        "value": "502117",
        "label": "Furniture-Mfrs' Equip & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42321031",
        "yellow_page_code": "35800",
        "kiosk": false
    },
    {
        "value": "502118",
        "label": "Church Furnishings (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42321025",
        "yellow_page_code": "15706, 15721, 71623",
        "kiosk": false
    },
    {
        "value": "502119",
        "label": "Seating Companies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42321007",
        "yellow_page_code": "75206",
        "kiosk": false
    },
    {
        "value": "502120",
        "label": "Benches & Work Tables (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42321019",
        "yellow_page_code": "07706",
        "kiosk": false
    },
    {
        "value": "502121",
        "label": "Bleachers & Grandstands (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42321020",
        "yellow_page_code": "08500, 38713, 80536",
        "kiosk": false
    },
    {
        "value": "502122",
        "label": "Office Furniture & Equip-Instltn (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42321004",
        "yellow_page_code": "56209",
        "kiosk": false
    },
    {
        "value": "502123",
        "label": "Furniture Grilles (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42321037",
        "yellow_page_code": "35612",
        "kiosk": false
    },
    {
        "value": "502124",
        "label": "Desks (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42321026",
        "yellow_page_code": "24303",
        "kiosk": false
    },
    {
        "value": "502125",
        "label": "Bed Springs-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42321016",
        "yellow_page_code": "07401",
        "kiosk": false
    },
    {
        "value": "502126",
        "label": "Bench Drawers & Legs-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42321018",
        "yellow_page_code": "07801",
        "kiosk": false
    },
    {
        "value": "502127",
        "label": "Furniture-Childrens-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42321029",
        "yellow_page_code": "35807",
        "kiosk": false
    },
    {
        "value": "502128",
        "label": "Furniture-Unfinished-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42321033",
        "yellow_page_code": "35913",
        "kiosk": false
    },
    {
        "value": "502130",
        "label": "Table Tops-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42321010",
        "yellow_page_code": "83109",
        "kiosk": false
    },
    {
        "value": "502131",
        "label": "School Furniture & Equip-Retail (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42321036",
        "yellow_page_code": "74334",
        "kiosk": false
    },
    {
        "value": "502132",
        "label": "Lecterns (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42321035",
        "yellow_page_code": "46806, 54819",
        "kiosk": false
    },
    {
        "value": "502133",
        "label": "Chairs-Metal (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42321023",
        "yellow_page_code": "14511",
        "kiosk": false
    },
    {
        "value": "502134",
        "label": "Chairs-Folding (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42321022",
        "yellow_page_code": "14607",
        "kiosk": false
    },
    {
        "value": "502135",
        "label": "Hotel & Motel Furnishings (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42321034",
        "yellow_page_code": "41816, 41730",
        "kiosk": false
    },
    {
        "value": "502136",
        "label": "Furniture Representatives (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42321028",
        "yellow_page_code": "35809",
        "kiosk": false
    },
    {
        "value": "502137",
        "label": "Tables-Cutting & Work (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42321011",
        "yellow_page_code": "83102",
        "kiosk": false
    },
    {
        "value": "502138",
        "label": "Stanchions (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42321008",
        "yellow_page_code": "80603",
        "kiosk": false
    },
    {
        "value": "502139",
        "label": "Home Office Furniture (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44911036",
        "yellow_page_code": "41213, 35719",
        "kiosk": false
    },
    {
        "value": "502140",
        "label": "Restaurant-Furniture (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42321038",
        "yellow_page_code": "71947",
        "kiosk": false
    },
    {
        "value": "502141",
        "label": "Futons & Accessories Whls & Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42321039",
        "yellow_page_code": "35998",
        "kiosk": false
    },
    {
        "value": "502301",
        "label": "Apartment House Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322044",
        "yellow_page_code": "02901",
        "kiosk": false
    },
    {
        "value": "502302",
        "label": "Towels-Cloth-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322041",
        "yellow_page_code": "87408",
        "kiosk": false
    },
    {
        "value": "502303",
        "label": "Bedspreads-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322003",
        "yellow_page_code": "07500",
        "kiosk": false
    },
    {
        "value": "502304",
        "label": "Bedding-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322002",
        "yellow_page_code": "07405",
        "kiosk": false
    },
    {
        "value": "502305",
        "label": "Blankets (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322004",
        "yellow_page_code": "08404",
        "kiosk": false
    },
    {
        "value": "502306",
        "label": "Chinaware & Glassware-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322010",
        "yellow_page_code": "15309, 15408",
        "kiosk": false
    },
    {
        "value": "502307",
        "label": "Carpet & Rug-Distributors (Wholesale)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322048",
        "yellow_page_code": "13702, 13610, 30146, 30056, 42675",
        "kiosk": false
    },
    {
        "value": "502308",
        "label": "Draperies & Curtains-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322015",
        "yellow_page_code": "23002, 25906, 93614",
        "kiosk": false
    },
    {
        "value": "502309",
        "label": "Closets & Closet Accessories (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322011",
        "yellow_page_code": "18609, 18621",
        "kiosk": false
    },
    {
        "value": "502310",
        "label": "Fireplace Equipment-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322017",
        "yellow_page_code": "32204",
        "kiosk": false
    },
    {
        "value": "502311",
        "label": "Drapery & Curtain Fixtures-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322016",
        "yellow_page_code": "26006, 26010",
        "kiosk": false
    },
    {
        "value": "502312",
        "label": "Glassware-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322021",
        "yellow_page_code": "37800",
        "kiosk": false
    },
    {
        "value": "502313",
        "label": "Floor Materials-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322019",
        "yellow_page_code": "33401",
        "kiosk": false
    },
    {
        "value": "502314",
        "label": "Housewares-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322024",
        "yellow_page_code": "42006",
        "kiosk": false
    },
    {
        "value": "502315",
        "label": "Linens-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322028",
        "yellow_page_code": "47604, 47603, 47521, 30489",
        "kiosk": false
    },
    {
        "value": "502316",
        "label": "Lamps-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322027",
        "yellow_page_code": "45800",
        "kiosk": false
    },
    {
        "value": "502317",
        "label": "Picture Frames-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322032",
        "yellow_page_code": "65100, 65116, 30263",
        "kiosk": false
    },
    {
        "value": "502318",
        "label": "Linoleum-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322029",
        "yellow_page_code": "47708",
        "kiosk": false
    },
    {
        "value": "502319",
        "label": "Pottery-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322034",
        "yellow_page_code": "67704",
        "kiosk": false
    },
    {
        "value": "502320",
        "label": "Table Pads (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322038",
        "yellow_page_code": "83008",
        "kiosk": false
    },
    {
        "value": "502321",
        "label": "Shower Curtains (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322036",
        "yellow_page_code": "77306",
        "kiosk": false
    },
    {
        "value": "502322",
        "label": "Vertical Blinds-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322045",
        "yellow_page_code": "90904, 29879",
        "kiosk": false
    },
    {
        "value": "502323",
        "label": "Venetian Blinds-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322043",
        "yellow_page_code": "90709, 08421, 08430",
        "kiosk": false
    },
    {
        "value": "502324",
        "label": "Lamp Shades-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322026",
        "yellow_page_code": "45608, 45627",
        "kiosk": false
    },
    {
        "value": "502325",
        "label": "Mirrors-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322031",
        "yellow_page_code": "52703",
        "kiosk": false
    },
    {
        "value": "502326",
        "label": "Tile-Non-Ceramic-Distributors (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322040",
        "yellow_page_code": "86102",
        "kiosk": false
    },
    {
        "value": "502327",
        "label": "Mats & Matting (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322030",
        "yellow_page_code": "50508, 29950",
        "kiosk": false
    },
    {
        "value": "502328",
        "label": "Window Shades-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322006",
        "yellow_page_code": "93609, 93643",
        "kiosk": false
    },
    {
        "value": "502329",
        "label": "Ceramic Products-Decorative-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322009",
        "yellow_page_code": "14405",
        "kiosk": false
    },
    {
        "value": "502330",
        "label": "Can Openers-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322005",
        "yellow_page_code": "12600",
        "kiosk": false
    },
    {
        "value": "502331",
        "label": "Cooking Utensils-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322012",
        "yellow_page_code": "21411",
        "kiosk": false
    },
    {
        "value": "502332",
        "label": "Curtains-Shower & Bath-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322013",
        "yellow_page_code": "22902",
        "kiosk": false
    },
    {
        "value": "502333",
        "label": "Cushions-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322014",
        "yellow_page_code": "23000",
        "kiosk": false
    },
    {
        "value": "502334",
        "label": "Furniture Accessories & Decor-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322020",
        "yellow_page_code": "35603",
        "kiosk": false
    },
    {
        "value": "502335",
        "label": "Hampers-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322022",
        "yellow_page_code": "39805",
        "kiosk": false
    },
    {
        "value": "502336",
        "label": "Home Furnishings-Whls & Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322023",
        "yellow_page_code": "41255",
        "kiosk": false
    },
    {
        "value": "502337",
        "label": "Kitchen Accessories-Whls & Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322025",
        "yellow_page_code": "44919",
        "kiosk": false
    },
    {
        "value": "502338",
        "label": "Pillows-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322033",
        "yellow_page_code": "65303",
        "kiosk": false
    },
    {
        "value": "502339",
        "label": "Quilting-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322035",
        "yellow_page_code": "69502, 69405",
        "kiosk": false
    },
    {
        "value": "502341",
        "label": "Slip Covers-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322037",
        "yellow_page_code": "78401",
        "kiosk": false
    },
    {
        "value": "502342",
        "label": "Wine Storage Equipment-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322001",
        "yellow_page_code": "93812",
        "kiosk": false
    },
    {
        "value": "502343",
        "label": "Carpet & Rug Pads Linings & Acces-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322008",
        "yellow_page_code": "13709",
        "kiosk": false
    },
    {
        "value": "502344",
        "label": "Valances (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322042",
        "yellow_page_code": "90503",
        "kiosk": false
    },
    {
        "value": "502345",
        "label": "Flatware-Table-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322018",
        "yellow_page_code": "32908",
        "kiosk": false
    },
    {
        "value": "502346",
        "label": "Tapestries-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322039",
        "yellow_page_code": "83810",
        "kiosk": false
    },
    {
        "value": "502347",
        "label": "Cardtable Covers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322007",
        "yellow_page_code": "13414",
        "kiosk": false
    },
    {
        "value": "502348",
        "label": "Rugs Wholesale & Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322046",
        "yellow_page_code": "73117",
        "kiosk": false
    },
    {
        "value": "502349",
        "label": "Picture Frame Supplies (Wholesale)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42322047",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "503101",
        "label": "Lumber-Exporters & Importers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331028",
        "yellow_page_code": "48907, 30194",
        "kiosk": false
    },
    {
        "value": "503102",
        "label": "Windows-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331049",
        "yellow_page_code": "93801, 93704, 93633",
        "kiosk": false
    },
    {
        "value": "503103",
        "label": "Louvers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331025",
        "yellow_page_code": "48702, 48641",
        "kiosk": false
    },
    {
        "value": "503104",
        "label": "Plywood & Veneers-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331035",
        "yellow_page_code": "66809",
        "kiosk": false
    },
    {
        "value": "503105",
        "label": "Pallets & Skids (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331032",
        "yellow_page_code": "58706, 58712",
        "kiosk": false
    },
    {
        "value": "503106",
        "label": "Poles (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331036",
        "yellow_page_code": "67100",
        "kiosk": false
    },
    {
        "value": "503107",
        "label": "Hardwoods-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331018",
        "yellow_page_code": "40203",
        "kiosk": false
    },
    {
        "value": "503108",
        "label": "Timber & Timberland Companies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331046",
        "yellow_page_code": "86104, 86108, 86110, 86113",
        "kiosk": false
    },
    {
        "value": "503109",
        "label": "Lumber-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331029",
        "yellow_page_code": "49008, 48901, 48909",
        "kiosk": false
    },
    {
        "value": "503110",
        "label": "Lath (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331021",
        "yellow_page_code": "46100",
        "kiosk": false
    },
    {
        "value": "503111",
        "label": "Millwork-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331030",
        "yellow_page_code": "52501",
        "kiosk": false
    },
    {
        "value": "503112",
        "label": "Moldings (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331031",
        "yellow_page_code": "53402, 53337",
        "kiosk": false
    },
    {
        "value": "503113",
        "label": "Cabinets-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331005",
        "yellow_page_code": "12201, 12119",
        "kiosk": false
    },
    {
        "value": "503114",
        "label": "Building Materials-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331002",
        "yellow_page_code": "11302, 31503, 30054",
        "kiosk": false
    },
    {
        "value": "503115",
        "label": "Ceiling Materials-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331008",
        "yellow_page_code": "14209",
        "kiosk": false
    },
    {
        "value": "503116",
        "label": "Doors & Frames-Coml & Industrial (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331012",
        "yellow_page_code": "25607, 25632, 42934",
        "kiosk": false
    },
    {
        "value": "503117",
        "label": "Door Frames (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331011",
        "yellow_page_code": "25602, 25619",
        "kiosk": false
    },
    {
        "value": "503118",
        "label": "Kitchen Cabinets-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331020",
        "yellow_page_code": "44908, 44927",
        "kiosk": false
    },
    {
        "value": "503119",
        "label": "Doors-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331013",
        "yellow_page_code": "25704, 25623, 25626",
        "kiosk": false
    },
    {
        "value": "503120",
        "label": "Cabinet Makers Equipment & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331004",
        "yellow_page_code": "12108",
        "kiosk": false
    },
    {
        "value": "503121",
        "label": "Stakes (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331043",
        "yellow_page_code": "80506",
        "kiosk": false
    },
    {
        "value": "503123",
        "label": "Lumber Mill Representatives (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331026",
        "yellow_page_code": "48906",
        "kiosk": false
    },
    {
        "value": "503124",
        "label": "Dowels (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331015",
        "yellow_page_code": "25802",
        "kiosk": false
    },
    {
        "value": "503125",
        "label": "Lumber-Drying (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331027",
        "yellow_page_code": "48905, 48912",
        "kiosk": false
    },
    {
        "value": "503126",
        "label": "Doors-Sliding (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418024",
        "yellow_page_code": "25605, 75219",
        "kiosk": false
    },
    {
        "value": "503127",
        "label": "Storm Windows & Doors-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331044",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "503128",
        "label": "Bamboo-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331001",
        "yellow_page_code": "06302",
        "kiosk": false
    },
    {
        "value": "503129",
        "label": "Cabinet Components-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331003",
        "yellow_page_code": "12135",
        "kiosk": false
    },
    {
        "value": "503130",
        "label": "Carports-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331006",
        "yellow_page_code": "13805",
        "kiosk": false
    },
    {
        "value": "503131",
        "label": "Counter Tops-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331010",
        "yellow_page_code": "22111",
        "kiosk": false
    },
    {
        "value": "503132",
        "label": "Doors-Wood-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331014",
        "yellow_page_code": "25614",
        "kiosk": false
    },
    {
        "value": "503133",
        "label": "Furniture Frames-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331016",
        "yellow_page_code": "35711",
        "kiosk": false
    },
    {
        "value": "503134",
        "label": "Furniture Legs-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331017",
        "yellow_page_code": "35903",
        "kiosk": false
    },
    {
        "value": "503135",
        "label": "Jalousies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331019",
        "yellow_page_code": "44113",
        "kiosk": false
    },
    {
        "value": "503136",
        "label": "Paneling-Distributors (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331033",
        "yellow_page_code": "58736",
        "kiosk": false
    },
    {
        "value": "503137",
        "label": "Particle Board-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331034",
        "yellow_page_code": "59511",
        "kiosk": false
    },
    {
        "value": "503138",
        "label": "Railings-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331037",
        "yellow_page_code": "70107, 29996, 70290",
        "kiosk": false
    },
    {
        "value": "503139",
        "label": "Railroad Ties-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331038",
        "yellow_page_code": "70207",
        "kiosk": false
    },
    {
        "value": "503140",
        "label": "Sash-Wood-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331039",
        "yellow_page_code": "73914",
        "kiosk": false
    },
    {
        "value": "503141",
        "label": "Screen Door & Window-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331040",
        "yellow_page_code": "74809",
        "kiosk": false
    },
    {
        "value": "503142",
        "label": "Shutters-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331041",
        "yellow_page_code": "77409",
        "kiosk": false
    },
    {
        "value": "503143",
        "label": "Skylights-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331042",
        "yellow_page_code": "78214",
        "kiosk": false
    },
    {
        "value": "503144",
        "label": "Wallboard & Plasterboard-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331047",
        "yellow_page_code": "91405",
        "kiosk": false
    },
    {
        "value": "503145",
        "label": "Lath-Mtl Plaster Composition/Etc (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331022",
        "yellow_page_code": "46101",
        "kiosk": false
    },
    {
        "value": "503146",
        "label": "Log Exporters (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331024",
        "yellow_page_code": "48512",
        "kiosk": false
    },
    {
        "value": "503148",
        "label": "Cedar Products (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331007",
        "yellow_page_code": "14213",
        "kiosk": false
    },
    {
        "value": "503149",
        "label": "Log Buyers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331023",
        "yellow_page_code": "48606",
        "kiosk": false
    },
    {
        "value": "503150",
        "label": "Lumber Brokers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512005",
        "yellow_page_code": "48902",
        "kiosk": false
    },
    {
        "value": "503151",
        "label": "Window Wells & Covers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331048",
        "yellow_page_code": "93800, 93615",
        "kiosk": false
    },
    {
        "value": "503152",
        "label": "Thresholds (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331045",
        "yellow_page_code": "85807",
        "kiosk": false
    },
    {
        "value": "503153",
        "label": "Composite Materials (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331009",
        "yellow_page_code": "20135",
        "kiosk": false
    },
    {
        "value": "503154",
        "label": "Hot Iron Stamping Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512074",
        "yellow_page_code": "41825",
        "kiosk": false
    },
    {
        "value": "503155",
        "label": "Garage Cabinets & Organizers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42331051",
        "yellow_page_code": "36110, 36117, 36134",
        "kiosk": false
    },
    {
        "value": "503201",
        "label": "Marble-Cultured (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332028",
        "yellow_page_code": "49908",
        "kiosk": false
    },
    {
        "value": "503202",
        "label": "Marble-Natural (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332029",
        "yellow_page_code": "50000, 49996, 30344",
        "kiosk": false
    },
    {
        "value": "503203",
        "label": "Marble-Synthetic (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332031",
        "yellow_page_code": "50002",
        "kiosk": false
    },
    {
        "value": "503204",
        "label": "Granite (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332024",
        "yellow_page_code": "38802, 41440",
        "kiosk": false
    },
    {
        "value": "503205",
        "label": "Concrete Aggregates (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332014",
        "yellow_page_code": "20408",
        "kiosk": false
    },
    {
        "value": "503206",
        "label": "Concrete Blocks & Shapes (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332015",
        "yellow_page_code": "20502, 11125, 06716, 08501, 59880",
        "kiosk": false
    },
    {
        "value": "503207",
        "label": "Concrete Constr Forms & Acces (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332016",
        "yellow_page_code": "20508, 30121, 20516",
        "kiosk": false
    },
    {
        "value": "503208",
        "label": "Concrete Products (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332017",
        "yellow_page_code": "20804, 20722",
        "kiosk": false
    },
    {
        "value": "503209",
        "label": "Cement-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332011",
        "yellow_page_code": "14308, 20526",
        "kiosk": false
    },
    {
        "value": "503210",
        "label": "Caulking Materials & Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332008",
        "yellow_page_code": "14200",
        "kiosk": false
    },
    {
        "value": "503211",
        "label": "Sand & Gravel (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332036",
        "yellow_page_code": "38809, 73706, 38814, 73741, 40141, 41491",
        "kiosk": false
    },
    {
        "value": "503212",
        "label": "Brick-Clay Common & Face (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332006",
        "yellow_page_code": "10506, 81591, 50418",
        "kiosk": false
    },
    {
        "value": "503213",
        "label": "Mosaics (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332033",
        "yellow_page_code": "53702",
        "kiosk": false
    },
    {
        "value": "503214",
        "label": "Mortar (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332032",
        "yellow_page_code": "53608",
        "kiosk": false
    },
    {
        "value": "503215",
        "label": "Asphalt Aggregates (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332002",
        "yellow_page_code": "04009",
        "kiosk": false
    },
    {
        "value": "503216",
        "label": "Plastering Contrs' Equip/Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332034",
        "yellow_page_code": "66205, 66137, 66142, 29986",
        "kiosk": false
    },
    {
        "value": "503217",
        "label": "Slate (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332038",
        "yellow_page_code": "78302",
        "kiosk": false
    },
    {
        "value": "503218",
        "label": "Sewer & Drain Pipe (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332037",
        "yellow_page_code": "75804, 75822",
        "kiosk": false
    },
    {
        "value": "503219",
        "label": "Tile-Ceramic-Distributors (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332050",
        "yellow_page_code": "86008, 30266",
        "kiosk": false
    },
    {
        "value": "503220",
        "label": "Terrazzo (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332048",
        "yellow_page_code": "85206",
        "kiosk": false
    },
    {
        "value": "503221",
        "label": "Stucco-Distributors (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332045",
        "yellow_page_code": "82105, 82037, 14307",
        "kiosk": false
    },
    {
        "value": "503222",
        "label": "Lime & Limestone-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332026",
        "yellow_page_code": "47502",
        "kiosk": false
    },
    {
        "value": "503223",
        "label": "Road Materials (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332035",
        "yellow_page_code": "72405",
        "kiosk": false
    },
    {
        "value": "503224",
        "label": "Cinders (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332012",
        "yellow_page_code": "18003",
        "kiosk": false
    },
    {
        "value": "503225",
        "label": "Crushing & Pulverizing Service (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332022",
        "yellow_page_code": "22801",
        "kiosk": false
    },
    {
        "value": "503226",
        "label": "Asphalt Products-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332003",
        "yellow_page_code": "04007, 30458",
        "kiosk": false
    },
    {
        "value": "503227",
        "label": "Bentonite-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332004",
        "yellow_page_code": "07711",
        "kiosk": false
    },
    {
        "value": "503228",
        "label": "Cement-Portland-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332010",
        "yellow_page_code": "14305",
        "kiosk": false
    },
    {
        "value": "503229",
        "label": "Clay-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332013",
        "yellow_page_code": "18212",
        "kiosk": false
    },
    {
        "value": "503230",
        "label": "Concrete-Ready Mixed-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332020",
        "yellow_page_code": "20716",
        "kiosk": false
    },
    {
        "value": "503231",
        "label": "Stone-Natural-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332043",
        "yellow_page_code": "81611",
        "kiosk": false
    },
    {
        "value": "503233",
        "label": "Tile-Marble-Distributors (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332051",
        "yellow_page_code": "86101",
        "kiosk": false
    },
    {
        "value": "503234",
        "label": "Loam (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23831016",
        "yellow_page_code": "48310",
        "kiosk": false
    },
    {
        "value": "503235",
        "label": "Concrete-Dry Mixed (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332019",
        "yellow_page_code": "20603",
        "kiosk": false
    },
    {
        "value": "503236",
        "label": "Terra Cotta (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332046",
        "yellow_page_code": "85207",
        "kiosk": false
    },
    {
        "value": "503237",
        "label": "Interlocking Stone (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332025",
        "yellow_page_code": "43514",
        "kiosk": false
    },
    {
        "value": "503238",
        "label": "Concrete-Crushed (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332018",
        "yellow_page_code": "20514",
        "kiosk": false
    },
    {
        "value": "503239",
        "label": "Coquina (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332021",
        "yellow_page_code": "21601",
        "kiosk": false
    },
    {
        "value": "503240",
        "label": "Brick-Used-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332007",
        "yellow_page_code": "10511",
        "kiosk": false
    },
    {
        "value": "503241",
        "label": "Marble-Pre-Cast (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332044",
        "yellow_page_code": "49922",
        "kiosk": false
    },
    {
        "value": "503242",
        "label": "Cement-Acid Resistant (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332009",
        "yellow_page_code": "14319",
        "kiosk": false
    },
    {
        "value": "503243",
        "label": "Geotextiles (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332023",
        "yellow_page_code": "37216",
        "kiosk": false
    },
    {
        "value": "503244",
        "label": "Terrazzo Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332049",
        "yellow_page_code": "85209",
        "kiosk": false
    },
    {
        "value": "503245",
        "label": "Stone Crushing Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332040",
        "yellow_page_code": "81607",
        "kiosk": false
    },
    {
        "value": "503246",
        "label": "Stone-Crushed-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332042",
        "yellow_page_code": "81615",
        "kiosk": false
    },
    {
        "value": "503247",
        "label": "Stone Patching Materials (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332041",
        "yellow_page_code": "81702",
        "kiosk": false
    },
    {
        "value": "503248",
        "label": "Statuary-Manufacturers Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332039",
        "yellow_page_code": "80712",
        "kiosk": false
    },
    {
        "value": "503249",
        "label": "Brick & Tile (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332005",
        "yellow_page_code": "10512",
        "kiosk": false
    },
    {
        "value": "503250",
        "label": "Aggregates-Construction Materials (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "503251",
        "label": "Rock (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332030",
        "yellow_page_code": "72409",
        "kiosk": false
    },
    {
        "value": "503252",
        "label": "Concrete Curbing (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332052",
        "yellow_page_code": "20622",
        "kiosk": false
    },
    {
        "value": "503253",
        "label": "Stone Throwers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332053",
        "yellow_page_code": "81725",
        "kiosk": false
    },
    {
        "value": "503254",
        "label": "Stoneware Wholesale & Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332027",
        "yellow_page_code": "81620",
        "kiosk": false
    },
    {
        "value": "503255",
        "label": "Brick Stone & Related Const Material (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42332054",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "503301",
        "label": "Roofing Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42333007",
        "yellow_page_code": "72701, 72706, 72717",
        "kiosk": false
    },
    {
        "value": "503302",
        "label": "Roofing Siding & Insulation Material (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42333018",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "503303",
        "label": "Roofing Materials-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42333008",
        "yellow_page_code": "72707, 30253",
        "kiosk": false
    },
    {
        "value": "503304",
        "label": "Insulation Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42333004",
        "yellow_page_code": "43308, 43337, 43355",
        "kiosk": false
    },
    {
        "value": "503305",
        "label": "Fiber Glass Materials (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42333002",
        "yellow_page_code": "31504, 37505, 30442",
        "kiosk": false
    },
    {
        "value": "503306",
        "label": "Energy Conservation Products-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42333001",
        "yellow_page_code": "28703",
        "kiosk": false
    },
    {
        "value": "503307",
        "label": "Insulation Materials-Cold & Heat-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42333005",
        "yellow_page_code": "43403, 43316",
        "kiosk": false
    },
    {
        "value": "503308",
        "label": "Siding Materials-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42333010",
        "yellow_page_code": "77411, 41343",
        "kiosk": false
    },
    {
        "value": "503309",
        "label": "Tar & Tar Products (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42333011",
        "yellow_page_code": "83902",
        "kiosk": false
    },
    {
        "value": "503310",
        "label": "Hatches & Scuttles (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42333003",
        "yellow_page_code": "40208",
        "kiosk": false
    },
    {
        "value": "503311",
        "label": "Pitch (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42333006",
        "yellow_page_code": "65904",
        "kiosk": false
    },
    {
        "value": "503312",
        "label": "Weather Strips-Mfrs Equip & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42333013",
        "yellow_page_code": "92709",
        "kiosk": false
    },
    {
        "value": "503313",
        "label": "Weather Stripping Materials (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42333012",
        "yellow_page_code": "92711",
        "kiosk": false
    },
    {
        "value": "503327",
        "label": "Shingles-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42333009",
        "yellow_page_code": "76311",
        "kiosk": false
    },
    {
        "value": "503329",
        "label": "Weather Strips-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42333014",
        "yellow_page_code": "92801",
        "kiosk": false
    },
    {
        "value": "503901",
        "label": "Brick-Fire (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42339005",
        "yellow_page_code": "10602",
        "kiosk": false
    },
    {
        "value": "503902",
        "label": "Fence Posts & Fittings (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42339014",
        "yellow_page_code": "31306, 31310",
        "kiosk": false
    },
    {
        "value": "503903",
        "label": "Fence (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42339013",
        "yellow_page_code": "31304, 31305, 31311, 31322, 91147, 41138",
        "kiosk": false
    },
    {
        "value": "503904",
        "label": "Gates (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42339016",
        "yellow_page_code": "36902, 04442",
        "kiosk": false
    },
    {
        "value": "503905",
        "label": "Culvert-Mfr/Distr-Corrugated Iron (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42339011",
        "yellow_page_code": "22835",
        "kiosk": false
    },
    {
        "value": "503906",
        "label": "Glass-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42339018",
        "yellow_page_code": "37706, 37518, 37516, 37421, 04518, 41378, 21188",
        "kiosk": false
    },
    {
        "value": "503907",
        "label": "Waterproofing Materials (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42339033",
        "yellow_page_code": "92700",
        "kiosk": false
    },
    {
        "value": "503908",
        "label": "Nailing Machinery (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42339024",
        "yellow_page_code": "55100",
        "kiosk": false
    },
    {
        "value": "503909",
        "label": "Bullet-Resistant Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42339009",
        "yellow_page_code": "11408",
        "kiosk": false
    },
    {
        "value": "503910",
        "label": "Screens-Manufacturers' Equip/Supl (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42339027",
        "yellow_page_code": "74806",
        "kiosk": false
    },
    {
        "value": "503911",
        "label": "Buildings-Metal-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42339006",
        "yellow_page_code": "11309, 29941",
        "kiosk": false
    },
    {
        "value": "503912",
        "label": "Flag Poles (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42339015",
        "yellow_page_code": "32802",
        "kiosk": false
    },
    {
        "value": "503913",
        "label": "Art Metal Work (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42339002",
        "yellow_page_code": "03508, 51420",
        "kiosk": false
    },
    {
        "value": "503914",
        "label": "Asbestos & Asbestos Products (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42339003",
        "yellow_page_code": "04000",
        "kiosk": false
    },
    {
        "value": "503915",
        "label": "Septic Tanks & Systems-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42339028",
        "yellow_page_code": "75601",
        "kiosk": false
    },
    {
        "value": "503916",
        "label": "Tanks-Concrete (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42339031",
        "yellow_page_code": "83409",
        "kiosk": false
    },
    {
        "value": "503917",
        "label": "Mobile Homes-Distributors (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42339021",
        "yellow_page_code": "53005, 53102, 49942, 53220",
        "kiosk": false
    },
    {
        "value": "503918",
        "label": "Trailers-Office (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42339032",
        "yellow_page_code": "88009",
        "kiosk": false
    },
    {
        "value": "503919",
        "label": "Buildings-Pre-Cut Prefabricated-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42339007",
        "yellow_page_code": "11409",
        "kiosk": false
    },
    {
        "value": "503920",
        "label": "Mobile Homes-Mfrs-Equip/Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42339023",
        "yellow_page_code": "53108, 49954",
        "kiosk": false
    },
    {
        "value": "503921",
        "label": "Acoustical Materials-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42339001",
        "yellow_page_code": "00205, 77601, 79500, 79411",
        "kiosk": false
    },
    {
        "value": "503922",
        "label": "Awnings & Canopies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42339004",
        "yellow_page_code": "05409, 05416",
        "kiosk": false
    },
    {
        "value": "503923",
        "label": "Glass-Stained & Leaded-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42339017",
        "yellow_page_code": "37611",
        "kiosk": false
    },
    {
        "value": "503924",
        "label": "Greenhouses-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42339019",
        "yellow_page_code": "38907",
        "kiosk": false
    },
    {
        "value": "503925",
        "label": "Guards-Door & Window-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42339020",
        "yellow_page_code": "39301",
        "kiosk": false
    },
    {
        "value": "503926",
        "label": "Mobile Homes-Equipment & Parts-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42339022",
        "yellow_page_code": "53106, 53317",
        "kiosk": false
    },
    {
        "value": "503927",
        "label": "Nonskid Products (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42339025",
        "yellow_page_code": "55612",
        "kiosk": false
    },
    {
        "value": "503928",
        "label": "Shower Doors & Enclosures-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42339029",
        "yellow_page_code": "77307",
        "kiosk": false
    },
    {
        "value": "503929",
        "label": "Roof Curbs-Distributors (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42339026",
        "yellow_page_code": "72613, 72616",
        "kiosk": false
    },
    {
        "value": "503930",
        "label": "Store Fronts-Materials & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42339030",
        "yellow_page_code": "81812",
        "kiosk": false
    },
    {
        "value": "503931",
        "label": "Cupolas (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42339012",
        "yellow_page_code": "22809",
        "kiosk": false
    },
    {
        "value": "503932",
        "label": "Buildings-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42339008",
        "yellow_page_code": "11313",
        "kiosk": false
    },
    {
        "value": "503933",
        "label": "Wire Work (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42339034",
        "yellow_page_code": "93913",
        "kiosk": false
    },
    {
        "value": "503934",
        "label": "Manufactured Housing Eqp/Parts Whls/Mfr",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42339035",
        "yellow_page_code": "49846",
        "kiosk": false
    },
    {
        "value": "503935",
        "label": "Athletic Field Construction Mtrls/Spls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42339036",
        "yellow_page_code": "04215, 80410",
        "kiosk": false
    },
    {
        "value": "503936",
        "label": "Gabions",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418041",
        "yellow_page_code": "36035",
        "kiosk": false
    },
    {
        "value": "503937",
        "label": "Banding Edge",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42339037",
        "yellow_page_code": "06319",
        "kiosk": false
    },
    {
        "value": "503938",
        "label": "Building Material Dealers ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418085",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "503999",
        "label": "Construction Materials NEC (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42339010",
        "yellow_page_code": "67968, 18225",
        "kiosk": false
    },
    {
        "value": "504301",
        "label": "Audio-Visual Equipment & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42341001",
        "yellow_page_code": "04304, 30094, 21318",
        "kiosk": false
    },
    {
        "value": "504302",
        "label": "Identification Equipment & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42341003",
        "yellow_page_code": "42606",
        "kiosk": false
    },
    {
        "value": "504303",
        "label": "Motion Picture Film (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42341005",
        "yellow_page_code": "53909",
        "kiosk": false
    },
    {
        "value": "504304",
        "label": "Motion Picture Equip & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42341004",
        "yellow_page_code": "53906, 29958, 53912",
        "kiosk": false
    },
    {
        "value": "504305",
        "label": "Photographic Equip & Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42341008",
        "yellow_page_code": "61100",
        "kiosk": false
    },
    {
        "value": "504306",
        "label": "Projection Apparatus (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42341009",
        "yellow_page_code": "68606",
        "kiosk": false
    },
    {
        "value": "504307",
        "label": "Photographic Color Prints/Trnsprncy-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42341007",
        "yellow_page_code": "61014",
        "kiosk": false
    },
    {
        "value": "504308",
        "label": "Slides & Film Strips-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42341011",
        "yellow_page_code": "78313",
        "kiosk": false
    },
    {
        "value": "504309",
        "label": "Motion Picture Properties (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42341006",
        "yellow_page_code": "54101, 54019",
        "kiosk": false
    },
    {
        "value": "504310",
        "label": "Projectors (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42341010",
        "yellow_page_code": "68611",
        "kiosk": false
    },
    {
        "value": "504311",
        "label": "Color Separations Offset Photo Etc-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42341002",
        "yellow_page_code": "19808",
        "kiosk": false
    },
    {
        "value": "504312",
        "label": "Video Cameras-Wholesale & Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42341012",
        "yellow_page_code": "91025",
        "kiosk": false
    },
    {
        "value": "504313",
        "label": "Cinema Equipment & Services (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42341013",
        "yellow_page_code": "17920",
        "kiosk": false
    },
    {
        "value": "504401",
        "label": "Accounting & Bookkeeping Systems (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42342001",
        "yellow_page_code": "00101, 09402, 20367, 20371",
        "kiosk": false
    },
    {
        "value": "504402",
        "label": "Calculating & Adding Machs/Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42342008",
        "yellow_page_code": "12308, 12366",
        "kiosk": false
    },
    {
        "value": "504403",
        "label": "Copying & Duplicating Machines & Supls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42342015",
        "yellow_page_code": "21504, 60800, 21518, 21519, 60812, 60816, 21526, 30389, 21527",
        "kiosk": false
    },
    {
        "value": "504404",
        "label": "Check Protection Sign/Endrsng Mach (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42342012",
        "yellow_page_code": "14804, 14900, 43757",
        "kiosk": false
    },
    {
        "value": "504405",
        "label": "Computing Devices (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42342014",
        "yellow_page_code": "20333, 67420",
        "kiosk": false
    },
    {
        "value": "504406",
        "label": "Counting Machines (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42342016",
        "yellow_page_code": "22200",
        "kiosk": false
    },
    {
        "value": "504407",
        "label": "Coin/Bill Cntng Sorting/Wrap-Mach (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42342013",
        "yellow_page_code": "19408, 79408",
        "kiosk": false
    },
    {
        "value": "504408",
        "label": "Accounting/Bookkeeping Mach/Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42342002",
        "yellow_page_code": "00102, 00119",
        "kiosk": false
    },
    {
        "value": "504409",
        "label": "Addressing Machines & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42342003",
        "yellow_page_code": "00300",
        "kiosk": false
    },
    {
        "value": "504410",
        "label": "Blueprinting Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42342007",
        "yellow_page_code": "08602",
        "kiosk": false
    },
    {
        "value": "504411",
        "label": "Bank Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42342005",
        "yellow_page_code": "06400",
        "kiosk": false
    },
    {
        "value": "504412",
        "label": "Cash Registers & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42342010",
        "yellow_page_code": "13908",
        "kiosk": false
    },
    {
        "value": "504413",
        "label": "Chalkboards (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42342011",
        "yellow_page_code": "14608",
        "kiosk": false
    },
    {
        "value": "504414",
        "label": "Dictating Machines & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42342020",
        "yellow_page_code": "24608, 24601",
        "kiosk": false
    },
    {
        "value": "504415",
        "label": "Mailing Machines & Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42342024",
        "yellow_page_code": "49706, 67513, 49619, 34319, 30494, 67432",
        "kiosk": false
    },
    {
        "value": "504416",
        "label": "Duplicating Machines & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42342021",
        "yellow_page_code": "26904, 26840",
        "kiosk": false
    },
    {
        "value": "504417",
        "label": "Stenographic Machines & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42342033",
        "yellow_page_code": "81207, 81211",
        "kiosk": false
    },
    {
        "value": "504418",
        "label": "Safes & Vaults (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42342031",
        "yellow_page_code": "73308",
        "kiosk": false
    },
    {
        "value": "504419",
        "label": "Safes & Vaults-Used (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42342032",
        "yellow_page_code": "73401",
        "kiosk": false
    },
    {
        "value": "504420",
        "label": "Fluid Meters & Counting Devices-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42342023",
        "yellow_page_code": "33611",
        "kiosk": false
    },
    {
        "value": "504421",
        "label": "Bar Code Scanning Equip & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42342006",
        "yellow_page_code": "06407, 06417, 06420, 19218",
        "kiosk": false
    },
    {
        "value": "504422",
        "label": "Credit Card-Terminal Systems (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42342017",
        "yellow_page_code": "22500, 22419",
        "kiosk": false
    },
    {
        "value": "504423",
        "label": "Facsimile Comm Equip Systems/Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42342022",
        "yellow_page_code": "30414, 30512, 31015",
        "kiosk": false
    },
    {
        "value": "504424",
        "label": "Microfilming Service Equip & Supls-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42342026",
        "yellow_page_code": "52111",
        "kiosk": false
    },
    {
        "value": "504425",
        "label": "Time Recorders-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42342034",
        "yellow_page_code": "86135",
        "kiosk": false
    },
    {
        "value": "504426",
        "label": "Typewriters-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42342036",
        "yellow_page_code": "89712",
        "kiosk": false
    },
    {
        "value": "504427",
        "label": "Cash Registers & Supls-Repairing (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42342009",
        "yellow_page_code": "13912",
        "kiosk": false
    },
    {
        "value": "504428",
        "label": "Credit Card/Credit Plns Eqpt/Supl (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42342018",
        "yellow_page_code": "22408, 22417",
        "kiosk": false
    },
    {
        "value": "504429",
        "label": "Micrographics Service Equip/Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42342027",
        "yellow_page_code": "52114",
        "kiosk": false
    },
    {
        "value": "504430",
        "label": "Automation Systems & Equipment-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42342004",
        "yellow_page_code": "56205, 04503, 56218, 56442",
        "kiosk": false
    },
    {
        "value": "504431",
        "label": "Decollating Machines (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42342019",
        "yellow_page_code": "23614",
        "kiosk": false
    },
    {
        "value": "504432",
        "label": "Post Office Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42342029",
        "yellow_page_code": "67433",
        "kiosk": false
    },
    {
        "value": "504433",
        "label": "Postage Stamp Dispensing Machines (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42342030",
        "yellow_page_code": "67437",
        "kiosk": false
    },
    {
        "value": "504434",
        "label": "Microfilm Storage (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42342025",
        "yellow_page_code": "52203",
        "kiosk": false
    },
    {
        "value": "504435",
        "label": "Training Devices (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42342035",
        "yellow_page_code": "88105",
        "kiosk": false
    },
    {
        "value": "504436",
        "label": "Plastic Cards(credit Gift Etc) Whls-Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42342038",
        "yellow_page_code": "66221",
        "kiosk": false
    },
    {
        "value": "504437",
        "label": "ATM Sales & Service (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42342039",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "504498",
        "label": "Office Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42342028",
        "yellow_page_code": "11728",
        "kiosk": false
    },
    {
        "value": "504501",
        "label": "Laser Printers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42343010",
        "yellow_page_code": "46015",
        "kiosk": false
    },
    {
        "value": "504502",
        "label": "Computers-Supplies & Parts-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42343004",
        "yellow_page_code": "20318",
        "kiosk": false
    },
    {
        "value": "504503",
        "label": "Computers-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42343006",
        "yellow_page_code": "20326, 20362, 30383, 42676, 49880",
        "kiosk": false
    },
    {
        "value": "504504",
        "label": "Information Retrieval Systs/Equip (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42343009",
        "yellow_page_code": "43006, 43018",
        "kiosk": false
    },
    {
        "value": "504505",
        "label": "Word Processing Equipment & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42343012",
        "yellow_page_code": "94406, 94417",
        "kiosk": false
    },
    {
        "value": "504506",
        "label": "Data Communications Equip/Systems (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42343007",
        "yellow_page_code": "23405, 85222, 30177, 43671",
        "kiosk": false
    },
    {
        "value": "504507",
        "label": "Data Processing Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42343008",
        "yellow_page_code": "23406, 23418",
        "kiosk": false
    },
    {
        "value": "504508",
        "label": "Computers-Micro Business-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42343002",
        "yellow_page_code": "20305",
        "kiosk": false
    },
    {
        "value": "504509",
        "label": "Computer Software-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42343001",
        "yellow_page_code": "20332, 30097",
        "kiosk": false
    },
    {
        "value": "504510",
        "label": "Computers-Supply-Remanufacturing (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42343005",
        "yellow_page_code": "20338",
        "kiosk": false
    },
    {
        "value": "504511",
        "label": "Scanning Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42343011",
        "yellow_page_code": "74207",
        "kiosk": false
    },
    {
        "value": "504512",
        "label": "Computers-Software Duplication (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42343003",
        "yellow_page_code": "20325",
        "kiosk": false
    },
    {
        "value": "504513",
        "label": "Laser Printer Supplies Svc-Repair (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42343013",
        "yellow_page_code": "46026",
        "kiosk": false
    },
    {
        "value": "504514",
        "label": "Computer & Computer Equip Industrial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42343014",
        "yellow_page_code": "20280",
        "kiosk": false
    },
    {
        "value": "504515",
        "label": "Computer & Peripheral Eqpt & Software (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42343015",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "504601",
        "label": "Food Processing Equipment & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344016",
        "yellow_page_code": "33806, 33713",
        "kiosk": false
    },
    {
        "value": "504602",
        "label": "Display Fixtures & Materials (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344008",
        "yellow_page_code": "25102, 81815, 25020, 30397",
        "kiosk": false
    },
    {
        "value": "504603",
        "label": "Partitions (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344028",
        "yellow_page_code": "59506",
        "kiosk": false
    },
    {
        "value": "504604",
        "label": "Restaurant Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344032",
        "yellow_page_code": "71906, 30350, 71955, 43781",
        "kiosk": false
    },
    {
        "value": "504605",
        "label": "Racks-Industrial (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344030",
        "yellow_page_code": "69603",
        "kiosk": false
    },
    {
        "value": "504606",
        "label": "Shelving-Commercial & Industrial (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344039",
        "yellow_page_code": "76304, 76226, 42949",
        "kiosk": false
    },
    {
        "value": "504607",
        "label": "Scales (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344035",
        "yellow_page_code": "74304, 74310, 06009",
        "kiosk": false
    },
    {
        "value": "504608",
        "label": "Show Cases (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344041",
        "yellow_page_code": "77305",
        "kiosk": false
    },
    {
        "value": "504609",
        "label": "Vending Machines Supplies & Parts (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344045",
        "yellow_page_code": "90706",
        "kiosk": false
    },
    {
        "value": "504610",
        "label": "Store Fixtures (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344044",
        "yellow_page_code": "81806, 81825, 21375, 30533",
        "kiosk": false
    },
    {
        "value": "504611",
        "label": "Racks (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344029",
        "yellow_page_code": "69602, 58722, 29994",
        "kiosk": false
    },
    {
        "value": "504612",
        "label": "Vending Machines-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344046",
        "yellow_page_code": "90707",
        "kiosk": false
    },
    {
        "value": "504613",
        "label": "Shelving (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344038",
        "yellow_page_code": "76302, 30366",
        "kiosk": false
    },
    {
        "value": "504614",
        "label": "Juice Extractors (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344018",
        "yellow_page_code": "44600",
        "kiosk": false
    },
    {
        "value": "504615",
        "label": "Mannequins-Display Fixture (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344051",
        "yellow_page_code": "49806, 49810",
        "kiosk": false
    },
    {
        "value": "504616",
        "label": "Dehydrating Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344004",
        "yellow_page_code": "23709",
        "kiosk": false
    },
    {
        "value": "504617",
        "label": "Dryers-Hand (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344012",
        "yellow_page_code": "26800, 39866",
        "kiosk": false
    },
    {
        "value": "504618",
        "label": "Scoreboards (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344037",
        "yellow_page_code": "74709, 28041",
        "kiosk": false
    },
    {
        "value": "504619",
        "label": "Dishwashing Machines-Commercial (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344005",
        "yellow_page_code": "25003",
        "kiosk": false
    },
    {
        "value": "504620",
        "label": "Doughnut Machines & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344010",
        "yellow_page_code": "25505, 25460",
        "kiosk": false
    },
    {
        "value": "504621",
        "label": "Office Partitions (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344026",
        "yellow_page_code": "56303",
        "kiosk": false
    },
    {
        "value": "504622",
        "label": "Display Designers & Producers-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344007",
        "yellow_page_code": "25012",
        "kiosk": false
    },
    {
        "value": "504623",
        "label": "Dress Forms-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344011",
        "yellow_page_code": "26109, 26111",
        "kiosk": false
    },
    {
        "value": "504624",
        "label": "Lockers-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344021",
        "yellow_page_code": "48418, 74427, 48423",
        "kiosk": false
    },
    {
        "value": "504625",
        "label": "Restaurant Equipment & Supls-Used (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344031",
        "yellow_page_code": "71913",
        "kiosk": false
    },
    {
        "value": "504626",
        "label": "Restaurant Fixtures-Used (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344033",
        "yellow_page_code": "71915",
        "kiosk": false
    },
    {
        "value": "504627",
        "label": "Scales-Used (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344036",
        "yellow_page_code": "73311",
        "kiosk": false
    },
    {
        "value": "504628",
        "label": "Food Preservation Equip & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344015",
        "yellow_page_code": "33703",
        "kiosk": false
    },
    {
        "value": "504629",
        "label": "Kitchen Equipment-Commercial (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344025",
        "yellow_page_code": "44913",
        "kiosk": false
    },
    {
        "value": "504630",
        "label": "Parking Meters (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344027",
        "yellow_page_code": "59404",
        "kiosk": false
    },
    {
        "value": "504631",
        "label": "Whipped Cream Dispensers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344047",
        "yellow_page_code": "93405",
        "kiosk": false
    },
    {
        "value": "504632",
        "label": "Bar Equipment-Home & Office (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344001",
        "yellow_page_code": "06409",
        "kiosk": false
    },
    {
        "value": "504633",
        "label": "Food Mixes (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344014",
        "yellow_page_code": "33814",
        "kiosk": false
    },
    {
        "value": "504634",
        "label": "Frankfurter Stand Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344017",
        "yellow_page_code": "34313",
        "kiosk": false
    },
    {
        "value": "504635",
        "label": "Liquor Control Systems (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344019",
        "yellow_page_code": "47904",
        "kiosk": false
    },
    {
        "value": "504636",
        "label": "Dispensing Devices (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344006",
        "yellow_page_code": "25019",
        "kiosk": false
    },
    {
        "value": "504637",
        "label": "Display Fixtures & Materials-Used (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344009",
        "yellow_page_code": "25013",
        "kiosk": false
    },
    {
        "value": "504638",
        "label": "Carts (Display-Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344002",
        "yellow_page_code": "13836",
        "kiosk": false
    },
    {
        "value": "504639",
        "label": "Meat Patty Molding Machines (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344022",
        "yellow_page_code": "50809",
        "kiosk": false
    },
    {
        "value": "504640",
        "label": "Meat Processing Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344023",
        "yellow_page_code": "50812, 71717",
        "kiosk": false
    },
    {
        "value": "504641",
        "label": "Meat Tenderizing Machines (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344024",
        "yellow_page_code": "50901",
        "kiosk": false
    },
    {
        "value": "504642",
        "label": "Signs-Machines & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344043",
        "yellow_page_code": "77515",
        "kiosk": false
    },
    {
        "value": "504643",
        "label": "Vending Brokers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512006",
        "yellow_page_code": "90713",
        "kiosk": false
    },
    {
        "value": "504644",
        "label": "Signs-Electronic (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344013",
        "yellow_page_code": "77525, 24750, 27515, 77442",
        "kiosk": false
    },
    {
        "value": "504645",
        "label": "Work Benches (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344048",
        "yellow_page_code": "94405",
        "kiosk": false
    },
    {
        "value": "504646",
        "label": "Yogurt Equipment-Sales & Service (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344049",
        "yellow_page_code": "94810",
        "kiosk": false
    },
    {
        "value": "504647",
        "label": "Shopping Carts & Baskets-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344040",
        "yellow_page_code": "77113",
        "kiosk": false
    },
    {
        "value": "504648",
        "label": "Showroom Equip & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344042",
        "yellow_page_code": "77303",
        "kiosk": false
    },
    {
        "value": "504649",
        "label": "Neon Signs (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344052",
        "yellow_page_code": "55310, 55315, 55320",
        "kiosk": false
    },
    {
        "value": "504650",
        "label": "Lockers-Used (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344020",
        "yellow_page_code": "48403",
        "kiosk": false
    },
    {
        "value": "504651",
        "label": "Safe Deposit Box-Sales (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344034",
        "yellow_page_code": "73313",
        "kiosk": false
    },
    {
        "value": "504652",
        "label": "Art Galleries-Museums Equip-Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344050",
        "yellow_page_code": "03420",
        "kiosk": false
    },
    {
        "value": "504653",
        "label": "Appliances-Commercial/Ind-Sales/Rpr",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344053",
        "yellow_page_code": "03023",
        "kiosk": false
    },
    {
        "value": "504654",
        "label": "Jewelry Display Units (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344054",
        "yellow_page_code": "44138",
        "kiosk": false
    },
    {
        "value": "504655",
        "label": "Coffee Cappuccino Espresso Machs (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344055",
        "yellow_page_code": "19217",
        "kiosk": false
    },
    {
        "value": "504656",
        "label": "Lighting-Marquee & Display (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344056",
        "yellow_page_code": "47417",
        "kiosk": false
    },
    {
        "value": "504657",
        "label": "Supermarket Equipment Sales-Svc (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344057",
        "yellow_page_code": "82309",
        "kiosk": false
    },
    {
        "value": "504658",
        "label": "Ticket Dispenser Equip-Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344058",
        "yellow_page_code": "85905",
        "kiosk": false
    },
    {
        "value": "504659",
        "label": "Strainers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344059",
        "yellow_page_code": "81906",
        "kiosk": false
    },
    {
        "value": "504660",
        "label": "Signs Electrical (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344060",
        "yellow_page_code": "77440",
        "kiosk": false
    },
    {
        "value": "504661",
        "label": "Restaurant Fire Suppression Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344061",
        "yellow_page_code": "71925",
        "kiosk": false
    },
    {
        "value": "504662",
        "label": "Restroom Partitions-Commercial (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344062",
        "yellow_page_code": "71941",
        "kiosk": false
    },
    {
        "value": "504663",
        "label": "Signals-Wholesale & Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344063",
        "yellow_page_code": "77439",
        "kiosk": false
    },
    {
        "value": "504699",
        "label": "Commercial Equipment NEC (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42344003",
        "yellow_page_code": "19928",
        "kiosk": false
    },
    {
        "value": "504701",
        "label": "Physical Therapy Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345024",
        "yellow_page_code": "61208",
        "kiosk": false
    },
    {
        "value": "504702",
        "label": "X-Ray Protection (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345038",
        "yellow_page_code": "94702",
        "kiosk": false
    },
    {
        "value": "504703",
        "label": "Reducing Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345027",
        "yellow_page_code": "71108",
        "kiosk": false
    },
    {
        "value": "504704",
        "label": "Physicians & Surgeons Equip & Supls-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345028",
        "yellow_page_code": "51235, 51103, 61300, 82406, 80010, 51059, 71741, 00217, 51131, 49508, 42611, 71889, 30197, 51071, 90130, 24952, 49897, 41493",
        "kiosk": false
    },
    {
        "value": "504705",
        "label": "Respirators (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345029",
        "yellow_page_code": "71902",
        "kiosk": false
    },
    {
        "value": "504706",
        "label": "Surgical Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345032",
        "yellow_page_code": "82409",
        "kiosk": false
    },
    {
        "value": "504707",
        "label": "Wheel Chair Lifts & Ramps (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345035",
        "yellow_page_code": "93408, 47221, 70313, 74721, 80418",
        "kiosk": false
    },
    {
        "value": "504708",
        "label": "Handicapped Equipment-Sales & Svc (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345011",
        "yellow_page_code": "40100",
        "kiosk": false
    },
    {
        "value": "504709",
        "label": "Trusses-Medical & Surgical (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345033",
        "yellow_page_code": "89204",
        "kiosk": false
    },
    {
        "value": "504710",
        "label": "Health Appliances (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345012",
        "yellow_page_code": "40501, 40523",
        "kiosk": false
    },
    {
        "value": "504711",
        "label": "Medical Alarms (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345017",
        "yellow_page_code": "51007, 51032, 51234, 60224",
        "kiosk": false
    },
    {
        "value": "504712",
        "label": "Hospital Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345015",
        "yellow_page_code": "41802",
        "kiosk": false
    },
    {
        "value": "504713",
        "label": "Microscopes (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345019",
        "yellow_page_code": "52206",
        "kiosk": false
    },
    {
        "value": "504714",
        "label": "Chairs-Orthopedic & Lift (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345006",
        "yellow_page_code": "14606",
        "kiosk": false
    },
    {
        "value": "504715",
        "label": "Artificial Larynxes (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345002",
        "yellow_page_code": "03806",
        "kiosk": false
    },
    {
        "value": "504716",
        "label": "Canes (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345005",
        "yellow_page_code": "12804, 91280",
        "kiosk": false
    },
    {
        "value": "504717",
        "label": "Beds-Hospital (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345004",
        "yellow_page_code": "07406",
        "kiosk": false
    },
    {
        "value": "504718",
        "label": "Massage Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345016",
        "yellow_page_code": "50400",
        "kiosk": false
    },
    {
        "value": "504719",
        "label": "Dental Equipment & Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345007",
        "yellow_page_code": "23900, 23911, 23920, 23815, 30242, 30394",
        "kiosk": false
    },
    {
        "value": "504720",
        "label": "Medical Imaging Equip & Systems (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345037",
        "yellow_page_code": "94606, 51021, 94620, 24740, 94603, 94624",
        "kiosk": false
    },
    {
        "value": "504721",
        "label": "Podiatrists Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345026",
        "yellow_page_code": "66907",
        "kiosk": false
    },
    {
        "value": "504722",
        "label": "Oxygen-Medical (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345023",
        "yellow_page_code": "58207, 58217",
        "kiosk": false
    },
    {
        "value": "504723",
        "label": "Hearing Aids & Devices-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345013",
        "yellow_page_code": "40606",
        "kiosk": false
    },
    {
        "value": "504724",
        "label": "Orthopedic Appliances-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345021",
        "yellow_page_code": "58009, 29969",
        "kiosk": false
    },
    {
        "value": "504725",
        "label": "Wheel Chairs-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345036",
        "yellow_page_code": "93305",
        "kiosk": false
    },
    {
        "value": "504726",
        "label": "Foot Appliances-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345010",
        "yellow_page_code": "34009",
        "kiosk": false
    },
    {
        "value": "504727",
        "label": "Sterilizing Apparatus (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345030",
        "yellow_page_code": "81304",
        "kiosk": false
    },
    {
        "value": "504728",
        "label": "Surgical Corsets (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345031",
        "yellow_page_code": "82403",
        "kiosk": false
    },
    {
        "value": "504729",
        "label": "Ultraviolet Ray Lamps & Apparatus (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345034",
        "yellow_page_code": "89901",
        "kiosk": false
    },
    {
        "value": "504730",
        "label": "Physiotherapy Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345025",
        "yellow_page_code": "62407",
        "kiosk": false
    },
    {
        "value": "504731",
        "label": "Medical Electronics (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345018",
        "yellow_page_code": "51102, 29953",
        "kiosk": false
    },
    {
        "value": "504732",
        "label": "Hospital Cubicles (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345014",
        "yellow_page_code": "41711",
        "kiosk": false
    },
    {
        "value": "504733",
        "label": "Electrocardiogram Systs/Analysis (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345008",
        "yellow_page_code": "27900",
        "kiosk": false
    },
    {
        "value": "504734",
        "label": "Bed Boards (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345003",
        "yellow_page_code": "07307",
        "kiosk": false
    },
    {
        "value": "504735",
        "label": "Oxygen Producing Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345022",
        "yellow_page_code": "58206",
        "kiosk": false
    },
    {
        "value": "504736",
        "label": "Gloves-Surgical & Examination (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345039",
        "yellow_page_code": "37815",
        "kiosk": false
    },
    {
        "value": "504737",
        "label": "Nursing Home Equipment & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345020",
        "yellow_page_code": "56003",
        "kiosk": false
    },
    {
        "value": "504738",
        "label": "Acupuncturists Equipment & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345001",
        "yellow_page_code": "00210",
        "kiosk": false
    },
    {
        "value": "504739",
        "label": "Aesthetic Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345009",
        "yellow_page_code": "00719, 29812",
        "kiosk": false
    },
    {
        "value": "504740",
        "label": "Speech Therapy Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345040",
        "yellow_page_code": "79818, 79825",
        "kiosk": false
    },
    {
        "value": "504741",
        "label": "Holistic Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45619906",
        "yellow_page_code": "41191",
        "kiosk": false
    },
    {
        "value": "504742",
        "label": "Occupational Therapy Equip & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345041",
        "yellow_page_code": "56119",
        "kiosk": false
    },
    {
        "value": "504743",
        "label": "Oxygen Therapy Equipment Whls & Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345042",
        "yellow_page_code": "58220",
        "kiosk": false
    },
    {
        "value": "504744",
        "label": "Shielding-Nuclear (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345043",
        "yellow_page_code": "76310",
        "kiosk": false
    },
    {
        "value": "504745",
        "label": "Medical/Dental/Hospital Eqpt & Supl (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42345044",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "504801",
        "label": "Optical Goods-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42346007",
        "yellow_page_code": "57602, 37713",
        "kiosk": false
    },
    {
        "value": "504802",
        "label": "Optical Equipment Machinery/Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42346006",
        "yellow_page_code": "57508, 57707, 57519",
        "kiosk": false
    },
    {
        "value": "504803",
        "label": "Opticians-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42346009",
        "yellow_page_code": "57700",
        "kiosk": false
    },
    {
        "value": "504804",
        "label": "Optical Laboratories-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42346008",
        "yellow_page_code": "57603",
        "kiosk": false
    },
    {
        "value": "504805",
        "label": "Lens Coating (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42346003",
        "yellow_page_code": "46908",
        "kiosk": false
    },
    {
        "value": "504806",
        "label": "Sunglasses & Sun Goggles-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42346010",
        "yellow_page_code": "82208",
        "kiosk": false
    },
    {
        "value": "504807",
        "label": "Contact Lenses Whls & Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42346001",
        "yellow_page_code": "21030, 21098",
        "kiosk": false
    },
    {
        "value": "504808",
        "label": "Ophthalmic Instruments & Equip (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42346005",
        "yellow_page_code": "57702",
        "kiosk": false
    },
    {
        "value": "504809",
        "label": "Lenses-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42346004",
        "yellow_page_code": "46911",
        "kiosk": false
    },
    {
        "value": "504810",
        "label": "Eye Training Equipment & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42346002",
        "yellow_page_code": "30401",
        "kiosk": false
    },
    {
        "value": "504811",
        "label": "Eyeglasses/Contact Lenses-Whls/Mfr Equip",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42346011",
        "yellow_page_code": "30396",
        "kiosk": false
    },
    {
        "value": "504901",
        "label": "Architects Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42349002",
        "yellow_page_code": "03300, 03120, 03205",
        "kiosk": false
    },
    {
        "value": "504902",
        "label": "Drafting Room Equipment & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42349006",
        "yellow_page_code": "25801, 25804",
        "kiosk": false
    },
    {
        "value": "504903",
        "label": "Geologists Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42349008",
        "yellow_page_code": "37202",
        "kiosk": false
    },
    {
        "value": "504904",
        "label": "Engineering Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42349007",
        "yellow_page_code": "28708, 29227, 29740",
        "kiosk": false
    },
    {
        "value": "504905",
        "label": "Scientific Apparatus & Instruments-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42349019",
        "yellow_page_code": "74702, 43321, 74720, 74724, 30158",
        "kiosk": false
    },
    {
        "value": "504906",
        "label": "Laboratory Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42349012",
        "yellow_page_code": "45406, 45310",
        "kiosk": false
    },
    {
        "value": "504907",
        "label": "Thermometers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42349023",
        "yellow_page_code": "85706",
        "kiosk": false
    },
    {
        "value": "504908",
        "label": "Lasers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42349013",
        "yellow_page_code": "46006",
        "kiosk": false
    },
    {
        "value": "504909",
        "label": "Geophysical Apparatus (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42349009",
        "yellow_page_code": "37205",
        "kiosk": false
    },
    {
        "value": "504910",
        "label": "Aircraft Instruments (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42349001",
        "yellow_page_code": "01504",
        "kiosk": false
    },
    {
        "value": "504911",
        "label": "Levels (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42349014",
        "yellow_page_code": "47006",
        "kiosk": false
    },
    {
        "value": "504912",
        "label": "Lie Detectors (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42349025",
        "yellow_page_code": "47137, 67120",
        "kiosk": false
    },
    {
        "value": "504913",
        "label": "Slide Rules-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42349020",
        "yellow_page_code": "78307, 78311",
        "kiosk": false
    },
    {
        "value": "504914",
        "label": "Assayers Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42349003",
        "yellow_page_code": "04011",
        "kiosk": false
    },
    {
        "value": "504915",
        "label": "Co-Generation Equipment & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42349005",
        "yellow_page_code": "19315",
        "kiosk": false
    },
    {
        "value": "504916",
        "label": "Global Positioning Systems (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42349010",
        "yellow_page_code": "37810, 37807",
        "kiosk": false
    },
    {
        "value": "504917",
        "label": "Biological Laboratory Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42349004",
        "yellow_page_code": "08217",
        "kiosk": false
    },
    {
        "value": "504918",
        "label": "Metallurgists Equipment & Supplies-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42349015",
        "yellow_page_code": "51808",
        "kiosk": false
    },
    {
        "value": "504919",
        "label": "Meteorological Instruments (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42349016",
        "yellow_page_code": "52005",
        "kiosk": false
    },
    {
        "value": "504920",
        "label": "Micrometers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42349017",
        "yellow_page_code": "52202",
        "kiosk": false
    },
    {
        "value": "504921",
        "label": "Hypnotists Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42349011",
        "yellow_page_code": "42209",
        "kiosk": false
    },
    {
        "value": "504922",
        "label": "Surveillance Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42349022",
        "yellow_page_code": "82414, 91142",
        "kiosk": false
    },
    {
        "value": "504923",
        "label": "Soil Testing Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42349021",
        "yellow_page_code": "79213",
        "kiosk": false
    },
    {
        "value": "504924",
        "label": "Geotechnical Apparatus & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42349024",
        "yellow_page_code": "37221",
        "kiosk": false
    },
    {
        "value": "504925",
        "label": "Archaeologists Equip & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42349026",
        "yellow_page_code": "03150",
        "kiosk": false
    },
    {
        "value": "504926",
        "label": "Religious Supplies Judaic Whls & Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45941019",
        "yellow_page_code": "71547",
        "kiosk": false
    },
    {
        "value": "504999",
        "label": "Professional Equipment NEC (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42349018",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "505101",
        "label": "Wire & Cable-Non-Electric (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351040",
        "yellow_page_code": "93903",
        "kiosk": false
    },
    {
        "value": "505102",
        "label": "Wire (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351039",
        "yellow_page_code": "93904, 93815, 12129",
        "kiosk": false
    },
    {
        "value": "505103",
        "label": "Wire Products (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351042",
        "yellow_page_code": "94004",
        "kiosk": false
    },
    {
        "value": "505104",
        "label": "Piling (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351028",
        "yellow_page_code": "65208",
        "kiosk": false
    },
    {
        "value": "505105",
        "label": "Wire Rope (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351043",
        "yellow_page_code": "94006",
        "kiosk": false
    },
    {
        "value": "505106",
        "label": "Steel-Distributors & Warehouses (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351036",
        "yellow_page_code": "81000, 80997, 80423, 29855, 80923",
        "kiosk": false
    },
    {
        "value": "505107",
        "label": "Pipe-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351029",
        "yellow_page_code": "65402, 65701, 25818, 29982",
        "kiosk": false
    },
    {
        "value": "505108",
        "label": "Tube Fittings (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351037",
        "yellow_page_code": "89304",
        "kiosk": false
    },
    {
        "value": "505109",
        "label": "Iron (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351012",
        "yellow_page_code": "43804, 43822",
        "kiosk": false
    },
    {
        "value": "505110",
        "label": "Tubing-Metal (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351038",
        "yellow_page_code": "89306, 80945, 80944",
        "kiosk": false
    },
    {
        "value": "505111",
        "label": "Metal-Distributors (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351023",
        "yellow_page_code": "51501, 51600",
        "kiosk": false
    },
    {
        "value": "505112",
        "label": "Lead (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351016",
        "yellow_page_code": "46500",
        "kiosk": false
    },
    {
        "value": "505113",
        "label": "Metal Specialties (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351022",
        "yellow_page_code": "51702",
        "kiosk": false
    },
    {
        "value": "505114",
        "label": "Nails & Tacks (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351026",
        "yellow_page_code": "55102",
        "kiosk": false
    },
    {
        "value": "505115",
        "label": "Metals-Base (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351025",
        "yellow_page_code": "51902, 51417, 89702, 51520",
        "kiosk": false
    },
    {
        "value": "505116",
        "label": "Metal Letters (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351019",
        "yellow_page_code": "51514",
        "kiosk": false
    },
    {
        "value": "505117",
        "label": "Alloys (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351001",
        "yellow_page_code": "01908, 10403, 01936, 94909",
        "kiosk": false
    },
    {
        "value": "505118",
        "label": "Anodes (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351003",
        "yellow_page_code": "02703",
        "kiosk": false
    },
    {
        "value": "505119",
        "label": "Concrete Reinforcements (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351008",
        "yellow_page_code": "20706, 71311",
        "kiosk": false
    },
    {
        "value": "505120",
        "label": "Castings-Investment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351006",
        "yellow_page_code": "14001",
        "kiosk": false
    },
    {
        "value": "505121",
        "label": "Copper Products (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351009",
        "yellow_page_code": "21508",
        "kiosk": false
    },
    {
        "value": "505122",
        "label": "Metal Finishers-Equipment & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351018",
        "yellow_page_code": "51503, 74734",
        "kiosk": false
    },
    {
        "value": "505123",
        "label": "Die Castings (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351010",
        "yellow_page_code": "24702",
        "kiosk": false
    },
    {
        "value": "505124",
        "label": "Laminated Structural Products (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351015",
        "yellow_page_code": "45507, 45610",
        "kiosk": false
    },
    {
        "value": "505125",
        "label": "Metal Ornaments (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351020",
        "yellow_page_code": "51516",
        "kiosk": false
    },
    {
        "value": "505126",
        "label": "Metal Powders (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351021",
        "yellow_page_code": "51509",
        "kiosk": false
    },
    {
        "value": "505127",
        "label": "Metal Extrusions-Impact (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351017",
        "yellow_page_code": "51603",
        "kiosk": false
    },
    {
        "value": "505128",
        "label": "Aluminum (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351002",
        "yellow_page_code": "02008, 02102, 13923",
        "kiosk": false
    },
    {
        "value": "505129",
        "label": "Steel Brokers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351048",
        "yellow_page_code": "80907, 30254",
        "kiosk": false
    },
    {
        "value": "505130",
        "label": "Metal-Steel (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351024",
        "yellow_page_code": "51705",
        "kiosk": false
    },
    {
        "value": "505131",
        "label": "Sheet Metal Work-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351032",
        "yellow_page_code": "76211, 30351",
        "kiosk": false
    },
    {
        "value": "505132",
        "label": "Barricades-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351005",
        "yellow_page_code": "06712",
        "kiosk": false
    },
    {
        "value": "505133",
        "label": "Castings-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351007",
        "yellow_page_code": "14018",
        "kiosk": false
    },
    {
        "value": "505134",
        "label": "Ironwork-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351014",
        "yellow_page_code": "43901",
        "kiosk": false
    },
    {
        "value": "505135",
        "label": "Wire Cloth-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351041",
        "yellow_page_code": "93923",
        "kiosk": false
    },
    {
        "value": "505136",
        "label": "Screens-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351031",
        "yellow_page_code": "74812",
        "kiosk": false
    },
    {
        "value": "505137",
        "label": "Iron-Pig (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351013",
        "yellow_page_code": "43809",
        "kiosk": false
    },
    {
        "value": "505138",
        "label": "Highway Guard Rails (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351011",
        "yellow_page_code": "41004",
        "kiosk": false
    },
    {
        "value": "505139",
        "label": "Pipe Structure (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351030",
        "yellow_page_code": "65710",
        "kiosk": false
    },
    {
        "value": "505140",
        "label": "Wire Rope Fittings (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351044",
        "yellow_page_code": "93921",
        "kiosk": false
    },
    {
        "value": "505141",
        "label": "Ball Screws & Splines (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351004",
        "yellow_page_code": "06109",
        "kiosk": false
    },
    {
        "value": "505142",
        "label": "Steel Alloy (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351033",
        "yellow_page_code": "80903",
        "kiosk": false
    },
    {
        "value": "505143",
        "label": "Tin Trade (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351047",
        "yellow_page_code": "86301",
        "kiosk": false
    },
    {
        "value": "505144",
        "label": "Steel Products (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351035",
        "yellow_page_code": "81011",
        "kiosk": false
    },
    {
        "value": "505145",
        "label": "Steel Processing/Fabricating Equip-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351034",
        "yellow_page_code": "81020",
        "kiosk": false
    },
    {
        "value": "505146",
        "label": "Non-Ferrous Metals (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351027",
        "yellow_page_code": "55617",
        "kiosk": false
    },
    {
        "value": "505147",
        "label": "Metal Stress Relieving (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351045",
        "yellow_page_code": "82089, 51419",
        "kiosk": false
    },
    {
        "value": "505148",
        "label": "Columbium (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351046",
        "yellow_page_code": "19919",
        "kiosk": false
    },
    {
        "value": "505149",
        "label": "Wire Forming & Working Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351050",
        "yellow_page_code": "93907",
        "kiosk": false
    },
    {
        "value": "505150",
        "label": "Rods-Threaded (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351049",
        "yellow_page_code": "72506",
        "kiosk": false
    },
    {
        "value": "505151",
        "label": "Pipe-Cast Iron",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42351051",
        "yellow_page_code": "65516",
        "kiosk": false
    },
    {
        "value": "505201",
        "label": "Coal & Coke-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42352001",
        "yellow_page_code": "18905, 18907",
        "kiosk": false
    },
    {
        "value": "505202",
        "label": "Copper (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42352004",
        "yellow_page_code": "21506",
        "kiosk": false
    },
    {
        "value": "505203",
        "label": "Coal Brokers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512007",
        "yellow_page_code": "18908",
        "kiosk": false
    },
    {
        "value": "505204",
        "label": "Nickel-Silver (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42352005",
        "yellow_page_code": "55603",
        "kiosk": false
    },
    {
        "value": "505205",
        "label": "Coal Industry Consultants (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42352003",
        "yellow_page_code": "18912",
        "kiosk": false
    },
    {
        "value": "505298",
        "label": "Coal & Other Minerals & Ores (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42352002",
        "yellow_page_code": "18919",
        "kiosk": false
    },
    {
        "value": "506301",
        "label": "Pole Line Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361057",
        "yellow_page_code": "67008",
        "kiosk": false
    },
    {
        "value": "506302",
        "label": "Power Transmission Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361060",
        "yellow_page_code": "68002, 29946",
        "kiosk": false
    },
    {
        "value": "506303",
        "label": "Power Plant Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361058",
        "yellow_page_code": "67906",
        "kiosk": false
    },
    {
        "value": "506304",
        "label": "Security Control Equip & Systems-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361063",
        "yellow_page_code": "75304, 93616, 13410, 22685, 75311, 75313, 75315, 75320, 75321, 75326, 75329, 28423, 75290, 94519, 25642, 25631, 75322, 75317, 75328",
        "kiosk": false
    },
    {
        "value": "506305",
        "label": "Signs-Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361066",
        "yellow_page_code": "77506, 30368",
        "kiosk": false
    },
    {
        "value": "506306",
        "label": "Signal Systems (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361065",
        "yellow_page_code": "77502",
        "kiosk": false
    },
    {
        "value": "506307",
        "label": "Switches-Electric (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361070",
        "yellow_page_code": "82904",
        "kiosk": false
    },
    {
        "value": "506308",
        "label": "Fire Alarm Systems (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361032",
        "yellow_page_code": "31902, 78504, 80224, 32122, 78514, 31909",
        "kiosk": false
    },
    {
        "value": "506309",
        "label": "Testing Apparatus-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361073",
        "yellow_page_code": "85300, 55614, 85210, 45333, 69812",
        "kiosk": false
    },
    {
        "value": "506310",
        "label": "Utilities-Undrgrnd-Cbl Pipe/Wire (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361080",
        "yellow_page_code": "90203",
        "kiosk": false
    },
    {
        "value": "506311",
        "label": "Transformers-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361077",
        "yellow_page_code": "27808, 88208",
        "kiosk": false
    },
    {
        "value": "506312",
        "label": "Wire & Cable-Electric (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361085",
        "yellow_page_code": "93905, 27810",
        "kiosk": false
    },
    {
        "value": "506313",
        "label": "Variable Speed Drives (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361082",
        "yellow_page_code": "90604, 26412",
        "kiosk": false
    },
    {
        "value": "506314",
        "label": "Fluorescent Lghtng Apparatus/Fxtr (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361035",
        "yellow_page_code": "33602",
        "kiosk": false
    },
    {
        "value": "506315",
        "label": "Fire Protection Equipment & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361033",
        "yellow_page_code": "32109, 32009, 80223, 80225, 80215, 30444",
        "kiosk": false
    },
    {
        "value": "506316",
        "label": "Generators-Electric (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361038",
        "yellow_page_code": "37100, 37107, 37014, 37015, 37110, 30324, 37115, 37116, 21264, 21271, 37117, 37119, 37120",
        "kiosk": false
    },
    {
        "value": "506317",
        "label": "Fuses-Electric (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361036",
        "yellow_page_code": "36000, 35910",
        "kiosk": false
    },
    {
        "value": "506318",
        "label": "Light Bulbs & Tubes (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361044",
        "yellow_page_code": "47300, 30343",
        "kiosk": false
    },
    {
        "value": "506319",
        "label": "Lighting Fixtures-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361047",
        "yellow_page_code": "47402, 47404, 27519",
        "kiosk": false
    },
    {
        "value": "506320",
        "label": "Lighting Equipment-Emergency (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361046",
        "yellow_page_code": "47306",
        "kiosk": false
    },
    {
        "value": "506321",
        "label": "Meters (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361052",
        "yellow_page_code": "30803, 52102, 29896",
        "kiosk": false
    },
    {
        "value": "506322",
        "label": "Lightning-Protection Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361050",
        "yellow_page_code": "47408",
        "kiosk": false
    },
    {
        "value": "506323",
        "label": "Batteries-Dry Cell-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361002",
        "yellow_page_code": "07006, 07005",
        "kiosk": false
    },
    {
        "value": "506324",
        "label": "Burglar Alarm Systems (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361007",
        "yellow_page_code": "11507, 01803, 11504, 11523, 11526, 28028, 41119, 11522",
        "kiosk": false
    },
    {
        "value": "506325",
        "label": "Batteries-Storage-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361003",
        "yellow_page_code": "07100, 07020",
        "kiosk": false
    },
    {
        "value": "506326",
        "label": "Emission Control & Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361087",
        "yellow_page_code": "28540",
        "kiosk": false
    },
    {
        "value": "506327",
        "label": "Burglar Resistant Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361008",
        "yellow_page_code": "11505",
        "kiosk": false
    },
    {
        "value": "506328",
        "label": "Controls Control Systs/Regulators (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361016",
        "yellow_page_code": "21300, 71515, 27510, 42916, 74289",
        "kiosk": false
    },
    {
        "value": "506329",
        "label": "Electric Equipment-Used (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361019",
        "yellow_page_code": "27602",
        "kiosk": false
    },
    {
        "value": "506330",
        "label": "Electric Equipment & Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361021",
        "yellow_page_code": "27506, 27511, 27818, 67913, 30336, 49881, 43779",
        "kiosk": false
    },
    {
        "value": "506331",
        "label": "Electric Motors-Distributors (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361022",
        "yellow_page_code": "27706",
        "kiosk": false
    },
    {
        "value": "506332",
        "label": "Electric Motors-Controls-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361090",
        "yellow_page_code": "27702",
        "kiosk": false
    },
    {
        "value": "506333",
        "label": "Electric Motors-Dlrs/Repairing (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361023",
        "yellow_page_code": "27704, 54318, 27518, 27701, 54220",
        "kiosk": false
    },
    {
        "value": "506334",
        "label": "Electric Motors-Supplies & Parts (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361024",
        "yellow_page_code": "27708",
        "kiosk": false
    },
    {
        "value": "506335",
        "label": "Electronic-Mfrs Representatives (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512013",
        "yellow_page_code": "28100, 28025",
        "kiosk": false
    },
    {
        "value": "506336",
        "label": "Electronic Instruments (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361028",
        "yellow_page_code": "27700, 28009, 30424, 30426",
        "kiosk": false
    },
    {
        "value": "506337",
        "label": "Switches-All Types (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361092",
        "yellow_page_code": "82909",
        "kiosk": false
    },
    {
        "value": "506338",
        "label": "Electronic Parts Assemblers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361029",
        "yellow_page_code": "28101, 28031, 28038, 30216",
        "kiosk": false
    },
    {
        "value": "506339",
        "label": "Wire Sealing & Tagging (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361093",
        "yellow_page_code": "93922",
        "kiosk": false
    },
    {
        "value": "506340",
        "label": "Weather Instruments (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361084",
        "yellow_page_code": "92706",
        "kiosk": false
    },
    {
        "value": "506341",
        "label": "Electronic Testing Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361031",
        "yellow_page_code": "28106, 30338",
        "kiosk": false
    },
    {
        "value": "506342",
        "label": "Battery Charging Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361005",
        "yellow_page_code": "07102, 07025",
        "kiosk": false
    },
    {
        "value": "506343",
        "label": "Christmas Lights & Decorations-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361010",
        "yellow_page_code": "15609",
        "kiosk": false
    },
    {
        "value": "506344",
        "label": "Electronic Power Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361030",
        "yellow_page_code": "28102, 67929",
        "kiosk": false
    },
    {
        "value": "506345",
        "label": "Battery Repairing & Rebuilding (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361006",
        "yellow_page_code": "07104",
        "kiosk": false
    },
    {
        "value": "506346",
        "label": "Wire Harnesses-Electrical (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361086",
        "yellow_page_code": "93908",
        "kiosk": false
    },
    {
        "value": "506347",
        "label": "Photo Electric Cells & Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361056",
        "yellow_page_code": "60804",
        "kiosk": false
    },
    {
        "value": "506348",
        "label": "Capacitors (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361009",
        "yellow_page_code": "13002",
        "kiosk": false
    },
    {
        "value": "506349",
        "label": "Semiconductor-Mfrs' Equip/Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361064",
        "yellow_page_code": "75505, 75521, 47430",
        "kiosk": false
    },
    {
        "value": "506350",
        "label": "Lighting-Plants (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361049",
        "yellow_page_code": "47407",
        "kiosk": false
    },
    {
        "value": "506351",
        "label": "Homeland Security-Radio/Comms Equip/Syst",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418078",
        "yellow_page_code": "41260",
        "kiosk": false
    },
    {
        "value": "506352",
        "label": "Batteries Wholesale & Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361094",
        "yellow_page_code": "07011",
        "kiosk": false
    },
    {
        "value": "506353",
        "label": "Sirens & Horns (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361067",
        "yellow_page_code": "77900, 41411",
        "kiosk": false
    },
    {
        "value": "506354",
        "label": "Searchlights (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361062",
        "yellow_page_code": "33104, 75202",
        "kiosk": false
    },
    {
        "value": "506355",
        "label": "Traffic Signs Signals & Equip-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361076",
        "yellow_page_code": "87805, 87808, 87816, 41025, 77527",
        "kiosk": false
    },
    {
        "value": "506356",
        "label": "Theatrical/Stage Lighting Equip (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361074",
        "yellow_page_code": "85506, 85599",
        "kiosk": false
    },
    {
        "value": "506357",
        "label": "Electrical Power Quality Systems (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361095",
        "yellow_page_code": "27816, 67926",
        "kiosk": false
    },
    {
        "value": "506358",
        "label": "Connectors-Electric (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361015",
        "yellow_page_code": "20909",
        "kiosk": false
    },
    {
        "value": "506359",
        "label": "Insulation Materials-Electric (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361040",
        "yellow_page_code": "43404",
        "kiosk": false
    },
    {
        "value": "506360",
        "label": "Electric Heating Elements (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361020",
        "yellow_page_code": "27604",
        "kiosk": false
    },
    {
        "value": "506361",
        "label": "Solenoids (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361068",
        "yellow_page_code": "79400",
        "kiosk": false
    },
    {
        "value": "506362",
        "label": "Coil Winding Machinery (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361012",
        "yellow_page_code": "19401",
        "kiosk": false
    },
    {
        "value": "506363",
        "label": "Electrodes (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361027",
        "yellow_page_code": "27809",
        "kiosk": false
    },
    {
        "value": "506364",
        "label": "Timing Devices-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361075",
        "yellow_page_code": "86208, 86214",
        "kiosk": false
    },
    {
        "value": "506365",
        "label": "Armatures (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361001",
        "yellow_page_code": "03306, 03311",
        "kiosk": false
    },
    {
        "value": "506366",
        "label": "Electric Switchboards/Panels (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361025",
        "yellow_page_code": "27806, 28029",
        "kiosk": false
    },
    {
        "value": "506367",
        "label": "Circuit Breakers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361011",
        "yellow_page_code": "18002",
        "kiosk": false
    },
    {
        "value": "506368",
        "label": "Lighting Systems & Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361048",
        "yellow_page_code": "47500, 47310, 47317",
        "kiosk": false
    },
    {
        "value": "506369",
        "label": "Electrical Discharge Machining (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361026",
        "yellow_page_code": "28000",
        "kiosk": false
    },
    {
        "value": "506370",
        "label": "Ultrasonic Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361079",
        "yellow_page_code": "89805, 89827, 89829",
        "kiosk": false
    },
    {
        "value": "506371",
        "label": "Microwave Ovens-Supplies & Parts (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361053",
        "yellow_page_code": "52305, 52221",
        "kiosk": false
    },
    {
        "value": "506372",
        "label": "Conduits & Conduit Fittings (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361014",
        "yellow_page_code": "20904, 20903",
        "kiosk": false
    },
    {
        "value": "506373",
        "label": "Switches-Pressure & Temperature (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361071",
        "yellow_page_code": "82906",
        "kiosk": false
    },
    {
        "value": "506374",
        "label": "Rectifiers-Electric (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361061",
        "yellow_page_code": "71107",
        "kiosk": false
    },
    {
        "value": "506376",
        "label": "Vacuum Cleaners-Supls & Parts-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361081",
        "yellow_page_code": "90403",
        "kiosk": false
    },
    {
        "value": "506377",
        "label": "Flashlights-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361034",
        "yellow_page_code": "32905",
        "kiosk": false
    },
    {
        "value": "506378",
        "label": "Lamps-Supplies & Parts-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361042",
        "yellow_page_code": "45709, 28127",
        "kiosk": false
    },
    {
        "value": "506379",
        "label": "Circuits-Printed & Etched-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361088",
        "yellow_page_code": "17938, 68210",
        "kiosk": false
    },
    {
        "value": "506381",
        "label": "Battery Back-Up Systems (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361004",
        "yellow_page_code": "07015, 87313",
        "kiosk": false
    },
    {
        "value": "506382",
        "label": "Turbines-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361078",
        "yellow_page_code": "89436",
        "kiosk": false
    },
    {
        "value": "506383",
        "label": "Lasers-Equipment & Service (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361043",
        "yellow_page_code": "46012",
        "kiosk": false
    },
    {
        "value": "506384",
        "label": "Sprinkler Alarm Systems (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361069",
        "yellow_page_code": "80203",
        "kiosk": false
    },
    {
        "value": "506385",
        "label": "Lamp Bulbs (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361041",
        "yellow_page_code": "45605",
        "kiosk": false
    },
    {
        "value": "506386",
        "label": "Compactors-Waste-Supplies & Parts (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361013",
        "yellow_page_code": "20115, 88420",
        "kiosk": false
    },
    {
        "value": "506387",
        "label": "Converters-Electric (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361017",
        "yellow_page_code": "21308",
        "kiosk": false
    },
    {
        "value": "506388",
        "label": "Gas-Card Lock (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361037",
        "yellow_page_code": "36615",
        "kiosk": false
    },
    {
        "value": "506389",
        "label": "Instruments (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361039",
        "yellow_page_code": "43306, 43327, 43352, 30341",
        "kiosk": false
    },
    {
        "value": "506390",
        "label": "Light Curtains (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361045",
        "yellow_page_code": "47237",
        "kiosk": false
    },
    {
        "value": "506391",
        "label": "Linemens Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361051",
        "yellow_page_code": "47509",
        "kiosk": false
    },
    {
        "value": "506392",
        "label": "Panels-Instrument (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361055",
        "yellow_page_code": "58714",
        "kiosk": false
    },
    {
        "value": "506393",
        "label": "Police Signal Systems (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361091",
        "yellow_page_code": "67009",
        "kiosk": false
    },
    {
        "value": "506394",
        "label": "Power Supply-Emergency (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361059",
        "yellow_page_code": "67918",
        "kiosk": false
    },
    {
        "value": "506395",
        "label": "Switches-Time (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361072",
        "yellow_page_code": "82907",
        "kiosk": false
    },
    {
        "value": "506396",
        "label": "Visual Control Systems (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361083",
        "yellow_page_code": "91211",
        "kiosk": false
    },
    {
        "value": "506397",
        "label": "Laser Cutting (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361018",
        "yellow_page_code": "23113, 46027",
        "kiosk": false
    },
    {
        "value": "506398",
        "label": "Fiber Optics Cable (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361089",
        "yellow_page_code": "31507",
        "kiosk": false
    },
    {
        "value": "506399",
        "label": "Misc Electrical Goods (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42361054",
        "yellow_page_code": "27419, 27829",
        "kiosk": false
    },
    {
        "value": "506401",
        "label": "Appliances-Household-Major-Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362002",
        "yellow_page_code": "03005, 02926, 03035, 03015",
        "kiosk": false
    },
    {
        "value": "506402",
        "label": "Video Recorders & Players-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362037",
        "yellow_page_code": "91126, 29883",
        "kiosk": false
    },
    {
        "value": "506403",
        "label": "Appliances-Household-Small-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362004",
        "yellow_page_code": "03009, 27307, 03033",
        "kiosk": false
    },
    {
        "value": "506404",
        "label": "Appliances-Household-Major-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362003",
        "yellow_page_code": "03008, 27826, 02917, 30453, 02935",
        "kiosk": false
    },
    {
        "value": "506405",
        "label": "Dishwashing Machines-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362009",
        "yellow_page_code": "25005",
        "kiosk": false
    },
    {
        "value": "506406",
        "label": "Garbage Disposal Equip-Ind & Coml (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362013",
        "yellow_page_code": "36204",
        "kiosk": false
    },
    {
        "value": "506407",
        "label": "Dishwashing Machines-Supls/Parts (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362008",
        "yellow_page_code": "25004",
        "kiosk": false
    },
    {
        "value": "506408",
        "label": "Washing Machines Dryers/Ironers-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362038",
        "yellow_page_code": "91800",
        "kiosk": false
    },
    {
        "value": "506409",
        "label": "Garbage Disposal Equipment-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362014",
        "yellow_page_code": "36205",
        "kiosk": false
    },
    {
        "value": "506410",
        "label": "Radio-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362020",
        "yellow_page_code": "69908",
        "kiosk": false
    },
    {
        "value": "506411",
        "label": "Ranges & Stoves-Supplies & Parts (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362022",
        "yellow_page_code": "70408, 81916, 70412, 21421",
        "kiosk": false
    },
    {
        "value": "506412",
        "label": "Ranges & Stoves-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362023",
        "yellow_page_code": "70500",
        "kiosk": false
    },
    {
        "value": "506413",
        "label": "Refrigerators & Freezers-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362025",
        "yellow_page_code": "71507",
        "kiosk": false
    },
    {
        "value": "506414",
        "label": "Refrigerators/Freezers-Supls/Prts (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362026",
        "yellow_page_code": "71500",
        "kiosk": false
    },
    {
        "value": "506415",
        "label": "Shavers-Electric (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362028",
        "yellow_page_code": "76104",
        "kiosk": false
    },
    {
        "value": "506416",
        "label": "Television & Radio-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362033",
        "yellow_page_code": "84608",
        "kiosk": false
    },
    {
        "value": "506417",
        "label": "Stereophonic & High Fidelity Equip-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362030",
        "yellow_page_code": "81302, 81217, 41041",
        "kiosk": false
    },
    {
        "value": "506418",
        "label": "Vacuum Cleaners-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362035",
        "yellow_page_code": "90404",
        "kiosk": false
    },
    {
        "value": "506419",
        "label": "Television-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362034",
        "yellow_page_code": "84908",
        "kiosk": false
    },
    {
        "value": "506420",
        "label": "Garbage Disposal Eqpt-Supls/Parts (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362012",
        "yellow_page_code": "36203",
        "kiosk": false
    },
    {
        "value": "506421",
        "label": "Speakers-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362029",
        "yellow_page_code": "79802",
        "kiosk": false
    },
    {
        "value": "506422",
        "label": "Sewing Machines Parts/Supls-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362027",
        "yellow_page_code": "75907",
        "kiosk": false
    },
    {
        "value": "506423",
        "label": "Air Conditioning Equip-Room Units-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "506424",
        "label": "Water Heaters-Industrial (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42372003",
        "yellow_page_code": "92214",
        "kiosk": false
    },
    {
        "value": "506425",
        "label": "Coffee Brewing Devices-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362005",
        "yellow_page_code": "19212",
        "kiosk": false
    },
    {
        "value": "506426",
        "label": "Compactors-Waste-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362006",
        "yellow_page_code": "20201",
        "kiosk": false
    },
    {
        "value": "506427",
        "label": "Dehumidifying Equipment-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362007",
        "yellow_page_code": "23711",
        "kiosk": false
    },
    {
        "value": "506428",
        "label": "Refrigeration Freezers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362024",
        "yellow_page_code": "71404, 34321",
        "kiosk": false
    },
    {
        "value": "506429",
        "label": "Fans-Household-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362010",
        "yellow_page_code": "30705",
        "kiosk": false
    },
    {
        "value": "506430",
        "label": "Food Mixers-Electric-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362011",
        "yellow_page_code": "33704",
        "kiosk": false
    },
    {
        "value": "506431",
        "label": "Hair Dryers-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362015",
        "yellow_page_code": "39607, 39602, 26726",
        "kiosk": false
    },
    {
        "value": "506432",
        "label": "Humidifying Apparatus-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362017",
        "yellow_page_code": "42103",
        "kiosk": false
    },
    {
        "value": "506433",
        "label": "Microwave Ovens-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362018",
        "yellow_page_code": "52207",
        "kiosk": false
    },
    {
        "value": "506434",
        "label": "Phonographs-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362019",
        "yellow_page_code": "60801",
        "kiosk": false
    },
    {
        "value": "506435",
        "label": "Range Hoods & Canopies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362021",
        "yellow_page_code": "70309",
        "kiosk": false
    },
    {
        "value": "506436",
        "label": "Tape Recorders/Players-Sound-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362031",
        "yellow_page_code": "83800",
        "kiosk": false
    },
    {
        "value": "506437",
        "label": "Video Equipment-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362036",
        "yellow_page_code": "91011",
        "kiosk": false
    },
    {
        "value": "506438",
        "label": "Telephone Answering Systs/Equip (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362032",
        "yellow_page_code": "84428",
        "kiosk": false
    },
    {
        "value": "506439",
        "label": "Humidifying Apparatus-Parts/Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362016",
        "yellow_page_code": "42034",
        "kiosk": false
    },
    {
        "value": "506440",
        "label": "Radios-Amateur (Wholesale)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362050",
        "yellow_page_code": "70018",
        "kiosk": false
    },
    {
        "value": "506441",
        "label": "Household Appliances & Electronics (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362051",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "506501",
        "label": "Video Tapes & Discs-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369062",
        "yellow_page_code": "91128, 30257",
        "kiosk": false
    },
    {
        "value": "506502",
        "label": "Video Games-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369061",
        "yellow_page_code": "91118, 29882",
        "kiosk": false
    },
    {
        "value": "506503",
        "label": "Recording Studio Eqpt-Sound/Video (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369032",
        "yellow_page_code": "70906",
        "kiosk": false
    },
    {
        "value": "506504",
        "label": "Radio Communication Equip & Systems-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369026",
        "yellow_page_code": "69708, 69824, 29995",
        "kiosk": false
    },
    {
        "value": "506505",
        "label": "Radio Paging/Signaling Eqpt Systs (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369028",
        "yellow_page_code": "58500, 69800, 58436, 58437",
        "kiosk": false
    },
    {
        "value": "506506",
        "label": "Television & Radio Supplies & Parts-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369052",
        "yellow_page_code": "69907, 84808, 29861",
        "kiosk": false
    },
    {
        "value": "506507",
        "label": "Sound Systems & Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369037",
        "yellow_page_code": "79508, 70919, 29850",
        "kiosk": false
    },
    {
        "value": "506508",
        "label": "Tapes (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369039",
        "yellow_page_code": "83802, 83819",
        "kiosk": false
    },
    {
        "value": "506509",
        "label": "Stereophonic/High Fidelity Parts (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369038",
        "yellow_page_code": "81208",
        "kiosk": false
    },
    {
        "value": "506510",
        "label": "Telephone Equipment & Systems-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369047",
        "yellow_page_code": "84407, 84426",
        "kiosk": false
    },
    {
        "value": "506511",
        "label": "Telephone Equip/Systs-Supls/Parts (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369046",
        "yellow_page_code": "84403, 30270",
        "kiosk": false
    },
    {
        "value": "506512",
        "label": "Teletypewriter Comms Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369050",
        "yellow_page_code": "84503",
        "kiosk": false
    },
    {
        "value": "506513",
        "label": "Teletypewriter Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369051",
        "yellow_page_code": "84506",
        "kiosk": false
    },
    {
        "value": "506514",
        "label": "Intercommunication Eqpt Systs/Svc (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369019",
        "yellow_page_code": "43508, 43517, 30478",
        "kiosk": false
    },
    {
        "value": "506515",
        "label": "Television Systs/Eqpt-Clsd Crct (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369057",
        "yellow_page_code": "84900, 18614, 14130, 14131",
        "kiosk": false
    },
    {
        "value": "506516",
        "label": "Mobile Telephone Equip & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369023",
        "yellow_page_code": "53207, 53201, 30032, 43672",
        "kiosk": false
    },
    {
        "value": "506517",
        "label": "Calling Paging/Signal Eqpt/Systs (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369007",
        "yellow_page_code": "12405",
        "kiosk": false
    },
    {
        "value": "506518",
        "label": "Appliances-Hshld-Small-Supls/Prts (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369004",
        "yellow_page_code": "02908, 02933",
        "kiosk": false
    },
    {
        "value": "506519",
        "label": "Electronic Equipment & Supplies-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369015",
        "yellow_page_code": "28006, 28112, 28036, 42677, 49882",
        "kiosk": false
    },
    {
        "value": "506520",
        "label": "Coils-Electric (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369010",
        "yellow_page_code": "19404",
        "kiosk": false
    },
    {
        "value": "506521",
        "label": "Mobile Telephone Units (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369024",
        "yellow_page_code": "53209",
        "kiosk": false
    },
    {
        "value": "506522",
        "label": "Radio Station Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369029",
        "yellow_page_code": "69805",
        "kiosk": false
    },
    {
        "value": "506523",
        "label": "Radar Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369025",
        "yellow_page_code": "69700",
        "kiosk": false
    },
    {
        "value": "506524",
        "label": "Microwave Comm Equip & Systems (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369022",
        "yellow_page_code": "52208",
        "kiosk": false
    },
    {
        "value": "506525",
        "label": "Television Studio Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369056",
        "yellow_page_code": "84809",
        "kiosk": false
    },
    {
        "value": "506527",
        "label": "Telemetering Systems & Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369045",
        "yellow_page_code": "84302, 84258",
        "kiosk": false
    },
    {
        "value": "506528",
        "label": "Facsimile Communication Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369017",
        "yellow_page_code": "30506, 31017, 31027, 31024",
        "kiosk": false
    },
    {
        "value": "506529",
        "label": "Telecommunications Equip-Disabled (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369042",
        "yellow_page_code": "84213, 84220",
        "kiosk": false
    },
    {
        "value": "506530",
        "label": "Television Station Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369055",
        "yellow_page_code": "84805",
        "kiosk": false
    },
    {
        "value": "506531",
        "label": "Recording Instr Ind/Scientific (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369031",
        "yellow_page_code": "70902",
        "kiosk": false
    },
    {
        "value": "506532",
        "label": "Radio Communications Equip-Mobile (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369027",
        "yellow_page_code": "69709",
        "kiosk": false
    },
    {
        "value": "506533",
        "label": "Records-Phonograph-Mfrs Eqpt/Supl (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369033",
        "yellow_page_code": "70907, 20137, 14218",
        "kiosk": false
    },
    {
        "value": "506534",
        "label": "Telephone-Coin & Card Operated (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369049",
        "yellow_page_code": "84402, 84515",
        "kiosk": false
    },
    {
        "value": "506535",
        "label": "Annunciators-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369002",
        "yellow_page_code": "02634",
        "kiosk": false
    },
    {
        "value": "506536",
        "label": "Antennas-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369003",
        "yellow_page_code": "02702",
        "kiosk": false
    },
    {
        "value": "506537",
        "label": "Cable Television Equipment-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369006",
        "yellow_page_code": "12118",
        "kiosk": false
    },
    {
        "value": "506538",
        "label": "Radios-Citizens Band-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369030",
        "yellow_page_code": "70011",
        "kiosk": false
    },
    {
        "value": "506539",
        "label": "Semiconductor Devices-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369036",
        "yellow_page_code": "75433",
        "kiosk": false
    },
    {
        "value": "506540",
        "label": "Telephone Instruments-Antq & Dcrtr-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369048",
        "yellow_page_code": "84421",
        "kiosk": false
    },
    {
        "value": "506541",
        "label": "Television Antenna Systems-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369053",
        "yellow_page_code": "84612",
        "kiosk": false
    },
    {
        "value": "506542",
        "label": "Television Master Antenna Systems-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369054",
        "yellow_page_code": "84703",
        "kiosk": false
    },
    {
        "value": "506543",
        "label": "Satellite Dish-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369034",
        "yellow_page_code": "73907",
        "kiosk": false
    },
    {
        "value": "506544",
        "label": "Electronic Tubes (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369016",
        "yellow_page_code": "28017",
        "kiosk": false
    },
    {
        "value": "506545",
        "label": "Coils-Fin (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369011",
        "yellow_page_code": "19405",
        "kiosk": false
    },
    {
        "value": "506546",
        "label": "Satellite Equipment & Systems-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369035",
        "yellow_page_code": "73909",
        "kiosk": false
    },
    {
        "value": "506547",
        "label": "Translators' Systems & Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369059",
        "yellow_page_code": "88304, 29872",
        "kiosk": false
    },
    {
        "value": "506548",
        "label": "Discotheque Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369013",
        "yellow_page_code": "24911",
        "kiosk": false
    },
    {
        "value": "506549",
        "label": "Tapes-Sound & Video-Unrecorded (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369040",
        "yellow_page_code": "83812",
        "kiosk": false
    },
    {
        "value": "506550",
        "label": "Clocks-Master Cntrl/Signal Systs (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369009",
        "yellow_page_code": "18613",
        "kiosk": false
    },
    {
        "value": "506551",
        "label": "Communications Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369012",
        "yellow_page_code": "20012, 19917",
        "kiosk": false
    },
    {
        "value": "506552",
        "label": "Telegraph/Teletypewriter Apparatus",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369043",
        "yellow_page_code": "84311",
        "kiosk": false
    },
    {
        "value": "506553",
        "label": "Telex Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369058",
        "yellow_page_code": "84909",
        "kiosk": false
    },
    {
        "value": "506554",
        "label": "Marine-Radio Telephone Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369020",
        "yellow_page_code": "50035",
        "kiosk": false
    },
    {
        "value": "506555",
        "label": "Electronic Enclosures-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369014",
        "yellow_page_code": "28126",
        "kiosk": false
    },
    {
        "value": "506557",
        "label": "Business Television (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369005",
        "yellow_page_code": "11815",
        "kiosk": false
    },
    {
        "value": "506558",
        "label": "Cathodes (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369008",
        "yellow_page_code": "14110",
        "kiosk": false
    },
    {
        "value": "506559",
        "label": "Turntables (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369060",
        "yellow_page_code": "89600, 89360",
        "kiosk": false
    },
    {
        "value": "506560",
        "label": "Intercom Systems-Residential (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369018",
        "yellow_page_code": "43515",
        "kiosk": false
    },
    {
        "value": "506561",
        "label": "Telematic Equipment Service (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369044",
        "yellow_page_code": "84230",
        "kiosk": false
    },
    {
        "value": "506562",
        "label": "Microprocessors-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369021",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "506563",
        "label": "Telecommunication Equip/Syst-Whls/Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369041",
        "yellow_page_code": "84237, 84239, 84248",
        "kiosk": false
    },
    {
        "value": "506564",
        "label": "Computer Communication Technology (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369063",
        "yellow_page_code": "20225",
        "kiosk": false
    },
    {
        "value": "506565",
        "label": "Home Integration (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921084",
        "yellow_page_code": "41221",
        "kiosk": false
    },
    {
        "value": "506566",
        "label": "Dvd Video Tapes Whls & Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369064",
        "yellow_page_code": "26981",
        "kiosk": false
    },
    {
        "value": "506567",
        "label": "Carbon Monoxide-Alarms & Testing (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369065",
        "yellow_page_code": "13209",
        "kiosk": false
    },
    {
        "value": "506568",
        "label": "Radio Frequency Identification Distr",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42369001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "507201",
        "label": "Bolts & Nuts (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42371001",
        "yellow_page_code": "09108, 56013, 30475",
        "kiosk": false
    },
    {
        "value": "507202",
        "label": "Builders Hardware (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42371002",
        "yellow_page_code": "11104, 40107",
        "kiosk": false
    },
    {
        "value": "507203",
        "label": "Cutlery-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42371005",
        "yellow_page_code": "23100, 45101, 45110",
        "kiosk": false
    },
    {
        "value": "507204",
        "label": "Casters & Glides (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42371003",
        "yellow_page_code": "14002",
        "kiosk": false
    },
    {
        "value": "507205",
        "label": "Fasteners-Industrial (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42371007",
        "yellow_page_code": "31003, 31004, 31014",
        "kiosk": false
    },
    {
        "value": "507206",
        "label": "Door Closers & Checks (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42371006",
        "yellow_page_code": "25508, 30422",
        "kiosk": false
    },
    {
        "value": "507207",
        "label": "Hardware-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42371011",
        "yellow_page_code": "40202, 49893",
        "kiosk": false
    },
    {
        "value": "507208",
        "label": "Nozzles (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42371017",
        "yellow_page_code": "55802",
        "kiosk": false
    },
    {
        "value": "507209",
        "label": "Locksmith Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42371015",
        "yellow_page_code": "48506",
        "kiosk": false
    },
    {
        "value": "507210",
        "label": "Rivets (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42371022",
        "yellow_page_code": "72400",
        "kiosk": false
    },
    {
        "value": "507211",
        "label": "Seals-O-Ring (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42371025",
        "yellow_page_code": "75104",
        "kiosk": false
    },
    {
        "value": "507212",
        "label": "Screws (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42371024",
        "yellow_page_code": "75004, 30363",
        "kiosk": false
    },
    {
        "value": "507213",
        "label": "Tools (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42371031",
        "yellow_page_code": "87008, 87211, 87014, 52105, 29865, 86938",
        "kiosk": false
    },
    {
        "value": "507214",
        "label": "Staples Staplers & Tackers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42371028",
        "yellow_page_code": "80604, 80538, 55024",
        "kiosk": false
    },
    {
        "value": "507215",
        "label": "Robots (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42371023",
        "yellow_page_code": "72500",
        "kiosk": false
    },
    {
        "value": "507216",
        "label": "Locks & Locksmiths-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42371014",
        "yellow_page_code": "48496",
        "kiosk": false
    },
    {
        "value": "507217",
        "label": "Mail Boxes (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42371016",
        "yellow_page_code": "49606",
        "kiosk": false
    },
    {
        "value": "507218",
        "label": "Picture Hangers-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42371018",
        "yellow_page_code": "65105",
        "kiosk": false
    },
    {
        "value": "507219",
        "label": "Tapes-Measuring-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42371029",
        "yellow_page_code": "83803",
        "kiosk": false
    },
    {
        "value": "507220",
        "label": "Tool Chests Kits Bags Etc-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42371030",
        "yellow_page_code": "87002, 87019, 86914",
        "kiosk": false
    },
    {
        "value": "507221",
        "label": "Fasteners-Snap (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42371008",
        "yellow_page_code": "31005",
        "kiosk": false
    },
    {
        "value": "507222",
        "label": "Jail Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42371013",
        "yellow_page_code": "44107",
        "kiosk": false
    },
    {
        "value": "507223",
        "label": "Pivots-Precision Instrument (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42371019",
        "yellow_page_code": "65906",
        "kiosk": false
    },
    {
        "value": "507224",
        "label": "Handles-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42371010",
        "yellow_page_code": "40117",
        "kiosk": false
    },
    {
        "value": "507225",
        "label": "Shelf Reachers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42371026",
        "yellow_page_code": "76301",
        "kiosk": false
    },
    {
        "value": "507226",
        "label": "Spacer Tubes (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42371027",
        "yellow_page_code": "79612",
        "kiosk": false
    },
    {
        "value": "507227",
        "label": "Bolting (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42371032",
        "yellow_page_code": "09114",
        "kiosk": false
    },
    {
        "value": "507228",
        "label": "Furniture-Hardware-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42371009",
        "yellow_page_code": "35707",
        "kiosk": false
    },
    {
        "value": "507229",
        "label": "Hinges-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42371012",
        "yellow_page_code": "41014",
        "kiosk": false
    },
    {
        "value": "507230",
        "label": "Postal Boxes-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42371020",
        "yellow_page_code": "67515",
        "kiosk": false
    },
    {
        "value": "507231",
        "label": "Concrete Fasteners (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42371004",
        "yellow_page_code": "20509",
        "kiosk": false
    },
    {
        "value": "507232",
        "label": "Rings-Retaining (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42371021",
        "yellow_page_code": "72302",
        "kiosk": false
    },
    {
        "value": "507401",
        "label": "Water Heaters-Parts & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42372040",
        "yellow_page_code": "92208",
        "kiosk": false
    },
    {
        "value": "507402",
        "label": "Water Heaters-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42372041",
        "yellow_page_code": "92302",
        "kiosk": false
    },
    {
        "value": "507403",
        "label": "Pipe-Used (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42372025",
        "yellow_page_code": "65804",
        "kiosk": false
    },
    {
        "value": "507404",
        "label": "Pipe & Boiler Covering Materials (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362039",
        "yellow_page_code": "65406, 65409",
        "kiosk": false
    },
    {
        "value": "507405",
        "label": "Solar Energy Equipment-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42372032",
        "yellow_page_code": "79208, 79219, 79317, 79318, 79218, 79314, 79315, 79222, 30160",
        "kiosk": false
    },
    {
        "value": "507406",
        "label": "Plumbing Fixtures & Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42372027",
        "yellow_page_code": "66804, 66805, 31007, 66715, 66814, 30155, 49886",
        "kiosk": false
    },
    {
        "value": "507407",
        "label": "Solar Energy Eqpt/Syst-Supl/Parts (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42372031",
        "yellow_page_code": "79301, 82218, 79118",
        "kiosk": false
    },
    {
        "value": "507408",
        "label": "Oil Burners (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362040",
        "yellow_page_code": "56500, 56416, 56498, 11519",
        "kiosk": false
    },
    {
        "value": "507409",
        "label": "Gas Burners (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362041",
        "yellow_page_code": "36506, 36510",
        "kiosk": false
    },
    {
        "value": "507410",
        "label": "Back Flow Prevention Devices (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42372001",
        "yellow_page_code": "05604, 05616",
        "kiosk": false
    },
    {
        "value": "507411",
        "label": "Oil Burners Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362042",
        "yellow_page_code": "56503",
        "kiosk": false
    },
    {
        "value": "507412",
        "label": "Boilers-Supplies & Parts (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362043",
        "yellow_page_code": "09100, 09002, 09007",
        "kiosk": false
    },
    {
        "value": "507413",
        "label": "Gas Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362044",
        "yellow_page_code": "36605",
        "kiosk": false
    },
    {
        "value": "507414",
        "label": "Dust Collecting Systems (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42372011",
        "yellow_page_code": "26906, 26815, 26905",
        "kiosk": false
    },
    {
        "value": "507415",
        "label": "Steam Specialties (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42372034",
        "yellow_page_code": "80808",
        "kiosk": false
    },
    {
        "value": "507416",
        "label": "Wind Energy Systems (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42372047",
        "yellow_page_code": "93507, 93517, 93531, 93529",
        "kiosk": false
    },
    {
        "value": "507417",
        "label": "Steam Traps (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42372035",
        "yellow_page_code": "80900",
        "kiosk": false
    },
    {
        "value": "507418",
        "label": "Stokers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362045",
        "yellow_page_code": "81509",
        "kiosk": false
    },
    {
        "value": "507419",
        "label": "De-Aerators (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42372010",
        "yellow_page_code": "23613",
        "kiosk": false
    },
    {
        "value": "507420",
        "label": "Boilers-New & Used (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362046",
        "yellow_page_code": "09104, 09008, 58095",
        "kiosk": false
    },
    {
        "value": "507421",
        "label": "Oil Burners-Supplies & Parts (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362047",
        "yellow_page_code": "57005",
        "kiosk": false
    },
    {
        "value": "507422",
        "label": "Leak Detecting Instruments (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42372019",
        "yellow_page_code": "46508",
        "kiosk": false
    },
    {
        "value": "507423",
        "label": "Water Purification/Filtration Eqpt-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42372043",
        "yellow_page_code": "92402",
        "kiosk": false
    },
    {
        "value": "507424",
        "label": "Bath Equipment & Supplies-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42372002",
        "yellow_page_code": "07001, 07019",
        "kiosk": false
    },
    {
        "value": "507425",
        "label": "Brass Products-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42372005",
        "yellow_page_code": "10312",
        "kiosk": false
    },
    {
        "value": "507426",
        "label": "Brass-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42372006",
        "yellow_page_code": "10311",
        "kiosk": false
    },
    {
        "value": "507427",
        "label": "Fireplaces-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42372014",
        "yellow_page_code": "32207",
        "kiosk": false
    },
    {
        "value": "507428",
        "label": "Kitchen Fixtures-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42372018",
        "yellow_page_code": "44916",
        "kiosk": false
    },
    {
        "value": "507429",
        "label": "Coils-Steam (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42372008",
        "yellow_page_code": "19335",
        "kiosk": false
    },
    {
        "value": "507430",
        "label": "Porcelain Products-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42372028",
        "yellow_page_code": "67312",
        "kiosk": false
    },
    {
        "value": "507431",
        "label": "Sauna Equipment & Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42372029",
        "yellow_page_code": "74006",
        "kiosk": false
    },
    {
        "value": "507432",
        "label": "Sinks & Sink Tops-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42372030",
        "yellow_page_code": "77803",
        "kiosk": false
    },
    {
        "value": "507433",
        "label": "Stoves-Wood Coal Etc-Distributors (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362048",
        "yellow_page_code": "81907, 94215",
        "kiosk": false
    },
    {
        "value": "507434",
        "label": "Toilet Seats-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42372038",
        "yellow_page_code": "86807",
        "kiosk": false
    },
    {
        "value": "507435",
        "label": "Water Softening Equip Svc/Supls-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42372044",
        "yellow_page_code": "92409",
        "kiosk": false
    },
    {
        "value": "507436",
        "label": "Whirlpool Bath Equip & Supls-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42372046",
        "yellow_page_code": "93336",
        "kiosk": false
    },
    {
        "value": "507437",
        "label": "Water Purification-Chemical (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42372042",
        "yellow_page_code": "92307",
        "kiosk": false
    },
    {
        "value": "507438",
        "label": "Energy Recovery Systems (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42372012",
        "yellow_page_code": "28615",
        "kiosk": false
    },
    {
        "value": "507439",
        "label": "Cooler Pads (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42372009",
        "yellow_page_code": "21507",
        "kiosk": false
    },
    {
        "value": "507440",
        "label": "Coils (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42372007",
        "yellow_page_code": "19402",
        "kiosk": false
    },
    {
        "value": "507441",
        "label": "Incinerators-Supplies & Parts (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42362049",
        "yellow_page_code": "42805",
        "kiosk": false
    },
    {
        "value": "507442",
        "label": "Plugs (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42372026",
        "yellow_page_code": "66707",
        "kiosk": false
    },
    {
        "value": "507443",
        "label": "Steam Fitters' Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42372033",
        "yellow_page_code": "80807",
        "kiosk": false
    },
    {
        "value": "507444",
        "label": "Misting Systems-Outdoor (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42372020",
        "yellow_page_code": "52911, 52912",
        "kiosk": false
    },
    {
        "value": "507445",
        "label": "Engine & Boiler Room Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42372013",
        "yellow_page_code": "28716",
        "kiosk": false
    },
    {
        "value": "507446",
        "label": "Water Conditioners (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42372039",
        "yellow_page_code": "92111",
        "kiosk": false
    },
    {
        "value": "507447",
        "label": "Water Treatment Equip Svc & Supls-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42372045",
        "yellow_page_code": "92511, 92047",
        "kiosk": false
    },
    {
        "value": "507448",
        "label": "Pipe-New (Wholesale)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42333017",
        "yellow_page_code": "65316",
        "kiosk": false
    },
    {
        "value": "507449",
        "label": "Plumbing & Heating Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42372048",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "507501",
        "label": "Air Cleaning & Purifying Equip (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42373001",
        "yellow_page_code": "00902",
        "kiosk": false
    },
    {
        "value": "507502",
        "label": "Heat Recovery Equipment & Systems (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42373015",
        "yellow_page_code": "40700",
        "kiosk": false
    },
    {
        "value": "507503",
        "label": "Air Conditioning Equipment & Systems-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42373002",
        "yellow_page_code": "01101, 01207, 30165",
        "kiosk": false
    },
    {
        "value": "507504",
        "label": "Dust Control Materials (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42373009",
        "yellow_page_code": "26908, 26910, 26820",
        "kiosk": false
    },
    {
        "value": "507505",
        "label": "Air Conditioning Supplies & Parts (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42373003",
        "yellow_page_code": "01100, 00924, 01016, 21270",
        "kiosk": false
    },
    {
        "value": "507506",
        "label": "Electric Heating Equip & Systems (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42373024",
        "yellow_page_code": "27606, 40842",
        "kiosk": false
    },
    {
        "value": "507507",
        "label": "Furnaces-Heating (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42373012",
        "yellow_page_code": "35506, 35513, 35512, 41288, 43763",
        "kiosk": false
    },
    {
        "value": "507508",
        "label": "Heaters-Unit-Dealers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512008",
        "yellow_page_code": "40807, 40813, 40814, 40815, 27410",
        "kiosk": false
    },
    {
        "value": "507509",
        "label": "Furnaces-Parts & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42373013",
        "yellow_page_code": "35509, 36612, 35507, 40843",
        "kiosk": false
    },
    {
        "value": "507510",
        "label": "Heating Equipment & Systems (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42373018",
        "yellow_page_code": "40904, 40837, 40838, 14370",
        "kiosk": false
    },
    {
        "value": "507511",
        "label": "Heating Equip & Systs-Supls/Parts (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42373017",
        "yellow_page_code": "40903, 40827",
        "kiosk": false
    },
    {
        "value": "507512",
        "label": "Thermostats (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42373021",
        "yellow_page_code": "85707",
        "kiosk": false
    },
    {
        "value": "507513",
        "label": "Induction Heating Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42373019",
        "yellow_page_code": "42908, 40820",
        "kiosk": false
    },
    {
        "value": "507514",
        "label": "Ventilating Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42373022",
        "yellow_page_code": "90806, 44932",
        "kiosk": false
    },
    {
        "value": "507515",
        "label": "Air Curtains & Screens (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42373004",
        "yellow_page_code": "01108",
        "kiosk": false
    },
    {
        "value": "507516",
        "label": "Filters-Air-Home-Furnace/AC Etc (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42373011",
        "yellow_page_code": "31707",
        "kiosk": false
    },
    {
        "value": "507517",
        "label": "Heat Pumps-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42373014",
        "yellow_page_code": "40708",
        "kiosk": false
    },
    {
        "value": "507518",
        "label": "Fans-Blower (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42373006",
        "yellow_page_code": "30720",
        "kiosk": false
    },
    {
        "value": "507519",
        "label": "Heaters-Unit-Distributors (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512009",
        "yellow_page_code": "40808",
        "kiosk": false
    },
    {
        "value": "507520",
        "label": "Air Pollution Measuring Equip (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42373005",
        "yellow_page_code": "01303",
        "kiosk": false
    },
    {
        "value": "507521",
        "label": "Kerosene Heaters-Sales & Service (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42373020",
        "yellow_page_code": "44709, 40810, 40816",
        "kiosk": false
    },
    {
        "value": "507522",
        "label": "Compressors-Air Cond & Refrig (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42373007",
        "yellow_page_code": "20233",
        "kiosk": false
    },
    {
        "value": "507523",
        "label": "Condensers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42373008",
        "yellow_page_code": "20803",
        "kiosk": false
    },
    {
        "value": "507524",
        "label": "Heaters-Construction (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42373016",
        "yellow_page_code": "40811",
        "kiosk": false
    },
    {
        "value": "507525",
        "label": "Electronic Heating Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42373010",
        "yellow_page_code": "28018",
        "kiosk": false
    },
    {
        "value": "507526",
        "label": "Doors-Traffic (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42373023",
        "yellow_page_code": "25618",
        "kiosk": false
    },
    {
        "value": "507527",
        "label": "Masonry Heaters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42373027",
        "yellow_page_code": "50324",
        "kiosk": false
    },
    {
        "value": "507528",
        "label": "Swamp Coolers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42373025",
        "yellow_page_code": "82600, 21491",
        "kiosk": false
    },
    {
        "value": "507529",
        "label": "Air Pollution Control Equipment & Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42373026",
        "yellow_page_code": "01315",
        "kiosk": false
    },
    {
        "value": "507530",
        "label": "Heating & Air-Cond Eqpt & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42373028",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "507801",
        "label": "Water Coolers Fountains & Filters (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512076",
        "yellow_page_code": "92202, 92216",
        "kiosk": false
    },
    {
        "value": "507802",
        "label": "Refrigerating Equip Supls & Parts (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42374006",
        "yellow_page_code": "71306, 29998",
        "kiosk": false
    },
    {
        "value": "507803",
        "label": "Water Coolers Equipment & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512078",
        "yellow_page_code": "92204, 92127",
        "kiosk": false
    },
    {
        "value": "507804",
        "label": "Refrigerating Equip-Commercial (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512079",
        "yellow_page_code": "71402, 21434, 91317, 71409, 71412, 71315, 30134, 71316, 25025, 43780, 19946, 71317, 71413",
        "kiosk": false
    },
    {
        "value": "507805",
        "label": "Restaurant Fixtures (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512080",
        "yellow_page_code": "71909",
        "kiosk": false
    },
    {
        "value": "507806",
        "label": "Liquor Dispensing Equip-Sales/Svc (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512081",
        "yellow_page_code": "47901",
        "kiosk": false
    },
    {
        "value": "507807",
        "label": "Ice Cream Cabinets (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512082",
        "yellow_page_code": "42400",
        "kiosk": false
    },
    {
        "value": "507808",
        "label": "Soda Fountain Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512083",
        "yellow_page_code": "79100",
        "kiosk": false
    },
    {
        "value": "507809",
        "label": "Ice Cream Freezers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512084",
        "yellow_page_code": "42404, 42414, 42417",
        "kiosk": false
    },
    {
        "value": "507810",
        "label": "Bar-Equip Fixtures Supplies/Custom Made",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512085",
        "yellow_page_code": "06408, 06415, 06714",
        "kiosk": false
    },
    {
        "value": "507811",
        "label": "Ice Making Equipment & Machines (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512086",
        "yellow_page_code": "42504, 42321, 42516, 42325",
        "kiosk": false
    },
    {
        "value": "507812",
        "label": "Beer Dispensing & Cooling Equip (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512087",
        "yellow_page_code": "07602",
        "kiosk": false
    },
    {
        "value": "507813",
        "label": "Bar Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512088",
        "yellow_page_code": "06500",
        "kiosk": false
    },
    {
        "value": "507814",
        "label": "Beverage Dispensing Equip/Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512089",
        "yellow_page_code": "07900, 79101",
        "kiosk": false
    },
    {
        "value": "507815",
        "label": "Coolers-Evaporative-Supls/Parts (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512090",
        "yellow_page_code": "21501",
        "kiosk": false
    },
    {
        "value": "507816",
        "label": "Beverage Coolers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512091",
        "yellow_page_code": "07901",
        "kiosk": false
    },
    {
        "value": "507817",
        "label": "Coolers-Evaporative-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512092",
        "yellow_page_code": "21503",
        "kiosk": false
    },
    {
        "value": "507818",
        "label": "Marine Refrigeration/AC-Sales/Svc (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512093",
        "yellow_page_code": "50026",
        "kiosk": false
    },
    {
        "value": "507819",
        "label": "Fountains-Drinking (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512094",
        "yellow_page_code": "34300",
        "kiosk": false
    },
    {
        "value": "507820",
        "label": "Soda Fountain Equip-Repairing (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512095",
        "yellow_page_code": "79102",
        "kiosk": false
    },
    {
        "value": "507821",
        "label": "Refrigerating Equip-Coml-Used (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512096",
        "yellow_page_code": "71308",
        "kiosk": false
    },
    {
        "value": "507822",
        "label": "Frozen Custard Machines (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512097",
        "yellow_page_code": "34601",
        "kiosk": false
    },
    {
        "value": "507823",
        "label": "Ice Handling Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512098",
        "yellow_page_code": "42303",
        "kiosk": false
    },
    {
        "value": "507824",
        "label": "Ice Cream Cone-Mfrs' Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512099",
        "yellow_page_code": "42311",
        "kiosk": false
    },
    {
        "value": "507825",
        "label": "Slush Machines (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42374004",
        "yellow_page_code": "78407",
        "kiosk": false
    },
    {
        "value": "507826",
        "label": "Snow Ball Machines (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42374003",
        "yellow_page_code": "78603",
        "kiosk": false
    },
    {
        "value": "507827",
        "label": "Warehouses-Cold Storage-Eqpt/Supl (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42374001",
        "yellow_page_code": "91513",
        "kiosk": false
    },
    {
        "value": "507828",
        "label": "Ice Makers Equip-Home & Office (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42374002",
        "yellow_page_code": "42501",
        "kiosk": false
    },
    {
        "value": "507829",
        "label": "Refrigeration Equip-Coml-Renting-Leasing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42374005",
        "yellow_page_code": "71411",
        "kiosk": false
    },
    {
        "value": "508201",
        "label": "Wellpoint Systems (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512067",
        "yellow_page_code": "93208",
        "kiosk": false
    },
    {
        "value": "508202",
        "label": "Sandblasting Equip & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42381019",
        "yellow_page_code": "73804",
        "kiosk": false
    },
    {
        "value": "508203",
        "label": "Safety Equipment & Clothing (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42381036",
        "yellow_page_code": "73406, 73321, 73322, 03313, 18742, 42938, 68725, 29894, 56471",
        "kiosk": false
    },
    {
        "value": "508204",
        "label": "Concrete Breaking Cut/Saw Eqpt (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42381001",
        "yellow_page_code": "20505",
        "kiosk": false
    },
    {
        "value": "508205",
        "label": "Dry Wall Contractors Equip/Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42381037",
        "yellow_page_code": "26706, 26720",
        "kiosk": false
    },
    {
        "value": "508206",
        "label": "Contractors-Equip/Supls-Dlrs/Svc (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42381002",
        "yellow_page_code": "21200, 21194, 21133, 21078, 21071, 41924, 20935, 21243, 27118, 30386, 74738, 21255, 41494",
        "kiosk": false
    },
    {
        "value": "508207",
        "label": "Log Splitting Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42381003",
        "yellow_page_code": "48604, 48523",
        "kiosk": false
    },
    {
        "value": "508208",
        "label": "Excavating Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42381004",
        "yellow_page_code": "30004, 30017, 69410, 21275",
        "kiosk": false
    },
    {
        "value": "508209",
        "label": "Mason Contractors Equip & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42381038",
        "yellow_page_code": "50304, 50299",
        "kiosk": false
    },
    {
        "value": "508210",
        "label": "Road Building Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42381005",
        "yellow_page_code": "72404, 72420",
        "kiosk": false
    },
    {
        "value": "508211",
        "label": "Mining Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42381006",
        "yellow_page_code": "52604, 18914, 29957",
        "kiosk": false
    },
    {
        "value": "508212",
        "label": "Well Drilling Equipment & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42381020",
        "yellow_page_code": "93205",
        "kiosk": false
    },
    {
        "value": "508213",
        "label": "Water Well Drilling Equip & Supls-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42381021",
        "yellow_page_code": "92600, 30549",
        "kiosk": false
    },
    {
        "value": "508214",
        "label": "Surveying Instruments (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42381039",
        "yellow_page_code": "82500, 82416, 82323",
        "kiosk": false
    },
    {
        "value": "508215",
        "label": "Coal Preparation-Washing Equip (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42381022",
        "yellow_page_code": "18911",
        "kiosk": false
    },
    {
        "value": "508216",
        "label": "Grinding Machines & Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42381023",
        "yellow_page_code": "39100, 39810",
        "kiosk": false
    },
    {
        "value": "508217",
        "label": "Paving Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42381007",
        "yellow_page_code": "59900",
        "kiosk": false
    },
    {
        "value": "508218",
        "label": "Concrete Mixing Plant Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42381008",
        "yellow_page_code": "20609",
        "kiosk": false
    },
    {
        "value": "508219",
        "label": "Pipe Locating Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42381024",
        "yellow_page_code": "65707",
        "kiosk": false
    },
    {
        "value": "508220",
        "label": "Concrete Mixers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42381009",
        "yellow_page_code": "20606",
        "kiosk": false
    },
    {
        "value": "508221",
        "label": "Concrete Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42381010",
        "yellow_page_code": "20604, 20517, 77302",
        "kiosk": false
    },
    {
        "value": "508222",
        "label": "Concrete Vibrators (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42381011",
        "yellow_page_code": "20800",
        "kiosk": false
    },
    {
        "value": "508223",
        "label": "Concrete Pumping Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42381012",
        "yellow_page_code": "20703, 30385",
        "kiosk": false
    },
    {
        "value": "508224",
        "label": "Crushing & Pulverizing Equip (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42381013",
        "yellow_page_code": "22709",
        "kiosk": false
    },
    {
        "value": "508225",
        "label": "Mixing & Agitation Machinery (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42381014",
        "yellow_page_code": "53004, 53010",
        "kiosk": false
    },
    {
        "value": "508226",
        "label": "Sand & Gravel Handling Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42381025",
        "yellow_page_code": "73707",
        "kiosk": false
    },
    {
        "value": "508228",
        "label": "Contractors-Equipment-Used (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42381026",
        "yellow_page_code": "21212",
        "kiosk": false
    },
    {
        "value": "508229",
        "label": "Forestry & Timber Equip & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42381015",
        "yellow_page_code": "34103",
        "kiosk": false
    },
    {
        "value": "508230",
        "label": "Compactors-Earth (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42381027",
        "yellow_page_code": "20206",
        "kiosk": false
    },
    {
        "value": "508231",
        "label": "Buckets-Digging & Hoisting (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42381028",
        "yellow_page_code": "11003",
        "kiosk": false
    },
    {
        "value": "508232",
        "label": "Dredging Machinery (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42381029",
        "yellow_page_code": "26106, 26105, 30423",
        "kiosk": false
    },
    {
        "value": "508233",
        "label": "Importers-Contractors Equip/Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42381030",
        "yellow_page_code": "42698",
        "kiosk": false
    },
    {
        "value": "508234",
        "label": "Smelters & Refiners Equip/Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42381031",
        "yellow_page_code": "78411",
        "kiosk": false
    },
    {
        "value": "508235",
        "label": "Construction Machinery (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42381032",
        "yellow_page_code": "21007, 49212",
        "kiosk": false
    },
    {
        "value": "508236",
        "label": "Trench Boxes (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42381033",
        "yellow_page_code": "88520, 21268, 88599",
        "kiosk": false
    },
    {
        "value": "508237",
        "label": "Ore Processing Equipment & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42381042",
        "yellow_page_code": "57808",
        "kiosk": false
    },
    {
        "value": "508238",
        "label": "Water Well Casings (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42381035",
        "yellow_page_code": "92507",
        "kiosk": false
    },
    {
        "value": "508239",
        "label": "Asphalt Machinery (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42381016",
        "yellow_page_code": "04013",
        "kiosk": false
    },
    {
        "value": "508240",
        "label": "Construction Camp Equip/Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42381017",
        "yellow_page_code": "21024",
        "kiosk": false
    },
    {
        "value": "508242",
        "label": "Pile Driving Equipment Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42381018",
        "yellow_page_code": "65210",
        "kiosk": false
    },
    {
        "value": "508243",
        "label": "Concrete Imaging Services (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42381040",
        "yellow_page_code": "20519",
        "kiosk": false
    },
    {
        "value": "508244",
        "label": "Scaffolding (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42381041",
        "yellow_page_code": "21276",
        "kiosk": false
    },
    {
        "value": "508301",
        "label": "Orchard Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382025",
        "yellow_page_code": "57801, 18012",
        "kiosk": false
    },
    {
        "value": "508302",
        "label": "Meat Packers Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382022",
        "yellow_page_code": "50806, 58411, 29952",
        "kiosk": false
    },
    {
        "value": "508303",
        "label": "Poultry Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382026",
        "yellow_page_code": "67708, 29893",
        "kiosk": false
    },
    {
        "value": "508304",
        "label": "Tractor-Dealers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382032",
        "yellow_page_code": "87700, 29868",
        "kiosk": false
    },
    {
        "value": "508305",
        "label": "Irrigation Systems & Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382016",
        "yellow_page_code": "44000, 80209, 30481, 00794, 43910",
        "kiosk": false
    },
    {
        "value": "508306",
        "label": "Trailers-Horse (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382034",
        "yellow_page_code": "88100, 41534",
        "kiosk": false
    },
    {
        "value": "508307",
        "label": "Tractor Equipment & Parts (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382031",
        "yellow_page_code": "87704, 29869, 87693, 90134",
        "kiosk": false
    },
    {
        "value": "508308",
        "label": "Trailers-Livestock (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382035",
        "yellow_page_code": "88101",
        "kiosk": false
    },
    {
        "value": "508309",
        "label": "Farm Equipment-Renting (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382006",
        "yellow_page_code": "30807",
        "kiosk": false
    },
    {
        "value": "508310",
        "label": "Farm Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382005",
        "yellow_page_code": "30804, 30437, 02158",
        "kiosk": false
    },
    {
        "value": "508311",
        "label": "Fur Farms Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382010",
        "yellow_page_code": "35300",
        "kiosk": false
    },
    {
        "value": "508312",
        "label": "Feeders-Industrial (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382008",
        "yellow_page_code": "31208, 31211, 23717",
        "kiosk": false
    },
    {
        "value": "508313",
        "label": "Lawn & Garden Equipment & Supls-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382018",
        "yellow_page_code": "36301, 46208",
        "kiosk": false
    },
    {
        "value": "508314",
        "label": "Grain Elevators-Equip & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382012",
        "yellow_page_code": "38702, 38607, 38521",
        "kiosk": false
    },
    {
        "value": "508315",
        "label": "Grain Dryers & Drying Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382011",
        "yellow_page_code": "38604, 26725",
        "kiosk": false
    },
    {
        "value": "508316",
        "label": "Baling Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382037",
        "yellow_page_code": "06104",
        "kiosk": false
    },
    {
        "value": "508317",
        "label": "Dairy Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382003",
        "yellow_page_code": "23300, 30392",
        "kiosk": false
    },
    {
        "value": "508318",
        "label": "Center Pivots (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382001",
        "yellow_page_code": "14403",
        "kiosk": false
    },
    {
        "value": "508319",
        "label": "Compost Shredding Machines (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382002",
        "yellow_page_code": "20207",
        "kiosk": false
    },
    {
        "value": "508320",
        "label": "Tractor-Distributors (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512010",
        "yellow_page_code": "87702",
        "kiosk": false
    },
    {
        "value": "508321",
        "label": "Barn Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382038",
        "yellow_page_code": "06607",
        "kiosk": false
    },
    {
        "value": "508322",
        "label": "Seed Cleaning Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382027",
        "yellow_page_code": "75402",
        "kiosk": false
    },
    {
        "value": "508323",
        "label": "Lawn Mowers-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382019",
        "yellow_page_code": "46312",
        "kiosk": false
    },
    {
        "value": "508324",
        "label": "Equine Housing & Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382043",
        "yellow_page_code": "29730",
        "kiosk": false
    },
    {
        "value": "508325",
        "label": "Landscaping Equipment & Supplies-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382017",
        "yellow_page_code": "45836, 45816",
        "kiosk": false
    },
    {
        "value": "508326",
        "label": "Lawn Maintenance Equip Commercial (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382020",
        "yellow_page_code": "46324",
        "kiosk": false
    },
    {
        "value": "508327",
        "label": "Snow Removal Equipment-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382029",
        "yellow_page_code": "78612",
        "kiosk": false
    },
    {
        "value": "508328",
        "label": "Egg Processing/Shpng Eqpt/Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382004",
        "yellow_page_code": "27301",
        "kiosk": false
    },
    {
        "value": "508329",
        "label": "Farm Equipment-Used (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382007",
        "yellow_page_code": "30907",
        "kiosk": false
    },
    {
        "value": "508330",
        "label": "Fertilizing Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382009",
        "yellow_page_code": "31409, 31410, 31416",
        "kiosk": false
    },
    {
        "value": "508331",
        "label": "Agricultural Implements & Supplies-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382039",
        "yellow_page_code": "42613, 00818, 00821, 41610, 08825, 41613",
        "kiosk": false
    },
    {
        "value": "508332",
        "label": "Hay Equipment & Service (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382013",
        "yellow_page_code": "40336",
        "kiosk": false
    },
    {
        "value": "508333",
        "label": "Heavy Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382014",
        "yellow_page_code": "40923, 21244, 58618",
        "kiosk": false
    },
    {
        "value": "508334",
        "label": "Milk-Dealers Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382023",
        "yellow_page_code": "52313",
        "kiosk": false
    },
    {
        "value": "508335",
        "label": "Manure Handling Equip & Systems (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382021",
        "yellow_page_code": "49834",
        "kiosk": false
    },
    {
        "value": "508336",
        "label": "Silos-Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382028",
        "yellow_page_code": "77703",
        "kiosk": false
    },
    {
        "value": "508337",
        "label": "Winery Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382036",
        "yellow_page_code": "93805, 93720",
        "kiosk": false
    },
    {
        "value": "508338",
        "label": "Nut Roasting Machines (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382024",
        "yellow_page_code": "56012",
        "kiosk": false
    },
    {
        "value": "508339",
        "label": "Heavy Equipment-Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382015",
        "yellow_page_code": "40921, 40912",
        "kiosk": false
    },
    {
        "value": "508340",
        "label": "Trailers-Cargo Implemnt Livestock (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382033",
        "yellow_page_code": "88026",
        "kiosk": false
    },
    {
        "value": "508341",
        "label": "Tanks-Drinking-Livestock (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382030",
        "yellow_page_code": "83520, 92043",
        "kiosk": false
    },
    {
        "value": "508342",
        "label": "Skid Steer Sales & Service (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382041",
        "yellow_page_code": "78012",
        "kiosk": false
    },
    {
        "value": "508343",
        "label": "Feeding & Manure Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382040",
        "yellow_page_code": "31203",
        "kiosk": false
    },
    {
        "value": "508344",
        "label": "Lawn Sweepers-Whls & Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382042",
        "yellow_page_code": "46214",
        "kiosk": false
    },
    {
        "value": "508345",
        "label": "Grain Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382044",
        "yellow_page_code": "38603",
        "kiosk": false
    },
    {
        "value": "508346",
        "label": "Farm/Garden Machinery & Eqpt (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42382045",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "508401",
        "label": "Drilling & Boring Equip & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383012",
        "yellow_page_code": "26400, 09495, 56449",
        "kiosk": false
    },
    {
        "value": "508402",
        "label": "Compressors-Air & Gas (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383006",
        "yellow_page_code": "20302, 00905, 20123, 30378, 56444, 43787",
        "kiosk": false
    },
    {
        "value": "508403",
        "label": "Lifts (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383042",
        "yellow_page_code": "47206, 47209, 00713, 47205, 89190, 11011, 90310, 00733, 03700",
        "kiosk": false
    },
    {
        "value": "508404",
        "label": "Bakers Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383004",
        "yellow_page_code": "05908",
        "kiosk": false
    },
    {
        "value": "508405",
        "label": "Sewer Cleaning Equipment & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383085",
        "yellow_page_code": "75809, 25813, 75800, 75811, 75819",
        "kiosk": false
    },
    {
        "value": "508406",
        "label": "Barbecue Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383005",
        "yellow_page_code": "06504, 39011, 30469",
        "kiosk": false
    },
    {
        "value": "508407",
        "label": "Incinerators (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383034",
        "yellow_page_code": "42806",
        "kiosk": false
    },
    {
        "value": "508408",
        "label": "Elevators-Supplies & Parts (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383016",
        "yellow_page_code": "28308, 28321, 47220",
        "kiosk": false
    },
    {
        "value": "508409",
        "label": "Couplings (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383009",
        "yellow_page_code": "22202",
        "kiosk": false
    },
    {
        "value": "508410",
        "label": "Conveyors & Conveying Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383008",
        "yellow_page_code": "21402",
        "kiosk": false
    },
    {
        "value": "508411",
        "label": "Engraving Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383020",
        "yellow_page_code": "29505, 29420",
        "kiosk": false
    },
    {
        "value": "508412",
        "label": "Cranes & Derricks (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383010",
        "yellow_page_code": "22306, 22318, 22321, 30391",
        "kiosk": false
    },
    {
        "value": "508413",
        "label": "Tool & Die Makers Equip & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384076",
        "yellow_page_code": "24800, 86907",
        "kiosk": false
    },
    {
        "value": "508414",
        "label": "Engines-Diesel (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383019",
        "yellow_page_code": "29300, 88713, 24793, 30430",
        "kiosk": false
    },
    {
        "value": "508415",
        "label": "Plating Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383068",
        "yellow_page_code": "66508",
        "kiosk": false
    },
    {
        "value": "508416",
        "label": "Gauges & Gages (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383029",
        "yellow_page_code": "36904, 68114, 36910, 49826",
        "kiosk": false
    },
    {
        "value": "508417",
        "label": "Fans-Industrial & Commercial (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383021",
        "yellow_page_code": "30706, 30710, 30436",
        "kiosk": false
    },
    {
        "value": "508418",
        "label": "Cranes-Accessories & Parts (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383011",
        "yellow_page_code": "22307",
        "kiosk": false
    },
    {
        "value": "508419",
        "label": "Hoists (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383032",
        "yellow_page_code": "41106, 41109, 72210, 41107",
        "kiosk": false
    },
    {
        "value": "508420",
        "label": "Heat Exchangers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383030",
        "yellow_page_code": "40602, 40604, 30029",
        "kiosk": false
    },
    {
        "value": "508421",
        "label": "Mezzanine & Platform Systs/Equip (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383052",
        "yellow_page_code": "52109",
        "kiosk": false
    },
    {
        "value": "508422",
        "label": "Ladders (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383039",
        "yellow_page_code": "45502, 88710, 89201, 48531",
        "kiosk": false
    },
    {
        "value": "508423",
        "label": "Rollers-Wooden Metal Rubber (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383080",
        "yellow_page_code": "72600, 72534",
        "kiosk": false
    },
    {
        "value": "508424",
        "label": "Pottery Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383070",
        "yellow_page_code": "67702",
        "kiosk": false
    },
    {
        "value": "508425",
        "label": "Machine Tools (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383045",
        "yellow_page_code": "49108, 49117, 49120, 30493",
        "kiosk": false
    },
    {
        "value": "508426",
        "label": "Material Handling Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383050",
        "yellow_page_code": "50408",
        "kiosk": false
    },
    {
        "value": "508427",
        "label": "Machinery-New (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383047",
        "yellow_page_code": "49300, 42937, 49303, 91616, 91514, 49014",
        "kiosk": false
    },
    {
        "value": "508428",
        "label": "Oil Field Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383055",
        "yellow_page_code": "56506, 56601, 56707, 56514, 56612, 56436, 56715, 57310, 56533, 56534, 56457, 56469, 56558, 56541, 56482",
        "kiosk": false
    },
    {
        "value": "508429",
        "label": "Oil Field Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383056",
        "yellow_page_code": "56604",
        "kiosk": false
    },
    {
        "value": "508430",
        "label": "Radioactivity Instruments Supls/Svc-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383077",
        "yellow_page_code": "70002",
        "kiosk": false
    },
    {
        "value": "508431",
        "label": "Oil Well Fishing Tools (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383059",
        "yellow_page_code": "57004",
        "kiosk": false
    },
    {
        "value": "508432",
        "label": "Oil Well Equipment & Supplies-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383058",
        "yellow_page_code": "57002, 57016, 57006, 29965",
        "kiosk": false
    },
    {
        "value": "508433",
        "label": "Inspection Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384077",
        "yellow_page_code": "43338",
        "kiosk": false
    },
    {
        "value": "508434",
        "label": "Machine Shop Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383044",
        "yellow_page_code": "49105, 77015",
        "kiosk": false
    },
    {
        "value": "508435",
        "label": "Welding Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384078",
        "yellow_page_code": "93102, 93202, 93105",
        "kiosk": false
    },
    {
        "value": "508436",
        "label": "Woodworkers' Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383054",
        "yellow_page_code": "94308, 87205",
        "kiosk": false
    },
    {
        "value": "508437",
        "label": "Winches (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384079",
        "yellow_page_code": "93504",
        "kiosk": false
    },
    {
        "value": "508438",
        "label": "Packaging Machinery-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383060",
        "yellow_page_code": "58308, 58309, 58310, 58410, 58421",
        "kiosk": false
    },
    {
        "value": "508439",
        "label": "Paper Shredding Machines (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383065",
        "yellow_page_code": "59104, 77412",
        "kiosk": false
    },
    {
        "value": "508440",
        "label": "Packings-Mechanical (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383061",
        "yellow_page_code": "58409",
        "kiosk": false
    },
    {
        "value": "508441",
        "label": "Printing Plates (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383072",
        "yellow_page_code": "68406, 68314",
        "kiosk": false
    },
    {
        "value": "508442",
        "label": "Printing Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383071",
        "yellow_page_code": "68402, 68407, 68403, 68401, 68408, 68316, 68318, 67956, 68409, 93055, 56390",
        "kiosk": false
    },
    {
        "value": "508443",
        "label": "Printer Cartridges (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383073",
        "yellow_page_code": "68500, 86910, 68322, 86890",
        "kiosk": false
    },
    {
        "value": "508444",
        "label": "Pumps (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383076",
        "yellow_page_code": "69108, 69200, 69112, 69113, 69114, 90416, 92046, 69120, 29993, 92042, 49887, 79224",
        "kiosk": false
    },
    {
        "value": "508445",
        "label": "Drills (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383013",
        "yellow_page_code": "26402",
        "kiosk": false
    },
    {
        "value": "508446",
        "label": "Rubbish Removal-Contrs Equip (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383082",
        "yellow_page_code": "73108, 73110",
        "kiosk": false
    },
    {
        "value": "508447",
        "label": "Fans-Ventilating & Exhaust (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383022",
        "yellow_page_code": "30709",
        "kiosk": false
    },
    {
        "value": "508448",
        "label": "Sewing Machines-Industrial/Coml (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383086",
        "yellow_page_code": "75908, 76006, 30364",
        "kiosk": false
    },
    {
        "value": "508449",
        "label": "Screen Printing Equipment & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383084",
        "yellow_page_code": "74900, 77611",
        "kiosk": false
    },
    {
        "value": "508450",
        "label": "Clamps (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383099",
        "yellow_page_code": "18206, 93540",
        "kiosk": false
    },
    {
        "value": "508451",
        "label": "Pulleys (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383075",
        "yellow_page_code": "69100, 14613",
        "kiosk": false
    },
    {
        "value": "508452",
        "label": "Shoe Findings & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383088",
        "yellow_page_code": "76704",
        "kiosk": false
    },
    {
        "value": "508453",
        "label": "Tools-Cutting (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384080",
        "yellow_page_code": "87100",
        "kiosk": false
    },
    {
        "value": "508454",
        "label": "Tools-Electric (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384081",
        "yellow_page_code": "87102, 87005, 87016, 27921, 67932, 21088",
        "kiosk": false
    },
    {
        "value": "508455",
        "label": "Bushings (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383096",
        "yellow_page_code": "11708",
        "kiosk": false
    },
    {
        "value": "508456",
        "label": "Tools-Pneumatic (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42371033",
        "yellow_page_code": "87108, 66901",
        "kiosk": false
    },
    {
        "value": "508457",
        "label": "Trucks-Industrial (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384083",
        "yellow_page_code": "34108, 48401, 89202, 34111, 47212, 47214, 89016, 89017, 34121, 34124, 34127, 26810, 29876, 21262, 21263, 56559, 38830",
        "kiosk": false
    },
    {
        "value": "508458",
        "label": "Tools-Carbide (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384084",
        "yellow_page_code": "87009, 87018",
        "kiosk": false
    },
    {
        "value": "508459",
        "label": "Tools-Hydraulic (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384094",
        "yellow_page_code": "87106",
        "kiosk": false
    },
    {
        "value": "508460",
        "label": "Embroidering Machines (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383018",
        "yellow_page_code": "28408",
        "kiosk": false
    },
    {
        "value": "508461",
        "label": "Oil Handling Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383057",
        "yellow_page_code": "56606",
        "kiosk": false
    },
    {
        "value": "508462",
        "label": "Embossing Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383017",
        "yellow_page_code": "28406",
        "kiosk": false
    },
    {
        "value": "508463",
        "label": "Food Products-Manufacturers Equip (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383023",
        "yellow_page_code": "33809",
        "kiosk": false
    },
    {
        "value": "508464",
        "label": "Air Brushes (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383001",
        "yellow_page_code": "00901",
        "kiosk": false
    },
    {
        "value": "508465",
        "label": "Electrical Discharge Machs/Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383015",
        "yellow_page_code": "27801, 49210",
        "kiosk": false
    },
    {
        "value": "508466",
        "label": "Inspection Devices-Industrial (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383036",
        "yellow_page_code": "43301",
        "kiosk": false
    },
    {
        "value": "508467",
        "label": "Pavement/Flr Marking Supls/Equip (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383066",
        "yellow_page_code": "59807, 72311",
        "kiosk": false
    },
    {
        "value": "508468",
        "label": "Sucker Rods & Couplings (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384085",
        "yellow_page_code": "82180",
        "kiosk": false
    },
    {
        "value": "508469",
        "label": "Machine Tools-Repairing/Rebldg (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383046",
        "yellow_page_code": "49200",
        "kiosk": false
    },
    {
        "value": "508470",
        "label": "Garbage Collection Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383026",
        "yellow_page_code": "36107",
        "kiosk": false
    },
    {
        "value": "508471",
        "label": "Machinery-Rebuilding & Repairing (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383048",
        "yellow_page_code": "49208, 49227, 49198, 49312, 49226, 66034",
        "kiosk": false
    },
    {
        "value": "508472",
        "label": "Secondary Containment Systems (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384086",
        "yellow_page_code": "75223",
        "kiosk": false
    },
    {
        "value": "508473",
        "label": "Gas Plant Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383028",
        "yellow_page_code": "36704",
        "kiosk": false
    },
    {
        "value": "508474",
        "label": "Labeling Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383038",
        "yellow_page_code": "45203",
        "kiosk": false
    },
    {
        "value": "508475",
        "label": "Paper Converting Machinery (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383063",
        "yellow_page_code": "58905",
        "kiosk": false
    },
    {
        "value": "508476",
        "label": "Broaching Tools & Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383094",
        "yellow_page_code": "10809",
        "kiosk": false
    },
    {
        "value": "508477",
        "label": "Asbestos Removal Supplies & Equip (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383002",
        "yellow_page_code": "03913, 03929, 43696",
        "kiosk": false
    },
    {
        "value": "508478",
        "label": "Chimney Cleaning Equip & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383098",
        "yellow_page_code": "15305",
        "kiosk": false
    },
    {
        "value": "508479",
        "label": "Riggers' Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383079",
        "yellow_page_code": "72209, 30064",
        "kiosk": false
    },
    {
        "value": "508480",
        "label": "Plastics-Machinery & Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383067",
        "yellow_page_code": "66308",
        "kiosk": false
    },
    {
        "value": "508481",
        "label": "Paper Cutting & Drilling Machines (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383064",
        "yellow_page_code": "58906",
        "kiosk": false
    },
    {
        "value": "508482",
        "label": "Ice Cream-Manufacturers Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383033",
        "yellow_page_code": "42406, 42324",
        "kiosk": false
    },
    {
        "value": "508483",
        "label": "Stencil Cutting Machines (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384087",
        "yellow_page_code": "81202",
        "kiosk": false
    },
    {
        "value": "508484",
        "label": "Cloth Cutting Machines (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384088",
        "yellow_page_code": "18704, 30416",
        "kiosk": false
    },
    {
        "value": "508485",
        "label": "Foundry Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383025",
        "yellow_page_code": "34208",
        "kiosk": false
    },
    {
        "value": "508486",
        "label": "Laminating Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383040",
        "yellow_page_code": "45508",
        "kiosk": false
    },
    {
        "value": "508487",
        "label": "Slicing Machines (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383089",
        "yellow_page_code": "78304",
        "kiosk": false
    },
    {
        "value": "508488",
        "label": "Rubber Working Machinery (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383081",
        "yellow_page_code": "73006",
        "kiosk": false
    },
    {
        "value": "508489",
        "label": "Bookbinders Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384089",
        "yellow_page_code": "08107, 09306, 09314",
        "kiosk": false
    },
    {
        "value": "508490",
        "label": "Asphalt Mixing Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383003",
        "yellow_page_code": "04004",
        "kiosk": false
    },
    {
        "value": "508491",
        "label": "Soldering Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384090",
        "yellow_page_code": "79307",
        "kiosk": false
    },
    {
        "value": "508492",
        "label": "Heat Sealing (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383031",
        "yellow_page_code": "40607, 40702",
        "kiosk": false
    },
    {
        "value": "508493",
        "label": "Knitting Machines (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383037",
        "yellow_page_code": "45100",
        "kiosk": false
    },
    {
        "value": "508494",
        "label": "Drying Equipment-Industrial (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383014",
        "yellow_page_code": "26708, 26620",
        "kiosk": false
    },
    {
        "value": "508495",
        "label": "Textile Machinery & Parts (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384091",
        "yellow_page_code": "85306, 85313",
        "kiosk": false
    },
    {
        "value": "508496",
        "label": "Typesetting Machines (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384092",
        "yellow_page_code": "89708",
        "kiosk": false
    },
    {
        "value": "508497",
        "label": "Textile Printers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384093",
        "yellow_page_code": "85406, 67953",
        "kiosk": false
    },
    {
        "value": "508498",
        "label": "Paint-Manufacturing Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383062",
        "yellow_page_code": "58604",
        "kiosk": false
    },
    {
        "value": "508499",
        "label": "Misc Indstrl Equip & Supls NEC (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383000",
        "yellow_page_code": "00107, 05701, 13600, 18903, 19101, 19608, 23507, 23605, 24206, 24804, 26306, 27304, 28202, 28206, 29307, 31601, 32409, 33409, 34704, 35401, 35607, 38007, 38801, 49502, 51605, 56413, 56509, 56803, 56808, 59803, 65502, 70804, 74008, 76800, 77005, 83201, 83300, 85212, 85403, 85701, 86904, 87303, 88007, 92309, 75001, 30309, 23805, 55707, 94409, 24609, 56708, 22311, 78607, 01201, 47511, 88809, 29703, 49207, 41713, 46102, 49202, 74205, 83701, 87104, 92305, 54435, 91220, 20118, 80535, 92213, 51500, 94000, 65506, 23702, 47208, 91008, 69300, 77006, 15705, 74211, 56712, 28303, 51517, 29301, 92536, 34818, 49219, 82001, 85702, 88124, 91824, 58718, 47218, 91812, 67919, 55424, 21025, 51426, 05690, 49211, 49116, 30572, 56452, 91838, 92028",
        "kiosk": false
    },
    {
        "value": "508501",
        "label": "Packing Materials-Mechanical (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384046",
        "yellow_page_code": "58406",
        "kiosk": false
    },
    {
        "value": "508502",
        "label": "Fiber Glass Products (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384021",
        "yellow_page_code": "31506, 37527, 37528",
        "kiosk": false
    },
    {
        "value": "508503",
        "label": "Pipe Fittings (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384049",
        "yellow_page_code": "65508, 29983, 56465",
        "kiosk": false
    },
    {
        "value": "508504",
        "label": "Pails (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384047",
        "yellow_page_code": "58506",
        "kiosk": false
    },
    {
        "value": "508505",
        "label": "Pneumatic Equipment Components (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383069",
        "yellow_page_code": "66900",
        "kiosk": false
    },
    {
        "value": "508506",
        "label": "Seals-Oil/Grease & Etc Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384057",
        "yellow_page_code": "56811, 75106",
        "kiosk": false
    },
    {
        "value": "508507",
        "label": "Rope (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384053",
        "yellow_page_code": "72802",
        "kiosk": false
    },
    {
        "value": "508508",
        "label": "Spraying Equipment-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383090",
        "yellow_page_code": "80108",
        "kiosk": false
    },
    {
        "value": "508509",
        "label": "Sheet Metal Working Equip & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383087",
        "yellow_page_code": "76206, 76137, 76217",
        "kiosk": false
    },
    {
        "value": "508510",
        "label": "Sprockets (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384060",
        "yellow_page_code": "80301",
        "kiosk": false
    },
    {
        "value": "508511",
        "label": "Tools-Powder Actuated (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384067",
        "yellow_page_code": "87200",
        "kiosk": false
    },
    {
        "value": "508512",
        "label": "Strapping & Strapping Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383091",
        "yellow_page_code": "81908",
        "kiosk": false
    },
    {
        "value": "508513",
        "label": "Valves-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384070",
        "yellow_page_code": "90506, 90514, 06213, 56476, 56557",
        "kiosk": false
    },
    {
        "value": "508514",
        "label": "Twines & Cordage (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384068",
        "yellow_page_code": "89604, 21614",
        "kiosk": false
    },
    {
        "value": "508515",
        "label": "Gaskets (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384027",
        "yellow_page_code": "36804, 36821, 44530",
        "kiosk": false
    },
    {
        "value": "508516",
        "label": "Hose & Tubing-Flexible-Metal (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384034",
        "yellow_page_code": "41608",
        "kiosk": false
    },
    {
        "value": "508517",
        "label": "Gutters & Downspouts-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384031",
        "yellow_page_code": "39501, 39491, 30243, 70190",
        "kiosk": false
    },
    {
        "value": "508518",
        "label": "Hose & Tubing-Rubber & Plastic (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384033",
        "yellow_page_code": "41700, 89310, 41615, 89314",
        "kiosk": false
    },
    {
        "value": "508519",
        "label": "Hose Couplings & Fittings (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384035",
        "yellow_page_code": "41702, 30563, 56537, 22198, 22197",
        "kiosk": false
    },
    {
        "value": "508520",
        "label": "Hydraulic Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383024",
        "yellow_page_code": "42108, 42120, 75120, 42114, 42125, 41725",
        "kiosk": false
    },
    {
        "value": "508521",
        "label": "Lapidary Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383041",
        "yellow_page_code": "46004",
        "kiosk": false
    },
    {
        "value": "508522",
        "label": "Industrial Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383035",
        "yellow_page_code": "43002, 71933, 18432, 42926, 18420, 43020, 06719, 82289, 90314, 69391",
        "kiosk": false
    },
    {
        "value": "508523",
        "label": "Abrasives (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384001",
        "yellow_page_code": "00002",
        "kiosk": false
    },
    {
        "value": "508524",
        "label": "Machinery-Used (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383049",
        "yellow_page_code": "49304",
        "kiosk": false
    },
    {
        "value": "508525",
        "label": "Bearings (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384004",
        "yellow_page_code": "07202, 07280, 44230",
        "kiosk": false
    },
    {
        "value": "508526",
        "label": "Bottlers Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383093",
        "yellow_page_code": "09601",
        "kiosk": false
    },
    {
        "value": "508527",
        "label": "Belting & Belting Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384005",
        "yellow_page_code": "07700, 07638, 07639",
        "kiosk": false
    },
    {
        "value": "508528",
        "label": "Chains (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384012",
        "yellow_page_code": "14600, 43756",
        "kiosk": false
    },
    {
        "value": "508529",
        "label": "Cans (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384009",
        "yellow_page_code": "12906, 12610, 12914",
        "kiosk": false
    },
    {
        "value": "508530",
        "label": "Cylinders-Air & Hydraulic (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384014",
        "yellow_page_code": "23108, 23114",
        "kiosk": false
    },
    {
        "value": "508531",
        "label": "Disinfectants & Germicides (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384016",
        "yellow_page_code": "25008",
        "kiosk": false
    },
    {
        "value": "508532",
        "label": "Diamonds-Industrial (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384015",
        "yellow_page_code": "24503, 44436",
        "kiosk": false
    },
    {
        "value": "508533",
        "label": "Eyelets (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384019",
        "yellow_page_code": "30405",
        "kiosk": false
    },
    {
        "value": "508534",
        "label": "Glass Beads-Industrial (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384028",
        "yellow_page_code": "37404",
        "kiosk": false
    },
    {
        "value": "508535",
        "label": "Fasteners-Textile & Apparel (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384020",
        "yellow_page_code": "31006, 30439",
        "kiosk": false
    },
    {
        "value": "508536",
        "label": "Tapes-Adhesive (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384064",
        "yellow_page_code": "83804, 83817, 00310",
        "kiosk": false
    },
    {
        "value": "508537",
        "label": "Stencils & Stencil Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384062",
        "yellow_page_code": "81204",
        "kiosk": false
    },
    {
        "value": "508538",
        "label": "Sawmill Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383083",
        "yellow_page_code": "74202",
        "kiosk": false
    },
    {
        "value": "508539",
        "label": "Lithographers Equipment & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383043",
        "yellow_page_code": "48004, 67952, 30193",
        "kiosk": false
    },
    {
        "value": "508540",
        "label": "Textile Mill Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384066",
        "yellow_page_code": "85309",
        "kiosk": false
    },
    {
        "value": "508541",
        "label": "Flanges (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384024",
        "yellow_page_code": "32900, 65416",
        "kiosk": false
    },
    {
        "value": "508542",
        "label": "Springs-Coil/Flat & Etc-Distrs (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512012",
        "yellow_page_code": "80204",
        "kiosk": false
    },
    {
        "value": "508543",
        "label": "Grilles & Registers & Diffusers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384029",
        "yellow_page_code": "39008",
        "kiosk": false
    },
    {
        "value": "508544",
        "label": "Maple Sugar-Manufacturers Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384041",
        "yellow_page_code": "49901, 49939",
        "kiosk": false
    },
    {
        "value": "508545",
        "label": "Cargo & Freight Containers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384011",
        "yellow_page_code": "13500, 21037, 76590, 21170, 21122, 21172, 21173",
        "kiosk": false
    },
    {
        "value": "508546",
        "label": "Wire-Insulated (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384074",
        "yellow_page_code": "93909",
        "kiosk": false
    },
    {
        "value": "508547",
        "label": "Canning Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383097",
        "yellow_page_code": "12808, 33801",
        "kiosk": false
    },
    {
        "value": "508548",
        "label": "Rubber Products-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384055",
        "yellow_page_code": "73000, 72911, 56539",
        "kiosk": false
    },
    {
        "value": "508549",
        "label": "Leather Goods-Manufacturers Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384039",
        "yellow_page_code": "46707",
        "kiosk": false
    },
    {
        "value": "508550",
        "label": "Inks-Manufacturers Materials (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384036",
        "yellow_page_code": "43200",
        "kiosk": false
    },
    {
        "value": "508551",
        "label": "Paper Mill Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384048",
        "yellow_page_code": "59008, 30141",
        "kiosk": false
    },
    {
        "value": "508552",
        "label": "Carbide Metals & Products (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384010",
        "yellow_page_code": "13205",
        "kiosk": false
    },
    {
        "value": "508553",
        "label": "Cork & Cork Products (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384013",
        "yellow_page_code": "21702",
        "kiosk": false
    },
    {
        "value": "508554",
        "label": "Mattress Makers Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384042",
        "yellow_page_code": "50600, 50605",
        "kiosk": false
    },
    {
        "value": "508555",
        "label": "Boxes-Wooden (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384008",
        "yellow_page_code": "10006",
        "kiosk": false
    },
    {
        "value": "508556",
        "label": "Barrels & Drums-Equipment & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384075",
        "yellow_page_code": "06702, 26614",
        "kiosk": false
    },
    {
        "value": "508557",
        "label": "Boxes-Metal (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384007",
        "yellow_page_code": "09806",
        "kiosk": false
    },
    {
        "value": "508558",
        "label": "Propane Gas Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383074",
        "yellow_page_code": "68620",
        "kiosk": false
    },
    {
        "value": "508559",
        "label": "Bottles-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384006",
        "yellow_page_code": "09508, 09509, 09410",
        "kiosk": false
    },
    {
        "value": "508560",
        "label": "Filters-Liquid-Industrial-Distrs (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384023",
        "yellow_page_code": "31713",
        "kiosk": false
    },
    {
        "value": "508561",
        "label": "Filters-Air & Gas-Coml & Industrial (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384022",
        "yellow_page_code": "31703",
        "kiosk": false
    },
    {
        "value": "508562",
        "label": "Mill Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383053",
        "yellow_page_code": "52413, 69103",
        "kiosk": false
    },
    {
        "value": "508563",
        "label": "Garbage/Rubbish Rmvl Contrs Equip (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383027",
        "yellow_page_code": "36207, 73118",
        "kiosk": false
    },
    {
        "value": "508564",
        "label": "Taps & Dies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384065",
        "yellow_page_code": "83903",
        "kiosk": false
    },
    {
        "value": "508565",
        "label": "Refractory Materials (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384052",
        "yellow_page_code": "71207, 71225",
        "kiosk": false
    },
    {
        "value": "508566",
        "label": "Jigs & Fixtures-Components (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384038",
        "yellow_page_code": "44505",
        "kiosk": false
    },
    {
        "value": "508567",
        "label": "Refinery Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383078",
        "yellow_page_code": "71202",
        "kiosk": false
    },
    {
        "value": "508568",
        "label": "Brush-Manufacturers Equip/Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383095",
        "yellow_page_code": "11004",
        "kiosk": false
    },
    {
        "value": "508569",
        "label": "Hose (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384032",
        "yellow_page_code": "41607",
        "kiosk": false
    },
    {
        "value": "508570",
        "label": "Grommets (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384030",
        "yellow_page_code": "39109",
        "kiosk": false
    },
    {
        "value": "508571",
        "label": "Seals-Mechanical (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384056",
        "yellow_page_code": "75105",
        "kiosk": false
    },
    {
        "value": "508572",
        "label": "Valve Actuators (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384069",
        "yellow_page_code": "90501, 90502",
        "kiosk": false
    },
    {
        "value": "508573",
        "label": "Missile & Rockets Equip & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384044",
        "yellow_page_code": "52907, 52908",
        "kiosk": false
    },
    {
        "value": "508574",
        "label": "Seals-Hermetic-Distributors (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512011",
        "yellow_page_code": "75109",
        "kiosk": false
    },
    {
        "value": "508575",
        "label": "Garbage Equipment-Industrial-Coml (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384043",
        "yellow_page_code": "36127",
        "kiosk": false
    },
    {
        "value": "508576",
        "label": "Spray Booths (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383092",
        "yellow_page_code": "80114, 80118",
        "kiosk": false
    },
    {
        "value": "508577",
        "label": "Rope Splicing (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384054",
        "yellow_page_code": "72803",
        "kiosk": false
    },
    {
        "value": "508578",
        "label": "Belting-Vee Drive (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512031",
        "yellow_page_code": "07641",
        "kiosk": false
    },
    {
        "value": "508579",
        "label": "Barrels & Drums-Fiber (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384003",
        "yellow_page_code": "06703",
        "kiosk": false
    },
    {
        "value": "508580",
        "label": "Fruit Packers Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384025",
        "yellow_page_code": "34707",
        "kiosk": false
    },
    {
        "value": "508581",
        "label": "Concrete Gun Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383007",
        "yellow_page_code": "20511",
        "kiosk": false
    },
    {
        "value": "508582",
        "label": "Vinyl Floor Covering-Wholesale & Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384071",
        "yellow_page_code": "91152, 33325",
        "kiosk": false
    },
    {
        "value": "508583",
        "label": "Orifice Fittings (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384045",
        "yellow_page_code": "58002",
        "kiosk": false
    },
    {
        "value": "508584",
        "label": "Vises (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384072",
        "yellow_page_code": "91207",
        "kiosk": false
    },
    {
        "value": "508585",
        "label": "Metal Working Machinery (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42383051",
        "yellow_page_code": "51800, 29955",
        "kiosk": false
    },
    {
        "value": "508586",
        "label": "Spark Arrestors (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384059",
        "yellow_page_code": "79609",
        "kiosk": false
    },
    {
        "value": "508587",
        "label": "Wheels-Industrial (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384073",
        "yellow_page_code": "93409",
        "kiosk": false
    },
    {
        "value": "508588",
        "label": "Silicone Products (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384058",
        "yellow_page_code": "77612",
        "kiosk": false
    },
    {
        "value": "508589",
        "label": "Jars-Glass & Plastic (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384037",
        "yellow_page_code": "44203",
        "kiosk": false
    },
    {
        "value": "508590",
        "label": "Swivels (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384063",
        "yellow_page_code": "82908",
        "kiosk": false
    },
    {
        "value": "508591",
        "label": "Steel Rule Dies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384061",
        "yellow_page_code": "80912",
        "kiosk": false
    },
    {
        "value": "508592",
        "label": "Dumbwaiters-Supplies & Parts (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384017",
        "yellow_page_code": "26806",
        "kiosk": false
    },
    {
        "value": "508593",
        "label": "Envelope Machinery Parts & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384018",
        "yellow_page_code": "29701",
        "kiosk": false
    },
    {
        "value": "508594",
        "label": "Mandrels (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384040",
        "yellow_page_code": "49820",
        "kiosk": false
    },
    {
        "value": "508595",
        "label": "Barrel Servicing Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384002",
        "yellow_page_code": "06704",
        "kiosk": false
    },
    {
        "value": "508596",
        "label": "Gas Burners-Supplies & Parts (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384026",
        "yellow_page_code": "36508",
        "kiosk": false
    },
    {
        "value": "508597",
        "label": "Plastics-High Pressure Laminates-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384050",
        "yellow_page_code": "66213",
        "kiosk": false
    },
    {
        "value": "508598",
        "label": "Pleating Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384051",
        "yellow_page_code": "66700",
        "kiosk": false
    },
    {
        "value": "508599",
        "label": "Miscellaneous Industrial Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42384095",
        "yellow_page_code": "26611, 25636, 56518, 54336, 29710, 86937, 50923",
        "kiosk": false
    },
    {
        "value": "508701",
        "label": "Bakers Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385005",
        "yellow_page_code": "06000, 59512",
        "kiosk": false
    },
    {
        "value": "508702",
        "label": "Beauty Salons-Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385091",
        "yellow_page_code": "07206, 55014, 07211, 07222, 07217, 30275, 30471",
        "kiosk": false
    },
    {
        "value": "508703",
        "label": "Hairdressing Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385043",
        "yellow_page_code": "39709",
        "kiosk": false
    },
    {
        "value": "508704",
        "label": "Buffing & Polishing Equip & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385011",
        "yellow_page_code": "11007, 67109",
        "kiosk": false
    },
    {
        "value": "508705",
        "label": "Barbers Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385006",
        "yellow_page_code": "06600",
        "kiosk": false
    },
    {
        "value": "508706",
        "label": "Brushes (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385010",
        "yellow_page_code": "11006",
        "kiosk": false
    },
    {
        "value": "508707",
        "label": "Burial Vaults (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385012",
        "yellow_page_code": "11508, 90609",
        "kiosk": false
    },
    {
        "value": "508708",
        "label": "Car Washing/Polishing Equip/Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385014",
        "yellow_page_code": "05308, 13104, 04827, 90622, 19921",
        "kiosk": false
    },
    {
        "value": "508709",
        "label": "Butchers Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385013",
        "yellow_page_code": "11902, 90415",
        "kiosk": false
    },
    {
        "value": "508710",
        "label": "Carpet & Rug Cleaning Equip/Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385016",
        "yellow_page_code": "13606, 13615, 53416, 53335, 66232",
        "kiosk": false
    },
    {
        "value": "508711",
        "label": "Carnival Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385015",
        "yellow_page_code": "13504, 30622",
        "kiosk": false
    },
    {
        "value": "508712",
        "label": "Carpet Layers Equipment & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385017",
        "yellow_page_code": "13806, 13512",
        "kiosk": false
    },
    {
        "value": "508713",
        "label": "Caterers Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385020",
        "yellow_page_code": "14102, 14023",
        "kiosk": false
    },
    {
        "value": "508714",
        "label": "Caskets (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385019",
        "yellow_page_code": "14000, 35012",
        "kiosk": false
    },
    {
        "value": "508715",
        "label": "Cleaners & Dyers Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385025",
        "yellow_page_code": "18400, 26616, 18422, 46118",
        "kiosk": false
    },
    {
        "value": "508716",
        "label": "Check Room Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385022",
        "yellow_page_code": "14805",
        "kiosk": false
    },
    {
        "value": "508717",
        "label": "Cleaners & Dyers Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385026",
        "yellow_page_code": "18402, 26604",
        "kiosk": false
    },
    {
        "value": "508718",
        "label": "Exterminating/Fumigating Eqpt/Supl",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385033",
        "yellow_page_code": "30402",
        "kiosk": false
    },
    {
        "value": "508719",
        "label": "Cleaning Systs-Pressure Chem/Etc (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385027",
        "yellow_page_code": "18500, 18417, 30576",
        "kiosk": false
    },
    {
        "value": "508720",
        "label": "Funeral Directors-Equip & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385038",
        "yellow_page_code": "35004, 35016, 30285",
        "kiosk": false
    },
    {
        "value": "508721",
        "label": "Floor Machines (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385037",
        "yellow_page_code": "33304, 33303, 33307",
        "kiosk": false
    },
    {
        "value": "508722",
        "label": "Garbage Container Receptacles (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385039",
        "yellow_page_code": "36109, 36118, 72515, 36126, 03916, 21261, 20939, 36196, 72514",
        "kiosk": false
    },
    {
        "value": "508723",
        "label": "Hair Goods-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385042",
        "yellow_page_code": "39605, 39700, 39637",
        "kiosk": false
    },
    {
        "value": "508724",
        "label": "Janitors Equipment/Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385044",
        "yellow_page_code": "44200, 44114, 18430, 30482",
        "kiosk": false
    },
    {
        "value": "508725",
        "label": "Motel & Hotel Equipment & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385054",
        "yellow_page_code": "41905, 53708, 47110, 41890, 30566",
        "kiosk": false
    },
    {
        "value": "508726",
        "label": "Laundry Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385046",
        "yellow_page_code": "46204, 30190",
        "kiosk": false
    },
    {
        "value": "508727",
        "label": "Steel Burial Vaults (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385059",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "508728",
        "label": "Laundry Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385047",
        "yellow_page_code": "46206",
        "kiosk": false
    },
    {
        "value": "508729",
        "label": "Municipal Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385055",
        "yellow_page_code": "54600",
        "kiosk": false
    },
    {
        "value": "508730",
        "label": "Mops (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385053",
        "yellow_page_code": "53606",
        "kiosk": false
    },
    {
        "value": "508731",
        "label": "Washing Machs Dryers/Ironers-Supl (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385085",
        "yellow_page_code": "91708, 91610, 29886",
        "kiosk": false
    },
    {
        "value": "508732",
        "label": "Washing Machs Dryers/Ironers-Coin (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385084",
        "yellow_page_code": "91702",
        "kiosk": false
    },
    {
        "value": "508733",
        "label": "Soaps & Detergents (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385063",
        "yellow_page_code": "78904",
        "kiosk": false
    },
    {
        "value": "508734",
        "label": "Sprinklers-Automatic-Fire (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385064",
        "yellow_page_code": "80208, 80212, 80227, 31918",
        "kiosk": false
    },
    {
        "value": "508735",
        "label": "Steam Cleaning Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385066",
        "yellow_page_code": "80800, 80801, 80814, 80795, 80815, 29948",
        "kiosk": false
    },
    {
        "value": "508736",
        "label": "Sprinklers-Garden & Lawn-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385065",
        "yellow_page_code": "80303, 80228",
        "kiosk": false
    },
    {
        "value": "508737",
        "label": "Sweepers-Power (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385068",
        "yellow_page_code": "82700",
        "kiosk": false
    },
    {
        "value": "508738",
        "label": "Stock Quotations Boards & Systems (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385067",
        "yellow_page_code": "81501",
        "kiosk": false
    },
    {
        "value": "508739",
        "label": "Tailors-Trimmings & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385069",
        "yellow_page_code": "83403",
        "kiosk": false
    },
    {
        "value": "508740",
        "label": "Vacuum Cleaners-Industrial & Coml (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385076",
        "yellow_page_code": "90306",
        "kiosk": false
    },
    {
        "value": "508741",
        "label": "Upholsterers Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385073",
        "yellow_page_code": "90100, 29878",
        "kiosk": false
    },
    {
        "value": "508742",
        "label": "Vacuum Cleaning Systems (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385077",
        "yellow_page_code": "90408, 90413, 30353",
        "kiosk": false
    },
    {
        "value": "508743",
        "label": "Vacuum Cleaning-Industrial (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385088",
        "yellow_page_code": "90406",
        "kiosk": false
    },
    {
        "value": "508744",
        "label": "Vacuum Equipment & Systems (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385078",
        "yellow_page_code": "90500",
        "kiosk": false
    },
    {
        "value": "508745",
        "label": "Chiropractors Equipment & Supply (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385024",
        "yellow_page_code": "15505, 15516",
        "kiosk": false
    },
    {
        "value": "508746",
        "label": "Veterinarians Equipment & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385080",
        "yellow_page_code": "91000, 90907",
        "kiosk": false
    },
    {
        "value": "508747",
        "label": "Amusement Park Rides Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385002",
        "yellow_page_code": "02304, 02213",
        "kiosk": false
    },
    {
        "value": "508748",
        "label": "Window Cleaning Equipment & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385087",
        "yellow_page_code": "93602",
        "kiosk": false
    },
    {
        "value": "508749",
        "label": "Truck-Washing/Cleaning Eqpt/Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385072",
        "yellow_page_code": "89002",
        "kiosk": false
    },
    {
        "value": "508750",
        "label": "Washing Machines-Industrial (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385083",
        "yellow_page_code": "91609",
        "kiosk": false
    },
    {
        "value": "508751",
        "label": "Pressing Machines-Clothes (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385058",
        "yellow_page_code": "68105, 68107",
        "kiosk": false
    },
    {
        "value": "508752",
        "label": "Fire Department Equipment & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385034",
        "yellow_page_code": "31906",
        "kiosk": false
    },
    {
        "value": "508753",
        "label": "Voting Machines & Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385082",
        "yellow_page_code": "91208",
        "kiosk": false
    },
    {
        "value": "508754",
        "label": "Fire Hose (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385035",
        "yellow_page_code": "32100",
        "kiosk": false
    },
    {
        "value": "508755",
        "label": "Pressure Washing Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385048",
        "yellow_page_code": "68123, 91617, 68115, 92044",
        "kiosk": false
    },
    {
        "value": "508756",
        "label": "Hair Clippers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385040",
        "yellow_page_code": "39606",
        "kiosk": false
    },
    {
        "value": "508757",
        "label": "Laundry Equip-Coml-Coin Operated (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385045",
        "yellow_page_code": "46109, 46190",
        "kiosk": false
    },
    {
        "value": "508758",
        "label": "Cemetery Equipment & Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385021",
        "yellow_page_code": "14316",
        "kiosk": false
    },
    {
        "value": "508759",
        "label": "Coin & Bill Changing Machines-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385028",
        "yellow_page_code": "19411",
        "kiosk": false
    },
    {
        "value": "508760",
        "label": "Electrolysis Equip & Supls-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385032",
        "yellow_page_code": "27805, 39701",
        "kiosk": false
    },
    {
        "value": "508761",
        "label": "Shoes-Manufacturers Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385061",
        "yellow_page_code": "76736, 77007, 30191",
        "kiosk": false
    },
    {
        "value": "508762",
        "label": "Upholsterers-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385074",
        "yellow_page_code": "90011",
        "kiosk": false
    },
    {
        "value": "508763",
        "label": "Wigs-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385086",
        "yellow_page_code": "93414",
        "kiosk": false
    },
    {
        "value": "508764",
        "label": "Police Equipment-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385057",
        "yellow_page_code": "67103, 29987",
        "kiosk": false
    },
    {
        "value": "508765",
        "label": "Deodorizing Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385030",
        "yellow_page_code": "24201",
        "kiosk": false
    },
    {
        "value": "508767",
        "label": "Manicure Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385051",
        "yellow_page_code": "49809",
        "kiosk": false
    },
    {
        "value": "508768",
        "label": "Soap Dispensers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385062",
        "yellow_page_code": "78905",
        "kiosk": false
    },
    {
        "value": "508769",
        "label": "Vacuum-Central/Built-In (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385079",
        "yellow_page_code": "90304, 14361",
        "kiosk": false
    },
    {
        "value": "508770",
        "label": "Luggage-Manufacturers Equip/Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385050",
        "yellow_page_code": "48802",
        "kiosk": false
    },
    {
        "value": "508771",
        "label": "Firestopping (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385036",
        "yellow_page_code": "32010, 32013",
        "kiosk": false
    },
    {
        "value": "508772",
        "label": "Artificial Eyelashes (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385003",
        "yellow_page_code": "03703",
        "kiosk": false
    },
    {
        "value": "508773",
        "label": "Auto Inspection Sta Equip/Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385004",
        "yellow_page_code": "04915",
        "kiosk": false
    },
    {
        "value": "508774",
        "label": "Combs (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385029",
        "yellow_page_code": "19907",
        "kiosk": false
    },
    {
        "value": "508775",
        "label": "Tapping Compounds (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385070",
        "yellow_page_code": "83910",
        "kiosk": false
    },
    {
        "value": "508776",
        "label": "Tattooing Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385071",
        "yellow_page_code": "84009",
        "kiosk": false
    },
    {
        "value": "508777",
        "label": "Boutiques-Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385009",
        "yellow_page_code": "09607",
        "kiosk": false
    },
    {
        "value": "508778",
        "label": "Casket Fabrics & Accessories (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385018",
        "yellow_page_code": "13913",
        "kiosk": false
    },
    {
        "value": "508779",
        "label": "Child Care Centers-Equip & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385023",
        "yellow_page_code": "23511, 15112, 55821",
        "kiosk": false
    },
    {
        "value": "508780",
        "label": "Vineyard Products & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385081",
        "yellow_page_code": "91216",
        "kiosk": false
    },
    {
        "value": "508781",
        "label": "Drying Compounds (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385031",
        "yellow_page_code": "26813",
        "kiosk": false
    },
    {
        "value": "508782",
        "label": "Upholstery Cleaning Equip/Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385075",
        "yellow_page_code": "90035",
        "kiosk": false
    },
    {
        "value": "508783",
        "label": "Shoe Repairing Equipment & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385060",
        "yellow_page_code": "76809",
        "kiosk": false
    },
    {
        "value": "508785",
        "label": "Pinking Machines (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385056",
        "yellow_page_code": "65311",
        "kiosk": false
    },
    {
        "value": "508786",
        "label": "Luggage Findings (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385049",
        "yellow_page_code": "48809",
        "kiosk": false
    },
    {
        "value": "508788",
        "label": "Beauty Schools Equipment & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385008",
        "yellow_page_code": "07300",
        "kiosk": false
    },
    {
        "value": "508789",
        "label": "Skate Sharpening Machinery (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385089",
        "yellow_page_code": "78007",
        "kiosk": false
    },
    {
        "value": "508790",
        "label": "Hair Implants-Transplants-Products-Supls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385007",
        "yellow_page_code": "39630",
        "kiosk": false
    },
    {
        "value": "508791",
        "label": "Trash Compactors Whls & Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385090",
        "yellow_page_code": "88423",
        "kiosk": false
    },
    {
        "value": "508799",
        "label": "Misc Svc Establishment Equip/Supl (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42385052",
        "yellow_page_code": "91203, 59405, 59498",
        "kiosk": false
    },
    {
        "value": "508801",
        "label": "Railroad Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42386021",
        "yellow_page_code": "70204",
        "kiosk": false
    },
    {
        "value": "508802",
        "label": "Parking Sta/Garages Equip/Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42386020",
        "yellow_page_code": "59408, 59414, 59418",
        "kiosk": false
    },
    {
        "value": "508803",
        "label": "Ship Brokers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42386022",
        "yellow_page_code": "76500, 50051",
        "kiosk": false
    },
    {
        "value": "508804",
        "label": "Tanks-Fiber/Glass/Plastic & Etc (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42386026",
        "yellow_page_code": "83600, 30083",
        "kiosk": false
    },
    {
        "value": "508805",
        "label": "Ship Chandlers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42386023",
        "yellow_page_code": "76502, 76410, 14617, 94712",
        "kiosk": false
    },
    {
        "value": "508806",
        "label": "Tanks-Metal (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42386014",
        "yellow_page_code": "83602",
        "kiosk": false
    },
    {
        "value": "508807",
        "label": "Tanks (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42386025",
        "yellow_page_code": "83508",
        "kiosk": false
    },
    {
        "value": "508808",
        "label": "Tanks-Used (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42386027",
        "yellow_page_code": "83603",
        "kiosk": false
    },
    {
        "value": "508809",
        "label": "Aircraft Parts-Reclaiming (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42386005",
        "yellow_page_code": "01513",
        "kiosk": false
    },
    {
        "value": "508810",
        "label": "Loading Dock Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42386013",
        "yellow_page_code": "48405, 30571",
        "kiosk": false
    },
    {
        "value": "508811",
        "label": "Marine Equipment & Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42386016",
        "yellow_page_code": "25302, 50103, 30497",
        "kiosk": false
    },
    {
        "value": "508812",
        "label": "Marine Electronic Equip & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42386015",
        "yellow_page_code": "50020, 50100, 50050",
        "kiosk": false
    },
    {
        "value": "508813",
        "label": "Nautical Instruments (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42386018",
        "yellow_page_code": "55203",
        "kiosk": false
    },
    {
        "value": "508814",
        "label": "Aircraft Brokers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42386002",
        "yellow_page_code": "01400",
        "kiosk": false
    },
    {
        "value": "508815",
        "label": "Oceanographic Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42386019",
        "yellow_page_code": "56201",
        "kiosk": false
    },
    {
        "value": "508816",
        "label": "Bulkheads (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42386008",
        "yellow_page_code": "11405",
        "kiosk": false
    },
    {
        "value": "508817",
        "label": "Aircraft Avionics-Sales & Service (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42386001",
        "yellow_page_code": "01309, 01411",
        "kiosk": false
    },
    {
        "value": "508818",
        "label": "Audiometers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42386007",
        "yellow_page_code": "04402",
        "kiosk": false
    },
    {
        "value": "508819",
        "label": "Aircraft Engines (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42386003",
        "yellow_page_code": "01406",
        "kiosk": false
    },
    {
        "value": "508820",
        "label": "Tank Cars (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42386024",
        "yellow_page_code": "83408",
        "kiosk": false
    },
    {
        "value": "508821",
        "label": "Monorail Systems (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42386017",
        "yellow_page_code": "53505",
        "kiosk": false
    },
    {
        "value": "508822",
        "label": "Aircraft Rebuilding (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42386006",
        "yellow_page_code": "01601",
        "kiosk": false
    },
    {
        "value": "508823",
        "label": "Aircraft Equipment Parts & Supls-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42386004",
        "yellow_page_code": "01414, 30452",
        "kiosk": false
    },
    {
        "value": "508824",
        "label": "Golf Cars & Carts-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42386012",
        "yellow_page_code": "38103",
        "kiosk": false
    },
    {
        "value": "508825",
        "label": "Helicopter Brokers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42386030",
        "yellow_page_code": "40911",
        "kiosk": false
    },
    {
        "value": "508826",
        "label": "Cargo Stabilizing Devices (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42386009",
        "yellow_page_code": "13501",
        "kiosk": false
    },
    {
        "value": "508827",
        "label": "Yacht Provisioning (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42386029",
        "yellow_page_code": "94614",
        "kiosk": false
    },
    {
        "value": "508829",
        "label": "Gas Tanks-Sales & Service (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42386010",
        "yellow_page_code": "36717, 36406",
        "kiosk": false
    },
    {
        "value": "508830",
        "label": "Tugboat & Barge Brokers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512014",
        "yellow_page_code": "89400",
        "kiosk": false
    },
    {
        "value": "508831",
        "label": "Go-Carts-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42386011",
        "yellow_page_code": "37910",
        "kiosk": false
    },
    {
        "value": "508898",
        "label": "Transportation Equipment & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42386028",
        "yellow_page_code": "88314, 42684",
        "kiosk": false
    },
    {
        "value": "509101",
        "label": "Billiard Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391054",
        "yellow_page_code": "08102, 67210, 78640",
        "kiosk": false
    },
    {
        "value": "509102",
        "label": "Bicycles-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391055",
        "yellow_page_code": "08002, 30472",
        "kiosk": false
    },
    {
        "value": "509103",
        "label": "Boat Distributors (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512016",
        "yellow_page_code": "08705",
        "kiosk": false
    },
    {
        "value": "509104",
        "label": "Bowling Lane Equipment & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391008",
        "yellow_page_code": "09708, 09713",
        "kiosk": false
    },
    {
        "value": "509105",
        "label": "Boat Equipment & Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391006",
        "yellow_page_code": "08707, 30474",
        "kiosk": false
    },
    {
        "value": "509106",
        "label": "Golf Equipment & Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391020",
        "yellow_page_code": "38208",
        "kiosk": false
    },
    {
        "value": "509107",
        "label": "Fishing Tackle-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391018",
        "yellow_page_code": "32708, 32632, 30340",
        "kiosk": false
    },
    {
        "value": "509108",
        "label": "Hot Tubs & Spas-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391026",
        "yellow_page_code": "41903, 79702",
        "kiosk": false
    },
    {
        "value": "509109",
        "label": "Skiing Equipment-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391036",
        "yellow_page_code": "78108",
        "kiosk": false
    },
    {
        "value": "509110",
        "label": "Steam Bath Equipment & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391041",
        "yellow_page_code": "80709",
        "kiosk": false
    },
    {
        "value": "509111",
        "label": "Sporting Goods-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391039",
        "yellow_page_code": "80006",
        "kiosk": false
    },
    {
        "value": "509112",
        "label": "Swimming Pool-Distributors (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512018",
        "yellow_page_code": "82806",
        "kiosk": false
    },
    {
        "value": "509113",
        "label": "Tennis Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391049",
        "yellow_page_code": "85108",
        "kiosk": false
    },
    {
        "value": "509114",
        "label": "Tanning Salons Equip & Supplies-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391047",
        "yellow_page_code": "83704, 82213, 81736",
        "kiosk": false
    },
    {
        "value": "509115",
        "label": "Gymnastic Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391023",
        "yellow_page_code": "39503",
        "kiosk": false
    },
    {
        "value": "509116",
        "label": "Golf Course Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391019",
        "yellow_page_code": "38106, 30543",
        "kiosk": false
    },
    {
        "value": "509117",
        "label": "Badminton Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391003",
        "yellow_page_code": "05708",
        "kiosk": false
    },
    {
        "value": "509118",
        "label": "Swimming Pool Equipment & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391044",
        "yellow_page_code": "82804",
        "kiosk": false
    },
    {
        "value": "509119",
        "label": "Pennants (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391030",
        "yellow_page_code": "60108",
        "kiosk": false
    },
    {
        "value": "509120",
        "label": "Tanning Equipment-Home & Office (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391046",
        "yellow_page_code": "83703, 83715",
        "kiosk": false
    },
    {
        "value": "509121",
        "label": "Boat Brokers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512015",
        "yellow_page_code": "08607",
        "kiosk": false
    },
    {
        "value": "509122",
        "label": "Ammunition-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391001",
        "yellow_page_code": "02203, 39412, 77020",
        "kiosk": false
    },
    {
        "value": "509123",
        "label": "Archery Equipment & Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391002",
        "yellow_page_code": "03201",
        "kiosk": false
    },
    {
        "value": "509124",
        "label": "Balloons-Manned-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391004",
        "yellow_page_code": "06136",
        "kiosk": false
    },
    {
        "value": "509125",
        "label": "Beach Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391056",
        "yellow_page_code": "07113",
        "kiosk": false
    },
    {
        "value": "509126",
        "label": "Bicycles-Parts & Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391057",
        "yellow_page_code": "08006, 23133",
        "kiosk": false
    },
    {
        "value": "509127",
        "label": "Boardsailing-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391058",
        "yellow_page_code": "08616",
        "kiosk": false
    },
    {
        "value": "509128",
        "label": "Boat Covers Tops & Upholstery-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391005",
        "yellow_page_code": "08613",
        "kiosk": false
    },
    {
        "value": "509129",
        "label": "Bowling Apparel & Accessories-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391007",
        "yellow_page_code": "09711",
        "kiosk": false
    },
    {
        "value": "509130",
        "label": "Camping Equipment-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391009",
        "yellow_page_code": "12605",
        "kiosk": false
    },
    {
        "value": "509131",
        "label": "Darts & Dart Boards-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391012",
        "yellow_page_code": "23336",
        "kiosk": false
    },
    {
        "value": "509132",
        "label": "Exercise Equipment-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391015",
        "yellow_page_code": "30013",
        "kiosk": false
    },
    {
        "value": "509133",
        "label": "Fish Farming Equipment & Supplies-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391016",
        "yellow_page_code": "32413",
        "kiosk": false
    },
    {
        "value": "509134",
        "label": "Fishermens Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391017",
        "yellow_page_code": "32601, 32516",
        "kiosk": false
    },
    {
        "value": "509135",
        "label": "Guns & Gunsmiths-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391021",
        "yellow_page_code": "39403",
        "kiosk": false
    },
    {
        "value": "509136",
        "label": "Gymnasiums Equipment & Supplies-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391022",
        "yellow_page_code": "39511",
        "kiosk": false
    },
    {
        "value": "509137",
        "label": "Paint Ball Games Equip & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391028",
        "yellow_page_code": "58591",
        "kiosk": false
    },
    {
        "value": "509138",
        "label": "Scuba Diving Equipment-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391037",
        "yellow_page_code": "74934",
        "kiosk": false
    },
    {
        "value": "509139",
        "label": "Skating Equipment & Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391034",
        "yellow_page_code": "78001",
        "kiosk": false
    },
    {
        "value": "509140",
        "label": "Sleeping Bags-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391038",
        "yellow_page_code": "78305",
        "kiosk": false
    },
    {
        "value": "509141",
        "label": "Squash Equipment & Supls-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391040",
        "yellow_page_code": "80401",
        "kiosk": false
    },
    {
        "value": "509142",
        "label": "Surfboards-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391042",
        "yellow_page_code": "82335",
        "kiosk": false
    },
    {
        "value": "509143",
        "label": "Swimming Pool Covers/Enclosures-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391043",
        "yellow_page_code": "82709, 82820",
        "kiosk": false
    },
    {
        "value": "509144",
        "label": "Tents-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391050",
        "yellow_page_code": "85136",
        "kiosk": false
    },
    {
        "value": "509145",
        "label": "Water Skiing Equipment & Supplies-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391052",
        "yellow_page_code": "92315",
        "kiosk": false
    },
    {
        "value": "509146",
        "label": "Divers Equipment & Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391013",
        "yellow_page_code": "25208",
        "kiosk": false
    },
    {
        "value": "509147",
        "label": "Playground Equipment-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391031",
        "yellow_page_code": "66603",
        "kiosk": false
    },
    {
        "value": "509148",
        "label": "Health Equipment & Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391025",
        "yellow_page_code": "40512",
        "kiosk": false
    },
    {
        "value": "509149",
        "label": "Karts-Motorized-Distributors (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512017",
        "yellow_page_code": "44705",
        "kiosk": false
    },
    {
        "value": "509150",
        "label": "Club Room Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391010",
        "yellow_page_code": "18809",
        "kiosk": false
    },
    {
        "value": "509151",
        "label": "Table Tennis Equipment & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391045",
        "yellow_page_code": "83100",
        "kiosk": false
    },
    {
        "value": "509152",
        "label": "Tennis Court Supplies & Repair (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391048",
        "yellow_page_code": "85007, 85101",
        "kiosk": false
    },
    {
        "value": "509153",
        "label": "Baseball Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391059",
        "yellow_page_code": "06810, 06820",
        "kiosk": false
    },
    {
        "value": "509154",
        "label": "Basketball Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391060",
        "yellow_page_code": "06900",
        "kiosk": false
    },
    {
        "value": "509155",
        "label": "Dock Bumpers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391014",
        "yellow_page_code": "25236",
        "kiosk": false
    },
    {
        "value": "509156",
        "label": "Patio Furniture & Accessories-Whls/Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391029",
        "yellow_page_code": "59712, 59716",
        "kiosk": false
    },
    {
        "value": "509157",
        "label": "Skateboards & Equipment-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391033",
        "yellow_page_code": "77913",
        "kiosk": false
    },
    {
        "value": "509158",
        "label": "Ski Lift & Ski Tow Equip & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391035",
        "yellow_page_code": "78100",
        "kiosk": false
    },
    {
        "value": "509159",
        "label": "Trap & Skeet Ranges Equip & Supl (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391051",
        "yellow_page_code": "88399, 88338",
        "kiosk": false
    },
    {
        "value": "509160",
        "label": "Sailboats & Sailboat Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391032",
        "yellow_page_code": "73412",
        "kiosk": false
    },
    {
        "value": "509161",
        "label": "Hardware-Marine-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391024",
        "yellow_page_code": "40209",
        "kiosk": false
    },
    {
        "value": "509162",
        "label": "Curling Rink Equipment & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391011",
        "yellow_page_code": "22912",
        "kiosk": false
    },
    {
        "value": "509163",
        "label": "Outboard Motors-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391027",
        "yellow_page_code": "58121",
        "kiosk": false
    },
    {
        "value": "509164",
        "label": "Snowboards-Wholesale & Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42391053",
        "yellow_page_code": "78707",
        "kiosk": false
    },
    {
        "value": "509165",
        "label": "Airsoft Games & Supls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911079",
        "yellow_page_code": "01720",
        "kiosk": false
    },
    {
        "value": "509201",
        "label": "Balloons-Novelty & Toy-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42392003",
        "yellow_page_code": "06203",
        "kiosk": false
    },
    {
        "value": "509202",
        "label": "Fireworks (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42392010",
        "yellow_page_code": "32304, 32314, 30539",
        "kiosk": false
    },
    {
        "value": "509203",
        "label": "Hobby & Model Constr Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42392014",
        "yellow_page_code": "41102, 41099",
        "kiosk": false
    },
    {
        "value": "509204",
        "label": "Games & Game Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42392012",
        "yellow_page_code": "36100",
        "kiosk": false
    },
    {
        "value": "509205",
        "label": "Dolls-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42392009",
        "yellow_page_code": "25502, 87521, 25423",
        "kiosk": false
    },
    {
        "value": "509206",
        "label": "Puppets & Marionettes (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42392020",
        "yellow_page_code": "69302",
        "kiosk": false
    },
    {
        "value": "509207",
        "label": "Ceramic-Equipment & Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42392004",
        "yellow_page_code": "14409",
        "kiosk": false
    },
    {
        "value": "509208",
        "label": "Doll-Mfrs Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42392008",
        "yellow_page_code": "25407",
        "kiosk": false
    },
    {
        "value": "509209",
        "label": "Toys-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42392022",
        "yellow_page_code": "87608, 87617, 87618, 29866, 29867, 49888",
        "kiosk": false
    },
    {
        "value": "509210",
        "label": "Arts & Crafts-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42392001",
        "yellow_page_code": "03601",
        "kiosk": false
    },
    {
        "value": "509211",
        "label": "Confetti-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42392005",
        "yellow_page_code": "20918",
        "kiosk": false
    },
    {
        "value": "509212",
        "label": "Doll Clothing & Accessories-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42392006",
        "yellow_page_code": "25413",
        "kiosk": false
    },
    {
        "value": "509213",
        "label": "Handicrafts-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42392013",
        "yellow_page_code": "40011",
        "kiosk": false
    },
    {
        "value": "509214",
        "label": "Kites-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42392016",
        "yellow_page_code": "44936",
        "kiosk": false
    },
    {
        "value": "509215",
        "label": "Macrame-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42392017",
        "yellow_page_code": "49305",
        "kiosk": false
    },
    {
        "value": "509216",
        "label": "Playing Cards-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42392019",
        "yellow_page_code": "66611",
        "kiosk": false
    },
    {
        "value": "509217",
        "label": "Stamps (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42392021",
        "yellow_page_code": "80537, 80516",
        "kiosk": false
    },
    {
        "value": "509218",
        "label": "Flares & Signals (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42392011",
        "yellow_page_code": "32901",
        "kiosk": false
    },
    {
        "value": "509219",
        "label": "Baby Carriages & Strollers-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42392002",
        "yellow_page_code": "05518",
        "kiosk": false
    },
    {
        "value": "509220",
        "label": "Kilns-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42392015",
        "yellow_page_code": "44836",
        "kiosk": false
    },
    {
        "value": "509221",
        "label": "Doll Houses & Accessories-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42392007",
        "yellow_page_code": "25417",
        "kiosk": false
    },
    {
        "value": "509222",
        "label": "Miniatures For Collectors-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42392018",
        "yellow_page_code": "52510",
        "kiosk": false
    },
    {
        "value": "509223",
        "label": "Wholesalers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42392023",
        "yellow_page_code": "93407, 13946",
        "kiosk": false
    },
    {
        "value": "509224",
        "label": "Suppliers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512019",
        "yellow_page_code": "82325, 30187, 49896",
        "kiosk": false
    },
    {
        "value": "509225",
        "label": "Toy & Hobby Goods & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42392024",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "509301",
        "label": "Aircraft Salvage (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42393001",
        "yellow_page_code": "01509",
        "kiosk": false
    },
    {
        "value": "509302",
        "label": "Barrels & Drums-Used (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42393004",
        "yellow_page_code": "06706",
        "kiosk": false
    },
    {
        "value": "509303",
        "label": "Film Scrap (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42393009",
        "yellow_page_code": "31609",
        "kiosk": false
    },
    {
        "value": "509304",
        "label": "Boxes-Used (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42393007",
        "yellow_page_code": "10002",
        "kiosk": false
    },
    {
        "value": "509305",
        "label": "Waste Rdctn Dspsl/Recycle Svc-Ind (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42393027",
        "yellow_page_code": "91900, 91816, 91830, 91823",
        "kiosk": false
    },
    {
        "value": "509306",
        "label": "Waste-Cotton Wool Synthetic Etc (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42393028",
        "yellow_page_code": "91807",
        "kiosk": false
    },
    {
        "value": "509307",
        "label": "Waste-Paper (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42393031",
        "yellow_page_code": "91808",
        "kiosk": false
    },
    {
        "value": "509308",
        "label": "Oils-Waste (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42393012",
        "yellow_page_code": "57408",
        "kiosk": false
    },
    {
        "value": "509309",
        "label": "Wiping Cloths (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42393033",
        "yellow_page_code": "93902, 18423, 93837",
        "kiosk": false
    },
    {
        "value": "509310",
        "label": "Rags (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42393015",
        "yellow_page_code": "70106",
        "kiosk": false
    },
    {
        "value": "509311",
        "label": "Plastics-Scrap (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42393014",
        "yellow_page_code": "66502, 30534",
        "kiosk": false
    },
    {
        "value": "509312",
        "label": "Recycling Centers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42393017",
        "yellow_page_code": "71104, 71113, 58814, 71042, 02011, 71035, 71110, 86505, 74728, 51427, 12895, 71041",
        "kiosk": false
    },
    {
        "value": "509313",
        "label": "Scrap Metals & Iron (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42393019",
        "yellow_page_code": "74802, 51429, 02165",
        "kiosk": false
    },
    {
        "value": "509314",
        "label": "Scrap Metals-Processing/Recycling (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42393020",
        "yellow_page_code": "74803",
        "kiosk": false
    },
    {
        "value": "509315",
        "label": "Steel-Used (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42393023",
        "yellow_page_code": "81104",
        "kiosk": false
    },
    {
        "value": "509316",
        "label": "Waste Disposal Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42393026",
        "yellow_page_code": "91803, 25119",
        "kiosk": false
    },
    {
        "value": "509317",
        "label": "Waste (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42393025",
        "yellow_page_code": "91801",
        "kiosk": false
    },
    {
        "value": "509318",
        "label": "Surplus & Salvage Merchandise-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42393038",
        "yellow_page_code": "82501, 82415, 29859",
        "kiosk": false
    },
    {
        "value": "509319",
        "label": "Scrap Rubber (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42393021",
        "yellow_page_code": "74800, 72917",
        "kiosk": false
    },
    {
        "value": "509320",
        "label": "Salvage-Yards (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42393018",
        "yellow_page_code": "73607",
        "kiosk": false
    },
    {
        "value": "509321",
        "label": "Tire Disposal (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42393024",
        "yellow_page_code": "86409",
        "kiosk": false
    },
    {
        "value": "509322",
        "label": "Bottle & Can Recycling Depots (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42393005",
        "yellow_page_code": "09503, 09511",
        "kiosk": false
    },
    {
        "value": "509323",
        "label": "Recycled Products (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42393016",
        "yellow_page_code": "71037",
        "kiosk": false
    },
    {
        "value": "509324",
        "label": "Automobile Crushing Service (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42393003",
        "yellow_page_code": "04609, 05135, 71033, 05134, 13041",
        "kiosk": false
    },
    {
        "value": "509325",
        "label": "Fur Cuttings (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42393010",
        "yellow_page_code": "35203",
        "kiosk": false
    },
    {
        "value": "509326",
        "label": "Bottles-Used (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42393006",
        "yellow_page_code": "09605",
        "kiosk": false
    },
    {
        "value": "509327",
        "label": "Metal Buyers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42393011",
        "yellow_page_code": "51404, 51914",
        "kiosk": false
    },
    {
        "value": "509328",
        "label": "Plastic Recycling (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42393013",
        "yellow_page_code": "66113",
        "kiosk": false
    },
    {
        "value": "509329",
        "label": "Wire-Scrap Processing (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42393034",
        "yellow_page_code": "93920",
        "kiosk": false
    },
    {
        "value": "509330",
        "label": "Wood-Waste & Recycling (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42393036",
        "yellow_page_code": "94221, 15410",
        "kiosk": false
    },
    {
        "value": "509331",
        "label": "Wood Bending (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42393035",
        "yellow_page_code": "94301",
        "kiosk": false
    },
    {
        "value": "509332",
        "label": "Waste-Grease (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42393029",
        "yellow_page_code": "91811",
        "kiosk": false
    },
    {
        "value": "509333",
        "label": "Waste-Paper-Buyers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512020",
        "yellow_page_code": "91814",
        "kiosk": false
    },
    {
        "value": "509334",
        "label": "Waste-Oil Furnaces-Sales & Svcs (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42393030",
        "yellow_page_code": "91817",
        "kiosk": false
    },
    {
        "value": "509335",
        "label": "Waste-Plastics (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42393032",
        "yellow_page_code": "91902",
        "kiosk": false
    },
    {
        "value": "509336",
        "label": "Sewage Disposal Systems-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42393022",
        "yellow_page_code": "75709",
        "kiosk": false
    },
    {
        "value": "509337",
        "label": "Container Depots-Bottles & Cans (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42393008",
        "yellow_page_code": "21033",
        "kiosk": false
    },
    {
        "value": "509338",
        "label": "Ammunition Disassembly Wst/Recycle",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42393002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "509339",
        "label": "Asphalt Reclaiming (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42393037",
        "yellow_page_code": "03990",
        "kiosk": false
    },
    {
        "value": "509340",
        "label": "Recycling-Electronics (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42393039",
        "yellow_page_code": "71111",
        "kiosk": false
    },
    {
        "value": "509341",
        "label": "Appliances Recycling (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42393040",
        "yellow_page_code": "02922, 34510, 27416",
        "kiosk": false
    },
    {
        "value": "509342",
        "label": "Recycling Non Ferrous Metals (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42393041",
        "yellow_page_code": "71045",
        "kiosk": false
    },
    {
        "value": "509401",
        "label": "Watches-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42394033",
        "yellow_page_code": "92006, 30084",
        "kiosk": false
    },
    {
        "value": "509402",
        "label": "Precious/Semi-Prcs/Synthetic Stns (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42394023",
        "yellow_page_code": "68004, 68027, 22780, 30156",
        "kiosk": false
    },
    {
        "value": "509403",
        "label": "Jewelers Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42394011",
        "yellow_page_code": "44204, 44219, 44225",
        "kiosk": false
    },
    {
        "value": "509404",
        "label": "Gold Silver & Platinum-Buyers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42394009",
        "yellow_page_code": "38003",
        "kiosk": false
    },
    {
        "value": "509405",
        "label": "Jewelry Castings (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42394014",
        "yellow_page_code": "44302",
        "kiosk": false
    },
    {
        "value": "509406",
        "label": "Jewelers-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42394012",
        "yellow_page_code": "44208, 44314, 44295, 30477, 30483",
        "kiosk": false
    },
    {
        "value": "509407",
        "label": "Jewelry Buyers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42394013",
        "yellow_page_code": "44300",
        "kiosk": false
    },
    {
        "value": "509408",
        "label": "Jewels-Imitation Stones (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42394018",
        "yellow_page_code": "44500",
        "kiosk": false
    },
    {
        "value": "509409",
        "label": "Jewelry Findings (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42394017",
        "yellow_page_code": "44402",
        "kiosk": false
    },
    {
        "value": "509410",
        "label": "Beads (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42394001",
        "yellow_page_code": "07108",
        "kiosk": false
    },
    {
        "value": "509411",
        "label": "Metals-Precious Sheet Wire Tubing (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42394019",
        "yellow_page_code": "51908, 67939",
        "kiosk": false
    },
    {
        "value": "509412",
        "label": "Clocks-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42394003",
        "yellow_page_code": "18608",
        "kiosk": false
    },
    {
        "value": "509413",
        "label": "Diamond Buyers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42394006",
        "yellow_page_code": "24406",
        "kiosk": false
    },
    {
        "value": "509414",
        "label": "Diamond Brokers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512021",
        "yellow_page_code": "24407",
        "kiosk": false
    },
    {
        "value": "509415",
        "label": "Jewelry Contractors (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42394016",
        "yellow_page_code": "44305",
        "kiosk": false
    },
    {
        "value": "509416",
        "label": "Diamonds-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42394007",
        "yellow_page_code": "24506",
        "kiosk": false
    },
    {
        "value": "509417",
        "label": "Watchmakers' Equipment & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42394034",
        "yellow_page_code": "92007",
        "kiosk": false
    },
    {
        "value": "509418",
        "label": "Plaques-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42394022",
        "yellow_page_code": "66106",
        "kiosk": false
    },
    {
        "value": "509419",
        "label": "Clocks-Components (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42394002",
        "yellow_page_code": "18602, 18601",
        "kiosk": false
    },
    {
        "value": "509420",
        "label": "Ribbons (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42394025",
        "yellow_page_code": "72104, 72116",
        "kiosk": false
    },
    {
        "value": "509421",
        "label": "Trophies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42394028",
        "yellow_page_code": "88603",
        "kiosk": false
    },
    {
        "value": "509422",
        "label": "Metals-Rare (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42394020",
        "yellow_page_code": "51909",
        "kiosk": false
    },
    {
        "value": "509423",
        "label": "Jewelry Brokers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512022",
        "yellow_page_code": "44301, 42626",
        "kiosk": false
    },
    {
        "value": "509424",
        "label": "Native American Goods-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42394010",
        "yellow_page_code": "42809, 42828",
        "kiosk": false
    },
    {
        "value": "509425",
        "label": "Turquoise (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42394030",
        "yellow_page_code": "89509",
        "kiosk": false
    },
    {
        "value": "509426",
        "label": "Coin Dealers Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42394004",
        "yellow_page_code": "19505",
        "kiosk": false
    },
    {
        "value": "509427",
        "label": "Costume Jewelry-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42394005",
        "yellow_page_code": "21812",
        "kiosk": false
    },
    {
        "value": "509428",
        "label": "Gold Nugget Jewelry-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42394008",
        "yellow_page_code": "37936",
        "kiosk": false
    },
    {
        "value": "509429",
        "label": "Pearls-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42394021",
        "yellow_page_code": "60107",
        "kiosk": false
    },
    {
        "value": "509430",
        "label": "Rhinestones-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42394024",
        "yellow_page_code": "72009",
        "kiosk": false
    },
    {
        "value": "509431",
        "label": "Silverware-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42394026",
        "yellow_page_code": "77807",
        "kiosk": false
    },
    {
        "value": "509432",
        "label": "Trophy Parts & Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42394029",
        "yellow_page_code": "88528",
        "kiosk": false
    },
    {
        "value": "509433",
        "label": "Jewelry Castings-Equip (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42394015",
        "yellow_page_code": "44304",
        "kiosk": false
    },
    {
        "value": "509434",
        "label": "Watch Straps & Bands-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42394032",
        "yellow_page_code": "91912",
        "kiosk": false
    },
    {
        "value": "509435",
        "label": "Watch Crowns (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42394031",
        "yellow_page_code": "92003",
        "kiosk": false
    },
    {
        "value": "509436",
        "label": "Stone Cutters Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42394027",
        "yellow_page_code": "81506",
        "kiosk": false
    },
    {
        "value": "509437",
        "label": "Silver Products-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45831002",
        "yellow_page_code": "77701",
        "kiosk": false
    },
    {
        "value": "509438",
        "label": "Bullion Dealers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42394036",
        "yellow_page_code": "11422",
        "kiosk": false
    },
    {
        "value": "509439",
        "label": "Diamond Registries (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42394037",
        "yellow_page_code": "24437",
        "kiosk": false
    },
    {
        "value": "509440",
        "label": "Exporters-Precious Metals (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42394038",
        "yellow_page_code": "30233, 30450",
        "kiosk": false
    },
    {
        "value": "509441",
        "label": "Jewelry/Watch Precious Stone & Metal (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42394039",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "509901",
        "label": "Exporters (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42399010",
        "yellow_page_code": "30208, 30214, 30210, 30215, 30115, 30219, 30225, 30220, 30234, 30236, 30239, 30222",
        "kiosk": false
    },
    {
        "value": "509902",
        "label": "Gas Lights (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42399017",
        "yellow_page_code": "36604",
        "kiosk": false
    },
    {
        "value": "509903",
        "label": "Fire Extinguishers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42399011",
        "yellow_page_code": "32004, 32011, 71952",
        "kiosk": false
    },
    {
        "value": "509904",
        "label": "Institution Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42399020",
        "yellow_page_code": "43302",
        "kiosk": false
    },
    {
        "value": "509905",
        "label": "Importers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42399019",
        "yellow_page_code": "42702, 42700, 42690, 42709, 93535, 42691, 30713",
        "kiosk": false
    },
    {
        "value": "509906",
        "label": "Luggage-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42399023",
        "yellow_page_code": "48900",
        "kiosk": false
    },
    {
        "value": "509907",
        "label": "Monuments-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42399029",
        "yellow_page_code": "53509",
        "kiosk": false
    },
    {
        "value": "509908",
        "label": "Manufacturers-Agents & Representatives",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42399026",
        "yellow_page_code": "49900, 69809, 30195, 00769",
        "kiosk": false
    },
    {
        "value": "509909",
        "label": "Records Tapes & Compact Discs-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512036",
        "yellow_page_code": "71000, 20138, 54815, 70920, 14220",
        "kiosk": false
    },
    {
        "value": "509910",
        "label": "Musical Instruments-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512027",
        "yellow_page_code": "55002, 30293",
        "kiosk": false
    },
    {
        "value": "509911",
        "label": "Tarpaulins-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42399030",
        "yellow_page_code": "84004, 83912",
        "kiosk": false
    },
    {
        "value": "509912",
        "label": "Name Plates (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512028",
        "yellow_page_code": "55104, 55037",
        "kiosk": false
    },
    {
        "value": "509913",
        "label": "Fire Extinguishing Fluid (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42399012",
        "yellow_page_code": "32007, 32012",
        "kiosk": false
    },
    {
        "value": "509914",
        "label": "Piano Supplies & Parts (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512033",
        "yellow_page_code": "64904",
        "kiosk": false
    },
    {
        "value": "509915",
        "label": "Piano Rolls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512032",
        "yellow_page_code": "64901",
        "kiosk": false
    },
    {
        "value": "509916",
        "label": "Magnets & Magnetic Devices (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42399025",
        "yellow_page_code": "49600, 49618, 49511",
        "kiosk": false
    },
    {
        "value": "509917",
        "label": "Sawdust & Shavings (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499098",
        "yellow_page_code": "74200, 94299, 93340, 93350",
        "kiosk": false
    },
    {
        "value": "509918",
        "label": "Instrument Cases (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42399021",
        "yellow_page_code": "43304",
        "kiosk": false
    },
    {
        "value": "509920",
        "label": "Amusement Devices-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42399001",
        "yellow_page_code": "02209",
        "kiosk": false
    },
    {
        "value": "509921",
        "label": "Binoculars-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42399002",
        "yellow_page_code": "08112, 30473",
        "kiosk": false
    },
    {
        "value": "509922",
        "label": "Firewood-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42399014",
        "yellow_page_code": "32236",
        "kiosk": false
    },
    {
        "value": "509923",
        "label": "Fountains-Garden Display Etc-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42399015",
        "yellow_page_code": "34234",
        "kiosk": false
    },
    {
        "value": "509924",
        "label": "Hammocks-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42399018",
        "yellow_page_code": "39812",
        "kiosk": false
    },
    {
        "value": "509925",
        "label": "Magicians Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42399024",
        "yellow_page_code": "49403",
        "kiosk": false
    },
    {
        "value": "509927",
        "label": "Musical Instruments-Supls & Acces-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512026",
        "yellow_page_code": "54912",
        "kiosk": false
    },
    {
        "value": "509928",
        "label": "Pianos-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512034",
        "yellow_page_code": "64809",
        "kiosk": false
    },
    {
        "value": "509929",
        "label": "Rental Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512037",
        "yellow_page_code": "71609",
        "kiosk": false
    },
    {
        "value": "509930",
        "label": "Sample Cases-Wholesale & Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512038",
        "yellow_page_code": "73701",
        "kiosk": false
    },
    {
        "value": "509931",
        "label": "Trunks-Wholesale & Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42399032",
        "yellow_page_code": "89109",
        "kiosk": false
    },
    {
        "value": "509932",
        "label": "Video Tape-Distributors (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42399035",
        "yellow_page_code": "91045",
        "kiosk": false
    },
    {
        "value": "509933",
        "label": "Signs-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512041",
        "yellow_page_code": "77514",
        "kiosk": false
    },
    {
        "value": "509934",
        "label": "Gaming Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42399016",
        "yellow_page_code": "36011, 36019, 36023",
        "kiosk": false
    },
    {
        "value": "509935",
        "label": "Log Brokers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512024",
        "yellow_page_code": "48600",
        "kiosk": false
    },
    {
        "value": "509936",
        "label": "Job Lot Merchandise (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42399022",
        "yellow_page_code": "44508",
        "kiosk": false
    },
    {
        "value": "509937",
        "label": "Posts-Corner Posts (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512035",
        "yellow_page_code": "67505",
        "kiosk": false
    },
    {
        "value": "509938",
        "label": "Pet Cemetery Equipment & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512030",
        "yellow_page_code": "60417",
        "kiosk": false
    },
    {
        "value": "509939",
        "label": "Casino Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42399003",
        "yellow_page_code": "13927",
        "kiosk": false
    },
    {
        "value": "509940",
        "label": "Monument Makers Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42399027",
        "yellow_page_code": "53508",
        "kiosk": false
    },
    {
        "value": "509941",
        "label": "Timbers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42399031",
        "yellow_page_code": "86107",
        "kiosk": false
    },
    {
        "value": "509942",
        "label": "Environmental & Ecological Products (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42399008",
        "yellow_page_code": "29613, 38921",
        "kiosk": false
    },
    {
        "value": "509943",
        "label": "Environmental Products & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42399009",
        "yellow_page_code": "29615, 29616, 29620, 29621",
        "kiosk": false
    },
    {
        "value": "509945",
        "label": "Dynamometers-Distributors (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512023",
        "yellow_page_code": "27009",
        "kiosk": false
    },
    {
        "value": "509946",
        "label": "Ear Piercing Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42399007",
        "yellow_page_code": "27103",
        "kiosk": false
    },
    {
        "value": "509947",
        "label": "Survival & Life Support Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512042",
        "yellow_page_code": "82601, 82319",
        "kiosk": false
    },
    {
        "value": "509948",
        "label": "Woodshavings (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42399033",
        "yellow_page_code": "94305",
        "kiosk": false
    },
    {
        "value": "509949",
        "label": "Monument Medallions (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42399028",
        "yellow_page_code": "53511",
        "kiosk": false
    },
    {
        "value": "509950",
        "label": "Paper Mache (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512029",
        "yellow_page_code": "59308",
        "kiosk": false
    },
    {
        "value": "509951",
        "label": "Self Defense Weapons (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512040",
        "yellow_page_code": "75436",
        "kiosk": false
    },
    {
        "value": "509952",
        "label": "Fire Starters-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42399013",
        "yellow_page_code": "32190",
        "kiosk": false
    },
    {
        "value": "509953",
        "label": "Exporters-Communications-Wireless (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512075",
        "yellow_page_code": "30231",
        "kiosk": false
    },
    {
        "value": "509954",
        "label": "Exporters-Training-Program Designing/Dev",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42399034",
        "yellow_page_code": "30315",
        "kiosk": false
    },
    {
        "value": "509955",
        "label": "Magnets-Retail (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912042",
        "yellow_page_code": "49510",
        "kiosk": false
    },
    {
        "value": "509956",
        "label": "Bollards (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42399036",
        "yellow_page_code": "09111",
        "kiosk": false
    },
    {
        "value": "509957",
        "label": "Stage Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42399037",
        "yellow_page_code": "80419",
        "kiosk": false
    },
    {
        "value": "509998",
        "label": "Distribution Centers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42399004",
        "yellow_page_code": "25115, 25118",
        "kiosk": false
    },
    {
        "value": "509999",
        "label": "Durable Goods NEC (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42399006",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "511101",
        "label": "Paper Products (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42411001",
        "yellow_page_code": "58903, 59100, 58911, 58912, 58815, 29974, 29975",
        "kiosk": false
    },
    {
        "value": "511102",
        "label": "Printing & Writing Paper (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42411003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "511103",
        "label": "Printing-Paper-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42411002",
        "yellow_page_code": "68416",
        "kiosk": false
    },
    {
        "value": "511201",
        "label": "Inks-Writing & Marking (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42412013",
        "yellow_page_code": "43201",
        "kiosk": false
    },
    {
        "value": "511202",
        "label": "Greeting Cards-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42412012",
        "yellow_page_code": "39004, 13509",
        "kiosk": false
    },
    {
        "value": "511203",
        "label": "Office Supplies-Commercial (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42412021",
        "yellow_page_code": "56400",
        "kiosk": false
    },
    {
        "value": "511204",
        "label": "Legal Forms (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42412017",
        "yellow_page_code": "46904",
        "kiosk": false
    },
    {
        "value": "511205",
        "label": "Office Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42412022",
        "yellow_page_code": "56401",
        "kiosk": false
    },
    {
        "value": "511206",
        "label": "Corporation Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42412008",
        "yellow_page_code": "21708",
        "kiosk": false
    },
    {
        "value": "511207",
        "label": "Business Forms & Systems (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42412005",
        "yellow_page_code": "11806, 11713",
        "kiosk": false
    },
    {
        "value": "511208",
        "label": "Pens & Pencils-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42412024",
        "yellow_page_code": "60109",
        "kiosk": false
    },
    {
        "value": "511209",
        "label": "Data Processing Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42412010",
        "yellow_page_code": "23502",
        "kiosk": false
    },
    {
        "value": "511210",
        "label": "Price Marking Equipment & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42412026",
        "yellow_page_code": "68202",
        "kiosk": false
    },
    {
        "value": "511211",
        "label": "Stationery-Commercial (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42412033",
        "yellow_page_code": "80701",
        "kiosk": false
    },
    {
        "value": "511212",
        "label": "Seals-Notary & Corp (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42412031",
        "yellow_page_code": "75102",
        "kiosk": false
    },
    {
        "value": "511213",
        "label": "School Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42412030",
        "yellow_page_code": "74404, 30359",
        "kiosk": false
    },
    {
        "value": "511214",
        "label": "Stationery-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42412034",
        "yellow_page_code": "80703, 80705, 93061",
        "kiosk": false
    },
    {
        "value": "511215",
        "label": "Bulletin & Directory Boards (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42412004",
        "yellow_page_code": "11500",
        "kiosk": false
    },
    {
        "value": "511216",
        "label": "Envelopes-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42412011",
        "yellow_page_code": "29700",
        "kiosk": false
    },
    {
        "value": "511217",
        "label": "Carbon Paper (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42412006",
        "yellow_page_code": "13206",
        "kiosk": false
    },
    {
        "value": "511218",
        "label": "Post Cards-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42412025",
        "yellow_page_code": "67503",
        "kiosk": false
    },
    {
        "value": "511219",
        "label": "Maps-Publishers & Distrs (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512044",
        "yellow_page_code": "49909, 49904",
        "kiosk": false
    },
    {
        "value": "511220",
        "label": "Crayons-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42412009",
        "yellow_page_code": "22411",
        "kiosk": false
    },
    {
        "value": "511221",
        "label": "Labels-Paper-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42412015",
        "yellow_page_code": "45112, 30486",
        "kiosk": false
    },
    {
        "value": "511222",
        "label": "Loose Leaf Equipment Systs & Supls-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42412018",
        "yellow_page_code": "48620",
        "kiosk": false
    },
    {
        "value": "511223",
        "label": "Rubber Stamps-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42412028",
        "yellow_page_code": "73011",
        "kiosk": false
    },
    {
        "value": "511224",
        "label": "Sample Cards & Books-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42412029",
        "yellow_page_code": "73611",
        "kiosk": false
    },
    {
        "value": "511225",
        "label": "Typewriters-Supplies & Attachments-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42412036",
        "yellow_page_code": "89713",
        "kiosk": false
    },
    {
        "value": "511226",
        "label": "Albums (Photo)-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42412001",
        "yellow_page_code": "01805",
        "kiosk": false
    },
    {
        "value": "511227",
        "label": "Chalk-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42412007",
        "yellow_page_code": "14611",
        "kiosk": false
    },
    {
        "value": "511228",
        "label": "Tapes-Adhesive-Dispensers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42412035",
        "yellow_page_code": "83806, 83713",
        "kiosk": false
    },
    {
        "value": "511229",
        "label": "Mapping Services (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42412019",
        "yellow_page_code": "13835, 49823",
        "kiosk": false
    },
    {
        "value": "511230",
        "label": "Music Writers Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42412020",
        "yellow_page_code": "54804",
        "kiosk": false
    },
    {
        "value": "511231",
        "label": "Pens & Pencils-Supplies & Parts (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42412023",
        "yellow_page_code": "60136",
        "kiosk": false
    },
    {
        "value": "511232",
        "label": "Pricing Systems (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42412027",
        "yellow_page_code": "68207",
        "kiosk": false
    },
    {
        "value": "511233",
        "label": "Invitations & Announcements-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42412014",
        "yellow_page_code": "43712",
        "kiosk": false
    },
    {
        "value": "511234",
        "label": "Blotters-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42412003",
        "yellow_page_code": "08510",
        "kiosk": false
    },
    {
        "value": "511235",
        "label": "Labels-Plastic Metal Foil & Etc-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42412016",
        "yellow_page_code": "45114",
        "kiosk": false
    },
    {
        "value": "511238",
        "label": "Binders-Loose Leaf-Whls & Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42412002",
        "yellow_page_code": "08115",
        "kiosk": false
    },
    {
        "value": "511239",
        "label": "Gummed Paper (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42412037",
        "yellow_page_code": "39317",
        "kiosk": false
    },
    {
        "value": "511240",
        "label": "Stationery & Office Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42412038",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "511301",
        "label": "Packing Materials-Shipping (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42413022",
        "yellow_page_code": "58408, 58407",
        "kiosk": false
    },
    {
        "value": "511302",
        "label": "Paper/Plastic Cups Cntnrs/Utnsls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42413027",
        "yellow_page_code": "58804, 24913, 22815",
        "kiosk": false
    },
    {
        "value": "511303",
        "label": "Paper Tubes & Cores (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42413025",
        "yellow_page_code": "59200, 89315, 59105",
        "kiosk": false
    },
    {
        "value": "511304",
        "label": "Paper Towels (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42413024",
        "yellow_page_code": "59108",
        "kiosk": false
    },
    {
        "value": "511305",
        "label": "Soda Fountain Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42413033",
        "yellow_page_code": "79105",
        "kiosk": false
    },
    {
        "value": "511306",
        "label": "Shipping Room Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42413031",
        "yellow_page_code": "76602",
        "kiosk": false
    },
    {
        "value": "511307",
        "label": "Boxes-Specialty & Fancy (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42413008",
        "yellow_page_code": "10000",
        "kiosk": false
    },
    {
        "value": "511308",
        "label": "Packaging Materials-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42413020",
        "yellow_page_code": "58400, 83714, 21437, 30250",
        "kiosk": false
    },
    {
        "value": "511309",
        "label": "Disposable Products (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42413013",
        "yellow_page_code": "25106, 58812",
        "kiosk": false
    },
    {
        "value": "511310",
        "label": "Box-Manufacturers Equip & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42413005",
        "yellow_page_code": "09805",
        "kiosk": false
    },
    {
        "value": "511311",
        "label": "Identification Cards (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42413017",
        "yellow_page_code": "42604",
        "kiosk": false
    },
    {
        "value": "511312",
        "label": "Paper Brokers & Mill Representatives",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512045",
        "yellow_page_code": "58808, 29973",
        "kiosk": false
    },
    {
        "value": "511313",
        "label": "Bags-Plastic-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42413003",
        "yellow_page_code": "05803, 05815, 66225",
        "kiosk": false
    },
    {
        "value": "511314",
        "label": "Plastic Containers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42413029",
        "yellow_page_code": "66107, 21091",
        "kiosk": false
    },
    {
        "value": "511315",
        "label": "Cardboard (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42413009",
        "yellow_page_code": "13407",
        "kiosk": false
    },
    {
        "value": "511316",
        "label": "Bags-Garbage-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42413001",
        "yellow_page_code": "05811",
        "kiosk": false
    },
    {
        "value": "511317",
        "label": "Bags-Specialty-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42413004",
        "yellow_page_code": "05812, 13825",
        "kiosk": false
    },
    {
        "value": "511318",
        "label": "Boxes-Corrugated & Fiber (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42413006",
        "yellow_page_code": "09901, 09801, 09812, 30099",
        "kiosk": false
    },
    {
        "value": "511319",
        "label": "Boxes-Paper-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42413007",
        "yellow_page_code": "09912",
        "kiosk": false
    },
    {
        "value": "511320",
        "label": "Decals-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42413011",
        "yellow_page_code": "23615",
        "kiosk": false
    },
    {
        "value": "511321",
        "label": "Decoration Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42413012",
        "yellow_page_code": "23611",
        "kiosk": false
    },
    {
        "value": "511322",
        "label": "Dress Patterns-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42413014",
        "yellow_page_code": "26203",
        "kiosk": false
    },
    {
        "value": "511323",
        "label": "Gift Wrapping Materials-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42413016",
        "yellow_page_code": "37401",
        "kiosk": false
    },
    {
        "value": "511324",
        "label": "Toothpicks-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42413035",
        "yellow_page_code": "87209",
        "kiosk": false
    },
    {
        "value": "511325",
        "label": "Wedding Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42413036",
        "yellow_page_code": "92905",
        "kiosk": false
    },
    {
        "value": "511326",
        "label": "Sanitary Products Supplies & Svc (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42413030",
        "yellow_page_code": "73736, 73740",
        "kiosk": false
    },
    {
        "value": "511327",
        "label": "Paper-Toilet (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42413026",
        "yellow_page_code": "59109",
        "kiosk": false
    },
    {
        "value": "511328",
        "label": "Paper Products-Retail (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42413023",
        "yellow_page_code": "59005",
        "kiosk": false
    },
    {
        "value": "511329",
        "label": "Coffee Urn Bags (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42413010",
        "yellow_page_code": "19211",
        "kiosk": false
    },
    {
        "value": "511330",
        "label": "Stretch Wrap Film (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42413034",
        "yellow_page_code": "82101",
        "kiosk": false
    },
    {
        "value": "511331",
        "label": "Bags-Industrial & Commercial (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42413002",
        "yellow_page_code": "05814",
        "kiosk": false
    },
    {
        "value": "511332",
        "label": "Dust Collector Bags (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42413015",
        "yellow_page_code": "26913",
        "kiosk": false
    },
    {
        "value": "511333",
        "label": "Shipping Service Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42413032",
        "yellow_page_code": "76506",
        "kiosk": false
    },
    {
        "value": "511334",
        "label": "Mailing Containers-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42413019",
        "yellow_page_code": "49614",
        "kiosk": false
    },
    {
        "value": "511335",
        "label": "Packing & Crating Materials (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42413021",
        "yellow_page_code": "58403",
        "kiosk": false
    },
    {
        "value": "511336",
        "label": "Paperboard-Solid Fiber (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42413028",
        "yellow_page_code": "58810",
        "kiosk": false
    },
    {
        "value": "511337",
        "label": "Bags-Paper Plastic & Fabric Suppliers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42413037",
        "yellow_page_code": "05820, 21360",
        "kiosk": false
    },
    {
        "value": "511338",
        "label": "Piece Goods Notions & Other Dry Goods (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42431037",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "511398",
        "label": "Industrial & Personal Svc-Paper (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42413018",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "512201",
        "label": "First Aid Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42421005",
        "yellow_page_code": "32307",
        "kiosk": false
    },
    {
        "value": "512202",
        "label": "Abdominal Supports (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42421001",
        "yellow_page_code": "00001",
        "kiosk": false
    },
    {
        "value": "512203",
        "label": "Pharmaceutical Products-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42421014",
        "yellow_page_code": "60701, 26619, 26601, 60618, 60620, 60622, 30345, 72077",
        "kiosk": false
    },
    {
        "value": "512204",
        "label": "Cosmetics-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42421002",
        "yellow_page_code": "21902, 21821, 21899, 21895, 21827",
        "kiosk": false
    },
    {
        "value": "512205",
        "label": "Vitamins & Food Supplements-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42421021",
        "yellow_page_code": "91205, 29884",
        "kiosk": false
    },
    {
        "value": "512206",
        "label": "Druggists-Sundries To The Trade (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42421004",
        "yellow_page_code": "26605, 26610",
        "kiosk": false
    },
    {
        "value": "512207",
        "label": "Ostomy Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42421011",
        "yellow_page_code": "58100, 58051",
        "kiosk": false
    },
    {
        "value": "512208",
        "label": "Perfuming-Fabric Paper Etc (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42421023",
        "yellow_page_code": "60205",
        "kiosk": false
    },
    {
        "value": "512209",
        "label": "Toilet Preparations (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42421019",
        "yellow_page_code": "86804",
        "kiosk": false
    },
    {
        "value": "512210",
        "label": "Razors & Razor Blades (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42421015",
        "yellow_page_code": "70602, 75699",
        "kiosk": false
    },
    {
        "value": "512211",
        "label": "Hair Preparations (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42421006",
        "yellow_page_code": "39704",
        "kiosk": false
    },
    {
        "value": "512212",
        "label": "Perfumers-Raw Materials & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42421013",
        "yellow_page_code": "60303",
        "kiosk": false
    },
    {
        "value": "512213",
        "label": "Perfume-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42421012",
        "yellow_page_code": "60302, 29977",
        "kiosk": false
    },
    {
        "value": "512214",
        "label": "Cosmetics/Toilet Preps-Mfrs Supl (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42421003",
        "yellow_page_code": "21900",
        "kiosk": false
    },
    {
        "value": "512215",
        "label": "Health & Beauty Aid Products-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42421007",
        "yellow_page_code": "40413, 49894",
        "kiosk": false
    },
    {
        "value": "512216",
        "label": "Make-Up-Masquerade & Theatrical-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42421009",
        "yellow_page_code": "49711",
        "kiosk": false
    },
    {
        "value": "512217",
        "label": "Medicines-Patent-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42421010",
        "yellow_page_code": "51115",
        "kiosk": false
    },
    {
        "value": "512218",
        "label": "Sanitary Napkins-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42421016",
        "yellow_page_code": "73735, 31237",
        "kiosk": false
    },
    {
        "value": "512219",
        "label": "Sundries-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42421018",
        "yellow_page_code": "82211",
        "kiosk": false
    },
    {
        "value": "512220",
        "label": "Tooth Powders & Pastes-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42421020",
        "yellow_page_code": "87208",
        "kiosk": false
    },
    {
        "value": "512221",
        "label": "Kelp Products-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42421008",
        "yellow_page_code": "44710",
        "kiosk": false
    },
    {
        "value": "512222",
        "label": "Condoms-Distributors (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512046",
        "yellow_page_code": "20815",
        "kiosk": false
    },
    {
        "value": "512223",
        "label": "Drug Brokers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512047",
        "yellow_page_code": "26505",
        "kiosk": false
    },
    {
        "value": "512224",
        "label": "Drug-Manufacturers Representatives",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512048",
        "yellow_page_code": "26509",
        "kiosk": false
    },
    {
        "value": "512225",
        "label": "Sun Tan Products (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42421017",
        "yellow_page_code": "82214, 82217",
        "kiosk": false
    },
    {
        "value": "512226",
        "label": "Biological Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42421022",
        "yellow_page_code": "08226",
        "kiosk": false
    },
    {
        "value": "513101",
        "label": "Woolen & Worsted Fabrics (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331037",
        "yellow_page_code": "83909, 94332, 44837",
        "kiosk": false
    },
    {
        "value": "513102",
        "label": "Rayon Fabrics-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331029",
        "yellow_page_code": "70600",
        "kiosk": false
    },
    {
        "value": "513103",
        "label": "Textile Brokers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331031",
        "yellow_page_code": "85302, 30255, 85320, 18765, 49891",
        "kiosk": false
    },
    {
        "value": "513104",
        "label": "Silks-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42431032",
        "yellow_page_code": "77606",
        "kiosk": false
    },
    {
        "value": "513105",
        "label": "Textile Converting (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331032",
        "yellow_page_code": "85303",
        "kiosk": false
    },
    {
        "value": "513106",
        "label": "Textiles-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331035",
        "yellow_page_code": "85304",
        "kiosk": false
    },
    {
        "value": "513107",
        "label": "Thread (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42431028",
        "yellow_page_code": "85804",
        "kiosk": false
    },
    {
        "value": "513108",
        "label": "Woolen Goods-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331038",
        "yellow_page_code": "94008, 94335",
        "kiosk": false
    },
    {
        "value": "513109",
        "label": "Upholstery Fabrics-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331010",
        "yellow_page_code": "90106",
        "kiosk": false
    },
    {
        "value": "513110",
        "label": "Bindings-Bias & Straight (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42431002",
        "yellow_page_code": "08200",
        "kiosk": false
    },
    {
        "value": "513111",
        "label": "Zippers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42431031",
        "yellow_page_code": "94904, 29890",
        "kiosk": false
    },
    {
        "value": "513112",
        "label": "Buttons-Clothing (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42431004",
        "yellow_page_code": "12000",
        "kiosk": false
    },
    {
        "value": "513113",
        "label": "Cotton Goods-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331016",
        "yellow_page_code": "22002, 22010",
        "kiosk": false
    },
    {
        "value": "513114",
        "label": "Cotton Goods-Converters (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331015",
        "yellow_page_code": "22001",
        "kiosk": false
    },
    {
        "value": "513115",
        "label": "Dress Trimmings (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42431008",
        "yellow_page_code": "26202",
        "kiosk": false
    },
    {
        "value": "513116",
        "label": "Drapery & Curtain Fabrics-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331017",
        "yellow_page_code": "26007",
        "kiosk": false
    },
    {
        "value": "513117",
        "label": "Dry Goods-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42431010",
        "yellow_page_code": "26700",
        "kiosk": false
    },
    {
        "value": "513118",
        "label": "Knit Goods-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331027",
        "yellow_page_code": "45003",
        "kiosk": false
    },
    {
        "value": "513119",
        "label": "Fabrics-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331020",
        "yellow_page_code": "30504, 42688, 30434",
        "kiosk": false
    },
    {
        "value": "513120",
        "label": "Notions-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42431023",
        "yellow_page_code": "55706, 76007, 75980, 75910",
        "kiosk": false
    },
    {
        "value": "513121",
        "label": "Labels-Fabric (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42431016",
        "yellow_page_code": "45204, 30487",
        "kiosk": false
    },
    {
        "value": "513122",
        "label": "Needlework & Needlework Mtrls-Whls/Mfr",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42431021",
        "yellow_page_code": "55402",
        "kiosk": false
    },
    {
        "value": "513123",
        "label": "Drapery Trimmings (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42431007",
        "yellow_page_code": "26008, 25913",
        "kiosk": false
    },
    {
        "value": "513124",
        "label": "Embroidery-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42431011",
        "yellow_page_code": "28503",
        "kiosk": false
    },
    {
        "value": "513125",
        "label": "Nylon Fabrics (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331028",
        "yellow_page_code": "56102",
        "kiosk": false
    },
    {
        "value": "513126",
        "label": "Coated Fabrics (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331014",
        "yellow_page_code": "19100",
        "kiosk": false
    },
    {
        "value": "513127",
        "label": "Synthetic & Blended Fabrics-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331030",
        "yellow_page_code": "83005",
        "kiosk": false
    },
    {
        "value": "513128",
        "label": "Aprons-Industrial-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42431033",
        "yellow_page_code": "03021",
        "kiosk": false
    },
    {
        "value": "513129",
        "label": "Cheesecloth-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42431005",
        "yellow_page_code": "14811",
        "kiosk": false
    },
    {
        "value": "513130",
        "label": "Elastic Goods-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42431034",
        "yellow_page_code": "27311",
        "kiosk": false
    },
    {
        "value": "513131",
        "label": "Hair Ornaments-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42431014",
        "yellow_page_code": "39703",
        "kiosk": false
    },
    {
        "value": "513132",
        "label": "Laces-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42431017",
        "yellow_page_code": "45407",
        "kiosk": false
    },
    {
        "value": "513133",
        "label": "Quilting Materials & Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42431035",
        "yellow_page_code": "69411",
        "kiosk": false
    },
    {
        "value": "513134",
        "label": "Smallwares & Notions-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42431026",
        "yellow_page_code": "78415",
        "kiosk": false
    },
    {
        "value": "513135",
        "label": "Tags-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42431027",
        "yellow_page_code": "83207",
        "kiosk": false
    },
    {
        "value": "513136",
        "label": "Trimmings-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42431029",
        "yellow_page_code": "88602",
        "kiosk": false
    },
    {
        "value": "513137",
        "label": "Velvets & Velveteens-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331036",
        "yellow_page_code": "90703",
        "kiosk": false
    },
    {
        "value": "513138",
        "label": "Crests-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42431006",
        "yellow_page_code": "22512",
        "kiosk": false
    },
    {
        "value": "513140",
        "label": "Flags & Banners-Mfrs' Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42431012",
        "yellow_page_code": "32803, 06416",
        "kiosk": false
    },
    {
        "value": "513141",
        "label": "Millinery-Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42431019",
        "yellow_page_code": "52409",
        "kiosk": false
    },
    {
        "value": "513142",
        "label": "Textile Jobbers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331034",
        "yellow_page_code": "85405",
        "kiosk": false
    },
    {
        "value": "513143",
        "label": "Ribbon Cutting (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42431024",
        "yellow_page_code": "72103",
        "kiosk": false
    },
    {
        "value": "513144",
        "label": "Gloves-Manufacturers Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42431013",
        "yellow_page_code": "37902",
        "kiosk": false
    },
    {
        "value": "513145",
        "label": "Linens-Piece Goods (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42431018",
        "yellow_page_code": "47601",
        "kiosk": false
    },
    {
        "value": "513146",
        "label": "Hatters Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42431015",
        "yellow_page_code": "40305",
        "kiosk": false
    },
    {
        "value": "513147",
        "label": "Boning (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42431003",
        "yellow_page_code": "09136",
        "kiosk": false
    },
    {
        "value": "513148",
        "label": "Chenilles-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331013",
        "yellow_page_code": "15101",
        "kiosk": false
    },
    {
        "value": "513149",
        "label": "Belts & Suspenders-Mfrs Supplies-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42431001",
        "yellow_page_code": "07632, 30207",
        "kiosk": false
    },
    {
        "value": "513150",
        "label": "Drapery & Curtain-Mfrs' Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331018",
        "yellow_page_code": "25905",
        "kiosk": false
    },
    {
        "value": "513151",
        "label": "Dry Goods-Commission (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42431009",
        "yellow_page_code": "26613",
        "kiosk": false
    },
    {
        "value": "513152",
        "label": "Worsted Goods (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331039",
        "yellow_page_code": "94509",
        "kiosk": false
    },
    {
        "value": "513153",
        "label": "Shirt Trimmings (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42431025",
        "yellow_page_code": "76612",
        "kiosk": false
    },
    {
        "value": "513154",
        "label": "Undercollar Cloth (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42431030",
        "yellow_page_code": "89814",
        "kiosk": false
    },
    {
        "value": "513155",
        "label": "Nets & Nettings-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42431022",
        "yellow_page_code": "55413",
        "kiosk": false
    },
    {
        "value": "513156",
        "label": "Nailheads & Nailhead Trimming (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42431020",
        "yellow_page_code": "55012",
        "kiosk": false
    },
    {
        "value": "513157",
        "label": "Jute (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31331023",
        "yellow_page_code": "44611",
        "kiosk": false
    },
    {
        "value": "513601",
        "label": "Hats-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435008",
        "yellow_page_code": "40304",
        "kiosk": false
    },
    {
        "value": "513602",
        "label": "Gloves-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435007",
        "yellow_page_code": "37906, 37817",
        "kiosk": false
    },
    {
        "value": "513603",
        "label": "Apparel-Mens-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435001",
        "yellow_page_code": "51208, 02809, 18723, 18796, 51225, 30035",
        "kiosk": false
    },
    {
        "value": "513605",
        "label": "Boys Clothing & Furnishings-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435003",
        "yellow_page_code": "10102, 30129",
        "kiosk": false
    },
    {
        "value": "513606",
        "label": "Caps (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435005",
        "yellow_page_code": "13003, 12916, 12918",
        "kiosk": false
    },
    {
        "value": "513607",
        "label": "Shirts-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435017",
        "yellow_page_code": "76606",
        "kiosk": false
    },
    {
        "value": "513608",
        "label": "Work Clothing-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435026",
        "yellow_page_code": "94503, 18824",
        "kiosk": false
    },
    {
        "value": "513609",
        "label": "Sportswear-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435018",
        "yellow_page_code": "80104, 80023, 80024, 46919, 46914, 80050, 30269, 80076, 80056",
        "kiosk": false
    },
    {
        "value": "513610",
        "label": "Neckwear-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435011",
        "yellow_page_code": "55304",
        "kiosk": false
    },
    {
        "value": "513611",
        "label": "T-Shirts-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435020",
        "yellow_page_code": "83006",
        "kiosk": false
    },
    {
        "value": "513612",
        "label": "Underwear-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435024",
        "yellow_page_code": "89808",
        "kiosk": false
    },
    {
        "value": "513614",
        "label": "Army & Navy Goods-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435002",
        "yellow_page_code": "52307, 03403, 30454",
        "kiosk": false
    },
    {
        "value": "513615",
        "label": "Buckles-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435004",
        "yellow_page_code": "11012",
        "kiosk": false
    },
    {
        "value": "513616",
        "label": "Overalls-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435012",
        "yellow_page_code": "58201",
        "kiosk": false
    },
    {
        "value": "513617",
        "label": "Umbrellas-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435022",
        "yellow_page_code": "89811",
        "kiosk": false
    },
    {
        "value": "513618",
        "label": "Underwear-Mens-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435023",
        "yellow_page_code": "89812",
        "kiosk": false
    },
    {
        "value": "513620",
        "label": "Jackets-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435009",
        "yellow_page_code": "44011",
        "kiosk": false
    },
    {
        "value": "513621",
        "label": "Jeans-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435010",
        "yellow_page_code": "44211",
        "kiosk": false
    },
    {
        "value": "513622",
        "label": "Pants-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435014",
        "yellow_page_code": "58713",
        "kiosk": false
    },
    {
        "value": "513623",
        "label": "Rainwear-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435015",
        "yellow_page_code": "70305",
        "kiosk": false
    },
    {
        "value": "513624",
        "label": "Western Apparel-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435025",
        "yellow_page_code": "93301",
        "kiosk": false
    },
    {
        "value": "513625",
        "label": "Sweat Shirts (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435019",
        "yellow_page_code": "82608",
        "kiosk": false
    },
    {
        "value": "513626",
        "label": "Shirtings (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435016",
        "yellow_page_code": "76613",
        "kiosk": false
    },
    {
        "value": "513627",
        "label": "Umbrellas-Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435021",
        "yellow_page_code": "89813",
        "kiosk": false
    },
    {
        "value": "513628",
        "label": "Clothing-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435006",
        "yellow_page_code": "18806, 38502, 30278, 42685",
        "kiosk": false
    },
    {
        "value": "513629",
        "label": "Pajamas (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435013",
        "yellow_page_code": "58611",
        "kiosk": false
    },
    {
        "value": "513701",
        "label": "Uniforms-Men's & Women's-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435070",
        "yellow_page_code": "90007",
        "kiosk": false
    },
    {
        "value": "513702",
        "label": "Swimming Suits-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435067",
        "yellow_page_code": "82905",
        "kiosk": false
    },
    {
        "value": "513703",
        "label": "Women's Coats & Suits-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435074",
        "yellow_page_code": "94103",
        "kiosk": false
    },
    {
        "value": "513704",
        "label": "Women's Accessories-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435072",
        "yellow_page_code": "94101",
        "kiosk": false
    },
    {
        "value": "513705",
        "label": "Women's Apparel-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435073",
        "yellow_page_code": "94108, 18717, 02856, 18794, 18734, 02860, 18825, 45424",
        "kiosk": false
    },
    {
        "value": "513706",
        "label": "Childrens Garment-Mfrs Representative",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435039",
        "yellow_page_code": "15209",
        "kiosk": false
    },
    {
        "value": "513707",
        "label": "Baby Accessories-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435031",
        "yellow_page_code": "05507, 05520",
        "kiosk": false
    },
    {
        "value": "513708",
        "label": "Dresses-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435046",
        "yellow_page_code": "26206",
        "kiosk": false
    },
    {
        "value": "513709",
        "label": "Childrens & Infants Wear-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435038",
        "yellow_page_code": "15208, 18715, 15137, 18701, 30372",
        "kiosk": false
    },
    {
        "value": "513710",
        "label": "Dressmakers Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435047",
        "yellow_page_code": "26300",
        "kiosk": false
    },
    {
        "value": "513711",
        "label": "Fur Buyers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435049",
        "yellow_page_code": "35104",
        "kiosk": false
    },
    {
        "value": "513712",
        "label": "Fur Business-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435048",
        "yellow_page_code": "35103",
        "kiosk": false
    },
    {
        "value": "513713",
        "label": "Handbags-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435055",
        "yellow_page_code": "39908, 69310",
        "kiosk": false
    },
    {
        "value": "513714",
        "label": "Fur-Dealers-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435050",
        "yellow_page_code": "35108",
        "kiosk": false
    },
    {
        "value": "513715",
        "label": "Hosiery-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435058",
        "yellow_page_code": "41800",
        "kiosk": false
    },
    {
        "value": "513716",
        "label": "Belts-Apparel-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435034",
        "yellow_page_code": "07702, 07629",
        "kiosk": false
    },
    {
        "value": "513717",
        "label": "Bridal Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435037",
        "yellow_page_code": "10701, 93069",
        "kiosk": false
    },
    {
        "value": "513718",
        "label": "Coats-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435040",
        "yellow_page_code": "19108",
        "kiosk": false
    },
    {
        "value": "513719",
        "label": "Lingerie-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435061",
        "yellow_page_code": "47607",
        "kiosk": false
    },
    {
        "value": "513720",
        "label": "Gloves & Mittens-Dress (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435054",
        "yellow_page_code": "37903",
        "kiosk": false
    },
    {
        "value": "513721",
        "label": "Robes-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435064",
        "yellow_page_code": "72306",
        "kiosk": false
    },
    {
        "value": "513722",
        "label": "Hawaiian Wear-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435056",
        "yellow_page_code": "40316",
        "kiosk": false
    },
    {
        "value": "513723",
        "label": "Dress Goods-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435045",
        "yellow_page_code": "26200",
        "kiosk": false
    },
    {
        "value": "513724",
        "label": "Apparel-Womens Millinery-Jobbers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435029",
        "yellow_page_code": "02811",
        "kiosk": false
    },
    {
        "value": "513725",
        "label": "Aprons-Domestic & Fancy-Wholesale & Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435030",
        "yellow_page_code": "03013",
        "kiosk": false
    },
    {
        "value": "513726",
        "label": "Baby Products-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435032",
        "yellow_page_code": "05511",
        "kiosk": false
    },
    {
        "value": "513727",
        "label": "Blouses-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435035",
        "yellow_page_code": "08512",
        "kiosk": false
    },
    {
        "value": "513728",
        "label": "Brassieres-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435036",
        "yellow_page_code": "10307",
        "kiosk": false
    },
    {
        "value": "513729",
        "label": "Corsets & Girdles-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435041",
        "yellow_page_code": "21804",
        "kiosk": false
    },
    {
        "value": "513730",
        "label": "Costumes-Masquerade & Theatrical-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435042",
        "yellow_page_code": "21805, 21917",
        "kiosk": false
    },
    {
        "value": "513731",
        "label": "Dancing Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435043",
        "yellow_page_code": "23311",
        "kiosk": false
    },
    {
        "value": "513732",
        "label": "Diapers-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435044",
        "yellow_page_code": "24600",
        "kiosk": false
    },
    {
        "value": "513733",
        "label": "Garters-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435051",
        "yellow_page_code": "36405",
        "kiosk": false
    },
    {
        "value": "513734",
        "label": "Girls Apparel-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435053",
        "yellow_page_code": "37403",
        "kiosk": false
    },
    {
        "value": "513735",
        "label": "Infants Equipment & Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435059",
        "yellow_page_code": "42911",
        "kiosk": false
    },
    {
        "value": "513736",
        "label": "Infants Garments-Wholesale & Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435060",
        "yellow_page_code": "43005",
        "kiosk": false
    },
    {
        "value": "513737",
        "label": "Maternity Apparel-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435062",
        "yellow_page_code": "50502, 18718",
        "kiosk": false
    },
    {
        "value": "513738",
        "label": "Smocks-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435065",
        "yellow_page_code": "78501",
        "kiosk": false
    },
    {
        "value": "513739",
        "label": "Millinery-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435063",
        "yellow_page_code": "52404",
        "kiosk": false
    },
    {
        "value": "513740",
        "label": "Bathing Suits-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435033",
        "yellow_page_code": "06912",
        "kiosk": false
    },
    {
        "value": "513741",
        "label": "Swimwear & Accessories-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435068",
        "yellow_page_code": "82808",
        "kiosk": false
    },
    {
        "value": "513742",
        "label": "Headwear-Novelties-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435057",
        "yellow_page_code": "40415",
        "kiosk": false
    },
    {
        "value": "513743",
        "label": "Sweaters-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435066",
        "yellow_page_code": "82607",
        "kiosk": false
    },
    {
        "value": "513744",
        "label": "Veiling (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435071",
        "yellow_page_code": "90702",
        "kiosk": false
    },
    {
        "value": "513745",
        "label": "Uniforms-Accessories-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435069",
        "yellow_page_code": "90010",
        "kiosk": false
    },
    {
        "value": "513746",
        "label": "Girdles & Bras-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435052",
        "yellow_page_code": "37313",
        "kiosk": false
    },
    {
        "value": "513747",
        "label": "Clothing & Acces-Women-Petites (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811001",
        "yellow_page_code": "18826",
        "kiosk": false
    },
    {
        "value": "513748",
        "label": "Clothing & Access-Men-Big & Tall (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435027",
        "yellow_page_code": "18797",
        "kiosk": false
    },
    {
        "value": "513750",
        "label": "Formal Wear Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435075",
        "yellow_page_code": "34199",
        "kiosk": false
    },
    {
        "value": "513799",
        "label": "Apparel-Chldrn/Teen Headwear Whls/Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42435028",
        "yellow_page_code": "02827",
        "kiosk": false
    },
    {
        "value": "513901",
        "label": "Boots-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42434001",
        "yellow_page_code": "09408",
        "kiosk": false
    },
    {
        "value": "513902",
        "label": "Shoes-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42434005",
        "yellow_page_code": "77004, 34023, 30352",
        "kiosk": false
    },
    {
        "value": "513903",
        "label": "Snowshoes-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42434007",
        "yellow_page_code": "78613",
        "kiosk": false
    },
    {
        "value": "513904",
        "label": "Shoe Trees-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42434004",
        "yellow_page_code": "76910",
        "kiosk": false
    },
    {
        "value": "513909",
        "label": "Slippers-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42434006",
        "yellow_page_code": "78405",
        "kiosk": false
    },
    {
        "value": "513910",
        "label": "Shoe Fabrics (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42434003",
        "yellow_page_code": "76703",
        "kiosk": false
    },
    {
        "value": "513911",
        "label": "Rubber & Plastic Footwear-Whls & Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42434002",
        "yellow_page_code": "72991",
        "kiosk": false
    },
    {
        "value": "513912",
        "label": "Footwear (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42434008",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "514101",
        "label": "Food Products (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42442005",
        "yellow_page_code": "33808, 33824, 33830, 33719, 33721, 12317, 12313, 30186, 30073, 42689, 33722",
        "kiosk": false
    },
    {
        "value": "514102",
        "label": "Food Brokers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512050",
        "yellow_page_code": "33800, 33831, 23012, 30181",
        "kiosk": false
    },
    {
        "value": "514103",
        "label": "Foods-Dehydrated (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42441004",
        "yellow_page_code": "34002, 33916, 33917",
        "kiosk": false
    },
    {
        "value": "514104",
        "label": "Mexican Food Products-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42441002",
        "yellow_page_code": "52107, 50292",
        "kiosk": false
    },
    {
        "value": "514105",
        "label": "Grocers-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42441005",
        "yellow_page_code": "39108",
        "kiosk": false
    },
    {
        "value": "514106",
        "label": "Delicatessens-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42442003",
        "yellow_page_code": "23803",
        "kiosk": false
    },
    {
        "value": "514107",
        "label": "Food Bulk (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42442001",
        "yellow_page_code": "11413, 33818",
        "kiosk": false
    },
    {
        "value": "514108",
        "label": "Delicatessen Products-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42442002",
        "yellow_page_code": "23736",
        "kiosk": false
    },
    {
        "value": "514109",
        "label": "Markets & Grocers Equip & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42441009",
        "yellow_page_code": "50203, 82250, 39117",
        "kiosk": false
    },
    {
        "value": "514110",
        "label": "Grocery Brokers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512049",
        "yellow_page_code": "10835, 39123",
        "kiosk": false
    },
    {
        "value": "514111",
        "label": "Food Agents (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42442004",
        "yellow_page_code": "33707",
        "kiosk": false
    },
    {
        "value": "514112",
        "label": "Food Service-Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42441003",
        "yellow_page_code": "33817",
        "kiosk": false
    },
    {
        "value": "514113",
        "label": "Kosher Food Products-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42441007",
        "yellow_page_code": "45107",
        "kiosk": false
    },
    {
        "value": "514114",
        "label": "Macaroni Products (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42441008",
        "yellow_page_code": "49035",
        "kiosk": false
    },
    {
        "value": "514115",
        "label": "Horseradish (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42441006",
        "yellow_page_code": "41509",
        "kiosk": false
    },
    {
        "value": "514116",
        "label": "Japanese Food Products-Whls & Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42441001",
        "yellow_page_code": "44116",
        "kiosk": false
    },
    {
        "value": "514201",
        "label": "Frozen Foods-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42442006",
        "yellow_page_code": "34608, 33912, 34599",
        "kiosk": false
    },
    {
        "value": "514202",
        "label": "Fruit Juices (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42442007",
        "yellow_page_code": "34701, 44602, 34716, 07815",
        "kiosk": false
    },
    {
        "value": "514203",
        "label": "Meats-Frozen (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42442008",
        "yellow_page_code": "50907",
        "kiosk": false
    },
    {
        "value": "514301",
        "label": "Cheese-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42443011",
        "yellow_page_code": "14907",
        "kiosk": false
    },
    {
        "value": "514302",
        "label": "Butter (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42443009",
        "yellow_page_code": "11904",
        "kiosk": false
    },
    {
        "value": "514303",
        "label": "Dairy Products-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42443012",
        "yellow_page_code": "23306, 52400, 23303",
        "kiosk": false
    },
    {
        "value": "514304",
        "label": "Ice Cream & Frozen Desserts-Distr (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42443013",
        "yellow_page_code": "42308, 24203, 30075",
        "kiosk": false
    },
    {
        "value": "514305",
        "label": "Ice Cream Mixes (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42443014",
        "yellow_page_code": "42407",
        "kiosk": false
    },
    {
        "value": "514306",
        "label": "Yogurt-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42443016",
        "yellow_page_code": "94803",
        "kiosk": false
    },
    {
        "value": "514307",
        "label": "Milk Buyers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42443015",
        "yellow_page_code": "52405",
        "kiosk": false
    },
    {
        "value": "514308",
        "label": "Cheese-Importing (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42443010",
        "yellow_page_code": "14905",
        "kiosk": false
    },
    {
        "value": "514401",
        "label": "Poultry Brokers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512051",
        "yellow_page_code": "67706",
        "kiosk": false
    },
    {
        "value": "514402",
        "label": "Poultry Services (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42444002",
        "yellow_page_code": "67806",
        "kiosk": false
    },
    {
        "value": "514403",
        "label": "Eggs (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42444001",
        "yellow_page_code": "27209, 27300, 27315",
        "kiosk": false
    },
    {
        "value": "514404",
        "label": "Poultry-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42444003",
        "yellow_page_code": "67900, 29988",
        "kiosk": false
    },
    {
        "value": "514501",
        "label": "Candy & Confectionery-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42445002",
        "yellow_page_code": "12802, 20920, 29849",
        "kiosk": false
    },
    {
        "value": "514502",
        "label": "Candy & Confectionery-Brokers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512052",
        "yellow_page_code": "12704",
        "kiosk": false
    },
    {
        "value": "514503",
        "label": "Concessionaires Equip & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42445006",
        "yellow_page_code": "20404",
        "kiosk": false
    },
    {
        "value": "514504",
        "label": "Potato Chips (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42445010",
        "yellow_page_code": "67508, 67601",
        "kiosk": false
    },
    {
        "value": "514505",
        "label": "Popcorn & Popcorn Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42445009",
        "yellow_page_code": "67303, 67307, 67311, 30294",
        "kiosk": false
    },
    {
        "value": "514506",
        "label": "Syrups-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42445004",
        "yellow_page_code": "83002",
        "kiosk": false
    },
    {
        "value": "514507",
        "label": "Pretzels (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42445003",
        "yellow_page_code": "68104",
        "kiosk": false
    },
    {
        "value": "514508",
        "label": "Candy & Confectionery-Mfrs Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42445001",
        "yellow_page_code": "12803",
        "kiosk": false
    },
    {
        "value": "514509",
        "label": "Ice Cream Cone-Distributors (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512053",
        "yellow_page_code": "42401",
        "kiosk": false
    },
    {
        "value": "514510",
        "label": "Nuts-Edible-Wholesale & Processing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42445008",
        "yellow_page_code": "56101",
        "kiosk": false
    },
    {
        "value": "514511",
        "label": "Corn Chips (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42445007",
        "yellow_page_code": "21700",
        "kiosk": false
    },
    {
        "value": "514512",
        "label": "Pistachios (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42445005",
        "yellow_page_code": "65905",
        "kiosk": false
    },
    {
        "value": "514601",
        "label": "Seafood-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42446002",
        "yellow_page_code": "32400, 75101, 75125, 29947",
        "kiosk": false
    },
    {
        "value": "514602",
        "label": "Oysters-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42446001",
        "yellow_page_code": "58302",
        "kiosk": false
    },
    {
        "value": "514603",
        "label": "Shrimp-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42446003",
        "yellow_page_code": "77401",
        "kiosk": false
    },
    {
        "value": "514604",
        "label": "Fish & Seafood-Brokers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512054",
        "yellow_page_code": "32309",
        "kiosk": false
    },
    {
        "value": "514605",
        "label": "Fish Buyers-Fresh Water (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42446004",
        "yellow_page_code": "32619",
        "kiosk": false
    },
    {
        "value": "514701",
        "label": "Butchering (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31161202",
        "yellow_page_code": "11900, 23719, 11898",
        "kiosk": false
    },
    {
        "value": "514702",
        "label": "Meat Jobbers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42447003",
        "yellow_page_code": "50803, 50814",
        "kiosk": false
    },
    {
        "value": "514703",
        "label": "Meat Brokers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42447002",
        "yellow_page_code": "50800",
        "kiosk": false
    },
    {
        "value": "514704",
        "label": "Meat-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42447005",
        "yellow_page_code": "50908, 93417",
        "kiosk": false
    },
    {
        "value": "514705",
        "label": "Sausages-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42447007",
        "yellow_page_code": "74101",
        "kiosk": false
    },
    {
        "value": "514706",
        "label": "Pork Dealers-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42447006",
        "yellow_page_code": "67407",
        "kiosk": false
    },
    {
        "value": "514707",
        "label": "Frankfurters & Rolls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42447001",
        "yellow_page_code": "34309",
        "kiosk": false
    },
    {
        "value": "514708",
        "label": "Bacon & Ham Curers & Suppliers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42447008",
        "yellow_page_code": "05618",
        "kiosk": false
    },
    {
        "value": "514711",
        "label": "Meat-Kosher-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42447004",
        "yellow_page_code": "50807",
        "kiosk": false
    },
    {
        "value": "514801",
        "label": "Fruits & Vegetables-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42448009",
        "yellow_page_code": "34800, 68505, 38903",
        "kiosk": false
    },
    {
        "value": "514802",
        "label": "Potatoes-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42448011",
        "yellow_page_code": "67608, 67516, 67612, 67603, 67522",
        "kiosk": false
    },
    {
        "value": "514803",
        "label": "Produce-Brokers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512057",
        "yellow_page_code": "68507",
        "kiosk": false
    },
    {
        "value": "514804",
        "label": "Fruits & Vegetables-Brokers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512056",
        "yellow_page_code": "18008, 34706",
        "kiosk": false
    },
    {
        "value": "514805",
        "label": "Fruits & Vegetables-Growers & Shippers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42448008",
        "yellow_page_code": "34700, 67523",
        "kiosk": false
    },
    {
        "value": "514806",
        "label": "Citrus Buyers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42448004",
        "yellow_page_code": "18006",
        "kiosk": false
    },
    {
        "value": "514807",
        "label": "Citrus Fruits (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42448006",
        "yellow_page_code": "18100",
        "kiosk": false
    },
    {
        "value": "514808",
        "label": "Bananas-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42448002",
        "yellow_page_code": "06305",
        "kiosk": false
    },
    {
        "value": "514809",
        "label": "Citrus Fruit Products-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42448005",
        "yellow_page_code": "18016",
        "kiosk": false
    },
    {
        "value": "514810",
        "label": "Fruit Baskets-Gift-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42448007",
        "yellow_page_code": "34613, 30542",
        "kiosk": false
    },
    {
        "value": "514811",
        "label": "Apple Brokers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512055",
        "yellow_page_code": "02813",
        "kiosk": false
    },
    {
        "value": "514812",
        "label": "Tomatoes-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42448012",
        "yellow_page_code": "86808",
        "kiosk": false
    },
    {
        "value": "514813",
        "label": "Apple Shippers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42448001",
        "yellow_page_code": "02817",
        "kiosk": false
    },
    {
        "value": "514814",
        "label": "Cherries-Maraschino (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42448003",
        "yellow_page_code": "15103",
        "kiosk": false
    },
    {
        "value": "514815",
        "label": "Gardens-U-Pick (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42448010",
        "yellow_page_code": "36310",
        "kiosk": false
    },
    {
        "value": "514901",
        "label": "Molasses (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449041",
        "yellow_page_code": "53400",
        "kiosk": false
    },
    {
        "value": "514902",
        "label": "Bakers-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449003",
        "yellow_page_code": "06006, 59597",
        "kiosk": false
    },
    {
        "value": "514903",
        "label": "Baby Food (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449001",
        "yellow_page_code": "05602",
        "kiosk": false
    },
    {
        "value": "514904",
        "label": "Chili & Chili Products (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449009",
        "yellow_page_code": "15301",
        "kiosk": false
    },
    {
        "value": "514905",
        "label": "Coffee & Tea-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449015",
        "yellow_page_code": "19303, 19317",
        "kiosk": false
    },
    {
        "value": "514906",
        "label": "Chocolate & Cocoa (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449011",
        "yellow_page_code": "15600",
        "kiosk": false
    },
    {
        "value": "514907",
        "label": "Commission Merchants (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449018",
        "yellow_page_code": "20005",
        "kiosk": false
    },
    {
        "value": "514908",
        "label": "Coffee Roasting (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449017",
        "yellow_page_code": "19305",
        "kiosk": false
    },
    {
        "value": "514909",
        "label": "Condiments & Sauces (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449019",
        "yellow_page_code": "20808, 41826",
        "kiosk": false
    },
    {
        "value": "514910",
        "label": "Pet Foods-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449047",
        "yellow_page_code": "25307, 60406, 33720",
        "kiosk": false
    },
    {
        "value": "514911",
        "label": "Foods-Foreign (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449029",
        "yellow_page_code": "33902",
        "kiosk": false
    },
    {
        "value": "514912",
        "label": "Fruits-Dried (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449030",
        "yellow_page_code": "34802",
        "kiosk": false
    },
    {
        "value": "514913",
        "label": "Flour (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449026",
        "yellow_page_code": "33600",
        "kiosk": false
    },
    {
        "value": "514914",
        "label": "Health Food Products-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449032",
        "yellow_page_code": "40505, 40425, 56019, 24843",
        "kiosk": false
    },
    {
        "value": "514915",
        "label": "Malts & Hops (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449037",
        "yellow_page_code": "49801",
        "kiosk": false
    },
    {
        "value": "514916",
        "label": "Honey (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449034",
        "yellow_page_code": "41402",
        "kiosk": false
    },
    {
        "value": "514917",
        "label": "Yeast (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449067",
        "yellow_page_code": "94800",
        "kiosk": false
    },
    {
        "value": "514918",
        "label": "Oils-Vegetable (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449044",
        "yellow_page_code": "57406, 29967",
        "kiosk": false
    },
    {
        "value": "514919",
        "label": "Olives (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449045",
        "yellow_page_code": "57502",
        "kiosk": false
    },
    {
        "value": "514920",
        "label": "Pizza-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449051",
        "yellow_page_code": "66003, 29984",
        "kiosk": false
    },
    {
        "value": "514921",
        "label": "Rice-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449055",
        "yellow_page_code": "72106",
        "kiosk": false
    },
    {
        "value": "514922",
        "label": "Pizza Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449050",
        "yellow_page_code": "65914, 65916",
        "kiosk": false
    },
    {
        "value": "514923",
        "label": "Salads (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449056",
        "yellow_page_code": "73502",
        "kiosk": false
    },
    {
        "value": "514924",
        "label": "Soft Drinks (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449059",
        "yellow_page_code": "79108",
        "kiosk": false
    },
    {
        "value": "514925",
        "label": "Sandwiches-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449058",
        "yellow_page_code": "73807",
        "kiosk": false
    },
    {
        "value": "514926",
        "label": "Tea-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449064",
        "yellow_page_code": "84204",
        "kiosk": false
    },
    {
        "value": "514927",
        "label": "Sugar-Brokers & Wholesalers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512059",
        "yellow_page_code": "82200, 29858",
        "kiosk": false
    },
    {
        "value": "514928",
        "label": "Tortillas (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449065",
        "yellow_page_code": "87306",
        "kiosk": false
    },
    {
        "value": "514929",
        "label": "Clams (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449013",
        "yellow_page_code": "18207",
        "kiosk": false
    },
    {
        "value": "514930",
        "label": "Pasta (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449046",
        "yellow_page_code": "59605, 59598",
        "kiosk": false
    },
    {
        "value": "514931",
        "label": "Soybean Products (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449060",
        "yellow_page_code": "79604",
        "kiosk": false
    },
    {
        "value": "514932",
        "label": "Flavoring Extracts (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449024",
        "yellow_page_code": "33002, 30445",
        "kiosk": false
    },
    {
        "value": "514933",
        "label": "Mayonnaise & Salad Dressings (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449039",
        "yellow_page_code": "50700",
        "kiosk": false
    },
    {
        "value": "514934",
        "label": "Tamales (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449062",
        "yellow_page_code": "83407",
        "kiosk": false
    },
    {
        "value": "514935",
        "label": "Ravioli (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449054",
        "yellow_page_code": "70509",
        "kiosk": false
    },
    {
        "value": "514936",
        "label": "Corn Products (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449021",
        "yellow_page_code": "21706",
        "kiosk": false
    },
    {
        "value": "514937",
        "label": "Beverages (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449005",
        "yellow_page_code": "07902, 07816, 07516, 92977, 79117, 34589, 59531",
        "kiosk": false
    },
    {
        "value": "514938",
        "label": "Spices-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449061",
        "yellow_page_code": "79907, 29851",
        "kiosk": false
    },
    {
        "value": "514939",
        "label": "Food Service-Distributors (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512058",
        "yellow_page_code": "33908",
        "kiosk": false
    },
    {
        "value": "514940",
        "label": "Bagels-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449002",
        "yellow_page_code": "05712",
        "kiosk": false
    },
    {
        "value": "514941",
        "label": "Barbecue-Wholesale (Sauce)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449004",
        "yellow_page_code": "06507",
        "kiosk": false
    },
    {
        "value": "514942",
        "label": "Bird Feed-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449006",
        "yellow_page_code": "08302, 01235",
        "kiosk": false
    },
    {
        "value": "514943",
        "label": "Canned Goods-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449008",
        "yellow_page_code": "12811",
        "kiosk": false
    },
    {
        "value": "514944",
        "label": "Chinese Foods-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449010",
        "yellow_page_code": "15435",
        "kiosk": false
    },
    {
        "value": "514945",
        "label": "Coffee Break Service & Supplies-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449016",
        "yellow_page_code": "19209",
        "kiosk": false
    },
    {
        "value": "514946",
        "label": "Doughnuts-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449023",
        "yellow_page_code": "25734",
        "kiosk": false
    },
    {
        "value": "514947",
        "label": "Food Specialties-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449028",
        "yellow_page_code": "33913, 33919, 38237",
        "kiosk": false
    },
    {
        "value": "514948",
        "label": "Maple Sugar & Syrup-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449038",
        "yellow_page_code": "49918",
        "kiosk": false
    },
    {
        "value": "514949",
        "label": "Natural Foods-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449042",
        "yellow_page_code": "55113",
        "kiosk": false
    },
    {
        "value": "514950",
        "label": "Pickles & Pickle Products-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449048",
        "yellow_page_code": "64911",
        "kiosk": false
    },
    {
        "value": "514951",
        "label": "Pies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449049",
        "yellow_page_code": "65111",
        "kiosk": false
    },
    {
        "value": "514952",
        "label": "Jams & Preserves (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449035",
        "yellow_page_code": "44102",
        "kiosk": false
    },
    {
        "value": "514953",
        "label": "Provisions-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449053",
        "yellow_page_code": "68707",
        "kiosk": false
    },
    {
        "value": "514954",
        "label": "Salt-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449057",
        "yellow_page_code": "73536",
        "kiosk": false
    },
    {
        "value": "514955",
        "label": "Wild Rice-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449066",
        "yellow_page_code": "93514",
        "kiosk": false
    },
    {
        "value": "514956",
        "label": "Flavoring Extracts-Raw Materials-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449025",
        "yellow_page_code": "33001",
        "kiosk": false
    },
    {
        "value": "514957",
        "label": "Food Facilities (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449027",
        "yellow_page_code": "33709, 33828, 33821",
        "kiosk": false
    },
    {
        "value": "514958",
        "label": "Cocoa (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449014",
        "yellow_page_code": "19207",
        "kiosk": false
    },
    {
        "value": "514959",
        "label": "Cookies & Crackers-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449020",
        "yellow_page_code": "21410",
        "kiosk": false
    },
    {
        "value": "514960",
        "label": "Tea Bag Packers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449063",
        "yellow_page_code": "84109",
        "kiosk": false
    },
    {
        "value": "514961",
        "label": "Distributor-Groceries (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449022",
        "yellow_page_code": "25113",
        "kiosk": false
    },
    {
        "value": "514962",
        "label": "Cider-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449012",
        "yellow_page_code": "15806",
        "kiosk": false
    },
    {
        "value": "514963",
        "label": "Milk-Powdered (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449040",
        "yellow_page_code": "52312",
        "kiosk": false
    },
    {
        "value": "514964",
        "label": "Italian Food Products-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449052",
        "yellow_page_code": "44010",
        "kiosk": false
    },
    {
        "value": "514965",
        "label": "Malted Milk (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449036",
        "yellow_page_code": "49813",
        "kiosk": false
    },
    {
        "value": "514966",
        "label": "Homeopathic Suppliers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449033",
        "yellow_page_code": "41419",
        "kiosk": false
    },
    {
        "value": "514967",
        "label": "Box Lunches-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449007",
        "yellow_page_code": "09809",
        "kiosk": false
    },
    {
        "value": "514968",
        "label": "Oils-Edible (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449043",
        "yellow_page_code": "57203",
        "kiosk": false
    },
    {
        "value": "514969",
        "label": "Olive Oil Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31122516",
        "yellow_page_code": "57501, 57303",
        "kiosk": false
    },
    {
        "value": "514970",
        "label": "Baby Foods Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449068",
        "yellow_page_code": "05525",
        "kiosk": false
    },
    {
        "value": "514999",
        "label": "Groceries & Related Products NEC (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42449031",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "515301",
        "label": "Grain-Dealers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42451002",
        "yellow_page_code": "38602, 38520, 38718",
        "kiosk": false
    },
    {
        "value": "515302",
        "label": "Grain Brokers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512061",
        "yellow_page_code": "38508",
        "kiosk": false
    },
    {
        "value": "515303",
        "label": "Grain Exchanges (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42451001",
        "yellow_page_code": "38704",
        "kiosk": false
    },
    {
        "value": "515304",
        "label": "Bean Brokers & Dealers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512060",
        "yellow_page_code": "07109, 07119",
        "kiosk": false
    },
    {
        "value": "515305",
        "label": "Seeds-Warehouse (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42451003",
        "yellow_page_code": "75335",
        "kiosk": false
    },
    {
        "value": "515306",
        "label": "Grain Packaging (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42451004",
        "yellow_page_code": "38519",
        "kiosk": false
    },
    {
        "value": "515307",
        "label": "Grain Merchants & Shippers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42451005",
        "yellow_page_code": "38516",
        "kiosk": false
    },
    {
        "value": "515308",
        "label": "Grain & Field Bean (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42451006",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "515401",
        "label": "Livestock Buyers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42452005",
        "yellow_page_code": "48106",
        "kiosk": false
    },
    {
        "value": "515402",
        "label": "Livestock Auction Markets (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42452004",
        "yellow_page_code": "48102, 73507",
        "kiosk": false
    },
    {
        "value": "515403",
        "label": "Livestock-Dealers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42452008",
        "yellow_page_code": "48200, 30490",
        "kiosk": false
    },
    {
        "value": "515404",
        "label": "Livestock Commission (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42452006",
        "yellow_page_code": "48402",
        "kiosk": false
    },
    {
        "value": "515405",
        "label": "Livestock Appraisers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42452003",
        "yellow_page_code": "48105",
        "kiosk": false
    },
    {
        "value": "515406",
        "label": "Cattle Companies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42452001",
        "yellow_page_code": "14204",
        "kiosk": false
    },
    {
        "value": "515407",
        "label": "Drovers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42452002",
        "yellow_page_code": "26511",
        "kiosk": false
    },
    {
        "value": "515408",
        "label": "Livestock Shows & Sales (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42452007",
        "yellow_page_code": "48315",
        "kiosk": false
    },
    {
        "value": "515901",
        "label": "Peanuts (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42459012",
        "yellow_page_code": "60103",
        "kiosk": false
    },
    {
        "value": "515902",
        "label": "Tobacco Leaf-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42459016",
        "yellow_page_code": "86702",
        "kiosk": false
    },
    {
        "value": "515903",
        "label": "Peanut Products (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42459011",
        "yellow_page_code": "60102",
        "kiosk": false
    },
    {
        "value": "515904",
        "label": "Cotton Buyers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42459001",
        "yellow_page_code": "21903",
        "kiosk": false
    },
    {
        "value": "515905",
        "label": "Wool (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42459017",
        "yellow_page_code": "94400",
        "kiosk": false
    },
    {
        "value": "515906",
        "label": "Feathers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42459003",
        "yellow_page_code": "31104, 31091",
        "kiosk": false
    },
    {
        "value": "515907",
        "label": "Horse Dealers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42459008",
        "yellow_page_code": "41500, 41535, 30332",
        "kiosk": false
    },
    {
        "value": "515908",
        "label": "Hides (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42459005",
        "yellow_page_code": "41002",
        "kiosk": false
    },
    {
        "value": "515909",
        "label": "Pecans (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42459013",
        "yellow_page_code": "60100",
        "kiosk": false
    },
    {
        "value": "515910",
        "label": "Spinning & Spinning Wheel Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42459015",
        "yellow_page_code": "79809",
        "kiosk": false
    },
    {
        "value": "515911",
        "label": "Hair-Animal (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42459004",
        "yellow_page_code": "39609",
        "kiosk": false
    },
    {
        "value": "515912",
        "label": "Cottonseed Products-Brokers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512062",
        "yellow_page_code": "22017",
        "kiosk": false
    },
    {
        "value": "515913",
        "label": "Home Food Products-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42459006",
        "yellow_page_code": "41210",
        "kiosk": false
    },
    {
        "value": "515914",
        "label": "Silk-Raw (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42459014",
        "yellow_page_code": "77700",
        "kiosk": false
    },
    {
        "value": "515915",
        "label": "Mohair (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42459009",
        "yellow_page_code": "53403",
        "kiosk": false
    },
    {
        "value": "515916",
        "label": "Wool Tops (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42459019",
        "yellow_page_code": "94334",
        "kiosk": false
    },
    {
        "value": "515917",
        "label": "Wool Brokers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512063",
        "yellow_page_code": "94401",
        "kiosk": false
    },
    {
        "value": "515918",
        "label": "Hops (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42459007",
        "yellow_page_code": "41416",
        "kiosk": false
    },
    {
        "value": "515919",
        "label": "Packing House By-Products (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42459010",
        "yellow_page_code": "58405",
        "kiosk": false
    },
    {
        "value": "515920",
        "label": "Wool Noils (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42459018",
        "yellow_page_code": "94333",
        "kiosk": false
    },
    {
        "value": "515999",
        "label": "Farm Products-Raw Materials NEC (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42459002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "516201",
        "label": "Plastics-Reinforced (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42461009",
        "yellow_page_code": "66406",
        "kiosk": false
    },
    {
        "value": "516202",
        "label": "Plastics-Products-Finished-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42461007",
        "yellow_page_code": "66402, 66217, 66244",
        "kiosk": false
    },
    {
        "value": "516203",
        "label": "Plastics-Rods/Tubes/Sheets & Etc (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42461010",
        "yellow_page_code": "66501, 76141, 66239",
        "kiosk": false
    },
    {
        "value": "516204",
        "label": "Credit Cards-Plastic Metal Etc-Distr",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512064",
        "yellow_page_code": "22434, 22415",
        "kiosk": false
    },
    {
        "value": "516205",
        "label": "Laminations-Plastic Paper Etc-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42461001",
        "yellow_page_code": "45604, 66229",
        "kiosk": false
    },
    {
        "value": "516206",
        "label": "Plastic Molders-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42461005",
        "yellow_page_code": "66307",
        "kiosk": false
    },
    {
        "value": "516207",
        "label": "Plastics-Raw Mtrls-Powders Liquid-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42461008",
        "yellow_page_code": "66405, 30079, 29943",
        "kiosk": false
    },
    {
        "value": "516208",
        "label": "Plastics-Fabrctng/Fnshng/Decor-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42461006",
        "yellow_page_code": "66211",
        "kiosk": false
    },
    {
        "value": "516209",
        "label": "Vinyl-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42461012",
        "yellow_page_code": "91226",
        "kiosk": false
    },
    {
        "value": "516210",
        "label": "Pipe-Plastic-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42461004",
        "yellow_page_code": "65802, 66234",
        "kiosk": false
    },
    {
        "value": "516211",
        "label": "Silage Coverings (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42461011",
        "yellow_page_code": "77535",
        "kiosk": false
    },
    {
        "value": "516212",
        "label": "Linings-Container (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42461003",
        "yellow_page_code": "47700",
        "kiosk": false
    },
    {
        "value": "516213",
        "label": "Linings-Bag Barrel & Etc (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42461002",
        "yellow_page_code": "47703",
        "kiosk": false
    },
    {
        "value": "516901",
        "label": "Ice Melting Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469046",
        "yellow_page_code": "42506",
        "kiosk": false
    },
    {
        "value": "516902",
        "label": "Glass Coating & Tinting Materials (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469043",
        "yellow_page_code": "37504",
        "kiosk": false
    },
    {
        "value": "516903",
        "label": "Explosives (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469037",
        "yellow_page_code": "08407, 30204, 08410",
        "kiosk": false
    },
    {
        "value": "516904",
        "label": "Absorbents (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469001",
        "yellow_page_code": "00003, 56406, 00028",
        "kiosk": false
    },
    {
        "value": "516905",
        "label": "Floor Treatment Compounds (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469038",
        "yellow_page_code": "33404",
        "kiosk": false
    },
    {
        "value": "516906",
        "label": "Ethanol (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32519301",
        "yellow_page_code": "29913",
        "kiosk": false
    },
    {
        "value": "516907",
        "label": "Helium (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469045",
        "yellow_page_code": "40907, 40929, 40928",
        "kiosk": false
    },
    {
        "value": "516908",
        "label": "Acetylene (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469002",
        "yellow_page_code": "00106",
        "kiosk": false
    },
    {
        "value": "516909",
        "label": "Oil Additives-Distributors (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512065",
        "yellow_page_code": "56411",
        "kiosk": false
    },
    {
        "value": "516910",
        "label": "Acids (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "516911",
        "label": "Alcohol (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469005",
        "yellow_page_code": "01809, 01819",
        "kiosk": false
    },
    {
        "value": "516912",
        "label": "Adhesives & Glues (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469004",
        "yellow_page_code": "00306, 37907",
        "kiosk": false
    },
    {
        "value": "516913",
        "label": "Argon (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469008",
        "yellow_page_code": "03304",
        "kiosk": false
    },
    {
        "value": "516914",
        "label": "Ammonia (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469006",
        "yellow_page_code": "02200",
        "kiosk": false
    },
    {
        "value": "516915",
        "label": "Carbonic Gas (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469018",
        "yellow_page_code": "13309",
        "kiosk": false
    },
    {
        "value": "516916",
        "label": "Chemicals (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469020",
        "yellow_page_code": "15006, 14914, 14910, 07814, 14917, 42683",
        "kiosk": false
    },
    {
        "value": "516917",
        "label": "Chemicals-Storage & Handling (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469024",
        "yellow_page_code": "15007, 81838",
        "kiosk": false
    },
    {
        "value": "516918",
        "label": "Concrete Curing & Treating Mtrl (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469031",
        "yellow_page_code": "20602",
        "kiosk": false
    },
    {
        "value": "516919",
        "label": "Concrete Additives (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469030",
        "yellow_page_code": "20406, 20407",
        "kiosk": false
    },
    {
        "value": "516920",
        "label": "Oxygen (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469061",
        "yellow_page_code": "58204, 58214, 58225",
        "kiosk": false
    },
    {
        "value": "516921",
        "label": "Resins (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469065",
        "yellow_page_code": "71806, 39316",
        "kiosk": false
    },
    {
        "value": "516922",
        "label": "Polyurethane Products-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469064",
        "yellow_page_code": "67206",
        "kiosk": false
    },
    {
        "value": "516923",
        "label": "Sealers-Asphalt/Concrete & Etc (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469067",
        "yellow_page_code": "75108",
        "kiosk": false
    },
    {
        "value": "516924",
        "label": "Rust Preventives & Removers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469066",
        "yellow_page_code": "73208",
        "kiosk": false
    },
    {
        "value": "516925",
        "label": "Cleaning Compounds (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469028",
        "yellow_page_code": "18405, 18406",
        "kiosk": false
    },
    {
        "value": "516926",
        "label": "Wax (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469073",
        "yellow_page_code": "92702",
        "kiosk": false
    },
    {
        "value": "516927",
        "label": "Deodorants (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469033",
        "yellow_page_code": "24105",
        "kiosk": false
    },
    {
        "value": "516928",
        "label": "Chemical Plant-Equipment & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469019",
        "yellow_page_code": "15002",
        "kiosk": false
    },
    {
        "value": "516929",
        "label": "Carbon Products (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469016",
        "yellow_page_code": "13301",
        "kiosk": false
    },
    {
        "value": "516930",
        "label": "Degreasing Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469032",
        "yellow_page_code": "23705",
        "kiosk": false
    },
    {
        "value": "516931",
        "label": "Nickel (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469051",
        "yellow_page_code": "55602",
        "kiosk": false
    },
    {
        "value": "516932",
        "label": "Zinc (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469075",
        "yellow_page_code": "94902",
        "kiosk": false
    },
    {
        "value": "516933",
        "label": "Oils-Essential (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469058",
        "yellow_page_code": "57204, 29820",
        "kiosk": false
    },
    {
        "value": "516934",
        "label": "Oil Treating Compounds (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469055",
        "yellow_page_code": "56806, 57015",
        "kiosk": false
    },
    {
        "value": "516935",
        "label": "Silicones (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469068",
        "yellow_page_code": "77602",
        "kiosk": false
    },
    {
        "value": "516936",
        "label": "Dyes & Dyestuffs (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469036",
        "yellow_page_code": "27004",
        "kiosk": false
    },
    {
        "value": "516937",
        "label": "Solvents (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469070",
        "yellow_page_code": "79402",
        "kiosk": false
    },
    {
        "value": "516938",
        "label": "Odor Neutralizers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469053",
        "yellow_page_code": "56202",
        "kiosk": false
    },
    {
        "value": "516939",
        "label": "Floor Waxing Polishing/Clng Mtrls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469039",
        "yellow_page_code": "33407, 92730",
        "kiosk": false
    },
    {
        "value": "516940",
        "label": "Dishwashing Compounds (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469034",
        "yellow_page_code": "24906",
        "kiosk": false
    },
    {
        "value": "516941",
        "label": "Carbide (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469014",
        "yellow_page_code": "13204",
        "kiosk": false
    },
    {
        "value": "516942",
        "label": "Calcium Chloride (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469013",
        "yellow_page_code": "12306, 12314",
        "kiosk": false
    },
    {
        "value": "516943",
        "label": "Chromium (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469027",
        "yellow_page_code": "15702",
        "kiosk": false
    },
    {
        "value": "516944",
        "label": "Undercoating/Rustproof Mtrl/Supl (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469009",
        "yellow_page_code": "89818",
        "kiosk": false
    },
    {
        "value": "516945",
        "label": "Paint-Manufacturing Materials (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469062",
        "yellow_page_code": "58603, 29972",
        "kiosk": false
    },
    {
        "value": "516946",
        "label": "Chlorine (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469026",
        "yellow_page_code": "15508",
        "kiosk": false
    },
    {
        "value": "516947",
        "label": "Carbon Dioxide (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469015",
        "yellow_page_code": "13308",
        "kiosk": false
    },
    {
        "value": "516948",
        "label": "Gasoline Additive-Distributors (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469042",
        "yellow_page_code": "36806",
        "kiosk": false
    },
    {
        "value": "516949",
        "label": "Oils-Fuel-Treatment Compounds (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469059",
        "yellow_page_code": "57407",
        "kiosk": false
    },
    {
        "value": "516950",
        "label": "Nitrogen (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469052",
        "yellow_page_code": "55606",
        "kiosk": false
    },
    {
        "value": "516951",
        "label": "Lubricants-Synthetic (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469049",
        "yellow_page_code": "48711, 56540",
        "kiosk": false
    },
    {
        "value": "516952",
        "label": "Chemicals-Industrial (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469023",
        "yellow_page_code": "14909",
        "kiosk": false
    },
    {
        "value": "516953",
        "label": "Tear Gas (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469076",
        "yellow_page_code": "84190",
        "kiosk": false
    },
    {
        "value": "516954",
        "label": "Epoxy Rock Stone (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469077",
        "yellow_page_code": "29723",
        "kiosk": false
    },
    {
        "value": "516955",
        "label": "Anti-Freeze Compounds-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469007",
        "yellow_page_code": "02803",
        "kiosk": false
    },
    {
        "value": "516956",
        "label": "Coatings-Protective-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469029",
        "yellow_page_code": "19105, 30057",
        "kiosk": false
    },
    {
        "value": "516957",
        "label": "Dyers-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469035",
        "yellow_page_code": "27008",
        "kiosk": false
    },
    {
        "value": "516958",
        "label": "Bleaching Compounds-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469010",
        "yellow_page_code": "08415",
        "kiosk": false
    },
    {
        "value": "516959",
        "label": "Boiler Compounds (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469011",
        "yellow_page_code": "08908",
        "kiosk": false
    },
    {
        "value": "516960",
        "label": "Carbonators (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469017",
        "yellow_page_code": "13400",
        "kiosk": false
    },
    {
        "value": "516961",
        "label": "Magnesite (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469050",
        "yellow_page_code": "49409",
        "kiosk": false
    },
    {
        "value": "516962",
        "label": "Oils-Synthetic (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469060",
        "yellow_page_code": "57403",
        "kiosk": false
    },
    {
        "value": "516963",
        "label": "Textile Dyers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469071",
        "yellow_page_code": "85312",
        "kiosk": false
    },
    {
        "value": "516964",
        "label": "Brick Preservatives (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469012",
        "yellow_page_code": "10601",
        "kiosk": false
    },
    {
        "value": "516965",
        "label": "Gases (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469041",
        "yellow_page_code": "36715, 30354",
        "kiosk": false
    },
    {
        "value": "516966",
        "label": "Chemicals-Bought (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469022",
        "yellow_page_code": "14911",
        "kiosk": false
    },
    {
        "value": "516967",
        "label": "Chemicals-Surplus Stock Buyers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469025",
        "yellow_page_code": "15005",
        "kiosk": false
    },
    {
        "value": "516968",
        "label": "Polishes-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469063",
        "yellow_page_code": "67115",
        "kiosk": false
    },
    {
        "value": "516969",
        "label": "Sodium Silicate (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469069",
        "yellow_page_code": "79107",
        "kiosk": false
    },
    {
        "value": "516970",
        "label": "Washing Compounds (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469072",
        "yellow_page_code": "91611",
        "kiosk": false
    },
    {
        "value": "516971",
        "label": "Wood Restoring Products (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469074",
        "yellow_page_code": "94309, 94230",
        "kiosk": false
    },
    {
        "value": "516972",
        "label": "Gas Compressor Stations (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469040",
        "yellow_page_code": "36601",
        "kiosk": false
    },
    {
        "value": "516973",
        "label": "Liquid Air (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469047",
        "yellow_page_code": "47802",
        "kiosk": false
    },
    {
        "value": "516974",
        "label": "Lubricants-Dry (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469048",
        "yellow_page_code": "48712",
        "kiosk": false
    },
    {
        "value": "516975",
        "label": "Oils-Emulsion (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469057",
        "yellow_page_code": "57205",
        "kiosk": false
    },
    {
        "value": "516976",
        "label": "Oil Well Drilling Mud & Additives-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469056",
        "yellow_page_code": "57011",
        "kiosk": false
    },
    {
        "value": "516977",
        "label": "Gums (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469044",
        "yellow_page_code": "39311",
        "kiosk": false
    },
    {
        "value": "516978",
        "label": "Oil Field Chemicals (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469054",
        "yellow_page_code": "56512",
        "kiosk": false
    },
    {
        "value": "516979",
        "label": "Storage Chemical-Hazardous Mtrls Equip",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469078",
        "yellow_page_code": "81745",
        "kiosk": false
    },
    {
        "value": "516999",
        "label": "Chemicals & Allied Products NEC (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42469021",
        "yellow_page_code": "14915, 21715, 15011, 37220, 21725",
        "kiosk": false
    },
    {
        "value": "517101",
        "label": "Oil-Bulk-Dealers-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42471004",
        "yellow_page_code": "56502, 11415",
        "kiosk": false
    },
    {
        "value": "517102",
        "label": "Gasoline & Oil Bulk Stations (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42471002",
        "yellow_page_code": "36817, 36820, 36826",
        "kiosk": false
    },
    {
        "value": "517103",
        "label": "Oil Products (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42471003",
        "yellow_page_code": "56704",
        "kiosk": false
    },
    {
        "value": "517104",
        "label": "Gasoline-Diesel Bulk (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42471001",
        "yellow_page_code": "36818",
        "kiosk": false
    },
    {
        "value": "517198",
        "label": "Petroleum Bulk Stations-Terminals (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42471005",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "517201",
        "label": "Petroleum Products (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42472020",
        "yellow_page_code": "60604, 83613",
        "kiosk": false
    },
    {
        "value": "517202",
        "label": "Service Station Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312076",
        "yellow_page_code": "36808, 75606",
        "kiosk": false
    },
    {
        "value": "517203",
        "label": "Diesel Fuel (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42472002",
        "yellow_page_code": "24802",
        "kiosk": false
    },
    {
        "value": "517204",
        "label": "Service Station Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312077",
        "yellow_page_code": "75608",
        "kiosk": false
    },
    {
        "value": "517205",
        "label": "Gas-Indstrl/Med-Cylinder & Bulk (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42472004",
        "yellow_page_code": "36706, 36733, 42948",
        "kiosk": false
    },
    {
        "value": "517206",
        "label": "Oils-Fuel (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42472014",
        "yellow_page_code": "34805, 34900, 57300, 56421, 34823, 40841, 34828, 34812, 34832, 57213, 30036",
        "kiosk": false
    },
    {
        "value": "517207",
        "label": "Gas (Lpg) Bottled Bulk Equip/Supl (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42472003",
        "yellow_page_code": "36709, 36726, 36731, 04410, 48725",
        "kiosk": false
    },
    {
        "value": "517208",
        "label": "Gas-Liquefied Petro-Bttld/Bulk (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42472005",
        "yellow_page_code": "36712, 36708, 36722, 36725, 09506, 36728, 36730, 57206, 36780, 41383",
        "kiosk": false
    },
    {
        "value": "517209",
        "label": "Greases (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42472007",
        "yellow_page_code": "38901",
        "kiosk": false
    },
    {
        "value": "517210",
        "label": "Gasoline & Oil-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42472006",
        "yellow_page_code": "36900, 36729, 36410",
        "kiosk": false
    },
    {
        "value": "517211",
        "label": "Lubricants (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42472009",
        "yellow_page_code": "48704",
        "kiosk": false
    },
    {
        "value": "517212",
        "label": "Oil Marketers & Distributors (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512068",
        "yellow_page_code": "56701, 56515, 56419",
        "kiosk": false
    },
    {
        "value": "517213",
        "label": "Oils-Industrial (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42472016",
        "yellow_page_code": "57306, 57214",
        "kiosk": false
    },
    {
        "value": "517214",
        "label": "Oils-Lubricating-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42472017",
        "yellow_page_code": "57308, 48714, 29966",
        "kiosk": false
    },
    {
        "value": "517215",
        "label": "Oils-Petroleum (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42472018",
        "yellow_page_code": "57400",
        "kiosk": false
    },
    {
        "value": "517217",
        "label": "Kerosene-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42472008",
        "yellow_page_code": "44802",
        "kiosk": false
    },
    {
        "value": "517218",
        "label": "Automobile-Oil Refiners-Distr (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512066",
        "yellow_page_code": "04805",
        "kiosk": false
    },
    {
        "value": "517219",
        "label": "Lubricating Compounds (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42472010",
        "yellow_page_code": "48705",
        "kiosk": false
    },
    {
        "value": "517220",
        "label": "Oils-Hydraulic (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42472015",
        "yellow_page_code": "57304",
        "kiosk": false
    },
    {
        "value": "517221",
        "label": "Petrolatum (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42472019",
        "yellow_page_code": "60601",
        "kiosk": false
    },
    {
        "value": "517222",
        "label": "Oils & Greases (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42472012",
        "yellow_page_code": "57109",
        "kiosk": false
    },
    {
        "value": "517223",
        "label": "Aviation Fuel (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42472001",
        "yellow_page_code": "05317, 01613, 01514, 34822",
        "kiosk": false
    },
    {
        "value": "517224",
        "label": "Oils-Diesel (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42472013",
        "yellow_page_code": "57207",
        "kiosk": false
    },
    {
        "value": "517225",
        "label": "Naphtha (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42472011",
        "yellow_page_code": "55106",
        "kiosk": false
    },
    {
        "value": "517226",
        "label": "Racing Fuels (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42472023",
        "yellow_page_code": "69520, 34909",
        "kiosk": false
    },
    {
        "value": "517227",
        "label": "Biodiesel Fuel (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42472022",
        "yellow_page_code": "08215",
        "kiosk": false
    },
    {
        "value": "517228",
        "label": "Formation-Installations Equip-Svc (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42472021",
        "yellow_page_code": "34115",
        "kiosk": false
    },
    {
        "value": "517229",
        "label": "Fuels-Renewable (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42472024",
        "yellow_page_code": "34830",
        "kiosk": false
    },
    {
        "value": "517230",
        "label": "Petroleum Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42472025",
        "yellow_page_code": "60555",
        "kiosk": false
    },
    {
        "value": "517231",
        "label": "Biofuel (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512043",
        "yellow_page_code": "08229",
        "kiosk": false
    },
    {
        "value": "517232",
        "label": "Commercial Fueling Stations (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42472026",
        "yellow_page_code": "",
        "kiosk": true
    },
    {
        "value": "518101",
        "label": "Beer & Ale-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42481001",
        "yellow_page_code": "07508, 93814, 30052",
        "kiosk": false
    },
    {
        "value": "518201",
        "label": "Liquors-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42482003",
        "yellow_page_code": "47908, 30289, 30192",
        "kiosk": false
    },
    {
        "value": "518202",
        "label": "Distillers Representatives (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512069",
        "yellow_page_code": "25103",
        "kiosk": false
    },
    {
        "value": "518203",
        "label": "Wines-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42482005",
        "yellow_page_code": "93900",
        "kiosk": false
    },
    {
        "value": "518204",
        "label": "Wine Brokers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512070",
        "yellow_page_code": "93706",
        "kiosk": false
    },
    {
        "value": "518205",
        "label": "Distillery Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42482002",
        "yellow_page_code": "25200, 01820",
        "kiosk": false
    },
    {
        "value": "518206",
        "label": "Brewers Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42482001",
        "yellow_page_code": "10500, 07517",
        "kiosk": false
    },
    {
        "value": "518207",
        "label": "Wines-Sacramental (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42482004",
        "yellow_page_code": "93807",
        "kiosk": false
    },
    {
        "value": "518208",
        "label": "Wine & Distilled Alcoholic Beverage (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42482006",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "519101",
        "label": "Pest Control Supplies & Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42491024",
        "yellow_page_code": "60403, 60337, 85231",
        "kiosk": false
    },
    {
        "value": "519102",
        "label": "Farm Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42491007",
        "yellow_page_code": "30904, 70306, 30438, 30798",
        "kiosk": false
    },
    {
        "value": "519103",
        "label": "Phosphates-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42491025",
        "yellow_page_code": "60706",
        "kiosk": false
    },
    {
        "value": "519104",
        "label": "Agricultural Chemicals (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42491001",
        "yellow_page_code": "00803, 30806, 15009",
        "kiosk": false
    },
    {
        "value": "519105",
        "label": "Seeds & Bulbs-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42491027",
        "yellow_page_code": "75406, 75340, 75341, 11407",
        "kiosk": false
    },
    {
        "value": "519106",
        "label": "Alfalfa & Alfalfa Products (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42491002",
        "yellow_page_code": "01902",
        "kiosk": false
    },
    {
        "value": "519107",
        "label": "Beekeepers Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42491006",
        "yellow_page_code": "07504",
        "kiosk": false
    },
    {
        "value": "519108",
        "label": "Animal Health Products (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42491004",
        "yellow_page_code": "02502, 02416, 02417, 60425, 60527, 25390, 60422, 60407",
        "kiosk": false
    },
    {
        "value": "519109",
        "label": "Feed Concentrates & Supplements (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42491009",
        "yellow_page_code": "31106",
        "kiosk": false
    },
    {
        "value": "519110",
        "label": "Feed Grinding (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42491010",
        "yellow_page_code": "31200",
        "kiosk": false
    },
    {
        "value": "519111",
        "label": "Feed-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42491013",
        "yellow_page_code": "31206, 31107, 31210, 30326, 38613",
        "kiosk": false
    },
    {
        "value": "519112",
        "label": "Feed-Dealers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42491012",
        "yellow_page_code": "31108, 31217, 30440, 02159",
        "kiosk": false
    },
    {
        "value": "519113",
        "label": "Greenhouse Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42491016",
        "yellow_page_code": "38904, 66103, 66112",
        "kiosk": false
    },
    {
        "value": "519114",
        "label": "Fertilizers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42491015",
        "yellow_page_code": "31408",
        "kiosk": false
    },
    {
        "value": "519115",
        "label": "Hay & Alfalfa (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42491017",
        "yellow_page_code": "40400, 40337, 81998, 65319",
        "kiosk": false
    },
    {
        "value": "519116",
        "label": "Kennels Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42491022",
        "yellow_page_code": "44708, 60539",
        "kiosk": false
    },
    {
        "value": "519117",
        "label": "Insecticides-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42491021",
        "yellow_page_code": "43205",
        "kiosk": false
    },
    {
        "value": "519118",
        "label": "Fertilizer Plant Equipment (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42491014",
        "yellow_page_code": "31406",
        "kiosk": false
    },
    {
        "value": "519119",
        "label": "Livestock Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42491023",
        "yellow_page_code": "48202",
        "kiosk": false
    },
    {
        "value": "519120",
        "label": "Soil Conditioners (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42491028",
        "yellow_page_code": "79202",
        "kiosk": false
    },
    {
        "value": "519121",
        "label": "Feed Ingredients (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42491011",
        "yellow_page_code": "31202",
        "kiosk": false
    },
    {
        "value": "519122",
        "label": "Horse Furnishings-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42491018",
        "yellow_page_code": "41515",
        "kiosk": false
    },
    {
        "value": "519123",
        "label": "Insect Control Devices-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42491019",
        "yellow_page_code": "43212",
        "kiosk": false
    },
    {
        "value": "519124",
        "label": "Saddlery & Harness-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42491026",
        "yellow_page_code": "73314",
        "kiosk": false
    },
    {
        "value": "519125",
        "label": "Feed & Poultry Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42491008",
        "yellow_page_code": "31212, 67622",
        "kiosk": false
    },
    {
        "value": "519126",
        "label": "Animal Foods-Zoo & Circus & Etc (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42491003",
        "yellow_page_code": "02407",
        "kiosk": false
    },
    {
        "value": "519127",
        "label": "Animal Supplies-Zoo Circus Etc (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42491005",
        "yellow_page_code": "02600",
        "kiosk": false
    },
    {
        "value": "519128",
        "label": "Insecticide Bases (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42491020",
        "yellow_page_code": "43211",
        "kiosk": false
    },
    {
        "value": "519129",
        "label": "Straw Goods (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42491029",
        "yellow_page_code": "82005",
        "kiosk": false
    },
    {
        "value": "519130",
        "label": "Sunflower Seeds (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42459020",
        "yellow_page_code": "75333, 82203, 82255",
        "kiosk": false
    },
    {
        "value": "519132",
        "label": "Cattle Oilers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42491036",
        "yellow_page_code": "14118",
        "kiosk": false
    },
    {
        "value": "519133",
        "label": "Limestone-Agricultural (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "21231204",
        "yellow_page_code": "47504",
        "kiosk": false
    },
    {
        "value": "519134",
        "label": "Horse Supplements & Feeds ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44424030",
        "yellow_page_code": "41537",
        "kiosk": false
    },
    {
        "value": "519135",
        "label": "Seeds Crop Production (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42491030",
        "yellow_page_code": "75336",
        "kiosk": false
    },
    {
        "value": "519201",
        "label": "Magazines-Distributors (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512071",
        "yellow_page_code": "49400, 30076",
        "kiosk": false
    },
    {
        "value": "519202",
        "label": "Book Dealers-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42492002",
        "yellow_page_code": "09300",
        "kiosk": false
    },
    {
        "value": "519203",
        "label": "Newspaper Distributors (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42492006",
        "yellow_page_code": "55500, 55410, 55505",
        "kiosk": false
    },
    {
        "value": "519204",
        "label": "Bibles-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42492001",
        "yellow_page_code": "07916",
        "kiosk": false
    },
    {
        "value": "519205",
        "label": "Newspaper Dealers-Back Number (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42492005",
        "yellow_page_code": "55501",
        "kiosk": false
    },
    {
        "value": "519206",
        "label": "Magazines-Back Number (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42492004",
        "yellow_page_code": "49307",
        "kiosk": false
    },
    {
        "value": "519207",
        "label": "Business Publications (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42492003",
        "yellow_page_code": "11813",
        "kiosk": false
    },
    {
        "value": "519208",
        "label": "Book Periodical & Newspaper (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42492007",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "519301",
        "label": "Plants-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42493013",
        "yellow_page_code": "66100",
        "kiosk": false
    },
    {
        "value": "519302",
        "label": "Artificial Flowers-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42493002",
        "yellow_page_code": "03802, 30455",
        "kiosk": false
    },
    {
        "value": "519303",
        "label": "Artificial Flowers-Mtrls/Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42493001",
        "yellow_page_code": "03709",
        "kiosk": false
    },
    {
        "value": "519304",
        "label": "Florists-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42493006",
        "yellow_page_code": "33508, 33410, 57805, 33419, 33615, 42692",
        "kiosk": false
    },
    {
        "value": "519305",
        "label": "Florists-Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42493005",
        "yellow_page_code": "33504",
        "kiosk": false
    },
    {
        "value": "519306",
        "label": "Nurseries-Plants Trees & Etc-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42493011",
        "yellow_page_code": "55808, 55818, 55819, 55820, 55812, 55925, 29961, 29962, 55830",
        "kiosk": false
    },
    {
        "value": "519307",
        "label": "Flowers/Plants-Preserved Silk Dried-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42493010",
        "yellow_page_code": "33604, 33417",
        "kiosk": false
    },
    {
        "value": "519308",
        "label": "Flower Arranging Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42493007",
        "yellow_page_code": "33607",
        "kiosk": false
    },
    {
        "value": "519309",
        "label": "Flower/Plnt-Artfcl/Prsrvd-Mfr Supl",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42493008",
        "yellow_page_code": "33608",
        "kiosk": false
    },
    {
        "value": "519310",
        "label": "Flowers & Plants-Artificial-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42493009",
        "yellow_page_code": "66013, 33516",
        "kiosk": false
    },
    {
        "value": "519311",
        "label": "Artificial Flowers/Plants-Mfrs Supl-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42493003",
        "yellow_page_code": "03711, 33510, 30457",
        "kiosk": false
    },
    {
        "value": "519312",
        "label": "Sod & Sodding Service-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42493014",
        "yellow_page_code": "79007",
        "kiosk": false
    },
    {
        "value": "519313",
        "label": "Soils-Potting-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42493015",
        "yellow_page_code": "79211",
        "kiosk": false
    },
    {
        "value": "519314",
        "label": "Fern Products (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42493004",
        "yellow_page_code": "31402",
        "kiosk": false
    },
    {
        "value": "519315",
        "label": "Orchid Growers Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42493012",
        "yellow_page_code": "57807",
        "kiosk": false
    },
    {
        "value": "519316",
        "label": "Flower/Nursery Stock/Florists' Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42493016",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "519401",
        "label": "Tobacco Buyers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512072",
        "yellow_page_code": "86609",
        "kiosk": false
    },
    {
        "value": "519402",
        "label": "Cigar Cigarette & Tobacco Dealers-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42494001",
        "yellow_page_code": "18000, 86703, 86706, 29864",
        "kiosk": false
    },
    {
        "value": "519403",
        "label": "Snuff (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42494002",
        "yellow_page_code": "78805",
        "kiosk": false
    },
    {
        "value": "519404",
        "label": "Tobacco Rehandlers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42494003",
        "yellow_page_code": "86704",
        "kiosk": false
    },
    {
        "value": "519405",
        "label": "Smokers Articles-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42494004",
        "yellow_page_code": "78513",
        "kiosk": false
    },
    {
        "value": "519406",
        "label": "Tobacco Product & Electronic Cigarette (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42494005",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "519801",
        "label": "Wallpapers & Wallcoverings-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42495010",
        "yellow_page_code": "91504, 91337",
        "kiosk": false
    },
    {
        "value": "519802",
        "label": "Painters Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42495007",
        "yellow_page_code": "58702, 58636, 58640",
        "kiosk": false
    },
    {
        "value": "519803",
        "label": "Paint-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42495006",
        "yellow_page_code": "58700, 13079, 29971",
        "kiosk": false
    },
    {
        "value": "519804",
        "label": "Colors & Pigments (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42495001",
        "yellow_page_code": "19902",
        "kiosk": false
    },
    {
        "value": "519805",
        "label": "Furniture-Repairing/Refinish Supls-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42495002",
        "yellow_page_code": "35813",
        "kiosk": false
    },
    {
        "value": "519806",
        "label": "Paint & Varnish Removers-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42495004",
        "yellow_page_code": "58613",
        "kiosk": false
    },
    {
        "value": "519807",
        "label": "Paint-Industrial (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42495005",
        "yellow_page_code": "58602, 04419, 42945",
        "kiosk": false
    },
    {
        "value": "519808",
        "label": "Tapes-Masking (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42495008",
        "yellow_page_code": "83808",
        "kiosk": false
    },
    {
        "value": "519809",
        "label": "Wood Finishing Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42495011",
        "yellow_page_code": "94205",
        "kiosk": false
    },
    {
        "value": "519810",
        "label": "Wallpaper Hangers Equip & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42495009",
        "yellow_page_code": "91318",
        "kiosk": false
    },
    {
        "value": "519811",
        "label": "Paint & Coatings-Marine (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42495003",
        "yellow_page_code": "58612",
        "kiosk": false
    },
    {
        "value": "519812",
        "label": "Paint Varnish & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42495012",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "519901",
        "label": "Craft & Craft Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499034",
        "yellow_page_code": "03600, 22303, 22315",
        "kiosk": false
    },
    {
        "value": "519902",
        "label": "Rubber-Foam & Sponge (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499084",
        "yellow_page_code": "33601, 72909, 33598",
        "kiosk": false
    },
    {
        "value": "519903",
        "label": "Etched Products-Metal Glass & Etc (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499037",
        "yellow_page_code": "29906",
        "kiosk": false
    },
    {
        "value": "519904",
        "label": "Interior Decorators/Dsgnrs Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499054",
        "yellow_page_code": "43604, 41125",
        "kiosk": false
    },
    {
        "value": "519905",
        "label": "Fishing Bait-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499040",
        "yellow_page_code": "32605",
        "kiosk": false
    },
    {
        "value": "519906",
        "label": "Fur-Skin-Dealers & Brokers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512073",
        "yellow_page_code": "35406",
        "kiosk": false
    },
    {
        "value": "519907",
        "label": "Fund Raising Merchandise (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499042",
        "yellow_page_code": "34906, 34908",
        "kiosk": false
    },
    {
        "value": "519908",
        "label": "General Merchandise-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499048",
        "yellow_page_code": "37008, 43630, 43636, 50291",
        "kiosk": false
    },
    {
        "value": "519909",
        "label": "Horseshoers Equipment & Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499013",
        "yellow_page_code": "41604, 31009",
        "kiosk": false
    },
    {
        "value": "519910",
        "label": "Giftwares-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499050",
        "yellow_page_code": "37306, 37310, 37315, 37316, 42686, 30062, 30063",
        "kiosk": false
    },
    {
        "value": "519911",
        "label": "Leather Apparel-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499057",
        "yellow_page_code": "46603, 46607, 18753",
        "kiosk": false
    },
    {
        "value": "519912",
        "label": "Ice-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499052",
        "yellow_page_code": "42304, 42320",
        "kiosk": false
    },
    {
        "value": "519913",
        "label": "Leather (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499056",
        "yellow_page_code": "46602, 30246",
        "kiosk": false
    },
    {
        "value": "519914",
        "label": "Novelties-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499063",
        "yellow_page_code": "55800",
        "kiosk": false
    },
    {
        "value": "519915",
        "label": "Leather Goods & Clothes-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499058",
        "yellow_page_code": "46800",
        "kiosk": false
    },
    {
        "value": "519916",
        "label": "Aquariums & Aquarium Supls-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499002",
        "yellow_page_code": "03105",
        "kiosk": false
    },
    {
        "value": "519917",
        "label": "Advertising-Specialties (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54189008",
        "yellow_page_code": "00502, 68517, 68527, 00612, 11824, 68490, 43594, 11722, 30033, 68030",
        "kiosk": false
    },
    {
        "value": "519918",
        "label": "Art Dealers-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499003",
        "yellow_page_code": "03407, 03412, 30241",
        "kiosk": false
    },
    {
        "value": "519919",
        "label": "Art Goods-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499004",
        "yellow_page_code": "03502, 03612",
        "kiosk": false
    },
    {
        "value": "519920",
        "label": "Badges (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499006",
        "yellow_page_code": "05704",
        "kiosk": false
    },
    {
        "value": "519921",
        "label": "Artists Materials-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499005",
        "yellow_page_code": "03905",
        "kiosk": false
    },
    {
        "value": "519922",
        "label": "Bamboo & Woven Wood Products (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499009",
        "yellow_page_code": "06303, 06211",
        "kiosk": false
    },
    {
        "value": "519923",
        "label": "Brooms (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499016",
        "yellow_page_code": "11002",
        "kiosk": false
    },
    {
        "value": "519924",
        "label": "Boutique Items-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499015",
        "yellow_page_code": "09700, 09610",
        "kiosk": false
    },
    {
        "value": "519925",
        "label": "Calendars (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499020",
        "yellow_page_code": "12404",
        "kiosk": false
    },
    {
        "value": "519926",
        "label": "Burlap (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499017",
        "yellow_page_code": "11600",
        "kiosk": false
    },
    {
        "value": "519927",
        "label": "Canvas-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499024",
        "yellow_page_code": "13000",
        "kiosk": false
    },
    {
        "value": "519928",
        "label": "Cigar & Cigarette Lighters (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499030",
        "yellow_page_code": "17904, 47303",
        "kiosk": false
    },
    {
        "value": "519929",
        "label": "Christmas Trees-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499027",
        "yellow_page_code": "15701",
        "kiosk": false
    },
    {
        "value": "519930",
        "label": "Corn Cob Products (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499032",
        "yellow_page_code": "21704",
        "kiosk": false
    },
    {
        "value": "519931",
        "label": "Oriental Goods-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499067",
        "yellow_page_code": "58001",
        "kiosk": false
    },
    {
        "value": "519932",
        "label": "Pet Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499071",
        "yellow_page_code": "60504",
        "kiosk": false
    },
    {
        "value": "519933",
        "label": "Pet Shops Supplies (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499069",
        "yellow_page_code": "60501",
        "kiosk": false
    },
    {
        "value": "519934",
        "label": "Premium Goods (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499078",
        "yellow_page_code": "68006",
        "kiosk": false
    },
    {
        "value": "519935",
        "label": "Pictures-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499073",
        "yellow_page_code": "65109",
        "kiosk": false
    },
    {
        "value": "519936",
        "label": "Sponges-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499089",
        "yellow_page_code": "79906",
        "kiosk": false
    },
    {
        "value": "519937",
        "label": "Souvenirs-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499088",
        "yellow_page_code": "79602",
        "kiosk": false
    },
    {
        "value": "519938",
        "label": "Variety Stores-Whls & Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499091",
        "yellow_page_code": "90606, 90590",
        "kiosk": false
    },
    {
        "value": "519939",
        "label": "Tropical Fish-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499090",
        "yellow_page_code": "88607",
        "kiosk": false
    },
    {
        "value": "519940",
        "label": "Yarn-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499096",
        "yellow_page_code": "94708",
        "kiosk": false
    },
    {
        "value": "519941",
        "label": "Candles-Mfrs Equipment & Supls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499021",
        "yellow_page_code": "12703, 12705",
        "kiosk": false
    },
    {
        "value": "519942",
        "label": "Candles-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499022",
        "yellow_page_code": "12701",
        "kiosk": false
    },
    {
        "value": "519943",
        "label": "Baskets-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499010",
        "yellow_page_code": "06809, 93419",
        "kiosk": false
    },
    {
        "value": "519944",
        "label": "Fans-Hand Paper Etc (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499038",
        "yellow_page_code": "30711",
        "kiosk": false
    },
    {
        "value": "519945",
        "label": "Water-Distilled (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499092",
        "yellow_page_code": "92301",
        "kiosk": false
    },
    {
        "value": "519946",
        "label": "Gavels (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499047",
        "yellow_page_code": "36906",
        "kiosk": false
    },
    {
        "value": "519947",
        "label": "Charcoal (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499026",
        "yellow_page_code": "14704",
        "kiosk": false
    },
    {
        "value": "519948",
        "label": "Canvas Goods (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499023",
        "yellow_page_code": "12908",
        "kiosk": false
    },
    {
        "value": "519949",
        "label": "Burls (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499018",
        "yellow_page_code": "11601",
        "kiosk": false
    },
    {
        "value": "519950",
        "label": "Bags-Burlap Canvas & Cotton (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499008",
        "yellow_page_code": "05808, 05817",
        "kiosk": false
    },
    {
        "value": "519951",
        "label": "Felt & Felt Products (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499039",
        "yellow_page_code": "31300",
        "kiosk": false
    },
    {
        "value": "519952",
        "label": "Plastics-Foam-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499076",
        "yellow_page_code": "66305",
        "kiosk": false
    },
    {
        "value": "519953",
        "label": "Window Shades Equip & Supls-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499094",
        "yellow_page_code": "93611",
        "kiosk": false
    },
    {
        "value": "519954",
        "label": "Party Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499068",
        "yellow_page_code": "59603, 41140",
        "kiosk": false
    },
    {
        "value": "519955",
        "label": "Plaster Products-Ornamental (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499075",
        "yellow_page_code": "66201, 66138",
        "kiosk": false
    },
    {
        "value": "519956",
        "label": "Pens & Pencils Advertising (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942046",
        "yellow_page_code": "60222",
        "kiosk": false
    },
    {
        "value": "519957",
        "label": "Religious Goods-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499080",
        "yellow_page_code": "71600",
        "kiosk": false
    },
    {
        "value": "519958",
        "label": "Posters-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499077",
        "yellow_page_code": "67504",
        "kiosk": false
    },
    {
        "value": "519959",
        "label": "Buttons-Advertising (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499019",
        "yellow_page_code": "11908, 11913, 12050",
        "kiosk": false
    },
    {
        "value": "519960",
        "label": "Animal Cages & Racks-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499001",
        "yellow_page_code": "02601",
        "kiosk": false
    },
    {
        "value": "519961",
        "label": "Rattan Reeds & Willows-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499079",
        "yellow_page_code": "70505",
        "kiosk": false
    },
    {
        "value": "519962",
        "label": "Ceramic Products-Industrial-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499025",
        "yellow_page_code": "14413",
        "kiosk": false
    },
    {
        "value": "519963",
        "label": "Curios-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499035",
        "yellow_page_code": "22903",
        "kiosk": false
    },
    {
        "value": "519964",
        "label": "Easels-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499036",
        "yellow_page_code": "27107",
        "kiosk": false
    },
    {
        "value": "519965",
        "label": "Flags & Banners-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499041",
        "yellow_page_code": "32812, 30072",
        "kiosk": false
    },
    {
        "value": "519966",
        "label": "Fur Cleaning Dyeing & Glazing-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499043",
        "yellow_page_code": "35101",
        "kiosk": false
    },
    {
        "value": "519967",
        "label": "Fur-Storage-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499044",
        "yellow_page_code": "35411",
        "kiosk": false
    },
    {
        "value": "519968",
        "label": "Garment Bags & Covers-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499045",
        "yellow_page_code": "36308, 30184",
        "kiosk": false
    },
    {
        "value": "519969",
        "label": "Garment Hangers-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499046",
        "yellow_page_code": "36400",
        "kiosk": false
    },
    {
        "value": "519970",
        "label": "Gift Baskets & Parcels-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499049",
        "yellow_page_code": "37235",
        "kiosk": false
    },
    {
        "value": "519971",
        "label": "Herbs-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499051",
        "yellow_page_code": "40935",
        "kiosk": false
    },
    {
        "value": "519972",
        "label": "Incense-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499053",
        "yellow_page_code": "42801",
        "kiosk": false
    },
    {
        "value": "519973",
        "label": "Keys-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499055",
        "yellow_page_code": "44809, 44816",
        "kiosk": false
    },
    {
        "value": "519974",
        "label": "Lighters-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499059",
        "yellow_page_code": "47236",
        "kiosk": false
    },
    {
        "value": "519975",
        "label": "Matches-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499060",
        "yellow_page_code": "50413",
        "kiosk": false
    },
    {
        "value": "519977",
        "label": "Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499093",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "519978",
        "label": "Pet Shops-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499070",
        "yellow_page_code": "60513, 30154",
        "kiosk": false
    },
    {
        "value": "519979",
        "label": "Picnic Equipment & Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499072",
        "yellow_page_code": "64936",
        "kiosk": false
    },
    {
        "value": "519980",
        "label": "Pins-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499074",
        "yellow_page_code": "65313",
        "kiosk": false
    },
    {
        "value": "519981",
        "label": "Bags-Burlap & Cotton-Used (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499007",
        "yellow_page_code": "05807",
        "kiosk": false
    },
    {
        "value": "519982",
        "label": "Shells-Decorative-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499086",
        "yellow_page_code": "76215",
        "kiosk": false
    },
    {
        "value": "519983",
        "label": "Shells-Marine-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499087",
        "yellow_page_code": "76207",
        "kiosk": false
    },
    {
        "value": "519984",
        "label": "Wood Crafters-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499095",
        "yellow_page_code": "94214",
        "kiosk": false
    },
    {
        "value": "519985",
        "label": "Occult Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499064",
        "yellow_page_code": "56114",
        "kiosk": false
    },
    {
        "value": "519986",
        "label": "Rhinestone Goods (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499081",
        "yellow_page_code": "72012",
        "kiosk": false
    },
    {
        "value": "519987",
        "label": "Bird Feeders & Houses-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499011",
        "yellow_page_code": "08219",
        "kiosk": false
    },
    {
        "value": "519988",
        "label": "Oil Spill Barriers (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499065",
        "yellow_page_code": "56814",
        "kiosk": false
    },
    {
        "value": "519989",
        "label": "Cotton-Duck (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499033",
        "yellow_page_code": "22009",
        "kiosk": false
    },
    {
        "value": "519990",
        "label": "Boat Covers Tops/Upholstery Mtrls-Distr",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499014",
        "yellow_page_code": "08715",
        "kiosk": false
    },
    {
        "value": "519991",
        "label": "Cigar & Cigarette Accessories-Whls/Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499029",
        "yellow_page_code": "65807, 17914",
        "kiosk": false
    },
    {
        "value": "519992",
        "label": "Bird Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499012",
        "yellow_page_code": "08218",
        "kiosk": false
    },
    {
        "value": "519993",
        "label": "Sheepskin Specialties-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499085",
        "yellow_page_code": "76113",
        "kiosk": false
    },
    {
        "value": "519994",
        "label": "Rubber-Crude (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499083",
        "yellow_page_code": "72906",
        "kiosk": false
    },
    {
        "value": "519995",
        "label": "Onyx & Onyx Specialties (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499066",
        "yellow_page_code": "57504",
        "kiosk": false
    },
    {
        "value": "519996",
        "label": "Church Supplies-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499028",
        "yellow_page_code": "15718",
        "kiosk": false
    },
    {
        "value": "519997",
        "label": "Riding Apparel & Equipment-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499082",
        "yellow_page_code": "72201, 30356",
        "kiosk": false
    },
    {
        "value": "519998",
        "label": "Coin Banks (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42499031",
        "yellow_page_code": "19511",
        "kiosk": false
    },
    {
        "value": "519999",
        "label": "Misc Non-Durable Goods NEC (Whls)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42512039",
        "yellow_page_code": "77304, 44215, 13814, 28313, 42616, 82060, 15218, 42619, 42624, 04122, 37418, 71690, 74321",
        "kiosk": false
    },
    {
        "value": "521101",
        "label": "Doors-Garage",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418018",
        "yellow_page_code": "25609, 36104, 36021, 36098, 36128, 41377",
        "kiosk": false
    },
    {
        "value": "521102",
        "label": "Door & Gate Operating Devices",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418015",
        "yellow_page_code": "25506, 36901, 36101, 25641, 30169",
        "kiosk": false
    },
    {
        "value": "521103",
        "label": "Windows-Louvered",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418068",
        "yellow_page_code": "93605, 90810",
        "kiosk": false
    },
    {
        "value": "521104",
        "label": "Wallboard & Plasterboard",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418065",
        "yellow_page_code": "91404",
        "kiosk": false
    },
    {
        "value": "521105",
        "label": "Windows-Plastic",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418070",
        "yellow_page_code": "93701, 93634",
        "kiosk": false
    },
    {
        "value": "521106",
        "label": "Windows-Metal",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418069",
        "yellow_page_code": "93607, 93626",
        "kiosk": false
    },
    {
        "value": "521107",
        "label": "Windows",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418066",
        "yellow_page_code": "93700, 93620, 93628, 93637, 93598, 93631, 30244, 41321",
        "kiosk": false
    },
    {
        "value": "521108",
        "label": "Windows-Wood",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418072",
        "yellow_page_code": "93709, 41472",
        "kiosk": false
    },
    {
        "value": "521109",
        "label": "Windows-Vinyl",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418071",
        "yellow_page_code": "93703, 41471",
        "kiosk": false
    },
    {
        "value": "521110",
        "label": "Plumbing Fixtures & Supplies-New-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418050",
        "yellow_page_code": "66800, 66714, 44924, 40826, 39020, 41345, 41464",
        "kiosk": false
    },
    {
        "value": "521111",
        "label": "Paneling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418048",
        "yellow_page_code": "58800",
        "kiosk": false
    },
    {
        "value": "521112",
        "label": "Plywood & Veneers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418051",
        "yellow_page_code": "66808, 90718",
        "kiosk": false
    },
    {
        "value": "521113",
        "label": "Prefinishing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418076",
        "yellow_page_code": "68018",
        "kiosk": false
    },
    {
        "value": "521114",
        "label": "Roofing Materials",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418052",
        "yellow_page_code": "72708",
        "kiosk": false
    },
    {
        "value": "521115",
        "label": "Shingles & Shakes",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418054",
        "yellow_page_code": "76400",
        "kiosk": false
    },
    {
        "value": "521116",
        "label": "Screens-Door & Window",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418053",
        "yellow_page_code": "74902, 74906, 93651, 93730, 30362",
        "kiosk": false
    },
    {
        "value": "521117",
        "label": "Shower Doors & Enclosures",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418055",
        "yellow_page_code": "77308",
        "kiosk": false
    },
    {
        "value": "521118",
        "label": "Siding Materials",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418057",
        "yellow_page_code": "77408",
        "kiosk": false
    },
    {
        "value": "521119",
        "label": "Shutters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418056",
        "yellow_page_code": "77404, 42136, 72509, 93732, 77413, 77399, 89790",
        "kiosk": false
    },
    {
        "value": "521120",
        "label": "Storm Windows & Doors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418061",
        "yellow_page_code": "81902, 37519",
        "kiosk": false
    },
    {
        "value": "521121",
        "label": "Stone-Cast",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418060",
        "yellow_page_code": "81606, 03704",
        "kiosk": false
    },
    {
        "value": "521122",
        "label": "Tool & Utility Sheds",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418063",
        "yellow_page_code": "86909",
        "kiosk": false
    },
    {
        "value": "521123",
        "label": "Brick-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418005",
        "yellow_page_code": "10603",
        "kiosk": false
    },
    {
        "value": "521124",
        "label": "Trusses-Construction Whls & Mfrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418064",
        "yellow_page_code": "89203",
        "kiosk": false
    },
    {
        "value": "521125",
        "label": "Ceiling Materials",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418007",
        "yellow_page_code": "14208, 46098",
        "kiosk": false
    },
    {
        "value": "521126",
        "label": "Building Materials",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44411001",
        "yellow_page_code": "11208, 40110, 11118, 11119, 42694, 43585, 30299, 41375, 02150",
        "kiosk": false
    },
    {
        "value": "521127",
        "label": "Marble-Natural-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418045",
        "yellow_page_code": "49995",
        "kiosk": false
    },
    {
        "value": "521128",
        "label": "Concrete-Ready Mixed",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418012",
        "yellow_page_code": "20806",
        "kiosk": false
    },
    {
        "value": "521129",
        "label": "Chimney Lining Materials",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418010",
        "yellow_page_code": "15306",
        "kiosk": false
    },
    {
        "value": "521130",
        "label": "Doors-Metal",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418020",
        "yellow_page_code": "25606, 02097, 80914, 25644",
        "kiosk": false
    },
    {
        "value": "521131",
        "label": "Doors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418017",
        "yellow_page_code": "25608, 25513, 25630, 25510, 25708, 25621, 25714, 59698, 25637, 25638, 30421, 41463",
        "kiosk": false
    },
    {
        "value": "521132",
        "label": "Doors-Overhead Type",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418021",
        "yellow_page_code": "25700, 58137, 41484",
        "kiosk": false
    },
    {
        "value": "521133",
        "label": "Doors-Wood",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418025",
        "yellow_page_code": "25705, 30085, 86125",
        "kiosk": false
    },
    {
        "value": "521134",
        "label": "Doors-Glass",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418019",
        "yellow_page_code": "25701",
        "kiosk": false
    },
    {
        "value": "521135",
        "label": "Electric Equipment & Supplies-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418028",
        "yellow_page_code": "27504, 27815, 41478",
        "kiosk": false
    },
    {
        "value": "521136",
        "label": "Energy Conservation Prods-Svcs-Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418029",
        "yellow_page_code": "28701, 30429",
        "kiosk": false
    },
    {
        "value": "521137",
        "label": "Hardwoods",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418039",
        "yellow_page_code": "40204, 49010",
        "kiosk": false
    },
    {
        "value": "521138",
        "label": "Home Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44411002",
        "yellow_page_code": "41206, 25245",
        "kiosk": false
    },
    {
        "value": "521139",
        "label": "Thermostats-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44411003",
        "yellow_page_code": "85708",
        "kiosk": false
    },
    {
        "value": "521140",
        "label": "Insulation Materials-Cold & Heat",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418042",
        "yellow_page_code": "43402, 43339, 43340, 43390, 43341, 21272, 43356",
        "kiosk": false
    },
    {
        "value": "521141",
        "label": "Medicine Cabinets",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418046",
        "yellow_page_code": "51104",
        "kiosk": false
    },
    {
        "value": "521142",
        "label": "Lumber-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418043",
        "yellow_page_code": "49004, 08617, 48914, 94241, 41456, 02162",
        "kiosk": false
    },
    {
        "value": "521143",
        "label": "Doors-Rolling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418023",
        "yellow_page_code": "25703",
        "kiosk": false
    },
    {
        "value": "521144",
        "label": "Concrete Patching Compounds",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418011",
        "yellow_page_code": "20702",
        "kiosk": false
    },
    {
        "value": "521145",
        "label": "Hinges",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418040",
        "yellow_page_code": "41005",
        "kiosk": false
    },
    {
        "value": "521146",
        "label": "Caning Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418006",
        "yellow_page_code": "12807",
        "kiosk": false
    },
    {
        "value": "521147",
        "label": "Grouting Compounds",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418037",
        "yellow_page_code": "39200",
        "kiosk": false
    },
    {
        "value": "521148",
        "label": "Creosoted Products",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418013",
        "yellow_page_code": "22601",
        "kiosk": false
    },
    {
        "value": "521149",
        "label": "Wood Preserving (Products)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418073",
        "yellow_page_code": "94207",
        "kiosk": false
    },
    {
        "value": "521150",
        "label": "Hardboard-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418038",
        "yellow_page_code": "40111",
        "kiosk": false
    },
    {
        "value": "521151",
        "label": "Boat Builders-Materials",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418001",
        "yellow_page_code": "08609",
        "kiosk": false
    },
    {
        "value": "521152",
        "label": "Cement-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418009",
        "yellow_page_code": "14306, 20710, 20521, 30384",
        "kiosk": false
    },
    {
        "value": "521153",
        "label": "Dry Wall Materials",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418027",
        "yellow_page_code": "26707",
        "kiosk": false
    },
    {
        "value": "521154",
        "label": "Masonry Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418002",
        "yellow_page_code": "10504, 50315, 81617",
        "kiosk": false
    },
    {
        "value": "521155",
        "label": "Marble-Cultured-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418044",
        "yellow_page_code": "49832",
        "kiosk": false
    },
    {
        "value": "521157",
        "label": "Brick-Concrete Pumice & Etc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418004",
        "yellow_page_code": "10508, 10510",
        "kiosk": false
    },
    {
        "value": "521158",
        "label": "Dog Houses",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418014",
        "yellow_page_code": "25405",
        "kiosk": false
    },
    {
        "value": "521159",
        "label": "Drain-Tile",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418026",
        "yellow_page_code": "25809, 25819",
        "kiosk": false
    },
    {
        "value": "521160",
        "label": "Cement-Portland-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418008",
        "yellow_page_code": "14311",
        "kiosk": false
    },
    {
        "value": "521161",
        "label": "Windows-Glass Block",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418067",
        "yellow_page_code": "93629",
        "kiosk": false
    },
    {
        "value": "521162",
        "label": "Timber Connectors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418062",
        "yellow_page_code": "86109",
        "kiosk": false
    },
    {
        "value": "521163",
        "label": "Doors-Revolving",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418022",
        "yellow_page_code": "25616",
        "kiosk": false
    },
    {
        "value": "521164",
        "label": "Solid Surface Materials",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418058",
        "yellow_page_code": "79313",
        "kiosk": false
    },
    {
        "value": "521165",
        "label": "Panels",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418049",
        "yellow_page_code": "58716, 82019",
        "kiosk": false
    },
    {
        "value": "521166",
        "label": "Stone-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418059",
        "yellow_page_code": "81703, 81612, 81599, 20941",
        "kiosk": false
    },
    {
        "value": "521167",
        "label": "Door Assemblies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418016",
        "yellow_page_code": "25534",
        "kiosk": false
    },
    {
        "value": "521169",
        "label": "Millwork-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418047",
        "yellow_page_code": "52437, 52414, 03214",
        "kiosk": false
    },
    {
        "value": "521170",
        "label": "Pavers-Stone Brick Interlocking Etc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418003",
        "yellow_page_code": "10514, 59920, 59921, 59721, 59922",
        "kiosk": false
    },
    {
        "value": "521171",
        "label": "Roofing Materials-Metal",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418077",
        "yellow_page_code": "72612",
        "kiosk": false
    },
    {
        "value": "521172",
        "label": "Kitchen Counters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418075",
        "yellow_page_code": "44928",
        "kiosk": false
    },
    {
        "value": "521173",
        "label": "Enclosures-In-House-Modular",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418080",
        "yellow_page_code": "28609",
        "kiosk": false
    },
    {
        "value": "521174",
        "label": "Vigas Latillas & Firewood",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418082",
        "yellow_page_code": "91160",
        "kiosk": false
    },
    {
        "value": "521175",
        "label": "Garages & Garage Accessories-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418083",
        "yellow_page_code": "36097",
        "kiosk": false
    },
    {
        "value": "523101",
        "label": "Glass-Circles & Other Special Shapes",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418033",
        "yellow_page_code": "37500, 37610, 37416",
        "kiosk": false
    },
    {
        "value": "523102",
        "label": "Glass-Tempered",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418035",
        "yellow_page_code": "37705",
        "kiosk": false
    },
    {
        "value": "523103",
        "label": "Glass-Stained & Leaded",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418034",
        "yellow_page_code": "37704, 80432, 93733, 03431",
        "kiosk": false
    },
    {
        "value": "523104",
        "label": "Wallpaper Removing Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44412010",
        "yellow_page_code": "91409",
        "kiosk": false
    },
    {
        "value": "523105",
        "label": "Wall Coatings",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44412007",
        "yellow_page_code": "91402",
        "kiosk": false
    },
    {
        "value": "523106",
        "label": "Wallpapers & Wallcoverings-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44412012",
        "yellow_page_code": "91502, 91313",
        "kiosk": false
    },
    {
        "value": "523107",
        "label": "Paint-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44412003",
        "yellow_page_code": "58608, 14261, 58617, 58725",
        "kiosk": false
    },
    {
        "value": "523108",
        "label": "Wood Preservatives",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44412013",
        "yellow_page_code": "94206, 94227",
        "kiosk": false
    },
    {
        "value": "523109",
        "label": "Glass-Broken",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418032",
        "yellow_page_code": "37700",
        "kiosk": false
    },
    {
        "value": "523110",
        "label": "Glass-Auto Plate & Window & Etc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418031",
        "yellow_page_code": "37604, 37615, 37620, 93717, 93618, 37517, 43675",
        "kiosk": false
    },
    {
        "value": "523111",
        "label": "Glass Bending Drilling & Grinding & Etc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418030",
        "yellow_page_code": "37405",
        "kiosk": false
    },
    {
        "value": "523112",
        "label": "Glaziers Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418036",
        "yellow_page_code": "37803",
        "kiosk": false
    },
    {
        "value": "523113",
        "label": "Color Matching Service & Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44412001",
        "yellow_page_code": "19804",
        "kiosk": false
    },
    {
        "value": "523114",
        "label": "Lacquers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44412002",
        "yellow_page_code": "45414",
        "kiosk": false
    },
    {
        "value": "523115",
        "label": "Stains",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44412006",
        "yellow_page_code": "80407",
        "kiosk": false
    },
    {
        "value": "523116",
        "label": "Wall Printing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44412008",
        "yellow_page_code": "91308",
        "kiosk": false
    },
    {
        "value": "523117",
        "label": "Wallpaper Designers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44412009",
        "yellow_page_code": "91411",
        "kiosk": false
    },
    {
        "value": "523118",
        "label": "Wallpapers & Wallcoverings-Equip & Supl",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44412011",
        "yellow_page_code": "91414",
        "kiosk": false
    },
    {
        "value": "523119",
        "label": "Paper Hangers Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44412004",
        "yellow_page_code": "59001",
        "kiosk": false
    },
    {
        "value": "523120",
        "label": "Shingle Stain",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44412005",
        "yellow_page_code": "76307",
        "kiosk": false
    },
    {
        "value": "523121",
        "label": "Glass-Residential",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418074",
        "yellow_page_code": "37511",
        "kiosk": false
    },
    {
        "value": "523122",
        "label": "Glass-Commercial & Store Front Instltn",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418079",
        "yellow_page_code": "37419",
        "kiosk": false
    },
    {
        "value": "523123",
        "label": "Leaded Lights & Windows",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418081",
        "yellow_page_code": "46517",
        "kiosk": false
    },
    {
        "value": "523124",
        "label": "Glass Customized Functional Sculptural",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44418084",
        "yellow_page_code": "37414",
        "kiosk": false
    },
    {
        "value": "523125",
        "label": "Vinyl Wall Lettering",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44412014",
        "yellow_page_code": "91153",
        "kiosk": false
    },
    {
        "value": "523126",
        "label": "Paint & Wallpaper Retailers ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44412015",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "525101",
        "label": "Saws",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44414010",
        "yellow_page_code": "14509, 74206, 74110, 74112, 74113, 08338, 02155",
        "kiosk": false
    },
    {
        "value": "525102",
        "label": "Tools-Hand",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44414015",
        "yellow_page_code": "87107, 39921",
        "kiosk": false
    },
    {
        "value": "525103",
        "label": "Weather Strips",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44414017",
        "yellow_page_code": "92800",
        "kiosk": false
    },
    {
        "value": "525104",
        "label": "Hardware-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44414005",
        "yellow_page_code": "40200, 40145, 30260, 41443",
        "kiosk": false
    },
    {
        "value": "525105",
        "label": "Ironworks-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44414007",
        "yellow_page_code": "43903",
        "kiosk": false
    },
    {
        "value": "525106",
        "label": "Tools-Cutting-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44414013",
        "yellow_page_code": "86936, 23099",
        "kiosk": false
    },
    {
        "value": "525107",
        "label": "Valves-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44414016",
        "yellow_page_code": "90512",
        "kiosk": false
    },
    {
        "value": "525108",
        "label": "Rope-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44414009",
        "yellow_page_code": "72811",
        "kiosk": false
    },
    {
        "value": "525109",
        "label": "Knobs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44414008",
        "yellow_page_code": "45103",
        "kiosk": false
    },
    {
        "value": "525110",
        "label": "Home & Automotive Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44414006",
        "yellow_page_code": "41115",
        "kiosk": false
    },
    {
        "value": "525111",
        "label": "Bolts & Nuts-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44414002",
        "yellow_page_code": "09115",
        "kiosk": false
    },
    {
        "value": "525113",
        "label": "Toilet Partitions",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44414012",
        "yellow_page_code": "86785",
        "kiosk": false
    },
    {
        "value": "525114",
        "label": "Furniture Legs-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44414004",
        "yellow_page_code": "35901",
        "kiosk": false
    },
    {
        "value": "525115",
        "label": "Tools-New & Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44414014",
        "yellow_page_code": "86916",
        "kiosk": false
    },
    {
        "value": "525116",
        "label": "Antiques-Restoration Supplies & Hardware",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44414001",
        "yellow_page_code": "02714",
        "kiosk": false
    },
    {
        "value": "525117",
        "label": "Doors-Supplies & Parts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44414003",
        "yellow_page_code": "25511, 25640",
        "kiosk": false
    },
    {
        "value": "525118",
        "label": "Screws-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44414011",
        "yellow_page_code": "74994",
        "kiosk": false
    },
    {
        "value": "525119",
        "label": "Bathroom Remodeling Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44414018",
        "yellow_page_code": "06919",
        "kiosk": false
    },
    {
        "value": "525120",
        "label": "Ladders-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44414019",
        "yellow_page_code": "45503",
        "kiosk": false
    },
    {
        "value": "525121",
        "label": "Pins-Common Safety Etc-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42431036",
        "yellow_page_code": "65315",
        "kiosk": false
    },
    {
        "value": "526101",
        "label": "Lawn & Garden Equip & Supplies-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44423002",
        "yellow_page_code": "50115, 36300, 46302, 45840, 79110, 11015, 46327, 11017, 36230, 11690, 11018",
        "kiosk": false
    },
    {
        "value": "526102",
        "label": "Engines-Natural Gas",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44423010",
        "yellow_page_code": "29320",
        "kiosk": false
    },
    {
        "value": "526103",
        "label": "Fountains-Garden Display Etc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44424007",
        "yellow_page_code": "34302, 34238, 34239, 33025",
        "kiosk": false
    },
    {
        "value": "526104",
        "label": "Garden Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44424008",
        "yellow_page_code": "36317, 36210, 36304, 66027, 46220",
        "kiosk": false
    },
    {
        "value": "526105",
        "label": "Garden Ornaments",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44424009",
        "yellow_page_code": "36303",
        "kiosk": false
    },
    {
        "value": "526106",
        "label": "Landscaping Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44424010",
        "yellow_page_code": "45902, 45845, 41349, 20528",
        "kiosk": false
    },
    {
        "value": "526107",
        "label": "Lawn Mowers-Parts & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44423004",
        "yellow_page_code": "46223, 46307",
        "kiosk": false
    },
    {
        "value": "526108",
        "label": "Nurserymen",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44424012",
        "yellow_page_code": "55822, 55900, 55810, 55817, 88532, 55824, 43743",
        "kiosk": false
    },
    {
        "value": "526109",
        "label": "Lawn Mowers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44423003",
        "yellow_page_code": "46306, 54510",
        "kiosk": false
    },
    {
        "value": "526110",
        "label": "Flower Pots",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44424006",
        "yellow_page_code": "33519, 67511",
        "kiosk": false
    },
    {
        "value": "526111",
        "label": "Bonsai Plants & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44424003",
        "yellow_page_code": "09210",
        "kiosk": false
    },
    {
        "value": "526112",
        "label": "Wheelbarrows",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44424026",
        "yellow_page_code": "93312",
        "kiosk": false
    },
    {
        "value": "526113",
        "label": "Lawn Mowers-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44423005",
        "yellow_page_code": "46315",
        "kiosk": false
    },
    {
        "value": "526114",
        "label": "Outdoor Power Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44423006",
        "yellow_page_code": "67911, 29719, 58110",
        "kiosk": false
    },
    {
        "value": "526115",
        "label": "Soils-Potting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44424020",
        "yellow_page_code": "79216",
        "kiosk": false
    },
    {
        "value": "526116",
        "label": "Water Garden & Pond Construction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44424024",
        "yellow_page_code": "92219, 67225, 92220, 67224, 67198",
        "kiosk": false
    },
    {
        "value": "526120",
        "label": "Animal & Bird Housing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44424002",
        "yellow_page_code": "08209, 02431",
        "kiosk": false
    },
    {
        "value": "526121",
        "label": "Bark",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44424001",
        "yellow_page_code": "06605",
        "kiosk": false
    },
    {
        "value": "526122",
        "label": "Christmas Trees",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521902",
        "yellow_page_code": "15700, 88518",
        "kiosk": false
    },
    {
        "value": "526123",
        "label": "Plants-Horticultural",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44424015",
        "yellow_page_code": "66007",
        "kiosk": false
    },
    {
        "value": "526124",
        "label": "Weed Control Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44424025",
        "yellow_page_code": "93005",
        "kiosk": false
    },
    {
        "value": "526125",
        "label": "Potatoes-Seed",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44424016",
        "yellow_page_code": "67605",
        "kiosk": false
    },
    {
        "value": "526126",
        "label": "Planter Boxes",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44424014",
        "yellow_page_code": "66006",
        "kiosk": false
    },
    {
        "value": "526127",
        "label": "Railroad Ties",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44424017",
        "yellow_page_code": "70206",
        "kiosk": false
    },
    {
        "value": "526128",
        "label": "Sprinklers-Garden & Lawn-Parts/Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44424021",
        "yellow_page_code": "80302",
        "kiosk": false
    },
    {
        "value": "526129",
        "label": "Seeds & Bulbs-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44424018",
        "yellow_page_code": "11060, 75404",
        "kiosk": false
    },
    {
        "value": "526130",
        "label": "Tractors-Lawn & Garden-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44423009",
        "yellow_page_code": "87800",
        "kiosk": false
    },
    {
        "value": "526131",
        "label": "Statuary",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44424022",
        "yellow_page_code": "80708, 80713",
        "kiosk": false
    },
    {
        "value": "526132",
        "label": "Snow Removal Equipment-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44423008",
        "yellow_page_code": "78635, 78608, 78620",
        "kiosk": false
    },
    {
        "value": "526133",
        "label": "Trees",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44424023",
        "yellow_page_code": "29929, 88505, 58724",
        "kiosk": false
    },
    {
        "value": "526134",
        "label": "Mulches",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44424011",
        "yellow_page_code": "54508",
        "kiosk": false
    },
    {
        "value": "526135",
        "label": "Soil",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44424019",
        "yellow_page_code": "79200",
        "kiosk": false
    },
    {
        "value": "526136",
        "label": "Sprinklers-Garden & Lawn-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44424029",
        "yellow_page_code": "80236, 80237, 41450, 80226, 80222, 80219, 80218, 80300, 46401",
        "kiosk": false
    },
    {
        "value": "526137",
        "label": "Engines-Gasoline",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44423001",
        "yellow_page_code": "28637, 78417, 36531, 29306, 36523",
        "kiosk": false
    },
    {
        "value": "526138",
        "label": "Peat Moss",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44424013",
        "yellow_page_code": "60105, 60110",
        "kiosk": false
    },
    {
        "value": "526139",
        "label": "Fertilizers-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44424005",
        "yellow_page_code": "00795, 31413, 49831",
        "kiosk": false
    },
    {
        "value": "526140",
        "label": "Cacti",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44424004",
        "yellow_page_code": "12213",
        "kiosk": false
    },
    {
        "value": "526141",
        "label": "Rototilling Equipment & Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44423007",
        "yellow_page_code": "72810",
        "kiosk": false
    },
    {
        "value": "526142",
        "label": "Deer Barriers & Repellants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44424028",
        "yellow_page_code": "23713",
        "kiosk": false
    },
    {
        "value": "526143",
        "label": "Shrubs & Shrubbery",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44424027",
        "yellow_page_code": "77410",
        "kiosk": false
    },
    {
        "value": "526144",
        "label": "Outdoor Living",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44424031",
        "yellow_page_code": "58141, 58127",
        "kiosk": false
    },
    {
        "value": "526145",
        "label": "Garden Center & Farm Supply Retailers ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44424032",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "527101",
        "label": "Mobile Homes-Dealers-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45993002",
        "yellow_page_code": "53101",
        "kiosk": false
    },
    {
        "value": "527102",
        "label": "Mobile Homes-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45993001",
        "yellow_page_code": "41941, 53100, 49943, 53020, 41332, 70603",
        "kiosk": false
    },
    {
        "value": "527103",
        "label": "Mobile Homes-Equipment & Parts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45993003",
        "yellow_page_code": "53024, 49843, 49848, 53104, 53338, 53328, 53114, 49944",
        "kiosk": false
    },
    {
        "value": "527104",
        "label": "Mobile Offices & Commercial Units",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45993005",
        "yellow_page_code": "83021, 67419, 56213, 53208",
        "kiosk": false
    },
    {
        "value": "527105",
        "label": "Mobile Homes-Listing Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45993004",
        "yellow_page_code": "49916, 53008",
        "kiosk": false
    },
    {
        "value": "527106",
        "label": "Modular Homes-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45993006",
        "yellow_page_code": "53320",
        "kiosk": false
    },
    {
        "value": "531101",
        "label": "Resident Buyers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45511005",
        "yellow_page_code": "71809",
        "kiosk": false
    },
    {
        "value": "531102",
        "label": "Department Stores",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45511001",
        "yellow_page_code": "24212, 24204, 37021, 41936",
        "kiosk": false
    },
    {
        "value": "531104",
        "label": "Retail Shops",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45511003",
        "yellow_page_code": "82339, 24910, 71932, 24904",
        "kiosk": false
    },
    {
        "value": "531108",
        "label": "Home & Personal Care Products",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45511006",
        "yellow_page_code": "41207",
        "kiosk": false
    },
    {
        "value": "531109",
        "label": "Merchandise Marts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45511004",
        "yellow_page_code": "51305",
        "kiosk": false
    },
    {
        "value": "531110",
        "label": "Wholesale Clubs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521101",
        "yellow_page_code": "91521, 93410",
        "kiosk": false
    },
    {
        "value": "531112",
        "label": "Government-Department Stores",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45511002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "533101",
        "label": "Variety Stores",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521903",
        "yellow_page_code": "32709, 25490, 81816, 90608",
        "kiosk": false
    },
    {
        "value": "533102",
        "label": "Trading Posts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521904",
        "yellow_page_code": "87803",
        "kiosk": false
    },
    {
        "value": "533103",
        "label": "Soap Mitts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521905",
        "yellow_page_code": "78901",
        "kiosk": false
    },
    {
        "value": "533104",
        "label": "Clothes Posts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521906",
        "yellow_page_code": "18712",
        "kiosk": false
    },
    {
        "value": "533105",
        "label": "Hula Supplies-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521907",
        "yellow_page_code": "42012",
        "kiosk": false
    },
    {
        "value": "539901",
        "label": "General Merchandise-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521908",
        "yellow_page_code": "37006",
        "kiosk": false
    },
    {
        "value": "539902",
        "label": "Country Stores",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521909",
        "yellow_page_code": "22201",
        "kiosk": false
    },
    {
        "value": "539903",
        "label": "Bead Strings",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521910",
        "yellow_page_code": "07107",
        "kiosk": false
    },
    {
        "value": "539904",
        "label": "Tote Boxes Pans & Trays",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521911",
        "yellow_page_code": "87302",
        "kiosk": false
    },
    {
        "value": "539905",
        "label": "Farmers Co-Op Retail Stores",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521912",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "539906",
        "label": "Car Seats-Children",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521913",
        "yellow_page_code": "13101, 15143",
        "kiosk": false
    },
    {
        "value": "539907",
        "label": "Sponges-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521914",
        "yellow_page_code": "79914",
        "kiosk": false
    },
    {
        "value": "541103",
        "label": "Convenience Stores",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44513101",
        "yellow_page_code": "21303",
        "kiosk": false
    },
    {
        "value": "541105",
        "label": "Grocers-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44511003",
        "yellow_page_code": "82021, 33805, 33900, 02905, 78604, 39107, 39111, 43657, 39106, 82251, 50107, 02176, 23790, 11411, 37010, 50116",
        "kiosk": false
    },
    {
        "value": "541106",
        "label": "Markets-Kosher",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44511004",
        "yellow_page_code": "50201",
        "kiosk": false
    },
    {
        "value": "541107",
        "label": "Grocers-Ethnic Foods",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44511006",
        "yellow_page_code": "39116",
        "kiosk": false
    },
    {
        "value": "541108",
        "label": "Grocers-Health Foods",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44511007",
        "yellow_page_code": "40452, 39120",
        "kiosk": false
    },
    {
        "value": "541110",
        "label": "Grocery Pickup-Curbside",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44511009",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "542101",
        "label": "Seafood-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44525004",
        "yellow_page_code": "32308, 75100, 32417",
        "kiosk": false
    },
    {
        "value": "542103",
        "label": "Frozen Foods-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529840",
        "yellow_page_code": "34606, 33918",
        "kiosk": false
    },
    {
        "value": "542104",
        "label": "Meat Cutting Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44524003",
        "yellow_page_code": "50802",
        "kiosk": false
    },
    {
        "value": "542105",
        "label": "Lobsters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44525003",
        "yellow_page_code": "48407",
        "kiosk": false
    },
    {
        "value": "542106",
        "label": "Ham Specialty Stores",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44524012",
        "yellow_page_code": "39819",
        "kiosk": false
    },
    {
        "value": "542107",
        "label": "Meat-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44524006",
        "yellow_page_code": "50815, 50817, 50906, 07507, 50706, 50818, 11110, 11915",
        "kiosk": false
    },
    {
        "value": "542108",
        "label": "Sausages",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44524009",
        "yellow_page_code": "09612, 74010, 74100",
        "kiosk": false
    },
    {
        "value": "542109",
        "label": "Shrimp-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44525005",
        "yellow_page_code": "77309",
        "kiosk": false
    },
    {
        "value": "542110",
        "label": "Caviar",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44525001",
        "yellow_page_code": "14202",
        "kiosk": false
    },
    {
        "value": "542111",
        "label": "Meat Markets-Kosher",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44524005",
        "yellow_page_code": "50810, 50805",
        "kiosk": false
    },
    {
        "value": "542113",
        "label": "Hams",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44524002",
        "yellow_page_code": "39807",
        "kiosk": false
    },
    {
        "value": "542114",
        "label": "Smoked Foods",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44524010",
        "yellow_page_code": "78601",
        "kiosk": false
    },
    {
        "value": "542115",
        "label": "Meat For Freezers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44524004",
        "yellow_page_code": "50711",
        "kiosk": false
    },
    {
        "value": "542116",
        "label": "Crab Meat",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44525002",
        "yellow_page_code": "22209",
        "kiosk": false
    },
    {
        "value": "542117",
        "label": "Meats-Barbecued",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44524007",
        "yellow_page_code": "50811",
        "kiosk": false
    },
    {
        "value": "542118",
        "label": "Smoking & Curing Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44524011",
        "yellow_page_code": "78511, 78510, 78509",
        "kiosk": false
    },
    {
        "value": "542119",
        "label": "Fish & Seafood Retailers ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44525006",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "543101",
        "label": "Fruits & Vegetables & Produce-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44523003",
        "yellow_page_code": "90621, 34708, 30550, 68504, 80290, 90620",
        "kiosk": false
    },
    {
        "value": "543102",
        "label": "Farm Markets",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44523001",
        "yellow_page_code": "30902",
        "kiosk": false
    },
    {
        "value": "543103",
        "label": "Fruit Packages",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44523002",
        "yellow_page_code": "34702",
        "kiosk": false
    },
    {
        "value": "543104",
        "label": "Juices-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44523005",
        "yellow_page_code": "44601",
        "kiosk": false
    },
    {
        "value": "543105",
        "label": "Fruits-Glace",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44523004",
        "yellow_page_code": "34803",
        "kiosk": false
    },
    {
        "value": "543106",
        "label": "Pick-Your-Own Fruit & Vegetables",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44523006",
        "yellow_page_code": "64909, 34612",
        "kiosk": false
    },
    {
        "value": "544101",
        "label": "Candy & Confectionery-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529202",
        "yellow_page_code": "12800, 34835, 20915, 82614",
        "kiosk": false
    },
    {
        "value": "544102",
        "label": "Nuts-Edible",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529205",
        "yellow_page_code": "56100",
        "kiosk": false
    },
    {
        "value": "544103",
        "label": "Popcorn & Popcorn Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529206",
        "yellow_page_code": "67302, 67305",
        "kiosk": false
    },
    {
        "value": "544104",
        "label": "Candy Making Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529204",
        "yellow_page_code": "12710, 12238, 12715, 52612, 12801, 06007, 20881",
        "kiosk": false
    },
    {
        "value": "544105",
        "label": "Wedding Candy & Confectioneries",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529201",
        "yellow_page_code": "92940",
        "kiosk": false
    },
    {
        "value": "544106",
        "label": "Candy & Cookie Arrangements",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529203",
        "yellow_page_code": "12714",
        "kiosk": false
    },
    {
        "value": "544107",
        "label": "Confectionery & Nut Retailers ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529207",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "545101",
        "label": "Dairy Products-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529807",
        "yellow_page_code": "52309, 52306, 30393, 23304",
        "kiosk": false
    },
    {
        "value": "545102",
        "label": "Yogurt",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529832",
        "yellow_page_code": "34591, 94805",
        "kiosk": false
    },
    {
        "value": "545103",
        "label": "Cheese",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529802",
        "yellow_page_code": "14906",
        "kiosk": false
    },
    {
        "value": "546101",
        "label": "Bagels",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72251502",
        "yellow_page_code": "05707",
        "kiosk": false
    },
    {
        "value": "546102",
        "label": "Bakers-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31181102",
        "yellow_page_code": "14270, 92922, 92948, 45089, 10336, 59601, 06005, 37912, 12303, 06004",
        "kiosk": false
    },
    {
        "value": "546103",
        "label": "Bakers-Cake & Pie",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31181101",
        "yellow_page_code": "06002",
        "kiosk": false
    },
    {
        "value": "546104",
        "label": "Pies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31181104",
        "yellow_page_code": "65202",
        "kiosk": false
    },
    {
        "value": "546105",
        "label": "Doughnuts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72251510",
        "yellow_page_code": "25800, 25457",
        "kiosk": false
    },
    {
        "value": "546106",
        "label": "Cake Decorating",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31181103",
        "yellow_page_code": "12307",
        "kiosk": false
    },
    {
        "value": "546108",
        "label": "Pretzels-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72251514",
        "yellow_page_code": "68036",
        "kiosk": false
    },
    {
        "value": "546109",
        "label": "Cookie Shops",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72251506",
        "yellow_page_code": "21417, 21407, 21412",
        "kiosk": false
    },
    {
        "value": "546110",
        "label": "Muffins",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529102",
        "yellow_page_code": "54511",
        "kiosk": false
    },
    {
        "value": "546111",
        "label": "Matzos",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529101",
        "yellow_page_code": "50609",
        "kiosk": false
    },
    {
        "value": "546112",
        "label": "Cake Decorating Instruction & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31181105",
        "yellow_page_code": "12316",
        "kiosk": false
    },
    {
        "value": "546113",
        "label": "Baked Goods Off-site Retailers ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529103",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "546114",
        "label": "Snack & Nonalcoholic Beverage Bars ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72251515",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "549901",
        "label": "Health & Diet Foods-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45619103",
        "yellow_page_code": "93015, 75473, 56008, 40439, 40440, 24742, 40504",
        "kiosk": false
    },
    {
        "value": "549902",
        "label": "Spices",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529827",
        "yellow_page_code": "75204, 79902",
        "kiosk": false
    },
    {
        "value": "549903",
        "label": "Water Companies-Bottled/Bulk & Etc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529830",
        "yellow_page_code": "09507, 26340, 41486, 92223, 92104, 92207, 92123, 92037, 92039",
        "kiosk": false
    },
    {
        "value": "549904",
        "label": "Vitamin & Food Supplements",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45619106",
        "yellow_page_code": "91204, 91227",
        "kiosk": false
    },
    {
        "value": "549905",
        "label": "Weight Control Preparation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529831",
        "yellow_page_code": "93007",
        "kiosk": false
    },
    {
        "value": "549906",
        "label": "Salt",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529824",
        "yellow_page_code": "73604",
        "kiosk": false
    },
    {
        "value": "549907",
        "label": "Poultry-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44524008",
        "yellow_page_code": "67808",
        "kiosk": false
    },
    {
        "value": "549908",
        "label": "Food Supplements",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45619101",
        "yellow_page_code": "23211, 40455, 33906",
        "kiosk": false
    },
    {
        "value": "549909",
        "label": "Foods-Natural",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45619102",
        "yellow_page_code": "55207, 33909",
        "kiosk": false
    },
    {
        "value": "549910",
        "label": "Food Specialties-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529811",
        "yellow_page_code": "79735, 24826, 50712, 33905, 39119",
        "kiosk": false
    },
    {
        "value": "549911",
        "label": "Game Birds Venison Etc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44524001",
        "yellow_page_code": "36032, 36005, 67621",
        "kiosk": false
    },
    {
        "value": "549912",
        "label": "Kosher Foods",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529818",
        "yellow_page_code": "45115, 45106",
        "kiosk": false
    },
    {
        "value": "549913",
        "label": "Herbs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529814",
        "yellow_page_code": "41000, 40937, 51138",
        "kiosk": false
    },
    {
        "value": "549914",
        "label": "Eggs-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529810",
        "yellow_page_code": "27302",
        "kiosk": false
    },
    {
        "value": "549915",
        "label": "Coffee & Tea",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529805",
        "yellow_page_code": "19208, 84137",
        "kiosk": false
    },
    {
        "value": "549916",
        "label": "Oriental Food Products",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529822",
        "yellow_page_code": "57909",
        "kiosk": false
    },
    {
        "value": "549917",
        "label": "Chinese Food Products",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529803",
        "yellow_page_code": "03932, 15490",
        "kiosk": false
    },
    {
        "value": "549918",
        "label": "Oriental Goods",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529823",
        "yellow_page_code": "58000",
        "kiosk": false
    },
    {
        "value": "549919",
        "label": "Japanese Food Products",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529816",
        "yellow_page_code": "44195",
        "kiosk": false
    },
    {
        "value": "549920",
        "label": "Gourmet Shops",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529812",
        "yellow_page_code": "38238, 38235, 38304, 38307, 30117",
        "kiosk": false
    },
    {
        "value": "549921",
        "label": "Imported Foods",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529815",
        "yellow_page_code": "33910, 42707",
        "kiosk": false
    },
    {
        "value": "549922",
        "label": "Coffee Roasting & Handling Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529806",
        "yellow_page_code": "19308, 19307",
        "kiosk": false
    },
    {
        "value": "549923",
        "label": "Korean Foods",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529817",
        "yellow_page_code": "45105, 45099",
        "kiosk": false
    },
    {
        "value": "549924",
        "label": "Seeds-Chinese Preserved",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529825",
        "yellow_page_code": "75401",
        "kiosk": false
    },
    {
        "value": "549925",
        "label": "Distilled Water",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529809",
        "yellow_page_code": "25016",
        "kiosk": false
    },
    {
        "value": "549926",
        "label": "Hawaiian Foods",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529813",
        "yellow_page_code": "40314",
        "kiosk": false
    },
    {
        "value": "549927",
        "label": "Mexican & Latin American Food Products",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529820",
        "yellow_page_code": "87320, 52090, 46113",
        "kiosk": false
    },
    {
        "value": "549928",
        "label": "Dietetic Food Products",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529808",
        "yellow_page_code": "24734",
        "kiosk": false
    },
    {
        "value": "549929",
        "label": "Coconut Products",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529804",
        "yellow_page_code": "19203",
        "kiosk": false
    },
    {
        "value": "549930",
        "label": "British Food Products",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529801",
        "yellow_page_code": "10765",
        "kiosk": false
    },
    {
        "value": "549931",
        "label": "Meat Tenderizing Preparations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529819",
        "yellow_page_code": "50902",
        "kiosk": false
    },
    {
        "value": "549932",
        "label": "Soyfoods",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529826",
        "yellow_page_code": "79512",
        "kiosk": false
    },
    {
        "value": "549933",
        "label": "Vegetable Juices",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529828",
        "yellow_page_code": "90605",
        "kiosk": false
    },
    {
        "value": "549934",
        "label": "Vitamins-Raw Materials",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45619107",
        "yellow_page_code": "91300",
        "kiosk": false
    },
    {
        "value": "549935",
        "label": "Organic Foods & Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45619105",
        "yellow_page_code": "34616, 57810, 57815",
        "kiosk": false
    },
    {
        "value": "549936",
        "label": "Nutrients",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45619104",
        "yellow_page_code": "56014",
        "kiosk": false
    },
    {
        "value": "549937",
        "label": "Vietnamese Foods",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529829",
        "yellow_page_code": "91014",
        "kiosk": false
    },
    {
        "value": "549938",
        "label": "Espresso & Espresso Bars",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72251503",
        "yellow_page_code": "29837",
        "kiosk": false
    },
    {
        "value": "549939",
        "label": "Ukrainian Foods",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529833",
        "yellow_page_code": "89822",
        "kiosk": false
    },
    {
        "value": "549940",
        "label": "Low Carb Foods & Programs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45619108",
        "yellow_page_code": "48632",
        "kiosk": false
    },
    {
        "value": "549941",
        "label": "Thai Food",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529834",
        "yellow_page_code": "85410",
        "kiosk": false
    },
    {
        "value": "549943",
        "label": "Awareness Products",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45619109",
        "yellow_page_code": "05420",
        "kiosk": false
    },
    {
        "value": "549944",
        "label": "Fitness Supplements",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45619110",
        "yellow_page_code": "32717",
        "kiosk": false
    },
    {
        "value": "549945",
        "label": "Halal Foods",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529836",
        "yellow_page_code": "39823",
        "kiosk": false
    },
    {
        "value": "549946",
        "label": "Native American Foods",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529837",
        "yellow_page_code": "42826",
        "kiosk": false
    },
    {
        "value": "549947",
        "label": "Caribbean Foods",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529835",
        "yellow_page_code": "13430",
        "kiosk": false
    },
    {
        "value": "549948",
        "label": "Beverages-Non-Alcoholic-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529838",
        "yellow_page_code": "07811, 07809, 07817",
        "kiosk": false
    },
    {
        "value": "549950",
        "label": "Meal Kit Delivery Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529839",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "549999",
        "label": "Miscellaneous Food Stores",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44529821",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "551101",
        "label": "Automobile Dlrs-Custom Designed Replica",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44112001",
        "yellow_page_code": "04704",
        "kiosk": false
    },
    {
        "value": "551102",
        "label": "Automobile Dealers-New Cars",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44111001",
        "yellow_page_code": "05332, 04555, 43645, 04706, 30463, 13064",
        "kiosk": false
    },
    {
        "value": "551104",
        "label": "Automobile Dealers-Hydrogen Cars-New",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44112004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "551105",
        "label": "Truck-Dealers-New",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44111008",
        "yellow_page_code": "88702, 30137",
        "kiosk": false
    },
    {
        "value": "551106",
        "label": "Limousine-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44111006",
        "yellow_page_code": "47503",
        "kiosk": false
    },
    {
        "value": "551107",
        "label": "Utility Vehicles-Sport & Atv",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44111007",
        "yellow_page_code": "24845, 56116, 56220, 90212, 67969",
        "kiosk": false
    },
    {
        "value": "551108",
        "label": "Vans-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44111009",
        "yellow_page_code": "90601",
        "kiosk": false
    },
    {
        "value": "551109",
        "label": "Fire Trucks-Sales & Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44111004",
        "yellow_page_code": "32114",
        "kiosk": false
    },
    {
        "value": "551110",
        "label": "Automobiles-Fleet Sales",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44111010",
        "yellow_page_code": "04833",
        "kiosk": false
    },
    {
        "value": "551111",
        "label": "Automobile Dealers-Electric Cars-New",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44111011",
        "yellow_page_code": "90626, 04710, 27813",
        "kiosk": false
    },
    {
        "value": "551112",
        "label": "Automobile-Specialty",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44111002",
        "yellow_page_code": "18241, 05418",
        "kiosk": false
    },
    {
        "value": "551113",
        "label": "Gas Conversion Motor Vehicles",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44111003",
        "yellow_page_code": "36521",
        "kiosk": false
    },
    {
        "value": "551114",
        "label": "Commercial Trucks-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44111012",
        "yellow_page_code": "88919",
        "kiosk": false
    },
    {
        "value": "551115",
        "label": "Truck Dealers-Electric-New",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44111013",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "552102",
        "label": "Automobile-Antique & Classic",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44112002",
        "yellow_page_code": "02715, 30462, 04413, 18220, 05401",
        "kiosk": false
    },
    {
        "value": "552103",
        "label": "Automobile Dealers Performance & Race",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44112006",
        "yellow_page_code": "69510, 89024, 04715, 05025",
        "kiosk": false
    },
    {
        "value": "552198",
        "label": "Automobile/Trucks Dealers-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44112003",
        "yellow_page_code": "04708, 90135",
        "kiosk": false
    },
    {
        "value": "553101",
        "label": "Automobile Sunroofs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133016",
        "yellow_page_code": "82308, 82256, 05209, 13047",
        "kiosk": false
    },
    {
        "value": "553102",
        "label": "Filters-Air & Gas",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133022",
        "yellow_page_code": "30443, 31702",
        "kiosk": false
    },
    {
        "value": "553103",
        "label": "Gasoline Additive-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133025",
        "yellow_page_code": "36807",
        "kiosk": false
    },
    {
        "value": "553104",
        "label": "Tachographs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133034",
        "yellow_page_code": "83205",
        "kiosk": false
    },
    {
        "value": "553105",
        "label": "Alternators & Generators-Automotive",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133001",
        "yellow_page_code": "02002, 05217, 04839",
        "kiosk": false
    },
    {
        "value": "553106",
        "label": "Automobile Air Conditioning Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133003",
        "yellow_page_code": "00912, 04506, 13020, 90260, 01000",
        "kiosk": false
    },
    {
        "value": "553107",
        "label": "Automobile Alarms",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133004",
        "yellow_page_code": "19944, 13073, 75291, 04507, 05136, 11518, 13072, 04714, 13044",
        "kiosk": false
    },
    {
        "value": "553108",
        "label": "Automobile Bumpers Guards & Grills",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133005",
        "yellow_page_code": "80606, 11435, 04700",
        "kiosk": false
    },
    {
        "value": "553109",
        "label": "Automobile Floor Coverings",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133008",
        "yellow_page_code": "04807",
        "kiosk": false
    },
    {
        "value": "553110",
        "label": "Automobile Electric Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133007",
        "yellow_page_code": "13029, 04804, 90663",
        "kiosk": false
    },
    {
        "value": "553111",
        "label": "Automobile Parts & Supplies-Retail-New",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133011",
        "yellow_page_code": "04553, 86414, 05002, 04519, 04525, 05330, 05337, 13071, 04633, 27408, 07130, 29313, 04918, 04531, 36610, 67925, 04639, 80047, 43751, 36527, 29328",
        "kiosk": false
    },
    {
        "value": "553112",
        "label": "Automobile Hub Caps",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133009",
        "yellow_page_code": "04907, 93401, 42013",
        "kiosk": false
    },
    {
        "value": "553113",
        "label": "Automobile Racing Car Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133012",
        "yellow_page_code": "69519, 05100, 41880, 05347, 69511, 05331",
        "kiosk": false
    },
    {
        "value": "553114",
        "label": "Automobile Radio & Stereo Systs-Sls/Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133013",
        "yellow_page_code": "81219, 05104, 05334, 04999, 05233, 13109, 13023, 05115, 05023, 81216",
        "kiosk": false
    },
    {
        "value": "553115",
        "label": "Battery Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133018",
        "yellow_page_code": "07106, 07124",
        "kiosk": false
    },
    {
        "value": "553116",
        "label": "Batteries-Storage-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133017",
        "yellow_page_code": "07008, 07018",
        "kiosk": false
    },
    {
        "value": "553117",
        "label": "Wheels & Wheel Covers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133043",
        "yellow_page_code": "01931, 72219, 93402, 93412",
        "kiosk": false
    },
    {
        "value": "553118",
        "label": "Windshield Wipers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133044",
        "yellow_page_code": "93707",
        "kiosk": false
    },
    {
        "value": "553119",
        "label": "Tires-Solid",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133037",
        "yellow_page_code": "86509",
        "kiosk": false
    },
    {
        "value": "553120",
        "label": "Shock Absorbers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133033",
        "yellow_page_code": "76700",
        "kiosk": false
    },
    {
        "value": "553121",
        "label": "Tachometers & Tachographs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133035",
        "yellow_page_code": "83203",
        "kiosk": false
    },
    {
        "value": "553122",
        "label": "Trailer Hitches",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133039",
        "yellow_page_code": "87900, 41022",
        "kiosk": false
    },
    {
        "value": "553123",
        "label": "Tire-Dealers-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44134001",
        "yellow_page_code": "30916, 87701, 04545, 43735, 30045, 86406, 88627",
        "kiosk": false
    },
    {
        "value": "553124",
        "label": "Brake Fluids",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133019",
        "yellow_page_code": "10200",
        "kiosk": false
    },
    {
        "value": "553125",
        "label": "Van Accessories",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133042",
        "yellow_page_code": "90509",
        "kiosk": false
    },
    {
        "value": "553126",
        "label": "Seat Belts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133032",
        "yellow_page_code": "74120, 75205",
        "kiosk": false
    },
    {
        "value": "553127",
        "label": "Truck Air Conditioning Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133041",
        "yellow_page_code": "88624, 88613",
        "kiosk": false
    },
    {
        "value": "553128",
        "label": "Four Wheel Drive-Parts & Accessories",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133024",
        "yellow_page_code": "88636, 04638, 34318, 34301",
        "kiosk": false
    },
    {
        "value": "553129",
        "label": "Automobile Accessories",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133002",
        "yellow_page_code": "04601, 69523, 05227",
        "kiosk": false
    },
    {
        "value": "553130",
        "label": "Tow Bars",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133038",
        "yellow_page_code": "87407",
        "kiosk": false
    },
    {
        "value": "553131",
        "label": "Filters-Fuel & Oil-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133023",
        "yellow_page_code": "31717",
        "kiosk": false
    },
    {
        "value": "553132",
        "label": "Radiators-Automotive-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133030",
        "yellow_page_code": "69724, 69714, 04558",
        "kiosk": false
    },
    {
        "value": "553133",
        "label": "Screens-Automobile",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133031",
        "yellow_page_code": "74903",
        "kiosk": false
    },
    {
        "value": "553134",
        "label": "Taxicabs Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133036",
        "yellow_page_code": "84113",
        "kiosk": false
    },
    {
        "value": "553135",
        "label": "Automobile Covers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133006",
        "yellow_page_code": "04801",
        "kiosk": false
    },
    {
        "value": "553136",
        "label": "Cruise Controls-Automotive",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133021",
        "yellow_page_code": "22706",
        "kiosk": false
    },
    {
        "value": "553137",
        "label": "Clutch Facings",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133020",
        "yellow_page_code": "18812",
        "kiosk": false
    },
    {
        "value": "553138",
        "label": "Radar Detectors-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133029",
        "yellow_page_code": "69609",
        "kiosk": false
    },
    {
        "value": "553139",
        "label": "Importers-Automotive-Parts & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133026",
        "yellow_page_code": "42699",
        "kiosk": false
    },
    {
        "value": "553140",
        "label": "Automobile Remote Starters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133014",
        "yellow_page_code": "04718, 05117, 71607",
        "kiosk": false
    },
    {
        "value": "553141",
        "label": "Transfer Cases",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133040",
        "yellow_page_code": "88237",
        "kiosk": false
    },
    {
        "value": "553142",
        "label": "Off-Road Vehicles-Equip Parts & Acces",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133028",
        "yellow_page_code": "04637, 04543, 88629, 56230, 56118",
        "kiosk": false
    },
    {
        "value": "553143",
        "label": "Automobile Kits",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133010",
        "yellow_page_code": "04821",
        "kiosk": false
    },
    {
        "value": "553144",
        "label": "Vehicle Tracking Devices",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133027",
        "yellow_page_code": "90635",
        "kiosk": false
    },
    {
        "value": "553145",
        "label": "Siphons & Siphon Bottles",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32616002",
        "yellow_page_code": "77418",
        "kiosk": false
    },
    {
        "value": "553146",
        "label": "Mufflers Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133045",
        "yellow_page_code": "54521",
        "kiosk": false
    },
    {
        "value": "553147",
        "label": "Keyless Entry",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133046",
        "yellow_page_code": "44790",
        "kiosk": false
    },
    {
        "value": "553148",
        "label": "Exporters-Automobile Tools & Access-Whls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133047",
        "yellow_page_code": "30218",
        "kiosk": false
    },
    {
        "value": "553149",
        "label": "Motorcycle & Motor Scooter Tires",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44134002",
        "yellow_page_code": "86918",
        "kiosk": false
    },
    {
        "value": "553155",
        "label": "Automobile Radios/Stereos-Equip/Access",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133049",
        "yellow_page_code": "42796, 05019",
        "kiosk": false
    },
    {
        "value": "554101",
        "label": "Service Stations-Gasoline & Oil",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45712003",
        "yellow_page_code": "36809, 36815, 75610, 31625, 60547, 36825, 04556, 75700",
        "kiosk": false
    },
    {
        "value": "554103",
        "label": "Truck Stops & Plazas",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45712005",
        "yellow_page_code": "88906",
        "kiosk": false
    },
    {
        "value": "554105",
        "label": "Kerosene",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45712001",
        "yellow_page_code": "44801",
        "kiosk": false
    },
    {
        "value": "554106",
        "label": "Marine Service Stations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45712004",
        "yellow_page_code": "50034, 08627, 75609, 50013",
        "kiosk": false
    },
    {
        "value": "554107",
        "label": "Oils-Lubricating-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45712002",
        "yellow_page_code": "57305",
        "kiosk": false
    },
    {
        "value": "554110",
        "label": "Alternative Fuels",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45712006",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "554111",
        "label": "Diesel Exhaust Fluid",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45712007",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "554112",
        "label": "Electric Charging Station",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45712008",
        "yellow_page_code": "27411",
        "kiosk": true
    },
    {
        "value": "555101",
        "label": "Engines-Marine",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122210",
        "yellow_page_code": "50040, 50003, 29309",
        "kiosk": false
    },
    {
        "value": "555102",
        "label": "Boat Covers Tops & Upholstery",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122203",
        "yellow_page_code": "08702",
        "kiosk": false
    },
    {
        "value": "555103",
        "label": "Boat Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122205",
        "yellow_page_code": "08614, 08706, 94701, 08725, 08816, 50010, 08721, 75217, 08626, 08634, 08636",
        "kiosk": false
    },
    {
        "value": "555104",
        "label": "Boat Dealers Sales & Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122204",
        "yellow_page_code": "08813, 08704, 08709",
        "kiosk": false
    },
    {
        "value": "555105",
        "label": "Hardware-Marine",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122211",
        "yellow_page_code": "40109",
        "kiosk": false
    },
    {
        "value": "555106",
        "label": "Canoes",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122208",
        "yellow_page_code": "12910, 44720, 12833, 44717, 12905, 44716",
        "kiosk": false
    },
    {
        "value": "555107",
        "label": "Yachts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122226",
        "yellow_page_code": "94705",
        "kiosk": false
    },
    {
        "value": "555108",
        "label": "Propellers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122221",
        "yellow_page_code": "68700, 50016",
        "kiosk": false
    },
    {
        "value": "555109",
        "label": "Outboard Motors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122218",
        "yellow_page_code": "08750, 58102, 88630",
        "kiosk": false
    },
    {
        "value": "555110",
        "label": "Marine Equipment & Supplies-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122216",
        "yellow_page_code": "50012",
        "kiosk": false
    },
    {
        "value": "555111",
        "label": "Rafts-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122222",
        "yellow_page_code": "70104, 70120, 47125, 70117",
        "kiosk": false
    },
    {
        "value": "555112",
        "label": "Outboard Motors-Parts & Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122219",
        "yellow_page_code": "58036",
        "kiosk": false
    },
    {
        "value": "555113",
        "label": "Marine Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122215",
        "yellow_page_code": "50102, 50025",
        "kiosk": false
    },
    {
        "value": "555114",
        "label": "Anchors-Marine",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122202",
        "yellow_page_code": "02402",
        "kiosk": false
    },
    {
        "value": "555115",
        "label": "Life Preservers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122213",
        "yellow_page_code": "47197, 47204",
        "kiosk": false
    },
    {
        "value": "555116",
        "label": "Transmissions-Marine",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122223",
        "yellow_page_code": "88309",
        "kiosk": false
    },
    {
        "value": "555117",
        "label": "Anchors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122201",
        "yellow_page_code": "02307",
        "kiosk": false
    },
    {
        "value": "555118",
        "label": "Life Raft Inflation Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122214",
        "yellow_page_code": "47111",
        "kiosk": false
    },
    {
        "value": "555119",
        "label": "Depth Sounders-Sales & Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122209",
        "yellow_page_code": "24205",
        "kiosk": false
    },
    {
        "value": "555120",
        "label": "Boat Windows",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122207",
        "yellow_page_code": "08811",
        "kiosk": false
    },
    {
        "value": "555121",
        "label": "Marine Equipment-Used & Rebuilt",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122217",
        "yellow_page_code": "50024",
        "kiosk": false
    },
    {
        "value": "555122",
        "label": "Kayaks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122212",
        "yellow_page_code": "44707, 44713",
        "kiosk": false
    },
    {
        "value": "555123",
        "label": "Outboard Motors-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122220",
        "yellow_page_code": "58103",
        "kiosk": false
    },
    {
        "value": "555124",
        "label": "Watercraft-Personal",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122224",
        "yellow_page_code": "08728, 92134, 92601, 92045, 92514, 60310",
        "kiosk": false
    },
    {
        "value": "555125",
        "label": "Yacht Brokers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122225",
        "yellow_page_code": "94612, 94704",
        "kiosk": false
    },
    {
        "value": "555126",
        "label": "Boat Performance & Racing Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122206",
        "yellow_page_code": "08718",
        "kiosk": false
    },
    {
        "value": "555127",
        "label": "Marine Electronics",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122227",
        "yellow_page_code": "50042",
        "kiosk": false
    },
    {
        "value": "555128",
        "label": "Boat Dealers Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122228",
        "yellow_page_code": "08729",
        "kiosk": false
    },
    {
        "value": "555129",
        "label": "Refrigeration-Marine",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122229",
        "yellow_page_code": "71408",
        "kiosk": false
    },
    {
        "value": "555130",
        "label": "Lift Raft Inflation Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122230",
        "yellow_page_code": "47122",
        "kiosk": false
    },
    {
        "value": "555131",
        "label": "Paddle Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122231",
        "yellow_page_code": "58415",
        "kiosk": false
    },
    {
        "value": "556101",
        "label": "Recreational Vehicles-Equip/Parts/Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44121006",
        "yellow_page_code": "71027, 13051, 71005",
        "kiosk": false
    },
    {
        "value": "556102",
        "label": "Trailers-Boat",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44121008",
        "yellow_page_code": "08824, 87908",
        "kiosk": false
    },
    {
        "value": "556103",
        "label": "Recreational Vehicles",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44121005",
        "yellow_page_code": "71006, 54210, 54243",
        "kiosk": false
    },
    {
        "value": "556104",
        "label": "Trailers-Equipment & Parts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44121010",
        "yellow_page_code": "88006",
        "kiosk": false
    },
    {
        "value": "556105",
        "label": "Trailers-Camping & Travel",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44121009",
        "yellow_page_code": "88002, 88023",
        "kiosk": false
    },
    {
        "value": "556106",
        "label": "Truck Canopies/Caps & Shells",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44121011",
        "yellow_page_code": "12901, 12505, 12840, 88722, 88620, 87301, 88701",
        "kiosk": false
    },
    {
        "value": "556107",
        "label": "Motor Homes Equipment & Parts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44121003",
        "yellow_page_code": "54207",
        "kiosk": false
    },
    {
        "value": "556108",
        "label": "Motor Homes-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44121004",
        "yellow_page_code": "54206",
        "kiosk": false
    },
    {
        "value": "556109",
        "label": "Campers-Supplies & Parts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44121002",
        "yellow_page_code": "12502",
        "kiosk": false
    },
    {
        "value": "556110",
        "label": "Campers & Pick-Up Coaches-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44121001",
        "yellow_page_code": "13052, 12408",
        "kiosk": false
    },
    {
        "value": "556111",
        "label": "Recreational Vehicles-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44121007",
        "yellow_page_code": "71011",
        "kiosk": false
    },
    {
        "value": "556112",
        "label": "Motorcycle Campers-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44121012",
        "yellow_page_code": "54211",
        "kiosk": false
    },
    {
        "value": "557101",
        "label": "All Terrain Vehicles",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122701",
        "yellow_page_code": "01906, 01928",
        "kiosk": false
    },
    {
        "value": "557102",
        "label": "Golf Cars & Carts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122704",
        "yellow_page_code": "38122, 38100, 75312",
        "kiosk": false
    },
    {
        "value": "557103",
        "label": "Motorcycles & Motor Scooters-Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122709",
        "yellow_page_code": "54302, 54236",
        "kiosk": false
    },
    {
        "value": "557104",
        "label": "Mopeds",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122705",
        "yellow_page_code": "53604",
        "kiosk": false
    },
    {
        "value": "557105",
        "label": "Motorcycles & Motor Scooters-Accessories",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122707",
        "yellow_page_code": "54238, 54322, 54233, 54401, 54219",
        "kiosk": false
    },
    {
        "value": "557106",
        "label": "Motorcycles & Motor Scooters-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122708",
        "yellow_page_code": "74718, 54249, 08019, 54330, 30151, 74722, 54215, 54239, 54304, 07909",
        "kiosk": false
    },
    {
        "value": "557107",
        "label": "Bicycle Motors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122702",
        "yellow_page_code": "08007",
        "kiosk": false
    },
    {
        "value": "557108",
        "label": "Cars-Pedal Power",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122703",
        "yellow_page_code": "13803",
        "kiosk": false
    },
    {
        "value": "557109",
        "label": "Motorcycles & Motor Scooters-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122710",
        "yellow_page_code": "54246, 54403",
        "kiosk": false
    },
    {
        "value": "557110",
        "label": "Drones",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122706",
        "yellow_page_code": "26515",
        "kiosk": false
    },
    {
        "value": "557111",
        "label": "Motorcycles-Antique & Classic",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122711",
        "yellow_page_code": "54234",
        "kiosk": false
    },
    {
        "value": "559901",
        "label": "Carts-Motorized",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122718",
        "yellow_page_code": "13904, 37911, 44704, 44714",
        "kiosk": false
    },
    {
        "value": "559902",
        "label": "Snowmobile Parts & Accessories-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122723",
        "yellow_page_code": "78717, 78706",
        "kiosk": false
    },
    {
        "value": "559903",
        "label": "Trailers-Automobile Utility Sports Etc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122726",
        "yellow_page_code": "88200, 90207",
        "kiosk": false
    },
    {
        "value": "559904",
        "label": "Snowmobiles",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122724",
        "yellow_page_code": "78709, 78718, 78630, 78802, 78618",
        "kiosk": false
    },
    {
        "value": "559905",
        "label": "Aircraft-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122715",
        "yellow_page_code": "01404, 01426, 00850, 30451",
        "kiosk": false
    },
    {
        "value": "559906",
        "label": "Dune Buggies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122719",
        "yellow_page_code": "26902",
        "kiosk": false
    },
    {
        "value": "559907",
        "label": "Hang Gliders",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122720",
        "yellow_page_code": "89890, 40139, 01612, 40201",
        "kiosk": false
    },
    {
        "value": "559908",
        "label": "Aircraft Equipment Parts & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122714",
        "yellow_page_code": "65310, 01408, 05342, 01430",
        "kiosk": false
    },
    {
        "value": "559909",
        "label": "Wreckers-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122729",
        "yellow_page_code": "94502, 05311, 05223",
        "kiosk": false
    },
    {
        "value": "559910",
        "label": "Helicopter-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122721",
        "yellow_page_code": "40909, 30560",
        "kiosk": false
    },
    {
        "value": "559911",
        "label": "Wrecker Service Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122728",
        "yellow_page_code": "94501",
        "kiosk": false
    },
    {
        "value": "559912",
        "label": "Aeronautical Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122712",
        "yellow_page_code": "00807",
        "kiosk": false
    },
    {
        "value": "559913",
        "label": "Air Cushion Vehicles",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122713",
        "yellow_page_code": "01105",
        "kiosk": false
    },
    {
        "value": "559914",
        "label": "Cars-Mini",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122717",
        "yellow_page_code": "13813",
        "kiosk": false
    },
    {
        "value": "559915",
        "label": "Midget Race Cars & Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122722",
        "yellow_page_code": "52211",
        "kiosk": false
    },
    {
        "value": "559917",
        "label": "Trailer-Sales",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122725",
        "yellow_page_code": "88019, 87905",
        "kiosk": false
    },
    {
        "value": "559918",
        "label": "Trailers-Snowmobile",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122727",
        "yellow_page_code": "88018",
        "kiosk": false
    },
    {
        "value": "559919",
        "label": "Trailers-Truck-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122730",
        "yellow_page_code": "88107",
        "kiosk": false
    },
    {
        "value": "559920",
        "label": "Trailers-Enclosed",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122731",
        "yellow_page_code": "87920",
        "kiosk": false
    },
    {
        "value": "559999",
        "label": "Automotive Dealers NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44122716",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "561101",
        "label": "Men's Clothing & Furnishings-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811002",
        "yellow_page_code": "51236, 18803, 51206, 51223, 18732, 51229, 51231, 18743",
        "kiosk": false
    },
    {
        "value": "561102",
        "label": "Salvage & Surplus Merchandise",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811003",
        "yellow_page_code": "51424, 73613, 81740, 81739, 30516, 52310, 52304, 82408",
        "kiosk": false
    },
    {
        "value": "561103",
        "label": "Boys Clothing & Furnishings-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811004",
        "yellow_page_code": "10100",
        "kiosk": false
    },
    {
        "value": "561104",
        "label": "Army & Navy Goods",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811005",
        "yellow_page_code": "67417, 03316, 03406",
        "kiosk": false
    },
    {
        "value": "561105",
        "label": "Gloves-Work & Industrial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811006",
        "yellow_page_code": "37904",
        "kiosk": false
    },
    {
        "value": "561106",
        "label": "Jeans",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811007",
        "yellow_page_code": "44202",
        "kiosk": false
    },
    {
        "value": "561107",
        "label": "Leather Clothing-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811008",
        "yellow_page_code": "46615, 46606, 46610, 18714, 46617, 46611",
        "kiosk": false
    },
    {
        "value": "561108",
        "label": "Neckwear-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811009",
        "yellow_page_code": "85991, 55302, 55237",
        "kiosk": false
    },
    {
        "value": "561109",
        "label": "Work Clothing-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811010",
        "yellow_page_code": "94504, 58134",
        "kiosk": false
    },
    {
        "value": "561110",
        "label": "Shirts-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811011",
        "yellow_page_code": "76605",
        "kiosk": false
    },
    {
        "value": "561111",
        "label": "Pants-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811012",
        "yellow_page_code": "58709",
        "kiosk": false
    },
    {
        "value": "561112",
        "label": "Tuxedos",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811013",
        "yellow_page_code": "89603",
        "kiosk": false
    },
    {
        "value": "561116",
        "label": "Clothing & Accessories Men Big & Tall",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811015",
        "yellow_page_code": "51245, 18735",
        "kiosk": false
    },
    {
        "value": "561117",
        "label": "Marine Corps Gear",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811016",
        "yellow_page_code": "50017",
        "kiosk": false
    },
    {
        "value": "562101",
        "label": "Women's Apparel-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811017",
        "yellow_page_code": "68531, 18811, 26207, 45506, 94106, 94107, 30935, 94114, 18822, 94111, 94122, 45423, 45510, 45425",
        "kiosk": false
    },
    {
        "value": "562103",
        "label": "Maternity Apparel & Supports",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811018",
        "yellow_page_code": "50500, 50504, 18699",
        "kiosk": false
    },
    {
        "value": "562104",
        "label": "Bridal Shops",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811019",
        "yellow_page_code": "92925, 92920, 10630, 10700, 10611, 10609, 92964, 92917",
        "kiosk": false
    },
    {
        "value": "562105",
        "label": "Boutique Items-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811020",
        "yellow_page_code": "09608, 09606, 09611",
        "kiosk": false
    },
    {
        "value": "563201",
        "label": "Fur-Designers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811021",
        "yellow_page_code": "35109",
        "kiosk": false
    },
    {
        "value": "563202",
        "label": "Fur Business-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811022",
        "yellow_page_code": "35110, 35115, 35405, 35102",
        "kiosk": false
    },
    {
        "value": "563204",
        "label": "Corsets Girdles & Brassieres",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811023",
        "yellow_page_code": "21806, 37312",
        "kiosk": false
    },
    {
        "value": "563205",
        "label": "Brassieres",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811024",
        "yellow_page_code": "10299, 10309",
        "kiosk": false
    },
    {
        "value": "563206",
        "label": "Hosiery-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811025",
        "yellow_page_code": "20205, 41708, 82322, 30564",
        "kiosk": false
    },
    {
        "value": "563207",
        "label": "Handbags",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811026",
        "yellow_page_code": "35040, 69213, 39902, 30559",
        "kiosk": false
    },
    {
        "value": "563208",
        "label": "Knit Goods-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811027",
        "yellow_page_code": "45016, 45002",
        "kiosk": false
    },
    {
        "value": "563209",
        "label": "Millinery-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811028",
        "yellow_page_code": "52402, 92990, 92978, 52410",
        "kiosk": false
    },
    {
        "value": "563210",
        "label": "Lingerie",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811029",
        "yellow_page_code": "47606",
        "kiosk": false
    },
    {
        "value": "563212",
        "label": "Costume Jewelry",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811030",
        "yellow_page_code": "44216, 14505, 21807",
        "kiosk": false
    },
    {
        "value": "563213",
        "label": "Rhinestones-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811031",
        "yellow_page_code": "71917",
        "kiosk": false
    },
    {
        "value": "563215",
        "label": "Accessories-Fashion",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811032",
        "yellow_page_code": "00030, 30936",
        "kiosk": false
    },
    {
        "value": "563217",
        "label": "Bags-Fabric",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811033",
        "yellow_page_code": "05810",
        "kiosk": false
    },
    {
        "value": "564101",
        "label": "Baby Accessories",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811034",
        "yellow_page_code": "37318, 05506, 05606, 05519, 05521, 05526, 05528",
        "kiosk": false
    },
    {
        "value": "564102",
        "label": "Girls Apparel",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811035",
        "yellow_page_code": "37402",
        "kiosk": false
    },
    {
        "value": "564103",
        "label": "Childrens & Infants Wear-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811036",
        "yellow_page_code": "05590, 15206, 18807, 15211, 18754",
        "kiosk": false
    },
    {
        "value": "564104",
        "label": "Infants Wear",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811037",
        "yellow_page_code": "42913",
        "kiosk": false
    },
    {
        "value": "564115",
        "label": "Baby Accessories-Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811038",
        "yellow_page_code": "05527, 05510",
        "kiosk": false
    },
    {
        "value": "564116",
        "label": "Junior Apparel",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811039",
        "yellow_page_code": "44590, 18766, 84164",
        "kiosk": false
    },
    {
        "value": "564117",
        "label": "Baby Gift Registry",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811040",
        "yellow_page_code": "",
        "kiosk": true
    },
    {
        "value": "565101",
        "label": "Clothing-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811041",
        "yellow_page_code": "18740, 07126, 18802, 18750, 18767",
        "kiosk": false
    },
    {
        "value": "565102",
        "label": "Sweaters-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811042",
        "yellow_page_code": "82605",
        "kiosk": false
    },
    {
        "value": "565103",
        "label": "Scarves-Wearing Apparel",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811043",
        "yellow_page_code": "85935, 74309",
        "kiosk": false
    },
    {
        "value": "565104",
        "label": "Hawaiian Wear-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811044",
        "yellow_page_code": "40315",
        "kiosk": false
    },
    {
        "value": "565107",
        "label": "Underwear-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811045",
        "yellow_page_code": "89909",
        "kiosk": false
    },
    {
        "value": "565109",
        "label": "Clothing & Accessories-Work",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811046",
        "yellow_page_code": "18823",
        "kiosk": false
    },
    {
        "value": "565111",
        "label": "Clothing Knitted",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811047",
        "yellow_page_code": "18722",
        "kiosk": false
    },
    {
        "value": "565112",
        "label": "School Uniforms",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811048",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "566101",
        "label": "Shoes-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45821007",
        "yellow_page_code": "73742, 76918, 76917, 77002, 02168, 76919, 76921, 92984, 76710, 34021",
        "kiosk": false
    },
    {
        "value": "566102",
        "label": "Shoes-Orthopedic",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45821006",
        "yellow_page_code": "30034, 77000, 58011, 76912, 76916, 58018",
        "kiosk": false
    },
    {
        "value": "566103",
        "label": "Boots",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45821001",
        "yellow_page_code": "09406, 09411",
        "kiosk": false
    },
    {
        "value": "566104",
        "label": "Shoe Laces",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45821003",
        "yellow_page_code": "76705",
        "kiosk": false
    },
    {
        "value": "566105",
        "label": "Slippers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45821009",
        "yellow_page_code": "78403",
        "kiosk": false
    },
    {
        "value": "566106",
        "label": "Shoe Store Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45821005",
        "yellow_page_code": "76905",
        "kiosk": false
    },
    {
        "value": "566107",
        "label": "Shoes-Children & Teens",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45821002",
        "yellow_page_code": "76807, 76911",
        "kiosk": false
    },
    {
        "value": "566108",
        "label": "Shoe Ornaments",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45821004",
        "yellow_page_code": "76804",
        "kiosk": false
    },
    {
        "value": "566109",
        "label": "Shoes-Safety",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45821008",
        "yellow_page_code": "76909, 34027",
        "kiosk": false
    },
    {
        "value": "566110",
        "label": "Shoes-Outdoors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45821010",
        "yellow_page_code": "76610",
        "kiosk": false
    },
    {
        "value": "566111",
        "label": "Shoes-Dance",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45821011",
        "yellow_page_code": "06116, 76906",
        "kiosk": false
    },
    {
        "value": "566112",
        "label": "Rubber & Plastic Footwear Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45821012",
        "yellow_page_code": "72903",
        "kiosk": false
    },
    {
        "value": "566113",
        "label": "Shoes Corrective",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45821013",
        "yellow_page_code": "76920",
        "kiosk": false
    },
    {
        "value": "566114",
        "label": "Shoes Molded",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45821014",
        "yellow_page_code": "76713",
        "kiosk": false
    },
    {
        "value": "569901",
        "label": "Fashion Designers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811049",
        "yellow_page_code": "31000, 24234, 45014, 18801, 24300",
        "kiosk": false
    },
    {
        "value": "569903",
        "label": "Belts & Suspenders",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811050",
        "yellow_page_code": "82609, 07704, 07712",
        "kiosk": false
    },
    {
        "value": "569904",
        "label": "Dancing Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811051",
        "yellow_page_code": "23317, 23400, 23320",
        "kiosk": false
    },
    {
        "value": "569905",
        "label": "Costumes-Masquerade & Theatrical",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811052",
        "yellow_page_code": "85650, 21906",
        "kiosk": false
    },
    {
        "value": "569906",
        "label": "Dressmakers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811053",
        "yellow_page_code": "26208, 93063, 75118",
        "kiosk": false
    },
    {
        "value": "569907",
        "label": "Western Apparel & Accessories",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811054",
        "yellow_page_code": "93303, 93302",
        "kiosk": false
    },
    {
        "value": "569909",
        "label": "Wigs Toupees & Hairpieces",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811055",
        "yellow_page_code": "93502, 39626, 21910, 39620, 93440",
        "kiosk": false
    },
    {
        "value": "569910",
        "label": "Sheepskin Specialties",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811056",
        "yellow_page_code": "76202",
        "kiosk": false
    },
    {
        "value": "569911",
        "label": "Riding Apparel & Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811057",
        "yellow_page_code": "72202",
        "kiosk": false
    },
    {
        "value": "569912",
        "label": "Shirts-Custom Made",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811058",
        "yellow_page_code": "76604",
        "kiosk": false
    },
    {
        "value": "569913",
        "label": "Sportswear-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811059",
        "yellow_page_code": "78633, 80042, 90108, 58096, 80037, 80119, 80102, 80116, 18756, 78703",
        "kiosk": false
    },
    {
        "value": "569914",
        "label": "Ski Apparel",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811060",
        "yellow_page_code": "78009",
        "kiosk": false
    },
    {
        "value": "569915",
        "label": "Swimwear & Accessories-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811061",
        "yellow_page_code": "06904, 82901, 06924",
        "kiosk": false
    },
    {
        "value": "569916",
        "label": "Square Dance Apparel",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811062",
        "yellow_page_code": "80304",
        "kiosk": false
    },
    {
        "value": "569917",
        "label": "T-Shirts-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811063",
        "yellow_page_code": "83007, 83018, 83016",
        "kiosk": false
    },
    {
        "value": "569918",
        "label": "Umbrellas",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811064",
        "yellow_page_code": "89807, 89831",
        "kiosk": false
    },
    {
        "value": "569919",
        "label": "Tailors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811065",
        "yellow_page_code": "83307, 83306",
        "kiosk": false
    },
    {
        "value": "569922",
        "label": "Uniforms & Accessories",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811066",
        "yellow_page_code": "56228, 90004, 90006, 29877",
        "kiosk": false
    },
    {
        "value": "569923",
        "label": "Clothing-Printing & Lettering",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811067",
        "yellow_page_code": "36805, 18708, 22511",
        "kiosk": false
    },
    {
        "value": "569924",
        "label": "Moccasins",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31699030",
        "yellow_page_code": "53303",
        "kiosk": false
    },
    {
        "value": "569925",
        "label": "Aprons-Domestic & Fancy",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811068",
        "yellow_page_code": "03012",
        "kiosk": false
    },
    {
        "value": "569928",
        "label": "Hair Goods & Supplies-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811069",
        "yellow_page_code": "39619, 93049, 39608",
        "kiosk": false
    },
    {
        "value": "569929",
        "label": "Healthcare Apparel & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811070",
        "yellow_page_code": "55907",
        "kiosk": false
    },
    {
        "value": "569930",
        "label": "Jackets-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811071",
        "yellow_page_code": "44007",
        "kiosk": false
    },
    {
        "value": "569933",
        "label": "Formal Wear-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811072",
        "yellow_page_code": "34109",
        "kiosk": false
    },
    {
        "value": "569934",
        "label": "Clothing-Custom Made",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811073",
        "yellow_page_code": "44925, 02862, 18709, 18739, 51226",
        "kiosk": false
    },
    {
        "value": "569935",
        "label": "Gloves-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811074",
        "yellow_page_code": "37901",
        "kiosk": false
    },
    {
        "value": "569936",
        "label": "Wool Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811075",
        "yellow_page_code": "94410, 94336",
        "kiosk": false
    },
    {
        "value": "569937",
        "label": "Robes-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811076",
        "yellow_page_code": "72407",
        "kiosk": false
    },
    {
        "value": "569938",
        "label": "Robes Caps & Gowns-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811077",
        "yellow_page_code": "72411, 72425, 13004",
        "kiosk": false
    },
    {
        "value": "569940",
        "label": "Caps & Hats-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811078",
        "yellow_page_code": "40302, 12912, 13012",
        "kiosk": false
    },
    {
        "value": "569941",
        "label": "Cheerleading Apparel",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811079",
        "yellow_page_code": "14903, 14817",
        "kiosk": false
    },
    {
        "value": "569944",
        "label": "Clothing For The Disabled",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811080",
        "yellow_page_code": "18731, 18720",
        "kiosk": false
    },
    {
        "value": "569945",
        "label": "Scout Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811081",
        "yellow_page_code": "74708",
        "kiosk": false
    },
    {
        "value": "569946",
        "label": "Athletic Clothing-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811082",
        "yellow_page_code": "04111",
        "kiosk": false
    },
    {
        "value": "569947",
        "label": "Apparel & Garments-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811083",
        "yellow_page_code": "30938, 92979, 69517, 02822",
        "kiosk": false
    },
    {
        "value": "569949",
        "label": "Clothing-Institutional",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811084",
        "yellow_page_code": "18729",
        "kiosk": false
    },
    {
        "value": "569950",
        "label": "Clown Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811085",
        "yellow_page_code": "18815",
        "kiosk": false
    },
    {
        "value": "569951",
        "label": "Coats-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811086",
        "yellow_page_code": "19107",
        "kiosk": false
    },
    {
        "value": "569952",
        "label": "Leather Goods-Custom Made",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811087",
        "yellow_page_code": "23011, 13951, 46716",
        "kiosk": false
    },
    {
        "value": "569953",
        "label": "Majorette & Marching Unit Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811088",
        "yellow_page_code": "49609",
        "kiosk": false
    },
    {
        "value": "569954",
        "label": "Church Vestments",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811089",
        "yellow_page_code": "15709",
        "kiosk": false
    },
    {
        "value": "569957",
        "label": "Skating Apparel",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811090",
        "yellow_page_code": "77907",
        "kiosk": false
    },
    {
        "value": "569959",
        "label": "Youth Organizations-Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811091",
        "yellow_page_code": "94808",
        "kiosk": false
    },
    {
        "value": "569960",
        "label": "Exercise & Physical Fitness Wear",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811092",
        "yellow_page_code": "30014",
        "kiosk": false
    },
    {
        "value": "569961",
        "label": "Exotic Apparel",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811093",
        "yellow_page_code": "30119",
        "kiosk": false
    },
    {
        "value": "569978",
        "label": "Beach Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811094",
        "yellow_page_code": "07127, 07112",
        "kiosk": false
    },
    {
        "value": "569979",
        "label": "T-Shirts-Custom Printed",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811095",
        "yellow_page_code": "83017, 83012",
        "kiosk": false
    },
    {
        "value": "569980",
        "label": "Clothing-Adaptive",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811096",
        "yellow_page_code": "18737",
        "kiosk": false
    },
    {
        "value": "569982",
        "label": "Socks-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811097",
        "yellow_page_code": "79020",
        "kiosk": false
    },
    {
        "value": "569984",
        "label": "Clothing-Activewear",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48819006",
        "yellow_page_code": "18820",
        "kiosk": false
    },
    {
        "value": "569985",
        "label": "Bridal Couturier",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31525036",
        "yellow_page_code": "93012, 92926, 10640",
        "kiosk": false
    },
    {
        "value": "569987",
        "label": "Feathers-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811098",
        "yellow_page_code": "31090",
        "kiosk": false
    },
    {
        "value": "569988",
        "label": "Clothing Embellishment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45811099",
        "yellow_page_code": "18744",
        "kiosk": false
    },
    {
        "value": "571201",
        "label": "Furniture-Outdoor",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44911016",
        "yellow_page_code": "36222, 58140, 58129, 35904, 59709, 03113, 36912",
        "kiosk": false
    },
    {
        "value": "571202",
        "label": "Furniture-Childrens",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44911011",
        "yellow_page_code": "35916, 15212, 35902, 05504",
        "kiosk": false
    },
    {
        "value": "571203",
        "label": "Patio Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44911025",
        "yellow_page_code": "59718, 23608, 59800",
        "kiosk": false
    },
    {
        "value": "571204",
        "label": "Tables",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44911027",
        "yellow_page_code": "83107",
        "kiosk": false
    },
    {
        "value": "571205",
        "label": "Table Tops",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44911026",
        "yellow_page_code": "83106",
        "kiosk": false
    },
    {
        "value": "571206",
        "label": "Water Beds",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44911032",
        "yellow_page_code": "92102",
        "kiosk": false
    },
    {
        "value": "571207",
        "label": "Furniture-Wicker",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44911018",
        "yellow_page_code": "35717, 35909, 93500, 12809",
        "kiosk": false
    },
    {
        "value": "571208",
        "label": "Apartment Furnishings",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44911001",
        "yellow_page_code": "02903",
        "kiosk": false
    },
    {
        "value": "571209",
        "label": "Beds-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44911006",
        "yellow_page_code": "07321, 07315, 07409",
        "kiosk": false
    },
    {
        "value": "571210",
        "label": "Bedding",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44911003",
        "yellow_page_code": "07404",
        "kiosk": false
    },
    {
        "value": "571211",
        "label": "Kitchen Cabinets & Equipment-Household",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33711006",
        "yellow_page_code": "12128, 44906, 30485, 44910, 02153, 41449",
        "kiosk": false
    },
    {
        "value": "571212",
        "label": "Bookcases",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44911007",
        "yellow_page_code": "09308, 35622",
        "kiosk": false
    },
    {
        "value": "571213",
        "label": "Counter Tops",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33711004",
        "yellow_page_code": "77814, 22106, 22130, 41362, 38799",
        "kiosk": false
    },
    {
        "value": "571214",
        "label": "Chairs-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44911008",
        "yellow_page_code": "14603",
        "kiosk": false
    },
    {
        "value": "571215",
        "label": "Furniture-Dealers-Showrooms",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44911013",
        "yellow_page_code": "35701",
        "kiosk": false
    },
    {
        "value": "571216",
        "label": "Furniture-Dealers-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44911012",
        "yellow_page_code": "35631, 35700, 46614, 41229, 35624, 12160, 35629, 42695, 02152, 70821, 41356",
        "kiosk": false
    },
    {
        "value": "571217",
        "label": "Furniture-Designers & Custom Builders",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33712205",
        "yellow_page_code": "35616, 35609, 35704, 35615, 29601, 24233, 56223",
        "kiosk": false
    },
    {
        "value": "571218",
        "label": "Furniture-Unfinished",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44911017",
        "yellow_page_code": "35906",
        "kiosk": false
    },
    {
        "value": "571219",
        "label": "House Furnishings-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44911022",
        "yellow_page_code": "42005",
        "kiosk": false
    },
    {
        "value": "571220",
        "label": "Mattresses",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44911024",
        "yellow_page_code": "50515, 50602",
        "kiosk": false
    },
    {
        "value": "571221",
        "label": "Hammocks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44911020",
        "yellow_page_code": "39809",
        "kiosk": false
    },
    {
        "value": "571222",
        "label": "Lawn & Garden Furnishings",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44911023",
        "yellow_page_code": "46209, 46332",
        "kiosk": false
    },
    {
        "value": "571223",
        "label": "Futons",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44911019",
        "yellow_page_code": "75222, 35997, 36001",
        "kiosk": false
    },
    {
        "value": "571224",
        "label": "Beds-Disappearing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44911005",
        "yellow_page_code": "07410, 91328, 07412",
        "kiosk": false
    },
    {
        "value": "571225",
        "label": "Furniture-Metal",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44911015",
        "yellow_page_code": "35801",
        "kiosk": false
    },
    {
        "value": "571226",
        "label": "Bed Headboards",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44911002",
        "yellow_page_code": "07309, 40389",
        "kiosk": false
    },
    {
        "value": "571227",
        "label": "Contract Furnishings",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44911009",
        "yellow_page_code": "21116",
        "kiosk": false
    },
    {
        "value": "571228",
        "label": "Furniture-Exposition Marts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44911014",
        "yellow_page_code": "35605",
        "kiosk": false
    },
    {
        "value": "571229",
        "label": "Hassocks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44911021",
        "yellow_page_code": "40214",
        "kiosk": false
    },
    {
        "value": "571230",
        "label": "Television Tables & Stands",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44911028",
        "yellow_page_code": "85005",
        "kiosk": false
    },
    {
        "value": "571231",
        "label": "Drainboards",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44911010",
        "yellow_page_code": "25900",
        "kiosk": false
    },
    {
        "value": "571232",
        "label": "Towel Cabinets",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44911029",
        "yellow_page_code": "87411",
        "kiosk": false
    },
    {
        "value": "571233",
        "label": "Umbrellas-Lawn",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44911031",
        "yellow_page_code": "89903",
        "kiosk": false
    },
    {
        "value": "571234",
        "label": "Trophy Cabinets",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44911030",
        "yellow_page_code": "88531",
        "kiosk": false
    },
    {
        "value": "571235",
        "label": "Beds-Adjustable",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44911004",
        "yellow_page_code": "07306",
        "kiosk": false
    },
    {
        "value": "571236",
        "label": "Cabinets",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33711002",
        "yellow_page_code": "12200, 91314, 41397, 12151, 23016, 44528, 35627, 12127, 12123, 12122, 12153",
        "kiosk": false
    },
    {
        "value": "571237",
        "label": "Mattress Pads & Covers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44911033",
        "yellow_page_code": "50612",
        "kiosk": false
    },
    {
        "value": "571238",
        "label": "Air Beds",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44911034",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "571242",
        "label": "Bedroom Accessories",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44911035",
        "yellow_page_code": "07316",
        "kiosk": false
    },
    {
        "value": "571244",
        "label": "Log Furniture",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33712212",
        "yellow_page_code": "65317, 48525, 35610, 48520",
        "kiosk": false
    },
    {
        "value": "571245",
        "label": "Condominium Furnishings",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44911037",
        "yellow_page_code": "20818",
        "kiosk": false
    },
    {
        "value": "571246",
        "label": "Southwest Furniture",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44911040",
        "yellow_page_code": "79517",
        "kiosk": false
    },
    {
        "value": "571247",
        "label": "Computer Furniture",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44911038",
        "yellow_page_code": "20334, 35620",
        "kiosk": false
    },
    {
        "value": "571248",
        "label": "Computer Room Furniture & Storage Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44911039",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "571301",
        "label": "Linoleum-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912111",
        "yellow_page_code": "47702",
        "kiosk": false
    },
    {
        "value": "571302",
        "label": "Carpet Workrooms",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912107",
        "yellow_page_code": "13900",
        "kiosk": false
    },
    {
        "value": "571303",
        "label": "Carpet & Rug-Pads Linings & Accessories",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912104",
        "yellow_page_code": "13706",
        "kiosk": false
    },
    {
        "value": "571304",
        "label": "Carpet & Rug Dealers-Oriental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912102",
        "yellow_page_code": "57916, 57914, 73115, 13609",
        "kiosk": false
    },
    {
        "value": "571305",
        "label": "Carpet & Rug Dealers-New",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912101",
        "yellow_page_code": "13703, 13608, 13714",
        "kiosk": false
    },
    {
        "value": "571306",
        "label": "Floor Materials",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912109",
        "yellow_page_code": "33400, 94217, 33316, 86111, 41398",
        "kiosk": false
    },
    {
        "value": "571307",
        "label": "Vinyl-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912115",
        "yellow_page_code": "91137, 91138, 91201",
        "kiosk": false
    },
    {
        "value": "571308",
        "label": "Floor Coverings-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912108",
        "yellow_page_code": "33206, 41376, 33319, 94223",
        "kiosk": false
    },
    {
        "value": "571309",
        "label": "Tile",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912113",
        "yellow_page_code": "86001, 33422, 86004",
        "kiosk": false
    },
    {
        "value": "571310",
        "label": "Floor Refinishing Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912110",
        "yellow_page_code": "92710, 33313, 33403, 33301",
        "kiosk": false
    },
    {
        "value": "571311",
        "label": "Carpet & Rug-Finishing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912103",
        "yellow_page_code": "13807",
        "kiosk": false
    },
    {
        "value": "571312",
        "label": "Mats & Matting-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912112",
        "yellow_page_code": "33329, 50506",
        "kiosk": false
    },
    {
        "value": "571313",
        "label": "Vinyl",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912114",
        "yellow_page_code": "91145, 91135",
        "kiosk": false
    },
    {
        "value": "571314",
        "label": "Carpet Consultants-Commercial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912106",
        "yellow_page_code": "13736",
        "kiosk": false
    },
    {
        "value": "571315",
        "label": "Carpet & Rug-Custom Made",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912105",
        "yellow_page_code": "13515, 13613",
        "kiosk": false
    },
    {
        "value": "571316",
        "label": "Tile-Custom Design",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912116",
        "yellow_page_code": "86005, 86020",
        "kiosk": false
    },
    {
        "value": "571317",
        "label": "Rugs-Southwestern",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912117",
        "yellow_page_code": "73112",
        "kiosk": false
    },
    {
        "value": "571318",
        "label": "Floors-Ceramic",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912118",
        "yellow_page_code": "33524",
        "kiosk": false
    },
    {
        "value": "571319",
        "label": "Rugs & Tapestries",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912119",
        "yellow_page_code": "73103, 73120",
        "kiosk": false
    },
    {
        "value": "571320",
        "label": "Floor-Laminates",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912120",
        "yellow_page_code": "33320, 33416",
        "kiosk": false
    },
    {
        "value": "571401",
        "label": "Drapery Installation Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912204",
        "yellow_page_code": "26005",
        "kiosk": false
    },
    {
        "value": "571402",
        "label": "Drapery Workrooms",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912205",
        "yellow_page_code": "26009",
        "kiosk": false
    },
    {
        "value": "571403",
        "label": "Interior Decorators/Designers Supls-Rtl",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45913008",
        "yellow_page_code": "41477, 43615, 23652",
        "kiosk": false
    },
    {
        "value": "571404",
        "label": "Quilting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45913017",
        "yellow_page_code": "59616, 69402",
        "kiosk": false
    },
    {
        "value": "571405",
        "label": "Drapery & Curtain Fixtures",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912203",
        "yellow_page_code": "26004",
        "kiosk": false
    },
    {
        "value": "571406",
        "label": "Drapery & Curtain Fabrics-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912202",
        "yellow_page_code": "26000",
        "kiosk": false
    },
    {
        "value": "571407",
        "label": "Draperies & Curtains-Retail/Custom Made",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912201",
        "yellow_page_code": "25904, 25912, 30281, 23001",
        "kiosk": false
    },
    {
        "value": "571408",
        "label": "Upholstery Fabrics-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45913022",
        "yellow_page_code": "90110, 90104, 35918",
        "kiosk": false
    },
    {
        "value": "571409",
        "label": "Slip Covers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45913020",
        "yellow_page_code": "78402, 48613, 78321, 78320",
        "kiosk": false
    },
    {
        "value": "571410",
        "label": "Vertical Blinds-Installation/Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912210",
        "yellow_page_code": "08429, 90903",
        "kiosk": false
    },
    {
        "value": "571411",
        "label": "Wall Decor",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31412002",
        "yellow_page_code": "91325",
        "kiosk": false
    },
    {
        "value": "571412",
        "label": "Curtain & Blind Fittings",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31412003",
        "yellow_page_code": "22930",
        "kiosk": false
    },
    {
        "value": "571901",
        "label": "Porch Shades",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912207",
        "yellow_page_code": "76101, 67404",
        "kiosk": false
    },
    {
        "value": "571902",
        "label": "Venetian Blinds-Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912208",
        "yellow_page_code": "90802, 91170, 08413",
        "kiosk": false
    },
    {
        "value": "571903",
        "label": "Shears & Scissors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912961",
        "yellow_page_code": "76108, 74707",
        "kiosk": false
    },
    {
        "value": "571904",
        "label": "Radiator Covers & Enclosures",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912958",
        "yellow_page_code": "69703",
        "kiosk": false
    },
    {
        "value": "571905",
        "label": "Weather Vanes",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912969",
        "yellow_page_code": "92802",
        "kiosk": false
    },
    {
        "value": "571906",
        "label": "Bedspreads",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912905",
        "yellow_page_code": "07304, 07437",
        "kiosk": false
    },
    {
        "value": "571907",
        "label": "Tapestries",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912966",
        "yellow_page_code": "83809",
        "kiosk": false
    },
    {
        "value": "571908",
        "label": "Wood Burning Stoves",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912972",
        "yellow_page_code": "94201, 81919, 41359, 81924, 40828, 81917, 81915, 81909, 81918, 94226, 81905",
        "kiosk": false
    },
    {
        "value": "571909",
        "label": "Screens-Decorative",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912960",
        "yellow_page_code": "74904, 76985",
        "kiosk": false
    },
    {
        "value": "571910",
        "label": "Rattan Reeds & Willows",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912959",
        "yellow_page_code": "70508, 70409",
        "kiosk": false
    },
    {
        "value": "571911",
        "label": "Blankets Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912957",
        "yellow_page_code": "69500, 08419",
        "kiosk": false
    },
    {
        "value": "571912",
        "label": "Pottery",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912956",
        "yellow_page_code": "67700",
        "kiosk": false
    },
    {
        "value": "571913",
        "label": "Pillows",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912954",
        "yellow_page_code": "85815, 65300",
        "kiosk": false
    },
    {
        "value": "571914",
        "label": "Wood Burning Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912971",
        "yellow_page_code": "94200",
        "kiosk": false
    },
    {
        "value": "571915",
        "label": "Window Shades Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912212",
        "yellow_page_code": "93608, 93642",
        "kiosk": false
    },
    {
        "value": "571916",
        "label": "Window Coverings-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912211",
        "yellow_page_code": "93604, 93640, 79309, 93644, 93619, 93646",
        "kiosk": false
    },
    {
        "value": "571917",
        "label": "Venetian Blinds-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912209",
        "yellow_page_code": "90708, 90902, 90899, 94231, 08422, 41460, 08409, 52508",
        "kiosk": false
    },
    {
        "value": "571918",
        "label": "Cutlery-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912923",
        "yellow_page_code": "45104, 23008",
        "kiosk": false
    },
    {
        "value": "571919",
        "label": "Cooking Utensils",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912920",
        "yellow_page_code": "21420, 30387, 21408",
        "kiosk": false
    },
    {
        "value": "571920",
        "label": "Clay Products",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32711015",
        "yellow_page_code": "18300",
        "kiosk": false
    },
    {
        "value": "571921",
        "label": "Chinaware & Glassware-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912915",
        "yellow_page_code": "30373, 15411, 15404, 37709, 15316, 37716, 22712",
        "kiosk": false
    },
    {
        "value": "571922",
        "label": "Carpet Sweepers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912911",
        "yellow_page_code": "13808",
        "kiosk": false
    },
    {
        "value": "571923",
        "label": "Bathroom Fixtures & Accessories-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912904",
        "yellow_page_code": "91281, 06906, 06909, 06920, 06925, 06921, 41459, 89317",
        "kiosk": false
    },
    {
        "value": "571924",
        "label": "Mirrors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912950",
        "yellow_page_code": "52707, 41445, 52702",
        "kiosk": false
    },
    {
        "value": "571925",
        "label": "Linens-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912948",
        "yellow_page_code": "83113, 47599, 93072, 47602, 47598",
        "kiosk": false
    },
    {
        "value": "571926",
        "label": "Lighting Fixtures-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912947",
        "yellow_page_code": "47400, 27613, 47312, 14614",
        "kiosk": false
    },
    {
        "value": "571927",
        "label": "Lamps & Lamp Shades-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912942",
        "yellow_page_code": "76020, 45702",
        "kiosk": false
    },
    {
        "value": "571928",
        "label": "Kitchen Accessories",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912937",
        "yellow_page_code": "44903, 03605, 58133",
        "kiosk": false
    },
    {
        "value": "571929",
        "label": "Housewares-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912936",
        "yellow_page_code": "42004, 30567",
        "kiosk": false
    },
    {
        "value": "571930",
        "label": "Glass-Carved Ornamental Beveled Etc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912934",
        "yellow_page_code": "37702, 58010, 37514, 37522, 37617",
        "kiosk": false
    },
    {
        "value": "571931",
        "label": "Glass Furniture Tops",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912933",
        "yellow_page_code": "37506",
        "kiosk": false
    },
    {
        "value": "571932",
        "label": "Fireplaces",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912930",
        "yellow_page_code": "32206, 32222, 41474, 36520",
        "kiosk": false
    },
    {
        "value": "571933",
        "label": "Fireplace Equipment-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912929",
        "yellow_page_code": "43665, 32202",
        "kiosk": false
    },
    {
        "value": "571934",
        "label": "Bath Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912903",
        "yellow_page_code": "07002",
        "kiosk": false
    },
    {
        "value": "571935",
        "label": "Candlesticks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912910",
        "yellow_page_code": "12712",
        "kiosk": false
    },
    {
        "value": "571936",
        "label": "Kitchen Tools-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912938",
        "yellow_page_code": "44911",
        "kiosk": false
    },
    {
        "value": "571937",
        "label": "Brass-Decorative Accessories",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912908",
        "yellow_page_code": "10305, 10318",
        "kiosk": false
    },
    {
        "value": "571938",
        "label": "Flatware-Table",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912931",
        "yellow_page_code": "32906",
        "kiosk": false
    },
    {
        "value": "571939",
        "label": "Lamp Shade Frames & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912941",
        "yellow_page_code": "45609",
        "kiosk": false
    },
    {
        "value": "571940",
        "label": "Stoneware",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912963",
        "yellow_page_code": "81701",
        "kiosk": false
    },
    {
        "value": "571941",
        "label": "Shelving-Household & Decorative",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912962",
        "yellow_page_code": "76225, 76305",
        "kiosk": false
    },
    {
        "value": "571942",
        "label": "Brushes-Retail (Household)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912909",
        "yellow_page_code": "11013",
        "kiosk": false
    },
    {
        "value": "571943",
        "label": "Fire Starters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912928",
        "yellow_page_code": "32201, 32210",
        "kiosk": false
    },
    {
        "value": "571944",
        "label": "Bamboo-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912902",
        "yellow_page_code": "06300",
        "kiosk": false
    },
    {
        "value": "571945",
        "label": "Crockery",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912922",
        "yellow_page_code": "22609",
        "kiosk": false
    },
    {
        "value": "571946",
        "label": "Coasters-Table & Serving",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912918",
        "yellow_page_code": "18913",
        "kiosk": false
    },
    {
        "value": "571947",
        "label": "Lamps-Incandescent",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912943",
        "yellow_page_code": "45603",
        "kiosk": false
    },
    {
        "value": "571948",
        "label": "Lamp Shade Covers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912940",
        "yellow_page_code": "45611",
        "kiosk": false
    },
    {
        "value": "571949",
        "label": "Lanterns",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912944",
        "yellow_page_code": "45909",
        "kiosk": false
    },
    {
        "value": "571950",
        "label": "Lighting Fixtures-Fluorescent",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912946",
        "yellow_page_code": "47309",
        "kiosk": false
    },
    {
        "value": "571951",
        "label": "Comforters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912919",
        "yellow_page_code": "19905",
        "kiosk": false
    },
    {
        "value": "571952",
        "label": "Tinware",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912967",
        "yellow_page_code": "86307",
        "kiosk": false
    },
    {
        "value": "571953",
        "label": "Copperwares",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912921",
        "yellow_page_code": "21602",
        "kiosk": false
    },
    {
        "value": "571954",
        "label": "Down Products",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912925",
        "yellow_page_code": "25712",
        "kiosk": false
    },
    {
        "value": "571955",
        "label": "Black Light Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912907",
        "yellow_page_code": "08400",
        "kiosk": false
    },
    {
        "value": "571956",
        "label": "Chair Seats",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912912",
        "yellow_page_code": "14605",
        "kiosk": false
    },
    {
        "value": "571957",
        "label": "Cheese Cutters & Graters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912913",
        "yellow_page_code": "14808",
        "kiosk": false
    },
    {
        "value": "571958",
        "label": "Chimney Accessories & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912914",
        "yellow_page_code": "15315, 15310",
        "kiosk": false
    },
    {
        "value": "571959",
        "label": "Chinaware Decorating & Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912916",
        "yellow_page_code": "15405",
        "kiosk": false
    },
    {
        "value": "571960",
        "label": "Mini-Blinds-Supplies & Parts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912206",
        "yellow_page_code": "52518",
        "kiosk": false
    },
    {
        "value": "571961",
        "label": "Mattress & Comforter Covers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912949",
        "yellow_page_code": "50603",
        "kiosk": false
    },
    {
        "value": "571962",
        "label": "Mugs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912952",
        "yellow_page_code": "54514, 81120",
        "kiosk": false
    },
    {
        "value": "571964",
        "label": "Wood-Heating",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912973",
        "yellow_page_code": "94216, 94224",
        "kiosk": false
    },
    {
        "value": "571965",
        "label": "Enameled Ware",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912927",
        "yellow_page_code": "28612",
        "kiosk": false
    },
    {
        "value": "571966",
        "label": "Electric Equipment-Fixtures",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912926",
        "yellow_page_code": "27507",
        "kiosk": false
    },
    {
        "value": "571967",
        "label": "Bench Drawers & Legs-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912906",
        "yellow_page_code": "07802",
        "kiosk": false
    },
    {
        "value": "571968",
        "label": "Towels-Cloth-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912968",
        "yellow_page_code": "87409",
        "kiosk": false
    },
    {
        "value": "571969",
        "label": "Window Treatments",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912970",
        "yellow_page_code": "30573, 93623",
        "kiosk": false
    },
    {
        "value": "571970",
        "label": "Ladles",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912939",
        "yellow_page_code": "45413",
        "kiosk": false
    },
    {
        "value": "571971",
        "label": "Sun Lamps",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912964",
        "yellow_page_code": "82305",
        "kiosk": false
    },
    {
        "value": "571972",
        "label": "Table Cloths & Covers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912965",
        "yellow_page_code": "83009, 59539",
        "kiosk": false
    },
    {
        "value": "571973",
        "label": "Gourmet Cookware",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912935",
        "yellow_page_code": "38301",
        "kiosk": false
    },
    {
        "value": "571974",
        "label": "Paperweights",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912953",
        "yellow_page_code": "59204",
        "kiosk": false
    },
    {
        "value": "571975",
        "label": "Furnace Arches-Suspended",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912978",
        "yellow_page_code": "35504",
        "kiosk": false
    },
    {
        "value": "571976",
        "label": "Stoves Wood Parts & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912980",
        "yellow_page_code": "81922",
        "kiosk": false
    },
    {
        "value": "571978",
        "label": "Gas Logs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912932",
        "yellow_page_code": "36608",
        "kiosk": false
    },
    {
        "value": "571979",
        "label": "Lighting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912945",
        "yellow_page_code": "47301, 47410, 30120, 92983, 41439, 47319",
        "kiosk": false
    },
    {
        "value": "571980",
        "label": "Door Chimes & Bells",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912924",
        "yellow_page_code": "25537",
        "kiosk": false
    },
    {
        "value": "571981",
        "label": "Plastic Products-Ornamental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912955",
        "yellow_page_code": "66403",
        "kiosk": false
    },
    {
        "value": "571982",
        "label": "Aluminum Products-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912901",
        "yellow_page_code": "02099",
        "kiosk": false
    },
    {
        "value": "571983",
        "label": "Tools-Household",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912974",
        "yellow_page_code": "87017",
        "kiosk": false
    },
    {
        "value": "571984",
        "label": "Juicing Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912975",
        "yellow_page_code": "44520",
        "kiosk": false
    },
    {
        "value": "571985",
        "label": "Barbecue Equipment & Supplies-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912976",
        "yellow_page_code": "78523, 06514, 06516",
        "kiosk": false
    },
    {
        "value": "571986",
        "label": "Tableware",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912977",
        "yellow_page_code": "83010",
        "kiosk": false
    },
    {
        "value": "571987",
        "label": "Tupperware",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912917",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "571988",
        "label": "Home Accessories & Furnishings",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912981",
        "yellow_page_code": "35626, 41244",
        "kiosk": false
    },
    {
        "value": "571989",
        "label": "Hat Racks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912982",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "571999",
        "label": "Miscellaneous Homefurnishings Stores",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912951",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "572201",
        "label": "Microwave Ovens",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921014",
        "yellow_page_code": "52300, 52222, 52210",
        "kiosk": false
    },
    {
        "value": "572202",
        "label": "Appliances-Household-Major-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921002",
        "yellow_page_code": "02924, 02907, 27401, 03019, 03024, 02918, 44926, 25495, 41939",
        "kiosk": false
    },
    {
        "value": "572203",
        "label": "Gas Appliances",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921012",
        "yellow_page_code": "36500",
        "kiosk": false
    },
    {
        "value": "572204",
        "label": "Garbage Disposal Equipment-Household",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921011",
        "yellow_page_code": "36202, 91916, 41942, 36195",
        "kiosk": false
    },
    {
        "value": "572205",
        "label": "Fans-Household",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921009",
        "yellow_page_code": "30435, 30707, 14211, 30712, 90816, 93430",
        "kiosk": false
    },
    {
        "value": "572206",
        "label": "Fans-Supplies & Parts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921010",
        "yellow_page_code": "30704, 30702",
        "kiosk": false
    },
    {
        "value": "572207",
        "label": "Appliances-Household-Small-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921003",
        "yellow_page_code": "02930, 02909, 33716, 27402",
        "kiosk": false
    },
    {
        "value": "572208",
        "label": "Dishwashing Machines-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921007",
        "yellow_page_code": "24908",
        "kiosk": false
    },
    {
        "value": "572209",
        "label": "Dehumidifying Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921006",
        "yellow_page_code": "23706, 23720",
        "kiosk": false
    },
    {
        "value": "572210",
        "label": "Coolers-Evaporative",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921005",
        "yellow_page_code": "21409",
        "kiosk": false
    },
    {
        "value": "572211",
        "label": "Compactors-Waste-Household-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921004",
        "yellow_page_code": "20108, 71303, 88419, 20301, 88430",
        "kiosk": false
    },
    {
        "value": "572212",
        "label": "Air Conditioning Room Units-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921001",
        "yellow_page_code": "00904, 01008",
        "kiosk": false
    },
    {
        "value": "572213",
        "label": "Washing Machines Dryers/Ironers-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921027",
        "yellow_page_code": "91704",
        "kiosk": false
    },
    {
        "value": "572214",
        "label": "Washers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921026",
        "yellow_page_code": "91608, 29885",
        "kiosk": false
    },
    {
        "value": "572215",
        "label": "Vacuum Cleaners-Supplies & Parts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921025",
        "yellow_page_code": "90402",
        "kiosk": false
    },
    {
        "value": "572216",
        "label": "Vacuum Cleaners-Household-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921024",
        "yellow_page_code": "90302, 90303",
        "kiosk": false
    },
    {
        "value": "572217",
        "label": "Sewing Machines-Supplies & Attachments",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921023",
        "yellow_page_code": "76002",
        "kiosk": false
    },
    {
        "value": "572218",
        "label": "Sewing Machines-Household",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921022",
        "yellow_page_code": "75903, 75914, 76004, 75906",
        "kiosk": false
    },
    {
        "value": "572219",
        "label": "Refrigerators & Freezers-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921020",
        "yellow_page_code": "71502, 34514, 34515",
        "kiosk": false
    },
    {
        "value": "572220",
        "label": "Ranges & Stoves-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921019",
        "yellow_page_code": "81923, 70402, 81914",
        "kiosk": false
    },
    {
        "value": "572221",
        "label": "Range Hoods Canopies & Vents",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921018",
        "yellow_page_code": "70400",
        "kiosk": false
    },
    {
        "value": "572222",
        "label": "Pumps-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921016",
        "yellow_page_code": "69203",
        "kiosk": false
    },
    {
        "value": "572223",
        "label": "Grills-Gas & Electric",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921013",
        "yellow_page_code": "39009, 36516",
        "kiosk": false
    },
    {
        "value": "572224",
        "label": "Dryers-Clothes",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921008",
        "yellow_page_code": "26812, 89335",
        "kiosk": false
    },
    {
        "value": "572225",
        "label": "Quilting Machines",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921017",
        "yellow_page_code": "69403, 69406, 49111",
        "kiosk": false
    },
    {
        "value": "572228",
        "label": "Ovens",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921015",
        "yellow_page_code": "21419, 58115",
        "kiosk": false
    },
    {
        "value": "572229",
        "label": "Sewing Cabinets",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921021",
        "yellow_page_code": "75900",
        "kiosk": false
    },
    {
        "value": "572230",
        "label": "Air Purification & Filtration Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921029",
        "yellow_page_code": "01209, 00831",
        "kiosk": false
    },
    {
        "value": "572232",
        "label": "Air Conditioning-Portable",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921030",
        "yellow_page_code": "00922",
        "kiosk": false
    },
    {
        "value": "572233",
        "label": "Grills Barbecue",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921031",
        "yellow_page_code": "39016",
        "kiosk": false
    },
    {
        "value": "573101",
        "label": "Antennas",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921033",
        "yellow_page_code": "84705, 02708, 02637, 02706, 84700",
        "kiosk": false
    },
    {
        "value": "573102",
        "label": "Television & Radio Supplies & Parts-Rtl",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921056",
        "yellow_page_code": "70807, 84604, 69905",
        "kiosk": false
    },
    {
        "value": "573103",
        "label": "Television & Radio-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921057",
        "yellow_page_code": "84602, 29860, 84713, 84627, 69810",
        "kiosk": false
    },
    {
        "value": "573104",
        "label": "Tape Recorders/Players-Sound/Video Eqpt",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921053",
        "yellow_page_code": "83708, 83710",
        "kiosk": false
    },
    {
        "value": "573105",
        "label": "Stereophonic & High Fidelity Equip-Dlrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921052",
        "yellow_page_code": "81307, 81300, 81214, 81305, 81215",
        "kiosk": false
    },
    {
        "value": "573106",
        "label": "Sound Systems & Equipment-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921050",
        "yellow_page_code": "21325, 79509, 93027",
        "kiosk": false
    },
    {
        "value": "573107",
        "label": "Satellite Equipment & Systems-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921049",
        "yellow_page_code": "73929, 73905, 73904, 73924, 73912, 73920, 73923, 84810, 73925",
        "kiosk": false
    },
    {
        "value": "573108",
        "label": "Recorders-Sound & Video",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921048",
        "yellow_page_code": "70809",
        "kiosk": false
    },
    {
        "value": "573109",
        "label": "Radios-Citizens Band",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921047",
        "yellow_page_code": "70004, 18015, 14140",
        "kiosk": false
    },
    {
        "value": "573110",
        "label": "Radio Dealers-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921046",
        "yellow_page_code": "69802",
        "kiosk": false
    },
    {
        "value": "573111",
        "label": "Video Recorders & Players",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921064",
        "yellow_page_code": "91139, 91009",
        "kiosk": false
    },
    {
        "value": "573112",
        "label": "Video Games",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921063",
        "yellow_page_code": "91100, 91148, 20292",
        "kiosk": false
    },
    {
        "value": "573113",
        "label": "Audio-Visual Equipment-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921034",
        "yellow_page_code": "04320, 92211, 04307",
        "kiosk": false
    },
    {
        "value": "573114",
        "label": "Metal Locating Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921043",
        "yellow_page_code": "88490, 51022, 51715, 51716, 68637, 51506",
        "kiosk": false
    },
    {
        "value": "573115",
        "label": "Video Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921062",
        "yellow_page_code": "91111, 91030, 91022",
        "kiosk": false
    },
    {
        "value": "573116",
        "label": "Karaoke Machines & Tapes",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921040",
        "yellow_page_code": "44695, 44694, 44697",
        "kiosk": false
    },
    {
        "value": "573117",
        "label": "Electronic Equipment & Supplies-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921038",
        "yellow_page_code": "30427, 27915, 41215, 28212, 30569, 28037, 28115, 05324",
        "kiosk": false
    },
    {
        "value": "573118",
        "label": "Automobile Stereo Equip & Accessories",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133015",
        "yellow_page_code": "05211",
        "kiosk": false
    },
    {
        "value": "573119",
        "label": "Loud Speakers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921041",
        "yellow_page_code": "48713",
        "kiosk": false
    },
    {
        "value": "573120",
        "label": "Television Satellite Service & Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921068",
        "yellow_page_code": "84814",
        "kiosk": false
    },
    {
        "value": "573121",
        "label": "Home Theatre Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921058",
        "yellow_page_code": "84904, 41226, 81220, 41270, 84511, 41368",
        "kiosk": false
    },
    {
        "value": "573122",
        "label": "Marine Electronic Equip & Supls-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921042",
        "yellow_page_code": "50039",
        "kiosk": false
    },
    {
        "value": "573123",
        "label": "Record Players",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921045",
        "yellow_page_code": "70831, 60707",
        "kiosk": false
    },
    {
        "value": "573124",
        "label": "Electronic Retailing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921039",
        "yellow_page_code": "89511, 28024, 20927",
        "kiosk": false
    },
    {
        "value": "573125",
        "label": "Electronic Equip/Supls-Process Controls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921037",
        "yellow_page_code": "28026",
        "kiosk": false
    },
    {
        "value": "573126",
        "label": "Transformers-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921060",
        "yellow_page_code": "88215",
        "kiosk": false
    },
    {
        "value": "573127",
        "label": "Disc Jockey Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921036",
        "yellow_page_code": "24895",
        "kiosk": false
    },
    {
        "value": "573128",
        "label": "Motion Picture Equipment & Supplies-Home",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921044",
        "yellow_page_code": "53911",
        "kiosk": false
    },
    {
        "value": "573129",
        "label": "Speakers-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921051",
        "yellow_page_code": "79710",
        "kiosk": false
    },
    {
        "value": "573130",
        "label": "Radios-Two Way-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921061",
        "yellow_page_code": "70014",
        "kiosk": false
    },
    {
        "value": "573131",
        "label": "Time Recorders",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921059",
        "yellow_page_code": "86137",
        "kiosk": false
    },
    {
        "value": "573132",
        "label": "Dvd Sales & Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921065",
        "yellow_page_code": "27015",
        "kiosk": false
    },
    {
        "value": "573133",
        "label": "Cassette Recorders Dealers & Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921066",
        "yellow_page_code": "13989",
        "kiosk": false
    },
    {
        "value": "573134",
        "label": "Cellular Ringtones Games & Graphics",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921067",
        "yellow_page_code": "14323",
        "kiosk": false
    },
    {
        "value": "573135",
        "label": "Navigational Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44133048",
        "yellow_page_code": "55215, 04838, 73932",
        "kiosk": false
    },
    {
        "value": "573136",
        "label": "Electronic Equipment & Supplies Kiosks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921097",
        "yellow_page_code": "",
        "kiosk": true
    },
    {
        "value": "573137",
        "label": "Sound Systems & Equipment-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921098",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "573402",
        "label": "Computer Parts & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921071",
        "yellow_page_code": "20321, 20246, 20276, 30382, 20293, 20242",
        "kiosk": false
    },
    {
        "value": "573403",
        "label": "Publishing-Desktop",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921078",
        "yellow_page_code": "24320, 24311, 69011",
        "kiosk": false
    },
    {
        "value": "573405",
        "label": "Computers-Dealers-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921073",
        "yellow_page_code": "20358, 20360, 20342, 20315, 20346, 71220, 20364",
        "kiosk": false
    },
    {
        "value": "573406",
        "label": "Desktop Publishing Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921075",
        "yellow_page_code": "24313, 24316",
        "kiosk": false
    },
    {
        "value": "573407",
        "label": "Computer & Equipment Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921069",
        "yellow_page_code": "20226, 20314, 20309, 20255, 20263, 82321, 20352, 20252, 20336, 25009, 94512, 68317, 20277, 20273, 30380, 20368, 20264, 43746, 20370",
        "kiosk": false
    },
    {
        "value": "573408",
        "label": "Plotters Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921077",
        "yellow_page_code": "66703, 66701",
        "kiosk": false
    },
    {
        "value": "573409",
        "label": "Data Storage Equipment & Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921074",
        "yellow_page_code": "23415",
        "kiosk": false
    },
    {
        "value": "573410",
        "label": "Computer Music Products",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921070",
        "yellow_page_code": "20214",
        "kiosk": false
    },
    {
        "value": "573413",
        "label": "Memory Systems & Components",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921076",
        "yellow_page_code": "51216",
        "kiosk": false
    },
    {
        "value": "573414",
        "label": "Computer Graphics Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921079",
        "yellow_page_code": "20266",
        "kiosk": false
    },
    {
        "value": "573415",
        "label": "Academic Software",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921080",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "573416",
        "label": "Medical Software",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921081",
        "yellow_page_code": "51045",
        "kiosk": false
    },
    {
        "value": "573417",
        "label": "Filtering-Internet & Email",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921082",
        "yellow_page_code": "31709",
        "kiosk": false
    },
    {
        "value": "573418",
        "label": "Computer Software Application Svc Prvdrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921083",
        "yellow_page_code": "20376, 03041, 20363",
        "kiosk": false
    },
    {
        "value": "573419",
        "label": "Computer Applications Retail-Whls Distr",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921085",
        "yellow_page_code": "20289",
        "kiosk": false
    },
    {
        "value": "573420",
        "label": "Custom Relationship Management Software",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921086",
        "yellow_page_code": "23150, 22720",
        "kiosk": false
    },
    {
        "value": "573421",
        "label": "Wifi Equipment & Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921087",
        "yellow_page_code": "93451",
        "kiosk": false
    },
    {
        "value": "573501",
        "label": "Records Tapes & Compact Discs-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921094",
        "yellow_page_code": "70908, 54816, 14228, 54612, 20141, 14217, 20117, 20142, 27016, 01804, 20106, 70914",
        "kiosk": false
    },
    {
        "value": "573502",
        "label": "Video Tapes Discs & Cassettes",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921095",
        "yellow_page_code": "91035, 91103",
        "kiosk": false
    },
    {
        "value": "573505",
        "label": "Movies Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921096",
        "yellow_page_code": "54418",
        "kiosk": false
    },
    {
        "value": "573601",
        "label": "Pianos-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45914013",
        "yellow_page_code": "64907",
        "kiosk": false
    },
    {
        "value": "573602",
        "label": "Pianos",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45914012",
        "yellow_page_code": "64902",
        "kiosk": false
    },
    {
        "value": "573603",
        "label": "Organs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45914010",
        "yellow_page_code": "57904",
        "kiosk": false
    },
    {
        "value": "573604",
        "label": "Violins",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45914014",
        "yellow_page_code": "91202",
        "kiosk": false
    },
    {
        "value": "573605",
        "label": "Amplifiers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45914002",
        "yellow_page_code": "68805, 02300",
        "kiosk": false
    },
    {
        "value": "573606",
        "label": "Accordions",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45914001",
        "yellow_page_code": "00006",
        "kiosk": false
    },
    {
        "value": "573607",
        "label": "Musical Instruments-Supplies & Acces",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45914009",
        "yellow_page_code": "55000, 54915",
        "kiosk": false
    },
    {
        "value": "573608",
        "label": "Musical Instruments-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45914008",
        "yellow_page_code": "44811, 70921, 29960, 54617, 89810, 54906",
        "kiosk": false
    },
    {
        "value": "573609",
        "label": "Music Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45914006",
        "yellow_page_code": "54713, 54904, 76140, 54723, 54818",
        "kiosk": false
    },
    {
        "value": "573610",
        "label": "Microphones",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45914005",
        "yellow_page_code": "52204",
        "kiosk": false
    },
    {
        "value": "573611",
        "label": "Guitars",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45914003",
        "yellow_page_code": "39308",
        "kiosk": false
    },
    {
        "value": "573612",
        "label": "Megaphones",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45914004",
        "yellow_page_code": "51118",
        "kiosk": false
    },
    {
        "value": "573613",
        "label": "Organs-Supplies & Parts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45914011",
        "yellow_page_code": "57905",
        "kiosk": false
    },
    {
        "value": "573614",
        "label": "Music Rolls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45914007",
        "yellow_page_code": "54905",
        "kiosk": false
    },
    {
        "value": "573615",
        "label": "Karaoke Machines-Rental Sales & Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45914015",
        "yellow_page_code": "44698",
        "kiosk": false
    },
    {
        "value": "581201",
        "label": "Virtual Kitchens",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72251302",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "581202",
        "label": "Food Service Kiosk",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72251303",
        "yellow_page_code": "",
        "kiosk": true
    },
    {
        "value": "581203",
        "label": "Ice Cream Parlors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72251512",
        "yellow_page_code": "23208, 42402, 79106, 42408, 42314, 42415, 71960, 59545, 78628, 12239, 79104, 42317, 42306",
        "kiosk": false
    },
    {
        "value": "581206",
        "label": "Foods-Carry Out",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72251301",
        "yellow_page_code": "34000, 33921, 71926, 71944, 83309, 65915, 26342, 30990, 23792, 15199, 71931, 13901, 33712",
        "kiosk": false
    },
    {
        "value": "581208",
        "label": "Restaurants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72251117",
        "yellow_page_code": "41806, 73808, 49738, 30252, 08325, 10303, 11429, 49020, 83735, 43685, 75126, 71958, 71959, 71961, 15099, 71963, 71965, 23802, 24211, 38912, 12206, 85503, 15500, 24811, 06502, 26502, 32406, 44004, 44201, 52106, 82400, 06511, 33820, 33803, 33825, 72002, 48715, 31013, 29912, 27115, 20001, 71208, 20004, 15200, 44003, 32416, 71924, 73812, 12220, 38991, 71927, 93020, 71945, 72028, 22214, 71949, 39808, 84201, 84139, 82610, 24815, 71942, 12208, 71946",
        "kiosk": false
    },
    {
        "value": "581212",
        "label": "Caterers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72232001",
        "yellow_page_code": "70813, 06406, 14100, 09804, 14036, 18210, 14016, 14113, 92910, 72023, 92923, 92932, 92941, 18230, 34902, 06410, 59518, 06404, 59532, 92996",
        "kiosk": false
    },
    {
        "value": "581220",
        "label": "Restaurant Management",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72231009",
        "yellow_page_code": "71940, 72000, 30355",
        "kiosk": false
    },
    {
        "value": "581222",
        "label": "Pizza",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72251115",
        "yellow_page_code": "66000",
        "kiosk": false
    },
    {
        "value": "581228",
        "label": "Coffee Shops",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72251505",
        "yellow_page_code": "19309, 67620, 83418, 19290, 71929, 19316, 19220, 19310",
        "kiosk": false
    },
    {
        "value": "581248",
        "label": "Juice Bars",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72251518",
        "yellow_page_code": "44522, 78517",
        "kiosk": false
    },
    {
        "value": "581301",
        "label": "Bars",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72241001",
        "yellow_page_code": "06707, 71934, 93813, 80019, 84100, 47905",
        "kiosk": false
    },
    {
        "value": "581302",
        "label": "Discotheques",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72241005",
        "yellow_page_code": "24902",
        "kiosk": false
    },
    {
        "value": "581303",
        "label": "Cocktail Lounges",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72241003",
        "yellow_page_code": "48719, 71957, 92927, 48803, 19202",
        "kiosk": false
    },
    {
        "value": "581304",
        "label": "Night Clubs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72241006",
        "yellow_page_code": "93043, 71962, 55604, 23329",
        "kiosk": false
    },
    {
        "value": "581305",
        "label": "Pubs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72241008",
        "yellow_page_code": "06423, 07807, 55308, 69012",
        "kiosk": false
    },
    {
        "value": "581307",
        "label": "Comedy Clubs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72241004",
        "yellow_page_code": "19916",
        "kiosk": false
    },
    {
        "value": "581308",
        "label": "Night Clubs Information Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72241007",
        "yellow_page_code": "55511",
        "kiosk": false
    },
    {
        "value": "581309",
        "label": "Karaoke Clubs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72241009",
        "yellow_page_code": "44693",
        "kiosk": false
    },
    {
        "value": "591201",
        "label": "Medicines-Patent & Proprietary",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45611007",
        "yellow_page_code": "51106",
        "kiosk": false
    },
    {
        "value": "591202",
        "label": "Health Care Products",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45611006",
        "yellow_page_code": "40408, 40527, 40528",
        "kiosk": false
    },
    {
        "value": "591203",
        "label": "First Aid Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45611005",
        "yellow_page_code": "41259, 32310, 28414, 32306",
        "kiosk": false
    },
    {
        "value": "591204",
        "label": "Elastic Stockings",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45611004",
        "yellow_page_code": "27306",
        "kiosk": false
    },
    {
        "value": "591205",
        "label": "Pharmacies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45611009",
        "yellow_page_code": "68026, 60614, 67942, 26600, 42898, 26617, 60702, 26602, 60623, 29979, 60700, 51145, 24953, 42795",
        "kiosk": false
    },
    {
        "value": "591206",
        "label": "Toilet Articles",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45611011",
        "yellow_page_code": "86800",
        "kiosk": false
    },
    {
        "value": "591207",
        "label": "Pharmaceutical Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45611008",
        "yellow_page_code": "60619, 21157, 60650, 60607",
        "kiosk": false
    },
    {
        "value": "591208",
        "label": "Razor Sharpeners & Stropper",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45611010",
        "yellow_page_code": "70601",
        "kiosk": false
    },
    {
        "value": "591209",
        "label": "Allergy Resistant Products",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45611001",
        "yellow_page_code": "01907",
        "kiosk": false
    },
    {
        "value": "591210",
        "label": "Convalescent Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45611002",
        "yellow_page_code": "21307",
        "kiosk": false
    },
    {
        "value": "591211",
        "label": "Drugs-Crude",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45611003",
        "yellow_page_code": "26512",
        "kiosk": false
    },
    {
        "value": "591212",
        "label": "Homeopathic Remedies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45611012",
        "yellow_page_code": "41239",
        "kiosk": false
    },
    {
        "value": "591213",
        "label": "Sun Tan Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45611013",
        "yellow_page_code": "82219",
        "kiosk": false
    },
    {
        "value": "591214",
        "label": "Compounding",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45611014",
        "yellow_page_code": "20180",
        "kiosk": false
    },
    {
        "value": "591215",
        "label": "Pharmaceutical Kiosk",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45611015",
        "yellow_page_code": "",
        "kiosk": true
    },
    {
        "value": "592101",
        "label": "Cocktail Mixes",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44532002",
        "yellow_page_code": "19204",
        "kiosk": false
    },
    {
        "value": "592102",
        "label": "Liquors-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44532004",
        "yellow_page_code": "07808, 47906, 58307, 47907, 47912, 47913, 47898, 47900",
        "kiosk": false
    },
    {
        "value": "592103",
        "label": "Wines-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44532005",
        "yellow_page_code": "93721, 93521, 93806, 93821",
        "kiosk": false
    },
    {
        "value": "592104",
        "label": "Beer & Ale-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44532001",
        "yellow_page_code": "07506",
        "kiosk": false
    },
    {
        "value": "592105",
        "label": "Cordials",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44532003",
        "yellow_page_code": "21703",
        "kiosk": false
    },
    {
        "value": "592106",
        "label": "Daiquiri Shops",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44532006",
        "yellow_page_code": "23205",
        "kiosk": false
    },
    {
        "value": "592107",
        "label": "Tasting Rooms",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44532007",
        "yellow_page_code": "34090",
        "kiosk": false
    },
    {
        "value": "593201",
        "label": "Book Dealers-Used & Rare",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951008",
        "yellow_page_code": "09208, 09226",
        "kiosk": false
    },
    {
        "value": "593202",
        "label": "Antiques-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951001",
        "yellow_page_code": "35617, 02801, 02802, 02713, 03203, 02805, 87519",
        "kiosk": false
    },
    {
        "value": "593203",
        "label": "Electric Motors-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951016",
        "yellow_page_code": "27800",
        "kiosk": false
    },
    {
        "value": "593204",
        "label": "Consignment Shops",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951014",
        "yellow_page_code": "15138, 21001, 21002, 71802, 20998",
        "kiosk": false
    },
    {
        "value": "593205",
        "label": "Clothing-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951013",
        "yellow_page_code": "18810, 30376, 18804, 30375, 18738, 18736, 18800, 18799",
        "kiosk": false
    },
    {
        "value": "593206",
        "label": "Carpet & Rug Dealers-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951010",
        "yellow_page_code": "41935, 13700",
        "kiosk": false
    },
    {
        "value": "593207",
        "label": "Building Materials-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951009",
        "yellow_page_code": "11221, 11300",
        "kiosk": false
    },
    {
        "value": "593208",
        "label": "Appliances-Household-Major-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951004",
        "yellow_page_code": "02925, 03022, 03007, 02923",
        "kiosk": false
    },
    {
        "value": "593209",
        "label": "Antiques-Reproductions",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951002",
        "yellow_page_code": "02806",
        "kiosk": false
    },
    {
        "value": "593210",
        "label": "Antiques-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951003",
        "yellow_page_code": "02808",
        "kiosk": false
    },
    {
        "value": "593211",
        "label": "Office Furniture & Equip-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951028",
        "yellow_page_code": "56304, 56319",
        "kiosk": false
    },
    {
        "value": "593212",
        "label": "Musical Instruments-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951027",
        "yellow_page_code": "55001, 26625",
        "kiosk": false
    },
    {
        "value": "593213",
        "label": "Magazines-Used & Rare",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951024",
        "yellow_page_code": "49401",
        "kiosk": false
    },
    {
        "value": "593214",
        "label": "Lumber-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951023",
        "yellow_page_code": "49006",
        "kiosk": false
    },
    {
        "value": "593215",
        "label": "Junk-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951021",
        "yellow_page_code": "74732, 44606, 30245",
        "kiosk": false
    },
    {
        "value": "593216",
        "label": "Jewelry-Bought",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951020",
        "yellow_page_code": "44207",
        "kiosk": false
    },
    {
        "value": "593217",
        "label": "Furniture-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951019",
        "yellow_page_code": "35601, 35908, 35703, 35618, 35623, 30329",
        "kiosk": false
    },
    {
        "value": "593218",
        "label": "Flea Markets",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951017",
        "yellow_page_code": "33006",
        "kiosk": false
    },
    {
        "value": "593219",
        "label": "Washing Machines Dryers/Ironers-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951046",
        "yellow_page_code": "91709",
        "kiosk": false
    },
    {
        "value": "593220",
        "label": "Vintage Clothing Stores",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951045",
        "yellow_page_code": "91200",
        "kiosk": false
    },
    {
        "value": "593221",
        "label": "Tools-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951041",
        "yellow_page_code": "87206",
        "kiosk": false
    },
    {
        "value": "593222",
        "label": "Thrift Shops",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951040",
        "yellow_page_code": "85808",
        "kiosk": false
    },
    {
        "value": "593223",
        "label": "Television & Radio Dealers-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951039",
        "yellow_page_code": "84702, 84619",
        "kiosk": false
    },
    {
        "value": "593224",
        "label": "Swap Meets & Shops",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951038",
        "yellow_page_code": "82604",
        "kiosk": false
    },
    {
        "value": "593225",
        "label": "Store Fixtures-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951037",
        "yellow_page_code": "81808",
        "kiosk": false
    },
    {
        "value": "593226",
        "label": "Second Hand Stores",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951033",
        "yellow_page_code": "42933, 35721, 75208",
        "kiosk": false
    },
    {
        "value": "593227",
        "label": "Refrigerators & Freezers-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951031",
        "yellow_page_code": "71506",
        "kiosk": false
    },
    {
        "value": "593228",
        "label": "Ranges & Ovens-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951029",
        "yellow_page_code": "70401",
        "kiosk": false
    },
    {
        "value": "593229",
        "label": "Pawnbrokers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52229915",
        "yellow_page_code": "59904, 59901",
        "kiosk": false
    },
    {
        "value": "593230",
        "label": "Used Merchandise Stores",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951043",
        "yellow_page_code": "71999, 90111",
        "kiosk": false
    },
    {
        "value": "593231",
        "label": "Video Games-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951048",
        "yellow_page_code": "91039",
        "kiosk": false
    },
    {
        "value": "593232",
        "label": "Bicycles-Dealers-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951007",
        "yellow_page_code": "08005",
        "kiosk": false
    },
    {
        "value": "593233",
        "label": "Records Tapes Cd's Used & Vintage",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951030",
        "yellow_page_code": "70912, 20139, 70910, 70905",
        "kiosk": false
    },
    {
        "value": "593234",
        "label": "Rummage Sale Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951032",
        "yellow_page_code": "73200",
        "kiosk": false
    },
    {
        "value": "593235",
        "label": "Clothing-Collectible Period & Vintage",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951012",
        "yellow_page_code": "18719, 18707",
        "kiosk": false
    },
    {
        "value": "593236",
        "label": "Baskets-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951006",
        "yellow_page_code": "06807",
        "kiosk": false
    },
    {
        "value": "593237",
        "label": "Appliances-Household-Small-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951005",
        "yellow_page_code": "02911",
        "kiosk": false
    },
    {
        "value": "593238",
        "label": "Fur-Dealers-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951018",
        "yellow_page_code": "35107",
        "kiosk": false
    },
    {
        "value": "593239",
        "label": "Lighting Fixtures-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951022",
        "yellow_page_code": "47403",
        "kiosk": false
    },
    {
        "value": "593240",
        "label": "Movie Memorabilia",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951026",
        "yellow_page_code": "54416",
        "kiosk": false
    },
    {
        "value": "593241",
        "label": "Childrens & Infants Wear-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951011",
        "yellow_page_code": "15205",
        "kiosk": false
    },
    {
        "value": "593242",
        "label": "Memorabilia",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951025",
        "yellow_page_code": "51217, 72430",
        "kiosk": false
    },
    {
        "value": "593243",
        "label": "Antique Silver",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951035",
        "yellow_page_code": "77709",
        "kiosk": false
    },
    {
        "value": "593244",
        "label": "Sporting Goods-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951036",
        "yellow_page_code": "79940",
        "kiosk": false
    },
    {
        "value": "593245",
        "label": "Valves-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951044",
        "yellow_page_code": "90508",
        "kiosk": false
    },
    {
        "value": "593246",
        "label": "Windows-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951047",
        "yellow_page_code": "93710",
        "kiosk": false
    },
    {
        "value": "593247",
        "label": "Doors-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951015",
        "yellow_page_code": "25707",
        "kiosk": false
    },
    {
        "value": "593248",
        "label": "Shoes-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951034",
        "yellow_page_code": "76907",
        "kiosk": false
    },
    {
        "value": "593249",
        "label": "Typewriters-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951042",
        "yellow_page_code": "89709",
        "kiosk": false
    },
    {
        "value": "593251",
        "label": "Men's Clothing & Furnishings-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951049",
        "yellow_page_code": "51228",
        "kiosk": false
    },
    {
        "value": "593252",
        "label": "Music Stores-Vintage",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951050",
        "yellow_page_code": "54721",
        "kiosk": false
    },
    {
        "value": "593253",
        "label": "Pawn Tickets-Bought",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52229917",
        "yellow_page_code": "59903",
        "kiosk": false
    },
    {
        "value": "593254",
        "label": "Formal Wear-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951051",
        "yellow_page_code": "34005",
        "kiosk": false
    },
    {
        "value": "593255",
        "label": "Radio Dealers Antique & Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951052",
        "yellow_page_code": "69806",
        "kiosk": false
    },
    {
        "value": "593256",
        "label": "Maps Antique",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951053",
        "yellow_page_code": "49964",
        "kiosk": false
    },
    {
        "value": "593257",
        "label": "Appliance Demanufacturing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951054",
        "yellow_page_code": "02920",
        "kiosk": false
    },
    {
        "value": "593258",
        "label": "Historical Reproductions",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951055",
        "yellow_page_code": "41055",
        "kiosk": false
    },
    {
        "value": "593259",
        "label": "Remanufactured & Refurbished Goods",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45951056",
        "yellow_page_code": "71635",
        "kiosk": false
    },
    {
        "value": "594101",
        "label": "Nettings-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911035",
        "yellow_page_code": "55414, 55403",
        "kiosk": false
    },
    {
        "value": "594102",
        "label": "Parachutes",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911037",
        "yellow_page_code": "59302",
        "kiosk": false
    },
    {
        "value": "594103",
        "label": "Running & Jogging Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911040",
        "yellow_page_code": "73204",
        "kiosk": false
    },
    {
        "value": "594104",
        "label": "Wind Surfing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911067",
        "yellow_page_code": "93705",
        "kiosk": false
    },
    {
        "value": "594105",
        "label": "Water Sports Equipment Sales & Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911068",
        "yellow_page_code": "73409, 92125, 93734, 93505",
        "kiosk": false
    },
    {
        "value": "594106",
        "label": "Water Skiing Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911065",
        "yellow_page_code": "92404, 92137",
        "kiosk": false
    },
    {
        "value": "594107",
        "label": "Trapping Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911064",
        "yellow_page_code": "88335, 88403",
        "kiosk": false
    },
    {
        "value": "594108",
        "label": "Trampoline Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911063",
        "yellow_page_code": "88202, 88119",
        "kiosk": false
    },
    {
        "value": "594109",
        "label": "Tents-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911062",
        "yellow_page_code": "85200, 85135",
        "kiosk": false
    },
    {
        "value": "594110",
        "label": "Tennis Equipment & Supplies-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911061",
        "yellow_page_code": "85102",
        "kiosk": false
    },
    {
        "value": "594111",
        "label": "Survival Products & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911059",
        "yellow_page_code": "82602",
        "kiosk": false
    },
    {
        "value": "594112",
        "label": "Surfboards",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911057",
        "yellow_page_code": "82402, 91310, 82332, 91311",
        "kiosk": false
    },
    {
        "value": "594113",
        "label": "Sporting Goods-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911055",
        "yellow_page_code": "43598, 06890, 08117, 04203, 45419, 80004, 31307, 41018, 36028",
        "kiosk": false
    },
    {
        "value": "594114",
        "label": "Skin Diving Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911049",
        "yellow_page_code": "78203",
        "kiosk": false
    },
    {
        "value": "594115",
        "label": "Skiing Equipment-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911048",
        "yellow_page_code": "78632, 78106, 22693",
        "kiosk": false
    },
    {
        "value": "594116",
        "label": "Skateboards & Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911044",
        "yellow_page_code": "77908",
        "kiosk": false
    },
    {
        "value": "594117",
        "label": "Skating Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911045",
        "yellow_page_code": "78000",
        "kiosk": false
    },
    {
        "value": "594118",
        "label": "Shuffleboards",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911043",
        "yellow_page_code": "77400, 77333",
        "kiosk": false
    },
    {
        "value": "594119",
        "label": "Sauna Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911042",
        "yellow_page_code": "74001, 74004",
        "kiosk": false
    },
    {
        "value": "594120",
        "label": "Saddlery & Harness",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911041",
        "yellow_page_code": "73304, 73310, 80403, 80404",
        "kiosk": false
    },
    {
        "value": "594121",
        "label": "Racquetball Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911039",
        "yellow_page_code": "69607",
        "kiosk": false
    },
    {
        "value": "594122",
        "label": "Playground Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911038",
        "yellow_page_code": "66614, 82913, 66602, 36219",
        "kiosk": false
    },
    {
        "value": "594123",
        "label": "Backpacking & Mountaineering-Equip/Supl",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911004",
        "yellow_page_code": "72518, 72516, 54306, 05600",
        "kiosk": false
    },
    {
        "value": "594124",
        "label": "Martial Arts Supplies & Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911033",
        "yellow_page_code": "50206, 75434, 75440, 44701, 30086",
        "kiosk": false
    },
    {
        "value": "594125",
        "label": "Karate Judo Jiu-Jitsu & Kung Fu Supls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911031",
        "yellow_page_code": "44702",
        "kiosk": false
    },
    {
        "value": "594126",
        "label": "Hockey Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911026",
        "yellow_page_code": "41103",
        "kiosk": false
    },
    {
        "value": "594127",
        "label": "Gymnasiums Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911025",
        "yellow_page_code": "80120, 39507, 39504",
        "kiosk": false
    },
    {
        "value": "594128",
        "label": "Gunsights Scopes & Mounts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911024",
        "yellow_page_code": "39404",
        "kiosk": false
    },
    {
        "value": "594129",
        "label": "Guns & Gunsmiths",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911023",
        "yellow_page_code": "39337, 39407, 39402, 31916, 39339, 39343, 39345, 39338, 39347, 39399, 39344, 20381, 39348",
        "kiosk": false
    },
    {
        "value": "594130",
        "label": "Golf Equipment & Supplies-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911021",
        "yellow_page_code": "38112, 38110, 38206, 38118, 38125, 38131, 38121",
        "kiosk": false
    },
    {
        "value": "594131",
        "label": "Fishing Tackle-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911019",
        "yellow_page_code": "32704, 32623, 32600, 33640, 02429, 32634, 32628, 32627, 32625, 33641, 83131, 83130, 79840",
        "kiosk": false
    },
    {
        "value": "594132",
        "label": "Fishing Tackle-Repairing & Parts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911020",
        "yellow_page_code": "32706",
        "kiosk": false
    },
    {
        "value": "594133",
        "label": "Fishing Bait",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911017",
        "yellow_page_code": "05904, 32606",
        "kiosk": false
    },
    {
        "value": "594134",
        "label": "Curling Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911012",
        "yellow_page_code": "22911",
        "kiosk": false
    },
    {
        "value": "594135",
        "label": "Fish Nets",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911016",
        "yellow_page_code": "32501",
        "kiosk": false
    },
    {
        "value": "594136",
        "label": "Exercise Equipment-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911015",
        "yellow_page_code": "30100, 30016, 32716, 30010, 32712",
        "kiosk": false
    },
    {
        "value": "594137",
        "label": "Divers Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911014",
        "yellow_page_code": "74936, 78715, 25218, 25206",
        "kiosk": false
    },
    {
        "value": "594138",
        "label": "Camping Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911010",
        "yellow_page_code": "78314, 12602",
        "kiosk": false
    },
    {
        "value": "594139",
        "label": "Bowling Apparel & Accessories",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911009",
        "yellow_page_code": "09704",
        "kiosk": false
    },
    {
        "value": "594140",
        "label": "Bicycles-Parts & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911007",
        "yellow_page_code": "08003, 07921",
        "kiosk": false
    },
    {
        "value": "594141",
        "label": "Bicycles-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911006",
        "yellow_page_code": "08000, 07923",
        "kiosk": false
    },
    {
        "value": "594142",
        "label": "Bicycle Racks & Security Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911005",
        "yellow_page_code": "23131, 07908",
        "kiosk": false
    },
    {
        "value": "594143",
        "label": "Archery Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911003",
        "yellow_page_code": "03200",
        "kiosk": false
    },
    {
        "value": "594144",
        "label": "Ammunition Reloading Equipment & Supls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911002",
        "yellow_page_code": "02206, 39341, 71530",
        "kiosk": false
    },
    {
        "value": "594145",
        "label": "Ammunition",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911001",
        "yellow_page_code": "02202",
        "kiosk": false
    },
    {
        "value": "594146",
        "label": "Surfing Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911058",
        "yellow_page_code": "82401",
        "kiosk": false
    },
    {
        "value": "594147",
        "label": "Snowboards-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911050",
        "yellow_page_code": "78622, 78615, 78609",
        "kiosk": false
    },
    {
        "value": "594148",
        "label": "Soccer Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911052",
        "yellow_page_code": "78907",
        "kiosk": false
    },
    {
        "value": "594149",
        "label": "Fishing Stations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911018",
        "yellow_page_code": "32702",
        "kiosk": false
    },
    {
        "value": "594150",
        "label": "Hunting Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911028",
        "yellow_page_code": "42139, 42107",
        "kiosk": false
    },
    {
        "value": "594151",
        "label": "Outfitters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911036",
        "yellow_page_code": "58105",
        "kiosk": false
    },
    {
        "value": "594152",
        "label": "Decoys",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911013",
        "yellow_page_code": "23617",
        "kiosk": false
    },
    {
        "value": "594153",
        "label": "Juggling Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911030",
        "yellow_page_code": "44507",
        "kiosk": false
    },
    {
        "value": "594154",
        "label": "Cave Exploration Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911011",
        "yellow_page_code": "14136",
        "kiosk": false
    },
    {
        "value": "594155",
        "label": "Skating Rinks-Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911046",
        "yellow_page_code": "78005",
        "kiosk": false
    },
    {
        "value": "594156",
        "label": "Jet Ski-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911029",
        "yellow_page_code": "44136",
        "kiosk": false
    },
    {
        "value": "594157",
        "label": "Snowshoes",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911051",
        "yellow_page_code": "78611",
        "kiosk": false
    },
    {
        "value": "594158",
        "label": "Mini-Bike Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911034",
        "yellow_page_code": "52519",
        "kiosk": false
    },
    {
        "value": "594159",
        "label": "Weights",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911066",
        "yellow_page_code": "93002",
        "kiosk": false
    },
    {
        "value": "594160",
        "label": "Landsailers & Accessories",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911032",
        "yellow_page_code": "45835",
        "kiosk": false
    },
    {
        "value": "594161",
        "label": "Sporting Clays",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911053",
        "yellow_page_code": "79936",
        "kiosk": false
    },
    {
        "value": "594162",
        "label": "Squash Equipment & Supls-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911056",
        "yellow_page_code": "80307",
        "kiosk": false
    },
    {
        "value": "594163",
        "label": "Tack",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911060",
        "yellow_page_code": "83295, 83301, 83015",
        "kiosk": false
    },
    {
        "value": "594164",
        "label": "Horse Furnishings",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911027",
        "yellow_page_code": "41502, 41510, 30562, 40190, 41533, 41519",
        "kiosk": false
    },
    {
        "value": "594165",
        "label": "Dog Sledding Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911069",
        "yellow_page_code": "25310",
        "kiosk": false
    },
    {
        "value": "594166",
        "label": "Outdoor Equipment Accessories",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911070",
        "yellow_page_code": "32626, 58122",
        "kiosk": false
    },
    {
        "value": "594167",
        "label": "Wave Runners",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911071",
        "yellow_page_code": "92620",
        "kiosk": false
    },
    {
        "value": "594168",
        "label": "Sporting Goods Track & Field Equip",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911072",
        "yellow_page_code": "79915",
        "kiosk": false
    },
    {
        "value": "594169",
        "label": "Leisure & Sports Equipment Management",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911073",
        "yellow_page_code": "46913",
        "kiosk": false
    },
    {
        "value": "594170",
        "label": "Black Powder Guns & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911076",
        "yellow_page_code": "08423",
        "kiosk": false
    },
    {
        "value": "594171",
        "label": "Kitesurfing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911077",
        "yellow_page_code": "44991, 44990",
        "kiosk": false
    },
    {
        "value": "594172",
        "label": "Wave Boards",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911075",
        "yellow_page_code": "92619",
        "kiosk": false
    },
    {
        "value": "594173",
        "label": "Wakeboarding",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911078",
        "yellow_page_code": "91302",
        "kiosk": false
    },
    {
        "value": "594178",
        "label": "Gifts-Corporate",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942048",
        "yellow_page_code": "37333, 21726, 37334, 37303",
        "kiosk": false
    },
    {
        "value": "594179",
        "label": "Tricycles Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911080",
        "yellow_page_code": "88560",
        "kiosk": false
    },
    {
        "value": "594201",
        "label": "Book Dealers-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45921005",
        "yellow_page_code": "09318, 09228, 09224, 09221, 04316, 09303, 09206",
        "kiosk": false
    },
    {
        "value": "594202",
        "label": "Bibles",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45921003",
        "yellow_page_code": "07906",
        "kiosk": false
    },
    {
        "value": "594203",
        "label": "Law Books",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45921009",
        "yellow_page_code": "46201",
        "kiosk": false
    },
    {
        "value": "594204",
        "label": "Book Dealers-Pro Technical & Trade",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45921004",
        "yellow_page_code": "09207, 09312",
        "kiosk": false
    },
    {
        "value": "594205",
        "label": "Comic Books",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45921008",
        "yellow_page_code": "19911",
        "kiosk": false
    },
    {
        "value": "594207",
        "label": "Automobile Repair Manuals",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45921001",
        "yellow_page_code": "05112",
        "kiosk": false
    },
    {
        "value": "594208",
        "label": "Race Books",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45921011",
        "yellow_page_code": "69506",
        "kiosk": false
    },
    {
        "value": "594209",
        "label": "Books On Tape",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45921006",
        "yellow_page_code": "09218, 09211",
        "kiosk": false
    },
    {
        "value": "594210",
        "label": "Books-Medical",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45921010",
        "yellow_page_code": "09315",
        "kiosk": false
    },
    {
        "value": "594211",
        "label": "Christian Books & Supplies-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45921007",
        "yellow_page_code": "15603, 15605, 09316",
        "kiosk": false
    },
    {
        "value": "594212",
        "label": "Automotive Foreign Manuals",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45921002",
        "yellow_page_code": "05321",
        "kiosk": false
    },
    {
        "value": "594213",
        "label": "Self Help-Books Tapes Etc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45921012",
        "yellow_page_code": "09222, 75445",
        "kiosk": false
    },
    {
        "value": "594214",
        "label": "Books School & Textbooks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45921013",
        "yellow_page_code": "09317, 09229",
        "kiosk": false
    },
    {
        "value": "594215",
        "label": "Book Retailers & News Dealers ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45921020",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "594301",
        "label": "Office Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45941007",
        "yellow_page_code": "47016, 56402, 68327",
        "kiosk": false
    },
    {
        "value": "594302",
        "label": "Filing Equipment Systems & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45941005",
        "yellow_page_code": "56291, 31602, 42802, 12133",
        "kiosk": false
    },
    {
        "value": "594303",
        "label": "Envelopes-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45941004",
        "yellow_page_code": "29702",
        "kiosk": false
    },
    {
        "value": "594304",
        "label": "Desk Pads & Accessories",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45941001",
        "yellow_page_code": "24307",
        "kiosk": false
    },
    {
        "value": "594305",
        "label": "Stationers-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45941015",
        "yellow_page_code": "80700, 35017, 09213, 80702",
        "kiosk": false
    },
    {
        "value": "594306",
        "label": "Rubber & Plastic Stamps",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45941012",
        "yellow_page_code": "80602, 73002",
        "kiosk": false
    },
    {
        "value": "594307",
        "label": "Pens & Pencils-Sales & Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45941009",
        "yellow_page_code": "60200, 60137",
        "kiosk": false
    },
    {
        "value": "594308",
        "label": "Maps-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45941006",
        "yellow_page_code": "49911, 49895",
        "kiosk": false
    },
    {
        "value": "594309",
        "label": "School Instructional Materials",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45941013",
        "yellow_page_code": "74401",
        "kiosk": false
    },
    {
        "value": "594310",
        "label": "Educational Materials",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45941003",
        "yellow_page_code": "27207, 84191, 84192, 27217, 27210",
        "kiosk": false
    },
    {
        "value": "594311",
        "label": "School Supplies-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45941014",
        "yellow_page_code": "74416, 41253",
        "kiosk": false
    },
    {
        "value": "594312",
        "label": "Diplomas",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45941002",
        "yellow_page_code": "24813",
        "kiosk": false
    },
    {
        "value": "594313",
        "label": "Parchment Paper",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45941008",
        "yellow_page_code": "59312",
        "kiosk": false
    },
    {
        "value": "594314",
        "label": "Post Cards-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45941011",
        "yellow_page_code": "67438, 67439",
        "kiosk": false
    },
    {
        "value": "594315",
        "label": "Personal Organizers & Planners",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45941010",
        "yellow_page_code": "24521, 24520",
        "kiosk": false
    },
    {
        "value": "594316",
        "label": "Scrapbook Albums & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45941016",
        "yellow_page_code": "22312, 74730, 74736, 74723, 74731",
        "kiosk": false
    },
    {
        "value": "594317",
        "label": "Stickers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45941017",
        "yellow_page_code": "81403",
        "kiosk": false
    },
    {
        "value": "594318",
        "label": "Postage Stamps",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45941018",
        "yellow_page_code": "67517",
        "kiosk": false
    },
    {
        "value": "594319",
        "label": "Stamping Eqpt-Supplies-Cold Gold Hot Etc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45941020",
        "yellow_page_code": "80532",
        "kiosk": false
    },
    {
        "value": "594401",
        "label": "Silverware",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42394035",
        "yellow_page_code": "77800",
        "kiosk": false
    },
    {
        "value": "594402",
        "label": "Pearls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45831015",
        "yellow_page_code": "60104",
        "kiosk": false
    },
    {
        "value": "594403",
        "label": "Watches-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45831019",
        "yellow_page_code": "92002, 29887, 91909",
        "kiosk": false
    },
    {
        "value": "594404",
        "label": "Diamonds",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45831005",
        "yellow_page_code": "24504",
        "kiosk": false
    },
    {
        "value": "594405",
        "label": "Diamond Setters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45831004",
        "yellow_page_code": "24502",
        "kiosk": false
    },
    {
        "value": "594406",
        "label": "Diamonds & Jewelry Bought",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45831006",
        "yellow_page_code": "24501",
        "kiosk": false
    },
    {
        "value": "594407",
        "label": "Clocks-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45831003",
        "yellow_page_code": "18604, 18610",
        "kiosk": false
    },
    {
        "value": "594408",
        "label": "Jewelry Designers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45831012",
        "yellow_page_code": "44306, 24236, 30342",
        "kiosk": false
    },
    {
        "value": "594409",
        "label": "Jewelers-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45831011",
        "yellow_page_code": "71629, 44206, 44212, 44313, 44292, 43592, 14519, 66233",
        "kiosk": false
    },
    {
        "value": "594410",
        "label": "Jade & Jade Goods",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45831010",
        "yellow_page_code": "44104",
        "kiosk": false
    },
    {
        "value": "594411",
        "label": "Gold Silver & Platinum-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45831009",
        "yellow_page_code": "38002, 38011, 38005",
        "kiosk": false
    },
    {
        "value": "594412",
        "label": "Gemologists",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45831007",
        "yellow_page_code": "37002",
        "kiosk": false
    },
    {
        "value": "594413",
        "label": "Jewelry Custom Made",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45831013",
        "yellow_page_code": "44409, 44403",
        "kiosk": false
    },
    {
        "value": "594414",
        "label": "Gold Nugget Jewelry-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45831008",
        "yellow_page_code": "38001",
        "kiosk": false
    },
    {
        "value": "594415",
        "label": "Agates",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45831001",
        "yellow_page_code": "00813",
        "kiosk": false
    },
    {
        "value": "594416",
        "label": "Jewelry-Vintage",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45831022",
        "yellow_page_code": "02721, 44227",
        "kiosk": false
    },
    {
        "value": "594417",
        "label": "Stop Watches",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45831018",
        "yellow_page_code": "81705",
        "kiosk": false
    },
    {
        "value": "594418",
        "label": "Opals",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45831014",
        "yellow_page_code": "57411",
        "kiosk": false
    },
    {
        "value": "594419",
        "label": "Scrimshaw",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45831016",
        "yellow_page_code": "74911",
        "kiosk": false
    },
    {
        "value": "594421",
        "label": "Jewelry-Renting & Leasing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45831017",
        "yellow_page_code": "44413",
        "kiosk": false
    },
    {
        "value": "594422",
        "label": "Jewelry-Body",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45831020",
        "yellow_page_code": "44311",
        "kiosk": false
    },
    {
        "value": "594423",
        "label": "Wedding-Jewelry",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45831021",
        "yellow_page_code": "92933",
        "kiosk": false
    },
    {
        "value": "594501",
        "label": "Craft Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912011",
        "yellow_page_code": "03509, 40009, 03914, 03611, 94212, 50512, 22217, 22302",
        "kiosk": false
    },
    {
        "value": "594502",
        "label": "Music Boxes",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912029",
        "yellow_page_code": "54700",
        "kiosk": false
    },
    {
        "value": "594503",
        "label": "Weaving-Loom",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912036",
        "yellow_page_code": "92809",
        "kiosk": false
    },
    {
        "value": "594504",
        "label": "Doll Clothing & Accessories",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912014",
        "yellow_page_code": "87606, 25501",
        "kiosk": false
    },
    {
        "value": "594505",
        "label": "Miniature Items For Collectors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912026",
        "yellow_page_code": "52509",
        "kiosk": false
    },
    {
        "value": "594506",
        "label": "Magicians Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912025",
        "yellow_page_code": "49406, 49405",
        "kiosk": false
    },
    {
        "value": "594507",
        "label": "Kites-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912023",
        "yellow_page_code": "45000, 44937",
        "kiosk": false
    },
    {
        "value": "594508",
        "label": "Hobby & Model Constr Supplies-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912021",
        "yellow_page_code": "41540, 41100, 53301, 41035, 30561",
        "kiosk": false
    },
    {
        "value": "594509",
        "label": "Games & Game Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912020",
        "yellow_page_code": "36008, 36007, 19920, 67129, 36034, 91056",
        "kiosk": false
    },
    {
        "value": "594510",
        "label": "Dolls-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912017",
        "yellow_page_code": "25500, 25420, 25424, 25419, 25422, 87616",
        "kiosk": false
    },
    {
        "value": "594511",
        "label": "Doll Houses & Accessories",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912015",
        "yellow_page_code": "87516, 25406, 87610",
        "kiosk": false
    },
    {
        "value": "594512",
        "label": "Darts & Dart Boards",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912013",
        "yellow_page_code": "23402",
        "kiosk": false
    },
    {
        "value": "594513",
        "label": "Ceramic Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912006",
        "yellow_page_code": "14417, 14500",
        "kiosk": false
    },
    {
        "value": "594514",
        "label": "Ceramic Products-Decorative",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912007",
        "yellow_page_code": "14410, 14406",
        "kiosk": false
    },
    {
        "value": "594515",
        "label": "Baskets",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912003",
        "yellow_page_code": "06823, 39821, 06822, 06808",
        "kiosk": false
    },
    {
        "value": "594516",
        "label": "Balsa Wood",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912002",
        "yellow_page_code": "06304",
        "kiosk": false
    },
    {
        "value": "594517",
        "label": "Toys-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912035",
        "yellow_page_code": "28035, 36029, 87604, 69801, 87590, 84189",
        "kiosk": false
    },
    {
        "value": "594518",
        "label": "Rock Shops",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912033",
        "yellow_page_code": "72501",
        "kiosk": false
    },
    {
        "value": "594519",
        "label": "Plaster Craft Products & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912030",
        "yellow_page_code": "66109",
        "kiosk": false
    },
    {
        "value": "594520",
        "label": "Crafts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912012",
        "yellow_page_code": "22301",
        "kiosk": false
    },
    {
        "value": "594521",
        "label": "Ceramics-Domestic-Studios Supplies Etc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912008",
        "yellow_page_code": "14415, 14408",
        "kiosk": false
    },
    {
        "value": "594522",
        "label": "Bingo Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912004",
        "yellow_page_code": "08211",
        "kiosk": false
    },
    {
        "value": "594523",
        "label": "Airplanes-Model",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912001",
        "yellow_page_code": "01705",
        "kiosk": false
    },
    {
        "value": "594524",
        "label": "Dolls-Parts Supplies & Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912016",
        "yellow_page_code": "25414, 25418",
        "kiosk": false
    },
    {
        "value": "594525",
        "label": "Railroads-Model",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912032",
        "yellow_page_code": "87509, 53318, 70301",
        "kiosk": false
    },
    {
        "value": "594526",
        "label": "Radio Equipment-Amateur",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912031",
        "yellow_page_code": "69817",
        "kiosk": false
    },
    {
        "value": "594527",
        "label": "Crafts & Craft Supplies-Leatherwork",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912024",
        "yellow_page_code": "22240",
        "kiosk": false
    },
    {
        "value": "594528",
        "label": "Carpet & Rug Making-Instruction & Supls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912005",
        "yellow_page_code": "13707",
        "kiosk": false
    },
    {
        "value": "594529",
        "label": "Chair Caning Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912009",
        "yellow_page_code": "14601",
        "kiosk": false
    },
    {
        "value": "594530",
        "label": "Electric Trains-Toy",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912018",
        "yellow_page_code": "87619, 27807, 88129",
        "kiosk": false
    },
    {
        "value": "594531",
        "label": "Wreaths",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912037",
        "yellow_page_code": "94516",
        "kiosk": false
    },
    {
        "value": "594532",
        "label": "Eyes-Artificial-Toys & Animals",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912019",
        "yellow_page_code": "30314",
        "kiosk": false
    },
    {
        "value": "594533",
        "label": "Motors-Miniature",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912028",
        "yellow_page_code": "54313",
        "kiosk": false
    },
    {
        "value": "594534",
        "label": "Kaleidoscopes",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912022",
        "yellow_page_code": "44650",
        "kiosk": false
    },
    {
        "value": "594535",
        "label": "Model Railroad Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912027",
        "yellow_page_code": "53236",
        "kiosk": false
    },
    {
        "value": "594536",
        "label": "Stenciling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912034",
        "yellow_page_code": "81203",
        "kiosk": false
    },
    {
        "value": "594537",
        "label": "Chess Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912010",
        "yellow_page_code": "15110",
        "kiosk": false
    },
    {
        "value": "594538",
        "label": "Playhouses-Children",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912038",
        "yellow_page_code": "66612",
        "kiosk": false
    },
    {
        "value": "594539",
        "label": "Video Games & Equipment-Sales & Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912039",
        "yellow_page_code": "91115, 36027, 36024, 36012",
        "kiosk": false
    },
    {
        "value": "594540",
        "label": "Foam & Sponge Rubber-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912040",
        "yellow_page_code": "33702",
        "kiosk": false
    },
    {
        "value": "594541",
        "label": "Toys-Special Needs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33993005",
        "yellow_page_code": "87605",
        "kiosk": false
    },
    {
        "value": "594542",
        "label": "Toys-Specialty",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912041",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "594543",
        "label": "Model Mfrs-Toys & Hobbies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912043",
        "yellow_page_code": "53239",
        "kiosk": false
    },
    {
        "value": "594544",
        "label": "Jigsaw Puzzles",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912044",
        "yellow_page_code": "44490",
        "kiosk": false
    },
    {
        "value": "594545",
        "label": "Beads-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912045",
        "yellow_page_code": "07110",
        "kiosk": false
    },
    {
        "value": "594546",
        "label": "Sculptors' Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912046",
        "yellow_page_code": "75008",
        "kiosk": false
    },
    {
        "value": "594547",
        "label": "Hobby Toy & Game Retailers ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45912047",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "594601",
        "label": "Photographic Equip & Supplies-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921089",
        "yellow_page_code": "60818, 12407, 12411, 61020, 61008, 12412",
        "kiosk": false
    },
    {
        "value": "594602",
        "label": "Photographic Equip & Supplies-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921090",
        "yellow_page_code": "61011",
        "kiosk": false
    },
    {
        "value": "594603",
        "label": "Photogrammetric Equip & Supls-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921088",
        "yellow_page_code": "60904",
        "kiosk": false
    },
    {
        "value": "594604",
        "label": "Photographic Mounts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921091",
        "yellow_page_code": "61201",
        "kiosk": false
    },
    {
        "value": "594605",
        "label": "Camcorders",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921092",
        "yellow_page_code": "12616",
        "kiosk": false
    },
    {
        "value": "594606",
        "label": "Digital Cameras",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921093",
        "yellow_page_code": "24795",
        "kiosk": false
    },
    {
        "value": "594701",
        "label": "Balloons-Novelty & Toy",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942002",
        "yellow_page_code": "06132, 59552, 92951, 06133, 06137, 06202",
        "kiosk": false
    },
    {
        "value": "594702",
        "label": "Porcelain Products-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942030",
        "yellow_page_code": "22802, 67401",
        "kiosk": false
    },
    {
        "value": "594703",
        "label": "Ivory Goods",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942020",
        "yellow_page_code": "44006",
        "kiosk": false
    },
    {
        "value": "594704",
        "label": "Curios",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942008",
        "yellow_page_code": "22904",
        "kiosk": false
    },
    {
        "value": "594705",
        "label": "Collectibles",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942005",
        "yellow_page_code": "19610, 79934, 39349, 30088, 87824, 70830, 19609, 87822",
        "kiosk": false
    },
    {
        "value": "594706",
        "label": "Bridal Registries",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942003",
        "yellow_page_code": "10608, 92994",
        "kiosk": false
    },
    {
        "value": "594707",
        "label": "Novelties-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942024",
        "yellow_page_code": "55708",
        "kiosk": false
    },
    {
        "value": "594708",
        "label": "Invitations & Announcements-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942018",
        "yellow_page_code": "43801",
        "kiosk": false
    },
    {
        "value": "594709",
        "label": "Native American Goods",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942017",
        "yellow_page_code": "00019, 42814, 55107, 55120, 44223, 42811, 03620, 42904, 00036, 42818, 55117, 32312",
        "kiosk": false
    },
    {
        "value": "594710",
        "label": "Greeting Cards-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942015",
        "yellow_page_code": "13422, 39002, 13503, 13420, 30557",
        "kiosk": false
    },
    {
        "value": "594711",
        "label": "Gift Wrapping Materials",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942014",
        "yellow_page_code": "37308",
        "kiosk": false
    },
    {
        "value": "594712",
        "label": "Gift Shops",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942013",
        "yellow_page_code": "37237, 43674, 37302, 37304, 37301, 37314",
        "kiosk": false
    },
    {
        "value": "594713",
        "label": "Gift Baskets & Parcels",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942012",
        "yellow_page_code": "37208, 33523, 39822, 37231",
        "kiosk": false
    },
    {
        "value": "594714",
        "label": "Tricks & Jokes",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942034",
        "yellow_page_code": "88601, 44531",
        "kiosk": false
    },
    {
        "value": "594715",
        "label": "Souvenirs-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942033",
        "yellow_page_code": "79600",
        "kiosk": false
    },
    {
        "value": "594716",
        "label": "Party Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942026",
        "yellow_page_code": "93023, 58614, 15150, 41473, 59542, 23704, 59600, 59517, 23653, 15615, 92972",
        "kiosk": false
    },
    {
        "value": "594717",
        "label": "Wedding Announcements & Invitations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942035",
        "yellow_page_code": "92902, 92911",
        "kiosk": false
    },
    {
        "value": "594718",
        "label": "Pewter",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942027",
        "yellow_page_code": "60605",
        "kiosk": false
    },
    {
        "value": "594719",
        "label": "Party Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942025",
        "yellow_page_code": "59560, 59505",
        "kiosk": false
    },
    {
        "value": "594720",
        "label": "Favors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942009",
        "yellow_page_code": "31008",
        "kiosk": false
    },
    {
        "value": "594721",
        "label": "Pinatas",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942028",
        "yellow_page_code": "65308",
        "kiosk": false
    },
    {
        "value": "594722",
        "label": "Shells-Decorative",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942032",
        "yellow_page_code": "76213, 75119",
        "kiosk": false
    },
    {
        "value": "594723",
        "label": "Amber & Amber Goods",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942001",
        "yellow_page_code": "02104",
        "kiosk": false
    },
    {
        "value": "594724",
        "label": "Lauhala Products",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942021",
        "yellow_page_code": "46105",
        "kiosk": false
    },
    {
        "value": "594725",
        "label": "Coral Jewelry",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942007",
        "yellow_page_code": "21609",
        "kiosk": false
    },
    {
        "value": "594726",
        "label": "Coral",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942006",
        "yellow_page_code": "21613",
        "kiosk": false
    },
    {
        "value": "594727",
        "label": "Christmas Stockings",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942004",
        "yellow_page_code": "15611",
        "kiosk": false
    },
    {
        "value": "594728",
        "label": "Pom Poms-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942029",
        "yellow_page_code": "67207",
        "kiosk": false
    },
    {
        "value": "594729",
        "label": "Windsocks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942036",
        "yellow_page_code": "93515",
        "kiosk": false
    },
    {
        "value": "594730",
        "label": "Neon Novelties",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942023",
        "yellow_page_code": "55311",
        "kiosk": false
    },
    {
        "value": "594731",
        "label": "Regalia & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942031",
        "yellow_page_code": "71516",
        "kiosk": false
    },
    {
        "value": "594732",
        "label": "Fruit Baskets-Gift",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942010",
        "yellow_page_code": "34609, 34614, 34801",
        "kiosk": false
    },
    {
        "value": "594733",
        "label": "Irish Goods-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942019",
        "yellow_page_code": "43803",
        "kiosk": false
    },
    {
        "value": "594734",
        "label": "Middle Eastern Goods",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942022",
        "yellow_page_code": "52223, 42816, 42819",
        "kiosk": false
    },
    {
        "value": "594735",
        "label": "Holiday Letters & Gifts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942016",
        "yellow_page_code": "41122",
        "kiosk": false
    },
    {
        "value": "594736",
        "label": "Funeral Announcements",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942011",
        "yellow_page_code": "34998",
        "kiosk": false
    },
    {
        "value": "594737",
        "label": "Lawn & Garden Decorations/Novelties",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942037",
        "yellow_page_code": "46319",
        "kiosk": false
    },
    {
        "value": "594738",
        "label": "Glass-Hand Blown Art",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942038",
        "yellow_page_code": "37410, 37510",
        "kiosk": false
    },
    {
        "value": "594739",
        "label": "Native American Jewelry",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942049",
        "yellow_page_code": "42590",
        "kiosk": false
    },
    {
        "value": "594740",
        "label": "Nascar Collectibles & Apparel",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942039",
        "yellow_page_code": "55125",
        "kiosk": false
    },
    {
        "value": "594741",
        "label": "Telephone-Novelty",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942040",
        "yellow_page_code": "84411",
        "kiosk": false
    },
    {
        "value": "594742",
        "label": "Windchimes & Windsocks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942041",
        "yellow_page_code": "93599",
        "kiosk": false
    },
    {
        "value": "594743",
        "label": "Baby Announcements",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942042",
        "yellow_page_code": "05522",
        "kiosk": false
    },
    {
        "value": "594744",
        "label": "Wedding Party Gifts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942043",
        "yellow_page_code": "92929, 92958",
        "kiosk": false
    },
    {
        "value": "594745",
        "label": "Halloween Attractions Events Prod/Svcs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942044",
        "yellow_page_code": "39820",
        "kiosk": false
    },
    {
        "value": "594746",
        "label": "Racing Collectibles",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942045",
        "yellow_page_code": "69522",
        "kiosk": false
    },
    {
        "value": "594747",
        "label": "Wedding Decorations & Favors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942047",
        "yellow_page_code": "92953",
        "kiosk": false
    },
    {
        "value": "594748",
        "label": "Gifts-Specialty",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45942050",
        "yellow_page_code": "79737",
        "kiosk": false
    },
    {
        "value": "594801",
        "label": "Luggage-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45832006",
        "yellow_page_code": "48808, 48810, 33022",
        "kiosk": false
    },
    {
        "value": "594802",
        "label": "Luggage-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45832005",
        "yellow_page_code": "88614, 48806",
        "kiosk": false
    },
    {
        "value": "594803",
        "label": "Leather Goods-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45832002",
        "yellow_page_code": "46706, 40210",
        "kiosk": false
    },
    {
        "value": "594804",
        "label": "Travel Accessories",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45832008",
        "yellow_page_code": "88413",
        "kiosk": false
    },
    {
        "value": "594805",
        "label": "Portfolios",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45832007",
        "yellow_page_code": "67411, 67421",
        "kiosk": false
    },
    {
        "value": "594806",
        "label": "Trunks-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45832009",
        "yellow_page_code": "89111",
        "kiosk": false
    },
    {
        "value": "594807",
        "label": "Cases",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45832001",
        "yellow_page_code": "13918, 13920",
        "kiosk": false
    },
    {
        "value": "594808",
        "label": "Luggage Carriers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45832003",
        "yellow_page_code": "04561, 71732, 13826, 48800",
        "kiosk": false
    },
    {
        "value": "594809",
        "label": "Luggage Covers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45832004",
        "yellow_page_code": "48805",
        "kiosk": false
    },
    {
        "value": "594810",
        "label": "Luggage & Leather Goods Retailers ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45832010",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "594901",
        "label": "Needles",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45913014",
        "yellow_page_code": "55306",
        "kiosk": false
    },
    {
        "value": "594902",
        "label": "Fabric Shops",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45913007",
        "yellow_page_code": "94707, 30502",
        "kiosk": false
    },
    {
        "value": "594903",
        "label": "Notions-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45913016",
        "yellow_page_code": "55704, 88516",
        "kiosk": false
    },
    {
        "value": "594904",
        "label": "Needlework & Needlework Materials-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45913015",
        "yellow_page_code": "55305, 03503, 55400, 55395",
        "kiosk": false
    },
    {
        "value": "594905",
        "label": "Monograms",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45913012",
        "yellow_page_code": "53504",
        "kiosk": false
    },
    {
        "value": "594906",
        "label": "Macrame Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45913010",
        "yellow_page_code": "49306",
        "kiosk": false
    },
    {
        "value": "594907",
        "label": "Knitting Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45913009",
        "yellow_page_code": "45009",
        "kiosk": false
    },
    {
        "value": "594908",
        "label": "Embroidery-Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45913006",
        "yellow_page_code": "28504",
        "kiosk": false
    },
    {
        "value": "594909",
        "label": "Dry Goods-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45913005",
        "yellow_page_code": "26608",
        "kiosk": false
    },
    {
        "value": "594910",
        "label": "Costume Fabrics Trimmings & Accessories",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45913002",
        "yellow_page_code": "21901",
        "kiosk": false
    },
    {
        "value": "594911",
        "label": "Yarn-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45913024",
        "yellow_page_code": "94706",
        "kiosk": false
    },
    {
        "value": "594912",
        "label": "Weaving & Mending Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45913023",
        "yellow_page_code": "92806",
        "kiosk": false
    },
    {
        "value": "594913",
        "label": "Quilting Materials & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45913018",
        "yellow_page_code": "69404",
        "kiosk": false
    },
    {
        "value": "594914",
        "label": "Remnants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45913019",
        "yellow_page_code": "71616",
        "kiosk": false
    },
    {
        "value": "594915",
        "label": "Cotton Goods-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45913003",
        "yellow_page_code": "22011",
        "kiosk": false
    },
    {
        "value": "594916",
        "label": "Dress Patterns",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45913004",
        "yellow_page_code": "26201",
        "kiosk": false
    },
    {
        "value": "594917",
        "label": "Button Covering",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45913001",
        "yellow_page_code": "11916, 11905, 11910",
        "kiosk": false
    },
    {
        "value": "594918",
        "label": "Mill Stores",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45913011",
        "yellow_page_code": "52403",
        "kiosk": false
    },
    {
        "value": "594919",
        "label": "Needle Threaders",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45913013",
        "yellow_page_code": "55307",
        "kiosk": false
    },
    {
        "value": "594922",
        "label": "Textiles-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45913021",
        "yellow_page_code": "85413",
        "kiosk": false
    },
    {
        "value": "594923",
        "label": "Backing Fabrics & Material",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45913025",
        "yellow_page_code": "05625",
        "kiosk": false
    },
    {
        "value": "594924",
        "label": "Hemp Products",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45913026",
        "yellow_page_code": "40940",
        "kiosk": false
    },
    {
        "value": "594925",
        "label": "Padding",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45913027",
        "yellow_page_code": "58414",
        "kiosk": false
    },
    {
        "value": "594926",
        "label": "Sewing Patterns",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45913029",
        "yellow_page_code": "75918",
        "kiosk": false
    },
    {
        "value": "594927",
        "label": "Cross Stitch",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45913030",
        "yellow_page_code": "22692",
        "kiosk": false
    },
    {
        "value": "594928",
        "label": "Silk Fabrics",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45913031",
        "yellow_page_code": "77613",
        "kiosk": false
    },
    {
        "value": "594929",
        "label": "Dressmakers' Supplies-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45913032",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "594930",
        "label": "Sewing Needlework & Piece Goods Retailers ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45913033",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "596102",
        "label": "Online Retailers & Marketplaces",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521915",
        "yellow_page_code": "43578, 49700, 14015, 13992, 28004, 43561, 43569, 43581, 14003, 43634, 43649, 43573",
        "kiosk": false
    },
    {
        "value": "596201",
        "label": "Vending Machines",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44513205",
        "yellow_page_code": "90715, 90719, 90705, 90811, 19508, 90716, 90704, 34590, 78619, 92516, 59544",
        "kiosk": false
    },
    {
        "value": "596202",
        "label": "Stamp Dispensing Machines",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44513203",
        "yellow_page_code": "80507",
        "kiosk": false
    },
    {
        "value": "596203",
        "label": "Phonographs-Coin Operated",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44513202",
        "yellow_page_code": "60704, 44604",
        "kiosk": false
    },
    {
        "value": "596204",
        "label": "Coin & Bill Changing/Handling Machines",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44513201",
        "yellow_page_code": "19406, 13945",
        "kiosk": false
    },
    {
        "value": "596205",
        "label": "Drink Dispensers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44513204",
        "yellow_page_code": "25022, 26341",
        "kiosk": false
    },
    {
        "value": "596206",
        "label": "Video Games Vending",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44513206",
        "yellow_page_code": "91038",
        "kiosk": false
    },
    {
        "value": "596302",
        "label": "Newspaper Delivery",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45921015",
        "yellow_page_code": "55416, 46524, 55409, 46523",
        "kiosk": false
    },
    {
        "value": "596303",
        "label": "Subscription Agencies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45921016",
        "yellow_page_code": "49402, 82111",
        "kiosk": false
    },
    {
        "value": "596304",
        "label": "Home Demonstration-Merchandise",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521916",
        "yellow_page_code": "41922, 41211, 33715, 41246",
        "kiosk": false
    },
    {
        "value": "596306",
        "label": "Encyclopedias",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45921014",
        "yellow_page_code": "28608",
        "kiosk": false
    },
    {
        "value": "596307",
        "label": "Coffee Break Service & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521917",
        "yellow_page_code": "19300",
        "kiosk": false
    },
    {
        "value": "596309",
        "label": "Meal/Grocery Order & Delivery Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49221006",
        "yellow_page_code": "73513, 71939, 71943, 50708, 33907",
        "kiosk": false
    },
    {
        "value": "596310",
        "label": "Trailers-Food & Concession",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72233001",
        "yellow_page_code": "88127",
        "kiosk": false
    },
    {
        "value": "596312",
        "label": "Beverage Order & Delivery Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "49221007",
        "yellow_page_code": "07905, 07519, 47902",
        "kiosk": false
    },
    {
        "value": "596316",
        "label": "Mobile Food Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72233002",
        "yellow_page_code": "53016, 33834, 33915, 19216",
        "kiosk": false
    },
    {
        "value": "596398",
        "label": "Direct Selling Establishments",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521918",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "598301",
        "label": "Fuel-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45721003",
        "yellow_page_code": "57108, 59205, 57210, 31435, 34809",
        "kiosk": false
    },
    {
        "value": "598302",
        "label": "Oil-Bulk-Dealers-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45721004",
        "yellow_page_code": "56407",
        "kiosk": false
    },
    {
        "value": "598303",
        "label": "Oils-Petroleum-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45721005",
        "yellow_page_code": "57311, 60546",
        "kiosk": false
    },
    {
        "value": "598304",
        "label": "Service Stations-Bulk Fuel",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45721002",
        "yellow_page_code": "11418, 75617",
        "kiosk": false
    },
    {
        "value": "598305",
        "label": "Fuel Automated Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45721001",
        "yellow_page_code": "34826",
        "kiosk": false
    },
    {
        "value": "598306",
        "label": "Gasohol-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45721006",
        "yellow_page_code": "36824",
        "kiosk": false
    },
    {
        "value": "598401",
        "label": "Propane (LP) Gas",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45721010",
        "yellow_page_code": "68610, 68604",
        "kiosk": false
    },
    {
        "value": "598402",
        "label": "Butane Gas",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45721007",
        "yellow_page_code": "11903, 36511",
        "kiosk": false
    },
    {
        "value": "598403",
        "label": "Propane Carburetion",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45721011",
        "yellow_page_code": "68617",
        "kiosk": false
    },
    {
        "value": "598404",
        "label": "Gas-Liquefied Petroleum Etc-Equip/Supls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45721008",
        "yellow_page_code": "36711, 36720",
        "kiosk": false
    },
    {
        "value": "598405",
        "label": "Gas-Propane-Refilling Stations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45721009",
        "yellow_page_code": "68622, 36719",
        "kiosk": false
    },
    {
        "value": "598406",
        "label": "Propane Tank Kiosks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45721017",
        "yellow_page_code": "",
        "kiosk": true
    },
    {
        "value": "598901",
        "label": "Wood (Tree Service & Landscaping)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45721015",
        "yellow_page_code": "94203",
        "kiosk": false
    },
    {
        "value": "598902",
        "label": "Coal & Coke-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45721012",
        "yellow_page_code": "18904",
        "kiosk": false
    },
    {
        "value": "598903",
        "label": "Firewood",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45721013",
        "yellow_page_code": "48591, 32302",
        "kiosk": false
    },
    {
        "value": "598904",
        "label": "Wood Pellets-Fuel",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45721016",
        "yellow_page_code": "94222",
        "kiosk": false
    },
    {
        "value": "598999",
        "label": "Fuel-Dealers NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45721014",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "599201",
        "label": "Florists-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45931001",
        "yellow_page_code": "33511, 33506, 33502, 33532, 30570, 30447, 33530, 46217, 46316, 33513, 66115, 33520, 33521, 33603",
        "kiosk": false
    },
    {
        "value": "599202",
        "label": "Plants-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45931004",
        "yellow_page_code": "66102, 66022",
        "kiosk": false
    },
    {
        "value": "599203",
        "label": "Flower Leis",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45931002",
        "yellow_page_code": "46930, 33514",
        "kiosk": false
    },
    {
        "value": "599204",
        "label": "Planters-Decorative",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45931003",
        "yellow_page_code": "66016",
        "kiosk": false
    },
    {
        "value": "599205",
        "label": "Tropical Plants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45931005",
        "yellow_page_code": "88608",
        "kiosk": false
    },
    {
        "value": "599206",
        "label": "Wedding Flowers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45931006",
        "yellow_page_code": "33533, 92916",
        "kiosk": false
    },
    {
        "value": "599207",
        "label": "Funeral Flowers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45931007",
        "yellow_page_code": "35021",
        "kiosk": false
    },
    {
        "value": "599301",
        "label": "Cigar Cigarette & Tobacco Dealers-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45999102",
        "yellow_page_code": "86710, 86611, 17913, 17899, 17911, 17915, 30374, 86606, 78524, 17900, 78512",
        "kiosk": false
    },
    {
        "value": "599302",
        "label": "Smoke Shops & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45999103",
        "yellow_page_code": "17917, 90533, 27068, 78519, 78518, 90536, 65806",
        "kiosk": false
    },
    {
        "value": "599303",
        "label": "Cigar & Cigarette Lighter Fluids",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45999101",
        "yellow_page_code": "17905",
        "kiosk": false
    },
    {
        "value": "599304",
        "label": "Cigar & Cigarette Lighters-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45999104",
        "yellow_page_code": "17898",
        "kiosk": false
    },
    {
        "value": "599305",
        "label": "Cigarette Outlet",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45999105",
        "yellow_page_code": "17916",
        "kiosk": false
    },
    {
        "value": "599307",
        "label": "Cannabis Consumption Lounge",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45999107",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "599308",
        "label": "Cannabis Dispensary",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45999108",
        "yellow_page_code": "55198, 50071, 50070, 12825, 51015, 50072",
        "kiosk": false
    },
    {
        "value": "599401",
        "label": "News Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45921018",
        "yellow_page_code": "55507, 58132, 55510, 55404, 43653",
        "kiosk": false
    },
    {
        "value": "599402",
        "label": "Magazines-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45921017",
        "yellow_page_code": "49308",
        "kiosk": false
    },
    {
        "value": "599403",
        "label": "Newsracks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45921019",
        "yellow_page_code": "55508",
        "kiosk": false
    },
    {
        "value": "599501",
        "label": "Low Vision Aids",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45613002",
        "yellow_page_code": "48703",
        "kiosk": false
    },
    {
        "value": "599502",
        "label": "Optical Goods-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45613003",
        "yellow_page_code": "30404, 21181, 37712, 30319, 30310, 30308, 30399, 57600, 46906",
        "kiosk": false
    },
    {
        "value": "599503",
        "label": "Contact Lenses",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45613001",
        "yellow_page_code": "21097, 21102",
        "kiosk": false
    },
    {
        "value": "599504",
        "label": "Opticians",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "45613004",
        "yellow_page_code": "30311, 57512, 57606, 21101, 57605, 79739, 29968, 21099, 57604",
        "kiosk": false
    },
    {
        "value": "599505",
        "label": "Sunglasses & Sun Goggles",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45613005",
        "yellow_page_code": "82300",
        "kiosk": false
    },
    {
        "value": "599901",
        "label": "Answering Machines-Automatic",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921032",
        "yellow_page_code": "02705, 84306",
        "kiosk": false
    },
    {
        "value": "599902",
        "label": "Cellular Telephones-Equipment & Supls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921035",
        "yellow_page_code": "14236, 53221, 14242, 14231",
        "kiosk": false
    },
    {
        "value": "599903",
        "label": "Safes & Vaults-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521919",
        "yellow_page_code": "73390, 73315",
        "kiosk": false
    },
    {
        "value": "599904",
        "label": "Telephone Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921054",
        "yellow_page_code": "84328, 43661, 84315, 84313, 40414, 84247, 84246, 84514, 84404",
        "kiosk": false
    },
    {
        "value": "599905",
        "label": "Water Heaters-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921028",
        "yellow_page_code": "92206, 92438, 42629, 40800",
        "kiosk": false
    },
    {
        "value": "599906",
        "label": "Banners",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521920",
        "yellow_page_code": "32819, 06405",
        "kiosk": false
    },
    {
        "value": "599907",
        "label": "CBD Dispensary/Legal THC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45999908",
        "yellow_page_code": "25023",
        "kiosk": false
    },
    {
        "value": "599908",
        "label": "Typewriters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521922",
        "yellow_page_code": "89800, 89710, 89802",
        "kiosk": false
    },
    {
        "value": "599909",
        "label": "Trophies Awards & Medals",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521923",
        "yellow_page_code": "88604, 51033, 05407, 05405",
        "kiosk": false
    },
    {
        "value": "599910",
        "label": "Tropical Fish-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45991005",
        "yellow_page_code": "88606, 32425",
        "kiosk": false
    },
    {
        "value": "599911",
        "label": "Telescopes",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44921055",
        "yellow_page_code": "84501",
        "kiosk": false
    },
    {
        "value": "599912",
        "label": "Irrigation Systems & Equipment-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521924",
        "yellow_page_code": "43904",
        "kiosk": false
    },
    {
        "value": "599913",
        "label": "Surgical Appliances",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521925",
        "yellow_page_code": "51037, 82404, 51048, 82410",
        "kiosk": false
    },
    {
        "value": "599914",
        "label": "Sundries Stores",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521926",
        "yellow_page_code": "82304",
        "kiosk": false
    },
    {
        "value": "599915",
        "label": "Stamps For Collectors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521927",
        "yellow_page_code": "80600, 80514",
        "kiosk": false
    },
    {
        "value": "599916",
        "label": "Hot Tubs & Spas-Supplies & Parts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521928",
        "yellow_page_code": "41902, 79700",
        "kiosk": false
    },
    {
        "value": "599917",
        "label": "Hot Tubs & Spas",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521929",
        "yellow_page_code": "41465, 41809, 79606, 44090",
        "kiosk": false
    },
    {
        "value": "599918",
        "label": "Importers-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521930",
        "yellow_page_code": "42701",
        "kiosk": false
    },
    {
        "value": "599919",
        "label": "Chemicals-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521931",
        "yellow_page_code": "30371, 15004, 15013, 15017",
        "kiosk": false
    },
    {
        "value": "599920",
        "label": "Home Health & Health Care Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45619904",
        "yellow_page_code": "41810, 41232, 41212, 41145, 71532, 30565, 38517, 41716, 41290",
        "kiosk": false
    },
    {
        "value": "599921",
        "label": "Religious Goods",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521932",
        "yellow_page_code": "71624, 71628, 15620, 71619, 71620, 71626, 32315, 71602, 71546, 71545",
        "kiosk": false
    },
    {
        "value": "599922",
        "label": "Prosthetic Devices",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45619901",
        "yellow_page_code": "58022, 03808, 68704, 47411, 68596, 30456, 63638",
        "kiosk": false
    },
    {
        "value": "599923",
        "label": "Law Enforcement Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521933",
        "yellow_page_code": "67104, 80337, 46219, 84206",
        "kiosk": false
    },
    {
        "value": "599924",
        "label": "Swimming Pool Equipment & Supls-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521934",
        "yellow_page_code": "82816, 82823, 43009, 82825, 82910, 82831, 82829",
        "kiosk": false
    },
    {
        "value": "599925",
        "label": "Plaques",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521935",
        "yellow_page_code": "66104",
        "kiosk": false
    },
    {
        "value": "599926",
        "label": "Pictures",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521936",
        "yellow_page_code": "65003, 65108, 65107",
        "kiosk": false
    },
    {
        "value": "599927",
        "label": "Picture Frames-Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521937",
        "yellow_page_code": "34315, 65011, 65004, 34307, 93045",
        "kiosk": false
    },
    {
        "value": "599928",
        "label": "Mining Equipment & Supplies-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521938",
        "yellow_page_code": "52537",
        "kiosk": false
    },
    {
        "value": "599929",
        "label": "Pet Supplies & Foods-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45991004",
        "yellow_page_code": "60542, 60525, 02531, 02433, 41542, 02430, 48319, 12223, 08203, 18752, 25308, 25309, 60502, 08300, 60514, 60420, 02518, 48111, 60414",
        "kiosk": false
    },
    {
        "value": "599930",
        "label": "Pet Shops",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45991003",
        "yellow_page_code": "60500",
        "kiosk": false
    },
    {
        "value": "599931",
        "label": "Factory Outlets",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521939",
        "yellow_page_code": "30600, 58108, 58111",
        "kiosk": false
    },
    {
        "value": "599932",
        "label": "Diabetic Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521940",
        "yellow_page_code": "24401, 24420",
        "kiosk": false
    },
    {
        "value": "599933",
        "label": "Orthopedic Appliances",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45619905",
        "yellow_page_code": "10104, 58021, 58006",
        "kiosk": false
    },
    {
        "value": "599934",
        "label": "Mexican Goods",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521941",
        "yellow_page_code": "52108, 52103, 41016",
        "kiosk": false
    },
    {
        "value": "599935",
        "label": "Wood Carving",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521942",
        "yellow_page_code": "94210, 94202",
        "kiosk": false
    },
    {
        "value": "599936",
        "label": "Wine Makers' Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521943",
        "yellow_page_code": "93719, 93809, 93802, 93723",
        "kiosk": false
    },
    {
        "value": "599937",
        "label": "Wicker Products",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521944",
        "yellow_page_code": "12813, 93501, 93503",
        "kiosk": false
    },
    {
        "value": "599938",
        "label": "Whirlpool Bath Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521945",
        "yellow_page_code": "93404, 79121, 93423",
        "kiosk": false
    },
    {
        "value": "599939",
        "label": "Wheel Chairs & Scooters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521946",
        "yellow_page_code": "93324, 74740, 93308, 74725, 74727, 74741",
        "kiosk": false
    },
    {
        "value": "599940",
        "label": "Wedding Supplies & Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521947",
        "yellow_page_code": "92988, 92962, 92912, 92908",
        "kiosk": false
    },
    {
        "value": "599941",
        "label": "Restaurant Equipment & Supplies-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521948",
        "yellow_page_code": "71930, 71919, 71928",
        "kiosk": false
    },
    {
        "value": "599942",
        "label": "Metaphysical Supplies & Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521949",
        "yellow_page_code": "51999, 52001",
        "kiosk": false
    },
    {
        "value": "599943",
        "label": "Dry Ice",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521950",
        "yellow_page_code": "26702, 42412",
        "kiosk": false
    },
    {
        "value": "599944",
        "label": "Photo Albums & Frames",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521951",
        "yellow_page_code": "01810, 93054, 60817, 60838",
        "kiosk": false
    },
    {
        "value": "599945",
        "label": "Autograph Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521952",
        "yellow_page_code": "04409, 04441",
        "kiosk": false
    },
    {
        "value": "599946",
        "label": "Crutches",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521953",
        "yellow_page_code": "22800",
        "kiosk": false
    },
    {
        "value": "599947",
        "label": "Fraternal Regalia & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521954",
        "yellow_page_code": "34308",
        "kiosk": false
    },
    {
        "value": "599948",
        "label": "African Goods-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521955",
        "yellow_page_code": "00727, 00715",
        "kiosk": false
    },
    {
        "value": "599949",
        "label": "Coin Dealers Supplies & Etc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521956",
        "yellow_page_code": "19410, 19504, 19506, 19509",
        "kiosk": false
    },
    {
        "value": "599950",
        "label": "Church Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521957",
        "yellow_page_code": "15708",
        "kiosk": false
    },
    {
        "value": "599951",
        "label": "Christmas Lights & Decorations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521958",
        "yellow_page_code": "15617, 15616, 15608, 15618",
        "kiosk": false
    },
    {
        "value": "599952",
        "label": "Canvas Products",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521959",
        "yellow_page_code": "88028, 22212, 12909, 12936",
        "kiosk": false
    },
    {
        "value": "599953",
        "label": "Candles",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521960",
        "yellow_page_code": "12702",
        "kiosk": false
    },
    {
        "value": "599954",
        "label": "Cake Decorating Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521961",
        "yellow_page_code": "12302",
        "kiosk": false
    },
    {
        "value": "599955",
        "label": "Birds",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45991002",
        "yellow_page_code": "08227, 08207",
        "kiosk": false
    },
    {
        "value": "599956",
        "label": "Binoculars",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521962",
        "yellow_page_code": "08202",
        "kiosk": false
    },
    {
        "value": "599957",
        "label": "Hair Ornaments",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45612002",
        "yellow_page_code": "39702",
        "kiosk": false
    },
    {
        "value": "599958",
        "label": "Beer-Homebrewing Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521963",
        "yellow_page_code": "41132, 07600, 07603, 10501, 07599",
        "kiosk": false
    },
    {
        "value": "599959",
        "label": "Incense",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521964",
        "yellow_page_code": "42800",
        "kiosk": false
    },
    {
        "value": "599960",
        "label": "Baby Carriages & Strollers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521965",
        "yellow_page_code": "05508, 67938",
        "kiosk": false
    },
    {
        "value": "599961",
        "label": "Awnings & Canopies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521966",
        "yellow_page_code": "58728, 12841, 12839, 05408, 41448, 05406",
        "kiosk": false
    },
    {
        "value": "599962",
        "label": "Furniture-Repairing & Refinishing Supls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521967",
        "yellow_page_code": "35808",
        "kiosk": false
    },
    {
        "value": "599963",
        "label": "Plastics-Products-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521968",
        "yellow_page_code": "66409, 66108",
        "kiosk": false
    },
    {
        "value": "599964",
        "label": "Covers-Protective",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521969",
        "yellow_page_code": "22206",
        "kiosk": false
    },
    {
        "value": "599965",
        "label": "Artists Materials & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521970",
        "yellow_page_code": "38810, 03500, 03904, 03890, 94540, 38817, 03510, 26102",
        "kiosk": false
    },
    {
        "value": "599966",
        "label": "Ethnic Products & Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521971",
        "yellow_page_code": "29916, 74715, 74325, 37245, 55423, 01816, 14230, 10705, 03926",
        "kiosk": false
    },
    {
        "value": "599967",
        "label": "Artificial Flowers & Plants & Trees",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521972",
        "yellow_page_code": "66018, 33528, 33527, 33526, 66029, 33529, 33531, 33617, 33616, 03800, 33610, 33413, 77607, 33515, 33509",
        "kiosk": false
    },
    {
        "value": "599968",
        "label": "Artificial Breasts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521973",
        "yellow_page_code": "68633, 10404, 03608, 10415, 50334, 50333, 50404",
        "kiosk": false
    },
    {
        "value": "599969",
        "label": "Art Galleries & Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45992002",
        "yellow_page_code": "36014, 03410, 03414, 36022, 03423, 03614, 03441, 30272, 03427, 03408, 03504, 03602",
        "kiosk": false
    },
    {
        "value": "599970",
        "label": "Aquariums & Aquarium Supls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45991001",
        "yellow_page_code": "03127, 03152, 03102",
        "kiosk": false
    },
    {
        "value": "599971",
        "label": "Murals",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45992003",
        "yellow_page_code": "54602",
        "kiosk": false
    },
    {
        "value": "599972",
        "label": "Monuments",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521974",
        "yellow_page_code": "53600, 40417, 51222, 51204, 86802, 38851, 14420, 35031",
        "kiosk": false
    },
    {
        "value": "599973",
        "label": "Perfume-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45612003",
        "yellow_page_code": "60237",
        "kiosk": false
    },
    {
        "value": "599974",
        "label": "Hawaiian Goods",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521975",
        "yellow_page_code": "40307, 48716",
        "kiosk": false
    },
    {
        "value": "599975",
        "label": "Laces",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521976",
        "yellow_page_code": "45408",
        "kiosk": false
    },
    {
        "value": "599976",
        "label": "Autographic Registers & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521977",
        "yellow_page_code": "04500",
        "kiosk": false
    },
    {
        "value": "599977",
        "label": "Ice",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521978",
        "yellow_page_code": "42302",
        "kiosk": false
    },
    {
        "value": "599978",
        "label": "House Cleaning Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521979",
        "yellow_page_code": "42002",
        "kiosk": false
    },
    {
        "value": "599979",
        "label": "Hearing Aids",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45619903",
        "yellow_page_code": "40538, 40530, 84332, 40543, 40541, 40544, 40540, 40600",
        "kiosk": false
    },
    {
        "value": "599980",
        "label": "Ear Molds & Protective Devices",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521980",
        "yellow_page_code": "27105, 40532, 27080, 27082, 40556",
        "kiosk": false
    },
    {
        "value": "599981",
        "label": "Woodenware",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521981",
        "yellow_page_code": "94303",
        "kiosk": false
    },
    {
        "value": "599982",
        "label": "Webbing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521982",
        "yellow_page_code": "92900",
        "kiosk": false
    },
    {
        "value": "599983",
        "label": "Protection Devices-Self Defense",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521983",
        "yellow_page_code": "68609, 41256",
        "kiosk": false
    },
    {
        "value": "599984",
        "label": "Watch Straps & Bands",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521984",
        "yellow_page_code": "92000",
        "kiosk": false
    },
    {
        "value": "599985",
        "label": "Picnic Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521985",
        "yellow_page_code": "65001",
        "kiosk": false
    },
    {
        "value": "599986",
        "label": "Foot Appliances",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45619902",
        "yellow_page_code": "34004",
        "kiosk": false
    },
    {
        "value": "599987",
        "label": "Cemetery Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521986",
        "yellow_page_code": "14402",
        "kiosk": false
    },
    {
        "value": "599988",
        "label": "Craft Galleries & Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521987",
        "yellow_page_code": "22235, 22208, 40012, 03512, 22234, 02418, 03424",
        "kiosk": false
    },
    {
        "value": "599989",
        "label": "Art Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45992001",
        "yellow_page_code": "03411",
        "kiosk": false
    },
    {
        "value": "599990",
        "label": "Wood Products",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521988",
        "yellow_page_code": "94208, 02174, 94218",
        "kiosk": false
    },
    {
        "value": "599991",
        "label": "Mausoleums",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81222009",
        "yellow_page_code": "50610, 50615, 50608",
        "kiosk": false
    },
    {
        "value": "599992",
        "label": "Cosmetics & Perfumes-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45612001",
        "yellow_page_code": "07220, 21880, 78102, 07303, 40511, 55011, 21808, 21824, 78118, 30390, 21896, 06940, 07203",
        "kiosk": false
    },
    {
        "value": "599993",
        "label": "Baseball Sports Cards & Memorabilia",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521989",
        "yellow_page_code": "06812, 80005, 06815, 06805, 79942",
        "kiosk": false
    },
    {
        "value": "599994",
        "label": "Graduation Supplies & Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521990",
        "yellow_page_code": "38505, 38511",
        "kiosk": false
    },
    {
        "value": "599995",
        "label": "Pins-Club School Etc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521991",
        "yellow_page_code": "65401",
        "kiosk": false
    },
    {
        "value": "599996",
        "label": "Shaving Supplies-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45612005",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "599997",
        "label": "Cosmetics-Skin Care-Toiletries Kiosks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45612004",
        "yellow_page_code": "",
        "kiosk": true
    },
    {
        "value": "599998",
        "label": "Make-Up-Masquerade & Theatrical-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521992",
        "yellow_page_code": "03910, 49707",
        "kiosk": false
    },
    {
        "value": "599999",
        "label": "Miscellaneous Retail Stores NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45521993",
        "yellow_page_code": "04406, 90607, 04107, 52215, 34134, 60117, 32815, 75517, 07122, 50335, 27001, 80911, 68634, 04300, 45936, 47106, 67436, 21110, 06411, 56105, 78109, 22237, 37012, 20816, 74716, 46216, 60118, 47118, 72416, 69616, 40019, 79801, 06918, 60410, 78906, 29813, 81837, 00418, 86801, 77605, 76102, 74003, 72502, 18421, 27310, 67003, 55105, 55003, 07317, 90117, 35022, 55716, 00390, 78910, 11515, 27316, 25117, 44112, 52101, 43719, 50903, 46309, 80221, 81826, 41911, 81827, 37005, 73518, 36401, 36309, 32407, 86806, 31712, 27200, 25711, 06910, 23603, 22509, 20107, 76014, 55110, 00399, 82303, 12104, 06608",
        "kiosk": false
    },
    {
        "value": "601101",
        "label": "Federal Reserve Banks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52111001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "601901",
        "label": "Central Reserve Depository Instn NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52232002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "602101",
        "label": "Banks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52211002",
        "yellow_page_code": "06397, 34016, 06402, 51321, 06396",
        "kiosk": false
    },
    {
        "value": "602102",
        "label": "Trust Companies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52399112",
        "yellow_page_code": "89206",
        "kiosk": false
    },
    {
        "value": "602103",
        "label": "ATM-Automated Teller Machines",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52211001",
        "yellow_page_code": "04501",
        "kiosk": true
    },
    {
        "value": "602104",
        "label": "Letters Of Credit",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52211004",
        "yellow_page_code": "47005",
        "kiosk": false
    },
    {
        "value": "602105",
        "label": "Banks/Overseas-Representative Offices",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52211006",
        "yellow_page_code": "06390",
        "kiosk": false
    },
    {
        "value": "602106",
        "label": "ITM-Interactive Teller Machine",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52211007",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "602201",
        "label": "State Commercial Banks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52211005",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "602999",
        "label": "Commercial Banks NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52211003",
        "yellow_page_code": "31750",
        "kiosk": false
    },
    {
        "value": "603501",
        "label": "Savings & Loan Associations",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "52218002",
        "yellow_page_code": "31105, 11106, 41403, 74102",
        "kiosk": false
    },
    {
        "value": "603502",
        "label": "Thrift & Loan Companies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52218005",
        "yellow_page_code": "85806",
        "kiosk": false
    },
    {
        "value": "603503",
        "label": "Savings Plans",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52218004",
        "yellow_page_code": "74106",
        "kiosk": false
    },
    {
        "value": "603504",
        "label": "Savings & Loan Assns-Representatives",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52218001",
        "yellow_page_code": "74104",
        "kiosk": false
    },
    {
        "value": "603698",
        "label": "Savings Institutions-Except Federal",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52218003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "606101",
        "label": "Credit Unions",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52213003",
        "yellow_page_code": "22504",
        "kiosk": false
    },
    {
        "value": "606102",
        "label": "Federally Chartered Credit Unions",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52213006",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "606298",
        "label": "Credit Unions Not Federally Chartered",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52213005",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "608198",
        "label": "Branches & Agencies Of Foreign Banks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52229904",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "608201",
        "label": "Export Finance",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52229901",
        "yellow_page_code": "30212",
        "kiosk": false
    },
    {
        "value": "608298",
        "label": "Foreign Trade & International Banks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52229902",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "609101",
        "label": "Fiduciaries",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52399106",
        "yellow_page_code": "31518",
        "kiosk": false
    },
    {
        "value": "609198",
        "label": "Nondeposit Trust Facilities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52399108",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "609901",
        "label": "Currency Exchanges",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52316002",
        "yellow_page_code": "34120, 30018, 22907",
        "kiosk": false
    },
    {
        "value": "609902",
        "label": "Money Order Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52232008",
        "yellow_page_code": "53408",
        "kiosk": false
    },
    {
        "value": "609903",
        "label": "Check Cashing Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52232003",
        "yellow_page_code": "14802, 53421",
        "kiosk": false
    },
    {
        "value": "609904",
        "label": "Money Brokers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52399905",
        "yellow_page_code": "31790, 02636, 53406",
        "kiosk": false
    },
    {
        "value": "609905",
        "label": "Foreign Exchange Brokers & Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52316003",
        "yellow_page_code": "34001, 34010",
        "kiosk": false
    },
    {
        "value": "609906",
        "label": "Escrow Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52399105",
        "yellow_page_code": "70630, 70732, 29900",
        "kiosk": false
    },
    {
        "value": "609907",
        "label": "Travelers' Checks-Issued",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52232011",
        "yellow_page_code": "88502, 88421",
        "kiosk": false
    },
    {
        "value": "609908",
        "label": "Safe Deposit Box-Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52239004",
        "yellow_page_code": "73306",
        "kiosk": false
    },
    {
        "value": "609909",
        "label": "Clearing Houses",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52232004",
        "yellow_page_code": "18504",
        "kiosk": false
    },
    {
        "value": "609910",
        "label": "Money Transfer Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52232010",
        "yellow_page_code": "53411",
        "kiosk": false
    },
    {
        "value": "609911",
        "label": "Registered Agents",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52399909",
        "yellow_page_code": "71518",
        "kiosk": false
    },
    {
        "value": "609912",
        "label": "Payroll Distribution Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52399109",
        "yellow_page_code": "60001",
        "kiosk": false
    },
    {
        "value": "609913",
        "label": "Gift & Loyalty Cards",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52399901",
        "yellow_page_code": "37230, 13915",
        "kiosk": false
    },
    {
        "value": "609914",
        "label": "Money Order Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52232009",
        "yellow_page_code": "53414, 53500, 93220",
        "kiosk": false
    },
    {
        "value": "609915",
        "label": "Banking Systems & Service-Electronic",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52232001",
        "yellow_page_code": "30167, 06316, 06419, 88219, 43524, 06315, 28020",
        "kiosk": false
    },
    {
        "value": "609916",
        "label": "Bid Depositories",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52399102",
        "yellow_page_code": "08009",
        "kiosk": false
    },
    {
        "value": "609918",
        "label": "Gift Card Exchange Kiosk",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52239006",
        "yellow_page_code": "",
        "kiosk": true
    },
    {
        "value": "609919",
        "label": "Virtual Currency Conversion Kiosk/ATM",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52316004",
        "yellow_page_code": "",
        "kiosk": true
    },
    {
        "value": "609920",
        "label": "Peer to Peer Payment Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52232012",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "609921",
        "label": "Virtual Currency Brokering & Dealing Exchange Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52316008",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "609999",
        "label": "Functions Related To Deposit Banking",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52239002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "611101",
        "label": "Federal/Federally Sponsored Credit Agncs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52229910",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "611102",
        "label": "State Govt-Adm Of Fed Credit Agencies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52229916",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "611103",
        "label": "County Govt-Adm Of Fed Credit Agencies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52229908",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "611104",
        "label": "City Govt-Adm Of Fed Credit Agencies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52229906",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "614101",
        "label": "Loans",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52229103",
        "yellow_page_code": "48404, 53409, 41430, 41205, 34911, 29727, 41047, 41228, 30535, 53639",
        "kiosk": false
    },
    {
        "value": "614102",
        "label": "Financing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52229101",
        "yellow_page_code": "19960, 31810, 31804, 31812, 00120, 43498",
        "kiosk": false
    },
    {
        "value": "614103",
        "label": "Financing-Automobile",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52229102",
        "yellow_page_code": "04818, 13032, 13025, 31805",
        "kiosk": false
    },
    {
        "value": "614104",
        "label": "Loans-Automobile",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52229104",
        "yellow_page_code": "04629, 48412",
        "kiosk": false
    },
    {
        "value": "614106",
        "label": "Mobile Homes-Financing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52229105",
        "yellow_page_code": "88027, 53218, 53315, 49941, 53015, 49852",
        "kiosk": false
    },
    {
        "value": "614107",
        "label": "Loans-Alternative",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52229107",
        "yellow_page_code": "48424",
        "kiosk": false
    },
    {
        "value": "614108",
        "label": "Loans-Personal",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "52229108",
        "yellow_page_code": "60221, 48426",
        "kiosk": false
    },
    {
        "value": "614110",
        "label": "Title Loans",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52229109",
        "yellow_page_code": "86613, 86612, 48413",
        "kiosk": false
    },
    {
        "value": "614111",
        "label": "Automobile Title Loans",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52222002",
        "yellow_page_code": "05212",
        "kiosk": false
    },
    {
        "value": "614112",
        "label": "Boat Financing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52229110",
        "yellow_page_code": "08623",
        "kiosk": false
    },
    {
        "value": "614113",
        "label": "Payday Loans",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52229111",
        "yellow_page_code": "48340, 59911, 00422, 13926, 59907, 48351",
        "kiosk": false
    },
    {
        "value": "614114",
        "label": "Bail Bonds",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299056",
        "yellow_page_code": "05915, 05920, 09200, 05907",
        "kiosk": false
    },
    {
        "value": "614115",
        "label": "Credit Card Issuing ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52221001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "615301",
        "label": "Factors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52229909",
        "yellow_page_code": "30507, 30598",
        "kiosk": false
    },
    {
        "value": "615302",
        "label": "Credit Card & Other Credit Plans",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52232005",
        "yellow_page_code": "22406, 68028, 22430",
        "kiosk": false
    },
    {
        "value": "615303",
        "label": "Financing-Business",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52222001",
        "yellow_page_code": "11721, 21724, 31811",
        "kiosk": false
    },
    {
        "value": "615304",
        "label": "Financing-Insurance Premium",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52232007",
        "yellow_page_code": "43511, 31809",
        "kiosk": false
    },
    {
        "value": "615305",
        "label": "Debt Buyers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52232006",
        "yellow_page_code": "23610",
        "kiosk": false
    },
    {
        "value": "615901",
        "label": "Loans-Agricultural",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52229912",
        "yellow_page_code": "48337, 30818",
        "kiosk": false
    },
    {
        "value": "615902",
        "label": "Church Financing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52229905",
        "yellow_page_code": "15801",
        "kiosk": false
    },
    {
        "value": "615903",
        "label": "Commercial Paper",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52229907",
        "yellow_page_code": "20009",
        "kiosk": false
    },
    {
        "value": "615904",
        "label": "Housing-Loans & Development",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52229911",
        "yellow_page_code": "41927",
        "kiosk": false
    },
    {
        "value": "615905",
        "label": "Financial Trade Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52229903",
        "yellow_page_code": "31797",
        "kiosk": false
    },
    {
        "value": "615906",
        "label": "Credit Intermediation ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52239007",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "615907",
        "label": "Sales Financing ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52222003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "615998",
        "label": "Miscellaneous Leasing Companies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52229914",
        "yellow_page_code": "46599",
        "kiosk": false
    },
    {
        "value": "615999",
        "label": "Misc Business Credit Institutions",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52229913",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "616201",
        "label": "Real Estate Loans",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "52229202",
        "yellow_page_code": "53607, 53609, 53637, 53614, 70626, 53638, 53617, 11121, 53631, 53630, 30262, 70710, 53634, 53611, 48411, 70705, 70701, 53700, 70740, 48339",
        "kiosk": false
    },
    {
        "value": "616202",
        "label": "Foreclosure Assistance",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52239001",
        "yellow_page_code": "53635, 34100",
        "kiosk": false
    },
    {
        "value": "616203",
        "label": "Mortgage Amortization Schedules",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52229201",
        "yellow_page_code": "53636",
        "kiosk": false
    },
    {
        "value": "616204",
        "label": "Mortgage Feasibility Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52239003",
        "yellow_page_code": "53707",
        "kiosk": false
    },
    {
        "value": "616205",
        "label": "Settlement Companies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52239005",
        "yellow_page_code": "75720, 68010, 75711",
        "kiosk": false
    },
    {
        "value": "616206",
        "label": "Notes & Contracts Buyers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52229204",
        "yellow_page_code": "55717",
        "kiosk": false
    },
    {
        "value": "616207",
        "label": "Construction Loans",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52229203",
        "yellow_page_code": "21032",
        "kiosk": false
    },
    {
        "value": "616301",
        "label": "Loan Brokerage",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52231002",
        "yellow_page_code": "10837, 48336",
        "kiosk": false
    },
    {
        "value": "616302",
        "label": "Industrial Plants-Bought",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52231001",
        "yellow_page_code": "42917",
        "kiosk": false
    },
    {
        "value": "616303",
        "label": "Viatical Settlements",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52231003",
        "yellow_page_code": "91023, 43486",
        "kiosk": false
    },
    {
        "value": "616304",
        "label": "Structured Settlement Buyers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52231004",
        "yellow_page_code": "82017",
        "kiosk": false
    },
    {
        "value": "616305",
        "label": "Remodeling Loans",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52231005",
        "yellow_page_code": "71637",
        "kiosk": false
    },
    {
        "value": "621101",
        "label": "Stock & Bond Brokers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52315007",
        "yellow_page_code": "34512, 69240, 81406, 33420, 30297",
        "kiosk": false
    },
    {
        "value": "621102",
        "label": "Oil Land Leases",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52399907",
        "yellow_page_code": "56700, 56613, 56608",
        "kiosk": false
    },
    {
        "value": "621103",
        "label": "Oil Brokers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52399906",
        "yellow_page_code": "56409",
        "kiosk": false
    },
    {
        "value": "621104",
        "label": "Mutual Funds",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52391002",
        "yellow_page_code": "55006, 43463",
        "kiosk": false
    },
    {
        "value": "621105",
        "label": "Investment Securities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52315002",
        "yellow_page_code": "43708, 30228, 43766, 28621",
        "kiosk": false
    },
    {
        "value": "621106",
        "label": "Mining Brokers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52399904",
        "yellow_page_code": "52607",
        "kiosk": false
    },
    {
        "value": "621108",
        "label": "Govt-Security Brokers Dlrs/Flotation Co",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52315005",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "621109",
        "label": "Investment Bankers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52315001",
        "yellow_page_code": "43710",
        "kiosk": false
    },
    {
        "value": "621110",
        "label": "Securities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52315006",
        "yellow_page_code": "75302",
        "kiosk": false
    },
    {
        "value": "621111",
        "label": "Investments",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52391001",
        "yellow_page_code": "43709, 31798, 43717",
        "kiosk": false
    },
    {
        "value": "621112",
        "label": "Bonds-Construction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52315003",
        "yellow_page_code": "09214",
        "kiosk": false
    },
    {
        "value": "621113",
        "label": "Bonds-Special Assessment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52315008",
        "yellow_page_code": "09212",
        "kiosk": false
    },
    {
        "value": "621114",
        "label": "Information Securities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52315004",
        "yellow_page_code": "43027",
        "kiosk": false
    },
    {
        "value": "622101",
        "label": "Liquor Brokers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52316007",
        "yellow_page_code": "47903",
        "kiosk": false
    },
    {
        "value": "622102",
        "label": "Cotton Brokers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52316006",
        "yellow_page_code": "22004",
        "kiosk": false
    },
    {
        "value": "622103",
        "label": "Commodity Price Analysts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52316001",
        "yellow_page_code": "20007, 30410",
        "kiosk": false
    },
    {
        "value": "622104",
        "label": "Commodity Brokers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52316005",
        "yellow_page_code": "43711, 43576, 30176, 20006, 20022",
        "kiosk": false
    },
    {
        "value": "623101",
        "label": "Barter & Trade Exchange",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52321001",
        "yellow_page_code": "06800, 06799",
        "kiosk": false
    },
    {
        "value": "623102",
        "label": "Stock Exchanges",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52321005",
        "yellow_page_code": "81408",
        "kiosk": false
    },
    {
        "value": "623103",
        "label": "Commodity Exchanges",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52321003",
        "yellow_page_code": "20003",
        "kiosk": false
    },
    {
        "value": "623104",
        "label": "Boards Of Trade",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52321002",
        "yellow_page_code": "08608",
        "kiosk": false
    },
    {
        "value": "623105",
        "label": "Futures-Exchanges",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52321004",
        "yellow_page_code": "35999",
        "kiosk": false
    },
    {
        "value": "623106",
        "label": "Virtual Currency Trading Exchange Clearinghouses",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52399911",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "628201",
        "label": "Retirement Planning Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52394015",
        "yellow_page_code": "72007, 30135, 72024",
        "kiosk": false
    },
    {
        "value": "628202",
        "label": "Investment Management",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52394001",
        "yellow_page_code": "43706",
        "kiosk": false
    },
    {
        "value": "628203",
        "label": "Financial Advisory Services",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "52394006",
        "yellow_page_code": "31800, 31785, 94818, 30188, 31793, 30221, 31792, 43626, 43704",
        "kiosk": false
    },
    {
        "value": "628204",
        "label": "Financing Consultants",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "52394011",
        "yellow_page_code": "21067, 31806, 31789, 31787, 31786",
        "kiosk": false
    },
    {
        "value": "628205",
        "label": "Financial Planning Consultants",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "52394009",
        "yellow_page_code": "53420, 72071, 92698, 31802, 30180, 31788",
        "kiosk": false
    },
    {
        "value": "628206",
        "label": "Financial Planners-Certified",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "52394008",
        "yellow_page_code": "31801",
        "kiosk": false
    },
    {
        "value": "628207",
        "label": "Mergers & Acquisitions",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52394013",
        "yellow_page_code": "00216, 51313, 11718",
        "kiosk": false
    },
    {
        "value": "628208",
        "label": "Banks-Consulting Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52394005",
        "yellow_page_code": "06313",
        "kiosk": false
    },
    {
        "value": "628210",
        "label": "Retirement Income Funds",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52394014",
        "yellow_page_code": "71519, 72020, 72018",
        "kiosk": false
    },
    {
        "value": "628211",
        "label": "Financial Report Analysts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52394010",
        "yellow_page_code": "31803",
        "kiosk": false
    },
    {
        "value": "628213",
        "label": "Investment Advisory Referral Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52394012",
        "yellow_page_code": "43703",
        "kiosk": false
    },
    {
        "value": "628214",
        "label": "Investment Trusts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52394004",
        "yellow_page_code": "43707",
        "kiosk": false
    },
    {
        "value": "628215",
        "label": "Tax Sheltered Investments",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52394003",
        "yellow_page_code": "84112, 43795",
        "kiosk": false
    },
    {
        "value": "628217",
        "label": "Asset Management",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52394002",
        "yellow_page_code": "04051",
        "kiosk": false
    },
    {
        "value": "628218",
        "label": "Independent Financial Advisers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52394007",
        "yellow_page_code": "42825",
        "kiosk": false
    },
    {
        "value": "628901",
        "label": "Stock & Bond Transfer Agents",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52399111",
        "yellow_page_code": "81407",
        "kiosk": false
    },
    {
        "value": "628902",
        "label": "Proxy Solicitors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52399110",
        "yellow_page_code": "68615",
        "kiosk": false
    },
    {
        "value": "628903",
        "label": "Bonds-Municipal & Corporate",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52399103",
        "yellow_page_code": "09201",
        "kiosk": false
    },
    {
        "value": "628904",
        "label": "Brokers-Countertrade",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52399104",
        "yellow_page_code": "10900",
        "kiosk": false
    },
    {
        "value": "628905",
        "label": "Lumber-Commission",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52399115",
        "yellow_page_code": "48908",
        "kiosk": false
    },
    {
        "value": "628999",
        "label": "Services Allied With Exch Of Sec NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52399910",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "631101",
        "label": "Insurance-Savings-Bank-Life",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52411302",
        "yellow_page_code": "43507",
        "kiosk": false
    },
    {
        "value": "631102",
        "label": "Insurance-Burial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52413004",
        "yellow_page_code": "43407",
        "kiosk": false
    },
    {
        "value": "631103",
        "label": "Insurance-Chartered-Life-Underwriters",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "52411301",
        "yellow_page_code": "43416, 43442",
        "kiosk": false
    },
    {
        "value": "631198",
        "label": "Life Insurance (Underwriters)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52411303",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "632101",
        "label": "Health Systems Evaluation Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52519001",
        "yellow_page_code": "40605",
        "kiosk": false
    },
    {
        "value": "632102",
        "label": "Insurance-Cancer",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52411405",
        "yellow_page_code": "43414",
        "kiosk": false
    },
    {
        "value": "632198",
        "label": "Accident & Health Ins (Underwriters)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52411401",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "632401",
        "label": "Medical Insurance Plans",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52411407",
        "yellow_page_code": "43480, 51064, 51035, 75218",
        "kiosk": false
    },
    {
        "value": "632402",
        "label": "Hospitalization Plans-Medical & Surgical",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52411404",
        "yellow_page_code": "57517, 41804, 91242",
        "kiosk": false
    },
    {
        "value": "632403",
        "label": "Preferred Provider Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52411409",
        "yellow_page_code": "68007",
        "kiosk": false
    },
    {
        "value": "632404",
        "label": "Health Plans",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52411402",
        "yellow_page_code": "40519, 40429",
        "kiosk": false
    },
    {
        "value": "632405",
        "label": "Insurance Senior Citizens",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52411406",
        "yellow_page_code": "43484",
        "kiosk": false
    },
    {
        "value": "632406",
        "label": "Medical Provider Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52411408",
        "yellow_page_code": "51119",
        "kiosk": false
    },
    {
        "value": "632407",
        "label": "Surgical & Medical Plans",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52411410",
        "yellow_page_code": "51057, 82411",
        "kiosk": false
    },
    {
        "value": "632408",
        "label": "Medical Emergency Funds",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52519002",
        "yellow_page_code": "51005",
        "kiosk": false
    },
    {
        "value": "632498",
        "label": "Hospital & Medical Service Plans",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52411403",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "633101",
        "label": "Insurance-Property & Casualty",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52412607",
        "yellow_page_code": "60219, 60217, 43392, 33106, 43453, 43501",
        "kiosk": false
    },
    {
        "value": "633102",
        "label": "Insurance-Fire",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52412605",
        "yellow_page_code": "43426, 43457",
        "kiosk": false
    },
    {
        "value": "633103",
        "label": "Government-Fire Marine & Casualty Ins",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52412603",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "633105",
        "label": "Insurance-Boat",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52412608",
        "yellow_page_code": "08625, 43425",
        "kiosk": false
    },
    {
        "value": "633106",
        "label": "Insurance Chartered Property Underwriter",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52413005",
        "yellow_page_code": "43382",
        "kiosk": false
    },
    {
        "value": "635102",
        "label": "Bonds-Surety & Fidelity",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52412602",
        "yellow_page_code": "82314, 09202",
        "kiosk": false
    },
    {
        "value": "635103",
        "label": "Insurance-Bonds",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52412604",
        "yellow_page_code": "43424",
        "kiosk": false
    },
    {
        "value": "635104",
        "label": "Insurance-Liability",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52412606",
        "yellow_page_code": "47021, 43427",
        "kiosk": false
    },
    {
        "value": "635105",
        "label": "Credit Card-Protection Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52413001",
        "yellow_page_code": "22409",
        "kiosk": false
    },
    {
        "value": "635106",
        "label": "Bonding Agents",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52412601",
        "yellow_page_code": "09109",
        "kiosk": false
    },
    {
        "value": "635107",
        "label": "Home Warranty Plans",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52413003",
        "yellow_page_code": "41218",
        "kiosk": false
    },
    {
        "value": "635109",
        "label": "Warranty Programs-Extended",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52413002",
        "yellow_page_code": "91620, 91613, 30398, 05232",
        "kiosk": false
    },
    {
        "value": "636101",
        "label": "Insurance-Title",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52412701",
        "yellow_page_code": "43504, 86603",
        "kiosk": false
    },
    {
        "value": "637101",
        "label": "Individual Retirement Accounts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52511003",
        "yellow_page_code": "42907, 43820",
        "kiosk": false
    },
    {
        "value": "637102",
        "label": "Pension & Profit Sharing Plans",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52511004",
        "yellow_page_code": "43329, 60202",
        "kiosk": false
    },
    {
        "value": "637103",
        "label": "Employee Benefit & Compensation Plans",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52511001",
        "yellow_page_code": "51116, 07820, 60135, 40441, 40550, 28517",
        "kiosk": false
    },
    {
        "value": "637104",
        "label": "Government-Pension Health/Welfare Funds",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52511002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "637105",
        "label": "Health & Welfare Funds ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52512001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "637198",
        "label": "Pension Funds",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52511005",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "639901",
        "label": "Real Estate Maintenance Protection Plans",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52412805",
        "yellow_page_code": "70707",
        "kiosk": false
    },
    {
        "value": "639902",
        "label": "Automobile Warranty Processing Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52412801",
        "yellow_page_code": "05307",
        "kiosk": false
    },
    {
        "value": "639903",
        "label": "Pet Health Plans",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52412804",
        "yellow_page_code": "60408",
        "kiosk": false
    },
    {
        "value": "639905",
        "label": "Federal Deposit Insurance",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52412802",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "639906",
        "label": "Reinsurance Carriers ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52413006",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "639999",
        "label": "Insurance Carriers NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52412803",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "641101",
        "label": "Adjusters-Public",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52429101",
        "yellow_page_code": "00309, 68810",
        "kiosk": false
    },
    {
        "value": "641102",
        "label": "Insurance Adjusters",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "52429103",
        "yellow_page_code": "43060, 00308, 43408, 00313, 43472, 04091, 19941",
        "kiosk": false
    },
    {
        "value": "641103",
        "label": "Insurance-Inspection & Audits",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52429807",
        "yellow_page_code": "43502",
        "kiosk": false
    },
    {
        "value": "641104",
        "label": "Insurance Consultants & Advisors",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "52429804",
        "yellow_page_code": "43318, 43481, 43419, 51028, 43500, 71514",
        "kiosk": false
    },
    {
        "value": "641105",
        "label": "Insurance-Mobile Home",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "52421007",
        "yellow_page_code": "43377, 43421, 53121",
        "kiosk": false
    },
    {
        "value": "641106",
        "label": "Insurance-Health & Accident",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "52421004",
        "yellow_page_code": "43385, 43418, 43446, 43448, 40419, 43434, 67959, 43496, 43379",
        "kiosk": false
    },
    {
        "value": "641107",
        "label": "Insurance-Homeowners",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "52421005",
        "yellow_page_code": "43450, 43371, 43365, 43420, 41276, 43375",
        "kiosk": false
    },
    {
        "value": "641108",
        "label": "Insurance-Group",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "52421003",
        "yellow_page_code": "43411, 43460, 43410",
        "kiosk": false
    },
    {
        "value": "641109",
        "label": "Insurance-Life (Agents)",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "52421006",
        "yellow_page_code": "47113, 43412, 43765",
        "kiosk": false
    },
    {
        "value": "641110",
        "label": "Insurance-Claim Processing Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52429201",
        "yellow_page_code": "43409, 51122, 43433, 51125, 51030",
        "kiosk": false
    },
    {
        "value": "641111",
        "label": "Insurance-Automobile",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "52421002",
        "yellow_page_code": "54229, 54226, 13054, 43489, 43437, 43474, 43473, 43468, 43467, 43449, 13074, 04625, 43405",
        "kiosk": false
    },
    {
        "value": "641112",
        "label": "Insurance",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "52421001",
        "yellow_page_code": "43366, 43406, 43399, 43436, 43439, 43431, 43443, 43444, 43445, 43451, 43454, 43452, 43455, 43456, 43461, 43464, 43465, 43466, 43469, 43476, 43477, 43479, 43499, 51126, 29628, 43475, 43432, 43441, 05328, 43482, 39204, 22691, 43393, 43389, 70623, 94510, 11117, 11723, 43637, 43397, 43490, 43491, 43494, 00730, 21054, 43387, 43386, 43384, 43378, 89009, 43374, 43372, 43370, 43368, 43367, 43764, 56542, 35032, 04185",
        "kiosk": false
    },
    {
        "value": "641113",
        "label": "Workmen's Compensation Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52429815",
        "yellow_page_code": "94412, 94517, 20133, 94505, 20152, 21166",
        "kiosk": false
    },
    {
        "value": "641114",
        "label": "Insurance-Excess & Surplus",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52429806",
        "yellow_page_code": "43428",
        "kiosk": false
    },
    {
        "value": "641115",
        "label": "Insured Property Replacement Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52429812",
        "yellow_page_code": "43506, 43447",
        "kiosk": false
    },
    {
        "value": "641116",
        "label": "Insurance-Reports",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52429811",
        "yellow_page_code": "43471, 43503",
        "kiosk": false
    },
    {
        "value": "641117",
        "label": "Loss Prevention Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52429813",
        "yellow_page_code": "48618",
        "kiosk": false
    },
    {
        "value": "641118",
        "label": "Farm & Ranch Insurance",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52429802",
        "yellow_page_code": "30913",
        "kiosk": false
    },
    {
        "value": "641119",
        "label": "Pension Claim Agents",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52429814",
        "yellow_page_code": "60201",
        "kiosk": false
    },
    {
        "value": "641120",
        "label": "Hospital/Hlth Facilities Planning Agency",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52429803",
        "yellow_page_code": "41805",
        "kiosk": false
    },
    {
        "value": "641121",
        "label": "Insurance Managers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52429805",
        "yellow_page_code": "43422",
        "kiosk": false
    },
    {
        "value": "641122",
        "label": "Insurance-Plan Administrators",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52429809",
        "yellow_page_code": "43395, 43317, 43429",
        "kiosk": false
    },
    {
        "value": "641123",
        "label": "Insurance-Rating Bureaus",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52429810",
        "yellow_page_code": "43440, 43505",
        "kiosk": false
    },
    {
        "value": "641124",
        "label": "Compensation-Accident Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52429801",
        "yellow_page_code": "20208",
        "kiosk": false
    },
    {
        "value": "641125",
        "label": "Insurance-Investigators",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52429808",
        "yellow_page_code": "18227, 43423",
        "kiosk": false
    },
    {
        "value": "641126",
        "label": "Insurance Appraisers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52429102",
        "yellow_page_code": "03017, 43391",
        "kiosk": false
    },
    {
        "value": "641127",
        "label": "Insurance-Pet",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "52429816",
        "yellow_page_code": "43478, 60429",
        "kiosk": false
    },
    {
        "value": "641128",
        "label": "Repair & Remodeling-Insurance",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52421008",
        "yellow_page_code": "71721",
        "kiosk": false
    },
    {
        "value": "641129",
        "label": "Insurance Travel",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "52421010",
        "yellow_page_code": "88427, 43485",
        "kiosk": false
    },
    {
        "value": "641130",
        "label": "Insurance Referral Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52429817",
        "yellow_page_code": "43435",
        "kiosk": false
    },
    {
        "value": "641131",
        "label": "Insurance-Estate Planning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52429818",
        "yellow_page_code": "43438",
        "kiosk": false
    },
    {
        "value": "641132",
        "label": "Insurance-Mortgage",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "52421011",
        "yellow_page_code": "53633, 53618, 43483",
        "kiosk": false
    },
    {
        "value": "641133",
        "label": "Insurance-Holding Companies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52429819",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "641134",
        "label": "Golf Insurance",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52421012",
        "yellow_page_code": "38214",
        "kiosk": false
    },
    {
        "value": "641135",
        "label": "Insurance Buying & Transferring",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52421013",
        "yellow_page_code": "43394",
        "kiosk": false
    },
    {
        "value": "641136",
        "label": "Insurance Mining",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52429821",
        "yellow_page_code": "43487",
        "kiosk": false
    },
    {
        "value": "641137",
        "label": "Cobra Administration",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52429822",
        "yellow_page_code": "19215",
        "kiosk": false
    },
    {
        "value": "641138",
        "label": "Insurance Cafeteria Plans",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52421014",
        "yellow_page_code": "43495",
        "kiosk": false
    },
    {
        "value": "641139",
        "label": "Insurance Prescription Drug Plans",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52429823",
        "yellow_page_code": "43417",
        "kiosk": false
    },
    {
        "value": "641140",
        "label": "Insurance Mergers & Acquisitions",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52429824",
        "yellow_page_code": "43388",
        "kiosk": false
    },
    {
        "value": "641198",
        "label": "Insurance Agents Brokers & Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52421009",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "651201",
        "label": "Shopping Centers & Malls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53112008",
        "yellow_page_code": "77106, 77104, 30093, 49739, 11123, 77110, 90730, 49811",
        "kiosk": false
    },
    {
        "value": "651202",
        "label": "Office Buildings & Parks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53112004",
        "yellow_page_code": "11720, 11307, 56222, 07210, 48528, 70632, 56206",
        "kiosk": false
    },
    {
        "value": "651203",
        "label": "Executive Suites",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53112001",
        "yellow_page_code": "30101",
        "kiosk": false
    },
    {
        "value": "651205",
        "label": "Shopping Center Developers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53112007",
        "yellow_page_code": "77103, 77105",
        "kiosk": false
    },
    {
        "value": "651206",
        "label": "World Trade Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53112009",
        "yellow_page_code": "94413",
        "kiosk": false
    },
    {
        "value": "651207",
        "label": "Shopping Center Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53112006",
        "yellow_page_code": "77114",
        "kiosk": false
    },
    {
        "value": "651208",
        "label": "Industrial Parks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53112003",
        "yellow_page_code": "42918",
        "kiosk": false
    },
    {
        "value": "651209",
        "label": "Rental Space",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53112012",
        "yellow_page_code": "71719, 71718",
        "kiosk": false
    },
    {
        "value": "651210",
        "label": "Retail Space-Renting & Leasing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53112010",
        "yellow_page_code": "72022",
        "kiosk": false
    },
    {
        "value": "651211",
        "label": "Event Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71131005",
        "yellow_page_code": "29933",
        "kiosk": false
    },
    {
        "value": "651212",
        "label": "Commercial Kitchen Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53112011",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "651298",
        "label": "Operators Of Nonresidential Buildings",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53112005",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "651301",
        "label": "Condominiums",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53111004",
        "yellow_page_code": "56099, 70611, 20813, 20898, 20902, 20899",
        "kiosk": false
    },
    {
        "value": "651302",
        "label": "Boarding Houses",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53111003",
        "yellow_page_code": "08606, 72800",
        "kiosk": false
    },
    {
        "value": "651303",
        "label": "Apartments",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53111002",
        "yellow_page_code": "02910, 02906, 93030, 32915, 30322, 02915, 02835",
        "kiosk": false
    },
    {
        "value": "651304",
        "label": "Apartment Rental Agencies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53111001",
        "yellow_page_code": "02902",
        "kiosk": false
    },
    {
        "value": "651305",
        "label": "Retirement Apartments & Hotels",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53111008",
        "yellow_page_code": "30148, 72005",
        "kiosk": false
    },
    {
        "value": "651307",
        "label": "Apartments-Short Term",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53111010",
        "yellow_page_code": "02840",
        "kiosk": false
    },
    {
        "value": "651308",
        "label": "Condominium Reserve Fund Studies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53111011",
        "yellow_page_code": "20812",
        "kiosk": false
    },
    {
        "value": "651398",
        "label": "Operators Of Apartment Buildings",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53111006",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "651401",
        "label": "Townhouses",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53111009",
        "yellow_page_code": "87508",
        "kiosk": false
    },
    {
        "value": "651498",
        "label": "Operators Of Dwellings Other Than Apts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53111007",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "651501",
        "label": "Mobile Homes-Parks & Communities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53119004",
        "yellow_page_code": "43742, 53006, 87901, 53290, 53118, 49841, 53021, 53017, 49949, 49919",
        "kiosk": false
    },
    {
        "value": "651798",
        "label": "Lessors Of Railroad Property",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53119002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "651901",
        "label": "Oil Properties",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53119005",
        "yellow_page_code": "56709",
        "kiosk": false
    },
    {
        "value": "651902",
        "label": "Oil Property Management",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53119006",
        "yellow_page_code": "56800",
        "kiosk": false
    },
    {
        "value": "651903",
        "label": "Coal Land Leases",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53119001",
        "yellow_page_code": "18906",
        "kiosk": false
    },
    {
        "value": "651999",
        "label": "Lessors Of Real Property NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53119003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "653101",
        "label": "Relocation Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53139014",
        "yellow_page_code": "70690, 71605",
        "kiosk": false
    },
    {
        "value": "653102",
        "label": "Office & Desk Space-Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53131205",
        "yellow_page_code": "56204, 30078, 56301",
        "kiosk": false
    },
    {
        "value": "653103",
        "label": "Brokers-Hotel Motel & Apartment House",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53131201",
        "yellow_page_code": "10901",
        "kiosk": false
    },
    {
        "value": "653104",
        "label": "Real Estate Investments",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53121004",
        "yellow_page_code": "43800, 30189, 70704, 67964, 70724, 70728, 70712",
        "kiosk": false
    },
    {
        "value": "653105",
        "label": "House Buyers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53131105",
        "yellow_page_code": "41909",
        "kiosk": false
    },
    {
        "value": "653106",
        "label": "Estate Management",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53121001",
        "yellow_page_code": "29834, 67962, 68320, 29902",
        "kiosk": false
    },
    {
        "value": "653107",
        "label": "Cooperatives",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81399002",
        "yellow_page_code": "21440, 21502, 21435",
        "kiosk": false
    },
    {
        "value": "653108",
        "label": "Real Estate Management",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "53121005",
        "yellow_page_code": "56468, 11205, 68703, 70706, 19925",
        "kiosk": false
    },
    {
        "value": "653109",
        "label": "Appraisers-Industrial & Commercial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53132001",
        "yellow_page_code": "03011",
        "kiosk": false
    },
    {
        "value": "653110",
        "label": "Apartment Sharing Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53131102",
        "yellow_page_code": "02904",
        "kiosk": false
    },
    {
        "value": "653111",
        "label": "Apartment Finding & Rental Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53131101",
        "yellow_page_code": "02900, 02837, 02838",
        "kiosk": false
    },
    {
        "value": "653112",
        "label": "Roommate Referral Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53131110",
        "yellow_page_code": "72801",
        "kiosk": false
    },
    {
        "value": "653113",
        "label": "Real Estate-Rental Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53121006",
        "yellow_page_code": "70731, 70708, 71702, 71711",
        "kiosk": false
    },
    {
        "value": "653114",
        "label": "Real Estate Syndicators",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53139011",
        "yellow_page_code": "70711",
        "kiosk": false
    },
    {
        "value": "653115",
        "label": "Real Estate Information Reports",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53139009",
        "yellow_page_code": "70709, 70723",
        "kiosk": false
    },
    {
        "value": "653116",
        "label": "Real Estate Appraisers",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "53132003",
        "yellow_page_code": "03026, 03031, 41829, 20807, 70608, 70620, 70617",
        "kiosk": false
    },
    {
        "value": "653117",
        "label": "Real Estate Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53139006",
        "yellow_page_code": "70721, 70714, 70700, 21159, 70717, 70618",
        "kiosk": false
    },
    {
        "value": "653118",
        "label": "Real Estate",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "53121003",
        "yellow_page_code": "70607, 56210, 70722, 70726, 70727, 70610, 70615, 70730, 11215, 70622, 11725, 30286, 43683, 43684, 70631, 70606",
        "kiosk": false
    },
    {
        "value": "653119",
        "label": "Cooperative Housing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53131202",
        "yellow_page_code": "21436",
        "kiosk": false
    },
    {
        "value": "653120",
        "label": "Real Estate Buyers & Brokers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53131103",
        "yellow_page_code": "29845, 70720, 45821, 41128",
        "kiosk": false
    },
    {
        "value": "653121",
        "label": "Garage Management",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53131203",
        "yellow_page_code": "36111",
        "kiosk": false
    },
    {
        "value": "653122",
        "label": "Real Estate Inspection",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53139008",
        "yellow_page_code": "41267, 68641, 41366, 25491, 41134, 43345, 41240, 70803, 41112",
        "kiosk": false
    },
    {
        "value": "653123",
        "label": "Housing-Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53121002",
        "yellow_page_code": "71625, 71618, 41920, 41916, 41934, 71728",
        "kiosk": false
    },
    {
        "value": "653124",
        "label": "Housing Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53139002",
        "yellow_page_code": "42026, 42205, 21151",
        "kiosk": false
    },
    {
        "value": "653125",
        "label": "Real Estate Exchangers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53139007",
        "yellow_page_code": "70725, 70612, 70629",
        "kiosk": false
    },
    {
        "value": "653127",
        "label": "Rental Agencies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53121007",
        "yellow_page_code": "41044, 71713, 71710, 71716, 41043",
        "kiosk": false
    },
    {
        "value": "653128",
        "label": "Manufactured Mobile Homes Appraisers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53132002",
        "yellow_page_code": "53105, 49914",
        "kiosk": false
    },
    {
        "value": "653129",
        "label": "Mobile Homes-Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53139004",
        "yellow_page_code": "53001, 53113",
        "kiosk": false
    },
    {
        "value": "653130",
        "label": "Vacation Time Sharing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53121010",
        "yellow_page_code": "70715, 86203, 20809, 90300, 86136",
        "kiosk": false
    },
    {
        "value": "653131",
        "label": "Rent Review Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53139015",
        "yellow_page_code": "71611",
        "kiosk": false
    },
    {
        "value": "653132",
        "label": "Housing Authorities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53131106",
        "yellow_page_code": "75475, 42010, 42007, 41914",
        "kiosk": false
    },
    {
        "value": "653133",
        "label": "Ranch Sales",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53131109",
        "yellow_page_code": "70312",
        "kiosk": false
    },
    {
        "value": "653134",
        "label": "Real Estate Video Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53139012",
        "yellow_page_code": "70718",
        "kiosk": false
    },
    {
        "value": "653135",
        "label": "Real Estate Support Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53139010",
        "yellow_page_code": "70719",
        "kiosk": false
    },
    {
        "value": "653136",
        "label": "Shoreline Management",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53131206",
        "yellow_page_code": "77117",
        "kiosk": false
    },
    {
        "value": "653137",
        "label": "Housing-Low Income",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53131108",
        "yellow_page_code": "41937, 48636, 41923",
        "kiosk": false
    },
    {
        "value": "653138",
        "label": "Property Identification Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53139005",
        "yellow_page_code": "68614",
        "kiosk": false
    },
    {
        "value": "653139",
        "label": "Housing-Industrial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53131204",
        "yellow_page_code": "42020",
        "kiosk": false
    },
    {
        "value": "653140",
        "label": "Assessment Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53139001",
        "yellow_page_code": "04101, 68636",
        "kiosk": false
    },
    {
        "value": "653141",
        "label": "Vacation Rentals",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53131111",
        "yellow_page_code": "90211, 70811, 70627, 90215",
        "kiosk": false
    },
    {
        "value": "653142",
        "label": "Landlord & Tenant Officers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53131107",
        "yellow_page_code": "71314, 45811",
        "kiosk": false
    },
    {
        "value": "653143",
        "label": "Homes-Proprietary",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53131104",
        "yellow_page_code": "68594, 41315",
        "kiosk": false
    },
    {
        "value": "653144",
        "label": "Real Estate Buyers Representatives",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53139016",
        "yellow_page_code": "70736, 70625",
        "kiosk": false
    },
    {
        "value": "653145",
        "label": "Training Facilities Equip & Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53139018",
        "yellow_page_code": "88128",
        "kiosk": false
    },
    {
        "value": "653146",
        "label": "Manufactured Housing Brokers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53121009",
        "yellow_page_code": "49842",
        "kiosk": false
    },
    {
        "value": "653147",
        "label": "Real Estate Settlement Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53139017",
        "yellow_page_code": "70741",
        "kiosk": false
    },
    {
        "value": "653148",
        "label": "Letting Agents",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53121008",
        "yellow_page_code": "47011",
        "kiosk": false
    },
    {
        "value": "653149",
        "label": "Energy Raters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53139003",
        "yellow_page_code": "28622, 41235",
        "kiosk": false
    },
    {
        "value": "653150",
        "label": "Residential Property Managers ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53131114",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "654101",
        "label": "Title Examiners",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54119107",
        "yellow_page_code": "86604",
        "kiosk": false
    },
    {
        "value": "654102",
        "label": "Title Companies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54119106",
        "yellow_page_code": "41470, 86601, 86605, 86602, 70802, 70616",
        "kiosk": false
    },
    {
        "value": "654103",
        "label": "Abstracters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54119101",
        "yellow_page_code": "00004, 00033",
        "kiosk": false
    },
    {
        "value": "654104",
        "label": "Land Contracts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54119103",
        "yellow_page_code": "45813, 45803",
        "kiosk": false
    },
    {
        "value": "654105",
        "label": "Finding & Searching Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54119102",
        "yellow_page_code": "31807",
        "kiosk": false
    },
    {
        "value": "654106",
        "label": "Real Estate Research Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54119104",
        "yellow_page_code": "70716",
        "kiosk": false
    },
    {
        "value": "654107",
        "label": "Real Estate Tax Searchers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54119105",
        "yellow_page_code": "70801",
        "kiosk": false
    },
    {
        "value": "655201",
        "label": "Land Companies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23721002",
        "yellow_page_code": "45737, 45804",
        "kiosk": false
    },
    {
        "value": "655202",
        "label": "Real Estate Developers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23721005",
        "yellow_page_code": "70702, 24322, 82190, 29847, 45736, 70619, 67963",
        "kiosk": false
    },
    {
        "value": "655203",
        "label": "Mobile Homes & Trailers-Parks Designers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23721003",
        "yellow_page_code": "53325, 53009, 53011",
        "kiosk": false
    },
    {
        "value": "655204",
        "label": "Mobile Homes-Park Developers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23721004",
        "yellow_page_code": "53107, 49827",
        "kiosk": false
    },
    {
        "value": "655205",
        "label": "Hotel & Motel Developers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23721001",
        "yellow_page_code": "41910",
        "kiosk": false
    },
    {
        "value": "655206",
        "label": "Property Management-Commercial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23721006",
        "yellow_page_code": "68612",
        "kiosk": false
    },
    {
        "value": "655207",
        "label": "Housing Developers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23721007",
        "yellow_page_code": "41925, 24326",
        "kiosk": false
    },
    {
        "value": "655301",
        "label": "Columbariums",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81222005",
        "yellow_page_code": "19904",
        "kiosk": false
    },
    {
        "value": "655302",
        "label": "Cemeteries",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81222002",
        "yellow_page_code": "51238, 14400, 35024",
        "kiosk": false
    },
    {
        "value": "655303",
        "label": "National Cemeteries",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81222008",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "655304",
        "label": "Caskets-Pet",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81222001",
        "yellow_page_code": "13928, 60424",
        "kiosk": false
    },
    {
        "value": "655305",
        "label": "Cemeteries & Memorial Parks Plots",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81222003",
        "yellow_page_code": "14315, 14401",
        "kiosk": false
    },
    {
        "value": "655398",
        "label": "Cemetery Subdividers & Developers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81222004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "671201",
        "label": "Holding Companies (Bank)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "55111101",
        "yellow_page_code": "41200",
        "kiosk": false
    },
    {
        "value": "671901",
        "label": "Holding Companies (Non-Bank)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "55111201",
        "yellow_page_code": "41199, 41198",
        "kiosk": false
    },
    {
        "value": "671902",
        "label": "Restaurant Holding Companies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "55111203",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "671904",
        "label": "Utilities-Holding Companies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "55111204",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "672201",
        "label": "Money Market Funds",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52591002",
        "yellow_page_code": "53407",
        "kiosk": false
    },
    {
        "value": "672298",
        "label": "Management Investment Open-End",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52591001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "672601",
        "label": "Insurance Annuities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52599002",
        "yellow_page_code": "43415, 02701",
        "kiosk": false
    },
    {
        "value": "672699",
        "label": "Unit Investment Trusts-Certificate Ofcs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52599003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "673201",
        "label": "Educational Scholarship Plans",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81321102",
        "yellow_page_code": "27140, 27213, 71525, 19632, 31791, 19706",
        "kiosk": false
    },
    {
        "value": "673202",
        "label": "Scholarship Programs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81321103",
        "yellow_page_code": "74315, 74465, 74340, 74318, 74317, 74316",
        "kiosk": false
    },
    {
        "value": "673298",
        "label": "Education Religious & Charitable Trusts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81321101",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "673301",
        "label": "Trust Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52399113",
        "yellow_page_code": "89209, 90020, 15147",
        "kiosk": false
    },
    {
        "value": "673302",
        "label": "Guardianship Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52399107",
        "yellow_page_code": "39211, 39210",
        "kiosk": false
    },
    {
        "value": "673303",
        "label": "Trustees",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52399114",
        "yellow_page_code": "89300",
        "kiosk": false
    },
    {
        "value": "673304",
        "label": "Bankruptcy Trustees",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52399101",
        "yellow_page_code": "06312, 47130",
        "kiosk": false
    },
    {
        "value": "673305",
        "label": "Estate Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52592001",
        "yellow_page_code": "29836",
        "kiosk": false
    },
    {
        "value": "673306",
        "label": "Insurance Funds ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52519003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "679201",
        "label": "Oil-Crude-Purchasing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52399908",
        "yellow_page_code": "57200",
        "kiosk": false
    },
    {
        "value": "679202",
        "label": "Oil Royalties",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53311003",
        "yellow_page_code": "56804",
        "kiosk": false
    },
    {
        "value": "679203",
        "label": "Royalties-Oil & Mineral",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53311005",
        "yellow_page_code": "72890",
        "kiosk": false
    },
    {
        "value": "679401",
        "label": "Franchising",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53311002",
        "yellow_page_code": "34304",
        "kiosk": false
    },
    {
        "value": "679402",
        "label": "Copywriters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53311001",
        "yellow_page_code": "21605",
        "kiosk": false
    },
    {
        "value": "679498",
        "label": "Patent Owners & Lessors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53311004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "679801",
        "label": "Real Estate Investment Trusts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52599001",
        "yellow_page_code": "70703",
        "kiosk": false
    },
    {
        "value": "679901",
        "label": "International Trade Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52399902",
        "yellow_page_code": "30575, 43603, 43566, 87730",
        "kiosk": false
    },
    {
        "value": "679902",
        "label": "Private Equity Companies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52391004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "679903",
        "label": "Equitable-Products",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52391005",
        "yellow_page_code": "29729",
        "kiosk": false
    },
    {
        "value": "679998",
        "label": "Venture Capital Companies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52391003",
        "yellow_page_code": "90809",
        "kiosk": false
    },
    {
        "value": "679999",
        "label": "Investors NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52399903",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "701101",
        "label": "Hotels & Motels",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72111002",
        "yellow_page_code": "41906, 53802, 71814, 48508, 48428, 92965, 93728, 41832",
        "kiosk": false
    },
    {
        "value": "701102",
        "label": "Inns",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72119102",
        "yellow_page_code": "43108",
        "kiosk": false
    },
    {
        "value": "701103",
        "label": "Hotel & Motel Management",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72111001",
        "yellow_page_code": "41904, 68830, 53800",
        "kiosk": false
    },
    {
        "value": "701104",
        "label": "Health Resorts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72119907",
        "yellow_page_code": "40508",
        "kiosk": false
    },
    {
        "value": "701105",
        "label": "Guest Ranches",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72119906",
        "yellow_page_code": "39305",
        "kiosk": false
    },
    {
        "value": "701106",
        "label": "Cottages & Cabins",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72119904",
        "yellow_page_code": "21931, 21908",
        "kiosk": false
    },
    {
        "value": "701107",
        "label": "Bed & Breakfast Accommodations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72119101",
        "yellow_page_code": "43668, 07305",
        "kiosk": false
    },
    {
        "value": "701108",
        "label": "Chalet & Cabin Rentals",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72119903",
        "yellow_page_code": "14615, 12132, 14616, 12107",
        "kiosk": false
    },
    {
        "value": "701109",
        "label": "Tourist Accommodations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72119911",
        "yellow_page_code": "87307",
        "kiosk": false
    },
    {
        "value": "701110",
        "label": "Skiing Centers & Resorts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72119910",
        "yellow_page_code": "78104, 22694, 80074, 78113, 78010",
        "kiosk": false
    },
    {
        "value": "701111",
        "label": "Resorts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72119909",
        "yellow_page_code": "70311, 71900, 45417",
        "kiosk": false
    },
    {
        "value": "701112",
        "label": "Hotels-Apartment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72111003",
        "yellow_page_code": "41917",
        "kiosk": false
    },
    {
        "value": "701113",
        "label": "Outing Facilities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72119908",
        "yellow_page_code": "58107",
        "kiosk": false
    },
    {
        "value": "701114",
        "label": "Fish Houses-Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72119905",
        "yellow_page_code": "32503",
        "kiosk": false
    },
    {
        "value": "701115",
        "label": "Cabin Rentals",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72119902",
        "yellow_page_code": "12120",
        "kiosk": false
    },
    {
        "value": "701116",
        "label": "Villas",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72119913",
        "yellow_page_code": "91133, 91132",
        "kiosk": false
    },
    {
        "value": "701117",
        "label": "Accommodations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72119901",
        "yellow_page_code": "90290, 00017, 92975, 41129",
        "kiosk": false
    },
    {
        "value": "701118",
        "label": "Honeymoons",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72119912",
        "yellow_page_code": "92934, 41421",
        "kiosk": false
    },
    {
        "value": "701119",
        "label": "Youth Hostels",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72111006",
        "yellow_page_code": "94820",
        "kiosk": false
    },
    {
        "value": "701120",
        "label": "Skiing-Back Country",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72111005",
        "yellow_page_code": "78112",
        "kiosk": false
    },
    {
        "value": "702101",
        "label": "Tourist Homes",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72131010",
        "yellow_page_code": "87402",
        "kiosk": false
    },
    {
        "value": "702102",
        "label": "Dormitories",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72131002",
        "yellow_page_code": "25706",
        "kiosk": false
    },
    {
        "value": "702103",
        "label": "Hostels",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72131006",
        "yellow_page_code": "41807",
        "kiosk": false
    },
    {
        "value": "702104",
        "label": "Guest Houses",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72131003",
        "yellow_page_code": "39303",
        "kiosk": false
    },
    {
        "value": "702105",
        "label": "Corporate Lodging",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72131001",
        "yellow_page_code": "21717, 02916, 41830, 22590",
        "kiosk": false
    },
    {
        "value": "702106",
        "label": "Student Housing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72131009",
        "yellow_page_code": "82121",
        "kiosk": false
    },
    {
        "value": "702107",
        "label": "Homestay",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72131004",
        "yellow_page_code": "41338",
        "kiosk": false
    },
    {
        "value": "702108",
        "label": "Hospital Visitors Accommodations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72131005",
        "yellow_page_code": "41717, 41727",
        "kiosk": false
    },
    {
        "value": "702109",
        "label": "Housing-Sharing Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72131007",
        "yellow_page_code": "41928",
        "kiosk": false
    },
    {
        "value": "702198",
        "label": "Rooming & Boarding Houses",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72131008",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "703201",
        "label": "Fishing Camps",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72121407",
        "yellow_page_code": "32607, 32613",
        "kiosk": false
    },
    {
        "value": "703202",
        "label": "Dude Ranches",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72121406",
        "yellow_page_code": "26804",
        "kiosk": false
    },
    {
        "value": "703203",
        "label": "Camps",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72121403",
        "yellow_page_code": "12606, 71810, 12511, 58098, 43669, 56551, 12603, 12515",
        "kiosk": false
    },
    {
        "value": "703204",
        "label": "Nudist Parks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72121409",
        "yellow_page_code": "55128, 55805, 12513",
        "kiosk": false
    },
    {
        "value": "703205",
        "label": "Fishing & Hunting Lodges",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72121408",
        "yellow_page_code": "32615, 42101",
        "kiosk": false
    },
    {
        "value": "703206",
        "label": "Camps-Information & Referral",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72121404",
        "yellow_page_code": "12509",
        "kiosk": false
    },
    {
        "value": "703207",
        "label": "Nudist Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72121402",
        "yellow_page_code": "55736, 55127",
        "kiosk": false
    },
    {
        "value": "703208",
        "label": "Adventure Games & Activities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72121401",
        "yellow_page_code": "00407",
        "kiosk": false
    },
    {
        "value": "703209",
        "label": "Bible Camps",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72121405",
        "yellow_page_code": "15727, 07911, 12510",
        "kiosk": false
    },
    {
        "value": "703210",
        "label": "Outpost Camps",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72121410",
        "yellow_page_code": "58114",
        "kiosk": false
    },
    {
        "value": "703211",
        "label": "Vacation Farms",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72121411",
        "yellow_page_code": "90210",
        "kiosk": false
    },
    {
        "value": "703301",
        "label": "Campgrounds",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72121101",
        "yellow_page_code": "12508, 03415, 12512, 13055",
        "kiosk": false
    },
    {
        "value": "703302",
        "label": "Recreational Vehicle Parks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72121102",
        "yellow_page_code": "88004, 71004",
        "kiosk": false
    },
    {
        "value": "704198",
        "label": "Organization Hotels On Membership Basis",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "72111004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "721101",
        "label": "Laundries",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81232001",
        "yellow_page_code": "46108, 46106",
        "kiosk": false
    },
    {
        "value": "721201",
        "label": "Cleaners",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81232002",
        "yellow_page_code": "93034, 26803, 18306, 26603, 92901, 93059, 30168, 18315",
        "kiosk": false
    },
    {
        "value": "721203",
        "label": "Pressing Machines & Services-Garment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81232014",
        "yellow_page_code": "68025",
        "kiosk": false
    },
    {
        "value": "721204",
        "label": "Cleaners-Blanket",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81232005",
        "yellow_page_code": "18304",
        "kiosk": false
    },
    {
        "value": "721205",
        "label": "Feather Renovators",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81232008",
        "yellow_page_code": "31102",
        "kiosk": false
    },
    {
        "value": "721206",
        "label": "Knit Goods-Blocking Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81232012",
        "yellow_page_code": "45006",
        "kiosk": false
    },
    {
        "value": "721207",
        "label": "Pressing Shops",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81232015",
        "yellow_page_code": "68108",
        "kiosk": false
    },
    {
        "value": "721208",
        "label": "Ironing Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81232010",
        "yellow_page_code": "43807",
        "kiosk": false
    },
    {
        "value": "721209",
        "label": "Suede Cloth (Cleaning)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81232016",
        "yellow_page_code": "82115",
        "kiosk": false
    },
    {
        "value": "721210",
        "label": "Laundries-Drop Off Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81232018",
        "yellow_page_code": "46221, 26490",
        "kiosk": false
    },
    {
        "value": "721211",
        "label": "Laundries Pickup & Delivery",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81232019",
        "yellow_page_code": "46197",
        "kiosk": false
    },
    {
        "value": "721212",
        "label": "Dry Cleaning & Laundry Lockers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81232020",
        "yellow_page_code": "",
        "kiosk": true
    },
    {
        "value": "721301",
        "label": "Towel Supply Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81233104",
        "yellow_page_code": "87406",
        "kiosk": false
    },
    {
        "value": "721302",
        "label": "Linen Supply Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81233103",
        "yellow_page_code": "47600",
        "kiosk": false
    },
    {
        "value": "721303",
        "label": "Coat & Apron Supply Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81233101",
        "yellow_page_code": "19004",
        "kiosk": false
    },
    {
        "value": "721304",
        "label": "Uniforms-Maintenance & Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81233105",
        "yellow_page_code": "90012",
        "kiosk": false
    },
    {
        "value": "721305",
        "label": "Linen Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81233106",
        "yellow_page_code": "47615, 92938",
        "kiosk": false
    },
    {
        "value": "721306",
        "label": "Uniform Supply Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81233107",
        "yellow_page_code": "90002, 58203, 51132",
        "kiosk": false
    },
    {
        "value": "721501",
        "label": "Laundries-Self Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81231001",
        "yellow_page_code": "91607, 91615, 18410, 18502, 46200, 46121, 46198",
        "kiosk": false
    },
    {
        "value": "721601",
        "label": "Dyers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81232013",
        "yellow_page_code": "27002, 94721",
        "kiosk": false
    },
    {
        "value": "721602",
        "label": "Drapery & Curtain Cleaners",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81232009",
        "yellow_page_code": "25908, 22909",
        "kiosk": false
    },
    {
        "value": "721603",
        "label": "Cleaners-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81232006",
        "yellow_page_code": "18307",
        "kiosk": false
    },
    {
        "value": "721604",
        "label": "Cleaners & Dyers-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81232003",
        "yellow_page_code": "18309",
        "kiosk": false
    },
    {
        "value": "721698",
        "label": "Drycleaning Plants-Except Rug",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81232011",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "721701",
        "label": "Upholstery Cleaners",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56174005",
        "yellow_page_code": "35606, 90103, 18314",
        "kiosk": false
    },
    {
        "value": "721702",
        "label": "Carpet & Rug Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56174002",
        "yellow_page_code": "13715, 13800",
        "kiosk": false
    },
    {
        "value": "721703",
        "label": "Carpet & Rug-Dyers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56174003",
        "yellow_page_code": "13704",
        "kiosk": false
    },
    {
        "value": "721704",
        "label": "Carpet & Rug Cleaners",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56174001",
        "yellow_page_code": "13514, 42217, 73116, 92135, 42213, 41360, 13614, 13603, 92121, 92200, 13602",
        "kiosk": false
    },
    {
        "value": "721705",
        "label": "Rugs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56174008",
        "yellow_page_code": "73202, 03312",
        "kiosk": false
    },
    {
        "value": "721706",
        "label": "Fabric Treatment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56174006",
        "yellow_page_code": "13510, 30499, 30412, 30411",
        "kiosk": false
    },
    {
        "value": "721707",
        "label": "Lamp Shades-Cleaning & Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56174007",
        "yellow_page_code": "45607",
        "kiosk": false
    },
    {
        "value": "721708",
        "label": "Carpet & Upholstery Protective Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56174004",
        "yellow_page_code": "13616, 13711",
        "kiosk": false
    },
    {
        "value": "721709",
        "label": "Steam Cleaning-Residential",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56174009",
        "yellow_page_code": "80810",
        "kiosk": false
    },
    {
        "value": "721710",
        "label": "Oriental Rug Cleaners",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56174010",
        "yellow_page_code": "57915",
        "kiosk": false
    },
    {
        "value": "721711",
        "label": "Carpet & Rug Binding Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56174011",
        "yellow_page_code": "13601",
        "kiosk": false
    },
    {
        "value": "721801",
        "label": "Dusters & Dusting Cloths",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81233204",
        "yellow_page_code": "27000",
        "kiosk": false
    },
    {
        "value": "721802",
        "label": "Aprons-Industrial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81233201",
        "yellow_page_code": "03100",
        "kiosk": false
    },
    {
        "value": "721803",
        "label": "Uniform Rental Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81233208",
        "yellow_page_code": "90000, 90023",
        "kiosk": false
    },
    {
        "value": "721804",
        "label": "Laundries-Industrial & Commercial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81233206",
        "yellow_page_code": "46107, 46207",
        "kiosk": false
    },
    {
        "value": "721805",
        "label": "Clean Room Facilities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81233202",
        "yellow_page_code": "18303, 18301",
        "kiosk": false
    },
    {
        "value": "721806",
        "label": "Gloves-Cleaning & Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81233205",
        "yellow_page_code": "37809",
        "kiosk": false
    },
    {
        "value": "721807",
        "label": "Mats & Matting-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81233207",
        "yellow_page_code": "50505",
        "kiosk": false
    },
    {
        "value": "721808",
        "label": "Coverall Supply Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81233203",
        "yellow_page_code": "22215",
        "kiosk": false
    },
    {
        "value": "721901",
        "label": "Fur-Storage",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81232004",
        "yellow_page_code": "35408",
        "kiosk": false
    },
    {
        "value": "721902",
        "label": "Fur-Remodeling & Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149017",
        "yellow_page_code": "35400",
        "kiosk": false
    },
    {
        "value": "721903",
        "label": "Fur Cleaning Dyeing & Storage-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149016",
        "yellow_page_code": "35106, 35121",
        "kiosk": false
    },
    {
        "value": "721904",
        "label": "Dressmakers Alterations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149014",
        "yellow_page_code": "26209, 26210, 75821",
        "kiosk": false
    },
    {
        "value": "721905",
        "label": "Diaper Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81233102",
        "yellow_page_code": "24509, 55123, 24508",
        "kiosk": false
    },
    {
        "value": "721906",
        "label": "Bridal Gown Preservation Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149006",
        "yellow_page_code": "92928, 92957, 10606",
        "kiosk": false
    },
    {
        "value": "721907",
        "label": "Tailors-Men's",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149050",
        "yellow_page_code": "83402",
        "kiosk": false
    },
    {
        "value": "721908",
        "label": "Tailors-Alteration & Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149049",
        "yellow_page_code": "83308",
        "kiosk": false
    },
    {
        "value": "721909",
        "label": "Pillows-Renovating",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149036",
        "yellow_page_code": "65302",
        "kiosk": false
    },
    {
        "value": "721910",
        "label": "Zippers-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149064",
        "yellow_page_code": "94906",
        "kiosk": false
    },
    {
        "value": "721911",
        "label": "Weaving & Mending",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149057",
        "yellow_page_code": "39865, 92804",
        "kiosk": false
    },
    {
        "value": "721912",
        "label": "Tailors-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149051",
        "yellow_page_code": "83401",
        "kiosk": false
    },
    {
        "value": "721913",
        "label": "Knit Goods-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149024",
        "yellow_page_code": "45012",
        "kiosk": false
    },
    {
        "value": "721914",
        "label": "Quilts Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149038",
        "yellow_page_code": "69412, 69501",
        "kiosk": false
    },
    {
        "value": "721915",
        "label": "Draperies & Curtains-Alterations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149013",
        "yellow_page_code": "25909",
        "kiosk": false
    },
    {
        "value": "721916",
        "label": "Sweaters-Trimming Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149048",
        "yellow_page_code": "82611",
        "kiosk": false
    },
    {
        "value": "721917",
        "label": "Garment Storage",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149018",
        "yellow_page_code": "36402",
        "kiosk": false
    },
    {
        "value": "721918",
        "label": "Shirts-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149045",
        "yellow_page_code": "76607",
        "kiosk": false
    },
    {
        "value": "721919",
        "label": "Sewing Shops-Custom",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149044",
        "yellow_page_code": "76003, 75915, 75818, 75913",
        "kiosk": false
    },
    {
        "value": "721920",
        "label": "Invisible Mending",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149021",
        "yellow_page_code": "43811",
        "kiosk": false
    },
    {
        "value": "721921",
        "label": "Reweaving & Mending",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149065",
        "yellow_page_code": "92803, 72100",
        "kiosk": false
    },
    {
        "value": "721922",
        "label": "Alterations-Clothing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149066",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "721999",
        "label": "Laundry & Garment Services NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81232007",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "722101",
        "label": "Photographers-Portrait",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54192110",
        "yellow_page_code": "93022, 60413, 60917, 43773, 61004, 60930",
        "kiosk": false
    },
    {
        "value": "722102",
        "label": "Photographers-Passport",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54192109",
        "yellow_page_code": "20933, 61001, 59612, 59615, 42609, 91180, 59606",
        "kiosk": false
    },
    {
        "value": "722103",
        "label": "Photographers-Legal",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54192107",
        "yellow_page_code": "60908, 60916",
        "kiosk": false
    },
    {
        "value": "722104",
        "label": "Photo Coloring",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54192103",
        "yellow_page_code": "60708",
        "kiosk": false
    },
    {
        "value": "722105",
        "label": "Identification Card Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54192102",
        "yellow_page_code": "42603",
        "kiosk": false
    },
    {
        "value": "722106",
        "label": "Photographers-Video",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54192113",
        "yellow_page_code": "60909",
        "kiosk": false
    },
    {
        "value": "722107",
        "label": "Photographic Restorations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54192114",
        "yellow_page_code": "61022",
        "kiosk": false
    },
    {
        "value": "722108",
        "label": "Photographers-Marine",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54192108",
        "yellow_page_code": "60907",
        "kiosk": false
    },
    {
        "value": "722109",
        "label": "Photographers-Copy & Restoration",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54192105",
        "yellow_page_code": "60911",
        "kiosk": false
    },
    {
        "value": "722110",
        "label": "Photographers-Fine Art",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54192106",
        "yellow_page_code": "61018, 60914, 03930",
        "kiosk": false
    },
    {
        "value": "722111",
        "label": "Photographers-Studio Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54192112",
        "yellow_page_code": "60915",
        "kiosk": false
    },
    {
        "value": "722112",
        "label": "Honor Rolls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54192101",
        "yellow_page_code": "41413",
        "kiosk": false
    },
    {
        "value": "722113",
        "label": "Wedding Photographers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54192116",
        "yellow_page_code": "92986, 92907",
        "kiosk": false
    },
    {
        "value": "722114",
        "label": "Wedding-Video Taping Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54192117",
        "yellow_page_code": "92909",
        "kiosk": false
    },
    {
        "value": "722115",
        "label": "Videography",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54192115",
        "yellow_page_code": "91098",
        "kiosk": false
    },
    {
        "value": "722116",
        "label": "Pet Photographers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54192104",
        "yellow_page_code": "60434, 60928",
        "kiosk": false
    },
    {
        "value": "722117",
        "label": "Photographers-Sports",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54192111",
        "yellow_page_code": "60918",
        "kiosk": false
    },
    {
        "value": "722118",
        "label": "Photographers-Events",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54192118",
        "yellow_page_code": "60927, 60910",
        "kiosk": false
    },
    {
        "value": "722119",
        "label": "Photographers-Specialty",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54192119",
        "yellow_page_code": "60920",
        "kiosk": false
    },
    {
        "value": "722120",
        "label": "Photographers-Digital Imaging",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54192120",
        "yellow_page_code": "24725, 61116, 60720",
        "kiosk": false
    },
    {
        "value": "722121",
        "label": "Photography",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54192121",
        "yellow_page_code": "60919, 60931, 61120, 43667, 60796",
        "kiosk": false
    },
    {
        "value": "722122",
        "label": "Photographers-Stock",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54192122",
        "yellow_page_code": "60921",
        "kiosk": false
    },
    {
        "value": "722123",
        "label": "Photographers-Technical",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54192123",
        "yellow_page_code": "61019",
        "kiosk": false
    },
    {
        "value": "722124",
        "label": "Photography-Real Estate",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54192125",
        "yellow_page_code": "61118",
        "kiosk": false
    },
    {
        "value": "722125",
        "label": "Photographers-Art",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54192124",
        "yellow_page_code": "60922",
        "kiosk": false
    },
    {
        "value": "722126",
        "label": "Photographers-Outdoor",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151023",
        "yellow_page_code": "60925",
        "kiosk": false
    },
    {
        "value": "722127",
        "label": "Photography-Model & Talent",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54192126",
        "yellow_page_code": "61122",
        "kiosk": false
    },
    {
        "value": "722128",
        "label": "Photographic Studios",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54192127",
        "yellow_page_code": "60716",
        "kiosk": false
    },
    {
        "value": "722129",
        "label": "Photographers Reunions & Groups",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54192128",
        "yellow_page_code": "60929",
        "kiosk": false
    },
    {
        "value": "722130",
        "label": "Photo Booths",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54192129",
        "yellow_page_code": "60795, 60798",
        "kiosk": false
    },
    {
        "value": "723101",
        "label": "Skin Treatments",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81211215",
        "yellow_page_code": "30505, 30498, 78204, 07215, 78116, 30369",
        "kiosk": false
    },
    {
        "value": "723102",
        "label": "Manicuring",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81211302",
        "yellow_page_code": "93065, 55023, 92969, 55016, 60112, 49737, 55010, 60106, 55008, 49804, 31808",
        "kiosk": false
    },
    {
        "value": "723103",
        "label": "Children's Hair Salon",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81211212",
        "yellow_page_code": "15141",
        "kiosk": false
    },
    {
        "value": "723104",
        "label": "Cosmetologists",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81211217",
        "yellow_page_code": "21803, 21819, 21904",
        "kiosk": false
    },
    {
        "value": "723105",
        "label": "Beauty Schools",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61151102",
        "yellow_page_code": "74467, 28005, 07302, 21825",
        "kiosk": false
    },
    {
        "value": "723106",
        "label": "Beauty Salons",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81211202",
        "yellow_page_code": "07216, 90018, 43662, 39631, 93075, 07204, 49712, 39708, 07205, 39616, 39624, 73510, 30510, 39629, 10110, 53219, 39611, 92924, 39625, 21894, 92985, 77917",
        "kiosk": false
    },
    {
        "value": "723107",
        "label": "Salt Therapy",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81211213",
        "yellow_page_code": "73617",
        "kiosk": false
    },
    {
        "value": "723108",
        "label": "Make-Up Studios",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81211216",
        "yellow_page_code": "93000, 49721, 21891, 21893, 92968, 49710, 49715, 49709, 49714",
        "kiosk": false
    },
    {
        "value": "723109",
        "label": "Hair Tinting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81211207",
        "yellow_page_code": "39800",
        "kiosk": false
    },
    {
        "value": "723110",
        "label": "Hair Treatment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81211208",
        "yellow_page_code": "39801",
        "kiosk": false
    },
    {
        "value": "723111",
        "label": "Schools-Hairdressing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61151104",
        "yellow_page_code": "39710, 74451",
        "kiosk": false
    },
    {
        "value": "723112",
        "label": "Health & Beauty Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81211209",
        "yellow_page_code": "07208, 07214, 40411",
        "kiosk": false
    },
    {
        "value": "723113",
        "label": "Estheticians",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81211204",
        "yellow_page_code": "29811, 21828",
        "kiosk": false
    },
    {
        "value": "723114",
        "label": "Eyelashes-Artificial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81211206",
        "yellow_page_code": "30407, 30580, 03706, 31814",
        "kiosk": false
    },
    {
        "value": "723115",
        "label": "Facial Cosmetology",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81211205",
        "yellow_page_code": "30501, 09490",
        "kiosk": false
    },
    {
        "value": "723116",
        "label": "Body Wrap Salons",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81211203",
        "yellow_page_code": "08901",
        "kiosk": false
    },
    {
        "value": "723118",
        "label": "Aromatherapy",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81211201",
        "yellow_page_code": "34330, 03350, 03349",
        "kiosk": false
    },
    {
        "value": "723119",
        "label": "Spas-Beauty & Day",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81211210",
        "yellow_page_code": "79615, 07218, 15151, 93062, 23516, 71627, 23599",
        "kiosk": false
    },
    {
        "value": "723120",
        "label": "Floating",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81211211",
        "yellow_page_code": "33026",
        "kiosk": false
    },
    {
        "value": "723122",
        "label": "Hair Cutting/Styling Wigs/Hair Pieces",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61151107",
        "yellow_page_code": "39623",
        "kiosk": false
    },
    {
        "value": "723123",
        "label": "Eyebrow Threading",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81211214",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "724101",
        "label": "Barbers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81211101",
        "yellow_page_code": "06508, 06513",
        "kiosk": false
    },
    {
        "value": "724102",
        "label": "Barber Schools",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61151101",
        "yellow_page_code": "06506, 74443",
        "kiosk": false
    },
    {
        "value": "724103",
        "label": "Men's Hairstyling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81211102",
        "yellow_page_code": "51211",
        "kiosk": false
    },
    {
        "value": "725101",
        "label": "Hats-Cleaners & Renovators",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81143002",
        "yellow_page_code": "40303, 40300",
        "kiosk": false
    },
    {
        "value": "725102",
        "label": "Shoe & Boot Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81143005",
        "yellow_page_code": "76808, 02167, 76715",
        "kiosk": false
    },
    {
        "value": "725103",
        "label": "Shoe Shining",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81143008",
        "yellow_page_code": "76902",
        "kiosk": false
    },
    {
        "value": "725104",
        "label": "Shoe Dyers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81143007",
        "yellow_page_code": "76702",
        "kiosk": false
    },
    {
        "value": "725105",
        "label": "Shoe Shining Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81143009",
        "yellow_page_code": "76903",
        "kiosk": false
    },
    {
        "value": "725106",
        "label": "Shoes-Orthopedic-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81143010",
        "yellow_page_code": "76805",
        "kiosk": false
    },
    {
        "value": "725107",
        "label": "Boots-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81143001",
        "yellow_page_code": "09407",
        "kiosk": false
    },
    {
        "value": "725108",
        "label": "Shoe Care & Shoe Coloring Products",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81143006",
        "yellow_page_code": "76714",
        "kiosk": false
    },
    {
        "value": "726102",
        "label": "Crematories",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81222007",
        "yellow_page_code": "22510, 22508, 35026, 22514",
        "kiosk": false
    },
    {
        "value": "726103",
        "label": "Funeral Directors",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "81221002",
        "yellow_page_code": "35000, 11506, 53625, 53620, 53605, 35007, 35002",
        "kiosk": false
    },
    {
        "value": "726104",
        "label": "Embalmers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81221001",
        "yellow_page_code": "28400",
        "kiosk": false
    },
    {
        "value": "726105",
        "label": "Funeral Plans (Pre-Arranged)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81221005",
        "yellow_page_code": "07812, 39010, 35028, 35014, 35013, 51239, 35030, 35006, 35020",
        "kiosk": false
    },
    {
        "value": "726106",
        "label": "Funeral Referral & Consulting Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81221006",
        "yellow_page_code": "35011, 35025",
        "kiosk": false
    },
    {
        "value": "726107",
        "label": "Funeral Information Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81221004",
        "yellow_page_code": "34999, 35005",
        "kiosk": false
    },
    {
        "value": "726108",
        "label": "Cremation Service (Pre-Arranged)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81222006",
        "yellow_page_code": "22506",
        "kiosk": false
    },
    {
        "value": "726109",
        "label": "Funeral Directors-Carrier Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81221003",
        "yellow_page_code": "35001",
        "kiosk": false
    },
    {
        "value": "726110",
        "label": "Funerals-Mausoleums",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81222010",
        "yellow_page_code": "35027",
        "kiosk": false
    },
    {
        "value": "729101",
        "label": "Tax Return Preparation & Filing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54121301",
        "yellow_page_code": "43745, 84129, 48341, 84124, 84125, 21051, 84106, 84104, 42900",
        "kiosk": false
    },
    {
        "value": "729102",
        "label": "Tax Return Transmission-Electronic",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54121302",
        "yellow_page_code": "84115, 84138",
        "kiosk": false
    },
    {
        "value": "729103",
        "label": "Sales Tax Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54121303",
        "yellow_page_code": "73511",
        "kiosk": false
    },
    {
        "value": "729901",
        "label": "Health & Fitness Program Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81219102",
        "yellow_page_code": "21149, 32710, 60218, 40430, 40403",
        "kiosk": false
    },
    {
        "value": "729902",
        "label": "Feng Shui",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299023",
        "yellow_page_code": "31319",
        "kiosk": false
    },
    {
        "value": "729903",
        "label": "Escort Service-Personal",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299015",
        "yellow_page_code": "29808",
        "kiosk": false
    },
    {
        "value": "729904",
        "label": "House Sitter Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299055",
        "yellow_page_code": "41209, 41913",
        "kiosk": false
    },
    {
        "value": "729905",
        "label": "Fashion Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299016",
        "yellow_page_code": "82130, 31030, 30909, 91530, 18728",
        "kiosk": false
    },
    {
        "value": "729906",
        "label": "Exercise & Physical Fitness Programs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81219101",
        "yellow_page_code": "30431, 09415, 30009, 40526, 40448, 30019, 69530, 93037, 30339, 79919",
        "kiosk": false
    },
    {
        "value": "729907",
        "label": "Fur-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228103",
        "yellow_page_code": "35402",
        "kiosk": false
    },
    {
        "value": "729908",
        "label": "Formal Wear-Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228102",
        "yellow_page_code": "38510, 26114, 68512, 92939, 92937, 92918, 24847, 89605, 34200, 43736",
        "kiosk": false
    },
    {
        "value": "729909",
        "label": "Genealogists",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299018",
        "yellow_page_code": "37003, 37016",
        "kiosk": false
    },
    {
        "value": "729910",
        "label": "Hair Removing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81219904",
        "yellow_page_code": "93038, 46021, 39706, 92701, 39638, 92731",
        "kiosk": false
    },
    {
        "value": "729911",
        "label": "Gift Wrapping Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299019",
        "yellow_page_code": "37307",
        "kiosk": false
    },
    {
        "value": "729912",
        "label": "Hair Weaving",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81219906",
        "yellow_page_code": "39622, 39802, 39617",
        "kiosk": false
    },
    {
        "value": "729913",
        "label": "Hair Replacement",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81219905",
        "yellow_page_code": "39627, 39615, 39614, 39707, 46037, 39613, 39628",
        "kiosk": false
    },
    {
        "value": "729914",
        "label": "Hot Springs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81219912",
        "yellow_page_code": "41900",
        "kiosk": false
    },
    {
        "value": "729915",
        "label": "Lockers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299035",
        "yellow_page_code": "48406, 48410",
        "kiosk": false
    },
    {
        "value": "729916",
        "label": "Singles Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299044",
        "yellow_page_code": "77809",
        "kiosk": false
    },
    {
        "value": "729917",
        "label": "Massage Therapists",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "81219908",
        "yellow_page_code": "50314, 50403, 40454, 40391, 08833, 50317, 85689, 50415, 93040, 50307, 81996, 50318",
        "kiosk": false
    },
    {
        "value": "729918",
        "label": "Mail Receiving Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299048",
        "yellow_page_code": "49702, 49622",
        "kiosk": false
    },
    {
        "value": "729919",
        "label": "Baby Sitters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62441001",
        "yellow_page_code": "05603",
        "kiosk": false
    },
    {
        "value": "729920",
        "label": "Shopping Service-Business",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299028",
        "yellow_page_code": "77112, 77115",
        "kiosk": false
    },
    {
        "value": "729921",
        "label": "Color Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81219913",
        "yellow_page_code": "19802, 19809",
        "kiosk": false
    },
    {
        "value": "729922",
        "label": "Buyers Information Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299006",
        "yellow_page_code": "12102",
        "kiosk": false
    },
    {
        "value": "729923",
        "label": "Consumer Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299010",
        "yellow_page_code": "21167, 21010, 21100, 04016",
        "kiosk": false
    },
    {
        "value": "729924",
        "label": "Credit & Debt Counseling Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54199026",
        "yellow_page_code": "23655, 23629, 23627, 23654, 23623, 22435, 22405, 22404",
        "kiosk": false
    },
    {
        "value": "729925",
        "label": "Costumes-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228101",
        "yellow_page_code": "22003, 21918",
        "kiosk": false
    },
    {
        "value": "729926",
        "label": "Dating Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299012",
        "yellow_page_code": "50510, 57516, 50218, 23506, 84317, 23515, 43639",
        "kiosk": false
    },
    {
        "value": "729927",
        "label": "Credit Rating Correction Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299011",
        "yellow_page_code": "22501",
        "kiosk": false
    },
    {
        "value": "729928",
        "label": "Ear Piercing Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299014",
        "yellow_page_code": "27104",
        "kiosk": false
    },
    {
        "value": "729929",
        "label": "Debt Adjusters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299013",
        "yellow_page_code": "23604, 22438",
        "kiosk": false
    },
    {
        "value": "729930",
        "label": "Electrologists",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81219902",
        "yellow_page_code": "28003",
        "kiosk": false
    },
    {
        "value": "729931",
        "label": "Electrolysis",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81219903",
        "yellow_page_code": "42207, 39705, 28002",
        "kiosk": false
    },
    {
        "value": "729932",
        "label": "Wedding Consultants Planning/Arranging",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299033",
        "yellow_page_code": "10613, 92906, 92913, 10612, 92961, 93013, 24327, 10614, 93001",
        "kiosk": false
    },
    {
        "value": "729933",
        "label": "Wake-Up Call Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81219911",
        "yellow_page_code": "91306",
        "kiosk": false
    },
    {
        "value": "729934",
        "label": "Weight Control Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81219103",
        "yellow_page_code": "24714, 93010, 24745, 24805, 93048, 71106, 93008, 56111",
        "kiosk": false
    },
    {
        "value": "729935",
        "label": "Wedding Chapels",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299032",
        "yellow_page_code": "14517, 92921, 14516, 92904, 50209, 92980, 79823",
        "kiosk": false
    },
    {
        "value": "729936",
        "label": "Palmists",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299037",
        "yellow_page_code": "58723, 58708",
        "kiosk": false
    },
    {
        "value": "729937",
        "label": "Welcoming Service For Newcomers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299034",
        "yellow_page_code": "93009",
        "kiosk": false
    },
    {
        "value": "729938",
        "label": "Pet Exercising Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299047",
        "yellow_page_code": "25410, 60404, 60433, 60543",
        "kiosk": false
    },
    {
        "value": "729939",
        "label": "Personal Service Bureaus",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299025",
        "yellow_page_code": "21152, 60220, 60315, 60312, 60308",
        "kiosk": false
    },
    {
        "value": "729940",
        "label": "Braille Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54199013",
        "yellow_page_code": "10109",
        "kiosk": false
    },
    {
        "value": "729941",
        "label": "Shopping Service-Personal",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299029",
        "yellow_page_code": "60318, 39115, 77108, 41247",
        "kiosk": false
    },
    {
        "value": "729942",
        "label": "Scalp Treatment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81219909",
        "yellow_page_code": "74308, 74320, 74319",
        "kiosk": false
    },
    {
        "value": "729943",
        "label": "Tattooing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81219923",
        "yellow_page_code": "08830, 08835, 84006",
        "kiosk": false
    },
    {
        "value": "729944",
        "label": "Tanning Salons",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81219910",
        "yellow_page_code": "82301, 83702, 83716, 92981, 10910, 79321, 83614",
        "kiosk": false
    },
    {
        "value": "729945",
        "label": "Aqua Massage",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299053",
        "yellow_page_code": "03124",
        "kiosk": false
    },
    {
        "value": "729946",
        "label": "Devotion For The Day",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51929001",
        "yellow_page_code": "24402",
        "kiosk": false
    },
    {
        "value": "729947",
        "label": "Image Consultants-Personal",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81219914",
        "yellow_page_code": "42627, 42607, 42625",
        "kiosk": false
    },
    {
        "value": "729948",
        "label": "Buyers Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299007",
        "yellow_page_code": "12100, 11937, 11935",
        "kiosk": false
    },
    {
        "value": "729949",
        "label": "Errands & Miscellaneous Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299050",
        "yellow_page_code": "29803",
        "kiosk": false
    },
    {
        "value": "729950",
        "label": "Jazzercise",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81219104",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "729951",
        "label": "Coats Of Arms",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299008",
        "yellow_page_code": "40941, 19005, 41028",
        "kiosk": false
    },
    {
        "value": "729952",
        "label": "Greeting Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299051",
        "yellow_page_code": "39006",
        "kiosk": false
    },
    {
        "value": "729953",
        "label": "Bankruptcy Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54119906",
        "yellow_page_code": "06394, 06311, 06398, 06399, 53632, 43510",
        "kiosk": false
    },
    {
        "value": "729954",
        "label": "Vehicle & Drivers Licensing Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56199022",
        "yellow_page_code": "05214, 83208, 54212, 05020, 83210, 26345, 47129, 86615, 53713, 26411, 25299, 47120, 54217, 65211, 47114, 26420, 05014",
        "kiosk": false
    },
    {
        "value": "729955",
        "label": "Relaxation Therapy",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81219921",
        "yellow_page_code": "71529",
        "kiosk": false
    },
    {
        "value": "729956",
        "label": "Raffia",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "31499405",
        "yellow_page_code": "70105",
        "kiosk": false
    },
    {
        "value": "729957",
        "label": "Surrogacy",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54199017",
        "yellow_page_code": "82422, 82421",
        "kiosk": false
    },
    {
        "value": "729958",
        "label": "Credit Restoration",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54199004",
        "yellow_page_code": "22436",
        "kiosk": false
    },
    {
        "value": "729959",
        "label": "Clothing-Coordinating Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299052",
        "yellow_page_code": "18716",
        "kiosk": false
    },
    {
        "value": "729960",
        "label": "Hostess Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299045",
        "yellow_page_code": "41714",
        "kiosk": false
    },
    {
        "value": "729961",
        "label": "Funeral Escort Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299017",
        "yellow_page_code": "35003",
        "kiosk": false
    },
    {
        "value": "729962",
        "label": "Shirts-Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228104",
        "yellow_page_code": "76614",
        "kiosk": false
    },
    {
        "value": "729963",
        "label": "Massage",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81219915",
        "yellow_page_code": "50309, 50308, 08836, 50320, 55015, 50310, 72519, 50316",
        "kiosk": false
    },
    {
        "value": "729964",
        "label": "Valet Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81293006",
        "yellow_page_code": "13048, 90504, 93029, 13017, 13049, 90505",
        "kiosk": false
    },
    {
        "value": "729965",
        "label": "Concierge Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299038",
        "yellow_page_code": "47126, 20409",
        "kiosk": false
    },
    {
        "value": "729966",
        "label": "Consumer Advisory Counseling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54199005",
        "yellow_page_code": "20926",
        "kiosk": false
    },
    {
        "value": "729967",
        "label": "Recreational Goods Rental ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228409",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "729970",
        "label": "Birth Certificate Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299003",
        "yellow_page_code": "08315, 08311, 71304",
        "kiosk": false
    },
    {
        "value": "729971",
        "label": "Henna Tattoos",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81219922",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "729972",
        "label": "Blood Pressure Checking Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299004",
        "yellow_page_code": "08436",
        "kiosk": false
    },
    {
        "value": "729973",
        "label": "Permanent Make-Up",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81219917",
        "yellow_page_code": "30518, 21892, 21810, 60255, 49717",
        "kiosk": false
    },
    {
        "value": "729974",
        "label": "College & University Placement Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299009",
        "yellow_page_code": "19701",
        "kiosk": false
    },
    {
        "value": "729975",
        "label": "Personal & Household Goods Repair & Maintenance ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149067",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "729976",
        "label": "Marriage Brokers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299022",
        "yellow_page_code": "50208",
        "kiosk": false
    },
    {
        "value": "729977",
        "label": "Philosophers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299027",
        "yellow_page_code": "60712, 43802",
        "kiosk": false
    },
    {
        "value": "729979",
        "label": "Body Piercing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299005",
        "yellow_page_code": "65115, 08838, 08832",
        "kiosk": false
    },
    {
        "value": "729980",
        "label": "Jewelry Engravers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299021",
        "yellow_page_code": "44400, 29405",
        "kiosk": false
    },
    {
        "value": "729981",
        "label": "Personal Care Services NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81219924",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "729982",
        "label": "Mail Boxes-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44414020",
        "yellow_page_code": "49615",
        "kiosk": false
    },
    {
        "value": "729983",
        "label": "Jewelry Appraisal",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54199010",
        "yellow_page_code": "44209",
        "kiosk": false
    },
    {
        "value": "729984",
        "label": "Bill Paying Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299002",
        "yellow_page_code": "08100, 26818",
        "kiosk": false
    },
    {
        "value": "729986",
        "label": "Restaurant Information & Referral Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299026",
        "yellow_page_code": "71914",
        "kiosk": false
    },
    {
        "value": "729987",
        "label": "Missing Persons Bureaus",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299024",
        "yellow_page_code": "53000",
        "kiosk": false
    },
    {
        "value": "729988",
        "label": "Electro Body Sculpting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81219106",
        "yellow_page_code": "27821",
        "kiosk": false
    },
    {
        "value": "729989",
        "label": "Party Planning Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299057",
        "yellow_page_code": "08309",
        "kiosk": false
    },
    {
        "value": "729990",
        "label": "Trager Bodywork",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81219920",
        "yellow_page_code": "87830",
        "kiosk": false
    },
    {
        "value": "729991",
        "label": "Yacht Documentation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54199016",
        "yellow_page_code": "08618, 94609",
        "kiosk": false
    },
    {
        "value": "729992",
        "label": "Bagging Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299001",
        "yellow_page_code": "05713",
        "kiosk": false
    },
    {
        "value": "729993",
        "label": "Parties",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299042",
        "yellow_page_code": "40932, 05621, 59507, 80422, 93067, 92982",
        "kiosk": false
    },
    {
        "value": "729994",
        "label": "Quinceaneras",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299043",
        "yellow_page_code": "69507",
        "kiosk": false
    },
    {
        "value": "729995",
        "label": "Yoga",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81219105",
        "yellow_page_code": "94814",
        "kiosk": false
    },
    {
        "value": "729996",
        "label": "Clothing & Accessories Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228105",
        "yellow_page_code": "18749",
        "kiosk": false
    },
    {
        "value": "729997",
        "label": "Consumer Group Buying",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299039",
        "yellow_page_code": "21027, 21022",
        "kiosk": false
    },
    {
        "value": "729998",
        "label": "Introduction Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299020",
        "yellow_page_code": "43612",
        "kiosk": false
    },
    {
        "value": "729999",
        "label": "Miscellaneous Personal Services NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299046",
        "yellow_page_code": "92947, 92987, 93026, 25512, 24330, 25436, 50214, 22432, 50216, 41219, 59526, 06938, 44839, 92914, 50219, 13939",
        "kiosk": false
    },
    {
        "value": "731101",
        "label": "Advertising-Agencies & Counselors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54181001",
        "yellow_page_code": "33019, 00406, 00411, 00619, 00620, 00510, 00710, 10808, 00516, 10814, 00521, 00525, 00527, 30050, 91062, 04328, 53031, 85425, 00528, 85237",
        "kiosk": false
    },
    {
        "value": "731102",
        "label": "Publication Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54181010",
        "yellow_page_code": "68904",
        "kiosk": false
    },
    {
        "value": "731103",
        "label": "Advertising-Directory & Guide",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54181003",
        "yellow_page_code": "00508, 00421, 43210, 24848, 24853",
        "kiosk": false
    },
    {
        "value": "731104",
        "label": "Advertising-Checking Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54181002",
        "yellow_page_code": "00505",
        "kiosk": false
    },
    {
        "value": "731105",
        "label": "Advertising-Motion Picture",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54181004",
        "yellow_page_code": "00602",
        "kiosk": false
    },
    {
        "value": "731106",
        "label": "Advertising-Newspaper",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54181005",
        "yellow_page_code": "00514, 92888, 00604",
        "kiosk": false
    },
    {
        "value": "731107",
        "label": "Advertising-Shoppers' Guides",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54181008",
        "yellow_page_code": "00702",
        "kiosk": false
    },
    {
        "value": "731108",
        "label": "Advertising-Periodical",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54181006",
        "yellow_page_code": "30164, 00608",
        "kiosk": false
    },
    {
        "value": "731109",
        "label": "Advertising-Personnel Recruitment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54181007",
        "yellow_page_code": "00503",
        "kiosk": false
    },
    {
        "value": "731110",
        "label": "Advertising-Time & Temp Announcements",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54181012",
        "yellow_page_code": "00618",
        "kiosk": false
    },
    {
        "value": "731111",
        "label": "Telephone Directory Advertising",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54181011",
        "yellow_page_code": "84415",
        "kiosk": false
    },
    {
        "value": "731112",
        "label": "Advertising-Theatrical",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54181013",
        "yellow_page_code": "00621",
        "kiosk": false
    },
    {
        "value": "731113",
        "label": "Digital Media & Content Creation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51621004",
        "yellow_page_code": "50915, 24730",
        "kiosk": false
    },
    {
        "value": "731114",
        "label": "Golf Advertising",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54181009",
        "yellow_page_code": "38218",
        "kiosk": false
    },
    {
        "value": "731115",
        "label": "Advertising Marketing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54181015",
        "yellow_page_code": "00526",
        "kiosk": false
    },
    {
        "value": "731201",
        "label": "Advertising-Outdoor",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54185001",
        "yellow_page_code": "00606, 08045, 58116, 00625, 00520",
        "kiosk": false
    },
    {
        "value": "731202",
        "label": "Signs-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54185007",
        "yellow_page_code": "77503",
        "kiosk": false
    },
    {
        "value": "731204",
        "label": "Yard Signs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54185008",
        "yellow_page_code": "77441, 94710",
        "kiosk": false
    },
    {
        "value": "731205",
        "label": "Lights-Advertising",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54185010",
        "yellow_page_code": "47420",
        "kiosk": false
    },
    {
        "value": "731301",
        "label": "Radio Station Representatives",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54184009",
        "yellow_page_code": "69904",
        "kiosk": false
    },
    {
        "value": "731302",
        "label": "Publishers-Representatives",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54184008",
        "yellow_page_code": "68908",
        "kiosk": false
    },
    {
        "value": "731303",
        "label": "Advertising-Radio",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54184002",
        "yellow_page_code": "00700",
        "kiosk": false
    },
    {
        "value": "731304",
        "label": "Media Brokers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54184006",
        "yellow_page_code": "50905",
        "kiosk": false
    },
    {
        "value": "731305",
        "label": "Advertising-Television",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54184004",
        "yellow_page_code": "00414, 00415, 84603, 00704",
        "kiosk": false
    },
    {
        "value": "731306",
        "label": "Advertising-Magazine",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54184001",
        "yellow_page_code": "73512, 00603",
        "kiosk": false
    },
    {
        "value": "731307",
        "label": "Advertising-Soundtrack & Jingles",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54184003",
        "yellow_page_code": "00607",
        "kiosk": false
    },
    {
        "value": "731308",
        "label": "Advertising-Video Production Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54184005",
        "yellow_page_code": "00615",
        "kiosk": false
    },
    {
        "value": "731309",
        "label": "Newspaper Representatives",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54184007",
        "yellow_page_code": "55504",
        "kiosk": false
    },
    {
        "value": "731901",
        "label": "Display Designers & Producers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54185004",
        "yellow_page_code": "25030, 00600, 25100, 25007, 25034, 21363",
        "kiosk": false
    },
    {
        "value": "731902",
        "label": "Advertising-Transit & Transportation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54189009",
        "yellow_page_code": "00706, 30296",
        "kiosk": false
    },
    {
        "value": "731903",
        "label": "Advertising-Aerial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48121901",
        "yellow_page_code": "78211, 00504, 00522, 43026",
        "kiosk": false
    },
    {
        "value": "731904",
        "label": "Display Dsgnrs/Producers-Expo Convention",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54185005",
        "yellow_page_code": "25101",
        "kiosk": false
    },
    {
        "value": "731905",
        "label": "Demonstration Service-Merchandise",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54189010",
        "yellow_page_code": "51317, 23808",
        "kiosk": false
    },
    {
        "value": "731906",
        "label": "Display Installation Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54185006",
        "yellow_page_code": "25104",
        "kiosk": false
    },
    {
        "value": "731907",
        "label": "Distributing Service-Circular & Sample",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54187004",
        "yellow_page_code": "17980, 73609, 10813, 25202, 17981",
        "kiosk": false
    },
    {
        "value": "731908",
        "label": "Distribution Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54187005",
        "yellow_page_code": "25201, 30413, 25109, 25111",
        "kiosk": false
    },
    {
        "value": "731909",
        "label": "Sample Cards & Books",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54187006",
        "yellow_page_code": "73608",
        "kiosk": false
    },
    {
        "value": "731910",
        "label": "Publicity Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54189012",
        "yellow_page_code": "67944, 68906",
        "kiosk": false
    },
    {
        "value": "731911",
        "label": "Advertising-Indoor",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54189005",
        "yellow_page_code": "00601",
        "kiosk": false
    },
    {
        "value": "731912",
        "label": "Folding-Circular Pamphlet Etc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54187007",
        "yellow_page_code": "33613",
        "kiosk": false
    },
    {
        "value": "731913",
        "label": "Media Buying Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54183002",
        "yellow_page_code": "50914",
        "kiosk": false
    },
    {
        "value": "731914",
        "label": "Advertising-Coupons",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54187001",
        "yellow_page_code": "00501, 22121, 22126, 22160",
        "kiosk": false
    },
    {
        "value": "731915",
        "label": "Advertising-Searchlights",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54189007",
        "yellow_page_code": "00616",
        "kiosk": false
    },
    {
        "value": "731916",
        "label": "Advertising-Distributors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54189003",
        "yellow_page_code": "00413",
        "kiosk": false
    },
    {
        "value": "731917",
        "label": "Advertising-Computer",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54189002",
        "yellow_page_code": "00610, 43622, 92889, 00500, 00623",
        "kiosk": false
    },
    {
        "value": "731918",
        "label": "Advertising-Jewelry Awards & Incentives",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54187002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "731919",
        "label": "Advertising-Promotional",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54189006",
        "yellow_page_code": "00609",
        "kiosk": false
    },
    {
        "value": "731920",
        "label": "Advertising-Traveling Signboards",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48121902",
        "yellow_page_code": "00705",
        "kiosk": false
    },
    {
        "value": "731921",
        "label": "Balloon Advertising",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48121905",
        "yellow_page_code": "06134, 06139",
        "kiosk": false
    },
    {
        "value": "731922",
        "label": "Discount Cards Coupons & Stamp Companies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54187003",
        "yellow_page_code": "24836",
        "kiosk": false
    },
    {
        "value": "731923",
        "label": "Shopper's Information Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54189013",
        "yellow_page_code": "77009",
        "kiosk": false
    },
    {
        "value": "731924",
        "label": "Media Monitoring",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54183003",
        "yellow_page_code": "50911",
        "kiosk": false
    },
    {
        "value": "731925",
        "label": "Interactive Media",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54183001",
        "yellow_page_code": "43516",
        "kiosk": false
    },
    {
        "value": "731926",
        "label": "Advertising-Fax",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54189004",
        "yellow_page_code": "00519",
        "kiosk": false
    },
    {
        "value": "731927",
        "label": "Point-Of-Purchase Advertising",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54185009",
        "yellow_page_code": "66918, 66921",
        "kiosk": false
    },
    {
        "value": "731928",
        "label": "Front Cover Spot Advertising",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54185002",
        "yellow_page_code": "34580",
        "kiosk": false
    },
    {
        "value": "731999",
        "label": "Advertising NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54189001",
        "yellow_page_code": "00703, 00518",
        "kiosk": false
    },
    {
        "value": "732201",
        "label": "Collection Agencies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56144002",
        "yellow_page_code": "19707, 19700, 44506",
        "kiosk": false
    },
    {
        "value": "732202",
        "label": "Collection Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56144003",
        "yellow_page_code": "19702",
        "kiosk": false
    },
    {
        "value": "732203",
        "label": "Tracing Bureaus",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56144005",
        "yellow_page_code": "87611",
        "kiosk": false
    },
    {
        "value": "732204",
        "label": "Landlords Service Bureaus",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56144004",
        "yellow_page_code": "45801",
        "kiosk": false
    },
    {
        "value": "732205",
        "label": "Check Collection Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56144001",
        "yellow_page_code": "14803",
        "kiosk": false
    },
    {
        "value": "732301",
        "label": "Credit Reporting Agencies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56145003",
        "yellow_page_code": "29595, 22410, 22437, 22502",
        "kiosk": false
    },
    {
        "value": "732302",
        "label": "Skip Tracing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56145005",
        "yellow_page_code": "78206",
        "kiosk": false
    },
    {
        "value": "732303",
        "label": "Credit Investigators",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56145002",
        "yellow_page_code": "22503",
        "kiosk": false
    },
    {
        "value": "732304",
        "label": "Collateral Certification & Supervision",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56145001",
        "yellow_page_code": "19611",
        "kiosk": false
    },
    {
        "value": "732305",
        "label": "Tenant Reference Checking Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56145006",
        "yellow_page_code": "85011, 85028",
        "kiosk": false
    },
    {
        "value": "732306",
        "label": "Mercantile Agencies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56145004",
        "yellow_page_code": "51311",
        "kiosk": false
    },
    {
        "value": "733101",
        "label": "Mailing & Shipping Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54186007",
        "yellow_page_code": "49605, 34413, 30495, 49708, 11428, 00301, 49617, 49623, 49621, 49625, 93724",
        "kiosk": false
    },
    {
        "value": "733102",
        "label": "Mail Sorting Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54186006",
        "yellow_page_code": "49703",
        "kiosk": false
    },
    {
        "value": "733103",
        "label": "Folding Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54186004",
        "yellow_page_code": "33635",
        "kiosk": false
    },
    {
        "value": "733104",
        "label": "Mailing Lists",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54186008",
        "yellow_page_code": "47914, 49704",
        "kiosk": false
    },
    {
        "value": "733105",
        "label": "Advertising-Direct Mail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54186002",
        "yellow_page_code": "00506, 00617, 00524, 49611",
        "kiosk": false
    },
    {
        "value": "733106",
        "label": "Addressing Plate Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54186001",
        "yellow_page_code": "00302",
        "kiosk": false
    },
    {
        "value": "733107",
        "label": "Circulation Builders",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54186003",
        "yellow_page_code": "17912",
        "kiosk": false
    },
    {
        "value": "733108",
        "label": "Letter Shop Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54186005",
        "yellow_page_code": "47000, 47002",
        "kiosk": false
    },
    {
        "value": "733109",
        "label": "Direct Mail Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51314004",
        "yellow_page_code": "24837",
        "kiosk": false
    },
    {
        "value": "733110",
        "label": "Mailing & Shipping Kiosks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54186009",
        "yellow_page_code": "",
        "kiosk": true
    },
    {
        "value": "733401",
        "label": "Blueprinting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56143901",
        "yellow_page_code": "08600, 27017",
        "kiosk": false
    },
    {
        "value": "733402",
        "label": "Law & Financial Printers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311124",
        "yellow_page_code": "46210",
        "kiosk": false
    },
    {
        "value": "733403",
        "label": "Copying & Duplicating Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56143903",
        "yellow_page_code": "60836, 56292, 21362, 24726, 68319, 60815, 21521, 21520, 21517, 25295, 71723, 26903, 60802, 21604",
        "kiosk": false
    },
    {
        "value": "733404",
        "label": "Mounting & Finishing (Photographs)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56143904",
        "yellow_page_code": "54409",
        "kiosk": false
    },
    {
        "value": "733405",
        "label": "Collating Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56143902",
        "yellow_page_code": "19607",
        "kiosk": false
    },
    {
        "value": "733406",
        "label": "Plotting Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56143905",
        "yellow_page_code": "66706",
        "kiosk": false
    },
    {
        "value": "733407",
        "label": "X-Ray Duplicating Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56143906",
        "yellow_page_code": "94610",
        "kiosk": false
    },
    {
        "value": "733408",
        "label": "Color Copying",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56143907",
        "yellow_page_code": "19803",
        "kiosk": false
    },
    {
        "value": "733409",
        "label": "Bindings",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56143908",
        "yellow_page_code": "08116",
        "kiosk": false
    },
    {
        "value": "733410",
        "label": "Document Recording",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56143909",
        "yellow_page_code": "25330",
        "kiosk": false
    },
    {
        "value": "733411",
        "label": "Instant Print Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32311125",
        "yellow_page_code": "43351",
        "kiosk": false
    },
    {
        "value": "733501",
        "label": "Photographers-Commercial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54192204",
        "yellow_page_code": "61002",
        "kiosk": false
    },
    {
        "value": "733502",
        "label": "Photographers-Aerial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48121906",
        "yellow_page_code": "61000",
        "kiosk": false
    },
    {
        "value": "733503",
        "label": "Photographic Agencies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54192207",
        "yellow_page_code": "61007",
        "kiosk": false
    },
    {
        "value": "733504",
        "label": "Photographers-Scientific",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54192206",
        "yellow_page_code": "61005",
        "kiosk": false
    },
    {
        "value": "733505",
        "label": "Photocomposition",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54192202",
        "yellow_page_code": "60906",
        "kiosk": false
    },
    {
        "value": "733506",
        "label": "Photo-Modeling Studios",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54192201",
        "yellow_page_code": "60903",
        "kiosk": false
    },
    {
        "value": "733507",
        "label": "Photographers-Architectural",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54192203",
        "yellow_page_code": "60912",
        "kiosk": false
    },
    {
        "value": "733508",
        "label": "Photographic Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54192208",
        "yellow_page_code": "61107",
        "kiosk": false
    },
    {
        "value": "733509",
        "label": "Aerial Patrol & Inspection Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48121904",
        "yellow_page_code": "00701",
        "kiosk": false
    },
    {
        "value": "733510",
        "label": "Photographers-Oil Fields",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54192205",
        "yellow_page_code": "61017",
        "kiosk": false
    },
    {
        "value": "733511",
        "label": "Photographic Engineering",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54192209",
        "yellow_page_code": "61105",
        "kiosk": false
    },
    {
        "value": "733513",
        "label": "Computer Pictures-Novelty",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54192210",
        "yellow_page_code": "20220",
        "kiosk": false
    },
    {
        "value": "733601",
        "label": "Package Designing & Development",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54143012",
        "yellow_page_code": "58306, 24304",
        "kiosk": false
    },
    {
        "value": "733602",
        "label": "Graphic Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54143007",
        "yellow_page_code": "38823, 38805, 92893",
        "kiosk": false
    },
    {
        "value": "733603",
        "label": "Graphic Designers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54143006",
        "yellow_page_code": "38804, 38812, 48532, 38821, 24232, 24235, 30555, 24241, 43676, 21359",
        "kiosk": false
    },
    {
        "value": "733604",
        "label": "Artists-Commercial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54143002",
        "yellow_page_code": "03906, 82830, 19940, 03901",
        "kiosk": false
    },
    {
        "value": "733605",
        "label": "Advertising-Art Layout & Production Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54143001",
        "yellow_page_code": "00507, 00408, 00420",
        "kiosk": false
    },
    {
        "value": "733606",
        "label": "Charts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54143005",
        "yellow_page_code": "14706",
        "kiosk": false
    },
    {
        "value": "733607",
        "label": "Calligraphers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54143004",
        "yellow_page_code": "12398, 92944, 12406",
        "kiosk": false
    },
    {
        "value": "733608",
        "label": "Bumper Stickers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54143003",
        "yellow_page_code": "11501",
        "kiosk": false
    },
    {
        "value": "733609",
        "label": "Silk Screen Printing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54143015",
        "yellow_page_code": "77614, 77608",
        "kiosk": false
    },
    {
        "value": "733610",
        "label": "Slides Computer Graphics Etc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54143016",
        "yellow_page_code": "78306, 78325",
        "kiosk": false
    },
    {
        "value": "733611",
        "label": "Illustrators",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54143009",
        "yellow_page_code": "42615",
        "kiosk": false
    },
    {
        "value": "733612",
        "label": "Technical Illustrations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54143017",
        "yellow_page_code": "42628, 84218",
        "kiosk": false
    },
    {
        "value": "733613",
        "label": "Legal Graphics",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54143010",
        "yellow_page_code": "46813",
        "kiosk": false
    },
    {
        "value": "733614",
        "label": "Logos",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54143011",
        "yellow_page_code": "48518",
        "kiosk": false
    },
    {
        "value": "733615",
        "label": "Photo Design Production Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54143013",
        "yellow_page_code": "30346, 60803",
        "kiosk": false
    },
    {
        "value": "733616",
        "label": "Halftone Prints-Screened",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54143008",
        "yellow_page_code": "39736",
        "kiosk": false
    },
    {
        "value": "733617",
        "label": "Reprographic Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54143014",
        "yellow_page_code": "71761",
        "kiosk": false
    },
    {
        "value": "733618",
        "label": "Computer Graphic Design",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54143021",
        "yellow_page_code": "25736",
        "kiosk": false
    },
    {
        "value": "733619",
        "label": "Artists Caricatures",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54143019",
        "yellow_page_code": "13421, 03924, 93031",
        "kiosk": false
    },
    {
        "value": "733620",
        "label": "Sportswear Imprinting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54143020",
        "yellow_page_code": "80036",
        "kiosk": false
    },
    {
        "value": "733801",
        "label": "Court & Convention Reporters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56149201",
        "yellow_page_code": "22151, 77107, 71708, 22203, 22110",
        "kiosk": false
    },
    {
        "value": "733802",
        "label": "Word Processing Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56141013",
        "yellow_page_code": "94500",
        "kiosk": false
    },
    {
        "value": "733803",
        "label": "Resume Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56141009",
        "yellow_page_code": "43458, 72004, 04213, 24109, 62304, 46410",
        "kiosk": false
    },
    {
        "value": "733804",
        "label": "Stenographers-Public",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56141011",
        "yellow_page_code": "81206",
        "kiosk": false
    },
    {
        "value": "733805",
        "label": "Video Depositions",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56149204",
        "yellow_page_code": "91016",
        "kiosk": false
    },
    {
        "value": "733806",
        "label": "Typing Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56141012",
        "yellow_page_code": "89804",
        "kiosk": false
    },
    {
        "value": "733807",
        "label": "Manuscript Editing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56141005",
        "yellow_page_code": "49903",
        "kiosk": false
    },
    {
        "value": "733808",
        "label": "Proofreading",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56141008",
        "yellow_page_code": "68608",
        "kiosk": false
    },
    {
        "value": "733809",
        "label": "Medical Transcription Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56141007",
        "yellow_page_code": "51113",
        "kiosk": false
    },
    {
        "value": "733810",
        "label": "Transcribers-Court",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56149202",
        "yellow_page_code": "88205",
        "kiosk": false
    },
    {
        "value": "733811",
        "label": "Manuscript Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56141006",
        "yellow_page_code": "49836",
        "kiosk": false
    },
    {
        "value": "733812",
        "label": "Letter Writing Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56141004",
        "yellow_page_code": "47001",
        "kiosk": false
    },
    {
        "value": "733813",
        "label": "Dictating Machine Transcribing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56141002",
        "yellow_page_code": "24607",
        "kiosk": false
    },
    {
        "value": "733814",
        "label": "Book Reviewers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56141001",
        "yellow_page_code": "09301",
        "kiosk": false
    },
    {
        "value": "733815",
        "label": "Editing & Research Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56141003",
        "yellow_page_code": "27135",
        "kiosk": false
    },
    {
        "value": "733816",
        "label": "Secretarial & Court Reporting Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56149203",
        "yellow_page_code": "75300, 22137",
        "kiosk": false
    },
    {
        "value": "733817",
        "label": "Business & Financial Reports",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56141010",
        "yellow_page_code": "11712",
        "kiosk": false
    },
    {
        "value": "733818",
        "label": "Financial Document Information Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56141014",
        "yellow_page_code": "31816",
        "kiosk": false
    },
    {
        "value": "733819",
        "label": "Document Preparation Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56141015",
        "yellow_page_code": "25314, 84197",
        "kiosk": false
    },
    {
        "value": "733820",
        "label": "Legal Evidence Presentation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56141016",
        "yellow_page_code": "46822",
        "kiosk": false
    },
    {
        "value": "734201",
        "label": "Pest Control",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56171009",
        "yellow_page_code": "60338, 30395, 43772, 07322, 34508, 02725, 41295, 30545, 11090, 85230, 34915, 88337, 85205, 60401, 53308, 43207, 34901, 30400",
        "kiosk": false
    },
    {
        "value": "734202",
        "label": "Insecticides",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56171006",
        "yellow_page_code": "43204",
        "kiosk": false
    },
    {
        "value": "734203",
        "label": "Washroom Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899095",
        "yellow_page_code": "91711",
        "kiosk": false
    },
    {
        "value": "734204",
        "label": "Insect Control Devices",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56171005",
        "yellow_page_code": "43202",
        "kiosk": false
    },
    {
        "value": "734205",
        "label": "Bird Barriers & Repellents & Controls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56171003",
        "yellow_page_code": "08208",
        "kiosk": false
    },
    {
        "value": "734206",
        "label": "Mothproofing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56171008",
        "yellow_page_code": "53900",
        "kiosk": false
    },
    {
        "value": "734207",
        "label": "Deodorizing & Disinfecting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899062",
        "yellow_page_code": "56115, 34916, 66713, 24200, 56117",
        "kiosk": false
    },
    {
        "value": "734208",
        "label": "Spraying-Insect Control",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56171011",
        "yellow_page_code": "80106, 80117, 80122",
        "kiosk": false
    },
    {
        "value": "734209",
        "label": "Smoke Odor Counteracting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56171010",
        "yellow_page_code": "78508",
        "kiosk": false
    },
    {
        "value": "734210",
        "label": "Bee Removal",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56171002",
        "yellow_page_code": "07518, 07501",
        "kiosk": false
    },
    {
        "value": "734211",
        "label": "Air Fragrances",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899045",
        "yellow_page_code": "01109, 01205",
        "kiosk": false
    },
    {
        "value": "734212",
        "label": "Animals-Pest Trappers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56171001",
        "yellow_page_code": "02523, 02604, 02517, 02512",
        "kiosk": false
    },
    {
        "value": "734213",
        "label": "Fungicides",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56171004",
        "yellow_page_code": "35100",
        "kiosk": false
    },
    {
        "value": "734214",
        "label": "Bats-Removal & Exclusion",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56172005",
        "yellow_page_code": "07021",
        "kiosk": false
    },
    {
        "value": "734215",
        "label": "Insect Screens",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56171012",
        "yellow_page_code": "33630, 43217",
        "kiosk": false
    },
    {
        "value": "734901",
        "label": "Venetian Blinds-Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899091",
        "yellow_page_code": "90800, 90710, 52515, 08411",
        "kiosk": false
    },
    {
        "value": "734902",
        "label": "Janitor Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56172001",
        "yellow_page_code": "44108, 43679, 75691, 66002, 43023, 49718, 11112, 49725, 56207, 18409",
        "kiosk": false
    },
    {
        "value": "734903",
        "label": "Ventilating Systems-Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899092",
        "yellow_page_code": "00833, 01301, 01206, 01210, 41446, 26710, 15802, 26805, 00829, 90808, 00914, 35515, 90813, 26802, 01203",
        "kiosk": false
    },
    {
        "value": "734904",
        "label": "Window Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899096",
        "yellow_page_code": "93650, 93610, 41394, 93625, 93600, 74807",
        "kiosk": false
    },
    {
        "value": "734905",
        "label": "Wall Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899094",
        "yellow_page_code": "91400, 91315",
        "kiosk": false
    },
    {
        "value": "734906",
        "label": "Window Shades-Cleaning & Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899097",
        "yellow_page_code": "93641, 93606, 93636",
        "kiosk": false
    },
    {
        "value": "734907",
        "label": "Roof Cleaning Solvents & Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899085",
        "yellow_page_code": "72617, 72602",
        "kiosk": false
    },
    {
        "value": "734908",
        "label": "Property Maintenance",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899081",
        "yellow_page_code": "68702, 70609, 68624, 68626, 68639, 68642, 29991",
        "kiosk": false
    },
    {
        "value": "734909",
        "label": "Building Cleaning-Exterior",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899049",
        "yellow_page_code": "11108",
        "kiosk": false
    },
    {
        "value": "734910",
        "label": "Snow Removal Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899086",
        "yellow_page_code": "78617, 78700, 42323, 72716",
        "kiosk": false
    },
    {
        "value": "734911",
        "label": "Building Maintenance",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899052",
        "yellow_page_code": "11207, 11220, 11213",
        "kiosk": false
    },
    {
        "value": "734912",
        "label": "Building Cleaning-Interior",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899050",
        "yellow_page_code": "11109",
        "kiosk": false
    },
    {
        "value": "734913",
        "label": "Chemical Cleaning-Industrial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899054",
        "yellow_page_code": "15000",
        "kiosk": false
    },
    {
        "value": "734914",
        "label": "Ceiling Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899053",
        "yellow_page_code": "14206",
        "kiosk": false
    },
    {
        "value": "734915",
        "label": "Cleaning Services-Industrial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899059",
        "yellow_page_code": "18499, 43025, 18313, 42931, 18312, 24280, 18505, 18507, 18408, 18501, 43024, 18308, 18418, 18416, 18414",
        "kiosk": false
    },
    {
        "value": "734916",
        "label": "Chimney & Fireplace Cleaning Build/Rpr",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899055",
        "yellow_page_code": "32203, 15304, 33609, 15311, 15313, 15314, 15320",
        "kiosk": false
    },
    {
        "value": "734917",
        "label": "Condominium Maintenance",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899061",
        "yellow_page_code": "20900",
        "kiosk": false
    },
    {
        "value": "734918",
        "label": "Cleaning-Roof Patio Sidewalk & Etc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899060",
        "yellow_page_code": "18407, 59720",
        "kiosk": false
    },
    {
        "value": "734919",
        "label": "Floor Waxing Polishing & Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899068",
        "yellow_page_code": "40135, 33406, 33211",
        "kiosk": false
    },
    {
        "value": "734920",
        "label": "Filters-Air & Gas-Cleaning Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899065",
        "yellow_page_code": "31704, 31725",
        "kiosk": false
    },
    {
        "value": "734921",
        "label": "House Washing-Exterior",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899073",
        "yellow_page_code": "42003, 41919",
        "kiosk": false
    },
    {
        "value": "734922",
        "label": "House Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899072",
        "yellow_page_code": "42000, 41135, 12161, 41929, 41293, 41384, 41090",
        "kiosk": false
    },
    {
        "value": "734923",
        "label": "Maid & Butler Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899076",
        "yellow_page_code": "49604, 18431",
        "kiosk": false
    },
    {
        "value": "734924",
        "label": "Mobile Homes-Washing & Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899078",
        "yellow_page_code": "53205, 49946",
        "kiosk": false
    },
    {
        "value": "734925",
        "label": "Lot Cleaners",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899075",
        "yellow_page_code": "48700",
        "kiosk": false
    },
    {
        "value": "734926",
        "label": "Chimney Tops & Caps",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899057",
        "yellow_page_code": "15307",
        "kiosk": false
    },
    {
        "value": "734927",
        "label": "Floor Degreasing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899066",
        "yellow_page_code": "33300",
        "kiosk": false
    },
    {
        "value": "734928",
        "label": "Restaurant Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899082",
        "yellow_page_code": "71907",
        "kiosk": false
    },
    {
        "value": "734929",
        "label": "Awnings & Canopies-Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899048",
        "yellow_page_code": "05412",
        "kiosk": false
    },
    {
        "value": "734930",
        "label": "Floor Polish & Wax",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899067",
        "yellow_page_code": "33311",
        "kiosk": false
    },
    {
        "value": "734931",
        "label": "Gutter & Downspout Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899070",
        "yellow_page_code": "27216, 70316, 41365, 39409",
        "kiosk": false
    },
    {
        "value": "734932",
        "label": "Apartment Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899047",
        "yellow_page_code": "75690, 02834",
        "kiosk": false
    },
    {
        "value": "734933",
        "label": "Kitchen Exhaust Systems-Cleaned",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899074",
        "yellow_page_code": "44907, 71954, 71956",
        "kiosk": false
    },
    {
        "value": "734934",
        "label": "Flues",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899069",
        "yellow_page_code": "33612",
        "kiosk": false
    },
    {
        "value": "734935",
        "label": "Restaurant Hood & Duct Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56179006",
        "yellow_page_code": "71923, 38915, 71918, 41417, 71948",
        "kiosk": false
    },
    {
        "value": "734936",
        "label": "Acoustical Cleaners",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899044",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "734937",
        "label": "Clean Rooms-Service & Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899058",
        "yellow_page_code": "18310",
        "kiosk": false
    },
    {
        "value": "734938",
        "label": "Chimney Screens",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899056",
        "yellow_page_code": "15303",
        "kiosk": false
    },
    {
        "value": "734939",
        "label": "Mildewproofing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899077",
        "yellow_page_code": "52295, 52314",
        "kiosk": false
    },
    {
        "value": "734940",
        "label": "Vacuum Cleaning Contrs-Bldg Interior",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899090",
        "yellow_page_code": "90407",
        "kiosk": false
    },
    {
        "value": "734941",
        "label": "Vertical Blinds-Cleaning & Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899093",
        "yellow_page_code": "90835",
        "kiosk": false
    },
    {
        "value": "734942",
        "label": "Townhouse Maintenance Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899089",
        "yellow_page_code": "87601",
        "kiosk": false
    },
    {
        "value": "734943",
        "label": "Oven Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899080",
        "yellow_page_code": "58112",
        "kiosk": false
    },
    {
        "value": "734944",
        "label": "Restroom Sanitation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899084",
        "yellow_page_code": "71937",
        "kiosk": false
    },
    {
        "value": "734945",
        "label": "Stainproofing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899087",
        "yellow_page_code": "80434",
        "kiosk": false
    },
    {
        "value": "734946",
        "label": "Store Front Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899088",
        "yellow_page_code": "81809",
        "kiosk": false
    },
    {
        "value": "734947",
        "label": "Environmental Cleaning Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899063",
        "yellow_page_code": "29610",
        "kiosk": false
    },
    {
        "value": "734948",
        "label": "Mobile Washing Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899079",
        "yellow_page_code": "53214",
        "kiosk": false
    },
    {
        "value": "734949",
        "label": "Estate Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899064",
        "yellow_page_code": "29895",
        "kiosk": false
    },
    {
        "value": "734950",
        "label": "Restaurant Equipment Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899098",
        "yellow_page_code": "71912",
        "kiosk": false
    },
    {
        "value": "734951",
        "label": "Biological Waste Clean-Up",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899024",
        "yellow_page_code": "08233",
        "kiosk": false
    },
    {
        "value": "734952",
        "label": "Rubbish Garbage & Trash Chute Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56172002",
        "yellow_page_code": "73102, 93327",
        "kiosk": false
    },
    {
        "value": "734953",
        "label": "Cleaning Services-Commercial/Residential",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56172003",
        "yellow_page_code": "14620, 18403",
        "kiosk": false
    },
    {
        "value": "734954",
        "label": "Catch Basin Building Cleaning/Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56172004",
        "yellow_page_code": "14028, 14029, 14021",
        "kiosk": false
    },
    {
        "value": "734955",
        "label": "Ice Melting Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56179005",
        "yellow_page_code": "42319",
        "kiosk": false
    },
    {
        "value": "734956",
        "label": "Garage Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56172006",
        "yellow_page_code": "36135",
        "kiosk": false
    },
    {
        "value": "734957",
        "label": "Event Clean-Up",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56172007",
        "yellow_page_code": "29932",
        "kiosk": false
    },
    {
        "value": "734958",
        "label": "Parking Lot Lighting Maintenance",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56179007",
        "yellow_page_code": "59423",
        "kiosk": false
    },
    {
        "value": "734999",
        "label": "Building Cleaning/Maintenance Svcs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899051",
        "yellow_page_code": "11216",
        "kiosk": false
    },
    {
        "value": "735201",
        "label": "Hospital Beds-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228303",
        "yellow_page_code": "41707",
        "kiosk": false
    },
    {
        "value": "735202",
        "label": "Dental Equipment & Supplies-Leasing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53249003",
        "yellow_page_code": "23811",
        "kiosk": false
    },
    {
        "value": "735203",
        "label": "Hearing Aids-Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228302",
        "yellow_page_code": "40533, 40555",
        "kiosk": false
    },
    {
        "value": "735204",
        "label": "Disabled Persons Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228301",
        "yellow_page_code": "24824, 24827, 24819, 24846, 24820, 40099, 40119, 00038, 53030, 40001, 24833",
        "kiosk": false
    },
    {
        "value": "735205",
        "label": "Back Supports",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228304",
        "yellow_page_code": "05613, 05615",
        "kiosk": false
    },
    {
        "value": "735206",
        "label": "Medical Equipment-Rentals",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53249006",
        "yellow_page_code": "51012",
        "kiosk": false
    },
    {
        "value": "735207",
        "label": "Home Health Equipment Rental ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228305",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "735301",
        "label": "Crane Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899001",
        "yellow_page_code": "22304, 56446, 22310, 22314",
        "kiosk": false
    },
    {
        "value": "735302",
        "label": "Concrete Mixers-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891036",
        "yellow_page_code": "20608",
        "kiosk": false
    },
    {
        "value": "735303",
        "label": "Machinery-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891040",
        "yellow_page_code": "39015, 66031, 49209",
        "kiosk": false
    },
    {
        "value": "735304",
        "label": "Cranes-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23899002",
        "yellow_page_code": "22309",
        "kiosk": false
    },
    {
        "value": "735305",
        "label": "Backhoe Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891035",
        "yellow_page_code": "05610",
        "kiosk": false
    },
    {
        "value": "735306",
        "label": "Excavating Equipment-Renting & Leasing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53241204",
        "yellow_page_code": "30003",
        "kiosk": false
    },
    {
        "value": "735307",
        "label": "Bridge Materials",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53241201",
        "yellow_page_code": "10707",
        "kiosk": false
    },
    {
        "value": "735308",
        "label": "Heavy Equipment-Sales Rental & Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53241214",
        "yellow_page_code": "40924, 71697",
        "kiosk": false
    },
    {
        "value": "735309",
        "label": "Bulldozers For Hire",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23891068",
        "yellow_page_code": "11511",
        "kiosk": false
    },
    {
        "value": "735310",
        "label": "Street Plate Rentals",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53241215",
        "yellow_page_code": "21265, 82022",
        "kiosk": false
    },
    {
        "value": "735398",
        "label": "Heavy Construction Equipment-Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53241206",
        "yellow_page_code": "21129",
        "kiosk": false
    },
    {
        "value": "735901",
        "label": "Tanks-Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56299104",
        "yellow_page_code": "83515",
        "kiosk": false
    },
    {
        "value": "735902",
        "label": "Hospital Equipment & Supplies-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53231005",
        "yellow_page_code": "91307, 41803, 75511",
        "kiosk": false
    },
    {
        "value": "735903",
        "label": "Lawn & Garden Equipment-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228925",
        "yellow_page_code": "46215, 45844, 86917, 36302, 46301",
        "kiosk": false
    },
    {
        "value": "735904",
        "label": "Audio-Visual Equipment-Renting & Leasing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53221003",
        "yellow_page_code": "04305, 21322, 04314, 53907, 71703",
        "kiosk": false
    },
    {
        "value": "735905",
        "label": "Camping Equipment-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228904",
        "yellow_page_code": "12604, 85202",
        "kiosk": false
    },
    {
        "value": "735906",
        "label": "Party Supplies-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228932",
        "yellow_page_code": "59602, 83115, 67320, 59549, 59547, 59538, 71696, 92974, 93021, 70815, 92946, 49950, 49999, 06403",
        "kiosk": false
    },
    {
        "value": "735907",
        "label": "Plants-Living-Renting & Leasing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228935",
        "yellow_page_code": "66023, 66009",
        "kiosk": false
    },
    {
        "value": "735908",
        "label": "Photographic Equipment-Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228933",
        "yellow_page_code": "61025, 61104",
        "kiosk": false
    },
    {
        "value": "735909",
        "label": "Pumps-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228937",
        "yellow_page_code": "69202",
        "kiosk": false
    },
    {
        "value": "735910",
        "label": "Rental Service-Stores & Yards",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53231003",
        "yellow_page_code": "71704, 41393, 21373, 92997, 41274, 71714, 93071, 40711, 11727",
        "kiosk": false
    },
    {
        "value": "735911",
        "label": "Refrigerators & Freezers-Renting/Lease",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53221010",
        "yellow_page_code": "29875, 71503",
        "kiosk": false
    },
    {
        "value": "735912",
        "label": "Saws-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228938",
        "yellow_page_code": "74208",
        "kiosk": false
    },
    {
        "value": "735913",
        "label": "Sandblasting Equipment-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53249009",
        "yellow_page_code": "73805",
        "kiosk": false
    },
    {
        "value": "735914",
        "label": "Scales-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53249011",
        "yellow_page_code": "74305",
        "kiosk": false
    },
    {
        "value": "735915",
        "label": "Scaffolding-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53249010",
        "yellow_page_code": "74302, 74299, 24281, 30259, 74301",
        "kiosk": false
    },
    {
        "value": "735916",
        "label": "Spraying Equipment-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53249012",
        "yellow_page_code": "80200",
        "kiosk": false
    },
    {
        "value": "735917",
        "label": "Silverware-Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228939",
        "yellow_page_code": "83103, 77806",
        "kiosk": false
    },
    {
        "value": "735918",
        "label": "Tables-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228942",
        "yellow_page_code": "83200, 83112",
        "kiosk": false
    },
    {
        "value": "735919",
        "label": "Stereophonic & High Fidelity Eqpt-Rent",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53221014",
        "yellow_page_code": "81213, 81303",
        "kiosk": false
    },
    {
        "value": "735920",
        "label": "Television-Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53221015",
        "yellow_page_code": "84802",
        "kiosk": false
    },
    {
        "value": "735921",
        "label": "Tools-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228943",
        "yellow_page_code": "41045, 87015, 87202, 87010",
        "kiosk": false
    },
    {
        "value": "735922",
        "label": "Toilets-Portable",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56299105",
        "yellow_page_code": "71698, 67412, 86900, 86738, 21267, 41480",
        "kiosk": false
    },
    {
        "value": "735923",
        "label": "Video Recorders & Players-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53221017",
        "yellow_page_code": "91121, 71694, 91051, 90616, 91046, 91113, 91012, 91101",
        "kiosk": false
    },
    {
        "value": "735924",
        "label": "Trenching Machines-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53241211",
        "yellow_page_code": "88511, 88600, 88524",
        "kiosk": false
    },
    {
        "value": "735925",
        "label": "Wallpaper Removing Machines-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228944",
        "yellow_page_code": "91500",
        "kiosk": false
    },
    {
        "value": "735926",
        "label": "Welding Equipment-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53241212",
        "yellow_page_code": "93106",
        "kiosk": false
    },
    {
        "value": "735927",
        "label": "Washing Machines Dryers/Ironers-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53221018",
        "yellow_page_code": "91706",
        "kiosk": false
    },
    {
        "value": "735928",
        "label": "Wheel Chairs-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228912",
        "yellow_page_code": "93400",
        "kiosk": false
    },
    {
        "value": "735929",
        "label": "Floor Machines-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228918",
        "yellow_page_code": "33306",
        "kiosk": false
    },
    {
        "value": "735930",
        "label": "Furniture-Renting & Leasing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228921",
        "yellow_page_code": "71617, 35804",
        "kiosk": false
    },
    {
        "value": "735931",
        "label": "Generators-Electric-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53242005",
        "yellow_page_code": "37102, 37017",
        "kiosk": false
    },
    {
        "value": "735932",
        "label": "Ladders-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228924",
        "yellow_page_code": "45504",
        "kiosk": false
    },
    {
        "value": "735933",
        "label": "Leasing Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53231001",
        "yellow_page_code": "46600, 60340",
        "kiosk": false
    },
    {
        "value": "735934",
        "label": "Moving Supplies & Equipment-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228927",
        "yellow_page_code": "54500",
        "kiosk": false
    },
    {
        "value": "735935",
        "label": "Office Furniture & Equip-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53242006",
        "yellow_page_code": "56208",
        "kiosk": false
    },
    {
        "value": "735936",
        "label": "Musical Instruments-Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228929",
        "yellow_page_code": "54907",
        "kiosk": false
    },
    {
        "value": "735937",
        "label": "Oil Well Equipment & Supplies-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53241207",
        "yellow_page_code": "56507, 56456, 56611, 57003, 56437",
        "kiosk": false
    },
    {
        "value": "735938",
        "label": "Misc Consumer Goods Rental ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228947",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "735939",
        "label": "Aircraft Charter Rental & Leasing Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53241101",
        "yellow_page_code": "01312, 33018, 01413, 01402, 01433",
        "kiosk": false
    },
    {
        "value": "735940",
        "label": "Flowers-Plants & Trees-Silk/Dried-Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228920",
        "yellow_page_code": "33517",
        "kiosk": false
    },
    {
        "value": "735941",
        "label": "Appliances-Household-Major-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53221002",
        "yellow_page_code": "03002, 02914, 27417, 02921, 02934",
        "kiosk": false
    },
    {
        "value": "735942",
        "label": "Boilers-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53242001",
        "yellow_page_code": "09106",
        "kiosk": false
    },
    {
        "value": "735943",
        "label": "Beds-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228903",
        "yellow_page_code": "07408",
        "kiosk": false
    },
    {
        "value": "735944",
        "label": "Carpet & Rug Cleaning Equipment-Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228906",
        "yellow_page_code": "13604",
        "kiosk": false
    },
    {
        "value": "735945",
        "label": "Caterers Supplies-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228908",
        "yellow_page_code": "14103",
        "kiosk": false
    },
    {
        "value": "735946",
        "label": "Carpet & Rugs-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228907",
        "yellow_page_code": "13708, 73203",
        "kiosk": false
    },
    {
        "value": "735947",
        "label": "Chinaware & Glassware-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228910",
        "yellow_page_code": "37717, 15400",
        "kiosk": false
    },
    {
        "value": "735948",
        "label": "Chairs-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228909",
        "yellow_page_code": "14602, 92949, 14521",
        "kiosk": false
    },
    {
        "value": "735949",
        "label": "Contractors-Equipment & Supls-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53249002",
        "yellow_page_code": "66721, 41104, 21202",
        "kiosk": false
    },
    {
        "value": "735950",
        "label": "Compressors-Air & Gas-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53249001",
        "yellow_page_code": "20304, 20228",
        "kiosk": false
    },
    {
        "value": "735951",
        "label": "Lamps-Health",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228946",
        "yellow_page_code": "45700",
        "kiosk": false
    },
    {
        "value": "735952",
        "label": "Electric Equipment-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53221005",
        "yellow_page_code": "27509, 47318",
        "kiosk": false
    },
    {
        "value": "735953",
        "label": "Floors-Portable",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228919",
        "yellow_page_code": "33501, 67413",
        "kiosk": false
    },
    {
        "value": "735954",
        "label": "Sod Cutting Machines",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228940",
        "yellow_page_code": "79006",
        "kiosk": false
    },
    {
        "value": "735955",
        "label": "Ranges & Stoves-Renting & Leasing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53221009",
        "yellow_page_code": "70403",
        "kiosk": false
    },
    {
        "value": "735956",
        "label": "Ice Making Equipment & Machines-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53221007",
        "yellow_page_code": "42503, 42322",
        "kiosk": false
    },
    {
        "value": "735957",
        "label": "Steam Cleaning Equipment-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53221013",
        "yellow_page_code": "80805",
        "kiosk": false
    },
    {
        "value": "735958",
        "label": "Restaurant Equipment & Supplies-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53221011",
        "yellow_page_code": "20399, 71922",
        "kiosk": false
    },
    {
        "value": "735959",
        "label": "Leasing Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53242007",
        "yellow_page_code": "21187, 29712, 32008, 37029, 29741, 46512",
        "kiosk": false
    },
    {
        "value": "735960",
        "label": "Exercise Equipment-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228916",
        "yellow_page_code": "30006",
        "kiosk": false
    },
    {
        "value": "735961",
        "label": "Water Softening Equip Svc/Supls-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53221020",
        "yellow_page_code": "92310, 92501",
        "kiosk": false
    },
    {
        "value": "735962",
        "label": "Wrecker Service Equipment-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53241213",
        "yellow_page_code": "94489",
        "kiosk": false
    },
    {
        "value": "735963",
        "label": "Fork Lifts-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53241205",
        "yellow_page_code": "34125, 47213, 34112",
        "kiosk": false
    },
    {
        "value": "735964",
        "label": "Safes & Vaults-Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53231002",
        "yellow_page_code": "73316",
        "kiosk": false
    },
    {
        "value": "735965",
        "label": "Baby Crib-Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228901",
        "yellow_page_code": "05601",
        "kiosk": false
    },
    {
        "value": "735966",
        "label": "Concrete Equipment & Supplies-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53241203",
        "yellow_page_code": "20605",
        "kiosk": false
    },
    {
        "value": "735967",
        "label": "Dishwashing Machines-Renting & Leasing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53221004",
        "yellow_page_code": "71951, 25006, 93726",
        "kiosk": false
    },
    {
        "value": "735968",
        "label": "Cleaning Systems-Pressure/Chem-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53241202",
        "yellow_page_code": "68124, 18503",
        "kiosk": false
    },
    {
        "value": "735969",
        "label": "Pianos-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228934",
        "yellow_page_code": "64903",
        "kiosk": false
    },
    {
        "value": "735970",
        "label": "Vacuum Cleaners-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53221016",
        "yellow_page_code": "90315, 90309",
        "kiosk": false
    },
    {
        "value": "735971",
        "label": "Water Coolers-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228945",
        "yellow_page_code": "92205",
        "kiosk": false
    },
    {
        "value": "735972",
        "label": "Rainmaker Rentals (Irrigation Equip)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53241209",
        "yellow_page_code": "70307",
        "kiosk": false
    },
    {
        "value": "735973",
        "label": "Air Conditioning Room Units-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53221001",
        "yellow_page_code": "01005, 01003, 01007, 01012, 00903",
        "kiosk": false
    },
    {
        "value": "735974",
        "label": "Sanding Machines-Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53241210",
        "yellow_page_code": "73801",
        "kiosk": false
    },
    {
        "value": "735975",
        "label": "Radio Communications Equip/Systs-Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53241208",
        "yellow_page_code": "21372, 69815, 69912",
        "kiosk": false
    },
    {
        "value": "735976",
        "label": "Bar Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228902",
        "yellow_page_code": "06503",
        "kiosk": false
    },
    {
        "value": "735977",
        "label": "Water Heaters-Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53221019",
        "yellow_page_code": "92212",
        "kiosk": false
    },
    {
        "value": "735978",
        "label": "Cots",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228913",
        "yellow_page_code": "21911",
        "kiosk": false
    },
    {
        "value": "735979",
        "label": "Dance Floors-Portable",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228914",
        "yellow_page_code": "23401",
        "kiosk": false
    },
    {
        "value": "735980",
        "label": "Coat Racks-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228911",
        "yellow_page_code": "19003",
        "kiosk": false
    },
    {
        "value": "735981",
        "label": "Kitchen Rentals-Commercial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53221008",
        "yellow_page_code": "19927, 44918",
        "kiosk": false
    },
    {
        "value": "735982",
        "label": "Stages",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228941",
        "yellow_page_code": "80414, 80424",
        "kiosk": false
    },
    {
        "value": "735983",
        "label": "Platforms-Portable",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228936",
        "yellow_page_code": "66511, 80416, 52113",
        "kiosk": false
    },
    {
        "value": "735984",
        "label": "Canopies-Rented",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228905",
        "yellow_page_code": "12907",
        "kiosk": false
    },
    {
        "value": "735985",
        "label": "Plumbing Drains/Sewer Cleaning Eqpt-Rntl",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56299103",
        "yellow_page_code": "66709",
        "kiosk": false
    },
    {
        "value": "735986",
        "label": "Tools-Electric-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53249014",
        "yellow_page_code": "87101",
        "kiosk": false
    },
    {
        "value": "735987",
        "label": "Music-Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228928",
        "yellow_page_code": "54900",
        "kiosk": false
    },
    {
        "value": "735988",
        "label": "Outboard Motors-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228930",
        "yellow_page_code": "58104",
        "kiosk": false
    },
    {
        "value": "735989",
        "label": "Pipe-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53249008",
        "yellow_page_code": "65505",
        "kiosk": false
    },
    {
        "value": "735990",
        "label": "Dust Control Materials-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53249004",
        "yellow_page_code": "26807",
        "kiosk": false
    },
    {
        "value": "735991",
        "label": "Exposition Supplies-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228917",
        "yellow_page_code": "30301",
        "kiosk": false
    },
    {
        "value": "735992",
        "label": "Sewing Machines-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53221012",
        "yellow_page_code": "75909",
        "kiosk": false
    },
    {
        "value": "735993",
        "label": "Golf Equipment-Rentals",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228923",
        "yellow_page_code": "38212",
        "kiosk": false
    },
    {
        "value": "735994",
        "label": "Mops-Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228926",
        "yellow_page_code": "53603",
        "kiosk": false
    },
    {
        "value": "735995",
        "label": "Painters Equipment & Supplies-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228931",
        "yellow_page_code": "58703",
        "kiosk": false
    },
    {
        "value": "735996",
        "label": "Calculating/Adding Machines/Supl-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53242002",
        "yellow_page_code": "12309",
        "kiosk": false
    },
    {
        "value": "735997",
        "label": "Draperies-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228915",
        "yellow_page_code": "25903",
        "kiosk": false
    },
    {
        "value": "735998",
        "label": "Garment Racks-Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228922",
        "yellow_page_code": "36408",
        "kiosk": false
    },
    {
        "value": "735999",
        "label": "Misc Equipment-Rental & Leasing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53231004",
        "yellow_page_code": "47412, 50044, 59550, 83114, 42646, 21603, 92943, 13942, 13938, 14322, 60799, 13935, 14237, 31020, 41811, 02303, 25011",
        "kiosk": false
    },
    {
        "value": "736101",
        "label": "Chauffeur Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56131101",
        "yellow_page_code": "14713, 26414, 14708",
        "kiosk": false
    },
    {
        "value": "736102",
        "label": "Bartending Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56132002",
        "yellow_page_code": "06424, 92952, 06709",
        "kiosk": false
    },
    {
        "value": "736103",
        "label": "Employment Agencies & Opportunities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56131102",
        "yellow_page_code": "28513, 28527, 30428, 44524, 28528, 28533, 28539, 28525, 28506",
        "kiosk": false
    },
    {
        "value": "736104",
        "label": "Employment Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161202",
        "yellow_page_code": "21075, 28544, 28507",
        "kiosk": false
    },
    {
        "value": "736105",
        "label": "Executive Search Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56131201",
        "yellow_page_code": "30008, 71030",
        "kiosk": false
    },
    {
        "value": "736106",
        "label": "Employment Service-Govt Co Fraternal",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56131103",
        "yellow_page_code": "75472, 28600, 28526, 28542",
        "kiosk": false
    },
    {
        "value": "736107",
        "label": "Stevedoring Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48832006",
        "yellow_page_code": "81400, 30068",
        "kiosk": false
    },
    {
        "value": "736108",
        "label": "Labor Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56131104",
        "yellow_page_code": "49957, 45213",
        "kiosk": false
    },
    {
        "value": "736109",
        "label": "Teachers' Agencies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56131105",
        "yellow_page_code": "84202",
        "kiosk": false
    },
    {
        "value": "736110",
        "label": "Outplacement Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56131106",
        "yellow_page_code": "58117, 58118, 28515, 58113",
        "kiosk": false
    },
    {
        "value": "736111",
        "label": "Personnel Training Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56131107",
        "yellow_page_code": "60309",
        "kiosk": false
    },
    {
        "value": "736112",
        "label": "Physician & Surgeon Recruitment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56131108",
        "yellow_page_code": "62310",
        "kiosk": false
    },
    {
        "value": "736113",
        "label": "Job Listing Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56131109",
        "yellow_page_code": "28531, 44514, 43546",
        "kiosk": false
    },
    {
        "value": "736114",
        "label": "Pharmacist Employment Register",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56131110",
        "yellow_page_code": "60616",
        "kiosk": false
    },
    {
        "value": "736115",
        "label": "Disabled & Handicapped Employment Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56131111",
        "yellow_page_code": "24823",
        "kiosk": false
    },
    {
        "value": "736116",
        "label": "Nurses-Recruitment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56131112",
        "yellow_page_code": "55906",
        "kiosk": false
    },
    {
        "value": "736117",
        "label": "Trained Attendants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56131113",
        "yellow_page_code": "88112",
        "kiosk": false
    },
    {
        "value": "736118",
        "label": "Endorsement Testimonials-Secured",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56131114",
        "yellow_page_code": "28616",
        "kiosk": false
    },
    {
        "value": "736119",
        "label": "Pre-Employment Verification",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56131115",
        "yellow_page_code": "68204",
        "kiosk": false
    },
    {
        "value": "736120",
        "label": "Personal Coaching",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56131116",
        "yellow_page_code": "60225, 18901",
        "kiosk": false
    },
    {
        "value": "736121",
        "label": "Maids & Butlers Svc-Referral & Info Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56131117",
        "yellow_page_code": "49616",
        "kiosk": false
    },
    {
        "value": "736122",
        "label": "Employment Verification",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56131118",
        "yellow_page_code": "28530, 29928",
        "kiosk": false
    },
    {
        "value": "736123",
        "label": "Personal Chefs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56131119",
        "yellow_page_code": "14820, 60319",
        "kiosk": false
    },
    {
        "value": "736124",
        "label": "Nannies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56131121",
        "yellow_page_code": "04296, 04294, 55103, 15229",
        "kiosk": false
    },
    {
        "value": "736125",
        "label": "Nurses' Registries",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56131122",
        "yellow_page_code": "55920",
        "kiosk": false
    },
    {
        "value": "736301",
        "label": "Modeling Agencies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56133003",
        "yellow_page_code": "53309, 53310, 53305, 53299, 50336, 53304",
        "kiosk": false
    },
    {
        "value": "736302",
        "label": "Truck Driver-Leasing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56133005",
        "yellow_page_code": "88708",
        "kiosk": false
    },
    {
        "value": "736303",
        "label": "Employment Service-Employee Leasing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56133001",
        "yellow_page_code": "28602, 28523, 60322",
        "kiosk": false
    },
    {
        "value": "736304",
        "label": "Employment Contractors-Temporary Help",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56132001",
        "yellow_page_code": "85012, 28508, 21364",
        "kiosk": false
    },
    {
        "value": "736305",
        "label": "Medical Services-Temporary",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56133002",
        "yellow_page_code": "51121",
        "kiosk": false
    },
    {
        "value": "736306",
        "label": "Waiter & Waitress Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56133007",
        "yellow_page_code": "91309",
        "kiosk": false
    },
    {
        "value": "736307",
        "label": "Usher Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56133006",
        "yellow_page_code": "90200",
        "kiosk": false
    },
    {
        "value": "736308",
        "label": "Oil Field Labor Crews",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56133004",
        "yellow_page_code": "56609",
        "kiosk": false
    },
    {
        "value": "736309",
        "label": "Medical Staff Relief",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56133008",
        "yellow_page_code": "51039",
        "kiosk": false
    },
    {
        "value": "736310",
        "label": "Professional Employer Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56133009",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "737101",
        "label": "Computer Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54151104",
        "yellow_page_code": "20311, 20230",
        "kiosk": false
    },
    {
        "value": "737102",
        "label": "Software/Application/Platform Developers & Programmers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54151103",
        "yellow_page_code": "53035, 20328, 20243, 20353, 23417, 79190, 20375, 20296, 26740",
        "kiosk": false
    },
    {
        "value": "737103",
        "label": "Computers-System Designers & Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54151105",
        "yellow_page_code": "20323, 20285, 30058, 20279, 76514",
        "kiosk": false
    },
    {
        "value": "737104",
        "label": "Data Systems Consultants & Designers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54151106",
        "yellow_page_code": "23504",
        "kiosk": false
    },
    {
        "value": "737105",
        "label": "Computer Graphics",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54151102",
        "yellow_page_code": "20330, 20244",
        "kiosk": false
    },
    {
        "value": "737106",
        "label": "Programming",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54151107",
        "yellow_page_code": "68519, 68518",
        "kiosk": false
    },
    {
        "value": "737107",
        "label": "Cd Rom Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54151101",
        "yellow_page_code": "14214",
        "kiosk": false
    },
    {
        "value": "737109",
        "label": "Information Technology Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54151109",
        "yellow_page_code": "43130, 20294",
        "kiosk": false
    },
    {
        "value": "737201",
        "label": "Computer Software-Manufacturers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51321001",
        "yellow_page_code": "20218",
        "kiosk": false
    },
    {
        "value": "737202",
        "label": "Data Processing Software",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33461004",
        "yellow_page_code": "23411",
        "kiosk": false
    },
    {
        "value": "737203",
        "label": "Instrumentation Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33461005",
        "yellow_page_code": "43323",
        "kiosk": false
    },
    {
        "value": "737204",
        "label": "Accounting Software & Solutions",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51321003",
        "yellow_page_code": "00122",
        "kiosk": false
    },
    {
        "value": "737205",
        "label": "Data Management",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51321004",
        "yellow_page_code": "23423",
        "kiosk": false
    },
    {
        "value": "737206",
        "label": "Software/Application/Platform Publishing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51321005",
        "yellow_page_code": "20344, 14137, 43747, 79114, 20288, 20329, 43014, 79116",
        "kiosk": false
    },
    {
        "value": "737298",
        "label": "Prepackaged Software",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51321002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "737301",
        "label": "Engineers-Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54151213",
        "yellow_page_code": "29119, 29209",
        "kiosk": false
    },
    {
        "value": "737302",
        "label": "Computers-Equipment Installation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54151206",
        "yellow_page_code": "20324",
        "kiosk": false
    },
    {
        "value": "737303",
        "label": "Cad Systems & Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54151201",
        "yellow_page_code": "12217, 20210",
        "kiosk": false
    },
    {
        "value": "737304",
        "label": "Data Communications Systems-Networks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54151209",
        "yellow_page_code": "23419, 55426, 24310",
        "kiosk": false
    },
    {
        "value": "737305",
        "label": "Computer Disaster Planning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54151203",
        "yellow_page_code": "24840, 20312, 20251",
        "kiosk": false
    },
    {
        "value": "737307",
        "label": "Computers-Multimedia",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54151207",
        "yellow_page_code": "20237",
        "kiosk": false
    },
    {
        "value": "737308",
        "label": "Computers-Virtual Reality",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54151208",
        "yellow_page_code": "20340, 91150",
        "kiosk": false
    },
    {
        "value": "737309",
        "label": "Donor Recognition Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54151210",
        "yellow_page_code": "25455",
        "kiosk": false
    },
    {
        "value": "737310",
        "label": "Electronic Data Interchange",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54151211",
        "yellow_page_code": "28033",
        "kiosk": false
    },
    {
        "value": "737311",
        "label": "Website Design Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54151217",
        "yellow_page_code": "20379, 92883, 92894, 30238, 27070, 92896, 43522, 92895, 20335, 43550, 92898",
        "kiosk": false
    },
    {
        "value": "737312",
        "label": "Robotic Components",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54151216",
        "yellow_page_code": "72450",
        "kiosk": false
    },
    {
        "value": "737313",
        "label": "Computers-Telephony",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54151220",
        "yellow_page_code": "20350",
        "kiosk": false
    },
    {
        "value": "737314",
        "label": "Internet Svcs-Network Designers/Conslnt",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54151219",
        "yellow_page_code": "55419, 20377, 55421, 43549, 20241, 20134, 11724",
        "kiosk": false
    },
    {
        "value": "737315",
        "label": "Computer Equipment-Mainframe",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54151221",
        "yellow_page_code": "20224",
        "kiosk": false
    },
    {
        "value": "737316",
        "label": "Data Acquisition Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54151222",
        "yellow_page_code": "23416",
        "kiosk": false
    },
    {
        "value": "737317",
        "label": "Voice-Data Network-Internet Svc-Internet",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54151223",
        "yellow_page_code": "91252",
        "kiosk": false
    },
    {
        "value": "737318",
        "label": "Consultants Usability",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54151224",
        "yellow_page_code": "21068",
        "kiosk": false
    },
    {
        "value": "737319",
        "label": "Computers Hard Drive Destruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54151225",
        "yellow_page_code": "20369",
        "kiosk": false
    },
    {
        "value": "737398",
        "label": "Computer Integrated Systems Design",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54151204",
        "yellow_page_code": "20349, 20269",
        "kiosk": false
    },
    {
        "value": "737401",
        "label": "Data Processing Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51821008",
        "yellow_page_code": "23425, 23426, 23500",
        "kiosk": false
    },
    {
        "value": "737402",
        "label": "Computer Time Sharing Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51821005",
        "yellow_page_code": "86207, 20307",
        "kiosk": false
    },
    {
        "value": "737403",
        "label": "Computer Bulletin Boards",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51821003",
        "yellow_page_code": "20209, 20249",
        "kiosk": false
    },
    {
        "value": "737404",
        "label": "Credit Card-Merchant Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51821007",
        "yellow_page_code": "22418, 34804, 22412, 51320",
        "kiosk": false
    },
    {
        "value": "737405",
        "label": "Scanning Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51821015",
        "yellow_page_code": "25116, 14221, 74314, 20339, 57610, 24737, 20258, 20274",
        "kiosk": false
    },
    {
        "value": "737406",
        "label": "Govt-Computer Processing & Preparation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51821010",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "737407",
        "label": "Fax Information & Service Bureaus",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51821009",
        "yellow_page_code": "31016",
        "kiosk": false
    },
    {
        "value": "737408",
        "label": "Codes",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51821002",
        "yellow_page_code": "19206",
        "kiosk": false
    },
    {
        "value": "737409",
        "label": "Computer Data Storage",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51821004",
        "yellow_page_code": "20253, 20221",
        "kiosk": false
    },
    {
        "value": "737410",
        "label": "Computing Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51821006",
        "yellow_page_code": "18840, 20232",
        "kiosk": false
    },
    {
        "value": "737411",
        "label": "Tabulating Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51821016",
        "yellow_page_code": "83202",
        "kiosk": false
    },
    {
        "value": "737412",
        "label": "Bar Coding",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51821001",
        "yellow_page_code": "06412, 06414, 42929",
        "kiosk": false
    },
    {
        "value": "737413",
        "label": "Key Punching Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51821014",
        "yellow_page_code": "44800",
        "kiosk": false
    },
    {
        "value": "737414",
        "label": "Automation Technology Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51821020",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "737415",
        "label": "Internet Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51821013",
        "yellow_page_code": "23103, 43528, 91248, 43574, 43610, 43635, 43633, 43520, 93932, 10815, 43526, 24741, 55321, 43568, 43529, 43564, 43628, 91223, 43558, 43532, 43531, 43560, 43553, 43555, 78925, 14232, 43597, 57530, 55420, 43530, 55418, 43621, 20356, 43542, 43540, 20245, 20240, 43539, 20341, 43538, 20284, 20286, 43527, 43650, 75140, 43589, 20236, 43583, 43577, 43713",
        "kiosk": false
    },
    {
        "value": "737416",
        "label": "Internet Home Page Dev Consulting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51821012",
        "yellow_page_code": "43541, 43591, 43543",
        "kiosk": false
    },
    {
        "value": "737417",
        "label": "Website Hosting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51821011",
        "yellow_page_code": "92897, 43554, 43535, 43660, 43545, 43556, 43551, 41822, 43607",
        "kiosk": false
    },
    {
        "value": "737418",
        "label": "Internet Services E-Mail Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51821017",
        "yellow_page_code": "43623",
        "kiosk": false
    },
    {
        "value": "737419",
        "label": "Internet Search Engines",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51821018",
        "yellow_page_code": "43563, 43570, 75190",
        "kiosk": false
    },
    {
        "value": "737420",
        "label": "Computer-Intranet Development",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51821028",
        "yellow_page_code": "20275",
        "kiosk": false
    },
    {
        "value": "737421",
        "label": "Electronic Networks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51821021",
        "yellow_page_code": "28032",
        "kiosk": false
    },
    {
        "value": "737422",
        "label": "Satellite Internet Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51821023",
        "yellow_page_code": "73931",
        "kiosk": false
    },
    {
        "value": "737423",
        "label": "Gaming-Online/Gambling/Esports",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51621003",
        "yellow_page_code": "20283",
        "kiosk": false
    },
    {
        "value": "737424",
        "label": "Computer Documentation Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51821025",
        "yellow_page_code": "20219",
        "kiosk": false
    },
    {
        "value": "737425",
        "label": "Internet Services Video Conferencing Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51821026",
        "yellow_page_code": "43525",
        "kiosk": false
    },
    {
        "value": "737426",
        "label": "Rfid (Radio Frequency Identification)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51821027",
        "yellow_page_code": "72050",
        "kiosk": false
    },
    {
        "value": "737427",
        "label": "Wifi Hotspots",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51821019",
        "yellow_page_code": "93450",
        "kiosk": false
    },
    {
        "value": "737429",
        "label": "Virtual Currency Mining",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51821029",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "737501",
        "label": "Information Brokers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51781008",
        "yellow_page_code": "43017",
        "kiosk": false
    },
    {
        "value": "737502",
        "label": "Information Search & Retrieval Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51781010",
        "yellow_page_code": "43105",
        "kiosk": false
    },
    {
        "value": "737503",
        "label": "Videotext Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51781014",
        "yellow_page_code": "91114",
        "kiosk": false
    },
    {
        "value": "737504",
        "label": "Retrieval Systems-Automated",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51781013",
        "yellow_page_code": "72013",
        "kiosk": false
    },
    {
        "value": "737505",
        "label": "Online Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51781012",
        "yellow_page_code": "57525, 92899, 57514, 43786, 43648, 25428, 92892, 43547",
        "kiosk": false
    },
    {
        "value": "737506",
        "label": "Information Provider Services-Dial It",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51781015",
        "yellow_page_code": "43015",
        "kiosk": false
    },
    {
        "value": "737507",
        "label": "Joint Records & Factum",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51781011",
        "yellow_page_code": "44513, 51218",
        "kiosk": false
    },
    {
        "value": "737598",
        "label": "Information Retrieval Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51781009",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "737698",
        "label": "Computer Rooms Monitoring & Management",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54151301",
        "yellow_page_code": "20268, 20290",
        "kiosk": false
    },
    {
        "value": "737701",
        "label": "Computers-Renting & Leasing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53242003",
        "yellow_page_code": "20322",
        "kiosk": false
    },
    {
        "value": "737702",
        "label": "Copiers-Renting & Leasing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53242004",
        "yellow_page_code": "21515",
        "kiosk": false
    },
    {
        "value": "737801",
        "label": "Computers-Service & Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121022",
        "yellow_page_code": "45912, 20365, 43575, 20359, 20265, 70017, 20272, 20281, 20317, 20254",
        "kiosk": false
    },
    {
        "value": "737802",
        "label": "Data Processing Equipment-Maintenance",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121024",
        "yellow_page_code": "23424, 23408",
        "kiosk": false
    },
    {
        "value": "737803",
        "label": "Word Processing Equipment-Maintenance",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121031",
        "yellow_page_code": "94407",
        "kiosk": false
    },
    {
        "value": "737804",
        "label": "Computer Wiring",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121021",
        "yellow_page_code": "20373, 20239, 30379, 20295, 20211",
        "kiosk": false
    },
    {
        "value": "737805",
        "label": "Computer Room Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121020",
        "yellow_page_code": "20313",
        "kiosk": false
    },
    {
        "value": "737806",
        "label": "Computer Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121032",
        "yellow_page_code": "20223, 20278",
        "kiosk": false
    },
    {
        "value": "737807",
        "label": "Computer Consultants-Installation/Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121033",
        "yellow_page_code": "20222",
        "kiosk": false
    },
    {
        "value": "737808",
        "label": "E-Waste (Electronic Recycling)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121019",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "737809",
        "label": "E-Waste (Electronics Recycling) Kiosks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121034",
        "yellow_page_code": "",
        "kiosk": true
    },
    {
        "value": "737901",
        "label": "Hardware Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54151214",
        "yellow_page_code": "40108",
        "kiosk": false
    },
    {
        "value": "737902",
        "label": "Electronics-Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54151212",
        "yellow_page_code": "28200",
        "kiosk": false
    },
    {
        "value": "737903",
        "label": "Word Processing Systems-Conslnts/Design",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54151218",
        "yellow_page_code": "94408",
        "kiosk": false
    },
    {
        "value": "737904",
        "label": "Computers-Networking",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54151905",
        "yellow_page_code": "41352, 20235, 20216, 55422",
        "kiosk": false
    },
    {
        "value": "737905",
        "label": "Computer Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54151202",
        "yellow_page_code": "20217, 21052, 55417, 23403",
        "kiosk": false
    },
    {
        "value": "737906",
        "label": "Information System Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54151215",
        "yellow_page_code": "43123",
        "kiosk": false
    },
    {
        "value": "737907",
        "label": "Computers-Enhancements",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54151904",
        "yellow_page_code": "20310, 20259",
        "kiosk": false
    },
    {
        "value": "737908",
        "label": "Computer Presentations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54151901",
        "yellow_page_code": "20337, 78324",
        "kiosk": false
    },
    {
        "value": "737910",
        "label": "Information Equip & Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54151906",
        "yellow_page_code": "43010, 43019",
        "kiosk": false
    },
    {
        "value": "737911",
        "label": "Computers-Support Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54151903",
        "yellow_page_code": "20262, 20270, 84205, 20345",
        "kiosk": false
    },
    {
        "value": "737912",
        "label": "Computers Virus Detection & Prevention",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51821022",
        "yellow_page_code": "20297, 20257, 20366",
        "kiosk": false
    },
    {
        "value": "737913",
        "label": "Data Retrieval Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54151907",
        "yellow_page_code": "23413",
        "kiosk": false
    },
    {
        "value": "737999",
        "label": "Computer Related Services NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54151902",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "738101",
        "label": "Polygraph Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56161110",
        "yellow_page_code": "67117, 67116, 47202, 67205",
        "kiosk": false
    },
    {
        "value": "738102",
        "label": "Security Guard & Patrol Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56161208",
        "yellow_page_code": "41263, 39208, 75306, 21011, 75310, 75314, 75319, 41258, 39315, 59790, 56556",
        "kiosk": false
    },
    {
        "value": "738103",
        "label": "Armored Car Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56161301",
        "yellow_page_code": "03402",
        "kiosk": false
    },
    {
        "value": "738104",
        "label": "Detective Agencies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56161103",
        "yellow_page_code": "30337, 24400",
        "kiosk": false
    },
    {
        "value": "738105",
        "label": "Burglary & Robbery Protective Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56161202",
        "yellow_page_code": "11509, 11521",
        "kiosk": false
    },
    {
        "value": "738106",
        "label": "Guard Dogs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56161206",
        "yellow_page_code": "39206, 75337",
        "kiosk": false
    },
    {
        "value": "738107",
        "label": "Fingerprinting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56161106",
        "yellow_page_code": "48101, 31900",
        "kiosk": false
    },
    {
        "value": "738108",
        "label": "Investigators",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56161107",
        "yellow_page_code": "43702",
        "kiosk": false
    },
    {
        "value": "738109",
        "label": "Escort Service-Motorized",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56161205",
        "yellow_page_code": "54222, 67011, 92931, 29806",
        "kiosk": false
    },
    {
        "value": "738110",
        "label": "Detectives-Private",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56161104",
        "yellow_page_code": "29989, 24308, 68421, 68426, 43718",
        "kiosk": false
    },
    {
        "value": "738112",
        "label": "Shoplifting Prevention Devices",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56161209",
        "yellow_page_code": "77008",
        "kiosk": false
    },
    {
        "value": "738113",
        "label": "Criminologists",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56161102",
        "yellow_page_code": "22605",
        "kiosk": false
    },
    {
        "value": "738114",
        "label": "Traffic Control Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56161210",
        "yellow_page_code": "87818, 87819",
        "kiosk": false
    },
    {
        "value": "738115",
        "label": "Constables",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56161204",
        "yellow_page_code": "21000",
        "kiosk": false
    },
    {
        "value": "738116",
        "label": "Litigation Support Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56161109",
        "yellow_page_code": "48009, 21083, 48007",
        "kiosk": false
    },
    {
        "value": "738117",
        "label": "Fingerprint Experts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56161105",
        "yellow_page_code": "31813",
        "kiosk": false
    },
    {
        "value": "738118",
        "label": "Law Enforcement Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56161108",
        "yellow_page_code": "46112",
        "kiosk": false
    },
    {
        "value": "738119",
        "label": "Bodyguard Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56161201",
        "yellow_page_code": "08906",
        "kiosk": false
    },
    {
        "value": "738120",
        "label": "Sabotage Prevention Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56161111",
        "yellow_page_code": "73318",
        "kiosk": false
    },
    {
        "value": "738121",
        "label": "Civil Enforcement",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56161203",
        "yellow_page_code": "18137, 18213",
        "kiosk": false
    },
    {
        "value": "738122",
        "label": "Police Service-Private",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56161207",
        "yellow_page_code": "67010",
        "kiosk": false
    },
    {
        "value": "738198",
        "label": "Detective Guard & Armored Car Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56161302",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "738201",
        "label": "Card Key Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56162105",
        "yellow_page_code": "13403, 13405",
        "kiosk": false
    },
    {
        "value": "738202",
        "label": "Security Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56162104",
        "yellow_page_code": "75330, 41444, 12410, 43640, 75323, 41136, 75324, 82525, 11513",
        "kiosk": false
    },
    {
        "value": "738203",
        "label": "Burglar Alarm Systems-Monitoring",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56162103",
        "yellow_page_code": "41461, 11516",
        "kiosk": false
    },
    {
        "value": "738204",
        "label": "Security Systs-Communications/Computer",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56162109",
        "yellow_page_code": "75343, 20212, 20348, 20229, 75327, 55322, 75307, 43587, 00043, 55430",
        "kiosk": false
    },
    {
        "value": "738205",
        "label": "Sprinkler Supervisory Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56162111",
        "yellow_page_code": "80205",
        "kiosk": false
    },
    {
        "value": "738206",
        "label": "Video Equipment-Security & Ind Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56162112",
        "yellow_page_code": "75334, 91123",
        "kiosk": false
    },
    {
        "value": "738207",
        "label": "Watchmen's Equipment & Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56162114",
        "yellow_page_code": "92005",
        "kiosk": false
    },
    {
        "value": "738208",
        "label": "Bank Protective Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56162102",
        "yellow_page_code": "06418, 06401",
        "kiosk": false
    },
    {
        "value": "738209",
        "label": "Door Look-Outs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56162106",
        "yellow_page_code": "25604",
        "kiosk": false
    },
    {
        "value": "738210",
        "label": "Access Panels",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56162101",
        "yellow_page_code": "00039",
        "kiosk": false
    },
    {
        "value": "738211",
        "label": "Warning Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56162113",
        "yellow_page_code": "91701, 24325",
        "kiosk": false
    },
    {
        "value": "738212",
        "label": "Sensors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56162110",
        "yellow_page_code": "75518",
        "kiosk": false
    },
    {
        "value": "738213",
        "label": "Remote Sensing Analysis",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56162108",
        "yellow_page_code": "71614",
        "kiosk": false
    },
    {
        "value": "738214",
        "label": "Lock Boxes",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56162115",
        "yellow_page_code": "48425",
        "kiosk": false
    },
    {
        "value": "738215",
        "label": "House Arrest Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56162116",
        "yellow_page_code": "41932, 41254",
        "kiosk": false
    },
    {
        "value": "738216",
        "label": "Installation/Monitoring-Security Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56162117",
        "yellow_page_code": "75213",
        "kiosk": false
    },
    {
        "value": "738217",
        "label": "Offender Monitoring Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56162118",
        "yellow_page_code": "02630, 56215",
        "kiosk": false
    },
    {
        "value": "738218",
        "label": "Alcohol Monitoring",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56162119",
        "yellow_page_code": "53430, 01812",
        "kiosk": false
    },
    {
        "value": "738219",
        "label": "Personal Monitoring Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56162120",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "738301",
        "label": "News Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51612006",
        "yellow_page_code": "55408",
        "kiosk": false
    },
    {
        "value": "738302",
        "label": "Newspaper Feature Syndicates",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51612009",
        "yellow_page_code": "55502",
        "kiosk": false
    },
    {
        "value": "738303",
        "label": "Traffic Reports",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51612012",
        "yellow_page_code": "87812",
        "kiosk": false
    },
    {
        "value": "738304",
        "label": "Citizens' Advice Bureau",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151007",
        "yellow_page_code": "17990",
        "kiosk": false
    },
    {
        "value": "738401",
        "label": "Photo Finishing-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81292201",
        "yellow_page_code": "31605, 60808, 61006, 60820, 61125, 61030, 61024, 60810, 60814, 31613, 93044",
        "kiosk": false
    },
    {
        "value": "738402",
        "label": "Photo Finishing-Custom",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81292102",
        "yellow_page_code": "61028, 60806",
        "kiosk": false
    },
    {
        "value": "738403",
        "label": "Photo-Retouching",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81292106",
        "yellow_page_code": "60813, 60839, 60902, 61023",
        "kiosk": false
    },
    {
        "value": "738404",
        "label": "Photo Finishing-Wholesale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81292104",
        "yellow_page_code": "60809, 60900",
        "kiosk": false
    },
    {
        "value": "738405",
        "label": "Photographic Enlargements",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81292111",
        "yellow_page_code": "61009, 67402",
        "kiosk": false
    },
    {
        "value": "738406",
        "label": "Photographs-Stock",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81292113",
        "yellow_page_code": "61114, 61106",
        "kiosk": false
    },
    {
        "value": "738407",
        "label": "Photographic Color Processing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81292109",
        "yellow_page_code": "61101",
        "kiosk": false
    },
    {
        "value": "738408",
        "label": "Slides & Film Strips",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81292114",
        "yellow_page_code": "78308",
        "kiosk": false
    },
    {
        "value": "738409",
        "label": "Photo Laboratories-Commercial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81292105",
        "yellow_page_code": "60901",
        "kiosk": false
    },
    {
        "value": "738410",
        "label": "Photographic Darkroom-Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81292110",
        "yellow_page_code": "61012",
        "kiosk": false
    },
    {
        "value": "738411",
        "label": "Photographic Reproduction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81292112",
        "yellow_page_code": "61026",
        "kiosk": false
    },
    {
        "value": "738412",
        "label": "Dark Room Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81292101",
        "yellow_page_code": "23312",
        "kiosk": false
    },
    {
        "value": "738413",
        "label": "Photo-Sculptures",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81292107",
        "yellow_page_code": "60837",
        "kiosk": false
    },
    {
        "value": "738414",
        "label": "Photogelatin Reproduction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81292108",
        "yellow_page_code": "60905",
        "kiosk": false
    },
    {
        "value": "738415",
        "label": "Photographic & Video-Imaging & Analysis",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81292115",
        "yellow_page_code": "61010",
        "kiosk": false
    },
    {
        "value": "738416",
        "label": "Photo Digital Processing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81292116",
        "yellow_page_code": "60807, 43299",
        "kiosk": false
    },
    {
        "value": "738417",
        "label": "Photographic Black & White Prints",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81292117",
        "yellow_page_code": "60924",
        "kiosk": false
    },
    {
        "value": "738901",
        "label": "Auctioneers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54199002",
        "yellow_page_code": "04310, 04299, 04321, 70614, 04326, 30460, 04302, 04309, 48112, 43629, 31831",
        "kiosk": false
    },
    {
        "value": "738902",
        "label": "Interior Decorators Design & Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54141003",
        "yellow_page_code": "58715, 21193, 41369, 30142, 77010, 71290, 07414, 24230, 23648, 41414, 21156, 41220, 43616, 56316, 56315, 23649, 43600, 56203, 41114, 24207, 23607",
        "kiosk": false
    },
    {
        "value": "738903",
        "label": "Paging & Answering Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56142102",
        "yellow_page_code": "84329, 84316, 84505, 51308, 84423, 84253, 84438, 58507, 02707, 84327, 84304, 02720, 84434",
        "kiosk": false
    },
    {
        "value": "738904",
        "label": "Paging & Signaling Equip/Systems Rpr",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51929002",
        "yellow_page_code": "58439, 58514, 58504",
        "kiosk": false
    },
    {
        "value": "738905",
        "label": "Coin & Bill Counting/Sorting Svc Kiosks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54199025",
        "yellow_page_code": "",
        "kiosk": true
    },
    {
        "value": "738906",
        "label": "Lecture & Seminar Bureaus",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51929003",
        "yellow_page_code": "75415, 79706, 68901, 54214, 79708, 79705, 46804, 91050, 67943, 79811",
        "kiosk": false
    },
    {
        "value": "738907",
        "label": "Arbitration Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54119901",
        "yellow_page_code": "50913, 45211, 46912, 46894, 46815, 20120, 03119, 55312, 50909, 03106, 20879",
        "kiosk": false
    },
    {
        "value": "738908",
        "label": "Water Conservation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81331205",
        "yellow_page_code": "92107",
        "kiosk": false
    },
    {
        "value": "738909",
        "label": "Swimming Pool-Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56179002",
        "yellow_page_code": "15506, 67199, 15515, 82833, 82900",
        "kiosk": false
    },
    {
        "value": "738910",
        "label": "Store Designers & Planners",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54141006",
        "yellow_page_code": "24306, 81805",
        "kiosk": false
    },
    {
        "value": "738911",
        "label": "License Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56149904",
        "yellow_page_code": "47108, 04836",
        "kiosk": false
    },
    {
        "value": "738912",
        "label": "Call Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56142202",
        "yellow_page_code": "84406, 84301, 84319, 84290, 84510, 84232, 12368, 60718, 79119, 03090",
        "kiosk": false
    },
    {
        "value": "738913",
        "label": "Appraisers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54199001",
        "yellow_page_code": "08611, 03038, 03032, 03419, 03028, 03027, 03030, 02712, 03004",
        "kiosk": false
    },
    {
        "value": "738914",
        "label": "Artists Agents",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71141006",
        "yellow_page_code": "03902, 60926",
        "kiosk": false
    },
    {
        "value": "738915",
        "label": "Tool Designers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54142004",
        "yellow_page_code": "86908",
        "kiosk": false
    },
    {
        "value": "738916",
        "label": "Automobile Appraisers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51929004",
        "yellow_page_code": "30031, 04705, 04508",
        "kiosk": false
    },
    {
        "value": "738917",
        "label": "Transcribing Service-Tape Disc Etc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51229004",
        "yellow_page_code": "88213, 75299, 21719, 88204",
        "kiosk": false
    },
    {
        "value": "738918",
        "label": "Barricades",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299916",
        "yellow_page_code": "06708, 21260",
        "kiosk": false
    },
    {
        "value": "738919",
        "label": "Building Inspection Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54135001",
        "yellow_page_code": "11206, 43290, 11298, 01307",
        "kiosk": false
    },
    {
        "value": "738920",
        "label": "Translators & Interpreters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54193001",
        "yellow_page_code": "77438, 88302, 43611, 29873",
        "kiosk": false
    },
    {
        "value": "738921",
        "label": "Business Development",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56149902",
        "yellow_page_code": "11805, 24323, 57917",
        "kiosk": false
    },
    {
        "value": "738922",
        "label": "Business Brokers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56149901",
        "yellow_page_code": "11830, 84128, 11802, 11812, 11825, 30316, 29990",
        "kiosk": false
    },
    {
        "value": "738923",
        "label": "Business Records & Documents-Storage",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56149903",
        "yellow_page_code": "11901, 81710, 81720, 25292",
        "kiosk": false
    },
    {
        "value": "738924",
        "label": "Waste Reduction & Disposal Equip-Ind",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33299911",
        "yellow_page_code": "91826, 91831, 71116, 56440, 91837, 91904",
        "kiosk": false
    },
    {
        "value": "738925",
        "label": "Searchers Of Records",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51929005",
        "yellow_page_code": "75200",
        "kiosk": false
    },
    {
        "value": "738926",
        "label": "Check Cashing Protection Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51929006",
        "yellow_page_code": "14800, 14715, 14807, 14813, 14816, 14814",
        "kiosk": false
    },
    {
        "value": "738927",
        "label": "Cloth Cutting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56199003",
        "yellow_page_code": "18703",
        "kiosk": false
    },
    {
        "value": "738928",
        "label": "Shoes-Custom Made",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51929007",
        "yellow_page_code": "30367, 76908",
        "kiosk": false
    },
    {
        "value": "738929",
        "label": "Tank Testing & Inspection",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54199006",
        "yellow_page_code": "83503",
        "kiosk": false
    },
    {
        "value": "738930",
        "label": "Textile Designers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54142002",
        "yellow_page_code": "85404, 24238",
        "kiosk": false
    },
    {
        "value": "738931",
        "label": "Convention & Meeting Facilities & Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56192002",
        "yellow_page_code": "51205, 30124, 91033, 20919, 21311, 51230, 51123, 21314, 21304",
        "kiosk": false
    },
    {
        "value": "738932",
        "label": "Convention Information Bureaus",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56159101",
        "yellow_page_code": "91251, 43012, 91219, 91222, 21302",
        "kiosk": false
    },
    {
        "value": "738933",
        "label": "Cutting & Slitting Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54142001",
        "yellow_page_code": "23102",
        "kiosk": false
    },
    {
        "value": "738934",
        "label": "Textile-Manufacturer's Representatives",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54142003",
        "yellow_page_code": "85408",
        "kiosk": false
    },
    {
        "value": "738935",
        "label": "Decorating Contractors-Party/Convention",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54141001",
        "yellow_page_code": "23700",
        "kiosk": false
    },
    {
        "value": "738936",
        "label": "Organizing Services-Household & Business",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54141004",
        "yellow_page_code": "41264, 57901",
        "kiosk": false
    },
    {
        "value": "738937",
        "label": "Signs-Erectors & Hangers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54189015",
        "yellow_page_code": "77508, 77507",
        "kiosk": false
    },
    {
        "value": "738938",
        "label": "Signs-Maintenance & Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54189016",
        "yellow_page_code": "77600",
        "kiosk": false
    },
    {
        "value": "738939",
        "label": "Conference Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51929008",
        "yellow_page_code": "20906, 30625",
        "kiosk": false
    },
    {
        "value": "738940",
        "label": "Drafting Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54134001",
        "yellow_page_code": "25817, 25805, 25815",
        "kiosk": false
    },
    {
        "value": "738941",
        "label": "Divers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54199003",
        "yellow_page_code": "25204, 25219, 74937",
        "kiosk": false
    },
    {
        "value": "738942",
        "label": "Embroidery",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54149001",
        "yellow_page_code": "28500, 23007",
        "kiosk": false
    },
    {
        "value": "738943",
        "label": "Electrical Power Systems-Maintenance",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131042",
        "yellow_page_code": "28001",
        "kiosk": false
    },
    {
        "value": "738944",
        "label": "Events-Special",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71131004",
        "yellow_page_code": "79935, 29936, 29935, 21365, 34850, 29938, 29939, 29940",
        "kiosk": false
    },
    {
        "value": "738945",
        "label": "Eviction Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56199005",
        "yellow_page_code": "29908",
        "kiosk": false
    },
    {
        "value": "738946",
        "label": "Voice Mail Messaging Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51929009",
        "yellow_page_code": "91236, 84430",
        "kiosk": false
    },
    {
        "value": "738947",
        "label": "Recording Studios",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51224002",
        "yellow_page_code": "54811, 54611, 79510, 82040, 54613, 04319, 55019, 30157, 70901, 54618, 70904, 70837",
        "kiosk": false
    },
    {
        "value": "738948",
        "label": "Business Service Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56143910",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "738949",
        "label": "Fire Protection Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56199006",
        "yellow_page_code": "32200",
        "kiosk": false
    },
    {
        "value": "738950",
        "label": "Space Planners",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54134003",
        "yellow_page_code": "79613, 79590",
        "kiosk": false
    },
    {
        "value": "738951",
        "label": "Promotions & Fund Raising",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56142201",
        "yellow_page_code": "33723, 68530, 20386, 77325, 68599, 68537, 68513",
        "kiosk": false
    },
    {
        "value": "738952",
        "label": "Monuments-Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56199018",
        "yellow_page_code": "53601",
        "kiosk": false
    },
    {
        "value": "738953",
        "label": "Paralegals",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54119903",
        "yellow_page_code": "59301, 44503, 46890, 59299, 46820",
        "kiosk": false
    },
    {
        "value": "738954",
        "label": "Glass-Board-Up Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56199008",
        "yellow_page_code": "37408, 28425, 08622",
        "kiosk": false
    },
    {
        "value": "738955",
        "label": "Engravers-General",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56199004",
        "yellow_page_code": "29413, 29416, 29501, 29419, 29404, 29407, 92955, 53513, 29425",
        "kiosk": false
    },
    {
        "value": "738956",
        "label": "Lead Paint Detection Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56199013",
        "yellow_page_code": "46516, 46525, 46504, 46518",
        "kiosk": false
    },
    {
        "value": "738957",
        "label": "Handwriting Experts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56199009",
        "yellow_page_code": "40006, 38826, 40104, 40115",
        "kiosk": false
    },
    {
        "value": "738958",
        "label": "Liquidators",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56199014",
        "yellow_page_code": "47806",
        "kiosk": false
    },
    {
        "value": "738959",
        "label": "Information & Referral Svcs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51929010",
        "yellow_page_code": "43021, 43111, 48625, 21041, 43117, 48626",
        "kiosk": false
    },
    {
        "value": "738960",
        "label": "Interior Decorators & Designers Workroom",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54141002",
        "yellow_page_code": "43631, 43602",
        "kiosk": false
    },
    {
        "value": "738961",
        "label": "Conference & Seminar Coordinators",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51929011",
        "yellow_page_code": "20911",
        "kiosk": false
    },
    {
        "value": "738962",
        "label": "Inventory Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56199011",
        "yellow_page_code": "43625, 43700, 81410, 43620",
        "kiosk": false
    },
    {
        "value": "738963",
        "label": "Labeling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56191001",
        "yellow_page_code": "45202",
        "kiosk": false
    },
    {
        "value": "738964",
        "label": "Layouts-Office Factory & Etc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54134002",
        "yellow_page_code": "46406",
        "kiosk": false
    },
    {
        "value": "738965",
        "label": "Laminations-Plastic Paper Etc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56199012",
        "yellow_page_code": "45516, 45512, 45602, 45509, 58816, 45619",
        "kiosk": false
    },
    {
        "value": "738966",
        "label": "Water Hauling Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56199021",
        "yellow_page_code": "82809, 21269, 56481, 91920, 82817, 92209",
        "kiosk": false
    },
    {
        "value": "738967",
        "label": "Incentive Programs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56199010",
        "yellow_page_code": "42804, 41824",
        "kiosk": false
    },
    {
        "value": "738968",
        "label": "Literary Agents",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56199015",
        "yellow_page_code": "48000",
        "kiosk": false
    },
    {
        "value": "738969",
        "label": "Medical Records Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56199016",
        "yellow_page_code": "28128, 30089, 51107",
        "kiosk": false
    },
    {
        "value": "738970",
        "label": "Messenger Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51929012",
        "yellow_page_code": "51400",
        "kiosk": false
    },
    {
        "value": "738971",
        "label": "Mail Box-Rentals",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56143101",
        "yellow_page_code": "67514, 49620, 49607",
        "kiosk": false
    },
    {
        "value": "738972",
        "label": "Metal Cutting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351719",
        "yellow_page_code": "23708, 51406, 51410, 51415",
        "kiosk": false
    },
    {
        "value": "738973",
        "label": "Teleconferencing Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56142101",
        "yellow_page_code": "84212, 43748, 84251",
        "kiosk": false
    },
    {
        "value": "738974",
        "label": "Estates",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53131112",
        "yellow_page_code": "29904",
        "kiosk": false
    },
    {
        "value": "738975",
        "label": "Statistical Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56199020",
        "yellow_page_code": "80030, 80706, 80617",
        "kiosk": false
    },
    {
        "value": "738976",
        "label": "Microfilming Service Equipment & Supls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56149905",
        "yellow_page_code": "52137, 52116, 52200",
        "kiosk": false
    },
    {
        "value": "738977",
        "label": "Motels & Hotels Reservations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56159905",
        "yellow_page_code": "53808, 41907, 53710, 07310, 53711, 41823, 41819, 48510, 07311, 71916",
        "kiosk": false
    },
    {
        "value": "738978",
        "label": "Gas Measurement Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56199007",
        "yellow_page_code": "36703",
        "kiosk": false
    },
    {
        "value": "738979",
        "label": "Notaries-Public",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "54112001",
        "yellow_page_code": "55703, 11730, 93066, 55702",
        "kiosk": false
    },
    {
        "value": "738980",
        "label": "Music-Background & Foreground",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51229001",
        "yellow_page_code": "54902, 54701, 54615, 54803",
        "kiosk": false
    },
    {
        "value": "738981",
        "label": "Estate Sales",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54135002",
        "yellow_page_code": "29840, 29835, 29809, 29830",
        "kiosk": false
    },
    {
        "value": "738982",
        "label": "Merchandising Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54189011",
        "yellow_page_code": "51306",
        "kiosk": false
    },
    {
        "value": "738983",
        "label": "Office Records-Storage",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33351720",
        "yellow_page_code": "56308",
        "kiosk": false
    },
    {
        "value": "738984",
        "label": "Water Treatment Equip Svc & Supls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56149907",
        "yellow_page_code": "92412, 56460, 21142, 92130, 92515, 92510, 92118, 92506, 92400, 92109",
        "kiosk": false
    },
    {
        "value": "738985",
        "label": "Music Production Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51224001",
        "yellow_page_code": "54710, 54722, 54609",
        "kiosk": false
    },
    {
        "value": "738986",
        "label": "Clipping Bureaus",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56199002",
        "yellow_page_code": "67945, 18600, 18599",
        "kiosk": false
    },
    {
        "value": "738987",
        "label": "Trade Fairs & Shows",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56192004",
        "yellow_page_code": "07117, 44519, 87710, 30300, 87716, 87712, 30071, 30126",
        "kiosk": false
    },
    {
        "value": "738988",
        "label": "Packaging Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56191002",
        "yellow_page_code": "76512, 58402, 58401, 58311, 21095, 58316, 76518, 91061, 58320, 58412, 33816, 21369, 43771, 58314",
        "kiosk": false
    },
    {
        "value": "738989",
        "label": "Patent Searchers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54119904",
        "yellow_page_code": "59706",
        "kiosk": false
    },
    {
        "value": "738990",
        "label": "Paper-Shredded",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56199019",
        "yellow_page_code": "77422, 20282, 51072, 70916, 59102, 56307, 20924, 25296, 23420, 25322",
        "kiosk": false
    },
    {
        "value": "738991",
        "label": "Plants-Interior Design & Maintenance",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54141005",
        "yellow_page_code": "66025, 29985, 66026, 66101, 66024, 43521",
        "kiosk": false
    },
    {
        "value": "738992",
        "label": "Petroleum Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53131113",
        "yellow_page_code": "60602",
        "kiosk": false
    },
    {
        "value": "738993",
        "label": "Rack Merchandise Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56149906",
        "yellow_page_code": "69509",
        "kiosk": false
    },
    {
        "value": "738994",
        "label": "Process Servers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54119905",
        "yellow_page_code": "68502",
        "kiosk": false
    },
    {
        "value": "738995",
        "label": "Merchandise Brokers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56199017",
        "yellow_page_code": "10902, 51304",
        "kiosk": false
    },
    {
        "value": "738996",
        "label": "Inspection Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54135003",
        "yellow_page_code": "42939, 66720, 49504, 43303, 14518, 43310, 43307, 43203, 43300",
        "kiosk": false
    },
    {
        "value": "738997",
        "label": "Recorded Information",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51229003",
        "yellow_page_code": "70810, 70808",
        "kiosk": false
    },
    {
        "value": "738998",
        "label": "Repossessing Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56149101",
        "yellow_page_code": "30304, 71800",
        "kiosk": false
    },
    {
        "value": "738999",
        "label": "Business Services NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56199001",
        "yellow_page_code": "59702, 00105, 00808, 05509, 06105, 06309, 09705, 11809, 14111, 14806, 19409, 19601, 21005, 21608, 22108, 24405, 24812, 25301, 28105, 28407, 28501, 29312, 29506, 29911, 31001, 32006, 32805, 33004, 35105, 38706, 39101, 42508, 43609, 46808, 53502, 55609, 61013, 69303, 70805, 70806, 71001, 73503, 73509, 75411, 77109, 77402, 79404, 79901, 81306, 83901, 84101, 85307, 85407, 86906, 87804, 92105, 92505, 94903, 24408, 32108, 29905, 39900, 41203, 69307, 12403, 55709, 61016, 69711, 84806, 13914, 68613, 41921, 23501, 41704, 13411, 85412, 13611, 25311, 83304, 19936, 21312, 71612, 79736, 52136, 21117, 70909, 27911, 28213, 68011, 73309, 39313, 51413, 60211, 55611, 27607, 30608, 51014, 77509, 79503, 93811, 49830, 46511, 85236, 37409, 02314, 34217, 69715, 81413, 05318, 91233, 41222, 84422, 73605, 11807, 15716, 54007, 10915, 34817, 47215, 40516, 11818, 69818, 04312, 11821, 49937, 20890, 04919, 21137, 88414, 03461, 01311, 20140, 92318, 66010, 21139, 15217, 56410, 55814, 32810, 59411, 42617, 78114, 68595, 11710, 82090, 90410, 43619, 49821, 60213, 69717, 70818, 57816, 73614, 79738, 46810, 52115, 53413, 87715, 42623, 49838, 21607, 24839, 84330, 58119, 84399, 76716, 87717, 60717, 68024, 90633, 12367, 70924, 87718, 25021, 21039, 44310, 90191, 33003, 42110, 85016, 43016, 70814, 59208, 35614, 32633, 27516, 40839, 68023, 58615, 14216, 05910, 84250, 00605, 46817, 14219, 14222, 14224, 20110, 88527, 21131, 27913, 31907, 30237, 55610, 87721, 33020, 93051, 23517, 11829, 70820, 43588, 23421, 29596, 51061, 23422, 60730, 52389, 18861, 49115, 68864, 75470, 14212, 23639, 03037",
        "kiosk": false
    },
    {
        "value": "751301",
        "label": "Tractor-Renting & Leasing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53212009",
        "yellow_page_code": "87706",
        "kiosk": false
    },
    {
        "value": "751302",
        "label": "Moving Supplies & Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53212006",
        "yellow_page_code": "54421, 29945, 54408",
        "kiosk": false
    },
    {
        "value": "751303",
        "label": "Truck Renting & Leasing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53212016",
        "yellow_page_code": "88902, 48614, 30240",
        "kiosk": false
    },
    {
        "value": "751304",
        "label": "Moving-Self-Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53212007",
        "yellow_page_code": "54502",
        "kiosk": false
    },
    {
        "value": "751305",
        "label": "Car Pullers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53212002",
        "yellow_page_code": "13009",
        "kiosk": false
    },
    {
        "value": "751306",
        "label": "Dump Truck Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53212017",
        "yellow_page_code": "26819",
        "kiosk": false
    },
    {
        "value": "751401",
        "label": "Automobile Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53211101",
        "yellow_page_code": "05200, 05109, 13075, 13106, 19923",
        "kiosk": false
    },
    {
        "value": "751402",
        "label": "Vans-Renting & Leasing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53211103",
        "yellow_page_code": "90602",
        "kiosk": false
    },
    {
        "value": "751403",
        "label": "Automobile-Antique/Classic-Renting/Lsng",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53211102",
        "yellow_page_code": "18240, 05199",
        "kiosk": false
    },
    {
        "value": "751404",
        "label": "Automobile Leasing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53211105",
        "yellow_page_code": "05230, 13058, 13035",
        "kiosk": false
    },
    {
        "value": "751405",
        "label": "Ambulances & Hearses-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53211104",
        "yellow_page_code": "02112",
        "kiosk": false
    },
    {
        "value": "751598",
        "label": "Passenger Car Leasing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53211201",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "751901",
        "label": "Recreational Vehicles-Renting & Leasing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53212008",
        "yellow_page_code": "71009",
        "kiosk": false
    },
    {
        "value": "751902",
        "label": "Mobile Homes-Renting & Leasing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53212003",
        "yellow_page_code": "49959, 53200, 53025, 49923",
        "kiosk": false
    },
    {
        "value": "751903",
        "label": "Trailer Renting & Leasing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53212010",
        "yellow_page_code": "87902",
        "kiosk": false
    },
    {
        "value": "751904",
        "label": "Campers & Pick-Up Coaches-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53212001",
        "yellow_page_code": "13053, 12504",
        "kiosk": false
    },
    {
        "value": "751905",
        "label": "Motor Homes-Renting & Leasing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53212005",
        "yellow_page_code": "54208",
        "kiosk": false
    },
    {
        "value": "751906",
        "label": "Trailers-Boat-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53212011",
        "yellow_page_code": "87911",
        "kiosk": false
    },
    {
        "value": "751907",
        "label": "Trailers-Camping & Travel-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53212012",
        "yellow_page_code": "87914",
        "kiosk": false
    },
    {
        "value": "751908",
        "label": "Trailers-Horse-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53212013",
        "yellow_page_code": "88011",
        "kiosk": false
    },
    {
        "value": "751909",
        "label": "Trailers-Office-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53212014",
        "yellow_page_code": "88020",
        "kiosk": false
    },
    {
        "value": "751910",
        "label": "Trailers-Truck-Renting & Leasing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53212015",
        "yellow_page_code": "88021",
        "kiosk": false
    },
    {
        "value": "751911",
        "label": "Mobile Offices/Commercial Units-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53212004",
        "yellow_page_code": "53213",
        "kiosk": false
    },
    {
        "value": "751912",
        "label": "Buildings Portable Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53212018",
        "yellow_page_code": "11115",
        "kiosk": false
    },
    {
        "value": "751913",
        "label": "Truck Utility Trailer & RV Rental & Leasing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53212019",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "752101",
        "label": "Parking Attendant Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81293002",
        "yellow_page_code": "59402, 59416",
        "kiosk": false
    },
    {
        "value": "752102",
        "label": "Parking Stations & Garages",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81293004",
        "yellow_page_code": "59413, 59417, 59415, 59406",
        "kiosk": false
    },
    {
        "value": "752103",
        "label": "Parking Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81293003",
        "yellow_page_code": "59403",
        "kiosk": false
    },
    {
        "value": "752104",
        "label": "Airport Parking Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81293001",
        "yellow_page_code": "01709",
        "kiosk": false
    },
    {
        "value": "752105",
        "label": "Park & Ride",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81293005",
        "yellow_page_code": "59316",
        "kiosk": false
    },
    {
        "value": "753201",
        "label": "Automobile Body-Repairing & Painting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81112102",
        "yellow_page_code": "04721, 04431, 13066, 04533, 04530, 23819, 04620, 19969, 08905, 58600, 04913, 04606, 19680, 58619, 23825, 04840, 08834",
        "kiosk": false
    },
    {
        "value": "753202",
        "label": "Automobile Paint",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81112104",
        "yellow_page_code": "13065, 04912",
        "kiosk": false
    },
    {
        "value": "753203",
        "label": "Automobile Customizing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81112103",
        "yellow_page_code": "88632, 71049, 04713, 88619, 04702",
        "kiosk": false
    },
    {
        "value": "753204",
        "label": "Automobile Restoration-Antique & Classic",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81112106",
        "yellow_page_code": "04529, 05206, 13043, 05220, 88631",
        "kiosk": false
    },
    {
        "value": "753205",
        "label": "Automobile-Reconditioning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81112109",
        "yellow_page_code": "05108",
        "kiosk": false
    },
    {
        "value": "753206",
        "label": "Truck-Painting & Lettering",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81112121",
        "yellow_page_code": "88719, 88634, 88806",
        "kiosk": false
    },
    {
        "value": "753207",
        "label": "Automobile Seatcovers Tops & Upholstery",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81112107",
        "yellow_page_code": "13068, 40393, 05216, 05208, 21316, 05226, 13018, 18927",
        "kiosk": false
    },
    {
        "value": "753208",
        "label": "Van & Truck Conversions & Accessories",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42312078",
        "yellow_page_code": "90530, 90600, 90510",
        "kiosk": false
    },
    {
        "value": "753209",
        "label": "Wheel Chair Van Lifts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81112123",
        "yellow_page_code": "93309",
        "kiosk": false
    },
    {
        "value": "753210",
        "label": "Motorcycles & Motor Scooters-Painting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81112116",
        "yellow_page_code": "54241, 54216, 30133, 54311",
        "kiosk": false
    },
    {
        "value": "753211",
        "label": "Motorcycles-Customizing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81112117",
        "yellow_page_code": "54407",
        "kiosk": false
    },
    {
        "value": "753212",
        "label": "Truck Bodies-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81112119",
        "yellow_page_code": "93326, 88716, 01918, 88703, 88638, 88633",
        "kiosk": false
    },
    {
        "value": "753213",
        "label": "Automobile Pin Striping",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81112105",
        "yellow_page_code": "05016, 65320",
        "kiosk": false
    },
    {
        "value": "753214",
        "label": "Automobile-Wood Refinishing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81112110",
        "yellow_page_code": "05315",
        "kiosk": false
    },
    {
        "value": "753215",
        "label": "Limousine-Conversions",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81112115",
        "yellow_page_code": "47515, 47505",
        "kiosk": false
    },
    {
        "value": "753216",
        "label": "Boat & Yacht Customizing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81112111",
        "yellow_page_code": "08703",
        "kiosk": false
    },
    {
        "value": "753217",
        "label": "Boat Restoration",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81112112",
        "yellow_page_code": "08717",
        "kiosk": false
    },
    {
        "value": "753218",
        "label": "Canvas Goods-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81112114",
        "yellow_page_code": "43714, 83915, 13001",
        "kiosk": false
    },
    {
        "value": "753219",
        "label": "Automobile Beds",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81112101",
        "yellow_page_code": "04602",
        "kiosk": false
    },
    {
        "value": "753220",
        "label": "Automobile-New Car Pre-Delivery Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81112108",
        "yellow_page_code": "04911, 13014",
        "kiosk": false
    },
    {
        "value": "753221",
        "label": "Truck Chassis Alterations & Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81112120",
        "yellow_page_code": "14720, 88704",
        "kiosk": false
    },
    {
        "value": "753222",
        "label": "Stock Cuts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81112118",
        "yellow_page_code": "81412",
        "kiosk": false
    },
    {
        "value": "753223",
        "label": "Bus Conversion",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81112113",
        "yellow_page_code": "11614",
        "kiosk": false
    },
    {
        "value": "753224",
        "label": "Canopies Truck Repair & Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81112124",
        "yellow_page_code": "12837",
        "kiosk": false
    },
    {
        "value": "753225",
        "label": "Automobile Paint Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81112125",
        "yellow_page_code": "05026",
        "kiosk": false
    },
    {
        "value": "753226",
        "label": "Horse Carriages-Build Repair Restore",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81112122",
        "yellow_page_code": "41536",
        "kiosk": false
    },
    {
        "value": "753227",
        "label": "Trailers-Custom Built",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81112126",
        "yellow_page_code": "87910",
        "kiosk": false
    },
    {
        "value": "753228",
        "label": "Vehicle Wraps",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81112127",
        "yellow_page_code": "04550, 88637, 90623",
        "kiosk": false
    },
    {
        "value": "753301",
        "label": "Mufflers & Exhaust Systems-Engine",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111402",
        "yellow_page_code": "05235, 05182, 04539, 30023, 54522, 30118, 54506, 56599",
        "kiosk": false
    },
    {
        "value": "753302",
        "label": "Manifolds",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111401",
        "yellow_page_code": "49818",
        "kiosk": false
    },
    {
        "value": "753401",
        "label": "Tire-Retreading & Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32621201",
        "yellow_page_code": "86403, 86504, 86510, 87694",
        "kiosk": false
    },
    {
        "value": "753402",
        "label": "Tire Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81119818",
        "yellow_page_code": "88918, 86508",
        "kiosk": false
    },
    {
        "value": "753403",
        "label": "Tire Ballast",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81119817",
        "yellow_page_code": "86402",
        "kiosk": false
    },
    {
        "value": "753404",
        "label": "Tire-Customizing & Modification",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81119819",
        "yellow_page_code": "86405",
        "kiosk": false
    },
    {
        "value": "753405",
        "label": "Tire-Studding Destudding & Restudding",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "32621202",
        "yellow_page_code": "86410",
        "kiosk": false
    },
    {
        "value": "753601",
        "label": "Automotive Glass Coating & Tinting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81112203",
        "yellow_page_code": "04427, 86280, 93522, 93612, 37502, 05222, 86281",
        "kiosk": false
    },
    {
        "value": "753602",
        "label": "Automobile Glass-Service & Installation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81112201",
        "yellow_page_code": "43383, 04901, 93711, 93713, 04819, 94518, 05336, 37415, 37412, 04540",
        "kiosk": false
    },
    {
        "value": "753603",
        "label": "Glass-Bullet Proof",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81112204",
        "yellow_page_code": "37520, 37501",
        "kiosk": false
    },
    {
        "value": "753698",
        "label": "Glass Replacement Shops",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81112202",
        "yellow_page_code": "37608",
        "kiosk": false
    },
    {
        "value": "753701",
        "label": "Transmissions-Automobile",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111404",
        "yellow_page_code": "05314, 88324, 88231, 88331, 04425, 88306, 36999",
        "kiosk": false
    },
    {
        "value": "753702",
        "label": "Transmissions-Automobile-Parts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111405",
        "yellow_page_code": "88232, 88218, 88307, 88221",
        "kiosk": false
    },
    {
        "value": "753703",
        "label": "Transmissions-Truck Tractor Etc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111406",
        "yellow_page_code": "88308",
        "kiosk": false
    },
    {
        "value": "753704",
        "label": "Automobile Differentials",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111403",
        "yellow_page_code": "04720, 24735",
        "kiosk": false
    },
    {
        "value": "753705",
        "label": "Gear Box Mfrs & Repairs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111407",
        "yellow_page_code": "36998",
        "kiosk": false
    },
    {
        "value": "753801",
        "label": "Automobile Repairing & Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111104",
        "yellow_page_code": "04420, 50998, 05228, 47510, 05181, 05018, 04813, 89403, 36106, 05202, 04562, 40392, 04546, 04544, 36119, 13042, 05022",
        "kiosk": false
    },
    {
        "value": "753802",
        "label": "Automobile Machine Shop Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111102",
        "yellow_page_code": "43750, 49121, 49119, 04906",
        "kiosk": false
    },
    {
        "value": "753803",
        "label": "Horse-Van-Builders & Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111117",
        "yellow_page_code": "41511",
        "kiosk": false
    },
    {
        "value": "753804",
        "label": "Automobile Motor Rebuilding",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111103",
        "yellow_page_code": "05001, 04619",
        "kiosk": false
    },
    {
        "value": "753805",
        "label": "Cylinders-Rebuilding & Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111107",
        "yellow_page_code": "23111, 23116, 23204",
        "kiosk": false
    },
    {
        "value": "753806",
        "label": "Crankshaft Grinding",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111106",
        "yellow_page_code": "22400",
        "kiosk": false
    },
    {
        "value": "753807",
        "label": "Engines-Diesel-Fuel Injection-Svc & Rpr",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111110",
        "yellow_page_code": "29302, 29327",
        "kiosk": false
    },
    {
        "value": "753808",
        "label": "Engine Tuning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111108",
        "yellow_page_code": "13031, 13030, 28706",
        "kiosk": false
    },
    {
        "value": "753809",
        "label": "Engines-Rebuilding & Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111115",
        "yellow_page_code": "29400, 30282, 29330",
        "kiosk": false
    },
    {
        "value": "753810",
        "label": "Engines-Rebuilding & Exchanging",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111114",
        "yellow_page_code": "54227, 04552, 29331, 02157, 29308, 29326, 04428",
        "kiosk": false
    },
    {
        "value": "753811",
        "label": "Recreational Vehicles-Repairing & Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111119",
        "yellow_page_code": "71007, 01922, 71109, 01923, 71047",
        "kiosk": false
    },
    {
        "value": "753812",
        "label": "Truck-Repairing & Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111124",
        "yellow_page_code": "88718, 88639, 30047, 88711, 88717, 88904",
        "kiosk": false
    },
    {
        "value": "753813",
        "label": "Trailers-Repairing & Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111122",
        "yellow_page_code": "88102, 08628, 30170",
        "kiosk": false
    },
    {
        "value": "753814",
        "label": "Four Wheel Drive-Repairing & Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111116",
        "yellow_page_code": "34311",
        "kiosk": false
    },
    {
        "value": "753815",
        "label": "Engines-Diesel-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111111",
        "yellow_page_code": "24738, 29310, 24739, 29319, 24799, 24798, 24794, 05348, 04551, 29316, 24736",
        "kiosk": false
    },
    {
        "value": "753816",
        "label": "Imported Automobile Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111118",
        "yellow_page_code": "04830, 13034, 42612",
        "kiosk": false
    },
    {
        "value": "753817",
        "label": "Steering Systems & Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111120",
        "yellow_page_code": "81108, 81110",
        "kiosk": false
    },
    {
        "value": "753818",
        "label": "Truck Lubricating Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111123",
        "yellow_page_code": "88804",
        "kiosk": false
    },
    {
        "value": "753819",
        "label": "Automobile Repairing & Service-Mobile",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111105",
        "yellow_page_code": "05180",
        "kiosk": false
    },
    {
        "value": "753820",
        "label": "Autoclaves",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111101",
        "yellow_page_code": "04408",
        "kiosk": false
    },
    {
        "value": "753821",
        "label": "Engines-Diesel-Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111112",
        "yellow_page_code": "29304",
        "kiosk": false
    },
    {
        "value": "753822",
        "label": "Engines-Gasoline-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111113",
        "yellow_page_code": "29317",
        "kiosk": false
    },
    {
        "value": "753823",
        "label": "Engine Turning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111109",
        "yellow_page_code": "28636",
        "kiosk": false
    },
    {
        "value": "753824",
        "label": "Trailers-Camping & Travel-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111121",
        "yellow_page_code": "87912",
        "kiosk": false
    },
    {
        "value": "753825",
        "label": "Automobile Performance/Sports Car Rpr",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111125",
        "yellow_page_code": "05021",
        "kiosk": false
    },
    {
        "value": "753826",
        "label": "Auto Warranty Repair Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111130",
        "yellow_page_code": "04422",
        "kiosk": false
    },
    {
        "value": "753827",
        "label": "Automobile Repair/Svc-Four Wheel Drive",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111126",
        "yellow_page_code": "05111",
        "kiosk": false
    },
    {
        "value": "753828",
        "label": "Machinists-Automotive",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111128",
        "yellow_page_code": "49113, 05346",
        "kiosk": false
    },
    {
        "value": "753831",
        "label": "Battery & Ignition Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111129",
        "yellow_page_code": "04841, 07013",
        "kiosk": false
    },
    {
        "value": "753832",
        "label": "Battery Recharging",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111131",
        "yellow_page_code": "07017",
        "kiosk": false
    },
    {
        "value": "753833",
        "label": "Motorcycles Engine Rebuilding",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111132",
        "yellow_page_code": "52311",
        "kiosk": false
    },
    {
        "value": "753834",
        "label": "Ignition Interlock Devices-Install & Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111127",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "753901",
        "label": "Automobile Radiator Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111414",
        "yellow_page_code": "13039, 05102, 69702, 69718, 69722, 69725, 69727, 69699, 69713, 13040",
        "kiosk": false
    },
    {
        "value": "753902",
        "label": "Automobile Smog Control Devices",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111418",
        "yellow_page_code": "78502, 78437, 05301",
        "kiosk": false
    },
    {
        "value": "753903",
        "label": "Wheel Alignment-Frame & Axle Svc-Auto",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111446",
        "yellow_page_code": "01919, 93304, 05240, 04516, 05221, 01912, 01916, 93317",
        "kiosk": false
    },
    {
        "value": "753904",
        "label": "Starters-Engine",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111441",
        "yellow_page_code": "80608, 29315",
        "kiosk": false
    },
    {
        "value": "753905",
        "label": "Springs-Automotive-Sales & Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111439",
        "yellow_page_code": "80202, 80229, 80210",
        "kiosk": false
    },
    {
        "value": "753906",
        "label": "Fuel Injection Equipment (Repairing)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111430",
        "yellow_page_code": "34824, 34820, 34819, 34814, 34808, 34825, 21127",
        "kiosk": false
    },
    {
        "value": "753907",
        "label": "Fuel-Economizers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111431",
        "yellow_page_code": "34806",
        "kiosk": false
    },
    {
        "value": "753908",
        "label": "Alternators & Generators-Automotive-Rpr",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111408",
        "yellow_page_code": "02019, 02004, 04505, 37101",
        "kiosk": false
    },
    {
        "value": "753909",
        "label": "Motor Homes-Repairing & Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111433",
        "yellow_page_code": "54209",
        "kiosk": false
    },
    {
        "value": "753910",
        "label": "Automobile Frame Straightening",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111411",
        "yellow_page_code": "04808, 34236, 04832",
        "kiosk": false
    },
    {
        "value": "753911",
        "label": "Automobile Electric Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111410",
        "yellow_page_code": "88709, 13057, 04806",
        "kiosk": false
    },
    {
        "value": "753912",
        "label": "Automobile Headlight Adjusting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111413",
        "yellow_page_code": "04902",
        "kiosk": false
    },
    {
        "value": "753913",
        "label": "Automobile Smog Control Inspections",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111417",
        "yellow_page_code": "05107, 78438, 24797, 04528",
        "kiosk": false
    },
    {
        "value": "753914",
        "label": "Brake Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111423",
        "yellow_page_code": "10191, 10209, 04548, 10214, 10210, 30199, 10202, 04828",
        "kiosk": false
    },
    {
        "value": "753915",
        "label": "Axles",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111420",
        "yellow_page_code": "05500",
        "kiosk": false
    },
    {
        "value": "753916",
        "label": "Campers & Pick-Up Coaches-Repair & Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111426",
        "yellow_page_code": "12416, 12506, 13056",
        "kiosk": false
    },
    {
        "value": "753917",
        "label": "Brake Shoe Bonding & Exchanging",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111424",
        "yellow_page_code": "10206",
        "kiosk": false
    },
    {
        "value": "753918",
        "label": "Clutches",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111428",
        "yellow_page_code": "18902, 04549, 18816",
        "kiosk": false
    },
    {
        "value": "753919",
        "label": "Carburetors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111427",
        "yellow_page_code": "13402, 13310, 04415",
        "kiosk": false
    },
    {
        "value": "753920",
        "label": "Spark Plugs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111437",
        "yellow_page_code": "79607",
        "kiosk": false
    },
    {
        "value": "753921",
        "label": "Buses-Repairing & Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111425",
        "yellow_page_code": "11704",
        "kiosk": false
    },
    {
        "value": "753922",
        "label": "Automobile Shock Absorbers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111416",
        "yellow_page_code": "05106",
        "kiosk": false
    },
    {
        "value": "753923",
        "label": "Radiators-Coolants & Solvents",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111434",
        "yellow_page_code": "69705",
        "kiosk": false
    },
    {
        "value": "753924",
        "label": "Engines-Fuel-Conversion",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111429",
        "yellow_page_code": "29303",
        "kiosk": false
    },
    {
        "value": "753925",
        "label": "Radiators-Industrial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111435",
        "yellow_page_code": "69706",
        "kiosk": false
    },
    {
        "value": "753926",
        "label": "Automobile Repair/Svc Facilities-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111415",
        "yellow_page_code": "05012",
        "kiosk": false
    },
    {
        "value": "753927",
        "label": "Balancing Service-Automotive",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111421",
        "yellow_page_code": "06100",
        "kiosk": false
    },
    {
        "value": "753928",
        "label": "Smoke Abatement Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111436",
        "yellow_page_code": "78505, 78503",
        "kiosk": false
    },
    {
        "value": "753929",
        "label": "Automobile Fuel Conversion",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111412",
        "yellow_page_code": "04817",
        "kiosk": false
    },
    {
        "value": "753930",
        "label": "Brake Linings",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111422",
        "yellow_page_code": "10203, 10205",
        "kiosk": false
    },
    {
        "value": "753931",
        "label": "Gas Tanks-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111432",
        "yellow_page_code": "36716, 34811",
        "kiosk": false
    },
    {
        "value": "753932",
        "label": "Automobile Suspensions",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111419",
        "yellow_page_code": "05126, 04607",
        "kiosk": false
    },
    {
        "value": "753933",
        "label": "Automobile Clutch Rebuilding",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111409",
        "yellow_page_code": "04615",
        "kiosk": false
    },
    {
        "value": "753934",
        "label": "Speedometers-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111438",
        "yellow_page_code": "79807",
        "kiosk": false
    },
    {
        "value": "753935",
        "label": "Starters-Automotive-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111440",
        "yellow_page_code": "80610",
        "kiosk": false
    },
    {
        "value": "753936",
        "label": "Steering Systems & Equipment-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111442",
        "yellow_page_code": "81109",
        "kiosk": false
    },
    {
        "value": "753937",
        "label": "Struts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111443",
        "yellow_page_code": "82013",
        "kiosk": false
    },
    {
        "value": "753938",
        "label": "Wheel Suspension Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111447",
        "yellow_page_code": "93313",
        "kiosk": false
    },
    {
        "value": "753939",
        "label": "Truck Air Conditioning Equip-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111444",
        "yellow_page_code": "88612",
        "kiosk": false
    },
    {
        "value": "753940",
        "label": "Air Bags Parts & Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111449",
        "yellow_page_code": "04434",
        "kiosk": false
    },
    {
        "value": "753941",
        "label": "Battery Charging Equipment-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111450",
        "yellow_page_code": "07103",
        "kiosk": false
    },
    {
        "value": "753949",
        "label": "Wheel & Rim Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111445",
        "yellow_page_code": "93325, 93328",
        "kiosk": false
    },
    {
        "value": "753950",
        "label": "Automotive Interior Leak Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111448",
        "yellow_page_code": "05322, 04433, 04515",
        "kiosk": false
    },
    {
        "value": "753951",
        "label": "Automobile Computer Equipment & Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111451",
        "yellow_page_code": "04510",
        "kiosk": false
    },
    {
        "value": "753952",
        "label": "Automobile Interior Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81119811",
        "yellow_page_code": "04512",
        "kiosk": false
    },
    {
        "value": "753953",
        "label": "Specialized Automotive Repair ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81111452",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "754201",
        "label": "Car Washing & Polishing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81119208",
        "yellow_page_code": "13102, 54245, 05309, 13105",
        "kiosk": false
    },
    {
        "value": "754202",
        "label": "Steam Cleaning-Automotive",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81119205",
        "yellow_page_code": "80802",
        "kiosk": false
    },
    {
        "value": "754203",
        "label": "Automobile Detail & Clean-Up Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81119201",
        "yellow_page_code": "04837, 04709, 30166",
        "kiosk": false
    },
    {
        "value": "754204",
        "label": "Automobile Upholstery Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81119202",
        "yellow_page_code": "05306",
        "kiosk": false
    },
    {
        "value": "754205",
        "label": "Car Washing & Polishing-Coin Operated",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81119203",
        "yellow_page_code": "13108",
        "kiosk": false
    },
    {
        "value": "754206",
        "label": "Truck-Washing & Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81119206",
        "yellow_page_code": "89000",
        "kiosk": false
    },
    {
        "value": "754207",
        "label": "Livestock Trailers-Washing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81119204",
        "yellow_page_code": "48306",
        "kiosk": false
    },
    {
        "value": "754901",
        "label": "Wrecker Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48841006",
        "yellow_page_code": "13060, 10339, 70950, 54312, 56473, 94506, 04630, 87517, 54218, 40926, 87505, 87438, 87504",
        "kiosk": false
    },
    {
        "value": "754902",
        "label": "Automobile Undercoating & Rustproofing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81119806",
        "yellow_page_code": "73302, 89819, 04925, 73206, 89817, 05304",
        "kiosk": false
    },
    {
        "value": "754903",
        "label": "Automobile Lubrication Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81119101",
        "yellow_page_code": "04905, 05009, 56428, 04825, 04914, 57001",
        "kiosk": false
    },
    {
        "value": "754904",
        "label": "Roadside Assistance",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48841001",
        "yellow_page_code": "04542, 72408, 05213, 28424, 72331, 53216, 72410, 05116, 86511",
        "kiosk": false
    },
    {
        "value": "754905",
        "label": "Towing-Truck",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48841002",
        "yellow_page_code": "87507, 88915, 19924",
        "kiosk": false
    },
    {
        "value": "754906",
        "label": "Lubricating Service-Mobile",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81119102",
        "yellow_page_code": "48708, 48723, 48722",
        "kiosk": false
    },
    {
        "value": "754907",
        "label": "Automobile Diagnostic Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81119802",
        "yellow_page_code": "24416, 04802, 05414",
        "kiosk": false
    },
    {
        "value": "754908",
        "label": "Automobile Inspection Stations-New/Used",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81119804",
        "yellow_page_code": "73324, 04904, 88715, 04903, 88623, 04829, 78440, 80621",
        "kiosk": false
    },
    {
        "value": "754909",
        "label": "Automobile-Striping Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81119807",
        "yellow_page_code": "05303",
        "kiosk": false
    },
    {
        "value": "754910",
        "label": "Automobile Transporters & Drive-Away Co",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81119805",
        "yellow_page_code": "88910, 13080, 05300",
        "kiosk": false
    },
    {
        "value": "754912",
        "label": "Rustproofing-Industrial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81119816",
        "yellow_page_code": "73301",
        "kiosk": false
    },
    {
        "value": "754913",
        "label": "Window Tinting Materials",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81119822",
        "yellow_page_code": "93601",
        "kiosk": false
    },
    {
        "value": "754914",
        "label": "Vehicle Inspection Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81119821",
        "yellow_page_code": "90634",
        "kiosk": false
    },
    {
        "value": "754915",
        "label": "Motorcycle Inspection Stations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81119813",
        "yellow_page_code": "54301",
        "kiosk": false
    },
    {
        "value": "754916",
        "label": "Recreational Vehicles-Transporting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81119815",
        "yellow_page_code": "71012",
        "kiosk": false
    },
    {
        "value": "754917",
        "label": "Automotive Information Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81119809",
        "yellow_page_code": "05319",
        "kiosk": false
    },
    {
        "value": "754918",
        "label": "Automobile Emission Testing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81119803",
        "yellow_page_code": "04824, 28535, 28510",
        "kiosk": false
    },
    {
        "value": "754919",
        "label": "Automobile Appearance & Protection",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81119801",
        "yellow_page_code": "04509",
        "kiosk": false
    },
    {
        "value": "754920",
        "label": "Carbon Petroleum",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81119812",
        "yellow_page_code": "13304",
        "kiosk": false
    },
    {
        "value": "754921",
        "label": "Automotive Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81119808",
        "yellow_page_code": "05310",
        "kiosk": false
    },
    {
        "value": "754922",
        "label": "Vehicle Emission Testing-Svc & Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81119820",
        "yellow_page_code": "28529, 90603, 04527",
        "kiosk": false
    },
    {
        "value": "754923",
        "label": "Winch Truck Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48841005",
        "yellow_page_code": "93510",
        "kiosk": false
    },
    {
        "value": "754924",
        "label": "Towing-Truck & Automotive-Referral Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48841003",
        "yellow_page_code": "87510",
        "kiosk": false
    },
    {
        "value": "754925",
        "label": "Tractor-Wreckers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48841004",
        "yellow_page_code": "87705",
        "kiosk": false
    },
    {
        "value": "754926",
        "label": "Motorcycles-Transporting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81119814",
        "yellow_page_code": "54247",
        "kiosk": false
    },
    {
        "value": "754927",
        "label": "Towing Motorhomes",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48841007",
        "yellow_page_code": "87518",
        "kiosk": false
    },
    {
        "value": "754928",
        "label": "Engine Diagnostic Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81119823",
        "yellow_page_code": "29321",
        "kiosk": false
    },
    {
        "value": "754929",
        "label": "Automobile Impound Retrieval Svcs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48841008",
        "yellow_page_code": "04534",
        "kiosk": false
    },
    {
        "value": "754999",
        "label": "Automotive Svcs-Except Rpr & Car Washes",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81119810",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "762201",
        "label": "Speakers-Rebuilding & Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121010",
        "yellow_page_code": "48811, 84600, 79800",
        "kiosk": false
    },
    {
        "value": "762202",
        "label": "Television & Radio-Service/Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121013",
        "yellow_page_code": "84508, 84617, 84620, 84618, 84615, 69825, 69804",
        "kiosk": false
    },
    {
        "value": "762203",
        "label": "Automobile Radio/Stereo Systs-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121004",
        "yellow_page_code": "05103",
        "kiosk": false
    },
    {
        "value": "762204",
        "label": "Audio-Visual Equipment-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121003",
        "yellow_page_code": "04298, 04306",
        "kiosk": false
    },
    {
        "value": "762205",
        "label": "Satellite Installation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23829029",
        "yellow_page_code": "24854, 73927",
        "kiosk": false
    },
    {
        "value": "762206",
        "label": "Tape Recorders/Players-Sound-Svc & Rpr",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121012",
        "yellow_page_code": "83707, 83709",
        "kiosk": false
    },
    {
        "value": "762207",
        "label": "Stereophonic & High Fidelity Equip-Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121011",
        "yellow_page_code": "81235, 79507, 81301, 41033, 41031, 81218",
        "kiosk": false
    },
    {
        "value": "762209",
        "label": "Stereo Equipment Service & Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121002",
        "yellow_page_code": "81238, 81312",
        "kiosk": false
    },
    {
        "value": "762210",
        "label": "Video Recorders & Players-Service/Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121016",
        "yellow_page_code": "91102",
        "kiosk": false
    },
    {
        "value": "762211",
        "label": "Video Games-Service & Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121015",
        "yellow_page_code": "91117",
        "kiosk": false
    },
    {
        "value": "762212",
        "label": "Video Equipment-Service & Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121014",
        "yellow_page_code": "91104, 91215, 91124, 70812, 26982, 91024, 91040, 91031, 91053, 91129",
        "kiosk": false
    },
    {
        "value": "762213",
        "label": "Satellite Equip & Systems-Svc & Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121009",
        "yellow_page_code": "84611, 43686, 39618, 84610, 12124, 73906",
        "kiosk": false
    },
    {
        "value": "762214",
        "label": "Aircraft Radio Servicing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121038",
        "yellow_page_code": "01515, 01607",
        "kiosk": false
    },
    {
        "value": "762216",
        "label": "Radio Comm Equip & Systems-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121008",
        "yellow_page_code": "69900, 70005",
        "kiosk": false
    },
    {
        "value": "762301",
        "label": "Water Coolers Fountains/Filters-Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131041",
        "yellow_page_code": "92203",
        "kiosk": false
    },
    {
        "value": "762302",
        "label": "Refrigerators & Freezers-Service/Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81141216",
        "yellow_page_code": "34513, 71210, 71440, 71309, 34324, 71504",
        "kiosk": false
    },
    {
        "value": "762303",
        "label": "Refrigerating Equip-Commercial-Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131032",
        "yellow_page_code": "71410, 71400",
        "kiosk": false
    },
    {
        "value": "762304",
        "label": "Air Conditioning Equipment-Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131001",
        "yellow_page_code": "00913, 00910, 01002, 00907",
        "kiosk": false
    },
    {
        "value": "762305",
        "label": "Ice Making Equipment & Machs-Rpr & Clean",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131020",
        "yellow_page_code": "42505, 42517, 42416, 42499",
        "kiosk": false
    },
    {
        "value": "762306",
        "label": "Air Conditioning Systems-Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131045",
        "yellow_page_code": "01102, 00917",
        "kiosk": false
    },
    {
        "value": "762307",
        "label": "Air Conditioning Equipment-Room Units-Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81141229",
        "yellow_page_code": "01009",
        "kiosk": false
    },
    {
        "value": "762308",
        "label": "Truck Refrigeration Equipment-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131039",
        "yellow_page_code": "88901",
        "kiosk": false
    },
    {
        "value": "762309",
        "label": "Air Conditioning & Heating-Service/Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81141227",
        "yellow_page_code": "01014",
        "kiosk": false
    },
    {
        "value": "762398",
        "label": "Refrigeration & Air Cond-Service & Rpr",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131033",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "762901",
        "label": "Appliances-Household-Small-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81141202",
        "yellow_page_code": "03001, 27400, 02931",
        "kiosk": false
    },
    {
        "value": "762902",
        "label": "Appliances-Household-Major-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81141201",
        "yellow_page_code": "44929, 27309, 02919, 02929, 02932, 03003",
        "kiosk": false
    },
    {
        "value": "762903",
        "label": "Electric Equipment-Service & Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121042",
        "yellow_page_code": "27803, 28114, 27600, 56313, 56310",
        "kiosk": false
    },
    {
        "value": "762904",
        "label": "Washing Machines Dryers/Ironers-Svc/Rpr",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81141222",
        "yellow_page_code": "91700, 26709",
        "kiosk": false
    },
    {
        "value": "762905",
        "label": "Bakers Equipment-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81141203",
        "yellow_page_code": "05909",
        "kiosk": false
    },
    {
        "value": "762906",
        "label": "Dishwashing Machines-Service & Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81141206",
        "yellow_page_code": "25002",
        "kiosk": false
    },
    {
        "value": "762907",
        "label": "Compactors-Waste-Service & Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81141205",
        "yellow_page_code": "20202, 88422",
        "kiosk": false
    },
    {
        "value": "762908",
        "label": "Electronic Equipment & Supplies-Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121006",
        "yellow_page_code": "28040, 28008, 28113, 24850",
        "kiosk": false
    },
    {
        "value": "762909",
        "label": "Floor Machines-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81141208",
        "yellow_page_code": "33308",
        "kiosk": false
    },
    {
        "value": "762910",
        "label": "Fans-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81141207",
        "yellow_page_code": "30708",
        "kiosk": false
    },
    {
        "value": "762911",
        "label": "Hearing Aids-Parts & Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121007",
        "yellow_page_code": "40650, 40601, 40546, 40549",
        "kiosk": false
    },
    {
        "value": "762912",
        "label": "Garbage Disposal Equipment-Svc & Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81141209",
        "yellow_page_code": "36200, 41945",
        "kiosk": false
    },
    {
        "value": "762913",
        "label": "Lamps-Mounting & Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121047",
        "yellow_page_code": "45623, 45624, 45706, 45613",
        "kiosk": false
    },
    {
        "value": "762914",
        "label": "Heaters-Unit-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81141210",
        "yellow_page_code": "40809",
        "kiosk": false
    },
    {
        "value": "762915",
        "label": "Lighting Fixtures-Repairing & Mntnc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121048",
        "yellow_page_code": "47315, 47308",
        "kiosk": false
    },
    {
        "value": "762916",
        "label": "Laundry Equipment-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81141212",
        "yellow_page_code": "18311, 46205, 18401, 46110",
        "kiosk": false
    },
    {
        "value": "762917",
        "label": "Marine Electric Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121050",
        "yellow_page_code": "50009, 50030",
        "kiosk": false
    },
    {
        "value": "762918",
        "label": "Lighting-Maintenance Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121049",
        "yellow_page_code": "47406",
        "kiosk": false
    },
    {
        "value": "762919",
        "label": "Microwave Ovens-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81141213",
        "yellow_page_code": "52302",
        "kiosk": false
    },
    {
        "value": "762920",
        "label": "Microwave Service & Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81141214",
        "yellow_page_code": "52301",
        "kiosk": false
    },
    {
        "value": "762921",
        "label": "Restaurant Equipment-Repairing & Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121028",
        "yellow_page_code": "71908",
        "kiosk": false
    },
    {
        "value": "762922",
        "label": "Ranges & Stoves-Service & Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81141215",
        "yellow_page_code": "70406",
        "kiosk": false
    },
    {
        "value": "762923",
        "label": "Telephone Equipment & Systems-Svc/Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121037",
        "yellow_page_code": "84244, 84405, 84238",
        "kiosk": false
    },
    {
        "value": "762924",
        "label": "Shavers-Electric-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81141218",
        "yellow_page_code": "76106",
        "kiosk": false
    },
    {
        "value": "762925",
        "label": "Vacuum Cleaners-Repairing & Parts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81141220",
        "yellow_page_code": "90400, 90313, 90305, 90401",
        "kiosk": false
    },
    {
        "value": "762926",
        "label": "Tools-Electric-Repairing & Parts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81141219",
        "yellow_page_code": "87103",
        "kiosk": false
    },
    {
        "value": "762927",
        "label": "Water Heaters-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81141223",
        "yellow_page_code": "92300",
        "kiosk": false
    },
    {
        "value": "762928",
        "label": "Water Softening Equipment Svc & Supls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81141224",
        "yellow_page_code": "92122, 30049, 79214, 92500, 92128",
        "kiosk": false
    },
    {
        "value": "762929",
        "label": "Telephone Auto Answer Equip-Servicing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121036",
        "yellow_page_code": "84309, 84305",
        "kiosk": false
    },
    {
        "value": "762931",
        "label": "Cellular Telephones-Service & Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121035",
        "yellow_page_code": "78425, 14304",
        "kiosk": false
    },
    {
        "value": "762932",
        "label": "Coffee Brewing Devices-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81141204",
        "yellow_page_code": "19301",
        "kiosk": false
    },
    {
        "value": "762933",
        "label": "Vacuum Cleaning Systems-Repair & Parts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81141221",
        "yellow_page_code": "90409",
        "kiosk": false
    },
    {
        "value": "762934",
        "label": "Copying Machines-Service & Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121023",
        "yellow_page_code": "26901, 21505, 52421, 21522",
        "kiosk": false
    },
    {
        "value": "762936",
        "label": "Facsimile-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121026",
        "yellow_page_code": "31023, 30511, 31019",
        "kiosk": false
    },
    {
        "value": "762937",
        "label": "Street Lighting Standards",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121029",
        "yellow_page_code": "82003",
        "kiosk": false
    },
    {
        "value": "762938",
        "label": "Data Communications Systems-Svc/Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121040",
        "yellow_page_code": "23410",
        "kiosk": false
    },
    {
        "value": "762939",
        "label": "Controls Control Systems/Regulators-Rpr",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121039",
        "yellow_page_code": "21315",
        "kiosk": false
    },
    {
        "value": "762940",
        "label": "Power Plant Equipment-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121027",
        "yellow_page_code": "67908",
        "kiosk": false
    },
    {
        "value": "762941",
        "label": "Power Transmission Equipment-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121053",
        "yellow_page_code": "67935",
        "kiosk": false
    },
    {
        "value": "762942",
        "label": "Dictating Machines-Service & Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121025",
        "yellow_page_code": "24610",
        "kiosk": false
    },
    {
        "value": "762943",
        "label": "Blueprinting Equipment-Repairing & Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121018",
        "yellow_page_code": "08537",
        "kiosk": false
    },
    {
        "value": "762944",
        "label": "Marine Electronic Equip-Repairing & Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121051",
        "yellow_page_code": "50019",
        "kiosk": false
    },
    {
        "value": "762945",
        "label": "Electric Equipment-Testing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121043",
        "yellow_page_code": "27601",
        "kiosk": false
    },
    {
        "value": "762946",
        "label": "Electronic Circuit Boards-Testing/Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121045",
        "yellow_page_code": "28021",
        "kiosk": false
    },
    {
        "value": "762947",
        "label": "Toner Cartridge Recycling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121017",
        "yellow_page_code": "86912, 86911, 20271, 43129",
        "kiosk": false
    },
    {
        "value": "762948",
        "label": "Casino Equipment-Service & Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81141225",
        "yellow_page_code": "13937",
        "kiosk": false
    },
    {
        "value": "762949",
        "label": "Trash Compactors-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81141226",
        "yellow_page_code": "88410",
        "kiosk": false
    },
    {
        "value": "762950",
        "label": "Service Contracts & Agreements",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121001",
        "yellow_page_code": "75716",
        "kiosk": false
    },
    {
        "value": "762951",
        "label": "Games Electronic Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121005",
        "yellow_page_code": "36033",
        "kiosk": false
    },
    {
        "value": "762952",
        "label": "Water Heaters Cleaning Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81141228",
        "yellow_page_code": "92029",
        "kiosk": false
    },
    {
        "value": "762953",
        "label": "A T M Dealers-Service & Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121057",
        "yellow_page_code": "04424, 04207",
        "kiosk": false
    },
    {
        "value": "762999",
        "label": "Electrical & Electronic Repair Shops NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121044",
        "yellow_page_code": "27828",
        "kiosk": false
    },
    {
        "value": "763101",
        "label": "Jewelry-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149023",
        "yellow_page_code": "44408",
        "kiosk": false
    },
    {
        "value": "763102",
        "label": "Watches-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149056",
        "yellow_page_code": "92004",
        "kiosk": false
    },
    {
        "value": "763103",
        "label": "Clocks-Repairing & Parts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149009",
        "yellow_page_code": "18611, 18606",
        "kiosk": false
    },
    {
        "value": "763104",
        "label": "Bead Stringing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149003",
        "yellow_page_code": "07009",
        "kiosk": false
    },
    {
        "value": "763105",
        "label": "Pearl Goods",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149031",
        "yellow_page_code": "60011",
        "kiosk": false
    },
    {
        "value": "763106",
        "label": "Watch Inspectors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149055",
        "yellow_page_code": "91910",
        "kiosk": false
    },
    {
        "value": "763198",
        "label": "Watch Clock & Jewelry-Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149054",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "764101",
        "label": "Caning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81142002",
        "yellow_page_code": "12810, 12805",
        "kiosk": false
    },
    {
        "value": "764102",
        "label": "Furniture-Outdoor-Recovering & Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81142005",
        "yellow_page_code": "35803, 35814",
        "kiosk": false
    },
    {
        "value": "764103",
        "label": "Cushions (Upholstery Service)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81142004",
        "yellow_page_code": "22908",
        "kiosk": false
    },
    {
        "value": "764104",
        "label": "Furniture-Stripping",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81142007",
        "yellow_page_code": "35900, 65318",
        "kiosk": false
    },
    {
        "value": "764105",
        "label": "Furniture-Repairing & Refinishing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81142006",
        "yellow_page_code": "30183, 35705, 35806, 93498, 43739, 71215",
        "kiosk": false
    },
    {
        "value": "764106",
        "label": "Office Furniture & Equip-Rpr & Refinish",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81142010",
        "yellow_page_code": "56302, 56217",
        "kiosk": false
    },
    {
        "value": "764108",
        "label": "Vinyl-Repair Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81142014",
        "yellow_page_code": "91106",
        "kiosk": false
    },
    {
        "value": "764109",
        "label": "Upholsterers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81142013",
        "yellow_page_code": "75230, 90037, 90036, 90102, 50032, 50053, 05231, 35718, 59711, 90038, 35714",
        "kiosk": false
    },
    {
        "value": "764110",
        "label": "Water Beds-Service & Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81142015",
        "yellow_page_code": "92009",
        "kiosk": false
    },
    {
        "value": "764111",
        "label": "Tufting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81142012",
        "yellow_page_code": "89309",
        "kiosk": false
    },
    {
        "value": "764112",
        "label": "Antiques-Repairing & Restoring",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81142001",
        "yellow_page_code": "30173, 02718, 02804",
        "kiosk": false
    },
    {
        "value": "764113",
        "label": "Chair Caning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81142003",
        "yellow_page_code": "14609",
        "kiosk": false
    },
    {
        "value": "764114",
        "label": "Marquetry",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81142009",
        "yellow_page_code": "50207",
        "kiosk": false
    },
    {
        "value": "764115",
        "label": "Woodwork Refinishing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81142016",
        "yellow_page_code": "94304",
        "kiosk": false
    },
    {
        "value": "764116",
        "label": "Truck-Seat Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81142011",
        "yellow_page_code": "88903",
        "kiosk": false
    },
    {
        "value": "764117",
        "label": "Furniture Polishers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81142017",
        "yellow_page_code": "34502, 34503, 35818",
        "kiosk": false
    },
    {
        "value": "764118",
        "label": "Doors Refinishing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81142018",
        "yellow_page_code": "25643, 25715",
        "kiosk": false
    },
    {
        "value": "764119",
        "label": "Aviation Upholsterers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81142019",
        "yellow_page_code": "05340",
        "kiosk": false
    },
    {
        "value": "769201",
        "label": "Welding Equipment-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149059",
        "yellow_page_code": "93108",
        "kiosk": false
    },
    {
        "value": "769202",
        "label": "Welding-Industrial & Commercial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149061",
        "yellow_page_code": "93101",
        "kiosk": false
    },
    {
        "value": "769203",
        "label": "Welding",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149058",
        "yellow_page_code": "93112, 24713, 56478, 80040, 86915, 02172, 93114, 02098, 93100, 29888",
        "kiosk": false
    },
    {
        "value": "769205",
        "label": "Welding-Household/Misc Items-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149060",
        "yellow_page_code": "93204",
        "kiosk": false
    },
    {
        "value": "769206",
        "label": "Brazing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149005",
        "yellow_page_code": "10400",
        "kiosk": false
    },
    {
        "value": "769207",
        "label": "Radiators-Industrial-Rebuilding/Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149040",
        "yellow_page_code": "69721",
        "kiosk": false
    },
    {
        "value": "769208",
        "label": "Castings-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149007",
        "yellow_page_code": "14012",
        "kiosk": false
    },
    {
        "value": "769209",
        "label": "Steel Flame Cutting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149047",
        "yellow_page_code": "81014, 80921",
        "kiosk": false
    },
    {
        "value": "769210",
        "label": "Plastic Welding",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149037",
        "yellow_page_code": "66212",
        "kiosk": false
    },
    {
        "value": "769211",
        "label": "Wire Mesh Work",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149062",
        "yellow_page_code": "94003",
        "kiosk": false
    },
    {
        "value": "769212",
        "label": "Hard Facing Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131044",
        "yellow_page_code": "40122",
        "kiosk": false
    },
    {
        "value": "769222",
        "label": "Ranges & Ovens-Service & Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149041",
        "yellow_page_code": "58199, 70411",
        "kiosk": false
    },
    {
        "value": "769401",
        "label": "Coil Winding",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131004",
        "yellow_page_code": "19400",
        "kiosk": false
    },
    {
        "value": "769402",
        "label": "Armatures-Repairing & Rewinding",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131002",
        "yellow_page_code": "03308",
        "kiosk": false
    },
    {
        "value": "769403",
        "label": "Outboard Motors-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33531213",
        "yellow_page_code": "58106, 58120",
        "kiosk": false
    },
    {
        "value": "769404",
        "label": "Generators-Electric-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33531209",
        "yellow_page_code": "37118, 37011, 37103",
        "kiosk": false
    },
    {
        "value": "769405",
        "label": "Hermetic Motor Rewinding",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33531210",
        "yellow_page_code": "40950",
        "kiosk": false
    },
    {
        "value": "769901",
        "label": "Photographic Equipment-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149032",
        "yellow_page_code": "24731, 12409, 53908, 61102, 61015, 12415",
        "kiosk": false
    },
    {
        "value": "769902",
        "label": "Plumbing Drain & Sewer Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56299102",
        "yellow_page_code": "25806, 30251, 67923, 66806",
        "kiosk": false
    },
    {
        "value": "769903",
        "label": "Racquets-Restringing & Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149039",
        "yellow_page_code": "85106, 69601",
        "kiosk": false
    },
    {
        "value": "769904",
        "label": "Taxidermists",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11521017",
        "yellow_page_code": "36036, 54402, 84200, 84161, 41030, 02795",
        "kiosk": false
    },
    {
        "value": "769905",
        "label": "Hot Tubs & Spas-Service & Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81141211",
        "yellow_page_code": "79608, 41901, 79618",
        "kiosk": false
    },
    {
        "value": "769906",
        "label": "Blacksmiths",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33211108",
        "yellow_page_code": "41602, 31012, 08402",
        "kiosk": false
    },
    {
        "value": "769907",
        "label": "Water Pressure Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81141108",
        "yellow_page_code": "18415, 92308, 92323",
        "kiosk": false
    },
    {
        "value": "769908",
        "label": "Hoists-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131018",
        "yellow_page_code": "41108, 41117",
        "kiosk": false
    },
    {
        "value": "769909",
        "label": "Trucks-Industrial-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131040",
        "yellow_page_code": "34126, 47216, 74290, 89205, 34119",
        "kiosk": false
    },
    {
        "value": "769910",
        "label": "Optical Goods-Service & Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149029",
        "yellow_page_code": "57601",
        "kiosk": false
    },
    {
        "value": "769911",
        "label": "Wheel Chairs-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121055",
        "yellow_page_code": "93311",
        "kiosk": false
    },
    {
        "value": "769912",
        "label": "Organs-Tuning & Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149030",
        "yellow_page_code": "57908",
        "kiosk": false
    },
    {
        "value": "769913",
        "label": "Pianos-Tuning Repairing & Refinishing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149033",
        "yellow_page_code": "64906",
        "kiosk": false
    },
    {
        "value": "769914",
        "label": "Tile & Grout Cleaning & Restoration",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149052",
        "yellow_page_code": "14414, 86009, 86013, 86014, 86016, 30267, 88428",
        "kiosk": false
    },
    {
        "value": "769915",
        "label": "Picture Framing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "44912979",
        "yellow_page_code": "22917, 34314, 65110, 65102",
        "kiosk": false
    },
    {
        "value": "769916",
        "label": "Picture Frames-Restoring & Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81141102",
        "yellow_page_code": "65008, 34317",
        "kiosk": false
    },
    {
        "value": "769917",
        "label": "Picture Restoring",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149035",
        "yellow_page_code": "60811, 65106, 60710, 61112",
        "kiosk": false
    },
    {
        "value": "769918",
        "label": "Cranes-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131008",
        "yellow_page_code": "22324, 22308, 22322",
        "kiosk": false
    },
    {
        "value": "769919",
        "label": "Pipe Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131029",
        "yellow_page_code": "65500",
        "kiosk": false
    },
    {
        "value": "769920",
        "label": "Pumps-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131031",
        "yellow_page_code": "69204, 60548, 69117",
        "kiosk": false
    },
    {
        "value": "769921",
        "label": "Printing Equipment-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131030",
        "yellow_page_code": "68323, 68311, 68315, 68404, 20248, 47012, 89613, 67947, 68334, 68321",
        "kiosk": false
    },
    {
        "value": "769922",
        "label": "Safes & Vaults-Opening & Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56162205",
        "yellow_page_code": "73391, 73410, 73400",
        "kiosk": false
    },
    {
        "value": "769923",
        "label": "Scales-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121054",
        "yellow_page_code": "74306",
        "kiosk": false
    },
    {
        "value": "769924",
        "label": "Saws-Sharpening & Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81141103",
        "yellow_page_code": "74300",
        "kiosk": false
    },
    {
        "value": "769925",
        "label": "Sewing Machines-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81141217",
        "yellow_page_code": "75916, 30365, 76000",
        "kiosk": false
    },
    {
        "value": "769926",
        "label": "Screens-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149043",
        "yellow_page_code": "74908, 74810, 74910",
        "kiosk": false
    },
    {
        "value": "769927",
        "label": "Silverware Cleaning/Repairing/Replating",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149046",
        "yellow_page_code": "77801, 77802",
        "kiosk": false
    },
    {
        "value": "769928",
        "label": "Sharpening Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81141105",
        "yellow_page_code": "45010, 76100, 39014",
        "kiosk": false
    },
    {
        "value": "769929",
        "label": "Snowmobile-Repairing & Maintenance",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81141106",
        "yellow_page_code": "78708, 78627",
        "kiosk": false
    },
    {
        "value": "769930",
        "label": "Skate Sharpening",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911074",
        "yellow_page_code": "77906",
        "kiosk": false
    },
    {
        "value": "769931",
        "label": "Hospital Equip-Repairing & Refinishing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121046",
        "yellow_page_code": "41723, 41706, 43315",
        "kiosk": false
    },
    {
        "value": "769932",
        "label": "Solar Energy Equip & Systems-Svc & Rpr",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131034",
        "yellow_page_code": "79310, 79322, 79209, 79220",
        "kiosk": false
    },
    {
        "value": "769933",
        "label": "Sporting Goods-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911054",
        "yellow_page_code": "80049, 88123, 79939, 18404, 80002, 82331",
        "kiosk": false
    },
    {
        "value": "769934",
        "label": "Tank Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131035",
        "yellow_page_code": "83500, 83512, 83517",
        "kiosk": false
    },
    {
        "value": "769935",
        "label": "Mirrors-Resilvering",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149027",
        "yellow_page_code": "37523, 52706",
        "kiosk": false
    },
    {
        "value": "769936",
        "label": "Tool Grinding-Industrial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131037",
        "yellow_page_code": "87000",
        "kiosk": false
    },
    {
        "value": "769937",
        "label": "Mattresses-Renovating",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149026",
        "yellow_page_code": "50606, 50520",
        "kiosk": false
    },
    {
        "value": "769938",
        "label": "Power Tool Supplies & Repairs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81141107",
        "yellow_page_code": "87110, 87204, 67434, 67931",
        "kiosk": false
    },
    {
        "value": "769939",
        "label": "Industrial Equipment & Supls-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131021",
        "yellow_page_code": "69125, 42902",
        "kiosk": false
    },
    {
        "value": "769940",
        "label": "Typewriters-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121030",
        "yellow_page_code": "89801",
        "kiosk": false
    },
    {
        "value": "769941",
        "label": "Tractor-Repairing & Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131038",
        "yellow_page_code": "87708, 88628, 87695, 87691, 87719",
        "kiosk": false
    },
    {
        "value": "769942",
        "label": "Farm Equipment-Repairing & Parts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131011",
        "yellow_page_code": "30808, 30810, 24832, 77705",
        "kiosk": false
    },
    {
        "value": "769943",
        "label": "Valves-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149053",
        "yellow_page_code": "90507, 90513",
        "kiosk": false
    },
    {
        "value": "769944",
        "label": "Fix-It Shops",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149015",
        "yellow_page_code": "71709, 56454, 29711, 32800",
        "kiosk": false
    },
    {
        "value": "769945",
        "label": "Fiber Glass-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131012",
        "yellow_page_code": "31508, 31510, 31520",
        "kiosk": false
    },
    {
        "value": "769946",
        "label": "Skiing Equipment-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911047",
        "yellow_page_code": "78107",
        "kiosk": false
    },
    {
        "value": "769947",
        "label": "Chinaware & Glassware-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149008",
        "yellow_page_code": "15402, 15399, 15318, 22821, 22804",
        "kiosk": false
    },
    {
        "value": "769948",
        "label": "Golf Equipment-Repairing & Refinishing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911022",
        "yellow_page_code": "38209",
        "kiosk": false
    },
    {
        "value": "769949",
        "label": "Heating Equipment & Systems-Repair & Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131016",
        "yellow_page_code": "40905, 35505, 14372",
        "kiosk": false
    },
    {
        "value": "769950",
        "label": "Handbags-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149019",
        "yellow_page_code": "39906, 69308",
        "kiosk": false
    },
    {
        "value": "769951",
        "label": "Beauty Supplies & Equipment-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131003",
        "yellow_page_code": "07212, 07301",
        "kiosk": false
    },
    {
        "value": "769952",
        "label": "Hydraulic Equipment-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131019",
        "yellow_page_code": "42200",
        "kiosk": false
    },
    {
        "value": "769953",
        "label": "Dairy Equipment & Supplies-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131009",
        "yellow_page_code": "23302",
        "kiosk": false
    },
    {
        "value": "769954",
        "label": "Picture Hanging Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149034",
        "yellow_page_code": "65104",
        "kiosk": false
    },
    {
        "value": "769955",
        "label": "Gas Appliances-Servicing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131013",
        "yellow_page_code": "36502",
        "kiosk": false
    },
    {
        "value": "769956",
        "label": "Jacks-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131022",
        "yellow_page_code": "44100",
        "kiosk": false
    },
    {
        "value": "769957",
        "label": "Lawn Mowers-Sharpening & Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81141101",
        "yellow_page_code": "46308",
        "kiosk": false
    },
    {
        "value": "769958",
        "label": "Keys",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56162203",
        "yellow_page_code": "44808",
        "kiosk": false
    },
    {
        "value": "769959",
        "label": "Leather Goods-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81143004",
        "yellow_page_code": "46605, 46708",
        "kiosk": false
    },
    {
        "value": "769960",
        "label": "Leather Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81143003",
        "yellow_page_code": "46613, 18730, 46604",
        "kiosk": false
    },
    {
        "value": "769961",
        "label": "Key Makers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56162202",
        "yellow_page_code": "44807, 44805, 44815",
        "kiosk": false
    },
    {
        "value": "769962",
        "label": "Locks & Locksmiths",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56162204",
        "yellow_page_code": "48505, 04541, 25645, 41357, 48408",
        "kiosk": false
    },
    {
        "value": "769963",
        "label": "Medical Equipment-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121052",
        "yellow_page_code": "51100, 85715, 61301",
        "kiosk": false
    },
    {
        "value": "769964",
        "label": "Maps-Mounting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149025",
        "yellow_page_code": "49906",
        "kiosk": false
    },
    {
        "value": "769965",
        "label": "Heavy Equipment-Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131017",
        "yellow_page_code": "49132, 40915, 29742",
        "kiosk": false
    },
    {
        "value": "769966",
        "label": "Meters-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131026",
        "yellow_page_code": "92330, 52104",
        "kiosk": false
    },
    {
        "value": "769967",
        "label": "Motorcycles & Motor Scooters-Rpr & Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131027",
        "yellow_page_code": "29959, 54300",
        "kiosk": false
    },
    {
        "value": "769968",
        "label": "Oil Burners-Servicing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131028",
        "yellow_page_code": "56501",
        "kiosk": false
    },
    {
        "value": "769969",
        "label": "Musical Instruments-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149028",
        "yellow_page_code": "54908, 64810, 54917",
        "kiosk": false
    },
    {
        "value": "769970",
        "label": "Cesspool Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56299101",
        "yellow_page_code": "14508, 14498",
        "kiosk": false
    },
    {
        "value": "769971",
        "label": "X-Ray Apparatus-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121056",
        "yellow_page_code": "94604",
        "kiosk": false
    },
    {
        "value": "769972",
        "label": "Textile Restoration",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151050",
        "yellow_page_code": "85415",
        "kiosk": false
    },
    {
        "value": "769973",
        "label": "Appliances-Household-Refinishing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149001",
        "yellow_page_code": "03000, 03025",
        "kiosk": false
    },
    {
        "value": "769974",
        "label": "Bicycles-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "45911008",
        "yellow_page_code": "23132, 08004",
        "kiosk": false
    },
    {
        "value": "769975",
        "label": "Engines-Marine-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131010",
        "yellow_page_code": "29314, 08612, 50005, 08823",
        "kiosk": false
    },
    {
        "value": "769976",
        "label": "Material Handling Equipment-Svc & Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131025",
        "yellow_page_code": "50407",
        "kiosk": false
    },
    {
        "value": "769977",
        "label": "Contractors-Equipment & Supls-Repair",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131006",
        "yellow_page_code": "21201",
        "kiosk": false
    },
    {
        "value": "769978",
        "label": "Compressors-Air & Gas-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131005",
        "yellow_page_code": "20250, 20306, 89440, 00890",
        "kiosk": false
    },
    {
        "value": "769979",
        "label": "Gas Equipment-Servicing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131014",
        "yellow_page_code": "36606",
        "kiosk": false
    },
    {
        "value": "769980",
        "label": "Cranes-Inspection & Testing Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131007",
        "yellow_page_code": "22403",
        "kiosk": false
    },
    {
        "value": "769981",
        "label": "Dolls-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149010",
        "yellow_page_code": "87520, 25408, 25427",
        "kiosk": false
    },
    {
        "value": "769982",
        "label": "Dental Equipment-Repairing & Refinishing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81121041",
        "yellow_page_code": "23902, 30060",
        "kiosk": false
    },
    {
        "value": "769983",
        "label": "Doors-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149012",
        "yellow_page_code": "25620, 25702, 25633, 18620, 25646, 25634, 25538",
        "kiosk": false
    },
    {
        "value": "769984",
        "label": "Door Closers & Checks-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149011",
        "yellow_page_code": "25600",
        "kiosk": false
    },
    {
        "value": "769985",
        "label": "Bathtubs & Sinks-Repairing & Refinishing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81149002",
        "yellow_page_code": "41396, 07003",
        "kiosk": false
    },
    {
        "value": "769986",
        "label": "Grease Traps (Cleaning Service)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56179001",
        "yellow_page_code": "38900, 38917, 38916, 38910",
        "kiosk": false
    },
    {
        "value": "769987",
        "label": "Automobile Lock-Out Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56162201",
        "yellow_page_code": "48420, 04924",
        "kiosk": false
    },
    {
        "value": "769988",
        "label": "Tank Lining & Coating",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131036",
        "yellow_page_code": "83504",
        "kiosk": false
    },
    {
        "value": "769989",
        "label": "Machinery-Cleaning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131023",
        "yellow_page_code": "49204",
        "kiosk": false
    },
    {
        "value": "769990",
        "label": "Gauges-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131015",
        "yellow_page_code": "36905",
        "kiosk": false
    },
    {
        "value": "769991",
        "label": "Key Duplicating Service Kiosks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56162206",
        "yellow_page_code": "",
        "kiosk": true
    },
    {
        "value": "769992",
        "label": "Pipe & Leak Locating",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822038",
        "yellow_page_code": "46322, 65407",
        "kiosk": false
    },
    {
        "value": "769993",
        "label": "Engines-Small-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81141104",
        "yellow_page_code": "78413, 29401",
        "kiosk": false
    },
    {
        "value": "769994",
        "label": "Propellers-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48839010",
        "yellow_page_code": "68616, 68629",
        "kiosk": false
    },
    {
        "value": "769995",
        "label": "Calculating/Adding Machs & Supls-Svc/Rpr",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42342037",
        "yellow_page_code": "12400",
        "kiosk": false
    },
    {
        "value": "769996",
        "label": "Instruments-Industrial-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822042",
        "yellow_page_code": "43326, 43305, 28030, 43328",
        "kiosk": false
    },
    {
        "value": "769997",
        "label": "Tools-Pneumatic-Repairing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822043",
        "yellow_page_code": "87201",
        "kiosk": false
    },
    {
        "value": "769998",
        "label": "Soldering Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23822044",
        "yellow_page_code": "79308",
        "kiosk": false
    },
    {
        "value": "769999",
        "label": "Repair Shops & Related Services NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81131043",
        "yellow_page_code": "68075, 88016, 77101, 21433, 73705, 32718, 80235, 77319, 83712, 59106, 31112, 23640, 84007, 90932, 88626, 07514, 44229, 14227, 45843, 32818, 20144, 58595, 78011, 06512, 54310, 38605, 03118, 39514, 23716, 82418, 73319, 84119, 66214, 75438, 04513, 38610, 93345, 01308, 02204, 07509, 07601, 07701, 07903, 14507, 17903, 18005, 20607, 25507, 30012, 35200, 40703, 40707, 42807, 44001, 44002, 45001, 45405, 49507, 61205, 67309, 69208, 74703, 77334, 83904, 84707, 87603, 75502, 05512, 46313, 52705, 91122, 08101, 36212, 78704, 10605, 38101, 46402, 82513, 85203, 90801, 39336, 84111, 37311, 89816, 66407, 09305, 86736, 36718, 82801, 19314, 52535, 52213, 04711, 83612",
        "kiosk": false
    },
    {
        "value": "781201",
        "label": "Audio-Visual Creative Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51211002",
        "yellow_page_code": "04301",
        "kiosk": false
    },
    {
        "value": "781202",
        "label": "Commercials-Radio & Television",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51211004",
        "yellow_page_code": "20002",
        "kiosk": false
    },
    {
        "value": "781203",
        "label": "Audio-Visual Production Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51211003",
        "yellow_page_code": "04308, 04315",
        "kiosk": false
    },
    {
        "value": "781204",
        "label": "Motion Picture Projection Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51211009",
        "yellow_page_code": "54104",
        "kiosk": false
    },
    {
        "value": "781205",
        "label": "Motion Picture Producers & Studios",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51211008",
        "yellow_page_code": "31620, 53810, 53811, 60794, 54102, 54021, 54017",
        "kiosk": false
    },
    {
        "value": "781206",
        "label": "Television-Films Producers & Distrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51211014",
        "yellow_page_code": "84704",
        "kiosk": false
    },
    {
        "value": "781207",
        "label": "Motion Picture Sound Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51211010",
        "yellow_page_code": "54108",
        "kiosk": false
    },
    {
        "value": "781208",
        "label": "Film Studio Production Facilities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51211005",
        "yellow_page_code": "68525, 31614, 79491, 30098, 24214",
        "kiosk": false
    },
    {
        "value": "781209",
        "label": "Television-Tape Producers & Studios",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51211015",
        "yellow_page_code": "84903",
        "kiosk": false
    },
    {
        "value": "781210",
        "label": "Photographers-Insurance Inventory",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51211018",
        "yellow_page_code": "61029, 43398, 61090",
        "kiosk": false
    },
    {
        "value": "781211",
        "label": "Video Production & Taping Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51211016",
        "yellow_page_code": "91108, 91049, 24732, 93047, 91032, 59548",
        "kiosk": false
    },
    {
        "value": "781212",
        "label": "Animated Film Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51211001",
        "yellow_page_code": "02609, 02611, 02603, 02613",
        "kiosk": false
    },
    {
        "value": "781213",
        "label": "Photographers-Underwater",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51211012",
        "yellow_page_code": "61103",
        "kiosk": false
    },
    {
        "value": "781214",
        "label": "Press-Photo Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51211013",
        "yellow_page_code": "68014, 60797",
        "kiosk": false
    },
    {
        "value": "781215",
        "label": "Motion Picture Film-Dubbing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51211007",
        "yellow_page_code": "54012",
        "kiosk": false
    },
    {
        "value": "781216",
        "label": "Motion Picture Stock Shots",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51211011",
        "yellow_page_code": "54011",
        "kiosk": false
    },
    {
        "value": "781217",
        "label": "Production Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51211017",
        "yellow_page_code": "68529",
        "kiosk": false
    },
    {
        "value": "781218",
        "label": "Dvd Production & Duplication-Coml & Indl",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51211006",
        "yellow_page_code": "26979",
        "kiosk": false
    },
    {
        "value": "781901",
        "label": "Video Tape Editing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51219110",
        "yellow_page_code": "91130, 91028, 91047",
        "kiosk": false
    },
    {
        "value": "781902",
        "label": "Video Tape Duplication Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33461011",
        "yellow_page_code": "04330, 91048, 91036, 91105",
        "kiosk": false
    },
    {
        "value": "781903",
        "label": "Studio Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51219903",
        "yellow_page_code": "82106",
        "kiosk": false
    },
    {
        "value": "781904",
        "label": "Motion Picture Film-Editing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51219102",
        "yellow_page_code": "54002",
        "kiosk": false
    },
    {
        "value": "781905",
        "label": "Motion Picture Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151010",
        "yellow_page_code": "53904",
        "kiosk": false
    },
    {
        "value": "781906",
        "label": "Motion Picture Representatives",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151012",
        "yellow_page_code": "54106",
        "kiosk": false
    },
    {
        "value": "781907",
        "label": "Motion Picture Laboratories",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51219105",
        "yellow_page_code": "54008",
        "kiosk": false
    },
    {
        "value": "781908",
        "label": "Audio-Visual Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151001",
        "yellow_page_code": "04313, 30092, 04317, 90229, 04303",
        "kiosk": false
    },
    {
        "value": "781909",
        "label": "Motion Picture Special Effects",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151013",
        "yellow_page_code": "54105",
        "kiosk": false
    },
    {
        "value": "781910",
        "label": "Sound System Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151017",
        "yellow_page_code": "79515, 79504",
        "kiosk": false
    },
    {
        "value": "781911",
        "label": "Motion Picture Location Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151011",
        "yellow_page_code": "54100",
        "kiosk": false
    },
    {
        "value": "781912",
        "label": "Television Program Producers-Svcs/Supls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51219111",
        "yellow_page_code": "84709",
        "kiosk": false
    },
    {
        "value": "781913",
        "label": "Casting Directors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151002",
        "yellow_page_code": "14019, 13917",
        "kiosk": false
    },
    {
        "value": "781914",
        "label": "Motion Picture Research Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51219107",
        "yellow_page_code": "54103",
        "kiosk": false
    },
    {
        "value": "781915",
        "label": "Motion Picture Technicians",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151014",
        "yellow_page_code": "54109",
        "kiosk": false
    },
    {
        "value": "781916",
        "label": "Closed Captioning Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51219101",
        "yellow_page_code": "18615",
        "kiosk": false
    },
    {
        "value": "781917",
        "label": "Motion Picture Casting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151009",
        "yellow_page_code": "53903",
        "kiosk": false
    },
    {
        "value": "781918",
        "label": "Motion Picture Servicing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51219108",
        "yellow_page_code": "54003",
        "kiosk": false
    },
    {
        "value": "781919",
        "label": "Titling Service-TV & Motion Picture",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51219109",
        "yellow_page_code": "86608",
        "kiosk": false
    },
    {
        "value": "781920",
        "label": "Motion Picture Film-Edit Fclts-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53249007",
        "yellow_page_code": "54013",
        "kiosk": false
    },
    {
        "value": "781921",
        "label": "Motion Picture Film-Music Scoring",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51219104",
        "yellow_page_code": "54014",
        "kiosk": false
    },
    {
        "value": "781922",
        "label": "Motion Picture Film-Inspctn-Cleaning/Etc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51219103",
        "yellow_page_code": "54015",
        "kiosk": false
    },
    {
        "value": "781923",
        "label": "Sound Effect Libraries",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151016",
        "yellow_page_code": "79490",
        "kiosk": false
    },
    {
        "value": "781924",
        "label": "Motion Picture Screening Rooms",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51219905",
        "yellow_page_code": "53812",
        "kiosk": false
    },
    {
        "value": "781925",
        "label": "Computer Animation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51219904",
        "yellow_page_code": "20215",
        "kiosk": false
    },
    {
        "value": "781926",
        "label": "Engineers Audio",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51219906",
        "yellow_page_code": "28781",
        "kiosk": false
    },
    {
        "value": "781927",
        "label": "Film & Slide Transfer Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51219907",
        "yellow_page_code": "31619, 31618",
        "kiosk": false
    },
    {
        "value": "781928",
        "label": "Disk & Tape Conversions",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51219106",
        "yellow_page_code": "24995",
        "kiosk": false
    },
    {
        "value": "781929",
        "label": "Teleproduction & Postproduction Services ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51219112",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "781998",
        "label": "Services Allied To Motion Pictures",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51219902",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "782201",
        "label": "Television Program Distributors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51212004",
        "yellow_page_code": "84706",
        "kiosk": false
    },
    {
        "value": "782202",
        "label": "Motion Picture Film-Distrs & Exchs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51212001",
        "yellow_page_code": "54000, 54018, 31617, 26983",
        "kiosk": false
    },
    {
        "value": "782203",
        "label": "Newsreels",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51212003",
        "yellow_page_code": "55509",
        "kiosk": false
    },
    {
        "value": "782901",
        "label": "Motion Picture Film-Libraries",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51212002",
        "yellow_page_code": "54004",
        "kiosk": false
    },
    {
        "value": "782902",
        "label": "Motion Picture Film-Delivery",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51219901",
        "yellow_page_code": "54005",
        "kiosk": false
    },
    {
        "value": "783201",
        "label": "Theatres-Movie",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51213101",
        "yellow_page_code": "17907, 85504, 85502, 85499, 54412, 59546",
        "kiosk": false
    },
    {
        "value": "783202",
        "label": "Festival Events",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51213102",
        "yellow_page_code": "31414",
        "kiosk": false
    },
    {
        "value": "783301",
        "label": "Drive-In Motion Picture Theaters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51213202",
        "yellow_page_code": "54414, 85512, 26506",
        "kiosk": false
    },
    {
        "value": "784101",
        "label": "Video Games-Renting & Leasing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228204",
        "yellow_page_code": "91109, 36026, 21366, 91060",
        "kiosk": false
    },
    {
        "value": "784102",
        "label": "Video Tapes & Discs-Renting & Leasing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228205",
        "yellow_page_code": "91027, 91107, 91143, 91037, 27007, 91034, 54411, 85023",
        "kiosk": false
    },
    {
        "value": "784103",
        "label": "Video Disc & Tape Equipment & Supls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228203",
        "yellow_page_code": "91120",
        "kiosk": false
    },
    {
        "value": "784104",
        "label": "Records-Phonograph-Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228202",
        "yellow_page_code": "70915",
        "kiosk": false
    },
    {
        "value": "784105",
        "label": "Nintendo-Rentals",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228201",
        "yellow_page_code": "55512",
        "kiosk": false
    },
    {
        "value": "784107",
        "label": "Video Tapes Adult Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228206",
        "yellow_page_code": "91136, 91066",
        "kiosk": false
    },
    {
        "value": "784108",
        "label": "Video Rental Kiosks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228207",
        "yellow_page_code": "91127",
        "kiosk": true
    },
    {
        "value": "791101",
        "label": "Dancing Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61161011",
        "yellow_page_code": "74411, 23308, 23318, 23319, 92971, 23323, 06115, 23330, 23316, 23310, 23325",
        "kiosk": false
    },
    {
        "value": "791102",
        "label": "Ballrooms",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399005",
        "yellow_page_code": "06204, 92998",
        "kiosk": false
    },
    {
        "value": "791103",
        "label": "Square Dance Callers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399047",
        "yellow_page_code": "80313, 80305, 80306",
        "kiosk": false
    },
    {
        "value": "791104",
        "label": "Hula Instructions",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61161003",
        "yellow_page_code": "42011",
        "kiosk": false
    },
    {
        "value": "791105",
        "label": "Square Dancing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71112001",
        "yellow_page_code": "80308",
        "kiosk": false
    },
    {
        "value": "791106",
        "label": "Dancing-Public",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399003",
        "yellow_page_code": "23331",
        "kiosk": false
    },
    {
        "value": "791107",
        "label": "Square Dance Information",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61161012",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "792201",
        "label": "Music Societies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71111008",
        "yellow_page_code": "54812",
        "kiosk": false
    },
    {
        "value": "792202",
        "label": "Opera Companies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71111002",
        "yellow_page_code": "57506",
        "kiosk": false
    },
    {
        "value": "792203",
        "label": "Scenery Studios",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51229005",
        "yellow_page_code": "74400",
        "kiosk": false
    },
    {
        "value": "792204",
        "label": "Radio Program Producers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51229002",
        "yellow_page_code": "69803, 69829",
        "kiosk": false
    },
    {
        "value": "792205",
        "label": "Television Program Producers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151018",
        "yellow_page_code": "77321, 84708, 84623",
        "kiosk": false
    },
    {
        "value": "792206",
        "label": "Talent Agencies & Casting Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56131120",
        "yellow_page_code": "68602, 83411, 83410, 83405",
        "kiosk": false
    },
    {
        "value": "792207",
        "label": "Theatres-Live",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71111007",
        "yellow_page_code": "85505, 66615, 85519, 85515, 85514, 85507",
        "kiosk": false
    },
    {
        "value": "792208",
        "label": "Television Station Representatives",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71141008",
        "yellow_page_code": "84807",
        "kiosk": false
    },
    {
        "value": "792209",
        "label": "Theatrical Make-Up",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71141012",
        "yellow_page_code": "85602",
        "kiosk": false
    },
    {
        "value": "792210",
        "label": "Theatrical Agencies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71141011",
        "yellow_page_code": "85508, 09309",
        "kiosk": false
    },
    {
        "value": "792211",
        "label": "Theatrical Managers & Producers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71141013",
        "yellow_page_code": "85604, 85509",
        "kiosk": false
    },
    {
        "value": "792212",
        "label": "Theatrical Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53249013",
        "yellow_page_code": "29863, 68630, 85600, 78506, 85520",
        "kiosk": false
    },
    {
        "value": "792213",
        "label": "Concert Venues",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71131001",
        "yellow_page_code": "20385, 20401",
        "kiosk": false
    },
    {
        "value": "792214",
        "label": "Choreographers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71141001",
        "yellow_page_code": "15602",
        "kiosk": false
    },
    {
        "value": "792215",
        "label": "Dance Companies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71112002",
        "yellow_page_code": "23307, 06110",
        "kiosk": false
    },
    {
        "value": "792216",
        "label": "Costume Designers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71141002",
        "yellow_page_code": "21809",
        "kiosk": false
    },
    {
        "value": "792217",
        "label": "Entertainment Producers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71141003",
        "yellow_page_code": "29603",
        "kiosk": false
    },
    {
        "value": "792218",
        "label": "Theatre Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71141010",
        "yellow_page_code": "85500",
        "kiosk": false
    },
    {
        "value": "792219",
        "label": "Contest Arranging & Judging",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71132001",
        "yellow_page_code": "21115",
        "kiosk": false
    },
    {
        "value": "792220",
        "label": "Theatre Parties-Arranged",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71111005",
        "yellow_page_code": "85501",
        "kiosk": false
    },
    {
        "value": "792221",
        "label": "Music Composers' Agents",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71141004",
        "yellow_page_code": "54903",
        "kiosk": false
    },
    {
        "value": "792222",
        "label": "Stage Lighting Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71111003",
        "yellow_page_code": "80415",
        "kiosk": false
    },
    {
        "value": "792223",
        "label": "Television-Mobile Production Vehicles",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71141009",
        "yellow_page_code": "84607",
        "kiosk": false
    },
    {
        "value": "792224",
        "label": "TV Program Contestant & Ticket Info",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71132003",
        "yellow_page_code": "84800",
        "kiosk": false
    },
    {
        "value": "792225",
        "label": "Plays & Play Brokers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71141005",
        "yellow_page_code": "66605",
        "kiosk": false
    },
    {
        "value": "792226",
        "label": "Theatrical Talent Inventory Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71141014",
        "yellow_page_code": "85603",
        "kiosk": false
    },
    {
        "value": "792227",
        "label": "Entertainers-Adult",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151026",
        "yellow_page_code": "29626, 67004, 29617, 00398",
        "kiosk": false
    },
    {
        "value": "792228",
        "label": "Stuntmen",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71141007",
        "yellow_page_code": "82050",
        "kiosk": false
    },
    {
        "value": "792229",
        "label": "Theatre & Stage Sets-Design/Construction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71111004",
        "yellow_page_code": "85518, 85517",
        "kiosk": false
    },
    {
        "value": "792230",
        "label": "Pageants-Production Training & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53249015",
        "yellow_page_code": "58499",
        "kiosk": false
    },
    {
        "value": "792231",
        "label": "Musical Productions",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51229007",
        "yellow_page_code": "54911",
        "kiosk": false
    },
    {
        "value": "792232",
        "label": "Mariachis",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71111009",
        "yellow_page_code": "49858",
        "kiosk": false
    },
    {
        "value": "792233",
        "label": "Performing Arts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71111010",
        "yellow_page_code": "60216, 03443",
        "kiosk": false
    },
    {
        "value": "792234",
        "label": "Amphitheaters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71111011",
        "yellow_page_code": "02295, 85521",
        "kiosk": false
    },
    {
        "value": "792235",
        "label": "Agents & Managers-Artists Athletes Entertainers ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71141015",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "792236",
        "label": "Theater Companies & Dinner Theaters ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71111001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "792237",
        "label": "Promoters of Events with Facilities ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71131006",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "792901",
        "label": "Orchestras & Bands",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71113002",
        "yellow_page_code": "92967, 06318, 06306, 43753, 93070, 57804, 82915, 93053",
        "kiosk": false
    },
    {
        "value": "792902",
        "label": "Singing Telegrams",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71119015",
        "yellow_page_code": "77808, 84209, 84231, 44840",
        "kiosk": false
    },
    {
        "value": "792903",
        "label": "Music-Entertainment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71113003",
        "yellow_page_code": "54717",
        "kiosk": false
    },
    {
        "value": "792904",
        "label": "Magicians",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151008",
        "yellow_page_code": "49404",
        "kiosk": false
    },
    {
        "value": "792905",
        "label": "Entertainment Bureaus",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71119007",
        "yellow_page_code": "29608, 29604, 21722, 29642, 29622, 59528, 54716, 20400, 06421",
        "kiosk": false
    },
    {
        "value": "792906",
        "label": "Musicians",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151015",
        "yellow_page_code": "92915, 55004, 92956",
        "kiosk": false
    },
    {
        "value": "792907",
        "label": "Clowns",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151003",
        "yellow_page_code": "18808, 59527",
        "kiosk": false
    },
    {
        "value": "792908",
        "label": "Music & Live Entertainment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151004",
        "yellow_page_code": "59535, 54720, 48008, 29641, 92954, 24900, 54704",
        "kiosk": false
    },
    {
        "value": "792909",
        "label": "Music Shows",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71119012",
        "yellow_page_code": "54801, 54918, 54703",
        "kiosk": false
    },
    {
        "value": "792910",
        "label": "Mobile Discotheques",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71119011",
        "yellow_page_code": "53013",
        "kiosk": false
    },
    {
        "value": "792911",
        "label": "Music Recorded Dance",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71119013",
        "yellow_page_code": "54712, 54805",
        "kiosk": false
    },
    {
        "value": "792912",
        "label": "Jugglers & Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71119016",
        "yellow_page_code": "44518",
        "kiosk": false
    },
    {
        "value": "792913",
        "label": "Karaoke",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71119009",
        "yellow_page_code": "44696, 93073",
        "kiosk": false
    },
    {
        "value": "792914",
        "label": "Celebrity Brokers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71119003",
        "yellow_page_code": "14207",
        "kiosk": false
    },
    {
        "value": "792915",
        "label": "Educational Performers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71119006",
        "yellow_page_code": "27212",
        "kiosk": false
    },
    {
        "value": "792916",
        "label": "Drum Corps",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71113001",
        "yellow_page_code": "26612",
        "kiosk": false
    },
    {
        "value": "792917",
        "label": "Entertainers-Children & Family",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151006",
        "yellow_page_code": "15223, 81890, 15130, 73820, 59534, 29625",
        "kiosk": false
    },
    {
        "value": "792918",
        "label": "Entertainers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151005",
        "yellow_page_code": "59520, 29602",
        "kiosk": false
    },
    {
        "value": "792919",
        "label": "Entertainers-Family & Business",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151020",
        "yellow_page_code": "29631",
        "kiosk": false
    },
    {
        "value": "792920",
        "label": "Ventriloquists",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151021",
        "yellow_page_code": "90815",
        "kiosk": false
    },
    {
        "value": "792921",
        "label": "Bag Pipers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151024",
        "yellow_page_code": "05801",
        "kiosk": false
    },
    {
        "value": "792922",
        "label": "Comedians",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151025",
        "yellow_page_code": "19910",
        "kiosk": false
    },
    {
        "value": "792923",
        "label": "Moonwalks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71113004",
        "yellow_page_code": "59536, 44589, 79601, 53580, 10010, 10012, 53616",
        "kiosk": false
    },
    {
        "value": "792924",
        "label": "Choral Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71113005",
        "yellow_page_code": "15601",
        "kiosk": false
    },
    {
        "value": "792925",
        "label": "Vocalist",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71113006",
        "yellow_page_code": "91247",
        "kiosk": false
    },
    {
        "value": "792926",
        "label": "Pianists",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71113007",
        "yellow_page_code": "64812",
        "kiosk": false
    },
    {
        "value": "792927",
        "label": "Entertainment Shows & Parties",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71119017",
        "yellow_page_code": "29607",
        "kiosk": false
    },
    {
        "value": "792928",
        "label": "Performing Arts Companies ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71119019",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "792999",
        "label": "Kids Entertainment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71119010",
        "yellow_page_code": "44830",
        "kiosk": false
    },
    {
        "value": "793301",
        "label": "Bowling Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71395001",
        "yellow_page_code": "09703, 85026, 59541, 09702",
        "kiosk": false
    },
    {
        "value": "794101",
        "label": "Sports Promoters Managers & Recruiters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71132002",
        "yellow_page_code": "80029, 80250, 66596, 80100",
        "kiosk": false
    },
    {
        "value": "794102",
        "label": "Soccer Clubs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71121104",
        "yellow_page_code": "78917, 78908",
        "kiosk": false
    },
    {
        "value": "794103",
        "label": "Basketball Clubs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71121101",
        "yellow_page_code": "06819, 06806, 06899",
        "kiosk": false
    },
    {
        "value": "794104",
        "label": "Stadiums Arenas & Athletic Fields",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71131003",
        "yellow_page_code": "03310, 78916, 03216, 80408, 04108, 34017, 80031",
        "kiosk": false
    },
    {
        "value": "794105",
        "label": "Football Clubs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71121102",
        "yellow_page_code": "34006",
        "kiosk": false
    },
    {
        "value": "794106",
        "label": "Recruiting-Sports",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71121903",
        "yellow_page_code": "71026",
        "kiosk": false
    },
    {
        "value": "794107",
        "label": "Jai-Alai Frontons",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71131002",
        "yellow_page_code": "44105",
        "kiosk": false
    },
    {
        "value": "794108",
        "label": "Sports Teams",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71121105",
        "yellow_page_code": "80110",
        "kiosk": false
    },
    {
        "value": "794109",
        "label": "Polo Clubs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71121106",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "794110",
        "label": "Sports-Indoor",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71121107",
        "yellow_page_code": "80046",
        "kiosk": false
    },
    {
        "value": "794111",
        "label": "Spectator Sports ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71121906",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "794198",
        "label": "Professional Sports Clubs & Promoters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71121103",
        "yellow_page_code": "68619, 67130",
        "kiosk": false
    },
    {
        "value": "794801",
        "label": "Race Tracks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71121203",
        "yellow_page_code": "69615, 25812, 69600, 05007, 80067, 69524, 54231, 54223",
        "kiosk": false
    },
    {
        "value": "794802",
        "label": "Race Track Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71121201",
        "yellow_page_code": "69505",
        "kiosk": false
    },
    {
        "value": "794803",
        "label": "Horse Racing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71121202",
        "yellow_page_code": "41518",
        "kiosk": false
    },
    {
        "value": "794804",
        "label": "Motorcross Facilities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71121204",
        "yellow_page_code": "54221",
        "kiosk": false
    },
    {
        "value": "794805",
        "label": "Dog Tracks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71121905",
        "yellow_page_code": "39122, 25389",
        "kiosk": false
    },
    {
        "value": "799101",
        "label": "Health Clubs Studios & Gymnasiums",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71394011",
        "yellow_page_code": "40424, 00709, 32707, 40500, 61200, 86902, 00722, 18896, 40520, 61310, 61311, 32715, 75220, 44719, 22695",
        "kiosk": false
    },
    {
        "value": "799102",
        "label": "Gymnasiums",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71394009",
        "yellow_page_code": "39502, 18515, 39510, 18510",
        "kiosk": false
    },
    {
        "value": "799104",
        "label": "Alexander Technique",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71394002",
        "yellow_page_code": "01915",
        "kiosk": false
    },
    {
        "value": "799105",
        "label": "Health Spas",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71394017",
        "yellow_page_code": "51051, 79614, 40545",
        "kiosk": false
    },
    {
        "value": "799106",
        "label": "Personal Trainers-Fitness",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71394013",
        "yellow_page_code": "40612, 60317, 32714, 61308",
        "kiosk": false
    },
    {
        "value": "799107",
        "label": "Pilates",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71394023",
        "yellow_page_code": "65215, 65212, 30020",
        "kiosk": false
    },
    {
        "value": "799108",
        "label": "Children's Fitness",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71394024",
        "yellow_page_code": "15226",
        "kiosk": false
    },
    {
        "value": "799109",
        "label": "Senior Citizen Fitness",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71394016",
        "yellow_page_code": "75439",
        "kiosk": false
    },
    {
        "value": "799110",
        "label": "Medical Fitness Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71394029",
        "yellow_page_code": "51056",
        "kiosk": false
    },
    {
        "value": "799111",
        "label": "Sports & Recreation Facilities Program",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71394030",
        "yellow_page_code": "80045, 80012",
        "kiosk": false
    },
    {
        "value": "799112",
        "label": "Health & Fitness Center Management",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71394031",
        "yellow_page_code": "40451",
        "kiosk": false
    },
    {
        "value": "799201",
        "label": "Golf Courses",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71391002",
        "yellow_page_code": "38204, 38113, 38202, 38215, 38111, 38205, 38109, 38116, 38124, 80071",
        "kiosk": false
    },
    {
        "value": "799202",
        "label": "Putting Greens",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71391004",
        "yellow_page_code": "69210",
        "kiosk": false
    },
    {
        "value": "799301",
        "label": "Amusement Devices",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71312003",
        "yellow_page_code": "02212, 02210, 34615, 02302, 02208",
        "kiosk": false
    },
    {
        "value": "799302",
        "label": "Casinos",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71321001",
        "yellow_page_code": "13909, 36025, 13934",
        "kiosk": false
    },
    {
        "value": "799303",
        "label": "Arcades",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71312001",
        "yellow_page_code": "03109",
        "kiosk": false
    },
    {
        "value": "799304",
        "label": "Coin Machines & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399012",
        "yellow_page_code": "19507",
        "kiosk": false
    },
    {
        "value": "799305",
        "label": "Video Gamerooms",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71312002",
        "yellow_page_code": "91112, 91055",
        "kiosk": false
    },
    {
        "value": "799306",
        "label": "Video Games-Supplies & Parts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399052",
        "yellow_page_code": "91116",
        "kiosk": false
    },
    {
        "value": "799307",
        "label": "Family Entertainment Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399001",
        "yellow_page_code": "30606, 30617",
        "kiosk": false
    },
    {
        "value": "799308",
        "label": "Pinball Machines",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399034",
        "yellow_page_code": "65312",
        "kiosk": false
    },
    {
        "value": "799309",
        "label": "Racing Simulators",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71312004",
        "yellow_page_code": "69521",
        "kiosk": false
    },
    {
        "value": "799310",
        "label": "Slot Machines-Sales & Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71329004",
        "yellow_page_code": "78318",
        "kiosk": false
    },
    {
        "value": "799311",
        "label": "Video Poker",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71329005",
        "yellow_page_code": "91125",
        "kiosk": false
    },
    {
        "value": "799312",
        "label": "Gaming Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71329006",
        "yellow_page_code": "45712, 36009",
        "kiosk": false
    },
    {
        "value": "799601",
        "label": "Amusement Places",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71311001",
        "yellow_page_code": "41130, 59540, 02306, 02310",
        "kiosk": false
    },
    {
        "value": "799602",
        "label": "Water Parks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71311002",
        "yellow_page_code": "59419, 92126",
        "kiosk": false
    },
    {
        "value": "799603",
        "label": "Haunted Houses",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71311003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "799604",
        "label": "Amusement & Theme Parks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71311004",
        "yellow_page_code": "85529",
        "kiosk": false
    },
    {
        "value": "799605",
        "label": "Corn Maze",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71311005",
        "yellow_page_code": "21723",
        "kiosk": false
    },
    {
        "value": "799606",
        "label": "Amusement/Water Park Ride/Attraction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71311006",
        "yellow_page_code": "92312, 02305",
        "kiosk": false
    },
    {
        "value": "799607",
        "label": "Laser Tag",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399060",
        "yellow_page_code": "46030, 46025",
        "kiosk": false
    },
    {
        "value": "799608",
        "label": "Trampoline Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399061",
        "yellow_page_code": "88206",
        "kiosk": false
    },
    {
        "value": "799609",
        "label": "Escape Rooms",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399062",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "799701",
        "label": "Recreation Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71394015",
        "yellow_page_code": "46910, 71019, 71002, 00231",
        "kiosk": false
    },
    {
        "value": "799702",
        "label": "Racquetball Courts-Private",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71394014",
        "yellow_page_code": "69606, 69610",
        "kiosk": false
    },
    {
        "value": "799703",
        "label": "Tennis Courts-Private",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71394021",
        "yellow_page_code": "85010, 85008, 85018",
        "kiosk": false
    },
    {
        "value": "799704",
        "label": "Swimming Pools-Private",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71394019",
        "yellow_page_code": "82902",
        "kiosk": false
    },
    {
        "value": "799705",
        "label": "Hockey Clubs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71394012",
        "yellow_page_code": "42310, 41038, 41097, 41101",
        "kiosk": false
    },
    {
        "value": "799707",
        "label": "Curling Clubs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71394008",
        "yellow_page_code": "22906, 22913",
        "kiosk": false
    },
    {
        "value": "799708",
        "label": "Sports Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399006",
        "yellow_page_code": "79111, 06821, 80028, 54530, 06804, 06802, 06798, 06818, 80014",
        "kiosk": false
    },
    {
        "value": "799709",
        "label": "Fishing Lakes-Private",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399017",
        "yellow_page_code": "32705",
        "kiosk": false
    },
    {
        "value": "799710",
        "label": "Skiing Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71392002",
        "yellow_page_code": "78201",
        "kiosk": false
    },
    {
        "value": "799711",
        "label": "Squash Courts-Private",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71394018",
        "yellow_page_code": "80309",
        "kiosk": false
    },
    {
        "value": "799712",
        "label": "Flying Clubs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399004",
        "yellow_page_code": "33637",
        "kiosk": false
    },
    {
        "value": "799713",
        "label": "Gun Clubs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399022",
        "yellow_page_code": "39406",
        "kiosk": false
    },
    {
        "value": "799714",
        "label": "Beach & Cabana Clubs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71394005",
        "yellow_page_code": "07118",
        "kiosk": false
    },
    {
        "value": "799716",
        "label": "Hunting & Fishing Clubs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399058",
        "yellow_page_code": "42095, 42118, 42099",
        "kiosk": false
    },
    {
        "value": "799717",
        "label": "Croquet Clubs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71394007",
        "yellow_page_code": "22612",
        "kiosk": false
    },
    {
        "value": "799718",
        "label": "Polo Grounds",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399035",
        "yellow_page_code": "67201",
        "kiosk": false
    },
    {
        "value": "799719",
        "label": "Softball Clubs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399043",
        "yellow_page_code": "79113",
        "kiosk": false
    },
    {
        "value": "799720",
        "label": "Sports Clubs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399044",
        "yellow_page_code": "04060, 82340, 80021, 80099, 04218, 56219",
        "kiosk": false
    },
    {
        "value": "799721",
        "label": "Volleyball Clubs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399053",
        "yellow_page_code": "91237",
        "kiosk": false
    },
    {
        "value": "799722",
        "label": "Snowmobile Clubs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399042",
        "yellow_page_code": "78710",
        "kiosk": false
    },
    {
        "value": "799723",
        "label": "Tee Times",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71391003",
        "yellow_page_code": "84160",
        "kiosk": false
    },
    {
        "value": "799724",
        "label": "Diving Excursion Packages",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399016",
        "yellow_page_code": "25214, 25222, 25221",
        "kiosk": false
    },
    {
        "value": "799725",
        "label": "Horse Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399008",
        "yellow_page_code": "41503",
        "kiosk": false
    },
    {
        "value": "799726",
        "label": "Chess Clubs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399055",
        "yellow_page_code": "15115",
        "kiosk": false
    },
    {
        "value": "799727",
        "label": "Rowing Clubs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399056",
        "yellow_page_code": "72891",
        "kiosk": false
    },
    {
        "value": "799728",
        "label": "Rugby Clubs & Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399057",
        "yellow_page_code": "73114",
        "kiosk": false
    },
    {
        "value": "799729",
        "label": "Sports Recreational",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71394028",
        "yellow_page_code": "72078, 80070, 80026, 80034",
        "kiosk": false
    },
    {
        "value": "799730",
        "label": "Hurling Clubs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399011",
        "yellow_page_code": "42123",
        "kiosk": false
    },
    {
        "value": "799731",
        "label": "Outdoor Sports",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399013",
        "yellow_page_code": "58131, 94910",
        "kiosk": false
    },
    {
        "value": "799732",
        "label": "Cheerleading Instructions",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399063",
        "yellow_page_code": "14812, 14815",
        "kiosk": false
    },
    {
        "value": "799798",
        "label": "Membership Sports & Recreation Clubs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399059",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "799901",
        "label": "Skating Rinks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71394025",
        "yellow_page_code": "77905, 42513, 42510, 77915, 42318, 72508, 78004, 41039, 80073, 42511, 77910",
        "kiosk": false
    },
    {
        "value": "799902",
        "label": "Archery Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61162001",
        "yellow_page_code": "03202",
        "kiosk": false
    },
    {
        "value": "799903",
        "label": "Archery Ranges",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61162002",
        "yellow_page_code": "03204, 03110",
        "kiosk": false
    },
    {
        "value": "799904",
        "label": "Auditoriums",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71394003",
        "yellow_page_code": "19606, 04403",
        "kiosk": false
    },
    {
        "value": "799905",
        "label": "Astrologers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299040",
        "yellow_page_code": "41495, 04106",
        "kiosk": false
    },
    {
        "value": "799906",
        "label": "Balloons-Manned",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48799001",
        "yellow_page_code": "41840, 06143, 06140, 06200, 06138, 80068, 06212, 06135",
        "kiosk": false
    },
    {
        "value": "799907",
        "label": "Tubes-Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228408",
        "yellow_page_code": "89321",
        "kiosk": false
    },
    {
        "value": "799908",
        "label": "Baseball Batting Ranges",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71394004",
        "yellow_page_code": "80032, 06801, 06803, 07101, 07094, 07096, 79115, 06817",
        "kiosk": false
    },
    {
        "value": "799909",
        "label": "Bicycles-Renting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228401",
        "yellow_page_code": "23134, 08001",
        "kiosk": false
    },
    {
        "value": "799910",
        "label": "Bathhouses Spas & Saunas",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81219919",
        "yellow_page_code": "06937, 07000",
        "kiosk": false
    },
    {
        "value": "799911",
        "label": "Bingo Games",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71329001",
        "yellow_page_code": "08196, 08201, 08110, 08198, 08197",
        "kiosk": false
    },
    {
        "value": "799912",
        "label": "Billiard Parlors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61162003",
        "yellow_page_code": "67209, 67221, 08011, 08097, 08104",
        "kiosk": false
    },
    {
        "value": "799913",
        "label": "Boats-Rental & Charter",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48721003",
        "yellow_page_code": "42017, 92993, 73420, 08815, 08610, 08899, 14710, 08902, 12615",
        "kiosk": false
    },
    {
        "value": "799914",
        "label": "Boating Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61162004",
        "yellow_page_code": "08740, 08808",
        "kiosk": false
    },
    {
        "value": "799915",
        "label": "Bridge Clubs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399009",
        "yellow_page_code": "10708",
        "kiosk": false
    },
    {
        "value": "799916",
        "label": "Bowling Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61162005",
        "yellow_page_code": "09706",
        "kiosk": false
    },
    {
        "value": "799917",
        "label": "Canoe Trip Outfitters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399010",
        "yellow_page_code": "12904",
        "kiosk": false
    },
    {
        "value": "799918",
        "label": "Canoes-Rental & Charter",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228404",
        "yellow_page_code": "12834, 12903",
        "kiosk": false
    },
    {
        "value": "799919",
        "label": "Carnivals",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71119002",
        "yellow_page_code": "13506, 22320, 30614, 31501",
        "kiosk": false
    },
    {
        "value": "799920",
        "label": "Card Playing Rooms",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71119001",
        "yellow_page_code": "13406",
        "kiosk": false
    },
    {
        "value": "799921",
        "label": "Concessionaires",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71119005",
        "yellow_page_code": "20402",
        "kiosk": false
    },
    {
        "value": "799922",
        "label": "National Landmark/Historic Site/Memorial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71219024",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "799923",
        "label": "Fairgrounds",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71119008",
        "yellow_page_code": "30604",
        "kiosk": false
    },
    {
        "value": "799924",
        "label": "Diving Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61162007",
        "yellow_page_code": "78205, 74938, 25220, 25207, 75005",
        "kiosk": false
    },
    {
        "value": "799925",
        "label": "Fishing Piers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48721006",
        "yellow_page_code": "32700",
        "kiosk": false
    },
    {
        "value": "799926",
        "label": "Fishing Lakes-Public",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48721004",
        "yellow_page_code": "32701",
        "kiosk": false
    },
    {
        "value": "799927",
        "label": "Children's Svcs & Activities Information",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399014",
        "yellow_page_code": "00451, 15148, 15128",
        "kiosk": false
    },
    {
        "value": "799928",
        "label": "Fishing Parties",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48721005",
        "yellow_page_code": "32610, 32713, 80055, 32703, 32621",
        "kiosk": false
    },
    {
        "value": "799929",
        "label": "Golf Courses-Miniature",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399019",
        "yellow_page_code": "65908, 52526, 69235, 38200",
        "kiosk": false
    },
    {
        "value": "799930",
        "label": "Professional Wrestlers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71121904",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "799931",
        "label": "Golf Practice Ranges",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399020",
        "yellow_page_code": "38302, 26519, 38114",
        "kiosk": false
    },
    {
        "value": "799932",
        "label": "Golf Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61162010",
        "yellow_page_code": "38115, 38127, 38300",
        "kiosk": false
    },
    {
        "value": "799933",
        "label": "Gun Safety & Marksmanship Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61162011",
        "yellow_page_code": "20380, 39400, 32217, 39342",
        "kiosk": false
    },
    {
        "value": "799934",
        "label": "Guide Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399021",
        "yellow_page_code": "41010, 19070, 71024, 39306, 71010, 39310, 32631, 14711, 32630, 23722, 42105, 32612, 32624, 42410",
        "kiosk": false
    },
    {
        "value": "799935",
        "label": "Halls & Auditoriums",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71394010",
        "yellow_page_code": "20102, 90820, 71695, 71701, 23324, 23322, 30621, 51190, 18113, 60215, 39806, 39816, 23321, 39804",
        "kiosk": false
    },
    {
        "value": "799936",
        "label": "Gymnastic Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61162012",
        "yellow_page_code": "39509, 39506, 89405",
        "kiosk": false
    },
    {
        "value": "799937",
        "label": "Hay Rides",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399024",
        "yellow_page_code": "40405",
        "kiosk": false
    },
    {
        "value": "799938",
        "label": "Tubing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71392003",
        "yellow_page_code": "78623",
        "kiosk": false
    },
    {
        "value": "799939",
        "label": "Paint Ball Games",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399031",
        "yellow_page_code": "58592, 58593",
        "kiosk": false
    },
    {
        "value": "799940",
        "label": "Historical Places",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71212001",
        "yellow_page_code": "41006, 66011",
        "kiosk": false
    },
    {
        "value": "799941",
        "label": "Sports-Motivational Training",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71121902",
        "yellow_page_code": "74520, 80018",
        "kiosk": false
    },
    {
        "value": "799942",
        "label": "Spas & Hot Tubs-Rentals",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228406",
        "yellow_page_code": "79611",
        "kiosk": false
    },
    {
        "value": "799943",
        "label": "Lottery Agents",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71329003",
        "yellow_page_code": "48701, 48640, 48639, 48638, 48631, 91019, 48709, 48612, 48611, 48707, 48609",
        "kiosk": false
    },
    {
        "value": "799944",
        "label": "Karate Judo Jiu-Jitsu & Kung Fu Instr",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61162013",
        "yellow_page_code": "44699, 04014, 74447, 44700, 44605, 44489, 75435, 43680, 83206, 75441",
        "kiosk": false
    },
    {
        "value": "799945",
        "label": "Martial Arts Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61162014",
        "yellow_page_code": "53003, 83340, 50205",
        "kiosk": false
    },
    {
        "value": "799946",
        "label": "Snowmobile Centers & Resorts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48711007",
        "yellow_page_code": "78705",
        "kiosk": false
    },
    {
        "value": "799947",
        "label": "Motorcycles & Motor Scooters-Rent/Lease",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228405",
        "yellow_page_code": "54307",
        "kiosk": false
    },
    {
        "value": "799948",
        "label": "Model Car Racing Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71121901",
        "yellow_page_code": "53300",
        "kiosk": false
    },
    {
        "value": "799949",
        "label": "Yoga Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169914",
        "yellow_page_code": "94804",
        "kiosk": false
    },
    {
        "value": "799950",
        "label": "Water Skiing Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71392001",
        "yellow_page_code": "92405",
        "kiosk": false
    },
    {
        "value": "799951",
        "label": "Parks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71219004",
        "yellow_page_code": "30821, 59502, 93516, 93530, 55121, 93519, 71736",
        "kiosk": false
    },
    {
        "value": "799952",
        "label": "Skydiving & Parachute Jumping Instrctns",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61162022",
        "yellow_page_code": "78220, 80075, 80015, 78215, 59300, 78207",
        "kiosk": false
    },
    {
        "value": "799953",
        "label": "Snowmobile-Renting & Leasing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228403",
        "yellow_page_code": "78714, 78801, 78712, 78716, 78637",
        "kiosk": false
    },
    {
        "value": "799954",
        "label": "Picnic Grounds",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71219005",
        "yellow_page_code": "65002",
        "kiosk": false
    },
    {
        "value": "799955",
        "label": "Psychic Mediums",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299041",
        "yellow_page_code": "43732, 18209, 68708, 68709, 68712, 34135, 68716, 61117, 51191, 30090, 25790, 68722",
        "kiosk": false
    },
    {
        "value": "799956",
        "label": "Playgrounds",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71219006",
        "yellow_page_code": "66604, 66610, 42820",
        "kiosk": false
    },
    {
        "value": "799957",
        "label": "Riding Academies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61162021",
        "yellow_page_code": "00022, 72137, 72215, 41524, 41530, 41531, 41505, 72200",
        "kiosk": false
    },
    {
        "value": "799958",
        "label": "Racquetball Courts-Public",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71394027",
        "yellow_page_code": "69604",
        "kiosk": false
    },
    {
        "value": "799959",
        "label": "Rodeos",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71119014",
        "yellow_page_code": "72504",
        "kiosk": false
    },
    {
        "value": "799960",
        "label": "Rifle & Pistol Ranges",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399038",
        "yellow_page_code": "83905, 32280, 18214, 72204, 83906, 39340, 40112",
        "kiosk": false
    },
    {
        "value": "799961",
        "label": "Sailing Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61162016",
        "yellow_page_code": "73500",
        "kiosk": false
    },
    {
        "value": "799962",
        "label": "Skating Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61162017",
        "yellow_page_code": "78002, 77916",
        "kiosk": false
    },
    {
        "value": "799963",
        "label": "Sightseeing Tours",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48799003",
        "yellow_page_code": "30159, 77500",
        "kiosk": false
    },
    {
        "value": "799964",
        "label": "Skiing Equipment-Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228402",
        "yellow_page_code": "78105, 78192",
        "kiosk": false
    },
    {
        "value": "799965",
        "label": "Mountain Climbing Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61162015",
        "yellow_page_code": "54315",
        "kiosk": false
    },
    {
        "value": "799966",
        "label": "Skiing Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61162018",
        "yellow_page_code": "78200",
        "kiosk": false
    },
    {
        "value": "799967",
        "label": "Swimming Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61162019",
        "yellow_page_code": "82710, 82706, 82713, 82840",
        "kiosk": false
    },
    {
        "value": "799968",
        "label": "Stables",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399050",
        "yellow_page_code": "80417, 08605, 41538, 67220, 41526, 41521, 67218, 41527, 41522, 48010, 72205, 41507, 80406, 41504",
        "kiosk": false
    },
    {
        "value": "799969",
        "label": "Swimming Pools-Public",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71394020",
        "yellow_page_code": "82903, 03130",
        "kiosk": false
    },
    {
        "value": "799970",
        "label": "Tennis Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61162020",
        "yellow_page_code": "85104",
        "kiosk": false
    },
    {
        "value": "799971",
        "label": "Tennis Courts-Public",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71394022",
        "yellow_page_code": "85014, 85100",
        "kiosk": false
    },
    {
        "value": "799972",
        "label": "Tourist Attractions",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71219007",
        "yellow_page_code": "00230, 30590, 14201, 13993, 30541, 87308, 66004",
        "kiosk": false
    },
    {
        "value": "799973",
        "label": "Ticket Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56159908",
        "yellow_page_code": "85510, 43595, 80022, 85907, 85902, 85900, 85809",
        "kiosk": false
    },
    {
        "value": "799974",
        "label": "Travel Clubs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "48799004",
        "yellow_page_code": "88408",
        "kiosk": false
    },
    {
        "value": "799975",
        "label": "Trap & Skeet Ranges",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399051",
        "yellow_page_code": "88402",
        "kiosk": false
    },
    {
        "value": "799976",
        "label": "Aquariums & Aquarium Supls-Leasing/Mntc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299054",
        "yellow_page_code": "03104",
        "kiosk": false
    },
    {
        "value": "799977",
        "label": "Parasailing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399033",
        "yellow_page_code": "59211",
        "kiosk": false
    },
    {
        "value": "799978",
        "label": "Boxing Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61162006",
        "yellow_page_code": "10007, 10011",
        "kiosk": false
    },
    {
        "value": "799979",
        "label": "Sports Information",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399045",
        "yellow_page_code": "80007, 80043, 80011",
        "kiosk": false
    },
    {
        "value": "799980",
        "label": "Water Sports Equipment-Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53228407",
        "yellow_page_code": "70019, 92116, 44210, 92119, 60321, 92131, 92503",
        "kiosk": false
    },
    {
        "value": "799981",
        "label": "Bridge Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61162023",
        "yellow_page_code": "10800",
        "kiosk": false
    },
    {
        "value": "799982",
        "label": "Circus Companies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71119018",
        "yellow_page_code": "18004",
        "kiosk": false
    },
    {
        "value": "799983",
        "label": "Fishing Lakes & Ponds",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71219009",
        "yellow_page_code": "32609, 32635",
        "kiosk": false
    },
    {
        "value": "799984",
        "label": "Squash Courts-Public",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71394032",
        "yellow_page_code": "80400",
        "kiosk": false
    },
    {
        "value": "799985",
        "label": "Snowboarding",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71392004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "799986",
        "label": "Raft Trips",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399018",
        "yellow_page_code": "70103",
        "kiosk": false
    },
    {
        "value": "799987",
        "label": "Scuba Diving Tours",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399023",
        "yellow_page_code": "74935",
        "kiosk": false
    },
    {
        "value": "799988",
        "label": "Sports Advisory Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61162024",
        "yellow_page_code": "80003",
        "kiosk": false
    },
    {
        "value": "799989",
        "label": "Snorkeling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399025",
        "yellow_page_code": "78701",
        "kiosk": false
    },
    {
        "value": "799990",
        "label": "Dog Park",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71219025",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "799991",
        "label": "Pageant Managers & Producers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169944",
        "yellow_page_code": "58435, 58433",
        "kiosk": false
    },
    {
        "value": "799992",
        "label": "Hunting Trips",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399026",
        "yellow_page_code": "42098, 42096, 42113, 42097",
        "kiosk": false
    },
    {
        "value": "799993",
        "label": "Punch Boards",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399027",
        "yellow_page_code": "69041, 69205",
        "kiosk": false
    },
    {
        "value": "799994",
        "label": "Skateboard Parks & Rinks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399028",
        "yellow_page_code": "77909",
        "kiosk": false
    },
    {
        "value": "799995",
        "label": "Chess Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169946",
        "yellow_page_code": "15105",
        "kiosk": false
    },
    {
        "value": "799996",
        "label": "Slot Car Race Courses",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399029",
        "yellow_page_code": "78309",
        "kiosk": false
    },
    {
        "value": "799997",
        "label": "Dog Sledding",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399030",
        "yellow_page_code": "25412",
        "kiosk": false
    },
    {
        "value": "799998",
        "label": "Table Tennis Facilities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71394033",
        "yellow_page_code": "83014, 83101",
        "kiosk": false
    },
    {
        "value": "799999",
        "label": "Amusement & Recreation NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71399002",
        "yellow_page_code": "05709, 87836, 54001, 37913, 80001, 78803, 32301, 41512, 07912, 39811, 40920, 47112, 07913, 88211, 82334, 54316, 30113, 82333, 91232, 08239, 07803, 59212, 77911, 77012, 71023, 94515, 01619, 04102, 59214, 06813, 29606, 04250, 78236, 75420, 80069, 02509, 06905, 09707, 11912, 30103, 31309, 37905, 40008, 56109, 58502, 80009, 82812, 91303, 92406, 07116, 91151, 58413, 80025, 79941, 18916, 78702, 04205, 38120, 78624, 15139, 69617, 72510, 11016, 19210, 80035, 46010, 91234, 15140, 46213, 46807, 05611, 71025, 72417, 55214, 38119, 44938, 30418, 08822, 24842, 15214, 29643, 03190, 41517, 43011, 46013, 22150, 60160, 31321, 04217, 58125, 40199, 71048, 88120, 58417, 90289, 69525, 78323, 31610, 69611, 80054, 44711, 27215, 11437, 38108, 92437, 72415, 23337, 78914, 46016, 67216, 41019, 72812, 54319, 36017, 88118, 30110",
        "kiosk": false
    },
    {
        "value": "801101",
        "label": "Physicians & Surgeons",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62111107",
        "yellow_page_code": "91598, 45915, 43774, 82291, 25321, 29981, 30037, 82290, 40390, 51058, 37113, 81738, 82293, 37020, 90537, 43580, 30612, 58015, 25319, 66410, 60119, 62306, 58008, 68701, 13409",
        "kiosk": false
    },
    {
        "value": "801102",
        "label": "Sports Medicine & Injuries",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62111108",
        "yellow_page_code": "04112, 80008",
        "kiosk": false
    },
    {
        "value": "801103",
        "label": "Hormone Therapy",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62111122",
        "yellow_page_code": "41490",
        "kiosk": false
    },
    {
        "value": "801104",
        "label": "Clinics",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62149301",
        "yellow_page_code": "94415, 10342, 18514, 88417, 90307, 94414, 18508, 58515, 87401, 20013, 42621, 51031, 94110, 94117, 51047",
        "kiosk": false
    },
    {
        "value": "801105",
        "label": "Physicians & Surgeons-Emergency Service",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62149302",
        "yellow_page_code": "90114, 61209",
        "kiosk": false
    },
    {
        "value": "801106",
        "label": "Psychoanalysts",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62111208",
        "yellow_page_code": "68807",
        "kiosk": false
    },
    {
        "value": "801107",
        "label": "Tattoos-Removed",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81219918",
        "yellow_page_code": "84008",
        "kiosk": false
    },
    {
        "value": "801108",
        "label": "Anesthetists",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62111101",
        "yellow_page_code": "02404, 02317, 02500",
        "kiosk": false
    },
    {
        "value": "801111",
        "label": "Ophthalmologists",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62111105",
        "yellow_page_code": "57717, 57701, 21105",
        "kiosk": false
    },
    {
        "value": "801112",
        "label": "Pathologists",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62111106",
        "yellow_page_code": "59707",
        "kiosk": false
    },
    {
        "value": "801113",
        "label": "Psychiatry-Adult Child & Adolescent",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62111207",
        "yellow_page_code": "68803",
        "kiosk": false
    },
    {
        "value": "801114",
        "label": "Neonatologists",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62111115",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "801115",
        "label": "Endermologie",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62111114",
        "yellow_page_code": "28620",
        "kiosk": false
    },
    {
        "value": "801116",
        "label": "Preventive Medicine",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62111113",
        "yellow_page_code": "68120",
        "kiosk": false
    },
    {
        "value": "801117",
        "label": "Bed Wetting Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62111112",
        "yellow_page_code": "07312",
        "kiosk": false
    },
    {
        "value": "801118",
        "label": "Oculists MD",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62111103",
        "yellow_page_code": "56110, 56107",
        "kiosk": false
    },
    {
        "value": "801119",
        "label": "Dermatologists",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62111102",
        "yellow_page_code": "24216",
        "kiosk": false
    },
    {
        "value": "801120",
        "label": "Allergy Physicians",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62111111",
        "yellow_page_code": "01901, 01920, 01905, 01917, 01807, 01924, 01925, 01921",
        "kiosk": false
    },
    {
        "value": "801122",
        "label": "House Calls Physicians",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62149303",
        "yellow_page_code": "61303, 41948",
        "kiosk": false
    },
    {
        "value": "801123",
        "label": "Cosmetic Plastic/Reconstructive Surgery",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62111110",
        "yellow_page_code": "46034, 21820, 21823, 21822",
        "kiosk": false
    },
    {
        "value": "801125",
        "label": "Oncologists",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62111116",
        "yellow_page_code": "57520",
        "kiosk": false
    },
    {
        "value": "801126",
        "label": "Vascular Surgeon",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62149101",
        "yellow_page_code": "90540",
        "kiosk": false
    },
    {
        "value": "801127",
        "label": "Nephrology",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62111118",
        "yellow_page_code": "55428",
        "kiosk": false
    },
    {
        "value": "801128",
        "label": "Orthopedic Surgeons",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62111119",
        "yellow_page_code": "58023, 58030",
        "kiosk": false
    },
    {
        "value": "801129",
        "label": "Prostate Screening",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62111123",
        "yellow_page_code": "68631",
        "kiosk": false
    },
    {
        "value": "801130",
        "label": "Hand Surgery",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62111120",
        "yellow_page_code": "39920",
        "kiosk": false
    },
    {
        "value": "801131",
        "label": "Pediatric Neurology",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62111121",
        "yellow_page_code": "60120",
        "kiosk": false
    },
    {
        "value": "801132",
        "label": "Health Screening & Vaccination Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62111124",
        "yellow_page_code": "40450",
        "kiosk": false
    },
    {
        "value": "801133",
        "label": "In-Patient Clinics & Treatment Ctr",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62111125",
        "yellow_page_code": "42823",
        "kiosk": false
    },
    {
        "value": "801134",
        "label": "Manipulative Treatment (Omt)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62111126",
        "yellow_page_code": "49864",
        "kiosk": false
    },
    {
        "value": "801135",
        "label": "Balance Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62111127",
        "yellow_page_code": "06010",
        "kiosk": false
    },
    {
        "value": "801136",
        "label": "Imrt (Intnsty Modulated Radiation Thrpy)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62111128",
        "yellow_page_code": "42635",
        "kiosk": false
    },
    {
        "value": "801137",
        "label": "Otorhinolaryngology Ear Nose Physician",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62111109",
        "yellow_page_code": "58090, 27088",
        "kiosk": false
    },
    {
        "value": "801138",
        "label": "Pulmonary & Respiratory Diseases",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62111117",
        "yellow_page_code": "69110",
        "kiosk": false
    },
    {
        "value": "802101",
        "label": "Dentists",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62121003",
        "yellow_page_code": "23914, 58027, 28625, 23923, 15153, 23944, 43663, 23908, 23919, 58020, 23915, 84165, 22207, 23921, 93036",
        "kiosk": false
    },
    {
        "value": "802102",
        "label": "Dental Mechanics-Public",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62121001",
        "yellow_page_code": "23813",
        "kiosk": false
    },
    {
        "value": "802103",
        "label": "Dental Technicians",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62121002",
        "yellow_page_code": "23912",
        "kiosk": false
    },
    {
        "value": "802104",
        "label": "Cosmetic Dentistry",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62121004",
        "yellow_page_code": "21890",
        "kiosk": false
    },
    {
        "value": "802105",
        "label": "Dental Spas",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62121005",
        "yellow_page_code": "23821",
        "kiosk": false
    },
    {
        "value": "802106",
        "label": "Dental Surgeons",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62121006",
        "yellow_page_code": "23822",
        "kiosk": false
    },
    {
        "value": "803198",
        "label": "Offices & Clinics Of Doctors-Osteopathy",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62111104",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "804101",
        "label": "Chiropractors DC",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62131002",
        "yellow_page_code": "15513, 15519, 15514, 15518, 43737, 15511, 15521, 62400, 80052, 15504",
        "kiosk": false
    },
    {
        "value": "804102",
        "label": "Spinologists",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62131004",
        "yellow_page_code": "79905",
        "kiosk": false
    },
    {
        "value": "804103",
        "label": "Emergency Chiropractic Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62131003",
        "yellow_page_code": "28410",
        "kiosk": false
    },
    {
        "value": "804104",
        "label": "Acupuncture Chiropractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62131001",
        "yellow_page_code": "00255",
        "kiosk": false
    },
    {
        "value": "804201",
        "label": "Optometrists OD",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62132003",
        "yellow_page_code": "30408, 57718, 43770, 91213, 30317, 91245, 14116, 57708, 57711, 57712, 62316, 57713, 57714",
        "kiosk": false
    },
    {
        "value": "804202",
        "label": "Contact Lenses-Optometrists",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62132001",
        "yellow_page_code": "21106",
        "kiosk": false
    },
    {
        "value": "804203",
        "label": "Optometrists OD-Childrens Vision",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62132004",
        "yellow_page_code": "15146, 57704",
        "kiosk": false
    },
    {
        "value": "804204",
        "label": "Optometrists OD-Geriatric Optometry",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62132005",
        "yellow_page_code": "57705",
        "kiosk": false
    },
    {
        "value": "804205",
        "label": "Optometrists OD-Therapy Visual Training",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62132006",
        "yellow_page_code": "57716, 57706",
        "kiosk": false
    },
    {
        "value": "804206",
        "label": "Eyes-Human Prosthetic",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62132002",
        "yellow_page_code": "30312, 68632",
        "kiosk": false
    },
    {
        "value": "804207",
        "label": "Optometrists OD Developmental Vision",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62132007",
        "yellow_page_code": "57715",
        "kiosk": false
    },
    {
        "value": "804301",
        "label": "Podiatrists",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62139103",
        "yellow_page_code": "29980, 15501, 34011, 66906, 62311, 62312, 61309, 34013",
        "kiosk": false
    },
    {
        "value": "804302",
        "label": "Physicians/Surgeons DPM Podiatrist Exchg",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139101",
        "yellow_page_code": "61216, 34020",
        "kiosk": false
    },
    {
        "value": "804303",
        "label": "Podiatrists Information Bureaus",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139104",
        "yellow_page_code": "62313, 66905, 66916, 66911",
        "kiosk": false
    },
    {
        "value": "804304",
        "label": "Podiatric Sports Medicine",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62139105",
        "yellow_page_code": "66915",
        "kiosk": false
    },
    {
        "value": "804398",
        "label": "Podiatrist Clinics",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139102",
        "yellow_page_code": "34026",
        "kiosk": false
    },
    {
        "value": "804901",
        "label": "Physicians-Naturopathic",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62139933",
        "yellow_page_code": "55202, 62402, 55119, 55216",
        "kiosk": false
    },
    {
        "value": "804902",
        "label": "Hypnotists",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139912",
        "yellow_page_code": "42211, 42300, 42121",
        "kiosk": false
    },
    {
        "value": "804903",
        "label": "Health & Nutrition Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139910",
        "yellow_page_code": "40404, 56025, 56021, 40433",
        "kiosk": false
    },
    {
        "value": "804904",
        "label": "Midwives",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62139916",
        "yellow_page_code": "52303, 25820, 55923",
        "kiosk": false
    },
    {
        "value": "804905",
        "label": "Metaphysicians",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62134003",
        "yellow_page_code": "52000, 52002",
        "kiosk": false
    },
    {
        "value": "804906",
        "label": "Myofunctional Therapy",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62134004",
        "yellow_page_code": "55007",
        "kiosk": false
    },
    {
        "value": "804907",
        "label": "Nurses-Practitioners",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62139923",
        "yellow_page_code": "55903, 55815, 55911",
        "kiosk": false
    },
    {
        "value": "804908",
        "label": "Nurses-Registered RN",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62139920",
        "yellow_page_code": "55904, 55917, 55924, 55918, 55915",
        "kiosk": false
    },
    {
        "value": "804909",
        "label": "Nutritionists",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62139925",
        "yellow_page_code": "56007, 56006, 56020",
        "kiosk": false
    },
    {
        "value": "804910",
        "label": "Occupational Health & Safety Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62134005",
        "yellow_page_code": "40416, 56106, 40548, 56112",
        "kiosk": false
    },
    {
        "value": "804911",
        "label": "Occupational Therapists",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62134006",
        "yellow_page_code": "56120, 56200, 56122",
        "kiosk": false
    },
    {
        "value": "804912",
        "label": "Audiologists",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62134001",
        "yellow_page_code": "40553, 04400, 04311, 30127",
        "kiosk": false
    },
    {
        "value": "804913",
        "label": "Acupuncture",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62139901",
        "yellow_page_code": "30144, 00208, 00214, 00218, 00219",
        "kiosk": false
    },
    {
        "value": "804914",
        "label": "Dental Hygienists",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62139955",
        "yellow_page_code": "23903, 42225",
        "kiosk": false
    },
    {
        "value": "804915",
        "label": "Biofeedback Therapists",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62139903",
        "yellow_page_code": "08232, 08231, 08205, 08228",
        "kiosk": false
    },
    {
        "value": "804916",
        "label": "Paramedical Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139930",
        "yellow_page_code": "59209",
        "kiosk": false
    },
    {
        "value": "804917",
        "label": "Dietitians",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62139904",
        "yellow_page_code": "24806",
        "kiosk": false
    },
    {
        "value": "804918",
        "label": "Physical Therapists",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62134007",
        "yellow_page_code": "92884, 71531, 43731, 61214, 61206, 85611, 66125",
        "kiosk": false
    },
    {
        "value": "804919",
        "label": "Paramedics",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139931",
        "yellow_page_code": "59303",
        "kiosk": false
    },
    {
        "value": "804920",
        "label": "Physicians Assistants",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62139932",
        "yellow_page_code": "62308",
        "kiosk": false
    },
    {
        "value": "804921",
        "label": "Psychiatric Social Workers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139935",
        "yellow_page_code": "68705",
        "kiosk": false
    },
    {
        "value": "804922",
        "label": "Psychologists",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62139936",
        "yellow_page_code": "51233, 68737, 68706, 68740, 68739, 68719, 68741, 15118",
        "kiosk": false
    },
    {
        "value": "804923",
        "label": "Speech & Hearing Therapy",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62134008",
        "yellow_page_code": "79827, 46514, 40554, 79824, 40535, 40610, 40930, 79814, 79810, 40539, 79805, 40609",
        "kiosk": false
    },
    {
        "value": "804924",
        "label": "Psychotherapists",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62133001",
        "yellow_page_code": "68802, 68765, 68735",
        "kiosk": false
    },
    {
        "value": "804925",
        "label": "Speech Pathologists",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62134009",
        "yellow_page_code": "30080, 79804, 79813, 79815, 79816, 79820",
        "kiosk": false
    },
    {
        "value": "804926",
        "label": "Homeopaths",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139911",
        "yellow_page_code": "61307, 41407, 41124",
        "kiosk": false
    },
    {
        "value": "804927",
        "label": "Nursing Assistants",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62139921",
        "yellow_page_code": "55912",
        "kiosk": false
    },
    {
        "value": "804928",
        "label": "Structural Integration",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139945",
        "yellow_page_code": "82007",
        "kiosk": false
    },
    {
        "value": "804929",
        "label": "Medical Diagnostic Equipment-Mobile",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139915",
        "yellow_page_code": "51112",
        "kiosk": false
    },
    {
        "value": "804930",
        "label": "Physiotherapists",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62139934",
        "yellow_page_code": "64208, 62406",
        "kiosk": false
    },
    {
        "value": "804931",
        "label": "Eating Disorders Info & Treatment Ctrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139905",
        "yellow_page_code": "27201",
        "kiosk": false
    },
    {
        "value": "804932",
        "label": "Therapists-Corrective",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62139947",
        "yellow_page_code": "85607",
        "kiosk": false
    },
    {
        "value": "804933",
        "label": "Orthotherapists",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62139928",
        "yellow_page_code": "58014",
        "kiosk": false
    },
    {
        "value": "804934",
        "label": "Sexology",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139941",
        "yellow_page_code": "76012",
        "kiosk": false
    },
    {
        "value": "804935",
        "label": "School Psychologists",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139940",
        "yellow_page_code": "74311",
        "kiosk": false
    },
    {
        "value": "804936",
        "label": "Myotherapy",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139917",
        "yellow_page_code": "54936",
        "kiosk": false
    },
    {
        "value": "804937",
        "label": "Flotation Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139908",
        "yellow_page_code": "33016",
        "kiosk": false
    },
    {
        "value": "804938",
        "label": "Rescue Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139939",
        "yellow_page_code": "71812",
        "kiosk": false
    },
    {
        "value": "804939",
        "label": "Pharmacists",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62133002",
        "yellow_page_code": "60612",
        "kiosk": false
    },
    {
        "value": "804940",
        "label": "Audiology Information & Referral Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62134002",
        "yellow_page_code": "04390",
        "kiosk": false
    },
    {
        "value": "804941",
        "label": "Kinesiotherapy",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62139913",
        "yellow_page_code": "44838, 44841, 44912",
        "kiosk": false
    },
    {
        "value": "804942",
        "label": "Healing Arts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139960",
        "yellow_page_code": "40434",
        "kiosk": false
    },
    {
        "value": "804943",
        "label": "Psychometric Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139937",
        "yellow_page_code": "68711",
        "kiosk": false
    },
    {
        "value": "804944",
        "label": "Sports-Psychology",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139944",
        "yellow_page_code": "80017",
        "kiosk": false
    },
    {
        "value": "804945",
        "label": "Psychologists Educational",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62133010",
        "yellow_page_code": "68714, 27141",
        "kiosk": false
    },
    {
        "value": "804946",
        "label": "Alternative Medicine",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62139902",
        "yellow_page_code": "02020, 55131, 40444, 09690, 62305, 51224, 02016, 02010, 41202",
        "kiosk": false
    },
    {
        "value": "804947",
        "label": "Snoring Treatment & Devices",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139943",
        "yellow_page_code": "78610",
        "kiosk": false
    },
    {
        "value": "804948",
        "label": "Electro-Therapists",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62139969",
        "yellow_page_code": "27824, 28210",
        "kiosk": false
    },
    {
        "value": "804949",
        "label": "Transactional Analysis",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139949",
        "yellow_page_code": "88212",
        "kiosk": false
    },
    {
        "value": "804950",
        "label": "Therapy-Group",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62139948",
        "yellow_page_code": "85615, 85608, 03613",
        "kiosk": false
    },
    {
        "value": "804951",
        "label": "Orthoptists",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139927",
        "yellow_page_code": "58005",
        "kiosk": false
    },
    {
        "value": "804952",
        "label": "Nursing Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139924",
        "yellow_page_code": "13418, 55936, 55914, 34014, 55930",
        "kiosk": false
    },
    {
        "value": "804953",
        "label": "Reiki",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139938",
        "yellow_page_code": "71535, 71520",
        "kiosk": false
    },
    {
        "value": "804954",
        "label": "Natural Health & Education",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139918",
        "yellow_page_code": "40551, 55205",
        "kiosk": false
    },
    {
        "value": "804955",
        "label": "Nurses-Licensed-Anesthetist",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62139922",
        "yellow_page_code": "55902",
        "kiosk": false
    },
    {
        "value": "804956",
        "label": "Shiatsu Therapy",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139942",
        "yellow_page_code": "76313",
        "kiosk": false
    },
    {
        "value": "804957",
        "label": "Laser Surgery",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139914",
        "yellow_page_code": "19617, 46023, 82292, 46011",
        "kiosk": false
    },
    {
        "value": "804958",
        "label": "Music Therapy",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139946",
        "yellow_page_code": "85619, 54817, 85610",
        "kiosk": false
    },
    {
        "value": "804959",
        "label": "Orthotics",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139929",
        "yellow_page_code": "58016",
        "kiosk": false
    },
    {
        "value": "804960",
        "label": "Vein Removal",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139950",
        "yellow_page_code": "90538, 90637",
        "kiosk": false
    },
    {
        "value": "804961",
        "label": "Neuropaths",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139919",
        "yellow_page_code": "55316",
        "kiosk": false
    },
    {
        "value": "804962",
        "label": "Autopsy Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139951",
        "yellow_page_code": "05325",
        "kiosk": false
    },
    {
        "value": "804963",
        "label": "Physicians-Oriental Medicine",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139952",
        "yellow_page_code": "62318, 15440, 57920, 15433",
        "kiosk": false
    },
    {
        "value": "804964",
        "label": "Respiratory Therapists",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62139953",
        "yellow_page_code": "71884",
        "kiosk": false
    },
    {
        "value": "804965",
        "label": "Feldenkrais Practitioners",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139954",
        "yellow_page_code": "31230",
        "kiosk": false
    },
    {
        "value": "804966",
        "label": "Cellulite Treatments",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139958",
        "yellow_page_code": "14260, 14262",
        "kiosk": false
    },
    {
        "value": "804967",
        "label": "Laser Vision Correction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139959",
        "yellow_page_code": "46024, 46032, 46022",
        "kiosk": false
    },
    {
        "value": "804968",
        "label": "Chelation Therapy",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62134010",
        "yellow_page_code": "14908",
        "kiosk": false
    },
    {
        "value": "804969",
        "label": "Health & Fitness Therapy",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62134011",
        "yellow_page_code": "40435",
        "kiosk": false
    },
    {
        "value": "804970",
        "label": "Psychosocial Therapists",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62133005",
        "yellow_page_code": "68780",
        "kiosk": false
    },
    {
        "value": "804971",
        "label": "Infertility Treatment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139909",
        "yellow_page_code": "43029, 43028, 31401",
        "kiosk": false
    },
    {
        "value": "804972",
        "label": "Anti Aging Products & Treatments",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139961",
        "yellow_page_code": "02716, 51139, 02717",
        "kiosk": false
    },
    {
        "value": "804973",
        "label": "Horse Therapeutic Riding",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139966",
        "yellow_page_code": "41529, 41516, 85616, 72138",
        "kiosk": false
    },
    {
        "value": "804974",
        "label": "Medical Spas",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62134013",
        "yellow_page_code": "79620, 51128, 79617",
        "kiosk": false
    },
    {
        "value": "804975",
        "label": "Lactation Counseling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139957",
        "yellow_page_code": "45428, 45416",
        "kiosk": false
    },
    {
        "value": "804976",
        "label": "Endoscopy Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139962",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "804977",
        "label": "Osteoporosis Information & Support",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139963",
        "yellow_page_code": "58040, 58017",
        "kiosk": false
    },
    {
        "value": "804978",
        "label": "Lyme Disease-Information & Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62133006",
        "yellow_page_code": "49007",
        "kiosk": false
    },
    {
        "value": "804979",
        "label": "Aquatic Therapy",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62134014",
        "yellow_page_code": "03123, 33024",
        "kiosk": false
    },
    {
        "value": "804980",
        "label": "Play Therapist",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62133007",
        "yellow_page_code": "66592",
        "kiosk": false
    },
    {
        "value": "804981",
        "label": "Psychologists-Sex Therapy",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139965",
        "yellow_page_code": "68718",
        "kiosk": false
    },
    {
        "value": "804982",
        "label": "Audioprosthologists",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62134012",
        "yellow_page_code": "04324",
        "kiosk": false
    },
    {
        "value": "804983",
        "label": "Lymphedema Therapy",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62134016",
        "yellow_page_code": "49050",
        "kiosk": false
    },
    {
        "value": "804984",
        "label": "Orofacial Myology",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139967",
        "yellow_page_code": "58024",
        "kiosk": false
    },
    {
        "value": "804985",
        "label": "Tmj Treatment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62134015",
        "yellow_page_code": "86614",
        "kiosk": false
    },
    {
        "value": "804986",
        "label": "Healers-Mind Body & Spiritual",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139907",
        "yellow_page_code": "40431",
        "kiosk": false
    },
    {
        "value": "804987",
        "label": "Mesotherapy Treatment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139968",
        "yellow_page_code": "51340",
        "kiosk": false
    },
    {
        "value": "804988",
        "label": "Educative Therapy",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139970",
        "yellow_page_code": "27221",
        "kiosk": false
    },
    {
        "value": "804989",
        "label": "Craniosacral Therapy",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139972",
        "yellow_page_code": "22340",
        "kiosk": false
    },
    {
        "value": "804990",
        "label": "Nia-Neuromuscular Interactive Action",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139971",
        "yellow_page_code": "55590",
        "kiosk": false
    },
    {
        "value": "804991",
        "label": "Speech & Hearing Disorders Information",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62134017",
        "yellow_page_code": "79826",
        "kiosk": false
    },
    {
        "value": "804992",
        "label": "Health & Safety Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139973",
        "yellow_page_code": "40453, 33827, 40446",
        "kiosk": false
    },
    {
        "value": "804993",
        "label": "Nurses Licensed Practical Lpn",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62139977",
        "yellow_page_code": "55916, 55921, 55928",
        "kiosk": false
    },
    {
        "value": "804994",
        "label": "Complementary Medicine",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139974",
        "yellow_page_code": "20125, 20126",
        "kiosk": false
    },
    {
        "value": "804995",
        "label": "Brain State Conditioning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62133008",
        "yellow_page_code": "10105",
        "kiosk": false
    },
    {
        "value": "804996",
        "label": "Sensory Procng Disorders-Thrptc Gds/Svcs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139975",
        "yellow_page_code": "75522",
        "kiosk": false
    },
    {
        "value": "804997",
        "label": "Oncology Massage Therapy",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139976",
        "yellow_page_code": "57490",
        "kiosk": false
    },
    {
        "value": "804998",
        "label": "Therapy",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62133009",
        "yellow_page_code": "85614, 85618",
        "kiosk": false
    },
    {
        "value": "804999",
        "label": "Offices Of Health Practitioners NEC",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62139926",
        "yellow_page_code": "58007, 60127",
        "kiosk": false
    },
    {
        "value": "805101",
        "label": "Nursing & Convalescent Homes",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62311016",
        "yellow_page_code": "41269, 56000, 13419, 55826, 57496, 43724, 51237, 41301",
        "kiosk": false
    },
    {
        "value": "805102",
        "label": "Health Care Facilities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62311006",
        "yellow_page_code": "40436, 40420, 40437",
        "kiosk": false
    },
    {
        "value": "805198",
        "label": "Skilled Nursing Care Facilities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62331103",
        "yellow_page_code": "41337",
        "kiosk": false
    },
    {
        "value": "805201",
        "label": "Residential Intellectual & Developmental Disability Facilities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62321001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "805298",
        "label": "Intermediate Care Facilities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62311013",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "805901",
        "label": "Rest Homes",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62311020",
        "yellow_page_code": "71904, 27312, 41330, 41326, 08604, 37209, 41309, 41325, 37210, 41324",
        "kiosk": false
    },
    {
        "value": "805902",
        "label": "Convalescent Homes",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62311002",
        "yellow_page_code": "21301, 21313, 55938, 78210, 55937, 41329, 21310, 41306, 41327",
        "kiosk": false
    },
    {
        "value": "805903",
        "label": "Adult Care Facilities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62311001",
        "yellow_page_code": "00402, 34137, 00405, 00396",
        "kiosk": false
    },
    {
        "value": "805904",
        "label": "Retirement Communities & Homes",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62331101",
        "yellow_page_code": "71727, 72006, 82316, 27308, 72021, 72010, 41328, 72019, 71936, 72025, 48325",
        "kiosk": false
    },
    {
        "value": "805905",
        "label": "Health Facilities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62311007",
        "yellow_page_code": "40502",
        "kiosk": false
    },
    {
        "value": "805906",
        "label": "Homes & Institutions",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62311008",
        "yellow_page_code": "41401",
        "kiosk": false
    },
    {
        "value": "805907",
        "label": "Nursing Home Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62311018",
        "yellow_page_code": "71911, 55909",
        "kiosk": false
    },
    {
        "value": "805908",
        "label": "Hospices",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62311011",
        "yellow_page_code": "00424, 41709, 58721",
        "kiosk": false
    },
    {
        "value": "805909",
        "label": "Personal Care Homes",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62311019",
        "yellow_page_code": "41248, 60300",
        "kiosk": false
    },
    {
        "value": "805910",
        "label": "Homes-Invalid",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62311009",
        "yellow_page_code": "41201",
        "kiosk": false
    },
    {
        "value": "805911",
        "label": "Homes-Personal Care Facility",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62311010",
        "yellow_page_code": "41322",
        "kiosk": false
    },
    {
        "value": "805912",
        "label": "Custodial Homes",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62311004",
        "yellow_page_code": "23005",
        "kiosk": false
    },
    {
        "value": "805913",
        "label": "Life Care Communities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62311014",
        "yellow_page_code": "47203",
        "kiosk": false
    },
    {
        "value": "805914",
        "label": "Convalescent Homes Placement Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62311003",
        "yellow_page_code": "21305",
        "kiosk": false
    },
    {
        "value": "805915",
        "label": "Independent Living Services For Disabled",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62311012",
        "yellow_page_code": "42813, 94118",
        "kiosk": false
    },
    {
        "value": "805916",
        "label": "Gerontologists",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62311005",
        "yellow_page_code": "37211",
        "kiosk": false
    },
    {
        "value": "805917",
        "label": "Long Term Care Facility",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62311015",
        "yellow_page_code": "40456, 48619",
        "kiosk": false
    },
    {
        "value": "805918",
        "label": "Independent Living Facilities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62331104",
        "yellow_page_code": "72073, 42942",
        "kiosk": false
    },
    {
        "value": "805999",
        "label": "Nursing & Personal Care NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62311017",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "806201",
        "label": "Medical Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62211003",
        "yellow_page_code": "51003",
        "kiosk": false
    },
    {
        "value": "806202",
        "label": "Hospitals",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62211002",
        "yellow_page_code": "41808",
        "kiosk": false
    },
    {
        "value": "806203",
        "label": "Emergency Medical & Surgical Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62211001",
        "yellow_page_code": "28420, 28426, 28505, 28422",
        "kiosk": false
    },
    {
        "value": "806301",
        "label": "Mental Health Services",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62221001",
        "yellow_page_code": "07610, 51310, 51314, 60722, 85613, 51318, 51301",
        "kiosk": false
    },
    {
        "value": "806302",
        "label": "Sanitariums",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62221004",
        "yellow_page_code": "73809",
        "kiosk": false
    },
    {
        "value": "806303",
        "label": "Psychiatric Treatment Facilities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62221003",
        "yellow_page_code": "68710, 68713",
        "kiosk": false
    },
    {
        "value": "806304",
        "label": "Attention Deficit Disorder Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62221005",
        "yellow_page_code": "04214",
        "kiosk": false
    },
    {
        "value": "806305",
        "label": "Anger Management",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62221006",
        "yellow_page_code": "02425",
        "kiosk": false
    },
    {
        "value": "806398",
        "label": "Psychiatric & Substance Abuse Hospitals ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62221002",
        "yellow_page_code": "04123",
        "kiosk": false
    },
    {
        "value": "806902",
        "label": "Birth Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139956",
        "yellow_page_code": "08304, 08230",
        "kiosk": false
    },
    {
        "value": "806903",
        "label": "Maternity Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62231002",
        "yellow_page_code": "50503",
        "kiosk": false
    },
    {
        "value": "806904",
        "label": "Government-Specialty Hosp Ex Psychiatric",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62231004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "806906",
        "label": "Cancer Treatment Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62231005",
        "yellow_page_code": "12617",
        "kiosk": false
    },
    {
        "value": "806998",
        "label": "Specialty Hospitals-Except Psychiatric",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62231007",
        "yellow_page_code": "71888",
        "kiosk": false
    },
    {
        "value": "807101",
        "label": "Laboratories-Medical",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62151106",
        "yellow_page_code": "45306, 51055, 51027, 51101, 69915, 51240, 45316",
        "kiosk": false
    },
    {
        "value": "807102",
        "label": "X-Ray Laboratories-Dental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62151119",
        "yellow_page_code": "94607",
        "kiosk": false
    },
    {
        "value": "807103",
        "label": "X-Ray Laboratories Medical & Dental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62151201",
        "yellow_page_code": "94619, 94618, 29889",
        "kiosk": false
    },
    {
        "value": "807104",
        "label": "Laboratories-Clinical",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62151105",
        "yellow_page_code": "45303",
        "kiosk": false
    },
    {
        "value": "807105",
        "label": "Bacteriologists",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62151101",
        "yellow_page_code": "05702, 45234",
        "kiosk": false
    },
    {
        "value": "807106",
        "label": "Speech & Language Pathology Info Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62151120",
        "yellow_page_code": "79812, 45308",
        "kiosk": false
    },
    {
        "value": "807107",
        "label": "Laboratories-Biological",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62151104",
        "yellow_page_code": "45401",
        "kiosk": false
    },
    {
        "value": "807108",
        "label": "Drug Detection Service & Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62151103",
        "yellow_page_code": "26510, 26516, 26701, 26513, 45320, 89090, 26518, 26517, 01818",
        "kiosk": false
    },
    {
        "value": "807109",
        "label": "Blood Tests",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62151102",
        "yellow_page_code": "08505",
        "kiosk": false
    },
    {
        "value": "807111",
        "label": "Pain Control",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62151108",
        "yellow_page_code": "58512, 13531, 51016",
        "kiosk": false
    },
    {
        "value": "807113",
        "label": "Radiotherapy",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62151112",
        "yellow_page_code": "70100",
        "kiosk": false
    },
    {
        "value": "807114",
        "label": "Microbiologists",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62151109",
        "yellow_page_code": "52205, 52117",
        "kiosk": false
    },
    {
        "value": "807115",
        "label": "Dna Testing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62151111",
        "yellow_page_code": "25230, 59714",
        "kiosk": false
    },
    {
        "value": "807116",
        "label": "Nuclear Energy Laboratories",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62151110",
        "yellow_page_code": "55712",
        "kiosk": false
    },
    {
        "value": "807117",
        "label": "Testing Service-Sobriety",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62151114",
        "yellow_page_code": "85219",
        "kiosk": false
    },
    {
        "value": "807118",
        "label": "Technicians",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62151113",
        "yellow_page_code": "84226",
        "kiosk": false
    },
    {
        "value": "807119",
        "label": "Optical Laboratories-Retail",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62151118",
        "yellow_page_code": "57612",
        "kiosk": false
    },
    {
        "value": "807120",
        "label": "Gas Testing-Medical",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62151117",
        "yellow_page_code": "36617",
        "kiosk": false
    },
    {
        "value": "807122",
        "label": "Electronic Imaging",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62151205",
        "yellow_page_code": "28027",
        "kiosk": false
    },
    {
        "value": "807123",
        "label": "Animal Plant Dna Testing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62151107",
        "yellow_page_code": "02521",
        "kiosk": false
    },
    {
        "value": "807125",
        "label": "Forensic Medicine",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62151122",
        "yellow_page_code": "34080",
        "kiosk": false
    },
    {
        "value": "807126",
        "label": "Fibromyalgia Diagnosis & Treatment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62151123",
        "yellow_page_code": "31590",
        "kiosk": false
    },
    {
        "value": "807127",
        "label": "Genetic Counseling",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62151124",
        "yellow_page_code": "37108",
        "kiosk": false
    },
    {
        "value": "807128",
        "label": "Teleradiology Equip & Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62151206",
        "yellow_page_code": "84470",
        "kiosk": false
    },
    {
        "value": "807129",
        "label": "Diagnostic Imaging Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62151207",
        "yellow_page_code": "94625, 94623, 42610, 49825, 51034, 51024, 67972, 89821, 49505, 79405, 51013, 45207, 24415, 50912, 94700, 94611, 89825, 10410, 67415, 69910, 14223, 08831, 24412, 69719, 69720, 10341, 49805, 85695, 69618, 30292, 24417",
        "kiosk": false
    },
    {
        "value": "807130",
        "label": "Toxicologists",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62151125",
        "yellow_page_code": "87511",
        "kiosk": false
    },
    {
        "value": "807201",
        "label": "Laboratories-Dental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911604",
        "yellow_page_code": "23904, 45304, 23817",
        "kiosk": false
    },
    {
        "value": "807202",
        "label": "Denture Repair Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911601",
        "yellow_page_code": "24004",
        "kiosk": false
    },
    {
        "value": "807203",
        "label": "Denturists",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "33911603",
        "yellow_page_code": "24111, 24108, 24003, 24002",
        "kiosk": false
    },
    {
        "value": "807204",
        "label": "Denture Therapists",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "33911602",
        "yellow_page_code": "23913",
        "kiosk": false
    },
    {
        "value": "807205",
        "label": "Dental Implants-Prosthesis",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33911605",
        "yellow_page_code": "23917",
        "kiosk": false
    },
    {
        "value": "808201",
        "label": "Home Health Service",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62161001",
        "yellow_page_code": "30226, 41300, 68220, 60227, 13412, 41137, 00423, 00419, 41217, 41238, 41233, 41231, 43127, 71886, 41249",
        "kiosk": false
    },
    {
        "value": "808202",
        "label": "Home Managing Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62161002",
        "yellow_page_code": "41223",
        "kiosk": false
    },
    {
        "value": "808204",
        "label": "Visitation Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62161004",
        "yellow_page_code": "91224, 13417",
        "kiosk": false
    },
    {
        "value": "809201",
        "label": "Artificial Kidney Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62149201",
        "yellow_page_code": "03805",
        "kiosk": false
    },
    {
        "value": "809202",
        "label": "Dialysis Equipment Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62149203",
        "yellow_page_code": "22407",
        "kiosk": false
    },
    {
        "value": "809203",
        "label": "Dialysis",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62149202",
        "yellow_page_code": "24513, 44829",
        "kiosk": false
    },
    {
        "value": "809301",
        "label": "Eyesight Training",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62149804",
        "yellow_page_code": "30406, 91250, 91212",
        "kiosk": false
    },
    {
        "value": "809302",
        "label": "Respiratory Therapy",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62149808",
        "yellow_page_code": "71903",
        "kiosk": false
    },
    {
        "value": "809303",
        "label": "Outpatient Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62149807",
        "yellow_page_code": "58109",
        "kiosk": false
    },
    {
        "value": "809304",
        "label": "Abortion Information & Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62141001",
        "yellow_page_code": "00031, 00040, 00075, 00005, 00032",
        "kiosk": false
    },
    {
        "value": "809305",
        "label": "Outpatient Mental Health & Substance Abuse Centers ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62142002",
        "yellow_page_code": "51209",
        "kiosk": false
    },
    {
        "value": "809306",
        "label": "Health Related Facilities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62142001",
        "yellow_page_code": "40507",
        "kiosk": false
    },
    {
        "value": "809307",
        "label": "Emergency Minor Medical Facilities/Svcs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62149803",
        "yellow_page_code": "28421, 28411",
        "kiosk": false
    },
    {
        "value": "809308",
        "label": "Surgical Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62149810",
        "yellow_page_code": "02107, 82315",
        "kiosk": false
    },
    {
        "value": "809309",
        "label": "Body Movement Education",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62149801",
        "yellow_page_code": "08837",
        "kiosk": false
    },
    {
        "value": "809310",
        "label": "Hyperbaric Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62149806",
        "yellow_page_code": "42131",
        "kiosk": false
    },
    {
        "value": "809311",
        "label": "Emergency Industrial Medical Units",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62149802",
        "yellow_page_code": "28409",
        "kiosk": false
    },
    {
        "value": "809312",
        "label": "Hair Clinics",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62149805",
        "yellow_page_code": "39621",
        "kiosk": false
    },
    {
        "value": "809313",
        "label": "Industrial Medicine",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62149811",
        "yellow_page_code": "42928",
        "kiosk": false
    },
    {
        "value": "809314",
        "label": "Integrated Medicine",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62149812",
        "yellow_page_code": "43512",
        "kiosk": false
    },
    {
        "value": "809315",
        "label": "Infertility Counseling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62141006",
        "yellow_page_code": "43022",
        "kiosk": false
    },
    {
        "value": "809316",
        "label": "Sleep Sensors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62149813",
        "yellow_page_code": "78337",
        "kiosk": false
    },
    {
        "value": "809317",
        "label": "Freestanding Ambulatory Surgical & Emergency Centers ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62149304",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "809318",
        "label": "Family Planning Centers ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62141007",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "809319",
        "label": "Rehabilitation Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62134019",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "809399",
        "label": "Specialty Outpatient Clinics NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62149809",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "809901",
        "label": "Physicians & Surgeons Information Bureau",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199946",
        "yellow_page_code": "61304, 62303, 61302, 51050, 62314, 62317",
        "kiosk": false
    },
    {
        "value": "809902",
        "label": "Dentist Information Bureaus",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199909",
        "yellow_page_code": "23907, 21141, 23910, 23810, 23905",
        "kiosk": false
    },
    {
        "value": "809903",
        "label": "Dentists Service Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199910",
        "yellow_page_code": "24000, 23918, 24001, 23906",
        "kiosk": false
    },
    {
        "value": "809904",
        "label": "Health Maintenance Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199919",
        "yellow_page_code": "40542, 40531, 40506",
        "kiosk": false
    },
    {
        "value": "809905",
        "label": "Health & Welfare Agencies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199915",
        "yellow_page_code": "93130, 40449, 40442, 40522, 40406, 40422",
        "kiosk": false
    },
    {
        "value": "809906",
        "label": "Health Information & Referral Programs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199918",
        "yellow_page_code": "40513, 37019, 51185, 40443, 40510, 51018",
        "kiosk": false
    },
    {
        "value": "809907",
        "label": "Health Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199921",
        "yellow_page_code": "66813, 40509, 40529, 40427, 13416, 40521, 75474, 29624",
        "kiosk": false
    },
    {
        "value": "809908",
        "label": "Hypnotherapy",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62199927",
        "yellow_page_code": "42215, 42210, 42208",
        "kiosk": false
    },
    {
        "value": "809909",
        "label": "Holistic Health Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199926",
        "yellow_page_code": "49410, 41190, 55101, 41121, 41189, 40395, 55129",
        "kiosk": false
    },
    {
        "value": "809910",
        "label": "Mechanotherapists Dm",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62199929",
        "yellow_page_code": "51001",
        "kiosk": false
    },
    {
        "value": "809911",
        "label": "Medical Groups",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199933",
        "yellow_page_code": "51002",
        "kiosk": false
    },
    {
        "value": "809912",
        "label": "Medical Information Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199934",
        "yellow_page_code": "43007, 51009",
        "kiosk": false
    },
    {
        "value": "809913",
        "label": "Medical & Surgical Svc Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199930",
        "yellow_page_code": "62320, 51026, 51006, 30131",
        "kiosk": false
    },
    {
        "value": "809914",
        "label": "Cognitive Disability Information Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199936",
        "yellow_page_code": "51300, 51270",
        "kiosk": false
    },
    {
        "value": "809915",
        "label": "Medical Examinations-Insurance",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199931",
        "yellow_page_code": "43470, 51109, 43430, 61306",
        "kiosk": false
    },
    {
        "value": "809916",
        "label": "Blood & Organ Banks & Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199101",
        "yellow_page_code": "08504, 08516, 08517, 66116",
        "kiosk": false
    },
    {
        "value": "809917",
        "label": "Naprapaths",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199938",
        "yellow_page_code": "55200",
        "kiosk": false
    },
    {
        "value": "809918",
        "label": "Chiropractic Doctor Info Bureaus",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199905",
        "yellow_page_code": "62309, 15502, 15517, 15510, 62401, 15507",
        "kiosk": false
    },
    {
        "value": "809919",
        "label": "Childbirth Education",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62141003",
        "yellow_page_code": "68015, 15126, 15203",
        "kiosk": false
    },
    {
        "value": "809920",
        "label": "Colonic Irrigation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199906",
        "yellow_page_code": "19710, 19708, 19712, 19711, 42112",
        "kiosk": false
    },
    {
        "value": "809921",
        "label": "Wellness Programs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199952",
        "yellow_page_code": "93299, 93300, 93298",
        "kiosk": false
    },
    {
        "value": "809922",
        "label": "Drugless Practitioners",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199911",
        "yellow_page_code": "26606",
        "kiosk": false
    },
    {
        "value": "809923",
        "label": "Oxygen Therapy Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199940",
        "yellow_page_code": "58216, 58208, 58213",
        "kiosk": false
    },
    {
        "value": "809924",
        "label": "Organ & Tissue Banks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199102",
        "yellow_page_code": "57900",
        "kiosk": false
    },
    {
        "value": "809925",
        "label": "Photographers-Medical & Dental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199943",
        "yellow_page_code": "61003",
        "kiosk": false
    },
    {
        "value": "809926",
        "label": "Parapsychologists",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199942",
        "yellow_page_code": "59304",
        "kiosk": false
    },
    {
        "value": "809927",
        "label": "Poison Control Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199948",
        "yellow_page_code": "67000",
        "kiosk": false
    },
    {
        "value": "809928",
        "label": "Sperm Banks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199950",
        "yellow_page_code": "79900",
        "kiosk": false
    },
    {
        "value": "809929",
        "label": "Reflexologists",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199949",
        "yellow_page_code": "71204",
        "kiosk": false
    },
    {
        "value": "809930",
        "label": "Acupuncture Information & Referral Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199957",
        "yellow_page_code": "00018",
        "kiosk": false
    },
    {
        "value": "809931",
        "label": "Audiometric Testing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199902",
        "yellow_page_code": "04401",
        "kiosk": false
    },
    {
        "value": "809932",
        "label": "Optometrists Referral & Info Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199939",
        "yellow_page_code": "57710, 57709",
        "kiosk": false
    },
    {
        "value": "809933",
        "label": "Aids Information & Testing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199901",
        "yellow_page_code": "00836, 00827, 00826, 00828",
        "kiosk": false
    },
    {
        "value": "809935",
        "label": "Bed Wetting Control Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199903",
        "yellow_page_code": "07314, 07402",
        "kiosk": false
    },
    {
        "value": "809936",
        "label": "Health Education",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199917",
        "yellow_page_code": "40503",
        "kiosk": false
    },
    {
        "value": "809937",
        "label": "Health Recording Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199920",
        "yellow_page_code": "40603",
        "kiosk": false
    },
    {
        "value": "809938",
        "label": "Hearing Testing-Industrial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199924",
        "yellow_page_code": "40701",
        "kiosk": false
    },
    {
        "value": "809939",
        "label": "Medical Examiners",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199932",
        "yellow_page_code": "51201, 05410, 21714",
        "kiosk": false
    },
    {
        "value": "809940",
        "label": "First Aid Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199912",
        "yellow_page_code": "28412, 32303, 32750",
        "kiosk": false
    },
    {
        "value": "809941",
        "label": "Hearing Aid-Acousticians",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199923",
        "yellow_page_code": "40534",
        "kiosk": false
    },
    {
        "value": "809942",
        "label": "Herbalists",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199925",
        "yellow_page_code": "40936",
        "kiosk": false
    },
    {
        "value": "809943",
        "label": "Health Care Utilization Review",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199916",
        "yellow_page_code": "40514",
        "kiosk": false
    },
    {
        "value": "809944",
        "label": "Physical Culture Institutes",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199944",
        "yellow_page_code": "61109",
        "kiosk": false
    },
    {
        "value": "809945",
        "label": "Hearing Imparied Relay Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199960",
        "yellow_page_code": "85021",
        "kiosk": false
    },
    {
        "value": "809946",
        "label": "Hand Therapy",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199913",
        "yellow_page_code": "39814",
        "kiosk": false
    },
    {
        "value": "809947",
        "label": "Birth Preparation Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62141002",
        "yellow_page_code": "08307",
        "kiosk": false
    },
    {
        "value": "809948",
        "label": "Medical Emergency Training",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199907",
        "yellow_page_code": "22210, 22220, 08506, 28490, 51025",
        "kiosk": false
    },
    {
        "value": "809950",
        "label": "Milk Banks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199937",
        "yellow_page_code": "52316",
        "kiosk": false
    },
    {
        "value": "809951",
        "label": "Medical Services-Mobile",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199935",
        "yellow_page_code": "51020",
        "kiosk": false
    },
    {
        "value": "809952",
        "label": "Physical Therapists' Referral & Info Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199945",
        "yellow_page_code": "61207",
        "kiosk": false
    },
    {
        "value": "809953",
        "label": "Plasma Collectors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199947",
        "yellow_page_code": "66105",
        "kiosk": false
    },
    {
        "value": "809955",
        "label": "Pacemaker Testing Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199941",
        "yellow_page_code": "58236",
        "kiosk": false
    },
    {
        "value": "809956",
        "label": "Health Services-Extended Care",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199922",
        "yellow_page_code": "40525",
        "kiosk": false
    },
    {
        "value": "809957",
        "label": "Health Care Alternatives",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199953",
        "yellow_page_code": "40410",
        "kiosk": false
    },
    {
        "value": "809958",
        "label": "Hypnotists' Referral Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199955",
        "yellow_page_code": "42214",
        "kiosk": false
    },
    {
        "value": "809959",
        "label": "Laser Treatments",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199954",
        "yellow_page_code": "46033, 46019, 46020",
        "kiosk": false
    },
    {
        "value": "809960",
        "label": "Learning Disabilities Information Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199956",
        "yellow_page_code": "46515, 27019",
        "kiosk": false
    },
    {
        "value": "809963",
        "label": "Polarity Therapy",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199904",
        "yellow_page_code": "67001",
        "kiosk": false
    },
    {
        "value": "809964",
        "label": "Egg Donation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199103",
        "yellow_page_code": "27237",
        "kiosk": false
    },
    {
        "value": "809965",
        "label": "Medical Alert Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199958",
        "yellow_page_code": "55910, 51062",
        "kiosk": false
    },
    {
        "value": "809966",
        "label": "Eye Banks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199104",
        "yellow_page_code": "30403",
        "kiosk": false
    },
    {
        "value": "809967",
        "label": "Hiv Information & Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199959",
        "yellow_page_code": "41026",
        "kiosk": false
    },
    {
        "value": "809968",
        "label": "Transplant-Organs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199105",
        "yellow_page_code": "88315",
        "kiosk": false
    },
    {
        "value": "809969",
        "label": "Aids & Hiv Counseling Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199908",
        "yellow_page_code": "00830",
        "kiosk": false
    },
    {
        "value": "809970",
        "label": "Trucking-Dot Medical Exams",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199961",
        "yellow_page_code": "89026",
        "kiosk": false
    },
    {
        "value": "809971",
        "label": "Bone Marrow Registry",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199106",
        "yellow_page_code": "09191",
        "kiosk": false
    },
    {
        "value": "809972",
        "label": "Organ & Tissue Banks Info & Referral Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199107",
        "yellow_page_code": "57906",
        "kiosk": false
    },
    {
        "value": "809973",
        "label": "Spinal Decompression",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199951",
        "yellow_page_code": "79925",
        "kiosk": false
    },
    {
        "value": "809974",
        "label": "Sleep Disorders-Diagnostic/Treatment",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62199962",
        "yellow_page_code": "78319, 78310, 78326, 78336",
        "kiosk": false
    },
    {
        "value": "809975",
        "label": "Ambulatory Health Care Services ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199963",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "809999",
        "label": "Health & Allied Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62199914",
        "yellow_page_code": "01935",
        "kiosk": false
    },
    {
        "value": "811101",
        "label": "Attorneys Service Bureaus",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54111003",
        "yellow_page_code": "46407, 46405, 04209, 04210, 04225, 46409",
        "kiosk": false
    },
    {
        "value": "811102",
        "label": "Attorney Referral Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54111001",
        "yellow_page_code": "04212, 04206, 46403",
        "kiosk": false
    },
    {
        "value": "811103",
        "label": "Attorneys",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "54111002",
        "yellow_page_code": "04435, 43363, 11729, 79471, 85030, 30620, 46224, 67924, 04538, 60327, 29627, 59699, 59613, 46408, 46404, 04208, 87802, 59700, 21606",
        "kiosk": false
    },
    {
        "value": "811104",
        "label": "Immigration & Naturalization Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54111009",
        "yellow_page_code": "29897, 28491, 42618, 42620, 42608",
        "kiosk": false
    },
    {
        "value": "811105",
        "label": "Referees In Bankruptcy",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54111020",
        "yellow_page_code": "71112",
        "kiosk": false
    },
    {
        "value": "811106",
        "label": "Social Security Counselors & Reps",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54111023",
        "yellow_page_code": "20996, 78918, 78919, 78911, 78915",
        "kiosk": false
    },
    {
        "value": "811107",
        "label": "Probate Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54111019",
        "yellow_page_code": "68510, 68503",
        "kiosk": false
    },
    {
        "value": "811108",
        "label": "Patent Agents",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54111018",
        "yellow_page_code": "59608, 59701",
        "kiosk": false
    },
    {
        "value": "811109",
        "label": "Dedimus Justices",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54111004",
        "yellow_page_code": "23616",
        "kiosk": false
    },
    {
        "value": "811110",
        "label": "Right Of Way Acquisition Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54111021",
        "yellow_page_code": "72300",
        "kiosk": false
    },
    {
        "value": "811111",
        "label": "Divorce Assistance",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54111005",
        "yellow_page_code": "25216, 25209, 15149, 25225, 22080, 25300, 25215, 25217, 25213, 15155",
        "kiosk": false
    },
    {
        "value": "811112",
        "label": "Incorporating Companies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54111010",
        "yellow_page_code": "42808",
        "kiosk": false
    },
    {
        "value": "811113",
        "label": "Legal Service Plans",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54111015",
        "yellow_page_code": "46905, 04204, 46915",
        "kiosk": false
    },
    {
        "value": "811114",
        "label": "Legal Clinics",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54111012",
        "yellow_page_code": "46900",
        "kiosk": false
    },
    {
        "value": "811115",
        "label": "Legal Research",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54111014",
        "yellow_page_code": "46892, 46901",
        "kiosk": false
    },
    {
        "value": "811116",
        "label": "Lien Processing Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54111017",
        "yellow_page_code": "47119, 47109",
        "kiosk": false
    },
    {
        "value": "811117",
        "label": "Law Citation Retrieval Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54111011",
        "yellow_page_code": "46203",
        "kiosk": false
    },
    {
        "value": "811118",
        "label": "Legal Marketing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54111013",
        "yellow_page_code": "46891",
        "kiosk": false
    },
    {
        "value": "811119",
        "label": "Divorce Kits-DO It Yourself",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54111006",
        "yellow_page_code": "25212",
        "kiosk": false
    },
    {
        "value": "811120",
        "label": "Estate Planning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54111008",
        "yellow_page_code": "67949, 93528, 93421, 29838, 48317, 29903, 29839",
        "kiosk": false
    },
    {
        "value": "811122",
        "label": "Small Claims Assistance",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54111022",
        "yellow_page_code": "78416",
        "kiosk": false
    },
    {
        "value": "811123",
        "label": "Real Estate-Law",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "54111024",
        "yellow_page_code": "70733",
        "kiosk": false
    },
    {
        "value": "811198",
        "label": "Legal Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54119907",
        "yellow_page_code": "46816, 46819, 59330",
        "kiosk": false
    },
    {
        "value": "821101",
        "label": "Religious Schools",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61111004",
        "yellow_page_code": "85020, 74615, 82991, 71603, 71540",
        "kiosk": false
    },
    {
        "value": "821102",
        "label": "Schools For Blind",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61111008",
        "yellow_page_code": "74412",
        "kiosk": false
    },
    {
        "value": "821103",
        "label": "Schools",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61111007",
        "yellow_page_code": "79740, 74452, 30138, 25890, 79122, 74438, 68440, 74458, 52315, 74696, 74695, 74693, 74450, 74449, 74429, 74602, 74508, 74606, 74600, 74408, 74413, 44901",
        "kiosk": false
    },
    {
        "value": "821105",
        "label": "Montessori Schools",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61111003",
        "yellow_page_code": "74515",
        "kiosk": false
    },
    {
        "value": "821106",
        "label": "Schools-Cognitive Disabilities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61111012",
        "yellow_page_code": "74505, 74442",
        "kiosk": false
    },
    {
        "value": "821107",
        "label": "Schools With Special Academic Education",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61111010",
        "yellow_page_code": "79734, 71840, 46530, 74700, 74697, 74698, 74440, 20819, 74459",
        "kiosk": false
    },
    {
        "value": "821108",
        "label": "Educational Programs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169103",
        "yellow_page_code": "74433, 35919, 36890, 27214, 00403, 27101, 27220, 74407, 43720",
        "kiosk": false
    },
    {
        "value": "821109",
        "label": "Schools-Preparatory",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61111013",
        "yellow_page_code": "67941, 74509",
        "kiosk": false
    },
    {
        "value": "821110",
        "label": "Schools-Speech Defect Correction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61111014",
        "yellow_page_code": "74415",
        "kiosk": false
    },
    {
        "value": "821111",
        "label": "Schools For The Hearing Impaired",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61111009",
        "yellow_page_code": "74601",
        "kiosk": false
    },
    {
        "value": "821112",
        "label": "Lip Reading Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61111002",
        "yellow_page_code": "47801",
        "kiosk": false
    },
    {
        "value": "821113",
        "label": "Boards Of Education",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61111005",
        "yellow_page_code": "30028, 74335, 08621",
        "kiosk": false
    },
    {
        "value": "821114",
        "label": "Home Schooling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61111016",
        "yellow_page_code": "74503, 41245",
        "kiosk": false
    },
    {
        "value": "821116",
        "label": "Child Care Certificate Program",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61111017",
        "yellow_page_code": "15131",
        "kiosk": false
    },
    {
        "value": "821117",
        "label": "Mosques Muslim-Islamic Educational Instn",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61111006",
        "yellow_page_code": "53691",
        "kiosk": false
    },
    {
        "value": "821118",
        "label": "Temples Buddhist Educational Institution",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61111011",
        "yellow_page_code": "84995",
        "kiosk": false
    },
    {
        "value": "821119",
        "label": "Bartending School",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61151930",
        "yellow_page_code": "06713",
        "kiosk": false
    },
    {
        "value": "821120",
        "label": "School Districts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61111018",
        "yellow_page_code": "74469",
        "kiosk": false
    },
    {
        "value": "821121",
        "label": "Schools-Departments/Facilities/Offices",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61111021",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "822101",
        "label": "Schools-Universities & Colleges Academic",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61131009",
        "yellow_page_code": "74464, 20017, 19614, 74608, 89905, 29931",
        "kiosk": false
    },
    {
        "value": "822102",
        "label": "Theological Schools",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61131010",
        "yellow_page_code": "85605, 75413",
        "kiosk": false
    },
    {
        "value": "822103",
        "label": "Law Schools",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61131004",
        "yellow_page_code": "46111, 74462",
        "kiosk": false
    },
    {
        "value": "822104",
        "label": "Psycho-Education",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61131005",
        "yellow_page_code": "68736",
        "kiosk": false
    },
    {
        "value": "822105",
        "label": "Government-Colleges & Professional Schls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61131003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "822106",
        "label": "Radiology Schools",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61131006",
        "yellow_page_code": "70001",
        "kiosk": false
    },
    {
        "value": "822107",
        "label": "Schools-Chiropractic",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61131007",
        "yellow_page_code": "15503, 74436",
        "kiosk": false
    },
    {
        "value": "822108",
        "label": "Schools-Medical",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61131008",
        "yellow_page_code": "51200, 74511",
        "kiosk": false
    },
    {
        "value": "822109",
        "label": "Dental Schools",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61131001",
        "yellow_page_code": "23901",
        "kiosk": false
    },
    {
        "value": "822110",
        "label": "Engineering Schools",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61131002",
        "yellow_page_code": "28714",
        "kiosk": false
    },
    {
        "value": "822111",
        "label": "Pharmaceutical Schools",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61131011",
        "yellow_page_code": "60621",
        "kiosk": false
    },
    {
        "value": "822113",
        "label": "University-College Dept/Facility/Office",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61131013",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "822114",
        "label": "Univ/Clg-Governing Body/Regent/Trustee",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61131014",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "822115",
        "label": "Universities/Colleges-Online Only",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61131015",
        "yellow_page_code": "21800",
        "kiosk": false
    },
    {
        "value": "822116",
        "label": "University/College-Satellite Locations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61131016",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "822117",
        "label": "University/College-Graduate Schools",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61131017",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "822118",
        "label": "University/College-Extension Programs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61131018",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "822119",
        "label": "University & College-Off Campus Classes",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61131019",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "822120",
        "label": "Veterinarian Schools",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61131020",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "822201",
        "label": "Business Colleges",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61121001",
        "yellow_page_code": "11801",
        "kiosk": false
    },
    {
        "value": "822202",
        "label": "Schools-Hypnotherapy",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61121003",
        "yellow_page_code": "74456",
        "kiosk": false
    },
    {
        "value": "822203",
        "label": "Schools-Computers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61121004",
        "yellow_page_code": "74437",
        "kiosk": false
    },
    {
        "value": "822298",
        "label": "Junior-Community College-Tech Institutes",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61121002",
        "yellow_page_code": "84196, 83419, 84198",
        "kiosk": false
    },
    {
        "value": "823101",
        "label": "Federal Government-Libraries",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51921003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "823102",
        "label": "State Government-Libraries",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51921010",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "823103",
        "label": "County Government-Libraries",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51921002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "823104",
        "label": "City Government-Libraries",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51921001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "823105",
        "label": "Library Networks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51921007",
        "yellow_page_code": "47103",
        "kiosk": false
    },
    {
        "value": "823106",
        "label": "Libraries-Public",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51921006",
        "yellow_page_code": "47013, 47102, 31616, 47020",
        "kiosk": false
    },
    {
        "value": "823107",
        "label": "Libraries-Circulating & Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51921004",
        "yellow_page_code": "47008",
        "kiosk": false
    },
    {
        "value": "823108",
        "label": "Library Research & Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51921008",
        "yellow_page_code": "47105, 47019",
        "kiosk": false
    },
    {
        "value": "823109",
        "label": "Libraries-Institutional",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51921005",
        "yellow_page_code": "47100",
        "kiosk": false
    },
    {
        "value": "823111",
        "label": "Special Interest Libraries",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51921009",
        "yellow_page_code": "54709",
        "kiosk": false
    },
    {
        "value": "824301",
        "label": "Computer Training",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61142001",
        "yellow_page_code": "20378, 20256, 20361, 20343, 20331",
        "kiosk": false
    },
    {
        "value": "824302",
        "label": "Computers-Programming Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61142002",
        "yellow_page_code": "20327, 74424",
        "kiosk": false
    },
    {
        "value": "824303",
        "label": "Internet Services-Internet Training",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61142003",
        "yellow_page_code": "43548",
        "kiosk": false
    },
    {
        "value": "824401",
        "label": "Schools-Business & Secretarial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61141004",
        "yellow_page_code": "09225, 11811, 84123, 30360, 13933, 74425, 75212, 74500, 74409",
        "kiosk": false
    },
    {
        "value": "824403",
        "label": "Court Reporting Schools",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61141001",
        "yellow_page_code": "22109",
        "kiosk": false
    },
    {
        "value": "824408",
        "label": "Mortgage Brokers School",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61141008",
        "yellow_page_code": "53615",
        "kiosk": false
    },
    {
        "value": "824901",
        "label": "Nurses Schools",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61151911",
        "yellow_page_code": "74510, 55908",
        "kiosk": false
    },
    {
        "value": "824903",
        "label": "Schools-Trade & Vocational",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61151916",
        "yellow_page_code": "19631, 74445, 74414, 74502, 87709, 74444, 74426, 74422, 93113, 91257, 74421, 74420, 30065",
        "kiosk": false
    },
    {
        "value": "824904",
        "label": "Real Estate Schools",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61151914",
        "yellow_page_code": "70800",
        "kiosk": false
    },
    {
        "value": "824905",
        "label": "Sewing Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61151919",
        "yellow_page_code": "75904, 92808",
        "kiosk": false
    },
    {
        "value": "824906",
        "label": "Schools-Medical & Dental-Assistants/Tech",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61151917",
        "yellow_page_code": "74504, 51063, 24110",
        "kiosk": false
    },
    {
        "value": "824907",
        "label": "Truck Driving Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61151924",
        "yellow_page_code": "74607, 47015, 88707, 41029",
        "kiosk": false
    },
    {
        "value": "824909",
        "label": "Pet Grooming Instructions",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61151912",
        "yellow_page_code": "60509, 60421",
        "kiosk": false
    },
    {
        "value": "824911",
        "label": "Television & Radio Broadcasting Schools",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61151921",
        "yellow_page_code": "84601, 74410, 10810, 69903",
        "kiosk": false
    },
    {
        "value": "824914",
        "label": "Acupuncture Schools",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61151901",
        "yellow_page_code": "00211",
        "kiosk": false
    },
    {
        "value": "824915",
        "label": "Advertising-Schools",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61151902",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "824916",
        "label": "Security Guard Schools",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61151918",
        "yellow_page_code": "75309",
        "kiosk": false
    },
    {
        "value": "824917",
        "label": "Taxidermy Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61151920",
        "yellow_page_code": "84107",
        "kiosk": false
    },
    {
        "value": "824918",
        "label": "Insurance-Schools",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61151908",
        "yellow_page_code": "43513, 74455, 03010",
        "kiosk": false
    },
    {
        "value": "824919",
        "label": "Physical Therapy Schools",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61151913",
        "yellow_page_code": "61204",
        "kiosk": false
    },
    {
        "value": "824922",
        "label": "Navigation Schools",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61151910",
        "yellow_page_code": "55208, 55210",
        "kiosk": false
    },
    {
        "value": "824923",
        "label": "Lead Paint Detection & Removal Schools",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61151909",
        "yellow_page_code": "46513",
        "kiosk": false
    },
    {
        "value": "824925",
        "label": "Schools Yoga",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61151926",
        "yellow_page_code": "74609",
        "kiosk": false
    },
    {
        "value": "824926",
        "label": "Forklifts-Training & Certification",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61151927",
        "yellow_page_code": "34123",
        "kiosk": false
    },
    {
        "value": "824950",
        "label": "Casino Training",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61151903",
        "yellow_page_code": "13925",
        "kiosk": false
    },
    {
        "value": "824951",
        "label": "Police Academies & Training",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92212007",
        "yellow_page_code": "67020",
        "kiosk": false
    },
    {
        "value": "824952",
        "label": "Fire Training Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61151302",
        "yellow_page_code": "32117",
        "kiosk": false
    },
    {
        "value": "824954",
        "label": "Aircraft Schools",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61151206",
        "yellow_page_code": "01418, 74454, 01600, 01427, 01429, 01421, 05327",
        "kiosk": false
    },
    {
        "value": "824955",
        "label": "Cosmetology & Barber Schools ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61151108",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "829901",
        "label": "Schools-General Interest",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169935",
        "yellow_page_code": "30361, 74501",
        "kiosk": false
    },
    {
        "value": "829902",
        "label": "Driving Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169201",
        "yellow_page_code": "26406, 87807, 74439, 26415, 23721, 42622, 54244, 26344, 01929",
        "kiosk": false
    },
    {
        "value": "829903",
        "label": "School & College Information",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169930",
        "yellow_page_code": "74403, 74466",
        "kiosk": false
    },
    {
        "value": "829904",
        "label": "Personal Development Schools",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169924",
        "yellow_page_code": "60306, 74514",
        "kiosk": false
    },
    {
        "value": "829905",
        "label": "Vocational Guidance",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169942",
        "yellow_page_code": "91206",
        "kiosk": false
    },
    {
        "value": "829906",
        "label": "Public Speaking Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61143004",
        "yellow_page_code": "74604, 60325, 28390, 68900",
        "kiosk": false
    },
    {
        "value": "829907",
        "label": "Photography Schools",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169927",
        "yellow_page_code": "61108, 61115, 61123, 61119",
        "kiosk": false
    },
    {
        "value": "829908",
        "label": "Reading Improvement Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61171008",
        "yellow_page_code": "47990, 00391, 47999, 46521, 70513, 70604",
        "kiosk": false
    },
    {
        "value": "829909",
        "label": "Tutoring",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169102",
        "yellow_page_code": "50507, 27142, 89602, 50319, 41268",
        "kiosk": false
    },
    {
        "value": "829910",
        "label": "First Aid Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169913",
        "yellow_page_code": "32305, 32316",
        "kiosk": false
    },
    {
        "value": "829911",
        "label": "Professional & Management Development Training ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61143002",
        "yellow_page_code": "49802",
        "kiosk": false
    },
    {
        "value": "829912",
        "label": "Language Schools",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61163001",
        "yellow_page_code": "45910, 74448, 45904, 29418, 29390",
        "kiosk": false
    },
    {
        "value": "829913",
        "label": "Modeling Schools",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169921",
        "yellow_page_code": "74419, 74512, 53306",
        "kiosk": false
    },
    {
        "value": "829914",
        "label": "Massage Schools",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169919",
        "yellow_page_code": "50402, 74453",
        "kiosk": false
    },
    {
        "value": "829915",
        "label": "Music Instruction-Vocal",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61161006",
        "yellow_page_code": "91256, 77805, 54708, 91228",
        "kiosk": false
    },
    {
        "value": "829916",
        "label": "Motivational & Self Improvement Training",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169922",
        "yellow_page_code": "54200, 67442, 54195",
        "kiosk": false
    },
    {
        "value": "829918",
        "label": "Music Instruction-Instrumental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61161005",
        "yellow_page_code": "89803, 39312, 91195, 54706, 54707",
        "kiosk": false
    },
    {
        "value": "829919",
        "label": "Art Instruction & Schools",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61161001",
        "yellow_page_code": "03515, 03506, 74417, 03610, 03429",
        "kiosk": false
    },
    {
        "value": "829920",
        "label": "Airline Training Schools",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61151202",
        "yellow_page_code": "01704",
        "kiosk": false
    },
    {
        "value": "829921",
        "label": "Ceramics-Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169908",
        "yellow_page_code": "14501",
        "kiosk": false
    },
    {
        "value": "829922",
        "label": "Baton Twirling Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169905",
        "yellow_page_code": "89607, 07004",
        "kiosk": false
    },
    {
        "value": "829923",
        "label": "Craft-Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169911",
        "yellow_page_code": "22300, 22239, 03511, 74521",
        "kiosk": false
    },
    {
        "value": "829924",
        "label": "Schools-Cooking",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169933",
        "yellow_page_code": "14024, 21406, 74406, 22814, 21415",
        "kiosk": false
    },
    {
        "value": "829926",
        "label": "Educational Cooperative Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61171002",
        "yellow_page_code": "15154, 27205, 27139",
        "kiosk": false
    },
    {
        "value": "829927",
        "label": "Driving Proficiency Test Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169202",
        "yellow_page_code": "26408",
        "kiosk": false
    },
    {
        "value": "829928",
        "label": "Knitting Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169918",
        "yellow_page_code": "45008",
        "kiosk": false
    },
    {
        "value": "829929",
        "label": "Educational Service-Business",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61171003",
        "yellow_page_code": "27208, 27137, 75471",
        "kiosk": false
    },
    {
        "value": "829930",
        "label": "Programmed Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61143003",
        "yellow_page_code": "68603",
        "kiosk": false
    },
    {
        "value": "829931",
        "label": "Training Programs & Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61143006",
        "yellow_page_code": "88117, 88203",
        "kiosk": false
    },
    {
        "value": "829932",
        "label": "Language Training Aids",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61171005",
        "yellow_page_code": "45906",
        "kiosk": false
    },
    {
        "value": "829934",
        "label": "Piano-Instructions",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61161008",
        "yellow_page_code": "64905",
        "kiosk": false
    },
    {
        "value": "829935",
        "label": "Schools-Travel Agents",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169936",
        "yellow_page_code": "74434",
        "kiosk": false
    },
    {
        "value": "829937",
        "label": "Academic Career Coordination",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61143001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "829938",
        "label": "Music Workshops",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61161007",
        "yellow_page_code": "71310, 54813, 54910",
        "kiosk": false
    },
    {
        "value": "829939",
        "label": "Speaking-Voice Improvement",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61163003",
        "yellow_page_code": "79709",
        "kiosk": false
    },
    {
        "value": "829940",
        "label": "Schools-College Board Preparation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61171009",
        "yellow_page_code": "74513",
        "kiosk": false
    },
    {
        "value": "829941",
        "label": "Literacy",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61171006",
        "yellow_page_code": "47911",
        "kiosk": false
    },
    {
        "value": "829942",
        "label": "Student Exchange Programs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169938",
        "yellow_page_code": "82120",
        "kiosk": false
    },
    {
        "value": "829943",
        "label": "Flight Aircraft Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61151203",
        "yellow_page_code": "33102, 33639, 33636, 33108",
        "kiosk": false
    },
    {
        "value": "829944",
        "label": "Schools With Special Vocational Educ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169931",
        "yellow_page_code": "74701",
        "kiosk": false
    },
    {
        "value": "829945",
        "label": "Schools-Acrobatic",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169932",
        "yellow_page_code": "74432",
        "kiosk": false
    },
    {
        "value": "829946",
        "label": "Schools-Fashion",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61161013",
        "yellow_page_code": "30937, 26302",
        "kiosk": false
    },
    {
        "value": "829947",
        "label": "Safety Training",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169929",
        "yellow_page_code": "73403",
        "kiosk": false
    },
    {
        "value": "829948",
        "label": "Asbestos Training Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169901",
        "yellow_page_code": "03918",
        "kiosk": false
    },
    {
        "value": "829949",
        "label": "Astrology Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169902",
        "yellow_page_code": "04110",
        "kiosk": false
    },
    {
        "value": "829950",
        "label": "Hiking Backpacking & Mountaineerings Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61162008",
        "yellow_page_code": "54314, 41021, 05620",
        "kiosk": false
    },
    {
        "value": "829951",
        "label": "Bar Review Courses",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169904",
        "yellow_page_code": "06501",
        "kiosk": false
    },
    {
        "value": "829952",
        "label": "Citizenship Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169909",
        "yellow_page_code": "18107",
        "kiosk": false
    },
    {
        "value": "829953",
        "label": "Civil Service Schools",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169910",
        "yellow_page_code": "18202",
        "kiosk": false
    },
    {
        "value": "829954",
        "label": "Foreign Exchange Students Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61171011",
        "yellow_page_code": "34007",
        "kiosk": false
    },
    {
        "value": "829955",
        "label": "Juggling Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169917",
        "yellow_page_code": "44603",
        "kiosk": false
    },
    {
        "value": "829956",
        "label": "Pottery Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169928",
        "yellow_page_code": "67703",
        "kiosk": false
    },
    {
        "value": "829957",
        "label": "Tailoring Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169939",
        "yellow_page_code": "83305",
        "kiosk": false
    },
    {
        "value": "829958",
        "label": "Program Service-Educational",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61171007",
        "yellow_page_code": "46507, 68601",
        "kiosk": false
    },
    {
        "value": "829959",
        "label": "Personality Development",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169925",
        "yellow_page_code": "60207",
        "kiosk": false
    },
    {
        "value": "829960",
        "label": "Personology",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169926",
        "yellow_page_code": "60405",
        "kiosk": false
    },
    {
        "value": "829961",
        "label": "Cardiopulmonary Resuscitation Info/Svcs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169906",
        "yellow_page_code": "13413",
        "kiosk": false
    },
    {
        "value": "829962",
        "label": "Casino Gambling Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169907",
        "yellow_page_code": "36016, 13929",
        "kiosk": false
    },
    {
        "value": "829963",
        "label": "Interior Decorating & Designing Schools",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169916",
        "yellow_page_code": "74461, 43509",
        "kiosk": false
    },
    {
        "value": "829964",
        "label": "Hospitality Training",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169915",
        "yellow_page_code": "41719, 41731",
        "kiosk": false
    },
    {
        "value": "829965",
        "label": "Neuro-Linguistic Programming",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61163002",
        "yellow_page_code": "55313",
        "kiosk": false
    },
    {
        "value": "829966",
        "label": "Memory Training Improvements",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169920",
        "yellow_page_code": "51214",
        "kiosk": false
    },
    {
        "value": "829967",
        "label": "Motorcycle Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169923",
        "yellow_page_code": "54321, 54305",
        "kiosk": false
    },
    {
        "value": "829968",
        "label": "Wood-Technologists",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169943",
        "yellow_page_code": "94014",
        "kiosk": false
    },
    {
        "value": "829969",
        "label": "Time Management Training",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61143005",
        "yellow_page_code": "86103",
        "kiosk": false
    },
    {
        "value": "829970",
        "label": "Etiquette Schools",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169912",
        "yellow_page_code": "29918, 29909",
        "kiosk": false
    },
    {
        "value": "829971",
        "label": "Employees Educational Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61171004",
        "yellow_page_code": "28512",
        "kiosk": false
    },
    {
        "value": "829972",
        "label": "Education Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61171001",
        "yellow_page_code": "46505, 27111",
        "kiosk": false
    },
    {
        "value": "829973",
        "label": "Traffic Law Representation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169204",
        "yellow_page_code": "87825, 87828",
        "kiosk": false
    },
    {
        "value": "829974",
        "label": "Telephone Training",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169940",
        "yellow_page_code": "84425",
        "kiosk": false
    },
    {
        "value": "829975",
        "label": "Sports & Recreation Instruction ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169937",
        "yellow_page_code": "80020",
        "kiosk": false
    },
    {
        "value": "829976",
        "label": "Dui Driving Schools",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169203",
        "yellow_page_code": "26817",
        "kiosk": false
    },
    {
        "value": "829977",
        "label": "Schools-Performing Arts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61161009",
        "yellow_page_code": "74460, 85601, 74613, 00201, 00212, 25902",
        "kiosk": false
    },
    {
        "value": "829978",
        "label": "Space Education Programs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92711005",
        "yellow_page_code": "79610",
        "kiosk": false
    },
    {
        "value": "829979",
        "label": "Schools Music",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61161010",
        "yellow_page_code": "54807, 54614, 74468",
        "kiosk": false
    },
    {
        "value": "829980",
        "label": "Circus Training & Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169945",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "829981",
        "label": "Music Instruction-Children",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61161014",
        "yellow_page_code": "55018, 54714",
        "kiosk": false
    },
    {
        "value": "829982",
        "label": "Protocol Etic & Etiquette-Seminars",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169903",
        "yellow_page_code": "68680",
        "kiosk": false
    },
    {
        "value": "829983",
        "label": "Helicopter Flight Training & Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61151204",
        "yellow_page_code": "40931",
        "kiosk": false
    },
    {
        "value": "829984",
        "label": "Outdoor Education",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169934",
        "yellow_page_code": "58130",
        "kiosk": false
    },
    {
        "value": "829985",
        "label": "Airplane Glider Helicopter Ride-Instrctn",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61151205",
        "yellow_page_code": "01710",
        "kiosk": false
    },
    {
        "value": "829986",
        "label": "Tactical Training",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169947",
        "yellow_page_code": "83296",
        "kiosk": false
    },
    {
        "value": "829987",
        "label": "Racing Schools",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169948",
        "yellow_page_code": "69513",
        "kiosk": false
    },
    {
        "value": "829988",
        "label": "Video Distance Learning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169949",
        "yellow_page_code": "91063",
        "kiosk": false
    },
    {
        "value": "829989",
        "label": "College Admissions Counselors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61171014",
        "yellow_page_code": "19613, 21094",
        "kiosk": false
    },
    {
        "value": "829990",
        "label": "Schools Natural Healing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169950",
        "yellow_page_code": "74470",
        "kiosk": false
    },
    {
        "value": "829991",
        "label": "Kids Cooking Classes/Schools",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169951",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "829992",
        "label": "Marching Units Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169952",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "829993",
        "label": "Fine Arts Schools ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61161015",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "829994",
        "label": "Exam Preparation & Tutoring ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169101",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "829998",
        "label": "Miscellaneous Schools & Instruction ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61169953",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "829999",
        "label": "Schools & Educational Services NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61171013",
        "yellow_page_code": "74603",
        "kiosk": false
    },
    {
        "value": "832201",
        "label": "Counseling Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62411004",
        "yellow_page_code": "60316, 22100, 22117, 22118, 22149, 22081, 60313, 60307",
        "kiosk": false
    },
    {
        "value": "832202",
        "label": "Child Guidance",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62411002",
        "yellow_page_code": "22140, 59314, 59307, 94812, 15202, 15107, 59315, 74463, 15117, 59313",
        "kiosk": false
    },
    {
        "value": "832203",
        "label": "Family Planning Information Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419001",
        "yellow_page_code": "08318, 08319, 08306, 30700, 30610, 93033, 08310, 08299",
        "kiosk": false
    },
    {
        "value": "832204",
        "label": "Counselors-Licensed Professional",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62419009",
        "yellow_page_code": "22102, 22120",
        "kiosk": false
    },
    {
        "value": "832205",
        "label": "Autism Counseling & Treatment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62411008",
        "yellow_page_code": "04404",
        "kiosk": false
    },
    {
        "value": "832206",
        "label": "Senior Citizens Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62412003",
        "yellow_page_code": "41323, 02021, 02022, 02009, 75520, 02023, 72079, 75477, 75507, 14416, 75510, 27320, 75526, 75525, 75501",
        "kiosk": false
    },
    {
        "value": "832207",
        "label": "Adoption Agencies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62411001",
        "yellow_page_code": "00409, 00404, 43654, 00311",
        "kiosk": false
    },
    {
        "value": "832208",
        "label": "Crisis Intervention Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62411005",
        "yellow_page_code": "25114, 36018, 70501, 94104, 07010, 22606",
        "kiosk": false
    },
    {
        "value": "832209",
        "label": "Mentoring",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419043",
        "yellow_page_code": "51319, 18934",
        "kiosk": false
    },
    {
        "value": "832210",
        "label": "Day Care Centers-Adult",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62412002",
        "yellow_page_code": "72070, 00395, 75513, 23508",
        "kiosk": false
    },
    {
        "value": "832211",
        "label": "Emergency Notification Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62423001",
        "yellow_page_code": "28502",
        "kiosk": false
    },
    {
        "value": "832212",
        "label": "Human Relations Counselors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419010",
        "yellow_page_code": "42009, 42019",
        "kiosk": false
    },
    {
        "value": "832213",
        "label": "Homemakers Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62412006",
        "yellow_page_code": "41310, 41400",
        "kiosk": false
    },
    {
        "value": "832214",
        "label": "Missions",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62422104",
        "yellow_page_code": "53002",
        "kiosk": false
    },
    {
        "value": "832215",
        "label": "Marriage & Family Counselors",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62419011",
        "yellow_page_code": "92989, 50217, 22128, 93039, 00417, 50204, 30626, 22012, 93035",
        "kiosk": false
    },
    {
        "value": "832216",
        "label": "Nursing Home Information & Referral",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62311022",
        "yellow_page_code": "55934, 56001",
        "kiosk": false
    },
    {
        "value": "832217",
        "label": "Psychologist Information/Referral Svcs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419033",
        "yellow_page_code": "68800, 68738",
        "kiosk": false
    },
    {
        "value": "832218",
        "label": "Social Service & Welfare Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419012",
        "yellow_page_code": "79012, 71513, 79000, 79005, 91241, 18895, 43655, 08432, 75478, 43775, 15152",
        "kiosk": false
    },
    {
        "value": "832219",
        "label": "Social Settlements",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62422903",
        "yellow_page_code": "47121, 79001",
        "kiosk": false
    },
    {
        "value": "832220",
        "label": "Storm Shelters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62422103",
        "yellow_page_code": "87330, 81901",
        "kiosk": false
    },
    {
        "value": "832221",
        "label": "Social Workers",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62133003",
        "yellow_page_code": "79002, 79013, 78912",
        "kiosk": false
    },
    {
        "value": "832222",
        "label": "Youth Organizations & Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62411006",
        "yellow_page_code": "94806, 10103, 94813",
        "kiosk": false
    },
    {
        "value": "832223",
        "label": "Stress Management Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419022",
        "yellow_page_code": "82002, 82015, 22127, 81995, 82010",
        "kiosk": false
    },
    {
        "value": "832224",
        "label": "Birth Control Alternative Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62141004",
        "yellow_page_code": "08305",
        "kiosk": false
    },
    {
        "value": "832225",
        "label": "Domestic Abuse Information & Treatment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62422101",
        "yellow_page_code": "25514, 22122, 25503, 80107, 22145, 25430",
        "kiosk": false
    },
    {
        "value": "832226",
        "label": "Senior Citizens Svc Orgs-Disc Merchants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62412007",
        "yellow_page_code": "75512",
        "kiosk": false
    },
    {
        "value": "832227",
        "label": "Mental Health Counselors",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62133004",
        "yellow_page_code": "22125, 51401, 30132",
        "kiosk": false
    },
    {
        "value": "832228",
        "label": "Referral Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419013",
        "yellow_page_code": "71201",
        "kiosk": false
    },
    {
        "value": "832229",
        "label": "Community Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419014",
        "yellow_page_code": "20122, 20111",
        "kiosk": false
    },
    {
        "value": "832230",
        "label": "Hotlines & Helping Lines",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419015",
        "yellow_page_code": "41813, 22603, 41821, 68012",
        "kiosk": false
    },
    {
        "value": "832231",
        "label": "Self-Esteem Programs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419027",
        "yellow_page_code": "75500",
        "kiosk": false
    },
    {
        "value": "832232",
        "label": "Sexual Counseling & Therapy",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419028",
        "yellow_page_code": "22146, 76010, 76011, 22147",
        "kiosk": false
    },
    {
        "value": "832233",
        "label": "Support Groups",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419016",
        "yellow_page_code": "82313",
        "kiosk": false
    },
    {
        "value": "832234",
        "label": "Diabetes Information Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419023",
        "yellow_page_code": "24319, 24403, 24411, 24413, 24317, 24312, 24414",
        "kiosk": false
    },
    {
        "value": "832235",
        "label": "Housing Assistance",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62422901",
        "yellow_page_code": "55317, 42023, 02841, 42015",
        "kiosk": false
    },
    {
        "value": "832236",
        "label": "Child Care Referral Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62411009",
        "yellow_page_code": "23514, 15123, 15136",
        "kiosk": false
    },
    {
        "value": "832237",
        "label": "Child Development-Parent/Child Education",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419034",
        "yellow_page_code": "74446, 15204, 15125, 27223",
        "kiosk": false
    },
    {
        "value": "832238",
        "label": "Sexual Assault & Abuse Crisis Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419017",
        "yellow_page_code": "70503, 70450, 22114, 76015",
        "kiosk": false
    },
    {
        "value": "832239",
        "label": "Hearing Impaired Services & Facilities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62412005",
        "yellow_page_code": "23509, 23601, 23520",
        "kiosk": false
    },
    {
        "value": "832240",
        "label": "Food Banks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62421001",
        "yellow_page_code": "33705, 33714, 33810",
        "kiosk": false
    },
    {
        "value": "832241",
        "label": "Homeless Shelters",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62422102",
        "yellow_page_code": "76221, 41111, 41236",
        "kiosk": false
    },
    {
        "value": "832242",
        "label": "Homes-Information & Placement Bureaus",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419029",
        "yellow_page_code": "41415",
        "kiosk": false
    },
    {
        "value": "832243",
        "label": "Crisis Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419018",
        "yellow_page_code": "22610, 22607",
        "kiosk": false
    },
    {
        "value": "832244",
        "label": "Social Services Info & Referral Programs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419003",
        "yellow_page_code": "78909, 22613",
        "kiosk": false
    },
    {
        "value": "832245",
        "label": "Social Workers-Clinical",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62419044",
        "yellow_page_code": "79003",
        "kiosk": false
    },
    {
        "value": "832246",
        "label": "Self Imprvmnt/Motivation-Training Mtrls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419045",
        "yellow_page_code": "75499",
        "kiosk": false
    },
    {
        "value": "832247",
        "label": "Crime Victim Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419030",
        "yellow_page_code": "22602",
        "kiosk": false
    },
    {
        "value": "832248",
        "label": "Information & Referral Svcs-Hlth Prgrms",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419004",
        "yellow_page_code": "43112",
        "kiosk": false
    },
    {
        "value": "832249",
        "label": "Learning Disability Counseling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419019",
        "yellow_page_code": "58019, 22144, 27018, 46509",
        "kiosk": false
    },
    {
        "value": "832250",
        "label": "Senior Citizens Counseling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419020",
        "yellow_page_code": "22135, 75503, 75519",
        "kiosk": false
    },
    {
        "value": "832251",
        "label": "Substance Abuse Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419031",
        "yellow_page_code": "82118, 00011",
        "kiosk": false
    },
    {
        "value": "832252",
        "label": "Employee Assistance Programs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419024",
        "yellow_page_code": "28522, 28509",
        "kiosk": false
    },
    {
        "value": "832253",
        "label": "Government-Individual/Family Social Svcs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62411003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "832254",
        "label": "Family & Children Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419021",
        "yellow_page_code": "30611, 15129, 15228, 30616, 94819, 30619, 15156",
        "kiosk": false
    },
    {
        "value": "832255",
        "label": "Refugee Programs-Resettlement/Health Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62423002",
        "yellow_page_code": "71510",
        "kiosk": false
    },
    {
        "value": "832256",
        "label": "Probation Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92215001",
        "yellow_page_code": "68522, 59490",
        "kiosk": false
    },
    {
        "value": "832257",
        "label": "Disaster Preparedness",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62423008",
        "yellow_page_code": "28418, 24838",
        "kiosk": false
    },
    {
        "value": "832258",
        "label": "Alzheimers Education/Referral/Support",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62412001",
        "yellow_page_code": "02015, 02024",
        "kiosk": false
    },
    {
        "value": "832259",
        "label": "Health Care Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419025",
        "yellow_page_code": "37280, 40524, 40432, 40423, 40412",
        "kiosk": false
    },
    {
        "value": "832260",
        "label": "Sexual Harassment Awareness & Prevention",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419032",
        "yellow_page_code": "76013",
        "kiosk": false
    },
    {
        "value": "832261",
        "label": "Teen Hot Lines",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419005",
        "yellow_page_code": "84227",
        "kiosk": false
    },
    {
        "value": "832262",
        "label": "Child Development Programs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62411010",
        "yellow_page_code": "15121",
        "kiosk": false
    },
    {
        "value": "832263",
        "label": "Pet Loss Counseling Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62411007",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "832264",
        "label": "Cancer Information",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419051",
        "yellow_page_code": "12619, 10340, 12621, 12620, 12607",
        "kiosk": false
    },
    {
        "value": "832265",
        "label": "Hospital Service Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419037",
        "yellow_page_code": "41712",
        "kiosk": false
    },
    {
        "value": "832266",
        "label": "Counselors-Workplace Relations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419047",
        "yellow_page_code": "22143",
        "kiosk": false
    },
    {
        "value": "832267",
        "label": "Child Support Collections",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419002",
        "yellow_page_code": "15219, 15222",
        "kiosk": false
    },
    {
        "value": "832268",
        "label": "Soup Kitchens",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62421002",
        "yellow_page_code": "79535",
        "kiosk": false
    },
    {
        "value": "832269",
        "label": "Travelers' Hlth Counseling/Vaccinations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419026",
        "yellow_page_code": "88499",
        "kiosk": false
    },
    {
        "value": "832270",
        "label": "Travelers' Advisory Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419006",
        "yellow_page_code": "88416, 88503",
        "kiosk": false
    },
    {
        "value": "832271",
        "label": "Self Help Groups",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419007",
        "yellow_page_code": "75498",
        "kiosk": false
    },
    {
        "value": "832272",
        "label": "Settlement Houses",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62422106",
        "yellow_page_code": "75705",
        "kiosk": false
    },
    {
        "value": "832273",
        "label": "Adult Abuse Hotline",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419008",
        "yellow_page_code": "27313, 00397",
        "kiosk": false
    },
    {
        "value": "832274",
        "label": "Transition Houses",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62422902",
        "yellow_page_code": "88210",
        "kiosk": false
    },
    {
        "value": "832275",
        "label": "Pregnancy Counseling Svc & Information",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62141005",
        "yellow_page_code": "68005, 22131",
        "kiosk": false
    },
    {
        "value": "832276",
        "label": "Arthritis Help Groups",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419035",
        "yellow_page_code": "03607",
        "kiosk": false
    },
    {
        "value": "832278",
        "label": "Emergency Relief Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62423003",
        "yellow_page_code": "28419",
        "kiosk": false
    },
    {
        "value": "832279",
        "label": "Elderly Companion Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62412004",
        "yellow_page_code": "41250, 27318, 27317",
        "kiosk": false
    },
    {
        "value": "832280",
        "label": "Flood Assistance",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62423004",
        "yellow_page_code": "33107",
        "kiosk": false
    },
    {
        "value": "832282",
        "label": "Counselors",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62419036",
        "yellow_page_code": "22116",
        "kiosk": false
    },
    {
        "value": "832283",
        "label": "Counselors-Couples",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419046",
        "yellow_page_code": "22123",
        "kiosk": false
    },
    {
        "value": "832284",
        "label": "Counselors-Holistic",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419038",
        "yellow_page_code": "22124",
        "kiosk": false
    },
    {
        "value": "832285",
        "label": "Autism Information & Referral Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419039",
        "yellow_page_code": "04418",
        "kiosk": false
    },
    {
        "value": "832287",
        "label": "Stroke Information & Treatment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419040",
        "yellow_page_code": "81820",
        "kiosk": false
    },
    {
        "value": "832288",
        "label": "Counseling-Transformation Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419041",
        "yellow_page_code": "22138, 47210, 22142",
        "kiosk": false
    },
    {
        "value": "832289",
        "label": "Counselors-Anger Control",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419042",
        "yellow_page_code": "22139",
        "kiosk": false
    },
    {
        "value": "832290",
        "label": "Disaster Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62423009",
        "yellow_page_code": "24835",
        "kiosk": false
    },
    {
        "value": "832291",
        "label": "Heart Disease Information & Treatment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62419049",
        "yellow_page_code": "40570",
        "kiosk": false
    },
    {
        "value": "832292",
        "label": "Day Services Adult",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62412008",
        "yellow_page_code": "23540",
        "kiosk": false
    },
    {
        "value": "832293",
        "label": "Counselors-Drug Alcohol & Nicotine",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "62419050",
        "yellow_page_code": "22148",
        "kiosk": false
    },
    {
        "value": "832294",
        "label": "Community Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62411011",
        "yellow_page_code": "20098, 20104",
        "kiosk": false
    },
    {
        "value": "832295",
        "label": "Meal Delivery Program",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62421003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "832296",
        "label": "Elderly & Disabled Person Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62412009",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "833101",
        "label": "Sheltered Workshops",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62431010",
        "yellow_page_code": "76300",
        "kiosk": false
    },
    {
        "value": "833102",
        "label": "Vocational Rehabilitation Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62431009",
        "yellow_page_code": "91185, 71523, 24955, 13415, 24959, 77199, 71508",
        "kiosk": false
    },
    {
        "value": "833103",
        "label": "Apprenticeship Training Programs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62431001",
        "yellow_page_code": "57495, 03006",
        "kiosk": false
    },
    {
        "value": "833104",
        "label": "Cognitive Disability-Dev Disability Svcs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62431008",
        "yellow_page_code": "51303, 24309, 51399, 43624",
        "kiosk": false
    },
    {
        "value": "833105",
        "label": "Employment Training Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62431006",
        "yellow_page_code": "28524, 28601, 28520, 44517",
        "kiosk": false
    },
    {
        "value": "833106",
        "label": "Career & Vocational Counseling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62431002",
        "yellow_page_code": "21044, 22129, 13408, 13815",
        "kiosk": false
    },
    {
        "value": "833107",
        "label": "Employment Counseling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62431004",
        "yellow_page_code": "28405",
        "kiosk": false
    },
    {
        "value": "833108",
        "label": "Government-Job Training/Voc Rehab Svcs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62431007",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "833109",
        "label": "Employment Svc-Handicapped & Disabled",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62431005",
        "yellow_page_code": "28521",
        "kiosk": false
    },
    {
        "value": "833110",
        "label": "Developmentally Disabled Info/Svcs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62431003",
        "yellow_page_code": "24318",
        "kiosk": false
    },
    {
        "value": "835101",
        "label": "Child Care Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62441003",
        "yellow_page_code": "23535, 43727, 15134, 15201, 05517, 15122, 23600, 74337, 15124, 00735, 55823, 15142, 15132, 23513, 15127",
        "kiosk": false
    },
    {
        "value": "835102",
        "label": "Schools-Pre-School/Kindergarten-Academic",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62441006",
        "yellow_page_code": "68201, 74506, 15133, 55809, 74614, 55825, 68013, 74457",
        "kiosk": false
    },
    {
        "value": "835103",
        "label": "Sitting Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62441007",
        "yellow_page_code": "77904",
        "kiosk": false
    },
    {
        "value": "835104",
        "label": "Child Care Centers-Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62441002",
        "yellow_page_code": "15108, 15114, 15113, 15111, 23510",
        "kiosk": false
    },
    {
        "value": "835106",
        "label": "Childrens After School Programs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62441004",
        "yellow_page_code": "15215",
        "kiosk": false
    },
    {
        "value": "835107",
        "label": "Head Start",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62441008",
        "yellow_page_code": "40426",
        "kiosk": false
    },
    {
        "value": "836101",
        "label": "Senior Citizens Service Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62331205",
        "yellow_page_code": "75506, 00770",
        "kiosk": false
    },
    {
        "value": "836102",
        "label": "Homes-Adult",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62331203",
        "yellow_page_code": "41305, 00410",
        "kiosk": false
    },
    {
        "value": "836103",
        "label": "Homes-Cognitive Disability-Dev Disabled",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62322001",
        "yellow_page_code": "41303, 41243",
        "kiosk": false
    },
    {
        "value": "836104",
        "label": "Homes-Children",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62399003",
        "yellow_page_code": "41308, 15144",
        "kiosk": false
    },
    {
        "value": "836105",
        "label": "Residential Care Homes",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62331206",
        "yellow_page_code": "75541, 41307, 71901, 04180, 71887, 04181, 41334, 55940, 75414, 04182, 04127, 04128, 41340, 02842",
        "kiosk": false
    },
    {
        "value": "836106",
        "label": "Blind Institutions",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62399004",
        "yellow_page_code": "08502",
        "kiosk": false
    },
    {
        "value": "836107",
        "label": "Sheltered Care Homes",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62399017",
        "yellow_page_code": "76209",
        "kiosk": false
    },
    {
        "value": "836108",
        "label": "Adult Congregate Living Facilities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62331201",
        "yellow_page_code": "00220, 00401",
        "kiosk": false
    },
    {
        "value": "836109",
        "label": "Foster Care",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62399013",
        "yellow_page_code": "34201, 34136",
        "kiosk": false
    },
    {
        "value": "836110",
        "label": "Retirement & Life Care Cmnty/Homes Info",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62331102",
        "yellow_page_code": "72016, 41331",
        "kiosk": false
    },
    {
        "value": "836111",
        "label": "Homes-Maternity",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62399005",
        "yellow_page_code": "84236, 41314",
        "kiosk": false
    },
    {
        "value": "836112",
        "label": "Homes-Boys",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62399006",
        "yellow_page_code": "41316",
        "kiosk": false
    },
    {
        "value": "836113",
        "label": "Community Care Facilities-Adult Care",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62331202",
        "yellow_page_code": "20113",
        "kiosk": false
    },
    {
        "value": "836114",
        "label": "Senior Citizens Housing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62331204",
        "yellow_page_code": "41940, 75515",
        "kiosk": false
    },
    {
        "value": "836115",
        "label": "Shelters For Battered Women",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62423005",
        "yellow_page_code": "94116, 76236",
        "kiosk": false
    },
    {
        "value": "836116",
        "label": "Group Homes",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62399007",
        "yellow_page_code": "39112, 39113",
        "kiosk": false
    },
    {
        "value": "836117",
        "label": "Juvenile Detention Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62399015",
        "yellow_page_code": "44612",
        "kiosk": false
    },
    {
        "value": "836118",
        "label": "Childrens Nursing & Rehabilitation Ctrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62399014",
        "yellow_page_code": "15213, 60125",
        "kiosk": false
    },
    {
        "value": "836119",
        "label": "Youth Homes",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62399008",
        "yellow_page_code": "94807",
        "kiosk": false
    },
    {
        "value": "836120",
        "label": "Homes-Battered Persons",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62423006",
        "yellow_page_code": "41319",
        "kiosk": false
    },
    {
        "value": "836121",
        "label": "Homes-Blind",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62399009",
        "yellow_page_code": "41320",
        "kiosk": false
    },
    {
        "value": "836122",
        "label": "Orphanages",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62399010",
        "yellow_page_code": "58003",
        "kiosk": false
    },
    {
        "value": "836123",
        "label": "Homes For The Handicapped",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62399011",
        "yellow_page_code": "41312, 41339",
        "kiosk": false
    },
    {
        "value": "836124",
        "label": "Homes-Girls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62399012",
        "yellow_page_code": "41313",
        "kiosk": false
    },
    {
        "value": "836125",
        "label": "Homes-Women",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62399001",
        "yellow_page_code": "41318",
        "kiosk": false
    },
    {
        "value": "836126",
        "label": "Halfway House",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62399016",
        "yellow_page_code": "39818",
        "kiosk": false
    },
    {
        "value": "836198",
        "label": "Residential Care",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62399002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "839901",
        "label": "Drug Abuse & Addiction Info & Treatment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81331904",
        "yellow_page_code": "14918, 26504, 30069",
        "kiosk": false
    },
    {
        "value": "839902",
        "label": "Alcoholism Information & Treatment Ctrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81331905",
        "yellow_page_code": "01814, 01817, 01813, 01900",
        "kiosk": false
    },
    {
        "value": "839903",
        "label": "Abortion Alternatives Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81331911",
        "yellow_page_code": "00007",
        "kiosk": false
    },
    {
        "value": "839904",
        "label": "Child Abuse Information & Treatment Ctrs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81331912",
        "yellow_page_code": "15116, 15109",
        "kiosk": false
    },
    {
        "value": "839905",
        "label": "Disability Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81331103",
        "yellow_page_code": "24822, 24825, 24809, 24903, 24860",
        "kiosk": false
    },
    {
        "value": "839906",
        "label": "Gambling Abuse/Addiction Info/Treatment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81331913",
        "yellow_page_code": "36003",
        "kiosk": false
    },
    {
        "value": "839907",
        "label": "Fund Raising Counselors & Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81321901",
        "yellow_page_code": "21148, 15722, 34903, 34904, 34907",
        "kiosk": false
    },
    {
        "value": "839908",
        "label": "Human Services Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81331101",
        "yellow_page_code": "42100, 42018",
        "kiosk": false
    },
    {
        "value": "839909",
        "label": "Handicapped Services & Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81331914",
        "yellow_page_code": "40102, 40017",
        "kiosk": false
    },
    {
        "value": "839910",
        "label": "Smokers Information & Treatment Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81331906",
        "yellow_page_code": "78516, 74729, 78521, 78602",
        "kiosk": false
    },
    {
        "value": "839911",
        "label": "Medical Management Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81321202",
        "yellow_page_code": "51202, 49740",
        "kiosk": false
    },
    {
        "value": "839912",
        "label": "Suicide Prevention Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81321203",
        "yellow_page_code": "82205, 82204",
        "kiosk": false
    },
    {
        "value": "839913",
        "label": "Native American Reservations & Tribes",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81331915",
        "yellow_page_code": "42905",
        "kiosk": false
    },
    {
        "value": "839914",
        "label": "Community Action Agencies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81331903",
        "yellow_page_code": "20099, 20101, 75476",
        "kiosk": false
    },
    {
        "value": "839915",
        "label": "Gay & Lesbian Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81331102",
        "yellow_page_code": "36908",
        "kiosk": false
    },
    {
        "value": "839916",
        "label": "Breastfeeding Supplies & Information",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81331907",
        "yellow_page_code": "10407, 45418, 10414, 10413, 10408",
        "kiosk": false
    },
    {
        "value": "839917",
        "label": "Crime Prevention Programs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81331910",
        "yellow_page_code": "22604",
        "kiosk": false
    },
    {
        "value": "839918",
        "label": "Volunteer Workers Placement Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81321902",
        "yellow_page_code": "91301",
        "kiosk": false
    },
    {
        "value": "839919",
        "label": "Charitable Institutions",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81331104",
        "yellow_page_code": "85019, 14699, 84127, 33832, 04799, 43519, 14702, 18733, 14701, 14705",
        "kiosk": false
    },
    {
        "value": "839920",
        "label": "Health-Employees Corporate Programs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81331105",
        "yellow_page_code": "40515",
        "kiosk": false
    },
    {
        "value": "839921",
        "label": "Addiction Treatment Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81331106",
        "yellow_page_code": "00252, 00251",
        "kiosk": false
    },
    {
        "value": "839922",
        "label": "Background Screening",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81331916",
        "yellow_page_code": "28538, 05614",
        "kiosk": false
    },
    {
        "value": "839924",
        "label": "Dependency Information & Help Centres",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81321201",
        "yellow_page_code": "24213",
        "kiosk": false
    },
    {
        "value": "839925",
        "label": "Memorial Societies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81331917",
        "yellow_page_code": "51215",
        "kiosk": false
    },
    {
        "value": "839929",
        "label": "Epilepsy Educational Referral/Sprt Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81331901",
        "yellow_page_code": "29717",
        "kiosk": false
    },
    {
        "value": "839930",
        "label": "Tax Advocacy",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81331902",
        "yellow_page_code": "84114",
        "kiosk": false
    },
    {
        "value": "839931",
        "label": "Hispanic Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81331918",
        "yellow_page_code": "41027",
        "kiosk": false
    },
    {
        "value": "839932",
        "label": "Patient Advocates",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81331919",
        "yellow_page_code": "59704",
        "kiosk": false
    },
    {
        "value": "839933",
        "label": "Advocacy Resources",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81331920",
        "yellow_page_code": "24957",
        "kiosk": false
    },
    {
        "value": "839934",
        "label": "Cultural Preservation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81331921",
        "yellow_page_code": "22813",
        "kiosk": false
    },
    {
        "value": "839935",
        "label": "Coalitions",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81331922",
        "yellow_page_code": "18950",
        "kiosk": false
    },
    {
        "value": "839936",
        "label": "Child Advocacy Groups",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81331107",
        "yellow_page_code": "15120",
        "kiosk": false
    },
    {
        "value": "839937",
        "label": "Environmental Conservation/Ecologcl Org",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81331210",
        "yellow_page_code": "29706, 27114, 29720",
        "kiosk": false
    },
    {
        "value": "839938",
        "label": "Donation Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81331108",
        "yellow_page_code": "25454",
        "kiosk": false
    },
    {
        "value": "839939",
        "label": "Donation Drop Boxes",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81331109",
        "yellow_page_code": "",
        "kiosk": true
    },
    {
        "value": "839940",
        "label": "Voluntary Health Organizations ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81321204",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "839941",
        "label": "Grantmaking & Giving Services ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81321903",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "839998",
        "label": "Non-Profit Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81331908",
        "yellow_page_code": "71970, 55615, 57817, 20025",
        "kiosk": false
    },
    {
        "value": "839999",
        "label": "Social Services NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81331909",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "841201",
        "label": "Museums",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71211001",
        "yellow_page_code": "03442, 54604, 03513, 54605",
        "kiosk": false
    },
    {
        "value": "841202",
        "label": "Arts Organizations & Information",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71211004",
        "yellow_page_code": "03615, 03507",
        "kiosk": false
    },
    {
        "value": "841203",
        "label": "Art Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71211005",
        "yellow_page_code": "43721, 03405, 03422, 03440",
        "kiosk": false
    },
    {
        "value": "841204",
        "label": "Planetariums",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71211006",
        "yellow_page_code": "66005",
        "kiosk": false
    },
    {
        "value": "841205",
        "label": "Archives",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71211010",
        "yellow_page_code": "03305",
        "kiosk": false
    },
    {
        "value": "841206",
        "label": "Cultural Centres",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71211007",
        "yellow_page_code": "22811",
        "kiosk": false
    },
    {
        "value": "841207",
        "label": "Couture Art",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71211002",
        "yellow_page_code": "22014",
        "kiosk": false
    },
    {
        "value": "841208",
        "label": "Neon-Art",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71211003",
        "yellow_page_code": "55318",
        "kiosk": false
    },
    {
        "value": "841209",
        "label": "Art Rental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71211008",
        "yellow_page_code": "03409",
        "kiosk": false
    },
    {
        "value": "841210",
        "label": "Art",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71211009",
        "yellow_page_code": "33690, 58616, 03418",
        "kiosk": false
    },
    {
        "value": "841211",
        "label": "National Monuments",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71212002",
        "yellow_page_code": "55111",
        "kiosk": false
    },
    {
        "value": "842201",
        "label": "Zoos",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71213006",
        "yellow_page_code": "95002, 59424, 95003",
        "kiosk": false
    },
    {
        "value": "842202",
        "label": "Nature Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71219003",
        "yellow_page_code": "55109, 74717",
        "kiosk": false
    },
    {
        "value": "842203",
        "label": "Arboretums",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71213002",
        "yellow_page_code": "33412, 03107",
        "kiosk": false
    },
    {
        "value": "842204",
        "label": "Govt-Arboreta Botanical/Zoological Grdn",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71213004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "842205",
        "label": "Aquariums-Public",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71219001",
        "yellow_page_code": "03111",
        "kiosk": false
    },
    {
        "value": "842206",
        "label": "Petting Zoos",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71213005",
        "yellow_page_code": "60537",
        "kiosk": false
    },
    {
        "value": "842207",
        "label": "Botanical Gardens",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71213003",
        "yellow_page_code": "36211, 59519, 09501",
        "kiosk": false
    },
    {
        "value": "842208",
        "label": "Animal Exhibition Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71213001",
        "yellow_page_code": "02411",
        "kiosk": false
    },
    {
        "value": "842209",
        "label": "Insectaries",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71219002",
        "yellow_page_code": "43216",
        "kiosk": false
    },
    {
        "value": "842210",
        "label": "Forest Land",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71219008",
        "yellow_page_code": "34095",
        "kiosk": false
    },
    {
        "value": "842211",
        "label": "Gardens",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71213007",
        "yellow_page_code": "44196, 36216",
        "kiosk": false
    },
    {
        "value": "861101",
        "label": "Business & Trade Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81391004",
        "yellow_page_code": "30307, 04121, 11800",
        "kiosk": false
    },
    {
        "value": "861102",
        "label": "Associations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81391002",
        "yellow_page_code": "04116, 51049, 55220, 43348, 43347, 04119, 04118, 04117, 04115, 04114, 04113, 04104",
        "kiosk": false
    },
    {
        "value": "861103",
        "label": "Sales Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81391009",
        "yellow_page_code": "73508",
        "kiosk": false
    },
    {
        "value": "861104",
        "label": "Chambers Of Commerce",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81391005",
        "yellow_page_code": "56225, 14700, 93217",
        "kiosk": false
    },
    {
        "value": "861105",
        "label": "Ancsa Native Corporations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81391001",
        "yellow_page_code": "02311",
        "kiosk": false
    },
    {
        "value": "861106",
        "label": "Oil Well Log Libraries",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81391008",
        "yellow_page_code": "56911",
        "kiosk": false
    },
    {
        "value": "861107",
        "label": "Farm Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81391006",
        "yellow_page_code": "00814, 30809, 30921, 00796, 28847",
        "kiosk": false
    },
    {
        "value": "861108",
        "label": "Better Business Bureaus",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81391003",
        "yellow_page_code": "07800",
        "kiosk": false
    },
    {
        "value": "861109",
        "label": "Lumber-Inspectors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81391007",
        "yellow_page_code": "48903",
        "kiosk": false
    },
    {
        "value": "861110",
        "label": "Shippers Cooperative Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81391010",
        "yellow_page_code": "76407",
        "kiosk": false
    },
    {
        "value": "861111",
        "label": "Women's Associations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81391013",
        "yellow_page_code": "94119",
        "kiosk": false
    },
    {
        "value": "861112",
        "label": "Motoring Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81391014",
        "yellow_page_code": "54248",
        "kiosk": false
    },
    {
        "value": "861113",
        "label": "Associations-Theater Owners",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81391015",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "862101",
        "label": "Professional Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81392006",
        "yellow_page_code": "68600, 28545, 68528",
        "kiosk": false
    },
    {
        "value": "862102",
        "label": "Attorney-Bar Associations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81392003",
        "yellow_page_code": "04211",
        "kiosk": false
    },
    {
        "value": "862103",
        "label": "Pro Standards Review Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81392005",
        "yellow_page_code": "68511",
        "kiosk": false
    },
    {
        "value": "862104",
        "label": "Bureaus",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81392001",
        "yellow_page_code": "11434",
        "kiosk": false
    },
    {
        "value": "862105",
        "label": "Pharmacologists",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81392004",
        "yellow_page_code": "60615",
        "kiosk": false
    },
    {
        "value": "862106",
        "label": "Dental Governing Bodies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81392002",
        "yellow_page_code": "23820",
        "kiosk": false
    },
    {
        "value": "862107",
        "label": "Scientific Organization",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81392007",
        "yellow_page_code": "74719",
        "kiosk": false
    },
    {
        "value": "862108",
        "label": "Medical Governing Bodies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81392008",
        "yellow_page_code": "50819",
        "kiosk": false
    },
    {
        "value": "862109",
        "label": "Chronic Disease Svcs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81392009",
        "yellow_page_code": "15704",
        "kiosk": false
    },
    {
        "value": "863101",
        "label": "Labor Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81393001",
        "yellow_page_code": "90003, 45300",
        "kiosk": false
    },
    {
        "value": "863102",
        "label": "Unions-Industrial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81393002",
        "yellow_page_code": "90005",
        "kiosk": false
    },
    {
        "value": "864101",
        "label": "Fraternal Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81341002",
        "yellow_page_code": "34306, 18897, 46909",
        "kiosk": false
    },
    {
        "value": "864102",
        "label": "Veterans' & Military Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81341003",
        "yellow_page_code": "90906",
        "kiosk": false
    },
    {
        "value": "864103",
        "label": "Civic Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81341001",
        "yellow_page_code": "18827, 18201",
        "kiosk": false
    },
    {
        "value": "864104",
        "label": "Ethnic Societies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81341027",
        "yellow_page_code": "29915",
        "kiosk": false
    },
    {
        "value": "864106",
        "label": "Educational Associations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81341005",
        "yellow_page_code": "27203",
        "kiosk": false
    },
    {
        "value": "864107",
        "label": "Fraternities & Sororities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81341006",
        "yellow_page_code": "34400",
        "kiosk": false
    },
    {
        "value": "864108",
        "label": "Clubs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81341004",
        "yellow_page_code": "18900, 68501, 22133, 78990, 18817, 18818, 18899, 18898, 18894, 30730",
        "kiosk": false
    },
    {
        "value": "864109",
        "label": "Peace Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81341007",
        "yellow_page_code": "60006",
        "kiosk": false
    },
    {
        "value": "864110",
        "label": "Community Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81341008",
        "yellow_page_code": "20105",
        "kiosk": false
    },
    {
        "value": "864111",
        "label": "Sororities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81341009",
        "yellow_page_code": "79407",
        "kiosk": false
    },
    {
        "value": "864112",
        "label": "Blind Services & Facilities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81341010",
        "yellow_page_code": "08515, 08514, 91210, 08431",
        "kiosk": false
    },
    {
        "value": "864113",
        "label": "Men's Organization & Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81341011",
        "yellow_page_code": "51207",
        "kiosk": false
    },
    {
        "value": "864114",
        "label": "Dining Club Plans",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81341012",
        "yellow_page_code": "24808",
        "kiosk": false
    },
    {
        "value": "864115",
        "label": "Natives Service Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81341017",
        "yellow_page_code": "52008, 55118, 55135, 42822, 32313, 55108, 42812, 00029",
        "kiosk": false
    },
    {
        "value": "864118",
        "label": "Teen Clubs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81341024",
        "yellow_page_code": "84216",
        "kiosk": false
    },
    {
        "value": "864120",
        "label": "Companions-Social",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81341013",
        "yellow_page_code": "20119",
        "kiosk": false
    },
    {
        "value": "864121",
        "label": "Business Support Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81341022",
        "yellow_page_code": "11819",
        "kiosk": false
    },
    {
        "value": "864123",
        "label": "Girl Scouts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81341025",
        "yellow_page_code": "37320",
        "kiosk": false
    },
    {
        "value": "864125",
        "label": "Clubs-Youth",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81341014",
        "yellow_page_code": "18819",
        "kiosk": false
    },
    {
        "value": "864126",
        "label": "Gentlemen's Clubs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81341015",
        "yellow_page_code": "37013",
        "kiosk": false
    },
    {
        "value": "864127",
        "label": "Home Owners Associations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81399001",
        "yellow_page_code": "68640, 70634, 41938, 41261",
        "kiosk": false
    },
    {
        "value": "864128",
        "label": "Inuit Organizations & Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92115001",
        "yellow_page_code": "43627",
        "kiosk": false
    },
    {
        "value": "864129",
        "label": "Universities/Colleges-Student Union/Ctr",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81341033",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "864130",
        "label": "Universities/Colleges-Alumni Assn/Club",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81341034",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "865101",
        "label": "Political Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81394003",
        "yellow_page_code": "67118, 67108",
        "kiosk": false
    },
    {
        "value": "865102",
        "label": "Legislative Research",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81394001",
        "yellow_page_code": "46809",
        "kiosk": false
    },
    {
        "value": "865103",
        "label": "Political Campaign Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81394002",
        "yellow_page_code": "67112, 67114",
        "kiosk": false
    },
    {
        "value": "866101",
        "label": "Retreat Houses",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311023",
        "yellow_page_code": "72008, 72074, 71526, 15728, 71938",
        "kiosk": false
    },
    {
        "value": "866102",
        "label": "Bible Schools & Study",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311002",
        "yellow_page_code": "07910, 07904, 74423",
        "kiosk": false
    },
    {
        "value": "866103",
        "label": "Spiritual Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311033",
        "yellow_page_code": "79917",
        "kiosk": false
    },
    {
        "value": "866104",
        "label": "Church Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311006",
        "yellow_page_code": "83001, 15707",
        "kiosk": false
    },
    {
        "value": "866105",
        "label": "Christian Science Practitioners",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311005",
        "yellow_page_code": "15604",
        "kiosk": false
    },
    {
        "value": "866106",
        "label": "Clergy",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "81311009",
        "yellow_page_code": "92963, 29910, 52609, 18506",
        "kiosk": false
    },
    {
        "value": "866107",
        "label": "Churches",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311008",
        "yellow_page_code": "24862, 71622, 43697, 15800, 15798, 68001, 15725",
        "kiosk": false
    },
    {
        "value": "866108",
        "label": "Clergy & Pastoral Counseling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311017",
        "yellow_page_code": "18509, 59607",
        "kiosk": false
    },
    {
        "value": "866109",
        "label": "Convents & Monasteries",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311011",
        "yellow_page_code": "21306, 53501",
        "kiosk": false
    },
    {
        "value": "866110",
        "label": "Religious Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311021",
        "yellow_page_code": "71604, 15622",
        "kiosk": false
    },
    {
        "value": "866111",
        "label": "Religious Counseling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311020",
        "yellow_page_code": "07836, 22132, 71601, 22119, 15621",
        "kiosk": false
    },
    {
        "value": "866112",
        "label": "Synagogues",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311026",
        "yellow_page_code": "85003, 83000, 82990, 82992",
        "kiosk": false
    },
    {
        "value": "866113",
        "label": "Spiritualists",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311025",
        "yellow_page_code": "79911, 79903",
        "kiosk": false
    },
    {
        "value": "866114",
        "label": "Mosques",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311015",
        "yellow_page_code": "53701, 44014, 53692",
        "kiosk": false
    },
    {
        "value": "866115",
        "label": "Religious Science Practitioners",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311022",
        "yellow_page_code": "71608",
        "kiosk": false
    },
    {
        "value": "866116",
        "label": "Ashrams",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311001",
        "yellow_page_code": "03915",
        "kiosk": false
    },
    {
        "value": "866117",
        "label": "Congregations-Messianic",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311010",
        "yellow_page_code": "20912",
        "kiosk": false
    },
    {
        "value": "866118",
        "label": "Rabbis",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311019",
        "yellow_page_code": "53311, 69503",
        "kiosk": false
    },
    {
        "value": "866119",
        "label": "Dianetics",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311012",
        "yellow_page_code": "24505",
        "kiosk": false
    },
    {
        "value": "866120",
        "label": "Parsonages",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311016",
        "yellow_page_code": "59501",
        "kiosk": false
    },
    {
        "value": "866121",
        "label": "Chaplains",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311003",
        "yellow_page_code": "14651",
        "kiosk": false
    },
    {
        "value": "866122",
        "label": "Christian Ministries",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311004",
        "yellow_page_code": "15614",
        "kiosk": false
    },
    {
        "value": "866123",
        "label": "Meditation Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311013",
        "yellow_page_code": "22820, 51011",
        "kiosk": false
    },
    {
        "value": "866125",
        "label": "Church Consulting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311007",
        "yellow_page_code": "15713, 15717",
        "kiosk": false
    },
    {
        "value": "866126",
        "label": "Scientologists",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311024",
        "yellow_page_code": "74705",
        "kiosk": false
    },
    {
        "value": "866127",
        "label": "Ministries-Out Reach",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311027",
        "yellow_page_code": "30627, 52610, 52605, 15145, 48120",
        "kiosk": false
    },
    {
        "value": "866128",
        "label": "Hutterite Colonies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311028",
        "yellow_page_code": "42129",
        "kiosk": false
    },
    {
        "value": "866129",
        "label": "Music Ministry",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311029",
        "yellow_page_code": "54718",
        "kiosk": false
    },
    {
        "value": "866130",
        "label": "Shrines",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311018",
        "yellow_page_code": "77339",
        "kiosk": false
    },
    {
        "value": "866131",
        "label": "Place Of Worship-Metaphysical & New Age",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311030",
        "yellow_page_code": "65997",
        "kiosk": false
    },
    {
        "value": "866132",
        "label": "Places Of Worship-Non Theistic",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311031",
        "yellow_page_code": "65990",
        "kiosk": false
    },
    {
        "value": "866133",
        "label": "Religion Other Places Of Worship",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311032",
        "yellow_page_code": "71521",
        "kiosk": false
    },
    {
        "value": "866134",
        "label": "Pastoral Counseling Of Religious Leaders",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311037",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "866135",
        "label": "Places Of Worship",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311034",
        "yellow_page_code": "65989",
        "kiosk": false
    },
    {
        "value": "866136",
        "label": "Temples-Buddhist",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311035",
        "yellow_page_code": "85009",
        "kiosk": false
    },
    {
        "value": "866137",
        "label": "Mosques Muslim-Islamic Charitable-Orgztn",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311036",
        "yellow_page_code": "53690",
        "kiosk": false
    },
    {
        "value": "866138",
        "label": "Temples-Hindu",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311038",
        "yellow_page_code": "85015",
        "kiosk": false
    },
    {
        "value": "866139",
        "label": "Temples-Sikh",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311039",
        "yellow_page_code": "85035",
        "kiosk": false
    },
    {
        "value": "866140",
        "label": "Temples-Hare Krishna",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311040",
        "yellow_page_code": "85036",
        "kiosk": false
    },
    {
        "value": "866141",
        "label": "Synagogues Jewish",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311041",
        "yellow_page_code": "85037, 82984, 82983, 82980, 82997, 82993",
        "kiosk": false
    },
    {
        "value": "866142",
        "label": "Synagogues Orthodox",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311042",
        "yellow_page_code": "82994",
        "kiosk": false
    },
    {
        "value": "866143",
        "label": "Synagogues Traditional",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311043",
        "yellow_page_code": "82995",
        "kiosk": false
    },
    {
        "value": "866144",
        "label": "Synagogues Messianic",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311044",
        "yellow_page_code": "82996",
        "kiosk": false
    },
    {
        "value": "866145",
        "label": "Synagogues Reconstructionist",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311045",
        "yellow_page_code": "82998",
        "kiosk": false
    },
    {
        "value": "866146",
        "label": "Synagogues Community",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311046",
        "yellow_page_code": "82989",
        "kiosk": false
    },
    {
        "value": "866147",
        "label": "Synagogues Liberal",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311047",
        "yellow_page_code": "82988",
        "kiosk": false
    },
    {
        "value": "866148",
        "label": "Synagogues Non Denominational",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311048",
        "yellow_page_code": "82987",
        "kiosk": false
    },
    {
        "value": "866149",
        "label": "Synagogues Lubavitch Chabad",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311049",
        "yellow_page_code": "82986",
        "kiosk": false
    },
    {
        "value": "866150",
        "label": "Synagogues Independent",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311050",
        "yellow_page_code": "82985",
        "kiosk": false
    },
    {
        "value": "866151",
        "label": "Synagogues-Ashkenazic",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311051",
        "yellow_page_code": "82982",
        "kiosk": false
    },
    {
        "value": "866152",
        "label": "Synagogues Sephardic",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81311052",
        "yellow_page_code": "82981",
        "kiosk": false
    },
    {
        "value": "869901",
        "label": "Humane Societies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81331201",
        "yellow_page_code": "08237, 02499, 42102",
        "kiosk": false
    },
    {
        "value": "869902",
        "label": "Livestock Recording Associations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81391012",
        "yellow_page_code": "48308",
        "kiosk": false
    },
    {
        "value": "869903",
        "label": "Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81341018",
        "yellow_page_code": "24324, 20024, 72076, 57902, 68815",
        "kiosk": false
    },
    {
        "value": "869904",
        "label": "Women's Organizations & Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81341019",
        "yellow_page_code": "94109",
        "kiosk": false
    },
    {
        "value": "869905",
        "label": "Athletic Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81399005",
        "yellow_page_code": "04200",
        "kiosk": false
    },
    {
        "value": "869906",
        "label": "Christian Science Reading Rooms",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81341028",
        "yellow_page_code": "15606",
        "kiosk": false
    },
    {
        "value": "869907",
        "label": "Historical Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81341016",
        "yellow_page_code": "41008",
        "kiosk": false
    },
    {
        "value": "869908",
        "label": "Farm & Home Advisory Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81391011",
        "yellow_page_code": "30801",
        "kiosk": false
    },
    {
        "value": "869909",
        "label": "Animal Protection Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81331202",
        "yellow_page_code": "02511, 60521, 02520, 02605, 02423",
        "kiosk": false
    },
    {
        "value": "869910",
        "label": "Meditation Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81341029",
        "yellow_page_code": "51108",
        "kiosk": false
    },
    {
        "value": "869912",
        "label": "Automobile Clubs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56159909",
        "yellow_page_code": "04603",
        "kiosk": false
    },
    {
        "value": "869913",
        "label": "Reading Rooms",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "62139964",
        "yellow_page_code": "70605",
        "kiosk": false
    },
    {
        "value": "869914",
        "label": "Golf Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81399004",
        "yellow_page_code": "38201",
        "kiosk": false
    },
    {
        "value": "869916",
        "label": "Dog Breeders-Information & Referral Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81331207",
        "yellow_page_code": "25316",
        "kiosk": false
    },
    {
        "value": "869917",
        "label": "Pets-Lost & Found",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81331208",
        "yellow_page_code": "60505, 60516",
        "kiosk": false
    },
    {
        "value": "869919",
        "label": "Pilots",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81341023",
        "yellow_page_code": "65305",
        "kiosk": false
    },
    {
        "value": "869921",
        "label": "Dance Clubs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81341030",
        "yellow_page_code": "23315",
        "kiosk": false
    },
    {
        "value": "869925",
        "label": "Casino-Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81399003",
        "yellow_page_code": "13936",
        "kiosk": false
    },
    {
        "value": "869926",
        "label": "Pigeon Control Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81331203",
        "yellow_page_code": "65114",
        "kiosk": false
    },
    {
        "value": "869927",
        "label": "Animal Adoptions",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81331204",
        "yellow_page_code": "60430, 02424",
        "kiosk": false
    },
    {
        "value": "869928",
        "label": "Computer Clubs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81341026",
        "yellow_page_code": "20238",
        "kiosk": false
    },
    {
        "value": "869929",
        "label": "Horse Rescuing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81331209",
        "yellow_page_code": "41544",
        "kiosk": false
    },
    {
        "value": "869930",
        "label": "Art Associations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81341031",
        "yellow_page_code": "03426",
        "kiosk": false
    },
    {
        "value": "869931",
        "label": "Cattleman's Associations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81391016",
        "yellow_page_code": "14119",
        "kiosk": false
    },
    {
        "value": "869932",
        "label": "Cooperative Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81341032",
        "yellow_page_code": "21441",
        "kiosk": false
    },
    {
        "value": "869933",
        "label": "Social Membership Organization",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81399006",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "869999",
        "label": "Membership Organizations NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81341020",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "871101",
        "label": "Naval Architects",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133068",
        "yellow_page_code": "55204",
        "kiosk": false
    },
    {
        "value": "871102",
        "label": "Acoustical Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133001",
        "yellow_page_code": "21134, 00200",
        "kiosk": false
    },
    {
        "value": "871103",
        "label": "Engineers-Agricultural",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133009",
        "yellow_page_code": "28805",
        "kiosk": false
    },
    {
        "value": "871104",
        "label": "Engineering-Job Shops",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133005",
        "yellow_page_code": "28709",
        "kiosk": false
    },
    {
        "value": "871105",
        "label": "Contractors-Engineering General",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133003",
        "yellow_page_code": "21203, 28717",
        "kiosk": false
    },
    {
        "value": "871106",
        "label": "Engineers",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "54133006",
        "yellow_page_code": "29040, 29032, 28809, 29218, 29028, 29224, 28916, 28713, 28712, 29212, 29205, 29201, 29117, 29107, 29023, 28815, 29127, 29016, 29013, 28914, 28903, 28813, 28801, 28711, 28852, 29237, 28850, 28829, 28796, 56451, 28848, 43350, 83420, 29041, 28910, 29033, 28725, 75535, 28821, 28824, 28919, 28849, 81999, 28820, 28819, 29230, 29034, 29019, 29232",
        "kiosk": false
    },
    {
        "value": "871107",
        "label": "Engineers-Air Cond Heating Ventilating",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133010",
        "yellow_page_code": "29122, 29027, 28806, 01104, 00918",
        "kiosk": false
    },
    {
        "value": "871108",
        "label": "Engineers-Acoustical",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133007",
        "yellow_page_code": "28802, 29235, 79120, 28721",
        "kiosk": false
    },
    {
        "value": "871109",
        "label": "Engineers-Chemical",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133013",
        "yellow_page_code": "14913, 28808",
        "kiosk": false
    },
    {
        "value": "871110",
        "label": "Engineers-Civil",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "54133014",
        "yellow_page_code": "18140, 28902",
        "kiosk": false
    },
    {
        "value": "871111",
        "label": "Engineers-Consulting",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "54133016",
        "yellow_page_code": "43759, 28906, 21046, 21019",
        "kiosk": false
    },
    {
        "value": "871112",
        "label": "Engineers-Construction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133015",
        "yellow_page_code": "28904, 20938",
        "kiosk": false
    },
    {
        "value": "871113",
        "label": "Engineers-Drainage",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133021",
        "yellow_page_code": "28907",
        "kiosk": false
    },
    {
        "value": "871114",
        "label": "Engineers-Control Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133017",
        "yellow_page_code": "28905",
        "kiosk": false
    },
    {
        "value": "871115",
        "label": "Engineers-Electrical",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "54133022",
        "yellow_page_code": "29017, 28908, 27920, 27418",
        "kiosk": false
    },
    {
        "value": "871116",
        "label": "Engineers-Electronic",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133023",
        "yellow_page_code": "27421, 72413, 29225, 29000",
        "kiosk": false
    },
    {
        "value": "871117",
        "label": "Engineers-Foundation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133026",
        "yellow_page_code": "34225, 34221, 29003",
        "kiosk": false
    },
    {
        "value": "871118",
        "label": "Engineers-Geological",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133028",
        "yellow_page_code": "29001",
        "kiosk": false
    },
    {
        "value": "871119",
        "label": "Engineers-Geotechnical",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133029",
        "yellow_page_code": "29004, 29025, 29226",
        "kiosk": false
    },
    {
        "value": "871120",
        "label": "Engineers-Environmental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133025",
        "yellow_page_code": "29002, 29010, 29199",
        "kiosk": false
    },
    {
        "value": "871121",
        "label": "Engineers-Hydraulic",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133031",
        "yellow_page_code": "29005, 42132",
        "kiosk": false
    },
    {
        "value": "871122",
        "label": "Engineers-Industrial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133032",
        "yellow_page_code": "42935, 29006",
        "kiosk": false
    },
    {
        "value": "871123",
        "label": "Engineers-Municipal",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133044",
        "yellow_page_code": "29009",
        "kiosk": false
    },
    {
        "value": "871124",
        "label": "Engineers-Land Planning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133035",
        "yellow_page_code": "29210, 29007",
        "kiosk": false
    },
    {
        "value": "871125",
        "label": "Engineers-Mechanical",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "54133041",
        "yellow_page_code": "50919, 29100, 50920",
        "kiosk": false
    },
    {
        "value": "871126",
        "label": "Engineers-Marine",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133039",
        "yellow_page_code": "29008, 50117",
        "kiosk": false
    },
    {
        "value": "871127",
        "label": "Engineers-Management",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133037",
        "yellow_page_code": "29101",
        "kiosk": false
    },
    {
        "value": "871128",
        "label": "Engineers-Metallurgical",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133042",
        "yellow_page_code": "51809, 29102",
        "kiosk": false
    },
    {
        "value": "871129",
        "label": "Engineers-Traffic & Transportation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133061",
        "yellow_page_code": "29105",
        "kiosk": false
    },
    {
        "value": "871130",
        "label": "Engineers-Mining",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133043",
        "yellow_page_code": "29103, 52534",
        "kiosk": false
    },
    {
        "value": "871131",
        "label": "Engineers-Petroleum",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133046",
        "yellow_page_code": "29198, 60610, 29200",
        "kiosk": false
    },
    {
        "value": "871132",
        "label": "Engineers-Manufacturing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133038",
        "yellow_page_code": "49920, 29104",
        "kiosk": false
    },
    {
        "value": "871133",
        "label": "Engineers-Professional",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "54133052",
        "yellow_page_code": "43673, 29202",
        "kiosk": false
    },
    {
        "value": "871134",
        "label": "Engineers-Water Supply",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133064",
        "yellow_page_code": "29217, 29106",
        "kiosk": false
    },
    {
        "value": "871135",
        "label": "Engineers-Testing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133060",
        "yellow_page_code": "29203",
        "kiosk": false
    },
    {
        "value": "871136",
        "label": "Engineers-Processing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133051",
        "yellow_page_code": "29204, 67960",
        "kiosk": false
    },
    {
        "value": "871137",
        "label": "Engineers-Structural",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "54133058",
        "yellow_page_code": "81732, 29208",
        "kiosk": false
    },
    {
        "value": "871138",
        "label": "Engineers-Sanitary",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133056",
        "yellow_page_code": "29206",
        "kiosk": false
    },
    {
        "value": "871139",
        "label": "Engineers-Solar",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133057",
        "yellow_page_code": "29207",
        "kiosk": false
    },
    {
        "value": "871140",
        "label": "Engineers-Designing",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "54133020",
        "yellow_page_code": "28913, 24231, 42909",
        "kiosk": false
    },
    {
        "value": "871141",
        "label": "Machinery-Designing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133067",
        "yellow_page_code": "49203, 49223, 30247",
        "kiosk": false
    },
    {
        "value": "871142",
        "label": "Vibration Measurement Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133070",
        "yellow_page_code": "91010, 91005",
        "kiosk": false
    },
    {
        "value": "871143",
        "label": "Engineers-Aeronautical",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133008",
        "yellow_page_code": "01431, 28795, 28803",
        "kiosk": false
    },
    {
        "value": "871144",
        "label": "Engineers-Inspecting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133033",
        "yellow_page_code": "29233, 29018",
        "kiosk": false
    },
    {
        "value": "871145",
        "label": "Engineers-Refrigerating",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133053",
        "yellow_page_code": "29118, 28817",
        "kiosk": false
    },
    {
        "value": "871146",
        "label": "Lighting Engineers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133065",
        "yellow_page_code": "47415, 80420, 47305",
        "kiosk": false
    },
    {
        "value": "871147",
        "label": "Engineering",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133011",
        "yellow_page_code": "28718, 28800, 11124, 28780, 28812",
        "kiosk": false
    },
    {
        "value": "871148",
        "label": "Engineers-Corrosion",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133018",
        "yellow_page_code": "28911, 21711",
        "kiosk": false
    },
    {
        "value": "871149",
        "label": "Engineers-Cost",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133019",
        "yellow_page_code": "28912",
        "kiosk": false
    },
    {
        "value": "871150",
        "label": "Engineers-Energy Management",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133024",
        "yellow_page_code": "29011, 28922, 28923, 28851",
        "kiosk": false
    },
    {
        "value": "871151",
        "label": "Engineers-Material Handling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133040",
        "yellow_page_code": "29022",
        "kiosk": false
    },
    {
        "value": "871152",
        "label": "Engineers-Nuclear",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133045",
        "yellow_page_code": "29024",
        "kiosk": false
    },
    {
        "value": "871153",
        "label": "Engineers-Machine Design",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133036",
        "yellow_page_code": "29029",
        "kiosk": false
    },
    {
        "value": "871154",
        "label": "Engineers-Highways & Bridges",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133030",
        "yellow_page_code": "29109",
        "kiosk": false
    },
    {
        "value": "871155",
        "label": "Engineers-Power",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133050",
        "yellow_page_code": "67933, 29197, 29116",
        "kiosk": false
    },
    {
        "value": "871156",
        "label": "Engineers-Research",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133054",
        "yellow_page_code": "29120, 29121",
        "kiosk": false
    },
    {
        "value": "871157",
        "label": "Engineers-Safety",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133055",
        "yellow_page_code": "29211",
        "kiosk": false
    },
    {
        "value": "871158",
        "label": "Engineers-Transportation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133062",
        "yellow_page_code": "29214",
        "kiosk": false
    },
    {
        "value": "871159",
        "label": "Engineers-Waste Water Treatment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133063",
        "yellow_page_code": "92319, 91914, 29216",
        "kiosk": false
    },
    {
        "value": "871160",
        "label": "Engineers-Irrigation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133034",
        "yellow_page_code": "29020",
        "kiosk": false
    },
    {
        "value": "871161",
        "label": "Engineers-Petroleum Refining & Trans",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133047",
        "yellow_page_code": "29026",
        "kiosk": false
    },
    {
        "value": "871162",
        "label": "Engineers-Automotive",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133012",
        "yellow_page_code": "28804",
        "kiosk": false
    },
    {
        "value": "871163",
        "label": "Engineers-Gas",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133027",
        "yellow_page_code": "36515, 29112, 36518, 29014",
        "kiosk": false
    },
    {
        "value": "871164",
        "label": "Engineers-Plumbing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133049",
        "yellow_page_code": "29108",
        "kiosk": false
    },
    {
        "value": "871165",
        "label": "Engineers-Pipe Line",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133048",
        "yellow_page_code": "65414, 29113, 29114",
        "kiosk": false
    },
    {
        "value": "871166",
        "label": "Engineers-Technical Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133059",
        "yellow_page_code": "28719, 29213",
        "kiosk": false
    },
    {
        "value": "871167",
        "label": "Machine Tools-Designers & Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133066",
        "yellow_page_code": "49112, 49110",
        "kiosk": false
    },
    {
        "value": "871168",
        "label": "Concrete Technologists",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133002",
        "yellow_page_code": "20709, 20619, 21076, 45334",
        "kiosk": false
    },
    {
        "value": "871169",
        "label": "Engineers-Pneumatic",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133069",
        "yellow_page_code": "28727",
        "kiosk": false
    },
    {
        "value": "871170",
        "label": "Engineers-Pulp & Paper",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133071",
        "yellow_page_code": "29035",
        "kiosk": false
    },
    {
        "value": "871171",
        "label": "Engineers-Earthquake",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133072",
        "yellow_page_code": "28924",
        "kiosk": false
    },
    {
        "value": "871172",
        "label": "Engineers-Plastic",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133073",
        "yellow_page_code": "66228, 66227, 28807",
        "kiosk": false
    },
    {
        "value": "871173",
        "label": "Engineers-Oil Field",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133074",
        "yellow_page_code": "28810",
        "kiosk": false
    },
    {
        "value": "871174",
        "label": "Technologists-Professional",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133075",
        "yellow_page_code": "84252",
        "kiosk": false
    },
    {
        "value": "871175",
        "label": "Engineers-Training & Publishing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133076",
        "yellow_page_code": "29222",
        "kiosk": false
    },
    {
        "value": "871176",
        "label": "Engineers-Building Moving",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133078",
        "yellow_page_code": "28823",
        "kiosk": false
    },
    {
        "value": "871177",
        "label": "Engineers-Pharmaceutical",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133079",
        "yellow_page_code": "29110",
        "kiosk": false
    },
    {
        "value": "871178",
        "label": "Engineers Thermal & Moisture Protection",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133080",
        "yellow_page_code": "28926",
        "kiosk": false
    },
    {
        "value": "871179",
        "label": "Engineers-Ordnance",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133083",
        "yellow_page_code": "28726",
        "kiosk": false
    },
    {
        "value": "871180",
        "label": "Engineers-Computer",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133082",
        "yellow_page_code": "28825",
        "kiosk": false
    },
    {
        "value": "871181",
        "label": "Engineers Textile",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133081",
        "yellow_page_code": "85318, 28828",
        "kiosk": false
    },
    {
        "value": "871182",
        "label": "Engineers Fiberglass",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133084",
        "yellow_page_code": "28814",
        "kiosk": false
    },
    {
        "value": "871183",
        "label": "Engineers-Meat Packing & Food Processing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133085",
        "yellow_page_code": "28818",
        "kiosk": false
    },
    {
        "value": "871184",
        "label": "Horticultural Engineers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133086",
        "yellow_page_code": "41614",
        "kiosk": false
    },
    {
        "value": "871185",
        "label": "Aviation Engineers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133087",
        "yellow_page_code": "05345",
        "kiosk": false
    },
    {
        "value": "871186",
        "label": "Engineers-Technology",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133088",
        "yellow_page_code": "28846",
        "kiosk": false
    },
    {
        "value": "871187",
        "label": "Engineers Naval",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133089",
        "yellow_page_code": "29130",
        "kiosk": false
    },
    {
        "value": "871198",
        "label": "Engineering & Architectural Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "871201",
        "label": "Building Designers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54131011",
        "yellow_page_code": "24240, 66021, 41374, 11419, 24210, 24301, 11204",
        "kiosk": false
    },
    {
        "value": "871202",
        "label": "Architects",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "54131001",
        "yellow_page_code": "03206, 11719, 41481, 29898",
        "kiosk": false
    },
    {
        "value": "871203",
        "label": "Golf Course Architects",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54131017",
        "yellow_page_code": "38117, 38102, 38126",
        "kiosk": false
    },
    {
        "value": "871204",
        "label": "Architectural Illustrators",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54131006",
        "yellow_page_code": "03302, 03213, 03218, 03219",
        "kiosk": false
    },
    {
        "value": "871205",
        "label": "Architects & Engineers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54131003",
        "yellow_page_code": "03208",
        "kiosk": false
    },
    {
        "value": "871206",
        "label": "Architectural & Constr Specifications",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54131004",
        "yellow_page_code": "03303",
        "kiosk": false
    },
    {
        "value": "871207",
        "label": "Architects & Builders Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54131002",
        "yellow_page_code": "03301",
        "kiosk": false
    },
    {
        "value": "871208",
        "label": "Construction Estimates",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54131012",
        "yellow_page_code": "11122, 21003, 11116",
        "kiosk": false
    },
    {
        "value": "871209",
        "label": "Architectural Designers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54131013",
        "yellow_page_code": "03209, 24209",
        "kiosk": false
    },
    {
        "value": "871210",
        "label": "Building Construction-Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54131010",
        "yellow_page_code": "11200, 30276, 21136, 11210, 11212",
        "kiosk": false
    },
    {
        "value": "871211",
        "label": "Engineers-Architectural",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54131014",
        "yellow_page_code": "03221, 28811, 03225, 03224, 03222",
        "kiosk": false
    },
    {
        "value": "871212",
        "label": "Engineers-Building Inspection",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54131015",
        "yellow_page_code": "28900",
        "kiosk": false
    },
    {
        "value": "871213",
        "label": "Home Design & Planning Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54131019",
        "yellow_page_code": "35015, 41118",
        "kiosk": false
    },
    {
        "value": "871214",
        "label": "Foundation-Exploration & Test Borings",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54131016",
        "yellow_page_code": "34205, 34209",
        "kiosk": false
    },
    {
        "value": "871215",
        "label": "House Plans",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54131020",
        "yellow_page_code": "41926, 41931",
        "kiosk": false
    },
    {
        "value": "871217",
        "label": "Architectural Details",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54131005",
        "yellow_page_code": "03211, 46028",
        "kiosk": false
    },
    {
        "value": "871218",
        "label": "Architectural Models",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54131007",
        "yellow_page_code": "03207, 53238",
        "kiosk": false
    },
    {
        "value": "871219",
        "label": "Architectural Programming",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54131008",
        "yellow_page_code": "03220",
        "kiosk": false
    },
    {
        "value": "871220",
        "label": "Ski Area Planners",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54131021",
        "yellow_page_code": "78101",
        "kiosk": false
    },
    {
        "value": "871221",
        "label": "Dioramas",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54131022",
        "yellow_page_code": "24830",
        "kiosk": false
    },
    {
        "value": "871222",
        "label": "House & Building Movers Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54131023",
        "yellow_page_code": "42025",
        "kiosk": false
    },
    {
        "value": "871298",
        "label": "Architectural Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54131009",
        "yellow_page_code": "03226, 03227",
        "kiosk": false
    },
    {
        "value": "871301",
        "label": "Surveyors-Land",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54137002",
        "yellow_page_code": "82506, 43688, 45809",
        "kiosk": false
    },
    {
        "value": "871302",
        "label": "Surveyors-Aerial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54137003",
        "yellow_page_code": "37217, 82502",
        "kiosk": false
    },
    {
        "value": "871303",
        "label": "Engineering Surveys",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54137004",
        "yellow_page_code": "29228, 28707",
        "kiosk": false
    },
    {
        "value": "871304",
        "label": "Engineers-Photogrammetric",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54137005",
        "yellow_page_code": "29111",
        "kiosk": false
    },
    {
        "value": "871305",
        "label": "Surveyors-Quantity",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54137006",
        "yellow_page_code": "69407, 82509",
        "kiosk": false
    },
    {
        "value": "871307",
        "label": "Surveyors-Construction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54137008",
        "yellow_page_code": "42124, 41451, 82326, 82327, 20928, 82521, 82505",
        "kiosk": false
    },
    {
        "value": "871308",
        "label": "Survey Reports",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54137014",
        "yellow_page_code": "82517, 82507",
        "kiosk": false
    },
    {
        "value": "871309",
        "label": "Surveyors-Industrial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54137009",
        "yellow_page_code": "82511, 82519",
        "kiosk": false
    },
    {
        "value": "871310",
        "label": "Surveyors-Mineral",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54137010",
        "yellow_page_code": "82512",
        "kiosk": false
    },
    {
        "value": "871311",
        "label": "Surveyors-Water Rights",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54137015",
        "yellow_page_code": "82514, 92401",
        "kiosk": false
    },
    {
        "value": "871312",
        "label": "Land Development Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54137018",
        "yellow_page_code": "42092, 45734, 45819, 45739, 30261",
        "kiosk": false
    },
    {
        "value": "871313",
        "label": "Surveyors-City",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54137011",
        "yellow_page_code": "82420, 82503, 56431, 82417",
        "kiosk": false
    },
    {
        "value": "871314",
        "label": "Traffic Surveys",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54137012",
        "yellow_page_code": "87811",
        "kiosk": false
    },
    {
        "value": "871315",
        "label": "Environmental Surveys",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54137001",
        "yellow_page_code": "29716",
        "kiosk": false
    },
    {
        "value": "871316",
        "label": "Surveyors Route",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54137013",
        "yellow_page_code": "82518",
        "kiosk": false
    },
    {
        "value": "871317",
        "label": "Geographers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54137016",
        "yellow_page_code": "37109",
        "kiosk": false
    },
    {
        "value": "871318",
        "label": "Surveyors-Seismic",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54136010",
        "yellow_page_code": "82520",
        "kiosk": false
    },
    {
        "value": "872101",
        "label": "Accountants",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "54121101",
        "yellow_page_code": "00114, 00117, 14514, 30095, 22219, 00111, 00123, 00035, 00025, 00100, 00008, 43646",
        "kiosk": false
    },
    {
        "value": "872102",
        "label": "Accounting & Bookkeeping General Svc",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "54121903",
        "yellow_page_code": "00113, 84130, 09412, 31794, 00121, 09413, 09403, 00116, 00115, 00110, 00034, 09400, 00103",
        "kiosk": false
    },
    {
        "value": "872103",
        "label": "Billing Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54121901",
        "yellow_page_code": "08108, 23922, 08095, 51227, 51110, 51135",
        "kiosk": false
    },
    {
        "value": "872104",
        "label": "Calculating Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54121908",
        "yellow_page_code": "12402, 12315",
        "kiosk": false
    },
    {
        "value": "872105",
        "label": "Tax Consultants",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "54121910",
        "yellow_page_code": "43040, 84126, 43070, 42824, 84103, 84110, 84118, 84102, 84122, 84120",
        "kiosk": false
    },
    {
        "value": "872106",
        "label": "Payroll Preparation Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54121402",
        "yellow_page_code": "59910, 43744, 60000, 60004",
        "kiosk": false
    },
    {
        "value": "872107",
        "label": "Accountants Referral Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54121904",
        "yellow_page_code": "00012",
        "kiosk": false
    },
    {
        "value": "872108",
        "label": "Accountants-Certified-General",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "54121102",
        "yellow_page_code": "00023",
        "kiosk": false
    },
    {
        "value": "872109",
        "label": "Accountants-Registered-Industrial",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "54121905",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "872110",
        "label": "Accountants-Certified-Management",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "54121103",
        "yellow_page_code": "00112, 00027",
        "kiosk": false
    },
    {
        "value": "872111",
        "label": "Chartered Accountants",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "54121906",
        "yellow_page_code": "14712, 00024",
        "kiosk": false
    },
    {
        "value": "872114",
        "label": "Circulation Auditors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54121105",
        "yellow_page_code": "17908",
        "kiosk": false
    },
    {
        "value": "872115",
        "label": "Reciprocal Billing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54121907",
        "yellow_page_code": "70817",
        "kiosk": false
    },
    {
        "value": "872116",
        "label": "Payroll Auditors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54121401",
        "yellow_page_code": "59909",
        "kiosk": false
    },
    {
        "value": "872117",
        "label": "Enrolled Agents",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "54121106",
        "yellow_page_code": "29599",
        "kiosk": false
    },
    {
        "value": "872118",
        "label": "Auditors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54121902",
        "yellow_page_code": "04412, 73505, 04405, 04411",
        "kiosk": false
    },
    {
        "value": "872119",
        "label": "Accountants-Public School",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54121104",
        "yellow_page_code": "00118",
        "kiosk": false
    },
    {
        "value": "872120",
        "label": "Business Solutions",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54121909",
        "yellow_page_code": "78414, 11715",
        "kiosk": false
    },
    {
        "value": "872121",
        "label": "Accountants Commercial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54121911",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "872122",
        "label": "Tax Grievance Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54121912",
        "yellow_page_code": "84117",
        "kiosk": false
    },
    {
        "value": "873101",
        "label": "Laboratories-Research & Development",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171401",
        "yellow_page_code": "71820, 45314, 71805, 45400",
        "kiosk": false
    },
    {
        "value": "873102",
        "label": "Experimental Work",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171413",
        "yellow_page_code": "30200",
        "kiosk": false
    },
    {
        "value": "873103",
        "label": "Solar Energy Research & Development",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171502",
        "yellow_page_code": "79300, 79319",
        "kiosk": false
    },
    {
        "value": "873104",
        "label": "Laboratories",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171414",
        "yellow_page_code": "45302, 45328",
        "kiosk": false
    },
    {
        "value": "873105",
        "label": "Human Factors-Research & Development",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171503",
        "yellow_page_code": "42008, 42037",
        "kiosk": false
    },
    {
        "value": "873106",
        "label": "Electronic Research & Development",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171504",
        "yellow_page_code": "28104",
        "kiosk": false
    },
    {
        "value": "873107",
        "label": "Oceanographers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171505",
        "yellow_page_code": "56108",
        "kiosk": false
    },
    {
        "value": "873108",
        "label": "Pharmaceutical Research Laboratories",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171506",
        "yellow_page_code": "60611",
        "kiosk": false
    },
    {
        "value": "873109",
        "label": "Testing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171415",
        "yellow_page_code": "56472, 85223, 85228",
        "kiosk": false
    },
    {
        "value": "873110",
        "label": "Technology Assistance Programs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171402",
        "yellow_page_code": "04120, 84224, 84222, 84234",
        "kiosk": false
    },
    {
        "value": "873111",
        "label": "Environmental & Ecological Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171416",
        "yellow_page_code": "56453, 29632, 29635, 29630, 21053, 29618, 29614, 29612, 29704, 29721, 30223, 43641, 38922",
        "kiosk": false
    },
    {
        "value": "873112",
        "label": "Engineers-Biomedical",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171417",
        "yellow_page_code": "28901",
        "kiosk": false
    },
    {
        "value": "873113",
        "label": "Lake Management Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171507",
        "yellow_page_code": "45409",
        "kiosk": false
    },
    {
        "value": "873114",
        "label": "Pollution Control",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171501",
        "yellow_page_code": "56408, 67113",
        "kiosk": false
    },
    {
        "value": "873116",
        "label": "Food Analysis",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171418",
        "yellow_page_code": "33708",
        "kiosk": false
    },
    {
        "value": "873117",
        "label": "Recycling Education Info & Training Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171508",
        "yellow_page_code": "71036",
        "kiosk": false
    },
    {
        "value": "873118",
        "label": "Recycling Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171509",
        "yellow_page_code": "71044",
        "kiosk": false
    },
    {
        "value": "873119",
        "label": "Agricultural Research",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171510",
        "yellow_page_code": "30122",
        "kiosk": false
    },
    {
        "value": "873120",
        "label": "Rubber Research & Consulting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171419",
        "yellow_page_code": "72914",
        "kiosk": false
    },
    {
        "value": "873121",
        "label": "Cryogenic Research & Development",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171404",
        "yellow_page_code": "22805",
        "kiosk": false
    },
    {
        "value": "873122",
        "label": "Laboratories-Agricultural",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171511",
        "yellow_page_code": "45311",
        "kiosk": false
    },
    {
        "value": "873123",
        "label": "Laboratories-Marine Biological",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171420",
        "yellow_page_code": "45313",
        "kiosk": false
    },
    {
        "value": "873124",
        "label": "Laboratories-Biochemical",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171421",
        "yellow_page_code": "45321",
        "kiosk": false
    },
    {
        "value": "873125",
        "label": "Lasers-Communication Research & Dev",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171403",
        "yellow_page_code": "46029, 46007, 46018",
        "kiosk": false
    },
    {
        "value": "873126",
        "label": "Biotechnology Products & Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171422",
        "yellow_page_code": "08210, 00817",
        "kiosk": false
    },
    {
        "value": "873127",
        "label": "Biology Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171423",
        "yellow_page_code": "08224, 08216",
        "kiosk": false
    },
    {
        "value": "873128",
        "label": "Chemical Research",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171424",
        "yellow_page_code": "15014",
        "kiosk": false
    },
    {
        "value": "873129",
        "label": "Artificial Intelligence Research/Development",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171523",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "873130",
        "label": "Wetlands Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171512",
        "yellow_page_code": "93239, 93238",
        "kiosk": false
    },
    {
        "value": "873131",
        "label": "Ergonomics",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171513",
        "yellow_page_code": "29714, 29715",
        "kiosk": false
    },
    {
        "value": "873133",
        "label": "Geological Laboratories",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171514",
        "yellow_page_code": "37201",
        "kiosk": false
    },
    {
        "value": "873135",
        "label": "Operations Research",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171405",
        "yellow_page_code": "57507",
        "kiosk": false
    },
    {
        "value": "873136",
        "label": "Pressure Testing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171515",
        "yellow_page_code": "68118",
        "kiosk": false
    },
    {
        "value": "873137",
        "label": "Science & Technology-Canada",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171406",
        "yellow_page_code": "74713",
        "kiosk": false
    },
    {
        "value": "873138",
        "label": "Surveyors-Global Positioning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171516",
        "yellow_page_code": "82510",
        "kiosk": false
    },
    {
        "value": "873141",
        "label": "Technologists-Applied Sciences",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171407",
        "yellow_page_code": "84223",
        "kiosk": false
    },
    {
        "value": "873142",
        "label": "Temperature Modification Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171517",
        "yellow_page_code": "84937",
        "kiosk": false
    },
    {
        "value": "873143",
        "label": "Breath Deodorants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171425",
        "yellow_page_code": "10411",
        "kiosk": false
    },
    {
        "value": "873198",
        "label": "Commercial Physical Research",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171408",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "873201",
        "label": "Educational Research",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54172001",
        "yellow_page_code": "27206",
        "kiosk": false
    },
    {
        "value": "873202",
        "label": "Economic Research & Analysis",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54191004",
        "yellow_page_code": "27109, 27112, 21196, 27119, 27123",
        "kiosk": false
    },
    {
        "value": "873203",
        "label": "Product Development & Marketing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54191005",
        "yellow_page_code": "68506",
        "kiosk": false
    },
    {
        "value": "873204",
        "label": "Market Research & Analysis",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54191001",
        "yellow_page_code": "75619, 50106, 55021, 70633",
        "kiosk": false
    },
    {
        "value": "873205",
        "label": "Public Opinion Analysts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54191002",
        "yellow_page_code": "68806",
        "kiosk": false
    },
    {
        "value": "873206",
        "label": "Research Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54191003",
        "yellow_page_code": "71725, 71707, 74611, 30349, 71724",
        "kiosk": false
    },
    {
        "value": "873207",
        "label": "Social Research Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54172002",
        "yellow_page_code": "79015, 78913",
        "kiosk": false
    },
    {
        "value": "873208",
        "label": "Ideas-Developed Marketed & Etc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54191006",
        "yellow_page_code": "42600",
        "kiosk": false
    },
    {
        "value": "873209",
        "label": "Toy Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54191007",
        "yellow_page_code": "87602",
        "kiosk": false
    },
    {
        "value": "873210",
        "label": "Flag Information",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54191008",
        "yellow_page_code": "32806",
        "kiosk": false
    },
    {
        "value": "873211",
        "label": "Researchers-Independent",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54191009",
        "yellow_page_code": "71803",
        "kiosk": false
    },
    {
        "value": "873212",
        "label": "Radio Program Surveys",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54191010",
        "yellow_page_code": "69811",
        "kiosk": false
    },
    {
        "value": "873213",
        "label": "Bill Reviewing Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54191011",
        "yellow_page_code": "08037",
        "kiosk": false
    },
    {
        "value": "873214",
        "label": "Travel Research",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54191012",
        "yellow_page_code": "88411",
        "kiosk": false
    },
    {
        "value": "873215",
        "label": "Cable Research & Development",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54191013",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "873216",
        "label": "Public Records Research",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54191014",
        "yellow_page_code": "68820",
        "kiosk": false
    },
    {
        "value": "873217",
        "label": "Laboratories-Psychological",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54172009",
        "yellow_page_code": "45403",
        "kiosk": false
    },
    {
        "value": "873298",
        "label": "Commercial Economic Educational Research",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54172003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "873301",
        "label": "Archaeologists",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54172004",
        "yellow_page_code": "03108, 03115",
        "kiosk": false
    },
    {
        "value": "873302",
        "label": "Geothermal Exploration",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54172007",
        "yellow_page_code": "37207, 37224",
        "kiosk": false
    },
    {
        "value": "873303",
        "label": "Foundation-Educ Philanthropic Research",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54172006",
        "yellow_page_code": "34204, 30061, 34228, 34210",
        "kiosk": false
    },
    {
        "value": "873304",
        "label": "Medical Research",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "54171518",
        "yellow_page_code": "51203",
        "kiosk": false
    },
    {
        "value": "873305",
        "label": "Historical Research",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54172010",
        "yellow_page_code": "71722, 41009",
        "kiosk": false
    },
    {
        "value": "873306",
        "label": "Observatories",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171519",
        "yellow_page_code": "56104",
        "kiosk": false
    },
    {
        "value": "873307",
        "label": "Noncommercial Research Organizations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171409",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "873308",
        "label": "Cryonic Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171410",
        "yellow_page_code": "22806",
        "kiosk": false
    },
    {
        "value": "873309",
        "label": "Psychic Scientific Research Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54172008",
        "yellow_page_code": "68715",
        "kiosk": false
    },
    {
        "value": "873310",
        "label": "Pharmaceutical Information",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171520",
        "yellow_page_code": "60613",
        "kiosk": false
    },
    {
        "value": "873311",
        "label": "Paleontologists",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54172005",
        "yellow_page_code": "58632",
        "kiosk": false
    },
    {
        "value": "873312",
        "label": "Cancer Research Centers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171521",
        "yellow_page_code": "12613",
        "kiosk": false
    },
    {
        "value": "873313",
        "label": "Pet Cloning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171412",
        "yellow_page_code": "60423",
        "kiosk": false
    },
    {
        "value": "873314",
        "label": "Oceanographic Research & Development",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171522",
        "yellow_page_code": "56123",
        "kiosk": false
    },
    {
        "value": "873315",
        "label": "Genetics Medical",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171411",
        "yellow_page_code": "37095",
        "kiosk": false
    },
    {
        "value": "873316",
        "label": "Nanotechnology Research & Development ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171301",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "873317",
        "label": "Physical Engineering & Life Sciences Research & Development",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54171524",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "873318",
        "label": "Social Sciences & Humanities Research & Development",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54172011",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "873401",
        "label": "Soil Testing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138035",
        "yellow_page_code": "60204, 79210, 79207",
        "kiosk": false
    },
    {
        "value": "873402",
        "label": "Laboratories-Testing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138023",
        "yellow_page_code": "45329, 30287, 33899, 45235, 85301, 45332, 45318, 60335, 45330, 45402",
        "kiosk": false
    },
    {
        "value": "873403",
        "label": "Well Testing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138042",
        "yellow_page_code": "93209",
        "kiosk": false
    },
    {
        "value": "873404",
        "label": "X-Ray Laboratories-Industrial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138044",
        "yellow_page_code": "94621, 94608",
        "kiosk": false
    },
    {
        "value": "873405",
        "label": "X-Ray Inspection Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138043",
        "yellow_page_code": "94605",
        "kiosk": false
    },
    {
        "value": "873406",
        "label": "Pollution Control Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138030",
        "yellow_page_code": "67200",
        "kiosk": false
    },
    {
        "value": "873407",
        "label": "Hearing & Sound Level Testing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138013",
        "yellow_page_code": "79502, 40402",
        "kiosk": false
    },
    {
        "value": "873408",
        "label": "Air Pollution Measuring Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138001",
        "yellow_page_code": "01298, 01213, 21079, 01304",
        "kiosk": false
    },
    {
        "value": "873409",
        "label": "Forensic Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138012",
        "yellow_page_code": "20355, 34018, 20227, 30070, 34096, 34122, 29030, 34102",
        "kiosk": false
    },
    {
        "value": "873410",
        "label": "Assayers & Assaying Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138003",
        "yellow_page_code": "04008",
        "kiosk": false
    },
    {
        "value": "873411",
        "label": "Laboratories-Veterinary",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54194004",
        "yellow_page_code": "45404, 90930",
        "kiosk": false
    },
    {
        "value": "873412",
        "label": "Metallurgists",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138026",
        "yellow_page_code": "51806",
        "kiosk": false
    },
    {
        "value": "873413",
        "label": "Machine Shops-Experimental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138025",
        "yellow_page_code": "49107",
        "kiosk": false
    },
    {
        "value": "873414",
        "label": "Laboratories-Analytical",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138018",
        "yellow_page_code": "36522, 45301",
        "kiosk": false
    },
    {
        "value": "873415",
        "label": "Infrared Inspection Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138016",
        "yellow_page_code": "43107",
        "kiosk": false
    },
    {
        "value": "873416",
        "label": "Plastics-Research & Consulting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138029",
        "yellow_page_code": "30540, 66408, 66219, 67121",
        "kiosk": false
    },
    {
        "value": "873417",
        "label": "Food Processing Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92614005",
        "yellow_page_code": "33807",
        "kiosk": false
    },
    {
        "value": "873418",
        "label": "Optical Engineers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138028",
        "yellow_page_code": "57511",
        "kiosk": false
    },
    {
        "value": "873419",
        "label": "Radon Testing & Correction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138033",
        "yellow_page_code": "70016, 70116, 70102, 70110, 70112, 70113, 70114, 69710, 70015, 70115",
        "kiosk": false
    },
    {
        "value": "873420",
        "label": "Asbestos-Consulting & Testing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138002",
        "yellow_page_code": "03919, 03911",
        "kiosk": false
    },
    {
        "value": "873421",
        "label": "Electrical Power Systems-Testing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138010",
        "yellow_page_code": "28011, 27990, 27814",
        "kiosk": false
    },
    {
        "value": "873422",
        "label": "Calibration Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138005",
        "yellow_page_code": "12364, 85027, 29236, 12401",
        "kiosk": false
    },
    {
        "value": "873423",
        "label": "Hydrostatic Testing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138014",
        "yellow_page_code": "42206, 56523, 23202",
        "kiosk": false
    },
    {
        "value": "873424",
        "label": "Laboratories-Metallurgical",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138021",
        "yellow_page_code": "45305",
        "kiosk": false
    },
    {
        "value": "873425",
        "label": "Laboratories-Petroleum",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138022",
        "yellow_page_code": "45307",
        "kiosk": false
    },
    {
        "value": "873426",
        "label": "Oil Well Core Analysis",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138027",
        "yellow_page_code": "56903, 56816",
        "kiosk": false
    },
    {
        "value": "873427",
        "label": "Surface Plates",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138036",
        "yellow_page_code": "82307",
        "kiosk": false
    },
    {
        "value": "873428",
        "label": "Tank Strapping & Calibration",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138037",
        "yellow_page_code": "83513",
        "kiosk": false
    },
    {
        "value": "873429",
        "label": "Veterinary Medical Laboratories",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54194014",
        "yellow_page_code": "91004",
        "kiosk": false
    },
    {
        "value": "873430",
        "label": "Testing (Non-Destructive)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138038",
        "yellow_page_code": "28826, 85217, 55613, 85225",
        "kiosk": false
    },
    {
        "value": "873431",
        "label": "Radium",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138032",
        "yellow_page_code": "70101",
        "kiosk": false
    },
    {
        "value": "873432",
        "label": "Grain Testing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138020",
        "yellow_page_code": "38714",
        "kiosk": false
    },
    {
        "value": "873433",
        "label": "Dairy Laboratories",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138011",
        "yellow_page_code": "45319, 23207, 23215",
        "kiosk": false
    },
    {
        "value": "873434",
        "label": "Poultry Research",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138031",
        "yellow_page_code": "45327, 67804",
        "kiosk": false
    },
    {
        "value": "873435",
        "label": "Contamination Control Equip & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138008",
        "yellow_page_code": "21111",
        "kiosk": false
    },
    {
        "value": "873436",
        "label": "Contamination Control Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138009",
        "yellow_page_code": "21112",
        "kiosk": false
    },
    {
        "value": "873437",
        "label": "Livestock Testing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54194005",
        "yellow_page_code": "48212",
        "kiosk": false
    },
    {
        "value": "873438",
        "label": "Carbon Monoxide Testing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138006",
        "yellow_page_code": "13210",
        "kiosk": false
    },
    {
        "value": "873439",
        "label": "Testing (Toxic Material & Gases)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138039",
        "yellow_page_code": "85216",
        "kiosk": false
    },
    {
        "value": "873440",
        "label": "Testing Equipment-Environmental",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138040",
        "yellow_page_code": "85311",
        "kiosk": false
    },
    {
        "value": "873441",
        "label": "Ultrasonic Inspection Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138041",
        "yellow_page_code": "89902, 89828, 89824",
        "kiosk": false
    },
    {
        "value": "873442",
        "label": "Liquor Testing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138024",
        "yellow_page_code": "47909",
        "kiosk": false
    },
    {
        "value": "873443",
        "label": "Soil Analysis",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138034",
        "yellow_page_code": "79212",
        "kiosk": false
    },
    {
        "value": "873444",
        "label": "Back Flow Device Testing Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138004",
        "yellow_page_code": "05605, 05627",
        "kiosk": false
    },
    {
        "value": "873445",
        "label": "Concrete Inspection",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138007",
        "yellow_page_code": "20712",
        "kiosk": false
    },
    {
        "value": "873446",
        "label": "Indoor Air Quality Testing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138015",
        "yellow_page_code": "42919",
        "kiosk": false
    },
    {
        "value": "873447",
        "label": "Iso Certification",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138017",
        "yellow_page_code": "43913, 20121, 14510",
        "kiosk": false
    },
    {
        "value": "873448",
        "label": "Water Testing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138046",
        "yellow_page_code": "92320",
        "kiosk": false
    },
    {
        "value": "873449",
        "label": "Smoke & Dust Abatement Surveys",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138045",
        "yellow_page_code": "78520",
        "kiosk": false
    },
    {
        "value": "873450",
        "label": "Nuclear Research & Development",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138047",
        "yellow_page_code": "55715",
        "kiosk": false
    },
    {
        "value": "873451",
        "label": "Seed Testing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138048",
        "yellow_page_code": "75338",
        "kiosk": false
    },
    {
        "value": "873452",
        "label": "Window Testing Laboratories",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138049",
        "yellow_page_code": "93638",
        "kiosk": false
    },
    {
        "value": "873453",
        "label": "Mudlogging",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138019",
        "yellow_page_code": "54509",
        "kiosk": false
    },
    {
        "value": "873454",
        "label": "Metals-Testing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138050",
        "yellow_page_code": "45331, 45322, 51416",
        "kiosk": false
    },
    {
        "value": "873455",
        "label": "Thermal Imaging",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138051",
        "yellow_page_code": "85691, 85690",
        "kiosk": false
    },
    {
        "value": "873456",
        "label": "Feed Testing Nir",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138052",
        "yellow_page_code": "31109",
        "kiosk": false
    },
    {
        "value": "873457",
        "label": "Laboratories Water & Waste Water",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138053",
        "yellow_page_code": "45317",
        "kiosk": false
    },
    {
        "value": "873458",
        "label": "Govt-Coml Physical/Biological Research",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138054",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "873459",
        "label": "Cannabis-Laboratory Testing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138055",
        "yellow_page_code": "12650",
        "kiosk": false
    },
    {
        "value": "873460",
        "label": "Testing Laboratories & Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54138056",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "874101",
        "label": "Medical Business Administration",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56111012",
        "yellow_page_code": "23814, 51042, 51008, 62302, 28534",
        "kiosk": false
    },
    {
        "value": "874102",
        "label": "Export Management",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56111008",
        "yellow_page_code": "30217, 30206",
        "kiosk": false
    },
    {
        "value": "874103",
        "label": "Management Accounting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56111013",
        "yellow_page_code": "49817",
        "kiosk": false
    },
    {
        "value": "874104",
        "label": "Construction Management",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23799012",
        "yellow_page_code": "21004",
        "kiosk": false
    },
    {
        "value": "874105",
        "label": "Association Management",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56111003",
        "yellow_page_code": "04103",
        "kiosk": false
    },
    {
        "value": "874106",
        "label": "Construction Reports",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23799013",
        "yellow_page_code": "21006",
        "kiosk": false
    },
    {
        "value": "874107",
        "label": "Nursing Homes Management Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56111014",
        "yellow_page_code": "56002, 27319, 55913",
        "kiosk": false
    },
    {
        "value": "874108",
        "label": "Project Management",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56111018",
        "yellow_page_code": "68536, 68535, 21146",
        "kiosk": false
    },
    {
        "value": "874109",
        "label": "Aircraft Management Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56111002",
        "yellow_page_code": "05323, 01401",
        "kiosk": false
    },
    {
        "value": "874110",
        "label": "Bid Reporting Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56111005",
        "yellow_page_code": "08010, 08008",
        "kiosk": false
    },
    {
        "value": "874111",
        "label": "Builders-Construction Control Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23799014",
        "yellow_page_code": "11105",
        "kiosk": false
    },
    {
        "value": "874112",
        "label": "Printing Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56111017",
        "yellow_page_code": "68335",
        "kiosk": false
    },
    {
        "value": "874113",
        "label": "Warehouses-Representatives",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56111019",
        "yellow_page_code": "91605",
        "kiosk": false
    },
    {
        "value": "874114",
        "label": "Construction Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23799015",
        "yellow_page_code": "20932",
        "kiosk": false
    },
    {
        "value": "874115",
        "label": "Critical Path Scheduling",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56111007",
        "yellow_page_code": "22608",
        "kiosk": false
    },
    {
        "value": "874116",
        "label": "Blueprint Exchange Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56111006",
        "yellow_page_code": "08536",
        "kiosk": false
    },
    {
        "value": "874117",
        "label": "Housing-Survey Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56111010",
        "yellow_page_code": "41915",
        "kiosk": false
    },
    {
        "value": "874118",
        "label": "Plan Bureaus",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56111016",
        "yellow_page_code": "66015, 65909",
        "kiosk": false
    },
    {
        "value": "874119",
        "label": "Beauty Salon Management",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56111004",
        "yellow_page_code": "07207",
        "kiosk": false
    },
    {
        "value": "874120",
        "label": "Exposition Managers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56111009",
        "yellow_page_code": "30303, 30123, 30320",
        "kiosk": false
    },
    {
        "value": "874121",
        "label": "Nursing Homes Constr & Mgmt Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "23622043",
        "yellow_page_code": "55935",
        "kiosk": false
    },
    {
        "value": "874122",
        "label": "Office Administrative Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56111011",
        "yellow_page_code": "56212, 56290",
        "kiosk": false
    },
    {
        "value": "874123",
        "label": "Administrators-Chartered",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56111001",
        "yellow_page_code": "00307",
        "kiosk": false
    },
    {
        "value": "874124",
        "label": "Swimming Pool Management",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56111020",
        "yellow_page_code": "82805",
        "kiosk": false
    },
    {
        "value": "874125",
        "label": "Business Plans",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56111015",
        "yellow_page_code": "11827",
        "kiosk": false
    },
    {
        "value": "874126",
        "label": "Internet Management & Maintenance",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56111021",
        "yellow_page_code": "43559",
        "kiosk": false
    },
    {
        "value": "874127",
        "label": "Food Service Management",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56111024",
        "yellow_page_code": "33904",
        "kiosk": false
    },
    {
        "value": "874130",
        "label": "Management Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56111022",
        "yellow_page_code": "30163, 49816, 68627",
        "kiosk": false
    },
    {
        "value": "874198",
        "label": "Fuel Management",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56111023",
        "yellow_page_code": "34827",
        "kiosk": false
    },
    {
        "value": "874201",
        "label": "Business Management Consultants",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "54161401",
        "yellow_page_code": "73323, 49840, 11810, 20997, 30145, 11822, 21045, 11814, 00515, 49800, 19006, 21035, 21012, 21056, 11808, 11804, 11820, 30011",
        "kiosk": false
    },
    {
        "value": "874202",
        "label": "Factory Locating Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161402",
        "yellow_page_code": "30509",
        "kiosk": false
    },
    {
        "value": "874203",
        "label": "Personnel Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161207",
        "yellow_page_code": "71838, 59426, 60314, 29934, 21069, 60304, 60320, 80421, 60400",
        "kiosk": false
    },
    {
        "value": "874204",
        "label": "Labor Relation Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161206",
        "yellow_page_code": "45209",
        "kiosk": false
    },
    {
        "value": "874205",
        "label": "Publishing Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161102",
        "yellow_page_code": "69008",
        "kiosk": false
    },
    {
        "value": "874206",
        "label": "Planners-Professional",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161208",
        "yellow_page_code": "65995, 66001, 66019",
        "kiosk": false
    },
    {
        "value": "874207",
        "label": "Site Planners",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161405",
        "yellow_page_code": "53116, 77902, 53326, 49955",
        "kiosk": false
    },
    {
        "value": "874208",
        "label": "Safety Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161214",
        "yellow_page_code": "43120, 21160, 68524, 73404, 41341",
        "kiosk": false
    },
    {
        "value": "874209",
        "label": "Financial Management & Consulting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161114",
        "yellow_page_code": "31796",
        "kiosk": false
    },
    {
        "value": "874210",
        "label": "Travel Marketing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161306",
        "yellow_page_code": "88501",
        "kiosk": false
    },
    {
        "value": "874212",
        "label": "Energy Conservation & Mgmt Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161203",
        "yellow_page_code": "71835, 28702, 28617, 28699, 71313, 28720, 28614, 04190, 21144, 28724, 28651",
        "kiosk": false
    },
    {
        "value": "874213",
        "label": "Marketing Programs & Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161303",
        "yellow_page_code": "70514, 50114, 23503, 73603, 50109, 50110, 24914",
        "kiosk": false
    },
    {
        "value": "874214",
        "label": "Marketing Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161302",
        "yellow_page_code": "50121, 51315, 50108, 22395, 21155, 11716, 50898",
        "kiosk": false
    },
    {
        "value": "874215",
        "label": "Consultants-Referral Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161218",
        "yellow_page_code": "21103",
        "kiosk": false
    },
    {
        "value": "874216",
        "label": "Employee Benefit Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161201",
        "yellow_page_code": "07813, 28514, 60134, 28519, 21145, 60626",
        "kiosk": false
    },
    {
        "value": "874217",
        "label": "Electric Rate Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161407",
        "yellow_page_code": "27709, 27830",
        "kiosk": false
    },
    {
        "value": "874218",
        "label": "Electrical Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161406",
        "yellow_page_code": "27916, 28010",
        "kiosk": false
    },
    {
        "value": "874219",
        "label": "Golf Course Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161103",
        "yellow_page_code": "38207, 38107",
        "kiosk": false
    },
    {
        "value": "874220",
        "label": "Hazardous Chemical Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161408",
        "yellow_page_code": "40317",
        "kiosk": false
    },
    {
        "value": "874221",
        "label": "Historical Restoration Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161104",
        "yellow_page_code": "29036, 41007",
        "kiosk": false
    },
    {
        "value": "874222",
        "label": "Human Resource Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161205",
        "yellow_page_code": "42130, 42135, 42036, 42022, 28543, 21047",
        "kiosk": false
    },
    {
        "value": "874223",
        "label": "Instrumentation Indicating Cntrl Cnsltnt",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161403",
        "yellow_page_code": "43325, 43343, 43311",
        "kiosk": false
    },
    {
        "value": "874224",
        "label": "Invention Consultants & Brokers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161307",
        "yellow_page_code": "43701, 43810, 43618",
        "kiosk": false
    },
    {
        "value": "874225",
        "label": "Marine Resource Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161418",
        "yellow_page_code": "50022",
        "kiosk": false
    },
    {
        "value": "874226",
        "label": "Specifications Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161409",
        "yellow_page_code": "79803",
        "kiosk": false
    },
    {
        "value": "874227",
        "label": "Sports Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161105",
        "yellow_page_code": "80016",
        "kiosk": false
    },
    {
        "value": "874228",
        "label": "Instructional Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161120",
        "yellow_page_code": "43324, 43320",
        "kiosk": false
    },
    {
        "value": "874229",
        "label": "Productivity Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161106",
        "yellow_page_code": "68521",
        "kiosk": false
    },
    {
        "value": "874230",
        "label": "Quality Control Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161107",
        "yellow_page_code": "69350, 49951, 69408, 21048",
        "kiosk": false
    },
    {
        "value": "874231",
        "label": "Library Support Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161121",
        "yellow_page_code": "47010",
        "kiosk": false
    },
    {
        "value": "874232",
        "label": "Risk Management Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161308",
        "yellow_page_code": "23009, 72303",
        "kiosk": false
    },
    {
        "value": "874233",
        "label": "Motivation Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161215",
        "yellow_page_code": "54201",
        "kiosk": false
    },
    {
        "value": "874234",
        "label": "Records-Management Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161108",
        "yellow_page_code": "70917, 25291, 25289, 70918, 25320",
        "kiosk": false
    },
    {
        "value": "874235",
        "label": "Power Plant Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161410",
        "yellow_page_code": "67907",
        "kiosk": false
    },
    {
        "value": "874236",
        "label": "Asset Protection Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161210",
        "yellow_page_code": "04050",
        "kiosk": false
    },
    {
        "value": "874237",
        "label": "Fallout Shelters-Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161411",
        "yellow_page_code": "30603",
        "kiosk": false
    },
    {
        "value": "874238",
        "label": "Gaming Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161211",
        "yellow_page_code": "30615",
        "kiosk": false
    },
    {
        "value": "874239",
        "label": "Fire Protection Engineers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161309",
        "yellow_page_code": "32123",
        "kiosk": false
    },
    {
        "value": "874240",
        "label": "Library Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161115",
        "yellow_page_code": "47014, 47101",
        "kiosk": false
    },
    {
        "value": "874241",
        "label": "Consultants-Support Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161109",
        "yellow_page_code": "21014",
        "kiosk": false
    },
    {
        "value": "874242",
        "label": "Health Care Management",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161110",
        "yellow_page_code": "40614, 49819, 40421, 13932, 40438",
        "kiosk": false
    },
    {
        "value": "874243",
        "label": "Dental Practice-Management & Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161111",
        "yellow_page_code": "23916, 23816",
        "kiosk": false
    },
    {
        "value": "874244",
        "label": "Disability Accessibility Compliance",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161216",
        "yellow_page_code": "24810, 04216",
        "kiosk": false
    },
    {
        "value": "874245",
        "label": "Candy & Confectionery-Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161116",
        "yellow_page_code": "12707",
        "kiosk": false
    },
    {
        "value": "874247",
        "label": "International Marketing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161301",
        "yellow_page_code": "43536",
        "kiosk": false
    },
    {
        "value": "874248",
        "label": "Multi-Level Marketing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161304",
        "yellow_page_code": "54517",
        "kiosk": false
    },
    {
        "value": "874249",
        "label": "Management Research",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161112",
        "yellow_page_code": "49835",
        "kiosk": false
    },
    {
        "value": "874250",
        "label": "Physical Distribution Management",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161404",
        "yellow_page_code": "61203",
        "kiosk": false
    },
    {
        "value": "874251",
        "label": "Solar Heating Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161413",
        "yellow_page_code": "79203",
        "kiosk": false
    },
    {
        "value": "874252",
        "label": "Soil Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161414",
        "yellow_page_code": "79217",
        "kiosk": false
    },
    {
        "value": "874253",
        "label": "Survival Training",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161415",
        "yellow_page_code": "82536, 82320",
        "kiosk": false
    },
    {
        "value": "874254",
        "label": "Employees Suggestion System",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161217",
        "yellow_page_code": "28511",
        "kiosk": false
    },
    {
        "value": "874255",
        "label": "Utility Bill Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161117",
        "yellow_page_code": "90190",
        "kiosk": false
    },
    {
        "value": "874257",
        "label": "Laboratory Set-Up Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161118",
        "yellow_page_code": "45315",
        "kiosk": false
    },
    {
        "value": "874258",
        "label": "Systems Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161416",
        "yellow_page_code": "82911",
        "kiosk": false
    },
    {
        "value": "874259",
        "label": "Scrap Marketing Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161305",
        "yellow_page_code": "74735",
        "kiosk": false
    },
    {
        "value": "874260",
        "label": "Natural Resources Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161417",
        "yellow_page_code": "55112",
        "kiosk": false
    },
    {
        "value": "874261",
        "label": "Navigation Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161419",
        "yellow_page_code": "55209",
        "kiosk": false
    },
    {
        "value": "874262",
        "label": "Airport Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161122",
        "yellow_page_code": "01707",
        "kiosk": false
    },
    {
        "value": "874263",
        "label": "Point Of Sale",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161101",
        "yellow_page_code": "29705, 66914",
        "kiosk": false
    },
    {
        "value": "874264",
        "label": "Consulting Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161113",
        "yellow_page_code": "21061",
        "kiosk": false
    },
    {
        "value": "874265",
        "label": "Hawaiian Cultural Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161124",
        "yellow_page_code": "40313",
        "kiosk": false
    },
    {
        "value": "874266",
        "label": "Logistics Consulting Services ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161125",
        "yellow_page_code": "48519, 56418",
        "kiosk": false
    },
    {
        "value": "874267",
        "label": "Foreign Sales Corporation-Management",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161126",
        "yellow_page_code": "34110",
        "kiosk": false
    },
    {
        "value": "874268",
        "label": "Customer Loyalty Programs & Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161310",
        "yellow_page_code": "23020",
        "kiosk": false
    },
    {
        "value": "874269",
        "label": "Administrative & General Management Consulting Services ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161127",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "874301",
        "label": "Lobbyists",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54182002",
        "yellow_page_code": "46893, 46902, 48416",
        "kiosk": false
    },
    {
        "value": "874302",
        "label": "Public Relations Counselors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54182004",
        "yellow_page_code": "49812, 68811, 68809, 20374, 68812, 22915, 68808, 22036, 21158",
        "kiosk": false
    },
    {
        "value": "874303",
        "label": "Corporate Image Development Svcs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54182001",
        "yellow_page_code": "21712",
        "kiosk": false
    },
    {
        "value": "874304",
        "label": "Political Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54182003",
        "yellow_page_code": "00425, 21059, 67107",
        "kiosk": false
    },
    {
        "value": "874401",
        "label": "Facilities Management",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56121003",
        "yellow_page_code": "30513",
        "kiosk": false
    },
    {
        "value": "874402",
        "label": "Aerospace Support Services-Technical",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56121001",
        "yellow_page_code": "00712",
        "kiosk": false
    },
    {
        "value": "874403",
        "label": "Directory Enquiries",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56121005",
        "yellow_page_code": "24851, 34323, 60003",
        "kiosk": false
    },
    {
        "value": "874498",
        "label": "Facilities Support Management Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56121004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "874499",
        "label": "Correctional Institutions-Private",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "56121002",
        "yellow_page_code": "21721, 21718",
        "kiosk": false
    },
    {
        "value": "874801",
        "label": "Lighting Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169031",
        "yellow_page_code": "30488, 47304, 47316",
        "kiosk": false
    },
    {
        "value": "874802",
        "label": "Training Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161212",
        "yellow_page_code": "88125, 88110, 88113, 88109, 88201",
        "kiosk": false
    },
    {
        "value": "874803",
        "label": "Motel & Hotel Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169027",
        "yellow_page_code": "53706, 41812, 21055",
        "kiosk": false
    },
    {
        "value": "874804",
        "label": "Wine Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161213",
        "yellow_page_code": "93708",
        "kiosk": false
    },
    {
        "value": "874805",
        "label": "Public Utility Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161831",
        "yellow_page_code": "21073, 68902, 90192",
        "kiosk": false
    },
    {
        "value": "874806",
        "label": "Product Design/Development Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169056",
        "yellow_page_code": "30347, 24237, 68523, 68508, 29221",
        "kiosk": false
    },
    {
        "value": "874807",
        "label": "Recreation Program Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169021",
        "yellow_page_code": "71031, 71102",
        "kiosk": false
    },
    {
        "value": "874808",
        "label": "Sales Counselors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169022",
        "yellow_page_code": "73506",
        "kiosk": false
    },
    {
        "value": "874809",
        "label": "Roofing Service Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54133077",
        "yellow_page_code": "72709, 30136",
        "kiosk": false
    },
    {
        "value": "874810",
        "label": "Sales Promotion Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161816",
        "yellow_page_code": "73600",
        "kiosk": false
    },
    {
        "value": "874811",
        "label": "Sales Training",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169055",
        "yellow_page_code": "21164, 73602",
        "kiosk": false
    },
    {
        "value": "874812",
        "label": "Shipping Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169023",
        "yellow_page_code": "76600",
        "kiosk": false
    },
    {
        "value": "874813",
        "label": "Security Systems Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161813",
        "yellow_page_code": "42134, 75308, 75301, 75303, 75316, 21374",
        "kiosk": false
    },
    {
        "value": "874814",
        "label": "Textile Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169026",
        "yellow_page_code": "85305",
        "kiosk": false
    },
    {
        "value": "874815",
        "label": "Telecommunications Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161817",
        "yellow_page_code": "21026, 84210, 43690, 84211",
        "kiosk": false
    },
    {
        "value": "874816",
        "label": "Trademark Agents & Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161818",
        "yellow_page_code": "87714, 87801, 87713",
        "kiosk": false
    },
    {
        "value": "874817",
        "label": "Traffic & Parking Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169035",
        "yellow_page_code": "87823, 87826, 87806",
        "kiosk": false
    },
    {
        "value": "874818",
        "label": "Agricultural Marketing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169009",
        "yellow_page_code": "00800",
        "kiosk": false
    },
    {
        "value": "874819",
        "label": "Automation Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169075",
        "yellow_page_code": "04502, 04514, 56438, 56430",
        "kiosk": false
    },
    {
        "value": "874820",
        "label": "Agricultural Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169010",
        "yellow_page_code": "00789, 00797, 21063, 31103, 22701, 00804",
        "kiosk": false
    },
    {
        "value": "874821",
        "label": "Bank Designers & Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54132015",
        "yellow_page_code": "06308",
        "kiosk": false
    },
    {
        "value": "874822",
        "label": "Aviation Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169074",
        "yellow_page_code": "21093, 30274, 05404",
        "kiosk": false
    },
    {
        "value": "874823",
        "label": "Communications Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161814",
        "yellow_page_code": "18915, 20008, 21074, 43642",
        "kiosk": false
    },
    {
        "value": "874824",
        "label": "City Regional & Town Planners",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54132011",
        "yellow_page_code": "18108, 18115, 90113, 87512",
        "kiosk": false
    },
    {
        "value": "874825",
        "label": "Educational Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61171012",
        "yellow_page_code": "27204, 21043",
        "kiosk": false
    },
    {
        "value": "874826",
        "label": "Consultant Brokers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161819",
        "yellow_page_code": "21009",
        "kiosk": false
    },
    {
        "value": "874827",
        "label": "Executive Training Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169041",
        "yellow_page_code": "30007",
        "kiosk": false
    },
    {
        "value": "874828",
        "label": "Elevator Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169050",
        "yellow_page_code": "28204, 28323",
        "kiosk": false
    },
    {
        "value": "874829",
        "label": "Facilities & Space Planning Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169039",
        "yellow_page_code": "30503, 30515",
        "kiosk": false
    },
    {
        "value": "874830",
        "label": "Export Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161820",
        "yellow_page_code": "30205",
        "kiosk": false
    },
    {
        "value": "874831",
        "label": "Food Facilities-Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161821",
        "yellow_page_code": "93060, 33833, 33710, 30283, 21050, 33802",
        "kiosk": false
    },
    {
        "value": "874832",
        "label": "Fire Protection Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169037",
        "yellow_page_code": "32106, 29012",
        "kiosk": false
    },
    {
        "value": "874833",
        "label": "Government-Contract Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161822",
        "yellow_page_code": "38305",
        "kiosk": false
    },
    {
        "value": "874834",
        "label": "Freight-Traffic Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169036",
        "yellow_page_code": "34408",
        "kiosk": false
    },
    {
        "value": "874835",
        "label": "Hospital Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161823",
        "yellow_page_code": "41722, 41726, 41801, 21049",
        "kiosk": false
    },
    {
        "value": "874836",
        "label": "Industrial Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54132012",
        "yellow_page_code": "21081, 42906",
        "kiosk": false
    },
    {
        "value": "874837",
        "label": "Industrial Hygiene Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169034",
        "yellow_page_code": "43001, 68816",
        "kiosk": false
    },
    {
        "value": "874838",
        "label": "Industrial Developments",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54132013",
        "yellow_page_code": "43000, 42915, 42946",
        "kiosk": false
    },
    {
        "value": "874839",
        "label": "Legislative Analysts & Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161824",
        "yellow_page_code": "46903, 11717",
        "kiosk": false
    },
    {
        "value": "874840",
        "label": "Industrial Relations Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169033",
        "yellow_page_code": "43003",
        "kiosk": false
    },
    {
        "value": "874841",
        "label": "Livestock Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169030",
        "yellow_page_code": "48108",
        "kiosk": false
    },
    {
        "value": "874842",
        "label": "Medical Management Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161812",
        "yellow_page_code": "51029, 51043, 51046, 21072, 51105",
        "kiosk": false
    },
    {
        "value": "874843",
        "label": "Marine Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169029",
        "yellow_page_code": "50011, 21154",
        "kiosk": false
    },
    {
        "value": "874844",
        "label": "Mining Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169017",
        "yellow_page_code": "52600",
        "kiosk": false
    },
    {
        "value": "874845",
        "label": "Calculating Charts & Tables",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169049",
        "yellow_page_code": "12311",
        "kiosk": false
    },
    {
        "value": "874846",
        "label": "Oil Field Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169024",
        "yellow_page_code": "56521, 56504",
        "kiosk": false
    },
    {
        "value": "874847",
        "label": "Zoning Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169042",
        "yellow_page_code": "95001, 11217",
        "kiosk": false
    },
    {
        "value": "874848",
        "label": "Farm Management Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169043",
        "yellow_page_code": "30800, 30901",
        "kiosk": false
    },
    {
        "value": "874849",
        "label": "Dairy Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169044",
        "yellow_page_code": "21150, 23209, 23212",
        "kiosk": false
    },
    {
        "value": "874850",
        "label": "Telephone Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169045",
        "yellow_page_code": "84401, 84241",
        "kiosk": false
    },
    {
        "value": "874851",
        "label": "Nuclear Energy Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169019",
        "yellow_page_code": "55803",
        "kiosk": false
    },
    {
        "value": "874852",
        "label": "Accident Reconstruction Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169051",
        "yellow_page_code": "00013",
        "kiosk": false
    },
    {
        "value": "874853",
        "label": "Engineers-Communications",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169052",
        "yellow_page_code": "28909",
        "kiosk": false
    },
    {
        "value": "874854",
        "label": "Automobile-Purchasing Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169057",
        "yellow_page_code": "04523, 21087, 90625, 04521, 04835, 33021, 04916, 04814, 04920",
        "kiosk": false
    },
    {
        "value": "874855",
        "label": "Engineers-Hazardous & Industrial Waste",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169011",
        "yellow_page_code": "29015, 29125",
        "kiosk": false
    },
    {
        "value": "874856",
        "label": "Media Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169068",
        "yellow_page_code": "21038, 69707, 69821, 69827, 50910",
        "kiosk": false
    },
    {
        "value": "874857",
        "label": "International Business Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161825",
        "yellow_page_code": "43537",
        "kiosk": false
    },
    {
        "value": "874858",
        "label": "Aquaculture Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169076",
        "yellow_page_code": "03014",
        "kiosk": false
    },
    {
        "value": "874859",
        "label": "Assisted Living Facility Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169072",
        "yellow_page_code": "04125, 04126",
        "kiosk": false
    },
    {
        "value": "874860",
        "label": "Combustion & Heating Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169065",
        "yellow_page_code": "19909",
        "kiosk": false
    },
    {
        "value": "874861",
        "label": "Employment Screening Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161828",
        "yellow_page_code": "03101, 28516",
        "kiosk": false
    },
    {
        "value": "874862",
        "label": "Expertise & Technical Analysis",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169004",
        "yellow_page_code": "30201",
        "kiosk": false
    },
    {
        "value": "874863",
        "label": "Employee Plans-Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161815",
        "yellow_page_code": "28518",
        "kiosk": false
    },
    {
        "value": "874864",
        "label": "Government-Relations Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169062",
        "yellow_page_code": "38512, 38312, 38402",
        "kiosk": false
    },
    {
        "value": "874865",
        "label": "Laundry Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169032",
        "yellow_page_code": "46202",
        "kiosk": false
    },
    {
        "value": "874866",
        "label": "Material Handling Systems Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169028",
        "yellow_page_code": "50409, 50996",
        "kiosk": false
    },
    {
        "value": "874867",
        "label": "Television Service-Consulting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169047",
        "yellow_page_code": "84509",
        "kiosk": false
    },
    {
        "value": "874868",
        "label": "Test Preparation Instruction",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61171010",
        "yellow_page_code": "30001, 85224, 85214",
        "kiosk": false
    },
    {
        "value": "874869",
        "label": "Unemployment Insurance Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169038",
        "yellow_page_code": "89904, 89908, 43396",
        "kiosk": false
    },
    {
        "value": "874870",
        "label": "Water Purification Consulting Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169046",
        "yellow_page_code": "92324, 92403",
        "kiosk": false
    },
    {
        "value": "874871",
        "label": "Welding-Specifications Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169040",
        "yellow_page_code": "93104, 93203",
        "kiosk": false
    },
    {
        "value": "874872",
        "label": "Medical Legal Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169059",
        "yellow_page_code": "51017, 46814, 50918",
        "kiosk": false
    },
    {
        "value": "874873",
        "label": "Forest Industry Products-Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169063",
        "yellow_page_code": "34118",
        "kiosk": false
    },
    {
        "value": "874874",
        "label": "Nursing Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169025",
        "yellow_page_code": "46818, 55927, 55919, 56004",
        "kiosk": false
    },
    {
        "value": "874875",
        "label": "Economic Development Agencies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169054",
        "yellow_page_code": "41208, 27113",
        "kiosk": false
    },
    {
        "value": "874876",
        "label": "Defense Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161826",
        "yellow_page_code": "23707",
        "kiosk": false
    },
    {
        "value": "874877",
        "label": "Municipal Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169071",
        "yellow_page_code": "54607",
        "kiosk": false
    },
    {
        "value": "874878",
        "label": "International Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169060",
        "yellow_page_code": "43533, 43534, 30480, 21070, 43518",
        "kiosk": false
    },
    {
        "value": "874879",
        "label": "Meeting & Conference Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161827",
        "yellow_page_code": "51124, 30514, 21096, 51213",
        "kiosk": false
    },
    {
        "value": "874880",
        "label": "Writing Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169058",
        "yellow_page_code": "94536",
        "kiosk": false
    },
    {
        "value": "874881",
        "label": "Educational Assessment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161829",
        "yellow_page_code": "85226, 27122",
        "kiosk": false
    },
    {
        "value": "874882",
        "label": "Fiber Optics-Systems Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169061",
        "yellow_page_code": "31517",
        "kiosk": false
    },
    {
        "value": "874883",
        "label": "Landscape Appraising & Consulting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54132016",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "874884",
        "label": "Radio Electronic Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169020",
        "yellow_page_code": "69807",
        "kiosk": false
    },
    {
        "value": "874885",
        "label": "Architects Referral Svc",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169070",
        "yellow_page_code": "03212, 03223",
        "kiosk": false
    },
    {
        "value": "874886",
        "label": "Citrus Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169005",
        "yellow_page_code": "18007",
        "kiosk": false
    },
    {
        "value": "874887",
        "label": "Modeling Consultants-Photographic",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169066",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "874888",
        "label": "Traffic Condition Information",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169069",
        "yellow_page_code": "87820",
        "kiosk": false
    },
    {
        "value": "874889",
        "label": "Waste Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54162002",
        "yellow_page_code": "91901, 91827",
        "kiosk": false
    },
    {
        "value": "874890",
        "label": "Lasers & Electro Optics Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169006",
        "yellow_page_code": "46014",
        "kiosk": false
    },
    {
        "value": "874891",
        "label": "Radio Interference Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169007",
        "yellow_page_code": "69819",
        "kiosk": false
    },
    {
        "value": "874892",
        "label": "Architectural Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169053",
        "yellow_page_code": "03217, 21036",
        "kiosk": false
    },
    {
        "value": "874893",
        "label": "Energy Code Compliance Analysts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169008",
        "yellow_page_code": "28635",
        "kiosk": false
    },
    {
        "value": "874894",
        "label": "Industrial Research",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54132009",
        "yellow_page_code": "42903",
        "kiosk": false
    },
    {
        "value": "874895",
        "label": "Television Station Planning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169048",
        "yellow_page_code": "84811",
        "kiosk": false
    },
    {
        "value": "874896",
        "label": "Camping Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169073",
        "yellow_page_code": "12601",
        "kiosk": false
    },
    {
        "value": "874897",
        "label": "Planning Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169067",
        "yellow_page_code": "21058, 20014, 66017, 66020",
        "kiosk": false
    },
    {
        "value": "874898",
        "label": "Welding Inspection & Consulting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169064",
        "yellow_page_code": "93109",
        "kiosk": false
    },
    {
        "value": "874899",
        "label": "Consultants-Business NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161830",
        "yellow_page_code": "14503, 65998, 00726, 91042, 91825, 20019, 28710, 68514, 21066, 48207, 68520, 71935, 92108, 02401, 87815, 33103, 00720, 23710, 40949, 91119, 48107, 29901, 52112, 40948, 79380, 10513, 48206, 04323, 57812",
        "kiosk": false
    },
    {
        "value": "899901",
        "label": "Weather Forecast Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54199018",
        "yellow_page_code": "92715, 92704, 21085",
        "kiosk": false
    },
    {
        "value": "899902",
        "label": "Writers-Business",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151028",
        "yellow_page_code": "94601, 79819",
        "kiosk": false
    },
    {
        "value": "899903",
        "label": "Writers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151029",
        "yellow_page_code": "44532, 94600, 94537, 08225, 38818, 04440",
        "kiosk": false
    },
    {
        "value": "899904",
        "label": "Scientists-Consulting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169002",
        "yellow_page_code": "74712, 84199, 74610, 93236, 74706, 74710",
        "kiosk": false
    },
    {
        "value": "899905",
        "label": "Physicists",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169077",
        "yellow_page_code": "62404",
        "kiosk": false
    },
    {
        "value": "899906",
        "label": "Technical Manual Preparation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151027",
        "yellow_page_code": "84208",
        "kiosk": false
    },
    {
        "value": "899907",
        "label": "Sculptors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151030",
        "yellow_page_code": "75007, 13890",
        "kiosk": false
    },
    {
        "value": "899908",
        "label": "Art Restoring",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151031",
        "yellow_page_code": "03609, 58711, 03430, 03604",
        "kiosk": false
    },
    {
        "value": "899909",
        "label": "Thesis Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151032",
        "yellow_page_code": "85800",
        "kiosk": false
    },
    {
        "value": "899910",
        "label": "Actuaries",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "52429820",
        "yellow_page_code": "00206",
        "kiosk": false
    },
    {
        "value": "899911",
        "label": "Chemists-Manufacturing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169012",
        "yellow_page_code": "49847, 15102",
        "kiosk": false
    },
    {
        "value": "899912",
        "label": "Artists-Fine Arts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151033",
        "yellow_page_code": "31830, 03908",
        "kiosk": false
    },
    {
        "value": "899913",
        "label": "Editorial Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151034",
        "yellow_page_code": "27202",
        "kiosk": false
    },
    {
        "value": "899914",
        "label": "Chemists-Analytical & Consulting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169013",
        "yellow_page_code": "15092, 15091, 15100",
        "kiosk": false
    },
    {
        "value": "899915",
        "label": "Geological Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169014",
        "yellow_page_code": "37203, 37227, 37200",
        "kiosk": false
    },
    {
        "value": "899916",
        "label": "Geochemists",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169078",
        "yellow_page_code": "37204",
        "kiosk": false
    },
    {
        "value": "899917",
        "label": "Home Planning Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54162004",
        "yellow_page_code": "41304",
        "kiosk": false
    },
    {
        "value": "899918",
        "label": "Geophysicists",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54162003",
        "yellow_page_code": "37206",
        "kiosk": false
    },
    {
        "value": "899919",
        "label": "Inventors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169079",
        "yellow_page_code": "43608",
        "kiosk": false
    },
    {
        "value": "899920",
        "label": "Information Bureaus",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54199008",
        "yellow_page_code": "43004, 00729",
        "kiosk": false
    },
    {
        "value": "899921",
        "label": "Music Arrangers & Composers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151035",
        "yellow_page_code": "20190, 54608",
        "kiosk": false
    },
    {
        "value": "899922",
        "label": "Meteorological Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54199019",
        "yellow_page_code": "52004",
        "kiosk": false
    },
    {
        "value": "899923",
        "label": "Psychological Examiners",
        "deprecated_at": null,
        "professional_practice": true,
        "naics_code": "54169080",
        "yellow_page_code": "55323, 68801, 55314, 55319, 55324",
        "kiosk": false
    },
    {
        "value": "899924",
        "label": "Ski & Snow Reports",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54199007",
        "yellow_page_code": "78008",
        "kiosk": false
    },
    {
        "value": "899925",
        "label": "Geophysical Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169081",
        "yellow_page_code": "37222, 37213, 37328, 37223",
        "kiosk": false
    },
    {
        "value": "899926",
        "label": "Meteorologists",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54199020",
        "yellow_page_code": "52006",
        "kiosk": false
    },
    {
        "value": "899927",
        "label": "Music Copyists",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51223003",
        "yellow_page_code": "54702",
        "kiosk": false
    },
    {
        "value": "899928",
        "label": "Newspaper Correspondents",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151036",
        "yellow_page_code": "55411",
        "kiosk": false
    },
    {
        "value": "899929",
        "label": "Hydrogeology Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169082",
        "yellow_page_code": "42122",
        "kiosk": false
    },
    {
        "value": "899930",
        "label": "Parliamentarians",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54199012",
        "yellow_page_code": "59521, 55217, 59503, 51221",
        "kiosk": false
    },
    {
        "value": "899931",
        "label": "Government-Weather Agencies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54199021",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "899932",
        "label": "Script Preparation Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151037",
        "yellow_page_code": "74909",
        "kiosk": false
    },
    {
        "value": "899933",
        "label": "Sculptures-Metal",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151038",
        "yellow_page_code": "74940, 75006",
        "kiosk": false
    },
    {
        "value": "899934",
        "label": "Sculptors-Architectural",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151039",
        "yellow_page_code": "75011",
        "kiosk": false
    },
    {
        "value": "899935",
        "label": "Technical Writing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151040",
        "yellow_page_code": "84214, 94511, 67443",
        "kiosk": false
    },
    {
        "value": "899936",
        "label": "Alcohol Permit Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54199022",
        "yellow_page_code": "01808, 01821",
        "kiosk": false
    },
    {
        "value": "899937",
        "label": "Commentators Narrators & Announcers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151041",
        "yellow_page_code": "19914",
        "kiosk": false
    },
    {
        "value": "899938",
        "label": "Chemical Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169083",
        "yellow_page_code": "14912",
        "kiosk": false
    },
    {
        "value": "899939",
        "label": "Portrait Painting",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151042",
        "yellow_page_code": "67418",
        "kiosk": false
    },
    {
        "value": "899940",
        "label": "Park & Recreation Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169084",
        "yellow_page_code": "59401",
        "kiosk": false
    },
    {
        "value": "899941",
        "label": "Hurricane Protection Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54199011",
        "yellow_page_code": "42119, 42140, 41489",
        "kiosk": false
    },
    {
        "value": "899942",
        "label": "Simulators",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54199014",
        "yellow_page_code": "77750",
        "kiosk": false
    },
    {
        "value": "899943",
        "label": "Poetry",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151043",
        "yellow_page_code": "66912",
        "kiosk": false
    },
    {
        "value": "899944",
        "label": "Air Flight Attendants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "61151928",
        "yellow_page_code": "01200",
        "kiosk": false
    },
    {
        "value": "899945",
        "label": "Weather Research",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54199023",
        "yellow_page_code": "92705",
        "kiosk": false
    },
    {
        "value": "899946",
        "label": "Weather Modification Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54199024",
        "yellow_page_code": "92707",
        "kiosk": false
    },
    {
        "value": "899947",
        "label": "Consular Invoices-Prepared",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161219",
        "yellow_page_code": "21020",
        "kiosk": false
    },
    {
        "value": "899951",
        "label": "Geographics Information Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54162005",
        "yellow_page_code": "37111, 37226, 37225",
        "kiosk": false
    },
    {
        "value": "899953",
        "label": "Information Management",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54151205",
        "yellow_page_code": "43013",
        "kiosk": false
    },
    {
        "value": "899954",
        "label": "Environmental Consulting Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54162001",
        "yellow_page_code": "29718, 46519, 29629",
        "kiosk": false
    },
    {
        "value": "899955",
        "label": "Artists-Santos-Folk Art",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151044",
        "yellow_page_code": "03903",
        "kiosk": false
    },
    {
        "value": "899956",
        "label": "Artists-Sculpture",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151045",
        "yellow_page_code": "03909",
        "kiosk": false
    },
    {
        "value": "899957",
        "label": "Record Production and Distribution",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "51225001",
        "yellow_page_code": "70816",
        "kiosk": false
    },
    {
        "value": "899977",
        "label": "Service Bureaus",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169018",
        "yellow_page_code": "75613",
        "kiosk": false
    },
    {
        "value": "899978",
        "label": "Employment Database Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161220",
        "yellow_page_code": "28532",
        "kiosk": false
    },
    {
        "value": "899979",
        "label": "Resource & Referrals",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161221",
        "yellow_page_code": "71837",
        "kiosk": false
    },
    {
        "value": "899980",
        "label": "Ice Carvers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151046",
        "yellow_page_code": "42315, 79822",
        "kiosk": false
    },
    {
        "value": "899981",
        "label": "Geoscientists",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169003",
        "yellow_page_code": "37215",
        "kiosk": false
    },
    {
        "value": "899982",
        "label": "Art Historians",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151022",
        "yellow_page_code": "03417",
        "kiosk": false
    },
    {
        "value": "899983",
        "label": "Photographers-Animal",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151019",
        "yellow_page_code": "60913, 60923, 02426",
        "kiosk": false
    },
    {
        "value": "899984",
        "label": "Celebrity Appearances/Endorsements",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151047",
        "yellow_page_code": "14226",
        "kiosk": false
    },
    {
        "value": "899985",
        "label": "Artists-Custom Design & Contract",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151048",
        "yellow_page_code": "03925",
        "kiosk": false
    },
    {
        "value": "899986",
        "label": "Artists Mixed Media Contemporary",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151049",
        "yellow_page_code": "03927",
        "kiosk": false
    },
    {
        "value": "899987",
        "label": "Grant Consultant",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54161204",
        "yellow_page_code": "21057, 38720",
        "kiosk": false
    },
    {
        "value": "899988",
        "label": "Paranormal Investigators",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54199015",
        "yellow_page_code": "59220",
        "kiosk": false
    },
    {
        "value": "899989",
        "label": "Independent Artists Writers & Performers ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71151051",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "899999",
        "label": "Services NEC",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54199009",
        "yellow_page_code": "35018",
        "kiosk": false
    },
    {
        "value": "911101",
        "label": "Federal Government-Executive Offices",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92111003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "911102",
        "label": "State Government-Executive Offices",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92111004",
        "yellow_page_code": "38501",
        "kiosk": false
    },
    {
        "value": "911103",
        "label": "County Government-Executive Offices",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92111002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "911104",
        "label": "City Government-Executive Offices",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92111001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "911199",
        "label": "Government Executive Offices ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92111099",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "912101",
        "label": "Government Offices-Federal",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92112009",
        "yellow_page_code": "38410, 20907, 20908, 90101, 38412, 38408, 90099",
        "kiosk": false
    },
    {
        "value": "912102",
        "label": "Government Offices-State",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92112008",
        "yellow_page_code": "38405, 41023, 38404, 38406, 75514, 80607",
        "kiosk": false
    },
    {
        "value": "912103",
        "label": "Government Offices-County",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92112007",
        "yellow_page_code": "38308, 59431, 38320",
        "kiosk": false
    },
    {
        "value": "912104",
        "label": "Government Offices-City/Village & Twp",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92112006",
        "yellow_page_code": "38306, 87513",
        "kiosk": false
    },
    {
        "value": "912105",
        "label": "Constituency Offices-Provincial",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92112003",
        "yellow_page_code": "20913",
        "kiosk": false
    },
    {
        "value": "912106",
        "label": "Constituency Offices-Federal",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92112002",
        "yellow_page_code": "20914",
        "kiosk": false
    },
    {
        "value": "912107",
        "label": "Municipalities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92112012",
        "yellow_page_code": "54536",
        "kiosk": false
    },
    {
        "value": "912108",
        "label": "Fire Emergency Calls",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92112004",
        "yellow_page_code": "32001",
        "kiosk": false
    },
    {
        "value": "912109",
        "label": "Legislative Assemblies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92112010",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "912110",
        "label": "Public Departments-Municipal",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92112005",
        "yellow_page_code": "68903",
        "kiosk": false
    },
    {
        "value": "912111",
        "label": "Members Of Legislative Assembly",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92112011",
        "yellow_page_code": "51220, 27314",
        "kiosk": false
    },
    {
        "value": "912112",
        "label": "City Hall",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92112001",
        "yellow_page_code": "18110, 09485, 18109, 22040",
        "kiosk": false
    },
    {
        "value": "912113",
        "label": "Public Administrators",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92112013",
        "yellow_page_code": "68804, 68813",
        "kiosk": false
    },
    {
        "value": "912114",
        "label": "Political Representatives",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92112014",
        "yellow_page_code": "67110, 04095",
        "kiosk": false
    },
    {
        "value": "912115",
        "label": "Government Ofcs-Authorities/Commissions",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92112015",
        "yellow_page_code": "38513, 71528",
        "kiosk": false
    },
    {
        "value": "912116",
        "label": "Public Works Department",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92112016",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "913101",
        "label": "Executive & Legislative Offices Combined ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92114001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "919901",
        "label": "Federal Government-General Offices",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92119003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "919902",
        "label": "State Government-General Offices",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92119006",
        "yellow_page_code": "80614",
        "kiosk": false
    },
    {
        "value": "919903",
        "label": "County Government-General Offices",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92119002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "919904",
        "label": "City Government-General Offices",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92119001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "919905",
        "label": "Government-Regional Agencies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92119005",
        "yellow_page_code": "38407",
        "kiosk": false
    },
    {
        "value": "919906",
        "label": "Government Offices-Native American",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92119004",
        "yellow_page_code": "88550, 38400",
        "kiosk": false
    },
    {
        "value": "919907",
        "label": "State Parks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71219010",
        "yellow_page_code": "80615",
        "kiosk": false
    },
    {
        "value": "919908",
        "label": "National Parks/Preserves",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71219011",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "919909",
        "label": "State Recreation Areas",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71219012",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "919910",
        "label": "State Wildlife Mgmt Areas/Wildlife Areas",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71219013",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "919911",
        "label": "State Forests",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71219014",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "919912",
        "label": "National Forests",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71219015",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "919913",
        "label": "State Grasslands",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71219016",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "919914",
        "label": "National Grasslands",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71219017",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "919915",
        "label": "Federal Wildlife Refuges",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71219018",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "919916",
        "label": "State Fish Hatcheries/Fishing Areas",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "11251103",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "919917",
        "label": "State Natural/Preserve/Reserve Areas",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71219019",
        "yellow_page_code": "55218",
        "kiosk": false
    },
    {
        "value": "919918",
        "label": "State Historic Sites",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71212003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "919919",
        "label": "State Wildlife Refuge",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71219020",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "919920",
        "label": "National Seashores/Lakeshores",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71219021",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "919921",
        "label": "National Recreation Area",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71219022",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "919922",
        "label": "National Battlefields/Military Parks",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "71219023",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "919923",
        "label": "Government-Printing/Publishing",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92119007",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "919924",
        "label": "Government-Book Store",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92119008",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "919925",
        "label": "Government-Operators of Nonresidential Buildings",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "53112002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "919926",
        "label": "General Government Support ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92119009",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "921101",
        "label": "Federal Government-Courts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92211004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "921102",
        "label": "State Government-Courts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92211006",
        "yellow_page_code": "49420, 25205, 25121",
        "kiosk": false
    },
    {
        "value": "921103",
        "label": "County Government-Courts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92211002",
        "yellow_page_code": "44510, 44609, 44516, 49408, 44610",
        "kiosk": false
    },
    {
        "value": "921104",
        "label": "City Government-Courts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92211001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "921105",
        "label": "Justice Of The Peace",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92211005",
        "yellow_page_code": "92959, 44608",
        "kiosk": false
    },
    {
        "value": "921106",
        "label": "Courts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92211003",
        "yellow_page_code": "22136",
        "kiosk": false
    },
    {
        "value": "921107",
        "label": "Bail Commissioners",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92211007",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "922101",
        "label": "Federal Government-Police",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92212002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "922102",
        "label": "State Government-Police",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92212005",
        "yellow_page_code": "80620, 41011",
        "kiosk": false
    },
    {
        "value": "922103",
        "label": "Sheriff",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92212004",
        "yellow_page_code": "26514, 76306",
        "kiosk": false
    },
    {
        "value": "922104",
        "label": "Police Departments",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92212003",
        "yellow_page_code": "67102, 46114, 18106, 67105",
        "kiosk": false
    },
    {
        "value": "922106",
        "label": "Bailiffs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92212001",
        "yellow_page_code": "05906",
        "kiosk": false
    },
    {
        "value": "922107",
        "label": "Narcotic & Contraband Dogs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92212008",
        "yellow_page_code": "55124",
        "kiosk": false
    },
    {
        "value": "922201",
        "label": "Federal Government-Legal Counsel",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92213003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "922202",
        "label": "State Government-Legal Counsel",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92213004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "922203",
        "label": "County Government-Legal Counsel",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92213002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "922204",
        "label": "City Government-Legal Counsel",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92213001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "922205",
        "label": "Legal Counsel & Prosecution ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92213005",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "922301",
        "label": "Federal Govt-Correctional Institutions",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92214003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "922302",
        "label": "State Govt-Correctional Institutions",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92214002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "922303",
        "label": "County Govt-Correctional Institutions",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92214004",
        "yellow_page_code": "21713",
        "kiosk": false
    },
    {
        "value": "922304",
        "label": "City Govt-Correctional Institutions",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92214001",
        "yellow_page_code": "68424",
        "kiosk": false
    },
    {
        "value": "922305",
        "label": "Correctional Institutions ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92214005",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "922401",
        "label": "Federal Government-Fire Protection",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92216002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "922402",
        "label": "State Government-Fire Protection",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92216004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "922403",
        "label": "County Government-Fire Protection",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92216001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "922404",
        "label": "Fire Departments",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92216003",
        "yellow_page_code": "71804, 31915, 31908",
        "kiosk": false
    },
    {
        "value": "922405",
        "label": "Fire Inspections & Investigations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92216005",
        "yellow_page_code": "32090",
        "kiosk": false
    },
    {
        "value": "922901",
        "label": "Federal Government-Public Order & Safety",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92219005",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "922902",
        "label": "State Government-Public Order & Safety",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92219006",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "922903",
        "label": "County Government-Public Order & Safety",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92219003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "922904",
        "label": "Civil Defense Agencies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92219001",
        "yellow_page_code": "18203",
        "kiosk": false
    },
    {
        "value": "922905",
        "label": "Civil Defense Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92219002",
        "yellow_page_code": "18204",
        "kiosk": false
    },
    {
        "value": "922906",
        "label": "Emergency Disaster Planning",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92219004",
        "yellow_page_code": "28416",
        "kiosk": false
    },
    {
        "value": "922907",
        "label": "General Justice Public Order & Safety Activities ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92219007",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "931101",
        "label": "Federal Government-Finance & Taxation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92113003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "931102",
        "label": "State Government-Finance & Taxation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92113005",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "931103",
        "label": "County Government-Finance & Taxation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92113002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "931104",
        "label": "City Government-Finance & Taxation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92113001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "931105",
        "label": "Mints (Coin Minting)",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92113004",
        "yellow_page_code": "52611",
        "kiosk": false
    },
    {
        "value": "931106",
        "label": "Public Finance Activities ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92113006",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "941101",
        "label": "Federal Government-Education Programs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92311003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "941102",
        "label": "State Government-Education Programs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92311004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "941103",
        "label": "County Government-Education Programs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92311002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "941104",
        "label": "City Government-Education Programs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92311001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "941105",
        "label": "Government Education Programs Administration",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92311005",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "943101",
        "label": "Federal Government-Public Hlth Programs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92312003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "943102",
        "label": "State Government-Public Health Programs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92312004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "943103",
        "label": "County Government-Public Health Programs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92312002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "943104",
        "label": "City Government-Public Health Programs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92312001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "943105",
        "label": "Public Health Programs Administration",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92312005",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "944101",
        "label": "Federal Government-Social & Human Rsrcs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92313003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "944102",
        "label": "State Government-Social/Human Resources",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92313004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "944103",
        "label": "County Government-Social/Human Resources",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92313002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "944104",
        "label": "City Government-Social & Human Resources",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92313001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "944105",
        "label": "Affirmative Action",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92313005",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "944106",
        "label": "Unemployment Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92313006",
        "yellow_page_code": "89911",
        "kiosk": false
    },
    {
        "value": "944107",
        "label": "Government Human Resource Programs Administration",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92313007",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "945101",
        "label": "Federal Government-Veterans Affairs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92314003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "945102",
        "label": "State Government-Veterans Affairs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92314004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "945103",
        "label": "County Government-Veterans Affairs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92314002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "945104",
        "label": "City Government-Veterans Affairs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92314001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "945105",
        "label": "Veterans & Military Information/Svcs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92314005",
        "yellow_page_code": "90921",
        "kiosk": false
    },
    {
        "value": "945106",
        "label": "Veterans' Affairs Administration",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92314006",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "951101",
        "label": "Federal Government-Air/Water/Solid Waste Management",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92411003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "951102",
        "label": "State Government-Air/Water/Solid Waste Management",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92411004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "951103",
        "label": "County Government-Air/Water/Solid Waste Management",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92411002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "951104",
        "label": "City Government-Air/Water/Solid Waste Management",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92411001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "951105",
        "label": "Air/Water Resource/Solid Waste Mgmt Programs Administration",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92411005",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "951201",
        "label": "Federal Government-Land/Mineral/Wildlife/Forest Conservation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92412003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "951202",
        "label": "State Government-Land/Mineral/Wildlife/Forest Conservation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92412005",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "951203",
        "label": "County Government-Land/Mineral/Wildlife/Forest Conservation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92412002",
        "yellow_page_code": "20917",
        "kiosk": false
    },
    {
        "value": "951204",
        "label": "City Government-Land/Mineral/Wildlife/Forest Conservation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92412001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "951206",
        "label": "Government Conservation Programs Administration",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92412006",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "951207",
        "label": "Sanctuaries-Wild Life",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92412004",
        "yellow_page_code": "73702, 02413, 08236",
        "kiosk": false
    },
    {
        "value": "953101",
        "label": "Federal Government-Housing Programs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92511003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "953102",
        "label": "State Government-Housing Programs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92511004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "953103",
        "label": "County Government-Housing Programs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92511002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "953104",
        "label": "City Government-Housing Programs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92511001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "953105",
        "label": "Government Housing Programs Administration",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92511005",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "953201",
        "label": "Federal Government-Urban Planning/Community & Rural Development",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92512003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "953202",
        "label": "State Government-Urban Planning/Community & Rural Development",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92512004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "953203",
        "label": "County Government-Urban Planning/Community & Rural Development",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92512002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "953204",
        "label": "City Government-Urban Planning/Community & Rural Development",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92512001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "953205",
        "label": "Government Urban Planning/Community/Rural Development Administration",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92512005",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "961101",
        "label": "Federal Government-Economic Program Administration",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92611003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "961102",
        "label": "State Government-Economic Program Administration",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92611005",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "961103",
        "label": "County Government-Economic Program Administration",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92611002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "961104",
        "label": "City Government-Economic Program Administration",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92611001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "961105",
        "label": "Ombudsmen",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92611004",
        "yellow_page_code": "57503",
        "kiosk": false
    },
    {
        "value": "961106",
        "label": "Consumer Protection Agencies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92611007",
        "yellow_page_code": "21023",
        "kiosk": false
    },
    {
        "value": "961107",
        "label": "Trade Commissions",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92611006",
        "yellow_page_code": "87711",
        "kiosk": false
    },
    {
        "value": "961108",
        "label": "Identity Theft Protection",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92611008",
        "yellow_page_code": "42605",
        "kiosk": false
    },
    {
        "value": "961109",
        "label": "Government Economic Programs Administration",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92611009",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "962101",
        "label": "Federal Government-Regulation & Administration-Transportation Programs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92612001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "962102",
        "label": "State Government-Regulation & Administration-Transportation Programs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92612002",
        "yellow_page_code": "67406, 38403",
        "kiosk": false
    },
    {
        "value": "962103",
        "label": "County Government-Regulation & Administration-Transportation Programs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92612003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "962104",
        "label": "City Government-Regulation & Administration-Transportation Programs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92612004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "962105",
        "label": "Port Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92612007",
        "yellow_page_code": "67410",
        "kiosk": false
    },
    {
        "value": "962106",
        "label": "Department Of Motor Vehicles",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92612009",
        "yellow_page_code": "24220",
        "kiosk": false
    },
    {
        "value": "962107",
        "label": "Transportation Authorities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92612005",
        "yellow_page_code": "88312",
        "kiosk": false
    },
    {
        "value": "962108",
        "label": "Aircraft Inspection",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92612006",
        "yellow_page_code": "01420",
        "kiosk": false
    },
    {
        "value": "962109",
        "label": "Prisoner Transports",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92612008",
        "yellow_page_code": "68533",
        "kiosk": false
    },
    {
        "value": "962110",
        "label": "Rest Area",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92612010",
        "yellow_page_code": "",
        "kiosk": true
    },
    {
        "value": "962111",
        "label": "Vehicle Registration Renewal Kiosk",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92612011",
        "yellow_page_code": "",
        "kiosk": true
    },
    {
        "value": "963101",
        "label": "Federal Government-Regulation & Administration-Communication/Utilities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92613003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "963102",
        "label": "State Government-Regulation & Administration-Communication/Utilities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92613004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "963103",
        "label": "County Government-Regulation & Administration-Communication/Utilities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92613002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "963104",
        "label": "City Government-Regulation & Administration-Communication/Utilities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92613001",
        "yellow_page_code": "73803",
        "kiosk": false
    },
    {
        "value": "963105",
        "label": "Irrigation Districts",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92613005",
        "yellow_page_code": "43907",
        "kiosk": false
    },
    {
        "value": "963106",
        "label": "Government Regulation & Administration-Communication/Utilities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92613006",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "964101",
        "label": "Federal Government-Regulation-Agricultural Marketing & Commodities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92614003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "964102",
        "label": "State Government-Regulation-Agricultural Marketing & Commodities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92614004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "964103",
        "label": "County Government-Regulation-Agricultural Marketing & Commodities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92614002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "964104",
        "label": "City Government-Regulation-Agricultural Marketing & Commodities",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92614001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "964105",
        "label": "Food Safety Training",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92614006",
        "yellow_page_code": "34025",
        "kiosk": false
    },
    {
        "value": "964106",
        "label": "Government Agricultural Marketing & Commodities Regulation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92614007",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "965101",
        "label": "Federal Government-Regulation/Licensing & Inspection-Commercial Sectors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92615003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "965102",
        "label": "State Government-Regulation/Licensing & Inspection-Commercial Sectors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92615004",
        "yellow_page_code": "47899",
        "kiosk": false
    },
    {
        "value": "965103",
        "label": "County Government-Regulation/Licensing & Inspection-Commercial Sectors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92615002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "965104",
        "label": "City Government-Regulation/Licensing & Inspection-Commercial Sectors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92615001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "965105",
        "label": "Government Commercial Licensing & Inspection Regulation",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92615007",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "965107",
        "label": "Automotive Prods/Supl Quality Control",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92615006",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "966101",
        "label": "Federal Government-Space Research & Technology",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92711001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "966102",
        "label": "State Government-Space Research & Technology",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92711002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "966103",
        "label": "County Government-Space Research & Technology",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92711003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "966104",
        "label": "City Government-Space Research & Technology",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92711004",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "966105",
        "label": "Space Research & Technology ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92711006",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "971101",
        "label": "Federal Government-National Security",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92811003",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "971102",
        "label": "State Government-National Security",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92811007",
        "yellow_page_code": "03404, 55201",
        "kiosk": false
    },
    {
        "value": "971103",
        "label": "County Government-National Security",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92811002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "971104",
        "label": "City Government-National Security",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92811008",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "971105",
        "label": "Recruiting-US Armed Forces",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92811006",
        "yellow_page_code": "52390, 71008, 03317",
        "kiosk": false
    },
    {
        "value": "971106",
        "label": "Recruiting-Canadian Forces",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92811004",
        "yellow_page_code": "71014",
        "kiosk": false
    },
    {
        "value": "971107",
        "label": "Recruiting-Government Services",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92811005",
        "yellow_page_code": "71015",
        "kiosk": false
    },
    {
        "value": "971108",
        "label": "Army Reserve Center",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92811001",
        "yellow_page_code": "03315",
        "kiosk": false
    },
    {
        "value": "971109",
        "label": "Military Bases",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92811009",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "972101",
        "label": "Federal Government-International Affairs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92812003",
        "yellow_page_code": "38409, 41042",
        "kiosk": false
    },
    {
        "value": "972102",
        "label": "State Government-International Affairs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92812005",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "972103",
        "label": "County Government-International Affairs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92812002",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "972104",
        "label": "City Government-International Affairs",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92812001",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "972105",
        "label": "Immigration Services-Government",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92812004",
        "yellow_page_code": "42614",
        "kiosk": false
    },
    {
        "value": "972106",
        "label": "Organizations-International",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92812006",
        "yellow_page_code": "57903",
        "kiosk": false
    },
    {
        "value": "972107",
        "label": "Embassies & Legations",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92812007",
        "yellow_page_code": "28401, 21008, 47018, 30279",
        "kiosk": false
    },
    {
        "value": "972108",
        "label": "International Affairs ",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "92812008",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "999901",
        "label": "Fallout Shelters-Contractors & Dealers",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "99999006",
        "yellow_page_code": "30605",
        "kiosk": false
    },
    {
        "value": "999903",
        "label": "Fishing Information",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "99999007",
        "yellow_page_code": "32608",
        "kiosk": false
    },
    {
        "value": "999904",
        "label": "Hydroponics Equipment & Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "99999002",
        "yellow_page_code": "39150, 42204",
        "kiosk": false
    },
    {
        "value": "999907",
        "label": "Identification Bureaus",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "99999008",
        "yellow_page_code": "42602",
        "kiosk": false
    },
    {
        "value": "999910",
        "label": "Key Control Systems",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "99999009",
        "yellow_page_code": "44804",
        "kiosk": false
    },
    {
        "value": "999916",
        "label": "Metal Cleaning Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "99999010",
        "yellow_page_code": "51408",
        "kiosk": false
    },
    {
        "value": "999918",
        "label": "Metal Spraying",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "99999011",
        "yellow_page_code": "51706",
        "kiosk": false
    },
    {
        "value": "999920",
        "label": "Mineralogists",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "99999012",
        "yellow_page_code": "52504",
        "kiosk": false
    },
    {
        "value": "999923",
        "label": "Minerals",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169015",
        "yellow_page_code": "52505",
        "kiosk": false
    },
    {
        "value": "999925",
        "label": "Noise Control Consultants",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "42333016",
        "yellow_page_code": "55608",
        "kiosk": false
    },
    {
        "value": "999926",
        "label": "Vibration Control",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "33451929",
        "yellow_page_code": "91006",
        "kiosk": false
    },
    {
        "value": "999929",
        "label": "Water Analysis",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "54169016",
        "yellow_page_code": "92100",
        "kiosk": false
    },
    {
        "value": "999933",
        "label": "Wood Specialties",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "99999013",
        "yellow_page_code": "94403",
        "kiosk": false
    },
    {
        "value": "999936",
        "label": "Bones",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "99999014",
        "yellow_page_code": "09204",
        "kiosk": false
    },
    {
        "value": "999938",
        "label": "Boy & Girl Organizations Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "99999015",
        "yellow_page_code": "10008",
        "kiosk": false
    },
    {
        "value": "999939",
        "label": "Chimneys-Prefabricated",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "99999016",
        "yellow_page_code": "15308",
        "kiosk": false
    },
    {
        "value": "999940",
        "label": "Chemicals-Reclaiming",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "99999003",
        "yellow_page_code": "15008",
        "kiosk": false
    },
    {
        "value": "999947",
        "label": "Electric Power Systems-Emergency/Standby",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "99999017",
        "yellow_page_code": "27802",
        "kiosk": false
    },
    {
        "value": "999950",
        "label": "Energy Audits",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "99999018",
        "yellow_page_code": "28700",
        "kiosk": false
    },
    {
        "value": "999952",
        "label": "Pipe Line Equipment",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "99999019",
        "yellow_page_code": "65700",
        "kiosk": false
    },
    {
        "value": "999954",
        "label": "Pool Car & Truck Distribution",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "99999020",
        "yellow_page_code": "67208",
        "kiosk": false
    },
    {
        "value": "999956",
        "label": "Purchasing Service",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "81299049",
        "yellow_page_code": "69304",
        "kiosk": false
    },
    {
        "value": "999958",
        "label": "Septic Tanks-Treatment Supplies",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "99999021",
        "yellow_page_code": "75604",
        "kiosk": false
    },
    {
        "value": "999962",
        "label": "Slotted Angle Materials",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "99999001",
        "yellow_page_code": "78404",
        "kiosk": false
    },
    {
        "value": "999966",
        "label": "Federal Government Contractors",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "99999005",
        "yellow_page_code": "",
        "kiosk": false
    },
    {
        "value": "999977",
        "label": "Nonclassified Establishments",
        "deprecated_at": null,
        "professional_practice": false,
        "naics_code": "99999004",
        "yellow_page_code": "77777",
        "kiosk": false
    }
]