import { React, useState, useEffect } from 'react';
import { emailSubscription } from '../../services/UserManagementService';
import { useLocation } from 'react-router-dom';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Grid } from '@mui/material';
import NavBar from '../../components/NavBar/NavBar';
import Footer from '../../components/Footer/Footer';
import { retrieveUserAttributes, retrieveUserProfile } from '../../services/UserManagementService';
import { SnackBar } from '../../components/SnackBar/SnackBar';
import SetPageTitle from '../../components/SetPageTitle/SetPageTitle';
const Unsubscribe = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const [userAttributes, setUserAttributes] = useState();
    const [userProfileData, setUserProfileData] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [subscriptionLink, setSubscriptionLink] = useState(queryParams.get('subscriptionLink'));
    const [userObject, setUserObject] = useState({email : queryParams.get('email') });
    const location = useLocation();
    
    const getUserAttributes = async() => {
        retrieveUserAttributes().then((data) => {
          if(data.errorMessage){
            setErrorMessage(data.errorMessage);
          }else{
            setUserAttributes(data.result)
          }
        });
      }

      const getUserProfile = async() => {
        retrieveUserProfile().then((response) => {
          if(response.errorMessage){
            setErrorMessage(response.errorMessage);
          }else{
            setUserProfileData(response);
          }
        });
      }
   
    const alterSubscription = async() => {
        const subscriptionResponse = await emailSubscription({email: userObject.email, subscription_hash: subscriptionLink});
        setUserObject(subscriptionResponse);
    }

    useEffect(() => {   
        alterSubscription();
        if(localStorage.getItem("authorized") && !userAttributes){
          getUserAttributes();
        }else if(localStorage.getItem("authorized") && !userProfileData){
          getUserProfile();
        }

        if (!window.location.host.includes('localhost') && !window.location.host.includes('dev.onedeal.biz')) {
            window.gtag("event", "page_view", {
              page_path: location.pathname + location.search,
            });
          }
    },[userAttributes, userProfileData, location])

    return(
        <>
        <Grid container direction="row" justifyContent="center" alignItems="start">
            <SetPageTitle title="Unsubscribe" />
            <NavBar userAttributes={userAttributes} setUserAttributes={setUserAttributes} userProfileData={userProfileData}/>
            <FormGroup>
                {
                userObject['email-sub-key'] &&
                    <FormControlLabel checked={userObject['email-subscription']} control={<Switch onChange={alterSubscription} />} label="Subscribed To Email" />
                }
            </FormGroup>
            {errorMessage && <SnackBar errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>}
        </Grid>
        <Footer/>
        </>
    )
};

export default Unsubscribe;