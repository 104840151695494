import { React, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import NavBar from '../../components/NavBar/NavBar';
import Footer from '../../components/Footer/Footer';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import { Grid } from '@mui/material';
import Select from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import { retrieveUserAttributes, retrieveUserProfile, getS3Docs, putDataRoomDocument } from '../../services/UserManagementService';
import { Industries, ListingType } from '../../helperData/ListingInfoData';
import Autocomplete from '@mui/material/Autocomplete';
import { States } from '../../helperData/LocationData';
import LoadingButton from '@mui/lab/LoadingButton';
import { updateListing } from '../../services/ListingManagementService';
import SetPageTitle from '../../components/SetPageTitle/SetPageTitle';
import DataRoomModifier from './DataRoomModifier';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';

const businessAddressContainerStyler = {
    padding: "4px", 
    borderRadius: "4px", 
    boxShadow:" 0 2px 4px -1px rgba(0,0,0,0.25)", 
    backgroundColor:"white", height:"fit-content",
    marginTop: "10px",
    marginBottom: "10px"
  }

const UpsertListing = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [userAttributes, setUserAttributes] = useState();
    const [userProfileData, setUserProfileData] = useState();
    const [availableNDAs, setAvailableNDAs] = useState();
    const [availableDrDocs, setAvailableDrDocs] = useState();
    const [files, setFiles] = useState([]);
    const [listing, setListing] = useState((location.state) ? location.state.data : null);
    const [isLoading, setIsLoading] = useState(false);
   
    const [errorMessage, setErrorMessage] = useState();

    const getUserAttributes = async() => {
        retrieveUserAttributes().then((data) => {
        if(data.errorMessage){
            setErrorMessage(data.errorMessage);
        }else{
            setUserAttributes(data.result)
        }
        });
    }

    const getUserProfile = async() => {
        retrieveUserProfile().then((response) => {
          if(response.errorMessage){
            setErrorMessage(response.errorMessage);
          }else{
            setUserProfileData(response);
          }
        });
      }

    const getAvailableNDAs = async() => {
       await getS3Docs("onedeal-broker-documents", `${userProfileData.email}/ndas/`).then((response) => {
            setAvailableNDAs(response);
        })
    }

    const getAvailableDataRoomDocs = async() => {
        await getS3Docs("onedeal-broker-documents", `${userProfileData.email}/datarooms/${listing['listing-id']}/`).then((response) => {
            setAvailableDrDocs(response);
        })
    }

    const setNDASelectMenu = (ndas) => {
        let menuItems = []; 
        if(ndas.length > 0){
            menuItems = ndas.map((nda) => {
                return(
                    <MenuItem value={nda.Key}>{nda.Key.split('/')[2]}</MenuItem>
                )
            })
        }else{
            menuItems.push(<MenuItem onClick={() => {localStorage.setItem('listingManagementTab', 3); window.open(`${process.env.REACT_APP_REDIRECT_URI}/myOneDeal/non-disclosure-agreements`)}} >Upload an NDA to your "My OneDeal"</MenuItem>);
        }
        return menuItems;
        
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      }).catch(error => {
        // Code to handle the error
        console.error('An error occurred during UpsertListing:', error);
    });  ;

    const handleUploadToS3 = async(_listing) => {
        for(let x=0; x < files.length; x++){
          const base64 = await toBase64(files[x]);
          await putDataRoomDocument(
            base64,
            files[x].type,
            files[x].name,
            'datarooms',
            _listing['listing-id'],
            setErrorMessage
          );   
        }
      }

    const handleSaveListing = async(_listing) => {
        setIsLoading(true);
        await handleUploadToS3(_listing);
        await updateListing(listing).then(() =>  {
            setIsLoading(false);
            navigate('/myOneDeal');
        });
    }

    useEffect(() => {
        if (!window.location.host.includes('localhost') && !window.location.host.includes('dev.onedeal.biz')) {
            window.gtag("event", "page_view", {
              page_path: location.pathname + location.search,
            });
        }

        if(!listing || !localStorage.getItem("authorized")){
            navigate('/');
        }else if(localStorage.getItem("authorized") && !userAttributes){
            getUserAttributes();
        }else if(localStorage.getItem("authorized") && !userProfileData){
            getUserProfile();
        }else if(localStorage.getItem("authorized") && !availableNDAs){
            getAvailableNDAs();
        }else if(localStorage.getItem("authorized") && !availableDrDocs){
            getAvailableDataRoomDocs();
        }
    }, [location, userAttributes, userProfileData, availableNDAs, availableDrDocs]);

    return (
        <>
            <SetPageTitle title="Edit Listing" />
            <NavBar userAttributes={userAttributes} setUserAttributes={setUserAttributes} userProfileData={userProfileData}/>
            {   
                listing && 
                <Grid container direction="row" justifyContent="center" height="100%">
                    <Grid item xs={12} md={11} sx={businessAddressContainerStyler}>
                    <h3>Business Address</h3>
                    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} >
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="address"
                                    label="Street Address"
                                    variant="outlined"
                                    autoComplete="street-address"
                                    onChange={(event) => setListing({...listing, [event.target.id] : event.target.value})}
                                    style={{width:"100%"}}
                                    value={listing.address}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            <TextField
                                id="apartment"
                                label="Apartment Number"
                                type="number"
                                variant="outlined"
                                autoComplete="address-level2"
                                onChange={(event) => setListing({...listing, [event.target.id] : parseInt(event.target.value)})}
                                sx={{width:"100%"}}
                                value={listing.apartment}
                            />
                            </Grid>
                            <Grid item xs={12} sm={6} sx={{display:"flex", justifyContent:"space-between"}}>
                            <Grid item xs={5.9}>
                                <TextField
                                required
                                //   error={invalidMap['city']}
                                id="city"
                                label="City"
                                variant="outlined"
                                autoComplete="address-level2"
                                onChange={(event) => setListing({...listing, location : listing.location.substring(0, listing.location.indexOf(",")+1) + event.target.value})}
                                style={{width:"100%"}}
                                value={listing.location.substring(listing.location.indexOf(",")+1, listing.location.length)}
                                />
                            </Grid>
                            <Grid item xs={5.9}>
                                <Autocomplete
                                value={listing.location.substring(0, listing.location.indexOf(","))}
                                disablePortal
                                disableClearable
                                id="state"
                                options={States}
                                autoHighlight={true}
                                autoComplete={true}
                                onChange={(event, val) => setListing({...listing, location : val + "," + listing.location.substring(listing.location.indexOf(',')+1, listing.location.length)})}
                                renderInput={(params) => <TextField {...params}  
                                    required
                                    //   error={invalidMap['state']}
                                    label="State"
                                    variant="outlined"
                                    autoComplete="address-level1"
                                    style={{width:"100%"}}                                    
                                    />}
                                />
                            </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            <TextField
                                id="zipcode"
                                label="Zipcode"
                                type="number"
                                variant="outlined"
                                autoComplete="postal-code"
                                onChange={(event) => setListing({...listing, [event.target.id] : parseInt(event.target.value)})}
                                sx={{width:"100%"}}
                                value={listing.zipcode}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                // error={invalidMap['contact-email']}
                                id="contact-email"
                                label="Email Address"
                                variant="outlined"
                                autoComplete="username"
                                onChange={(event) => setListing({...listing, [event.target.id] : event.target.value})}
                                style={{width:"100%"}}
                                value={listing['contact-email']}
                            />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            <TextField
                                id="phone"
                                label="Phone Number"
                                // type="number"
                                variant="outlined"
                                autoComplete="tel"
                                onChange={(event) => setListing({...listing, [event.target.id] : event.target.value})}
                                sx={{width:"100%"}}
                                value={listing.phone}
                                />
                            </Grid>
                        </Grid> 
                    </Grid> 
                    <Grid item xs={12} sm={11} sx={businessAddressContainerStyler}>
                    <h3>Listing Information</h3>
                    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                        <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel id="type-label">Type of Listing</InputLabel>
                            <Select
                            //error={invalidMap.type}
                            labelId="type-label"
                            id="type"
                            value={listing.type}
                            label="Type"
                            onChange={(event) => setListing({...listing, type : event.target.value})}
                            style={{width:"100%"}}
                            >
                            {
                                ListingType.map((item) => {
                                return <MenuItem key={item} value={item}>{item}</MenuItem>;
                                })
                            }
                            </Select>
                        </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel id="industry-label">Industry</InputLabel>
                            <Select
                            //error={invalidMap.industry}
                            labelId="industry-label"
                            id="industry"
                            value={listing.industry}
                            label="Industry"
                            onChange={(event) => setListing({...listing, industry : event.target.value})}
                            style={{width:"100%"}}
                            >
                            {
                                Industries.map((item) => {
                                return <MenuItem value={item}>{item}</MenuItem>;
                                })
                            }
                            </Select>
                        </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="outlined-adornment-amount">Asking Price</InputLabel>
                            <OutlinedInput
                            //error={invalidMap.price}
                            id="price"
                            value={listing.price}
                            onChange={(event) => setListing({...listing, [event.target.id] : parseInt(event.target.value)})}
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            label="Asking Price"
                            style={{width:"100%"}}
                            type="number"
                            />
                        </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <TextField
                            //error={invalidMap.headline}
                            required
                            id="headline"
                            label="Headline"
                            variant="outlined"
                            onChange={(event) => setListing({...listing, [event.target.id] : event.target.value})}
                            style={{width:"100%"}}
                            value={listing.headline}
                        />
                        </Grid>
                        <Grid item xs={12}>
                        <TextField
                            //error={invalidMap.description}
                            required
                            id="description"
                            label="Tell us about your business"
                            multiline
                            value={listing.description}
                            onChange={(event) => setListing({...listing, [event.target.id] : event.target.value})}
                            style={{width:"100%"}}
                            rows={2}
                        />
                        </Grid>
                    </Grid>
                    </Grid>
                    <Grid item xs={12} sm={11} sx={businessAddressContainerStyler}>
                    <h3>Financials</h3>
                    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                            <InputLabel htmlFor="outlined-adornment-amount">Gross Revenue</InputLabel>
                            <OutlinedInput
                                id="gross-revenue"
                                value={listing['gross-revenue']}
                                onChange={(event) => setListing({...listing, [event.target.id] : parseInt(event.target.value)})}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                label="Gross Revenue"
                                style={{width:"100%"}}
                                type="number"
                            />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                            <InputLabel htmlFor="outlined-adornment-amount">Cash Flow</InputLabel>
                            <OutlinedInput
                                required
                                // error={invalidMap['cash-flow']}
                                id="cash-flow"
                                value={listing['cash-flow']}
                                onChange={(event) => setListing({...listing, [event.target.id] : parseInt(event.target.value)})}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                label="Cash Flow"
                                style={{width:"100%"}}
                                type="number"
                            />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                            <InputLabel htmlFor="outlined-adornment-amount">EBITDA</InputLabel>
                            <OutlinedInput
                                id="ebitda"
                                value={listing.ebitda}
                                onChange={(event) => setListing({...listing, [event.target.id] : parseInt(event.target.value)})}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                label="EBITDA"
                                style={{width:"100%"}}
                                type="number"
                            />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                            <InputLabel htmlFor="outlined-adornment-amount">Expenses</InputLabel>
                            <OutlinedInput
                                id="expenses"
                                value={listing.expenses}
                                onChange={(event) => setListing({...listing, [event.target.id] : parseInt(event.target.value)})}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                label="Expenses"
                                style={{width:"100%"}}
                                type="number"
                            />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                            <InputLabel htmlFor="outlined-adornment-amount">Net Income</InputLabel>
                            <OutlinedInput
                                id="net-income"
                                value={listing['net-income']}
                                onChange={(event) => setListing({...listing, [event.target.id] : parseInt(event.target.value)})}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                label="Net Income"
                                style={{width:"100%"}}
                                type="number"
                            />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                            <InputLabel htmlFor="outlined-adornment-amount">Inventory</InputLabel>
                            <OutlinedInput
                                id="inventory"
                                value={listing.inventory}
                                onChange={(event) => setListing({...listing, [event.target.id] : parseInt(event.target.value)})}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                label="Inventory"
                                style={{width:"100%"}}
                                type="number"
                            />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                // error={invalidMap['year-established']}
                                id="year-established"
                                label="Year Established"
                                type="number"
                                variant="outlined"
                                // autoComplete="address-level2"
                                value={listing['year-established']}
                                onChange={(event) => setListing({...listing, [event.target.id] : parseInt(event.target.value)})}
                                sx={{width:"100%"}}
                                />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                            <InputLabel htmlFor="outlined-adornment-amount">Rent</InputLabel>
                            <OutlinedInput
                                id="rent"
                                value={listing.rent}
                                onChange={(event) => setListing({...listing, [event.target.id] : parseInt(event.target.value)})}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                label="Rent"
                                style={{width:"100%"}}
                                type="number"
                            />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                            id="valution-rationale"
                            label={`Why is your business worth $${listing.price}?`}
                            multiline
                            value={listing['valution-rationale']}
                            onChange={(event) => setListing({...listing, [event.target.id] : event.target.value})}
                            style={{width:"100%"}}
                            rows={2}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                            id="selling-reason"
                            label="Why are you selling?"
                            multiline
                            value={listing['selling-reason']}
                            onChange={(event) => setListing({...listing, [event.target.id] : event.target.value})}
                            style={{width:"100%"}}
                            rows={2}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                            id="competition"
                            label="Tell us about your competitors..."
                            multiline
                            value={listing.competition}
                            onChange={(event) => setListing({...listing, [event.target.id] : event.target.value})}
                            style={{width:"100%"}}
                            rows={2}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                            id="growth"
                            label="What are the growth opportunities for your business?"
                            multiline
                            value={listing.growth}
                            onChange={(event) => setListing({...listing, [event.target.id] : event.target.value})}
                            style={{width:"100%"}}
                            rows={2}
                            />
                        </Grid>
                        {   
                        availableNDAs &&
                        <Grid item xs={12}  sx={{display:"flex"}}>
                           <Grid items xs={1} sx={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                <IconButton onClick={() => getAvailableNDAs()}>
                                    <RefreshIcon fontSize='large' sx={{border:"1px solid #0e9384", borderRadius:"100%", color:"#0e9384"}}/>
                                </IconButton>
                            </Grid>
                            <Grid item xs={11}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Associated NDA</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={listing.nda}
                                        label="Associated NDA"
                                        onChange={(e) => setListing({...listing, "nda" : e.target.value})}
                                    >
                                        {setNDASelectMenu(availableNDAs)} 
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    }
                    </Grid>
                    {
                        <div id="dataroom">
                            <DataRoomModifier dataRoomFiles={availableDrDocs} setDataRoomFiles={setAvailableDrDocs} getAvailableDataRoomDocs={getAvailableDataRoomDocs} files={files} setFiles={setFiles}/>
                        </div>
                    }
                    </Grid>
                    <Grid item xs={12} sx={{textAlign:"center"}}>
                        <LoadingButton
                            onClick={() => handleSaveListing(listing)}
                            loading={isLoading}
                            // loadingPosition="end"
                            variant="contained"
                            sx={{backgroundColor: "#0e9384", mt:"8px", mb:"8px", fontWeight:"bold",borderRadius:"10px"}}
                        >Save
                        </LoadingButton>
                    </Grid>  
                </Grid>
            }
            <Footer />
        </>
    );
}

export default UpsertListing;