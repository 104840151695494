import { React } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { dataAxleCategories } from "./HelperData";

const CategoryDisplay = (props) => {
  // const [selectedValues, setSelectedValues] = useState([]);
  return (
    <Autocomplete
        multiple
        id="tags-outlined"
        options={dataAxleCategories}
        getOptionLabel={(option) => option.label}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label="Business Categories"
            placeholder="Type to search"
          />
        )}
        onChange={ (event, newValue) => {
          props.setSearchMap({...props.searchMap, 'categories' : newValue})
            // props.setSearchMap({...props.searchMap, 'categories' : [
            //   ...props.searchMap.categories,
            //   ...newValue.filter((option) => props.searchMap.categories.indexOf(option) === -1)
            // ]});
          }
        }
        value={props.searchMap.categories}
        sx={{
          width: "100%",
          "& .MuiOutlinedInput-root": { borderRadius: "24px" },
        }}
      />
  );
}
export default CategoryDisplay;