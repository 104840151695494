import { React, useState } from 'react';
import { Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { SnackBar } from '../SnackBar/SnackBar';
import { verifyCode, resendCode } from '../../services/UserManagementService';
import { useNavigate } from "react-router-dom";

// confirmation code component
export const ConfirmationCode = (props) => {

    const [resendResponse, setResendResponse] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [confirmationLoading, setConfirmationLoading] = useState(false);
    const [resendLoading, setResendLoading] = useState(false);
    const [confirmationCode, setConfirmationCode] = useState();

    const handleConfirmationCode = (event) => { setConfirmationCode(event.target.value) };
    const navigate = useNavigate();

    const handleConfirmationSubmit = async() => {
      const response = await verifyCode(
        setConfirmationLoading,
        props.signUpDetails,
        confirmationCode,
        setErrorMessage,
        navigate
      );
      if(response && !response.errorMessage){
        props.setStep(2);
      }
    }

    const handleResendSubmit = async() => {
      resendCode(
        setResendLoading, props.username,
        setErrorMessage,
        setResendResponse
      );
    }
  
    return(
        <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={11} md={7}>
                <h3>Check your email for a confirmation code and enter it below :) </h3>
                <TextField
                required
                id="outlined-number"
                label="Confirmation Code"
                type="number"
                variant="filled"
                onChange={handleConfirmationCode}
                sx={{width:"100%", marginBottom: "8px"}}
                />
            </Grid>
            <Grid item xs={11} md={7}>
                <LoadingButton
                    onClick={handleConfirmationSubmit}
                    loading={confirmationLoading}
                    // loadingPosition="end"
                    variant="contained"
                    sx={{backgroundColor:"#0e9384", width:"100%", marginBottom: "8px"}}
                    >
                    Submit
                </LoadingButton>
            </Grid>
            <Grid item xs={11} md={7}>
                <p>Need a new code?</p>
                <LoadingButton
                    onClick={handleResendSubmit}
                    loading={resendLoading}
                    // loadingPosition="end"
                    variant="contained"
                    sx={{width:"100%",backgroundColor:"#8DCAC3"}}
                    >
                    Resend Confirmation Code
                </LoadingButton>
            </Grid>
            {errorMessage && <SnackBar errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>}
      </Grid>
    );
  }