import { React, useState, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Box } from '@mui/material';
import { SnackBar } from '../../components/SnackBar/SnackBar';
import NavBar from '../../components/NavBar/NavBar'
import { retrieveUserAttributes, retrieveUserProfile, getS3Docs, putDataRoomDocument} from '../../services/UserManagementService';
import { putImage, postListing } from '../../services/ListingManagementService';
import { BusinessAddress } from './BusinessAddress';
import { ListingInformation } from './ListingInformation';
import { Financials } from './Financials';
import SetPageTitle from '../../components/SetPageTitle/SetPageTitle';
import TakeTheTour from '../../components/TakeTheTour/TakeTheTour';

export const PostListing = () => {
  //state management
  const [userAttributes, setUserAttributes] = useState();
  const [userProfileData, setUserProfileData] = useState();
  const [availableNDAs, setAvailableNDAs] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [validAddress, setValidAddress] = useState(false);
  const [validListing, setValidListing] = useState(false);
  const location = useLocation();
  const [tourSteps, setTourSteps] = useState(location?.state?.showTour);
  
  const [businessDetails, setBusinessDetails] = useState({
    "address" : "",
    "apartment": "",
    "city": "",
    "state": "",
    "zipcode": "",
    "contact-email": "",
    "phone": "",
    "type": "",
    "industry": "",
    "price": 0, //number
    "headline": "",
    "description": "",
    "gross-revenue": 0, //number
    "cash-flow":0, //number
    "ebitda":0, //number
    "expenses":"", //number
    "net-income":0, //number
    "inventory":0, //number
    "valution-rationale":"",
    "selling-reason":"",
    "competition":"",
    "growth":"",
    "status":"available",
    "image-title": "",
    "image-content": "",
    "image-type": "",
    "s3-resource-url": "",
    "listing-id": "",
    "financing": true,
    "year-established" : "", //number
    "rent": 0, //number
    "nda" : ""
  });
  const [dataRoomFiles, setDataRoomFiles] = useState([]);

  const navigate = useNavigate();

  const handleFinancing = () => setBusinessDetails({...businessDetails, "financing" : !businessDetails.financing});
  const handleType = (event) => setBusinessDetails({...businessDetails, "type" : event.target.value});
  const handleIndustry = (event) => setBusinessDetails({...businessDetails, "industry" : event.target.value});
  const handleNumberUpdates = (event) => {
    if((event.target.id === "price" || event.target.id === "cash-flow") && event.target.valueAsNumber > 30000000){
      setBusinessDetails({...businessDetails, [event.target.id] : 30000000})
    }else{
      setBusinessDetails({...businessDetails, [event.target.id] : event.target.valueAsNumber})
    }
  };
  const handleFieldUpdate = (event) => setBusinessDetails({...businessDetails, [event.target.id] : event.target.value});
  const handleStateUpdate = (state) => setBusinessDetails({...businessDetails, "state" : state});
  const handlePhoneUpdate = (event) => setBusinessDetails({...businessDetails, "phone" : event})
  const handleImageUpdate = (imageObj) => {setBusinessDetails({...businessDetails, "image-title" : imageObj['image-title'], "image-content": imageObj['image-content'], "image-type": imageObj['image-type']});}
  const handleNDASelect = (ndaKey) => setBusinessDetails({...businessDetails, "nda": ndaKey.target.value});
  const handleSubmit = async() => {
    setLoading(true);
    if (!window.location.host.includes('localhost') && !window.location.host.includes('dev.onedeal.biz')) {
      window.gtag("event", "button_click", {
        button: "publish_listing",
        user_email: userProfileData.email
      });
    }
    putImage(businessDetails['image-content'], businessDetails['image-type'], businessDetails['image-title'], setErrorMessage).then((data) => {
      postListing(businessDetails, data.key, data['listing-id'], userAttributes).then((response) => {
        if(!response['listing-id']){
          setErrorMessage(response);
        }
        else{
          handleUploadToS3(response['listing-id']).then(() => {
            navigate('/myOneDeal');
            setLoading(false);
          });          
        }
      });
    })
  };

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  }).catch(error => {
    // Code to handle the error
    console.error('An error occurred during PostAListing:', error);
});  ;

  const handleUploadToS3 = async(listingId) => {
    for(let x=0; x < dataRoomFiles.length; x++){
      const base64 = await toBase64(dataRoomFiles[x]);
      await putDataRoomDocument(
        base64,
        dataRoomFiles[x].type,
        dataRoomFiles[x].name,
        'datarooms',
        listingId,
        setErrorMessage
      );   
    }
  }

  const getUserAttributes = async() => {
    retrieveUserAttributes().then((data) => {
      if(data.errorMessage){
        setErrorMessage(data.errorMessage);
      }else{
        setUserAttributes(data.result)
        setBusinessDetails({...businessDetails, "contact-email" : data.result.email})
      }
    });
  }

  const getUserProfile = async() => {
    retrieveUserProfile().then((response) => {
      if(response.errorMessage){
        setErrorMessage(response.errorMessage);
      }else{
        setUserProfileData(response);
      }
    });
  }

  const getAvailableNDAs = async() => {
    getS3Docs("onedeal-broker-documents", `${userProfileData.email}/ndas/`).then((response) => {
      setAvailableNDAs(response);
    })
  }

  useEffect(() => {
    if (!window.location.host.includes('localhost') && !window.location.host.includes('dev.onedeal.biz')) {
      window.gtag("event", "page_view", {
        page_path: location.pathname + location.search,
      });
    }
    if(localStorage.getItem("authorized") && !userAttributes){
      getUserAttributes();
    }else if(localStorage.getItem("authorized") && !userProfileData){
      getUserProfile();
    }else if(localStorage.getItem("authorized") && !availableNDAs){
      getAvailableNDAs();
    }else if(!localStorage.getItem("authorized")){
      navigate('/login');
    }
  },[location, userAttributes, userProfileData, availableNDAs]);

  return (
    <Box sx={{height:"100%"}}>
      {userProfileData && tourSteps && <TakeTheTour userEmail={userProfileData.email} isBroker={userProfileData['is-broker']}/>}
      <SetPageTitle title="Sell A Business"/>
      <NavBar userAttributes={userAttributes} setUserAttributes={setUserAttributes} userProfileData={userProfileData}/>
      {
        !validAddress &&
        <BusinessAddress 
          handleFieldUpdate={handleFieldUpdate}
          businessDetails={businessDetails}
          setBusinessDetails={setBusinessDetails}
          setValidAddress={setValidAddress}
          handleNumberUpdates={handleNumberUpdates}
          handlePhoneUpdate={handlePhoneUpdate}
          handleStateUpdate={handleStateUpdate}
        />      
      }
      {
        validAddress &&
        !validListing &&
        <ListingInformation 
          handleFieldUpdate={handleFieldUpdate}  
          businessDetails={businessDetails}
          setValidAddress={setValidAddress}
          setValidListing={setValidListing}
          handleType={handleType} 
          handleIndustry={handleIndustry}
          handleImageUpdate={handleImageUpdate}
          handleFinancing={handleFinancing}
          handleNumberUpdates={handleNumberUpdates}
        />
      }
      {
        validAddress && 
        validListing &&
        <Financials 
          handleFieldUpdate={handleFieldUpdate}
          businessDetails={businessDetails}
          setValidListing={setValidListing}
          handleSubmit={handleSubmit}
          handleNumberUpdates={handleNumberUpdates}
          availableNDAs={availableNDAs}
          handleNDASelect={handleNDASelect}
          dataRoomFiles={dataRoomFiles}
          setDataRoomFiles={setDataRoomFiles}
          loading={loading}
          getAvailableNDAs={getAvailableNDAs}
        />
      }
    {errorMessage && <SnackBar errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>}
    </Box>
  );
}