import { React, useState } from 'react';
import { Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import './FAQ.css'
const FAQComponent = () => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <p>What is OneDeal?</p>
                </AccordionSummary>
                <AccordionDetails>
                <p>
                OneDeal is the first end to end platform for the small business acquisition process. Currently we are offering a <Link className='faqLink' to="/businesses-for-sale">marketplace</Link> with over 30,000 actively listed businesses along with proprietary deals only available on OneDeal. We also offer <a className='faqLink' href='https://www.calendly.com/info-onedeal' target="_blank" rel="noreferrer">pre qualification services</a>to vet quality buyers and sellers so the process is expedited for both parties.
                </p>
                </AccordionDetails>
            </Accordion>            
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                >
                <p>Who should use OneDeal?</p>
                </AccordionSummary>
                <AccordionDetails>
                <p>
                OneDeal platform is for anyone involved in the small business acquisition process. This includes, but not limited to business owners, business brokers, small cap private equity companies, search funds, M&A advisors, strategic buyers, and more. OneDeal is also the perfect place for a first time small business buyer.
                </p>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                >
                <p>What types of businesses are available for sale on OneDeal? </p>
                </AccordionSummary>
                <AccordionDetails>
                <p>
                The OneDeal <Link className='faqLink' to="/businesses-for-sale">marketplace</Link> includes any physical small business currently listed for sale on the web. Some of the industries included are service businesses, retail, financial services, restaurants and food, manufacturing, and many more.
                </p>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                >
                <p>How do I create an account? </p>
                </AccordionSummary>
                <AccordionDetails>
                <p>
                Click on the <Link className='faqLink' to="/signup">sign up</Link> button to get started.
                </p>
                </AccordionDetails>
            </Accordion>
    </>
  );
}
export default FAQComponent;