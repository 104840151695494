import { React } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {createFilterOptions} from '@mui/material/Autocomplete';

export const LocationSearch = (props) => {

  const filterOptions = createFilterOptions({
    limit: 10
  });
  
  return (
    <>
      <Autocomplete
        
        filterOptions={filterOptions}
        id="location"
        options={props.locations ? props.locations : []}
        renderInput={(params) => <TextField required={props.required} error={props.error} variant="outlined" {...params} label={props.label} sx={{
          '& .MuiOutlinedInput-root': { borderRadius: "10px"}}}
          />}
        autoHighlight={true}
        autoComplete={true}
        sx={{textAlign:"center", borderRadius:"10px"}}
        onInputChange={(event, value) =>  props.setInfoMap({...props.infoMap, "location" : value})}
        value={props.infoMap.location}
      />
    </>
  );
}

export default LocationSearch;