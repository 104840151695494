import { React, useState, useEffect } from 'react';
import { useNavigate, useLocation } from  'react-router-dom';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FavoriteListings from './FavoriteListings';
import UserPostedListings from './UserPostedListings';
import SavedSearches from './SavedSearch/SavedSearches';
import Analytics from './Analytics/Analytics';
import { UserProfile } from './Profile/UserProfile';
import DragAndDrop from './FileUploader';
import MyDeals from './MyDeals/MyDeals';
import Requests from './Requests/Requests';
import TakeTheTour from '../../components/TakeTheTour/TakeTheTour';
import SubscriptionManagement from './SubscriptionManagement';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p:  0}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TabManager(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const [value, setValue] = useState((localStorage.getItem('listingManagementTab')) ? parseInt(localStorage.getItem('listingManagementTab')): 0);
  const [tourSteps, setTourSteps] = useState(location?.state?.showTour)
  useEffect(() => {
    if(props.isBroker === 'Y'){
      document.title = "Listings | My OneDeal"
    }else{
      document.title = "Deals | My OneDeal"
    }
  }, [props.isBroker]);

  const handleGoogleEvent = (buttonName) => {
    if (!window.location.host.includes('localhost') && !window.location.host.includes('dev.onedeal.biz')) {
      window.gtag("event", "button_click", {
        button: buttonName,
        user_email: props.userProfileData.email
      });
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem('listingManagementTab', newValue);

    if(props.isBroker === 'Y'){
      if(newValue === 0){
        navigate('/myOneDeal/my-listings', { replace: true });
        document.title = "Listings | My OneDeal"
        handleGoogleEvent("mod_listings");
      }else if(newValue === 1){
        navigate('/myOneDeal/listing-analytics', { replace: true });
        document.title = "Analytics | My OneDeal"
        handleGoogleEvent("mod_analytics");
      }else if(newValue === 2){
        navigate('/myOneDeal/requests', { replace: true });
        document.title = "Requests | My OneDeal"
        handleGoogleEvent("mod_requests");
      }else if(newValue === 3){
        navigate('/myOneDeal/non-disclosure-agreements', { replace: true });
        document.title = "NDAs | My OneDeal"
        handleGoogleEvent("mod_nda");
      }else if(newValue === 4){
        navigate('/myOneDeal/favorites', { replace: true });
        document.title = "Favorites | My OneDeal"
        handleGoogleEvent("mod_favorites");
      }else if(newValue === 5){
        navigate('/myOneDeal/user-profile', { replace: true });
        document.title = "Profile | My OneDeal"
        handleGoogleEvent("mod_profile");
      } else if (newValue === 6) {
        navigate('/myOneDeal/subscription', { replace: true });
        document.title = "Subscription | My OneDeal"
        handleGoogleEvent("mod_subscription");
      }
    }else{
      if(newValue === 0){
        navigate('/myOneDeal/my-deals', { replace: true });
        document.title = "Deals | My OneDeal";
        handleGoogleEvent("mod_my_deals");
      }else if(newValue === 1){
        navigate('/myOneDeal/favorites', { replace: true });
        document.title = "Favorites | My OneDeal"
        handleGoogleEvent("mod_favorites");
      }else if(newValue === 2){
        navigate('/myOneDeal/saved-searches', { replace: true });
        document.title = "Saved Searches | My OneDeal"
        handleGoogleEvent("mod_saved_search");
      }else if(newValue === 3){
        navigate('/myOneDeal/prequalify', { replace: true });
        document.title = "Prequalify | My OneDeal"
        handleGoogleEvent("mod_prequalify");
      }else if(newValue === 4){
        navigate('/myOneDeal/user-profile', { replace: true });
        document.title = "Profile | My OneDeal"
        handleGoogleEvent("mod_profile");
      } else if (newValue === 5) {
        navigate('/myOneDeal/subscription', { replace: true });
        document.title = "Subscription | My OneDeal"
        handleGoogleEvent("mod_subscription");
      }
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        {
          props.userProfileData && tourSteps && <TakeTheTour setTabValue={setValue} userEmail={props.userProfileData.email} isBroker={props.userProfileData['is-broker']}/>
        }
        {
          props.isBroker === 'Y' &&
          <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons allowScrollButtonsMobile>
            <Tab label="Listings" {...a11yProps(0)} />
            <Tab label="Analytics" {...a11yProps(1)} />
            <Tab label="Requests" {...a11yProps(2)} />
            <Tab label="NDAs" {...a11yProps(3)} />
            <Tab label="Favorite Listings" {...a11yProps(4)} />
            <Tab label="Profile" {...a11yProps(5)} />
            <Tab label="DealScout" {...a11yProps(6)} />
          </Tabs>
        }

        {
          props.isBroker !== 'Y' &&
          <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons allowScrollButtonsMobile>
            <Tab label="My Deals" {...a11yProps(0)} />
            <Tab label="Favorite Listings" {...a11yProps(1)} />
            <Tab label="Saved Searches" {...a11yProps(2)} />
            <Tab label="Prequalify" {...a11yProps(3)} />
            <Tab label="Profile" {...a11yProps(4)} />
            <Tab label="DealScout" {...a11yProps(5)} />
          </Tabs>
        }
      </Box>
      {
        props.isBroker !== 'Y' &&
        <>
          <TabPanel value={value} index={0}>
            <MyDeals 
              userAttributes={props.userAttributes}
              userFavoritedListings={props.userFavoritedListings}
              userFavorites={props.userFavorites}
              setUserFavorites={props.setUserFavorites}
              // userInfoRequestsListings = {props.userInfoRequestsListings}
              userInfoRequests={props.userInfoRequests}
              buyerDeals={props.buyerDeals}
              drRequestsSent={props.drRequestsSent}
              // drRequestsSentListings={props.drRequestsSentListings}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <FavoriteListings 
              userAttributes={props.userAttributes}
              userFavoritedListings={props.userFavoritedListings}
              userFavorites={props.userFavorites}
              setUserFavorites={props.setUserFavorites}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <SavedSearches 
                userSavedSearches={props.userSavedSearches}
                setUserSavedSearches={props.setUserSavedSearches}
                setErrorMessage={props.setErrorMessage}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <DragAndDrop prequalSettings={props.prequalSettings} isBroker={props.isBroker}/>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <UserProfile />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <SubscriptionManagement userProfileData={props.userProfileData}/>
          </TabPanel>
        </>
      }
      {
        props.isBroker === 'Y' &&
        <>
        <TabPanel value={value} index={0}>
            <UserPostedListings
              userAttributes={props.userAttributes}
              userFavorites={props.userFavorites}
              setUserFavorites={props.setUserFavorites}
              isLoading={props.isLoading}
              userPostedListings={props.userPostedListings}
              getUserListings={props.getUserListings}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Analytics
              userPostedListings={props.userPostedListings}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Requests
              drRequestsReceived={props.drRequestsReceived}
              setDrRequestsReceived={props.setDrRequestsReceived}
              userProfileData={props.userProfileData}
              userPostedListings={props.userPostedListings}
            />
          </TabPanel>
        <TabPanel value={value} index={3}>
          <DragAndDrop prequalSettings={props.prequalSettings} isBroker={props.isBroker} email={props.userProfileData.email}/>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <FavoriteListings 
            userAttributes={props.userAttributes}
            userFavoritedListings={props.userFavoritedListings}
            userFavorites={props.userFavorites}
            setUserFavorites={props.setUserFavorites}
          />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <UserProfile />
        </TabPanel>
        <TabPanel value={value} index={6}>
          <SubscriptionManagement userProfileData={props.userProfileData}/>
        </TabPanel>
      </>
      }
    </Box>
  );
}