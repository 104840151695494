import React from "react";
import { useNavigate } from "react-router-dom";
import "./DealScoutLandingPage.css";
import Stack from "@mui/material/Stack";

const Action = () => {
  const navigate = useNavigate();
  return (
    <div>
      <section
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "8rem",
          justifyContent: "center",
          alignItems: "center",
          gap: "3rem",
        }}
      >
        <h2 style={{ maxWidth: "77%", textAlign: "center" }}>
          Join over <span style={{ color: "var(--base)" }}>1,000</span> users
          already using <span style={{ color: "var(--base)" }}>OneDeal</span> to
          find their perfect business
        </h2>
        <Stack direction="row" spacing={4}>
          <button className="listingDetails-btn">
            <p
              className="actionText"
              onClick={() => {
                window.open(
                  "https://calendly.com/anthony-onedeal/30min",
                  "_blank"
                );
              }}
            >
              Chat with us
            </p>
          </button>

          <button className="listingDetails-btn">
            <p
              className="actionText"
              onClick={() => {
                navigate("/deal-scout/checkout");
              }}
            >
              Get Started
            </p>
          </button>
        </Stack>
      </section>
    </div>
  );
};

export default Action;
