import { React } from 'react';
import { getSubscriptionLink } from './UserManagementService';
import { listingStatistics } from './ListingManagementService';

const getLocation = (locationValue) => {
  if(locationValue.includes("Undisclosed")){
      return "Undisclosed Location"
  }
  const splitLocation = locationValue.split(',');
  return `${splitLocation[1]}, ${splitLocation[0]}`;
}

export const requestInfo = async(moreInfoParams, listingDetails, listingLink, isLoggedIn) => { 
  moreInfoParams['headline'] = listingDetails.headline;
  moreInfoParams['location'] = getLocation(listingDetails.location);
  moreInfoParams['listingLink'] = listingLink;
  

  let subscriptionData;
  if(isLoggedIn){
    subscriptionData = await getSubscriptionLink({"email": moreInfoParams.email});
    moreInfoParams['subscription-link'] = subscriptionData.subscriptionKey;
    moreInfoParams['email-subscription'] = subscriptionData.emailSubscription;
  }

  if(moreInfoParams.email !== listingDetails['posted-by-email']){
    await listingStatistics({"user-email": moreInfoParams.email, "listing": listingDetails, "action": "information"}); 
  }
  
  
  const response = await fetch('/api/aws/moreInfo', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(moreInfoParams),
  }).then(res => res.json()).then((data) => {
    return data;
  });
  
  return response;
};

export const notifyOneDealTeam = async(notificationPayload) => {    
  
  const response = await fetch('/api/aws/notify-onedeal-team', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(notificationPayload),
  }).then(res => res.json()).then((data) => {
    return data;
  });
  
  return response;
};

export const sendDRRequestNotification = async(notificationPayload) => {    
  
  const response = await fetch('/api/aws/notify-broker-dr-request', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(notificationPayload),
  }).then(res => res.json()).then((data) => {
    return data;
  });
  
  return response;
};

export const sendDRResponseNotification = async(notificationPayload) => {    
  
  const response = await fetch('/api/aws/notify-buyer-dr-request', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(notificationPayload),
  }).then(res => res.json()).then((data) => {
    return data;
  });
  
  return response;
};