import { React } from 'react';
import {Grid} from '@mui/material';
import { ListingDesktop } from './ListingDesktop/ListingDesktop';
import { ListingMobile } from './ListingMobile/ListingMobile';

const mobile = {
  textAlign:"center",
  width:"100%",
  '@media(min-Width: 1024px)' : {
      display: "none"
  }
}

const desktop = {
  textAlign:"center",
  width:"100%", 
  '@media(max-Width: 1023px)' : {
      display: "none"
  }
}
const Listing = (props) => {
  return (
    <>
      <Grid item sx={mobile}> 
        <ListingMobile listing={props.listing} key={props.key} userAttributes={props.userAttributes} indexOfFavorite={props.indexOfFavorite} setUserFavorites={props.setUserFavorites} setErrorMessage={props.setErrorMessage} brokerProfile={props.brokerProfile} getUserListings={props.getUserListings} dealStatuses={props.dealStatuses}/>
      </Grid>
      <Grid item sx={desktop}>
        <ListingDesktop listing={props.listing} key={props.key} userAttributes={props.userAttributes} indexOfFavorite={props.indexOfFavorite} setUserFavorites={props.setUserFavorites} setErrorMessage={props.setErrorMessage} brokerProfile={props.brokerProfile} getUserListings={props.getUserListings} dealStatuses={props.dealStatuses}/>
      </Grid>
    </>
  );
};
export default Listing;
