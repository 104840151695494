import { React } from 'react';
import { Grid } from '@mui/material';
import Listing from '../../components/Listing/Listing';
import Footer from '../../components/Footer/Footer';

const FavoriteListings = (props) => {
    return(
      <>
        <Grid container direction="row" justifyContent="center" marginTop="8px">
          { 
            props.userFavoritedListings && 
            props.userFavoritedListings.map((listing) => {
              return(
                <Listing listing={listing._source} key={listing._id} indexOfFavorite={props.userFavorites.indexOf(listing._id)} setUserFavorites={props.setUserFavorites} setErrorMessage={props.setErrorMessage}/>
              )
            })
          }
        </Grid>
        <Footer />
      </>
    );
}

export default FavoriteListings;