import { React } from 'react';
import Button from '@mui/material/Button';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { Grid } from '@mui/material';
import { putAvatar, updateUserProfile } from '../../services/UserManagementService';

const ProfilePicture = (props) => {

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      }).catch(error => {
        // Code to handle the error
        console.error('An error occurred during profilePicture Signup:', error);
    });  ;

    const handleFile = async(event) => {
        const file = event.target.files[0];
        const base64 = await toBase64(file);
        props.setNewAvatar({"image-title":file.name, "image-content": base64, "image-type": file.type});
        let profileData = props.userProfileData;
        if(file){
            const avatarResponse = await putAvatar(base64, file.type, file.name, props.setErrorMessage, props.userProfileData.email); 
            if(!avatarResponse.key){
                props.setErrorMessage("Error uploading new avatar");
              }else{
                props.setUserProfileData({...props.userProfileData, 'avatar' : avatarResponse.key});
                props.setImageUploaded(true);
                profileData = {...props.userProfileData, 'avatar' : avatarResponse.key};
              }
        }
        const updateProfileResponse = await updateUserProfile(profileData);
        if(!updateProfileResponse.email){
            props.setErrorMessage("Error updating user profile")
        }
    }

    return (
    <Grid container direction="row" alignItems="center" justifyContent="center">
        <Grid item xs={11} sm={6.5} sx={{marginBottom:"10px"}}>
            <Button variant="contained" component="label" sx={{backgroundColor:"#0e9384", fontWeight:"bold"}}>
                Change Profile Picture
                <input hidden accept="image/*" type="file" onChange={handleFile} id="image"/>
                <PhotoCamera sx={{pl:"10px"}}/>
            </Button>
        </Grid>
    </Grid>
    );
}
export default ProfilePicture;