import React from "react";
import Labels from "./Labels";
import List from "./List";
import Numbers from "./Numbers";
const DealScoutCard = ({ labelsData, listData, numbersData }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        maxWidth: "80%",
        gap: "3rem",
      }}
    >
      <h2 style={{ margin: "2rem" }}>Our Process</h2>
      {labelsData && <Labels props={labelsData[0]} />}
      {listData && <List props={listData} />}
      {numbersData && <Numbers props={numbersData[0]} />}
      {labelsData[1] && <Labels props={labelsData[1]} />}
      {numbersData[1] && <Numbers props={numbersData[1]} />}
    </div>
  );
};

export default DealScoutCard;
