import { React, useState, useEffect } from "react";
import { Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
// import Avatar from "@mui/material/Avatar";
// import Chip from "@mui/material/Chip";
import { upsertDealScoutList } from "../../../services/DealScout";
import ListButton from "./ListButton";
import "../DealScout.css";
import { makeStyles } from "@mui/styles";
import Pagination from "@mui/material/Pagination";
import SearchList from "../Scouts/SearchList";

const useStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      fontWeight: "bold",
      marigin: "Auto",
      "&.Mui-selected": {
        backgroundColor: "#518e6e",
        color: "white",
      },
    },
  },
}));

const dividerStyle = {
  top: "272px",
  left: "154px",
  width: "1096px",
  height: "2px",
  backgroundColor: "#f0f0f0",
  borderRadius: "2px",
};

const Lists = (props) => {
  const classes = useStyles();

  //state management
  const [displayNewList, setDisplayNewList] = useState(false);
  const [selectListDropDown, setSelectListDropDown] = useState(
    props.listControlledByCampaign ? props.listControlledByCampaign : ""
  );
  const [selectedPageRange, setSelectedPageRange] = useState([0, 10]);
  const [currentList, setCurrentList] = useState(
    props.listControlledByCampaign && props.usersDealScouts
      ? props.usersDealScouts.scoutData.lists[props.listControlledByCampaign]
      : {
          name: "",
          businesses: {},
          lastModified: Date.now(),
        }
  );

  const [totalBusinesses, setTotalBusinesses] = useState(
    props.listControlledByCampaign && props.usersDealScouts
      ? Object.keys(
          props.usersDealScouts.scoutData.lists[props.listControlledByCampaign]
            .businesses
        ).length
      : 0
  );
  //hanlder functions
  const handleListSelect = (event) => {
    setSelectListDropDown(event.target.value);
    if (event.target.value in props.usersDealScouts.scoutData.lists) {
      setCurrentList(props.usersDealScouts.scoutData.lists[event.target.value]);
      setTotalBusinesses(
        Object.keys(
          props.usersDealScouts.scoutData.lists[event.target.value].businesses
        ).length
      );
    }
  };

  const handleListName = (event) => {
    setCurrentList({
      name: event.target.value,
      businesses: {},
      lastModified: Date.now(),
    });
  };

  const handleRemoveFromList = async (bizId) => {
    let updatedBusinesses = currentList.businesses;
    if (bizId in currentList.businesses) {
      delete updatedBusinesses[bizId];
      setTotalBusinesses(Object.keys(updatedBusinesses).length);
    }
    setCurrentList({ ...currentList, businesses: updatedBusinesses });
    await upsertDealScoutList({
      listId: selectListDropDown,
      listBody: currentList,
    });
  };

  const handlePageSelect = (event, page) => {
    setSelectedPageRange([page * 10 - 10, page * 10]);
  };

  useEffect(() => {}, [currentList, totalBusinesses]);

  return (
    <>
      <Grid container direction="row" justifyContent="center" spacing={2}>
        <Grid item xs={10}>
          <h1 style={{ color: "black" }}>Lists</h1>
        </Grid>
        {!displayNewList && (
          <Grid
            item
            xs={5}
            sx={{ backgroundColor: "white", borderRadius: "10px" }}
          >
            <FormControl sx={{ m: 1, minWidth: "100%" }}>
              <InputLabel id="demo-simple-select-helper-label">
                Select A Saved List
              </InputLabel>
              <Select
                //   labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={selectListDropDown}
                label="Select A Saved List"
                onChange={handleListSelect}
                sx={{ borderRadius: "24px" }}
              >
                <MenuItem value="" onClick={() => setDisplayNewList(true)}>
                  Create New List
                </MenuItem>
                {props.usersDealScouts &&
                  "scoutData" in props.usersDealScouts &&
                  Object.keys(props.usersDealScouts.scoutData.lists).map(
                    (key) => {
                      return (
                        <MenuItem value={key}>
                          {props.usersDealScouts.scoutData.lists[key].name}
                        </MenuItem>
                      );
                    }
                  )}
              </Select>
            </FormControl>
          </Grid>
        )}
        {displayNewList && (
          <>
            <Grid item xs={10}>
              <TextField
                id="name"
                label="Enter Your New List Name Here!"
                variant="outlined"
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": { borderRadius: "24px" },
                }}
                onChange={handleListName}
                onMouseLeave={async () => {
                  if (currentList.name) {
                    await upsertDealScoutList({
                      listId: selectListDropDown,
                      listBody: currentList,
                    }).then((response) => {
                      setDisplayNewList(false);
                      setSelectListDropDown(response["updated-list-id"]);
                      setCurrentList(
                        response.Attributes.lists[response["updated-list-id"]]
                      );
                      props.setUsersDealScouts({
                        scoutData: response.Attributes,
                        userExists: true,
                      });
                    });
                  }
                }}
                // onKeyDown={(e) => {if(e.code === 'NumpadEnter' || e.code === 'Enter'){props.getFilteredListings()}}}
                value={currentList.name}
              />
            </Grid>
            {/* <Grid item xs={5}>
            <button
              onClick={async () =>
                await upsertDealScoutList({
                  listId: selectListDropDown,
                  listBody: currentList,
                }).then((response) => {
                  setDisplayNewList(false);
                  setSelectListDropDown(response["updated-list-id"]);
                  setCurrentList(
                    response.Attributes.lists[response["updated-list-id"]]
                  );
                  props.setUsersDealScouts({
                    scoutData: response.Attributes,
                    userExists: true,
                  });
                })
              }
              variant="contained"
              className="listingDetails-btn"
            >
              Save This List
            </button>
          </Grid> */}
          </>
        )}
      </Grid>
      {totalBusinesses > 0 &&
        Object.keys(currentList.businesses)
          .slice(selectedPageRange[0], selectedPageRange[1])
          .map((biz) => {
            let existsInList = biz.infogroup_id in currentList.businesses;
            return (
              <>
                <Divider orientation="horizontal" sx={dividerStyle} />
                <Grid
                  item
                  xs={11}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    paddingBottom: "16px",
                  }}
                >
                  <SearchList
                    biz={currentList.businesses[biz]}
                    existsInList={existsInList}
                    saveButtonState={false}
                  />
                  <ListButton
                    existsInList={true}
                    handleAddRemoveFromList={handleRemoveFromList}
                    biz={biz}
                  />
                </Grid>
              </>
            );
          })}
      {totalBusinesses > 0 && (
        <Grid item sx={{ justifyContent: "center", display: "flex" }}>
          <Pagination
            count={Math.ceil(totalBusinesses / 10)}
            disabled={!totalBusinesses}
            onChange={handlePageSelect}
            classes={{ ul: classes.ul }}
          />
        </Grid>
      )}
    </>
  );
};
export default Lists;
