import { React, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import {
  retrieveUserAttributes,
  retrieveUserProfile,
} from "../../services/UserManagementService";
import "../DealScoutLandingPage/DealScoutLandingPage.css";
import NavBar from "../../components/NavBar/NavBar";
import SetPageTitle from "../../components/SetPageTitle/SetPageTitle";
import ResultNumbers from "../DealScoutLandingPage/ResultNumbers";

import Footer from "../../components/Footer/Footer";
import Step from "./Step";
import brokereddeals from "../../assets/Landing/brokereddeals.webp";
import curate from "../../assets/curate.webp";
import questionnaires from "../../assets/questionnaires.webp";
import nda from "../../assets/nda.webp";

import Action from "../DealScoutLandingPage/Action";
const BrokeredDeals = () => {
  const [userAttributes, setUserAttributes] = useState();
  const [userProfileData, setUserProfileData] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const location = useLocation();
  const BrokeredDealsData = [
    {
      p: "Aggregated from every marketplace and broker website on the internet ",
      h: "Aggregated Deals",
    },
    {
      p: "Get instant access to NDA, CIM, and Financials if listed directly by the broker or seller ",
      h: "Directly Listed Deals",
    },
    {
      p: "All deals are verified to make sure they are still available for sale",
      h: "Verification Protection",
    },
  ];

  const Step_1 = {
    number: 1,
    h4: "Curate your search",
    para: "Simple, easy, and specific filters to find the deals you are interested in",
    img: curate,
  };

  const Step_2 = {
    number: 2,
    h4: "Fill out Pre-Qualification Questionnaire",
    para: "The Pre-Qualificaiton Questionnaire allows us to vet buyers so you don’t have to send your documents to every deal",
    img: questionnaires,
  };

  const Step_3 = {
    number: 3,
    h4: "Request Access to Data Rooms ",
    para: "One click secure NDA process to access data rooms",
    img: nda,
  };

  const getUserAttributes = async () => {
    retrieveUserAttributes().then((response) => {
      if (response.errorMessage) {
        setErrorMessage(response.errorMessage);
      } else {
        setUserAttributes(response.result);
      }
    });
  };

  const getUserProfile = async () => {
    retrieveUserProfile().then((response) => {
      if (response.errorMessage) {
        setErrorMessage(response.errorMessage);
      } else {
        setUserProfileData(response);
      }
    });
  };
  useEffect(() => {
    if (localStorage.getItem("authorized") && !userAttributes) {
      getUserAttributes();
    } else if (localStorage.getItem("authorized") && !userProfileData) {
      getUserProfile();
    }

    if (
      !window.location.host.includes("localhost") &&
      !window.location.host.includes("dev.onedeal.biz")
    ) {
      window.gtag("event", "page_view", {
        page_path: location.pathname + location.search,
      });
    }
  }, [userAttributes, userProfileData, location]);
  return (
    <div>
      <SetPageTitle title="Brokered Deals" />
      <NavBar
        userAttributes={userAttributes}
        setUserAttributes={setUserAttributes}
        userProfileData={userProfileData}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "0.75rem",
          marginTop: "5rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2>
            Easiest way to
            <span style={{ color: "#0E9384" }}> find brokered </span>
            deals
          </h2>
        </div>
        <p
          style={{ textAlign: "center", maxWidth: "50%", marginBottom: "3rem" }}
        >
          Get instant access to actively listed businesses with NDA, Data rooms,
          CIMs, and financials
        </p>

        <button
          className="listingDetails-btn"
          style={{ width: "10rem" }}
          onClick={() => {
            window.open("/deal-scout/checkout", "_blank");
          }}
        >
          Get Started
        </button>

        <img
          src={brokereddeals}
          style={{ maxWidth: "70%", margin: "4rem 0 4rem 0 " }}
          alt="hero"
        ></img>
        <ResultNumbers data={BrokeredDealsData} showHeader={true} />
      </div>

      {/* <Industries /> */}
      <section
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "5rem",
        }}
      >
        <Step data={Step_1} position={true} />
        <Step data={Step_2} position={false} />
        <Step data={Step_3} position={true} />
      </section>
      <Action />
      <Footer />
    </div>
  );
};

export default BrokeredDeals;
