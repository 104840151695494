import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const List = (props) => {
  let arr = props.props.points;
  let data = props.props;
  return (
    <div>
      {console.log(props, props.props.points, data)}

      {props.position === "left" ? (
        <section className="cardSection">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              width: "40%",
            }}
          >
            <div className="chip">{data.chip}</div>
            <div className="cardDiv">
              <h3>{data.title}</h3>
              <p>{data.paragraph}</p>
            </div>
            <hr />
            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
              <div>
                <ul className="benefitsList">
                  <li>
                    <CheckCircleIcon sx={{ color: "#0E9384" }} />
                    <p>{arr[0]}</p>
                  </li>
                  <li>
                    <CheckCircleIcon sx={{ color: "#0E9384" }} />
                    <p>{arr[1]}</p>
                  </li>
                  <li>
                    <CheckCircleIcon sx={{ color: "#0E9384" }} />
                    <p>{arr[2]}</p>
                  </li>
                </ul>
              </div>
              <div>
                <ul className="benefitsList">
                  <li>
                    <CheckCircleIcon sx={{ color: "#0E9384" }} />
                    <p>{arr[3]}</p>
                  </li>
                  <li>
                    <CheckCircleIcon sx={{ color: "#0E9384" }} />
                    <p>{arr[4]}</p>
                  </li>
                  <li>
                    <CheckCircleIcon sx={{ color: "#0E9384" }} />
                    <p>{arr[5]}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <img src={data.img} className="cardImg" alt="" />
        </section>
      ) : (
        <section className="cardSection">
          <img src={data.img} className="cardImg" alt="" />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              width: "40%",
            }}
          >
            <div className="chip">{data.chip}</div>
            <div className="cardDiv">
              <h3>{data.title}</h3>
              <p>{data.paragraph}</p>
            </div>
            <hr />
            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
              <div>
                <ul className="benefitsList">
                  <li>
                    <CheckCircleIcon sx={{ color: "#0E9384" }} />
                    <p>{arr[0]}</p>
                  </li>
                  <li>
                    <CheckCircleIcon sx={{ color: "#0E9384" }} />
                    <p>{arr[1]}</p>
                  </li>
                  <li>
                    <CheckCircleIcon sx={{ color: "#0E9384" }} />
                    <p>{arr[2]}</p>
                  </li>
                </ul>
              </div>
              <div>
                <ul className="benefitsList">
                  <li>
                    <CheckCircleIcon sx={{ color: "#0E9384" }} />
                    <p>{arr[3]}</p>
                  </li>
                  <li>
                    <CheckCircleIcon sx={{ color: "#0E9384" }} />
                    <p>{arr[4]}</p>
                  </li>
                  <li>
                    <CheckCircleIcon sx={{ color: "#0E9384" }} />
                    <p>{arr[5]}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default List;
