import { React, useState, useEffect} from 'react';
import { Grid } from '@mui/material';
import "../DealScout.css"

const ListButton = (props) => {
    const [buttonText, setButtonText] = useState()
    useEffect(() => {
        setButtonText(props.existsInList ? "Saved" : "Save to List");
    },[props.existsInList])
    return(
        <Grid item xs={1.5}>
            <button 
                variant="contained" 
                className={props.existsInList ? 'remove-from-list-button' : 'add-to-list-button'} 
                onClick={() => props.handleAddRemoveFromList(props.biz)}
                onMouseEnter={props.existsInList ? () => setButtonText("Remove") : () => setButtonText("Save")}
                onMouseLeave={props.existsInList ? () => setButtonText("Saved") : () => setButtonText("Save to List")}
            >
                {buttonText}
            </button>
        </Grid>
    );
}

export default ListButton;