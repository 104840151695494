import { React, useState, useEffect, forwardRef} from 'react';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { getS3Docs, deleteFilesFromS3, deleteBrokerFilesFromS3 } from '../../services/UserManagementService';
import { putPrequalificationDocument, putBrokerDocument } from '../../services/UserManagementService';
const DragAndDrop = forwardRef((props, ref) => {

const descriptions = {
    "proof_of_funds" : "Thank you for your interest in our services. To proceed with your request, we require proof of funds documentation. Please upload your latest bank statement showing your available balance and recent transactions. Your uploaded document will be kept confidential and securely stored. Please ensure that the document is clear and legible. Thank you for your cooperation.", 
    "nda" : "To keep SMB listing financial data confidential, all potential buyers must sign an NDA. Brokers can upload their NDA to OneDeal for automatic prompt to potential buyers. Protect your sensitive data and offer secure buying experience by uploading your NDA."
    }
  const [dragging, setDragging] = useState(false);
  const [files, setFiles] = useState([]);
  const [description, setDescription] = useState(!props.isBroker ? descriptions["proof_of_funds"] : descriptions["nda"]);  
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [fundsDocuments, setFundsDocuments] = useState();


  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  }).catch(error => {
    // Code to handle the error
    console.error('An error occurred during FileUploader Signup:', error);
});  ;

  const handleUploadToS3 = async() => {
    setLoading(true);
    for(let x=0; x < files.length; x++){
        const base64 = await toBase64(files[x]);
        if(!props.isBroker){
          await putPrequalificationDocument(
            base64,
            files[x].type,
            files[x].name,
            "funds",
            setErrorMessage
          );
        }else{
          await putBrokerDocument(
            base64,
            files[x].type,
            files[x].name,
            "ndas",
            setErrorMessage
          );
        }
    }
    setLoading(false);
  }

  const getPreviouslyUploadedDocs = async() => {
    let results;
    if(!props.isBroker){
      results = await getS3Docs("onedeal-prequalification-documents", `${props.email}/funds/`);
    }else{
      results = await getS3Docs("onedeal-broker-documents",  `${props.email}/ndas/`);
    }
    setFundsDocuments(results);
    props.setUploadPOF(results);
  }

  const deleteUploadedDocs = async(title, folder) => {
    if(!props.isBroker){
      await deleteFilesFromS3(title, folder).then(async() => await getPreviouslyUploadedDocs());
    }else{
      await deleteBrokerFilesFromS3(title, folder).then(async() => await getPreviouslyUploadedDocs());
    }
    
  }

  const handleDragEnter = e => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDragOver = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    const newFiles = [...files];
    for (let i = 0; i < e.dataTransfer.files.length; i++) {
      newFiles.push(e.dataTransfer.files[i]);
    }
    setFiles(newFiles);
  };

  const handleFileUpload = e => {
    const newFiles = [...files];
    for (let i = 0; i < e.target.files.length; i++) {
      newFiles.push(e.target.files[i]);
    }
    setFiles(newFiles);
  };

  const handleRemoveFile = index => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

    useEffect(() => {
        if(!fundsDocuments){
            getPreviouslyUploadedDocs();
        } 
    },[fundsDocuments])

  return (
    <div ref={ref} {...props}>
      <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={9}>
              <h4 style={{textAlign:"center"}}>{!props.isBroker ? "Proof of Funds" : "Non-Disclosure Agreements"}</h4>
              <p>{description}</p>
              {fundsDocuments?.map((fundDoc) => {
                  let fundDocSplit = fundDoc.Key.split('/');
                  return(
                      <div style={{display:"flex", justifyContent:"center", marginBottom:"10px", alignItems:'center'}}>
                          <p style={{marginBottom:"0px", paddingRight:"10px"}}>{fundDocSplit[2]}</p>
                          <Button variant="outlined" color="error" onClick={() => deleteUploadedDocs(fundDocSplit[2],fundDocSplit[1])}>
                              Remove
                          </Button>
                      </div>
                  )
              })}
              <div
              className={`drag-and-drop${dragging ? ' active' : ''}`}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              style={{
                  textAlign:"center",
                  borderRadius:"10px",
                  // borderStyle:"dotted",
                  // borderColor: "#8DCAC3"
                  padding:"10px",
                  outline: "4px dashed #8DCAC3"
              }}
              >
                  <input
                      type="file"
                      multiple
                      onChange={handleFileUpload}
                      className="hidden-input"
                      style={{display:"None"}}
                  />
                  <Button className="upload-button" variant="contained" component="label" sx={{backgroundColor:"#0e9384", fontWeight:"bold"}} onClick={() => document.querySelector('.hidden-input').click()}>
                      Select Files
                  </Button>
                  <p style={{paddingTop:"10px"}}>Drag and drop files here, or click to select files</p>
                  <div className="file-list">
                      {files.map((file, index) => (
                      <div key={file.name} className="file-item">
                          <p>{file.name}</p>
                          <Button variant="contained" component="label" sx={{backgroundColor:"#0e9384", fontWeight:"bold", marginBottom:"10px"}} onClick={() => handleRemoveFile(index)}>
                              Remove
                          </Button>
                      </div>
                      ))}
                  </div>
                  <LoadingButton variant="contained" component="label" sx={{backgroundColor:"#0e9384", fontWeight:"bold"}} onClick={() => handleUploadToS3().then(() => setFiles([])).then(async() => await getPreviouslyUploadedDocs())} loading={loading}>
                      Upload
                  </LoadingButton>
              </div>
          </Grid>
      </Grid>
    </div>
  );
});

export default DragAndDrop;