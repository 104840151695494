/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { searchListings } from "../../services/ListingManagementService";
import { States } from "../../helperData/LocationData";

import Switches from "./Switches";
import techstar from "../../assets/Landing/techstars-logo.png";

const Banner = () => {
  const [locations, setLocations] = useState();

  const [filterMap, setFilterMap] = useState({
    listing_status: "available",
    search_phrase: "",
    location: "",
    industries: {},
    lowest_price: 0,
    highest_price: 30000000,
    lowest_cash_flow: 0,
    highest_cash_flow: 30000000,
    lowest_revenue: 0,
    highest_revenue: 30000000,
    sort_by: "Keywords",
    from: 0,
  });

  const getLocations = async () => {
    const locationResponse = await searchListings({
      size: 0,
      aggs: {
        locations: { terms: { field: "location.keyword", size: 50000 } },
      },
    });
    const locationAgg = [];
    for (
      let x = 0;
      x < locationResponse.aggregations.locations.buckets.length;
      x++
    ) {
      const currentLoc =
        locationResponse.aggregations.locations.buckets[x].key.split(",");
      locationAgg.push(currentLoc[1] + "," + currentLoc[0]);
    }
    const locationAggSet = [...new Set(locationAgg)];
    locationAggSet.sort();
    setLocations(States.concat(locationAggSet));
  };

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem("filterSettings", JSON.stringify(filterMap));
    navigate("/businesses-for-sale");
  };

  useEffect(() => {
    if (!locations) {
      getLocations();
    }
  }, [locations]);

  return (
    <section className="banner-section">
      <div
        className="background-eclipse"
        style={{
          flex: "none",
          height: "861px",
          left: "-228px",
          overflow: "visible",
          position: "absolute",
          top: "-254px",
          width: "2229px",
        }}
      >
        <div
          name="Ellipse 1"
          style={{
            background:
              "radial-gradient(33.333332382376064% 33.333332382376064% at 49.99999737637682% 49.9999990210614%,rgba(14,147,132,0) 0%,rgba(224,222,250,0) 100%)",
            borderRadius: "100%",
            flex: "none",
            height: "303px",
            left: "52px",
            position: "absolute",
            top: "127px",
            width: "548px",
          }}
        ></div>

        <div
          name="Ellipse 2"
          style={{
            background:
              "radial-gradient(36.51977163644526% 36.52729603343015% at 49.99999891162599% 62.00000055082716%,rgba(14,147,132,.21) 0%,rgba(224,222,250,0) 100%)",
            borderRadius: "100%",
            flex: "none",
            height: "861px",
            left: "557px",
            position: "absolute",
            top: "0",
            width: "1672px",
          }}
        ></div>

        <div
          name="Ellipse 3"
          style={{
            background:
              "radial-gradient(32.51977163644526% 32.52729603343015% at 49.99999891162599% 69.00000055082716%,rgba(14,147,132,.47) 0%,rgba(224,222,250,0) 100%)",
            borderRadius: "100%",
            flex: "none",
            height: "614px",
            left: "0",
            position: "absolute",
            top: "44px",
            width: "730px",
          }}
        ></div>
      </div>

      <div
        className="container"
        style={{
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div className="banner-wrapper">
          <div className="banner-content">
            <div
              className="content"
              style={{
                gap: "2.75rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h1 className="title" style={{ color: "black" }}>
                Easiest way to
                <span style={{ color: "var(--base)" }}> find </span> your next
                business{" "}
              </h1>
              <p
                style={{
                  whiteSpace: "pre-wrap",
                  maxWidth: "80%",
                  textAlign: "center",
                  fontWeight: "600",
                }}
              >
                Most searchers use a mix of looking at brokered deals and
                sourcing proprietary deal flow. Why not use a platform that does
                both.
              </p>
            </div>
            {/* <div className="dropdown-area">
              <Grid container display="flex" justifyContent="space-between">
                <Grid item xs={12} sm={3.5}>
                  <AutoComplete
                    keyToModify="location"
                    filterMap={filterMap}
                    setFilterMap={setFilterMap}
                    required={false}
                    error={false}
                    label="Search by Location"
                    locations={locations}
                  />
                </Grid>
                <Divider orientation="vertical" flexItem sx={DividerStyle} />
                <Grid item xs={12} sm={3.5}>
                  <AutoComplete
                    keyToModify="industries"
                    filterMap={filterMap}
                    setFilterMap={setFilterMap}
                    required={false}
                    error={false}
                    label="Search by Industry"
                    locations={Industries}
                  />
                </Grid>
                <Divider orientation="vertical" flexItem sx={DividerStyle} />
                <Grid item xs={12} sm={3.5} sx={{ textAlign: "center" }}>
                  <div className="btn-area">
                    <button
                      type="submit"
                      className="cmn-btn"
                      onClick={handleSubmit}
                    >
                      Browse Business
                    </button>
                  </div>
                </Grid>
              </Grid>
            </div> */}
          </div>
        </div>
        <Switches />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            marginBottom: "4rem",
          }}
        >
          <h3>backed by</h3>
          <img alt="techstar" src={techstar}></img>
        </div>
      </div>
    </section>
  );
};

export default Banner;
