import { Box, Grid } from "@mui/material";
import Footer from "../../components/Footer/Footer";
import {
  cancelSubscription,
  getCustomerSubscription,
} from "../../services/StripeService";
import { useEffect, useState } from "react";
const SubscriptionManagement = (props) => {
  // add API call to get user subscription status, change header and button depending on subscription status
  // if subscribed, include subscription info
  const [subscriptionInfo, setSubscriptionInfo] = useState();
  const [loading, setLoading] = useState(true);
  const [confirmSubCancel, setConfirmSubCancel] = useState(false);

  const getSubscriptionStatus = async () => {
    // the user doesn't have an associated customer object
    if (!('subscriptions' in props.userProfileData) || !props.userProfileData['subscriptions']['deal-scout']['customer-id']) {
      setLoading(false);
      return;
    }

    const response = await getCustomerSubscription(props.userProfileData['subscriptions']['deal-scout']['customer-id']);
    // the user doesn't have an active or trialing subscription
    if (response.length === 0) {
      setLoading(false);
      return;
    }

    // at this point, there is subscription info
    const subscription = response[0];

    // parse trial end or period end
    if (subscription.status === "trialing") {
      const trialEndDate = new Date(subscription.trial_end * 1000);
      const endMonth = trialEndDate.getMonth() + 1;
      const endDay = trialEndDate.getDate();
      subscription.trial_end = `${endMonth}/${endDay}`;
    } else {
      const periodEndDate = new Date(subscription.current_period_end * 1000);
      const endMonth = periodEndDate.getMonth() + 1;
      const endDay = periodEndDate.getDate();
      subscription.current_period_end = `${endMonth}/${endDay}`;
    }
    setSubscriptionInfo(subscription);
    setLoading(false);
  };

  const handleCancelSubscription = async () => {
    const cancelResponse = await cancelSubscription(subscriptionInfo.id);
    // if there is a response, then the cancellation was successful
    if (cancelResponse) {
    //   let updatedUserProfileData = props.userProfileData;
    //   updatedUserProfileData['subscriptions']['deal-scout']['status'] = 'inactive';
    //   await updateUserProfile(updatedUserProfileData);
      window.location.reload();
    }
  };

  const handleGoogleEvent = (buttonName) => {
    if (
      !window.location.host.includes("localhost") &&
      !window.location.host.includes("dev.onedeal.biz")
    ) {
      const gtagParamMap = {
        button: buttonName,
      };
      window.gtag("event", "button_click", gtagParamMap);
    }
  };

  useEffect(() => {
    if(props.userProfileData){
      getSubscriptionStatus();  
    }
    
  }, [props.userProfileData]);

  return (
    <Box>
      {!loading && (
        <Grid
          container
          direction={"column"}
          alignItems={"center"}
          gap={2}
          sx={{ padding: "0 1rem", textAlign: "center" }}
        >
          {subscriptionInfo ? (
            <>
              <h1 style={{ marginTop: "2rem" }}>You are subscribed.</h1>
              {subscriptionInfo.status === "trialing" ? (
                <p>
                  Your subscription trial will end on{" "}
                  {subscriptionInfo.trial_end}, and your account will be charged
                  $
                  {(
                    subscriptionInfo.items.data[0].price.unit_amount / 100
                  ).toFixed(2)}
                  .
                </p>
              ) : (
                <p>
                  Your subscription will renew on{" "}
                  {subscriptionInfo.current_period_end} for $
                  {(
                    subscriptionInfo.items.data[0].price.unit_amount / 100
                  ).toFixed(2)}
                  .
                </p>
              )}
              <div
                style={{
                  marginTop: "1rem",
                  display: "flex",
                  flexFlow: "column wrap",
                  gap: "2rem",
                }}
              >
                <a href="/deal-scout/checkout">
                  <button
                    className="cmn-btn"
                    onClick={() => handleGoogleEvent("change_subscription")}
                  >
                    Change Subscription
                  </button>
                </a>
                {!confirmSubCancel && (
                  <button
                    className="cmn-btn"
                    onClick={() => {
                      handleGoogleEvent("initiate_cancel_subscription");
                      setConfirmSubCancel(true);
                    }}
                  >
                    Cancel Subscription
                  </button>
                )}
              </div>
            </>
          ) : (
            <>
              <h1 style={{ marginTop: "2rem" }}>
              You are currently not subscribed.
              </h1>
              <a
                href="/deal-scout/checkout"
                onClick={() => handleGoogleEvent("checkout_subscription")}
              >
                <button className="cmn-btn" style={{ marginTop: "1rem" }}>
                  Subscribe
                </button>
              </a>
            </>
          )}
          {confirmSubCancel && (
            <>
              <p>Are you sure you want to cancel your subscription?</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "2rem",
                }}
              >
                <button
                  className="cmn-btn"
                  onClick={() => {
                    handleGoogleEvent("confirm-cancel-subscription");
                    handleCancelSubscription();
                  }}
                >
                  Yes
                </button>
                <button
                  className="cmn-btn"
                  onClick={() => {
                    handleGoogleEvent("abort-cancel-subscription");
                    setConfirmSubCancel(false);
                  }}
                >
                  No
                </button>
              </div>
            </>
          )}
        </Grid>
      )}
      <Footer />
    </Box>
  );
};

export default SubscriptionManagement;
