import {React, useState, useEffect} from "react";
import { Box, Grid } from '@mui/material';
import NavBar from "../../components/NavBar/NavBar";
import { useLocation } from 'react-router-dom';
import { retrieveUserAttributes, retrieveUserProfile, retrieveBuyerInfo,getS3Docs } from "../../services/UserManagementService";
import Avatar from '@mui/material/Avatar';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import { SnackBar } from '../../components/SnackBar/SnackBar';
import loadingGif from '../../assets/Rocket.gif'
import { useNavigate } from "react-router-dom";
import VerifiedIcon from '@mui/icons-material/Verified';
import Tooltip from '@mui/material/Tooltip';
import SetPageTitle from "../../components/SetPageTitle/SetPageTitle";

const avatarPhoto = {
    width: "5vw", 
    height:"5vw",
    margin:"auto", 
    marginBottom:"8px", 
    marginTop:"8px",
    '@media(max-Width: 768px)' : {
      width: "15vw", 
      height:"15vw",
    }
  }
  
  const avatarNoPhoto = { 
    bgcolor: "#0e9384", 
    width: "5vw", 
    height:"5vw", 
    margin:"auto", 
    marginBottom:"8px", 
    marginTop:"8px",
    '@media(max-Width: 768px)' : {
      width: "15vw", 
      height:"15vw",
    }
  }

const BuyerProfile = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [userAttributes, setUserAttributes] = useState();
    const [userProfileData, setUserProfileData] = useState();
    const [fundsDocuments, setFundsDocuments] = useState(false);
    const [buyerEmail, setBuyerEmail] = useState(new URLSearchParams(window.location.search).get('email'));
    const [buyerProfileData, setBuyerProfileData] = useState();
    const [errorMessage, setErrorMessage] = useState();

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const getUserAttributes = async() => {
        retrieveUserAttributes().then((data) => {
          if(data.errorMessage){
            setErrorMessage(data.errorMessage);
          }else{
            setUserAttributes(data.result)
          }
        });
    }

    const getUserProfile = async() => {
        retrieveUserProfile().then((response) => {
            if(response.errorMessage){
                setErrorMessage(response.errorMessage);
            }else{
                setUserProfileData(response);
            }
        });
    }

    const GetAvatar = () => {

        let initials = "";
        if(!buyerProfileData.avatar){
          if(buyerProfileData['first-name'] && buyerProfileData['last-name']){
            initials = buyerProfileData['first-name'][0] + buyerProfileData['last-name'][0];
          }else if(buyerProfileData['first-name'] && !buyerProfileData['last-name']){
            initials =  buyerProfileData['first-name'][0];
          }
        }
       
        return (
    
          <>
            {/* 1. if user has google pic, display that */}
            {/* {
              userAttributes.picture && <Avatar src={userAttributes.picture} sx={avatarPhoto} imgProps={{referrerPolicy:"no-referrer"}}/>
            } */}
            
            {/* 2. else if, user has userProfileData.avatar display that */}
            
            {
              buyerProfileData.avatar && <Avatar src={`${process.env.REACT_APP_S3_AVATARS_URL}${buyerProfileData.avatar}`} sx={avatarPhoto} imgProps={{referrerPolicy:"no-referrer"}}/>
            }
            
            {/* 3. else display initials */}
            {!buyerProfileData.avatar && <Avatar sx={avatarNoPhoto} src="">{initials}</Avatar>}
            
          </>
        );
      }

    const getBuyerInfo = async(buyerEmail) => {
        const response = await retrieveBuyerInfo(buyerEmail);
        if(response.Error){
            setErrorMessage(response.Error)
        }else{
            setBuyerProfileData(response);
        }

        const s3Response = await getS3Docs("onedeal-prequalification-documents", `${buyerEmail}/funds/`);
        if(s3Response.length){
            setFundsDocuments(true);
        }
    }

    useEffect(() => {
        if (!window.location.host.includes('localhost') && !window.location.host.includes('dev.onedeal.biz')) {
            window.gtag("event", "page_view", {
              page_path: location.pathname + location.search,
            });
        }
        if(localStorage.getItem("authorized") && !userAttributes){
          getUserAttributes();
        }else if(localStorage.getItem("authorized") && !userProfileData){
          getUserProfile();
        }else if(localStorage.getItem("authorized") && !buyerProfileData && buyerEmail){
            setIsLoading(true);
            getBuyerInfo(buyerEmail);
            setIsLoading(false)
        }else if(!localStorage.getItem("authorized")){
            navigate('/login');
        }
    }, [location, userAttributes, userProfileData, buyerProfileData, buyerEmail])

    return(
        <Box height="100%" position="relative">
        <SetPageTitle title="Buyer Profile" />
        <NavBar userAttributes={userAttributes} setUserAttributes={setUserAttributes} userProfileData={userProfileData}/>
        { isLoading && <Grid item sx={{textAlign:"center"}}><img alt='loading...' src={loadingGif}/></Grid> }
        <Grid container direction="row" justifyContent="center" alignContent="center" >      
            <Grid item xs={10} sm={5} sx={{textAlign:'center'}}>
                { userAttributes && buyerProfileData &&
                    <GetAvatar/>
                }
            </Grid>
            {
                buyerProfileData && 
                <Grid item xs={11} md={10} sx={{backgroundColor:"white", padding: "10px"}}>
                    <Grid item xs={12} sx={{textAlign:"center"}}>
                        <h3 style={{width:"100%", marginBottom: "10px"}}>{(buyerProfileData['first-name'] || buyerProfileData['last-name']) ? buyerProfileData['first-name'] + " " + buyerProfileData['last-name'] + "'s Profile" :  "Buyer Profile"}</h3>
                        {
                            ( (buyerProfileData['first-name'] && buyerProfileData['last-name'] &&  buyerProfileData.prequalification && fundsDocuments) || buyerProfileData['manual-verify'] === 'Y' ) &&
                            <>
                                <span style={{color: "#0e9384"}}>OneDeal Verified</span> 
                                <Tooltip title="Buyer has an avatar, first/last name, proof of funds, and prequalification questions.">
                                    <VerifiedIcon sx={{color: "#0e9384"}} />
                                </Tooltip>
                            </>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl sx={{marginBottom: "10px"}} >
                            <FormLabel id="demo-radio-buttons-group-label" sx={{fontWeight:"bold"}} >Are you self funded or backed by investors/firm?:</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue={buyerProfileData.prequalification.funding ? buyerProfileData.prequalification.funding : "self"}
                                name="funding"
                                // onChange={handleChange}
                            >
                                <FormControlLabel disabled={buyerProfileData.prequalification.funding !== "self"} value="self" control={<Radio sx={{ '&, &.Mui-checked': { color: '#0e9384', }, }}/>} label="Self Funded" />
                                <FormControlLabel disabled={buyerProfileData.prequalification.funding !== "backed"} value="backed" control={<Radio sx={{ '&, &.Mui-checked': { color: '#0e9384', }, }}/>} label="Backed by investors/firm" />
                            </RadioGroup>
                        </FormControl>

                        <Typography id="non-linear-slider" gutterBottom sx={{fontWeight:"bold", paddingTop:'1px'}}>
                            {"How much liquid capital do you have available for investment?:"}
                        </Typography>
                        <Typography id="non-linear-slider" gutterBottom sx={{fontWeight:"bold", paddingTop:'1px', textAlign:"center"}}>
                            {buyerProfileData.prequalification?.lowest_down_payment.toLocaleString('en-US') + " - $" + buyerProfileData.prequalification?.highest_down_payment.toLocaleString('en-US')}
                        </Typography>
                        
                        <div style={{textAlign:"center"}}>
                            <Slider
                                max={3000000}
                                min={0}
                                step={50000}
                                value={[buyerProfileData.prequalification?.lowest_down_payment, buyerProfileData.prequalification?.highest_down_payment]}
                                // onChange={handleDownPaymentUpdate}
                                sx={{color:"#0e9384", width:"85%", marginBottom: "10px"}}
                                size="small"
                            />
                        </div>

                        <FormControl sx={{ marginBottom: "10px"}}>
                            <FormLabel id="demo-radio-buttons-group-label" sx={{fontWeight:"bold"}} >Have you ever owned a buisness before?:</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue={buyerProfileData.prequalification.experience ? buyerProfileData.prequalification.experience : "N"}
                                name="experience"
                                // onChange={handleChange}
                            >
                                <FormControlLabel disabled={buyerProfileData.prequalification.experience !== "N"} value="N" control={<Radio sx={{ '&, &.Mui-checked': { color: '#0e9384', }, }}/>} label="No" />
                                <FormControlLabel disabled={buyerProfileData.prequalification.experience !== "Y"} value="Y"control={<Radio sx={{ '&, &.Mui-checked': { color: '#0e9384', }, }}/>} label="Yes" />
                            </RadioGroup>
                        </FormControl>

                        {
                            buyerProfileData.prequalification?.experience === "Y" &&
                                <TextField
                                id="experience_details"
                                label="Please provide details about the type, size, and your role in the business."
                                multiline
                                value={buyerProfileData.prequalification?.experience_details}
                                // onChange={(event) => props.setPrequalSettings({...buyerProfileData.prequalification, [event.target.id] : event.target.value})}
                                style={{width:"100%", marginBottom: "10px"}}
                                rows={2}
                            />
                        }

                        <Autocomplete
                            multiple
                            id="checkboxes-tags-demo"
                            // options={Industries}
                            defaultValue={buyerProfileData.prequalification?.industries}
                            freeSolo
                            disableCloseOnSelect
                            getOptionLabel={(option) => option}
                            // onChange={(event,newValue) => {
                            //     props.setPrequalSettings({...buyerProfileData.prequalification, industries : newValue});
                            // }}
                            disabled={true}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                    disabled={true}
                                />
                                {option}
                                </li>
                            )}
                            style={{marginBottom:"10px" }}
                            renderInput={(params) => (
                                <TextField {...params} label={"Industries of interest"} placeholder="Industries..." />
                            )}
                            />

                        <TextField
                            id="timeframe"
                            label="What is your desired timeframe for acquiring a business?"
                            multiline
                            value={buyerProfileData.prequalification?.timeframe}
                            // onChange={(event) => {
                            //     props.setPrequalSettings({...buyerProfileData.prequalification, [event.target.id] : event.target.value});
                            // }}
                            style={{width:"100%", marginBottom: "10px"}}
                            rows={2}
                            disabled={true}
                            // error={props.checkQuestionnaire && !buyerProfileData.prequalification?.timeframe } 
                        />
                        
                        {
                            buyerProfileData.prequalification?.prequalified === "Y" &&
                            <TextField
                                id="experience_details"
                                label="Please provide details about your financing."
                                multiline
                                value={buyerProfileData.prequalification?.prequalified_details}
                                // onChange={(event) => props.setPrequalSettings({...buyerProfileData.prequalification, [event.target.id] : event.target.value})}
                                style={{width:"100%", marginBottom: "10px"}}
                                rows={2}
                            />
                        }
                    
                        <FormControl sx={{ marginBottom: "10px"}}>
                            <FormLabel id="demo-radio-buttons-group-label" sx={{fontWeight:"bold"}} >Are you looking for a passive investment, or do you plan to be actively involved in the day-to-day operations?:</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue={buyerProfileData.prequalification?.investment_type ? buyerProfileData.prequalification?.investment_type : "passive"}
                                name="investment_type"
                                // onChange={handleChange}
                            >
                                <FormControlLabel disabled={buyerProfileData.prequalification.investment_type !== "passive"} value="passive" control={<Radio sx={{ '&, &.Mui-checked': { color: '#0e9384', }, }}/>} label="Passive" />
                                <FormControlLabel disabled={buyerProfileData.prequalification.investment_type !== "active"} value="active"control={<Radio sx={{ '&, &.Mui-checked': { color: '#0e9384', }, }}/>} label="Active" />
                            </RadioGroup>
                        </FormControl>

                        <Typography id="non-linear-slider" gutterBottom sx={{fontWeight:"bold", paddingTop:'1px'}}>
                            {"Investment range?:" }
                        </Typography>
                        <Typography id="non-linear-slider" gutterBottom sx={{fontWeight:"bold", paddingTop:'1px', textAlign:"center"}}>
                            {buyerProfileData.prequalification?.lowest_investment_range.toLocaleString('en-US') + " - $" + buyerProfileData.prequalification?.highest_investment_range.toLocaleString('en-US')}
                        </Typography>
                        
                        <div style={{textAlign:"center"}}>
                            <Slider
                                max={3000000}
                                min={0}
                                step={50000}
                                value={[buyerProfileData.prequalification?.lowest_investment_range, buyerProfileData.prequalification?.highest_investment_range]}
                                // onChange={handleInvestmentRange}
                                sx={{color:"#0e9384", width:"85%"}}
                                size="small"
                            />
                        </div>
                    </Grid>
                </Grid>
            }    
        </Grid>
        {errorMessage && <SnackBar errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>}
        </Box>
    )
}

export default BuyerProfile;