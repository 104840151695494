import { React, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import {
  retrieveUserAttributes,
  retrieveUserProfile,
} from "../../services/UserManagementService";
import "../DealScoutLandingPage/DealScoutLandingPage.css";
import NavBar from "../../components/NavBar/NavBar";
import SetPageTitle from "../../components/SetPageTitle/SetPageTitle";
import ResultNumbers from "../DealScoutLandingPage/ResultNumbers";

import Footer from "../../components/Footer/Footer";
import brokers from "../../assets/Landing/brokers.webp";
import DealScoutCard from "../DealScoutLandingPage/DealScoutCard";
import search from "../../assets/DealScout/search.webp";
import source from "../../assets/DealScout/source.webp";
import track from "../../assets/DealScout/track.webp";
import connect from "../../assets/DealScout/connect.webp";
import Action from "../DealScoutLandingPage/Action";
const Brokers = () => {
  const [userAttributes, setUserAttributes] = useState();
  const [userProfileData, setUserProfileData] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const location = useLocation();

  const BrokersData = [
    {
      p: "Find proprietary deals specifically in the SME space that fit your search criteria",
      n: "17M+",
      h: "Lower Middle Market Companies ",
    },
    {
      p: "Connect directly with business owners and key decision makers ",
      n: "50M+",
      h: "Email Contacts",
    },
    {
      p: "Use our  search  to find businesses specifically tailored to your search criteria. These include location, industry, employee size, estimated revenue, and  more",
      n: "15+",
      h: "Data Points",
    },
  ];
  const labelsData = [
    {
      position: "left",
      img: search,
      chip: "Versatile Search",
      title: "Find businesses ready to come to market",
      paragraph:
        "Identity the best lower middle market businesses that fit  your typical profile of business owners you want to represent ",
      note_1: "Basic info",
      note_2: "Search by location, keywords and categories",
      note_3: "Advanced info",
      note_4: "Narrow your search by employee counts and estimated revenue",
    },
    {
      position: "left",
      img: track,
      chip: "Efficient Management",
      title: "Create more Pipeline ",
      paragraph:
        " Instead of wasting countless hours searching online for businesses, spend more time  doing the task you love: closing  deals! ",
      note_1: "Always in sync",
      note_2: "Don't worry about the data, always be synchronized",
      note_3: "Keep status noted",
      note_4: "Keep the progress in mind through each update",
    },
  ];

  const numbersData = [
    {
      position: "right",
      img: connect,
      chip: "Strong Connection",
      title: "Connect directly with business owners ",
      paragraph:
        "Find contact information on over 30M  business owners. Get access to their phone numbers and email addresses.",
      number_1: "17M+",
      note_1: "Lower Middle Market Companies",
      number_2: "50M+",
      note_2: "Email Contacts",
    },
    {
      position: "right",
      img: connect,
      chip: "Meet Qualified Buyers",
      title: "List your new businesses on our marketplace ",
      paragraph:
        "Connect with over 1,000 pre-vetted buyers coming from Private Equity, Search Funds, and Experience Self Funded Buyers",
      number_1: "1k+",
      note_1: "Pre-Qualified Buyers",
      number_2: "75%",
      note_2: "Conversion Rate",
    },
  ];

  const getUserAttributes = async () => {
    retrieveUserAttributes().then((response) => {
      if (response.errorMessage) {
        setErrorMessage(response.errorMessage);
      } else {
        setUserAttributes(response.result);
      }
    });
  };

  const getUserProfile = async () => {
    retrieveUserProfile().then((response) => {
      if (response.errorMessage) {
        setErrorMessage(response.errorMessage);
      } else {
        setUserProfileData(response);
      }
    });
  };
  useEffect(() => {
    if (localStorage.getItem("authorized") && !userAttributes) {
      getUserAttributes();
    } else if (localStorage.getItem("authorized") && !userProfileData) {
      getUserProfile();
    }

    if (
      !window.location.host.includes("localhost") &&
      !window.location.host.includes("dev.onedeal.biz")
    ) {
      window.gtag("event", "page_view", {
        page_path: location.pathname + location.search,
      });
    }
  }, [userAttributes, userProfileData, location]);
  return (
    <div>
      <SetPageTitle title="Brokers" />
      <NavBar
        userAttributes={userAttributes}
        setUserAttributes={setUserAttributes}
        userProfileData={userProfileData}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "0.75rem",
          marginTop: "5rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2>
            Easiest way to
            <span style={{ color: "#0E9384" }}> find and market </span>
            your next deal
          </h2>
        </div>
        <p
          style={{ textAlign: "center", maxWidth: "50%", marginBottom: "3rem" }}
        >
          There are over 17M lower middle market business owners awaiting to be
          contacted by you. Get started by entering your email below.
        </p>

        <button
          className="listingDetails-btn"
          style={{ width: "10rem" }}
          onClick={() => {
            window.open("/deal-scout/checkout", "_blank");
          }}
        >
          Get Started
        </button>

        <img
          src={brokers}
          style={{ maxWidth: "70%", margin: "4rem 0 4rem 0 " }}
          alt="hero"
        ></img>
        <ResultNumbers data={BrokersData} showHeader={true} />
      </div>
      <section
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          margin: "4rem 0",
          gap: "8rem",
        }}
      >
        <DealScoutCard labelsData={labelsData} numbersData={numbersData} />
      </section>
      <Action />
      <Footer />
    </div>
  );
};

export default Brokers;
