
import { React, useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';
import FAQComponent from './FAQComponent';
import { retrieveUserAttributes, retrieveUserProfile } from '../../services/UserManagementService';
import { SnackBar } from '../../components/SnackBar/SnackBar';
import NavBar from '../../components/NavBar/NavBar';
import Footer from '../../components/Footer/Footer';
import SetPageTitle from '../../components/SetPageTitle/SetPageTitle';
const FrequentlyAskQuestions = () => {

    const location = useLocation();
    const [userAttributes, setUserAttributes] = useState();
    const [userProfileData, setUserProfileData] = useState();
    const [successMessage, setSuccessMessage] = useState();
    const [errorMessage, setErrorMessage] = useState();

    const getUserAttributes = async() => {
        retrieveUserAttributes().then((data) => {
          if(data.errorMessage){
            setErrorMessage(data.errorMessage);
          }else{
            setUserAttributes(data.result)
          }
        });
    }
    
    const getUserProfile = async() => {
      retrieveUserProfile().then((response) => {
        if(response.errorMessage){
          setErrorMessage(response.errorMessage);
        }else{
          setUserProfileData(response);
        }
      });
    }

    useEffect(() => {
        if (!window.location.host.includes('localhost') && !window.location.host.includes('dev.onedeal.biz')) {
            window.gtag("event", "page_view", {
              page_path: location.pathname + location.search,
            });
        }
        if(localStorage.getItem("authorized") && !userAttributes){
          getUserAttributes();
        }else if(localStorage.getItem("authorized") && !userProfileData){
          getUserProfile();
        }
    }, [location, userAttributes, userProfileData])

  return (
    <Box height="100%" position="relative">
      <SetPageTitle title="FAQ" />
        <NavBar userAttributes={userAttributes} setUserAttributes={setUserAttributes} userProfileData={userProfileData}/>
        <Grid container direction="row" justifyContent="center" height="100%" marginTop="10px">      
            <Grid item xs={10} sm={8}>
                <FAQComponent /> 
            </Grid>
        </Grid>
        <Footer/>
        {(errorMessage || successMessage) && <SnackBar errorMessage={errorMessage} setErrorMessage={setErrorMessage} successMessage={successMessage} setSuccessMessage={setSuccessMessage}/>}    
    </Box>
  );
}

export default FrequentlyAskQuestions;