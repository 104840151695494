import { React } from 'react';
import { Grid, InputAdornment } from '@mui/material';
import LocationSearch from '../LocationSearch';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import '../../../pages/Landing/App.scss'

 const theme = createTheme({
    typography: {
      body1: {
        fontFamily:"'Plus Jakarta Sans', sans-serif",
      }
    }
  })

const FilterDesktop = (props) => {

    const handleFilterUpdate = (event) => {
        const targetId = (event.target.id) ? event.target.id : event.target.name;
        props.setFilterMap({...props.filterMap, [targetId] : event.target.value});
    }   

    return(
        <ThemeProvider theme={theme}>
        <Grid container direction="row" justifyContent="center" alignItems="center" mt="8px" mb="8px">
            <Grid item xs={10} sx={{display:"flex",position:"relative", justifyContent:"space-between"}}>
                <Grid item xs={2.75} sx={{backgroundColor:"white", borderRadius : "10px"}}>
                    <LocationSearch infoMap={props.filterMap} setInfoMap={props.setFilterMap} required={false} error={false} label="Search by city or state" locations={props.locations}/>
                </Grid>
                <Grid item xs={7} sx={{backgroundColor:"white", borderRadius : "10px"}}>
                    <TextField id="search_phrase" 
                        label="Search Businesses For Sale By Keywords ..." 
                        variant="outlined" 
                        sx={{width:"100%", '& .MuiOutlinedInput-root': { borderRadius: "10px"}}}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='start'>
                                    <SearchIcon />
                                </InputAdornment>
                            )
                        }}
                        onChange={handleFilterUpdate}
                        onKeyDown={(e) => {if(e.code === 'NumpadEnter' || e.code === 'Enter'){props.getFilteredListings()}}}
                        value={props.filterMap.search_phrase}
                    />
                </Grid>
                <Grid item xs={1.25}>
                    <button onClick={() => props.getFilteredListings()} variant="contained" className='listingDetails-btn'>Search</button>
                </Grid>
                <Grid item xs={.75} sx={{fontWeight:"bold",textAlign:"center"}}>
                    <p style={{margin:"0px", paddingTop:"5px"}}>{props.numberOfListings}</p>
                    <p style={{margin:"0px"}}>Results</p>
                </Grid>
            </Grid>
        </Grid>
        </ThemeProvider>
    )
};

export default FilterDesktop;