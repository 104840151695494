import { React, useState, useEffect } from "react";
import { Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
// import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import PermPhoneMsgOutlinedIcon from "@mui/icons-material/PermPhoneMsgOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import LanguageIcon from "@mui/icons-material/Language";
const dividerStyle = {
  top: "272px",
  left: "154px",
  width: "1096px",
  height: "2px",
  backgroundColor: "#f0f0f0",
  borderRadius: "2px",
};
const assignStatusColor = (status) => {
  let color = "";
  switch (status) {
    case "Not Contacted":
      color = "#ffeeaa";
      break;
    // case 'Contacted':
    //     break;
    case "Responded":
      color = "#c8edfd";
      break;
    case "Not Interested":
      color = "#ffbeba";
      break;
    case "Call Scheduled":
      color = "#c3f2cb";
      break;
    case "Letter of Intent":
      color = "#91eaa0";
      break;
    default:
      color = "#ffffff";
  }
  return color;
};
const BusinessDisplay = (props) => {
  const [state, setState] = useState(false);
  const toggleDrawer = (anchor, open) => (event) => {
    setState({ state, [anchor]: open });
  };

  const moneyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const list = (anchor) => (
    <Box
      sx={{
        fontFamily: "Montserrat",
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "start",
          gap: "1.5rem",
          padding: "3.75rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <div style={{ fontSize: "1.5rem", fontWeight: "500" }}>
            {" "}
            {props.name}{" "}
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          {props.display_phone && (
            <div className="detail-page-icon">
              <PermPhoneMsgOutlinedIcon />{" "}
              {props.display_phone ? (
                <span style={{ color: "#0e9384" }}>{props.display_phone}</span>
              ) : (
                <span>/</span>
              )}
            </div>
          )}
          {(props.street || props.city || props.state) && (
            <div className="detail-page-icon">
              <LocationOnOutlinedIcon /> {props.street && `${props.street}, `}
              {props.city && `${props.city}, `}
              {props.state}
            </div>
          )}
          {props.website && (
            <div className="detail-page-icon">
              <LanguageIcon />{" "}
              <a href={props.website} target="_blank" rel="noopener noreferrer">
                {props.website ? (
                  <span style={{ color: "#0e9384" }}>{props.website}</span>
                ) : (
                  <span>/</span>
                )}{" "}
              </a>
            </div>
          )}
          {/*LinkedIn  <div className="detail-page-icon">
            <LinkedInIcon />
            {props.location.display_address}
          </div> */}
        </div>
        {
          (props.latitude && props.longtitude) &&
          <iframe
          title={props.name}
          width="95%"
          style={{ border: "0", borderRadius: "10px" }}
          loading="lazy"
          allowFullScreen
          referrerPolicy="no-referrer-when-downgrade"
          src={
            `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=` +
            `${props.latitude},${props.longtitude}`
          }
        ></iframe>}
        {(props.estimated_opened_for_business ||
          props.verified_on ||
          props.mailing_address ||
          props.mailing_address_city ||
          props.mailing_address_state ||
          props.mailing_address_postal_code ||
          props.location_email_address ||
          props.primary_contact ||
          props.job_titles) && (
          <div
            id="list"
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "1.5rem",
                fontWeight: "500",
                margin: "2rem",
                color: "#0e9384",
              }}
            >
              Detailed Information
            </p>
            <div
              className="detail-list"
              style={{ width: "100%", fontSize: "0.85rem" }}
            >
              {props.estimated_opened_for_business && (
                <div className="detail-list-item">
                  <div className="detail-list-item-title">
                    Opened for Business Date
                  </div>
                  <span>{props.estimated_opened_for_business}</span>
                </div>
              )}
              {props.verified_on && (
                <div className="detail-list-item">
                  <div className="detail-list-item-title">
                    Business Date Verified On
                  </div>
                  <span>{props.verified_on}</span>
                </div>
              )}
              {props.mailing_address && (
                <div className="detail-list-item">
                  <div className="detail-list-item-title">
                    Business Address{" "}
                  </div>
                  <span>{props.mailing_address}</span>
                </div>
              )}
              {props.mailing_address_city && (
                <div className="detail-list-item">
                  <div className="detail-list-item-title">Business City </div>
                  <span>{props.mailing_address_city}</span>
                </div>
              )}
              {props.mailing_address_state && (
                <div className="detail-list-item">
                  <div className="detail-list-item-title">Business State </div>
                  <span>{props.mailing_address_state}</span>
                </div>
              )}
              {props.mailing_address_postal_code && (
                <div className="detail-list-item">
                  <div className="detail-list-item-title">
                    Business Postcode{" "}
                  </div>
                  <span>{props.mailing_address_postal_code}</span>
                </div>
              )}
              {props.location_email_address && (
                <div className="detail-list-item">
                  <div className="detail-list-item-title">Email Address</div>
                  <span>{props.location_email_address}</span>
                </div>
              )}
              {props.primary_contact && (
                <div className="detail-list-item">
                  <div className="detail-list-item-title">
                    Primary Contact Name
                  </div>
                  <span>
                    {props.primary_contact.first_name}{" "}
                    {props.primary_contact.last_name}
                  </span>
                </div>
              )}
              {props.job_titles && (
                <div className="detail-list-item">
                  <div className="detail-list-item-title">
                    {" "}
                    Primary Contact Title
                  </div>
                  <span>{props.primary_contact.job_titles}</span>
                </div>
              )}
            </div>
            {(props.location_employee_count ||
              props.estimated_location_employee_count ||
              props.estimated_corporate_employee_count ||
              props.estimated_corporate_sales_revenue ||
              props.corporate_sales_revenue) && (
              <div
                className="detail-list"
                style={{ width: "100%", fontSize: "0.8rem" }}
              >
                <p
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "500",
                    margin: "2rem",
                    color: "#0e9384",
                    textAlign: "center",
                  }}
                >
                  Financial Information
                </p>
                {props.location_employee_count && (
                  <div className="detail-list-item">
                    <div className="detail-list-item-title">Employee Count</div>
                    <span>{props.location_employee_count.toLocaleString('en-US')}</span>
                  </div>
                )}
                {props.estimated_location_employee_count && (
                  <div className="detail-list-item">
                    <div className="detail-list-item-title">
                      Estimated Location Employee Count
                    </div>
                    <span>{props.estimated_location_employee_count.toLocaleString('en-US')}</span>
                  </div>
                )}
                {props.estimated_corporate_employee_count && (
                  <div className="detail-list-item">
                    <div className="detail-list-item-title">
                      Estimated Corporate Employee Count
                    </div>
                    <span>{props.estimated_corporate_employee_count.toLocaleString('en-US')}</span>
                  </div>
                )}
                {props.estimated_corporate_sales_revenue && (
                  <div className="detail-list-item">
                    <div className="detail-list-item-title">
                      Estimated Corporate Sales Revenue
                    </div>
                    <span>{moneyFormatter.format(props.estimated_corporate_sales_revenue).replace(".00", "")}</span>
                  </div>
                )}

                {props.corporate_sales_revenue && (
                  <div className="detail-list-item">
                    <div className="detail-list-item-title">
                      Reported Corporate Sales Revenue
                    </div>
                    <span>{moneyFormatter.format(props.corporate_sales_revenue).replace(".00", "")}</span>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        {/*<div id="list">
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <p style={{ fontSize: "1.5rem", fontStyle: "bold" }}>
              Financial Information
            </p>
          </div>
          <div className="detail-list">
            <div className="detail-list-item">
              <div className="detail-list-item-title">
                Location Employee Count
              </div>
              <div>{props.estimated_corporate_employee_count}asdf</div>
            </div>
            <div className="detail-list-item">
              <div className="detail-list-item-title">
                Corporate Employee Count
              </div>
              <div>{props.estimated_corporate_employee_count}asdf</div>
            </div>
            <div className="detail-list-item">
              <div className="detail-list-item-title">
                Corporate Sales Revenue
              </div>
              <div>{props.estimated_corporate_sales_revenue}asdf</div>
            </div>
          </div>
          </div>*/}
      </div>
    </Box>
  );
  const [selectStatusDropDown, setSelectStatusDropDown] = useState(
    props.status ? props.status : "Action Item"
  );
  const [statusColor, setStatusColor] = useState(
    assignStatusColor(props.status)
  );
  const handleSelect = (event, listKey, bizKey) => {
    setSelectStatusDropDown(event.target.value);
    setStatusColor(assignStatusColor(event.target.value));
    props.handleStatusSelect(event, listKey, bizKey);
  };
  return (
    <>
      <Divider orientation="horizontal" sx={dividerStyle} />
      <Grid item xs={10} sx={{ display: "flex", paddingBottom: "16px" }}>
        {/* <Grid item xs={1}>
          <Avatar
            alt={props.alias}
            src={props.image_url}
            sx={{ width: 56, height: 56 }}
          /> 
        </Grid> */}
        <Grid item xs={4}>
          <Grid item xs={12}>
            <span className="text">{props.name}</span>
          </Grid>
          <Grid item xs={12}>
            <span className="text">
              {props.street && `${props.street}, `}
              {props.city && `${props.city}, `}
              {props.state}
            </span>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid item xs={12}>
            <a
              className="deal-scout-link"
              target="_blank"
              rel="noopener noreferrer"
              href={props.website}
            >
              Website
            </a>
          </Grid>
          <Grid item xs={12}>
            <span className="text">{props.display_phone}</span>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <div onClick={toggleDrawer("right", true)}>
            <span className="deal-scout-span-link">More Details</span>
          </div>
        </Grid>
        {/* <Grid item xs={3}>
          {props.categories.map((categoryChip) => {
            return (
              <Chip
                label={categoryChip.title}
                size="small"
                variant="outlined"
                sx={{ backgroundColor: "black", color: "white" }}
              />
            );
          })}
        </Grid> */}
        <Drawer
          PaperProps={{
            sx: { width: "32.5rem" },
          }}
          anchor="right"
          open={state.right}
          onClose={toggleDrawer("right", false)}
        >
          {list("right")}
        </Drawer>
        <Grid item>
          <FormControl sx={{ m: 1, minWidth: "100%" }}>
            <InputLabel id="demo-simple-select-helper-label">
              Select A Saved Campaign
            </InputLabel>
            <Select
              //   labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={selectStatusDropDown}
              label="Select A Saved Campaign Or Create A New One"
              onChange={(e) => handleSelect(e, props.listKey, props.bizKey)}
              sx={{ borderRadius: "24px", backgroundColor: statusColor }}
            >
              <MenuItem value="Action Item">Action Item</MenuItem>
              <MenuItem value="Not Contacted">Not Contacted</MenuItem>
              <MenuItem value="Contacted">Contacted</MenuItem>
              <MenuItem value="Responded">Responded</MenuItem>
              <MenuItem value="Not Interested">Not Interested</MenuItem>
              <MenuItem value="Call Scheduled">Call Scheduled</MenuItem>
              <MenuItem value="Letter of Intent">Letter of Intent</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};
export default BusinessDisplay;
