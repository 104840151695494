export const States = [
    "New York", 
    "California", 
    "Illinois", 
    "Florida", 
    "Texas", 
    "Pennsylvania", 
    "Georgia", 
    "District of Columbia", 
    "Massachusetts", 
    "Arizona", 
    "Michigan", 
    "Washington", 
    "Minnesota", 
    "Colorado", 
    "Maryland", 
    "Nevada", 
    "Missouri", 
    "Oregon", 
    "Puerto Rico", 
    "Ohio", 
    "Indiana", 
    "North Carolina", 
    "Virginia", 
    "Wisconsin", 
    "Rhode Island", 
    "Utah", 
    "Tennessee", 
    "Kentucky", 
    "Louisiana", 
    "Connecticut", 
    "Oklahoma", 
    "Hawaii", 
    "Nebraska", 
    "New Mexico", 
    "Alabama", 
    "South Carolina", 
    "Iowa", 
    "Kansas", 
    "Arkansas", 
    "Idaho", 
    "Mississippi", 
    "New Jersey", 
    "Alaska", 
    "New Hampshire", 
    "Maine", 
    "North Dakota", 
    "West Virginia", 
    "South Dakota", 
    "Delaware", 
    "Montana", 
    "Vermont", 
    "Wyoming", 
];