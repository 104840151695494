import React from "react";
import { HashLink } from "react-router-hash-link";

import img1 from "../../assets/Landing/pax.webp";
import img2 from "../../assets/Landing/smartbiz-logo.png";
import img3 from "../../assets/Landing/img3.webp";

const data = [img1, img2, img3];
const Partner = () => {
  return (
    <section className="partner-section pt-5">
      <div className="container">
        <h3 style={{ textAlign: "center", marginBottom: "3rem" }}>
          Our Partners
        </h3>
        <div className="partner-wrapper">
          {data?.map((item, i) => (
            <img src={item} alt="" />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Partner;
