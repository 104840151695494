import { React, useState, useEffect } from "react";
import { Grid, InputAdornment } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import {
  upsertDealScoutSearch,
  searchForBusinesses,
} from "../../../services/DealScout";
import SearchIcon from "@mui/icons-material/Search";
import CategoryDisplay from "../CategoryDisplay";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import { constructDataAxleRequest } from "../HelperFunctions";
import GeoLocation from "./GeoLocation";
import { SnackBar } from "../../../components/SnackBar/SnackBar";
import { updateUserProfile } from "../../../services/UserManagementService";
import LinearProgress from "@mui/joy/LinearProgress";

const Scouts = (props) => {
  //state managment
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorEmployees, setErrorEmployees] = useState(false);
  const [errorRevenue, setErrorRevenue] = useState(false);
  const [displayNewScout, setDisplayNewScout] = useState(false);
  const [selectScoutDropDown, setSelectScoutDropDown] = useState("");
  const [locationError, setLocationError] = useState(false);
  const [searchMap, setSearchMap] = useState({
    name: "",
    keywords: "",
    location: "",
    location_description: "",
    mile_radius: 50,
    lowest_employees: "",
    highest_employees: "",
    lowest_estimated_sales_revenue: "",
    highest_estimated_sales_revenue: "",
    categories: [],
    limit: 10,
  });

  const isBadRequest = () => {
    let foundError = false;
    if (!searchMap.location) {
      setErrorMessage("A search location is required.");
      setLocationError(true);
      foundError = true;
    } else if (
      parseInt(searchMap.lowest_employees) &&
      parseInt(searchMap.highest_employees) &&
      parseInt(searchMap.lowest_employees) >=
        parseInt(searchMap.highest_employees)
    ) {
      setErrorMessage(
        "Higest amount of employees but be less than the lowest amount."
      );
      setErrorEmployees(true);
      foundError = true;
    } else if (
      parseInt(searchMap.lowest_estimated_sales_revenue) &&
      parseInt(searchMap.highest_estimated_sales_revenue) &&
      parseInt(searchMap.lowest_estimated_sales_revenue) >=
        parseInt(searchMap.highest_estimated_sales_revenue)
    ) {
      setErrorMessage("Higest revenue but be less than the lowest revenue.");
      setErrorRevenue(true);
      foundError = true;
    }
    // check to see if the users search will exceed their credits
    // trial, package 1,package 2, package 3
    else if (
      props.userProfileData["subscriptions"]["deal-scout"]["credits-used"] +
        searchMap.limit >
        props.userProfileData["subscriptions"]["deal-scout"]["credit-limit"]
    ) {
      setErrorMessage("You don't have enough credits to complete this scout.");
      foundError = true;
    } else if (
      props.userProfileData["subscriptions"]["deal-scout"]["status"] ===
      "inactive"
    ) {
      setErrorMessage("Please renew your subscription.");
      foundError = true;
    }
    return foundError;
  };

  //handler functions

  const handleLimit = (event) => {
    if (
      (parseInt(event.target.value) >= 0 &&
        parseInt(event.target.value) <= 400) ||
      !parseInt(event.target.value)
    ) {
      setSearchMap({ ...searchMap, limit: event.target.value });
    }
  };

  const handleRevenueLowest = (event) => {
    if (parseInt(event.target.value) >= 0 || !parseInt(event.target.value)) {
      setSearchMap({
        ...searchMap,
        lowest_estimated_sales_revenue: event.target.value,
      });
    }
  };

  const handleRevenueHighest = (event) => {
    if (parseInt(event.target.value) >= 0 || !parseInt(event.target.value)) {
      setSearchMap({
        ...searchMap,
        highest_estimated_sales_revenue: event.target.value,
      });
    }
  };

  const handleEmployeeCountLowest = (event) => {
    if (parseInt(event.target.value) >= 0 || !parseInt(event.target.value)) {
      setSearchMap({ ...searchMap, lowest_employees: event.target.value });
    }
  };

  const handleEmployeeCountHighest = (event) => {
    if (parseInt(event.target.value) >= 0 || !parseInt(event.target.value)) {
      setSearchMap({ ...searchMap, highest_employees: event.target.value });
    }
  };

  const handleScoutSelect = (event) => {
    setSelectScoutDropDown(event.target.value);
    if (event.target.value in props.usersDealScouts.scoutData.scouts) {
      setSearchMap(props.usersDealScouts.scoutData.scouts[event.target.value]);
    }
  };

  const handleSearchParamChange = (event) => {
    setSearchMap({ ...searchMap, [event.target.id]: event.target.value });
  };

  const handleGeoLocationChange = (description, coordinateMap) => {
    setSearchMap({
      ...searchMap,
      location_description: description,
      location: coordinateMap,
    });
  };

// iterate through list of documents returned from DataAxle
// and for each document take a total of the amount of keys
// then sort by most keys from top to bottom
const sortByTotalDataFieldsDesc = (resultsToSort) => {
  // Custom comparator function to compare maps based on the number of keys
  const compareMaps = (map1, map2) => Object.keys(map2).length - Object.keys(map1).length;

  // Sort the list of maps using the custom comparator
  const sortedList = resultsToSort.slice().sort(compareMaps);

  return sortedList;
}

  const getSearchResults = async (searchParams) => {

    if(!isBadRequest()){
      let result = await searchForBusinesses(constructDataAxleRequest(searchParams));
      result = sortByTotalDataFieldsDesc(result.documents);
      props.setSearchResults(result);
      
      //here we check for user's credits and update them
      //also check dealScout subscription status (trial | active | inactive)
      // assume the subscription status is set
      if (
        props.userProfileData["subscriptions"]["deal-scout"]["status"] !==
        "inactive"
      ) {
        let currentUserProfile = props.userProfileData;
        let currentCredits = currentUserProfile['subscriptions']['deal-scout']['credits-used'] + result.length;
        currentUserProfile['subscriptions']['deal-scout']['credits-used'] = currentCredits;

        await updateUserProfile(currentUserProfile);
        props.setUserProfileData(currentUserProfile);
      }
      props.setDrawComponent("searchResults");
    }
  };

  const getPercentageOfCredits = () => {
    let percent = 0;
    percent = props.userProfileData["subscriptions"]["deal-scout"][
      "credits-used"
    ]
      ? (props.userProfileData["subscriptions"]["deal-scout"]["credits-used"] /
          props.userProfileData["subscriptions"]["deal-scout"][
            "credit-limit"
          ]) *
        100
      : 0;
    percent = percent < 5 && percent > 0 ? 5 : percent;
    return percent;
  };

  useEffect(() => {}, [searchMap]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Grid item xs={5}>
        {/* <h1>Create A New Search</h1> */}
        <h1 style={{ color: "black", marginTop: "3rem" }}>Deal Scout</h1>
      </Grid>
      <Grid item xs={5}>
        {props.userProfileData && (
          <LinearProgress
            determinate
            size="md"
            variant="soft"
            thickness={32}
            value={getPercentageOfCredits()}
            sx={{
              backgroundColor: "#8DCAC3",
              color: "#0e9384",
              width: "100%",
              marginTop: "3rem",
            }}
          >
            <Typography
              level="body3"
              fontWeight="xl"
              // textColor="white"
              sx={{ mixBlendMode: "color-dodge", color: "white" }}
            >
              {props.userProfileData["subscriptions"]["deal-scout"][
                "credit-limit"
              ] -
                props.userProfileData["subscriptions"]["deal-scout"][
                  "credits-used"
                ] +
                " Credits Left"}
            </Typography>
          </LinearProgress>
        )}
      </Grid>
      {!displayNewScout && (
        <Grid
          item
          xs={5}
          sx={{ backgroundColor: "white", borderRadius: "10px" }}
        >
          <FormControl sx={{ minWidth: "100%" }}>
            <InputLabel id="demo-simple-select-helper-label">
              Select A Saved Scout Or Create A New One
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={selectScoutDropDown}
              label="Select A Saved Scout Or Create A New One"
              onChange={handleScoutSelect}
              sx={{ borderRadius: "24px" }}
            >
              <MenuItem value="" onClick={() => setDisplayNewScout(true)}>
                Create New Scout
              </MenuItem>
              {props.usersDealScouts &&
                "scoutData" in props.usersDealScouts &&
                Object.keys(props.usersDealScouts.scoutData.scouts).map(
                  (key) => {
                    return (
                      <MenuItem value={key}>
                        {props.usersDealScouts.scoutData.scouts[key].name}
                      </MenuItem>
                    );
                  }
                )}
            </Select>
          </FormControl>
        </Grid>
      )}
      {displayNewScout && (
        <>
          <Grid item xs={5}>
            <TextField
              id="name"
              label="Enter Your Saved Search Name Here!"
              variant="outlined"
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": { borderRadius: "24px" },
              }}
              onChange={handleSearchParamChange}
              value={searchMap.name}
            />
          </Grid>
        </>
      )}
      <Grid item xs={5}>
        <button
          onClick={async () =>
            await upsertDealScoutSearch({
              scoutId: selectScoutDropDown,
              scoutBody: searchMap,
            })
          }
          style={{ width: "100%" }}
          variant="contained"
          className="listingDetails-btn"
        >
          Save This Search
        </button>
      </Grid>
      <Grid item xs={5} sx={{ backgroundColor: "white", borderRadius: "24px" }}>
        <h3 style={{ color: "black", margin: "3rem 0 1rem 0" }}>Location</h3>
        <GeoLocation
          handleGeoLocationChange={handleGeoLocationChange}
          locationError={locationError}
          location={searchMap.location}
          location_description={searchMap.location_description}
        />
      </Grid>
      <Grid item xs={5}>
        <h3 style={{ color: "black", margin: "3rem 0 1rem 0" }}>Mile Radius</h3>
        <FormControl sx={{ width: "100%" }}>
          {/* <FormLabel id="demo-radio-buttons-group-label">Mile Radius</FormLabel> */}
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            id="mile_radius"
            defaultValue="female"
            name="radio-buttons-group"
            value={searchMap.mile_radius}
            onChange={handleSearchParamChange}
            sx={{ justifyContent: "space-evenly" }}
          >
            <FormControlLabel
              value="50"
              control={
                <Radio
                  id="mile_radius"
                  sx={{ "&, &.Mui-checked": { color: "#0e9384" } }}
                />
              }
              label="50"
            />
            <FormControlLabel
              value="100"
              control={
                <Radio
                  id="mile_radius"
                  sx={{ "&, &.Mui-checked": { color: "#0e9384" } }}
                />
              }
              label="100"
            />
            <FormControlLabel
              value="150"
              control={
                <Radio
                  id="mile_radius"
                  sx={{ "&, &.Mui-checked": { color: "#0e9384" } }}
                />
              }
              label="250"
            />
            <FormControlLabel
              value="200"
              control={
                <Radio
                  id="mile_radius"
                  sx={{ "&, &.Mui-checked": { color: "#0e9384" } }}
                />
              }
              label="500"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={5} sx={{ backgroundColor: "white", borderRadius: "10px" }}>
        <h3 style={{ color: "black", margin: "3rem 0 1rem 0" }}>Keywords</h3>
        <TextField
          id="keywords"
          label='"Laundromat", "HVAC", "Car Wash"'
          variant="outlined"
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": { borderRadius: "24px" },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleSearchParamChange}
          // onKeyDown={(e) => {if(e.code === 'NumpadEnter' || e.code === 'Enter'){props.getFilteredListings()}}}
          value={searchMap.keywords}
        />
      </Grid>
      <Grid item xs={5}>
        <h3 style={{ color: "black", margin: "3rem 0 1rem 0" }}>Categories</h3>
        <CategoryDisplay searchMap={searchMap} setSearchMap={setSearchMap} />
      </Grid>
      <Grid item xs={5} sx={{ fontSize: "4vw", textAlign: "center" }}>
        <h3 style={{ color: "black", margin: "3rem 0 1rem 0" }}>
          Number of Employees
        </h3>
        <Grid item xs={12} display="Flex" justifyContent="space-evenly">
          <TextField
            id="employee-low"
            label="Min Employees"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              handleEmployeeCountLowest(e);
            }}
            value={searchMap.lowest_employees}
            error={errorEmployees}
          />
          <Typography
            id="non-linear-slider"
            gutterBottom
            sx={{
              fontSize: "12.25px",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
            }}
          >
            to
          </Typography>
          <TextField
            id="employee-high"
            label="Max Employees"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              handleEmployeeCountHighest(e);
            }}
            value={searchMap.highest_employees}
            error={errorEmployees}
          />
        </Grid>
      </Grid>
      <Grid item xs={5} sx={{ fontSize: "4vw", textAlign: "center" }}>
        <h3 style={{ color: "black", margin: "3rem 0 1rem 0" }}>
          Estimated Revenue
        </h3>
        <Grid item xs={12} display="Flex" justifyContent="space-evenly">
          <TextField
            id="revenue-low"
            label="Min Revenue"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => handleRevenueLowest(e)}
            value={searchMap.lowest_estimated_sales_revenue}
            error={errorRevenue}
          />
          <Typography
            id="non-linear-slider"
            gutterBottom
            sx={{
              fontSize: "12.25px",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
            }}
          >
            to
          </Typography>
          <TextField
            id="revenue-high"
            label="Max Revenue"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => handleRevenueHighest(e)}
            value={searchMap.highest_estimated_sales_revenue}
            error={errorRevenue}
          />
        </Grid>
      </Grid>
      <Grid item xs={5} sx={{ fontSize: "4vw", textAlign: "center" }}>
        <h3 style={{ color: "black", margin: "0 0 1rem 0" }}>
          Search Results Limit
        </h3>
        <TextField
          id="limit"
          label="Limit"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => handleLimit(e)}
          value={searchMap.limit}
          sx={{ width: "100%" }}
        />
      </Grid>
      <Grid item xs={5}>
        <button
          onClick={() => getSearchResults(searchMap)}
          variant="contained"
          className="listingDetails-btn"
          style={{ marginTop: "3rem", width: "100%" }}
        >
          Search
        </button>
      </Grid>

      {errorMessage && (
        <SnackBar
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )}
    </Grid>
  );
};

export default Scouts;
