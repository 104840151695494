import { React, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { retrieveUserAttributes } from "../../services/UserManagementService";
import { retrieveUserProfile } from "../../services/UserManagementService";
import Footer from "../../components/Footer/Footer";
import SetPageTitle from "../../components/SetPageTitle/SetPageTitle";
import "../DealScoutLandingPage/DealScoutLandingPage.css";
import DealScoutCard from "../DealScoutLandingPage/DealScoutCard";
import Carousel from "../DealScoutLandingPage/Carousel";
import ResultNumbers from "../DealScoutLandingPage/ResultNumbers";
import heroimage from "../../assets/DealScout/heroImg.webp";
import logo from "../../assets/DealScout/logo1.png";
import search from "../../assets/DealScout/search.webp";
import source from "../../assets/DealScout/source.webp";
// import campaign from "../../assets/DealScout/campaign.png";
import track from "../../assets/DealScout/track.webp";
import connect from "../../assets/DealScout/connect.webp";
import Action from "../DealScoutLandingPage/Action";
import NavBar from "../../components/NavBar/NavBar";

const ProprietaryDeals = () => {
  const [userAttributes, setUserAttributes] = useState();
  const [userProfileData, setUserProfileData] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const location = useLocation();
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const handlePrevClick = () => {
    setCurrentCardIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : prevIndex
    );
  };
  const handleNextClick = () => {
    setCurrentCardIndex((prevIndex) =>
      prevIndex < 2 ? prevIndex + 1 : prevIndex
    );
  };
  const backButton = (
    <svg
      className="carouselBtn"
      xmlns="http://www.w3.org/2000/svg"
      width="85"
      height="85"
      viewBox="0 0 85 85"
      fill="none"
      onClick={() => {
        handlePrevClick();
      }}
    >
      <g clip-path="url(#clip0_500_43)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M66 42.0014C66 42.797 65.684 43.56 65.1214 44.1225C64.5589 44.6851 63.7959 45.0011 63.0003 45.0011H28.2462L41.1268 57.8757C41.4057 58.1546 41.6269 58.4857 41.7778 58.8501C41.9288 59.2145 42.0065 59.605 42.0065 59.9994C42.0065 60.3939 41.9288 60.7844 41.7778 61.1488C41.6269 61.5132 41.4057 61.8443 41.1268 62.1232C40.8479 62.4021 40.5168 62.6233 40.1524 62.7743C39.788 62.9252 39.3974 63.0029 39.003 63.0029C38.6086 63.0029 38.218 62.9252 37.8536 62.7743C37.4892 62.6233 37.1582 62.4021 36.8793 62.1232L18.8813 44.1252C18.6019 43.8466 18.3803 43.5155 18.2291 43.1511C18.0778 42.7867 18 42.396 18 42.0014C18 41.6069 18.0778 41.2162 18.2291 40.8518C18.3803 40.4873 18.6019 40.1563 18.8813 39.8777L36.8793 21.8797C37.4425 21.3164 38.2065 21 39.003 21C39.7996 21 40.5635 21.3164 41.1268 21.8797C41.69 22.4429 42.0065 23.2069 42.0065 24.0035C42.0065 24.8 41.69 25.564 41.1268 26.1272L28.2462 39.0018H63.0003C63.7959 39.0018 64.5589 39.3178 65.1214 39.8804C65.684 40.4429 66 41.2059 66 42.0014Z"
          fill="var(--dsbtn)"
        />
      </g>
      <defs>
        <clipPath id="clip0_500_39">
          <rect
            width="85"
            height="85"
            fill="white"
            transform="matrix(-1 0 0 1 85 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );

  const forwardButton = (
    <svg
      className="carouselBtn"
      xmlns="http://www.w3.org/2000/svg"
      width="85"
      height="85"
      viewBox="0 0 85 85"
      fill="none"
      onClick={() => {
        handleNextClick();
      }}
    >
      <g clip-path="url(#clip0_500_39)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18 42.0014C18 42.797 18.316 43.56 18.8786 44.1225C19.4411 44.6851 20.2041 45.0011 20.9997 45.0011H55.7538L42.8732 57.8757C42.5943 58.1546 42.3731 58.4857 42.2222 58.8501C42.0712 59.2145 41.9935 59.605 41.9935 59.9994C41.9935 60.3939 42.0712 60.7844 42.2222 61.1488C42.3731 61.5132 42.5943 61.8443 42.8732 62.1232C43.1521 62.4021 43.4832 62.6233 43.8476 62.7743C44.212 62.9252 44.6026 63.0029 44.997 63.0029C45.3914 63.0029 45.782 62.9252 46.1464 62.7743C46.5108 62.6233 46.8418 62.4021 47.1207 62.1232L65.1187 44.1252C65.3981 43.8466 65.6197 43.5155 65.7709 43.1511C65.9222 42.7867 66 42.396 66 42.0014C66 41.6069 65.9222 41.2162 65.7709 40.8518C65.6197 40.4873 65.3981 40.1563 65.1187 39.8777L47.1207 21.8797C46.5575 21.3164 45.7935 21 44.997 21C44.2004 21 43.4365 21.3164 42.8732 21.8797C42.31 22.4429 41.9935 23.2069 41.9935 24.0035C41.9935 24.8 42.31 25.564 42.8732 26.1272L55.7538 39.0018H20.9997C20.2041 39.0018 19.4411 39.3178 18.8786 39.8804C18.316 40.4429 18 41.2059 18 42.0014Z"
          fill="var(--dsbtn)"
        />
      </g>
      <defs>
        <clipPath id="clip0_500_39">
          <rect
            width="85"
            height="85"
            fill="white"
            transform="matrix(-1 0 0 1 85 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
  const labelsData = [
    {
      position: "left",
      img: search,
      chip: "Versatile Search",
      title: "Source proprietary deals right for you",
      paragraph:
        "Find businesses directly associated with your search criteria. Filter from over 17M lower middle market  companies that fall into your thesis guidelines. ",
      note_1: "Basic info",
      note_2: "Search by location, keywords and categories",
      note_3: "Advanced info",
      note_4: "Narrow your search by employee counts and estimated revenue",
    },
    {
      position: "right",
      img: track,
      chip: "Efficient Management",
      title: "Track your Outreach",
      paragraph:
        "Keep track of potential deals by using tags to update outreach progress",
      note_1: "Always in sync",
      note_2: "Don't worry about the data, always be synchronized",
      note_3: "Keep status noted",
      note_4: "Keep the progress in mind through each update",
    },
  ];

  const listData = {
    position: "right",
    img: source,
    chip: "Powerful Database",
    title: "Access to company's data",
    paragraph:
      "Data points on company's description, contact information, revenue, employee count, location, year founded, and many more",
    points: [
      "Location",
      "Primary contact",
      "Revenue",
      "Year Founded",
      "Employee Count",
      "Contact Information",
    ],
  };

  const numbersData = [
    {
      position: "left",
      img: connect,
      chip: "Strong Connection",
      title: "Create Campaigns",
      paragraph: "Use the CRM function to keep save searches and lists",
      number_1: "17M+",
      note_1: "Lower Middle Market Companies",
      number_2: "50M+",
      note_2: "Email Contacts",
    },
  ];

  const ProprietaryDealsData = [
    {
      p: "Find proprietary deals specifically in the SME space that fit your search criteria",
      h: "Estimated Sales Revenue ",
    },
    {
      p: "Connect directly with business owners and key decision makers ",
      h: "Employee Count",
    },
    {
      p: "Use our  search  to find businesses specifically tailored to your search criteria. These include location, industry, employee size, estimated revenue, and  more",
      h: "Estimated Revenue",
    },
  ];
  const navigate = useNavigate();
  const getUserAttributes = async () => {
    retrieveUserAttributes().then((response) => {
      if (response.errorMessage) {
        setErrorMessage(response.errorMessage);
      } else {
        setUserAttributes(response.result);
      }
    });
  };

  const getUserProfile = async () => {
    retrieveUserProfile().then((response) => {
      if (response.errorMessage) {
        setErrorMessage(response.errorMessage);
      } else {
        setUserProfileData(response);
      }
    });
  };
  useEffect(() => {
    if (localStorage.getItem("authorized") && !userAttributes) {
      getUserAttributes();
    } else if (localStorage.getItem("authorized") && !userProfileData) {
      getUserProfile();
    }

    if (
      !window.location.host.includes("localhost") &&
      !window.location.host.includes("dev.onedeal.biz")
    ) {
      window.gtag("event", "page_view", {
        page_path: location.pathname + location.search,
      });
    }
  }, [userAttributes, userProfileData, location]);
  return (
    <div style={{ overflowX: "hidden" }}>
      <SetPageTitle title="Find Proprietary Deals" />
      <NavBar
        userAttributes={userAttributes}
        setUserAttributes={setUserAttributes}
        userProfileData={userProfileData}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          marginTop: "5rem",
        }}
      >
        <section
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "4rem",
            maxWidth: "90%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "3rem",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
              }}
            >
              <h1>
                Find your next{" "}
                <span style={{ color: "#0e9384" }}>off market</span> deal
              </h1>
              <p>
                Use our proprietary search engine to access over 17.5M
                off-market businesses with relevant data points.
              </p>
              <button
                className="listingDetails-btn"
                style={{ width: "10rem" }}
                onClick={() => {
                  navigate("/deal-scout/checkout");
                }}
              >
                Get Started
              </button>
            </div>
            <img
              style={{ maxWidth: "40rem" }}
              src={heroimage}
              alt="hero image"
            />
          </div>
        </section>
        <section
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "auto",
            alignItems: "center",
            marginTop: "3rem",
          }}
        >
          <p>backed by</p>
          <img
            style={{ height: "2rem", marginLeft: "0.5rem" }}
            src={logo}
            alt="hero image"
          />
        </section>
        <section className="backgroundImage">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "2rem",
              padding: "10rem 0 3rem 0",
            }}
          >
            <h1 style={{ fontSize: "128px", color: "white" }}>17,825,036</h1>
            <h2 style={{ color: "white" }}>
              Lower Middle Market businesses available
            </h2>
          </div>
        </section>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            marginTop: "5rem",
            gap: "3rem",
          }}
        >
          <h2 style={{ margin: "2rem 0" }}>Our Data Points Include</h2>
          <ResultNumbers data={ProprietaryDealsData} showHeader={true} />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <section
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            margin: "4rem 0",
          }}
        >
          {/* <h2 style={{ margin: "4rem" }}>Our Process</h2> */}
          <DealScoutCard
            labelsData={labelsData}
            listData={listData}
            numbersData={numbersData}
          />
        </section>
        <section
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            maxWidth: "77%",
          }}
        >
          <h2 style={{ margin: "6rem 0 4rem 0 " }}>Find your deal </h2>
        </section>
        <section
          style={{
            maxWidth: "77%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {" "}
          <div
            style={{
              margin: "4rem 0 4rem 0",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "1152px",
            }}
          >
            <h2>Hear from our users</h2>
            <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
              {backButton}
              {forwardButton}
            </div>
          </div>
        </section>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignContent: "center",
            alignItems: "flex-start",
            maxWidth: "100%",
            overflowX: "auto",
            marginLeft: "20rem",
          }}
        >
          <Carousel currentCardIndex={currentCardIndex} />
        </div>
      </div>

      <Action />
      <Footer />
    </div>
  );
};

export default ProprietaryDeals;
