import React from "react";

const Numbers = ({ props }) => {
  return (
    <div>
      {props.position === "left" ? (
        <section className="cardSection">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              width: "40%",
            }}
          >
            <div className="chip">{props.chip}</div>
            <div className="cardDiv">
              <h3>{props.title}</h3>
              <p>{props.paragraph}</p>
            </div>
            <hr />
            <div>
              <span style={{ fontSize: "2rem", color: "#0E9384" }}>
                {props.number_1}
              </span>{" "}
              <span style={{ color: "#000" }}>{props.note_1}</span>
            </div>
            <div>
              <span style={{ fontSize: "2rem", color: "#0E9384" }}>
                {props.number_2}
              </span>{" "}
              <span style={{ color: "#000" }}>{props.note_2}</span>
            </div>
          </div>
          <img src={props.img} className="cardImg" alt="" />
        </section>
      ) : (
        <section className="cardSection">
          <img src={props.img} className="cardImg" alt="" />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              width: "40%",
            }}
          >
            <div className="chip">{props.chip}</div>
            <div className="cardDiv">
              <h3>{props.title}</h3>
              <p>{props.paragraph}</p>
            </div>
            <hr />
            <div>
              <span style={{ fontSize: "2rem", color: "#0E9384" }}>
                {props.number_1}
              </span>{" "}
              <span style={{ color: "#000" }}>{props.note_1}</span>
            </div>
            <div>
              <span style={{ fontSize: "2rem", color: "#0E9384" }}>
                {props.number_2}
              </span>{" "}
              <span style={{ color: "#000" }}>{props.note_2}</span>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default Numbers;
