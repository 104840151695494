import { React, useState } from 'react';
import { Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import UploadImage from '../../components/UploadImage/UploadImage';
import { Industries, ListingType } from '../../helperData/ListingInfoData';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Footer from '../../components/Footer/Footer';

const listingInformationContainer = {
  padding: "4px", 
  mt:"8px", 
  borderRadius: "4px", 
  boxShadow:" 0 2px 4px -1px rgba(0,0,0,0.25)", 
  backgroundColor:"white",  
  height:"fit-content", 
  marginTop:"auto",
  '@media(max-Width: 768px)' : {
    marginTop: "11px"
  }
}

export const ListingInformation = (props) => {
  
  const [invalidMap, setInvalidMap] = useState({});
  const [requireImage, setRequireImage] = useState(false);

  const listingInfoReqKeys = ['industry', 'type', 'price', 'headline', 'description'];
  const handleValidateListingInfo = () => {
    if (!window.location.host.includes('localhost') && !window.location.host.includes('dev.onedeal.biz')) {
      window.gtag("event", "button_click", {
        button: "post_listing_info"
      });
    }
    let validationMap = {};
    let isValid = true;
    for(let x = 0; x< listingInfoReqKeys.length; x++){
      if(props.businessDetails[listingInfoReqKeys[x]] === null || props.businessDetails[listingInfoReqKeys[x]] === ""){
        validationMap[listingInfoReqKeys[x]] = true;
        isValid = false;
      }else{
        validationMap[listingInfoReqKeys[x]] = false;
      }
    }
    setInvalidMap(validationMap);
    if(!props.businessDetails['image-title']){
      setRequireImage(true);
      isValid = false;
    }else{
      setRequireImage(false);
    }
    props.setValidListing(isValid);
  }

    return(
        <>
          <Grid container direction="row" justifyContent="center" height="100%">
            <Grid item xs={10} sm={6} sx={listingInformationContainer}>
              <h3>Listing Information</h3>
              <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                <Grid item xs={11} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="type-label">Type of Listing</InputLabel>
                    <Select
                      error={invalidMap.type}
                      labelId="type-label"
                      id="type"
                      value={props.businessDetails.type}
                      label="Type"
                      onChange={props.handleType}
                      style={{width:"100%"}}
                      >
                    {
                        ListingType.map((item) => {
                          return <MenuItem key={item} value={item}>{item}</MenuItem>;
                        })
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={11} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="industry-label">Industry</InputLabel>
                    <Select
                      error={invalidMap.industry}
                      labelId="industry-label"
                      id="industry"
                      value={props.businessDetails.industry}
                      label="Industry"
                      onChange={props.handleIndustry}
                      style={{width:"100%"}}
                    >
                      {
                        Industries.map((item) => {
                          return <MenuItem value={item}>{item}</MenuItem>;
                        })
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={11} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="outlined-adornment-amount">Asking Price</InputLabel>
                    <OutlinedInput
                      error={invalidMap.price}
                      id="price"
                      value={props.businessDetails.price}
                      onChange={props.handleNumberUpdates}
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      label="Asking Price"
                      style={{width:"100%"}}
                      type="number"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={11} sm={6}>
                  <TextField
                    error={invalidMap.headline}
                    required
                    id="headline"
                    label="Headline"
                    variant="outlined"
                    onChange={props.handleFieldUpdate}
                    style={{width:"100%"}}
                    value={props.businessDetails.headline}
                  />
                </Grid>
                <Grid item xs={11}>
                  <TextField
                    error={invalidMap.description}
                    required
                    id="description"
                    label="Tell us about your business"
                    multiline
                    value={props.businessDetails.description}
                    onChange={props.handleFieldUpdate}
                    style={{width:"100%"}}
                    rows={2}
                  />
                </Grid>
                <Grid item xs={12}>
                  <UploadImage requireImage={requireImage} handleImageUpdate={props.handleImageUpdate} businessDetails={props.businessDetails}/>
                </Grid>
                <Grid item xs={12} >
                  <FormGroup sx={{alignContent:"center"}}>
                    <FormControlLabel control={<Switch id="financing" defaultChecked onChange={props.handleFinancing}/>} label="Financing Available" />
                  </FormGroup>
                </Grid>
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={10} sm={6}>
              <Button onClick={handleValidateListingInfo} variant="contained" sx={{backgroundColor: "#0e9384", mt:"8px",mr:"5px", float:"right",borderRadius:"10px"}}>Next</Button>
              <Button onClick={() => props.setValidAddress(false)} variant="contained" sx={{backgroundColor: "#E5E4E2", color:"#0e9384", mt:"8px",mr:"5px", float:"right",borderRadius:"10px"}}>Previous</Button>
            </Grid>
        </Grid>
        </Grid>
        <Footer/>
      </>
    );
}