export const fetchFavoritesFromOpenSearch =  (listingIds) => {
    const openSearchRequest = {
        "query": {
          "bool": {
            "must": [
              {
                "terms": {
                  "listing-id": listingIds
                }
              }
            ]
          }
        },
        "sort": [
          {
            "request-time": {
              "order": "desc",
              "unmapped_type" : "long"
            }
          }
        ]
      }
    return openSearchRequest;
}