import { React } from 'react';
import { Grid } from '@mui/material';
import Footer from '../../../components/Footer/Footer';
import SavedSearchCard from './SavedSearchCard';
import { HashLink } from 'react-router-hash-link';
import Button from '@mui/material/Button';

  const savedSearchContainer = {
    direction: "row",
    justifyContent : "flex-start",
    '@media(max-Width: 768px)' : {
        textAlign: "center",
        position: "inherit",
        marginBottom: "56px",
        justifyContent: "center"
    }

  }

const SavedSearches = (props) => {
    return(
        <>
            <Grid container direction="row" justifyContent="center">
                <Grid container direction="row" justifyContent="center">
                    <Grid item sx={{textAlign:"center"}}>
                        <HashLink to="/saved-business-search">
                            <Button variant="contained" sx={{backgroundColor: "#0e9384", mt:"8px", mb:"8px", fontWeight:"bold",borderRadius:"10px"}}>Create a new Saved Search</Button>
                        </HashLink>
                    </Grid>
                </Grid>
                <Grid container sx={savedSearchContainer}>
                {
                    props.userSavedSearches && 
                    Object.keys(props.userSavedSearches).map((savedSearchKey) => {
                        return(
                            <SavedSearchCard 
                                searchKey={savedSearchKey}
                                data={props.userSavedSearches[savedSearchKey]}
                                setUserSavedSearches={props.setUserSavedSearches}
                                setErrorMessage={props.setErrorMessage}
                            />
                        )
                    })
                }
                </Grid>
            </Grid>
            <Footer />
        </>
    );
};

export default SavedSearches;