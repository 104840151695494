import {React, useState, useEffect} from "react";
import { useLocation } from "react-router-dom";
import { retrieveAllBrokers, retrieveUserAttributes, retrieveUserProfile} from "../../services/UserManagementService";
import {Grid } from '@mui/material';
import { SnackBar } from "../../components/SnackBar/SnackBar";
import NavBar from "../../components/NavBar/NavBar";
import Footer from "../../components/Footer/Footer";
import BrokerCard from "../../components/BrokerCard/BrokerCard";
import SetPageTitle from "../../components/SetPageTitle/SetPageTitle";
import TakeTheTour from "../../components/TakeTheTour/TakeTheTour";

const BrowseBrokers = () => {
    const [brokers, setBrokers] = useState([]);
    const [userAttributes, setUserAttributes] = useState();
    const [userProfileData, setUserProfileData] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const location = useLocation();
    const [tourSteps, setTourSteps] = useState(location?.state?.showTour);    
    const [brokerProfileInDom, setBrokerProfileInDom] = useState(false);
    
    const waitForElement = async(selector) => {
        const element = document.querySelector(selector);
        return new Promise((resolve) => {
            if (document.querySelector(selector)) {
                return resolve(element);
            }
            const observer = new MutationObserver(() => {
            if (element) {
                resolve(element);
                observer.disconnect();
            }
            });
            observer.observe(document.body, {
            childList: true,
            subtree: true,
            });
        }).catch(error => {
            // Code to handle the error
            console.error('An error occurred during BrowseBrokers:', error);
          });  ;
        }


    const getUserAttributes = async() => {
        retrieveUserAttributes().then((response) => {
            if(response.errorMessage){
                setErrorMessage(response.errorMessage);
            }else{
                setUserAttributes(response.result)
            }
        });
    }

    const getBrokers = async() => {
        retrieveAllBrokers().then((response) => {
            if(response.Error){
                setErrorMessage("Couldn't retrieve broker data");
            }else{
                setBrokers(response.Items)
            }
        });
    }

    const getUserProfile = async() => {
        retrieveUserProfile().then((response) => {
          if(response.errorMessage){
            setErrorMessage(response.errorMessage);
          }else{
            setUserProfileData(response);
          }
        });
      }

    const generateBrokerCards = (brokers) => {
        let brokerCards = [];
        brokerCards = brokers.map((currentBroker) => {
            return(
                <div id={currentBroker.email.replace("@","").replaceAll(".","")} style={{marginBottom:'10px'}}>
                    <BrokerCard 
                        name={currentBroker['first-name'] && currentBroker['last-name'] ? currentBroker['first-name'] + ' ' + currentBroker['last-name'] : 'Broker Profile'}
                        email={currentBroker.email}
                        phone={currentBroker.phone}
                        extension={currentBroker.extension}
                        avatar={currentBroker.avatar}
                    />
                </div>
            )
        });
        return brokerCards;
    }

    const showTheTour = () => {
        let userBrokerId = '#' + userProfileData.email.replace('@', '').replaceAll('.', '');
        waitForElement(userBrokerId).then(() => {
            setBrokerProfileInDom(true);
        });
    }
    useEffect(() => {
        if (!window.location.host.includes('localhost') && !window.location.host.includes('dev.onedeal.biz')) {
            window.gtag("event", "page_view", {
                page_path: location.pathname + location.search,
            });
        }

        if(localStorage.getItem("authorized") && !userAttributes){
			getUserAttributes();
		}else if(localStorage.getItem("authorized") && !userProfileData){
			getUserProfile();
		}

        if(!brokers.length){
            getBrokers();
        }

        if(!brokerProfileInDom && tourSteps && userProfileData){
            showTheTour();
        }

    }, [location, brokers, userAttributes, userProfileData, tourSteps])
    return(
        <>
        <SetPageTitle title="Broker Directory" />
        <NavBar userAttributes={userAttributes} setUserAttributes={setUserAttributes} userProfileData={userProfileData}/>
        <Grid container direction="row" justifyContent="center" alignItems="start" spacing={2} marginTop="10px">
            { brokers && generateBrokerCards(brokers) }
        </Grid>
        {userProfileData && brokerProfileInDom && <TakeTheTour userEmail={userProfileData.email} isBroker={userProfileData['is-broker']}/>}
        <Footer />
        {errorMessage && <SnackBar errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>}
        </>
    )
}

export default BrowseBrokers;