import { React, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cta from "./Cta";
import FinancialFuture from "./FinancialFuture";
import Partner from "./Partner";
import TrendingBusiness from "./TrendingBusiness";
import WhyOneDeal from "./WhyOneDeal";
import Banner from "./Banner";
import "./App.scss";
import NavBar from "../../components/NavBar/NavBar";
import Footer from "../../components/Footer/Footer";
import {
  googleAuthentication,
  retrieveUserAttributes,
  retrieveUserProfile,
} from "../../services/UserManagementService";
import SetPageTitle from "../../components/SetPageTitle/SetPageTitle";
import Links from "./Links";
import Action from "../DealScoutLandingPage/Action";

const Landing = () => {
  const [userAttributes, setUserAttributes] = useState();
  const [userProfileData, setUserProfileData] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  // google
  const search = useLocation().search;
  const [authCode, setAuthCode] = useState(
    new URLSearchParams(search).get("code")
  );

  const getGoogleToken = async () => {
    const googleAuthResponse = await googleAuthentication(authCode, "login");
    setAuthCode(null);
    if (googleAuthResponse === "User not found") {
      navigate("/signup");
    } else {
      navigate("/businesses-for-sale", {
        state: googleAuthResponse["has-logged-in"],
      });
    }
  };

  const getUserAttributes = async () => {
    retrieveUserAttributes().then((response) => {
      if (response.errorMessage) {
        setErrorMessage(response.errorMessage);
      } else {
        setUserAttributes(response.result);
      }
    });
  };

  const getUserProfile = async () => {
    retrieveUserProfile().then((response) => {
      if (response.errorMessage) {
        setErrorMessage(response.errorMessage);
      } else {
        setUserProfileData(response);
      }
    });
  };

  useEffect(() => {
    if (localStorage.getItem("authorized") && !userAttributes) {
      getUserAttributes();
    } else if (localStorage.getItem("authorized") && !userProfileData) {
      getUserProfile();
    }

    if (authCode) {
      getGoogleToken();
    }

    if (
      !window.location.host.includes("localhost") &&
      !window.location.host.includes("dev.onedeal.biz")
    ) {
      window.gtag("event", "page_view", {
        page_path: location.pathname + location.search,
      });
    }
  }, [location, userAttributes, userProfileData]);

  return (
    <>
      <SetPageTitle title="Home" />
      <NavBar
        userAttributes={userAttributes}
        setUserAttributes={setUserAttributes}
        userProfileData={userProfileData}
      />
      <Banner />
      <Partner />
      {/* <TrendingBusiness userAttributes={userAttributes} /> */}
      <FinancialFuture />
      <WhyOneDeal />
      <Links />
      {/* <Cta /> */}
      <Action />
      <Footer />
    </>
  );
};

export default Landing;
