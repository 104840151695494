import { React, useState } from 'react';
import { Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Footer from '../../components/Footer/Footer';
import { States } from '../../helperData/LocationData';
import Autocomplete from '@mui/material/Autocomplete';
import { MuiTelInput } from 'mui-tel-input'

const businessAddressContainerStyler = {
  padding: "4px", 
  borderRadius: "4px", 
  boxShadow:" 0 2px 4px -1px rgba(0,0,0,0.25)", 
  backgroundColor:"white", height:"fit-content",
  marginTop:"auto",
  '@media(max-Width: 768px)' : {
    marginTop: "11px"
  }
}

export const BusinessAddress = (props) => {
  const [invalidMap, setInvalidMap] = useState({});

  const businessAddressReqKeys = ['state', 'contact-email'];
  const handleValidateBusinessAddress = () => {
    if (!window.location.host.includes('localhost') && !window.location.host.includes('dev.onedeal.biz')) {
      window.gtag("event", "button_click", {
        button: "post_listing_address"
      });
    }
    let validationMap = {};
    let isValid = true;
    for(let x = 0; x< businessAddressReqKeys.length; x++){
      if(!props.businessDetails[businessAddressReqKeys[x]]){
        validationMap[businessAddressReqKeys[x]] = true;
        isValid = false;
      }else{
        validationMap[businessAddressReqKeys[x]] = false;
      }
    }
    setInvalidMap(validationMap);
    props.setValidAddress(isValid);
  }

    return(
      <>
        <Grid container direction="row" justifyContent="center" height="100%">
        <Grid id="post-a-business" item xs={10} md={6} sx={businessAddressContainerStyler}>
          <h3>Business Address</h3>
          <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} >
           <Grid item xs={11} sm={6}>
              <TextField
                id="address"
                label="Street Address"
                variant="outlined"
                autoComplete="street-address"
                onChange={props.handleFieldUpdate}
                style={{width:"100%"}}
                value={props.businessDetails.address}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                id="apartment"
                label="Apartment Number"
                type="number"
                variant="outlined"
                autoComplete="address-level2"
                onChange={props.handleFieldUpdate}
                sx={{width:"100%"}}
                value={props.businessDetails.apartment}
              />
            </Grid>
            <Grid item xs={11} sm={6} sx={{display:"flex", justifyContent:"space-between"}}>
              <Grid item xs={5.9}>
                <TextField
                  error={invalidMap['city']}
                  id="city"
                  label="City"
                  variant="outlined"
                  autoComplete="address-level2"
                  onChange={props.handleFieldUpdate}
                  style={{width:"100%"}}
                  value={props.businessDetails['city']}
                />
              </Grid>
              <Grid item xs={5.9}>
                <Autocomplete
                  disablePortal
                  id="state"
                  options={States}
                  autoHighlight={true}
                  autoComplete={true}
                  onInputChange={(event, value) => props.handleStateUpdate(value)}
                  renderInput={(params) => <TextField {...params}  
                      required
                      error={invalidMap['state']}
                      label="State"
                      variant="outlined"
                      autoComplete="address-level1"
                      style={{width:"100%"}}
                    />}
                />
              </Grid>
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                id="zipcode"
                label="Zipcode"
                type="number"
                variant="outlined"
                autoComplete="postal-code"
                onChange={props.handleNumberUpdates}
                sx={{width:"100%"}}
                value={props.businessDetails.zipcode}
                />
            </Grid>
            <Grid item xs={11} sm={6}>
              <TextField
                required
                error={invalidMap['contact-email']}
                id="contact-email"
                label="Email Address"
                variant="outlined"
                autoComplete="username"
                onChange={props.handleFieldUpdate}
                style={{width:"100%"}}
                value={props.businessDetails['contact-email']}
              />
            </Grid>
            <Grid item xs={11} sm={6}>
              <MuiTelInput
                id="phone"
                label="Phone Number"
                variant="outlined"
                autoComplete="tel"
                onChange={props.handlePhoneUpdate}
                sx={{width:"100%"}}
                value={props.businessDetails.phone}
                defaultCountry='US'
              />
            </Grid>
          </Grid> 
        </Grid>
        <Grid container direction="row" justifyContent="center" height="fit-content">
          <Grid item xs={11} sm={6}>
            <Button onClick={handleValidateBusinessAddress} variant="contained" sx={{backgroundColor: "#0e9384", float:"right",borderRadius:"10px"}}>Next</Button>
          </Grid>
        </Grid>
      </Grid>
      <Footer/>
      </>
    );
}