import { React, useState, useEffect} from 'react';
import { Grid } from '@mui/material';
import "../DealScout.css"

const CampaignButton = (props) => {
    const [buttonText, setButtonText] = useState()
    useEffect(() => {
        setButtonText(props.existsInCampaign ? "Saved" : "Save to Campaign");
    },[props.existsInCampaign])
    return(
        <Grid item xs={2.4}>
            <button 
                variant="contained" 
                className={props.existsInCampaign ? 'remove-from-list-button' : 'add-to-campaign-button'} 
                onClick={() => props.handleAddRemoveFromCampaign(props.listKey)}
                onMouseEnter={props.existsInCampaign ? () => setButtonText("Remove") : () => setButtonText("Save")}
                onMouseLeave={props.existsInCampaign ? () => setButtonText("Saved") : () => setButtonText("Save to Campaign")}
            >
                {buttonText}
            </button>
        </Grid>
    );
}

export default CampaignButton;