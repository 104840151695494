import { React, useState, useEffect, forwardRef} from 'react';
import { Grid } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import { Industries } from '../../helperData/ListingInfoData';
import { States } from '../../helperData/LocationData';
import { searchListings } from '../../services/ListingManagementService';
import {createFilterOptions} from '@mui/material/Autocomplete';

const PrequalQuestions = forwardRef((props, ref) => {

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const handleChange = (event) => {
        props.setPrequalSettings({...props.prequalSettings, [event.target.name] : event.target.value});
    };

    const handleDownPaymentUpdate = (event, newPrice) => {
        props.setPrequalSettings({...props.prequalSettings, "lowest_down_payment" : newPrice[0], "highest_down_payment": newPrice[1]});
    };

    // const handleNetWorthUpdate = (event, newPrice) => {
    //     props.setPrequalSettings({...props.prequalSettings, "lowest_net_worth" : newPrice[0], "highest_net_worth": newPrice[1]});
    // };
    const handleInvestmentRange = (event, newPrice) => {
        props.setPrequalSettings({...props.prequalSettings, "lowest_investment_range" : newPrice[0], "highest_investment_range": newPrice[1]});
    };

    const [locations, setLocations] = useState();

    const getLocations = async() => {
        const locationResponse = await searchListings({"size": 0,"aggs": {"locations": {"terms": {"field": "location.keyword","size": 50000}}}});
        const locationAgg = [];
        for(let x = 0; x < locationResponse.aggregations.locations.buckets.length; x++){
            const currentLoc = locationResponse.aggregations.locations.buckets[x].key.split(',');
            locationAgg.push(currentLoc[1] + ',' + currentLoc[0]);
        }
        const locationAggSet = [...new Set(locationAgg)];
        locationAggSet.sort();
        setLocations(States.concat(locationAggSet));
    }

    useEffect(() => {
        if(!locations){
            getLocations();
        }

    },[locations])

    return (
        <div ref={ref} {...props}>
            <Grid container direction="row" justifyContent="center">
                <Grid item xs={11} md={5} sx={{borderRadius: "10px", boxShadow:" 0 2px 4px -1px rgba(0,0,0,0.25)", backgroundColor:"white", padding: "10px"}}>
                    <Grid item xs={12} sx={{textAlign:"center"}}>
                        <h3 style={{width:"100%", marginBottom: "10px"}}>Prequalification Questionnaire</h3>
                    </Grid>
                <Grid item xs={12}>
                    <FormControl sx={{marginBottom: "10px"}}>
                        <FormLabel id="demo-radio-buttons-group-label" sx={{fontWeight:"bold"}} >Are you self funded or backed by investors/firm?:</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="self"
                            name="funding"
                            onChange={handleChange}
                        >
                            <FormControlLabel value="self" control={<Radio sx={{ '&, &.Mui-checked': { color: '#0e9384', }, }}/>} label="Self Funded" />
                            <FormControlLabel value="backed" control={<Radio sx={{ '&, &.Mui-checked': { color: '#0e9384', }, }}/>} label="Backed by investors/firm" />
                        </RadioGroup>
                    </FormControl>

                    <Typography id="non-linear-slider" gutterBottom sx={{fontWeight:"bold", paddingTop:'1px'}}>
                        {"How much liquid capital do you have available for investment?:"}
                    </Typography>
                    <Typography id="non-linear-slider" gutterBottom sx={{fontWeight:"bold", paddingTop:'1px', textAlign:"center"}}>
                        {props.prequalSettings.lowest_down_payment.toLocaleString('en-US') + " - $" + props.prequalSettings.highest_down_payment.toLocaleString('en-US')}
                    </Typography>
                    
                    <div style={{textAlign:"center"}}>
                        <Slider
                            max={3000000}
                            min={0}
                            step={50000}
                            value={[props.prequalSettings.lowest_down_payment, props.prequalSettings.highest_down_payment]}
                            onChange={handleDownPaymentUpdate}
                            sx={{color:"#0e9384", width:"85%", marginBottom: "10px"}}
                            size="small"
                        />
                    </div>

                    {/* <Typography id="non-linear-slider" gutterBottom sx={{fontWeight:"bold", paddingTop:'1px'}}>
                        {"What is your approximate networth?: $" + props.prequalSettings.lowest_net_worth.toLocaleString('en-US') + " - $" + props.prequalSettings.highest_net_worth.toLocaleString('en-US')}
                    </Typography>
                    
                    <div style={{textAlign:"center"}}>
                        <Slider
                            max={30000000}
                            min={0}
                            step={50000}
                            value={[props.prequalSettings.lowest_net_worth, props.prequalSettings.highest_net_worth]}
                            onChange={handleNetWorthUpdate}
                            sx={{color:"#0e9384", width:"85%", marginBottom: "10px"}}
                            size="small"
                        />
                    </div> */}

                    <FormControl sx={{ marginBottom: "10px"}}>
                        <FormLabel id="demo-radio-buttons-group-label" sx={{fontWeight:"bold"}} >Have you ever owned a buisness before?:</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="N"
                            name="experience"
                            onChange={handleChange}
                        >
                            <FormControlLabel value="N" control={<Radio sx={{ '&, &.Mui-checked': { color: '#0e9384', }, }}/>} label="No" />
                            <FormControlLabel value="Y"control={<Radio sx={{ '&, &.Mui-checked': { color: '#0e9384', }, }}/>} label="Yes" />
                        </RadioGroup>
                    </FormControl>

                    {
                        props.prequalSettings.experience === "Y" &&
                         <TextField
                            id="experience_details"
                            label="Please provide details about the type, size, and your role in the business."
                            multiline
                            value={props.prequalSettings.experience_details}
                            onChange={(event) => props.setPrequalSettings({...props.prequalSettings, [event.target.id] : event.target.value})}
                            style={{width:"100%", marginBottom: "10px"}}
                            rows={2}
                        />
                    }

                    {/* <TextField
                        id="background"
                        label="What is your professional background and relevant experience?"
                        multiline
                        value={props.prequalSettings.background}
                        onChange={(event) => props.setPrequalSettings({...props.prequalSettings, [event.target.id] : event.target.value})}
                        style={{width:"100%", marginBottom: "10px"}}
                        rows={2}
                    />
                    
                    <TextField
                        id="leadership"
                        label="Describe any managerial or leadership experience you have."
                        multiline
                        value={props.prequalSettings.leadership}
                        onChange={(event) => props.setPrequalSettings({...props.prequalSettings, [event.target.id] : event.target.value})}
                        style={{width:"100%", marginBottom: "10px"}}
                        rows={2}
                    /> */}

                    <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={Industries}
                        freeSolo
                        disableCloseOnSelect
                        getOptionLabel={(option) => option}
                        onChange={(event,newValue) => {
                            props.setPrequalSettings({...props.prequalSettings, industries : newValue});
                        }}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option}
                            </li>
                        )}
                        style={{marginBottom:"10px" }}
                        renderInput={(params) => (
                            <TextField {...params} error={props.checkQuestionnaire && !props.prequalSettings.industries.length } label="What Industries are you interested in?" placeholder="Industries..." />
                        )}
                        />
                
                     <FormControl sx={{ marginBottom: "10px"}}>
                        <FormLabel id="demo-radio-buttons-group-label" sx={{fontWeight:"bold"}} >Are you looking for a passive investment, or do you plan to be actively involved in the day-to-day operations?:</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="passive"
                            name="investment_type"
                            onChange={handleChange}
                        >
                            <FormControlLabel value="passive" control={<Radio sx={{ '&, &.Mui-checked': { color: '#0e9384', }, }}/>} label="Passive" />
                            <FormControlLabel value="active"control={<Radio sx={{ '&, &.Mui-checked': { color: '#0e9384', }, }}/>} label="Active" />
                        </RadioGroup>
                    </FormControl>

                    <TextField
                        id="timeframe"
                        label="What is your desired timeframe for acquiring a business?"
                        multiline
                        value={props.prequalSettings.timeframe}
                        onChange={(event) => {
                            props.setPrequalSettings({...props.prequalSettings, [event.target.id] : event.target.value});
                        }}
                        style={{width:"100%", marginBottom: "10px"}}
                        rows={2}
                        error={props.checkQuestionnaire && !props.prequalSettings.timeframe } 
                    />
                    {/* <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label" sx={{fontWeight:"bold"}} >How do you plan to fund your aquisition?</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="personal"
                            name="financing"
                            onChange={handleChange}
                        >
                            <FormControlLabel value="personal" control={<Radio sx={{ '&, &.Mui-checked': { color: '#0e9384', }, }}/>} label="Personal Funds" />
                            <FormControlLabel value="bank" control={<Radio sx={{ '&, &.Mui-checked': { color: '#0e9384', }, }}/>} label="Bank Financing" />
                            <FormControlLabel value="other" control={<Radio sx={{ '&, &.Mui-checked': { color: '#0e9384', }, }}/>} label="Other Sources" />
                        </RadioGroup>
                    </FormControl> */}

                    {/* <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label" sx={{fontWeight:"bold"}} >Are you prequalified for any type of financing?</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="N"
                            name="prequalified"
                            onChange={handleChange}
                        >
                            <FormControlLabel value="N" control={<Radio sx={{ '&, &.Mui-checked': { color: '#0e9384', }, }}/>} label="No" />
                            <FormControlLabel value="Y" control={<Radio sx={{ '&, &.Mui-checked': { color: '#0e9384', }, }}/>} label="Yes" />
                        </RadioGroup>
                    </FormControl> */}
                    
                    {
                        props.prequalSettings.prequalified === "Y" &&
                        <TextField
                            id="experience_details"
                            label="Please provide details about your financing."
                            multiline
                            value={props.prequalSettings.prequalified_details}
                            onChange={(event) => props.setPrequalSettings({...props.prequalSettings, [event.target.id] : event.target.value})}
                            style={{width:"100%", marginBottom: "10px"}}
                            rows={2}
                        />
                    }

                    {
                        locations?.length &&
                        <Autocomplete
                        filterOptions={createFilterOptions({
                            limit: 5
                          })
                        }
                        multiple
                        id="locations-autocomplete"
                        options={locations}
                        freeSolo
                        disableCloseOnSelect
                        getOptionLabel={(option) => option}
                        onChange={(event,newValue) => {
                            props.setPrequalSettings({...props.prequalSettings, locations : newValue});
                        }}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option}
                            </li>
                        )}
                        style={{marginBottom:"10px" }}
                        renderInput={(params) => (
                            <TextField {...params} error={props.checkQuestionnaire && !props.prequalSettings.locations.length }  label="What states or cities are you most interested in for potential business acquisitions?" placeholder="Locations..." />
                        )}
                    />}

                    <Typography id="non-linear-slider" gutterBottom sx={{fontWeight:"bold", paddingTop:'1px'}}>
                        {"Investment range?:" }
                    </Typography>
                    <Typography id="non-linear-slider" gutterBottom sx={{fontWeight:"bold", paddingTop:'1px', textAlign:"center"}}>
                        {props.prequalSettings.lowest_investment_range.toLocaleString('en-US') + " - $" + props.prequalSettings.highest_investment_range.toLocaleString('en-US')}
                    </Typography>
                    
                    <div style={{textAlign:"center"}}>
                        <Slider
                            max={3000000}
                            min={0}
                            step={50000}
                            value={[props.prequalSettings.lowest_investment_range, props.prequalSettings.highest_investment_range]}
                            onChange={handleInvestmentRange}
                            sx={{color:"#0e9384", width:"85%"}}
                            size="small"
                        />
                    </div>

                    {/* <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label" sx={{fontWeight:"bold"}}>Do you have a team of advisors, such as an attorney, accountant, or financial planner, to assist you during the acquisition process?</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="self"
                            name="professional_support"
                            onChange={handleChange}
                        >
                            <FormControlLabel value="yes" control={<Radio sx={{ '&, &.Mui-checked': { color: '#0e9384', }, }}/>} label="Yes" />
                            <FormControlLabel value="no" control={<Radio sx={{ '&, &.Mui-checked': { color: '#0e9384', }, }}/>} label="No" />
                        </RadioGroup>
                    </FormControl> */}

                    {/* <TextField
                        id="additional_info"
                        label="Is there any other relevant information you would like to provide about your background, preferences, or requirements for a potential business acquisition?"
                        multiline
                        value={props.prequalSettings.additional_info}
                        onChange={(event) => props.setPrequalSettings({...props.prequalSettings, [event.target.id] : event.target.value})}
                        style={{width:"100%"}}
                        rows={2}
                    /> */}
                </Grid>
                </Grid>
            </Grid>
            {/* <button onClick={() => console.log("settings: ", props.prequalSettings)}>show prequal settings</button> */}
        </div>
        
    );
});

export default PrequalQuestions;