import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  retrieveUserAttributes,
  retrieveUserProfile,
} from "../../services/UserManagementService";
import SetPageTitle from "../../components/SetPageTitle/SetPageTitle";
import DealScoutNavBar from "../../components/NavBar/DealScoutNavBar";
import { SnackBar } from "../../components/SnackBar/SnackBar";
import { Box, Stack } from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { createCheckoutSession } from "../../services/StripeService";
import {
  getCustomerSubscription,
  getPricesProducts,
} from "../../services/StripeService";
import ProductListing from "../../components/ProductListing/ProductListing";
import "./DealScoutCheckout.css";
import spinner from "../../assets/spinner.gif";
import Footer from "../../components/Footer/Footer";
import { Card } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const DealScoutCheckout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [stripePromise, setStripePromise] = useState(() =>
    loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_PROD)
  );
  const [userAttributes, setUserAttributes] = useState();
  const [userProfileData, setUserProfileData] = useState();
  const [clientResponse, setClientResponse] = useState();
  const [subscription, setSubscription] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [listOfProducts, setListOfProducts] = useState();
  const [selectedProductPrice, setSelectedProduct] = useState();
  const [isSubscribed, setIsSubscribed] = useState();
  const [currentUserPlan, setCurrentUserPlan] = useState(null);
  const [loading, setLoading] = useState(false);

  const getUserAttributes = async () => {
    retrieveUserAttributes().then((response) => {
      if (response.errorMessage) {
        setErrorMessage(response.errorMessage);
      } else {
        setUserAttributes(response.result);
      }
    });
  };

  const getUserProfile = async () => {
    retrieveUserProfile().then((response) => {
      if (response.errorMessage) {
        setErrorMessage(response.errorMessage);
      } else {
        setUserProfileData(response);
      }
    });
  };
  const getClientSecret = async (priceId) => {
    const customerId = userProfileData?.subscriptions?.["deal-scout"]?.[
      "customer-id"
    ]
      ? userProfileData.subscriptions["deal-scout"]["customer-id"]
      : null;
    const response = await createCheckoutSession(
      priceId,
      customerId,
      userProfileData.email
    );
    setClientResponse(response);
    const element = document.getElementById("stripe-checkout");
    element.scrollIntoView({ behavior: "smooth", block: "end" });
    setLoading(false);
  };

  // checks if the user is already subscribed to
  // adjust the page's content accordingly
  const checkIfAlreadySubscribed = async () => {
    if (!userProfileData["subscriptions"]["deal-scout"]["customer-id"]) {
      setIsSubscribed(false);
      return;
    }

    const subscriptionResponse = await getCustomerSubscription(
      userProfileData["subscriptions"]["deal-scout"]["customer-id"]
    );
    setSubscription(subscriptionResponse);

    if (subscriptionResponse.length !== 0) {
      const subscribedProductId = subscriptionResponse[0].plan.product;
      setCurrentUserPlan(subscribedProductId);
      setIsSubscribed(true);
    } else {
      setIsSubscribed(false);
    }
  };

  const handleProductPriceSelection = async (
    productName,
    productDescription,
    priceId,
    priceCost
  ) => {
    setClientResponse(null)
    setLoading(true);
    const productData = {
      productName,
      productDescription,
      priceId,
      priceCost,
    };
    setSelectedProduct(productData);
    getClientSecret(productData.priceId);
  };

  useEffect(() => {
    const getPricingInfo = async () => {
      const pricingInfo = await getPricesProducts();
      setListOfProducts(pricingInfo);

      // set default product for payment intent and page render
      // if the user is already subscribed to the standard default product,
      // set the default to the next tier product
      const defaultProduct =
        currentUserPlan !== pricingInfo[0].product_id
          ? pricingInfo[0]
          : pricingInfo[1];
      const defaultPrice = defaultProduct["prices"][0];
      const productPriceData = {
        productName: defaultProduct.product_name,
        productDescription: defaultProduct.product_description,
        priceId: defaultPrice.price_id,
        priceCost: defaultPrice.price_cost,
      };

      setSelectedProduct(productPriceData);
    };
    if (
      !window.location.host.includes("localhost") &&
      !window.location.host.includes("dev.onedeal.biz")
    ) {
      window.gtag("event", "page_view", {
        page_path: location.pathname + location.search,
      });
    }
    if (localStorage.getItem("authorized")) {
      if (!userAttributes) {
        getUserAttributes();
      }

      if (!userProfileData) {
        getUserProfile();
      }

      if (userProfileData && !subscription) {
        checkIfAlreadySubscribed();
      }
    }
    if (localStorage.getItem("authorized") && !userAttributes) {
      getUserAttributes();
    } else if (localStorage.getItem("authorized") && !userProfileData) {
      getUserProfile();
    }
    if (!listOfProducts) {
      getPricingInfo();
    }
  }, [
    localStorage.getItem("authorized"),
    location,
    userAttributes,
    userProfileData,
    isSubscribed,
    listOfProducts,
  ]);

  return (
    <>
      <SetPageTitle title="Checkout | Deal Scout" />
      <DealScoutNavBar
        userAttributes={userAttributes}
        setUserAttributes={setUserAttributes}
        userProfileData={userProfileData}
      />
      <main>
        <Box className="checkout-page-container">
          <Stack
            spacing={4}
            sx={{ margin: "4rem" }}
            justifyContent="center"
            alignItems="center"
          >
            <Stack spacing={2} justifyContent="center" alignItems="center">
              <h5 style={{ color: "#0e9384" }}>Pricing</h5>
              <h2>Our pricing plans</h2>
            </Stack>{" "}
            <p
              style={{
                textAlign: "center",
                maxWidth: "60%",
                marginBottom: "3rem",
              }}
            >
              The only tool that combines proprietary and brokered deal flow
              into one platform.
            </p>
          </Stack>
          <div className="checkout-products-container">
            {listOfProducts &&
              listOfProducts.map((product) => {
                return (
                  <ProductListing
                    key={product.product_name}
                    product={product}
                    isCheckout={true}
                    returningUser={isSubscribed}
                    currentUserPlan={currentUserPlan}
                    selectPricingPlan={handleProductPriceSelection}
                    userAttributes={userAttributes}
                  />
                );
              })}
            <Card
              raised={true}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "2rem 1.5rem",
                flex: 1,
                width: "100%",
                maxWidth: "17.5rem",
                overflow: "inherit",
                borderRadius: "20px",
                gap: "1rem",
              }}
            >
              <div className="product-name-container">
                <p className="product-name">Enterprise</p>
                <div
                  style={{ margin: "1rem 0" }}
                  className="product-price-container"
                >
                  <p className="product-price">Custom</p>
                </div>
                <p
                  style={{ margin: "1rem 0 0 0" }}
                  className="product-description"
                >
                  Includes custom monthly credits for proprietary sourcing and
                  unlimited access to brokered deals
                </p>
                <hr style={{ margin: "1rem 0" }} className="line-separator" />
                <ul
                  style={{
                    fontFamily: "'Montserrat', sans-serif",
                  }}
                  className="product-features-list"
                >
                  <li className="product-feature">
                    <CheckCircleIcon />
                    Customized credits
                  </li>
                  <li className="product-feature">
                    <CheckCircleIcon />
                    All analytics features{" "}
                  </li>
                  <li className="product-feature">
                    <CheckCircleIcon />
                    Unlimited Saved Campaigns{" "}
                  </li>
                  <li className="product-feature">
                    <CheckCircleIcon />
                    Premium Support
                  </li>
                  <li className="product-feature">
                    <CheckCircleIcon />
                    Custom Team Seats
                  </li>
                </ul>
                <a
                  href="https://calendly.com/anthony-onedeal/30min"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button
                    className="cmn-btn non-checkout-button"
                    style={{ borderRadius: "20px" }}
                  >
                    Contact OneDeal
                  </button>
                </a>
              </div>
            </Card>
          </div>
          {loading && (
            <div id="stripe-checkout" style={{ textAlign: "center" }}>
              <img alt="loading..." src={spinner} />
            </div>
          )}
          <div id="stripe-checkout">
            {clientResponse && (
              <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={clientResponse}
              >
                <EmbeddedCheckout />
              </EmbeddedCheckoutProvider>
            )}
          </div>
        </Box>
      </main>
      {/* <div style={{ height: "6rem" }}></div> */}

      {(errorMessage || successMessage) && (
        <SnackBar
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          successMessage={successMessage}
          setSuccessMessage={setSuccessMessage}
        />
      )}
      <Footer />
    </>
  );
};

export default DealScoutCheckout;
