import { React } from 'react';

export const getGeoCoordinates = async(address) => {

  const response = await fetch('/api/googleMaps', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({"address": address}),
  }).then(res => res.json()).then((data) => {
    return data;
  });
  return response;
};