import { React, useState, useEffect } from "react";
import { Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { upsertDealScoutCampaign } from "../../../services/DealScout";
import BusinessDisplay from "./BusinessDisplay";

const CRM = (props) => {
  //state management
  const [selectCampaignDropDown, setSelectCampaignDropDown] = useState("");
  const [currentCampaign, setCurrentCampaign] = useState({
    lists: {},
  });
  //hanlder functions
  const handleCampaignSelect = (event) => {
    setSelectCampaignDropDown(event.target.value);
    if (event.target.value in props.usersDealScouts.scoutData.campaigns) {
      setCurrentCampaign(
        props.usersDealScouts.scoutData.campaigns[event.target.value]
      );
    }
  };

  const handleStatusSelect = async (event, listId, bizId) => {
    let updatedCampaign = currentCampaign;
    updatedCampaign.lists[listId].businesses[bizId]["status"] =
      event.target.value;
    setCurrentCampaign(updatedCampaign);
    await upsertDealScoutCampaign({
      campaignId: selectCampaignDropDown,
      campaignBody: updatedCampaign,
    });
  };

  useEffect(() => {}, [currentCampaign]);

  return (
    <Grid container direction="row" justifyContent="center" spacing={2}>
      <Grid item xs={10}>
        <h1 style={{ color: "black", marginTop: "3rem" }}>CRM</h1>
      </Grid>
      <Grid item xs={5} sx={{ backgroundColor: "white", borderRadius: "10px" }}>
        <FormControl sx={{ m: 1, minWidth: "100%" }}>
          <InputLabel id="demo-simple-select-helper-label">
            Select A Saved Campaign
          </InputLabel>
          <Select
            //   labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={selectCampaignDropDown}
            label="Select A Saved Campaign Or Create A New One"
            onChange={handleCampaignSelect}
            sx={{ borderRadius: "24px" }}
          >
            <MenuItem onClick={() => props.setDrawComponent("campaignManager")}>
              Create New Campaign
            </MenuItem>
            {props.usersDealScouts &&
              "scoutData" in props.usersDealScouts &&
              Object.keys(props.usersDealScouts.scoutData.campaigns).map(
                (key) => {
                  return (
                    <MenuItem value={key}>
                      {props.usersDealScouts.scoutData.campaigns[key].name}
                    </MenuItem>
                  );
                }
              )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={10}>
        <h2 style={{ color: "black" }}>Businesses</h2>
      </Grid>
      {/* for every list in the campaign display every business and a status */}
      {currentCampaign &&
        Object.keys(currentCampaign.lists).map((listKey) => {
          let currentListsBusinesses =
            currentCampaign.lists[listKey].businesses;
          let existsInList =
            currentListsBusinesses.infogroup_id in currentListsBusinesses;
          return Object.keys(currentListsBusinesses).map((bizKey) => {
            return (
              <>
                <BusinessDisplay
                  alias={currentListsBusinesses[bizKey].alias}
                  image_url={currentListsBusinesses[bizKey].image_url}
                  name={currentListsBusinesses[bizKey].name}
                  street={currentListsBusinesses[bizKey].street}
                  city={currentListsBusinesses[bizKey].city}
                  state={currentListsBusinesses[bizKey].state}
                  location={currentListsBusinesses[bizKey].location}
                  website={currentListsBusinesses[bizKey].website}
                  display_phone={currentListsBusinesses[bizKey].phone}
                  categories={currentListsBusinesses[bizKey].categories}
                  handleStatusSelect={handleStatusSelect}
                  estimated_opened_for_business={
                    currentListsBusinesses[bizKey].estimated_opened_for_business
                  }
                  verified_on={currentListsBusinesses[bizKey].verified_on}
                  mailing_address={
                    currentListsBusinesses[bizKey].mailing_address
                  }
                  mailing_address_city={
                    currentListsBusinesses[bizKey].mailing_address_city
                  }
                  mailing_address_state={
                    currentListsBusinesses[bizKey].mailing_address_state
                  }
                  mailing_address_postal_code={
                    currentListsBusinesses[bizKey].mailing_address_postal_code
                  }
                  location_email_address={
                    currentListsBusinesses[bizKey].location_email_address
                  }
                  primary_contact={
                    currentListsBusinesses[bizKey].primary_contact
                  }
                  job_titles={currentListsBusinesses[bizKey].job_titles}
                  location_employee_count={
                    currentListsBusinesses[bizKey].location_employee_count
                  }
                  estimated_location_employee_count={
                    currentListsBusinesses[bizKey]
                      .estimated_location_employee_count
                  }
                  estimated_corporate_employee_count={
                    currentListsBusinesses[bizKey]
                      .estimated_corporate_employee_count
                  }
                  estimated_corporate_sales_revenue={
                    currentListsBusinesses[bizKey]
                      .estimated_corporate_sales_revenue
                  }
                  listKey={listKey}
                  bizKey={bizKey}
                  status={currentListsBusinesses[bizKey]?.status}
                  biz={currentListsBusinesses}
                  existsInList={existsInList}
                />
              </>
              // <>
              //   <Divider orientation="horizontal" sx={dividerStyle}/>
              //   <Grid item xs={10} sx={{display:"flex", paddingBottom:"16px"}}>
              //       <Grid item xs={1}>
              //       <Avatar
              //       alt={currentListsBusinesses[bizKey].alias}
              //       src={currentListsBusinesses[bizKey].image_url}
              //       sx={{ width: 56, height: 56 }}
              //       />
              //       </Grid>
              //       <Grid item xs={4}>
              //       <Grid item xs={12}>
              //           <span className='text'>{currentListsBusinesses[bizKey].name}</span>
              //       </Grid>
              //       <Grid item xs={12}>
              //       <span className='text'>{currentListsBusinesses[bizKey].location.display_address}</span>
              //       </Grid>
              //       </Grid>
              //       <Grid item xs={3}>
              //       <Grid item xs={12}>
              //           <a className='deal-scout-link' href={currentListsBusinesses[bizKey].url}>Website</a>
              //       </Grid>
              //       <Grid item xs={12}>
              //       <span className='text'>{currentListsBusinesses[bizKey].display_phone}</span>
              //       </Grid>
              //       </Grid>
              //       <Grid item xs={2.5}>
              //       {
              //           currentListsBusinesses[bizKey].categories.map((categoryChip) => {
              //           return(
              //               <Chip label={categoryChip.title} size="small" variant="outlined" sx={{"backgroundColor": "black",
              //               "color": "white"}}/>
              //           )
              //           })
              //       }
              //       </Grid>
              //       <Grid item>
              //       <FormControl sx={{ m: 1, minWidth: "100%" }}>
              //         <InputLabel id="demo-simple-select-helper-label">Select A Saved Campaign</InputLabel>
              //         <Select
              //         //   labelId="demo-simple-select-helper-label"
              //             id="demo-simple-select-helper"
              //             value={selectStatusDropDown}
              //             label="Select A Saved Campaign Or Create A New One"
              //             onChange={(e) => handleStatusSelect(e, listKey, bizKey)}
              //             sx={{ borderRadius: "24px" }}
              //         >
              //             <MenuItem value='Action Item'>Action Item</MenuItem>
              //             <MenuItem value='Not Contacted'>Not Contacted</MenuItem>
              //             <MenuItem value='Contacted'>Contacted</MenuItem>
              //             <MenuItem value='Responded'>Responded</MenuItem>
              //             <MenuItem value='Not Interested'>Not Interested</MenuItem>
              //             <MenuItem value='Call Scheduled'>Call Scheduled</MenuItem>
              //             <MenuItem value='Letter of Intent'>Letter of Intent</MenuItem>

              //         </Select>
              //     </FormControl>
              //       </Grid>
              //   </Grid>
              // </>
            );
          });
        })}
    </Grid>
  );
};

export default CRM;
