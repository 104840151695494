import { React, useState } from 'react';
import { Box, Button } from '@mui/material';
import pattern from '../../assets/Landing/pattern-1.svg'
import Modal from '@mui/material/Modal';
import confetti from '../../assets/welcome-confetti-green.png';
import { initializeDealScout } from '../../services/DealScout';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    backgroundSize: "cover",
    background: `url(${confetti})`,
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };

  const welcomeMessage = "We're thrilled to welcome you to DealScout, your all-in-one solution for finding and managing proprietary deals efficiently. Congratulations on being part of our exclusive beta community!"

export default function WelcomeModal(props) {
  const [open, setOpen] = useState(props.openWelcomeModal);
  // const [startTour, setStartTour] = useState(false);
  
  const handleClose = async() => {
    setOpen(false);
    await initializeDealScout();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
            <section className="banner-section">
			<img src={pattern} alt="" className="pattern" />
			<div className="container">
                <div className="banner-content">
                    <div className="content">
                        <h1 className="title">
                          Welcome to DealScout Beta!
                        </h1>
                        <p style={{textAlign:"justify", marginBottom:"10px", color:'white'}}>
                          {welcomeMessage}
                        </p>
                    </div>
                </div>
			</div>
		</section>
        </Box>
        </Modal>
        {/* {startTour && <TakeTheTour isBroker={props.isBroker} userEmail={props.userEmail} />} */}
    </div>
  );
}