import { React, useState,useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';
import pleaseStandBy from '../../assets/pleasestandby.gif'
import NavBar from '../../components/NavBar/NavBar';
import Footer from '../../components/Footer/Footer';
import { HashLink } from 'react-router-hash-link';
import './PageNotFound.css'
import { retrieveUserAttributes, retrieveUserProfile } from '../../services/UserManagementService';
import SetPageTitle from '../../components/SetPageTitle/SetPageTitle';

const PageNotFound = () => {
    const [userAttributes, setUserAttributes] = useState();
    const [userProfileData, setUserProfileData] = useState();
    const [errorMessage, setErrorMessage] = useState();

    const getUserAttributes = async() => {
        retrieveUserAttributes().then((response) => {
          if(response.errorMessage){
            setErrorMessage(response.errorMessage);
          }else{
            setUserAttributes(response.result)
          }
        });
      }

    const getUserProfile = async() => {
      retrieveUserProfile().then((response) => {
        if(response.errorMessage){
          setErrorMessage(response.errorMessage);
        }else{
          setUserProfileData(response);
        }
      });
    }

    const location = useLocation();
    useEffect(() => {
      if (!window.location.host.includes('localhost') && !window.location.host.includes('dev.onedeal.biz')) {
        window.gtag("event", "page_view", {
          page_path: location.pathname + location.search,
        });
      }
      if(localStorage.getItem("authorized") && !userAttributes){
        getUserAttributes();
      }else if(localStorage.getItem("authorized") && !userProfileData){
        getUserProfile();
      }

    },[localStorage.getItem("authorized"), location, userAttributes, userProfileData]);

    return(
        <>
          <SetPageTitle title="Page Not Found" />
          <NavBar userAttributes={userAttributes} setUserAttributes={setUserAttributes} userProfileData={userProfileData}/>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={10} sm={8} sx={{textAlign: "center", marginTop:"10px"}}>
                <img alt="404 You have reached a broken link" src={pleaseStandBy} className='pageNotFoundImage'/>
                <h1>Whoops! Something doesn't look right ... <HashLink to="/#" className='returnHome'>Return Home</HashLink></h1>
            </Grid>
          </Grid>
          <Footer/>
        </>
    )
}

export default PageNotFound;
