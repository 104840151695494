import { React, useState, useRef, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import Slide from '@mui/material/Slide';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Register } from '../../components/UserRegistration/Register';
import { ConfirmationCode } from '../../components/UserRegistration/Confirmation';
import { useLocation, useNavigate } from 'react-router-dom';
import { googleAuthentication, updatePrequalificationSettings } from '../../services/UserManagementService';
import { ProfilePreview } from './ProfilePreview';
import DragAndDrop from './FileUploader';
import PrequalQuestions from './PrequalQuestions';
import Footer from '../../components/Footer/Footer'
import FormHelperText from '@mui/material/FormHelperText';
import VerifiedIcon from '@mui/icons-material/Verified';
import loadingGif from '../../assets/Rocket.gif'

const TransitionalSignup = () => {
	const location = useLocation();
  const navigate = useNavigate();

  // START SLIDE CTRL
  const [step, setStep] = useState(1);
  const containerRef = useRef(null);

  // END SLIDE CTRL

  // START GOOGLE AUTH
  const search = useLocation().search;
  const [authCode, setAuthCode] = useState(new URLSearchParams(search).get('code'));
  const [googleLoginResponse, setGoogleLoginResponse] = useState();
  const [loading, setLoading] = useState(false);

  const getGoogleToken = async() => {
      setLoading(true);
      googleAuthentication(authCode, 'signup').then((data) => {
      setAuthCode(null);
      return data;
    }).then((lastLoggedInresponse) => {
      setGoogleLoginResponse(lastLoggedInresponse);
      setLoading(false);
      setStep(2);
    });
  }
  // END GOOGLE AUTH

  const [registrationResponse, setRegistrationResponse] = useState();
  const [holdSignUpDetails, setHoldSignUpDetails] = useState();

  // continue requirements
  const [imageUploaded, setImageUploaded] = useState();
  const [uploadPOF, setUploadPOF] = useState(); // for step 3
  const [helperText, setHelperText] = useState();
  const [checkQuestionnaire, setCheckQuestionnaire] = useState();
  const [prequalSettings, setPrequalSettings] = useState({
    funding: "self", //required
    lowest_down_payment: 0, //required,
    highest_down_payment: 3000000, //required,
    lowest_net_worth: 0,
    highest_net_worth: 30000000,
    experience : "N", //required
    experience_details: "",
    background: "",
    leadership: "",
    industries: [], //required
    investment_type: "", //required
    timeframe: "", //required
    financing : "",
    prequalified : "N",
    prequalified_details: "",
    "locations": [],
    lowest_investment_range: 0,
    highest_investment_range: 30000000,
    professional_support: "",
    additional_info: "",
});


  const skipForNow = async() => {
    setHelperText(false);
      if(step + 1 === 5){
        await updatePrequalificationSettings({"prequalificationSettings" : prequalSettings});
        navigate('/businesses-for-sale', { state: 'N' });
      }else if((holdSignUpDetails?.isBroker || (googleLoginResponse && googleLoginResponse['is-broker'] === 'Y')) && step === 3){
        navigate('/businesses-for-sale', { state: 'N' });
      }else{
        setStep(step + 1);
      }
  }

  //if user tries to continue, check what is required for verification and notify before moving on
  const continueToVerify = () => {
    if((step + 1) === 3 && !imageUploaded){
      setHelperText("Upload a profile photo to be considered for OneDeal Verification");
    }else if((step + 1) === 4 && !uploadPOF.length){
      if((holdSignUpDetails?.isBroker || (googleLoginResponse && googleLoginResponse['is-broker'] === 'Y'))){
        setHelperText("Upload a non-disclosure agreement document to be considered for OneDeal Verification");
      }else{
        setHelperText("Upload a proof of funds document to be considered for OneDeal Verification");
      }      
    }else if((step + 1) === 5 && (!prequalSettings.locations.length || !prequalSettings.industries.length || !prequalSettings.timeframe)){
      setCheckQuestionnaire(true);
      setHelperText("Complete the form above to be considered for OneDeal Verification");
    }else{
      skipForNow();
    }
  }

  useEffect(() => {

    // if(localStorage.getItem("authorized")){
    //   navigate('/')
    // }

    if(authCode){
			getGoogleToken();
		}

    if (!window.location.host.includes('localhost') && !window.location.host.includes('dev.onedeal.biz')) {
      window.gtag("event", "page_view", {
        page_path: location.pathname + location.search,
      });
    }
  },[location])

  return (
    <Box
      sx={{
       
        // display: 'flex',
        padding: 2,
        borderRadius: 1,
        // bgcolor: (theme) =>
        //   theme.palette.mode === 'light' ? 'grey.100' : 'grey.900',
        overflow: 'hidden',
      }}
      ref={containerRef}
    >
        { 
          step === 1 && !loading &&
          <Slide direction="left" in={step === 1} container={containerRef.current}>
          <Grid container direction="row" justifyContent="center">
            <Grid item sx={{borderRadius: "10px", boxShadow:" 0 2px 4px -1px rgba(0,0,0,0.25)", display:"flex", backgroundColor:"white"}}>              
                {!registrationResponse && <Register setRegistrationResponse={setRegistrationResponse} setHoldSignUpDetails={setHoldSignUpDetails}/>}
                {registrationResponse && <ConfirmationCode username={registrationResponse.user.username} signUpDetails={holdSignUpDetails} setStep={setStep}/>}
            </Grid>
          </Grid>
        </Slide>}
        {
          loading &&
          <Grid item sx={{textAlign:"center"}}>
              <img alt='loading...' src={loadingGif}/>
            </Grid>
        }
         {
          step === 2 && 
          <Slide direction="left" in={step === 2} container={containerRef.current}>
            <ProfilePreview setImageUploaded={setImageUploaded} />
          </Slide>
        }
        
       {        
        step === 3 &&
        <Slide direction="left" in={step === 3} container={containerRef.current}>
          <DragAndDrop setUploadPOF={setUploadPOF} isBroker={(holdSignUpDetails?.isBroker || (googleLoginResponse && googleLoginResponse['is-broker'] === 'Y'))} email={holdSignUpDetails?.email}/>
        </Slide>
        }
        {
          step === 4 &&
          <Slide direction="left" in={step === 4} container={containerRef.current}>
            <PrequalQuestions checkQuestionnaire={checkQuestionnaire} setCheckQuestionnaire={setCheckQuestionnaire} prequalSettings={prequalSettings} setPrequalSettings={setPrequalSettings}/>
          </Slide>
        }
        {
          step > 1 &&
          <Grid container justifyContent="center" sx={{marginTop:"10px"}}>
            <Grid item xs={5}>
              {<FormHelperText sx={{color:"red", textAlign:"center"}}>{helperText}</FormHelperText>}
              <Grid item>
                <button className="listingDetails-btn" onClick={() => continueToVerify()} style={{width:"100%"}}>
                  Continue <VerifiedIcon /> 
                </button>
              </Grid>
             
                <Grid item>
                <button className="listingDetails-btn-grey" onClick={() => skipForNow()}>
                  Skip for now<NavigateNextIcon /> 
                </button>
              </Grid>
            </Grid>
        </Grid>}
        <Footer />
    </Box>
  );
}

export default TransitionalSignup;