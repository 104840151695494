import React from "react";
import "./DealScoutLandingPage.css";

const ResultNumbers = ({ data, showHeader }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "3rem",
        maxWidth: "77%",
      }}
    >
      {showHeader || (
        <h2 style={{ margin: "2rem 0" }}> Our results in numbers</h2>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "2rem",
          alignItems: "start",
        }}
      >
        {data.map((item, index) => {
          return (
            <div key={index} className="resultNumbersCard">
              {item.n && <h3 style={{ color: "#0e9384" }}>{item.n}</h3>}
              {item.h && <h4 style={{ color: "#0e9384" }}>{item.h}</h4>}
              {item.p && <p>{item.p}</p>}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ResultNumbers;
