import { React, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import { retrieveUserAttributes, modifySavedSearches, retrieveUserProfile } from '../../../services/UserManagementService';
import NavBar from '../../../components/NavBar/NavBar';
import LocationSearch from '../../../components/Filter/LocationSearch';
import { States } from '../../../helperData/LocationData';
import { searchListings } from '../../../services/ListingManagementService';
import { Industries } from '../../../helperData/ListingInfoData';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import FormControl from '@mui/material/FormControl';
import Footer from '../../../components/Footer/Footer';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import LoadingButton from '@mui/lab/LoadingButton';
import NotificationDropDown from './NotificationDropdown';

const sortByList = [
    "Keywords",
    "Newest",
    "Oldest",
    "Highest Price",
    "Lowest Price",
    "Highest Cash Flow",
    "Lowest Cash Flow",
    "Highest Revenue",
    "Lowest Revenue",
]

const UpsertSearch = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [locations, setLocations] = useState();
    const [userAttributes, setUserAttributes] = useState();
    const [userProfileData, setUserProfileData] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [searchToSave, setSearchToSave] =  useState( (location.state) ? location.state.data : {
        "saved_search_name" : "",
        "listing_status": "available",
        "search_phrase" : "",
        "location": "",
        "industries": {},
        "lowest_price": 0,
        "highest_price": 30000000,
        "lowest_cash_flow": 0,
        "highest_cash_flow": 30000000,
        "lowest_revenue": 0,
        "highest_revenue": 30000000,
        "sort_by" : "Keywords",
        "from" : 0
      });
    const [notifications, setNotifications] = useState((location.state?.data?.notifications) ? location.state.data.notifications : {  
      "frequency" : "Weekly",
      "last-notification" : Date.now(),
      "sent-listings" : {}
    });
    const [isLoading, setIsLoading] = useState(false);
    const getUserAttributes = async() => {
        retrieveUserAttributes().then((data) => {
        if(data.errorMessage){
            setErrorMessage(data.errorMessage);
        }else{
            setUserAttributes(data.result)
        }
        });
    }

    const getUserProfile = async() => {
      retrieveUserProfile().then((response) => {
        if(response.errorMessage){
          setErrorMessage(response.errorMessage);
        }else{
          setUserProfileData(response);
        }
      });
    }

    const getLocations = async() => {
      const locationResponse = await searchListings({"size": 0,"aggs": {"locations": {"terms": {"field": "location.keyword","size": 50000}}}});
      const locationAgg = [];
      for(let x = 0; x < locationResponse.aggregations.locations.buckets.length; x++){
          const currentLoc = locationResponse.aggregations.locations.buckets[x].key.split(',');
          locationAgg.push(currentLoc[1] + ',' + currentLoc[0]);
      }
      const locationAggSet = [...new Set(locationAgg)];
      locationAggSet.sort();
      setLocations(States.concat(locationAggSet));
    }

    useEffect(() => {
        if (!window.location.host.includes('localhost') && !window.location.host.includes('dev.onedeal.biz')) {
            window.gtag("event", "page_view", {
              page_path: location.pathname + location.search,
            });
        }
        if(localStorage.getItem("authorized") && !userAttributes){
          getUserAttributes();
        }else if(localStorage.getItem("authorized") && !userProfileData){
          getUserProfile();
        }else if(!localStorage.getItem("authorized")){
            navigate('/login');
        }
        if(!locations){
            getLocations();
        }
    }, [location, locations, userAttributes, userProfileData]);
    
    
    const handleModifySearch = (event, something) => {
        setSearchToSave({...searchToSave, [event.target.id] : event.target.value})
    }

    const handleIndustries = (industry) => {
        if(industry in searchToSave.industries){
          delete searchToSave.industries[industry];
          setSearchToSave({...searchToSave})
        }else{
          let industryMap = searchToSave.industries;
          industryMap[industry] = true;
          setSearchToSave({...searchToSave, "industries": industryMap});
        }
    }
    
    const handlePriceUpdate = (event, newPrice) => {
      setSearchToSave({...searchToSave, "lowest_price" : newPrice[0], "highest_price": newPrice[1]});
    };

    const handleCashFlowUpdate = (event, newCashFlow) => {    
      setSearchToSave({...searchToSave, "lowest_cash_flow" : newCashFlow[0], "highest_cash_flow": newCashFlow[1]});
    };

    const handleRevenueUpdate = (event, newRevenue) => {
      setSearchToSave({...searchToSave, "lowest_revenue" : newRevenue[0], "highest_revenue": newRevenue[1]});
    };

    const handleFrequencyUpdate = (frequency) => {
      setNotifications({...notifications, 'frequency' : frequency})
    }

    return(
      <>
        <NavBar userAttributes={userAttributes} setUserAttributes={setUserAttributes} userProfileData={userProfileData}/>
        <Grid container xs={11} sm={8} spacing={1} sx= {{
          direction:"row",
          justifyContent:"center",
          alignItems:"baseline",
          borderRadius: "4px", 
          boxShadow:" 0 2px 4px -1px rgba(0,0,0,0.25)", 
          backgroundColor:"white",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "10px",
          marginBottom: "10px"    
        }} >        
          <Grid item xs={11}>
            <TextField
                id="saved_search_name"
                label="Saved Search Name"
                variant="outlined"
                onChange={handleModifySearch}
                style={{width:"100%"}}
                value={searchToSave.saved_search_name}
            />
          </Grid>
          <Grid item xs={11}>
            <TextField
                id="search_phrase"
                label="Keyword Search"
                variant="outlined"
                onChange={handleModifySearch}
                style={{width:"100%"}}
                value={searchToSave.search_phrase}
            />
          </Grid>  
          <Grid item xs={11}>
              <LocationSearch infoMap={searchToSave} setInfoMap={setSearchToSave} required={false} error={false} label="Search by city or state" locations={locations}/>
          </Grid>
            <Grid item xs={11} sm={3.6} sx={{fontSize: "4vw", textAlign:'center'}}>
                <Typography id="non-linear-slider" gutterBottom sx={{fontSize:"12.25px", fontWeight:"bold", paddingTop:'1px'}}>
                    {"Price: $" + searchToSave.lowest_price.toLocaleString('en-US') + " - $" + searchToSave.highest_price.toLocaleString('en-US')}
                </Typography>
                <Slider
                    max={30000000}
                    min={0}
                    step={100000}
                    value={[searchToSave.lowest_price, searchToSave.highest_price]}
                    onChange={handlePriceUpdate}
                    sx={{color:"#0e9384", width:"85%"}}
                    size="small"
                />
            </Grid>
            <Grid item xs={11} sm={3.6} sx={{fontSize: "4vw", textAlign:'center'}}>
            <Typography id="non-linear-slider" gutterBottom sx={{fontSize:"12.25px", fontWeight:"bold"}}>
                    {"Cash Flow: $" + searchToSave.lowest_cash_flow.toLocaleString('en-US') + " - $" + searchToSave.highest_cash_flow.toLocaleString('en-US')}
                </Typography>
                <Slider
                    max={30000000}
                    min={0}
                    step={100000}
                    value={[searchToSave.lowest_cash_flow, searchToSave.highest_cash_flow]}
                    onChange={handleCashFlowUpdate}
                    sx={{color:"#0e9384", width:"85%"}}
                    size="small"
                />
            </Grid>
            <Grid item xs={11} sm={3.6} sx={{fontSize: "4vw", textAlign:'center'}}>
              <Typography id="non-linear-slider" gutterBottom sx={{fontSize:"12.25px", fontWeight:"bold"}}>
              {"Revenue: $" + searchToSave.lowest_revenue.toLocaleString('en-US') + " - $" + searchToSave.highest_revenue.toLocaleString('en-US')}
                  </Typography>
              <Slider
                  max={30000000}
                  min={0}
                  step={100000}
                  value={[searchToSave.lowest_revenue, searchToSave.highest_revenue]}
                  onChange={handleRevenueUpdate}
                  sx={{color:"#0e9384", width:"85%"}}
                  size="small"
              />
            </Grid>
            <Grid item xs={11} sm={6} sx={{display:"flex", justifyContent:"center", textAlign:"center"}}>
            <FormGroup>
              <h4 style={{textDecoration:"underline"}}>Industry</h4>
              {
                Industries.map((industry) => {
                  return(
                    <FormControlLabel 
                    control={<Checkbox size='small' onChange={() => handleIndustries(industry)}/>} 
                    label={industry}
                    checked={industry in searchToSave.industries}
                    sx={
                      { 
                        '& .MuiFormControlLabel-label': { fontFamily: "'Plus Jakarta Sans', sans-serif"},
                        margin: "0"
                      }
                    }
                    />
                  )
                })
              }
            </FormGroup>
            </Grid>
            <Grid item xs={11} sm={6} sx={{display:"flex", justifyContent:"center", textAlign:"center"}}>
                <FormControl>
                  <h4 style={{textDecoration:"underline"}}>Sort By</h4>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="Keywords"
                    name="radio-buttons-group"
                    onChange={(event) => setSearchToSave({...searchToSave, "sort_by" : event.target.value})}
                    value={searchToSave.sort_by}
                  >
                    {
                      sortByList.map((sortByItem) => {
                        return(
                          <FormControlLabel sx={{
                            '& .MuiFormControlLabel-label': { fontFamily: "'Plus Jakarta Sans', sans-serif"},
                            margin:"0px"
                          }} value={sortByItem} control={<Radio size='small'/>} label={sortByItem} />    
                        )
                      })
                    }
                  </RadioGroup>
                </FormControl>
            </Grid>
            {notifications.frequency &&
              <Grid item xs={11} sx={{justifyContent:"center", display:"flex"}}>
                <NotificationDropDown frequency={notifications.frequency} handleFrequencyUpdate={handleFrequencyUpdate}/>
              </Grid>
            }
            <Grid item xs={11} sx={{textAlign:"center"}}>
              <LoadingButton
                  onClick={async() => { 
                      setIsLoading(true);
                      searchToSave.notifications = notifications;
                      await modifySavedSearches({searchId: (!location.state) ? null : location.state.searchKey, searchBody: searchToSave})
                      .then(() =>  {
                          setIsLoading(false);
                          navigate('/myOneDeal');
                      })
                  }}
                  loading={isLoading}
                  variant="contained"
                  sx={{backgroundColor: "#0e9384", mt:"8px", mb:"8px", fontWeight:"bold",borderRadius:"10px"}}
              >Save
              </LoadingButton>
            </Grid>          
        </Grid>
        <Footer/>
        </>
    );
};

export default UpsertSearch;