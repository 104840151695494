import { React, useState } from 'react';
import { Box, Button } from '@mui/material';
import pattern from '../../assets/Landing/pattern-1.svg'
import Modal from '@mui/material/Modal';
import confetti from '../../assets/welcome-confetti-green.png';
import TakeTheTour from '../../components/TakeTheTour/TakeTheTour';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    backgroundSize: "cover",
    background: `url(${confetti})`,
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };

  const buyerWelcomeMessage = "We're thrilled to have you here and excited to help you find the perfect business to fit your goals and ambitions. Let's take a quick tour!";
  const brokerWelcomeMessage = "We're excited to have you join our community of business brokers. With OneDeal, you have access to a platform that makes it easier to find qualified buyers for your listings. Let's take a quick tour!";

export default function WelcomeModal(props) {
  const [open, setOpen] = useState(props.openWelcomeModal);
  const [startTour, setStartTour] = useState(false);
  const handleClose = () => {
    setOpen(false);
    setStartTour(true);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
            <section className="banner-section">
			<img src={pattern} alt="" className="pattern" />
			<div className="container">
                <div className="banner-content">
                    <div className="content">
                        <h1 className="title">
                            Welcome to OneDeal!
                        </h1>
                        <p style={{textAlign:"justify", marginBottom:"10px"}}>
                          {props.isBroker === "Y" ? brokerWelcomeMessage : buyerWelcomeMessage}
                        </p>
                    </div>
                    <div style={{textAlign:"center"}}>
                        <Button variant="contained" sx={{
                            backgroundColor: "#ffffff", 
                            color: "#0e9384", 
                            mt:"8px", mb:"8px", 
                            fontWeight:"bold",
                            borderRadius:"24px",
                            ':hover': {
                                bgcolor: '#13548b', // theme.palette.primary.main
                                color: '#ffffff',
                              },
                            }}
                            onClick={() => handleClose()}
                            >
                                Get Started
                        </Button>
                    </div>
                </div>
			</div>
		</section>
        </Box>
        </Modal>
        {startTour && <TakeTheTour isBroker={props.isBroker} userEmail={props.userEmail} />}
    </div>
  );
}