import { React } from 'react';
import { Grid } from '@mui/material';
import Divider from '@mui/material/Divider';
import CampaignButton from './CampaignButton'
import '../DealScout.css';

const dividerStyle = {
    "top": "272px",
    "left": "154px",
    "width": "1096px",
    "height": "2px",
    "backgroundColor": "#f0f0f0",
    "borderRadius": "2px" 
}

const getDate = (dateString) => {
    const months = ["January","February","March","April","May","June","July","August","September","October","November","December"]
    const postDate = new Date(dateString);       
    return months[postDate.getMonth()] + " " + postDate.getDate() + ", " + postDate.getFullYear();
}

const ListSelect = (props) => {
    return(
        <>
            <Divider orientation="horizontal" sx={dividerStyle}/>
            {
                props.listKey && props.usersDealScouts &&
                <Grid item xs={10} sx={{
                    display:"flex", 
                    paddingBottom:"16px",
                    // ':hover': {
                    //     bgcolor: '#D3D3D3',
                    //     cursor: 'pointer'
                    //     },
                    }}
                    >
                    <Grid item xs={2.4}>
                        <span className='textLink' onClick={() => {props.setListControlledByCampaign(props.listKey); props.setDrawComponent('listBuilder')}}>{props.usersDealScouts.scoutData.lists[props.listKey].name}</span>
                    </Grid>
                    <Grid item xs={2.4}>
                        <span className='text'>{getDate(props.usersDealScouts.scoutData.lists[props.listKey].lastModified)}</span>
                    </Grid>
                    <Grid item xs={2.4}>
                        <span className='text'>{props.name}</span>
                    </Grid>
                    <Grid item xs={2.4}>
                        <span className='text'>{Object.keys(props.usersDealScouts.scoutData.lists[props.listKey].businesses).length} Total Records</span>
                    </Grid>
                    <CampaignButton existsInCampaign={props.existsInCampaign} handleAddRemoveFromCampaign={props.handleAddRemoveFromCampaign} listKey={props.listKey}/>
                </Grid>
            }
       </>
    )
}

export default ListSelect;