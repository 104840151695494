import { React, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import DealScout from "./DealScout";
import DealScoutLandingPage from "../DealScoutLandingPage/DealScoutLandingPage";
import { retrieveUserProfile } from '../../services/UserManagementService';

const DealScoutSwitch = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [userProfileData, setUserProfileData] = useState();
    const [confirmedPayment, setConfirmedPayment] = useState(location?.state?.confirmedPayment)
    const [errorMessage, setErrorMessage] = useState();

    const getUserProfileData = async () => {
        retrieveUserProfile().then((response) => {
          if (response.errorMessage) {
            setErrorMessage(response.errorMessage);
          } else {
            setUserProfileData(response);
          }
        });
      };

      useEffect(() => {
        if (
          !window.location.host.includes("localhost") &&
          !window.location.host.includes("dev.onedeal.biz")
        ) {
          window.gtag("event", "page_view", {
            page_path: location.pathname + location.search,
          });
        }
    
        //if the user is logged in...
        if (localStorage.getItem("authorized")) {
          if (!userProfileData || (confirmedPayment && !('subscriptions' in userProfileData))) {
            getUserProfileData();
          }
        } else {
          navigate("/login");
        }
    
      },[userProfileData]);
      if(userProfileData && ('subscriptions' in userProfileData && (userProfileData['subscriptions']['deal-scout']['status'] === 'active' || userProfileData['subscriptions']['deal-scout']['status'] === 'trialing'))){
        return(<DealScout userProfileData={userProfileData} setUserProfileData={setUserProfileData}/>);
      }else if(userProfileData && (!('subscriptions' in userProfileData) || userProfileData['subscriptions']['deal-scout']['status'] !== 'active')){
        return(<DealScoutLandingPage userProfileData={userProfileData} setUserProfileData={setUserProfileData}/>);
      }
}

export default DealScoutSwitch;