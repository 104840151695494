export const constructDataAxleRequest = (searchParams) => {
    // categories: [],

    const requestBody = {
        "filter": {
            "connective": "and",
            "propositions": []
        },
        "packages": "enhanced_v1",
    };

    if(searchParams.keywords){
        requestBody['query'] = searchParams.keywords;
    }

    //append employee count
    if(searchParams.lowest_employees && searchParams.highest_employees){
        requestBody.filter.propositions.push({
            "relation": "between",
            "attribute": "location_employee_count",
            "value": [searchParams.lowest_employees, searchParams.highest_employees]
        });
    }else if(searchParams.lowest_employees && !searchParams.highest_employees){
        requestBody.filter.propositions.push({
            "relation": "greater_than",
            "attribute": "location_employee_count",
            "value": searchParams.lowest_employees
        });
    }else if(!searchParams.lowest_employees && searchParams.highest_employees){
        requestBody.filter.propositions.push({
            "relation": "less_than",
            "attribute": "location_employee_count",
            "value": searchParams.highest_employees
        });
    }

    //append revenue
    if(searchParams.lowest_estimated_sales_revenue && searchParams.highest_estimated_sales_revenue){
        requestBody.filter.propositions.push(
            {
                "connective": "or",
                "propositions": [
                    {
                        "relation": "between",
                        "attribute": "corporate_sales_revenue",
                        "value": [searchParams.lowest_estimated_sales_revenue, searchParams.highest_estimated_sales_revenue]
                    },
                    {
                        "relation": "between",
                        "attribute": "estimated_corporate_sales_revenue",
                        "value": [searchParams.lowest_estimated_sales_revenue, searchParams.highest_estimated_sales_revenue]
                    }
                ]
            }
        );
    }else if(!searchParams.lowest_estimated_sales_revenue && searchParams.highest_estimated_sales_revenue){
        requestBody.filter.propositions.push(
            {
                "connective": "or",
                "propositions": [
                    {
                        "relation": "greater_than",
                        "attribute": "corporate_sales_revenue",
                        "value": [searchParams.lowest_estimated_sales_revenue]
                    },
                    {
                        "relation": "greater_than",
                        "attribute": "estimated_corporate_sales_revenue",
                        "value": [searchParams.lowest_estimated_sales_revenue]
                    }
                ]
            }
        );
    }else if(searchParams.lowest_estimated_sales_revenue && !searchParams.highest_estimated_sales_revenue){
        requestBody.filter.propositions.push(
            {
                "connective": "or",
                "propositions": [
                    {
                        "relation": "less_than",
                        "attribute": "corporate_sales_revenue",
                        "value": [searchParams.highest_estimated_sales_revenue]
                    },
                    {
                        "relation": "less_than",
                        "attribute": "estimated_corporate_sales_revenue",
                        "value": [searchParams.highest_estimated_sales_revenue]
                    }
                ]
            }
        );
    }

    //append categories
    if(searchParams.categories.length){
        requestBody.filter.propositions.push({
            "relation": "in",
            "attribute": "sic_code_ids",
            "value": searchParams.categories.map(categoryObj => categoryObj.value)
        })
    }

    //append location
    if(searchParams.location && searchParams.mile_radius){
        requestBody.filter.propositions.push({
            "relation": "geo_distance",
            "value": {
            "distance": searchParams.mile_radius + "mi",
            "lat": searchParams.location.lat,
            "lon": searchParams.location.lng
            }
        })
    }

    if(searchParams.limit){
        requestBody['limit'] = searchParams.limit
    }

    return(requestBody);
}