import { React } from 'react';
import Button from '@mui/material/Button';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { Grid } from '@mui/material';
import styled from 'styled-components';

export default function UploadImage(props) {
    
    const ImageName = styled.span`
    line-height:0px;
    font-size: 13px;
    color: #0e9384;
    font-weight: bold;
    `;

    const RequiredImage = styled.span`
    line-height:0px;
    font-size: 13px;
    color: red;
    font-weight: bold;
    `;

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      }).catch(error => {
        // Code to handle the error
        console.error('An error occurred during UploadImage:', error);
    });  

    const handleFile = async(event) => {
        const file = event.target.files[0];
        const base64 = await toBase64(file);
        props.handleImageUpdate({"image-title":file.name, "image-content": base64, "image-type": file.type})
    }

    return (
    <Grid container direction="row" alignItems="center" justifyContent="center">
        <Grid item xs={11} sm={6.5}>
            <Button variant="contained" component="label" sx={{backgroundColor:"#0e9384", fontWeight:"bold", borderRadius:"10px"}}>
                Upload An Image Of Your Business
                <input hidden accept="image/*" type="file" onChange={handleFile} id="image"/>
                <PhotoCamera sx={{pl:"10px"}}/>
            </Button>
        </Grid>
        {
            props.businessDetails['image-title'] && 
            <Grid item xs={11} sm={5.5} sx={{padding:"20px", textAlign:"center"}}>
                <img src={props.businessDetails['image-content']} alt="BusinessPhoto" style={{width:"100%", borderRadius: "10px"}}/>
                <ImageName>{props.businessDetails['image-title']}</ImageName>
            </Grid>
        }
        {
            !props.businessDetails['image-title'] && props.requireImage &&
            <Grid item xs={11} sm={5.5} sx={{textAlign:"center"}}>
                <RequiredImage>A listing image is required.</RequiredImage>
            </Grid> 
        }
    </Grid>
    );
}
