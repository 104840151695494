import Modal from '@mui/material/Modal';
import { Box, Button } from '@mui/material';
import { Link } from "react-router-dom";
// import { HashLink } from 'react-router-hash-link';

const requireLoginStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: '75%',
    backgroundColor: '#ffffff',
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };

const SignUpModal = (props) => {
    const handleClose = () => props.setDisplayModal(false);
    return (
      <div>
        <Modal
          open={props.displayModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          >
          <Box sx={requireLoginStyle}>
              <section className="banner-section">
              {/* <img src={pattern} alt="" className="pattern" /> */}
              <div className="container">
                  <div className="banner-content">
                      <div className="content">
                          {/* <h4>Ready to take the next step towards owning your own business?</h4> */}
                          <p style={{textAlign:"center", marginBottom:"10px", color:"#0e9384"}}>
                          To unlock more info about this opportunity, <b>Login</b> or <b>Sign Up</b>. Get access to our exclusive listings and resources in seconds. Discover your next venture today!
                          </p>
                      </div>
                      <div style={{textAlign:"center"}}>
                      <Link to={{ pathname: '/login'}} >
                          <Button variant="contained" sx={{
                              backgroundColor: "#0e9384", 
                              color: "#ffffff", 
                              mt:"8px", mb:"8px", 
                              fontWeight:"bold",
                              borderRadius:"24px",
                              ':hover': {
                                  bgcolor: '#13548b', // theme.palette.primary.main
                                  color: '#ffffff',
                                },
                              }}>
                                  Login
                          </Button>
                      </Link>
                      <span style={{paddingLeft:"5px", paddingRight:"5px", color:"#0e9384"}}><b>OR</b></span>
                      <Link to={{ pathname: "/signup?isBroker=false" }}> 
                          <Button variant="contained" sx={{
                              backgroundColor: "#0e9384", 
                              color: "#ffffff", 
                              mt:"8px", mb:"8px", 
                              fontWeight:"bold",
                              borderRadius:"24px",
                              ':hover': {
                                bgcolor: '#13548b', // theme.palette.primary.main
                                color: '#ffffff',
                                },
                              }}>
                                  Sign Up
                          </Button>
                      </Link>
                      </div>
                  </div>
              </div>
          </section>
          </Box>
          </Modal>
      </div>
    );
  }

  export default SignUpModal;