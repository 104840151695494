import { React, useState } from 'react';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import {Box, Grid, InputAdornment} from '@mui/material';
import Typography from '@mui/material/Typography';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Divider from '@mui/material/Divider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Slider from '@mui/material/Slider';
import LocationSearch from '../LocationSearch';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Industries } from '../../../helperData/ListingInfoData';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import '../../../pages/Landing/App.scss'

const sortByList = [
  "Keywords",
  "Newest",
  "Oldest",
  "Highest Price",
  "Lowest Price",
  "Highest Cash Flow",
  "Lowest Cash Flow",
  "Highest Revenue",
  "Lowest Revenue",
]

const theme = createTheme({
    typography: {
      body1: {
        fontFamily:"'Plus Jakarta Sans', sans-serif",
      }
    }
  });

const drawerBleeding = 56;
const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

const dividerStyle = {
  width: "100%",
  marginBottom : "5px"
}

const FilterMobile = (props) => {
 const [open, setOpen] = useState(false);
 const toggleDrawer = (newOpen) => () => {setOpen(newOpen)};
 const [filterDisplay, setFilterDisplay] = useState({
    "expandPrice" : true,
    "expandCashFlow" : true,
    "expandRevenue" : true,
    "expandIndustry" : true,
    "expandLocation" : true,
    "expandSort" : true,
    "expandDealSource" : true
  });
  
  //sort by  
 const handlePriceUpdate = (event, newPrice) => {
    props.setFilterMap({...props.filterMap, "lowest_price" : newPrice[0], "highest_price": newPrice[1]});
  };

 const handleCashFlowUpdate = (event, newCashFlow) => {    
    props.setFilterMap({...props.filterMap, "lowest_cash_flow" : newCashFlow[0], "highest_cash_flow": newCashFlow[1]});
  };

  const handleRevenueUpdate = (event, newRevenue) => {
    props.setFilterMap({...props.filterMap, "lowest_revenue" : newRevenue[0], "highest_revenue": newRevenue[1]});
  };

  const handleFilterUpdate = (event) => {
    const targetId = (event.target.id) ? event.target.id : event.target.name;
    props.setFilterMap({...props.filterMap, [targetId] : event.target.value});
}

  const handleIndustries = (industry) => {
    if(industry in props.filterMap.industries){
      delete props.filterMap.industries[industry];
      props.setFilterMap({...props.filterMap})
    }else{
      let industryMap = props.filterMap.industries;
      industryMap[industry] = true;
      props.setFilterMap({...props.filterMap, "industries": industryMap});
    }
  } 

  const handleSource = (source) => {
    props.setFilterMap({...props.filterMap, [source] : !props.filterMap[source]});
  }

 const handleApplyFilters = () => {
  setOpen(false);
  if (!window.location.host.includes('localhost') && !window.location.host.includes('dev.onedeal.biz')) {
    const gtagParamMap = {
      button: "search",
    }
    window.gtag("event", "button_click", gtagParamMap);
  }
  props.getFilteredListings();
 }

  return (
    <ThemeProvider theme={theme}>
        <Grid container direction="row" justifyContent="center" alignItems="center" marginTop="8px">
          <Global
            styles={{
              '@media(max-Width: 1023px)' : {
                '.MuiDrawer-root > .MuiPaper-root': {
                  height: `calc(90% - ${drawerBleeding}px)`,
                  overflow: 'visible', 
                }
              },
            }}
          />

        <Grid item xs={10} sx={{backgroundColor:"white", borderRadius : "10px", mt:"8px", mb:"8px", zIndex:0}}>
            <TextField id="search_phrase" 
                label="Search For Businesses ..." 
                variant="outlined" 
                sx={{width:"100%", '& .MuiOutlinedInput-root': { borderRadius: "10px"}}}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='start'>
                            <SearchIcon />
                        </InputAdornment>
                    )
                }}
                onChange={handleFilterUpdate}
                onKeyDown={(e) => {if(e.code === 'NumpadEnter' || e.code === 'Enter'){props.getFilteredListings()}}}
                value={props.filterMap.search_phrase}
            />
        </Grid>
        
        <SwipeableDrawer
          anchor="bottom"
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          swipeAreaWidth={drawerBleeding}
          disableSwipeToOpen={false}
          ModalProps={{
            keepMounted: true,
          }}
          
        >
          <StyledBox
            sx={{
              position: 'absolute',
              top: -drawerBleeding,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              visibility: 'visible',
              right: 0,
              left: 0,
            }}
          >
            <Puller  />
            <Typography sx={{ p: 2, color: 'text.secondary' }}>{props.numberOfListings} Businesses Shown</Typography>
          </StyledBox>
          <Grid container direction="row" justifyContent="center" alignItems="center" overflow="scroll">
            <Grid item xs={11.5} sx={{display:"inline-flex"}}>
              <Grid item xs={6}>
                <span>Price</span>
              </Grid>
              <Grid item xs={6}>
                {
                  filterDisplay.expandPrice &&
                  <IconButton onClick={() => setFilterDisplay({...filterDisplay, "expandPrice" : false})} sx={{float:"right", padding: "0px"}}>
                    <AddIcon/>
                  </IconButton>
                }
                {
                   !filterDisplay.expandPrice &&
                   <IconButton onClick={() => setFilterDisplay({...filterDisplay, "expandPrice" : true})} sx={{float:"right", padding: "0px"}}>
                     <RemoveIcon/>
                   </IconButton>
                }
              </Grid>
            </Grid>
            {
                !filterDisplay.expandPrice &&
                <>
                <Grid item xs={12} sx={{fontSize: "4vw", textAlign:'center'}}>
                  <Typography id="non-linear-slider" gutterBottom sx={{fontSize:"12.25px", fontWeight:"bold", paddingTop:'1px'}}>
                      {"Price: $" + props.filterMap.lowest_price.toLocaleString('en-US') + " - $" + props.filterMap.highest_price.toLocaleString('en-US')}
                  </Typography>
                  <Slider
                      max={30000000}
                      min={0}
                      step={100000}
                      value={[props.filterMap.lowest_price, props.filterMap.highest_price]}
                     onChange={handlePriceUpdate}
                      sx={{color:"#0e9384", width:"85%"}}
                      size="small"
                  />
                </Grid>
                </>
              }
            <Divider orientation="horizontal" sx={dividerStyle}/>
            <Grid item xs={11.5} sx={{display:"inline-flex"}}>
              <Grid item xs={6}>
                <span>Cash Flow</span>
              </Grid>
              <Grid item xs={6}>
                {
                  filterDisplay.expandCashFlow &&
                  <IconButton onClick={() => setFilterDisplay({...filterDisplay, "expandCashFlow" : false})} sx={{float:"right", padding: "0px"}}>
                    <AddIcon/>
                  </IconButton>
                }
                {
                   !filterDisplay.expandCashFlow &&
                   <IconButton onClick={() => setFilterDisplay({...filterDisplay, "expandCashFlow" : true})} sx={{float:"right", padding: "0px"}}>
                     <RemoveIcon/>
                   </IconButton>
                }
              </Grid>
            </Grid>
            {
                !filterDisplay.expandCashFlow &&
                <>
                <Grid item xs={12} sx={{fontSize: "4vw", textAlign:'center'}}>
                <Typography id="non-linear-slider" gutterBottom sx={{fontSize:"12.25px", fontWeight:"bold"}}>
                        {"Cash Flow: $" + props.filterMap.lowest_cash_flow.toLocaleString('en-US') + " - $" + props.filterMap.highest_cash_flow.toLocaleString('en-US')}
                    </Typography>
                    <Slider
                        max={30000000}
                        min={0}
                        step={100000}
                        value={[props.filterMap.lowest_cash_flow, props.filterMap.highest_cash_flow]}
                        onChange={handleCashFlowUpdate}
                        sx={{color:"#0e9384", width:"85%"}}
                        size="small"
                    />
                </Grid>
                </>
              }
            <Divider orientation="horizontal" sx={dividerStyle}/>
            <Grid item xs={11.5} sx={{display:"inline-flex"}}>
              <Grid item xs={6}>
                <span>Revenue</span>
              </Grid>
              <Grid item xs={6}>
                {
                  filterDisplay.expandRevenue &&
                  <IconButton onClick={() => setFilterDisplay({...filterDisplay, "expandRevenue" : false})} sx={{float:"right", padding: "0px"}}>
                    <AddIcon/>
                  </IconButton>
                }
                {
                   !filterDisplay.expandRevenue &&
                   <IconButton onClick={() => setFilterDisplay({...filterDisplay, "expandRevenue" : true})} sx={{float:"right", padding: "0px"}}>
                     <RemoveIcon/>
                   </IconButton>
                }
              </Grid>
            </Grid>
            {
              !filterDisplay.expandRevenue &&
              <>
              <Grid item xs={12} sx={{fontSize: "4vw", textAlign:'center'}}>
              <Typography id="non-linear-slider" gutterBottom sx={{fontSize:"12.25px", fontWeight:"bold"}}>
              {"Revenue: $" + props.filterMap.lowest_revenue.toLocaleString('en-US') + " - $" + props.filterMap.highest_revenue.toLocaleString('en-US')}
                  </Typography>
                  <Slider
                      max={30000000}
                      min={0}
                      step={100000}
                      value={[props.filterMap.lowest_revenue, props.filterMap.highest_revenue]}
                      onChange={handleRevenueUpdate}
                      sx={{color:"#0e9384", width:"85%"}}
                      size="small"
                  />
              </Grid>
              </>
            }
            <Divider orientation="horizontal" sx={dividerStyle}/>
            <Grid item xs={11.5} sx={{display:"inline-flex"}}>
              <Grid item xs={6}>
                <span>Deal Source</span>
              </Grid>
              <Grid item xs={6}>
                {
                  filterDisplay.expandDealSource &&
                  <IconButton onClick={() => setFilterDisplay({...filterDisplay, "expandDealSource" : false})} sx={{float:"right", padding: "0px"}}>
                    <AddIcon/>
                  </IconButton>
                }
                {
                   !filterDisplay["expandDealSource"] &&
                   <IconButton onClick={() => setFilterDisplay({...filterDisplay, "expandDealSource" : true})} sx={{float:"right", padding: "0px"}}>
                     <RemoveIcon/>
                   </IconButton>
                }
              </Grid>
            </Grid>
            {
                !filterDisplay["expandDealSource"] &&
                <Grid item xs={12}>
                  <FormGroup>
                  <FormControlLabel 
                            key="aggregated"
                            control={<Checkbox size='small' onChange={() => handleSource("aggregated")}/>} 
                            label="Aggregated"
                            checked={props.filterMap['aggregated']}
                            sx={
                              { 
                                '& .MuiFormControlLabel-label': { fontFamily: "'Plus Jakarta Sans', sans-serif"},
                                margin: "0"
                              }
                            }
                      />
                       <FormControlLabel 
                            key="direct"
                            control={<Checkbox size='small' onChange={() => handleSource("direct")}/>} 
                            label="Direct"
                            checked={props.filterMap['direct']}
                            sx={
                              { 
                                '& .MuiFormControlLabel-label': { fontFamily: "'Plus Jakarta Sans', sans-serif"},
                                margin: "0"
                              }
                            }
                      />
                  </FormGroup>
              </Grid>
              }
            <Divider orientation="horizontal" sx={dividerStyle}/>
            <Grid item xs={11.5} sx={{display:"inline-flex"}}>
              <Grid item xs={6}>
                <span>Industry</span>
              </Grid>
              <Grid item xs={6}>
                {
                  filterDisplay.expandIndustry &&
                  <IconButton onClick={() => setFilterDisplay({...filterDisplay, "expandIndustry" : false})} sx={{float:"right", padding: "0px"}}>
                    <AddIcon/>
                  </IconButton>
                }
                {
                   !filterDisplay.expandIndustry &&
                   <IconButton onClick={() => setFilterDisplay({...filterDisplay, "expandIndustry" : true})} sx={{float:"right", padding: "0px"}}>
                     <RemoveIcon/>
                   </IconButton>
                }
              </Grid>
            </Grid>
            {
                !filterDisplay.expandIndustry &&
                <>
                <Grid item xs={12} sx={{fontSize: "4vw"}}>
                  <FormGroup>
                    {
                      Industries.map((industry) => {
                        return(
                          <FormControlLabel 
                          control={<Checkbox size='small' onChange={() => handleIndustries(industry)}/>} 
                          label={industry}
                          checked={industry in props.filterMap.industries}
                          sx={
                            { 
                              '& .MuiFormControlLabel-label': { fontFamily: "'Plus Jakarta Sans', sans-serif"},
                              margin: "0"
                            }
                          }
                          />
                        )
                      })
                    }
                  </FormGroup>
                </Grid>
                </>
              }
            <Divider orientation="horizontal" sx={dividerStyle}/>
            <Grid item xs={11.5} sx={{display:"inline-flex"}}>
              <Grid item xs={6}>
                <span>Location</span>
              </Grid>
              <Grid item xs={6}>
                {
                  filterDisplay.expandLocation &&
                  <IconButton onClick={() => setFilterDisplay({...filterDisplay, "expandLocation" : false})} sx={{float:"right", padding: "0px"}}>
                    <AddIcon/>
                  </IconButton>
                }
                {
                   !filterDisplay.expandLocation &&
                   <IconButton onClick={() => setFilterDisplay({...filterDisplay, "expandLocation" : true})} sx={{float:"right", padding: "0px"}}>
                     <RemoveIcon/>
                   </IconButton>
                }
              </Grid>
            </Grid>
            {
                !filterDisplay.expandLocation &&
                <>
                <Grid item xs={12} sx={{fontSize: "4vw"}}>
                  <LocationSearch infoMap={props.filterMap} setInfoMap={props.setFilterMap} required={false} error={false} label="Search by city or state" locations={props.locations}/>
                </Grid>
                </>
              }
              <Divider orientation="horizontal" sx={dividerStyle}/>
            <Grid item xs={11.5} sx={{display:"inline-flex"}}>
              <Grid item xs={6}>
                <span>Sort By</span>
              </Grid>
              <Grid item xs={6}>
                {
                  filterDisplay.expandSort &&
                  <IconButton onClick={() => setFilterDisplay({...filterDisplay, "expandSort" : false})} sx={{float:"right", padding: "0px"}}>
                    <AddIcon/>
                  </IconButton>
                }
                {
                   !filterDisplay["expandSort"] &&
                   <IconButton onClick={() => setFilterDisplay({...filterDisplay, "expandSort" : true})} sx={{float:"right", padding: "0px"}}>
                     <RemoveIcon/>
                   </IconButton>
                }
              </Grid>
            </Grid>
            {
                !filterDisplay["expandSort"] &&
                <Grid item xs={12}>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="Keywords"
                      name="radio-buttons-group"
                      onChange={(event) => props.setFilterMap({...props.filterMap, "sort_by" : event.target.value})}
                      sx={{}}
                    >
                      {
                        sortByList.map((sortByItem) => {
                          return(
                            <FormControlLabel sx={{
                              '& .MuiFormControlLabel-label': { fontFamily: "'Plus Jakarta Sans', sans-serif", fontSize:"4vw", '@media(min-Width: 768px)' : {fontSize: "2vw" }},
                              margin:"0px"
                            }} value={sortByItem} control={<Radio size='small'/>} label={sortByItem} />    
                          )
                        })
                      }
                    </RadioGroup>
                  </FormControl>
              </Grid>
              }
            <Divider orientation="horizontal" sx={dividerStyle}/>
            <Grid item xs={12} sx={{textAlign:"center",paddingBottom:"2px"}}>
                <button onClick={() => handleApplyFilters()} className='listingDetails-btn'>Apply Filters</button>
            </Grid>
          </Grid>
        </SwipeableDrawer>
        </Grid>
    </ThemeProvider>
  );
}

export default FilterMobile;