import { React, useState, useEffect } from "react";
import { Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import { upsertDealScoutList } from "../../../services/DealScout";
import SearchList from "./SearchList";

const dividerStyle = {
  top: "272px",
  left: "154px",
  width: "1096px",
  height: "2px",
  backgroundColor: "#f0f0f0",
  borderRadius: "2px",
};

const SearchResults = (props) => {
  //state management
  const [displayPopup, setDisplayPopup] = useState(false);

  const [displayNewList, setDisplayNewList] = useState(false);
  const [selectListDropDown, setSelectListDropDown] = useState("");
  const [currentList, setCurrentList] = useState({
    name: "",
    businesses: {},
    lastModified: Date.now(),
  });
  const [noListName, setNoListName] = useState(false);
  //hanlder functions
  const handleListSelect = (event) => {
    setSelectListDropDown(event.target.value);
    if (event.target.value in props.usersDealScouts.scoutData.lists) {
      setCurrentList(props.usersDealScouts.scoutData.lists[event.target.value]);
    }
  };

  const handleListName = (event) => {
    // setCurrentList({...currentList, [event.target.id] : event.target.value});
    setCurrentList({
      name: event.target.value,
      businesses: {},
      lastModified: Date.now(),
    });
  };

  const handleAddRemoveFromList = async (businessObj) => {
    if (!selectListDropDown) {
      setNoListName(true);
    } else {
      let updatedBusinesses = currentList.businesses;
      if (businessObj.infogroup_id in currentList.businesses) {
        delete updatedBusinesses[businessObj.infogroup_id];
      } else {
        updatedBusinesses[businessObj.infogroup_id] = businessObj;
      }

      setCurrentList({ ...currentList, businesses: updatedBusinesses });
      await upsertDealScoutList({
        listId: selectListDropDown,
        listBody: currentList,
      });
    }
    if (!selectListDropDown) {
      setDisplayPopup(true);
    } else {
      setDisplayPopup(false);
    }
  };

  useEffect(() => {}, [currentList]);

  return (
    <div>
      {" "}
      {displayPopup && (
        <div className="popup-overlay">
          <div className="popup">
            <h3>Please select or create a list to start.</h3>
            <button
              variant="contained"
              className="listingDetails-btn"
              onClick={() => setDisplayPopup(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
      <Grid container direction="row" justifyContent="center" spacing={2}>
        <Grid item xs={10}>
          <h1 style={{ color: "black" }}>Search Results</h1>
        </Grid>
        {!displayNewList && (
          <Grid
            item
            xs={5}
            sx={{ backgroundColor: "white", borderRadius: "10px" }}
          >
            <FormControl sx={{ m: 1, minWidth: "100%" }}>
              <InputLabel id="demo-simple-select-helper-label">
                Select A Saved List Or Create A New One
              </InputLabel>
              <Select
                //   labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={selectListDropDown}
                label="Select A Saved List Or Create A New One"
                onChange={handleListSelect}
                error={noListName}
                sx={{ borderRadius: "24px" }}
              >
                <MenuItem value="" onClick={() => setDisplayNewList(true)}>
                  Create New List
                </MenuItem>
                {props.usersDealScouts &&
                  "scoutData" in props.usersDealScouts &&
                  Object.keys(props.usersDealScouts.scoutData.lists).map(
                    (key) => {
                      return (
                        <MenuItem value={key}>
                          {props.usersDealScouts.scoutData.lists[key].name}
                        </MenuItem>
                      );
                    }
                  )}
              </Select>
            </FormControl>
          </Grid>
        )}
        {displayNewList && (
          <>
            <Grid item xs={10}>
              <TextField
                id="name"
                label="Enter Your New List Name Here!"
                variant="outlined"
                onMouseLeave={async () => {
                  await upsertDealScoutList({
                    listId: selectListDropDown,
                    listBody: currentList,
                  }).then((response) => {
                    setDisplayNewList(false);
                    setSelectListDropDown(response["updated-list-id"]);
                    setCurrentList(
                      response.Attributes.lists[response["updated-list-id"]]
                    );
                    props.setUsersDealScouts({
                      scoutData: response.Attributes,
                      userExists: true,
                    });
                    setNoListName(false);
                  });
                }}
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": { borderRadius: "24px" },
                }}
                // InputProps={{
                //     endAdornment: (
                //         <InputAdornment position='start'>
                //             <AddLocationIcon />
                //         </InputAdornment>
                //     )
                // }}
                onChange={handleListName}
                // onKeyDown={(e) => {if(e.code === 'NumpadEnter' || e.code === 'Enter'){props.getFilteredListings()}}}
                value={currentList.name}
              />
            </Grid>
            {/* <Grid item xs={5}>
              <button
                onClick={async () =>
                  await upsertDealScoutList({
                    listId: selectListDropDown,
                    listBody: currentList,
                  }).then((response) => {
                    setDisplayNewList(false);
                    setSelectListDropDown(response["updated-list-id"]);
                    setCurrentList(
                      response.Attributes.lists[response["updated-list-id"]]
                    );
                    props.setUsersDealScouts({
                      scoutData: response.Attributes,
                      userExists: true,
                    });
                    setNoListName(false);
                  })
                }
                variant="contained"
                className="listingDetails-btn"
              >
                Save This List
              </button>
            </Grid> */}
          </>
        )}
        {props.searchResults &&
          props.searchResults
            .slice(props.selectedPageRange[0], props.selectedPageRange[1])
            .map((biz, index) => {
              let existsInList = biz.infogroup_id in currentList.businesses;
              return (
                <>
                  <Divider orientation="horizontal" sx={dividerStyle} />
                  <SearchList
                    biz={biz}
                    existsInList={existsInList}
                    handleAddRemoveFromList={handleAddRemoveFromList}
                    saveButtonState={true}
                  />
                </>
              );
            })}
      </Grid>
    </div>
  );
};
export default SearchResults;
