import React from "react";
import "./DealScoutLandingPage.css";

const Labels = ({ props }) => {
  return (
    <div>
      {props.position === "left" ? (
        <section className="cardSection">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              width: "40%",
            }}
          >
            <div className="chip">{props.chip}</div>
            <div className="cardDiv">
              <h3>{props.title}</h3>
              <p>{props.paragraph}</p>
            </div>
            <hr />
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "1rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.75rem",
                  width: "100%",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  height="49"
                  viewBox="0 0 48 49"
                  fill="none"
                >
                  <g clip-path="url(#clip0_404_3176)">
                    <rect
                      y="-0.517578"
                      width="48"
                      height="48"
                      rx="12"
                      fill="#0E9384"
                    />
                    <path
                      d="M24 24.5C23.2583 24.5 22.5333 24.2801 21.9166 23.868C21.2999 23.456 20.8193 22.8703 20.5355 22.1851C20.2516 21.4998 20.1774 20.7458 20.3221 20.0184C20.4668 19.291 20.8239 18.6228 21.3484 18.0984C21.8728 17.5739 22.541 17.2168 23.2684 17.0721C23.9958 16.9274 24.7498 17.0016 25.4351 17.2855C26.1203 17.5693 26.706 18.0499 27.118 18.6666C27.5301 19.2833 27.75 20.0083 27.75 20.75C27.7488 21.7442 27.3533 22.6973 26.6503 23.4003C25.9473 24.1033 24.9942 24.4988 24 24.5ZM24 18.5C23.555 18.5 23.12 18.632 22.75 18.8792C22.38 19.1264 22.0916 19.4778 21.9213 19.889C21.751 20.3001 21.7064 20.7525 21.7932 21.189C21.8801 21.6254 22.0943 22.0263 22.409 22.341C22.7237 22.6557 23.1246 22.87 23.561 22.9568C23.9975 23.0436 24.4499 22.999 24.861 22.8287C25.2722 22.6584 25.6236 22.37 25.8708 22C26.118 21.63 26.25 21.195 26.25 20.75C26.2494 20.1534 26.0122 19.5815 25.5903 19.1597C25.1685 18.7378 24.5966 18.5006 24 18.5Z"
                      fill="white"
                    />
                    <path
                      d="M24 33.5L17.673 26.0383C17.5851 25.9262 17.4981 25.8135 17.412 25.7C16.3312 24.2763 15.7474 22.5374 15.75 20.75C15.75 18.562 16.6192 16.4635 18.1664 14.9164C19.7136 13.3692 21.812 12.5 24 12.5C26.188 12.5 28.2865 13.3692 29.8336 14.9164C31.3808 16.4635 32.25 18.562 32.25 20.75C32.2526 22.5366 31.669 24.2747 30.5888 25.6978L30.588 25.7C30.588 25.7 30.363 25.9955 30.3293 26.0353L24 33.5ZM18.609 24.7963C18.6105 24.7963 18.7845 25.0272 18.8243 25.0767L24 31.181L29.1825 25.0685C29.2155 25.0272 29.391 24.7948 29.3918 24.794C30.2746 23.6308 30.7517 22.2103 30.75 20.75C30.75 18.9598 30.0388 17.2429 28.773 15.977C27.5071 14.7112 25.7902 14 24 14C22.2098 14 20.4929 14.7112 19.227 15.977C17.9612 17.2429 17.25 18.9598 17.25 20.75C17.2484 22.2112 17.7253 23.6326 18.609 24.7963Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_404_3176">
                      <rect
                        width="48"
                        height="48"
                        fill="white"
                        transform="translate(0 0.482422)"
                      />
                    </clipPath>
                  </defs>
                </svg>{" "}
                <h5 style={{ margin: "0" }}>{props.note_1}</h5>
                <p>{props.note_2}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.75rem",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  height="49"
                  viewBox="0 0 48 49"
                  fill="none"
                >
                  <rect
                    y="0.482422"
                    width="48"
                    height="48"
                    rx="12"
                    fill="#0E9384"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M27 21.4824H21V27.4824H27V21.4824V21.4824ZM25 25.4824H23V23.4824H25V25.4824V25.4824ZM33 23.4824V21.4824H31V19.4824C31 18.3824 30.1 17.4824 29 17.4824H27V15.4824H25V17.4824H23V15.4824H21V17.4824H19C17.9 17.4824 17 18.3824 17 19.4824V21.4824H15V23.4824H17V25.4824H15V27.4824H17V29.4824C17 30.5824 17.9 31.4824 19 31.4824H21V33.4824H23V31.4824H25V33.4824H27V31.4824H29C30.1 31.4824 31 30.5824 31 29.4824V27.4824H33V25.4824H31V23.4824H33V23.4824ZM29 29.4824H19V19.4824H29V29.4824V29.4824Z"
                    fill="white"
                  />
                </svg>
                <h5 style={{ margin: "0" }}>{props.note_3}</h5>
                <p>{props.note_4}</p>
              </div>
            </div>
          </div>
          <img src={props.img} className="cardImg" alt="" />
        </section>
      ) : (
        <section className="cardSection">
          <img src={props.img} className="cardImg" alt="" />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              width: "40%",
            }}
          >
            <div className="chip">{props.chip}</div>
            <div className="cardDiv">
              <h3>{props.title}</h3>
              <p>{props.paragraph}</p>
            </div>
            <hr />
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "1rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.75rem",
                  width: "100%",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  height="49"
                  viewBox="0 0 48 49"
                  fill="none"
                >
                  <g clip-path="url(#clip0_404_3176)">
                    <rect
                      y="-0.517578"
                      width="48"
                      height="48"
                      rx="12"
                      fill="#0E9384"
                    />
                    <path
                      d="M24 24.5C23.2583 24.5 22.5333 24.2801 21.9166 23.868C21.2999 23.456 20.8193 22.8703 20.5355 22.1851C20.2516 21.4998 20.1774 20.7458 20.3221 20.0184C20.4668 19.291 20.8239 18.6228 21.3484 18.0984C21.8728 17.5739 22.541 17.2168 23.2684 17.0721C23.9958 16.9274 24.7498 17.0016 25.4351 17.2855C26.1203 17.5693 26.706 18.0499 27.118 18.6666C27.5301 19.2833 27.75 20.0083 27.75 20.75C27.7488 21.7442 27.3533 22.6973 26.6503 23.4003C25.9473 24.1033 24.9942 24.4988 24 24.5ZM24 18.5C23.555 18.5 23.12 18.632 22.75 18.8792C22.38 19.1264 22.0916 19.4778 21.9213 19.889C21.751 20.3001 21.7064 20.7525 21.7932 21.189C21.8801 21.6254 22.0943 22.0263 22.409 22.341C22.7237 22.6557 23.1246 22.87 23.561 22.9568C23.9975 23.0436 24.4499 22.999 24.861 22.8287C25.2722 22.6584 25.6236 22.37 25.8708 22C26.118 21.63 26.25 21.195 26.25 20.75C26.2494 20.1534 26.0122 19.5815 25.5903 19.1597C25.1685 18.7378 24.5966 18.5006 24 18.5Z"
                      fill="white"
                    />
                    <path
                      d="M24 33.5L17.673 26.0383C17.5851 25.9262 17.4981 25.8135 17.412 25.7C16.3312 24.2763 15.7474 22.5374 15.75 20.75C15.75 18.562 16.6192 16.4635 18.1664 14.9164C19.7136 13.3692 21.812 12.5 24 12.5C26.188 12.5 28.2865 13.3692 29.8336 14.9164C31.3808 16.4635 32.25 18.562 32.25 20.75C32.2526 22.5366 31.669 24.2747 30.5888 25.6978L30.588 25.7C30.588 25.7 30.363 25.9955 30.3293 26.0353L24 33.5ZM18.609 24.7963C18.6105 24.7963 18.7845 25.0272 18.8243 25.0767L24 31.181L29.1825 25.0685C29.2155 25.0272 29.391 24.7948 29.3918 24.794C30.2746 23.6308 30.7517 22.2103 30.75 20.75C30.75 18.9598 30.0388 17.2429 28.773 15.977C27.5071 14.7112 25.7902 14 24 14C22.2098 14 20.4929 14.7112 19.227 15.977C17.9612 17.2429 17.25 18.9598 17.25 20.75C17.2484 22.2112 17.7253 23.6326 18.609 24.7963Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_404_3176">
                      <rect
                        width="48"
                        height="48"
                        fill="white"
                        transform="translate(0 0.482422)"
                      />
                    </clipPath>
                  </defs>
                </svg>{" "}
                <h5 style={{ margin: "0" }}>{props.note_1}</h5>
                <p>{props.note_2}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.75rem",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  height="49"
                  viewBox="0 0 48 49"
                  fill="none"
                >
                  <rect
                    y="0.482422"
                    width="48"
                    height="48"
                    rx="12"
                    fill="#0E9384"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M27 21.4824H21V27.4824H27V21.4824V21.4824ZM25 25.4824H23V23.4824H25V25.4824V25.4824ZM33 23.4824V21.4824H31V19.4824C31 18.3824 30.1 17.4824 29 17.4824H27V15.4824H25V17.4824H23V15.4824H21V17.4824H19C17.9 17.4824 17 18.3824 17 19.4824V21.4824H15V23.4824H17V25.4824H15V27.4824H17V29.4824C17 30.5824 17.9 31.4824 19 31.4824H21V33.4824H23V31.4824H25V33.4824H27V31.4824H29C30.1 31.4824 31 30.5824 31 29.4824V27.4824H33V25.4824H31V23.4824H33V23.4824ZM29 29.4824H19V19.4824H29V29.4824V29.4824Z"
                    fill="white"
                  />
                </svg>
                <h5 style={{ margin: "0" }}>{props.note_3}</h5>
                <p>{props.note_4}</p>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default Labels;
