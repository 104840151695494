import * as React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import buyers from "../../assets/Landing/buyers.webp";
import brokereddeals from "../../assets/Landing/brokereddeals.webp";

const Switches = () => {
  const theme = createTheme({
    components: {
      // Name of the component ⚛️
      MuiButtonBase: {
        defaultProps: {
          // The props to apply
          disableRipple: true, // No more ripple, on the whole application 💣!
        },
      },
    },
  });
  const [switchOn, setSwitchOn] = React.useState(true);
  const handleSwitch = () => {
    setSwitchOn(!switchOn);
  };
  return (
    <div>
      {switchOn ? (
        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack spacing={2} direction="row">
            <ThemeProvider theme={theme}>
              <Button
                style={{
                  backgroundColor: "var(--base)",
                  fontWeight: "700",
                  borderRadius: "2rem",
                  padding: "1.2rem",
                }}
                variant="contained"
              >
                Proprietary Deal Sourcing
              </Button>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <Button
                style={{
                  color: "var(--base)",
                  fontWeight: "700",
                  borderRadius: "2rem",
                  padding: "1.2rem",
                }}
                onClick={handleSwitch}
              >
                Marketplace
              </Button>
            </ThemeProvider>
          </Stack>{" "}
          <img
            src={buyers}
            alt="img"
            style={{
              maxWidth: "75%",
              margin: "4rem 0 6rem 0 ",
              transitionDelay: "1s",
            }}
          ></img>{" "}
        </div>
      ) : (
        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {" "}
          <Stack spacing={2} direction="row">
            <ThemeProvider theme={theme}>
              <Button
                style={{
                  color: "var(--base)",
                  fontWeight: "700",
                  borderRadius: "2rem",
                  padding: "1.2rem",
                }}
                onClick={handleSwitch}
              >
                Proprietary Deal Sourcing
              </Button>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <Button
                style={{
                  backgroundColor: "var(--base)",
                  fontWeight: "700",
                  borderRadius: "2rem",
                  padding: "1.2rem",
                }}
                variant="contained"
              >
                Marketplace{" "}
              </Button>
            </ThemeProvider>
          </Stack>
          <img
            src={brokereddeals}
            alt="img"
            style={{
              maxWidth: "75%",
              margin: "4rem 0 6rem 0 ",
              transitionDelay: "1s",
            }}
          ></img>{" "}
        </div>
      )}
    </div>
  );
};

export default Switches;
