import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// Retrieve a Checkout Session
// Use the session ID
// initialize();

const PaymentConfirmation = () => {
    const navigate = useNavigate();
    const initialize = async() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessionId = urlParams.get('session_id');
        const response = await fetch(`/api/stripe/session-status?session_id=${sessionId}`);
        const session = await response.json();
      // Handle the session according to its status
        if (session.status === 'open') {
          // Remount embedded Checkout
          navigate('/deal-scout/checkout');
        } else if (session.status === 'complete') {
            navigate('/deal-scout', 
            {
              replace: true,
              state: {confirmedPayment : true}
            });
        }
    }
    useEffect(()=>{
        initialize()
    },[])
}

export default PaymentConfirmation;