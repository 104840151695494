import { React } from 'react';

export const getDropboxSignUrl = async(email, firstName, lastName, listedBy, listedByEmail, headline, listingId, requiredNda) => {
    const response = await fetch('/api/dropboxsign', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'email' : email,
        'firstName' : firstName,
        'lastName' : lastName,
        'listedBy' : listedBy,
        'listedByEmail' : listedByEmail,
        'headline' : headline,
        'listingId' : listingId,
        'ndaFileName' : requiredNda
    })
    }).then(res => res.json()).then((data) => {
      return data;
    });
    return response;
};