export const createPaymentIntent = async (priceId, priceCost, customerMetadata) => {
  const response = await fetch("/api/stripe/create-payment-intent", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      priceId,
      priceCost,
      customerMetadata
    }),
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    });
  return response;
};

export const updatePaymentIntent = async (paymentId, priceId, priceCost) => {
  const response = await fetch("/api/stripe/update-payment-intent", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      paymentId,
      priceId,
      priceCost,
    }),
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    });
  return response;
};

export const assignCustomerToPaymentIntent = async (paymentId, customerId) => {
  const response = await fetch("/api/stripe/assign-customer-payment-intent", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      paymentId,
      customerId,
    }),
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    });
  return response;
};

export const createCustomer = async () => {
  const response = await fetch("/api/stripe/create-customer", {
    method: "POST",
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    });
  return response;
};

export const getPricesProducts = async () => {
  const response = await fetch("/api/stripe/get-prices-products")
    .then((res) => res.json())
    .then((data) => {
      return data;
    });
  return response;
};

export const getCustomerSubscription = async (customerId) => {
  const response = await fetch("/api/stripe/get-customer-subscription", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      customerId,
    }),
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    });
  return response;
};

export const cancelSubscription = async (subscriptionId) => {
  const response = await fetch("/api/stripe/cancel-subscription", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      subscriptionId,
    }),
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    });
  return response;
}

export const createCheckoutSession = async(priceId, customerId, email) => {
  const response = await fetch("/api/stripe/create-checkout-session", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      price_id : priceId,
      customer_id: customerId,
      'email' : email
    }),
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    });
  return response;
};