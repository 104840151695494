import { React, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { ResetPassword } from '../../components/ForgotPassword/ResetPassword';
import { ConfirmResetPassword } from '../../components/ForgotPassword/ConfirmResetPassword';
import { useEffect } from 'react';
import Footer from '../../components/Footer/Footer';
import artWork from '../../assets/artwork.png'
import SetPageTitle from '../../components/SetPageTitle/SetPageTitle';
const artworkContainer = {
  height: "100%", 
  backgroundColor: "#cfebf8",
  '@media(max-Width: 767px)' : {
      display: "none"
  }
}

const ForgotPassword = () => {
  const [resetPasswordResponse, setResetPasswordResponse] = useState();
  const [email, setEmail] = useState();
  const location = useLocation();

  useEffect(() => {
    if (!window.location.host.includes('localhost') && !window.location.host.includes('dev.onedeal.biz')) {
      window.gtag("event", "page_view", {
        page_path: location.pathname + location.search,
      });
    }
  },[location])

  return (
    <>
    <Box sx={{height:"100%"}}> 
      <SetPageTitle title="Reset Password" />
      <Grid container direction="row" justifyContent="center" alignItems="start" position="relative" sx={{backgroundColor:"#ffffff", height:"100%"}}>
          <Grid item xs={11} sm={6}>
            {!resetPasswordResponse && <ResetPassword setResetPasswordResponse={setResetPasswordResponse} setEmail={setEmail} email={email}/>}
            {resetPasswordResponse && <ConfirmResetPassword email={email}/>}
          </Grid>
          <Grid item xs={6} sx={artworkContainer}>
                <img alt='artwork' src={artWork} style={{ maxWidth:"30%" }}/>
            </Grid>
      </Grid>
    </Box>
    <Footer/>
    </>
  );
}
export default ForgotPassword;