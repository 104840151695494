import { React, useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Footer from '../../../components/Footer/Footer';
import { retrieveListingStatistics } from '../../../services/ListingManagementService';
import { SnackBar } from '../../../components/SnackBar/SnackBar';
import { HashLink } from 'react-router-hash-link';

const columns = [
    { id: 'listing-id', label: 'Listing Title', minWidth: 170 },
    {
      id: 'clicks-day',
      label: 'Daily Clicks',
      minWidth: 170,
      align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'clicks-week',
      label: 'Weekly Clicks',
      minWidth: 170,
      align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'clicks-month',
      label: 'Monthly Clicks',
      minWidth: 170,
      align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'clicks-year',
      label: 'Yearly Clicks',
      minWidth: 170,
      align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'favorited',
      label: 'Favorited',
      minWidth: 170,
      align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'info',
      label: 'Information Requests',
      minWidth: 170,
      align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    },
  ];

const Analytics = (props) => {
    const [stats, setStats] =  useState();
    const [listingIdToTitle, setListingIdToTitle] = useState({});
    const [errorMessage, setErrorMessage] = useState();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    
    const startDate = new Date(today.getFullYear(), 0, 1);
    const days = Math.floor((today - startDate) / (24 * 60 * 60 * 1000));
    
    const currentWeek = Math.ceil(days / 7);
    const currentDay = today.getDay();
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    const getListingStats = async() => {
      let listingIds = [];
      let listingsAndTitles = {};
      for(let x = 0; x < props.userPostedListings.length; x++){
        listingIds.push(props.userPostedListings[x]['listing-id']);
        listingsAndTitles[props.userPostedListings[x]['listing-id']] = props.userPostedListings[x]['headline'];
      }
      setListingIdToTitle(listingsAndTitles);
      const response = await retrieveListingStatistics(listingIds);
      if(response.stats){
        setStats(response.stats);
      }else{
        setErrorMessage("Error pulling listing statistics.")
      }
    }

    useEffect(() => {
      if(!stats && props.userPostedListings.length){
        getListingStats();
      }
    },[stats]);

    return(
        <>
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                      <TableRow>
                      {columns.map((column) => (
                          <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                          >
                          {column.label}
                          </TableCell>
                      ))}
                      </TableRow>
                  </TableHead>
                  {
                    stats && listingIdToTitle && 
                    <TableBody>
                      {stats
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((stat) => {
                          return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={stat['listing-id']}>
                              {columns.map((column) => {
                                let value;
                                switch(column.id){
                                  case('clicks-day'):
                                    value = (stat.clicks[currentYear.toString()]) ? stat.clicks[currentYear.toString()].day[currentDay] : 0;
                                    break;
                                  case('clicks-week'):
                                    value = (stat.clicks[currentYear.toString()]) ? stat.clicks[currentYear.toString()].week[currentWeek] : 0;
                                    break;
                                  case('clicks-month'):
                                    value = (stat.clicks[currentYear.toString()]) ? stat.clicks[currentYear.toString()].month[currentMonth] : 0;
                                    break;
                                  case('clicks-year'):
                                  value = (stat.clicks[currentYear.toString()]) ? stat.clicks[currentYear.toString()].total : 0;
                                    break;
                                  case('favorited'):
                                    value = stat['favorited-by'].length;
                                    break;
                                  case('info'):
                                    value = stat['requested-more-info'].length;
                                    break;
                                  default:
                                    value = listingIdToTitle[stat[column.id]];
                                }
                              return (
                                  <TableCell key={column.id} align={column.align}>
                                  {column.format && typeof value === 'number'
                                      ? column.format(value)
                                      : <HashLink to={`/listingDetails?id=${stat[column.id]}`}>{value}</HashLink>}
                                  </TableCell>
                              );
                              })}
                          </TableRow>
                          );
                      })}
                  </TableBody>
                  }
                  </Table>
              </TableContainer>
              <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={(stats) ? stats.length : 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
              />
          </Paper>
          <Footer/>
          {errorMessage && <SnackBar errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>}
        </>
    )
}
export default Analytics;