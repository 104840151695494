import { React } from 'react';
import { Grid } from '@mui/material';
import Listing from '../../components/Listing/Listing';

const BrokerPostedListings = (props) => {

    return(
      <>
        <Grid container direction="row" justifyContent="center">
          { 
           props.brokerPostedListings && props.userFavorites &&
            props.brokerPostedListings.map((listing) => {
              return(
                <Listing listing={listing} key={listing['listing-id']} indexOfFavorite={props.userFavorites.indexOf(listing['listing-id'])} setUserFavorites={props.setUserFavorites} setErrorMessage={props.setErrorMessage} brokerProfile={props.brokerProfile}/>
              )
            })
          }
        </Grid>
      </>
    );
}

export default BrokerPostedListings;