import { React, useState} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const options = ['Weekly', 'Daily', 'Monthly', 'Never'];

const NotificationDropDown = (props) => {
const [value, setValue] = useState(props.frequency ? props.frequency : options[0]);
  return (
      <Autocomplete
        freeSolo={false}
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          props.handleFrequencyUpdate(newValue);
        }}
        id="notifications-dropdown"
        options={options}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Notifications" />}
      />
  );
}
export default NotificationDropDown;