// useDocumentTitle.js
import { useEffect } from 'react'

const SetPageTitle = (props) => {

  useEffect(() => {
    document.title = props.title;
  }, [props.title]);
}

export default SetPageTitle