/*
0. Form which will capture user email to submit for requesting a code
1. On success, Display a form that will accept a code (can probably just copy the confirmation page)
2. The form should also capture a new password
3. Submit both to cognitorUser.confirm password
3. On SUCCESS navigate('/login/')

*/
import { React, useState } from 'react';
import { Box, Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import { SnackBar } from '../SnackBar/SnackBar';
import LoadingButton from '@mui/lab/LoadingButton';
import { forgotPassword } from '../../services/UserManagementService';

const LoginFormField = {
    margin: "auto"
}

export const ResetPassword = (props) => {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    // update registration details state
    const handleFieldUpdate = (event) => {
        props.setEmail(event.target.value);
    }

    // attempt to register user
  const handleSubmit = async(username) => {
    forgotPassword(
        setLoading, 
        username, 
        setErrorMessage, 
        props.setResetPasswordResponse
    )
  }

    return(
        <Box>
            <Grid container direction="row" justifyContent="center" alignItems="center" position="relative">
                <Grid item xs={11} sm={6} sx={LoginFormField}>
                    <h3>Let's get that password changed!</h3>
                    <p>Please enter your email address below</p>
                    <TextField
                            required
                            id="email"
                            label="Email Address"
                            variant="filled"
                            autoComplete="username"
                            onChange={handleFieldUpdate}
                            style={{width:"100%"}}
                    />
                    <LoadingButton
                        onClick={() => handleSubmit(props.email)}
                        loading={loading}
                        // loadingPosition="end"
                        variant="contained"
                        style={{width:"100%", marginTop:"8px", marginBottom: "8px"}}
                    >
                    Submit
                </LoadingButton>
                </Grid>
                {errorMessage && <SnackBar errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>}
            </Grid>
        </Box>
    )
}