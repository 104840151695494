import { React, useEffect,useState } from 'react';
import { Grid } from '@mui/material';
import Listing from '../../../components/Listing/Listing';
import Footer from '../../../components/Footer/Footer';

const MyDeals = (props) => {

  const [dealStatuses, setDealStatuses] = useState();

  /**
   *  1. Requested More Info
      2. Signed NDA
      3. Requested Access to DR
      4. Received DR Access

      listingId : {
        requested-info : true/false,
        signed-nda : true/false
        dr-request-sent : true/false
        dr-request-response : False or [Approved,Rejected,Pending]
      }
   */

  const gatherUserDeals = () => {
    let listingStatus = {};

    for(let li in props.buyerDeals){ 
      let listingId = props.buyerDeals[li]._id;
      if(listingId in props.userInfoRequests){
        listingStatus[listingId] = {
          "requested-info" : props.userInfoRequests[listingId].status['requested-info'],
          "signed-nda" : props.userInfoRequests[listingId].status['signed-nda'] ? props.userInfoRequests[listingId].status['signed-nda'] : false,
          "dr-request-sent" : false,
          "dr-request-response" : false,
          "listing" : props.buyerDeals[li]
        }
      }else if(listingId in props.drRequestsSent){
        listingStatus[listingId] = {
          "requested-info" : true,
          "signed-nda" : true,
          "dr-request-sent" : true,
          "dr-request-response" : props.drRequestsSent[listingId],
          "listing" : props.buyerDeals[li]
        }
      }
    }
    return listingStatus;
  }

  useEffect(() => {
    if(!dealStatuses && props.buyerDeals){
      const results = gatherUserDeals();
      setDealStatuses(results);
    }
  },[dealStatuses, props.buyerDeals]);

    return(
      <>
        <Grid container direction="row" justifyContent="center" marginTop="8px">
          { 
            props.buyerDeals && dealStatuses &&
            props.buyerDeals.map((listing) => {
              return(
                <Listing listing={listing._source} key={listing._id} indexOfFavorite={props.userFavorites.indexOf(listing._id)} setUserFavorites={props.setUserFavorites} setErrorMessage={props.setErrorMessage} dealStatuses={dealStatuses}/>
              )
            })
          }          
        </Grid>
        <Footer />
      </>
    );
}

export default MyDeals;