import { React } from 'react';

export const retrieveListedByProfileData = async(email) => {
  
  const response = await fetch('/api/listing/listed-by-profile', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({'email' : email})
  }).then(res => res.json()).then((data) => {
    return data;
  });
  return response;
};

export const retrieveListingStatistics = async(statPayload) => {
  const response = await fetch('/api/listing/broker/listing-statistics', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(statPayload)
  }).then(res => res.json()).then((data) => {
    return data;
  });
  return response;
};

export const listingStatistics = async(statPayload) => {
  const response = await fetch('/api/listing/listing-statistics', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(statPayload)
  }).then(res => res.json()).then((data) => {
    return data;
  });
  return response;
};

export const searchListings = async(searchBody) => {
  const response = await fetch('/api/listing/search-listings', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(searchBody)
  }).then(res => res.json()).then((data) => {
    return data;
  });
  return response;
};

export const putImage = async(image_content, image_type, image_title, setErrorMessage) => {
  const response = await fetch('/api/listing/image', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      'image-content' : image_content,
      'image-type' : image_type,
      'image-title' : image_title
    }),
  }).then(res => res.json()).then((data) => {
    return data;
  });
  if(response.errorMessage){
    setErrorMessage(response.errorMessage);
  }
  else{
    return response;
  }
}

export const postListing = async(businessDetails, s3ResourceURL, listingId, userAttributes) => { 
    delete businessDetails['image-content'];
    businessDetails['s3-resource-url'] = s3ResourceURL;
    businessDetails['listing-id'] = listingId;
    businessDetails['posted-by-name'] = userAttributes.name;
    businessDetails['posted-by-email'] = userAttributes.email;
    businessDetails['location'] = businessDetails.state + ',' + businessDetails.city;
    businessDetails['phone'] = businessDetails['phone'].replaceAll(' ', '');
    delete businessDetails.state;
    delete businessDetails.city;
    (userAttributes.picture) ? (businessDetails['posted-by-picture'] = userAttributes.picture) : (businessDetails['posted-by-picture'] = "");
    
    const response = await fetch('/api/listing', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(businessDetails),
    }).then(res => res.json()).then((data) => {
      return data;
    });
   
    return response
  };

export const retrieveUserListings = async() => {
  const response = await fetch('/api/listing', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(res => res.json()).then((data) => {
    return data;
  });

  return response;
};

export const updateListing = async(updatePayload) => {
  const response = await fetch('/api/listing', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body : JSON.stringify(updatePayload)
  }).then(res => res.json()).then((data) => {
    return data;
  });

  return response;
};

export const deleteListing = async(deletePayload) => {
  const response = await fetch('/api/listing', {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body : JSON.stringify(deletePayload)
  }).then(res => res.json()).then((data) => {
    return data;
  });

  return response;
};

export const queryByPrice = async(listing_status, lowest_price, highest_price, priceLastEvaluatedKey) => {
  const response = await fetch('/api/listing/query-by-price', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      "listingStatus": listing_status,
      "lowestPrice": lowest_price, 
      "highestPrice": highest_price, 
      "priceLastEvaluatedKey": priceLastEvaluatedKey
  }),
  }).then(res => res.json()).then((data) => {
    return data;
  });
  return response;
};

export const queryByCashFlow = async(listing_status, lowest_cash_flow, highest_cash_flow, cashFlowLastEvaluatedKey) => {
  const response = await fetch('/api/listing/query-by-cash-flow', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      "listingStatus": listing_status,
      "lowestCashFlow": lowest_cash_flow, 
      "highestCashFlow": highest_cash_flow, 
      "cashFlowLastEvaluatedKey": cashFlowLastEvaluatedKey  
  }),
  }).then(res => res.json()).then((data) => {
    return data;
  });
  return response;
};

export const queryByIndustry = async(listing_status, industry, industryLastEvaluatedKey) => {
  const response = await fetch('/api/listing/query-by-industry', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      "listingStatus": listing_status, 
      "industry": industry, 
      "industryLastEvaluatedKey":industryLastEvaluatedKey 
  }),
  }).then(res => res.json()).then((data) => {
    return data;
  });
  return response;
};

export const queryByLocation = async(listing_status, location, locationLastEvaluatedKey) => {
  const response = await fetch('/api/listing/query-by-location', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      "listingStatus": listing_status, 
      "listingLocation": location, 
      "locationLastEvaluatedKey": locationLastEvaluatedKey
  }),
  }).then(res => res.json()).then((data) => {
    return data;
  });
  return response;
};

export const queryByIndustryAndLocation = async(listing_status, industry, location, industryAndLocationLastEvaluatedKey) => {
  const response = await fetch('/api/listing/query-by-industry-and-location', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      "listingStatus": listing_status, 
      "listingLocation": location, 
      "industry": industry,
      "industryAndLocationLastEvaluatedKey": industryAndLocationLastEvaluatedKey
  }),
  }).then(res => res.json()).then((data) => {
    return data;
  });
  return response;
};

export const listingDetailsById = async(listingId) => {
  const response = await fetch('/api/listing/details', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      'listing-id': listingId
  }),
  }).then(res => res.json()).then((data) => {
    return data;
  });
  return response;
};

export const retrieveTotalListings = async() => {
  const response = await fetch('/api/listing/total-listings', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(res => res.json()).then((data) => {
    return data;
  });

  return response;
};

export const getDataRoomDocURL = async (key) => {
  const response = await fetch('/api/aws/get-dataroom-doc-url', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      "document-key" : key
    })
  }).then(res => res.json()).then((data) => {
    return data;
  });
  return response;
}