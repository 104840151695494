import { React } from 'react';

export const searchForBusinesses = async(searchPayload) => {

  const response = await fetch('/api/dealScout', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(searchPayload),
  }).then(res => res.json()).then((data) => {
    return data;
  });
  return response;
};

export const upsertDealScoutSearch = async(savedSearchPayload) => {
  const response = await fetch('/api/dealScout/saved-searches', {
     method: 'POST',
     headers: {
       'Content-Type': 'application/json',
     },
     body: JSON.stringify(savedSearchPayload),
   }).then(res => res.json()).then((data) => {
     return data;
   });
   return response;
 };

 export const upsertDealScoutList = async(savedListPayload) => {
  const response = await fetch('/api/dealScout/saved-lists', {
     method: 'POST',
     headers: {
       'Content-Type': 'application/json',
     },
     body: JSON.stringify(savedListPayload),
   }).then(res => res.json()).then((data) => {
     return data;
   });
   return response;
 };

 export const upsertDealScoutCampaign = async(savedCampaignPayload) => {
  const response = await fetch('/api/dealScout/saved-campaigns', {
     method: 'POST',
     headers: {
       'Content-Type': 'application/json',
     },
     body: JSON.stringify(savedCampaignPayload),
   }).then(res => res.json()).then((data) => {
     return data;
   });
   return response;
 };

 export const getDealScoutData = async() => {
  const response = await fetch('/api/dealScout/scout-objects', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  }).then(res => res.json()).then((data) => {
    return data;
  });
  return response;
};

export const initializeDealScout = async() => {
  const response = await fetch('/api/dealScout/initialize', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    }
  }).then(res => res.json()).then((data) => {
    return data;
  });
  return response;
};

// export const dataAxle = async(searchPayload) => {
//   const response = await fetch('/api/dealScout/data-axle', {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(searchPayload),
//   }).then(res => res.json()).then((data) => {
//     return data;
//   });
//   return response;
// };

// export const getDataAxleCategories = async() => {
//   const response = await fetch('/api/dealScout/data-axle-sit-codes', {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   }).then(res => res.json()).then((data) => {
//     return data;
//   });
//   return response;
// };