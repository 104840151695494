import { Card } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Link } from "react-router-dom";
import "./ProductListing.css";
import { useNavigate } from "react-router-dom";

const ProductListing = ({
  product,
  isCheckout,
  returningUser,
  currentUserPlan,
  selectPricingPlan,
  userAttributes
}) => {
  const navigate = useNavigate();
  const growthButton = product.product_name === "Growth" ? "growth-button" : "";
  const textColor = product.product_name === "Growth" ? "white" : "black";
  let backgroundColor;
  switch (product.product_name) {
    case "Basic":
      backgroundColor = "white";
      break;
    case "Growth":
      backgroundColor = "var(--base)";
      break;
    case "Advanced":
      backgroundColor = "white";
      break;
    case "Enterprise":
      backgroundColor = "white";
      break;
    default:
      backgroundColor = "white";
      break;
  }

  const handleGoogleEvent = (buttonName) => {
    if (
      !window.location.host.includes("localhost") &&
      !window.location.host.includes("dev.onedeal.biz")
    ) {
      const gtagParamMap = {
        button: buttonName,
      };
      window.gtag("event", "button_click", gtagParamMap);
    }
  };

  return (
    <Card
      raised={true}
      sx={{
        backgroundColor,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "2rem 1.5rem",
        flex: 1,
        width: "100%",
        maxWidth: "17.5rem",
        overflow: "inherit",
        borderRadius: "20px",
        gap: "1rem",
        color: textColor,
      }}
    >
      <div className="product-name-container">
        <p
          className={`product-name ${
            product.product_name === "Growth" ? "growth" : ""
          }`}
        >
          {product.product_name}
        </p>
        {product.product_name === "Growth" && (
          <p className="cmn-btn popular-badge">Popular</p>
        )}
      </div>
      <div className="product-price-container">
        {product.product_name !== "Enterprise" ? (
          <>
            <p
              className={`product-price ${
                product.product_name === "Growth" ? "growth" : ""
              }`}
            >
              ${product.prices[0].price_cost / 100}
            </p>
            <div style={{ position: "relative", top: "10px" }}>
              <p
                className={`${
                  product.product_name === "Growth" ? "growth" : ""
                }`}
                style={{ margin: 0 }}
              >
                /
              </p>
              <p
                className={`${
                  product.product_name === "Growth" ? "growth" : ""
                }`}
                style={{ margin: 0, lineHeight: 0.9 }}
              >
                month
              </p>
            </div>
          </>
        ) : (
          <p className="product-price">Custom</p>
        )}
      </div>
      {product.product_name === "Growth" ? (
        <p className="product-description growth">
          {product.product_description}
        </p>
      ) : (
        <p className="product-description">{product.product_description}</p>
      )}

      <hr className="line-separator" />
      {product.product_features && (
        <ul
          style={{
            fontFamily: "'Montserrat', sans-serif",
          }}
          className="product-features-list"
        >
          {product.product_features.map((feature) => {
            return (
              <li key={feature.name} className="product-feature">
                <CheckCircleIcon />
                {feature.name}
              </li>
            );
          })}
        </ul>
      )}
      {isCheckout ? (
        <div className="product-button-container">
          {product.prices.map((price) => {
            if(price.price_id === process.env.REACT_APP_STRIPE_BASIC_ID_PROD || price.price_id === process.env.REACT_APP_STRIPE_GROWTH_ID_PROD || price.price_id === process.env.REACT_APP_STRIPE_ADV_ID_PROD){
              return (
                <button
                  key={price.price_id}
                  disabled={currentUserPlan === product.product_id ? true : false}
                  className={`cmn-btn product-button ${growthButton}`}
                  onClick={() => {
                    // prevents choosing the same already-subscribed plan if user edits the HTML to remove the disabled attribute
                    if (currentUserPlan === product.product_id) {
                      return;
                    }
                    if(localStorage.getItem("authorized") && userAttributes){
                      selectPricingPlan(
                        product.product_name,
                        product.product_description,
                        price.price_id,
                        price.price_cost
                      );
                    }else{
                      navigate('/login');
                    }
                  }}
                >
                  {returningUser
                    ? `Change to ${product.product_name} Plan`
                    : `Choose ${product.product_name} Plan`}
                </button>
              ); 
            }
          })}
        </div>
      ) : product.product_name === "Enterprise" ? (
        <a
          href="https://calendly.com/anthony-onedeal/30min"
          target="_blank"
          rel="noreferrer"
          onClick={() => handleGoogleEvent("enterprise-plan-inquiry")}
        >
          <button
            className="cmn-btn non-checkout-button"
            style={{ borderRadius: "20px" }}
          >
            Contact OneDeal
          </button>
        </a>
      ) : (
        <Link to="/deal-scout/checkout">
          <button
            className={`cmn-btn non-checkout-button ${growthButton}`}
            style={{ borderRadius: "24px" }}
          >
            Get started
          </button>
        </Link>
      )}
    </Card>
  );
};

export default ProductListing;
