import { React, useState, useEffect } from 'react';
import { Grid, Box} from '@mui/material';
import DealScoutNavBar from '../../components/NavBar/DealScoutNavBar';
import { useLocation, useNavigate } from 'react-router-dom';
import { retrieveUserAttributes } from '../../services/UserManagementService';
import { SnackBar } from '../../components/SnackBar/SnackBar';
import Footer from '../../components/Footer/Footer';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import CampaignIcon from '@mui/icons-material/Campaign';
import ListItemText from '@mui/material/ListItemText';
import '../../pages/Landing/App.scss';
import SetPageTitle from '../../components/SetPageTitle/SetPageTitle';
import { getDealScoutData, } from '../../services/DealScout';
import WelcomeModal from './Welcome';
import "./DealScout.css"
import Scouts from './Scouts/Scouts';
import Lists from './Lists/Lists';
import Campaigns from './Campaigns/Campaigns';
import Pagination from '@mui/material/Pagination';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';
import CRM from './CRM/CRM';
import {makeStyles} from '@mui/styles';
import SearchResults from './Scouts/SearchResults';

const useStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      fontWeight: "bold",
      marigin: "Auto",
      "&.Mui-selected": {
        backgroundColor: "#518e6e",
        color: "white",
      },
    },
  },
}));

const drawerWidth = 200;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme, drawOpen }) => ({
  display: "flex",
  alignItems: "center",
  ...(drawOpen && {
    justifyContent: "flex-end",
  }),
  ...(!drawOpen && {
    justifyContent: "center",
  }),
  // padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "drawOpen",
})(({ theme, drawOpen }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(drawOpen && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!drawOpen && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
  "@media(max-Width: 1023px)": {
    display: "none",
  },
}));

const DealScout = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();

  // state management
  const [userAttributes, setUserAttributes] = useState();
  const [usersDealScouts, setUsersDealScouts] = useState();
  const [drawOpen, setDrawOpen] = useState(true);
  const [drawComponent, setDrawComponent] = useState("setScout");
  const [searchResults, setSearchResults] = useState();
  const [selectedPageRange, setSelectedPageRange] = useState([0, 10]);
  const [errorMessage, setErrorMessage] = useState();
  const [listControlledByCampaign, setListControlledByCampaign] = useState();
  const handleDrawer = () => setDrawOpen(!drawOpen);

  const getUserAttributes = async () => {
    retrieveUserAttributes().then((response) => {
      if (response.errorMessage) {
        setErrorMessage(response.errorMessage);
      } else {
        setUserAttributes(response.result);
      }
    });
  };

  const getUsersDealScouts = async () => {
    getDealScoutData().then((response) => {
      setUsersDealScouts(response);
    });
  };

  const handlePageSelect = (event, page) => {
    setSelectedPageRange([page * 10 - 10, page * 10]);
  };

  useEffect(() => {
    if (
      !window.location.host.includes("localhost") &&
      !window.location.host.includes("dev.onedeal.biz")
    ) {
      window.gtag("event", "page_view", {
        page_path: location.pathname + location.search,
      });
    }

    //if the user is logged in...
    if (localStorage.getItem("authorized")) {
      if (!userAttributes) {
        getUserAttributes();
      }
      if(!usersDealScouts){
        getUsersDealScouts(props.userProfileData.email);
      }
    } else {
      navigate("/login");
    }

  },[props.userProfileData, usersDealScouts, drawComponent, searchResults]);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      {
        usersDealScouts !== undefined && 
        <WelcomeModal openWelcomeModal={!usersDealScouts.userExists} />
      }
      <SetPageTitle title="Deal Scout | Find Off Market Deals" />
      <Drawer
        id="filter-drawer"
        variant="permanent"
        drawOpen={drawOpen}
        sx={{ "& .MuiDrawer-paper": { backgroundColor: "#F2F2F2" } }}
      >
        <DrawerHeader drawOpen={drawOpen}>
          {drawOpen && (
            <div style={{ textAlign: "center" }}>
              <p>Deal Scout</p>
            </div>
          )}
          <IconButton
            onClick={handleDrawer}
            sx={{ marginLeft: drawOpen ? "10%" : "0" }}
          >
            {!drawOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        {/* <Divider /> */}
        <List>
          <ListItem key="Scouts" disablePadding sx={{ display: "block" }}>
            <ListItemButton
              onClick={() => setDrawComponent("setScout")}
              sx={{
                minHeight: 48,
                justifyContent: drawOpen ? "initial" : "center",
                px: 2.5,
                color: drawComponent === "setScout" ? "#0e9384" : "#000", //change color
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: drawOpen ? 3 : "auto",
                  justifyContent: "center",
                  color: drawComponent === "setScout" ? "#0e9384" : "#000", //change color
                }}
              >
                <ManageSearchIcon />
              </ListItemIcon>
              <ListItemText
                primary="Scouts"
                sx={{ opacity: drawOpen ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem key="Lists" disablePadding sx={{ display: "block" }}>
            <ListItemButton
              onClick={() => setDrawComponent("listBuilder")}
              sx={{
                minHeight: 48,
                // justifyContent: 'center',
                justifyContent: drawOpen ? "initial" : "center",
                px: 2.5,
                color: drawComponent === "listBuilder" ? "#0e9384" : "#000",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: drawOpen ? 3 : "auto",
                  justifyContent: "center",
                  color: drawComponent === "listBuilder" ? "#0e9384" : "#000",
                }}
              >
                <ChecklistRtlIcon />
              </ListItemIcon>
              <ListItemText
                primary="Lists"
                sx={{ opacity: drawOpen ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem
            key="Campaign Manager"
            disablePadding
            sx={{ display: "block" }}
          >
            <ListItemButton
              onClick={() => setDrawComponent("campaignManager")}
              sx={{
                minHeight: 48,
                // justifyContent: 'center',
                justifyContent: drawOpen ? "initial" : "center",
                px: 2.5,
                color: drawComponent === "campaignManager" ? "#0e9384" : "#000",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: drawOpen ? 3 : "auto",
                  justifyContent: "center",
                  color:
                    drawComponent === "campaignManager" ? "#0e9384" : "#000",
                }}
              >
                <CampaignIcon />
              </ListItemIcon>
              <ListItemText
                primary="Campaigns"
                sx={{ opacity: drawOpen ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem key="CRM" disablePadding sx={{ display: "block" }}>
            <ListItemButton
              onClick={() => setDrawComponent("crm")}
              sx={{
                minHeight: 48,
                // justifyContent: 'center',
                justifyContent: drawOpen ? "initial" : "center",
                px: 2.5,
                color: drawComponent === "crm" ? "#0e9384" : "#000",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: drawOpen ? 3 : "auto",
                  justifyContent: "center",
                  color: drawComponent === "crm" ? "#0e9384" : "#000",
                }}
              >
                <MarkunreadMailboxIcon />
                </ListItemIcon>
                <ListItemText primary="CRM" sx={{ opacity: drawOpen ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
        <Grid container direction="row" justifyContent="center" marginTop="8px" display="flow-root">
          <Grid item xs={12}>
            <DealScoutNavBar userAttributes={userAttributes} setUserAttributes={setUserAttributes} userProfileData={props.userProfileData} setUserProfileData={props.setUserProfileData}/>
          </Grid>
          {
            drawComponent === "setScout" && 
            <Scouts usersDealScouts={usersDealScouts} setSearchResults={setSearchResults} setDrawComponent={setDrawComponent} userProfileData={props.userProfileData} setUserProfileData={props.setUserProfileData}/>
          }
          {
            (drawComponent === "searchResults") && 
            <>
              <SearchResults usersDealScouts={usersDealScouts} setUsersDealScouts={setUsersDealScouts} searchResults={searchResults} selectedPageRange={selectedPageRange}/>
              {
                searchResults !== undefined && searchResults.length &&
                <Grid item sx={{justifyContent:'center', display:'flex'}}>
                  <Pagination 
                    count={Math.ceil(searchResults.length / 10)} 
                    disabled={!searchResults.length} 
                    onChange={handlePageSelect}
                    classes={{ ul: classes.ul }}
                  />
                  </Grid>
              }
            </>
          }
          {
            drawComponent === "listBuilder" &&
              <Lists usersDealScouts={usersDealScouts} setUsersDealScouts={setUsersDealScouts} searchResults={searchResults} selectedPageRange={selectedPageRange} listControlledByCampaign={listControlledByCampaign}/>
          }
          {
            drawComponent === "campaignManager" &&
            <Campaigns usersDealScouts={usersDealScouts} setUsersDealScouts={setUsersDealScouts} name={props.userProfileData['first-name'] + " " + props.userProfileData['last-name']} setDrawComponent={setDrawComponent} setListControlledByCampaign={setListControlledByCampaign}/>
          }
          {
            drawComponent === "crm" &&
            <CRM usersDealScouts={usersDealScouts} setUsersDealScouts={setUsersDealScouts} setDrawComponent={setDrawComponent} />
          }
          
          <Footer/>
        </Grid>
        {errorMessage && <SnackBar errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>}
      </Box>
  );
};

export default DealScout;
